import React from 'react';
import moment from 'moment';
import { Avatar } from '_seriph';

export const getColumns = () => {
  return [
    {
      title: 'Name',
      dataIndex: 'display_name',
      render: (display_name, data) => {
        return (
          <div className="emp-info">
            <Avatar type="company" picture={data.picture || null} size="mini" />
            <h4>{display_name}</h4>
          </div>
        );
      }
    },
    {
      title: 'Joined',
      dataIndex: 'createdAt',
      key: 'invite_link',
      align: 'right',
      width: 180,
      render: (createdAt) => {
        return <span style={{color: '#707B90'}}>joined {moment(createdAt * 1).fromNow()}</span>;
      }
    }
  ];
};
