import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Accordion, Button } from '_seriph';
import { IconEye, IconPlus, IconArchive } from '@tabler/icons-react';
import { touchpointTypes } from '_constants/campaigns';
import './Touchpoints.scss';

const Panel = Accordion.Panel;

const Touchpoint = props => {
  return (
    <div className="touchpoint" onClick={props.onClick}>
      <span>{props.children}</span>
      <div className="preview">
        <IconEye />
      </div>
    </div>
  );
};

class Touchpoints extends Component {
  newTouchpoint = () => {
    this.props.showTouchpointModal('create');
  };
  getIcon = touchpoint => {
    const typeData = touchpointTypes[touchpoint.type];
    if (!typeData) return null;
    return typeData.icon || null;
  };
  render() {
    const { touchpoints, showTouchpointModal, company } = this.props;

    const archiveList = touchpoints.filter(ch => ch.archived === true);

    return (
      <div id="company-sequences-touchpoints">
        <div className="touchpoints-title">
          <span>Touchpoints</span>
          <Button type="default" size="tiny" circle onClick={this.newTouchpoint}>
            <IconPlus />
          </Button>
        </div>
        <Accordion defaultActiveKey={['calls']} className="touchpoint-list">
          {Object.keys(touchpointTypes).filter(t => {
            if (company?.text_number) return true;
            return t !== 'text'
          }).map(t => {
            const touchpoint = touchpointTypes[t];
            const touchpointList = touchpoints.filter(ch => ch.type === t && !ch.archived);
            return touchpoint.static ? null : (
              <Panel
                header={touchpoint.name}
                key={t}
                menu
                icon={touchpoint.icon}
                amount={touchpointList.length}
                disabled={touchpointList.length <= 0}
              >
                {touchpointList.map(ch => (
                  <Touchpoint key={'ch-' + ch.id} onClick={() => showTouchpointModal(ch.id)}>
                    {ch.name}
                  </Touchpoint>
                ))}
              </Panel>
            );
          })}
          <Panel
            className="archived"
            header="Archived"
            key="archived"
            menu
            icon={<IconArchive />}
            amount={archiveList.length}
            disabled={archiveList.length <= 0}
          >
            {archiveList.map(ch => (
              <Touchpoint key={'ch-' + ch.id} onClick={() => showTouchpointModal(ch.id)}>
                <span className="icn">
                  {this.getIcon(ch)}
                  {ch.name}
                </span>
              </Touchpoint>
            ))}
          </Panel>
        </Accordion>
      </div>
    );
  }
}

export default withRouter(Touchpoints);
