import { experienceTypes, leadTypes, companyTypes, calculateValue } from './budget';
import { formatMoney, numberWithCommas, slicer, stripHTML, capitalize } from '_assets/js/helpers';
import { getKeyByValue } from '_constants/leadFields';
import { Hours } from '_constants/timezones';
import { durationText } from '_constants/campaigns';

/* WIZARD SETUP VALIDATION */
export const validateSetup = (step, c) => {
  if (!c.name) {
    step.missing.push(['Campaign Name', 'Missing campaign name']);
  } else {
    step.values.push(['Name', c.name]);
  }
  if (!c.objective) {
    step.missing.push(['Objective', 'Missing campaign objective']);
  } else {
    step.values.push([
      'Objective',
      c.objective === 'leadgen' ? 'Lead Generation' : 'Lead Qualification'
    ]);
  }
  step.isValid = step.missing.length > 0 ? false : true;
  return step;
};

/* WIZARD SALES REPS VALIDATION */
export const validateSalesReps = (step, c) => {
  if (c.rep_type !== 'internal') {
    if (!c.target_experience_level) {
      step.missing.push(['Rep Experience Level', 'Missing rep experience level']);
    } else {
      const level = experienceTypes.find(e => (e.value = c.target_experience_level));
      step.values.push(['Experience Level', level.title]);
    }
    if (c.target_skills.length <= 0) {
      step.missing.push('Rep Skills', 'At least 1 skill is required');
    } else {
      step.values.push(['Skills', slicer(c.target_skills, 3)]);
    }
  }
  if (c.chilipiper && c.calendly) {
    step.missing.push(['Calendars', 'Can only use ONE calendar (Calendly OR Chilipiper)']);
  }
  if (c.target_locations.length > 0)
    step.values.push([
      'Locations',
      slicer(
        c.target_locations.map(l => l.name),
        3
      )
    ]);
  if (c.target_languages.length > 0) step.values.push(['Languages', slicer(c.target_languages, 3)]);
  if (c.objective === 'qualify' && !c.custom_domain) {
    step.missing.push(['Email Domain', 'Must select an email domain to send from']);
  }
  step.isValid = step.missing.length > 0 ? false : true;
  return step;
};

/* WIZARD CUSTOMER PROFILE VALIDATION */
export const validateCustomerProfile = (step, c) => {
  if (!c.customer_type) {
    step.missing.push(['Lead Type', 'Must select a lead type']);
  } else {
    const obj = leadTypes.find(e => (e.value = c.customer_type));
    step.values.push(['Lead type', obj.title]);
  }
  if (!c.customer_company_size) {
    step.missing.push(['Company Size', 'Must select a company size']);
  } else {
    const obj = companyTypes.find(e => (e.value = c.customer_company_size));
    step.values.push(['Company Size', obj.title]);
  }
  if (c.customer_industry.length <= 0) {
    step.missing.push(['Industry', 'Must select at least 1 industry']);
  } else {
    step.values.push(['Industry', slicer(c.customer_industry, 3)]);
  }
  if (c.customer_decision_maker && c.customer_decision_maker.length > 0)
    step.values.push(['Decision Maker', slicer(c.customer_decision_maker, 15)]);
  if (c.customer_locations.length > 0)
    step.values.push([
      'Location',
      slicer(
        c.customer_locations.map(l => l.name),
        3
      )
    ]);
  if (c.customer_spend_start && c.customer_spend_end)
    step.values.push([
      'Spending',
      formatMoney(c.customer_spend_start) + ' - ' + formatMoney(c.customer_spend_end)
    ]);
  step.isValid = step.missing.length > 0 ? false : true;
  return step;
};

/* WIZARD LEAD INFORMATION VALIDATION */
export const validateLeadInfo = (step, c) => {
  const names = c.required.map(r => r.field);
  if (names.includes('mobile') || names.includes('email') || names.includes('phone')) {
    const niceNames = names.map(n => getKeyByValue(n));
    step.values.push(slicer(niceNames, 6));
  } else {
    step.missing.push('Point of Contact Required (Email, Phone, etc)');
  }
  let missingOptions = [];
  c.required.forEach(r => {
    if (r.type === 'Multiple' && (r.values || []).length <= 0) {
      missingOptions.push(getKeyByValue(r.field));
    }
  });
  if (missingOptions.length > 0) step.missing.push('Missing Options: ' + missingOptions.join(', '));
  step.isValid = step.missing.length > 0 ? false : true;
  return step;
};

/* WIZARD INSTRUCTIONS VALIDATION */
export const validateInstructions = (step, c) => {
  const strippedInstructions = stripHTML(c.guidelines);
  if (!strippedInstructions) {
    step.missing.push(['Missing', 'Instructions are required']);
  } else {
    step.values.push(['Instructions', slicer(strippedInstructions, 15)]);
  }
  step.isValid = step.missing.length > 0 ? false : true;
  return step;
};

/* WIZARD BUDGET VALIDATION */
export const validateBudget = (step, c, importData) => {
  const calc = calculateValue(c, importData ? importData.amount || 0 : 0);
  if (calc.cause.includes('payPerLead')) {
    step.missing.push(['Pay Per Lead', 'Pay per lead is too low']);
  } else {
    step.values.push(['Pay Per Lead', formatMoney(calc.payPerLead) + '/ lead']);
  }
  if (calc.cause.includes('target_agent_count')) {
    step.missing.push(['Reps Needed', 'Not enough reps selected']);
  } else {
    step.values.push([
      'Reps Needed',
      c.target_agent_count + ' rep' + (c.target_agent_count.length === 1 ? '' : 's')
    ]);
  }
  if (calc.cause.includes('target_experience_level')) {
    step.missing.push(['Experience Level', 'Must select an experience level']);
  } else {
    const level = experienceTypes.find(e => (e.value = c.target_experience_level));
    if (level) {
      step.values.push(['Experience Level', level.title]);
    } else {
      step.missing.push(['Experience Level', 'Must select an experience level']);
    }
  }
  if (calc.cause.includes('budget_total')) {
    step.missing.push(['Budget Total', 'Budget total is invalid']);
  } else {
    step.values.push(['Budget Total', formatMoney(c.budget_total)]);
  }
  step.isValid = step.missing.length > 0 ? false : true;
  return step;
};

/* WIZARD THRESHOLD VALIDATION */
export const validateThreshold = (step, c, importData) => {
  const calc = calculateValue(c, importData ? importData.amount || 0 : 0);
  const duration = c.threshold_settings.duration;
  if (!duration) {
    step.missing.push(['Duration', 'Need to select how long you want your campaign to last']);
  } else {
    step.values.push(['Duration', durationText[duration]]);
  }
  if (calc.cause.includes('payPerLead')) {
    step.missing.push(['Pay Per Lead', 'Pay per lead is too low']);
  } else {
    step.values.push(['Pay Per Lead', formatMoney(calc.payPerLead) + ' / lead']);
  }
  if (c.objective === 'qualify' && importData && importData.amount > 0) {
    const leadCount =
      numberWithCommas(importData.amount) + ' lead' + (importData.amount === 1 ? '' : 's');
    if (duration === 'continuous') step.values.push(['Lead Amount', leadCount + ' to start with']);
    if (duration === 'lifetime') step.values.push(['Lead Amount', leadCount]);
  } else if (c.objective === 'qualify') {
    step.missing.push(['Lead Amount', 'No import found, please import your leads']);
  } else if (c.objective === 'leadgen') {
    const leadCount =
      numberWithCommas(c.threshold_settings.lead_count) +
      ' lead' +
      (c.threshold_settings.lead_count === 1 ? '' : 's');
    if (duration === 'weekly') step.values.push(['Lead Amount', leadCount + ' / weekly']);
    if (duration === 'lifetime') step.values.push(['Lead Amount', leadCount]);
  }
  if (calc.cause.includes('target_agent_count')) {
    step.missing.push(['Reps Needed', 'Not enough reps selected']);
  } else {
    step.values.push([
      'Reps Needed',
      c.target_agent_count + ' rep' + (c.target_agent_count.length === 1 ? '' : 's')
    ]);
  }
  step.isValid = step.missing.length > 0 ? false : true;
  return step;
};

/* WIZARD IMPORT VALIDATION */
export const validateImport = (step, c, importData) => {
  if (importData && importData.is_valid) {
    ['phone', 'mobile', 'linkedin', 'email'].forEach(key => {
      if (!importData.is_valid[key]) {
        step.warning.push([
          'Missing Columns',
          `> 30% of your leads from your import are missing their ${key}`
        ]);
      }
    });
  }
  if (importData && importData.amount > 0) {
    step.values.push(['Type', importData.type.toUpperCase()]);
    step.values.push(['Name', importData.name]);
    step.values.push([
      'Amount',
      numberWithCommas(importData.amount) + ' lead' + (importData.amount === 1 ? '' : 's')
    ]);
  } else {
    step.missing.push(['Missing', 'No Import Found']);
  }
  step.isValid = step.missing.length > 0 ? false : true;
  return step;
};

/* WIZARD QUESTIONS VALIDATION */
// QUESTIONS: MIN 0, MAX 10
export const validateQuestions = (step, c) => {
  const missing = new Set();
  c.questions.forEach((q, i) => {
    const missingQuestion = !q.question ? true : false;
    if (missingQuestion) {
      missing.add(['Question #' + (i + 1), 'Does not have a question filled out']);
    }
    if (q.type === 'Checkbox' || q.type === 'Radio') {
      const validOptions = q.options.filter(o => (o ? o : false));
      if (validOptions.length < q.options.length) {
        return missing.add([
          'Question #' + (i + 1),
          'Need at least 1 option for this type of question'
        ]);
      }
    }
    if (missingQuestion) return;
    return step.values.push(['Question' + ' #' + (i + 1), q.question]);
  });
  if (c.questions && c.questions.length > 15)
    missing.add(['Maximum Questions', 'No more than 15 questions allowed']);
  if (c.questions && c.questions.length <= 0)
    step.values.push(['No questions', 'You chose not to ask any questions']);
  step.missing = [...missing];
  step.isValid = step.missing.length > 0 ? false : true;
  return step;
};

/* WIZARD PRODUCT VALIDATION */
export const validateProduct = (step, c) => {
  const missing = new Set();
  c.benefits.forEach((b, i) => {
    if (!b) return missing.add(['Benefit #' + (i + 1), 'Empty benefit input']);
    return step.values.push(['Benefit #' + (i + 1), slicer(b, 15)]);
  });
  c.objections.forEach((o, i) => {
    if (!o.name) return missing.add(['Objection #' + (i + 1), 'Empty objection title input']);
    const validResponses = o.responses.filter(r => (r ? r : false));
    if (validResponses.length < o.responses.length) {
      return missing.add(['Objection #' + (i + 1), 'Some of your responses are empty or missing']);
    } else if (o.responses.length <= 0) {
      return missing.add(['Objection #' + (i + 1), 'Missing responses, must have at least 1']);
    }
    return step.values.push(['Objection #' + (i + 1), slicer(o.name, 15)]);
  });
  if (c.benefits && c.benefits.length < 2)
    missing.add(['Benefit Amount', 'Must have at least 2 product benefits']);
  if (c.benefits && c.benefits.length > 15)
    missing.add(['Benefit Amount', 'Maximum 15 product benefits']);
  if (c.objections && c.objections.length < 1)
    missing.add(['Objection Amount', 'Minimum 1 product objection (pain points)']);
  if (c.objections && c.objections.length > 10)
    missing.add(['Objection Amount', 'Maximum 10 product objections (pain points)']);
  step.missing = [...missing];
  step.isValid = step.missing.length > 0 ? false : true;
  return step;
};

/* WIZARD SEQUENCES VALIDATION */
export const validateSequences = (step, c, importData, sequences) => {
  if (sequences?.length <= 0) {
    step.missing.push(['Missing', 'You must select a sequence']);
    step.isValid = false;
    return step;
  }

  const missing = new Set();
  const importCampaign = importData?.type === 'campaign';

  let mappings = [];
  try {
    if (importData?.mappings) {
      mappings = JSON.parse(importData?.mappings);
    }
  } catch (e) {
    console.error(e);
    mappings = [];
  }

  (sequences || []).forEach(seq => {
    step.values.push([seq.name, ' ']);
    if (seq.days && seq.days.length <= 0) {
      missing.add(['Day Amount', seq.name + ' must have at least 1 day']);
    }
    seq.days.forEach((s, i) => {
      const validTouchpoints = [];
      if (
        s.touchpoints.length === 1 &&
        s.touchpoints[0].type === 'wait' &&
        s.touchpoints[0].duration_type !== 'Days'
      ) {
        missing.add(['Day #' + (i + 1), "Wait must be in 'Days' if it is the only touchpoint"]);
      }
      if (s.touchpoints.length > 1 && s.touchpoints[0].type === 'wait') {
        missing.add(['Day #' + (i + 1), "Can't start day with a Wait"]);
      }
      if (s.touchpoints.length > 1 && s.touchpoints[s.touchpoints.length - 1].type === 'wait') {
        missing.add(['Day #' + (i + 1), "Can't end day with a Wait"]);
      }

      const fieldMapping = {
        call: ['phone', 'mobile'],
        linkedin: ['linkedin'],
        email: ['email']
      };

      s.touchpoints.forEach((r, j) => {
        if (['call', 'linkedin', 'email'].includes(r.type) && !importCampaign) {
          if (!mappings.some(m => fieldMapping[r.type].includes(m.leadProperty))) {
            return missing.add([
              'Missing Field',
              `Sequence action "${r.type}" is missing from your import`
            ]);
          }
        }
        if (!r.type)
          return missing.add(['Day #' + (i + 1), 'Touchpoint #' + (j + 1) + ' is missing a type']);
        if (r.type !== 'wait' && !r.touchpoint_id) {
          return missing.add([
            'Day #' + (i + 1),
            'Touchpoint #' + (j + 1) + ' does not have a selected template'
          ]);
        }
        if (r.type === 'wait' && !r.duration_type) {
          return missing.add([
            'Day #' + (i + 1),
            'Touchpoint #' + (j + 1) + ' is missing a duration type'
          ]);
        }
        if (r.type === 'wait' && r.duration_type !== 'All Day' && !r.duration) {
          return missing.add([
            'Day #' + (i + 1),
            'Touchpoint #' + (j + 1) + ' is missing a duration amount'
          ]);
        }
        validTouchpoints.push(r);
      });
      if (validTouchpoints.length > 0) {
        return step.values.push([
          'Day #' + (i + 1),
          validTouchpoints.map(s => capitalize(s.type)).join(', ')
        ]);
      }
    });
  });

  step.missing = [...missing];
  step.isValid = step.missing.length > 0 ? false : true;
  step.isValid = true;
  return step;
};

/* WIZARD SCHEDULE VALIDATION */
export const validateSchedule = (step, c) => {
  if (c.schedule_required && !c.schedule_timezone) {
    step.missing.push('Timezone');
  } else {
    step.values.push(c.schedule_timezone);
  }
  if (c.schedule_required && c.schedule_days.length <= 0) {
    step.missing.push('Minimum 1 Day');
  } else {
    const dayMap = c.schedule_days.map(s => {
      const startHours = Hours.find(h => h.value === s.start);
      const endHours = Hours.find(h => h.value === s.end);
      return (
        s.day +
        ` (${startHours.label.replace(':00', '') || '???'} - ${
          endHours.label.replace(':00', '') || '???'
        })`
      );
    });
    step.values.push(dayMap.join(', '));
  }
  (c.schedule_days || []).forEach(s => {
    if (s.start >= s.end) {
      step.missing.push('End Is Before Start (' + s.day + ')');
    }
  });
  step.isValid = step.missing.length > 0 ? false : true;
  return step;
};
