import React, { Component } from 'react';
import { Select } from '_seriph';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import './LocationMultiple.scss';

export default class LocationMultiple extends Component {
  state = {
    address: '',
    loading: false
  };
  onSelect = address => {
    this.setState({ loading: true });
    let currentValue = this.props.value;
    if (address === 'all') {
      currentValue = [{ name: 'All Locations', lat: 0, lng: 0 }];
      this.props.onChange(currentValue);
      this.setState({ loading: false });
    } else {
      geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(latLng => {
          currentValue.push({
            name: address,
            lat: latLng.lat,
            lng: latLng.lng
          });
          this.props.onChange(currentValue);
          this.setState({ loading: false });
        })
        .catch(() => this.setState({ loading: false }));
    }
  };
  onDeselect = place => {
    const currentValue = this.props.value || [];
    const newValue = currentValue.filter(address => address.name !== place);
    this.props.onChange(newValue);
  };
  handleChange = address => this.setState({ address });
  render() {
    const values = this.props.value.map(location => location.name);
    const atMax = values && values.length >= (this.props.limit || 9999);
    return (
      <React.Fragment>
        <PlacesAutocomplete value={this.state.address} onChange={this.handleChange}>
          {({ getInputProps, suggestions, getSuggestionItemProps }) => {
            const inputProps = { ...getInputProps() };
            let options = suggestions.map(suggestion => (
              <Select.Option {...getSuggestionItemProps(suggestion)} key={suggestion.description}>
                {suggestion.description}
              </Select.Option>
            ));

            return (
              <React.Fragment>
                <Select
                  mode="multiple"
                  disabled={this.props.disabled}
                  className={atMax ? 'seriph-multiple-locations max' : 'seriph-multiple-locations'}
                  dropdownClassName={atMax ? 'seriph-locations-drop-max' : ''}
                  placeholder={this.props.placeholder}
                  size="large"
                  style={{ width: '100%' }}
                  value={values}
                  onSelect={this.onSelect}
                  onDeselect={this.onDeselect}
                  onSearch={address => inputProps.onChange({ target: { value: address } })}
                  notFoundContent={this.props.notFoundContent}
                  loading={this.state.loading}
                  filterOption={() => true}
                >
                  {this.props.allLocations ? (
                    <Select.Option value="all" key="all">
                      All Locations
                    </Select.Option>
                  ) : null}
                  {options}
                </Select>
                <input {...getInputProps()} type="hidden" />
              </React.Fragment>
            );
          }}
        </PlacesAutocomplete>
      </React.Fragment>
    );
  }
}
