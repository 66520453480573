import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '_seriph';
import './EmptyBox.scss';

const EmptyBox = incomingProps => {
  const props = { ...incomingProps };
  return (
    <div className="empty-box">
      <h3>{props.title}</h3>
      <p>{props.description}</p>
      <Link to={props.to}><Button type="primary">{props.button}</Button></Link>
    </div>
  );
};

export default EmptyBox;
