import moment from 'moment';
import { otherColors } from '_seriph/_constants/colors';

export const generateData = data => {

  const weekdayData = [...data];

  const chartData = {
    not_interested: [
      {
        name: 'Bad timing',
        settings: { stroke: otherColors[0], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Financial issue',
        settings: { stroke: otherColors[1], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Using another solution already',
        settings: { stroke: otherColors[2], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Wrong contact person',
        settings: { stroke: otherColors[3], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'No reason',
        settings: { stroke: otherColors[4], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'No response',
        settings: { stroke: otherColors[5], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Wrong number',
        settings: { stroke: otherColors[6], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Bad email address',
        settings: { stroke: otherColors[7], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Duplicate',
        settings: { stroke: otherColors[8], strokeWidth: 3, dot: false },
        data: []
      },
      { name: 'Other', settings: { stroke: otherColors[9], strokeWidth: 3, dot: false }, data: [] }
    ]
  };

  if (weekdayData && weekdayData.length > 0) {
    const totals = { ...weekdayData[0].daily };
    weekdayData.forEach((d, i) => {
      /* Totals */
      if (i > 0) Object.keys(d.daily).forEach(s => (totals[s] += d.daily[s]));

      /* Sentiment Chart */
      chartData.not_interested[0].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.interest_bad_timing
      });
      chartData.not_interested[1].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.interest_financial
      });
      chartData.not_interested[2].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.interest_another_solution
      });
      chartData.not_interested[3].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.interest_wrong_contact
      });
      chartData.not_interested[4].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.interest_no_reason
      });
      chartData.not_interested[5].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.interest_no_response
      });
      chartData.not_interested[6].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.interest_wrong_number
      });
      chartData.not_interested[7].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.interest_bad_email
      });
      chartData.not_interested[8].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.interest_duplicate
      });
      chartData.not_interested[9].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.interest_other
      });

      chartData.not_interested[0].total = totals?.interest_bad_timing || 0;
      chartData.not_interested[1].total = totals?.interest_financial || 0;
      chartData.not_interested[2].total = totals?.interest_another_solution || 0;
      chartData.not_interested[3].total = totals?.interest_wrong_contact || 0;
      chartData.not_interested[4].total = totals?.interest_no_reason || 0;
      chartData.not_interested[5].total = totals?.interest_no_response || 0;
      chartData.not_interested[6].total = totals?.interest_wrong_number || 0;
      chartData.not_interested[7].total = totals?.interest_bad_email || 0;
      chartData.not_interested[8].total = totals?.interest_duplicate || 0;
      chartData.not_interested[9].total = totals?.interest_other || 0;

    });
    chartData.totals = totals;
  }

  /* Setup not-interested percentages */
  const niAmount = chartData.not_interested?.reduce((n, { total }) => n + total, 0) || 0;
  chartData.not_interested?.forEach((c, i) => {
    chartData.not_interested[i].percent = c.total / niAmount;
  });
  chartData.not_interested?.sort((a, b) => parseFloat(b.percent) - parseFloat(a.percent));

  return chartData;
};
