import gql from 'graphql-tag';

export const favoriteCall = gql`
  mutation favoriteCall($call_id: String!) {
    favoriteCall(call_id: $call_id)
  }
`;

export const getNewNumber = gql`
  mutation getNewNumber($campaign_id: String!) {
    getNewNumber(campaign_id: $campaign_id)
  }
`;

export const saveCallDisposition = gql`
  mutation saveCallDisposition($callSID: String!, $answered: Boolean!, $voicemail: Boolean) {
    saveCallDisposition(callSID: $callSID, answered: $answered, voicemail: $voicemail)
  }
`;

export const noCallList = gql`
  mutation noCallList($lead_id: String!, $number: String!, $type: String!) {
    noCallList(lead_id: $lead_id, number: $number, type: $type)
  }
`;
