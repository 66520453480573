import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { removeGmail } from '_graphql/mutations/integrations';
import queryString from 'query-string';
import { getErrors } from '_assets/js/helpers';
import logo from '_assets/brands/gmail.svg';
import { Card, Button, message } from '_seriph';
import { IconCheck, IconX, IconPlus } from '@tabler/icons-react';
import './Gmail.scss';

class Gmail extends Component {
  state = {
    setting: null
  };
  componentDidMount = () => {
    const params = queryString.parse(this.props.location.search);
    if (params.type === 'gmail' && params.result === 'success') {
      this.addGmail(params);
    } else if (params.type === 'gmail' && params.result === 'error') {
      message.error('Error connecting gmail account, try again');
    }
  };
  addGmail = () => {
    this.setState({ setting: 'gmail' });
    window.history.replaceState(null, null, window.location.pathname);
    setTimeout(() => this.props.loadIntegrations(this.resetSetting), 100);
    message.success('Gmail successfully integrated');
  };
  removeGmail = () => {
    this.setState({ setting: 'gmail' });
    this.props.client
      .mutate({ variables: { refresh: 'false' }, mutation: removeGmail })
      .then(result => {
        if (result && result.data && result.data.removeGmail) {
          setTimeout(() => this.props.loadIntegrations(this.resetSetting), 100);
          message.success('Gmail successfully removed');
        }
      })
      .catch(err => {
        this.setState({ setting: null });
        message.error(getErrors(err) || 'Error removing gmail, try again');
      });
  };
  gmail = () => {
    window.location.href =
      process.env.REACT_APP_API_PATH + '/gmail/oauth?companyId=' + this.props.myself.company;
  };
  resetSetting = () => this.setState({ setting: null });
  render() {
    const { setting } = this.state;
    const { integrations, loading } = this.props;

    const integration = integrations.gmail ? integrations.gmail : {};

    return (
      <Card id="gmail">
        <div className="int">
          <div className="int-header">
            <div className="int-logo">
              <img src={logo} title="Calendly" alt="Calendly" />
            </div>
            <h3 className="int-name">Gmail</h3>
            {integration.access_token && !loading && (
              <div className="int-check">
                <IconCheck />
              </div>
            )}
          </div>
          <p className="int-desc">
            Gmail lets reps use an email address you create for sending & receiving emails on SellX
          </p>
          <div className="int-actions">
            {(!integration.access_token || loading) && (
              <Button
                disabled={loading}
                type="secondary"
                icon="left"
                loading={setting === 'gmail'}
                onClick={this.gmail}
              >
                <IconPlus />
                Connect to Gmail
              </Button>
            )}
            {integration.access_token && !loading && (
              <Button
                type="danger"
                icon="left"
                loading={setting === 'gmail'}
                onClick={this.removeGmail}
              >
                <IconX />
                Disconnect {integration.email || 'Gmail'}
              </Button>
            )}
          </div>
        </div>
      </Card>
    );
  }
}

export default withRouter(Gmail);
