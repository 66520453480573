import React from 'react';
import moment from 'moment';

import { Alert, TextArea, InputDate } from '_seriph';

export function FollowUpTag({ formState: { note, date }, onChange }) {
  return (
    <>
      <InputDate
        label="Revisit Date?"
        onChange={v => onChange('date', v)}
        disabledDate={current => {
          current && current < moment().endOf('day');
        }}
        size="large"
        value={date}
      />
      <TextArea
        value={note}
        onChange={v => onChange('note', v.target.value)}
        size="large"
        label={
          <>
            Add a note <span>(optional)</span>
          </>
        }
        rows={6}
        placeholder="Write a message..."
      />
      <Alert type="info" icon={<></>}>
        <b>Follow up - </b>
        Pauses lead activities from showing up in your tasks until the selected follow up date.
      </Alert>
    </>
  );
}
