import React, { Component } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { numberWithCommas, decimalToPercent } from '_assets/js/helpers';
import { Loading } from '_seriph';
import './CallChart.scss';

export default class CallChart extends Component {
  state = {
    active: null
  };
  customTooltip = data => {
    const payload = data.payload || [];
    return (
      <div className="chart-tooltip">
        {payload.map((p, i) => {
          const percent = p?.payload?.payload?.percent;
          return (
            <div key={`tip-${i}`} className="tip-box">
              <div className="tip-title">{p.payload.name}</div>
              <div className="tip-contents">
                <div className="tip-item">
                  <div className="tip-name">Percent</div>
                  <div className="tip-value">{decimalToPercent(percent)}</div>
                </div>
                <div className="tip-item">
                  <div className="tip-name">Total</div>
                  <div className="tip-value">{numberWithCommas(p.value)}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  onMouseOver = (data, index) => this.setState({ active: index });
  onMouseLeave = () => this.setState({ active: null });
  render() {
    const { data, range, loading } = this.props;

    return (
      <div className="chart-box" id="connectivity-call-chart">
        <div className="chart-title">
          <h4>
            Call connectivity<span>({range.title})</span>
          </h4>
          <p>Details on your current call outreach</p>
        </div>
        <div className="chart-display">
          {loading ? (
            <div className="chart-loading" style={{ minHeight: '360px' }}>
              <Loading />
            </div>
          ) : (
            <React.Fragment>
              <div className="left-chart">
                <PieChart width={400} height={400}>
                  <Pie
                    data={data}
                    cx={200}
                    cy={200}
                    innerRadius={90}
                    outerRadius={180}
                    paddingAngle={2}
                    dataKey="value"
                    onMouseOver={this.onMouseOver}
                    onMouseLeave={this.onMouseLeave}
                  >
                    {data.map((s, i) => (
                      <Cell
                        key={`cell-${i}`}
                        fill={i === this.state.active ? s.fill + 'ee' : s.fill}
                      />
                    ))}
                  </Pie>
                  <Tooltip content={this.customTooltip} />
                </PieChart>
              </div>
              <div className="right-chart">
                <h4>Call breakdown</h4>
                <div className="dash-data">
                  {data?.map((d, i) => (
                    <div className={`dash-sts ${d.total <= 0 ? 'na' : ''}`} key={`ni-${i}`}>
                      <div>
                        <span style={{ backgroundColor: d.fill }} />
                        {d.name}
                      </div>
                      <h2>
                        {decimalToPercent(d.percent)} ({numberWithCommas(d.value) || '0'})
                      </h2>
                    </div>
                  ))}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}
