import React, { Component } from 'react';
import { Select, Avatar } from '_seriph';
import './Multiple.scss';

const { Option } = Select;

export default class Multiple extends Component {
  state = {
    term: ''
  };
  onSearch = term => this.setState({ term });
  onChange = value => {
    this.props.onChange(value);
  };

  render() {
    let children = [];
    const autocomplete = this.props.autocomplete || [];
    const type = this.props.type;

    if (type === 'users') {
      children = autocomplete;
      children = children.map(user => (
        <Option value={user.id} key={user.display_name}>
          <Avatar
            className="multiple-user-avatar"
            size="mini"
            type="agent"
            picture={user.picture}
          />
          {user.display_name}
        </Option>
      ));
    } else {
      const term = this.state.term || '';
      if (term.length > 0 || this.props.searchOnEmpty === true) {
        children = autocomplete.filter(item => {
          return item.toLowerCase().includes(term.toLowerCase());
        });
        const termAmount = 10 * (term.length || 1);
        if (children.length > termAmount && this.props.filter !== false)
          children = children.slice(0, termAmount);
      }
      children = children.map(item => <Option key={item}>{item}</Option>);
    }

    return (
      <Select
        className={`seriph-multiple ${type === 'users' ? 'users' : ''}`}
        disabled={this.props.disabled}
        mode="multiple"
        size="large"
        style={{ width: '100%' }}
        placeholder={this.props.placeholder}
        value={this.props.value || undefined}
        onChange={this.onChange}
        onSearch={this.onSearch}
        notFoundContent={this.props.notFoundContent}
      >
        {children}
      </Select>
    );
  }
}
