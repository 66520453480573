import React, { useState } from 'react';
import { IconPlus } from '@tabler/icons-react';
import './LeadTag.scss';

import { Tooltip } from '_seriph';
import { LeadTagModal } from './LeadTagModal';
import { tagMap } from './_variables';
import moment from 'moment';

export function LeadTag({ lead, client, reload, manageOnly }) {
  const [open, setOpen] = useState(false);
  const [tag, setTag] = useState({});
  const tags = lead.tags?.map(t => ({ ...t, ...{ date: moment(parseInt(t.date)).utc() } })) || [];
  // May not add/edit tags when lead is completed, and we limit to 1 right now
  const mayAddTags = lead.stage !== 'completed' && tags.length === 0;
  return (
    <>
      <LeadTagModal
        tagEdit={tag}
        client={client}
        lead={lead}
        open={open}
        close={() => {
          setOpen(false);
          setTag({});
          if (reload) reload();
        }}
      />
      { manageOnly || (!manageOnly && mayAddTags) ? (
        <div className={manageOnly ? 'manage' : 'view'}>
          { manageOnly ? (
            <Tooltip title="Tags" placement="left">
              <div className="paddme">
                <IconPlus className="add-lead-tag" onClick={mayAddTags ? () => setOpen(!open) : () => {
                  setTag({ ...tags[0], mode: 'edit' });
                  setOpen(true);
                }} />
              </div>
            </Tooltip>
          ) : (
            <IconPlus className="add-lead-tag" onClick={() => setOpen(!open)} />
          )}
        </div>
      ) : (
        <ul className="lead-tags">
          {tags.map?.(t => (
            <li className="" key={t.tag}>
              <Tooltip title={tagMap[t.tag]?.tooltip(t)}>
                <div
                  onClick={() => {
                    setTag({ ...t, mode: 'edit' });
                    setOpen(true);
                  }}
                  className={tagMap[t.tag].className}
                >
                  {tagMap[t.tag].display}
                </div>
              </Tooltip>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}
