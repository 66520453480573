import React, { Component } from 'react';
import { Loading, Modal, Button, message } from '_seriph';
import { getErrors } from '_assets/js/helpers';
import { getTemplate } from '_graphql/queries/campaign';
import Icons from 'App/_shared/Icons/Icons';
import { convertTagsOnly } from '_helpers/smartTags';
import './Instructions.scss';

export default class Instructions extends Component {
  state = {
    visible: true,
    template: null,
    loading: true
  };
  componentDidMount = () => {
    this.loadTemplate();
  };
  loadTemplate = () => {
    this.props.client
      .query({ variables: { template_id: this.props.templateId }, query: getTemplate })
      .then(result => {
        if (result && result.data && result.data.getTemplate) {
          const template = this.convert(result.data.getTemplate);
          this.setState({ template, loading: false });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not load instructions, try again');
        this.setState({ loading: false });
      });
  };
  convert = template => {
    const replaceInfo = {
      lead: this.props.lead || {},
      company: this.props.company || {},
      rep: this.props.user || {}
    };
    const templateWithoutSpan = convertTagsOnly(template.content);
    const preview = { content: templateWithoutSpan + '', information: template.information + '' };
    const content = templateWithoutSpan + template.information + '';
    (content.match(/\{\{(.*?)\}\}/g) || []).forEach(fullTag => {
      const parts = fullTag.replace('{{', '').replace('}}', '').split('.');
      const [tagType, tagField] = parts;
      const valueToReplace = replaceInfo[tagType][tagField] || null;
      if (valueToReplace && valueToReplace.length > 0) {
        preview.content = preview.content.replace(new RegExp(fullTag, 'g'), valueToReplace);
        preview.information = preview.information.replace(new RegExp(fullTag, 'g'), valueToReplace);
      }
    });
    return preview;
  };
  getTags = (body, subject) => {
    const content = body + subject + '',
      invalidTags = [];
    (content.match(/\{\{(.*?)\}\}/g) || []).forEach(val => invalidTags.push(val));
    return invalidTags;
  };
  complete = () => {
    if (this.props.complete) this.props.complete();
    this.hideModal();
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { loading, template } = this.state;
    const { isCompleted } = this.props;
    return (
      <Modal
        wrapClassName="sequence-instructions-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={true}
        width={640}
      >
        <Modal.Close onClick={this.hideModal} />
        <div className="seq-header">
          <Icons.Instructions />
          <h4>Review General Task</h4>
        </div>
        <div className="seq-body">
          {loading || !template ? (
            <Loading />
          ) : (
            <div className="quill-render" dangerouslySetInnerHTML={{ __html: template.content }} />
          )}
        </div>
        <div className="seq-actions">
          <Button type="default" onClick={this.hideModal}>
            Close
          </Button>
          {!isCompleted && (
            <Button type="primary" disabled={loading} onClick={this.complete}>
              Done
            </Button>
          )}
        </div>
      </Modal>
    );
  }
}
