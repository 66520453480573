import gql from 'graphql-tag';

export const getMessages = gql`
  query {
    messages {
      id
      last_chat
      last_user_id
      employee {
        id
        display_name
        title
        picture
        company {
          logo
          name
        }
      }
      agent {
        id
        display_name
        picture
        reputation
      }
      feed_read
      campaign_id
      employee_unread
      agent_unread
      updatedAt
      createdAt
    }
  }
`;

export const getMessage = gql`
  query ($id: ID, $campaign_id: ID) {
    message(id: $id, campaign_id: $campaign_id) {
      id
      last_chat
      last_user_id
      conversation {
        id
        body
        agent_id
        employee_id
        updatedAt
        createdAt
      }
      employee {
        id
        first_name
        display_name
        title
        picture
        company {
          logo
          name
        }
      }
      agent {
        id
        first_name
        display_name
        picture
        reputation
      }
      campaign_id
      employee_unread
      agent_unread
      updatedAt
      createdAt
    }
  }
`;
