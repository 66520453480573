import gql from 'graphql-tag';

export const createRepApplication = gql`
  mutation createRepApplication(
    $name: String!
    $email: String!
    $phone: String!
    $linkedin_url: String!
  ) {
    createRepApplication(name: $name, email: $email, phone: $phone, linkedin_url: $linkedin_url)
  }
`;

export const updateRepApplication = gql`
  mutation updateRepApplication(
    $id: String
    $status: String
    $name: String
    $email: String
    $phone: String
    $linkedin_url: String
    $country: String
    $state: String
    $city: String
    $years_experience: String
    $most_experienced_industries: [String]
    $weekly_availability: String
    $why_join: [String]
    $hear_about: [String]
    $hear_about_other: String
    $biggest_achievement: String
    $is_calendly_event_scheduled: Boolean
    $is_waitlist: Boolean
  ) {
    updateRepApplication(
      id: $id
      status: $status
      name: $name
      email: $email
      phone: $phone
      linkedin_url: $linkedin_url
      country: $country
      state: $state
      city: $city
      years_experience: $years_experience
      most_experienced_industries: $most_experienced_industries
      weekly_availability: $weekly_availability
      why_join: $why_join
      hear_about: $hear_about
      hear_about_other: $hear_about_other
      biggest_achievement: $biggest_achievement
      is_calendly_event_scheduled: $is_calendly_event_scheduled
      is_waitlist: $is_waitlist
    )
  }
`;

export const revokeTeamRep = gql`
  mutation revokeTeamRep($agent_id: String!) {
    revokeTeamRep(agent_id: $agent_id)
  }
`;

export const reinstateTeamRep = gql`
  mutation reinstateTeamRep($agent_id: String!) {
    reinstateTeamRep(agent_id: $agent_id)
  }
`;

