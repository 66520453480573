export const INDUSTRIES = [
  { code: '47', name: 'Accounting' },
  { code: '94', name: 'Airlines/Aviation' },
  { code: '120', name: 'Alternative Dispute Resolution' },
  { code: '125', name: 'Alternative Medicine' },
  { code: '127', name: 'Animation' },
  { code: '19', name: 'Apparel & Fashion' },
  { code: '50', name: 'Architecture & Planning' },
  { code: '111', name: 'Arts and Crafts' },
  { code: '53', name: 'Automotive' },
  { code: '52', name: 'Aviation & Aerospace' },
  { code: '41', name: 'Banking' },
  { code: '12', name: 'Biotechnology' },
  { code: '36', name: 'Broadcast Media' },
  { code: '49', name: 'Building Materials' },
  { code: '138', name: 'Business Supplies and Equipment' },
  { code: '129', name: 'Capital Markets' },
  { code: '54', name: 'Chemicals' },
  { code: '90', name: 'Civic & Social Organization' },
  { code: '51', name: 'Civil Engineering' },
  { code: '128', name: 'Commercial Real Estate' },
  { code: '118', name: 'Computer & Network Security' },
  { code: '109', name: 'Computer Games' },
  { code: '3', name: 'Computer Hardware' },
  { code: '5', name: 'Computer Networking' },
  { code: '4', name: 'Computer Software' },
  { code: '48', name: 'Construction' },
  { code: '24', name: 'Consumer Electronics' },
  { code: '25', name: 'Consumer Goods' },
  { code: '91', name: 'Consumer Services' },
  { code: '18', name: 'Cosmetics' },
  { code: '65', name: 'Dairy' },
  { code: '1', name: 'Defense & Space' },
  { code: '99', name: 'Design' },
  { code: '69', name: 'Education Management' },
  { code: '132', name: 'E-Learning' },
  { code: '112', name: 'Electrical/Electronic Manufacturing' },
  { code: '28', name: 'Entertainment' },
  { code: '86', name: 'Environmental Services' },
  { code: '110', name: 'Events Services' },
  { code: '76', name: 'Executive Office' },
  { code: '122', name: 'Facilities Services' },
  { code: '63', name: 'Farming' },
  { code: '43', name: 'Financial Services' },
  { code: '38', name: 'Fine Art' },
  { code: '66', name: 'Fishery' },
  { code: '34', name: 'Food & Beverages' },
  { code: '23', name: 'Food Production' },
  { code: '101', name: 'Fund-Raising' },
  { code: '26', name: 'Furniture' },
  { code: '29', name: 'Gambling & Casinos' },
  { code: '145', name: 'Glass, Ceramics & Concrete' },
  { code: '75', name: 'Government Administration' },
  { code: '148', name: 'Government Relations' },
  { code: '140', name: 'Graphic Design' },
  { code: '124', name: 'Health, Wellness and Fitness' },
  { code: '68', name: 'Higher Education' },
  { code: '14', name: 'Hospital & Health Care' },
  { code: '31', name: 'Hospitality' },
  { code: '137', name: 'Human Resources' },
  { code: '134', name: 'Import and Export' },
  { code: '88', name: 'Individual & Family Services' },
  { code: '147', name: 'Industrial Automation' },
  { code: '84', name: 'Information Services' },
  { code: '96', name: 'Information Technology and Services' },
  { code: '42', name: 'Insurance' },
  { code: '74', name: 'International Affairs' },
  { code: '141', name: 'International Trade and Development' },
  { code: '6', name: 'Internet' },
  { code: '45', name: 'Investment Banking' },
  { code: '46', name: 'Investment Management' },
  { code: '73', name: 'Judiciary' },
  { code: '77', name: 'Law Enforcement' },
  { code: '9', name: 'Law Practice' },
  { code: '10', name: 'Legal Services' },
  { code: '72', name: 'Legislative Office' },
  { code: '30', name: 'Leisure, Travel & Tourism' },
  { code: '85', name: 'Libraries' },
  { code: '116', name: 'Logistics and Supply Chain' },
  { code: '143', name: 'Luxury Goods & Jewelry' },
  { code: '55', name: 'Machinery' },
  { code: '11', name: 'Management Consulting' },
  { code: '95', name: 'Maritime' },
  { code: '97', name: 'Market Research' },
  { code: '80', name: 'Marketing and Advertising' },
  { code: '135', name: 'Mechanical or Industrial Engineering' },
  { code: '126', name: 'Media Production' },
  { code: '17', name: 'Medical Devices' },
  { code: '13', name: 'Medical Practice' },
  { code: '139', name: 'Mental Health Care' },
  { code: '71', name: 'Military' },
  { code: '56', name: 'Mining & Metals' },
  { code: '35', name: 'Motion Pictures and Film' },
  { code: '37', name: 'Museums and Institutions' },
  { code: '115', name: 'Music' },
  { code: '114', name: 'Nanotechnology' },
  { code: '81', name: 'Newspapers' },
  { code: '100', name: 'Non-Profit Organization Management' },
  { code: '57', name: 'Oil & Energy' },
  { code: '113', name: 'Online Media' },
  { code: '123', name: 'Outsourcing/Offshoring' },
  { code: '87', name: 'Package/Freight Delivery' },
  { code: '146', name: 'Packaging and Containers' },
  { code: '61', name: 'Paper & Forest Products' },
  { code: '39', name: 'Performing Arts' },
  { code: '15', name: 'Pharmaceuticals' },
  { code: '131', name: 'Philanthropy' },
  { code: '136', name: 'Photography' },
  { code: '117', name: 'Plastics' },
  { code: '107', name: 'Political Organization' },
  { code: '67', name: 'Primary/Secondary Education' },
  { code: '83', name: 'Printing' },
  { code: '105', name: 'Professional Training & Coaching' },
  { code: '102', name: 'Program Development' },
  { code: '79', name: 'Public Policy' },
  { code: '98', name: 'Public Relations and Communications' },
  { code: '78', name: 'Public Safety' },
  { code: '82', name: 'Publishing' },
  { code: '62', name: 'Railroad Manufacture' },
  { code: '64', name: 'Ranching' },
  { code: '44', name: 'Real Estate' },
  { code: '40', name: 'Recreational Facilities and Services' },
  { code: '89', name: 'Religious Institutions' },
  { code: '144', name: 'Renewables & Environment' },
  { code: '70', name: 'Research' },
  { code: '32', name: 'Restaurants' },
  { code: '27', name: 'Retail' },
  { code: '121', name: 'Security and Investigations' },
  { code: '7', name: 'Semiconductors' },
  { code: '58', name: 'Shipbuilding' },
  { code: '20', name: 'Sporting Goods' },
  { code: '33', name: 'Sports' },
  { code: '104', name: 'Staffing and Recruiting' },
  { code: '22', name: 'Supermarkets' },
  { code: '8', name: 'Telecommunications' },
  { code: '60', name: 'Textiles' },
  { code: '130', name: 'Think Tanks' },
  { code: '21', name: 'Tobacco' },
  { code: '108', name: 'Translation and Localization' },
  { code: '92', name: 'Transportation/Trucking/Railroad' },
  { code: '59', name: 'Utilities' },
  { code: '106', name: 'Venture Capital & Private Equity' },
  { code: '16', name: 'Veterinary' },
  { code: '93', name: 'Warehousing' },
  { code: '133', name: 'Wholesale' },
  { code: '142', name: 'Wine and Spirits' },
  { code: '119', name: 'Wireless' },
  { code: '103', name: 'Writing and Editing' }
];

export const TITLES = [
  'Truck Driver',
  'Driver',
  'Registered Nurse',
  'Software Engineer',
  'Project Manager',
  'Sales Associate',
  'Company Driver',
  'Certified Nursing Assistant',
  'Associate',
  'Customer Service Representative',
  'Administrative Assistant',
  'Caregiver',
  'Sales Representative',
  'Business Analyst',
  'Account Manager',
  'Account Executive',
  'Data Analyst',
  'Volunteer',
  'Product Manager',
  'Delivery Driver',
  'Data Scientist',
  'Financial Analyst',
  'Senior Software Engineer',
  'Engineer',
  'Program Manager',
  'Physical Therapist',
  'Technician',
  'Executive Assistant',
  'Owner',
  'Operations Manager',
  'Cook',
  'Licensed Practical Nurse',
  'Assistant Store Manager',
  'Cashier',
  'Assistant Manager',
  'General Manager',
  'Life Insurance Agent',
  'Analyst',
  'Marketing Manager',
  'Business Development Manager',
  'Specialist',
  'Security Officer',
  'Physician',
  'Summer Intern',
  'Travel Registered Nurse',
  'Intern',
  'Vice President',
  'Manager',
  'Sales Director',
  'Sales Manager',
  'Senior Project Manager',
  'Recruiter',
  'Mechanical Engineer',
  'Marketing Coordinator',
  'Senior Financial Analyst',
  'Speech Language Pathologist',
  'Server',
  'Graphic Designer',
  'Project Coordinator',
  'Regional Sales Manager',
  'Pharmacy Technician',
  'Store Manager',
  'Director Of Operations',
  'Developer',
  'Human Resources Manager',
  'Chief Financial Officer',
  'System Engineer',
  'Data Engineer',
  'Marketing Director',
  'Receptionist',
  'Maintenance Technician',
  'Partner',
  'Senior Accountant',
  'Staff Accountant',
  'Customer Success Manager',
  'Senior Product Manager',
  'Human Resources Generalist',
  'Outside Sales Representative',
  'Lead Sales Associate',
  'Shift Lead',
  'Java Developer',
  'English Teacher',
  'Shift Supervisor',
  'Retail Specialist',
  'Accountant',
  'Medical Assistant',
  'Office Manager',
  'Director',
  'Health Insurance Agent',
  'Occupational Therapist',
  'Insurance Agent',
  'Sales Specialist',
  'Frontend Developer',
  'Human Resources Business Partner',
  'Controller',
  'Manufacturing Engineer',
  'Marketing Specialist',
  'Human Resources Director',
  'Restaurant General Manager',
  'Retail Sales Associate',
  'Housekeeper',
  'Electrical Engineer',
  'Project Engineer',
  'Nurse Practitioner',
  'Dishwasher',
  'Marketing Associate',
  'Senior Director',
  'Operator',
  'Student Job',
  'Full Stack Developer',
  'Director Of Business Development',
  'Customer Service Specialist',
  'Scientist',
  'Store Associate',
  'Service Technician',
  'Senior Engineer',
  'Nurse',
  'Solutions Architect',
  'Research Associate',
  'User Experience Designer',
  'Merchandiser',
  'Human Resources Specialist',
  'Food Specialist',
  'Consultant',
  'Network Engineer',
  'Automotive Technician',
  'Executive Director',
  'System Administrator',
  'Line Cook',
  'Vice President Operations',
  'Senior Manager',
  'Event Specialist',
  'Inside Sales Representative',
  'Digital Marketing Manager',
  'Finance Manager',
  'Senior Program Manager',
  'Vice President Sales',
  'Hairstylist',
  'Data Entry Specialist',
  'Supervisor',
  'Mechanic',
  'Senior Scientist',
  'Engineering Manager',
  'Data Entry Clerk',
  'Sales Consultant',
  'Human Resources Coordinator',
  'Warehouse Specialist',
  'Sales Executive',
  'Shift Manager',
  'Accounting Manager',
  'Financial Director',
  'Managing Director',
  'Sales Development Representative',
  'Associate Attorney',
  'Crew Member',
  'Process Engineer',
  'Coordinator',
  'Restaurant Manager',
  'Quality Engineer',
  'Delivery Specialist',
  'Operational Specialist',
  'Product Marketing Manager',
  'Training Specialist',
  'Tutor',
  'Human Resources Assistant',
  'Team Lead',
  'Research Scientist',
  'Warehouse Associate',
  'Social Media Manager',
  'Territory Manager',
  'Bartender',
  'Restaurant Specialist',
  'Associate Director',
  'Marketing Assistant',
  'Attendant',
  'Frontend Engineer',
  'Area Manager',
  'Lead',
  'Director Of Information Technology',
  'Technical Program Manager',
  'Operations Associate',
  'Students',
  'Customer Service Associate',
  'Director of Product Management',
  'Marketing Intern',
  'Senior Business Analyst',
  'Copywriter',
  'Attorney',
  'Outside Sales',
  'Dotnet Developer',
  'Territory Sales Manager',
  'Senior Account Executive',
  'Chief Executive Officer',
  'Quality Assurance Engineer',
  'Teacher',
  'Designer',
  'Product Designer',
  'Shopper',
  'Assistant General Manager',
  'Diesel Mechanic',
  'Creative Director',
  'Software Engineering Manager',
  'Business Development Representative',
  'Research Analyst',
  'Electrician',
  'Vice President Marketing',
  'Assistant',
  'Clerk',
  'Host',
  'Operations Analyst',
  'Buyer',
  'Paralegal',
  'Maintenance Specialist',
  'District Manager',
  'Retail Store Manager',
  'Senior Analyst',
  'Retail Merchandiser',
  'Pharmacist',
  'Architect',
  'Production Supervisor',
  'Junior Software Engineer',
  'Personal Assistant',
  'Social Worker',
  'Laboratory Technician',
  'Representative',
  'Senior Vice President',
  'Product Owner',
  'Business System Analyst',
  'Scrum Master',
  'Case Manager',
  'Information Technology Specialist',
  'Web Developer',
  'Technical Writer',
  'Field Service Technician',
  'Program Coordinator',
  'Sales Engineer',
  'Enterprise Account Executive',
  'Investment Analyst',
  'Senior Data Analyst',
  'Recruiting Coordinator',
  'Marketing Analyst',
  'Machine Operator',
  'Aide',
  'Material Handler',
  'Physical Therapist Assistant',
  'Laborer',
  'Art Director',
  'Construction Project Manager',
  'Event Coordinator',
  'Digital Marketing Specialist',
  'Store Assistant',
  'Home Health Aide',
  'Research Assistant',
  'Talent Acquisition Specialist',
  'Brand Manager',
  'Assistant Vice President',
  'Physician Assistant',
  'Direct Support Professional',
  'Chief Operating Officer',
  'Service Agent',
  'Phlebotomist',
  'Design Engineer',
  'Plant Manager',
  'Assistant Restaurant Manager',
  'Administrator',
  'Office Assistant',
  'Business Intelligence Analyst',
  'Engineering Intern',
  'Accounts Payable Specialist',
  'Food Service Specialist',
  'Information Technology Manager',
  'Financial Advisor',
  'Assistant Operations Manager',
  'Customer Service Manager',
  'General',
  'Senior System Engineer',
  'Director Project Management',
  'Operations Supervisor',
  'Account Coordinator',
  'Senior Marketing Manager',
  'Social Media Coordinator',
  'Director Of Engineering',
  'Medical Technologist',
  'Machine Learning Engineer',
  'Program Director',
  'Lead Customer Service Representative',
  'Application Engineer',
  'Custodian',
  'Technical Recruiter',
  'Leasing Specialist',
  'Assistant Project Manager',
  'Route Driver',
  'Regional Director',
  'Sales Support Specialist',
  'Counter Sales',
  'Senior Associate',
  'Account Director',
  'Supply Chain Manager',
  'Brand Ambassador',
  'Quality Manager',
  'Health Specialist',
  'Branch Manager',
  'Vice President Finance',
  'Relationship Manager',
  'Technical Project Manager',
  'Special Education Teacher',
  'Chief Of Staff',
  'Area Sales Manager',
  'Salesperson',
  'Licensed Vocational Nurse',
  'Corporate Counsel',
  'Communications Specialist',
  'Civil Engineer',
  'Quality Assurance Manager',
  'Patient Care Technician',
  'Social Media Specialist',
  'Cleaner',
  'Production Manager',
  'Keyholder',
  'Welder',
  'Parttime Sales Associate',
  'Quality Assurance Specialist',
  'Sales Account Manager',
  'Management Trainee',
  'Sales Lead',
  'Supply Chain Analyst',
  'Chief Information Officer',
  'Senior Developer',
  'Teller',
  'National Account Manager',
  'Instructor',
  'Assembler',
  'Quality Assurance Analyst',
  'Senior Consultant',
  'Security Professional',
  'System Analyst',
  'Researcher',
  'Senior',
  'Writer',
  'Information Technology Project Manager',
  'Technologist',
  'Test Engineer',
  'Executive Administrative Assistant',
  'Counsel',
  'Software Engineer Intern',
  'Sales Training Specialist',
  'General Laborer',
  'Benefits Specialist',
  'Service Manager',
  'Food Service Worker',
  'Event Manager',
  'Security Engineer',
  'Massage Therapist',
  'Medical Director',
  'Legal Assistant',
  'Instructional Designer',
  'Support Specialist',
  'Sales Account Executive',
  'Barista',
  'Senior Operations Manager',
  'Producer',
  'Maintenance Mechanic',
  'Operations Coordinator',
  'Property Manager',
  'Maintenance Supervisor',
  'Special Agent',
  'Cyber Security Specialist',
  'Crew',
  'Unemployed',
  'Senior Java Developer',
  'Data Science Specialist',
  'Subject Matter Expert',
  'Accounts Receivable Specialist',
  'Adjunct Faculty',
  'Office Administrator',
  'Medical Specialist',
  'Principal Software Engineer',
  'Senior Account Manager',
  'Construction Manager',
  'Housekeeping Specialist',
  'Clinical Nurse',
  'Principal',
  'Member Services Specialist',
  'Hospitalist',
  'Superintendent',
  'Content Writer',
  'Regional Vice President',
  'Mechanical Design Engineer',
  'Insurance Sales Agent',
  'Full Stack Engineer',
  'Dental Assistant',
  'Regional Sales Director',
  'Stocker',
  'Bookkeeper',
  'Inside Sales Specialist',
  'Communications Manager',
  'Training Manager',
  'Educator',
  'Photographer',
  'Hostess',
  'Counselor',
  'User Experience Researcher',
  'Senior Data Engineer',
  'Director Of Communications',
  'Associate Manager',
  'Credit Analyst',
  'Product Analyst',
  'District Sales Manager',
  'Business Development Specialist',
  'Real Estate Analyst',
  'Respiratory Therapist',
  'Nursing Assistant',
  'School Psychologist',
  'Department Manager',
  'Associate Marketing Manager',
  'Business Intelligence Developer',
  'Technical Support Engineer',
  'Field Engineer',
  'Technical Support Specialist',
  'Legal Counsel',
  'Scheduler',
  'Psychiatrist',
  'Human Resources Intern',
  'Information Technology Support Specialist',
  'Community Manager',
  'Forklift Operator',
  'Software Development Engineer In Test',
  'Heating Air Conditioning Specialist',
  'Regional Manager',
  'Associate Product Manager',
  'Porter',
  'Retail Clerk',
  'Quantitative Analyst',
  'Assistant Controller',
  'Investment Associate',
  'Engagement Manager',
  'Senior Network Engineer',
  'Stylist',
  'Chief Marketing Officer',
  'Room Attendant',
  'Industrial Engineer',
  'Editor',
  'Assistant Professor',
  'Site Reliability Engineer',
  'Paid Intern',
  'Engineering Technician',
  'Client Manager',
  'Carpenter',
  'Database Administrator',
  'Business Development Associate',
  'English Instructor',
  'Investment Banking Analyst',
  'Key Account Manager',
  'Business Operations Manager',
  'Senior Designer',
  'Program Analyst',
  'Chief Technology Officer',
  'Salesforce Administrator',
  'Estimator',
  'Sales Coordinator',
  'Product Engineer',
  'Python Developer',
  'Administrative Coordinator',
  'Installer',
  'Retail Sales Specialist',
  'Social Media Intern',
  'Baker',
  'Associate Project Manager',
  'Shift Coordinator',
  'Field Technician',
  'Medical Science Liaison',
  'Contract Manager',
  'Field Service Engineer',
  'Embedded Software Engineer',
  'Business Manager',
  'Strategist',
  'Tax Manager',
  'Solutions Engineer',
  'Quality Assurance Automation Engineer',
  'Account Representative',
  'Digital Marketing Director',
  'Product Specialist',
  'Sales Assistant',
  'Facilities Manager',
  'Compliance Analyst',
  'Retail Sales Consultant',
  'Strategic Account Manager',
  'Assistant Director',
  'Production Assistant',
  'Senior Mechanical Engineer',
  'Accounting Clerk',
  'Construction Superintendent',
  'Program Assistant',
  'Application Developer',
  'Environment, Health and Safety Manager',
  'Finance Associate',
  'Surgical Technician',
  'Maintenance Manager',
  'Babysitter',
  'Clinical Research Associate',
  'Home Health Nurse',
  'Inspector',
  'Associate General Counsel',
  'General Counsel',
  'Technical Lead',
  'Tax Specialist',
  'Data Architect',
  'Investment Banking Associate',
  'Senior Recruiter',
  'Janitor',
  'Office Coordinator',
  'Production Specialist',
  'Speech Pathologist',
  'Vice President Human Resources',
  'Business Data Analyst',
  'Summer Internship',
  'Production Coordinator',
  'Logistics Coordinator',
  'Talent Acquisition Manager',
  'Information Technology Intern',
  'Investigator',
  'Interior Designer',
  'Content Strategist',
  'Automation Engineer',
  'User Interface Designer',
  'Warehouse Worker',
  'Territory Sales Representative',
  'Research Intern',
  'Strategy Analyst',
  'Lifeguard',
  'Information Technology Analyst',
  'Environmental Specialist',
  'Service Advisor',
  'Security Guard',
  'Vice President Of Engineering',
  'Insurance Specialist',
  'Visual Designer',
  'Foreman',
  'Senior Technical Program Manager',
  'SQL Developer',
  'Construction Specialist',
  'Assistant General Counsel',
  'Trial Attorney',
  'Family Physician',
  'Client Service Representative',
  'Compliance Specialist',
  'Head',
  'Dispatcher',
  'Warehouse Supervisor',
  'Diesel Technician',
  'Registered Nurse Operating Room',
  'Vice President Of Products',
  'Sales Management Trainee',
  'Sales Operations Manager',
  'Associate Scientist',
  'Analytics Manager',
  'Elementary School Teacher',
  'Sales Floor Associate',
  'Staff Nurse',
  'Program Specialist',
  'Field Sales Representative',
  'Vice President Of Business Development',
  'Teacher Assistant',
  'Strategy Manager',
  'Parttime Teacher',
  'Contract Specialist',
  'Executive Assistant To Chief Executive Officer',
  'Call Center Representative',
  'Laboratory Assistant',
  'Control Engineer',
  'Head Of Marketing',
  'Practice Manager',
  'Director Program Management',
  'Operations Lead',
  'Director Of Development',
  'Family Practice Physician',
  'Internal Medicine Physician',
  'Sales Agent',
  'Real Estate Associate',
  'Certified Medical Assistant',
  'Senior Electrical Engineer',
  'Senior Human Resources Business Partner',
  'Payroll Specialist',
  'Chemist',
  'Project Director',
  'Staff Registered Nurse',
  'Executive Chef',
  'Senior Dotnet Developer',
  'Marketing Communications Manager',
  'Partnerships Manager',
  'Sous Chef',
  'Equipment Operator',
  'Finance Intern',
  'Staff Software Engineer',
  'Android Developer',
  'Assistant Associate Professor',
  'Associate Analyst',
  'Client Partner',
  'Therapist',
  'Procurement Specialist',
  'Senior Administrative Assistant',
  'Planner',
  'Underwriter',
  'Travel Editor',
  'Mobile Engineer',
  'Retail Sales Representative',
  'Strategy Director',
  'Customer Director',
  'Trainer',
  'Nanny',
  'Design Director',
  'Plumber',
  'Customer Specialist',
  'Tester',
  'Global Director',
  'Video Editor',
  'Security Analyst',
  'Chief Operations Officer',
  'Solutions Consultant',
  'Adjunct Instructor',
  'Retail Associate',
  'Jewelry Consultant',
  'Lead Software Engineer',
  'Personal Banker',
  'Portfolio Manager',
  'Video Producer',
  'Frontend Web Developer',
  'Supply Chain Specialist',
  'Supply Chain Director',
  'Senior User Experience Designer',
  'Machinist',
  'Structural Engineer',
  'Concierge',
  'Senior Counsel',
  'Logistics Manager',
  'Financial Consultant',
  'Computer Numerical Control Machinist',
  'Hardware Engineer',
  'Quality Assurance Tester',
  'Veterinarian',
  'Implementation Specialist',
  'Leasing Consultant',
  'Service Coordinator',
  'Real Estate Specialist',
  'Senior Product Marketing Manager',
  'Administrative Specialist',
  'Client Services Manager',
  'Warehouse Manager',
  'Financial Planning and Analysis Manager',
  'Director Of Sales Marketing',
  'Production Associate',
  'Safety Manager',
  'Private Equity Associate',
  'Customer Associate',
  'Associate Producer',
  'Internship',
  'Logistics Analyst',
  'Student Intern',
  'Utilities Specialist',
  'Customer Services Specialist',
  'Senior Graphic Designer',
  'Technical Account Manager',
  'Product Director',
  'Logistics',
  'Business Development Executive',
  'Sales Operations Analyst'
];

export const COMPANIES = [
  'Jobs @ TheJobNetwork',
  'Amazon',
  'Uber',
  'C.R. England',
  'myCNAjobs',
  'Hogan Transportation Companies',
  'ASSURANCE',
  'Apple',
  'Dollar General',
  'Ivy Exec',
  'Heartland Express',
  'Amazon Web Services (AWS)',
  'Pizza Hut',
  'VelvetJobs',
  '"Work From Home"',
  'Microsoft',
  'PwC',
  'Robert Half',
  'WeWork',
  'CareInHomes',
  'Facebook',
  'CompHealth',
  'Google',
  'Midwest Continental Inc',
  'VolunteerMatch',
  'HVH Transportation',
  'Burger King Corporation',
  'Accenture',
  'J.B. Hunt Transport Services, Inc',
  'CVS Health',
  'Varsity Tutors',
  'U.S. Xpress, Inc.',
  'HospitalCareers.com',
  'ClearedJobs.Net',
  'Revature',
  'CRST International',
  'Verizon',
  'Allied Universal',
  'Creative Circle',
  'Circle K',
  'Chalk Mountain Services of Texas',
  'AppleOne Employment Services',
  'Dollar Tree',
  'Capital One',
  'The Gypsy Nurse',
  'Mid West Apply',
  'EatStreet',
  "Lowe's Companies, Inc.",
  'Navajo Express',
  'Family Dollar',
  'LinkedIn',
  'HCA Healthcare',
  'Stella.ai',
  'Soliant',
  'Comcast',
  'Leidos',
  'Platinum Supplemental Insurance, Inc.',
  'Walmart',
  'Centerline Drivers',
  'Wells Fargo',
  'Kelly Services',
  'Advance Auto Parts',
  'Modis',
  'Target',
  'Deloitte',
  'Kaiser Permanente',
  'Gartner',
  'KFC US',
  'Mesilla Valley Transportation',
  'Jobspring Partners',
  'PetSmart',
  'Apex Systems',
  'Airbnb',
  'Brookdale',
  'BCG Attorney Search',
  'Western Express',
  'Toptal',
  'Morgan Stanley',
  'H&R Block',
  'PlatinumHPL',
  'American Express',
  'Michael Page',
  'NBCUniversal Media, LLC',
  'Lyft',
  'Goldman Sachs',
  'Tutree, Inc.',
  'Kreilkamp Trucking Inc',
  'Confidential',
  'Lockheed Martin',
  'U.S. Department of Veterans Affairs',
  'CyberCoders',
  'Catchafire',
  'Northrop Grumman',
  'Taco Bell',
  'IBM',
  'Perficient',
  'Workbridge Associates',
  'JLL',
  'Parker and Lynch',
  'Boston Market',
  'Blackhawk Transport',
  'Supplemental Health Care',
  'JPMorgan Chase & Co.',
  'Sunbelt Staffing',
  'Ajilon',
  "O'Reilly Auto Parts",
  'Salesforce',
  'Bank of America',
  'Sherwin-Williams',
  'Compass Group USA',
  'Randstad General Staffing',
  'Speedway LLC',
  'Dream Home Improvement Inc',
  'Army National Guard',
  'Eaton',
  'Dollar Tree Stores',
  "Macy's",
  'Costco Wholesale',
  'Planet Fitness World Headquarters',
  'Conduent',
  'Panera Bread',
  'The Estée Lauder Companies Inc.',
  'Kroger',
  'Cisco',
  'McDonald´s',
  'Swift Transportation',
  'Brooksource',
  'Healthcare Services Group, Inc',
  'Workday',
  'Booz Allen Hamilton',
  'CDM Smith',
  'Cognizant',
  'Best Buy',
  'Circle K Stores Inc',
  'EY',
  'Siemens',
  'The Judge Group',
  'Walgreens',
  'Walgreen Company',
  'Netflix',
  'Cushman & Wakefield',
  'JCPenney',
  'Roehl Transport',
  'Department Of Navy',
  'Elastic',
  'Massage Envy',
  'XPO Logistics, Inc.',
  'Spotify',
  'R E Garrison',
  'Warner Bros. Entertainment',
  'Carrols Corporation',
  'CRST Logistics',
  'Gallano Trucking Inc',
  'On Time Talent Solutions',
  'JUUL Labs',
  'Petco',
  'Federal Bureau of Investigation (FBI)',
  'Hartt Transportation Systems',
  'The Execu|Search Group',
  'ServiceNow',
  'Cardinal Health',
  'Cintas',
  'Splunk',
  'Jacobs',
  'Groendyke Transport',
  'Management Recruiters International',
  'HDR',
  'Ralph Lauren',
  'Pride Transport',
  'Volt Workforce Solutions',
  'Twitter',
  '7-Eleven',
  'Simbeck Inc',
  'Citi',
  'Honeywell',
  'DoorDash',
  'U.S. Department of the Treasury',
  'CalArk Inc.',
  'UnitedHealth Group',
  'TravelCenters of America',
  "Chili's",
  'Whole Foods Market',
  'Oracle',
  'Bath & Body Works',
  'Desert de Oro Foods, Inc.',
  'LifePoint Health®',
  'Wipro Limited',
  'United States Army National Guard',
  'LanceSoft, Inc.',
  'McKinsey & Company',
  'Anheuser-Busch',
  'J&R Schugel Trucking, Inc.',
  'Slack',
  'ManTech',
  'Fourth Floor',
  'Dell',
  'Life Time Inc.',
  'Aramark',
  'Qualcomm',
  'Olive Garden',
  'Expedia Group',
  'TRC Companies, Inc.',
  'Samsung Electronics America',
  'Chase',
  'Wayfair',
  'UrbanSitter',
  'L3 Technologies',
  'Brinker International',
  'Nordstrom',
  'gpac',
  'Trinity Health (HQ Michigan)',
  'The Coca-Cola Company',
  'Intel Corporation',
  'System Transport',
  'Apex Life Sciences',
  'J.P. Morgan',
  'Infor',
  'Bowlero Corporation',
  'Stryker',
  'Boston Consulting Group (BCG)',
  'Foot Locker',
  'Mississippi Department of Transportation',
  'Omni Hotels & Resorts',
  'Poly Trucking',
  "McDonald's",
  'Hardees Restaurants',
  'Tailored Brands, Inc.',
  'Taproot Foundation',
  'Community Health System',
  'UHS',
  'Visa',
  'Snap Inc.',
  'APN Consulting Inc.',
  'Raytheon',
  'Green Key Resources',
  'Hays',
  'Infinity Consulting Solutions',
  'Red Lobster',
  'Staples',
  'Stanley Black & Decker, Inc.',
  'National Football League (NFL)',
  'Jackson Nurse Professionals',
  'Hulu',
  'Sonic Drivein',
  'jobleads.com - Careers for Senior-Level Professionals',
  'Genesis',
  'Collabera Inc.',
  "Victoria's Secret",
  'PVH Corp.',
  'The Home Depot',
  'Infosys',
  'BlackRock',
  'Intuitive',
  'Great Clips Inc.',
  'TikTok',
  'eFinancialCareers',
  'State of North Carolina',
  'BAE Systems',
  'GHR',
  'Virtual Vocations, Inc.',
  'U.S. Bank',
  'Columbia University in the City of New York',
  'Planet Pharma',
  'Informatica',
  'Venture Express Inc',
  'Nealy Pierce, LLC',
  'Pfizer',
  'Lucas Group',
  'Dropbox',
  'Takeda',
  'The New York Times',
  'Amazon Lab126',
  'Advantage Solutions, s. r. o.',
  'FranchiseHelp',
  'Stability Healthcare',
  'DISH Network',
  'Interstate Hotels & Resorts',
  'Guitar Center',
  'Advanced Therapy',
  'GameStop',
  'Prudential Financial',
  'Bridgestone Americas',
  'Asurion',
  'Buddy Moore Trucking Inc',
  'Johnson & Johnson',
  'Advantage Solutions: Sales, Marketing, Technology',
  'SAIC',
  'General Nutrition Centers, Inc',
  'Stripe',
  'DXC Technology',
  'Postmates Inc.',
  'Endeavor',
  'The Dart Network',
  'Aetna, a CVS Health Company',
  "DICK'S Sporting Goods",
  'FieldCore',
  'AmeriCorps',
  'Medical Staffing Options',
  'Verizon Media',
  'BD',
  'HBO',
  'Georgia-Pacific LLC',
  'City of Burlington',
  'Electronic Arts (EA)',
  'Navigant',
  'Paladin',
  'American Express Global Business Travel',
  'Allergan',
  'Advantage Solutions, Inc.',
  'Disney Streaming Services',
  'USAA',
  'Weatherby Healthcare',
  "Men's Wearhouse",
  'Walmart eCommerce',
  'Nike',
  'FRESENIUS MEDICAL CENTER',
  'Novartis',
  'Advantage Solutions group, LLC',
  'Humboldt University of Berlin',
  'ClubCorp',
  'Centene Corporation',
  'Cypress HCM',
  'Barnes & Noble, Inc.',
  'Eliassen Group',
  'PayPal',
  'Avanade',
  'Hyvee Grocery',
  'Johnson Controls',
  'Thermo Fisher Scientific',
  'Commodore',
  'Securitas Security Services USA, Inc.',
  'Bullhorn Jobscience',
  'SEPHORA',
  'Saint Augustine’s University',
  'Hackensack Meridian Health',
  'ALDI USA',
  'Wyndham Destinations',
  'Rent The Runway',
  'Penn State University',
  'BAE Systems, Inc.',
  'The Adecco Group',
  'Manpower',
  'AECOM',
  'Department of Agriculture',
  'SmartStyle',
  'Tractor Supply Company',
  'KORE1',
  'Malakye.com',
  'CPS, Inc.',
  'McKesson',
  'Symmetry Financial Group',
  'Ford Motor Company',
  'Darden',
  'Barneys New York',
  'Universal Music Group',
  'Roadrunner Transportation Systems',
  'Caleres, Inc.',
  'Genpact',
  'Luxottica',
  'Encompass Health',
  'Shell',
  'Bristol-Myers Squibb',
  'VMware',
  'Michael Kors',
  'Hibbett Sports',
  'RITE AID',
  'Epsilon',
  'Financial Staffing Group',
  'Innovationario',
  'Student Transportation of America',
  'ABB',
  'T-Mobile',
  'New York Life Insurance Company',
  'Medline Industries, Inc.',
  'Tableau Software',
  'Tesla',
  'Crawford Thomas Recruiting',
  'Nestlé',
  'Tradesmen International',
  'US Foods',
  'Work At Home Jobs',
  'Climate Express Inc',
  'Palo Alto Networks',
  'Quest Diagnostics',
  'CACI International Inc',
  'Philips',
  'University of Rochester',
  'The MENTOR Network',
  'Ogilvy',
  'Northwell Health',
  'Spectrum',
  'Nepris Inc.',
  'U.S. Army Network Enterprise Technology Command',
  'Crossover for Work',
  'Sprouts Farmers Market',
  'Red Hat',
  'Blue Cross and Blue Shield of Illinois, Montana, New Mexico, Oklahoma & Texas',
  'Stanford University',
  'Aquent',
  'University of California San Diego',
  'Rutgers University',
  'Chipotle Mexican Grill',
  "Love's Travel Stops",
  'Education First',
  'THRIVAS',
  'CareerStaff Unlimited',
  'Mayo Clinic',
  'The Kraft Heinz Company',
  'Travel Noire',
  'CoStar Group',
  'Mondelēz International',
  'Tata Consultancy Services',
  'Duane Reade',
  'Spot On Recruiting, Inc',
  'Baylor Scott & White Health',
  'Journeys',
  'NTG (Northern Technologies Group, Inc.)',
  'Royal Caribbean Cruises Ltd.',
  'Risinger',
  'Unilever',
  'Steak n Shake',
  'Cardinal Logistics Management',
  'Forever 21',
  "Bloomingdale's",
  'Wolters Kluwer',
  "Kohl's",
  'Waste Management',
  'HelloFresh',
  'thelabrat.com',
  'The Penn State Student Radio Alumni Interest Group',
  'Anthem, Inc.',
  'Peloton Interactive',
  'Merck',
  'Bloomberg LP',
  'Washington State University',
  'VCA Animal Hospitals',
  'Zillow Group',
  'Jefferies',
  'SAP',
  'Walmart Labs',
  'Twitch',
  'Disney Parks, Experiences and Products',
  'Regions Bank',
  'State of Georgia',
  'Amazon Marketplace',
  "Schwan's Company",
  'Instacart',
  'Sysco',
  'The Krystal Company',
  'Keurig Dr Pepper Inc.',
  'Career Group',
  'Enterprise Rent-A-Car',
  'DISYS',
  'LHC Group',
  'Baker Hughes, a GE company',
  'American Airlines',
  'adidas',
  'Job Juncture',
  'Randstad USA',
  'Aureus Medical Group',
  'General Dynamics Information Technology',
  'Adview',
  'CodeForce 360',
  'ettain group',
  'AT&T',
  'Collins Aerospace',
  "Denny's",
  'Inova Health System',
  'Specialized Transportation, Inc.',
  'St Anthony',
  'Silverline',
  'Jobot',
  'SYKES',
  'Stitch Fix',
  "Aaron's, Inc",
  'A+E Networks',
  'Duke University Health System',
  'Magic Leap',
  'IHS Markit',
  'NTT DATA Services',
  'Discover Financial Services',
  'UBS',
  'AIG',
  'Liberty Personnel Services, Inc.',
  'Guggenheim Partners',
  'Encompass Home Health & Hospice',
  'WunderLand Group',
  'Vaco',
  'New York-Presbyterian Hospital',
  'University of Michigan',
  'Home Instead Senior Care',
  "Arby's",
  'Illumina',
  'Whataburger',
  'Amgen',
  'Sonder Inc.',
  'Favor Delivery',
  'The Salvation Army',
  'Ascensus',
  'Mastercard',
  'Starbucks',
  'Flex',
  'KLA',
  'Vector Marketing',
  'RNnetwork',
  'Sears Holdings Corporation',
  'Mercer Transportation',
  'Five Star Senior Living',
  'Lincoln Financial Group',
  'The Walt Disney Company',
  'Addison Group',
  'Regeneron Pharmaceuticals, Inc.',
  'CBRE',
  'Pinterest',
  'Select Medical',
  'U-Haul International, Inc',
  'Smith & Nephew',
  'Tiffany & Co.',
  'Rose International',
  'Aspen Dental',
  'Greyhound Lines, Inc.',
  'The Metropolitan Museum of Art',
  'Charles Schwab',
  'Century Group',
  'Sprint',
  'Synopsys Inc',
  'Premium Retail Services',
  'Russell Tobin',
  'Toll Brothers',
  'Creative Financial Staffing (CFS)',
  'GHR Travel Nursing',
  'Nielsen',
  'Hub Group Trucking',
  'JetBlue Airways',
  'Vail Resorts',
  'ProCare Therapy',
  'Experis',
  'RealPage, Inc.',
  'Coach',
  'Pursuit Sales Solutions',
  'Edward Jones',
  'Corizon Health',
  'Roku Inc.',
  'Oculus VR',
  'Follett',
  'Safeway',
  'IHOP',
  'American Red Cross',
  'Pegasystems',
  'Government of Virginia',
  'Accenture Federal Services',
  'MUFG',
  'Partners HealthCare',
  'Mercy',
  '80Twenty',
  'Providence Health & Services',
  "Culver's Restaurants",
  'Shoe Carnival, Inc.',
  'Hyatt Hotels Corporation',
  'WorkInSports.com',
  'Selby Jennings',
  'Calvin Klein',
  'AMETEK',
  'Cleveland Clinic',
  'Golfsmith International',
  'iHeartMedia',
  'Hitachi Vantara',
  'Chick-fil-A Corporate',
  'Gallagher',
  'Neiman Marcus Group',
  'Staffmark',
  'SSM Health',
  'Superprof',
  'Kforce Inc',
  'Grubhub',
  'St. Joseph Health',
  'Western Governors University',
  'RGP',
  'Mid Seven Transportation Company',
  'Ross Stores, Inc.',
  'BP',
  'Siemens Healthineers',
  'ADP',
  'Valvoline Instant Oil Change',
  'iHire',
  'SoFi',
  'UST Global',
  'Avacend Inc',
  'Goodwin Recruiting',
  'Spectraforce Technologies',
  'Procter & Gamble',
  'US Tech Solutions',
  'DSW Designer Shoe Warehouse',
  'PPD',
  'Harbor Freight Tools',
  'Emerson Automation Solutions',
  'McDermott International Inc.',
  'CSL',
  'Allstate',
  'CHRISTUS Health',
  'Tenet Healthcare',
  'Oldcastle',
  'Rockwell Automation',
  'Nutanix',
  'Solomon Page',
  'Nigel Frank International',
  'Levy Restaurants',
  'International Rescue Committee',
  'Sony Pictures Entertainment',
  'American Central Transport',
  "Kirkland's",
  'PlayStation',
  'Envision Physician Services',
  'Sunbelt Rentals, Inc.',
  'General Dynamics',
  'Harris Teeter',
  'Flexport',
  'Avantor',
  'AMERICAN EAGLE OUTFITTERS INC.',
  'Oakley Trucking',
  'HubSpot',
  'Motion Recruitment Partners',
  'Kellogg Company',
  'Verizon Communication',
  'PNC',
  'Samsara, Inc',
  'Gilead Sciences',
  'Thomson Reuters',
  'Agoda',
  'Yext',
  'Teva Pharmaceuticals',
  'Kindred Healthcare',
  'eBay',
  'Tanium',
  'Capgemini',
  'Henry Ford Health System',
  'AmerisourceBergen',
  'Boeing',
  'Burlington Stores, Inc.',
  'Memorial Hermann Health System',
  'CHANEL',
  'Genentech',
  'Fitbit',
  'BNY Mellon',
  'Square',
  'Coty',
  'BB&T',
  'SmileDirectClub',
  'La Quinta Inns & Suites',
  'Beckman Coulter',
  'Cox Automotive Inc.',
  'Fort Transfer Company',
  'Zoom Video Communications',
  'Entercom',
  'Blair Logistics',
  'ECS',
  'Meredith Corporation',
  'Corning Incorporated',
  'The Connor Group',
  "Bojangles'​ Restaurants, Inc.",
  'AutoNation',
  'St Marys Good Samaritan Inc',
  'Boston Scientific',
  'Atlantic Group',
  'UC Irvine',
  'Shopko'
];

export const DEGREES = [
  'Associate of Arts',
  'Associate of Applied Arts',
  'Associate of Engineering or Associate in Electronics Engineering Technology',
  'Associate of Science',
  'Associate of General Studies',
  'Associate of Science in Nursing',
  'Associate of Forestry',
  'Associate of Technology',
  'Associate of Applied Business',
  'Associate of Applied Science or Associate of Arts and Sciences',
  'Associate of Arts in Teaching',
  'Associate of Baccalaureate Studies',
  'Associate of Business Administration',
  'Associate of Engineering Science',
  'Associate Degree in Nursing',
  'Associate in Engineering Technology',
  'Associate of Fine Arts',
  'Associate of Pre-Engineering',
  'Associate of Industrial Technology',
  'Associate of Occupational Studies',
  'Associate in Physical Therapy',
  'Associate of Political Science or Associate of Public Service',
  'Bachelor of Architecture',
  'Bachelor of Arts',
  'Bachelor of Applied Arts',
  'Bachelor of Applied Arts and Science',
  'Bachelor of Applied Science in Information Technology',
  'Bachelor of Design',
  'Bachelor of Engineering',
  'Bachelor of Science in Business',
  'Bachelor of Engineering Technology',
  'Bachelor of Technology',
  'International Business Economics',
  'Bachelor of Business Administration',
  'Bachelor of Management Studies',
  'Bachelor of Administrative Studies',
  'Bachelor of International Business Economics',
  'Bachelor of Commerce',
  'Bachelor of Fine Arts',
  'Bachelor of Business',
  'Bachelor of Management and Organizational Studies',
  'Bachelor of Business Science',
  'Bachelor of Accountancy',
  'Bachelor of Comptrolling',
  'Bachelor of Economics',
  'Bachelor of Arts in Organizational Management',
  'Bachelor of Computer Science',
  'Bachelor of Computing',
  'Bachelor of Science in Information Technology',
  'Bachelor of Computer Applications',
  'Bachelor of Business Information Systems',
  'Intercalated Bachelor of Science',
  'Bachelor of Medical Science',
  'Bachelor of Medical Biology',
  'Bachelor of Science in Public Health',
  'Bachelor of Science in Nursing',
  'Bachelor of Health Science',
  'Bachelor of Kinesiology',
  'Bachelor of Arts for Teaching',
  'Bachelor of Aviation',
  'Bachelor of Divinity',
  'Bachelor of Theology',
  'Bachelor of Religious Education',
  'Bachelor of Religious Studies',
  'Bachelor of Film and Television',
  'Bachelor of Integrated studies',
  'Bachelor of Journalism',
  'Bachelor of Landscape Architecture',
  'Bachelor of Liberal Arts',
  'Bachelor of General Studies',
  'Bachelor of Science in Human Biology',
  'Bachelor of Applied Studies',
  'Bachelor of Liberal Studies',
  'Bachelor of Professional Studies',
  'Bachelor of Library Science',
  'Bachelor of Library and Information Science',
  'Bachelor of Music',
  'Bachelor of Art in Music',
  'Bachelor of Music Education',
  'Bachelor of Philosophy',
  'Bachelor of Arts in Psychology',
  'Bachelor of Mortuary Science',
  'Bachelor of Science in Psychology',
  'Bachelor of Science in Education',
  'Bachelor of Science and/with education degree',
  'Bachelor of Science in Forestry',
  'Bachelor of Applied Science',
  'Bachelor of Science in Law',
  'Bachelor of Social Science',
  'Bachelor of Arts in Social Work',
  'Bachelor of Talmudic Law',
  'Bachelor of Technology',
  'Bachelor of Tourism Studies',
  'Bachelor of Mathematics',
  'Bachelor of Mathematical Sciences',
  'Bachelor of Public Affairs and Policy Management',
  'Bachelor of Urban and Regional Planning',
  'Master of Accountancy',
  'Master of Advanced Study',
  'Master of Economics',
  'Master of Architecture',
  'Master of Applied Science',
  'Master of Arts',
  'Master of Arts in Teaching',
  'Master of Arts in Liberal Studies',
  'Master of Business',
  'Master of Business Administration',
  'Master of Business Informatics',
  'Master of City Planning',
  'Master of Chemistry',
  'Master of Commerce',
  'Master of Computational Finance',
  'Master of Computer Applications',
  'Master in Creative Technologies',
  'Master of Criminal Justice',
  'Master of Design',
  'Master of Divinity',
  'Master of Economics',
  'Master of Education',
  'Master of Enterprise',
  'Master of Engineering',
  'Master of Engineering Management',
  'Master of European Law',
  'Master of Finance',
  'Master of Financial Mathematics',
  'Master of Financial Engineering',
  'Master of Financial Economics',
  'Master of Fine Arts',
  'Master of Health Administration',
  'Master of Health Science',
  'Master of Humanities',
  'Master of Industrial and Labor Relations',
  'Master of International Affairs',
  'Master of International Business',
  'Master of International Studies',
  'Masters in International Economics',
  'Master of Information System Management',
  'Master of IT',
  'Master of Jurisprudence',
  'Master of Laws',
  'Master of Studies in Law',
  'Master of Landscape Architecture',
  'Master of Letters',
  'Master of Liberal Arts',
  'Master of Library and Information Science',
  'Master of Management',
  'Master of Mathematics',
  'Master of Mathematical Finance',
  'Master of Medical Science',
  'Master of Music',
  'Master of Occupational Therapy',
  'Master of Pharmacy',
  'Master of Philosophy',
  'Master of Physics',
  'Master of Physician Assistant Studies',
  'Master of Political Science',
  'Master of Professional Studies',
  'Master of Public Administration',
  'Master of Public Affairs',
  'Master of Public Health',
  'Master of Public Management',
  'Master of Public Policy',
  'Master of Quantitative Finance',
  'Master of Rabbinic Studies',
  'Master of Real Estate Development',
  'Master of Religious Education',
  'Master of Research - MSc(R)',
  'Master of Sacred Theology',
  'Master of Sacred Music',
  'Master of Science',
  'Master of Science in Education',
  'Master of Science in Engineering',
  'Master of Science in Finance',
  'Master of Science in Human Resource Development',
  'Master of Science in Information Systems Management',
  'Master of Science in Information Systems',
  'Master of Science in Information Technology',
  'Master of Science in Nursing',
  'Master of Science in Project Management',
  'Master of Science in Management',
  'Master of Science in Leadership',
  'Master of Science in Supply Chain Management',
  'Master of Science in Taxation',
  'Master of Science in Teaching',
  'Master of Social Work',
  'Master of Social Science',
  'Master of Surgery',
  'Master of Studies',
  'Master of Theology',
  'Master of Theological Studies',
  'Master of Urban Planning',
  'Master of Veterinary Science',
  'Doctor of Arts',
  'Doctor of Business Administration',
  'Doctor of Canon Law',
  'Doctor of Church Music',
  'Doctor of Design',
  'Doctor of Education',
  'Doctor of Engineering',
  'Doctor of Fine Arts',
  'Doctor of Hebrew Letters',
  'Doctor of Industrial Technology',
  'Doctor of Juridical Science',
  'Doctor of Music',
  'Doctor of Musical Arts',
  'Doctor of Music Education',
  'Doctor of Modern Languages',
  'Doctor of Nursing Science',
  'Doctor of Philosophy',
  'Doctor of Physical Education',
  'Doctor of Public Administration',
  'Doctor of Sacred Theology',
  'Doctor of Science',
  'Doctor of Social Work',
  'Doctor of Theology',
  'Doctor of Audiology',
  'Doctor of Behavioral Health',
  'Doctor of Business Administration',
  'Doctor of Chiropractic Medicine',
  'Doctor of Computer Science',
  'Doctor of Professional Studies/Counseling',
  'Doctor of Dental Surgery',
  'Doctor of Medical Dentistry',
  'Doctor of Environmental Science',
  'Doctor of Health Administration',
  'Doctor of Health Science',
  'Doctor of Jurisprudence',
  'Doctor of Management',
  'Doctor of Medicine',
  'Doctor of Ministry',
  'Doctor of Practical Theology',
  'Doctor of Biblical Studies',
  'Doctor of Counseling',
  'Doctor of Educational Ministry',
  'Doctor of Musical Arts',
  'Doctor of Naturopathic Medicine',
  'Doctor of Nursing Practice',
  'Doctor of Nurse Anesthesia Practice',
  'Doctor of Occupational Therapy',
  'Doctor of Optometry',
  'Doctor of Osteopathic Medicine',
  'Doctor of Pharmacy',
  'Doctor of Physical Therapy',
  'Doctor of Podiatric Medicine',
  'Doctor of Psychology',
  'Doctor of Public Administration',
  'Doctor of Public Health',
  'Doctor of Social Work',
  'Doctor of Speech-Language Pathology',
  'Doctor of Veterinary Medicine'
];
