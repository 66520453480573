import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneSlash } from '@fortawesome/free-solid-svg-icons';

export const formatCall = n => ({
  description: (
    <p>
      Missed call from {n.lead.first_name} {n.lead.last_name}
    </p>
  ),
  avatar: (
    <IconWrapper>
      <FontAwesomeIcon className="call-icon" icon={faPhoneSlash} type="email" />
    </IconWrapper>
  ),
  link: '/settings/earnings'
});

const IconWrapper = ({ children }) => {
  return <div className="icon-wrapper">{children}</div>;
};
