import React, { Component } from 'react';
import { Table, message } from '_seriph';
import { getColumns } from './Columns';
import WaveSurfer from 'wavesurfer.js';
import './CallTable.scss';

class CallTable extends Component {
  state = {
    playing_recording: null,
  }
  playRecording = (call_id, url) => {
    if (this.state.playing_recording === call_id) {
      message.info(`Playback stopped.`);
      this.setState({ playing_recording: null });
      this.wavesurfer.stop();
    } else {
      this.setState({ playing_recording: call_id });
      const wavesurfer = WaveSurfer.create({
        container: '#waveform'
      });
      this.wavesurfer = wavesurfer;
      message.info(`Playing recording...`);
      wavesurfer.load(url);
      wavesurfer.on('ready', function () {
        wavesurfer.play();
      });
    }
  };
  render() {
    const { calls } = this.props;

    const columns = getColumns(
      this.playRecording,
      this.state.playing_recording
    );
    return (
      <div id="report-call-table">
        <div id="waveform" />
        <Table
          lined
          rounded
          locale={{ emptyText: 'No calls found' }}
          dataSource={calls.map((d, i) => {
            d.key = 'rtt-'+i;
            return d;
          })}
          scroll={{ x: 'max-content' }}
          pagination={{ pageSize: 10 }}
          columns={columns}
        />
      </div>
    );
  }
}

export default CallTable;
