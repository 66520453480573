import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, Input, Select, SelectMultiple, Button, message } from '_seriph';
import { saveAdminUser, createAdminUser, removeAdminUser } from '_graphql/mutations/admin';
import { getErrors } from '_assets/js/helpers';
import { roleMap } from '_constants/admin';
import './ManageAdminUser.scss';

class ManageAdminUser extends Component {
  state = {
    visible: true,
    saving: false,
    form:
      this.props.form === 'new'
        ? {
            user_id: 'new',
            display_name: '',
            role: '',
            company_ids: [],
            email: ''
          }
        : this.props.form
  };
  handleSave = async () => {
    this.setState({ saving: true });
    this.props.client
      .mutate({
        variables: { ...this.state.form },
        mutation: saveAdminUser
      })
      .then(result => {
        if (result?.data?.saveAdminUser) {
          message.success('Admin user saved');
          if (this.props.reload) this.props.reload();
          this.hideModal();
        }
        this.setState({ saving: false });
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Error saving admin user, try again.');
      });
  };
  handleCreate = async () => {
    this.setState({ saving: true });
    this.props.client
      .mutate({
        variables: { ...this.state.form },
        mutation: createAdminUser
      })
      .then(result => {
        if (result?.data?.createAdminUser) {
          message.success('Admin user created');
          if (this.props.reload) this.props.reload();
          this.hideModal();
        }
        this.setState({ saving: false });
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Error creating admin user, try again.');
      });
  };
  handleRemove = async () => {
    this.setState({ saving: true });
    this.props.client
      .mutate({
        variables: { user_id: this.state.form.user_id },
        mutation: removeAdminUser
      })
      .then(result => {
        if (result?.data?.removeAdminUser) {
          message.success('Admin user removed');
          if (this.props.reload) this.props.reload();
          this.hideModal();
        }
        this.setState({ saving: false });
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Error removing admin user, try again.');
      });
  };
  handleRemoveConfirm = () => {
    Modal.confirm({
      title: 'Are you sure you want to remove this admin user?',
      content:
        'DANGER: This cannot be undone, you will have to recreate the account if it is deleted.',
      onOk: this.handleRemove
    });
  };
  updateAdmin = (field, value) => {
    const form = { ...this.state.form };
    form[field] = value;
    this.setState({ form });
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { form } = this.state;
    const { user } = this.props;

    const companyOptions = this.props.allCompanies.map(c => ({
      id: c.id,
      display_name: c.name,
      picture: c.logo
    }));

    const myRole = user?.admin?.role;

    return (
      <Modal
        wrapClassName="manage-admin-user-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={false}
        width={600}
      >
        <Modal.Close onClick={this.hideModal} />
        <Modal.Header>
          {form.user_id === 'new' ? 'Create Admin User' : 'Edit Admin User'}
        </Modal.Header>
        <div className="formed">
          {form.user_id === 'new' ? (
            <Input
              value={form.email}
              onChange={e => this.updateAdmin('email', e.target.value)}
              label="Email Address"
            />
          ) : null}
          <Input
            value={form.display_name}
            label="Display Name"
            onChange={e => this.updateAdmin('display_name', e.target.value)}
          />
          <Select
            value={form.role}
            onChange={val => this.updateAdmin('role', val)}
            label="Admin Role"
          >
            { Object.keys(roleMap).filter(r => {
              if (r === 'owner' || r === 'super') {
                return myRole === 'owner' ? true : false;
              } else {
                return true;
              }
            }).map(r => (
              <Select.Option value={r} key={r}>{roleMap[r]}</Select.Option>
            ))}
          </Select>
          {form.role && form.role !== 'super' ? (
            <div>
              <div className="formed-label">Companies to Manage</div>
              <SelectMultiple
                placeholder="Search companies..."
                notFoundContent="Type to search companies..."
                autocomplete={companyOptions}
                value={form.company_ids || []}
                onChange={val => this.updateAdmin('company_ids', val)}
                type="companies"
              />
            </div>
          ) : null}
        </div>
        <Modal.Actions>
          {form.user_id === 'new' ? (
            <Button type="primary" onClick={this.handleCreate}>
              Create
            </Button>
          ) : (
            <div>
              { myRole === 'owner' && (
                <Button type="danger" onClick={this.handleRemoveConfirm}>
                  Remove User
                </Button>
              )}
              <Button type="primary" onClick={this.handleSave}>
                Save
              </Button>
            </div>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withRouter(ManageAdminUser);
