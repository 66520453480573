import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { Button, Checkbox } from '_seriph';
import { withdrawCampaignInvite, resendCampaignInvite } from 'src/_graphql/mutations/company';
import './WithdrawModal.scss';

class WithdrawModal extends Component {
  state = {
    visible: true,
    selectedCampaigns: {},
    loading: false
  };
  hideModal = () => this.setState({ visible: false });

  getSelectedIds = () => {
    let campIds = [];
    for (const campId in this.state.selectedCampaigns) {
      if (this.state.selectedCampaigns[campId] === true) {
        campIds.push(campId);
      }
    }
    return campIds;
  };
  withdrawInvite = async () => {
    const campIds = this.getSelectedIds();
    let errorMessage = `Could not ${this.props.insteadResend ? 'resend' : 'withdraw'} invite`;
    this.setState({ loading: true });
    try {
      const result = await this.props.client.mutate({
        variables: {
          rep_id: this.props.rep.id,
          campaign_ids: campIds
        },
        mutation: this.props.insteadResend ? resendCampaignInvite : withdrawCampaignInvite
      });
      if (this.props.insteadResend && result?.data?.resendCampaignInvite === true) {
        message.success('Invitation resent');
        this.hideModal();
      } else if (result?.data?.withdrawCampaignInvite === true) {
        this.props.reload();
        message.success('Invitation(s) withdrawn');
        setTimeout(this.hideModal, 100);
      } else {
        throw new Error(errorMessage);
      }
    } catch (e) {
      console.log(e);
      message.error(errorMessage);
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <Modal
        wrapClassName="withdraw-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        style={{ top: 60 }}
        width={600}
        closable={false}
        maskClosable={true}
      >
        <div className="wm-header">
          <h3>{this.props.insteadResend ? 'Resend' : 'Withdraw'} Invitations</h3>
          <p>
            {this.props.insteadResend
              ? `
            This will send a new email to the rep, in addition to giving them a new notification.`
              : `
            This will hide the selected campaign from the rep and not allow them to apply to it.
            They must be re-invited to join.
            `}
          </p>
        </div>

        <div className="wm-main">
          <div className="wm-main-header">
            <div>Select campaign(s) to {this.props.insteadResend ? 'resend' : 'withdraw'} </div>
            <div>{this.getSelectedIds()?.length || 0} selected</div>
          </div>

          <div className="wm-main-table">
            {this.props.campaigns?.map((campaign, i) => (
              <div className="wm-row" key={i}>
                <div className="wm-row-left">
                  <Checkbox
                    noBg={true}
                    onChange={e => {
                      let selectedCampaigns = this.state.selectedCampaigns;
                      selectedCampaigns[campaign.id] = e.target.checked;
                      this.setState({ selectedCampaigns });
                    }}
                  />
                </div>
                <div className="wm-row-right">
                  <h4>{campaign.name}</h4>
                  <p>
                    Lead Generation <span> • Active {campaign?.status_updated_ago}</span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="wm-actions">
          <Button type="default" onClick={this.hideModal}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={this.withdrawInvite}
            loading={this.state.loading}
            disabled={!this.getSelectedIds()?.length}
          >
            {this.props.insteadResend ? 'Resend' : 'Withdraw'}
          </Button>
        </div>
      </Modal>
    );
  }
}

export default WithdrawModal;
