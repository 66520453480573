import { isEmail } from '_assets/js/helpers';

export const validate = (lead, required, campaignObjective) => {
  let incorrect = [],
    req = required || [];

  req.forEach(r => {
    if (campaignObjective === 'leadgen' && !r.required) {
      return;
    }
    /* Handle Strings */
    if (r.field === 'email' && !isEmail(lead[r.field])) {
      incorrect.push(r.field);
      return;
    }
    if (r.field === 'email' && !lead['email_valid']) {
      incorrect.push(r.field);
      return;
    }
    if (r.field === 'phone' && !lead['phone_valid']) {
      incorrect.push(r.field);
      return;
    }
    if (r.field === 'mobile' && !lead['mobile_valid']) {
      incorrect.push(r.field);
      return;
    }
    if (r.type === null && !validateString(lead[r.field])) {
      incorrect.push(r.field.split('_').join(' '));
      return;
    }

    /* Handle multiple */
    if (r.type === 'Multiple' && !r.values.includes(lead[r.field]) && r.values.length > 0) {
      if (r.field === 'primary_language' && r.values.includes('All Languages')) return;
      incorrect.push(r.field.split('_').join(' '));
    }

    /* Hande locations */
    if (r.type === 'Location') {
      if (!validateLocation(lead)) {
        incorrect.push('Location (at least two fields)');
        return;
      }
    }
  });
  return incorrect;
};

export const softValidate = (lead, required) => {
  let incorrect = [];
  (required || []).forEach(r => {
    if (['email', 'phone', 'mobile'].includes(r.field)) return;
    if (lead[r.field]) {
      /* Handle multiple */
      if (r.type === 'Multiple' && !r.values.includes(lead[r.field]) && r.values.length > 0) {
        if (r.field === 'primary_language' && r.values.includes('All Languages')) return;
        incorrect.push(r.field);
      }
      /* Hande locations */
      if (r.type === 'Location') {
        if (!validateLocation(lead)) {
          incorrect.push(r.field);
          return;
        }
      }
    }
  });
  return incorrect;
};

/* HELPER FUNCTIONS */
function validateLocation(lead) {
  let exists = 0;
  if (validateString(lead.street)) exists++;
  if (validateString(lead.city)) exists++;
  if (validateString(lead.state)) exists++;
  if (validateString(lead.postal_code)) exists++;
  if (validateString(lead.country)) exists++;
  if (exists < 2) return false;
  return true;
}

function validateString(val) {
  return val && val !== undefined && val !== null && val !== '' ? true : false;
}
