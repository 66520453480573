import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import logo from '_assets/img/logo.svg';
import { forgotPassword } from '_graphql/mutations/auth';
import { getErrors } from '_assets/js/helpers';
import { Input, Button, Header, message } from '_seriph';
import './Forgot.scss';

class Forgot extends Component {
  state = {
    email: '',
    loading: false
  };
  sendCode = () => {
    this.setState({ loading: true });
    let lowerEmail = (`${this.state.email}`).toLowerCase();
    this.props.client
      .mutate({ variables: { email: lowerEmail }, mutation: forgotPassword })
      .then(result => {
        if (result && result.data && result.data.forgotPassword) {
          message.success(
            'Check your email (' + lowerEmail + ') for a link to reset your password'
          );
          this.props.history.push('/');
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not reset your password, try again');
        this.setState({ loading: false });
      });
  };
  render() {
    const { email, loading } = this.state;

    return (
      <div id="sellx-forgot" className="signin">
        <div className="home-logo">
          <img src={logo} alt={logo} />
        </div>
        <div className="home">
          <div className="home-left"></div>
          <div className="home-right">
            <div className="home-form">
              <Header type="display" size="4" className="headline">
                Reset your password
              </Header>
              <Input
                label="Email address"
                size="large"
                name="email"
                autoComplete="email"
                value={email}
                onChange={e => this.setState({ email: e.target.value })}
                placeholder="Email address"
              />
              <div className="home-actions">
                <Button type="default" size="large" loading={loading} onClick={this.sendCode}>
                  Reset Password
                </Button>
              </div>
              <Link to="/">
                <p className="descript">&lt;&nbsp;&nbsp;&nbsp;Back to sign in</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Forgot);
