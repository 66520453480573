import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { connect } from 'react-redux';
import history from '../history';
import Home from './Home/Home';
import Agent from './Agent/Agent';
import FrozenAgent from './FrozenAgent/FrozenAgent';
import Company from './Company/Company';
import VerifyPhone from './Home/VerifyPhone/VerifyPhone';
import Mobile from './Home/Mobile/Mobile';
import Construction from './Home/Construction/Construction';
import Admin from './Admin/Admin';
import { setApolloClient } from 'Store/apollo/apollo_actions';
import { isMobile } from 'react-device-detect';
import './App.scss';
import '_styleguide/_styleguide.scss';

class App extends Component {
  UNSAFE_componentWillMount = () => {
    this.props.setApolloClient(this.props.client);
  };
  render() {
    const roleType = this.props.user ? this.props.user.role_type : null;
    const construction = process.env.REACT_APP_PRODUCT_STATE === 'construction';
    const home = <Home client={this.props.client} />;
    const agent = <Agent client={this.props.client} />;
    const company = <Company client={this.props.client} />;
    const verify = <VerifyPhone client={this.props.client} />;
    const frozenAgent = <FrozenAgent client={this.props.client} />;
    const mobile = <Mobile />;
    const admin = <Admin client={this.props.client} role={roleType} user={this.props.user} />;

    let routes = home;
    if (this.props.user?.frozen === true) {
      routes = frozenAgent;
    } else if (construction) {
      return <Construction />;
    } else if (this.props.user && roleType === 'agent' && this.props.user.verify) {
      routes = verify;
    } else if (this.props.user && isMobile) {
      routes = mobile;
    } else if (this.props.user && roleType === 'agent' && !this.props.user.verify) {
      routes = agent;
    } else if (this.props.user && roleType === 'company') {
      routes = company;
    } else if (this.props.user && roleType === 'admin') {
      routes = admin;
    }
    return <Router history={history}>{routes}</Router>;
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default connect(mapStateToProps, { setApolloClient })(App);
