import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Infotip from '_shared/Infotip/Infotip';
import { Timezones, Hours, DayMap } from '_constants/timezones';
import { Card, Button, Checkbox, Select } from '_seriph';

import './Schedule.scss';

class Schedule extends Component {
  componentDidMount = () => {
    if (this.props.save) this.props.save();
  };
  hasDay = day => {
    return this.props.campaign.schedule_days.find(s => s.day === day) ? true : false;
  };
  toggleDay = day => {
    let schedule_days = [...this.props.campaign.schedule_days];
    if (this.hasDay(day)) {
      schedule_days = schedule_days.filter(d => d.day !== day);
    } else {
      schedule_days.push({ day, start: 8, end: 18 });
    }
    this.props.update('schedule_days', schedule_days);
  };
  changeTime = (day, type, val) => {
    let schedule_days = [...this.props.campaign.schedule_days];
    schedule_days = schedule_days.map(s => {
      if (s.day === day) s[type] = val;
      return s;
    });
    this.props.update('schedule_days', schedule_days);
  };
  render() {
    const { campaign, update, actions, fromReview } = this.props;

    const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
    return (
      <Card id="wizard-schedule" className="wz-card">
        <div className="wz-container sx-form">
          {/* HEADER */}
          <div className="wz-card-header">
            <h3>Set a work schedule</h3>
            <h5>Setting a schedule keeps reps engaged at the right times.</h5>
          </div>

          {/* CONTENT */}
          <div className="check-wrapper">
            <Checkbox
              className="sxd-checkbox"
              checked={campaign.schedule_required}
              onChange={e => update('schedule_required', e.target.checked)}
            >
              Strictly enforce when sales reps can reach out to leads?
              <Infotip title="This will prevent reps from reaching out to leads outside of your set work window.  This is based off of the lead's timezone, if available." />
            </Checkbox>
          </div>

          {campaign.schedule_required ? (
            <div className="schedule">
              <div
                className={
                  fromReview && campaign.schedule_days.length <= 0 ? 'days invalid' : 'days'
                }
              >
                {days.map(d => (
                  <Button
                    key={'day-' + d}
                    className={`btnx btnx-large ${
                      this.hasDay(d) ? 'btnx-primary' : 'btnx-neutral'
                    }`}
                    onClick={() => this.toggleDay(d)}
                  >
                    {d}
                  </Button>
                ))}
              </div>
              <div className="field timezone">
                <Select
                  className={fromReview && !campaign.schedule_timezone ? 'invalid' : ''}
                  placeholder="Select a Timezone"
                  dropdownClassName="sx-dropdown"
                  value={campaign.schedule_timezone || undefined}
                  onChange={val => update('schedule_timezone', val)}
                >
                  {Timezones.map((t, i) => (
                    <Select.Option key={`tz-${i}`} value={t.value}>
                      {t.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="day-table">
                {days.map((d, i) => {
                  const day = campaign.schedule_days.find(s => s.day === d);
                  return (
                    <div className={`dt-row ${!day ? 'disabled' : ''}`} key={'dt-' + i}>
                      <div className="dt-name">{DayMap[d]}</div>
                      <Select
                        className={
                          fromReview && day && (!day.start || day.start >= day.end) ? 'invalid' : ''
                        }
                        disabled={!day}
                        dropdownClassName="sx-dropdown"
                        value={day ? day.start : 8}
                        onChange={val => this.changeTime(d, 'start', val)}
                      >
                        {Hours.map((h, i) => (
                          <Select.Option key={`sh-${i}`} value={h.value}>
                            {h.label}
                          </Select.Option>
                        ))}
                      </Select>
                      <Select
                        className={
                          fromReview && day && (!day.end || day.start >= day.end) ? 'invalid' : ''
                        }
                        disabled={!day}
                        dropdownClassName="sx-dropdown"
                        value={day ? day.end : 18}
                        onChange={val => this.changeTime(d, 'end', val)}
                      >
                        {Hours.map((h, i) => (
                          <Select.Option key={`eh-${i}`} value={h.value}>
                            {h.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>

        {/* ACTIONS */}
        {actions}
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(Schedule));
