import moment from 'moment';
import { leadColors } from '_seriph/_constants/colors';

export const generateData = data => {

  const weekdayData = [...data];
  weekdayData.shift();
  weekdayData.pop();

  const chartData = {
    vs: [
      {
        name: 'Calls',
        settings: { stroke: leadColors['created'], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Emails',
        settings: { stroke: leadColors['review'], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'LinkedIn',
        settings: { stroke: leadColors['generated'], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Total Tasks',
        settings: { stroke: leadColors['qualified'], strokeWidth: 3, dot: false },
        data: []
      }
    ]
  };

  if (weekdayData && weekdayData.length > 0) {
    const totals = { ...weekdayData[0].daily };
    weekdayData.forEach((d, i) => {
      /* Totals */
      if (i > 0) Object.keys(d.daily).forEach(s => (totals[s] += d.daily[s]));

      /* Activity Chart */
      chartData.vs[0].data.push({
        month: moment(d.date.toString()).format('MM YY'),
        name: moment(d.date.toString()).format('ddd, MMM Do'),
        value: d.daily.touchpoint_call
      });
      chartData.vs[1].data.push({
        month: moment(d.date.toString()).format('ddd YY'),
        name: moment(d.date.toString()).format('ddd, MMM Do'),
        value: d.daily.touchpoint_email
      });
      chartData.vs[2].data.push({
        month: moment(d.date.toString()).format('MM YY'),
        name: moment(d.date.toString()).format('ddd, MMM Do'),
        value: d.daily.touchpoint_linkedin
      });
      chartData.vs[3].data.push({
        month: moment(d.date.toString()).format('MM YY'),
        name: moment(d.date.toString()).format('ddd, MMM Do'),
        value: d.daily.touchpoint_total
      });

    });
    chartData.totals = totals;
  }

  return chartData;
};
