export const calculateMatch = (application, a, c) => {
  let campaign, agent;
  if (application) {
    agent = application.agent;
    campaign = application.campaign;
  } else {
    campaign = c;
    agent = a;
  }

  let rating = 0;
  const weight = { skills: 15, experience: 40, location: 15, languages: 30 };

  // Rate Skills
  const skills = new Set(agent.skills);
  campaign.target_skills.forEach(s => {
    if (skills.has(s)) rating += weight.skills / campaign.target_skills.length;
  });

  // Rate Location
  const loc = agent.location;
  let locRating = 0;
  campaign.target_locations.forEach(l => {
    if (loc.lat > l.lat - 2 && loc.lat < l.lat + 2 && loc.lng > l.lng - 2 && loc.lng < l.lng + 2) {
      locRating = weight.location;
    } else if (l.lat === 0 && l.lng === 0) {
      locRating = weight.location;
    }
  });
  rating += locRating;

  // Rate Experience
  const level = campaign.target_experience_level;
  if (level === 'any') {
    rating += weight.experience;
  } else if (level === 'entry') {
    if (agent.total_experience === 'entry') rating += weight.experience * 1;
    if (agent.total_experience === 'intermediate') rating += weight.experience * 0.9;
    if (agent.total_experience === 'expert') rating += weight.experience * 0.85;
  } else if (level === 'intermediate') {
    if (agent.total_experience === 'entry') rating += weight.experience * 0.75;
    if (agent.total_experience === 'intermediate') rating += weight.experience * 1;
    if (agent.total_experience === 'expert') rating += weight.experience * 0.9;
  } else if (level === 'expert') {
    if (agent.total_experience === 'entry') rating += weight.experience * 0.5;
    if (agent.total_experience === 'intermediate') rating += weight.experience * 75;
    if (agent.total_experience === 'expert') rating += weight.experience * 1;
  }

  // Rate Languages
  const languages = new Set(agent.languages);
  const allLanguages = campaign.target_languages.find(l => l === 'All Languages');
  if (allLanguages) {
    rating += weight.languages;
  } else {
    campaign.target_languages.forEach(l => {
      if (languages.has(l)) rating += weight.languages / campaign.target_languages.length;
    });
  }

  return Math.min(Math.round(rating), 100);
};

export const calculateMultiple = (campaigns, agent) => {
  let bestMatch = 0;
  campaigns.forEach(c => {
    let matchPercent = 0;
    if (c.status === 'active') matchPercent = calculateMatch(null, agent, c) || 0;
    if (matchPercent >= bestMatch) bestMatch = matchPercent;
  });
  return bestMatch;
};
