import React from 'react';
import { Button } from 'antd';
import { slicer, stripHTML, capitalize } from '_assets/js/helpers';

export const getColumns = showTemplateModal => {
  return [
    {
      title: 'Template Name',
      dataIndex: 'name',
      render: column => <b>{column}</b>
    },
    {
      title: 'Template Type',
      dataIndex: 'type',
      render: column => <div className="temp-type">{capitalize(column)}</div>
    },
    {
      title: 'Subject',
      dataIndex: 'information',
      render: column => <p>{column}</p>
    },
    {
      title: 'Content',
      dataIndex: 'content',
      render: column => <p>{slicer(stripHTML(column), 100)}</p>
    },
    {
      title: 'Actions',
      dataIndex: 'acknowledged',
      width: 120,
      render: (column, data) => {
        return (
          <Button className="btnx btnx-primary" onClick={() => showTemplateModal(data)}>
            Edit Template
          </Button>
        );
      }
    }
  ];
};
