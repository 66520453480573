import gql from 'graphql-tag';

export const hiredAgents = gql`
  query hiredAgents($campaign_id: String, $status: String) {
    hiredAgents(campaign_id: $campaign_id, status: $status) {
      agent {
        id
        display_name
        about
        picture
        location {
          name
        }
        reputation
      }
      campaigns {
        id
        name
        status
        agents {
          agent_id
          active
        }
      }
      hired
      is_internal
      removed
      stats {
        total_paid
        total_fees
        in_progress
        review
        generated
        qualified
        emailed
        called
        meetings
        pulled
        meeting_value
        touchpoint_email
        touchpoint_call
        touchpoint_linkedin
        touchpoint_instructions
        touchpoint_skipped
        touchpoint_total
      }
    }
  }
`;

export const myAgents = gql`
  query hiredAgents($campaign_id: String, $status: String) {
    hiredAgents(campaign_id: $campaign_id, status: $status) {
      agent {
        id
        display_name
        picture
      }
    }
  }
`;

export const getTeamMembers = gql`
  query {
    getTeamMembers {
      id
      display_name
      picture
      permission_type
      createdAt
      user {
        email
      }
    }
  }
`;
export const getTeamInvites = gql`
  query {
    getTeamInvites {
      id
      email
      used
      invite_link
      permission_type
      used_tstamp
      used_by {
        display_name
        picture
      }
    }
  }
`;

export const getMyReps = gql`
  query {
    getMyReps {
      id
      display_name
      picture
      removed
      user {
        email
      }
      createdAt
      company {
        role
        company_id
        joined
      }
    }
  }
`;
export const getRepInvites = gql`
  query {
    getRepInvites {
      id
      used
      email
      invite_key
      used_tstamp
      updatedAt
      createdAt
    }
  }
`;

export const getNumSeats = gql`
  query {
    getNumSeats
  }
`;

export const getNumEmpSeats = gql`
  query {
    getNumEmpSeats
  }
`;



export const integrations = gql`
  query {
    integrations {
      id
      calendly {
        url
        auth_token
        refresh_token
      }
      salesforce {
        enabled
        instance_url
        access_token
        gen_duplicates
        gen_type
        qual_sync
        qual_account
        qual_contact
        qual_opportunity
      }
      chilipiper {
        domain
        inbound_router
      }
      hubspot {
        enabled
        access_token
        portal_id
        qual_sync
        qual_convert
      }
      gmail {
        email
        access_token
      }
      custom_domains {
        domain
        active
      }
    }
  }
`;

export const billingHistory = gql`
  query billingHistory(
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $status: String
  ) {
    billingHistory(
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      status: $status
    ) {
      data {
        id
        name
        type
        status
        total
        createdAt
      }
      runningTotal
      threshold
      total
      pageTotal
    }
  }
`;

export const billDetails = gql`
  query billDetails($id: ID) {
    billDetails(id: $id) {
      id
      name
      status
      try_again_date
      type
      subtotal
      total
      updatedAt
      pdf_url
      fees {
        name
        amount
      }
      activity {
        id
        name
        type
        timestamp
      }
      payment_info {
        stripe_charge_id
        type
        sub_type
        ending
      }
      items {
        id
        name
        type
        item_count
        charge_amount
        agent_amount
        campaign {
          id
          name
        }
      }
    }
  }
`;

export const applications = gql`
  query {
    applications {
      id
      note
      status
      applied_date
      hours
      audio_url
      seen_by
      expanded_by
      commitment
      campaign {
        id
        name
        target_experience_level
        target_skills
        target_languages
        target_locations {
          lat
          lng
        }
      }
      agent {
        id
        about
        availability
        employment_type
        experience_type
        experiences {
          start_date
          end_date
        }
        profile_percent
        skills
        languages
        display_name
        first_name
        picture
        total_experience
        location {
          name
          lat
          lng
        }
        rating
        reputation
      }
    }
  }
`;

export const getNewCompanies = gql`
  query {
    newCompanies {
      id
      name
      logo
      industries {
        name
      }
      createdAt
    }
  }
`;

export const getCompany = gql`
  query company($id: ID) {
    company(id: $id) {
      id
      name
      headline
      about
      overview
      website
      logo
      employee_count_min
      employee_count_max
      linkedin
      facebook
      instagram
      twitter
      rating
      verified
      locations {
        name
        lat
        lng
      }
      industries {
        name
        code
      }
      owner {
        display_name
        picture
        title
      }
      employees {
        id
        display_name
        picture
        title
      }
      active_campaigns {
        id
        name
        objective
        objective_type
        description
        short_description
        target_skills
        budget_ppl
        budget_ppql
        budget_total
        budget_bonus
        budget_bonus_count
        estimated_earnings
        created
        target_agent_count
        agents_remaining
        price_per_lead
        target_experience_text
        target_availability_text
        target_experience_short
        target_availability_short
        target_languages
        target_locations {
          name
        }
        threshold
        threshold_settings {
          lead_count
        }
        applications {
          agent_id
          status
        }
        company {
          id
          name
          overview
          logo
          industries {
            name
          }
        }
      }
    }
  }
`;

export const getCompanyApplication = gql`
  query getCompanyApplication($id: ID) {
    getCompanyApplication(id: $id) {
      name
      phone
      email
      status
      company_name
      company_website
      company_size
      industries
      needs
      hear_about
      hear_about_other
      desired_start
      desired_engagement
      ok_with_remote
      ok_with_remote_explanation
      is_calendly_event_scheduled
      calendly_url
    }
  }
`;

export const campaignInvitations = gql`
  query campaignInvitations($campaign_status: String) {
    campaignInvitations(campaign_status: $campaign_status) {
      rep {
        id
        picture
        display_name
      }
      campaigns {
        id
        name
        status_updated
      }
      most_recent_invite
    }
  }
`;

export const allCompanies = gql`
  query allCompanies {
    allCompanies {
      id
      name
      logo
    }
  }
`;

export const companySearchName = gql`
  query companySearchName($name: String) {
    companySearchName(name: $name) {
      id
      name
      logo
    }
  }
`;
