import gql from 'graphql-tag';

export const createInvite = gql`
  mutation createInvite($agent_id: ID!, $campaign_ids: [ID!]) {
    createInvite(agent_id: $agent_id, campaign_ids: $campaign_ids)
  }
`;

export const acceptInvite = gql`
  mutation acceptInvite($id: ID!, $commitment: Int) {
    acceptInvite(id: $id, commitment: $commitment)
  }
`;

export const acceptInviteFromDiscover = gql`
  mutation acceptInviteFromDiscover($campaign_id: ID!, $commitment: Int) {
    acceptInviteFromDiscover(campaign_id: $campaign_id, commitment: $commitment)
  }
`;

export const declineInvite = gql`
  mutation declineInvite($id: ID!) {
    declineInvite(id: $id)
  }
`;
