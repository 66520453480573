import React, { Component } from 'react';
import { Button } from 'antd';
import Avatar from '_styleguide/Avatar/Avatar';
import './Submitted.scss';

export default class Submitted extends Component {
  render() {
    const { company } = this.props;

    return (
      <div className="apply-submitted">
        <div className="apply-pics">
          <Avatar size="large" type="company" picture={company.logo} />
          <Avatar size="large" type="company" picture={this.props.user.picture} />
        </div>
        <div className="apply-info">
          <h3>Application Submitted</h3>
          <p>
            You’ll be notified by a company representative if you’re accepted to the campagin or if
            they have any follow up questions.
          </p>
          <Button className="btnx btnx-default" onClick={this.props.hideModal}>
            Close
          </Button>
        </div>
      </div>
    );
  }
}
