import React, { Component } from 'react';
import { Menu } from 'antd';
import './StepList.scss';

export default class StepList extends Component {
  validClass = field => {
    const { validation, showValidation } = this.props;
    return !validation[field] && showValidation ? 'invalid' : '';
  };
  render() {
    const { step, changeStep, form } = this.props;

    return (
      <div id="step-list" className="step-list">
        <div className="sx-menu">
          <Menu mode="vertical" selectedKeys={[step.toString()]}>
            <Menu.Item
              className={this.validClass('campaign_setup')}
              key="1"
              onClick={() => changeStep(1)}
            >
              Campaign Setup
            </Menu.Item>
            {form.objective === 'leadgen' && (
              <Menu.Item
                className={this.validClass('customer_profile')}
                key="2"
                onClick={() => changeStep(2)}
              >
                Customer Profile
              </Menu.Item>
            )}
            {form.objective === 'qualify' && (
              <Menu.Item
                className={this.validClass('qualify_questions')}
                key="2"
                onClick={() => changeStep(2)}
              >
                Qualifying Information
              </Menu.Item>
            )}
            <Menu.Item
              className={this.validClass('agent_targeting')}
              key="3"
              onClick={() => changeStep(3)}
            >
              Sales Rep Criteria
            </Menu.Item>
            <Menu.Item key="4" onClick={() => changeStep(4)}>
              Instructions
            </Menu.Item>
            <Menu.Item className={this.validClass('budget')} key="5" onClick={() => changeStep(5)}>
              Budget
            </Menu.Item>
          </Menu>
        </div>
      </div>
    );
  }
}
