import React, { Component } from 'react';
import { connect } from 'react-redux';
import Listing from '../Listing/Listing';
import { Loading } from '_seriph';
import './Recommended.scss';

class Recommended extends Component {
  render() {
    return (
      <div id="company-discover-recommended">
        <div className="recommended-companies">
          {this.props.loading ? (
            <Loading />
          ) : (
            <React.Fragment>
              {this.props.agents.map(a => (
                <Listing
                  key={'c-' + a.id}
                  id={a.id}
                  agent={a}
                  campaigns={this.props.campaigns}
                  showModal={this.props.showModal}
                  messages={this.props.messages}
                  loadMessaging={this.props.loadMessaging}
                />
              ))}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default connect(mapStateToProps, {})(Recommended);
