import React, { Component } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { numberWithCommas } from '_assets/js/helpers';
import { Loading } from '_seriph';
import { leadColors } from '_seriph/_constants/colors';
import './GeneratedLeadsChart.scss';

export default class GeneratedLeadsChart extends Component {
  state = {
    visible: {
      in_progress: true,
      generated_review: true,
      generated: true,
      generated_disputed: true
    }
  };
  toggleVisible = slug => {
    const visible = { ...this.state.visible };
    visible[slug] = !visible[slug];
    this.setState({ visible });
  };
  customTooltip = data => {
    const payload = data.payload || [];
    return (
      <div className="chart-tooltip">
        <div className="tip-box">
          <div className="tip-title">{data.label}</div>
          <div className="tip-contents">
            {payload.map((p, i) => (
              <div key={`tip-${i}`} className="tip-item">
                <div className="tip-circle" style={{ backgroundColor: p.stroke }} />
                <div className="tip-name">{p.name}</div>
                <div className="tip-value">{numberWithCommas(p.value) || '0'}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  render() {
    const { visible } = this.state;
    const { totals, data, range, loading } = this.props;

    const tickCount = data && data.length > 0 ? data[0].data.length : 0;
    const interval = tickCount > 7 ? Math.ceil(tickCount / 7) : 0;

    return (
      <div className="chart-box" id="overview-generated-leads-chart">
        <div className="chart-title">
          <h4>
            Generated leads<span>({range.title})</span>
          </h4>
          <p>Total leads that have been created for lead generation campaigns.</p>
        </div>
        <div className="chart-display">
          {loading ? (
            <div className="chart-loading" style={{ minHeight: '360px' }}>
              <Loading />
            </div>
          ) : (
            <div className="chart-column">
              <div className="chart-toggles">
                <div
                  className={`chart-toggle ${!visible.in_progress ? 'disabled' : ''}`}
                  onClick={() => this.toggleVisible('in_progress')}
                  style={{ borderColor: leadColors['in-progress'] }}
                >
                  <h4>Created</h4>
                  <h2>{numberWithCommas(totals.in_progress) || '0'}</h2>
                </div>
                <div
                  className={`chart-toggle ${!visible.generated_review ? 'disabled' : ''}`}
                  onClick={() => this.toggleVisible('generated_review')}
                  style={{ borderColor: leadColors['review'] }}
                >
                  <h4>Submitted</h4>
                  <h2>{numberWithCommas(totals.generated_review) || '0'}</h2>
                </div>
                <div
                  className={`chart-toggle ${!visible.generated ? 'disabled' : ''}`}
                  onClick={() => this.toggleVisible('generated')}
                  style={{ borderColor: leadColors['generated'] }}
                >
                  <h4>Generated</h4>
                  <h2>{numberWithCommas(totals.generated) || '0'}</h2>
                </div>
                <div
                  className={`chart-toggle ${!visible.generated_disputed ? 'disabled' : ''}`}
                  onClick={() => this.toggleVisible('generated_disputed')}
                  style={{ borderColor: leadColors['disputed'] }}
                >
                  <h4>Disputed</h4>
                  <h2>{numberWithCommas(totals.generated_disputed) || '0'}</h2>
                </div>
              </div>
              <ResponsiveContainer height={360} width="99%">
                <LineChart margin={{ right: 32 }}>
                  <CartesianGrid stroke="#E7ECF4" strokeDasharray="0 0" vertical={false} />
                  <XAxis
                    interval={interval}
                    tickLine={false}
                    axisLine={false}
                    dataKey="name"
                    type="category"
                    allowDuplicatedCategory={false}
                  />
                  <YAxis
                    tickLine={false}
                    axisLine={false}
                    dataKey="value"
                    domain={[0, 'dataMax + 2']}
                    allowDecimals={false}
                    width={40}
                  />
                  <Tooltip content={this.customTooltip} />
                  {data.map(s =>
                    visible[s.slug] ? (
                      <Line
                        dataKey="value"
                        data={s.data}
                        name={s.name}
                        key={s.name}
                        {...s.settings}
                      />
                    ) : null
                  )}
                </LineChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>
      </div>
    );
  }
}
