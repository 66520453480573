import React from 'react';
import { pickerData } from '_constants/salesforce';
import { LANGUAGES } from '_assets/js/languages';
import { ETHNICITIES } from '_assets/js/ethnicities';
import {
  faFont,
  faAlignLeft,
  faAt,
  faPhone,
  faHashtag,
  faLink
} from '@fortawesome/free-solid-svg-icons';

import {
  IconPhone,
  IconAt,
  IconLetterCase,
  IconCertificate,
  IconLanguage,
  IconMan,
  IconOld,
  IconBrandLinkedin,
  IconBrandFacebook,
  IconBrandTwitter,
  IconBrandInstagram,
  IconPerspective,
  IconBuilding,
  IconBuildingFactory,
  IconBrowser,
  IconMap,
  IconListNumbers,
  IconCash
} from '@tabler/icons-react';

export const leadFields = {
  'Contact Information': {
    Saluation: { field: 'salutation', canTag: 'Mr.', icon: <IconLetterCase />, type: 'Short text' },
    'First Name': {
      field: 'first_name',
      canTag: 'John',
      icon: <IconLetterCase />,
      type: 'Short text'
    },
    'Last Name': {
      field: 'last_name',
      canTag: 'Smith',
      icon: <IconLetterCase />,
      type: 'Short text'
    },
    'Email Address': {
      field: 'email',
      canTag: 'johnsmith@gmail.com',
      icon: <IconAt />,
      type: 'Email'
    },
    'Phone Number': {
      field: 'phone',
      canTag: '555-555-5555',
      icon: <IconPhone />,
      type: 'Phone'
    },
    'Mobile Number': {
      field: 'mobile',
      canTag: '555-555-1234',
      icon: <IconPhone />,
      type: 'Phone'
    },
    Fax: { field: 'fax', canTag: '555-678-5555', icon: <IconPhone />, type: 'Phone number' }
  },
  'Personal Information': {
    'Education Level': {
      field: 'education',
      type: 'Multiple',
      values: [],
      icon: <IconCertificate />
    },
    Languages: {
      field: 'primary_language',
      type: 'Multiple',
      values: [],
      icon: <IconLanguage />
    },
    Gender: { field: 'gender', type: 'Multiple', values: [], icon: <IconMan /> },
    Age: { field: 'age', icon: <IconOld />, type: 'Number' },
    Race: { field: 'race', type: 'Multiple', values: [], icon: <IconPerspective /> },
    Linkedin: { field: 'linkedin', icon: <IconBrandLinkedin />, type: 'Short text' },
    Facebook: { field: 'facebook', icon: <IconBrandFacebook />, type: 'Short text' },
    Twitter: { field: 'twitter', icon: <IconBrandTwitter />, type: 'Short text' },
    Instagram: { field: 'instagram', icon: <IconBrandInstagram />, type: 'Short text' }
  },
  'Company Information': {
    'Company Name': {
      field: 'company',
      canTag: 'CandyCorp',
      icon: <IconBuilding />,
      type: 'Short text'
    },
    'Job Title': { field: 'title', canTag: 'CTO', icon: <IconLetterCase />, type: 'Short text' },
    'Job Position': {
      field: 'job_position',
      type: 'Multiple',
      values: [],
      canTag: 'Manager',
      icon: <IconLetterCase />
    },
    Industry: { field: 'industry', type: 'Multiple', values: [], icon: <IconBuildingFactory /> },
    Website: {
      field: 'website',
      canTag: 'https://www.candycorp.com',
      icon: <IconBrowser />,
      type: 'URL'
    },
    'Company Scope (Local, Regional, etc)': {
      field: 'company_scope',
      type: 'Multiple',
      values: [],
      icon: <IconMap />
    },
    'Number of Employees': {
      field: 'number_of_employees',
      icon: <IconListNumbers />,
      type: 'Number'
    },
    'Annual Revenue': { field: 'annual_revenue', icon: <IconCash />, type: 'Number' }
  },
  Location: {
    Location: { field: 'location', type: 'Location', locations: [], icon: <IconMap /> }
  }
};

export const companyFields = {
  'Campaign Company Info.': {
    'Company Name': { field: 'name', canTag: 'SellX' },
    'Company Website': { field: 'website', canTag: 'https://www.sellx.com' },
    'Company LinkedIn': { field: 'linkedin', canTag: 'https://www.linkedin.com/in/sellx/' },
    'Company Facebook': { field: 'facebook', canTag: 'https://www.facebook.com/sellx' },
    'Company Instagram': { field: 'instagram', canTag: 'https://www.instagram.com/sellx' },
    'Company Twitter': { field: 'twitter', canTag: 'https://twitter.com/JoinSellX' }
  }
};

export const repFields = {
  'Sales Rep Info.': {
    'Rep Full Name': { field: 'display_name', canTag: 'Franklin Timber' },
    'Rep First name': { field: 'first_name', canTag: 'Franklin' },
    'Rep Last Name': { field: 'last_name', canTag: 'Timber' },
    'Rep Phone': { field: 'phone_number', canTag: '555-555-5555' },
    'Rep Email': { field: 'email', canTag: 'franklin@timber.com' }
  }
};

export const fieldTypes = {
  lead: leadFields,
  company: companyFields,
  rep: repFields
};

export const justTags = () => {
  const tags = {};
  Object.keys(leadFields).forEach(c =>
    Object.keys(leadFields[c]).forEach(i => {
      if (leadFields[c][i].canTag) tags['lead.' + leadFields[c][i].field] = leadFields[c][i].canTag;
    })
  );
  Object.keys(companyFields).forEach(c =>
    Object.keys(companyFields[c]).forEach(i => {
      if (companyFields[c][i].canTag)
        tags['company.' + companyFields[c][i].field] = companyFields[c][i].canTag;
    })
  );
  Object.keys(repFields).forEach(c =>
    Object.keys(repFields[c]).forEach(i => {
      if (repFields[c][i].canTag) tags['rep.' + repFields[c][i].field] = repFields[c][i].canTag;
    })
  );
  return tags;
};

export const getKeyByValue = item => {
  let returnKey = null;
  Object.keys(leadFields).forEach(c => {
    Object.keys(leadFields[c]).forEach(f => {
      if (leadFields[c][f].field === item) returnKey = f;
    });
  });
  return returnKey;
};

export const statusList = [
  { name: 'In Progress', key: 'in-progress' },
  { name: 'Completed', key: 'completed' },
  { name: 'Pending Review', key: 'review' },
  { name: 'Not Interested', key: 'not-interested' },
  { name: 'Disputed', key: 'disputed' }
];

export const statusMap = {
  'in-progress': 'In Progress',
  completed: 'Completed',
  review: 'Pending Review',
  'not-interested': 'Not Interested',
  disputed: 'Disputed',
  unassigned: 'Unassigned',
  new: 'New'
};

export const disputeMap = {
  incorrect: 'Incorrect info',
  enough: 'Not enough info',
  scope: 'Out of scope',
  'no-show': 'Missed Meeting',
  other: 'Other'
};

export const skipMap = {
  incorrect: 'Incorrect info',
  missing: 'Missing info',
  instructed: 'Instructed to',
  other: 'Other'
};

export const qualifyMap = {
  interested: 'Interested',
  'not-interested': 'Not Interested',
  'no-response': 'No Response',
  invalid: 'Invalid or Duplicate'
};

export const qualifyPrice = {
  interested: null,
  'not-interested': 2,
  'no-response': 2,
  invalid: 2
};

export const typeMap = {
  leadgen: 'Lead Generation',
  qualify: 'Lead Qualification'
};

export const typeList = [
  { name: 'Generated', key: 'generated' },
  { name: 'Qualified', key: 'qualified' }
];

export const pickerFields = {
  education: pickerData.education,
  primary_language: LANGUAGES,
  gender: pickerData.gender,
  race: ETHNICITIES,
  job_position: pickerData.job_position,
  industry: pickerData.industry,
  company_scope: pickerData.company_scope
};

export const customOptions = [
  {
    name: 'Short text',
    description: 'For single line information',
    value: 'text',
    icon: faFont
  },
  {
    name: 'Long text',
    description: 'For long form/multi-line info like notes',
    value: 'textarea',
    icon: faAlignLeft
  },
  {
    name: 'Email',
    description: 'Use for additional email addresses',
    value: 'email',
    icon: faAt
  },
  {
    name: 'Phone',
    description: 'For when you have additional phone numbers that are valuable',
    value: 'phone',
    icon: faPhone,
    tablerIcon: IconPhone
  },
  {
    name: 'Number',
    description: 'Use for any number based info like employee count, revenue etc.',
    value: 'number',
    icon: faHashtag
  },
  {
    name: 'Web URL',
    description: 'Use for info like social media accounts, company websites etc.',
    value: 'url',
    icon: faLink
  }
];
