import React, { Component } from 'react';
import { numberWithCommas, formatPercent, decimalToPercent } from '_assets/js/helpers';
import { Loading } from '_seriph';
import './ConversionTable.scss';

export default class ConversionTable extends Component {
  render() {
    const { data, campaign, loading } = this.props;

    return (
      <div className="chart-box" id="progress-conversion-table">
        <div className="chart-title">
          <h4>
            Lead conversion<span>({campaign})</span>
          </h4>
          <p>Conversion rates on qualified leads</p>
        </div>
        <div className="chart-table">
          {loading ? (
            <div className="chart-loading" style={{ minHeight: '360px' }}>
              <Loading />
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Total</th>
                  <th>Active</th>
                  <th>Completed</th>
                  <th>Responded</th>
                  <th>Qualified</th>
                  <th>Responded qualified</th>
                  <th>Campaign Completion %</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{numberWithCommas(data.total_leads)}</td>
                  <td>{numberWithCommas(data.in_progress + data.review)}</td>
                  <td>{numberWithCommas(data.completed)}</td>
                  <td>
                    {numberWithCommas(data.has_responded)} ({formatPercent(data.response_rate)})
                  </td>
                  <td>
                    {numberWithCommas(data.qualified)} ({formatPercent(data.conversion_rate)})
                  </td>
                  <td>
                    {numberWithCommas(data.responded_qualified)} (
                    {formatPercent(data.response_conversion_rate)})
                  </td>
                  <td>{decimalToPercent(data.completed / data.total_leads)}</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  }
}
