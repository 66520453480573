import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getScheduledMeetings } from '_graphql/queries/analytics';
import { generateRange } from '_constants/datepicker';
import { Table, Header } from '_seriph';
import { getColumns } from './Columns';
import './Meetings.scss';

const generateCampaigns = campaign => {
  const isStatus = ['active', 'inactive', 'completed', 'all'].includes(campaign);
  if (isStatus) {
    return { status: campaign };
  } else {
    return { campaign_id: campaign };
  }
};

const defaultParams = {
  results: 10, 
  page: 1, 
  sortField: 'paid', 
  sortOrder: 'descend' 
};

class Meetings extends Component {
  state = {
    loading: true,
    pagination: { pageSize: 10 },
    data: [],
    hired: [],
    filters: {
      campaign_id: 'all',
      range: 'last7',
      meetingStatus: 'upcoming',
      owner: 'all'
    },
    previous: null
  };
  componentDidMount() {
    this.fetchMeetings();
  }
  fetchMeetings = async (params = defaultParams) => {
    this.setState({ loading: true });
    const range = generateRange(this.state.filters.range);
    const selected = generateCampaigns(this.state.filters.campaign_id);
    const meetingStatus = this.state.filters.meetingStatus;
    const owner = this.state.filters.owner;
    this.props.client
      .query({
        variables: { ...range, ...selected, meetingStatus, owner, ...params },
        query: getScheduledMeetings
      })
      .then(result => {
        if (result && result.data) {
          const meetings = result.data.getScheduledMeetings.data || [];
          const pagination = { ...this.state.pagination };
          pagination.total = result.data.getScheduledMeetings.pageTotal;
          pagination.totalAll = result.data.getScheduledMeetings.total;
          this.setState({
            loading: false,
            data: meetings,
            pagination,
            previous: params
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(() => this.setState({ loading: false }));
  };
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetchMeetings({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
    });
  };
  UNSAFE_componentWillMount = () => window.scrollTo({ top: 0 });
  render() {
    const { data, pagination, loading } = this.state;

    const columns = getColumns(this.props.campaigns);

    return (
      <div id="company-analytics-meetings">
        <div className="seriph-header-wrapper">
          <Header type="display" weight="600" size="4">
            Scheduled Meetings
          </Header>
        </div>

        <div className="meetings-table">
          <Table
            lined
            rounded
            rowSelection={null}
            columns={columns}
            rowKey={record => record.id}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={this.handleTableChange}
            scroll={{ x: 'max-content' }}
            locale={{ emptyText: loading ? 'Loading scheduled meetings...' : 'No scheduled meetings found' }}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(Meetings);
