import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { IconCheck, IconLicense, IconViewfinder, IconCoin, IconStars } from '@tabler/icons-react';
import { formatMoney, flatMoney } from '_assets/js/helpers';
import { getQualEstimate } from 'src/App/Company/Wizard/_helpers/durationText';
import moment from 'moment';
import { Header, Card, Avatar, Button, Tooltip } from '_seriph';
import './Listing.scss';

const DESCRIPTION_MAX = 320;
const FAKE_TEXT =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Massa tempor nec. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Massa tempor nec. Lorem ipsum dolor sit amet, consect and something else';

export default class Listing extends Component {
  toggleFavorite = (isFavorite, campaign) => {
    if (isFavorite) {
      this.props.removeFavorite(campaign);
    } else {
      this.props.addFavorite(campaign);
    }
  };
  render() {
    const { campaign } = this.props;

    const isGen = campaign.objective === 'leadgen' ? true : false;
    const leadCount = campaign.threshold_settings?.lead_count || 0;
    const budgetEstimate = isGen
      ? flatMoney(campaign.budget_ppl * leadCount)
      : getQualEstimate(campaign, 400); // change 400

    const company = campaign.company || {};
    const description = (company.overview || FAKE_TEXT).substring(0, DESCRIPTION_MAX);
    const hasApplied = this.props.isAgent && this.props.hasApplied && !this.props.hasJoined;
    const hasJoined = this.props.isAgent && this.props.hasJoined;
    const canApply = this.props.isAgent && !hasApplied && !hasJoined && !this.props.hasBeenInvited;
    // const isFull = campaign.agents_remaining > 0 ? false : true;
    const isFull = false;
    let type = null;
    if (campaign.objective === 'leadgen') {
      type = 'leadgen';
    } else if (campaign.objective === 'qualify' && campaign.objective_type === 'inbound') {
      type = 'inbound';
    } else if (campaign.objective === 'qualify' && campaign.objective_type === 'outbound') {
      type = 'outbound';
    }

    const spacer = <span>&nbsp;&nbsp;&nbsp;&nbsp;&#9642;&nbsp;&nbsp;&nbsp;&nbsp;</span>;

    return (
      <div className="brief-listing">
        <Card style={{ boxShadow: 'none', padding: 0, marginBottom: '16px', position: 'relative' }}>
          <div className="explore-page">
            <div className="listing-top">
              <Link to={`/company/${company.id}`}>
                <Avatar size="small" picture={company.logo || null} />
              </Link>
              <div className="listing-info">
                <Link to={`/company/${company.id}`}>
                  <Header font="sans" size="5">
                    {company.name}
                  </Header>
                </Link>
                <div className="name-cat">{this.props.category}</div>
              </div>
            </div>
            <div className="company-information agent-explore">
              <Header font="sans" size="3">
                {campaign.name}
              </Header>
              <p>
                {description}
                {(company.overview || FAKE_TEXT).length > DESCRIPTION_MAX ? '...' : ''}
              </p>
              <div className="skills">
                {type === 'leadgen' && (
                  <Tooltip title="Lead Generation: Identifying and cultivating potential customers for a business's products or services.">
                    <div className="objective">
                      <IconViewfinder />
                      Generate New Leads{spacer}
                    </div>
                  </Tooltip>
                )}
                {(type === 'inbound' || type === 'outbound') && (
                  <Tooltip title="Lead Qualification: Determining if a lead is interested in a business's products or services.">
                    <div className="objective">
                      <IconViewfinder />
                      {type === 'inbound' ? 'Connect with Warm Leads' : 'Qualify Cold Leads'}
                      {spacer}
                    </div>
                  </Tooltip>
                )}

                <div className="info">
                  <Tooltip title="The total amount of possible earnings available to sales reps">
                    <span>
                      <IconCoin />
                      {budgetEstimate} budget{spacer}
                    </span>
                  </Tooltip>
                  <span>
                    <IconCoin />
                    {formatMoney(campaign.price_per_lead) + ' / lead'}
                    {spacer}
                  </span>
                  {campaign.target_experience_short && (
                    <Tooltip title="Experience needed">
                      <IconStars />
                      <span>{campaign.target_experience_short}</span>
                    </Tooltip>
                  )}
                </div>
                <div className="cmp-tstamp">Posted {moment(campaign.created * 1).fromNow()}</div>
              </div>
            </div>
          </div>
          <div className="company-actions">
            {canApply && !isFull ? (
              <Button type="default" onClick={() => this.props.showModal(this.props.id)}>
                Apply
              </Button>
            ) : null}
            {canApply && isFull ? (
              <Tooltip
                placement="bottomRight"
                title={
                  <div className="popover-body">
                    <h4>Waitlist</h4>
                    <p>
                      When a campaign reaches its rep capacity, companies can decide if they’ll
                      allow in more reps to join later.
                    </p>
                  </div>
                }
              >
                <Button type="default" onClick={() => this.props.showModal(this.props.id)}>
                  Join waitlist
                </Button>
              </Tooltip>
            ) : null}
            {hasApplied ? (
              <Button disabled={true} type="default">
                <IconLicense />
                Applied
              </Button>
            ) : null}
            {this.props.hasBeenInvited ? (
              <Button
                type="default"
                icon="left"
                onClick={() => this.props.showModal(this.props.id, true)}
              >
                Invited
              </Button>
            ) : null}
            {hasJoined ? (
              <Button disabled={true} type="default" icon="left">
                <IconCheck />
                Joined
              </Button>
            ) : null}
          </div>
        </Card>
      </div>
    );
  }
}
