import gql from 'graphql-tag';

export const checkVerification = gql`
  mutation checkVerification($code: String!) {
    checkVerification(code: $code)
  }
`;

export const resendVerification = gql`
  mutation resendVerification($code: String!) {
    resendVerification(code: $code)
  }
`;

export const forgotPassword = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const changePassword = gql`
  mutation changePassword($password: String!, $token: String!) {
    changePassword(password: $password, token: $token)
  }
`;

export const masqueradeAdmin = gql`
  mutation masqueradeAdmin {
    masqueradeAdmin
  }
`;

export const masqueradeOwner = gql`
  mutation masqueradeOwner {
    masqueradeOwner
  }
`;

export const masqueradeBack = gql`
  mutation masqueradeBack {
    masqueradeBack
  }
`;