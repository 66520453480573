import React, { Component } from 'react';
import { Table, Empty, Input } from 'antd';
import { adminOnboarding, adminCounts } from '../_graphql/queries';
import { getColumns } from './Columns';
import { numberWithCommas } from '_assets/js/helpers';
import './AgentOnboarding.scss';

export default class AgentOnboarding extends Component {
  state = {
    data: [],
    selectedRowKeys: [],
    pagination: { pageSize: 20 },
    invitedCount: 0,
    signupCount: 0,
    applicationCount: 0,
    profileCount: 0,
    loading: false,
    selected: null,
    searchText: ''
  };
  componentDidMount() {
    this.fetch({ results: 20, page: 1 });
    this.getCounts();
  }
  getCounts = () => {
    this.setState({ loading: true });
    this.props.client
      .query({ variables: { roleType: 'agent' }, query: adminCounts })
      .then(result => {
        if (result && result.data && result.data.adminCounts) {
          this.setState({
            invitedCount: result.data.adminCounts.invitedCount || 0,
            signupCount: result.data.adminCounts.signupCount || 0,
            applicationCount: result.data.adminCounts.applicationCount || 0,
            profileCount: result.data.adminCounts.profileCount || 0
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(() => this.setState({ loading: false }));
  };
  fetch = (params = {}) => {
    this.setState({ loading: true });
    this.props.client
      .query({
        variables: {
          name: this.state.searchText,
          roleType: 'agent',
          query: 'onboarding',
          ...params
        },
        query: adminOnboarding
      })
      .then(result => {
        if (result && result.data) {
          const applications = result.data.adminApplications.data || [];
          const pagination = { ...this.state.pagination };
          pagination.total = result.data.adminApplications.pageTotal;
          pagination.totalAll = result.data.adminApplications.total;
          this.setState({
            loading: false,
            data: applications,
            pagination
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(() => this.setState({ loading: false }));
  };
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      name: this.state.searchText,
      ...filters
    });
  };
  render() {
    const { pagination, data, loading, invitedCount, signupCount, applicationCount, profileCount } =
      this.state;
    const total = pagination.totalAll || 0;
    const totalFiltered = pagination.total || 0;
    const columns = getColumns(this.showReviewCandidate, this.resendInvite);

    let signupRate = 0,
      inviteRate = 0,
      profileRate = 0;
    if (signupCount) signupRate = (signupCount / invitedCount) * 100;
    if (applicationCount) inviteRate = (invitedCount / applicationCount) * 100;
    if (profileCount) profileRate = (profileCount / signupCount) * 100;

    return (
      <div id="admin-agent-onboarding">
        <div className="admin-header">
          Rep Onboarding{' '}
          <div>
            Showing {totalFiltered} of {total}
          </div>
          <div className="rates">
            <span className="rate">
              <h3>{numberWithCommas(applicationCount)}</h3>
              <h5>APPLIED</h5>
            </span>
            <span className="rate">
              <h3>{numberWithCommas(invitedCount)}</h3>
              <h5>INVITED</h5>
            </span>
            <span className="rate">
              <h3>{numberWithCommas(signupCount)}</h3>
              <h5>JOINED</h5>
            </span>
            <span className="rate percent">
              <h3>{inviteRate.toFixed(1)}%</h3>
              <h5>% INVITED</h5>
            </span>
            <span className="rate percent">
              <h3>{signupRate.toFixed(1)}%</h3>
              <h5>% JOINED</h5>
            </span>
            <span className="rate percent">
              <h3>{profileRate.toFixed(1)}%</h3>
              <h5>% PROFILE</h5>
            </span>
            <Input.Search
              placeholder="Search"
              value={this.state.searchText}
              onChange={e => this.setState({ searchText: e.target.value })}
              onSearch={value => this.fetch({ results: 20, page: 1, name: value })}
              ref={node => (this.searchInput = node)}
              size="large"
            />
          </div>
        </div>
        <div className="admin-table">
          <div className="table-content">
            <Table
              className="sellx-table bordered"
              bordered
              rowSelection={null}
              columns={columns}
              rowKey={record => record.id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              scroll={{ x: 'max-content' }}
              locale={{ emptyText: <Empty description="No reps" /> }}
            />
          </div>
        </div>
      </div>
    );
  }
}
