import React, { Component } from 'react';
import { Modal, Button, Input, Quill, Alert, Popconfirm, Toggle } from '_seriph';
import { stripHTML } from '_assets/js/helpers';
import { touchpointTypes } from '_constants/campaigns';
import { justTags } from '_constants/leadFields';
import SmartTags from '../SmartTags/SmartTags';
import { IconCopy } from '@tabler/icons-react';
import './EditTouchpoint.scss';

const allTags = justTags();

export default class EditTouchpoint extends Component {
  state = {
    lastFocus: null,
    isFocused: false,
    selectingTag: false,
    realData: false,
    errors: []
  };
  reactQuillRef = null;
  addTagToContent = tag => {
    const quillRef = this.reactQuillRef.getEditor();
    var range = quillRef.getSelection();
    let position = range ? range.index : 0;
    quillRef.insertText(position, '{{' + tag + '}}');
    this.isValid();
  };
  addTagToSubject = tag => {
    const cursorPosition =
      this.touchpointSubject && this.touchpointSubject.input
        ? this.touchpointSubject.input.selectionStart || 0
        : 0;
    const value = this.props.touchpoint.subject + '';
    let textBefore = value.substring(0, cursorPosition);
    let textAfter = value.substring(cursorPosition, value.length);
    this.props.update('subject', `${textBefore}${'{{' + tag + '}}'}${textAfter}`);
    this.isValid();
  };
  addTagTo = tag => {
    if (this.state.lastFocus === 'subject') {
      this.addTagToSubject(tag);
    } else {
      this.addTagToContent(tag);
    }
  };
  validateAndSave = () => {
    if (this.props.isNew) {
      this.props.createTouchpoint();
    } else {
      this.props.saveTouchpoint();
    }
  };
  isValid = () => {
    const { touchpoint } = this.props;
    const errors = [];
    if (!touchpoint.name) errors.push('Touchpoint name is required');
    if (!touchpoint.subject && touchpoint.type === 'email')
      errors.push('Touchpoint subject is required');
    if (!stripHTML(touchpoint.content)) errors.push('Touchpoint script/content is required');
    if (!touchpoint.type) errors.push('Touchpoint type is required');

    const content = this.props.touchpoint?.content + this.props.touchpoint?.subject + '';
    (content.match(/\{\{(.*?)\}\}/g) || []).forEach(val => {
      const basicTag = val.replace('{{', '').replace('}}', '');
      if (!allTags?.[basicTag]) {
        errors.push(`${basicTag} is not a valid tag`);
      }
    });

    this.setState({ errors });
  };
  previewTags = () => {
    const preview = {
      content: this.props.touchpoint?.content + '',
      subject: this.props.touchpoint?.subject + ''
    };
    const content = this.props.touchpoint?.content + this.props.touchpoint?.subject + '';
    (content.match(/\{\{(.*?)\}\}/g) || []).forEach(val => {
      const fullTag = val;
      const basicTag = val.replace('{{', '').replace('}}', '');
      preview.content = preview.content.replace(new RegExp(fullTag, 'g'), allTags[basicTag]);
      preview.subject = preview.subject.replace(new RegExp(fullTag, 'g'), allTags[basicTag]);
    });
    return preview;
  };
  changeQuill = val => {
    if (!this.state.realData) {
      this.props.update('content', val);
    }
    this.isValid();
  };
  render() {
    const { touchpoint, touchpointCampaigns } = this.props;
    const { realData } = this.state;
    const type = touchpointTypes[touchpoint.type];
    const campaignCount = touchpointCampaigns.length;

    const doNotEdit = campaignCount > 0 || touchpoint.stats?.has_stats ? true : false;
    const preview = realData ? this.previewTags() : null;

    return (
      <div id="edit-touchpoint">
        <Modal.Close onClick={this.props.hideModal} />

        <div className="edit-top">
          <div className="type" style={{ background: type.color }}>
            {type.icon}
          </div>
          <Input
            value={touchpoint.name}
            onChange={e => this.props.update('name', e.target.value)}
            placeholder={`Enter a touchpoint name`}
          />
        </div>

        {touchpoint.type === 'email' && (
          <div className="edit-subject">
            <div className="subject">Subject:</div>
            <Input
              value={realData ? preview.subject : touchpoint.subject}
              onChange={e => this.props.update('subject', e.target.value)}
              placeholder={`Enter an email subject`}
              onClick={() => this.setState({ lastFocus: 'subject' })}
              ref={node => (this.touchpointSubject = node)}
              disabled={realData}
            />
          </div>
        )}

        <div className={`edit-content ${doNotEdit ? 'alert' : ''}`}>
          {this.state.errors?.length ? (
            <Alert type="warning" title="ERROR">
              {this.state.errors.join(', ')}
            </Alert>
          ) : (
            doNotEdit && (
              <Alert
                type="warning"
                title="WARNING"
                actions={
                  <Button type="primary" icon="left">
                    <IconCopy />
                    Clone instead
                  </Button>
                }
              >
                Editing will alter sequence & campaign data. We recommend small changes only.
              </Alert>
            )
          )}
          <Quill
            value={realData ? preview.content : touchpoint.content}
            readOnly={realData}
            hideModules={type.name.toLowerCase() === 'text'}
            onChange={this.changeQuill}
            onFocus={() => this.setState({ lastFocus: 'content' })}
            placeholder={`Write your ${type.name.toLowerCase()} template here...`}
            setRef={el => (this.reactQuillRef = el)}
            showTags={true}
          />
          <SmartTags
            onFocus={() => this.setState({ selectingTag: true })}
            disabled={!this.state.lastFocus || realData}
            onSelect={this.addTagTo}
          />
          <div className="bottom-row">
            <div className="real-toggle">
              <Toggle
                checked={realData}
                size="small"
                onChange={checked => this.setState({ realData: checked })}
              />
              <h4>Show with real data</h4>
            </div>
            { type.name.toLowerCase() !== 'text' ? (
              <div className="disable-signature">
                <Toggle
                  checked={touchpoint.disable_signature}
                  size="small"
                  onChange={checked => this.props.update('disable_signature', checked)}
                />
                <h4>Disable signature</h4>
              </div>
            ) : null }
            
          </div>
        </div>

        <Modal.Actions align="right">
          {this.props.touchpointId === 'create' && (
            <Button
              className="back"
              type="secondary"
              onClick={() => this.props.changePage('create')}
            >
              Back
            </Button>
          )}

          {!this.props.isNew && doNotEdit ? (
            <Popconfirm
              title={`Archive this touchpoint?`}
              content="This touchpoint is a part of a sequence that has statistics so it can only be archived, but you can unarchive it at any time."
              onConfirm={() => this.props.removeTouchpoint(true)}
              okText="Archive Touchpoint"
              cancelText="Cancel"
              warning={true}
            >
              <Button className="danger" type="danger" loading={this.props.removing}>
                Archive
              </Button>
            </Popconfirm>
          ) : null}
          {!this.props.isNew && !doNotEdit ? (
            <Popconfirm
              title={`Delete this touchpoint?`}
              content="This will permanently delete this touchpoint and cannot be undone."
              onConfirm={() => this.props.removeTouchpoint()}
              okText="Delete Touchpoint"
              cancelText="Cancel"
              warning={true}
            >
              <Button className="danger" type="danger" loading={this.props.removing}>
                Delete
              </Button>
            </Popconfirm>
          ) : null}
          <Button type="default" onClick={() => this.props.hideModal()}>
            Cancel
          </Button>
          <Button
            type="primary"
            disabled={!this.props.edited || this.state.errors?.length || realData}
            loading={this.props.saving}
            onClick={this.validateAndSave}
          >
            {this.props.isNew ? 'Create' : 'Save'}
          </Button>
        </Modal.Actions>
      </div>
    );
  }
}
