import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, Button, Select, Input, message } from '_seriph';
import moment from 'moment';
import './Salary.scss';
import { updateSalary } from '../../../_graphql/mutations';

class Salary extends Component {
  state = {
    visible: true,
    editValues: false,
    type: null,
    amount: null,
    monthly_paydate: null,
    saving: false
  };
  hideModal = () => this.setState({ visible: false });
  componentDidMount() {
    const salary = this.props.agent?.salary;
    this.setState({
      type: salary?.type,
      amount: salary?.amount,
      monthly_paydate: salary?.monthly_paydate,
      sellx_fee_override: this.props.agent.sellx_fee_override
    });
  }

  editValues = () => {
    this.setState({ editValues: true });
  };
  stopEditValues = () => {
    this.setState({ editValues: false });

    const salary = this.props.agent?.salary;
    this.setState({
      type: salary?.type,
      amount: salary?.amount,
      monthly_paydate: salary?.monthly_paydate,
      editValues: false
    });
  };
  saveValues = () => {
    const agent_id = this.props.agent?.id;
    const { type, amount, monthly_paydate, sellx_fee_override } = this.state;
    this.setState({ saving: true });
    this.props.client
      .mutate({
        variables: {
          agent_id,
          type,
          amount: parseFloat(amount),
          monthly_paydate: parseInt(monthly_paydate),
          sellx_fee_override: parseFloat(sellx_fee_override)
        },
        mutation: updateSalary
      })
      .then(result => {
        if (result && result.data && result.data.updateSalary) {
          this.setState({ saving: false, editValues: false, type, amount, monthly_paydate });
          const newAgent = { ...this.props.agent };
          newAgent.salary = {
            type,
            amount,
            monthly_paydate,
            last_paydate: this.props.agent?.salary?.last_paydate
          };
          this.props.reload(newAgent);
        } else {
          throw new Error('Could not update salary, try again');
        }
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(err.message);
      });
  };
  render() {
    const agent = this.props?.agent;
    const salary = agent?.salary;
    return (
      <Modal
        wrapClassName="salary-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={false}
        width={800}
      >
        <Modal.Close onClick={this.hideModal} />
        <Modal.Header>
          Salary for {agent?.first_name} {agent?.last_name}
        </Modal.Header>

        {this.state.editValues ? (
          <div className="salary-form-cont">
            <div className="salary-form">
              <div>Salary Type</div>
              <div>
                <Select value={this.state.type} onChange={value => this.setState({ type: value })}>
                  <Select.Option value="part-time">Part Time</Select.Option>
                  <Select.Option value="full-time">Full Time</Select.Option>
                  <Select.Option value="freelance">Freelance</Select.Option>
                </Select>
              </div>
              <div>Amount</div>
              <div>
                <Input
                  size="medium"
                  onChange={e => this.setState({ amount: e.target.value })}
                  value={this.state.amount}
                />
              </div>
              <div>Paydate</div>
              <div>
                <Input
                  size="medium"
                  onChange={e => this.setState({ monthly_paydate: e.target.value })}
                  value={this.state.monthly_paydate}
                />
              </div>
              <div>
                Fee % <small>(.10 = 10%, .20 = 20%, .30 = 30%)</small>
              </div>
              <div>
                <Input
                  size="medium"
                  onChange={e => this.setState({ sellx_fee_override: e.target.value })}
                  value={this.state.sellx_fee_override}
                />
              </div>
            </div>
            <div className="salary-buttons">
              <Button onClick={this.saveValues}>Save</Button>
              <Button onClick={this.stopEditValues}>Cancel editing values</Button>
            </div>
          </div>
        ) : (
          <div className="salary-form-cont">
            <div className="salary-form">
              <div>Salary Type</div>
              <div>
                {salary?.type === 'part-time' ? (
                  <span>Part Time</span>
                ) : salary?.type === 'full-time' ? (
                  <span>Full Time</span>
                ) : salary?.type === 'freelance' ? (
                  <span>Freelance</span>
                ) : (
                  <span>[Not Set]</span>
                )}
              </div>
              <div>Amount</div>
              <div>{salary?.amount || 'not set'}</div>
              <div>Paydate</div>
              <div>{salary?.monthly_paydate || 'not set'}</div>
              <div>SellX Fee (currently evaluated as: {agent.sellx_fee})</div>
              <div>{agent.sellx_fee_override || 'not set'}</div>
            </div>
            <div>
              <Button onClick={this.editValues}>Edit values</Button>
            </div>
          </div>
        )}
        <h3>Last Paydate</h3>
        <p>
          {this.props.salary?.last_paydate
            ? moment(parseInt(salary?.last_paydate)).format()
            : 'no transactions'}
        </p>
      </Modal>
    );
  }
}

export default withRouter(Salary);
