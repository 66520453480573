import React from 'react';
import moment from 'moment';
import { getLeadName } from 'App/Company/Leads/Lead/_helpers/leadInformation';
import { Avatar, Tag } from '_seriph';

const zone_name =  moment.tz.guess();
const timezone = moment.tz(zone_name).zoneAbbr() 

export const getColumns = () => {
  return [
    {
      title: 'Lead',
      dataIndex: 'first_name',
      render: (column, data) => {
        let momentData = moment(Date.parse(data.calendly.created_at));
        if (!momentData.isValid()) {
          momentData = moment(data.calendly.created_at*1);
        }
        return (
          <div className="meeting-lead">
            <h3>{getLeadName(data)}</h3>
            <div>{data?.campaign?.name || 'Unknown Campaign'}</div>
            <p>Scheduled on: {momentData.format('MM/D h:mma')}</p>
          </div>
        );
      }
    },
    {
      title: 'Owner',
      dataIndex: 'picture',
      render: (column, data) => {
        return (
          <div className="meeting-own">
            <Avatar
              size="mini"
              type="company"
              picture={data.agent?.picture}
            />
            {data.agent?.display_name}
          </div>
        );
      }
    },
    {
      title: 'Meeting date',
      dataIndex: 'calendly.start_time',
      render: (column, data) => {
        const startTime =
          data.meetingScheduled && data.calendly && data.calendly.start_time
            ? data.calendly.start_time
            : null;

        let momentData = moment(Date.parse(startTime));
        if (!momentData.isValid()) {
          momentData = moment(startTime*1);
        }
        
        return momentData.format('ddd, M/D/YY');
      }
    },
    {
      title: 'Meeting time',
      dataIndex: 'calendly.end_time',
      render: (column, data) => {
        const startTime =
          data.meetingScheduled && data.calendly && data.calendly.start_time
            ? data.calendly.start_time
            : null;

        let momentData = moment(Date.parse(startTime));
        if (!momentData.isValid()) {
          momentData = moment(startTime*1);
        }

        return '@ ' + momentData.format('h:mma') + ' ' + timezone;
      }
    },
    {
      title: 'Status',
      dataIndex: 'meetingStatus',
      render: (column, data) => {

        const startTime =
          data.meetingScheduled && data.calendly && data.calendly.start_time
            ? data.calendly.start_time
            : null;
        const endTime =
          data.meetingScheduled && data.calendly && data.calendly.end_time
            ? data.calendly.end_time
            : null;

        const inProgress = moment().isBetween(startTime, endTime);
        const upComing = moment().isBefore(moment(Date.parse(startTime)));

        if (data.stage === 'disputed') {
          return <Tag type="danger">Disputed</Tag>
        } else if (inProgress) {
          return <Tag type="info">In Progress</Tag>
        } else if (upComing) {
          return <Tag type="default">Upcoming</Tag>
        } else{
          return <Tag type="primary">Completed</Tag>
        }
      }
    }
  ];
};
