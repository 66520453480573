import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Loading from '_shared/Loading/Loading';
import { formatEmail } from './formatEmail';
import { markAsRead } from '_graphql/mutations/notification';
import CampaignOverview from '_styleguide/CampaignOverview/CampaignOverview';
import InfiniteScroll from 'react-infinite-scroll-component';
import { touchpointEmails } from '_graphql/queries/touchpoints';
import './Email.scss';

class NotificationsList extends Component {
  state = {
    notifications: [],
    loading: true,
    page: 1,
    campaignId: null
  };
  componentDidMount = () => {
    this.loadNotifications({ results: 20, page: 1 });
  };
  loadNotifications = (params = {}) => {
    this.props.client
      .query({ variables: { ...params }, query: touchpointEmails })
      .then(result => {
        // touchpointEmails
        if (result && result.data && result.data.touchpointEmails) {
          const notificationsData = result.data.touchpointEmails.data || [];
          const newNotifications = [...this.state.notifications, ...notificationsData];
          this.setState({
            notifications: newNotifications,
            hasMore: newNotifications.length < result.data.touchpointEmails.total,
            page: params.page
          });
        }
        this.setState({ loading: false });
      })
      .catch(() => this.setState({ loading: false }));
  };
  markAsRead = id => {
    this.props.client
      .mutate({ variables: { notification_id: id }, mutation: markAsRead })
      .then(() => {
        if (this.props.reload) this.props.reload();
        this.loadNotifications();
      })
      .catch(() => {
        return false;
      });
  };
  selectNotification = (notification, itemData) => {
    if (itemData.link && !itemData.invitation) {
      if (!notification.expired) this.markAsRead(notification.id);
      this.props.closeDrawer();
      this.props.history.push(itemData.link);
    } else if (!itemData.link && itemData.invitation) {
      if (!notification.expired) this.markAsRead(notification.id);
      this.showModal(notification);
    }
  };
  showModal = notification => {
    this.setState({ campaignId: notification.campaign.id, notificationId: notification.id });
  };
  removeModal = () => this.setState({ campaignId: null, notificationId: null });
  render() {
    const { notifications, page, hasMore, loading } = this.state;
    if (loading) return <Loading size="large" />;
    return (
      <>
        <div className="note-box" id="noteificationsDiv">
          <InfiniteScroll
            dataLength={notifications.length}
            next={() => this.loadNotifications({ results: 20, page: page + 1 })}
            hasMore={hasMore}
            loader={<p className="inf">Loading...</p>}
            endMessage={
              page > 1 && notifications.length > 0 ? (
                <p className="inf">No more notifications to show.</p>
              ) : (
                <span></span>
              )
            }
            scrollableTarget="noteificationsDiv"
            scrollThreshold={0.9}
          >
            {notifications.length ? (
              notifications.map((item, i) => {
                const itemData = formatEmail(item);
                return itemData ? (
                  <div
                    className={!item.expired ? 'notification' : 'notification read'}
                    key={`notify-${i}`}
                    onClick={() => this.selectNotification(item, itemData)}
                  >
                    <div className="note-av">{itemData.avatar}</div>
                    <div className="note-cont">
                      <div className="contents">{itemData.description}</div>
                      <span>{moment(item.createdAt * 1).fromNow()}</span>
                    </div>
                    {!item.expired && <div className="note-alerting"></div>}
                  </div>
                ) : null;
              })
            ) : (
              <div className="none">No current notifications</div>
            )}
          </InfiniteScroll>
        </div>

        {this.state.campaignId ? (
          <CampaignOverview
            client={this.props.client}
            campaignId={this.state.campaignId}
            removeModal={this.removeModal}
            loadCampaigns={this.loadCampaigns}
            invitation={true}
            agentId={this.props.user.id}
            notification={this.state.notificationId}
            loadNotifications={this.loadNotifications}
          />
        ) : null}
      </>
    );
  }
}

export default withRouter(NotificationsList);
