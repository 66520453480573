import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Highlights from './_components/Highlights/Highlights';
import { companyHome } from '_graphql/queries/stats';
import { Header, Loading, message } from '_seriph';
import TaskChart from './_charts/TaskChart/TaskChart';
import CampaignCards from './_components/CampaignCards/CampaignCards';
import UpcomingMeetings from './_components/UpcomingMeetings/UpcomingMeetings';
import Tools from './_components/Tools/Tools';
import moment from 'moment';
import { generateData } from './data';
import './Home.scss';

class Home extends Component {
  state = {
    loading: true,
    data: null
  };
  componentDidMount = () => {
    this.loadInternalHome();
  };
  loadInternalHome = () => {
    this.props.client.query({ query: companyHome }).then(result => {
      if (result?.data?.companyHome) {
        this.setState({ data: result.data.companyHome, loading: false });
      } else {
        message.error('Could not load, try again');
      }
    });
  };
  render() {
    const { counts, loadNotifications, loadMessaging, integration } = this.props;
    const { loading, data } = this.state;

    const campaigns = data?.campaigns || [];

    const realData = generateData(data?.data, data?.previous);

    return loading || this.props.counts === null || this.state.data === null ? (
      <Loading />
    ) : (
      <div id="internal-home">
        <div className="home-main">
          <Header size="3" type="sans" className="home-name">
            {moment().format('dddd MMMM, Do')}
          </Header>
          <Header type="display" size="2" className="home-ttl">
            Welcome {this.props.user.display_name.split(' ')[0]},
          </Header>

          <Tools counts={counts} data={data} campaigns={campaigns} integration={integration} company={this.props.company} />
          <TaskChart data={realData.vs} totals={realData.totals} zero={realData.zeroData} />
          <CampaignCards campaigns={campaigns} />
          <UpcomingMeetings meetings={data.meetings || []} />

        </div>

        <Highlights
          counts={counts}
          data={data}
          campaigns={campaigns}
          loadNotifications={loadNotifications}
          loadMessaging={loadMessaging}
          user={this.props.user}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(Home));
