import React from 'react';
import { IconNote } from '@tabler/icons-react';
import moment from 'moment';

export default function Note(props) {
  const { activity, user, goTo } = props;
  const noteId = activity.note.note_id;

  return (
    <div className={'lead-activity ' + activity.type}>
      <div className="act-icon note">
        <IconNote />
      </div>
      <div className="descript">
        <b>{user.display_name}</b> posted a note
        <div className="act-tstamp">
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;{moment(activity.createdAt * 1).format('MMM D @ h:mm a')}
        </div>
        &nbsp;&bull;&nbsp;
        <div className="sxd-link" onClick={() => goTo('notes', `?note=${noteId}`)}>
          See Note
        </div>
      </div>
    </div>
  );
}
