import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { leadFields, pickerFields } from '_constants/leadFields';
import { Locked } from '_shared/Locked/Locked';
import { Card, Select, Checkbox, Button, Input } from '_seriph';
import './LeadInformation.scss';
import CustomFieldSelect from 'src/_styleguide/FieldMapping/_components/CustomFieldSelect/CustomFieldSelect';
import { customOptions } from '_constants/leadFields';
import Guide from '_styleguide/Guide/Guide';
import { IconX, IconSearch } from '@tabler/icons-react';

function getKeyByValue(item) {
  let returnKey = null;
  Object.keys(leadFields).forEach(c => {
    Object.keys(leadFields[c]).forEach(f => {
      if (leadFields[c][f].field === item)
        returnKey = { panelName: f, icon: leadFields[c][f]?.icon, type: leadFields[c][f]?.type };
    });
  });
  return returnKey;
}

class LeadInformation extends Component {
  state = {};
  componentDidMount = () => {
    if (this.props.save) this.props.save();
    if (this.props.campaign?.required?.length <= 0) {
      this.updateRequired(
        [{ field: 'first_name' }, { field: 'last_name' }, { field: 'email' }, { field: 'phone' }],
        true
      );
    }
  };
  updateRequired = (value, save) => {
    let required = this.props.campaign.required ? [...this.props.campaign.required] : [];
    required = value;
    this.props.update('required', required, save || false);
  };
  checkboxToggle = (checked, r) => {
    let matching = this.props.campaign.required.find(f => {
      return f.field == r.field;
    });
    matching.required = checked;

    this.updateRequired(this.props.campaign.required, true);
  };
  updateValues = (field, values) => {
    const required = (this.props.campaign.required || []).map(r => {
      if (r.field === field) r.values = values;
      return r;
    });
    this.props.update('required', required);
  };
  updateCustomLabel = i => {
    const custom_fields = this.props.campaign.custom_fields || [];
    custom_fields[i].label = this.state?.[`custom_${i}`];
    custom_fields[i].type = this.state?.[`custom_type_${i}`];
    this.props.update('custom_fields', custom_fields);
  };
  handleSelect = item => {
    let foundItem = null;
    Object.keys(leadFields).forEach(c => {
      Object.keys(leadFields[c]).forEach(f => {
        if (leadFields[c][f].field === item) foundItem = leadFields[c][f];
      });
    });
    if (foundItem !== null) {
      const required = this.props.campaign.required ? [...this.props.campaign.required] : [];
      required.push(foundItem);
      this.props.update('required', required);
    }
  };
  handleCreateCustomButton = () => {
    const custom_fields = this.props.campaign.custom_fields || [];
    custom_fields.push({
      label: 'New',
      type: 'url'
    });
    this.props.update('custom_fields', custom_fields);
  };
  handleUpdateCustomButton = i => {
    this.updateCustomLabel(i);
    this.setState({ [`custom_editing_${i}`]: false });
  };
  handleCancelCustomButton = i => {
    this.setState({ [`custom_editing_${i}`]: false });
  };
  handleEditCustomButton = i => {
    this.setState({ [`custom_editing_${i}`]: true });
  };
  removeField = ndx => {
    let required = this.props.campaign.required ? [...this.props.campaign.required] : [];
    required.splice(ndx, 1);
    this.props.update('required', required);
  };
  removeCustomField = i => {
    const custom_fields = this.props.campaign.custom_fields || [];
    custom_fields.splice(i, 1);
    this.props.update('custom_fields', custom_fields);
  };
  render() {
    const { campaign, actions, fromReview, isLocked } = this.props;
    const required = campaign.required || [];
    const custom_fields = campaign.custom_fields || [];
    const numRequired = campaign.required?.filter(({ required }) => required)?.length;

    const names = required.map(r => r.field);
    const isInvalid =
      fromReview &&
      !names.includes('mobile') &&
      !names.includes('email') &&
      !names.includes('phone');
    return (
      <Card id="wizard-info" className="wz-card">
        <div className="wz-container sx-form">
          {numRequired >= 4 && (
            <Guide
              type="alert"
              dismissable={false}
              id="maximum-required"
              header="Maximum required reached"
              message="You've reached the maximum of four required fields."
            />
          )}
          {/* HEADER */}

          <div className="wz-card-header">
            <h3>What info should reps collect?</h3>
            <h5>Select at least 1 point of contact (email or phone number)</h5>
          </div>
          {/* CONTENT */}
          <div className="info-content">
            <Select
              disabled={isLocked}
              mode="multiple"
              placeholder="Search lead fields..."
              dropdownClassName="sx-dropdown"
              className={isInvalid ? 'invalid' : ''}
              showArrow={false}
              value={[]}
              onSelect={this.handleSelect}
              style={{ width: '100%' }}
              icon=<IconSearch />
            >
              {Object.keys(leadFields).map(c => (
                <Select.OptGroup key={c} label={c}>
                  {Object.keys(leadFields[c]).map(f => (
                    <Select.Option
                      disabled={
                        required.find(r => r.field === leadFields[c][f].field) || isLocked
                          ? true
                          : false
                      }
                      key={leadFields[c][f].field}
                      value={leadFields[c][f].field}
                    >
                      {f}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>

            <div className="info-fields">
              {required.map((r, i) => {
                const { panelName, icon, type } = getKeyByValue(r.field);
                const valuesInvalid =
                  fromReview && r.type === 'Multiple' && (r.values || []).length <= 0;
                return (
                  <div className="req-container" key={'req-f-' + i}>
                    <div className="info-req">
                      <div className="left-side">
                        <div className="field-icon-container">
                          <div className="field-icon">{icon}</div>
                        </div>
                        <div>
                          <div className="field-name">
                            {panelName}
                            {r.type === 'Multiple' && ` (${r.values.length})`}
                          </div>
                          <div className="field-type">{type || 'Short text'}</div>
                        </div>
                      </div>
                      {isLocked ? (
                        <Locked isLocked={isLocked} />
                      ) : (
                        <div className="right-side">
                          <Checkbox
                            onChange={e => {
                              if (numRequired >= 4 && e.target.checked) return;
                              this.checkboxToggle(e.target.checked, r);
                            }}
                            checked={r.required}
                            reverse={true}
                            fontMinor={true}
                          >
                            Required
                          </Checkbox>
                          <div className="remove" onClick={() => this.removeField(i)}>
                            <IconX />
                          </div>
                        </div>
                      )}
                    </div>
                    {r.type === 'Multiple' && (
                      <Select
                        mode="tags"
                        disabled={isLocked}
                        placeholder={`Select ${panelName}...`}
                        dropdownClassName="sx-dropdown"
                        className={valuesInvalid ? 'invalid' : ''}
                        value={r.values || undefined}
                        onChange={value => this.updateValues(r.field, value)}
                      >
                        {(pickerFields[r.field] || []).map((f, i) => (
                          <Select.Option key={i.toString(36) + i} value={f}>
                            {f}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="custom-fields">
              <h3>Custom Fields</h3>
              {custom_fields.map((r, i) => {
                if (this.state?.[`custom_editing_${i}`]) {
                  return (
                    <div className="custom-editing" key={`custom_edit_${i}`}>
                      <div className="custom-editing-top">
                        <Input
                          defaultValue={r.label}
                          onChange={v => {
                            this.setState({ [`custom_${i}`]: v.target.value });
                          }}
                        />
                        <CustomFieldSelect
                          key={i}
                          value={this.state[`custom_type_${i}`]}
                          onChange={type => {
                            this.setState({ [`custom_type_${i}`]: type });
                          }}
                        />
                      </div>
                      <div className="custom-editing-buttons">
                        <Button
                          className="custom-update-button"
                          onClick={() => {
                            this.handleUpdateCustomButton(i);
                          }}
                        >
                          Update
                        </Button>
                        <Button
                          className="custom-cancel-button"
                          onClick={() => {
                            this.handleCancelCustomButton(i);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  );
                } else {
                  const option = customOptions.find(c => c.value === r.type);
                  return (
                    <div className="custom-req" key={`custom_nonedit_${i}`}>
                      <div className="custom-req-left">
                        <div className="cr-field">{r.label} </div>
                        <div className="cr-type">
                          <FontAwesomeIcon icon={option.icon} /> {option?.name}
                        </div>
                      </div>

                      <div>
                        <Button
                          className="custom-button"
                          onClick={() => {
                            this.setState({ [`custom_type_${i}`]: r.type });
                            this.handleEditCustomButton(i);
                          }}
                        >
                          Edit
                        </Button>
                        <span className="remove" onClick={() => this.removeCustomField(i)}>
                          <IconX />
                        </span>
                      </div>
                    </div>
                  );
                }
              })}
              <Button className="create-custom" onClick={this.handleCreateCustomButton}>
                + Create custom field
              </Button>
            </div>
          </div>
        </div>

        {/* ACTIONS */}
        {actions}
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(LeadInformation));
export { LeadInformation };
