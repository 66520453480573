import React, { Component } from 'react';
import { Modal, Button } from '_seriph';
import './ThresholdModal.scss';

export default class ThresholdModal extends Component {
  state = {
    visible: true
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    return (
      <Modal
        wrapClassName="threshold-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={true}
        width={456}
      >
        <Modal.Close onClick={this.hideModal} />
        <h4>Account Threshold</h4>
        <p>
          Your account threshold is a fixed amount. Once your balance reaches or exceeds your
          threshold ($1,000), your account will be charged.
        </p>

        <div className="bold">
          <b>No inital payment to get started</b>
        </div>
        <p>
          We do not require payment to launch campaigns but we do require a payment method applied
          to your account. Your account will be billed everytime the threshold amount of $1,000 is
          reached.
        </p>

        <Button type="primary" onClick={this.hideModal}>
          Close
        </Button>
      </Modal>
    );
  }
}
