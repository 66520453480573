import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Avatar from '_styleguide/Avatar/Avatar';
import { notification } from 'antd';
import { Modal, Button, message } from '_seriph';
import { getFromHopper } from '_graphql/mutations/lead';
import { getErrors } from '_assets/js/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Selection } from '_seriph';
import {
  faInfoCircle,
  faCheck,
  faUser,
  faFileCsv,
  faBuilding
} from '@fortawesome/free-solid-svg-icons';
import UploadLeads from 'App/Company/Leads/_modals/UploadLeads/UploadLeads';
import {
  CAMPAIGN_TYPE_QUALIFY,
  CAMPAIGN_TYPE_LEADGEN,
  LEAD_UPLOAD_TYPE_SINGLE,
  LEAD_UPLOAD_TYPE_MULTI
} from '_constants/campaigns';
import './CreateLead.scss';
import classNames from 'classnames';
import { getUser } from '_assets/js/helpers';

class CreateLead extends Component {
  state = {
    visible: true,
    campaign: undefined,
    saving: false,
    page: 'select',
    qualifyUserMode: false,
    pullLeadQty: 1
  };
  createLead = (campaign, pullLeadQty) => {
    if (campaign.objective === CAMPAIGN_TYPE_LEADGEN) {
      if (this.state.leadUploadType === LEAD_UPLOAD_TYPE_SINGLE) {
        this.props.selectLead('new', campaign.id);
        this.hideModal();
      } else {
        this.setState({
          page: 'upload'
        });
      }
    } else if (campaign.objective === CAMPAIGN_TYPE_QUALIFY) {
      if (this.state.qualifyUserMode) {
        this.props.selectLead('newQualified', campaign.id);
        this.hideModal();
      } else {
        this.getFromHopper(campaign, pullLeadQty);
      }
    }
  };
  getFromHopper = (campaign, pullLeadQty) => {
    this.setState({ saving: true });
    const isMenuCreate = this.props.menuCreate ? true : false;
    this.props.client
      .mutate({
        variables: { qty: pullLeadQty, campaign_id: campaign.id },
        mutation: getFromHopper
      })
      .then(result => {
        if (result?.data?.getFromHopper?.leads) {
          const [{ lead_id, work_started }] = result.data.getFromHopper.leads;
          if (work_started) {
            notification.open({
              message: (
                <div>
                  <FontAwesomeIcon icon={faInfoCircle} />
                  This lead was previously worked on
                </div>
              ),
              description:
                'Please continue work where the previous sales rep left off.  Make sure to review previous activity, emails, and notes prior to reaching out.',
              duration: 20
            });
          } else {
            message.success(
              `${result.data.getFromHopper.leads.length} Lead(s) started for ${campaign.name}`
            );
          }
          this.hideModal();
          if (this.props.selectLead && this.props.reload) {
            this.props.reload();
            this.props.selectLead(lead_id);
          } else {
            this.props.history.push(`/leads/${lead_id}`);
            if (isMenuCreate) this.props.history.go();
          }
        } else {
          throw new Error('Error pulling in lead, try again');
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Error pulling in lead, try again', 8);
        this.setState({ saving: false });
      });
  };
  hideModal = () => {
    this.setState({ visible: false, page: 'select' });
  };
  companyLogo = campaign => {
    if (campaign.id === this.state.campaign?.id)
      return <FontAwesomeIcon className="btnx-left" icon={faCheck} />;
    return <Avatar size="tiny" picture={campaign.company.logo || null} />;
  };
  getButton = () => {
    const { campaign, leadUploadType, pullLeadQty, saving, qualifyUserMode } = this.state;
    let buttonText = 'Continue';
    let disabled = !campaign;
    if (campaign) {
      if (campaign.objective === CAMPAIGN_TYPE_QUALIFY) {
        if (!qualifyUserMode) {
          buttonText = 'Pull new lead';
        } else {
          buttonText = 'Provide warm lead';
        }
      } else {
        buttonText = `Create new lead${leadUploadType === LEAD_UPLOAD_TYPE_MULTI ? 's' : ''}`;
        disabled = !leadUploadType;
      }
    }
    return (
      <Button
        disabled={disabled}
        loading={saving}
        type="primary"
        size="large"
        onClick={() => this.createLead(campaign, pullLeadQty)}
      >
        {buttonText}
      </Button>
    );
  };
  render() {
    const {
      campaign: activeCampaign,
      pullLeadQty,
      leadUploadType,
      visible,
      page,
      qualifyUserMode
    } = this.state;
    const { campaigns, client } = this.props;
    const user = getUser();

    return page === 'upload' ? (
      <UploadLeads campaign={activeCampaign} client={client} afterClose={this.props.removeModal} />
    ) : (
      <Modal
        wrapClassName="create-lead-modal"
        visible={visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        width={540}
        closable={false}
        maskClosable={false}
      >
        <Modal.Close onClick={this.hideModal} />
        <div className="upload-header">
          <h3>Select a campaign</h3>
        </div>
        <div className="campaign-list">
          {campaigns
            .filter(
              campaign =>
                campaign.status === 'active' &&
                (campaign.agents || []).filter(a => a.active === true && a.agent_id === user.id)
                  .length > 0
            )
            .map(campaign => (
              <div
                className={classNames('campaign-list-item', {
                  active: campaign.id === activeCampaign?.id
                })}
                onClick={() => {
                  this.setState({ campaign });
                }}
                key={campaign.id}
              >
                <div
                  className={classNames('campaign-image', {
                    active: campaign.id === activeCampaign?.id
                  })}
                >
                  {this.companyLogo(campaign)}
                </div>
                <div className="campaign-info">
                  <div className="campaign-name">{campaign.name}</div>
                  <div className="paragraph3">
                    {campaign.objective === CAMPAIGN_TYPE_QUALIFY && (
                      <div className="campaign-type">Qualify cold leads</div>
                    )}
                    {campaign.objective === CAMPAIGN_TYPE_LEADGEN && (
                      <div className="campaign-type">Generate Leads</div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
        {activeCampaign && activeCampaign.objective === CAMPAIGN_TYPE_LEADGEN && (
          <div className="lead-upload">
            <p>How many leads to upload?</p>
            <div className="lead-upload-actions">
              <div
                className={classNames('lead-type-container', {
                  active: leadUploadType === LEAD_UPLOAD_TYPE_SINGLE
                })}
                onClick={() => this.setState({ leadUploadType: LEAD_UPLOAD_TYPE_SINGLE })}
              >
                <div className="">
                  <div>
                    <div className="upload-icon">
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                  </div>
                  <div>Single lead</div>
                </div>
              </div>
              <div
                className={classNames('lead-type-container', {
                  active: leadUploadType === LEAD_UPLOAD_TYPE_MULTI
                })}
                onClick={() => this.setState({ leadUploadType: LEAD_UPLOAD_TYPE_MULTI })}
              >
                <div className="">
                  <div>
                    <div className="upload-icon">
                      <FontAwesomeIcon icon={faFileCsv} />
                    </div>
                  </div>
                  <div>Multiple leads</div>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeCampaign && activeCampaign.objective === CAMPAIGN_TYPE_QUALIFY && (
          <div className="lead-upload">
            <p>Add from company-provided list (like normal), or you provide?</p>
            <div className="lead-upload-actions">
              <div
                className={classNames('lead-type-container', {
                  active: qualifyUserMode === false
                })}
                onClick={() => this.setState({ qualifyUserMode: false })}
              >
                <div>
                  <div>
                    <div className="upload-icon">
                      <FontAwesomeIcon icon={faBuilding} />
                    </div>
                  </div>
                  <div>Normal</div>
                </div>
              </div>
              <div
                className={classNames('lead-type-container', {
                  active: qualifyUserMode === true
                })}
                onClick={() => this.setState({ qualifyUserMode: true })}
              >
                <div>
                  <div>
                    <div className="upload-icon">
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                  </div>
                  <div>You provide</div>
                </div>
              </div>
            </div>
            {!qualifyUserMode && (
              <>
                <p>How many leads would you like?</p>
                <div>
                  <Selection
                    disabled={qualifyUserMode}
                    values={[
                      {
                        title: '1',
                        value: 1
                      },
                      {
                        title: '5',
                        value: 5
                      },
                      {
                        title: '10',
                        value: 10
                      },
                      {
                        title: '20',
                        value: 20
                      }
                    ]}
                    value={pullLeadQty}
                    onChange={val => this.setState({ pullLeadQty: val })}
                    full={true}
                  />
                </div>
              </>
            )}
          </div>
        )}

        <div className="create-actions">{this.getButton()}</div>
      </Modal>
    );
  }
}

export default withRouter(CreateLead);
