import React, { Component } from 'react';
import { Modal } from 'antd';
import Instructions from 'App/Company/Leads/Lead/Instructions/Instructions';
import './InstructionsModal.scss';

export default class InstructionsModal extends Component {
  state = {
    visible: true
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { campaign } = this.props;

    return (
      <Modal
        wrapClassName="instructions-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        width="100%"
        closable={true}
        maskClosable={false}
      >
        <Instructions campaign={campaign} />
      </Modal>
    );
  }
}
