import React, { Component } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import Invited from 'App/Home/Invited/Invited';
import InviteCompany from 'App/Home/InviteCompany/InviteCompany';
import InviteTeamMember from 'App/Home/InviteTeamMember/InviteTeamMember';
import Login from 'App/Home/Login/Login';
import Forgot from 'App/Home/Forgot/Forgot';
import Reset from 'App/Home/Reset/Reset';
import Missing from 'App/Home/Missing/Missing';
import Join from 'App/Home/Join/Join';
import CompanyOnboarding from 'App/Home/CompanyOnboarding/CompanyOnboarding';
import RepOnboarding from 'App/Home/RepOnboarding/RepOnboarding';
import { PublicRoutes } from '_shared/PublicRoutes/PublicRoutes';
import './Home.scss';

class Home extends Component {
  render() {
    return (
      <div className="main-bg">
        <Switch>
          <Route
            exact
            path="/"
            render={i => <Login match={i.match} client={this.props.client} />}
          />
          <Route
            exact
            path="/invitation/:id"
            render={i => (
              <Invited match={i.match} client={this.props.client} responseId={i.match.params.id} />
            )}
          />
          <Route
            exact
            path="/invite/:id"
            render={i => (
              <InviteCompany
                match={i.match}
                client={this.props.client}
                responseId={i.match.params.id}
              />
            )}
          />
          <Route
            exact
            path="/join/:id"
            render={i => (
              <InviteTeamMember
                match={i.match}
                client={this.props.client}
                responseId={i.match.params.id}
                type="company"
              />
            )}
          />
          <Route
            exact
            path="/teams/:id"
            render={i => (
              <InviteTeamMember
                match={i.match}
                client={this.props.client}
                responseId={i.match.params.id}
                type="teamrep"
              />
            )}
          />
          <Route
              exact
              path="/start/:tab(type|create|verify|company|personal|completed)?/:id?"
              render={i => <Join match={i.match} client={this.props.client} />}
            />
          { true && (
            <Route
              exact
              path="/onboarding/company"
              render={i => <CompanyOnboarding match={i.match} client={this.props.client} />}
            />
          )}
          { false && (
            <Route
              exact
              path="/onboarding/rep"
              render={i => <RepOnboarding match={i.match} client={this.props.client} />}
            />
          )}
          <Route
            exact
            path="/forgot"
            render={i => <Forgot match={i.match} client={this.props.client} />}
          />
          <Route
            exact
            path="/reset"
            key="reset"
            hash="false"
            render={i => <Reset match={i.match} client={this.props.client} />}
          />

          {PublicRoutes.Public(this.props)}
          <Route component={Missing} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(Home);
