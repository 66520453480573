import React from 'react';
import { message, Button } from '_seriph';

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const validate = sequence => {
  let issues = [];

  let missingTouchpoint = false;
  // let missingTemplate = false;
  let lastDayWait = false;
  let startDayWait = false;
  let hasDuplicates = false;
  let waitDays = false;
  sequence.days.forEach(d => {
    const dups = new Set();
    d.touchpoints.forEach((t, i) => {
      if (!t.type) missingTouchpoint = true;
      // if (t.type && t.type !== 'wait' && !t.touchpoint_id) missingTemplate = true;
      if (d.touchpoints.length === i + 1 && t.type === 'wait' && d.touchpoints.length !== 1)
        lastDayWait = true;
      if (i === 0 && t.type === 'wait' && d.touchpoints.length !== 1) startDayWait = true;
      if (t.type !== 'wait' && t.touchpoint_id && dups.has(t.touchpoint_id)) hasDuplicates = true;
      if (t.type === 'wait' && t.duration_type === 'Days' && d.touchpoints.length > 1) {
        waitDays = true;
      }
      dups.add(t.touchpoint_id);
    });
  });

  if (missingTouchpoint) issues.push('One of your days has an empty touchpoint type');
  // if (missingTemplate) issues.push('One of your touchpoints does not have a template selected');
  if (lastDayWait) issues.push("Can't end a day with a Wait touchpoint");
  if (startDayWait) issues.push("Can't start a day with a Wait touchpoint");
  if (hasDuplicates) issues.push("Can't have two of the same touchpoint a day");
  if (waitDays) issues.push("A wait that is a day or longer must be on it's own day");

  const isValid = issues.length === 0;
  if (!isValid) {
    message.error(
      <div style={{ textAlign: 'left' }}>
        {issues.map((s, i) => (
          <div key={'issue' + i}>{s}</div>
        ))}
      </div>,
      5
    );
  }
  return isValid;
};

export const minutes = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
export const hours = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24
];
export const days = [1, 2, 3, 4, 5, 6, 7];
export const timeTypes = ['Minutes', 'Hours', 'Days'];

export const TimeMenu = props => {
  return (
    <div className="time-menu">
      <div className="selectors">
        <div className="options">
          {timeTypes.map((t, i) => (
            <div
              className={props.step.duration_type === t ? 'active' : ''}
              key={'tt-' + i}
              onClick={() => props.updateType(t)}
            >
              {t}
            </div>
          ))}
        </div>
        <div className="numbers">
          {props.step.duration_type === 'Minutes'
            ? minutes.map((t, i) => (
                <div
                  className={props.step.duration === t ? 'active' : ''}
                  key={'tt-' + i}
                  onClick={() => props.updateTime(t)}
                >
                  {t}
                </div>
              ))
            : null}
          {props.step.duration_type === 'Hours'
            ? hours.map((t, i) => (
                <div
                  className={props.step.duration === t ? 'active' : ''}
                  key={'th-' + i}
                  onClick={() => props.updateTime(t)}
                >
                  {t}
                </div>
              ))
            : null}
          {props.step.duration_type === 'Days'
            ? days.map((t, i) => (
                <div
                  className={props.step.duration === t ? 'active' : ''}
                  key={'td-' + i}
                  onClick={() => props.updateTime(t)}
                >
                  {t}
                </div>
              ))
            : null}
          <span>&nbsp;</span>
        </div>
      </div>
      <div className="time-actions">
        <Button type="primary" onClick={props.hide}>
          Ok
        </Button>
      </div>
    </div>
  );
};
