export const validateQualify = (lead, questions, interestLevel) => {
  const answers = lead.answers || [];
  let incorrect = [];

  /* VALIDATE NO-RESPONSE */
  if (interestLevel === 'invalid') {
    return [];
  } else if (interestLevel === 'no-response') {
    // if (lead.hasResponded) incorrect.push('Can\'t submit no response, lead has responded');
    if (lead.emailAttempts < 0) incorrect.push('Must attempt to reach out with at least 3 emails');
    if (lead.callAttempts < 0) incorrect.push('Must attempt to reach out with at least 3 calls');
  } else {
    /* VALIDATE QUESTIONS HAVE BEEN ANSWERED */
    if (interestLevel === 'interested') {
      (questions || []).forEach(q => {
        const answer = answers.find(a => a.question === q.question);
        if (!answer) {
          incorrect.push('Question: ' + q.question);
        } else if (!answer.answer && !answer.answers.length > 0) {
          incorrect.push('Question: ' + q.question);
        }
      });
    }

    if (!lead.hasResponded) {
      incorrect.push("Lead hasn't responded to Email or Call yet");
    }
  }

  return [];
};
