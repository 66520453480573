import React, { Component } from 'react';
import { Modal, Button, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { createCompany, declineInvitation } from '../../../_graphql/mutations';
import moment from 'moment';
import './ReviewCandidate.scss';

export default class ReviewCandidate extends Component {
  state = {
    visible: true
  };
  createCompany = () => {
    this.setState({ loading: true });
    this.props.client
      .mutate({
        variables: { response_id: this.props.candidate.response_id },
        mutation: createCompany
      })
      .then(result => {
        if (result && result.data && result.data.createCompany) {
          message.success('Company created');
          this.hideModal();
        } else {
          throw new Error('Could not create company, try again');
        }
      })
      .catch(err => {
        message.error(err.message);
        this.setState({ loading: false });
      });
  };
  declineInvitation = () => {
    this.setState({ loading: true });
    this.props.client
      .mutate({
        variables: { response_id: this.props.candidate.response_id },
        mutation: declineInvitation
      })
      .then(result => {
        if (result && result.data && result.data.declineInvitation) {
          message.success('Application passed');
          this.hideModal();
          this.props.reload();
        } else {
          throw new Error('Could not pass application, try again');
        }
      })
      .catch(err => {
        message.error(err.message);
        this.setState({ loading: false });
      });
  };
  hideModal = () => this.setState({ visible: false });
  getAnswers = (answer, type) => {
    if (type === 'date') return moment(answer).format('MMMM Do YYYY');
    if (type === 'file_url')
      return (
        <a href={answer} rel="noopener noreferrer" target="_blank">
          View File
        </a>
      );
    if (type === 'url')
      return (
        <a href={answer} rel="noopener noreferrer" target="_blank">
          View Link
        </a>
      );
    return answer;
  };
  render() {
    const { candidate } = this.props;

    const skip = [];

    return (
      <Modal
        wrapClassName="review-candidate-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        style={{ top: 60 }}
        width={800}
        closable={false}
        maskClosable={true}
      >
        {candidate ? (
          <React.Fragment>
            <div className="ra-title">
              {candidate.first_name + ' ' + candidate.last_name} ({candidate.status})
              <div className="ra-actions">
                {candidate.status !== 'Passed' && (
                  <Button
                    id="pass-cf"
                    className="btnx btnx-danger"
                    onClick={this.declineInvitation}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} />
                    Pass
                  </Button>
                )}
                <Button id="save-cf" className="btnx btnx-primary" onClick={this.createCompany}>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Create {candidate.company}
                </Button>
              </div>
            </div>

            <div className="ra-body">
              <div className="ra-columns">
                {(candidate.answers || []).map((a, i) =>
                  !skip.includes(a.answer_id) && i < 9 ? (
                    <div className="ra-message" key={'rc-' + i}>
                      <h3 className="ra-review">
                        {i + 1}. {a.title}
                      </h3>
                      <p>{this.getAnswers(a.answer, a.type)}</p>
                    </div>
                  ) : null
                )}
              </div>
              <div className="ra-columns">
                {(candidate.answers || []).map((a, i) =>
                  !skip.includes(a.answer_id) && i >= 9 ? (
                    <div className="ra-message" key={'rc-' + i}>
                      <h3 className="ra-review">
                        {i + 1}. {a.title}
                      </h3>
                      <p>{this.getAnswers(a.answer, a.type)}</p>
                    </div>
                  ) : null
                )}
              </div>
            </div>
          </React.Fragment>
        ) : null}
      </Modal>
    );
  }
}
