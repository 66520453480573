import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Avatar, Select, Tooltip, Tag } from '_seriph';
import moment from 'moment';

export const getTeamColumns = (company, user, updatePermissionType, confirmRemoveMember) => {
  return [
    {
      title: 'Name',
      dataIndex: 'display_name',
      key: 'display_name',
      render: (column, data) => {
        return (
          <div className="emp-info">
            <Avatar type="company" picture={data.picture} size="small" />
            <div>
              <h4>{column}</h4>
              <p className="joined-date">Joined {moment(data?.createdAt * 1).fromNow()}</p>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Email',
      dataIndex: 'user.email',
      key: 'user.email'
    },
    {
      title: '',
      dataIndex: 'user.type',
      key: 'user.type',
      render: (column, data) => {
        const isOwner = company && company.owner_id === data.id ? true : false;
        if (isOwner) return <Tag type="info">Owner</Tag>;
        return data.permission_type === 'Removed' ? <Tag type="danger">Removed</Tag> : <Tag type="primary">Active</Tag>;
      }
    },
    {
      title: 'Role',
      dataIndex: 'permission_type',
      key: 'permission_type',
      align: 'right',
      render: (permission_type, data) => {
        const isOwner = company && company.owner_id === data.id ? true : false;
        return (
          <Select
            disabled={isOwner || user.id === data.id}
            value={isOwner ? 'Owner' : permission_type}
            onChange={val => updatePermissionType('employee', data.id, val)}
          >
            {isOwner && <Select.Option value="Owner">Owner/Admin</Select.Option>}
            <Select.Option value="Admin">Admin</Select.Option>
            <Select.Option value="Manager">Manager</Select.Option>
            <Select.Option value="Member">Member</Select.Option>
            {permission_type === 'Removed' && (
              <Select.Option value="Removed">Removed</Select.Option>
            )}
          </Select>
        );
      }
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 40,
      align: 'right',
      render: (id, data) => {
        const isOwner = company && company.owner_id === data.id ? true : false;
        return !isOwner && data.permission_type !== 'Removed' && user.id !== id ? (
          <div className="emp-delete">
            <Tooltip title="Remove">
              <span onClick={() => confirmRemoveMember('employee', id)}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </span>
            </Tooltip>
          </div>
        ) : null;
      }
    }
  ];
};
