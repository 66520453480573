import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddBox from '_shared/AddBox/AddBox';
import AccomplishmentsEdit from './AccomplishmentsEdit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Card, Button } from '_seriph';
import './Accomplishments.scss';

class Accomplishments extends Component {
  state = { editor: false, selected: null, description: '' };
  updateDescription = e => this.setState({ description: e.target.value });
  openEditor = id => {
    if (id) {
      const accomplishment = this.props.agent.accomplishments.find(a => a.id === id);
      this.setState({
        editor: true,
        selected: accomplishment.id,
        description: accomplishment.description
      });
    } else {
      this.setState({ editor: true, selected: null, description: '' });
    }
  };
  closeEditor = () => this.setState({ editor: false, selected: null, description: '' });
  render() {
    const { agent, isEditable } = this.props;
    const accomplishments = agent.accomplishments || [];
    const addBox = isEditable && accomplishments.length === 0;
    return (
      <Card id="agent-profile-accomplishments">
        {isEditable ? (
          <AccomplishmentsEdit
            description={this.state.description}
            updateDescription={this.updateDescription}
            visible={this.state.editor}
            close={this.closeEditor}
            reload={this.props.reload}
            editing={this.state.selected}
          />
        ) : null}
        {!addBox && <h2>Accomplishments</h2>}
        {!addBox && (
          <React.Fragment>
            {accomplishments.map(a => (
              <p className="inline-item" key={a.id}>
                {a.description}
                {isEditable && (
                  <Button
                    type="secondary"
                    className="btnx-edit"
                    size="tiny"
                    icon="left"
                    onClick={() => this.openEditor(a.id)}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                    Edit
                  </Button>
                )}
              </p>
            ))}
          </React.Fragment>
        )}
        {isEditable && (
          <Button
            type="secondary"
            className="btnx-edit"
            size="tiny"
            icon="left"
            onClick={() => this.openEditor()}
          >
            <FontAwesomeIcon icon={faPlus} />
            Accomplishment
          </Button>
        )}
        {accomplishments.length === 0 && !isEditable ? (
          <i className="none-yet">No accomplishments listed</i>
        ) : null}
        {addBox ? (
          <AddBox action={this.openEditor} title="Accomplishments" percent={this.props.value} />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user, ...state.agent };
};

export default connect(mapStateToProps)(Accomplishments);
