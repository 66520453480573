import React, { Component } from 'react';
import { Modal, Button, message, Input, Alert, Checkbox } from '_seriph';
import { isEmail, getErrors } from '_assets/js/helpers';
import { inviteTeamRep } from '_graphql/mutations/company';
import './InviteRepMember.scss';

export default class InviteRepMember extends Component {
  state = {
    visible: true,
    loading: false,
    invalid: false,
    email: null,
    dropdownVisible: false,
    options: [],
    campaigns: {}
  };
  inviteTeamRep = () => {
    const { email } = this.state;
    if (!isEmail(email)) {
      this.setState({ invalid: true });
      return;
    }
    this.setState({ loading: true });
    this.props.client
      .mutate({
        variables: {
          email: email,
          campaigns_auto_add: Object.keys(this.state.campaigns).filter(c => this.state.campaigns[c])
        },
        mutation: inviteTeamRep
      })
      .then(result => {
        if (result && result.data && result.data.inviteTeamRep) {
          message.success('Invite sent to ' + email);
          if (this.props.reload) this.props.reload();
          this.hideModal();
        } else {
          throw new Error('Could not send invite, try again');
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        message.error(getErrors(err) || 'Could not send invite, try again');
      });
  };
  hideModal = () => this.setState({ visible: false });
  selectAllCampaigns = () => {
    const allAlreadySelected = this.props.campaigns.every(c => this.state.campaigns[c.id] === true);

    if (allAlreadySelected) {
      this.setState({ campaigns: {} });
    } else {
      let campaigns = {};
      for (let { id } of this.props.campaigns) {
        campaigns[id] = true;
      }
      this.setState({ campaigns });
    }
  };
  selectOneCampaign = (id, checked) => {
    !checked
      ? this.setState({ campaigns: { ...this.state.campaigns, [id]: false } })
      : this.setState({ campaigns: { ...this.state.campaigns, [id]: true } });
  };
  render() {
    const { email, invalid } = this.state;

    return (
      <Modal
        wrapClassName="invite-rep-member-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        style={{ top: 60 }}
        width={480}
        closable={false}
        maskClosable={false}
      >
        <Modal.Close onClick={this.hideModal} />

        <div className="itm-title">
          <h3 className="ra-title">Invite team</h3>
          <p>
            Your account has <strong>{this.props.numSeats} more</strong> internal seat available.
          </p>
        </div>

        <div className="itm-form">
          <Input
            onChange={e => this.setState({ email: e.target.value })}
            placeholder="Email to invite..."
            error={!isEmail(email) && invalid ? true : false}
            hint={!isEmail(email) && invalid ? 'Invalid email address' : null}
            className="email-input"
          />
        </div>
        <div className="campaign-list">
          <div className="filter-options">
            <Checkbox onChange={this.selectAllCampaigns}>All campaigns</Checkbox>
            {this.props.campaigns.filter(c => c.status === 'active' || c.status === 'inactive').map(c => (
              <Checkbox
                key={`cs-${c.id}`}
                checked={this.state.campaigns[c.id]}
                onChange={e => this.selectOneCampaign(c.id, e.target.checked)}
              >
                {c.name}
              </Checkbox>
            ))}
          </div>
        </div>
        <div>
          <Alert type="info" variant="line">
            The rep will be added to the campaigns upon joining.
          </Alert>
        </div>

        <div className="itm-actions">
          <Button type="secondary" onClick={this.hideModal}>
            Cancel
          </Button>
          <Button
            disabled={!email}
            onClick={this.inviteTeamRep}
            loading={this.state.loading}
            type="primary"
          >
            Invite Rep
          </Button>
        </div>
      </Modal>
    );
  }
}
