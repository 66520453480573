import React, { Component } from 'react';
import { message } from 'antd';
import axios from 'axios';
import { IconCreditCard, IconBuildingBank } from '@tabler/icons-react';
import { injectStripe, CardElement } from 'react-stripe-elements';
import { PlaidLink } from 'react-plaid-link';
import { createPlaid } from '_graphql/mutations/company';
import { Header, Alert, Button, Modal, Card, Loading } from '_seriph';
import './PaymentMethod.scss';

const confirm = Modal.confirm;

class PaymentMethod extends Component {
  state = {
    loading: true,
    saving: false,
    errored: false,
    customer: null,
    source: null,
    addCard: false,
    addBank: false,
    removingCard: false,
    plaidToken: null
  };
  submit = this.submit.bind(this);
  componentDidMount = () => {
    this.loadBanking();
    this.createLinkToken();
  };
  loadBanking = cardAdded => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/v1/stripe/company/customer`, {
        headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') }
      })
      .then(response => {
        if (cardAdded === true) {
          message.success('Card successfully added');
        }
        if (response && response.data && response.data.customer) {
          const customer = response.data.customer;
          const source =
            customer && customer.sources && customer.sources.data && customer.sources.data.length
              ? customer.sources.data[0]
              : null;
          this.setState({ customer, source, addCard: false, addBank: false });
        }
      })
      .catch(() => {
        message.error('Could not load information');
        this.setState({ errored: true });
      })
      .finally(() => this.setState({ loading: false, saving: false }));
  };
  async submit() {
    this.setState({ saving: true });
    let { token } = await this.props.stripe.createToken({
      name: this.props.myself.display_name || 'Name'
    });
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_PATH}/v1/stripe/company/paymentMethod`,
      headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') },
      data: token
    })
      .then(response => {
        if (!(response && response.data && response.data.customer)) {
          message.error('Could not save payment information, try again');
          this.setState({ saving: false });
        }
        setTimeout(() => this.loadBanking(true), 100);
      })
      .catch(() => {
        message.error('Could not save payment information, try again');
        this.setState({ saving: false });
      });
  }
  removeCard = () => {
    confirm({
      title: `Are you sure you want to remove this payment method?`,
      content:
        'This will not prevent you from completing an active campaign and returning your deposit, but this will prevent you from launching a new campaign.',
      onOk: () => {
        this.setState({ removingCard: true });
        axios({
          method: 'POST',
          url: `${process.env.REACT_APP_API_PATH}/v1/stripe/company/removeCard`,
          headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') },
          data: { id: this.state.source.id }
        })
          .then(response => {
            if (response && response.data && response.data.removed) {
              message.success(`You payment method has been removed.`);
              this.setState({ source: null });
            }
          })
          .catch(() => {
            message.error('Error removing payment method, try again');
          })
          .finally(() => this.setState({ removingCard: false }));
      }
    });
  };
  createLinkToken = async () => {
    let response = await fetch(`${process.env.REACT_APP_API_PATH}/plaid/token`, {
      headers: new Headers({
        Authorization: 'JWT ' + localStorage.getItem('JWT')
      })
    });
    const { link_token } = await response.json();
    if (link_token) this.setState({ plaidToken: link_token });
  };
  onSuccess = (token, data) => {
    const metadata = JSON.stringify(data);
    if (token && data) {
      this.props.client
        .mutate({ variables: { token, metadata }, mutation: createPlaid })
        .then(result => {
          if (result && result.data && result.data.createPlaid) {
            this.setState({ source: null }, () => {
              message.success('Bank account successfully added');
              setTimeout(this.loadBanking, 1000);
            });
          }
        });
    } else {
      message.error('There was an issue connecting your bank account, try again');
    }
  };
  render() {
    const { loading, addCard, saving, source, addBank, plaidToken } = this.state;

    const isBank = source && source.object === 'bank_account';

    return (
      <Card id="agent-settings-banking">
        <div className="settings-card">
          <Header size="4" type="sans">
            Payment Method
          </Header>
          <p className="seriph-subheader">Choose between card or bank account</p>
          { loading ? (
            <Loading />
          ) : (
            <div className="sources">
              {!source && !addCard && !addBank && (
                <Alert type="info">There is currently no payment method on file.</Alert>
              )}
              {source && !addCard && !addBank && (
                <div className="source">
                  {isBank ? <IconBuildingBank /> : <IconCreditCard />}
                  <div className="source-name">
                    <h3>
                      {source.brand}
                      &nbsp;{isBank ? `#${source.last4}` : `ending in ${source.last4}`}
                    </h3>
                    <div>
                      {isBank
                        ? source.bank_name
                        : `Expires ${source.exp_month + '/' + source.exp_year}`}
                    </div>
                  </div>
                </div>
              )}
              {addCard && (
                <div>
                  <CardElement />
                </div>
              )}
              {addBank && plaidToken && (
                <div className="add-bank">
                  <PlaidLink token={plaidToken} onSuccess={this.onSuccess}>
                    Connect a bank account
                  </PlaidLink>
                </div>
              )}
              <div className="manage-box">
                {!addCard && !addBank && !source && (
                  <Button onClick={() => this.setState({ addCard: true })} type="primary">
                    +&nbsp;&nbsp;Add Credit Card
                  </Button>
                )}
                {!addCard && !addBank && !source && (
                  <Button onClick={() => this.setState({ addBank: true })} type="primary">
                    +&nbsp;&nbsp;Add Bank Account
                  </Button>
                )}
                {source && !addCard && !addBank && (
                  <Button onClick={this.removeCard} loading={this.state.removingCard} type="primary">
                    Remove Payment Method
                  </Button>
                )}
                {(addCard || addBank) && (
                  <React.Fragment>
                    <Button
                      onClick={() => this.setState({ addCard: false, addBank: false })}
                      type="default"
                    >
                      Cancel
                    </Button>
                    {addCard && (
                      <Button loading={saving === true} onClick={this.submit} type="primary">
                        Save Credit Card
                      </Button>
                    )}
                    {addBank && (
                      <Button loading={saving === true} onClick={this.submitBank} type="primary">
                        Save Bank Account
                      </Button>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          )}
        </div>

      </Card>
    );
  }
}

export default injectStripe(PaymentMethod);
