import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Modal, Button, message } from '_seriph';
import { declineInvite } from 'Mutations/Notification/notification';
import { typeMap } from '_constants/leadFields';
import { getErrors } from '_assets/js/helpers';
import './CompanyHeader.scss';

const confirm = Modal.confirm;

export default class CompanyHeader extends Component {
  declineInvite = () => {
    confirm({
      title: `Are you sure you want to decline this invitation?`,
      content:
        'You will not be able to change your decision unless the company sends you another invite.',
      onOk: () => {
        this.props.client
          .mutate({
            variables: { id: this.props.notification },
            mutation: declineInvite
          })
          .then(result => {
            if (result && result.data && result.data.declineInvite) {
              this.props.loadNotifications();
              message.success(`Invitation was declined`);
              this.props.hideModal();
            }
          })
          .catch(err => message.error(getErrors(err) || 'Could not decline invite, try again'));
      }
    });
  };
  render() {
    const { campaign, company, isBelow } = this.props;

    // const isFull = campaign && campaign.agents_remaining > 0 ? false : true;
    const isFull = false;
    const isInvite =
      (this.props.invitation && campaign.invites.includes(this.props.agentId)) ||
      this.props.isFromDiscover;

    return (
      <div className={`overview-header ${isBelow ? 'scrolled' : ''}`}>
        <div className="oc-header">
          <div className="oc-header-left">
            <Link to={`/company/${company.id}`}>
              <Avatar size="small" picture={company.logo} />
            </Link>
            <div className="oc-header-match">{this.props.percentMatch || 0}% match</div>
          </div>
          <div className="oc-scroll">
            <div className="oc-types">
              <b>{company.name}</b>&nbsp;&nbsp;&middot;&nbsp;&nbsp;{typeMap[campaign.objective]}
            </div>
            <h3>{campaign.name}</h3>
          </div>
          <div className="oc-actions">
            {isInvite ? (
              <React.Fragment>
                <Button type="secondary" size="medium" onClick={this.declineInvite}>
                  Decline Invite
                </Button>
                <Button type="primary" size="medium" onClick={this.props.showJustTouchpointsModal}>
                  Accept Invite
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {!this.props.hasApplied && !this.props.isAlreadyIn ? (
                  <Button
                    disabled={this.props.preview}
                    type="primary"
                    size="medium"
                    onClick={() => this.props.showApplyModal(true)}
                  >
                    {isFull ? 'Join waitlist' : 'Apply'}
                  </Button>
                ) : (
                  <Button disabled={true} type="secondary" size="medium">
                    {this.props.isAlreadyIn ? 'Joined' : 'Applied'}
                  </Button>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}
