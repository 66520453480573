import React, { Component } from 'react';
import { Skeleton, Empty, Button, message, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faFileInvoice,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { companyCharges } from '_graphql/queries/stripe';
import { getErrors } from '_assets/js/helpers';
import { formatMoney } from '_assets/js/helpers';
import moment from 'moment';
import Card from '_styleguide/Card/Card';
import './Payments.scss';

class Payments extends Component {
  state = {
    loading: true,
    paging: false,
    hasMore: false,
    startId: null,
    payments: []
  };
  componentDidMount = () => {
    this.loadPayments();
  };
  loadPayments = pagination => {
    if (pagination) this.setState({ paging: true });
    this.props.client
      .query({
        variables: { company_id: this.props.myself.company, ...pagination },
        query: companyCharges
      })
      .then(result => {
        if (result && result.data && result.data.companyCharges) {
          const payments = result.data.companyCharges.data;
          this.setState({
            loading: false,
            paging: false,
            payments,
            hasMore:
              pagination && pagination.ending_before ? true : result.data.companyCharges.hasMore,
            startId: this.state.startId || (payments.length > 0 ? payments[0].id : null)
          });
        } else {
          throw new Error('Could not load payments, try again');
        }
      })
      .catch(err => {
        this.setState({ loading: false, paging: false });
        message.error(getErrors(err) || 'Could not load payments, try again');
      });
  };
  nextPage = () =>
    this.loadPayments({ starting_after: this.state.payments[this.state.payments.length - 1].id });
  previousPage = () => this.loadPayments({ ending_before: this.state.payments[0].id });
  render() {
    const { loading, payments, hasMore, startId, paging } = this.state;
    const hasPrevious = payments && payments.length > 0 && startId !== payments[0].id;
    return (
      <Card id="company-settings-payments">
        <div className="settings-card">
          <div className="settings-card-header">Payments</div>
          {!loading ? (
            <React.Fragment>
              <table className="history">
                <thead>
                  <tr className="charge title">
                    <th>Created</th>
                    <th>Description</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Actual Cost</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {payments.length > 0 &&
                    payments.map(p => (
                      <tr className="payment" key={p.id}>
                        <td>{moment(p.created * 1000).format('MMM D, YYYY, h:mma')}</td>
                        <td>
                          <i>{p.description}</i>
                        </td>
                        <td>
                          {p.succeeded && <span className="failed">{formatMoney(p.amount)}</span>}
                          {!p.succeeded && (
                            <span className="failed">
                              <i>{formatMoney(p.amount)}</i>
                            </span>
                          )}
                        </td>
                        <td>
                          {p.refunded && <span className="cost">Completed</span>}
                          {!p.refunded && p.succeeded && <span className="cost">In Progress</span>}
                          {!p.succeeded && (
                            <span className="disputed">
                              <i>Failed</i>
                            </span>
                          )}
                        </td>
                        <td>
                          {p.refunded && <span>{formatMoney(p.amount - p.amount_refunded)}</span>}
                          {!p.refunded && p.succeeded && <span>N/A</span>}
                          {!p.succeeded && <span>$0</span>}
                        </td>
                        <td>
                          {p.receipt_url ? (
                            <Tooltip
                              overlayClassName="sellx-tooltip sellx-tooltip-small"
                              title="View Receipt"
                            >
                              <a
                                href={p.receipt_url}
                                className="receipt"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FontAwesomeIcon icon={faFileInvoice} />
                              </a>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              overlayClassName="sellx-tooltip sellx-tooltip-small"
                              title="Receipt Pending"
                            >
                              <span className="receipt">
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </span>
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {(hasMore || hasPrevious) && (
                <div className="payment-actions">
                  <Button
                    disabled={!hasPrevious || paging}
                    onClick={this.previousPage}
                    className="btn btn-default"
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </Button>
                  <Button
                    disabled={!hasMore || paging}
                    onClick={this.nextPage}
                    className="btn btn-default"
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                </div>
              )}
              {payments.length <= 0 && <Empty description="You don't have any payments yet..." />}
            </React.Fragment>
          ) : (
            <Skeleton active paragraph={{ rows: 4 }} />
          )}
        </div>
      </Card>
    );
  }
}

export default Payments;
