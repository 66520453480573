import gql from 'graphql-tag';

export const getCampaignAgents = gql`
  query campaign($id: ID) {
    campaign(id: $id) {
      agents {
        id
        hired
        active
        agent {
          id
          about
          display_name
          picture
        }
      }
    }
  }
`;

export const getCampaign = gql`
  query campaign($id: ID) {
    campaign(id: $id) {
      id
      name
      objective
      description
      invites
      target_skills
      target_experience_text
      target_agent_count
      agents_remaining
      target_languages
      target_locations {
        name
      }
      budget_ppl
      budget_ppql
      budget_total
      budget_bonus
      budget_bonus_count
      customer_target
      customer_type
      customer_decision_maker
      customer_company_size
      customer_race
      customer_age
      customer_gender
      customer_education
      customer_locations {
        name
      }
      customer_industry
      customer_spend_start
      customer_spend_end
      cohort
      created
      price_per_lead
      price_not_interested
      applications {
        agent_id
        status
      }
      agents {
        agent_id
      }
      required {
        field
        type
        values
        locations {
          name
          lat
          lng
        }
        min
        max
        required
      }
      custom_fields {
        type
        label
        value
      }
      employee {
        first_name
        display_name
        picture
      }
      company {
        id
        name
        overview
        logo
        website
        employee_count_min
        employee_count_max
        rating
        locations {
          name
          lat
          lng
        }
        industries {
          name
          code
        }
        linkedin
        facebook
        instagram
        twitter
        active_campaigns {
          id
          name
          created
          objective
          target_skills
          target_experience_text
          target_agent_count
          target_languages
          target_locations {
            name
          }
          budget_ppl
          budget_ppql
          budget_total
          budget_bonus
          budget_bonus_count
        }
      }
    }
  }
`;
