import React from 'react';
import { Button, Tag } from '_seriph';
import { roleMap } from '_constants/admin';
import moment from 'moment';

export const getColumns = showEditModal => {
  return [
    {
      title: 'Display Name',
      dataIndex: 'display_name'
    },
    {
      title: 'Email',
      dataIndex: 'user.email'
    },
    {
      title: 'Role',
      dataIndex: 'role',
      render: column => roleMap[column]
    },
    {
      title: 'Joined',
      dataIndex: 'user.createdAt',
      render: column => moment(column * 1).format('M/D/YY h:mma')
    },
    {
      title: 'Companies',
      dataIndex: 'companies',
      align: 'left',
      render: (column, data) => {
        if (data.role === 'super' || data.role === 'owner') {
          return <Tag type="info">All Companies</Tag>;
        }
        return (
          <div className="company-tags">
            {data.companies.map(({ name, id }) => (
              <Tag key={id}>{name}</Tag>
            ))}
          </div>
        );
      }
    },
    {
      title: 'Edit',
      fixed: 'right',
      render: (column, data) => {
        return (
          <Button
            size="small"
            type="primary"
            disabled={data.role === 'owner'}
            onClick={() => {
              showEditModal(data);
            }}
          >
            Edit User
          </Button>
        );
      }
    }
  ];
};
