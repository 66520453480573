import React, { Component } from 'react';
import { Select, Modal, Button, message } from '_seriph';
import { prettyPhone, getErrors } from '_assets/js/helpers';
import { getNewNumber } from '_graphql/mutations/call';
import { 
  IconSelector
} from '@tabler/icons-react';
import { getToken, createTestListeners, createPhoneListeners } from '../../../../_helpers/twilio';
import { Device } from '@twilio/voice-sdk';
import './ChangeNumber.scss';

export default class ChangeNumber extends Component {
  state = {
    open: false,
    testStatus: null,
    changing: false
  };
  testCallerId = async () => {
    if (this.props.changeTestStatus) this.props.changeTestStatus('calling');
    const token = await getToken();
    this.phone = new Device(token, {
      codecPreferences: ['opus', 'pcmu'],
      enableRingingState: true
    });
    createPhoneListeners(this.phone, this.endPhone);
    const testCall = await this.phone.connect({
      params: {
        testCall: true,
        userPhone: this.props.user?.phone_number,
        campaignPhone: this.props.repPhoneNumber
      }
    });
    createTestListeners(testCall, this.endPhone);
  };
  componentWillUnmount = () => {
    this.endPhone();
  };
  endPhone = () => {
    if (this.props.changeTestStatus) this.props.changeTestStatus('init');
    this.phone?.destroy();
  };
  toggle = () => {
    this.setState({ open: !this.state.open })
  };
  getNewNumber = campaignId => {
    Modal.confirm({
      title: 'Are you sure you want change your outreach phone number?',
      okText: 'Get new number',
      cancelText: 'Cancel',
      onOk: () => {
        this.setState({ changing: true });
        this.props.client
          .mutate({ variables: { campaign_id: campaignId }, mutation: getNewNumber })
          .then(result => {
            if (result?.data?.getNewNumber) {
              if (this.props.reload) this.props.reload();
              this.setState({ changing: false });
              message.success('You now have a new number for this campaign');
            } else {
              throw new Error();
            }
          })
          .catch(err => {
            message.error(getErrors(err) || 'Error changing number, try again.');
            this.setState({ changing: false });
          });
      }
    });
  };
  render() {
    const { open, changing } = this.state;
    const { campaign, repPhoneNumber, testStatus, settingsDisabled } = this.props;

    const myPhone = prettyPhone(repPhoneNumber);
    const isTesting = testStatus === 'calling';

    return (
      <div id="change-number">
        <div className="number-bar" onClick={this.toggle}>
          { false && (
            <Select
              value={<span><b>Dialing From:&nbsp;&nbsp;</b>{myPhone}</span>}
              placeholder="(555) 555-5555"
              onChange={value => this.setState({ phone: value })}
              disabled={true}
              size="tiny"
              style={{ width: '100%' }}
            >
              <Select.Option key={myPhone}>
                {myPhone}
              </Select.Option>
            </Select>
          )}
          <div className="from-bar">Dialing From:&nbsp;&nbsp;<span>{myPhone}</span></div>
          <div className="bar-toggle"><IconSelector /></div>
        </div>
        { open ? (
          <div className="change-actions">
            <Button
              size="tiny"
              type="default"
              disabled={settingsDisabled}
              loading={isTesting}
              onClick={this.testCallerId}
            >
              {isTesting ? 'Calling: ' + myPhone : 'Test Caller ID'}
            </Button>
            {isTesting ? (
              <Button size="tiny" type="danger" onClick={this.endPhone}>
                End Call
              </Button>
            ) : null}
            <Button
              size="tiny"
              type="secondary"
              disabled={isTesting || settingsDisabled}
              loading={changing}
              onClick={() => this.getNewNumber(campaign.id)}
            >
              Change number
            </Button>
          </div>
        ) : null }
      </div>
    );
  }
}