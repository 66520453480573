import React, { Component } from 'react';
import { Modal, Button, Input, message, Select } from '_seriph';
import { isEmail, getErrors } from '_assets/js/helpers';
import Guide from '_styleguide/Guide/Guide';
import { inviteMember } from '_graphql/mutations/company';
import './InviteTeamMember.scss';

const { Option } = Select;

export default class InviteTeamMember extends Component {
  state = {
    visible: true,
    loading: false,
    invite_email: undefined,
    invite_role: 'Manager'
  };
  inviteMember = () => {
    this.setState({ loading: true });
    const { invite_email, invite_role } = this.state;
    this.props.client
      .mutate({
        variables: { email: invite_email, role: invite_role },
        mutation: inviteMember
      })
      .then(result => {
        if (result && result.data && result.data.inviteMember) {
          if (this.props.reload) this.props.reload();
          this.hideModal();
        } else {
          throw new Error('Could not send invite, try again');
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        message.error(getErrors(err) || 'Could not send invite, try again');
      });
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { invite_email, invite_role } = this.state;

    const ROLE_INFO = (
      <span className="role-info">
        <span>
          <b>Admin</b> - Account holder with full account & platform access
        </span>
        <span>
          <b>Manager</b> - Full account & platform access
        </span>
        <span>
          <b>Editor</b> - Limited account access (payment info & inviting disabled)
        </span>
      </span>
    );

    return (
      <Modal
        wrapClassName="invite-team-member-modal sx-theme"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        style={{ top: 60 }}
        width={600}
        closable={false}
        maskClosable={false}
      >
        <div className="itm-title">
          <h3 className="ra-title">Invite a new team member</h3>
          <p>
            Your account has room for more teammates to join! <br />
            Send invites to only company emails.
          </p>
        </div>

        <div className="itm-form sx-form">
          <div className="field field-email">
            <label>Email address</label>
            <Input
              placeholder="name@company.com"
              value={invite_email}
              onChange={e => this.setState({ invite_email: e.target.value })}
            />
          </div>
          <div className="field field-role">
            <label>Role</label>
            <Select
              value={invite_role}
              onChange={val => this.setState({ invite_role: val })}
              dropdownClassName="sx-dropdown"
            >
              <Option value="Admin">Admin</Option>
              <Option value="Manager">Manager</Option>
              <Option value="Editor">Editor</Option>
            </Select>
          </div>
        </div>

        <Guide
          type="info"
          id="invite-team-member"
          dismissable={false}
          marginBottom={32}
          message={ROLE_INFO}
        />

        <Modal.Actions>
          <Button onClick={this.hideModal}>
            Cancel
          </Button>
          <Button
            disabled={!isEmail(invite_email || '') || !invite_role}
            onClick={this.inviteMember}
            type="primary"
            loading={this.state.loading}
          >
            Invite Member
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
