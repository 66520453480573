import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { typeMap } from '_constants/leadFields';
import { linkify } from '_assets/js/helpers';
import moment from 'moment';
import { Button } from '_seriph';
import {
  IconBrandLinkedin,
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandTwitter,
  IconWorld
} from '@tabler/icons-react';
import './CompanyContent.scss';

export default class CompanyContent extends Component {
  render() {
    const { campaign, company } = this.props;

    let formattedCompanyOverview =
      campaign && company.overview
        ? company.overview.replace(/\r?\n/g, '<br>').substring(0, 400) + '...'
        : '<i>No information yet...</i>';
    const isLong = campaign && company.overview && company.overview.length > 400 ? true : false;
    if (isLong && campaign) {
      formattedCompanyOverview =
        formattedCompanyOverview + ' <a href="/company/' + company.id + '">(read more)</a>';
    }
    return (
      <div className="overview-company">
        <div className="oc-info">
          <div className="oc-types">
            <b>{company.name}</b>&nbsp;&nbsp;&middot;&nbsp;&nbsp;{typeMap[campaign.objective]}
          </div>
          <h3>{campaign.name}</h3>
          <div
            className="oc-overview"
            dangerouslySetInnerHTML={{ __html: formattedCompanyOverview }}
          />
          <div className="oc-footer">
            <Link to={`/company/${company.id}`}>
              <Button type="default">Visit profile</Button>
            </Link>
            <div className="social">
              {company.website ? (
                <a href={linkify(company.website)} target="_blank" rel="noopener noreferrer">
                  <IconWorld />
                </a>
              ) : null}
              {company.linkedin ? (
                <a href={linkify(company.linkedin)} target="_blank" rel="noopener noreferrer">
                  <IconBrandLinkedin />
                </a>
              ) : null}
              {company.facebook ? (
                <a href={linkify(company.facebook)} target="_blank" rel="noopener noreferrer">
                  <IconBrandFacebook />
                </a>
              ) : null}
              {company.twitter ? (
                <a href={linkify(company.twitter)} target="_blank" rel="noopener noreferrer">
                  <IconBrandTwitter />
                </a>
              ) : null}
              {company.instagram ? (
                <a href={linkify(company.instagram)} target="_blank" rel="noopener noreferrer">
                  <IconBrandInstagram />
                </a>
              ) : null}
            </div>
            <div className="posted">Posted {moment(campaign.created * 1).fromNow()}</div>
          </div>
        </div>
      </div>
    );
  }
}
