import React, { Component } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { tagRegex } from '_helpers/smartTags';
import './Quill.scss';

let Parchment = Quill.import('parchment');
class Label extends Parchment.Embed {
  static create(value) {
    const node = super.create(value);
    node.innerText = value;
    node.contentEditable = 'false';
    return node;
  }
  static value(node) {
    return node.childNodes[0].textContent;
  }
}
Label.blotName = 'label';
Label.tagName = 'SPAN';
Label.className = 'ql-label';
Quill.register(Label);

class TemplateError extends Parchment.Embed {
  static create(value) {
    const node = super.create(value);
    node.innerText = value;
    node.contentEditable = 'false';
    return node;
  }
  static value(node) {
    return node.childNodes[0].textContent;
  }
}

TemplateError.blotName = 'error';
TemplateError.tagName = 'SPAN';
TemplateError.className = 'ql-error';
Quill.register(TemplateError);

class TemplateWarning extends Parchment.Embed {
  static create(value) {
    const node = super.create(value);
    node.innerText = value;
    node.contentEditable = 'false';
    return node;
  }
  static value(node) {
    return node.childNodes[0].textContent;
  }
}

TemplateWarning.blotName = 'warning';
TemplateWarning.tagName = 'SPAN';
TemplateWarning.className = 'ql-warning';
Quill.register(TemplateWarning);

export default class QuillComponent extends Component {
  replaceWithTags = quillValue => {
    let str = quillValue || '';
    (str.match(tagRegex) || []).forEach(val => {
      str = str.replace(tagRegex, `<span class="ql-label" contenteditable="false">${val}</span>`);
    });
    return str;
  };
  onChange = val => {
    if (this.props.onChange) {
      this.props.onChange(this.props.showTags ? this.replaceWithTags(val) : val);
    }
  };
  render() {
    let modules = this.props.modules ?? {
      toolbar: [['bold', 'italic', 'underline', 'link', { list: 'ordered' }, { list: 'bullet' }]],
      clipboard: { matchVisual: false }
    };
    
    let formats = this.props.formats ?? [
      'label',
      'error',
      'warning',
      'bold',
      'italic',
      'underline',
      'link',
      'list'
    ];

    if (this.props.hideModules) {
      modules = {
        toolbar: [],
        clipboard: { matchVisual: false }
      };
      formats = [
        'label',
        'error',
        'warning'
      ];
    }

    const props = { ...this.props };

    props.onChange = val => this.onChange(val);

    delete props.showTags;

    let className = 'seriph-quill';

    // Handle Size (medium, large)
    if (props.size) {
      className += ' quill-' + props.size;
      delete props.size;
    }
    if (props.noHeight) {
      className += ' no-height';
    }

    return (
      <div className={className}>
        <ReactQuill
          ref={el => (this.props.setRef ? this.props.setRef(el) : null)}
          modules={modules}
          formats={formats}
          {...props}
        />
      </div>
    );
  }
}
