import React, { Component } from 'react';
import { Step } from './_components/Step/Step';
import Instructions from './_modals/Instructions/Instructions';
import Email from './_modals/Email/Email';
import Text from './_modals/Text/Text';
import LinkedIn from './_modals/LinkedIn/LinkedIn';
import Skip from './_modals/Skip/Skip';
import moment from 'moment';
import Guide from '_styleguide/Guide/Guide';
import { prettyPhone } from '_assets/js/helpers';
import { hasFeature } from '_helpers/permissions';
import classNames from 'classnames';
import { Button } from '_seriph';
import { Collapse } from 'antd';
import { IconFlag, IconCheck, IconLock } from '@tabler/icons-react';
import './Sequences.scss';

export default class Sequences extends Component {
  state = {
    completeFunc: null,
    isCompleted: null,
    instructionsModal: null,
    emailModal: null,
    textModal: null,
    linkedInModal: null,
    skipModal: null
  };
  showInstructionsModal = (templateId, complete, isCompleted) =>
    this.setState({
      instructionsModal: templateId,
      completeFunc: complete,
      isCompleted: isCompleted
    });
  removeInstructionsModal = () =>
    this.setState({ instructionsModal: null, completeFunc: null, isCompleted: false });
  showEmailModal = (templateId, complete) =>
    this.setState({ emailModal: templateId, completeFunc: complete });
  removeEmailModal = () => this.setState({ emailModal: null, completeFunc: null });
  showTextModal = (templateId, complete, step) =>
    this.setState({ textModal: templateId, completeFunc: complete, step });
  removeTextModal = () => this.setState({ textModal: null, completeFunc: null });
  showLinkedInModal = (templateId, complete) =>
    this.setState({ linkedInModal: templateId, completeFunc: complete });
  removeLinkedInModal = () => this.setState({ linkedInModal: null, completeFunc: null });
  showSkipModal = (templateId, complete) =>
    this.setState({ skipModal: templateId, completeFunc: complete });
  removeSkipModal = () => this.setState({ skipModal: null, completeFunc: null });
  showModal = (type, template_id, complete, isCompleted, step) => {
    if (type === 'instructions') this.showInstructionsModal(template_id, complete, isCompleted);
    if (type === 'email') this.showEmailModal(template_id, complete);
    if (type === 'linkedin') this.showLinkedInModal(template_id, complete);
    if (type === 'skip') this.showSkipModal(template_id, complete);
    if (type === 'text') this.showTextModal(template_id, complete, step);
  };
  showCallPopout = (template_id, day, complete, rep) => {
    this.props.showCallPopout({
      day: day,
      templateId: template_id,
      complete: complete,
      saveAnswers: this.props.saveAnswers,
      lead: this.props.lead,
      campaign: this.props.campaign,
      integration: this.props.integration,
      showCalendarModal: this.props.showCalendarModal,
      company: this.props.campaign.company,
      rep: rep,
      type: 'call'
    });
  };
  showEmailPopout = (template_id, day, complete, rep) => {
    this.props.showCallPopout({
      day: day,
      templateId: template_id,
      complete: complete,
      lead: this.props.lead,
      campaign: this.props.campaign,
      company: this.props.campaign.company,
      rep: rep,
      type: 'email'
    });
  };
  renderSteps = (steps, day, user, isAgent) => {
    return steps.map((step, j) => {
      const complete = typeId => this.props.completeStep(step.task_id, typeId);
      const skip = (skipType, skipReason) =>
        this.props.completeStep(step.task_id, 'skip', skipType, skipReason);
      
      const features = this.props.info?.features || [];
      const oldDialer = hasFeature(features, 'old-dialer'); 
      let callTask = () => this.props.showDialer(this.props.campaign.id, this.props.lead.id);
      if (oldDialer) {
        callTask = () => this.showCallPopout(step.template_id, day, complete, user);
      }
      return (
        <Step
          key={'step-' + day + '-' + j}
          step={step}
          lead={this.props.lead}
          goTo={this.props.goTo}
          canEdit={this.props.canEdit && isAgent}
          complete={complete}
          isPaused={this.props.isPaused}
          showCallPopout={callTask}
          showEmailPopout={() => this.showEmailPopout(step.template_id, day, complete, user)}
          showModal={() => this.showModal(step.type, step.template_id, complete, false, step)}
          showSkip={() => this.showModal('skip', step.template_id, skip, false)}
        />
      );
    });
  };
  render() {
    const { campaign, lead, isLeadChanged, showCompleteModal } = this.props;
    let leadSequences = [];
    if (lead && !lead.agent_id) {
      campaign.sequences.forEach((day, i) => {
        day.steps.forEach((task, j) => {
          leadSequences.push({
            task_id: task._id?.toString() ?? `000-${i}-${j}`,
            day: i + 1,
            step: j + 1,
            type: task.type,
            duration: task.duration,
            duration_type: task.duration_type,
            template_id: task.template_id,
            completed: false,
            start_tstamp: i === 0 && j === 0 ? Date.now() : null,
            completed_tstamp: null
          });
        });
      });
    } else {
      leadSequences = [...lead.sequences];
    }

    /* Setup Agent Phone Number */
    const agent = (campaign.agents || []).find(a => a.agent_id === this.props.user.id);
    const user = { ...this.props.user };
    const isAgent = agent ? true : false;
    user.phone_number = agent && agent.phone_number ? prettyPhone(agent.phone_number) : null;

    const days = {};
    let currentStart = null;
    let now = parseInt(leadSequences?.[0]?.now);

    let allSequencesCompleted = true;

    leadSequences.forEach(t => {
      if (!t.completed) {
        allSequencesCompleted = false;
      }
      if (days[t.day]) {
        days[t.day].amount = days[t.day].amount + 1;
        if (t.completed_tstamp) days[t.day].completed = days[t.day].completed + 1;
        if (t.start_tstamp) days[t.day].last_start = moment(parseInt(t.start_tstamp)).utc();
        if (t.completed_tstamp) days[t.day].last_completed = moment(parseInt(t.completed_tstamp));
      } else {
        days[t.day] = {
          amount: 1,
          completed: t.completed_tstamp ? 1 : 0,
          last_start: t.start_tstamp ? moment(parseInt(t.start_tstamp)).utc() : null,
          last_completed: t.completed_tstamp ? moment(parseInt(t.completed_tstamp)) : null
        };
      }
      if (t.start_tstamp) currentStart = moment(parseInt(t.start_tstamp)).utc();
    });

    const isSubmitable = !isLeadChanged && lead.stage === 'in-progress' && allSequencesCompleted;
    const isSubmitted = lead.stage !== 'in-progress';
    return (
      <div id="lead-sequences" className="sx-form">
        {lead && !lead?.agent_id && (
          <div className="will-begin">
            <div className="fa-cont">
              <IconLock />
            </div>
            <h3>Work will begin once lead is assigned to a rep.</h3>
          </div>
        )}
        <Guide
          id="lead-sequences-v2"
          message="Follow the list of tasks below to help qualify the lead"
        />

        {Object.keys(days).map((day, i) => {
          const dayInfo = days[day];
          const dayComplete = dayInfo.completed >= dayInfo.amount;
          const steps = leadSequences.filter(t => t.day.toString() === day.toString());
          const dayStart = dayInfo.last_start || currentStart.add(1, 'day');
          const dayCompleted = dayInfo.last_completed || dayInfo.last_start;
          const isTomorrow = dayStart.isSame(moment(now).add(1, 'day'), 'd');
          const isLastDay = i === Object.keys(days).length - 1;
          const today = moment(now);
          return (
            <div className="sequence" key={'day-' + day}>
              <div className="sequence-header">
                <h3>
                  Day {day}
                  {dayStart.isSame(today, 'd') ? (
                    <span>|&nbsp;&nbsp;{dayComplete ? 'Completed ' : ''}Today</span>
                  ) : null}
                  {dayStart.isBefore(today, 'd') ? (
                    <span>
                      |&nbsp;&nbsp;
                      {dayComplete
                        ? 'Completed ' + dayCompleted.format('dddd, MMM Do')
                        : 'Started ' + dayStart.format('dddd, MMM Do')}
                    </span>
                  ) : null}
                  {dayStart.isAfter(today, 'd') && isTomorrow ? (
                    <span>|&nbsp;&nbsp;Work begins {dayStart.format('dddd, MMM Do')}</span>
                  ) : null}
                  {dayStart.isAfter(today, 'd') && !isTomorrow ? (
                    <span>|&nbsp;&nbsp;Can begin {dayStart.format('dddd, MMM Do')}</span>
                  ) : null}
                  {!campaign.agents?.length ? (
                    <span>|&nbsp;&nbsp;Work begins {dayStart.format('MMM Do')}</span>
                  ) : null}
                </h3>
              </div>
              <div className="steps">
                {dayCompleted && false ? (
                  <Collapse>
                    <Collapse.Panel header="Complete">
                      {this.renderSteps(steps, day, user, isAgent)}
                    </Collapse.Panel>
                  </Collapse>
                ) : (
                  this.renderSteps(steps, day, user, isAgent)
                )}

                {isAgent && isLastDay && campaign.agents?.length ? (
                  <div
                    className={classNames({
                      'sequence-step': true,
                      todo: isSubmitable && !isSubmitted,
                      completed: isSubmitted
                    })}
                  >
                    <div className={`step-icon`}>
                      {lead.stage === 'in-progress' ? <IconFlag /> : <IconCheck />}
                    </div>
                    <h4>Submit Lead For Review</h4>
                    <div className="actions">
                      <Button
                        onClick={showCompleteModal}
                        className="btnx btnx-neutral"
                        disabled={!isSubmitable}
                      >
                        {lead.stage === 'disputed' ? 'Re-submit' : 'Submit'}
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}

        {this.state.instructionsModal ? (
          <Instructions
            client={this.props.client}
            lead={lead}
            user={user}
            company={campaign.company}
            templateId={this.state.instructionsModal}
            isCompleted={this.state.isCompleted}
            removeModal={this.removeInstructionsModal}
            complete={this.state.completeFunc}
            reload={this.props.reload}
          />
        ) : null}

        {this.state.linkedInModal ? (
          <LinkedIn
            client={this.props.client}
            lead={lead}
            user={user}
            campaign={campaign}
            company={campaign.company}
            templateId={this.state.linkedInModal}
            removeModal={this.removeLinkedInModal}
            complete={this.state.completeFunc}
            reload={this.props.reload}
          />
        ) : null}

        {this.state.skipModal ? (
          <Skip
            r
            client={this.props.client}
            lead={lead}
            user={user}
            campaign={campaign}
            company={campaign.company}
            templateId={this.state.skipModal}
            removeModal={this.removeSkipModal}
            complete={this.state.completeFunc}
            reload={this.props.reload}
          />
        ) : null}

        {this.state.textModal ? (
          <Text
            client={this.props.client}
            lead={lead}
            user={user}
            step={this.state.step}
            campaign={campaign}
            company={campaign.company}
            templateId={this.state.textModal}
            removeModal={this.removeTextModal}
            complete={this.state.completeFunc}
            reload={this.props.reload}
          />
        ) : null}

        {this.state.emailModal ? (
          <Email
            client={this.props.client}
            lead={lead}
            user={user}
            campaign={campaign}
            company={campaign.company}
            templateId={this.state.emailModal}
            removeModal={this.removeEmailModal}
            complete={this.state.completeFunc}
            reload={this.props.reload}
          />
        ) : null}
      </div>
    );
  }
}
