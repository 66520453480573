import React, { Component } from 'react';
import { Loading, Button, message, Modal, Quill, Tag } from '_seriph';
import { getErrors, stripHTML } from '_assets/js/helpers';
import { callNotes } from '_graphql/queries/call';
import { createNote } from '_graphql/mutations/lead';
import { IconArrowBack } from '@tabler/icons-react';
import moment from 'moment';
import './Notes.scss';

export default class Notes extends Component {
  state = {
    loading: true,
    saving: false,
    notes: [],
    note: ''
  };
  componentDidMount = () => {
    this.loadNotes(this.props.lead.id);
  };
  componentDidUpdate = prevProps => {
    const prevId = prevProps.lead.id;
    const newId = this.props.lead.id;
    if (prevId !== newId) this.loadNotes(newId, true);
  };
  loadNotes = (id, init) => {
    if (init) this.setState({ loading: true });
    this.props.client
      .query({ variables: { lead_id: id }, query: callNotes })
      .then(result => {
        if (result && result.data && result.data.leadNotes) {
          const notes = result.data.leadNotes || [];
          if (this.props.notesUpdated) this.props.notesUpdated(id, notes.length || 0);
          this.setState({ notes, loading: false });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        message.error(getErrors(err) || 'Could not load notes, try again');
      });
  };
  createNote = () => {
    if (!this.state.note) return;
    const lead = this.props.lead || {};
    this.setState({ saving: true });
    this.props.client
      .mutate({
        variables: { lead_id: lead.id, campaign_id: lead.campaign_id, content: this.state.note },
        mutation: createNote
      })
      .then(result => {
        if (result && result.data && result.data.createNote) {
          this.setState({ note: '', saving: false });
          this.loadNotes(lead.id);
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Error creating note, try again');
      });
  };
  handleEnter = e => {
    if (this.state.saving) return;
    if (e.key === 'Enter' && !e.shiftKey) e.preventDefault();
    if (e.key === 'Enter' && !e.shiftKey && !!stripHTML(this.state.note)) {
      this.createNote();
    }
  };
  render() {
    const { loading, notes, note } = this.state;
    const { lead } = this.props;

    return loading ? (
      <Loading />
    ) : (
      <div id="dialer-notes">
        <div className="notes-header">
          <Modal.Close onClick={this.props.close} />
          Notes for {lead.first_name}
        </div>
        <Quill
          size="small"
          value={note}
          onChange={note => this.setState({ note })}
          placeholder="Write a note..."
          onKeyDown={this.handleEnter}
          readOnly={this.state.saving}
        />
        <div className="notes-actions">
          <div className="enter">
            Press enter <IconArrowBack /> to post
          </div>
          <Button loading={this.state.saving} disabled={!stripHTML(this.state.note)} type="primary" onClick={this.createNote}>
            Post
          </Button>
        </div>

        <div className="note-box">
          <div className="note-header">
            All notes <Tag>{notes.length}</Tag>
          </div>
          <div className="note-list">
            {notes.map(n => {
              const timestamp = moment(parseInt(n.createdAt)).format('M/DD h:mma');
              const content = ` • ${stripHTML(n.content)} <span>- ${timestamp}</span>`;
              return (
                <div key={n.id} className="note-data">
                  <div className="note-mid" dangerouslySetInnerHTML={{ __html: content }} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
