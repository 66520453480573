import React, { Component } from 'react';
import { Elements } from 'react-stripe-elements';
import PaymentMethod from '../PaymentMethod/PaymentMethod';
import Subscription from './_components/Subscription/Subscription';
import Billing from '../Billing/Billing';
import './PlanPayment.scss';

class PlanPayment extends Component {
  render() {
    return (
      <div id="company-settings-plan-payment">

        <div className="plansub">
          <div className="plan">
            <Subscription 
              client={this.props.client} 
              company={this.props.company}
              showSubscribeModal={this.props.showSubscribeModal}
            />
          </div>
          <div className="payment-method">
            <Elements>
              <PaymentMethod client={this.props.client} myself={this.props.myself} />
            </Elements>
          </div>
        </div>
        <Billing client={this.props.client} myself={this.props.user} />
      </div>
    );
  }
}

export default PlanPayment;
