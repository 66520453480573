import React from 'react';
import { Input, Icon } from 'antd';
import PlacesAutocomplete from 'react-places-autocomplete';
import './GooglePlaces.scss';

const searchOptions = {
  types: ['(regions)']
};

export default class GooglePlaces extends React.Component {
  state = {
    address: ''
  };
  handleChange = address => this.setState({ address });
  handleSelect = address => {
    this.setState({ address: '', preview: address });
    this.props.onSelect(address);
  };
  render() {
    const inlineStyles = {};
    if (this.props.inline) {
      inlineStyles.marginTop = 0;
    }
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div
            className={`google-places ${this.props.placeLocation ? 'place-color' : null}`}
            style={inlineStyles}
          >
            <Input
              {...getInputProps({ placeholder: this.props.placeholder || 'Search Places...' })}
              size={this.props.size || 'large'}
              suffix={<Icon type="search" />}
              style={{ maxWidth: 500, width: '100%', marginBottom: this.props.inline ? 0 : '10px' }}
            />
            <div className="places-container">
              {suggestions.map((suggestion, i) => {
                const className = suggestion.active ? 'gp-item gp-item--active' : 'gp-item';
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, { className })}
                    key={'suggestion-' + i}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}
