import React, { Component } from 'react';
import { Radio } from 'antd';
import Guide from '_styleguide/Guide/Guide';
import { TextArea, Card, Checkbox, Header } from '_seriph';
import './Questions.scss';

export default class QuestionsV2 extends Component {
  changeAnswers = (question, val) => {
    const answers = [...(this.props.lead.answers || [])];
    if (this.doesAnswerExist(question)) {
      answers.map(a => {
        if (a.question === question.question && question.type === 'Checkbox') a.answers = val;
        if (a.question === question.question && question.type !== 'Checkbox') a.answer = val;
        return a;
      });
    } else {
      if (question.type === 'Checkbox') {
        answers.push({ question: question.question, answer: null, answers: val });
      } else {
        answers.push({ question: question.question, answer: val, answers: [] });
      }
    }
    this.props.update('answers', answers);
  };
  doesAnswerExist = q => {
    return this.props.lead.answers.find(a => a.question === q.question) ? true : false;
  };
  getAnswer = q => {
    const answer = this.props.lead.answers.find(a => a.question === q.question);
    if (q.type === 'Checkbox') {
      return answer && answer.answers ? answer.answers : [];
    } else {
      return answer && answer.answer ? answer.answer : '';
    }
  };
  render() {
    const { isAgent, questions, canEdit } = this.props;

    return (
      <div id="lead-questions" className="sx-form">
        {isAgent && (
          <Guide
            id="lead-questions-v2"
            message="Help gather information about the lead during your tasks to help fill out the qualifying questions below."
          />
        )}
        <div className="q-body">
          <div className="questions v2">
            <Header type="sans" size="3">
              Questions ({questions.length})
            </Header>
            {questions.map((q, i) => (
              <Card className="lead-quest" key={'lq-' + i}>
                <Header type="sans" size="4">
                  <span>{i + 1}.</span>&nbsp;{q.question}
                </Header>

                {q.type === 'Text' && (
                  <TextArea
                    disabled={!canEdit}
                    rows={3}
                    placeholder="Write your answer here..."
                    value={this.getAnswer(q)}
                    onChange={e => this.changeAnswers(q, e.target.value)}
                  />
                )}
                {q.type === 'Checkbox' && (
                  <Checkbox.Group
                    disabled={!canEdit}
                    options={q.options}
                    value={this.getAnswer(q)}
                    onChange={val => this.changeAnswers(q, val)}
                  />
                )}
                {q.type === 'Radio' && (
                  <Radio.Group
                    disabled={!canEdit}
                    options={q.options}
                    value={this.getAnswer(q)}
                    onChange={e => this.changeAnswers(q, e.target.value)}
                  />
                )}
              </Card>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
