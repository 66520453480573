import React, { Component } from 'react';
import { Route, withRouter, Link, Switch } from 'react-router-dom';
// import TagManager from 'react-gtm-module';
import { connect } from 'react-redux';
import { Layout, Menu, Dropdown } from 'antd';
import Discover from './Discover/Discover';
import Messaging from '../_shared/Messaging/Messaging';
import MessageCompany from '../_shared/Messaging/_modals/MessageCompany/MessageCompany';
import Profile from './Profile/Profile';
import Settings from './Settings/Settings';
import OAuth from './Settings/_components/Banking/OAuth';
import Analytics from './Analytics/Analytics';
import Learn from './Learn/Learn';
import Campaigns from './Campaigns/Campaigns';
import XDRHome from './Home/XDRHome/XDRHome';
import InternalHome from './Home/InternalHome/InternalHome';
import Leads from '../Company/Leads/Leads';
import logo from '../../_assets/img/logo_black.svg';
import { loginUser, logoutUser } from 'Store/user/user_actions';
import CompanyProfile from 'App/Company/Profile/Profile';
import Notifications from '../_shared/Notifications/Notifications';
import CreateLead from 'App/Company/Leads/_modals/CreateLead/CreateLead';
import { getAgentInformation } from '_graphql/queries/rep';
import Verify from '../Home/Verify/Verify';
import { PublicRoutes } from '_shared/PublicRoutes/PublicRoutes';
import MissingAgent from 'App/Home/Missing/MissingAgent';
import { Avatar, message } from '_seriph';
import { agentData } from '_graphql/queries/auth';
import ReachOut from 'App/Agent/_components/ReachOut/ReachOut';
import Dialer from 'App/Agent/_components/Dialer/Dialer';
import { hasAccess } from '_helpers/permissions';
import { determineReputation, reputationTextIndex } from '_helpers/reputation';
import { masqueradeAdmin, masqueradeBack } from '_graphql/mutations/auth';
import { ReactComponent as SellXWordmark } from '_assets/img/sellx-wordmark.svg';
import {
  IconBrandSafari,
  IconSmartHome,
  IconTriangleSquareCircle,
  IconRocket,
  IconChartArcs3,
  IconCirclePlus,
  IconMessageCircle,
  IconBell,
  IconArrowBackUp
} from '@tabler/icons-react';
import { isInternal } from '_helpers/permissions';
import './Agent.scss';

const { Content, Header } = Layout;

class Agent extends Component {
  state = {
    drawer: false,
    loadDrawer: false,
    small: true,
    messageCount: 0,
    notificationCount: 0,
    touchpoints: { email: [], call: [] },
    info: {},
    campaigns: [],
    profileMenu: false,
    counts: null,
    showCreate: false,
    messageOwner: false
  };
  componentDidMount = () => {
    this.getAgentInformation();
    this.getAgentData();
  };
  getAgentData = () => {
    this.props.client.query({ query: agentData }).then(result => {
      if (result && result.data && result.data.agentData) {
        this.setState({
          counts: result.data.agentData,
          messageCount: result.data.agentData.message_count || 0,
          notificationCount: result.data.agentData.notification_count || 0
        });
      }
    });
  };
  getAgentInformation = () => {
    this.props.client.query({ query: getAgentInformation }).then(result => {
      if (result && result.data && result.data.agentInformation) {
        const isBanned =
          result.data.agentInformation.banned || result.data.agentInformation.cohort === 'remove'
            ? true
            : false;
        if (isBanned) {
          localStorage.removeItem('JWT');
          window.location.href = '/';
          return false;
        }
        const info = result.data.agentInformation;
        const campaigns = info && info.campaigns ? info.campaigns : [];
        this.setState({ info, campaigns });
      }
    });
  };
  masqBack = () => {
    this.props.client
      .mutate({ mutation: masqueradeBack })
      .then(result => {
        if (result && result.data && result.data.masqueradeBack) {
          this.props.loginUser(result.data.masqueradeBack);
          this.props.history.push('/');
        } else {
          throw new Error('Could not login as company, try again');
        }
      })
      .catch(err => message.error(err.message));
  };
  masqAdmin = () => {
    this.props.client
      .mutate({ mutation: masqueradeAdmin })
      .then(result => {
        if (result && result.data && result.data.masqueradeAdmin) {
          this.props.loginUser(result.data.masqueradeAdmin);
          this.props.history.push('/sales-reps');
        } else {
          throw new Error('Could not login as admin, try again');
        }
      })
      .catch(err => message.error(err.message));
  };
  updateInfo = (field, value) => {
    const info = Object.assign({}, this.state.info);
    info[field] = value !== undefined ? value : null;
    this.setState({ info });
  };
  convoCount = obj => {
    let sum = 0;
    for (const el in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, el)) sum += parseFloat(obj[el]);
    }
    return sum;
  };
  showPopout = sequence => {
    const touchpoints = { ...this.state.touchpoints };
    const exists = touchpoints[sequence.type].find(s => s.lead.id === sequence.lead.id);
    if (!exists || !sequence.templateId) {
      if (sequence.type === 'email') {
        touchpoints[sequence.type].push(sequence);
      } else {
        touchpoints[sequence.type] = [sequence];
      }
      this.setState({ touchpoints });
    }
  };
  hidePopout = (index, type) => {
    const touchpoints = { ...this.state.touchpoints };
    if (type === 'email') {
      touchpoints[type].splice(index, 1);
    } else {
      touchpoints[type] = [];
    }
    this.setState({ touchpoints });
  };
  makeSmall = () => this.setState({ small: true });
  logoutCallback = () => this.props.history.push('/');
  closeDrawer = () => this.setState({ drawer: false });
  showMessageOwnerModal = () => {
    this.closeDrawer();
    this.setState({ messageOwner: true });
  };
  removeMessageOwnerModal = () => this.setState({ messageOwner: false });
  changeMenu = a => this.setState({ profileMenu: a });
  showCreateModal = () => this.setState({ showCreate: true });
  removeCreateModal = () => this.setState({ showCreate: false });
  showDialerModal = (campaignId, leadId) => {
    this.setState({ showDialer: { campaignId, leadId } });
  }
  removeDialerModal = () => this.setState({ showDialer: false });
  selectLead = (leadId, campaignId) => {
    this.props.history.push(`/leads/new?campaign=${campaignId}`);
  };
  loadNotifications = () => {
    this.setState({ drawer: true, loadDrawer: true, messagingDrawer: false }, () => {
      document.body.classList.remove('messaging-open');
    });
  };
  loadMessaging = () => {
    this.setState({ messagingDrawer: true, drawer: false, loadDrawer: false }, () => {
      document.body.classList.add('messaging-open');
    });
  };
  closeMessaging = () => {
    this.setState({ messagingDrawer: false }, () => {
      document.body.classList.remove('messaging-open');
    });
  };
  hideMessaging = () => {
    this.setState({ closingMessaging: true });
  };
  render() {
    const { messageCount, notificationCount } = this.state;

    const menu = (
      <Menu>
        { this.props.user?.from_owner ? (
          <Menu.Item key="4" onClick={this.masqBack}>
            Switch to Company
          </Menu.Item>
        ) : null }
        <Menu.Item key="0">
          <Link to="/profile">Edit Profile</Link>
        </Menu.Item>
        <Menu.Item key="1">
          <Link to="/settings">Manage Account</Link>
        </Menu.Item>
        <Menu.Item
          key="3"
          onClick={() => this.props.logoutUser(this.logoutCallback)}
          className="sign-out"
        >
          Sign Out
        </Menu.Item>
      </Menu>
    );

    const drawerOpen = this.state.drawer || this.state.messagingDrawer;

    let currentPage = [];
    if (this.props.location.pathname === '/') currentPage = ['home'];
    if (this.props.location.pathname === '/analytics') currentPage = ['analytics'];
    if (this.props.location.pathname === '/explore') currentPage = ['explore'];
    if (this.props.location.pathname === '/leads') currentPage = ['leads'];
    if (this.props.location.pathname.includes('/leads/')) currentPage = ['leads'];
    if (this.props.location.pathname === '/learn') currentPage = ['learn'];
    if (this.props.location.pathname === '/campaigns') currentPage = ['campaigns'];
    if (this.props.location.pathname === '/funds') currentPage = ['funds'];
    if (this.state.messagingDrawer) currentPage = ['messages'];
    if (this.state.loadDrawer && this.state.drawer) currentPage = ['alerts'];

    let isOnLeads = false;
    const re = new RegExp('^(/leads/(.)+)$');
    if (re.test(this.props.location.pathname) || this.props.location.pathname === '/leads')
      isOnLeads = true;

    const isPublic =
      PublicRoutes.Routes.has(this.props.location.pathname) ||
      PublicRoutes.Routes.has(this.props.location.pathname.slice(0, -1));

    const isSmall = this.state.small;
    const isMasq = this.props.user?.masq ? true : false;
    const hasLiveCampaign = this.state.campaigns
      .filter(c => {
        const member = c.agents.find(a => a.agent_id === this.props.user.id);
        return member && member.active && c.status === 'active' ? true : false;
      })
      .find(c => c.status === 'active')
      ? true
      : false;

    const reputationCount = this.state.info?.reputation || 0;
    const reputationIndex = determineReputation(reputationCount);
    window.dataLayer.push({ userReputation: reputationTextIndex(reputationIndex) });

    const reputationClasses = [
      'earner',
      'big-earner',
      'super-earner',
      'ultra-earner',
      'extreme-earner'
    ];

    const internal = isInternal();

    return isPublic ? (
      PublicRoutes.Public(this.props)
    ) : (
      <React.Fragment>
        <Layout
          id="agent"
          className={isSmall && !drawerOpen ? 'small-menu' : ''}
          style={isPublic ? { display: 'none' } : {}}
        >
          <Header
            id="agent-menu"
            onMouseEnter={() => this.setState({ small: false })}
            onMouseLeave={() => this.setState({ small: true })}
          >
            <div className="logo">
              <Link to="/">
                <div>
                  <img src={logo} alt="Logo" />
                </div>
                <div>
                  <SellXWordmark />
                </div>
              </Link>
            </div>
            <Menu mode="inline" selectedKeys={currentPage} className="main-menu">
              <Menu.Item key="home">
                <Link to="/">
                  <span className="lfa">
                    <IconSmartHome size={22} stroke={1.75} />
                  </span>
                  Home
                </Link>
              </Menu.Item>
              {hasAccess(['Rep', 'SellX']) && (
                <Menu.Item key="explore">
                  <Link to="/explore">
                    <span className="lfa">
                      <IconBrandSafari size={22} stroke={1.75} />
                    </span>
                    Explore
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item key="leads">
                <Link to="/leads">
                  <span className="lfa">
                    <IconTriangleSquareCircle size={22} stroke={1.75} />
                  </span>
                  Leads
                </Link>
              </Menu.Item>
              <Menu.Item key="campaigns">
                <Link to="/campaigns">
                  <span className="lfa">
                    <IconRocket size={22} stroke={1.75} />
                  </span>
                  Campaigns
                </Link>
              </Menu.Item>
              <Menu.Item key="analytics">
                <Link to="/analytics">
                  <span className="lfa">
                    <IconChartArcs3 size={22} stroke={1.75} />
                  </span>
                  Analytics
                </Link>
              </Menu.Item>
              {hasLiveCampaign ? (
                <Menu.Item key="newcampaign" className="menu-button add-button">
                  <div className="mock-a" onClick={this.showCreateModal}>
                    <span className="lfa">
                      <IconCirclePlus size={22} stroke={1.75} />
                    </span>
                    New Lead
                  </div>
                </Menu.Item>
              ) : null}
              <div className="menu-line" />
              {hasAccess(['Rep', 'SellX']) && (
                <Menu.Item key="messages">
                  <div
                    className="drawer-click"
                    onClick={this.state.messagingDrawer ? null : this.loadMessaging}
                  >
                    <span className="lfa">
                      <IconMessageCircle size={22} stroke={1.75} />
                    </span>
                    Messaging
                    {messageCount ? (
                      <div className={`alerting ${messageCount < 10 ? '' : 'empty'}`}>
                        <span>{messageCount}</span>
                      </div>
                    ) : null}
                  </div>
                </Menu.Item>
              )}
              <Menu.Item key="alerts">
                <div
                  className="drawer-click"
                  onClick={this.state.drawer ? this.closeDrawer : this.loadNotifications}
                >
                  <span className="lfa">
                    <IconBell size={22} stroke={1.75} />
                  </span>
                  Notifications
                  {notificationCount ? (
                    <div className={`alerting ${notificationCount < 10 ? '' : 'empty'}`}>
                      <span>{notificationCount}</span>
                    </div>
                  ) : null}
                </div>
              </Menu.Item>
            </Menu>

            <div className="actions-menu">
              <Dropdown
                onVisibleChange={this.changeMenu}
                overlayClassName={
                  isSmall && !drawerOpen ? 'agent-dropdown small' : 'agent-dropdown'
                }
                overlay={menu}
                trigger={['click']}
                placement="topLeft"
              >
                <div className={this.state.profileMenu ? 'menu-user active' : 'menu-user'}>
                  <Avatar
                    type="agent"
                    size="tiny"
                    picture={this.props.user.picture}
                    reputation={reputationCount}
                  />
                  <div className="menu-av">
                    <div className="menu-user-name">{this.props.user.display_name}</div>
                    <div className="menu-rep">
                      <span className={reputationClasses[reputationIndex]}>
                        {reputationTextIndex(reputationIndex)}
                      </span>
                    </div>
                  </div>
                </div>
              </Dropdown>
            </div>

            {this.state.loadDrawer ? (
              <Notifications
                type="agent"
                small={isSmall && !drawerOpen}
                drawer={this.state.drawer}
                closeDrawer={this.closeDrawer}
                client={this.props.client}
                user={this.props.user}
                unread={this.props.notifications || {}}
                reload={this.getAgentData}
              />
            ) : null}

            {this.state.messagingDrawer ? (
              <Messaging
                type="company"
                small={this.state.small && !drawerOpen}
                drawer={this.state.messagingDrawer}
                closeDrawer={this.closeMessaging}
                closing={this.state.closingMessaging}
                client={this.props.client}
                user={this.props.user}
                campaigns={this.state.campaigns}
                reload={this.getAgentData}
                showMessageOwnerModal={this.showMessageOwnerModal}
              />
            ) : null}
            {this.state.messageOwner ? (
              <MessageCompany
                client={this.props.client}
                reload={this.getCompanyData}
                user={this.props.user}
                campaigns={this.state.campaigns}
                removeModal={this.removeMessageOwnerModal}
              />
            ) : null}
          </Header>
          <Content className="agent-body">
            {isMasq && (
              <div id="masq-user">
                <b>Warning</b>&nbsp;&nbsp;&nbsp;You are currently logged into a client account
                <div className="go-back" onClick={this.masqAdmin}>
                  <IconArrowBackUp />
                  Back to Admin
                </div>
              </div>
            )}
            <Switch>
              <Route
                exact
                path="/"
                render={i => (
                  internal ? (
                    <InternalHome
                      match={i.match}
                      client={this.props.client}
                      counts={this.state.counts}
                      loadMessaging={this.loadMessaging}
                      loadNotifications={this.loadNotifications}
                      campaigns={this.state.campaigns}
                      hasLiveCampaign={hasLiveCampaign}
                    />
                  ) : (
                    <XDRHome
                      match={i.match}
                      client={this.props.client}
                      counts={this.state.counts}
                      loadMessaging={this.loadMessaging}
                      loadNotifications={this.loadNotifications}
                      campaigns={this.state.campaigns}
                      hasLiveCampaign={hasLiveCampaign}
                    />
                  )
                )}
              />
              <Route
                exact
                path="/analytics/:page(dashboard|call-recordings|meetings)?"
                render={i => (
                  <Analytics
                    myself={this.props.user}
                    client={this.props.client}
                    page={i.match.params.page}
                    match={i.match}
                    campaigns={this.state.campaigns}
                  />
                )}
              />
              <Route
                exact
                path="/verify"
                render={i => <Verify match={i.match} client={this.props.client} />}
              />
              <Route
                exact
                path="/explore"
                render={i => (
                  <Discover
                    client={this.props.client}
                    info={this.state.info}
                    updateInfo={this.updateInfo}
                    match={i.match}
                    getAgentInformation={this.getAgentInformation}
                  />
                )}
              />
              <Route
                exact
                path="/leads"
                render={i => (
                  <Leads
                    match={i.match}
                    client={this.props.client}
                    campaigns={this.state.campaigns}
                    info={this.state.info}
                    hasLiveCampaign={hasLiveCampaign}
                    showDialer={this.showDialerModal}
                  />
                )}
              />
              <Route
                exact
                path="/leads/:id/:tab(sequences|info|questions|email|calls|notes|texts|activity|instructions)?/:otherId?"
                render={i => (
                  <Leads
                    match={i.match}
                    client={this.props.client}
                    campaigns={this.state.campaigns}
                    info={this.state.info}
                    showCallPopout={this.showPopout}
                    showDialer={this.showDialerModal}
                    leadId={i.match.params.id}
                    tab={i.match.params.tab}
                    otherId={i.match.params.otherId}
                    hasLiveCampaign={hasLiveCampaign}
                  />
                )}
              />
              <Route
                exact
                path="/learn/:id?"
                render={i => (
                  <Learn client={this.props.client} match={i.match} learnId={i.match.params.id} />
                )}
              />
              <Route
                exact
                path="/campaigns"
                render={i => (
                  <Campaigns
                    myself={this.props.user}
                    info={this.state.info}
                    updateInfo={this.updateInfo}
                    client={this.props.client}
                    match={i.match}
                  />
                )}
              />
              <Route
                exact
                path="/profile/:id?"
                render={i => (
                  <Profile
                    match={i.match}
                    info={this.state.info}
                    client={this.props.client}
                    agentId={i.match.params.id}
                  />
                )}
              />
              <Route
                exact
                path="/settings/:page?"
                render={i => (
                  <Settings
                    match={i.match}
                    client={this.props.client}
                    info={this.state.info}
                    page={i.match.params.page}
                    logoutUser={this.props.logoutUser}
                  />
                )}
              />
              <Route
                exact
                path="/stripe/oath"
                render={i => <OAuth match={i.match} client={this.props.client} />}
              />
              <Route
                exact
                path="/company/:id"
                render={i => (
                  <CompanyProfile
                    match={i.match}
                    info={this.state.info}
                    client={this.props.client}
                    companyId={i.match.params.id}
                    isAgent={true}
                  />
                )}
              />
              <Route component={MissingAgent} />
            </Switch>
          </Content>

          {this.state.showCreate && (
            <CreateLead
              client={this.props.client}
              selectLead={this.selectLead}
              campaigns={this.state.campaigns}
              removeModal={this.removeCreateModal}
              menuCreate={isOnLeads ? true : false}
            />
          )}

          {this.state.showDialer && (
            <Dialer
              client={this.props.client}
              campaigns={this.state.campaigns}
              campaignId={this.state.showDialer?.campaignId}
              leadId={this.state.showDialer?.leadId}
              removeModal={this.removeDialerModal}
              info={this.state.info}
            />
          )}

          <ReachOut
            touchpoints={this.state.touchpoints}
            client={this.props.client}
            hidePopout={this.hidePopout}
          />
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, { logoutUser, loginUser })(Agent));
