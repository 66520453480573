export const tagMap = {
  pause: {
    display: 'Pause',
    className: 'pause-tag',
    tooltip: t => `Paused until ${t.date.format('M/D/YY')}`
  },
  manual: {
    display: 'Manual',
    className: 'manual-tag',
    tooltip: () => `Manual Mode`
  },
  follow: {
    display: 'Follow Up',
    className: 'follow-tag',
    tooltip: t => `Follow up on ${t.date.format('M/D/YY')}`
  },
  nurture: {
    display: 'Nurture',
    className: 'nurture-tag',
    tooltip: t => `Nurture on ${t.date.format('M/D/YY')}`
  }
};

export const typeMap = {
  hot: {
    display: 'Hot'
  },
  warm: {
    display: 'Warm'
  },
  cool: {
    display: 'Cool'
  },
  cold: {
    display: 'Cold'
  }
};
