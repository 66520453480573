import React, { Component } from 'react';
import { getErrors, formatMoney } from '_assets/js/helpers';
import { payoutDetailsHistory } from '_graphql/queries/rep';
import { message } from 'antd';
import Loading from '_shared/Loading/Loading';
import { Modal, Button, Header, Tag } from '_seriph';
import moment from 'moment';
import './PayoutDetails.scss';

export default class PayoutDetails extends Component {
  state = {
    visible: true,
    loading: true,
    initialLoading: true,
    transfers: [],
    paging: false,
    hasMore: false,
    startId: null,
    endingBefore: null,
    startingAfter: null
  };
  componentDidMount = () => {
    this.loadPayoutDetails();
  };
  loadPayoutDetails = paging => {
    if (paging) {
      this.setState({ loading: true, paging: true });
    } else {
      this.setState({ initialLoading: true, paging: false });
    }
    this.props.client
      .query({
        variables: {
          payoutId: this.props.payout.id,
          endingBefore: paging === 'prev' ? this.state.endingBefore : null,
          startingAfter: paging === 'next' ? this.state.startingAfter : null
        },
        query: payoutDetailsHistory
      })
      .then(result => {
        if (result && result.data && result.data.payoutDetailsHistory) {
          const transfers = result.data.payoutDetailsHistory.data || [];
          this.setState({
            loading: false,
            initialLoading: false,
            transfers,
            hasMore: result.data.payoutDetailsHistory?.hasMore ? true : false,
            endingBefore: transfers[0]?.id,
            startingAfter: transfers[transfers.length - 1]?.id,
            startId: this.state.startId || transfers[0]?.id
          });
        } else {
          throw new Error('Could not load transfers, try again');
        }
      })
      .catch(err => {
        this.setState({ loading: false, initialLoading: false });
        message.error(getErrors(err) || 'Could not load transfers, try again');
      });
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { transfers, loading, initialLoading, startId, paging } = this.state;
    const { payout } = this.props;

    const hasPrevious = startId !== transfers[0]?.id;
    const hasMore = (startId && startId === transfers[0]?.id && paging) || this.state.hasMore;

    let status = payout?.status;
    let statusTag = <Tag>Pending</Tag>;
    if (status === 'paid') {
      statusTag = <Tag type="primary">Paid</Tag>;
    } else if (status === 'pending') {
      statusTag = <Tag type="default">Pending</Tag>;
    } else if (status === 'failed') {
      statusTag = <Tag type="danger">Failed</Tag>;
    } else if (status === 'canceled') {
      statusTag = <Tag type="info">Canceled</Tag>;
    }

    return (
      <Modal
        wrapClassName="payout-details-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={true}
        width={800}
      >
        {loading ? (
          <Loading />
        ) : (
          <div className="bd-wrapper">
            <div className="bd-details">
              <Modal.Close onClick={this.hideModal} />

              <Header size="2" type="serif">
                Payout Details
              </Header>

              <div className="bd-info">{statusTag}</div>

              <h5>SUMMARY</h5>
              <div className="bd-summary">
                <div className="transfers">
                  <div className="transfer header">
                    <div>Name</div>
                    <div>Paid</div>
                  </div>
                  {transfers.map(t => {
                    return (
                      <div className="transfer" key={'transfer-' + t.id}>
                        <div>{t.name}</div>
                        <div>{formatMoney(t.paid)}</div>
                      </div>
                    );
                  })}
                </div>
                {(hasMore || hasPrevious) && !initialLoading ? (
                  <div className="earnings-actions">
                    <Button
                      onClick={() => this.loadPayoutDetails('prev')}
                      size="tiny"
                      icon="left"
                      disabled={!hasPrevious}
                    >
                      Prev.
                    </Button>
                    <Button
                      onClick={() => this.loadPayoutDetails('next')}
                      size="tiny"
                      icon="right"
                      disabled={!hasMore}
                    >
                      Next
                    </Button>
                  </div>
                ) : null}
                <div className="line-item payment-total">
                  <h3>Payment total</h3>
                  <h3 className="subtotal">+{formatMoney(payout.amount / 100)}</h3>
                </div>
              </div>

              <div className="bd-hint">
                If you have any questions, contact us at{' '}
                <a href="mailto:support@sellx.com">support@sellx.com</a>
              </div>

              <Modal.Actions align="right">
                <Button onClick={this.hideModal}>CLOSE</Button>
              </Modal.Actions>
            </div>
            <div className="bd-activity">
              <Header size="4" type="sans">
                Activity
              </Header>
              <div className="bd-list">
                <div className="activity-item">
                  <div className="left"></div>
                  <div className="right">
                    <b>Created;</b> on {moment(payout.created * 1000).format('L')}
                  </div>
                </div>
                {payout.status === 'paid' ? (
                  <div className="activity-item">
                    <div className="left"></div>
                    <div className="right">
                      <b>Paid;</b> on {moment(payout.arrival_date * 1000).format('L')}
                    </div>
                  </div>
                ) : (
                  <div className="activity-item">
                    <div className="left"></div>
                    <div className="right">
                      <b>Est. Arrival;</b> on {moment(payout.arrival_date * 1000).format('L')}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
