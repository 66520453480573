import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, message, Loading, Header, Table } from '_seriph';
import { getErrors } from '_assets/js/helpers';
import { getSubscription } from '_graphql/queries/stripe';
import { getColumns } from './Columns';
import './SeatInfoModal.scss';

class SeatInfoModal extends Component {
  state = {
    loading: true,
    visible: true,
    employees: [],
    internal: []
  };
  componentDidMount = () => {
    this.getSeatInfo();
  }
  getSeatInfo = () => {
    this.props.client
      .query({ query: getSubscription })
      .then(result => {
        if (result?.data?.getSubscription) {
          this.setState({ 
            employees: result?.data?.getSubscription?.employees || [],
            internal: result?.data?.getSubscription?.internal_reps || [],
            loading: false 
          });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        message.error(getErrors(err) || 'Could not load seats, try again');
      });
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { loading, employees, internal } = this.state;
    const { company } = this.props;



    return (
      <Modal
        wrapClassName="seat-info-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={false}
        width={480}
      >
        { loading ? <Loading /> : (
          <div className="seat-info">
            <Modal.Close onClick={this.hideModal} />
            <Header font="serif" size="2">
              Seat allotment
            </Header>
            <div className="numbers">
              <div><h3>{company?.internal_seats || 0}</h3><p>Available</p></div>
              <div><h3>{employees?.length || 0}</h3><p>Employees</p></div>
              <div><h3>{internal?.length || 0}</h3><p>Sales Reps</p></div>
              <div><h3>{company?.seats_left || 0}</h3><p>Remaining</p></div>
            </div>
            <div className="seat-list">
              <Header font="sans" size="3">
                Employees ({employees?.length || 0})
              </Header>
              <Table
                className="team-table"
                striped={true}
                columns={getColumns()}
                rowKey={record => record.id}
                dataSource={employees}
                pagination={false}
                locale={{ emptyText: 'No employees yet' }}
              />
            </div>
             <div className="seat-list">
              <Header font="sans" size="3">
                Sales reps ({internal?.length || 0})
              </Header>
              <Table
                className="team-table"
                striped={true}
                columns={getColumns()}
                rowKey={record => record.id}
                dataSource={internal}
                pagination={false}
                locale={{ emptyText: 'No sales reps yet' }}
              />
            </div>
          </div>
        )}

      </Modal>
    );
  }
}

export default withRouter(SeatInfoModal);
