import React, { Component } from 'react';
import { Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Guide from '_styleguide/Guide/Guide';
import ActionSelect from './_components/ActionSelect/ActionSelect';
import CustomFieldSelect from './_components/CustomFieldSelect/CustomFieldSelect';
import SellXFieldSelect from './_components/SellXFieldSelect/SellXFieldSelect';
import './FieldMapping.scss';

export default class FieldMapping extends Component {
  componentDidMount = () => {
    const mappedData = this.props.data.map(d => {
      if (this.props.historicalMap) {
        d.mapType = !d.leadProperty && !d.mapType ? 'unmatched' : d.mapType;
      } else {
        d.mapType = d.leadProperty ? 'mapped' : 'unmatched';
      }
      return d;
    });
    this.props.updateData(mappedData);
  };
  updateMapProperty = (csvKey, value, field) => {
    let mappedData = this.props.data.map(d => {
      if (d.csvKey === csvKey) d[field] = value;
      return d;
    });
    this.props.updateData(mappedData);
  };
  render() {
    const {
      data,
      isDataCompliant,
      alertText = 'Your leads are ready to be imported into the campaign!'
    } = this.props;

    const unmatchedCount = data.filter(d => {
      return (
        d.mapType === 'unmatched' ||
        (d.mapType === 'mapped' && d.leadProperty === 'custom_fields') || 
        (d.mapType === 'mapped' && !d.leadProperty) ||
        (d.mapType === 'custom' && (!d.customType || !d.customName))
      );
    }).length;
    const readyForSubmit = unmatchedCount <= 0;
    let mappedCount = 0;
    const chosen = data
      .map(d => {
        if (d.mapType === 'mapped' && d.leadProperty) mappedCount++;
        return d.mapType === 'mapped' && d.leadProperty;
      })
      .filter(f => f !== null);

    return (
      <div className="field-mapping">
        {unmatchedCount > 0 ? (
          <Guide
            type="alert"
            dismissable={false}
            id="csv-mapping-status"
            message={
              <span>
                <b>
                  {unmatchedCount} unmatched field{unmatchedCount > 1 ? 's' : ''}
                </b>{' '}
                needs some attention
              </span>
            }
          />
        ) : null}
        {!isDataCompliant ? (
          <Guide
            type="alert"
            dismissable={false}
            id="csv-compliance-status"
            message={
              <span>
                Your data may contain international contacts that we do not presently support.
                Please remove and try again. Click Import again to continue.
              </span>
            }
          />
        ) : null}
        {readyForSubmit ? (
          <Guide
            type="info"
            dismissable={false}
            id="csv-mapping-success"
            message={
              <span>
                <b>
                  {mappedCount} field{mappedCount > 1 ? 's' : ''}
                </b>{' '}
                successfully mapped. {alertText}
              </span>
            }
          />
        ) : null}
        <table className="csv-fields">
          <thead>
            <tr>
              <th>Imported from file</th>
              <th>Action</th>
              <th>Sellx lead information</th>
            </tr>
          </thead>
          <tbody>
            {data.map(d => {
              const isCustomClass = d.mapType === 'custom' && d.customType ? 'custom-row' : '';
              const isUnmapped =
                d.mapType === 'unmatched' ||
                (d.mapType === 'mapped' && !d.leadProperty) ||
                (d.mapType === 'mapped' && d.leadProperty === 'custom_fields') ||
                (d.mapType === 'custom' && (!d.customType || !d.customName))
                  ? 'unmapped'
                  : '';

              return (
                <React.Fragment key={'tmap1-' + d.csvKey}>
                  <tr key={'tmap-' + d.csvKey} className={isCustomClass + ' ' + isUnmapped}>
                    <td>
                      <Input value={d.csvKey} size="large" className="forms" disabled={true} />
                      <div className="arrow">
                        <FontAwesomeIcon icon={faChevronRight} />
                      </div>
                    </td>
                    <td>
                      <ActionSelect
                        value={d.mapType}
                        onChange={val => this.updateMapProperty(d.csvKey, val, 'mapType')}
                      />
                      {(d.mapType === 'mapped' || d.mapType === 'custom') && (
                        <div className="arrow">
                          <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                      )}
                    </td>
                    <td>
                      {d.mapType === 'mapped' ? (
                        <SellXFieldSelect
                          value={d.leadProperty}
                          chosen={chosen}
                          onChange={val => this.updateMapProperty(d.csvKey, val, 'leadProperty')}
                          campaignCustomFields={this.props.campaignCustomFields || []}
                        />
                      ) : null}

                      {d.mapType === 'custom' ? (
                        <CustomFieldSelect
                          value={d.customType}
                          onChange={val => this.updateMapProperty(d.csvKey, val, 'customType')}
                        />
                      ) : null}
                    </td>
                  </tr>
                  {d.mapType === 'custom' && d.customType ? (
                    <tr key={'tmap2-' + d.csvKey} className={isUnmapped}>
                      <td colSpan="3">
                        <h4>New field label</h4>
                        <Input
                          value={d.customName || undefined}
                          onChange={e =>
                            this.updateMapProperty(d.csvKey, e.target.value, 'customName')
                          }
                          size="large"
                          className="forms"
                          placeholder="Field name..."
                        />
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
