import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Avatar, Button, Tooltip } from '_seriph';

export const getInviteColumns = (resendLoading, resendInvite, confirmRemoveMember, withdrawTeamRep) => {
  return [
    {
      title: 'Name',
      dataIndex: 'used_by',
      key: 'used_by',
      render: (used_by, data) => {
        const unused = !data.used || !used_by;
        return (
          <div className="emp-info">
            <Avatar type="company" picture={unused ? undefined : used_by.picture} size="small" />
            <div>
              <h4>{data.email}</h4>
              <div className="joined-date">Sent {moment(data.updatedAt * 1).fromNow()}</div>
            </div>
          </div>
        );
      }
    },
    {
      title: '',
      dataIndex: 'used',
      key: 'used',
      align: 'right',
      render: (used, data) => {
        return !used ? (
          <div className="bundle">
            <Button
              loading={resendLoading === data.email}
              type="default"
              size="tiny"
              onClick={() => withdrawTeamRep(data.email, data.id)}
            >
              Withdraw
            </Button>
            <Button
              loading={resendLoading === data.email}
              type="secondary"
              size="tiny"
              onClick={() => resendInvite(data.email)}
            >
              Send a reminder
            </Button>
          </div>
        ) : (
          <Button disabled={true} type="default">
            Joined {moment(data.used_tstamp * 1).fromNow()}
          </Button>
        );
      }
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 40,
      align: 'right',
      render: id => {
        return (
          <div className="emp-delete">
            <Tooltip title="Remove">
              <span onClick={() => confirmRemoveMember('invite', id)}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </span>
            </Tooltip>
          </div>
        );
      }
    }
  ];
};
