import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  IconHistory,
  IconUser,
  IconCoin,
  IconReceipt,
  IconPower,
  IconPuzzle,
  IconMail,
  IconUsers,
  IconBuildingCommunity
} from '@tabler/icons-react';
import Account from './_components/Account/Account';
import EmailSignature from './_components/EmailSignature/EmailSignature';
import PlanPayment from './_components/PlanPayment/PlanPayment';
import Payments from './_components/Payments/Payments';
import Team from './_components/Team/Team';
import Reps from './_components/Reps/Reps';
import Integrations from './_components/Integrations/Integrations';
import { hasAccess } from '_helpers/permissions';
import { Header, Menu, Card } from '_seriph';
import './Settings.scss';

class Settings extends Component {
  componentDidMount = () => {
    if (this.props.reloadCompany) this.props.reloadCompany();
  };
  logoutCallback = () => this.props.history.push('/');
  emailLogout = () => this.props.logoutUser(() => this.props.history.push('/'));
  render() {
    const currentPage = this.props.page || 'account';
    const { company } = this.props;

    return (
      <div id="company-settings">
        <Header className="settings-header">Manage Account</Header>
        <div className="settings-content">
          <div className="settings-menu">
            <Card className="menu-card">
              <Menu mode="vertical" selectedKeys={[currentPage]} className="settings-antd">
                <Menu.Item key="account">
                  <Link to="/settings/account">
                    <IconUser size={16} stroke={1.75} /> Account
                  </Link>
                </Menu.Item>
                {hasAccess(['Admin']) && (
                  <Menu.Item key="paymentmethod">
                    <Link to="/settings/paymentmethod">
                      <IconCoin size={16} stroke={1.75} /> Plan & Billing
                    </Link>
                  </Menu.Item>
                )}
                {hasAccess(['Admin', 'Manager']) && (
                  <Menu.Item key="payments">
                    <Link to="/settings/payments">
                      <IconReceipt size={16} stroke={1.75} /> Payments / Receipts
                    </Link>
                  </Menu.Item>
                )}
                {hasAccess(['Admin']) && (
                  <Menu.Item key="team">
                    <Link to="/settings/team">
                      <IconBuildingCommunity size={16} stroke={1.75} /> My Team { company?.num_employees ? `(${company?.num_employees})` : ''}
                    </Link>
                  </Menu.Item>
                )}
                {hasAccess(['Admin']) && (
                  <Menu.Item key="reps">
                    <Link to="/settings/reps">
                      <IconUsers size={16} stroke={1.75} /> My Reps { company?.num_reps ? `(${company?.num_reps})` : ''}
                    </Link>
                  </Menu.Item>
                )}
                <Menu.Item key="signature">
                  <Link to="/settings/signature">
                    <IconMail size={16} stroke={1.75} /> Email Signature
                  </Link>
                </Menu.Item>
                {hasAccess(['Admin', 'Manager']) && (
                  <Menu.Item key="integrations">
                    <Link to="/settings/integrations">
                      <IconPuzzle size={16} stroke={1.75} /> Integrations
                    </Link>
                  </Menu.Item>
                )}
                {false && (
                  <Menu.Item key="security">
                    <Link to="/settings/security">
                      <IconHistory size={16} stroke={1.75} /> Security
                    </Link>
                  </Menu.Item>
                )}
                <Menu.Divider />
                <Menu.Item key="logout" onClick={() => this.props.logoutUser(this.logoutCallback)}>
                  <IconPower size={16} stroke={1.75} /> Log out
                </Menu.Item>
              </Menu>
            </Card>
          </div>
          <div className="settings-main">
            {currentPage === 'account' && (
              <Account
                client={this.props.client}
                myself={this.props.user}
                logout={this.emailLogout}
              />
            )}
            {currentPage === 'signature' && (
              <EmailSignature
                client={this.props.client}
                myself={this.props.user}
                company={this.props.company}
              />
            )}
            {currentPage === 'team' && (
              <Team
                client={this.props.client}
                myself={this.props.user}
                company={this.props.company}
                reloadCompany={this.props.reloadCompany}
              />
            )}
            {currentPage === 'reps' && (
              <Reps
                client={this.props.client}
                myself={this.props.user}
                company={this.props.company}
                campaigns={[]}
                user={this.props.user}
                reloadCompany={this.props.reloadCompany}
              />
            )}
            {currentPage === 'paymentmethod' && (
              <PlanPayment 
                client={this.props.client} 
                myself={this.props.user}
                company={this.props.company}
                showSubscribeModal={this.props.showSubscribeModal}
              />
            )}
            {currentPage === 'payments' && (
              <Payments client={this.props.client} myself={this.props.user} />
            )}
            {currentPage === 'integrations' && (
              <Integrations
                client={this.props.client}
                company={this.props.company}
                myself={this.props.user}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(Settings));
