import React, { Component } from 'react';
import { Dropdown } from 'antd';
import { IconChevronDown } from '@tabler/icons-react';
import './CampaignDropdown.scss';

const CampaignSelect = props => (
  <div id="dash-list">
    <div className="filter-container">
      {props.allowAll && !props.singleCampaign && (
        <div className="filter-options">
          <div
            onClick={() => props.selectCampaign('all')}
            className={props.campaign === 'all' ? 'filter-option active' : 'filter-option'}
          >
            All Campaigns (
            {
              props.campaigns.filter(c => ['active', 'inactive', 'completed'].includes(c.status))
                .length
            }
            )
          </div>
        </div>
      )}
      {!props.singleCampaign && (
        <React.Fragment>
          <div className="filter-title">Campaign Status</div>
          <div className="filter-options">
            <div
              onClick={() => props.selectCampaign('active')}
              className={props.campaign === 'active' ? 'filter-option active' : 'filter-option'}
            >
              <span style={{ background: '#30F09E' }} />
              Active ({props.campaigns.filter(c => c.status === 'active').length})
            </div>
            <div
              onClick={() => props.selectCampaign('inactive')}
              className={props.campaign === 'inactive' ? 'filter-option active' : 'filter-option'}
            >
              <span style={{ background: '#FF6C4B' }} />
              Paused ({props.campaigns.filter(c => c.status === 'inactive').length})
            </div>
            <div
              onClick={() => props.selectCampaign('completed')}
              className={props.campaign === 'completed' ? 'filter-option active' : 'filter-option'}
            >
              <span style={{ background: '#4353FF' }} />
              Completed ({props.campaigns.filter(c => c.status === 'completed').length})
            </div>
          </div>
        </React.Fragment>
      )}
      {!props.hideList && (
        <React.Fragment>
          {!props.singleCampaign && <div className="filter-title">All Campaigns</div>}
          <div className="filter-options">
            {props.campaigns.map(c => (
              <div
                onClick={() => props.selectCampaign(c.id)}
                key={'cs-' + c.id}
                className={props.campaign === c.id ? 'filter-option active' : 'filter-option'}
              >
                {c.name}
              </div>
            ))}
          </div>
        </React.Fragment>
      )}
    </div>
  </div>
);

export default class CampaignDropdown extends Component {
  getCampaignName = () => {
    if (this.props.campaign === 'all') return 'All Campaigns';
    if (this.props.campaign === 'active') return 'Active Campaigns';
    if (this.props.campaign === 'inactive') return 'Paused Campaigns';
    if (this.props.campaign === 'completed') return 'Completed Campaigns';
    const campaigns = this.props.campaigns || [];
    const campaign = campaigns.find(c => c.id === this.props.campaign);
    return campaign && campaign.name ? campaign.name : 'Select a campaign';
  };
  getCampaignCount = campaigns => {
    if (this.props.campaign === 'all') return campaigns.filter(c => ['active', 'inactive', 'completed'].includes(c.status)).length;
    if (this.props.campaign === 'active') return campaigns.filter(c => c.status === 'active').length;
    if (this.props.campaign === 'inactive') return campaigns.filter(c => c.status === 'inactive').length;
    if (this.props.campaign === 'completed') return campaigns.filter(c => c.status === 'completed').length;
    return 0;
  }
  render() {
    const { selectCampaign, campaign, campaigns, allowAll, singleCampaign, allowDraft, hideList } =
      this.props;

    let filtered = allowDraft ? campaigns : campaigns.filter(c => c.status !== 'draft');
    const campaignCount = this.getCampaignCount(filtered);
    return (
      <div id="campaign-dropdown" className="dash-drop">
        <Dropdown
          overlay={
            <CampaignSelect
              allowAll={allowAll}
              singleCampaign={singleCampaign}
              selectCampaign={selectCampaign}
              campaign={campaign}
              campaigns={filtered}
              hideList={hideList}
            />
          }
          placement={this.props.placement || 'bottomRight'}
        >
          <h4 className={this.props.size === 'medium' ? 'medium' : ''}>
            {this.getCampaignName()} { campaignCount > 0 ? `(${campaignCount})` : ''}
            <IconChevronDown size={18} stroke={1.75} />
          </h4>
        </Dropdown>
      </div>
    );
  }
}
