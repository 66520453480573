import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Skeleton } from 'antd';
import Card from '_shared/Card/Card';
import company from '_assets/img/company.png';
import './Featured.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Company = props => {
  const defaultImage = <img src={company} alt={props.name} />;
  return (
    <Card className="company-card">
      <div className="featured-company">
        <div className="fc-box" onClick={() => props.history.push(`/company/${props.id}`)}>
          {props.picture ? <img src={props.picture} alt={props.name} /> : defaultImage}
          <h3>{props.name}</h3>
        </div>
        <p onClick={() => props.updateCompany(props.id, props.name)}>
          {props.opportunities} {props.opportunities === 1 ? 'opportunity' : 'opportunities'}{' '}
          available
        </p>
      </div>
    </Card>
  );
};

class Featured extends Component {
  render() {
    return (
      <div id="discover-featured">
        <div className="featured-title">
          <h1>Featured Companies</h1>
          <a href="/">
            All Companies
            <FontAwesomeIcon icon={faChevronRight} />
          </a>
        </div>
        <div className="featured-companies">
          {this.props.loading ? (
            <Row gutter={30}>
              <Col span={8}>
                <Card>
                  <Skeleton active paragraph={{ rows: 2 }} />
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <Skeleton active paragraph={{ rows: 2 }} />
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <Skeleton active paragraph={{ rows: 2 }} />
                </Card>
              </Col>
            </Row>
          ) : (
            <Row gutter={30}>
              {this.props.companies.map(c => (
                <Col key={'feat' + c.id} span={8}>
                  <Company
                    history={this.props.history}
                    id={c.id}
                    name={c.name}
                    opportunities={c.campaigns.length}
                    picture={c.logo}
                    favorite={false}
                    updateCompany={this.props.updateCompany}
                  />
                </Col>
              ))}
            </Row>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(Featured);
