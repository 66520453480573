import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Dropdown, notification } from 'antd';
import { updateCampaignStatus, deleteCampaign } from 'Mutations/Company/campaign';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import EndCampaign from './_modals/EndCampaign/EndCampaign';
import EndThresholdCampaign from './_modals/EndThresholdCampaign/EndThresholdCampaign';
import MessageOwner from './_modals/MessageOwner/MessageOwner';
import LeaveCampaign from './_modals/LeaveCampaign/LeaveCampaign';
import { getFromHopper } from '_graphql/mutations/lead';
import { createDuplicate, importLeads } from '_graphql/mutations/campaign';
import axios from 'axios';
import { getErrors, downloadFile } from '_assets/js/helpers';
import { hasAccess } from '_helpers/permissions';
import { Menu, Modal, message } from '_seriph';
import { IconDots } from '@tabler/icons-react';
import './CampaignActions.scss';

class CampaignActions extends Component {
  state = {
    endModal: false,
    messageOwner: false,
    leaveCampaign: false
  };
  duplicateCampaign = campaignId => {
    this.props.apollo
      .mutate({ variables: { campaignId: campaignId }, mutation: createDuplicate })
      .then(result => {
        if (result && result.data && result.data.createDuplicate) {
          this.props.history.push('/wizard/' + result.data.createDuplicate);
        } else {
          throw new Error();
        }
      })
      .catch(err => message.error(getErrors(err) || 'Could not update campaign, try again'));
  };
  updateCampaign = (campaignId, status) => {
    this.props.apollo
      .mutate({ variables: { id: campaignId, status }, mutation: updateCampaignStatus })
      .then(result => {
        if (result && result.data && result.data.updateCampaignStatus) this.props.reload(true);
        if (status === 'active') message.success('Campaign is now active again');
        if (status === 'inactive') message.success('Campaign has been paused');
      })
      .catch(err => message.error(getErrors(err) || 'Could not update campaign, try again'));
  };
  deactiveCampaign = (campaignId, status) => {
    Modal.confirm({
      title: 'Are you sure you want to pause this campaign?',
      content:
        'Once your campaign is paused, your listing will be removed from searches and Reps will no longer be able to apply to join the campaign.  You can resume your campaign at any time.',
      onOk: () => this.updateCampaign(campaignId, status)
    });
  };
  confirmLaunch = (campaignId, status) => {
    Modal.confirm({
      title: 'Are you sure you want to resume this campaign?',
      content:
        'Once your campaign has resumed it will be made public and Reps can begin applying to join your campaign.  DO NOT resume if you are not ready to accept Reps.',
      onOk: () => this.updateCampaign(campaignId, status)
    });
  };
  removeCampaign = campaignId => {
    Modal.confirm({
      title: 'Are you sure you want to remove this campaign?',
      content:
        'DANGER: This cannot be undone, all data will be lost if you remove this campaign.  Only draft campaigns can currently be removed. ',
      onOk: () => {
        this.props.apollo
          .mutate({ variables: { id: campaignId }, mutation: deleteCampaign })
          .then(result => {
            if (result && result.data && result.data.deleteCampaign) {
              message.success('Campaign successfully removed');
              this.props.reload(true);
            } else {
              throw new Error();
            }
          })
          .catch(err => message.error(getErrors(err) || 'Could not remove campaign, try again'));
      }
    });
  };
  importLeads = () => {
    this.props.apollo
      .mutate({ variables: { campaign_id: this.props.campaign.id }, mutation: importLeads })
      .then(result => {
        if (result && result.data && result.data.importLeads > 0) {
          message.success('New leads added to the campaign');
        } else {
          message.error('No new leads to add to the campaign');
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not add leads to the campaign, try again');
      });
  };
  exportLeads = () => {
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_PATH}/v1/leads/export`,
      headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') },
      data: {
        campaign_id: this.props.campaign.id
      }
    })
      .then(res => {
        if (res && res.data && res.data.csv) {
          downloadFile(
            `sellx_leads_${res.data.amount}_export.csv`,
            'data:text/csv;charset=UTF-8,\uFEFF' + encodeURIComponent(res.data.csv)
          );
          message.success('Leads (' + res.data.amount + ') successfully exported');
        } else {
          message.error('Export failed, try again');
        }
      })
      .catch(() => message.error('Export failed, try again'));
  };
  getFromHopper = campaign => {
    this.props.apollo
      .mutate({ variables: { qty: 1, campaign_id: campaign.id }, mutation: getFromHopper })
      .then(result => {
        if (result?.data?.getFromHopper?.leads) {
          const [{ lead_id, work_started }] = result.data.getFromHopper.leads;
          if (work_started) {
            notification.open({
              message: (
                <div>
                  <FontAwesomeIcon icon={faInfoCircle} />
                  This lead was previously worked on
                </div>
              ),
              description:
                'Please continue work where the previous sales rep left off.  Make sure to review previous activity, emails, and notes prior to reaching out.',
              duration: 20
            });
          } else {
            message.success('Lead started for ' + campaign.name);
          }
          this.props.history.push(`/leads/${lead_id}`);
        } else {
          throw new Error('Error pulling in lead, try again');
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Error pulling in lead, try again', 8);
      });
  };
  showEndModal = campaign => this.setState({ endModal: campaign });
  removeEndModal = () => this.setState({ endModal: false });
  showMessageOwnerModal = campaign => this.setState({ messageOwner: campaign });
  removeMessageOwnerModal = () => this.setState({ messageOwner: false });
  showLeaveCampaignModal = campaign => this.setState({ leaveCampaign: campaign });
  removeLeaveCampaignModal = () => this.setState({ leaveCampaign: false });
  render() {
    const { campaign, reload, stats } = this.props;
    let isActive = false;
    if (this.props.user.role_type !== 'company') {
      const findAgent = campaign.agents.find(a => a.agent_id === this.props.user.id);
      if (findAgent && findAgent.active) isActive = true;
    }

    const versionTwo = campaign && campaign.wizard_version === '2' ? true : false;

    const canCreate = campaign.status === 'active' ? true : false;
    const menu =
      this.props.user.role_type === 'company' ? (
        <Menu>
          {campaign.status !== 'draft' && stats && (
            <Menu.Item>
              <Link to={`/analytics?campaign=${campaign.id}`}>View Analytics</Link>
            </Menu.Item>
          )}
          {campaign.status !== 'draft' && (
            <Menu.Item>
              <Link to={`/leads?campaign=${campaign.id}`}>Manage Leads</Link>
            </Menu.Item>
          )}
          {campaign.status !== 'draft' && hasAccess(['Admin', 'Manager']) && (
            <Menu.Item onClick={() => this.exportLeads()}>Export Leads</Menu.Item>
          )}
          {process.env.REACT_APP_CLIENT_PATH === 'http://localhost:3000' && (
            <Menu.Item onClick={this.importLeads}>Import Missing Leads</Menu.Item>
          )}
          {campaign.status === 'active' && hasAccess(['Admin', 'Manager']) && (
            <Menu.Item onClick={() => this.deactiveCampaign(campaign.id, 'inactive')}>
              Pause
            </Menu.Item>
          )}
          {campaign.status !== 'completed' && !versionTwo && hasAccess(['Admin', 'Manager']) && (
            <Menu.Item>
              <Link to={`/campaign/${campaign.id}/edit`}>Edit Campaign</Link>
            </Menu.Item>
          )}
          {campaign.status !== 'completed' && versionTwo && hasAccess(['Admin', 'Manager']) && (
            <Menu.Item>
              <Link to={`/wizard/${campaign.id}`}>Edit Campaign</Link>
            </Menu.Item>
          )}
          {campaign.status === 'draft' && versionTwo && hasAccess(['Member']) && (
            <Menu.Item>
              <Link to={`/wizard/${campaign.id}`}>Edit Campaign</Link>
            </Menu.Item>
          )}
          {true && versionTwo && (
            <Menu.Item onClick={() => this.duplicateCampaign(campaign.id)}>
              Duplicate Campaign
            </Menu.Item>
          )}
          {campaign.status !== 'completed' && <Menu.Divider />}
          {(campaign.status === 'active' || campaign.status === 'inactive') &&
            hasAccess(['Admin', 'Manager']) && (
              <Menu.Item className="end-it danger" onClick={() => this.showEndModal(campaign)}>
                End Campaign
              </Menu.Item>
            )}
          {campaign.status === 'draft' && hasAccess(['Admin', 'Manager']) && (
            <Menu.Item className="end-it danger" onClick={() => this.removeCampaign(campaign.id)}>
              Remove Campaign
            </Menu.Item>
          )}
          {campaign.status === 'inactive' && hasAccess(['Admin', 'Manager']) && (
            <Menu.Item
              className="start-it"
              onClick={() => this.confirmLaunch(campaign.id, 'active')}
            >
              Resume Campaign
            </Menu.Item>
          )}
        </Menu>
      ) : (
        <Menu>
          {canCreate && campaign.objective === 'leadgen' && (
            <Menu.Item>
              <Link to={`/leads/new?campaign=${campaign.id}`}>New Lead</Link>
            </Menu.Item>
          )}
          {canCreate && campaign.objective === 'qualify' && (
            <Menu.Item onClick={() => this.getFromHopper(campaign)}>New Lead</Menu.Item>
          )}
          {stats && (
            <Menu.Item>
              <Link to={`/analytics?campaign=${campaign.id}`}>View Analytics</Link>
            </Menu.Item>
          )}
          {
            <Menu.Item>
              <Link to={`/leads?campaign=${campaign.id}`}>Manage Leads</Link>
            </Menu.Item>
          }
          {(campaign.status === 'active' || campaign.status === 'inactive') && (
            <Menu.Item onClick={() => this.showMessageOwnerModal(campaign)}>
              Send a Message
            </Menu.Item>
          )}
          {campaign.status !== 'completed' && isActive && <Menu.Divider />}
          {campaign.status !== 'completed' && isActive && (
            <Menu.Item
              className="end-it danger"
              onClick={() => this.showLeaveCampaignModal(campaign)}
            >
              Leave Campaign
            </Menu.Item>
          )}
        </Menu>
      );

    return (
      <React.Fragment>
        <div className="campaign-actions">
          <Dropdown
            overlayClassName="seriph-dropdown"
            overlay={menu}
            trigger={['click']}
            placement="bottomRight"
          >
            <div className="ell-box">
              <IconDots />
            </div>
          </Dropdown>
        </div>
        {this.state.endModal && campaign.threshold ? (
          <EndThresholdCampaign
            client={this.props.apollo}
            user={this.props.user}
            campaign={this.state.endModal}
            removeModal={this.removeEndModal}
            reload={reload}
          />
        ) : null}
        {this.state.endModal && !campaign.threshold ? (
          <EndCampaign
            client={this.props.apollo}
            user={this.props.user}
            campaign={this.state.endModal}
            removeModal={this.removeEndModal}
            reload={reload}
          />
        ) : null}
        {this.state.messageOwner ? (
          <MessageOwner
            client={this.props.apollo}
            user={this.props.user}
            campaign={this.state.messageOwner}
            removeModal={this.removeMessageOwnerModal}
          />
        ) : null}

        {this.state.leaveCampaign ? (
          <LeaveCampaign
            client={this.props.apollo}
            user={this.props.user}
            campaign={this.state.leaveCampaign}
            removeModal={this.removeLeaveCampaignModal}
            reload={() => reload(true)}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user, ...state.apollo };
};

export default withRouter(connect(mapStateToProps, {})(CampaignActions));
