export const leadColors = {
  created: '#B0D0E8',
  'in-progress': '#BDEAFF',
  review: '#B5B5FD',
  disputed: '#FDC6B5',
  completed: '#C1DDBB',
  generated: '#9DC0B7',
  qualified: '#C1DDBB',
  'not-interested': '#FDF6B5',
  unassigned: '#C4CBD9',
  invalid: '#8E6B71'
};

export const otherColors = [
  '#746BBC',
  '#C79086',
  '#B9D8D4',
  '#D9C19E',
  '#8E6B71',
  '#A2BAD2',
  '#DBBCA9',
  '#5F7989',
  '#809F8A',
  '#DFC5C1',
  '#090E15',
  '#D2D0FB',
  '#D7DDE9',
  '#7D7B78'
];
