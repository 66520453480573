import React, { Component } from 'react';
import { Modal, Button, Dropdown, Tooltip, message } from 'antd';
import { createNote } from '_graphql/mutations/lead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faMinus,
  faLongArrowAltRight,
  faTextHeight,
  faStickyNote,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import Questions from './_components/Questions/Questions';
import Benefits from './_components/Benefits/Benefits';
import Objections from './_components/Objections/Objections';
import Loading from '_shared/Loading/Loading';
import ReactQuill from 'react-quill';
import './Call.scss';

const MenuItem = props => (
  <div className={`menu-item ${props.name === props.tab ? 'active' : ''}`} onClick={props.onClick}>
    {props.title}
    <FontAwesomeIcon icon={faLongArrowAltRight} />
  </div>
);

const options = [
  { name: 'Small / 14pt', value: 'sm' },
  { name: 'Medium / 16pt', value: 'md' },
  { name: 'Large / 18pt', value: 'lg' },
  { name: 'Extra Large / 20pt', value: 'xl' }
];
const FontOptions = props => (
  <div id="font-options">
    {options.map((o, i) => (
      <div
        key={'fopt-' + i}
        className={props.value === o.value ? 'active' : ''}
        onClick={() => props.changeFontSize(o.value)}
      >
        {props.value === o.value ? <FontAwesomeIcon icon={faCheck} /> : null}
        {o.name}
      </div>
    ))}
  </div>
);

const modules = {
  toolbar: ['bold', 'italic', 'underline'],
  clipboard: { matchVisual: false }
};
const PostNote = props => (
  <div id="note-input">
    <div className="input-content">
      <ReactQuill
        modules={modules}
        value={props.note}
        onChange={note => props.updateNote(note)}
        placeholder="Write a note about your lead..."
      />
      <Button
        className="btnx btnx-primary"
        disabled={!props.note}
        loading={props.saving}
        onClick={props.createNote}
      >
        Post note
      </Button>
    </div>
  </div>
);

export default class Call extends Component {
  state = {
    visible: true,
    saving: false,
    tab: this.props.sequence && this.props.sequence.templateId ? 'script' : 'questions',
    hasScript: this.props.sequence && this.props.sequence.templateId ? true : false,
    fontVisible: false,
    noteVisible: false,
    fontSize: 'md',
    note: ''
  };
  createNote = () => {
    const lead = this.props.lead || {};
    this.setState({ saving: true });
    this.props.client
      .mutate({
        variables: {
          lead_id: lead.id,
          campaign_id: this.props.campaign.id,
          content: this.state.note
        },
        mutation: createNote
      })
      .then(result => {
        if (result && result.data && result.data.createNote) {
          message.success('Note added for ' + lead.first_name);
          this.setState({ note: '', noteVisible: false });
        }
        this.setState({ saving: false });
      })
      .catch(() => {
        this.setState({ saving: false });
        message.error('Error creating note, try again.');
      });
  };
  handleFontVisibleChange = fontVisible => {
    this.setState({ fontVisible });
  };
  handleNoteVisibleChange = noteVisible => {
    this.setState({ noteVisible });
  };
  updateNote = note => this.setState({ note });
  changeFontSize = fontSize => this.setState({ fontSize });
  hideModal = () => this.setState({ visible: false });
  render() {
    const { tab, fontSize, fontVisible, noteVisible, note, saving, hasScript } = this.state;
    const { loading, script, questions, lead, callSid } = this.props;
    return (
      <Modal
        wrapClassName="sequence-call-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={true}
      >
        <div className="close-options">
          <div onClick={this.hideModal}>
            <FontAwesomeIcon icon={faMinus} />
          </div>
          {!callSid ? (
            <div onClick={this.props.hideCallPopout}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          ) : null}
        </div>
        <div className="options">
          <Dropdown
            overlay={<FontOptions value={fontSize} changeFontSize={this.changeFontSize} />}
            trigger={['click']}
            placement="bottomRight"
            arrow
            visible={fontVisible}
            onVisibleChange={this.handleFontVisibleChange}
          >
            <Tooltip
              placement="left"
              overlayClassName="sellx-tooltip sellx-tooltip-small"
              title="Font size"
            >
              <div className={fontVisible ? 'active' : ''}>
                <FontAwesomeIcon icon={faTextHeight} />
              </div>
            </Tooltip>
          </Dropdown>
          <Dropdown
            overlay={
              <PostNote
                note={note}
                updateNote={this.updateNote}
                saving={saving}
                createNote={this.createNote}
              />
            }
            trigger={['click']}
            placement="bottomRight"
            arrow
            visible={noteVisible}
            onVisibleChange={this.handleNoteVisibleChange}
          >
            <Tooltip
              placement="left"
              overlayClassName="sellx-tooltip sellx-tooltip-small"
              title="Add Note"
            >
              <div className={noteVisible ? 'active' : ''}>
                <FontAwesomeIcon icon={faStickyNote} />
              </div>
            </Tooltip>
          </Dropdown>
        </div>
        <div className="seq-body">
          <div className="seq-menu">
            <div className="menu-title">
              <span style={{ background: '#4353FF' }} />
              {hasScript ? 'Script & ' : ''}Questions
            </div>
            {hasScript ? (
              <MenuItem
                name="script"
                title="Call Script"
                tab={tab}
                onClick={() => this.setState({ tab: 'script' })}
              />
            ) : null}
            {questions && questions.length > 0 ? (
              <MenuItem
                name="questions"
                title="Questions to ask"
                tab={tab}
                onClick={() => this.setState({ tab: 'questions' })}
              />
            ) : null}
            <div className="menu-sep" />
            <div className="menu-title">
              <span style={{ background: '#090E15' }} />
              Call Support
            </div>
            <MenuItem
              name="benefits"
              title="Key Product Benefits"
              tab={tab}
              onClick={() => this.setState({ tab: 'benefits' })}
            />
            <MenuItem
              name="objections"
              title="Possible Objections"
              tab={tab}
              onClick={() => this.setState({ tab: 'objections' })}
            />
          </div>
          <div className="seq-content">
            {loading ? (
              <Loading />
            ) : (
              <div className={`seq-text quill-render ${fontSize}`}>
                {tab === 'script' ? (
                  <div className="call-script">
                    <h3 className="call-h3">Call script</h3>
                    <div dangerouslySetInnerHTML={{ __html: script }} />
                  </div>
                ) : null}
                {tab === 'questions' ? (
                  <Questions
                    lead={lead}
                    questions={questions}
                    answers={this.props.answers}
                    updateLead={this.props.updateLead}
                  />
                ) : null}
                {tab === 'benefits' ? <Benefits benefits={this.props.benefits} /> : null}
                {tab === 'objections' ? <Objections objections={this.props.objections} /> : null}
              </div>
            )}
          </div>
        </div>
        <div className="seq-actions">{this.props.callFooter}</div>
      </Modal>
    );
  }
}
