import React, { Component } from 'react';
import { Modal, Button, message } from '_seriph';
import { Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import './CancelMeetingModal.scss';
import { cancelMeeting } from '_graphql/mutations/lead';

export default class CancelMeetingModal extends Component {
  state = {
    visible: true,
    maskClosable: true,
    loading: false,
    reason: ''
  };
  hideModal = () => {
    this.setState({ visible: false });
  };
  cancelMeeting = () => {
    const { id, calendly } = this.props.lead;
    const { event_id, calendar_type } = calendly;

    this.props.client
      .mutate({
        variables: {
          lead_id: id,
          event_id,
          calendar_type,
          reason: this.state.reason
        },
        mutation: cancelMeeting
      })
      .then(result => {
        if (result?.data?.cancelMeeting) {
          this.props.reload(id);
          message.success('Meeting canceled');
          this.hideModal();
        } else {
          message.error('Error cancelling meeting, try again');
        }
      })
      .catch(err => {
        console.log(err);
        message.error('Lead not found, try again');
      });
  };
  render() {
    const { loading, reason } = this.state;
    const { removeModal, lead } = this.props;
    const { calendar_type } = lead.calendly;

    return (
      <Modal
        wrapClassName="cancel-meeting-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={this.state.maskClosable}
        width={456}
      >
        <Modal.Close onClick={this.hideModal} />
        <div className="stage-one">
          <div className="stage-icon">
            <FontAwesomeIcon icon={faExclamation} />
          </div>
          <h3>Cancel Meeting</h3>
          <p>You will need to reschedule the meeting to submit as interested.</p>
          {calendar_type === 'calendly' && (
            <>
              <h4>Reason for cancellation</h4>
              <Input.TextArea
                className="forms forms-textarea"
                rows={6}
                placeholder="Meeting was canceled because..."
                autoSize={false}
                value={reason}
                onChange={e => this.setState({ reason: e.target.value })}
              />
            </>
          )}
          <div className="stage-actions">
            <Button type="secondary" onClick={this.hideModal}>
              Close
            </Button>
            <Button
              disabled={calendar_type === 'calendly' && reason === ''}
              type="primary"
              loading={loading}
              onClick={this.cancelMeeting}
            >
              Cancel Meeting
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}
