import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Input, Header, RadioButtonList, CheckboxList, Select } from '_seriph';
import logo from '_assets/img/logo_black.svg';
import { companyStages } from '_constants/onboarding';
import classNames from 'classnames';
import { createCompanyApplication, updateCompanyApplication } from '_graphql/mutations/company';
import { getCompanyApplication } from '_graphql/queries/company';
import { validateEmail, validatePhone } from '_graphql/queries/integration';
import { IconCheck, IconRefresh } from '@tabler/icons-react';
import { InlineWidget, CalendlyEventListener } from 'react-calendly';

import './CompanyOnboarding.scss';
import 'animate.css';

async function delay(time) {
  return new Promise(function (resolve) {
    setTimeout(resolve, time);
  });
}

class CompanyOnboarding extends Component {
  state = {
    form: {
      name: '',
      email: '',
      phone: '',
      company_name: '',
      company_website: '',
      company_size: '',
      needs: [],
      industries: '',
      hear_about: [],
      hear_about_other: '',
      desired_start: '',
      desired_engagement: '',
      ok_with_remote: '',
      ok_with_remote_explanation: '',
      is_calendly_event_scheduled: false,
      status: 'started'
    },
    email_valid: null,
    phone_valid: null,
    name_valid: null,
    website_valid: null,
    stage: 1,
    animating: 'none',
    applicationExists: false,
    applicationId: null
  };

  async componentDidMount() {
    // fixes "zoom when focusing" behavior on mobile safari
    document
      .querySelector('meta[name=viewport]')
      .setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');

    const storedApplicationId = localStorage.getItem('applicationId');
    if (storedApplicationId) {
      console.log(`found stored application: ${storedApplicationId}`);

      const result = await this.props.client.query({
        variables: { id: storedApplicationId },
        query: getCompanyApplication
      });
      const prevApplication = result?.data?.getCompanyApplication;
      if (prevApplication) {
        this.setState({
          form: prevApplication,
          applicationExists: true,
          applicationId: storedApplicationId
        });
        await delay(300);
        this.validateName(this.state.form.name, 'name_valid');
        this.validatePhone(this.state.form.phone, 'phone_valid');
        this.validateEmail(this.state.form.email, 'email_valid');
        this.validateWebsite(this.state.form.company_website, 'website_valid');
        if (this.state.form.is_calendly_event_scheduled) {
          this.setState({
            stage: 8
          });
        }
      }
    }
  }

  async advanceStage() {
    if (this.state.stage < companyStages.length) {
      this.setState({
        animating: 'next_start'
      });
      await delay(600);
      this.setState({
        animating: 'next_end',
        stage: this.state.stage + 1
      });
      if (this.state.stage === 2 && !this.state.applicationExists) {
        this.createCompanyApplication();
      } else {
        this.updateCompanyApplication();
      }
      await delay(600);
      this.setState({
        animating: 'none'
      });
    }
  }
  async previousStage() {
    if (this.state.stage > 1) {
      this.setState({
        animating: 'prev_start'
      });
      await delay(600);
      this.setState({
        animating: 'prev_end',
        stage: this.state.stage - 1
      });
      await delay(600);
      this.setState({
        animating: 'none'
      });
    }
  }

  async createCompanyApplication() {
    const result = await this.props.client.query({
      variables: { ...this.state.form },
      query: createCompanyApplication
    });
    const applicationId = result?.data?.createCompanyApplication;
    this.setState({
      applicationExists: true,
      applicationId
    });

    localStorage.setItem('applicationId', applicationId);
  }

  async updateCompanyApplication() {
    await this.props.client.query({
      variables: { id: this.state?.applicationId, ...this.state?.form },
      query: updateCompanyApplication
    });
  }

  async validateEmail(email, stateKey) {
    console.log('validate email');
    if (email.length < 4) {
      this.setState({
        [stateKey]: false
      });
      return;
    }
    if (email === 'workaround') {
      this.setState({
        [stateKey]: true
      });
      return;
    }

    const response = await this.props.client.query({
      variables: { email },
      query: validateEmail
    });
    console.log(response);

    let isValid = response?.data.validateEmail || false;
    console.log(`email isValid: ${isValid}`);

    this.setState({
      [stateKey]: isValid
    });
  }

  async validatePhone(phone, stateKey) {
    console.log('validate phone');
    if (phone.length < 4) {
      this.setState({
        [stateKey]: false
      });
      return;
    }
    if (phone === 'workaround') {
      this.setState({
        [stateKey]: true
      });
      return;
    }

    const response = await this.props.client.query({
      variables: { phone },
      query: validatePhone
    });
    console.log(response);

    let isValid = response?.data.validatePhone || false;
    console.log(`phone isValid: ${isValid}`);

    this.setState({
      [stateKey]: isValid
    });
  }

  async validateName(name, stateKey) {
    let regex = new RegExp(
      "^([a-zA-Z]{2,}\\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\\s?([a-zA-Z]{1,})?)",
      'i'
    );
    let isValid = regex.test(name);

    this.setState({
      [stateKey]: isValid
    });
  }

  async validateWebsite(website, stateKey) {
    let regex = new RegExp(
      '((http|https)://)?(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
    );
    let isValid = regex.test(website);

    this.setState({
      [stateKey]: isValid
    });
  }

  formatPhone(phone, key) {
    var cleaned = ('' + phone).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    let formatted;
    if (match) {
      formatted = `(${match[2]}) ${match[3]} - ${match[4]}`;
    }

    let form = this.state.form;
    form[key] = formatted ?? phone;
    this.setState(form);
  }

  async update(value, key) {
    let form = this.state.form;
    form[key] = value;
    /*
    let updated_website = false;
    if (key === 'email' && this.state.email_valid === true && value.indexOf('gmail') == -1) {
      form.company_website = value.substr(value.indexOf('@') + 1);
      updated_website = true;
    }
   */

    this.setState({ form });

    /*
    if (updated_website) {
      await delay(500);
      this.validateWebsite(form.company_website, 'website_valid');
      this.updateCompanyApplication();
    }
   */
  }

  startOver() {
    localStorage.removeItem('applicationId');
    location.reload();
  }

  async onCalendlyEventScheduled(event) {
    console.log(event);
    const calendlyUrl = event?.data?.payload?.event?.uri;
    this.update(true, 'is_calendly_event_scheduled');
    this.update(calendlyUrl, 'calendly_url');
    this.update('submitted', 'status');
    await delay(500);
    this.updateCompanyApplication();
  }

  render() {
    const { form, stage = 1 } = this.state;
    const percentDone = Math.round((stage / companyStages.length) * 100);
    let { title, subtitle, inputs, textarea } = companyStages.find(cs => cs.stage === stage) ?? {};

    if (form?.is_calendly_event_scheduled) {
      title = 'Walkthrough scheduled!';
      subtitle = (
        <div className="onb-walk">
          <div className="onb-walk-main">
            An email has been sent to <strong>{form?.email || 'your email'}</strong> to confirm your
            onboarding. We’re looking forward to meeting you!
          </div>
          {/*
          <Button size="large" type="primary" onClick={() => {}}>
            Add to your calendar
          </Button>
          <Button size="large" type="default" onClick={() => {}}>
            Reschedule
          </Button>
          */}
          <div className="start-over" onClick={this.startOver}>
            <IconRefresh />
            Start over
          </div>
        </div>
      );
    }

    const hasEmptyFields =
      inputs?.find(i => {
        return this.state?.form?.[i.key]?.length == 0;
      }) != undefined;
    const isInvalid =
      inputs?.find(i => {
        if (!i.validation || !i.validationStateKey) return false;
        return this.state?.[i.validationStateKey] === false;
      }) != undefined;

    const animationClasses = {
      animate__animated: this.state.animating !== 'none',
      animate__fadeOutUp: this.state.animating === 'next_start',
      animate__fadeInUp: this.state.animating === 'next_end',
      animate__fadeOutDown: this.state.animating === 'prev_start',
      animate__fadeInDown: this.state.animating === 'prev_end'
    };

    return (
      <div className="company-onboarding">
        <div
          className={classNames({
            'onb-left': true,
            'full-screen': form?.is_calendly_event_scheduled
          })}
        >
          <div
            className={classNames({
              'onb-left-main': true,
              ...animationClasses
            })}
          >
            <div className="onb-logo-nav">
              <img src={logo} alt={logo} />
            </div>
            <Header type="display" size="1" className="headline">
              {title ?? ''}
            </Header>
            <div className="descript">{subtitle ?? ''}</div>
          </div>
          <div className="onb-left-footer">
            <a target="_blank" rel="noreferrer" href="https://www.sellx.com/">
              sellx.com
            </a>
            <span className="spacer">•</span>
            <a href="mailto:dean@sellx.com">Contact</a>
          </div>
        </div>
        <div
          className={classNames({ 'onb-right': true, hidden: form?.is_calendly_event_scheduled })}
        >
          <div
            className={classNames({
              'onb-right-main': true,
              'extra-wide': stage === 8,
              ...animationClasses
            })}
          >
            {inputs.map(input => {
              let iconRight = null;
              let hasError = false;
              let errorText = '';
              if (form?.[input.key]?.length && input.validationStateKey) {
                const isValid = this.state[input.validationStateKey];
                if (isValid === true) {
                  iconRight = <IconCheck />;
                } else if (isValid === false) {
                  hasError = true;
                  errorText = input.errorText;
                }
              }

              if (input.type === 'text') {
                return (
                  <Input
                    label={input.label}
                    size="large"
                    key={input.key}
                    name={input.key}
                    dark="true"
                    error={hasError}
                    hint={errorText}
                    value={form[input.key]}
                    placeholder={input.placeholder}
                    inputMode={input?.validation === 'phone' ? 'numeric' : null}
                    onChange={e => {
                      this.update(e?.target.value, input.key);
                    }}
                    onBlur={async e => {
                      console.log('onBlur');
                      const value = e?.target.value;
                      if (input.validationStateKey) {
                        if (input.validation == 'email') {
                          await this.validateEmail(value, input.validationStateKey);
                        } else if (input.validation == 'phone') {
                          await this.validatePhone(value, input.validationStateKey);
                        } else if (input.validation == 'name') {
                          await this.validateName(value, input.validationStateKey);
                        } else if (input.validation == 'website') {
                          await this.validateWebsite(value, input.validationStateKey);
                        }
                      }
                      if (input.format === 'phone') {
                        this.formatPhone(value, input.key);
                      }
                    }}
                    iconRight={iconRight}
                  />
                );
              } else if (input.type === 'radio-button-list') {
                return (
                  <RadioButtonList
                    options={input.options}
                    label={input.label}
                    labelhelper={input.labelhelper}
                    key={input.key}
                    value={form[input.key]}
                    dark="true"
                    multi={input.multi ? 1 : 0}
                    onChange={value => {
                      this.update(value, input.key);
                    }}
                  />
                );
              } else if (input.type === 'checkbox-list') {
                return (
                  <CheckboxList
                    options={input.options}
                    dark="true"
                    key={input.key}
                    value={form[input.key]}
                    multi={input.multi ? 1 : 0}
                    textareaKey={textarea?.key}
                    textareaValue={textarea?.key ? form[textarea.key] : ''}
                    textareaPlaceholder={textarea?.placeholder}
                    textareaLabel={textarea?.label}
                    onChange={(value, key = input.key) => {
                      this.update(value, key);
                    }}
                  />
                );
              } else if (input.type === 'select') {
                return (
                  <div className="onb-select" key={input.key}>
                    <label>{input.label}</label>
                    <Select
                      placeholder={input.placeholder || ''}
                      value={form[input.key]}
                      onChange={value => {
                        this.update(value, input.key);
                      }}
                      dark={true}
                    >
                      {input.options.map((p, i) => (
                        <Select.Option key={`ind-${i}`} value={p}>
                          {p}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                );
              } else if (input.type === 'calendly-embed') {
                const onCalendlyEventScheduled = this.onCalendlyEventScheduled.bind(this);
                return (
                  <React.Fragment key={input.key}>
                    <InlineWidget
                      url={input.url}
                      pageSettings={{
                        hideEventTypeDetails: false,
                        backgroundColor: '090e15',
                        textColor: 'ffffff',
                        primaryColor: 'e7ecf4'
                      }}
                      styles={{
                        height: '100%',
                        minHeight: '40vh',
                        width: '100%',
                        margin: '0'
                      }}
                      prefill={{
                        email: this.state.form?.email,
                        name: this.state.form?.name
                      }}
                    />
                    <CalendlyEventListener onEventScheduled={onCalendlyEventScheduled} />
                  </React.Fragment>
                );
              }
            })}
          </div>
          <div className="onb-progress">
            <div className="onb-progress-bar" style={{ right: `${100 - percentDone}%` }} />
          </div>
          <div className="onb-footer">
            <Button
              disabled={stage == 1}
              size="large"
              type="primary"
              className="back-button"
              onClick={() => this.previousStage()}
            >
              Back
            </Button>
            {this.state.stage < 8 ? (
              <Button
                size="large"
                disabled={hasEmptyFields || isInvalid || this.state.stage === 8}
                onClick={() => this.advanceStage()}
              >
                Continue
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps)(CompanyOnboarding));
