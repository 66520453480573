import React, { Component } from 'react';
import './ReputationText.scss';

import {
  determineReputation,
  reputationTextIndex,
  reputationRangeForIndex
} from '_helpers/reputation';

export default class ReputationText extends Component {
  render() {
    const { reputation } = this.props;

    const reputationIndex = determineReputation(reputation);
    const reputationClasses = [
      'earner',
      'big-earner',
      'super-earner',
      'ultra-earner',
      'extreme-earner'
    ];

    const [min] = reputationRangeForIndex(reputationIndex);

    return (
      <div className="sxd-reputation">
        <span className={reputationClasses[reputationIndex]}>
          {min} {reputationTextIndex[reputationIndex]}
        </span>
      </div>
    );
  }
}
