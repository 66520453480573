import React from 'react';
import { Popconfirm as AntdPopconfirm } from 'antd';
import './Popconfirm.scss';
import { IconAlertTriangle } from '@tabler/icons-react';

const Popconfirm = incomingProps => {
  const props = { ...incomingProps };

  props.icon = <div className="no-icon" />;

  const newTitle = (
    <div className="pop-main">
      <div className="pop-title">
        {props.warning ? <IconAlertTriangle /> : null}
        {props.title}
      </div>
      {props.content ? <div className="pop-content">{props.content}</div> : null}
    </div>
  );
  props.title = newTitle;

  return <AntdPopconfirm {...props} />;
};
export default Popconfirm;
