import React from 'react';
import { AutoComplete as AntdAutoComplete } from 'antd';
import { IconSearch } from '@tabler/icons-react';
import './AutoComplete.scss';

const AutoComplete = incomingProps => {
  const props = { ...incomingProps };
  let containerClassName = 'seriph-autocomplete';

  return (
    <div className={containerClassName}>
      <div className="autocomplete-box">
        <AntdAutoComplete {...props} />
        <span className="autocomplete-icon-left">
          <IconSearch />
        </span>
      </div>
    </div>
  );
};

export default AutoComplete;
