import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { xdrScorecard } from '_graphql/queries/analytics';
import { Header, message, Loading } from '_seriph';
import { DatePicker, Select } from 'antd';
import moment from 'moment';
import RepDropdown from '_shared/RepDropdown/RepDropdown';
import ScoreTasks from './_components/ScoreTasks/ScoreTasks';
import CallStats from './_components/CallStats/CallStats';
import LeadStats from './_components/LeadStats/LeadStats';
import queryString from 'query-string';
import { loginUser } from 'Store/user/user_actions';
import './Scorecard.scss';

const { MonthPicker, WeekPicker } = DatePicker;

class Scorecard extends Component {
  state = {
    loading: false,
    date: moment().format('YYYYMMDD'),
    type: 'weekly',
    data: null,
    rep_id: null,
    internal: 'xdr',
  };
  componentDidMount = () => {
    this.getStats();
  }
  componentDidMount = () => {
    const params = queryString.parse(window.location.href.split('?')[1]);
    if (params.rep_id) {
      this.setState({ rep_id: params.rep_id }, this.getStats);
    }
  };
  getStats = () => {
    if (!this.state.loading) this.setState({ data: null, loading: true });
    this.props.client
      .query({
        variables: {
          date: this.state.date,
          type: this.state.type,
          rep_id: this.state.rep_id,
        },
        query: xdrScorecard
      })
      .then(result => {
        if (result?.data?.xdrScorecard) {
          const data = result?.data?.xdrScorecard?.data || [];
          const calls = result?.data?.xdrScorecard?.calls || [];
          const callStats = result?.data?.xdrScorecard?.call_stats || {};
          this.setState({ data, calls, callStats }, () => {
            this.setState({ loading: false });
          });
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        message.error('Could not load your statistics, try again');
        this.setState({ loading: false });
      });
  };
  getTotals = data => {
    const totals = {};
    // Gather totals
    data.map(r => {
      for (const key in r.daily) {
        if (Number.isInteger(r.daily[key])) {
          totals[key] = !totals[key] ? r.daily[key] : totals[key] + r.daily[key];
        }
      }
      return r;
    });
    return totals;
  };
  onChange = currentDate => {
    const { type } = this.state;
    let date = currentDate;
    if (type === 'weekly') {
      date = currentDate.startOf('week');
    } else if (type === 'monthly') {
      date = currentDate.startOf('month');
    }
    this.setState({ date: date.format('YYYYMMDD') }, this.getStats);
  };
  onRepChange = rep_id => {
    this.setState({ rep_id }, this.getStats);
  };
  UNSAFE_componentWillMount = () => window.scrollTo({ top: 0 });
  render() {
    const { loading, data, calls, callStats } = this.state;

    return (
      <div id="rep-analytics-scorecard">
        <div className="seriph-header-wrapper">
          <Header type="display" weight="600" size="4">
            XDR Scorecard
          </Header>
        </div>

        <div className="dashboard-actions">
          <span className="no-left">Viewing the</span>
          { false && (
            <Select value={this.state.type} onChange={type => this.setState({ type }, this.getStats)}>
              <Select.Option value="daily">Daily</Select.Option>
              <Select.Option value="weekly">Weekly</Select.Option>
              <Select.Option value="monthly">Monthly</Select.Option>
            </Select>
          )}
          {/*<span>stats, for</span>*/}
          {this.state.type === 'daily' && (
            <DatePicker 
              onChange={this.onChange}
              defaultValue={moment().startOf('week')}
              format="[the day of] M/D/YY"
            />
          )}
          {this.state.type === 'weekly' && (
            <WeekPicker 
              onChange={this.onChange}
              defaultValue={moment().startOf('week')}
              format="[Week of] M/D/YY"
            />
          )}
          {this.state.type === 'monthly' && (
            <MonthPicker 
              onChange={this.onChange}
              defaultValue={moment().startOf('week')}
              format="[the month of] MMM YYYY"
            />
          )}
          <span>for</span>
          <RepDropdown value={this.state.rep_id} onChange={this.onRepChange} />
        </div>

        { loading ? <Loading /> : null }
        { !loading && data ? (
          <div className="scorecard">
            <ScoreTasks data={data} />
            <CallStats calls={calls} callStats={callStats} />
            <LeadStats data={data} />
          </div>
        ) : null }
        
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, { loginUser })(Scorecard));
