import React from 'react';
import { Select, Button, Avatar } from '_seriph';
import { prettyPhone } from '_assets/js/helpers';
import { cohorts } from '_constants/campaigns';
import logo from '_assets/img/logo_black.svg';
import { hasAccess } from '_helpers/permissions';

export const getColumns = (
  updateCohort,
  saveCohort,
  saving,
  masq,
  onSalaryClick,
  isSuperAdmin,
  updateManager,
  saveManager,
  managers,
  onBonusClick
) => {
  return [
    {
      title: 'Full Name',
      dataIndex: 'first_name',
      fixed: 'left',
      render: (column, data) => {
        const email = data.user && data.user.email ? data.user.email : null;
        const isTeam = data.team?.id ? true : false;
        return (
          <div className="lead-name">
            <div className="lead-photo">
              {isTeam ? (
                <Avatar
                  size="small"
                  type="company"
                  picture={data.picture}
                  company={data.team.logo}
                />
              ) : (
                <Avatar
                  size="small"
                  type="agent"
                  picture={data.picture}
                  reputation={data.reputation}
                />
              )}
            </div>
            <div>
              <div className="lead-full">
                {data.first_name} {data.last_name}
                {isTeam ? `(${data.team.name})` : ''}
                {data.sellx_rep === true ? <img src={logo} alt="Logo" /> : null}
              </div>
              <div className="sub">{email}</div>
              <div className="sub">{prettyPhone(data.phone_number)}</div>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Information',
      dataIndex: 'information',
      render: (column, data) => (
        <div>
          {data.location && data.location.name ? <div>{data.location.name}</div> : null}
          <div>{data.experience_type}</div>
          <div>{data.employment_type}</div>
        </div>
      )
    },
    {
      title: 'Cohort',
      dataIndex: 'cohort',
      render: (column, data) => {
        return (
          <div>
            <div className="cohort">
              <Select
                placeholder="Select a cohort..."
                size="small"
                style={{ width: '160px', marginRight: 8 }}
                value={column}
                onChange={value => updateCohort(data.id, value)}
              >
                {data.sellx_rep ? (
                  <Select.Option key="cohnonep" value="undo">
                    Undo SellX Rep
                  </Select.Option>
                ) : (
                  <Select.Option key="cohnonep" value="make">
                    Make a SellX Rep
                  </Select.Option>
                )}
                <Select.Option key="cohnoner" value="remove">
                  Ban from platform
                </Select.Option>
                <Select.Option key="cohnonen" value="none">
                  None
                </Select.Option>
                {Object.keys(cohorts).map(c => (
                  <Select.Option key={'coh' + c} value={c}>
                    {cohorts[c]}
                  </Select.Option>
                ))}
              </Select>
              <Button
                type="primary"
                onClick={() => saveCohort(data.id, column)}
                loading={saving === data.id}
              >
                Save
              </Button>
            </div>
            <p style={{ margin: '4px 0 0 0', fontSize: '12px' }}>
              <b>ID:&nbsp;&nbsp;</b>
              {data.id}
            </p>
          </div>
        );
      }
    },
    {
      title: 'Login',
      dataIndex: 'login',
      render: (column, data) => {
        return (
          <Button
            className="btnx btnx-default btnx-tiny"
            onClick={() => masq(data.id)}
            loading={saving === data.id}
          >
            Login as Rep
          </Button>
        );
      }
    },

    // {
    //   title: 'Created',
    //   dataIndex: 'stats.in_progress',
    //   render: column => numberWithCommas(column)
    // },
    // {
    //   title: 'Generated',
    //   dataIndex: 'stats.generated',
    //   render: column => numberWithCommas(column)
    // },
    // {
    //   title: 'Qualified',
    //   dataIndex: 'stats.qualified',
    //   render: column => numberWithCommas(column)
    // },
    // { title: 'Review', dataIndex: 'stats.review', render: column => numberWithCommas(column) },
    // { title: 'Disputed', dataIndex: 'stats.disputed', render: column => numberWithCommas(column) },
    // { title: 'Emailed', dataIndex: 'stats.emailed', render: column => numberWithCommas(column) },
    // { title: 'Called', dataIndex: 'stats.called', render: column => numberWithCommas(column) },
    // { title: 'Meetings', dataIndex: 'stats.meetings', render: column => numberWithCommas(column) },

    ...(isSuperAdmin
      ? [
          {
            title: 'Manager',
            dataIndex: 'manager_id',
            render: (column, data) => (
              <div className="manager-column">
                <Select
                  placeholder="Select..."
                  size="small"
                  style={{ width: '200px', marginRight: 8 }}
                  value={column || ''}
                  onChange={value => updateManager(data.id, value)}
                >
                  <Select.Option key="" value="">
                    None
                  </Select.Option>
                  {managers?.map(m => (
                    <Select.Option key={m.id} value={m.id}>
                      {m.admin?.display_name}
                    </Select.Option>
                  ))}
                </Select>
                <Button
                  type="primary"
                  onClick={() => {
                    saveManager(data.id, column);
                  }}
                  loading={saving === data.id}
                >
                  Save
                </Button>
              </div>
            )
          }
        ]
      : []),

    {
      title: 'Bonus',
      dataIndex: 'pay-bonus',
      hidden: !hasAccess(['owner']),
      render: (column, data) => {
        if (data.stripe_user_id && data?.company?.company_id == null && data?.cohort !== 'remove') {
          return (
            <Button size="tiny" onClick={() => onBonusClick(data)}>
              Pay Bonus
            </Button>
          );
        } else {
          return null;
        }
      }
    }
    // {
    //   title: 'Salary',
    //   dataIndex: 'salary',
    //   render: (column, data) => {
    //     return (
    //       <Button
    //         className="btnx btnx-default btnx-tiny"
    //         onClick={() => {
    //           onSalaryClick(data);
    //         }}
    //         loading={saving === data.id}
    //       >
    //         Salary
    //       </Button>
    //     );
    //   }
    // }
  ].filter(c => !c.hidden);
};
