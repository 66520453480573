import React, { Component } from 'react';
import { Modal, Button, message } from '_seriph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { acceptLead } from '_graphql/mutations/lead';
import { getErrors } from '_assets/js/helpers';
import './AcceptModal.scss';

export default class AcceptModal extends Component {
  state = {
    visible: true,
    maskClosable: true,
    loading: false
  };
  hideModal = () => {
    this.setState({ visible: false });
  };
  acceptLead = () => {
    this.setState({ loading: true });
    this.props.client
      .mutate({ variables: { lead_id: this.props.lead.id }, mutation: acceptLead })
      .then(result => {
        if (result && result.data && result.data.acceptLead) {
          setTimeout(() => {
            this.props.reload(this.props.lead.id);
            setTimeout(this.hideModal, 250);
          }, 500);
        } else {
          throw new Error('Error disputing lead');
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        message.error(getErrors(err) || 'Could not dispute lead, try again');
      });
  };
  render() {
    const { loading } = this.state;
    const { sourceInternal } = this.props;
    return (
      <Modal
        wrapClassName="accept-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={this.state.maskClosable}
        width={456}
      >
        <Modal.Close onClick={this.hideModal} />
        <div className="stage-one">
          <div className="stage-icon">
            <FontAwesomeIcon icon={faExclamation} />
          </div>
          <h3>Accept Lead</h3>
          { sourceInternal ? (
            <p>
              This will mark the lead as <b>Completed</b> and cannot be undone.
              <br />
              Are you sure you want to accept this lead?
            </p>
          ) : (
            <p>
              This will mark the lead as <b>Completed</b> and the sales rep will be paid out{' '}
              <b>{this.props.price}</b>. <br />
              <br />
              Are you sure you want to accept this lead?
            </p>
          )}
          <div className="stage-actions">
            <Button type="secondary" onClick={this.hideModal}>
              Cancel
            </Button>
            <Button type="primary" loading={loading} onClick={this.acceptLead}>
              Accept Lead
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}
