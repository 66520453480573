import gql from 'graphql-tag';

export const removeRoundRobin = gql`
  mutation removeRoundRobin($id: String!) {
    removeRoundRobin(id: $id)
  }
`;

export const saveRoundRobin = gql`
  mutation saveRoundRobin($roundrobin: RoundRobinInput!) {
    saveRoundRobin(roundrobin: $roundrobin)
  }
`;

export const createRoundRobin = gql`
  mutation createRoundRobin($roundrobin: RoundRobinInput!) {
    createRoundRobin(roundrobin: $roundrobin)
  }
`;

export const toggleRoundRobin = gql`
  mutation toggleRoundRobin($id: String!, $active: Boolean!) {
    toggleRoundRobin(id: $id, active: $active)
  }
`;
