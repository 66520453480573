import gql from 'graphql-tag';

export const createLead = gql`
  mutation createLead($lead: LeadInput!, $submit: Boolean) {
    createLead(lead: $lead, submit: $submit) {
      id
      phone_valid
      mobile_valid
      email_valid
    }
  }
`;

export const saveLead = gql`
  mutation saveLead($lead: LeadInput!) {
    saveLead(lead: $lead)
  }
`;

export const removeLead = gql`
  mutation removeLead($lead_id: String!) {
    removeLead(lead_id: $lead_id)
  }
`;

export const saveLeadTags = gql`
  mutation saveLeadTags($lead_id: String!, $tags: [LeadTagType]) {
    saveLeadTags(lead_id: $lead_id, tags: $tags)
  }
`;

export const completeStep = gql`
  mutation completeStep(
    $leadId: String!
    $taskId: String!
    $typeId: String
    $skipType: String
    $skipReason: String
  ) {
    completeStep(
      leadId: $leadId
      taskId: $taskId
      typeId: $typeId
      skipType: $skipType
      skipReason: $skipReason
    )
  }
`;

export const completeTasks = gql`
  mutation completeTasks($tasks: [CompleteTasksType], $socketId: String!) {
    completeTasks(tasks: $tasks, socketId: $socketId)
  }
`;

export const saveAnswers = gql`
  mutation saveAnswers($lead_id: String!, $answers: [LeadAnswerType]) {
    saveAnswers(lead_id: $lead_id, answers: $answers)
  }
`;

export const createNote = gql`
  mutation createNote($lead_id: String!, $campaign_id: String!, $content: String!) {
    createNote(lead_id: $lead_id, campaign_id: $campaign_id, content: $content) {
      id
    }
  }
`;

export const sendEmail = gql`
  mutation sendEmail(
    $lead_id: String!
    $campaign_id: String!
    $origin_email_id: String
    $sequence_id: String
    $touchpoint_id: String
    $day: String
    $email_id: String
    $thread_id: String
    $subject: String
    $content: String!
    $to: String
    $cc: [String]
    $bcc: [String]
    $attachment_url: String
  ) {
    sendEmail(
      lead_id: $lead_id
      campaign_id: $campaign_id
      sequence_id: $sequence_id
      touchpoint_id: $touchpoint_id
      day: $day
      origin_email_id: $origin_email_id
      email_id: $email_id
      thread_id: $thread_id
      subject: $subject
      content: $content
      to: $to
      cc: $cc
      bcc: $bcc
      attachment_url: $attachment_url
    )
  }
`;

export const sendTextMessage = gql`
  mutation sendTextMessage(
    $lead_id: String!
    $sequence_id: String
    $touchpoint_id: String
    $to_number: String!
    $day: String
    $content: String!
  ) {
    sendTextMessage(
      lead_id: $lead_id
      sequence_id: $sequence_id
      touchpoint_id: $touchpoint_id
      to_number: $to_number
      day: $day
      content: $content
    )
  }
`;

export const submitGenerated = gql`
  mutation submitGenerated($lead_id: String!) {
    submitGenerated(lead_id: $lead_id)
  }
`;

export const submitQualified = gql`
  mutation submitQualified(
    $lead_id: String!
    $interested: Boolean!
    $reason: String!
    $reason_detail: String!
    $information: String!
    $meeting_time: String
  ) {
    submitQualified(
      lead_id: $lead_id
      interested: $interested
      reason: $reason
      reason_detail: $reason_detail
      information: $information
      meeting_time: $meeting_time
    )
  }
`;

export const disputeLead = gql`
  mutation disputeLead($lead_id: String!, $reason: String!, $information: String!) {
    disputeLead(lead_id: $lead_id, reason: $reason, information: $information)
  }
`;

export const revertLead = gql`
  mutation revertLead($lead_id: String!) {
    revertLead(lead_id: $lead_id)
  }
`;

export const getFromHopper = gql`
  mutation getFromHopper($campaign_id: String!, $qty: Int, $lead_id: String) {
    getFromHopper(campaign_id: $campaign_id, qty: $qty, lead_id: $lead_id) {
      leads {
        lead_id
        work_started
      }
    }
  }
`;

export const acceptLead = gql`
  mutation acceptLead($lead_id: String!) {
    acceptLead(lead_id: $lead_id)
  }
`;

export const meetingScheduled = gql`
  mutation meetingScheduled(
    $lead_id: String!
    $event_uri: String
    $calendar_type: String
    $event_id: String
    $start_time: String
    $end_time: String
  ) {
    meetingScheduled(
      lead_id: $lead_id
      event_uri: $event_uri
      calendar_type: $calendar_type
      event_id: $event_id
      start_time: $start_time
      end_time: $end_time
    )
  }
`;

export const cancelMeeting = gql`
  mutation cancelMeeting(
    $lead_id: String!
    $calendar_type: String
    $event_id: String
    $reason: String
  ) {
    cancelMeeting(
      lead_id: $lead_id
      calendar_type: $calendar_type
      event_id: $event_id
      reason: $reason
    )
  }
`;

export const createLeadsFromImport = gql`
  mutation createLeadsFromImport($import_id: String!) {
    createLeadsFromImport(import_id: $import_id)
  }
`;
