import React, { Component } from 'react';
import { leadCalls } from '_graphql/queries/lead';
import SelectedCall from './_components/SelectedCall/SelectedCall';
import 'react-h5-audio-player/src/styles.scss';
import { prettyPhone } from '_assets/js/helpers';
import Guide from '_styleguide/Guide/Guide';
import { IconPhone, IconCirclePlus, IconPhoneOutgoing } from '@tabler/icons-react';
import { Table, Header, Empty, Button, message, Loading } from '_seriph';
import { getColumns } from './Columns';
import './Calls.scss';

export default class Calls extends Component {
  state = {
    loading: true,
    calls: [],
    opened: false,
    selected: null
  };
  componentDidMount() {
    this.loadCalls(this.props.lead.id);
  }
  UNSAFE_componentWillMount = () => window.scrollTo({ top: 0 });
  resetOpened = () => this.setState({ opened: true });
  loadCalls = id => {
    this.props.client
      .query({
        variables: { lead_id: id },
        query: leadCalls
      })
      .then(result => {
        if (result && result.data && result.data.leadCalls) {
          this.setState({ calls: (result.data.leadCalls || []).reverse() });
        } else {
          message.error('Could not load calls, try again');
        }
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error('Could not load calls, try again');
      });
  };
  showCallPopout = () => {
    const rep = { ...this.props.user };
    const agent = (this.props.campaign.agents || []).find(a => a.agent_id === this.props.user.id);
    rep.phone_number = agent && agent.phone_number ? prettyPhone(agent.phone_number) : null;

    this.props.showCallPopout({
      update: () => this.loadCalls(this.props.lead.id),
      saveAnswers: this.props.saveAnswers,
      lead: this.props.lead,
      campaign: this.props.campaign,
      integration: this.props.integration,
      showCalendarModal: this.props.showCalendarModal,
      company: this.props.campaign.company,
      rep: rep,
      type: 'call'
    });
  };
  selectCall = id => this.setState({ selected: id });
  scrollTo = id => {
    document.getElementById('scroll-' + id).scrollIntoView({ behavior: 'smooth' });
  };
  render() {
    const { loading, calls, opened, selected } = this.state;
    const { isAgent, lead, source, otherId } = this.props;

    const agent = (this.props.campaign.agents || []).find(a => a.agent_id === this.props.user.id);
    const hasCalls = calls && calls.length > 0 ? true : false;
    const selectedCall = calls.find(c => c.id === selected);

    const columns = getColumns(lead, this.selectCall);

    return loading ? (
      <Loading />
    ) : (
      <div id="lead-calls">
        {isAgent && (
          <Guide
            id="lead-calls-agent"
            message="Call leads using the script to qualify them. All calls are recorded for quality so make sure to represent the company well."
          />
        )}
        {!hasCalls && (
          <Empty
            icon={<IconPhone />}
            type="success"
            title="No calls yet"
            onClick={this.showCallPopout}
            action={
              <span>
                <IconPhoneOutgoing />
                Call {lead.first_name}
              </span>
            }
          >
            When a call is made by a rep, the recording, transcript and activies will appear here.
            Reach out via your phone number <b>{agent ? prettyPhone(agent.phone_number) : ''}</b> to
            contact <b>{lead.first_name}</b>.
          </Empty>
        )}
        {hasCalls ? (
          <React.Fragment>
            {selectedCall ? (
              <div className="selected">
                <SelectedCall
                  call={selectedCall}
                  lead={lead}
                  source={source}
                  otherId={otherId}
                  opened={opened}
                  resetOpened={this.resetOpened}
                  scrollTo={this.scrollTo}
                  selectCall={this.selectCall}
                  focused={true}
                />
              </div>
            ) : null}
            <div className="call-actions">
              <Header font="sans" size="2">
                Call activity
              </Header>
              {isAgent && (
                <div className="compose-new">
                  <Button type="primary" icon="left" onClick={this.showCallPopout}>
                    <IconCirclePlus />
                    Call {lead.first_name}
                  </Button>
                </div>
              )}
            </div>
{/*            {calls.map(c => (
              <SelectedCall
                key={'call-' + c.id}
                call={c}
                lead={lead}
                source={source}
                otherId={otherId}
                opened={opened}
                resetOpened={this.resetOpened}
                scrollTo={this.scrollTo}
                selectCall={this.selectCall}
              />
            ))}*/}
            <div className="calls-table">
              <Table
                lined
                rounded
                striped
                rowSelection={null}
                columns={columns}
                rowKey={record => record.id}
                dataSource={calls}
              />
            </div>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}
