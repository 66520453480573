import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, Button, message } from '_seriph';
import { launchWithReview, launchCampaign, importLeads } from '_graphql/mutations/campaign';
import { getErrors } from '_assets/js/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import { formatMoney, numberWithCommas } from '_assets/js/helpers';
import PaymentMethod from '_styleguide/PaymentMethod/PaymentMethod';
import { Elements } from 'react-stripe-elements';
import Guide from '_styleguide/Guide/Guide';
import { durationText } from '_constants/campaigns';
import ThresholdModal from '_styleguide/ThresholdModal/ThresholdModal';
import './Launch.scss';

class Launch extends Component {
  state = {
    visible: true,
    launching: false,
    status: null,
    validPaymentMethod: false,
    hasLaunched: false
  };
  softLaunchCampaign = () => {
    const isUnpaid = this.props.user?.unpaid;
    if (!this.state.validPaymentMethod && !isUnpaid)
      return message.error('Please select a valid payment method before launching campaign');
    this.reviewAndStart();
  };
  fullLaunchCampaign = () => {
    if (this.props.campaign.objective === 'qualify') {
      setTimeout(this.importLeads, 500);
    } else {
      setTimeout(this.fillAndStart, 500);
    }
  };
  importLeads = () => {
    this.setState({ launching: true, status: 'Importing leads...' });
    this.props.client
      .mutate({ variables: { campaign_id: this.props.campaignId }, mutation: importLeads })
      .then(result => {
        if (result && result.data && result.data.importLeads > 0) {
          setTimeout(this.fillAndStart, 1500);
        } else {
          this.setState({ launching: false, status: null });
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not import leads, try again');
        this.setState({ launching: false, status: null });
      });
  };
  reviewAndStart = () => {
    this.setState({ launching: true, status: 'Launching...' });
    this.props.client
      .mutate({ variables: { campaign_id: this.props.campaignId }, mutation: launchWithReview })
      .then(result => {
        if (
          result &&
          result.data &&
          result.data.launchWithReview &&
          result.data.launchWithReview.id === this.props.campaignId
        ) {
          setTimeout(() => this.setState({ hasLaunched: true }), 1200);
        } else {
          this.setState({ launching: false, status: null, hasLaunched: false });
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not launch Campaign, try again');
        this.setState({ launching: false, status: null, hasLaunched: false });
      });
  };
  fillAndStart = () => {
    this.setState({ launching: true, status: 'Launching...' });
    this.props.client
      .mutate({ variables: { campaign_id: this.props.campaignId }, mutation: launchCampaign })
      .then(result => {
        if (
          result &&
          result.data &&
          result.data.launchCampaign &&
          result.data.launchCampaign.id === this.props.campaignId
        ) {
          setTimeout(this.finishLaunch, 1500);
        } else {
          this.setState({ launching: false, status: null });
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not launch Campaign, try again');
        this.setState({ launching: false, status: null });
      });
  };
  finishLaunch = () => {
    if (this.props.fromAdmin) {
      message.success('Campaign is now active');
      if (this.props.reload) this.props.reload();
      this.hideModal();
    } else if (this.props.campaign?.rep_type === 'internal') {
      message.success('Campaign is now launched');
      if (this.props.reload) this.props.reload();
      this.props.history.push('/campaigns');
    } else {
      message.success('Campaign is now pending review');
      this.props.history.push('/discover');
    }
  };
  hideModal = () => this.setState({ visible: false });
  showThresholdModal = () => this.setState({ thresholdModal: true });
  removeThresholdModal = () => this.setState({ thresholdModal: false });
  render() {
    const { launching, status, hasLaunched, validPaymentMethod } = this.state;
    const { campaign, importData, fromAdmin } = this.props;

    const noFinancial = campaign?.rep_type === 'internal';

    const budget = campaign.budget_total ? campaign.budget_total * 1 : 0;

    let leadAmount = 0;
    if (fromAdmin) {
      leadAmount = campaign.import ? campaign.import.amount : 0;
    } else {
      leadAmount = importData ? importData.amount : 0;
    }

    const isUnpaid = this.props.user?.unpaid;

    return (
      <Modal
        wrapClassName="campaign-launch-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={false}
        width={fromAdmin || isUnpaid || noFinancial ? 360 : hasLaunched ? 600 : 800}
      >
        {!launching && <Modal.Close onClick={this.hideModal} />}
        {!hasLaunched ? (
          <div className="launch-content">
            {!fromAdmin && !isUnpaid && !noFinancial ? (
              <div className="payments">
                <h5>Lets get to work!</h5>
                <h2>Your campaign is ready to launch!</h2>
                <Elements>
                  <PaymentMethod
                    client={this.props.client}
                    myself={this.props.user}
                    isValid={validPaymentMethod => this.setState({ validPaymentMethod })}
                  />
                </Elements>
                <Guide
                  type="alert"
                  dismissable={false}
                  id="campaign-review-alert"
                  header="The campaign will not be live until it is reviewed by the SellX team (less than 24 hours)."
                />
              </div>
            ) : null}
            <div className="summary">
              <h3>{fromAdmin ? 'Review Campaign' : 'Summary'}</h3>
              <p>Please review the info below prior to launch</p>
              <div className="line-items">
                {!campaign.threshold ? (
                  <div className="item">
                    <div className="info">
                      <h4>Campaign Budget</h4>
                    </div>
                    <div className="value">{formatMoney(budget)}</div>
                  </div>
                ) : (
                  <div className="item">
                    <div className="info">
                      <h4>Campaign Duration</h4>
                    </div>
                    <div className="value">
                      {durationText[campaign.threshold_settings.duration].split(' ')[0]}
                    </div>
                  </div>
                )}

                {campaign.objective === 'leadgen' && (
                  <React.Fragment>
                    <div className="item">
                      <div className="info">
                        <h4>Pay per lead</h4>
                      </div>
                      <div className="value">{formatMoney(campaign.budget_ppl)}</div>
                    </div>
                    {campaign.threshold ? (
                      <div className="item">
                        <div className="info">
                          <h4>No. of Generated Leads</h4>
                          <p>
                            {campaign.threshold_settings.duration === 'weekly'
                              ? '/ weekly needed'
                              : 'Total needed'}
                          </p>
                        </div>
                        <div className="value">
                          {numberWithCommas(campaign.threshold_settings.lead_count)} leads
                        </div>
                      </div>
                    ) : (
                      <div className="item">
                        <div className="info">
                          <h4>No. of Generated Leads</h4>
                          <p>Est. based on pay per lead</p>
                        </div>
                        <div className="value">
                          {numberWithCommas(Math.floor(budget / campaign.budget_ppl))} leads
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}

                {campaign.objective === 'qualify' && (
                  <React.Fragment>
                    { !noFinancial ? (
                      <div className="item">
                        <div className="info">
                          <h4>Pay per lead</h4>
                          <p>Work done is $2.00 / lead</p>
                        </div>
                        <div className="value">{formatMoney(campaign.budget_ppql)}</div>
                      </div>
                    ) : null }
                    <div className="item">
                      <div className="info">
                        <h4>No. of Leads to Qualify</h4>
                        <p>Based on lead import</p>
                      </div>
                      <div className="value">{numberWithCommas(leadAmount)} leads</div>
                    </div>
                  </React.Fragment>
                )}
              </div>
              {campaign.threshold && !noFinancial ? (
                <React.Fragment>
                  <div className="notice no-bottom">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <p>
                      We use threshold billing, which means you pay for each lead and will not be
                      charged until your balance reaches or exceeds the limit ($1,000).
                    </p>
                  </div>
                  <div className="link-notice">
                    <div className="sxd-link" onClick={this.showThresholdModal}>
                      Learn more about Threshold Billing
                    </div>
                  </div>
                  <div className="total">
                    <h4>Total due</h4>
                    <div>{formatMoney(0)}</div>
                  </div>
                </React.Fragment>
              ) : (noFinancial ? (
                <React.Fragment>
                  <div className="notice">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <p>
                      This campaign is only available to internal reps, and cannot be changed 
                      after launch.  Adjust from the setup page if this is incorrect.
                    </p>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="notice">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <p>
                      Budget not used during the campaign, will auto refund once the campaign has
                      ended. Credit card fee is non-refundable.
                    </p>
                  </div>
                  <div className="total">
                    <h4>Total due</h4>
                    <div>{formatMoney(budget)}</div>
                  </div>
                </React.Fragment>
              ))}
              <Button
                disabled={!fromAdmin && !noFinancial && !validPaymentMethod && !isUnpaid}
                loading={launching}
                type="primary"
                size="large"
                style={{ width: '100%' }}
                onClick={fromAdmin || noFinancial ? this.fullLaunchCampaign : this.softLaunchCampaign}
              >
                {status ? status : 'Launch Campaign'}
              </Button>
            </div>
          </div>
        ) : (
          <div className="launch-content">
            <div className="reviewing">
              <div className="icon-part">
                <div>
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              </div>
              <h3>Submitted for Review</h3>
              <p>
                You’ll receive an email, once your <br />
                campaign has been reviewed.
              </p>
              <p>In the meantime, head over to the Discover page to start looking at Sales Reps.</p>
              <Button type="primary" size="large" onClick={this.finishLaunch}>
                Discover Sales Reps
              </Button>
            </div>
          </div>
        )}

        {this.state.thresholdModal ? (
          <ThresholdModal removeModal={this.removeThresholdModal} />
        ) : null}
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(Launch));
