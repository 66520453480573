import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Input, InputNumber, Button, DatePicker, AutoComplete, message } from 'antd';
import {
  createAgentProfileEducation,
  deleteAgentProfileEducation,
  updateAgentProfileEducation
} from 'Mutations/Agent/updateAgentProfile';
import { DEGREES } from '_assets/js/constants';
import { UNIVERSITIES } from '_assets/js/universities';
import { updateAgentProfile } from 'Store/agent/agent_actions';
import moment from 'moment';

const { TextArea } = Input;
const { MonthPicker } = DatePicker;

const mutations = {
  save: { mutation: updateAgentProfileEducation, name: 'updateAgentEducation' },
  create: { mutation: createAgentProfileEducation, name: 'createAgentEducation' },
  delete: { mutation: deleteAgentProfileEducation, name: 'deleteAgentEducation' }
};

class EducationEdit extends Component {
  state = { saving: false };
  action = type => {
    this.setState({ saving: true });
    this.props.apollo
      .mutate({
        variables: { id: this.props.editing, ...this.props.form },
        mutation: mutations[type].mutation
      })
      .then(result => {
        if (result && result.data) {
          if (type === 'create') message.success('Education has been added to your profile');
          if (type === 'save')
            message.success('Your education at ' + this.props.form.school + ' has been updated');
          if (type === 'delete')
            message.success('Your education at ' + this.props.form.school + ' was removed');
          this.props.close();
          this.props.reload();
        } else {
          this.setState({ saving: false });
        }
      })
      .catch(() => {
        message.error('Please fill out the required fields');
        this.setState({ saving: false });
      });
  };
  setInitial = () => this.setState({ saving: false });
  disabledDate = current => {
    return current && current > moment().endOf('day');
  };
  render() {
    const editing = this.props.editing ? true : false;

    let degreeSource = [];
    const degreeSearch = this.props.form.degree || '';
    if (degreeSearch.length > 0) {
      degreeSource = DEGREES.filter(degree => {
        return degree.toLowerCase().includes(degreeSearch.toLowerCase());
      });
      if (degreeSource.length > 10) degreeSource = degreeSource.slice(0, 10);
    }
    let schoolSource = [];
    const schoolSearch = this.props.form.school || '';
    if (schoolSearch.length > 0) {
      schoolSource = UNIVERSITIES.filter(school => {
        return school.toLowerCase().includes(schoolSearch.toLowerCase());
      });
      if (schoolSource.length > 10) schoolSource = schoolSource.slice(0, 10);
    }
    const defaultStart = this.props.form.start_date
      ? moment.unix(this.props.form.start_date)
      : null;
    const defaultEnd = this.props.form.end_date ? moment.unix(this.props.form.end_date) : null;
    return (
      <Modal
        title={editing ? 'Edit: Education' : 'Create: Education'}
        visible={this.props.visible}
        footer={null}
        afterClose={this.setInitial}
        onCancel={this.props.close}
        wrapClassName="sx-theme form-modal agent-education-edit"
        destroyOnClose={true}
        maskClosable={true}
        centered={true}
      >
        <div className="sx-form">
          <div className="field">
            <label>School *</label>
            <AutoComplete
              style={{ width: '100%' }}
              dataSource={schoolSource}
              placeholder="Ex: New York University"
              value={this.props.form.school}
              onChange={v => this.props.updateForm('school', v)}
            />
          </div>

          <div className="field">
            <label>Degree *</label>
            <AutoComplete
              style={{ width: '100%' }}
              dataSource={degreeSource}
              placeholder="Ex: Bachelor of Science"
              value={this.props.form.degree}
              onChange={v => this.props.updateForm('degree', v)}
            />
          </div>

          <div className="field">
            <label>From Date *</label>
            <MonthPicker
              defaultValue={defaultStart}
              disabledDate={this.disabledDate}
              onChange={d => this.props.updateForm('start_date', d.unix().toString())}
              placeholder="Start Date"
            />
          </div>

          <div className="field">
            <label>End Date (or expected) *</label>
            <MonthPicker
              defaultValue={defaultEnd}
              onChange={d => this.props.updateForm('end_date', d.unix().toString())}
              placeholder="End Date"
            />
          </div>

          <div className="field">
            <label>GPA</label>
            <InputNumber
              value={this.props.form.gpa}
              min={0}
              max={10}
              step={0.1}
              onChange={v => this.props.updateForm('gpa', v)}
            />
          </div>

          <div className="field">
            <label>Description *</label>
            <TextArea
              rows={4}
              autoSize={false}
              value={this.props.form.description}
              onChange={e => this.props.updateForm('description', e.target.value)}
            />
          </div>
        </div>

        {editing ? (
          <div className="sx-actions">
            <Button className="btnx btnx-default" onClick={() => this.action('delete')}>
              Remove
            </Button>
            <Button
              className="btnx btnx-primary"
              loading={this.props.saving}
              onClick={() => this.action('save')}
            >
              Save
            </Button>
          </div>
        ) : (
          <div className="sx-actions">
            <Button
              className="btnx btnx-primary"
              loading={this.props.saving}
              onClick={() => this.action('create')}
            >
              Create
            </Button>
          </div>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.agent, ...state.apollo };
};

export default connect(mapStateToProps, { updateAgentProfile })(EducationEdit);
