import React, { Component } from 'react';
import { Button, Dropdown, Select, Popconfirm } from 'antd';
import { Checkbox } from '_seriph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhoneSlash,
  faPhone,
  faCog,
  faVolumeMute,
  faVolumeUp,
  faMicrophoneAlt,
  faVolumeOff,
  faTh,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import { prettyPhone } from '_assets/js/helpers';
import moment from 'moment';
import parsePhoneNumber from 'libphonenumber-js';
import './CallFooter.scss';

const parsePhone = number => {
  return parsePhoneNumber((number || '').toString(), 'US');
};

const DialPad = props => (
  <div id="call-dialpad">
    <div className="">
      <div className="pressed">&nbsp;{props.pressed}&nbsp;</div>
    </div>
    <div className="keys">
      <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('1')}>
        1<div>&nbsp;</div>
      </Button>
      <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('2')}>
        2<div>ABC</div>
      </Button>
      <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('3')}>
        3<div>DEF</div>
      </Button>
    </div>
    <div className="keys">
      <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('4')}>
        4<div>GHI</div>
      </Button>
      <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('5')}>
        5<div>JKL</div>
      </Button>
      <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('6')}>
        6<div>MNO</div>
      </Button>
    </div>
    <div className="keys">
      <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('7')}>
        7<div>PQRS</div>
      </Button>
      <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('8')}>
        8<div>TUV</div>
      </Button>
      <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('9')}>
        9<div>WXYZ</div>
      </Button>
    </div>
    <div className="keys">
      <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('*')}>
        *<div>&nbsp;</div>
      </Button>
      <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('0')}>
        0<div>+</div>
      </Button>
      <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('#')}>
        #<div>&nbsp;</div>
      </Button>
    </div>
  </div>
);

const CallSettings = props => (
  <div id="cl-settings" className="sx-theme">
    <h3>Audio Settings</h3>
    <div className="mic">
      <h5>Microphone</h5>
      <div>
        <FontAwesomeIcon icon={faMicrophoneAlt} />
      </div>
    </div>
    <div className="sx-form">
      <Select
        value={props.audioInput}
        size="small"
        onChange={props.changeDevice}
        dropdownClassName="sx-dropdown"
      >
        {props.inputDevices.map((p, i) => (
          <Select.Option key={'nd-' + i} value={p.value}>
            {p.label}
          </Select.Option>
        ))}
      </Select>
    </div>
    <div className="mic">
      <h5>Speakers</h5>
      <div>
        <FontAwesomeIcon icon={faVolumeUp} />
        <Button className="btnx btnx-default btnx-tiny" onClick={props.testSpeakers}>
          Test
        </Button>
      </div>
    </div>
    <div className="sx-form">
      <Select
        value={props.audioOutput}
        size="small"
        onChange={props.changeOutput}
        dropdownClassName="sx-dropdown"
      >
        {props.outputDevices.map((p, i) => (
          <Select.Option key={'nd-' + i} value={p.value}>
            {p.label}
          </Select.Option>
        ))}
      </Select>
    </div>
    <div className="status-sounds">
      <div>
        <Checkbox
            checked={props.soundsEnabled}
            onChange={e => props.updateStatusSounds(e.target.checked)}
            size="small"
            noBg
          >
            Enabled status sounds
          </Checkbox>
      </div>
    </div>
  </div>
);

const CallNumber = props => {
  const { lead, rep, ready } = props;

  const leadPhone = parsePhone(lead.phone);
  const leadMobile = parsePhone(lead.mobile);

  <h5>{parsePhone(lead.phone)?.formatNational() || parsePhone(lead.mobile)?.formatNational()}</h5>;

  return (
    <div id="call-lead-drop">
      <h3>Phone Numbers</h3>
      <div className="from">
        <span>Your number:</span> <b>{rep ? prettyPhone(rep.phone_number) : 'Loading...'}</b>
      </div>
      <div className="cl-actions">
        {leadPhone ? (
          <Button
            disabled={!ready || !leadPhone?.isValid()}
            className="btnx btnx-default btnx-large"
            onClick={() => props.makeCall(leadPhone.number)}
          >
            Call Phone<div>{leadPhone?.formatNational()}</div>
          </Button>
        ) : null}
        {leadMobile ? (
          <Button
            disabled={!ready || !leadMobile?.isValid()}
            className="btnx btnx-default btnx-large"
            onClick={() => props.makeCall(leadMobile.number)}
          >
            Call Mobile<div>{leadMobile?.formatNational()}</div>
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default class CallFooter extends Component {
  state = {
    keyPadVisible: false,
    dropVisible: false,
    settingsVisible: false
  };
  callNumber = number => {
    this.setState({ dropVisible: false }, () => this.props.makeCall(number));
  };
  handleVisibleChange = keyPadVisible => {
    this.setState({ keyPadVisible });
  };
  handleDropVisibleChange = dropVisible => {
    this.setState({ dropVisible });
  };
  handleSettingsVisibleChange = settingsVisible => {
    this.setState({ settingsVisible });
  };
  render() {
    const { keyPadVisible, dropVisible, settingsVisible } = this.state;
    const {
      lead,
      rep,
      ready,
      callSid,
      mute,
      pressed,
      time,
      inputDevices,
      audioInput,
      outputDevices,
      audioOutput,
      soundsEnabled,
      updateStatusSounds
    } = this.props;
    const { endCall, toggleMute, pressKey, changeDevice, changeOutput, testSpeakers } = this.props;

    const callMenu = <CallNumber lead={lead} rep={rep} ready={ready} makeCall={this.callNumber} />;

    return (
      <div className="call-footer">
        <Dropdown
          overlay={
            <CallSettings
              soundsEnabled={soundsEnabled}
              updateStatusSounds={updateStatusSounds}
              inputDevices={inputDevices}
              audioInput={audioInput}
              changeDevice={changeDevice}
              outputDevices={outputDevices}
              audioOutput={audioOutput}
              changeOutput={changeOutput}
              testSpeakers={testSpeakers}
            />
          }
          trigger={['click']}
          placement="topCenter"
          arrow
          visible={settingsVisible}
          onVisibleChange={this.handleSettingsVisibleChange}
        >
          <Button className="btnx btnx-default btnx-giant btnx-round">
            <FontAwesomeIcon icon={faCog} />
          </Button>
        </Dropdown>
        {callSid ? (
          <React.Fragment>
            <Button
              className={`btnx ${mute ? 'btnx-danger' : 'btnx-default'} btnx-giant btnx-round`}
              onClick={toggleMute}
            >
              <FontAwesomeIcon icon={mute ? faVolumeMute : faVolumeOff} />
            </Button>
            <Dropdown
              overlay={<DialPad pressed={pressed} pressKey={pressKey} />}
              trigger={['click']}
              placement="topCenter"
              arrow
              visible={keyPadVisible}
              onVisibleChange={this.handleVisibleChange}
            >
              <Button className="btnx btnx-default btnx-giant btnx-round">
                <FontAwesomeIcon icon={faTh} />
              </Button>
            </Dropdown>
            <Popconfirm
              className="footer-pop"
              placement="top"
              title="Are you sure you want to end call?"
              onConfirm={endCall}
              okText="Yes, end call"
              cancelText="Cancel"
              okButtonProps={{ className: 'btnx btnx-danger' }}
              cancelButtonProps={{ className: 'btnx btnx-default' }}
            >
              <Button className="btnx btnx-danger btnx-giant btnx-round">
                <FontAwesomeIcon icon={faPhoneSlash} />
              </Button>
            </Popconfirm>
          </React.Fragment>
        ) : (
          <Dropdown
            overlay={callMenu}
            trigger={['click']}
            placement="topCenter"
            arrow
            visible={dropVisible}
            onVisibleChange={this.handleDropVisibleChange}
          >
            <Button className="btnx btnx-success btnx-giant btnx-round">
              <FontAwesomeIcon icon={faPhone} />
            </Button>
          </Dropdown>
        )}

        <div className="left-actions">
          <div className="who">
            <h4>
              {lead.first_name} {lead.last_name}
            </h4>
            <h5>
              {parsePhone(lead.phone)?.formatNational() ||
                parsePhone(lead.mobile)?.formatNational()}
            </h5>
          </div>
          {callSid ? (
            <div className="timer call">
              <span />
              {moment.utc(time).format('mm:ss')}
            </div>
          ) : null}
          {!callSid ? (
            <div className="timer">
              <span />
              Not connected
            </div>
          ) : null}
        </div>
        <div className="right-actions">
          {this.props.canComplete ? (
            <Button className="btnx btnx-primary btnx-large" onClick={this.props.completeTask}>
              <FontAwesomeIcon icon={faCheck} className="btnx-left" />
              Complete Task
            </Button>
          ) : null}
        </div>
      </div>
    );
  }
}
