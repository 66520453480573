import React, { Component } from 'react';
import logo from '_assets/img/logo_grey.svg';
import './Footer.scss';

class Footer extends Component {
  render() {
    return (
      <div id="sellx-footer">
        <div className="footer-contain">
          <img src={logo} alt="Logo" />
          <div className="footer-links">
            <a href={process.env.REACT_APP_WEB_PATH + '/legal/terms'}>Terms of Use</a>
            <a href={process.env.REACT_APP_WEB_PATH + '/legal/privacy'}>Privacy Policy</a>
            <a href="mailto:support@sellx.com">Contact Us</a>
          </div>
          <p>© 2020 SellX - All rights reserved.</p>
        </div>
      </div>
    );
  }
}

export default Footer;
