import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faFacebookF,
  faLinkedinIn,
  faInstagram
} from '@fortawesome/free-brands-svg-icons';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import { pickerData } from '_constants/salesforce';
import { leadFields } from '_constants/leadFields';
import { LANGUAGES } from '_assets/js/languages';
import { ETHNICITIES } from '_assets/js/ethnicities';
import ErrorIcon from '_assets/icons/error_icon.svg';
import Guide from '_styleguide/Guide/Guide';
import { softValidate } from '../_helpers/validateLead';
import { isUrlValid, linkify } from '_assets/js/helpers';
import { Header, Card, Input, Select, Tooltip, Location } from '_seriph';
import './Info.scss';

export default class Info extends Component {
  isRequired = field => {
    const isGen = this.props.campaign?.objective === 'leadgen' ? true : false;
    const isNewQualified = this.props.isNew && !this.props.lead.type;
    if (this.props.campaign?.objective === 'qualify') return true;
    if (isNewQualified && isGen) {
      const required = this.props.required || [];
      return required.find(f => f.field === field) ? true : false;
    } else if (isNewQualified) {
      return true;
    } else if (this.props.lead.type === 'qualified') {
      if (['location', 'email', 'phone', 'mobile'].includes(field)) {
        return true;
      } else {
        const val = this.props.lead[field];
        return val ? true : false;
      }
    } else {
      const required = this.props.required || [];
      return required.find(f => f.field === field) ? true : false;
    }
  };
  isSectionRequired = section => {
    if (this.props.campaign?.objective === 'qualify') return true;
    if (this.props.isNew && !this.props.lead.type) {
      return true;
    }
    if (section === 'Contact Information') return true;
    if (this.props.lead.type === 'qualified') {
      let hasAllFields = false;
      Object.keys(leadFields[section]).forEach(s => {
        if (this.props.lead[leadFields[section][s].field]) {
          hasAllFields = true;
        }
      });
      return hasAllFields;
    } else {
      let isRequired = false;
      const required = this.props.required || [];
      Object.keys(leadFields[section]).forEach(s => {
        if (required.find(f => f.field === leadFields[section][s].field)) {
          isRequired = true;
        }
      });
      return isRequired;
    }
  };
  getOptions = field => {
    const req = (this.props.required || []).find(r => r.field === field);
    if (req && req.type === 'Multiple') {
      return `(${req.values.join(', ')})`;
    }
    return '';
  };
  render() {
    const { isAgent, campaign, lead, update, updateCustom, canEdit, isGenerated, required, isNew } =
      this.props;
    const softResults = softValidate(lead, required);

    return (
      <div id="lead-information" className="sx-form">
        {isAgent && campaign.objective === 'leadgen' && (
          <Guide
            id="lead-info-generate-agent"
            message="Find businesses or individuals that are most likely to benefit from the companies product or service, add their information to the database, and submit to get paid. Review the companies instructions to learn who will benefit most from their product or service."
          />
        )}
        {isAgent && campaign.objective === 'qualify' && !isNew && (
          <Guide
            id="lead-info-qualify-agent"
            message="Use this information to learn more about who you are reaching out to and how this product or service will solve their problems."
          />
        )}
        {isAgent && campaign.objective === 'qualify' && isNew && (
          <Guide
            id="lead-info-add-qualified"
            message="Add a qualified lead that the company didn't provide."
          />
        )}
        <Card
          className={this.isSectionRequired('Contact Information') ? 'inputs' : 'inputs hidden'}
        >
          <Header type="sans" size="3">
            Contact
          </Header>
          {this.isRequired('salutation') && (
            <div className="field">
              <label>Salutation</label>
              <Select
                disabled={!canEdit}
                placeholder="Mr, Mrs, etc"
                dropdownClassName="sx-dropdown"
                value={lead.salutation || undefined}
                onChange={val => update('salutation', val)}
              >
                {pickerData.salutation.map((p, i) => (
                  <Select.Option key={`sal-${i}`} value={p}>
                    {p}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}
          {this.isRequired('first_name') && (
            <div className="field">
              <label>First Name</label>
              <Input
                disabled={!canEdit}
                placeholder="John"
                onChange={e => update('first_name', e.target.value)}
                value={lead.first_name}
              />
            </div>
          )}
          {this.isRequired('last_name') && (
            <div className="field">
              <label>Last Name</label>
              <Input
                disabled={!canEdit}
                placeholder="Smith"
                onChange={e => update('last_name', e.target.value)}
                value={lead.last_name}
              />
            </div>
          )}
          {this.isRequired('email') && (
            <div className="field">
              <label>Email Address</label>
              <Input
                className={isGenerated && lead.email_valid === false ? 'invalid' : ''}
                disabled={!canEdit}
                placeholder="lead@example.com"
                onChange={e => update('email', e.target.value)}
                value={lead.email}
              />
              {isGenerated && lead.email_valid === false && (
                <Tooltip title="This email does not exist, please fix before submitting">
                  <img src={ErrorIcon} alt="" />
                </Tooltip>
              )}
            </div>
          )}
          {this.isRequired('phone') && (
            <div className="field">
              <label>Phone Number</label>
              <Input
                className={isGenerated && lead.phone_valid === false ? 'invalid' : ''}
                disabled={!canEdit}
                placeholder="(000)-000-0000 ext. 1234"
                onChange={e => update('phone', e.target.value)}
                value={lead.phone}
              />
              {isGenerated && lead.phone_valid === false && (
                <Tooltip title="This phone number does not exist, please fix before saving">
                  <img src={ErrorIcon} alt="" />
                </Tooltip>
              )}
            </div>
          )}
          {this.isRequired('mobile') && (
            <div className="field">
              <label>Cell Phone Number</label>
              <Input
                className={isGenerated && lead.mobile_valid === false ? 'invalid' : ''}
                disabled={!canEdit}
                placeholder="(000)-000-0000"
                onChange={e => update('mobile', e.target.value)}
                value={lead.mobile}
              />
              {isGenerated && lead.mobile_valid === false && (
                <Tooltip title="This cell number does not exist, please fix before saving">
                  <img src={ErrorIcon} alt="" />
                </Tooltip>
              )}
            </div>
          )}
          {this.isRequired('fax') && (
            <div className="field">
              <label>Fax Number</label>
              <Input
                disabled={!canEdit}
                placeholder="(XXX)-XXX-XXXX"
                onChange={e => update('fax', e.target.value)}
                value={lead.fax}
              />
            </div>
          )}
        </Card>

        <Card
          className={this.isSectionRequired('Personal Information') ? 'inputs' : 'inputs hidden'}
        >
          <Header type="sans" size="3">
            Personal
          </Header>
          {this.isRequired('education') && (
            <div className="field field-select">
              <label>Education</label>
              <Select
                className={isGenerated && softResults.includes('education') ? 'invalid' : ''}
                disabled={!canEdit}
                placeholder="Masters, Bachelors, etc"
                dropdownClassName="sx-dropdown"
                value={lead.education || undefined}
                onChange={val => update('education', val)}
              >
                {pickerData.education.map((p, i) => (
                  <Select.Option key={`edu-${i}`} value={p}>
                    {p}
                  </Select.Option>
                ))}
              </Select>
              {isGenerated && softResults.includes('education') && (
                <Tooltip
                  title={`Education does not fit the defined customer profile ${this.getOptions(
                    'education'
                  )}`}
                >
                  <img src={ErrorIcon} alt="" />
                </Tooltip>
              )}
            </div>
          )}
          {this.isRequired('primary_language') && (
            <div className="field field-select">
              <label>Language</label>
              <Select
                className={isGenerated && softResults.includes('primary_language') ? 'invalid' : ''}
                disabled={!canEdit}
                placeholder="Primary Language"
                dropdownClassName="sx-dropdown"
                value={lead.primary_language || undefined}
                onChange={val => update('primary_language', val)}
              >
                {LANGUAGES.map((p, i) => (
                  <Select.Option key={`pri-${i}`} value={p}>
                    {p}
                  </Select.Option>
                ))}
              </Select>
              {isGenerated && softResults.includes('primary_language') && (
                <Tooltip
                  title={`Language does not fit the defined customer profile ${this.getOptions(
                    'primary_language'
                  )}`}
                >
                  <img src={ErrorIcon} alt="" />
                </Tooltip>
              )}
            </div>
          )}
          {this.isRequired('gender') && (
            <div className="field field-select">
              <label>Gender</label>
              <Select
                className={isGenerated && softResults.includes('gender') ? 'invalid' : ''}
                disabled={!canEdit}
                placeholder="Male, Female, etc"
                dropdownClassName="sx-dropdown"
                value={lead.gender || undefined}
                onChange={val => update('gender', val)}
              >
                {pickerData.gender.map((p, i) => (
                  <Select.Option key={`gen-${i}`} value={p}>
                    {p}
                  </Select.Option>
                ))}
              </Select>
              {isGenerated && softResults.includes('gender') && (
                <Tooltip
                  title={`Gender does not fit the defined customer profile ${this.getOptions(
                    'gender'
                  )}`}
                >
                  <img src={ErrorIcon} alt="" />
                </Tooltip>
              )}
            </div>
          )}
          {this.isRequired('race') && (
            <div className="field field-select">
              <label>Ethnicity</label>
              <Select
                className={isGenerated && softResults.includes('race') ? 'invalid' : ''}
                disabled={!canEdit}
                placeholder="White, Latino, Mixed, etc"
                dropdownClassName="sx-dropdown"
                value={lead.race || undefined}
                onChange={val => update('race', val)}
              >
                {ETHNICITIES.map((p, i) => (
                  <Select.Option key={`rac-${i}`} value={p}>
                    {p}
                  </Select.Option>
                ))}
              </Select>
              {isGenerated && softResults.includes('race') && (
                <Tooltip
                  title={`Ethnicity does not fit the defined customer profile ${this.getOptions(
                    'race'
                  )}`}
                >
                  <img src={ErrorIcon} alt="" />
                </Tooltip>
              )}
            </div>
          )}
          {this.isRequired('age') && (
            <div className="field">
              <label>Age</label>
              <Input
                disabled={!canEdit}
                placeholder="27"
                onChange={e => update('age', e.target.value)}
                value={lead.age}
              />
            </div>
          )}
          {this.isRequired('linkedin') && (
            <div className="field">
              <label>LinkedIn</label>
              <Input
                className="is-link"
                disabled={!canEdit}
                placeholder="https://www.linkedin.com/in/username"
                onChange={e => update('linkedin', e.target.value)}
                value={lead.linkedin}
              />
              {isUrlValid(lead.linkedin) ? (
                <a href={linkify(lead.linkedin)} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              ) : (
                <FontAwesomeIcon icon={faLinkedinIn} />
              )}
            </div>
          )}
          {this.isRequired('facebook') && (
            <div className="field">
              <label>Facebook</label>
              <Input
                className="is-link"
                disabled={!canEdit}
                placeholder="https://www.facebook.com/user.name"
                onChange={e => update('facebook', e.target.value)}
                value={lead.facebook}
              />
              <FontAwesomeIcon icon={faFacebookF} />
              {isUrlValid(lead.facebook) ? (
                <a href={linkify(lead.facebook)} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
              ) : (
                <FontAwesomeIcon icon={faFacebookF} />
              )}
            </div>
          )}
          {this.isRequired('twitter') && (
            <div className="field">
              <label>Twitter</label>
              <Input
                className="is-link"
                disabled={!canEdit}
                placeholder="https://twitter.com/UserName"
                onChange={e => update('twitter', e.target.value)}
                value={lead.twitter}
              />
              <FontAwesomeIcon icon={faTwitter} />
              {isUrlValid(lead.twitter) ? (
                <a href={linkify(lead.twitter)} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              ) : (
                <FontAwesomeIcon icon={faTwitter} />
              )}
            </div>
          )}
          {this.isRequired('instagram') && (
            <div className="field">
              <label>Instagram</label>
              <Input
                className="is-link"
                disabled={!canEdit}
                placeholder="https://instagram.com/username"
                onChange={e => update('instagram', e.target.value)}
                value={lead.instagram}
              />
              <FontAwesomeIcon icon={faInstagram} />
              {isUrlValid(lead.instagram) ? (
                <a href={linkify(lead.instagram)} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              ) : (
                <FontAwesomeIcon icon={faInstagram} />
              )}
            </div>
          )}
        </Card>

        <Card
          className={this.isSectionRequired('Company Information') ? 'inputs' : 'inputs hidden'}
        >
          <Header type="sans" size="3">
            Company
          </Header>
          {this.isRequired('company') && (
            <div className="field">
              <label>Company Name</label>
              <Input
                disabled={!canEdit}
                placeholder="Amazon, Google, etc"
                onChange={e => update('company', e.target.value)}
                value={lead.company}
              />
            </div>
          )}
          {this.isRequired('title') && (
            <div className="field">
              <label>Job Title</label>
              <Input
                disabled={!canEdit}
                placeholder="Chief Officer"
                onChange={e => update('title', e.target.value)}
                value={lead.title}
              />
            </div>
          )}
          {this.isRequired('job_position') && (
            <div className="field field-select">
              <label>Position</label>
              <Select
                className={isGenerated && softResults.includes('job_position') ? 'invalid' : ''}
                disabled={!canEdit}
                placeholder="Manager, Employee, etc"
                dropdownClassName="sx-dropdown"
                value={lead.job_position || undefined}
                onChange={val => update('job_position', val)}
              >
                {pickerData.job_position.map((p, i) => (
                  <Select.Option key={`pos-${i}`} value={p}>
                    {p}
                  </Select.Option>
                ))}
              </Select>
              {isGenerated && softResults.includes('job_position') && (
                <Tooltip
                  title={`Job Position does not fit the defined customer profile ${this.getOptions(
                    'job_position'
                  )}`}
                >
                  <img src={ErrorIcon} alt="" />
                </Tooltip>
              )}
            </div>
          )}
          {this.isRequired('industry') && (
            <div className="field field-select">
              <label>Industry</label>
              <Select
                className={isGenerated && softResults.includes('industry') ? 'invalid' : ''}
                disabled={!canEdit}
                placeholder="Agriculture, Apparel, etc"
                dropdownClassName="sx-dropdown"
                value={lead.industry || undefined}
                onChange={val => update('industry', val)}
              >
                {pickerData.industry.map((p, i) => (
                  <Select.Option key={`ind-${i}`} value={p}>
                    {p}
                  </Select.Option>
                ))}
              </Select>
              {isGenerated && softResults.includes('industry') && (
                <Tooltip
                  title={`Industry does not fit the defined customer profile ${this.getOptions(
                    'industry'
                  )}`}
                >
                  <img src={ErrorIcon} alt="" />
                </Tooltip>
              )}
            </div>
          )}
          {this.isRequired('website') && (
            <div className="field">
              <label>Website</label>
              <Input
                className="is-link"
                disabled={!canEdit}
                placeholder="https://www.company.com"
                onChange={e => update('website', e.target.value)}
                value={lead.website}
              />
              {isUrlValid(lead.website) ? (
                <a href={linkify(lead.website)} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faGlobeAmericas} />
                </a>
              ) : (
                <FontAwesomeIcon icon={faGlobeAmericas} />
              )}
            </div>
          )}
          {this.isRequired('company_scope') && (
            <div className="field">
              <label>Company Scope</label>
              <Input
                disabled={!canEdit}
                placeholder="Local, Regional, etc"
                onChange={e => update('company_scope', e.target.value)}
                value={lead.company_scope}
              />
            </div>
          )}
          {this.isRequired('number_of_employees') && (
            <div className="field">
              <label>Number of Employees</label>
              <Input
                disabled={!canEdit}
                placeholder="3000"
                onChange={e => update('number_of_employees', e.target.value || '0')}
                value={lead.number_of_employees}
              />
            </div>
          )}
          {this.isRequired('annual_revenue') && (
            <div className="field">
              <label>Annual Revenue</label>
              <Input
                disabled={!canEdit}
                placeholder="0"
                onChange={e => update('annual_revenue', e.target.value || '0')}
                value={lead.annual_revenue}
              />
            </div>
          )}
        </Card>

        {this.isRequired('location') && (
          <Card className="inputs">
            <Header type="sans" size="3">
              Location <span className="info-note">Minimum of 2 inputs required</span>
            </Header>
            <div className="field">
              <label>Street Name</label>
              <Location
                disabled={!canEdit}
                placeholder="123 Carson St"
                onChange={update}
                value={lead.street}
              />
            </div>
            <div className="field">
              <label>City</label>
              <Input
                disabled={!canEdit}
                placeholder="Albany"
                onChange={e => update('city', e.target.value)}
                value={lead.city}
              />
            </div>
            <div className="field">
              <label>State</label>
              <Input
                disabled={!canEdit}
                placeholder="New York"
                onChange={e => update('state', e.target.value)}
                value={lead.state}
              />
            </div>
            <div className="field">
              <label>Postal Code</label>
              <Input
                disabled={!canEdit}
                placeholder="10001"
                onChange={e => update('postal_code', e.target.value)}
                value={lead.postal_code}
              />
            </div>
            <div className="field">
              <label>Country</label>
              <Input
                disabled={!canEdit}
                placeholder="United States"
                onChange={e => update('country', e.target.value)}
                value={lead.country}
              />
            </div>
          </Card>
        )}

        {(lead.custom_fields || []).length > 0 && (
          <Card className="inputs">
            <Header type="sans" size="3">
              Custom Lead Fields
            </Header>
            {lead.custom_fields.map((f, i) => (
              <div className="field" key={'custom-field-' + i}>
                <label>{f.label}</label>
                <Input
                  disabled={!canEdit}
                  onChange={e => updateCustom(i, e.target.value)}
                  value={f.value}
                />
              </div>
            ))}
          </Card>
        )}
      </div>
    );
  }
}
