import gql from 'graphql-tag';

export const agentTransfers = gql`
  query agentTransfers($agent_id: String!, $starting_after: String, $ending_before: String) {
    agentTransfers(
      agent_id: $agent_id
      starting_after: $starting_after
      ending_before: $ending_before
    ) {
      data {
        id
        amount
        amount_reversed
        description
        campaign
        lead
        created
        reversed
      }
      hasMore
      pageTotal
    }
  }
`;

export const companyCharges = gql`
  query companyCharges($company_id: String!, $starting_after: String, $ending_before: String) {
    companyCharges(
      company_id: $company_id
      starting_after: $starting_after
      ending_before: $ending_before
    ) {
      data {
        id
        amount
        amount_refunded
        description
        campaign
        created
        succeeded
        refunded
        receipt_url
      }
      hasMore
      pageTotal
    }
  }
`;

export const getSubscription = gql`
  query {
    getSubscription {
      data
      internal_reps {
        id
        display_name
        picture
        createdAt
      }
      employees {
        id
        display_name
        picture
        createdAt
      }
    }
  }
`;