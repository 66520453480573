import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button } from '_seriph';
import { getColumns } from './Columns';
import EmptyBox from '../EmptyBox/EmptyBox';
import './UpcomingMeetings.scss';

class UpcomingMeetings extends Component {
  render() {
    const { meetings } = this.props;

    const columns = getColumns();
    return (
      <div id="internal-meetings-table">
        <h3>Upcoming Meetings</h3>
        { meetings?.length > 0 && <Link to="/analytics/meetings"><Button size="tiny" type="secondary">All Meetings</Button></Link> }
        
        { meetings?.length > 0 ? (
          <Table
            lined
            rounded
            locale={{ emptyText: 'No meetings found' }}
            dataSource={meetings.map((d, i) => {
              d.key = 'rtt-'+i;
              return d;
            })}
            scroll={{ x: 'max-content' }}
            pagination={{ pageSize: 10 }}
            columns={columns}
          />
        ) : (
          <EmptyBox
            title="Recently scheduled meetings will show here"
            description="Things will get more exciting once meetings are booked!"
            to="/analytics/meetings"
            button="My Meetings"
          />
        )}
      </div>
    );
  }
}

export default UpcomingMeetings;
