import React, { Component } from 'react';
import { Header, Card } from '_seriph';
import { numberWithCommas } from '_assets/js/helpers';
import { generateData } from './data';
import NotInterestedChart from './_charts/NotInterestedChart/NotInterestedChart';

import './LeadStats.scss';

class LeadStats extends Component {
  render() {
    const { data } = this.props;

    const realData = generateData(data);
    const completed = realData?.totals?.qualified + realData?.totals?.not_interested + realData?.totals?.invalid;

    return (
      <div id="rep-analytics-lead-stats">
        <Header size="3" font="sans">
          Lead Analytics
        </Header>
        <div className="xdr-totals">
          <Card>
            <div className="descriptor">
              Pulled In
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(realData?.totals?.pulled)}<span>leads</span>
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              For Review
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(realData?.totals?.review)}<span>leads</span>
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              Not Interested
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(realData?.totals?.not_interested)}<span>leads</span>
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              Invalid
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(realData?.totals?.invalid)}<span>leads</span>
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              Meetings Held
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(realData?.totals?.qualified)}<span>held</span>
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              Total Completed
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(completed)}<span>leads</span>
            </Header>
          </Card>
        </div>
        <div className="call-row">
          <NotInterestedChart
            data={realData.not_interested}
            totals={realData.totals}
          />
        </div>
      </div>
    );
  }
}

export default LeadStats;


