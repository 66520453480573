import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './Tabs.scss';

const Tab = incomingProps => {
  const props = { ...incomingProps };
  props.className = 'seriph-tab ' + (props.className || '');
  props.onClick = () => {
    if (props.to) {
      incomingProps.changePage(props.to);
    } else {
      incomingProps.selectTab(incomingProps.keyName);
    }
  };

  // Handle Incoming
  if (props.isActive) props.className += ' active';
  if (props.disabled) {
    props.onClick = null;
    props.className += ' disabled';
  }

  // Cleanup or causes errors
  const isVisible = props.visible === undefined || props.visible !== false ? true : false;
  delete props.isActive;
  delete props.selectTab;
  delete props.keyName;
  delete props.visible;
  delete props.changePage;

  return isVisible ? (
    <div {...props}>
      {props.children}
      {props.amount ? <span className="tab-amount">{props.amount}</span> : null}
    </div>
  ) : null;
};

class Tabs extends Component {
  selectTab = tabName => {
    if (this.props.onChange) this.props.onChange(tabName);
  };
  changePage = location => this.props.history.push(location);
  render() {
    const props = { ...this.props };
    let containerClassName = 'seriph-tabs';

    // Handle Size (small, medium, large)
    if (props.size) {
      containerClassName += ' tabs-' + props.size;
    }

    // Handle Size (small, medium, large)
    if (props.underline) {
      containerClassName += ' tabs-underline';
    }

    return (
      <div className={containerClassName}>
        {props.children.filter(c => c !== null).map((c, i) => {
          return React.cloneElement(c, {
            keyName: c.key || 'tab-item-' + i,
            isActive: props.currentTab && props.currentTab === c.key,
            selectTab: this.selectTab,
            changePage: this.changePage
          });
        })}
      </div>
    );
  }
}

Tabs.Tab = Tab;
export default withRouter(Tabs);
