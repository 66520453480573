import gql from 'graphql-tag';

export const notificationsFeed = gql`
  query notificationsFeed($results: Int, $page: Int) {
    notificationsFeed(results: $results, page: $page) {
      data {
        id

        title
        type
        for_type
        for_id
        expired
        result
        description

        campaign {
          name
          id
        }
        employee {
          id
          display_name
          picture
          company {
            logo
            name
          }
        }
        agent {
          id
          display_name
          picture
        }
        lead {
          id
          first_name
          last_name
        }

        updatedAt
        createdAt
      }
      total
    }
  }
`;

export const getNotificationCampaign = gql`
  query ($company_id: String!) {
    getNotificationCampaign(company_id: $company_id) {
      id
    }
  }
`;
