import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getErrors } from '_assets/js/helpers';
import { message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons';
import { getTemplate } from '_graphql/queries/campaign';
import CallFooter from './_components/CallFooter/CallFooter';
import ScheduleMeeting from 'App/Company/Leads/Lead/_components/ScheduleMeeting/ScheduleMeeting';
import Call from './_modals/Call/Call';
import { Device } from 'twilio-client';
import axios from 'axios';
import { convertTagsOnly } from '_helpers/smartTags';
import './CallPopout.scss';

const apiPath = process.env.REACT_APP_API_PATH;

class CallPopout extends Component {
  state = {
    loading: true,
    lead: null,
    answers: [],
    minimized: false,

    /* PHONE STATE */
    ready: false,
    callMade: false,
    lastCallSid: null,
    callSid: null,
    completed: false,
    time: 0,
    start: 0,
    mute: false,
    pressed: '',
    keyVisible: false,
    dropVisible: false,
    close: false,
    inputDevices: [],
    outputDevices: [],
    soundsEnabled: false,
    audioInput: undefined,
    audioOutput: undefined
  };
  componentDidMount = () => {
    if (Device) Device.destroy();
    const answers =
      this.props.sequence && this.props.sequence.lead ? this.props.sequence.lead.answers || [] : [];
    this.setState({ answers });
    setTimeout(() => {
      if (this.props.sequence && this.props.sequence.templateId) {
        this.loadTemplate();
      } else {
        this.setState({ loading: false });
      }
      this.setupPhone();
    }, 0);
  };
  componentWillUnmount = () => {
    this.killDevice();
  };
  setupPhone = () => {
    // Get Twilio Device Token
    axios
      .get(`${apiPath}/twilio/token`, {
        headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') }
      })
      .then(result => {
        const { data } = result;
        if (data && data.token) Device.setup(data.token);
      })
      .catch(err => console.log(err));

    // Listen to connection to get CallSID

    Device.on('connect', this.deviceOnConnect);
    Device.on('disconnect', this.deviceOnDisconnect);
    Device.on('ready', this.deviceOnReady);

  };
  deviceOnReady = () => {
    this.setState({ ready: true });
    // console.log('Device Ready', Math.random());
    Device.audio.on('deviceChange', this.updateMicOptions);
    Device._enabledSounds[Device.SoundName.Outgoing] = false;
    Device._enabledSounds[Device.SoundName.Disconnect] = false;
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        this.updateMicOptions();
        stream.getTracks().forEach(track => track.stop());
      })
      .catch(error => {
        console.error(error);
      });
  };
  deviceOnConnect = connection => {
    //console.log('Device connected', connection);
    this.setState(
      { callSid: connection.mediaStream.callSid, lastCallSid: connection.mediaStream.callSid },
      () => {
        this.startTimer();
      }
    );
  };
  deviceOnDisconnect = () => {
    //console.log('Device has been disconnected');
    this.setState({ callSid: null, callMade: true, pressed: '', mute: false });
    this.stopTimer();
  };
  killDevice = () => {
    Device.disconnectAll();
    Device.removeListener('connect', this.deviceOnConnect);
    Device.removeListener('disconnect', this.deviceOnDisconnect);
    Device.removeListener('ready', this.deviceOnReady);
    Device.destroy();
    clearInterval(this.timer);
  };
  updateMicOptions = () => {
    let inputDevices = [],
      outputDevices = [];
    Device.audio.availableInputDevices.forEach(d =>
      inputDevices.push({ value: d.deviceId, label: d.label })
    );
    Device.audio.availableOutputDevices.forEach(d =>
      outputDevices.push({ value: d.deviceId, label: d.label })
    );
    this.setState({
      inputDevices,
      audioInput: inputDevices.length > 0 ? inputDevices[0].value : undefined,
      outputDevices,
      audioOutput: outputDevices.length > 0 ? outputDevices[0].value : undefined
    });
  };
  makeCall = phoneNumber => {
    if (!this.state.callSid && phoneNumber) {
      const { lead, rep, campaign } = this.props.sequence;
      const number = phoneNumber;
      Device.connect({
        number,
        oneOff: true,
        campaignId: campaign.id,
        sequence_id: lead?.sequence_id || null,
        touchpoint_id: this.props.sequence.templateId,
        day: this.props.sequence.day || null,
        leadId: lead.id,
        leadType: lead.type,
        userId: rep.id,
        userRole: rep.role_type
      });
    }
  };
  endCall = () => Device.disconnectAll();
  changeDevice = val => {
    this.setState({ audioInput: val }, () => {
      Device.audio.setInputDevice(val);
    });
  };
  updateStatusSounds = (soundsEnabled) => {
    this.setState({ soundsEnabled }, () => {
      Device._enabledSounds[Device.SoundName.Outgoing] = soundsEnabled;
      Device._enabledSounds[Device.SoundName.Disconnect] = soundsEnabled;
    })
  };
  changeOutput = val => {
    //console.log('changing to: ', val);
    this.setState({ audioOutput: val }, () => {
      Device.audio.speakerDevices.set(val);
    });
  };
  testSpeakers = () => Device.audio.speakerDevices.test();
  startTimer() {
    this.setState({ time: 0, start: Date.now() }, () => {
      this.timer = setInterval(() => this.setState({ time: Date.now() - this.state.start }), 1000);
    });
  }
  stopTimer() {
    this.setState({ time: 0, start: 0 });
    clearInterval(this.timer);
  }
  toggleMute = () => {
    const isMuted = !this.state.mute;
    Device.activeConnection().mute(isMuted);
    this.setState({ mute: isMuted });
  };
  pressKey = key => {
    Device.activeConnection().sendDigits(key);
    this.setState({ pressed: this.state.pressed + key });
  };
  loadTemplate = () => {
    const { sequence } = this.props;
    this.props.client
      .query({ variables: { template_id: sequence.templateId }, query: getTemplate })
      .then(result => {
        if (result && result.data && result.data.getTemplate) {
          const template = this.convertCall(result.data.getTemplate);
          const answers = this.props.sequence.lead.answers
            ? [...this.props.sequence.lead.answers]
            : [];
          this.setState({ script: template.content, answers, loading: false });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not load script, try again');
        this.setState({ loading: false });
      });
  };
  convertCall = template => {
    const { lead, company, rep } = this.props.sequence;
    const replaceInfo = { lead: lead || {}, company: company || {}, rep: rep || {} };
    const templateWithoutSpan = convertTagsOnly(template.content);
    const preview = { content: templateWithoutSpan + '' };
    const content = templateWithoutSpan + '';
    (content.match(/\{\{(.*?)\}\}/g) || []).forEach(fullTag => {
      const parts = fullTag.replace('{{', '').replace('}}', '').split('.');
      const [tagType, tagField] = parts;
      const valueToReplace = replaceInfo[tagType][tagField] || null;
      if (valueToReplace && valueToReplace.length > 0) {
        preview.content = preview.content.replace(new RegExp(fullTag, 'g'), valueToReplace);
      }
    });
    return preview;
  };
  updateLead = answers => {
    this.setState({ answers });
  };
  hideCallPopout = () => {
    /* KILL DEVICE */
    this.killDevice();

    /* COMPLETE LEAD */
    if (this.props.sequence && this.props.sequence.templateId) this.completeTask();

    /* SAVE ANSWERS TO QUESTIONS */
    const { lead, saveAnswers } = this.props.sequence;
    saveAnswers(lead.id, this.state.answers);

    /* IF UPDATE NEEDED */
    if (this.props.update) this.props.update();

    /* DELAY AND CLOSE MODAL */
    this.setState({ close: true }, this.props.hideCallPopout);
  };
  completeTask = () => {
    if (this.state.callMade && this.props.sequence.complete && !this.state.completed) {
      this.props.sequence.complete(this.state.lastCallSid);
      this.setState({ completed: true });
    }
  };
  showModal = () => this.setState({ minimized: false });
  removeModal = () => this.setState({ minimized: true });
  render() {
    const { minimized, script, answers, loading, completed, callMade } = this.state;
    const { lead, rep, campaign } = this.props.sequence;

    /* CALL SETUP */

    const { integration, showCalendarModal } = this.props.sequence;
    const scheduleButton = (
      <ScheduleMeeting
        lead={lead}
        campaign={campaign}
        integration={integration}
        showCalendarModal={showCalendarModal}
      />
    );

    const {
      ready,
      callSid,
      mute,
      time,
      soundsEnabled,
      pressed,
      inputDevices,
      audioInput,
      outputDevices,
      audioOutput
    } = this.state;
    const callFooter = (
      <CallFooter
        lead={lead}
        rep={rep}
        ready={ready}
        scheduleButton={scheduleButton}
        canComplete={callMade && !completed && this.props.sequence.complete}
        completeTask={this.completeTask}
        callSid={callSid}
        mute={mute}
        soundsEnabled={soundsEnabled}
        time={time}
        pressed={pressed}
        inputDevices={inputDevices}
        audioInput={audioInput}
        outputDevices={outputDevices}
        audioOutput={audioOutput}
        makeCall={this.makeCall}
        endCall={this.endCall}
        toggleMute={this.toggleMute}
        pressKey={this.pressKey}
        testSpeakers={this.testSpeakers}
        changeDevice={this.changeDevice}
        updateStatusSounds={this.updateStatusSounds}
        changeOutput={this.changeOutput}
      />
    );
    const { questions, benefits, objections } = campaign;

    return (
      <React.Fragment>
        <div id="call-popout" className={!minimized ? 'hidden' : null}>
          <div className="call-expand" onClick={this.showModal}>
            <FontAwesomeIcon icon={faArrowsAltV} />
          </div>
          <div className="call-container">{callFooter}</div>
        </div>

        {!minimized ? (
          <Call
            client={this.props.client}
            loading={loading}
            lead={lead}
            campaign={campaign}
            callSid={callSid}
            script={script}
            questions={questions || []}
            benefits={benefits || []}
            objections={objections || []}
            answers={answers}
            updateLead={this.updateLead}
            callFooter={callFooter}
            hideCallPopout={this.hideCallPopout}
            removeModal={this.removeModal}
            sequence={this.props.sequence}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(CallPopout));
