import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { meetingScheduled } from '_graphql/mutations/lead';
import { getErrors } from '_assets/js/helpers';
import './CalendarModal.scss';

function isCalendlyEvent(e) {
  return e.data.event && e.data.event.indexOf('calendly') === 0;
}

export default class CalendarModal extends Component {
  state = {
    visible: true
  };
  componentDidMount = () => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);
    window.addEventListener('message', this.handleEvent);
  };
  componentWillUnmount = () => {
    window.removeEventListener('message', this.handleEvent);
  };
  meetingScheduled = ({ eventURI, eventId, calendarType }) => {
    this.props.client
      .mutate({
        variables: {
          lead_id: this.props.lead.id,
          event_uri: eventURI,
          calendar_type: calendarType,
          event_id: eventId,
        },
        mutation: meetingScheduled
      })
      .then(result => {
        if (result && result.data && result.data.meetingScheduled) {
          setTimeout(() => {
            message.success('Meeting scheduled.');
            if (this.props.reload) this.props.reload(this.props.lead.id);
            this.hideModal();
          }, 2000);
        } else {
          throw new Error('Error scheduling meeting, try again');
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Error scheduling meeting, try again');
      });
  };
  handleEvent = e => {
    if (isCalendlyEvent(e) && e.data.event === 'calendly.event_scheduled') {
      const eventURI = e?.data?.payload?.event?.uri;
      const re = /[^/]+(?=\/$|$)/ // get last part of calendly url (eventId)
      const eventId = eventURI.match(re)[0];
      this.meetingScheduled({ eventURI, calendarType: 'calendly', eventId });
    }
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { calendly, lead } = this.props;

    const calendlyUrl = `${calendly}?name=${lead.first_name + ' ' + lead.last_name}&email=${
      lead.email
    }&utm_source=${lead.id}`;

    return (
      <Modal
        wrapClassName="calendar-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        style={{ top: 60 }}
        width={600}
        closable={false}
        maskClosable={true}
      >
        <div
          className="calendly-inline-widget"
          style={{ minWidth: 320, height: '580px' }}
          data-url={calendlyUrl}
        />
      </Modal>
    );
  }
}
