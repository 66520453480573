import React, { Component } from 'react';
import { leadTexts } from '_graphql/queries/lead';
import Guide from '_styleguide/Guide/Guide';
import moment from 'moment';
import queryString from 'query-string';
import { IconMessage } from '@tabler/icons-react';
import { getErrors, prettyPhone } from '_assets/js/helpers';
import { Empty, Button, message, Loading, TextArea, Select } from '_seriph';
import { sendTextMessage } from '_graphql/mutations/lead';
import './Texts.scss';

const TextMessage = props => {
  const { text } = props;
  return (
    <div className={`text-box ${text.outgoing_text ? 'out' : 'in'}`} id={'text-' + text.id}>
      <div className="speech-bubble" dangerouslySetInnerHTML={{ __html: text.content }} />
      <div className="text-info">
        {moment(text.createdAt * 1).fromNow()}
      </div>
    </div>
  );
};

export default class Texts extends Component {
  state = {
    loading: true,
    texts: [],
    text: '',
    saving: false,
    readyForScroll: false,
    showEdit: false,
    sendTo: undefined
  };
  componentDidMount() {
    this.loadTexts(this.props.lead.id);
  }
  componentDidUpdate = (prevProps, prevState) => {
    const params = queryString.parse(this.props.location.search);
    if (params.text && prevState.readyForScroll !== this.state.readyForScroll) {
      document.getElementById('text-' + params.text).scrollIntoView({ behavior: 'smooth' });
    }
  };
  UNSAFE_componentWillMount = () => window.scrollTo({ top: 0 });
  createDates = texts => {
    let dates = [];
    texts.forEach(data => {
      dates.push(moment(data.createdAt * 1).format('dddd, MMMM Do YYYY'));
    });
    return [...new Set(dates.reverse())].reverse();
  };
  sendTextMessage = () => {
    this.setState({ sending: true });
    this.props.client
      .mutate({
        variables: {
          lead_id: this.props.lead?.id,
          to_number: this.state.sendTo?.split('---')?.[0],
          content: this.state.text
        },
        mutation: sendTextMessage
      })
      .then(result => {
        if (result && result.data && result.data.sendTextMessage) {
          this.setState({ sending: false, text: '', sendTo: undefined }, () => {
            this.loadTexts(this.props.lead.id);
          })
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not send text message, try again');
        this.setState({ sending: false });
      });
  };
  loadTexts = id => {
    this.props.client
      .query({
        variables: { lead_id: id },
        query: leadTexts
      })
      .then(result => {
        if (result && result.data && result.data.leadTexts) {
          this.setState({ texts: result.data.leadTexts }, () => {
            setTimeout(() => this.setState({ readyForScroll: true }), 100);
          });
        } else {
          message.error('Could not load texts, try again');
        }
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error('Could not load texts, try again');
      });
  };
  render() {
    const { loading, texts, showEdit, sending, sendTo } = this.state;
    const { canEdit, isAgent } = this.props;

    let dateList = null;
    if (texts.length > 0) {
      const dates = this.createDates(texts);
      dateList = dates.reverse().map((item, i) => {
        let textsList = texts.map(n => {
          if (moment(n.createdAt * 1).format('dddd, MMMM Do YYYY') === item) {
            return <TextMessage key={n.id} text={n} />;
          }
          return null;
        });
        return (
          <div key={'notegroup-' + i} date={item} className="date-section">
            <div className="note-date">
              <span>{item === moment().format('dddd, MMMM Do YYYY') ? 'Today' : item}</span>
            </div>
            {textsList.reverse()}
          </div>
        );
      });
    }

    const numbers = [
      { type: 'Phone', number: this.props.lead?.phone?.number },
      { type: 'Mobile', number: this.props.lead?.mobile?.number },
    ];

    this.props.lead?.custom_fields?.forEach(f => {
      if (f.type === 'phone') {
        numbers.push([{
          type: f.label,
          number: f.value
        }]);
      }
    })

    const hasNotes = texts.length > 0 ? true : false;

    return loading ? (
      <Loading />
    ) : (
      <div id="lead-texts" className="sx-form">
        {isAgent && (
          <div style={{ padding: '32px 32px 0px 32px' }}>
            <Guide
              id="lead-notes-agent"
              message="Send text messages to leads who want to learn more about your business."
            />
          </div>
        )}
        {canEdit && (hasNotes || (!hasNotes && showEdit)) ? (
          <div className="text-input">
            <div className="text-content">
              <TextArea
                value={this.state.text}
                onChange={e => this.setState({ text: e.target.value })}
                size="large"
                rows={2}
                placeholder={'Send a text message...'}
              />
              <div className="send-info">
                <Select
                  placeholder="Select a phone number"
                  value={this.state.sendTo}
                  size="small"
                  onChange={val => this.setState({ sendTo: val })}
                  getPopupContainer={trigger => trigger.parentNode}
                >
                  {numbers.map((n, i) => (
                    <Select.Option key={`pm-${i}`} value={n.number + '---' + n.type}>{n.type}:&nbsp;&nbsp;{prettyPhone(n.number)}</Select.Option>
                  ))}
                </Select>
                <Button
                  type="primary"
                  disabled={!this.state.text || !sendTo}
                  loading={sending}
                  onClick={this.sendTextMessage}
                >
                  Send Text Message
                </Button>
              </div>
              
            </div>
          </div>
        ) : null}
        {hasNotes || (!hasNotes && showEdit) ? (
          <div className="note-content">{texts.length > 0 ? dateList : null}</div>
        ) : (
          <Empty
            icon={<IconMessage />}
            type="negative"
            title="No texts messages"
            onClick={() => this.setState({ showEdit: true })}
            action={<span>+&nbsp;&nbsp;Send a text message</span>}
          >
            Text messages sent between the rep and the lead will be listed here when they are sent/received
          </Empty>
        )}
        
      </div>
    );
  }
}
