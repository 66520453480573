import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { prettyPhone } from '_assets/js/helpers';
import TwilioLogo from '_assets/brands/twilio.svg';
import { Card, Button } from '_seriph';
import { IconCheck, IconPlus } from '@tabler/icons-react';
import './Texting.scss';

class Texting extends Component {
  render() {
    const { loading, company } = this.props;
    return (
      <Card id="calendly">
        <div className="int">
          <div className="int-header">
            <div className="int-logo">
              <img src={TwilioLogo} title="Twilio" alt="Twilio" />
            </div>
            <h3 className="int-name">
              Twilio Texting
              { company?.text_number && <p>Number: {prettyPhone(company?.text_number)}</p> }
            </h3>
            {company?.text_number && !loading && (
              <div className="int-check">
                <IconCheck />
              </div>
            )}
          </div>
          <p className="int-desc">Twilio texting allows registered companies to send texts to leads/clients</p>
          <div className="int-actions">
            {(!company?.text_number || loading) && (
              <Button
                disabled={true}
                type="secondary"
                icon="left"
              >
                <IconPlus />
                Contact us to Register
              </Button>
            )}
            {company?.text_number && !loading && (
              <Button
                disabled={true}
                type="secondary"
                icon="left"
              >
                Registered: {prettyPhone(company?.text_number)}
              </Button>
            )}
          </div>
        </div>
      </Card>
    );
  }
}

export default withRouter(Texting);
