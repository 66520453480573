import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, TextArea } from '_seriph';
import { updateAgentProfileAbout } from 'Mutations/Agent/updateAgentProfile';

class AboutMeEdit extends Component {
  state = {
    about: this.props.agent.about,
    saving: false
  };
  save = () => {
    this.setState({ saving: true });
    this.props.apollo
      .mutate({
        variables: { about: this.state.about },
        mutation: updateAgentProfileAbout
      })
      .then(result => {
        if (result && result.data && result.data.updateAgentAbout) {
          this.props.close();
          this.props.reload();
        } else {
          this.setState({ saving: false });
        }
      })
      .catch(() => {
        this.setState({ saving: false });
      });
  };
  updateAbout = e => this.setState({ about: e.target.value });
  render() {
    return (
      <div
        id="agent-aboutme-edit"
        className="inline-form sx-theme"
        style={{ margin: '-5px -12px' }}
      >
        <div className="sx-form">
          <TextArea rows={6} autoSize={true} value={this.state.about} onChange={this.updateAbout} />
        </div>
        <div className="inline-actions">
          <Button type="default" onClick={this.props.close}>
            Cancel
          </Button>
          <Button type="primary" onClick={this.save} loading={this.state.saving}>
            Save
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.apollo };
};

export default connect(mapStateToProps, {})(AboutMeEdit);
