import React, { Component } from 'react';
import { Avatar } from '_seriph';
import { getLeadName } from '_helpers/lead';
import { IconCheck, IconChevronRight } from '@tabler/icons-react';
import { noCall } from '_assets/js/helpers';
import './CallList.scss';

const tagDisplay = {
  follow: 'Follow Up',
  nurture: 'Nurture',
  pause: 'Paused',
  manual: 'Manual'
};

const CallCard = props => {
  const { lead, currentCall, selectCurrent, disabled } = props;
  const leadCompleted = lead.completed ? true : false;
  const currentTask = lead?.current_task;
  const taskInfo = <React.Fragment>Day {currentTask?.day}, #{currentTask?.step}/{currentTask?.steps_today}</React.Fragment>;
  return (
    <div 
      className={
        `call-lead ${currentCall === lead.id ? 'active' : ''} ${leadCompleted ? 'completed' : ''} ${disabled ? 'disabled' : ''}`
      }
      onClick={() => disabled ? null : selectCurrent(lead)}
      key={'ll-'+lead.id}
      id={'ls-'+lead.id}
    >
      { leadCompleted ? <div className="cl-check"><IconCheck /></div> : null }
      <div className="cl-content">
        <h3>
          { getLeadName(lead) }
          {lead.tags?.length > 0 && (
            <div className={`call-tag ${lead.tags?.[0]?.tag}`}>
              <span className="tag-text">{tagDisplay[lead.tags?.[0]?.tag]}</span>
            </div>
          )}
        </h3>
        <p className="comp">{lead.company}</p>
        { lead.calls.length > 0 ? (
          <p>{lead.calls.filter(c => {
            const dontCall = noCall(lead.no_call_list, c?.number);
            return !c.skip && !c.invalid && !c.duplicate && !dontCall;
          }).map(c => c.name).join(' > ') || 'No numbers'} • {taskInfo}</p>
        ) : (
          <p>No valid numbers</p>
        )}
      </div>
      <div className="cl-chev"><IconChevronRight /></div>
    </div>
  );
};

export default class CallList extends Component {
  selectCurrent = lead => {
    this.props.selectCurrentCall(lead.id);
  }
  render() {
    const { campaign, calls, currentCall } = this.props;

    const completed = calls.filter(c => c.completed === true);
    const available = calls.filter(c => !c.completed);

    return (
      <div id="call-list">

        { /* CAMPAIGN INFO */ }
        <div className="cl-campaign">
          <Avatar type="company" size="mini" picture={campaign?.company?.logo} />
          <div className="cl-info">
            <h3>{campaign.name}</h3>
            <p>{completed.length} of {calls.length} called</p>
          </div>
        </div>

        { /* CALL LIST */ }
        <div className="lead-list" id="leadScroll">
          { completed.length > 0 ? (
            <React.Fragment>
              <div className="ls-section complete">
                <span /> COMPLETE&nbsp;&nbsp;({completed.length})
              </div>
              { completed.map(lead => (
                <CallCard
                  key={'cc-'+lead.id}
                  lead={lead}
                  currentCall={currentCall}
                  disabled={this.props.settingsDisabled}
                  selectCurrent={this.selectCurrent}
                />
              ))}
            </React.Fragment>
          ) : null }
          { available.length > 0 ? (
            <React.Fragment>
              <div className="ls-section available">
                <span /> AVAILABLE&nbsp;&nbsp;({available.length})
              </div>
              { available.map(lead => (
                <CallCard
                  key={'cc-'+lead.id}
                  lead={lead}
                  currentCall={currentCall}
                  disabled={this.props.settingsDisabled}
                  selectCurrent={this.selectCurrent}
                />
              ))}
            </React.Fragment>
          ) : null }


        </div>

      </div>
    );
  }
}