import React from 'react';
import { IconPencil } from '@tabler/icons-react';
import moment from 'moment';

export default function Update(props) {
  const { activity, user } = props;

  const fields = activity.update.fields;
  const justFields = fields.map(f => f.field_name);
  let changes =
    justFields.length === 2
      ? justFields[0].replace(/_/g, ' ') + ' & ' + justFields[1].replace(/_/g, ' ')
      : justFields.join(', ').replace(/_/g, ' ');

  return (
    <div className={'lead-activity ' + activity.type}>
      <div className="act-icon">
        <IconPencil />
      </div>
      <div className="descript">
        <b>{user.display_name}</b> updated Lead info: <span className="changes">{changes}</span>
        <div className="act-tstamp">
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;{moment(activity.createdAt * 1).format('MMM D @ h:mm a')}
        </div>
      </div>
    </div>
  );
}
