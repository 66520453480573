import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';
import { billingHistory } from '_graphql/queries/company';
import { getErrors } from '_assets/js/helpers';
import Loading from '_shared/Loading/Loading';
import BillDetails from './_modals/BillDetails/BillDetails';
import { Card, Header, Tabs, Table } from '_seriph';
import { getColumns } from './_data/Columns';
import { formatMoney } from '_assets/js/helpers';
import './Billing.scss';

class Billing extends Component {
  state = {
    loading: true,
    threshold: 0,
    runningTotal: 0,
    billing: [],
    tab: 'all',
    pagination: {
      pageSize: 10
    },
    billDetailsModal: null
  };
  componentDidMount = () => {
    this.reload();
  };
  reload = () => this.loadBilling({ results: 10, page: 1 });
  loadBilling = (params = {}) => {
    this.props.client
      .query({
        variables: {
          ...params,
          status: this.state.tab
        },
        query: billingHistory
      })
      .then(result => {
        if (result && result.data && result.data.billingHistory) {
          const billing = result.data.billingHistory.data || [];
          const threshold = result.data.billingHistory.threshold || 0;
          const runningTotal = result.data.billingHistory.runningTotal || 0;
          const pagination = { ...this.state.pagination };
          pagination.total = result.data.billingHistory.pageTotal;
          pagination.totalAll = result.data.billingHistory.total;
          this.setState({
            loading: false,
            billing,
            pagination,
            threshold,
            runningTotal
          });
        } else {
          throw new Error('Could not load billing, try again');
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        message.error(getErrors(err) || 'Could not load billing, try again');
      });
  };
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.loadBilling({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    });
  };
  changeTab = tab => {
    this.setState({ tab }, () => {
      this.reload();
    });
  };
  showBillDetailsModal = billId => this.setState({ billDetailsModal: billId });
  removeBillDetailsModal = () => this.setState({ billDetailsModal: null });
  render() {
    const { billing, pagination, loading, threshold, runningTotal, billDetailsModal } = this.state;

    const columns = getColumns(this.showBillDetailsModal);

    return loading ? (
      <Loading />
    ) : (
      <Card id="company-settings-billing">
        <Header size="2" type="sans">
          Billing History
        </Header>
        <Header className="running" size="4" type="sans">
          Running total:&nbsp;&nbsp;&nbsp;<span>{formatMoney(runningTotal)}</span> /{' '}
          {formatMoney(threshold)}
        </Header>
        <div className="filter-menu">
          <Tabs size="small" currentTab={this.state.tab} onChange={this.changeTab}>
            <Tabs.Tab key="all">All</Tabs.Tab>
            <Tabs.Tab key="paid">Paid</Tabs.Tab>
            <Tabs.Tab key="processing">Processing</Tabs.Tab>
            <Tabs.Tab key="failed">Failed</Tabs.Tab>
            <Tabs.Tab key="refunded">Refunded</Tabs.Tab>
          </Tabs>
        </div>
        <div className="billing-table">
          <Table
            striped={true}
            columns={columns}
            rowKey={record => record.id}
            dataSource={billing}
            pagination={pagination}
            loading={loading}
            onChange={this.handleTableChange}
            scroll={{ x: 'max-content' }}
            locale={{ emptyText: 'No bills found' }}
          />
        </div>
        {billDetailsModal ? (
          <BillDetails
            billId={billDetailsModal}
            client={this.props.client}
            reload={this.reload}
            removeModal={this.removeBillDetailsModal}
          />
        ) : null}
      </Card>
    );
  }
}

export default withRouter(Billing);
