import React, { Component } from 'react';
import { Loading, Modal } from '_seriph';
import { isUrlValid, linkify } from '_assets/js/helpers';
import './LeadInfo.scss';

export default class LeadInfo extends Component {
  state = {
    loading: false
  };
  transform = value => {
    if (isUrlValid(value)) {
      return (
        <a href={linkify(value)} target="_blank" rel="noopener noreferrer">{value}</a>
      );
    } else {
      return value;
    }
  }
  render() {
    const { loading } = this.state;
    const { lead } = this.props;

    let leadContact = true, leadPersonal = true, leadCompany = true;
    let leadSocial = true, leadLocation = true, leadCustom = true;

    if (!lead.email && !lead.phone && !lead.mobile && !lead.fax) {
      leadContact = false;
    }
    if (!lead.primary_language && !lead.education && !lead.race && !lead.gender && !lead.age) {
      leadPersonal = false;
    }
    if (!lead.company && !lead.company_scope && !lead.job_position && !lead.title && !lead.number_of_employees && !lead.industry && !lead.job_position && !lead.website) {
      leadCompany = false;
    }
    if (!lead.linkedin && !lead.facebook && !lead.twitter && !lead.instagram) {
      leadSocial = false;
    }
    if (!lead.street && !lead.city && !lead.state && !lead.postal_code && !lead.country) {
      leadLocation = false;
    }
    if (lead.custom_fields?.length <= 0) {
      leadCustom = false;
    }

    return loading ? (
      <Loading />
    ) : (
      <div id="dialer-lead-info">
        <div className="info-header">
          <Modal.Close onClick={this.props.close} />
          {lead?.salutation} {lead.first_name} {lead.last_name}
        </div>

        <div className="info-list">
          { leadContact ? (
            <React.Fragment>
              <div className="section">Contact</div>
              <div className="info">
                { lead.email ? <div><h4>Email Address</h4><p>{this.transform(lead.email)}</p></div> : null }
                { lead.phone ? <div><h4>Phone Number</h4><p>{this.transform(lead.phone)}</p></div> : null }
                { lead.mobile ? <div><h4>Mobile Number</h4><p>{this.transform(lead.mobile)}</p></div> : null }
                { lead.fax ? <div><h4>Fax Number</h4><p>{this.transform(lead.fax)}</p></div> : null }
              </div>
            </React.Fragment>
          ) : null }
          { leadPersonal ? (
            <React.Fragment>
              <div className="section">Personal</div>
              <div className="info">
                { lead.primary_language ? <div><h4>Primary Language</h4><p>{this.transform(lead.primary_language)}</p></div> : null }
                { lead.education ? <div><h4>Education</h4><p>{this.transform(lead.education)}</p></div> : null }
                { lead.race ? <div><h4>Ethnicity</h4><p>{this.transform(lead.race)}</p></div> : null }
                { lead.gender ? <div><h4>Gender</h4><p>{this.transform(lead.gender)}</p></div> : null }
                { lead.age ? <div><h4>Age</h4><p>{this.transform(lead.age)}</p></div> : null }
              </div>
            </React.Fragment>
          ) : null }
          { leadCompany ? (
            <React.Fragment>
              <div className="section">Company</div>
              <div className="info">
                { lead.company ? <div><h4>Company Name</h4><p>{this.transform(lead.company)}</p></div> : null }
                { lead.company_scope ? <div><h4>Company Scope</h4><p>{this.transform(lead.company_scope)}</p></div> : null }
                { lead.job_position ? <div><h4>Job Position</h4><p>{this.transform(lead.job_position)}</p></div> : null }
                { lead.title ? <div><h4>Job Title</h4><p>{this.transform(lead.title)}</p></div> : null }
                { lead.number_of_employees ? <div><h4>Number of Employees</h4><p>{this.transform(lead.number_of_employees)}</p></div> : null }
                { lead.annual_revenue ? <div><h4>Annual Revenue</h4><p>{this.transform(lead.annual_revenue)}</p></div> : null }
                { lead.industry ? <div><h4>Industry</h4><p>{this.transform(lead.industry)}</p></div> : null }
                { lead.website ? <div><h4>Website</h4><p>{this.transform(lead.website)}</p></div> : null }
              </div>
            </React.Fragment>
          ) : null }
          { leadSocial ? (
            <React.Fragment>
              <div className="section">Social</div>
              <div className="info">
                { lead.linkedin ? <div><h4>LinkedIn</h4><p>{this.transform(lead.linkedin)}</p></div> : null }
                { lead.facebook ? <div><h4>Facebook</h4><p>{this.transform(lead.facebook)}</p></div> : null }
                { lead.twitter ? <div><h4>Twitter</h4><p>{this.transform(lead.twitter)}</p></div> : null }
                { lead.instagram ? <div><h4>Instagram</h4><p>{this.transform(lead.instagram)}</p></div> : null }
              </div>
            </React.Fragment>
          ) : null }
          { leadLocation ? (
            <React.Fragment>
              <div className="section">Location</div>
              <div className="info">
                { lead.street ? <div><h4>Street</h4><p>{this.transform(lead.street)}</p></div> : null }
                { lead.city ? <div><h4>City</h4><p>{this.transform(lead.city)}</p></div> : null }
                { lead.state ? <div><h4>State</h4><p>{this.transform(lead.state)}</p></div> : null }
                { lead.postal_code ? <div><h4>Postal Code</h4><p>{this.transform(lead.postal_code)}</p></div> : null }
                { lead.country ? <div><h4>Country</h4><p>{this.transform(lead.country)}</p></div> : null }
              </div>
            </React.Fragment>
          ) : null }
          { leadCustom ? (
            <React.Fragment>
              <div className="section">Custom Fields</div>
              <div className="info">
                {lead.custom_fields.map((f, i) => (
                  <div key={'cf-call-' + i}><h4>{f.label}</h4><p>{this.transform(f.value)}</p></div>
                ))}
              </div>
            </React.Fragment>
          ) : null }
        </div>
      </div>
    );
  }
}
