import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Select } from 'antd';
import { repSearchName } from 'App/Admin/_graphql/queries';
import { getErrors } from '_assets/js/helpers';
import { message, Avatar } from '_seriph';
import './RepDropdown.scss';

class RepDropdown extends Component {
  state = {
    reps: [],
    value: undefined
  };
  componentDidMount = () => {
    this.fetch();
  }
  handleSearch = value => {
    if (value) {
      this.fetch(value);
    } else {
      this.setState({ reps: [] });
    }
  };
  fetch = () => {
    this.setState({ loading: true });
    this.props.apollo
      .query({ query: repSearchName })
      .then(result => {
        if (result?.data?.repSearchName) {
          this.setState({ reps: result.data.repSearchName });
        } else {
          throw new Error();
        }
      })
      .catch(err => message.error(getErrors(err) || 'Could not load reps, try again'));
  };
  render() {
    let options = this.state.reps.map(d => (
      <Select.Option key={d.id} value={d.id}>
        <Avatar className="multiple-user-avatar" size="micro" type="agent" picture={d.picture} />
        {d.display_name}
      </Select.Option>
    )).sort(o => o.id === this.props.value ? 1 : -1);
    return (
      <Select
        className="rep-drop-down"
        showSearch
        value={this.props.value}
        placeholder="Sales reps..."
        optionFilterProp="children"
        onChange={this.props.onChange}
        filterOption={(inputValue, option) => {
          return option?.props?.children[1]?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1
        }}
      >
        {this.props.value !== undefined ? (
          <Select.Option key="null-key" value={undefined}>None</Select.Option>
        ) : null}
        {options}
      </Select>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.apollo };
};

export default withRouter(connect(mapStateToProps, {})(RepDropdown));
