import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Skeleton } from 'antd';
import Listing from '../Listing/Listing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Card, Avatar } from '_seriph';
import './Recommended.scss';

const Fade = () => {
  return (
    <div className="fade">
      <div className="fade-header">
        <div className="av">
          <FontAwesomeIcon icon={faLock} />
        </div>
        <div className="info">
          <div>
            <div className="h3" />
          </div>
          <div>
            <div className="h5" />
          </div>
        </div>
        <div className="action" />
      </div>
      <div className="fade-body">
        <p />
        <p />
      </div>
      <div className="fade-footer">
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  );
};

class Recommended extends Component {
  hasApplied = applications => {
    const userId = this.props.user.id;
    for (let i = 0; i < applications.length; i++) {
      if (applications[i].agent_id === userId) return applications[i].status;
    }
    return false;
  };
  hasJoined = applications => {
    const userId = this.props.user.id;
    for (let i = 0; i < applications.length; i++) {
      if (applications[i].agent_id === userId) return applications[i].status;
    }
    return false;
  };
  render() {
    const { loading, info, campaigns, companies } = this.props;

    const isLoading = loading || info.is_cohort === undefined;
    const isCohort = info && info.is_cohort ? true : false;

    const firstThree = campaigns.slice(0, campaigns.length >= 3 ? 3 : campaigns.length);
    const lastList = campaigns.length > 3 ? campaigns.slice(3, campaigns.length) : [];

    return (
      <div id="discover-recommended">
        <div className="recommended-companies">
          {isLoading ? (
            <div className="loading-cards">
              <Card>
                <Skeleton active avatar paragraph={{ rows: 3 }} />
              </Card>
              <Card>
                <Skeleton active avatar paragraph={{ rows: 3 }} />
              </Card>
              <Card>
                <Skeleton active avatar paragraph={{ rows: 3 }} />
              </Card>
              <Card>
                <Skeleton active avatar paragraph={{ rows: 3 }} />
              </Card>
              <Card>
                <Skeleton active avatar paragraph={{ rows: 3 }} />
              </Card>
              <Card>
                <Skeleton active avatar paragraph={{ rows: 3 }} />
              </Card>
              <Card>
                <Skeleton active avatar paragraph={{ rows: 3 }} />
              </Card>
              <Card>
                <Skeleton active avatar paragraph={{ rows: 3 }} />
              </Card>
              <Card>
                <Skeleton active avatar paragraph={{ rows: 3 }} />
              </Card>
              <Card>
                <Skeleton active avatar paragraph={{ rows: 3 }} />
              </Card>
            </div>
          ) : (
            <React.Fragment>
              {firstThree.map(c => {
                const hasApplied = this.hasApplied(c.applications);
                const hasJoined = this.props.myCampaigns.find(m => m.id === c.id);
                const hasBeenInvited =
                  c.invites.includes(this.props.user.id) ||
                  c.invited_reps.includes(this.props.user.id);
                return (
                  <Listing
                    key={'c-' + c.id}
                    id={c.id}
                    companyId={c.company.id}
                    showModal={this.props.showModal}
                    campaign={c}
                    position={c.name}
                    category={
                      c.company && c.company.industries.length > 0
                        ? c.company.industries[0].name
                        : ''
                    }
                    name={c.company.name}
                    picture={c.company.logo || null}
                    favorite={this.props.favorites.find(f => f === c.id)}
                    addFavorite={this.props.addFavorite}
                    removeFavorite={this.props.removeFavorite}
                    description={c.description}
                    skills={c.target_skills}
                    hasApplied={hasApplied}
                    hasJoined={hasJoined ? true : false}
                    hasBeenInvited={hasBeenInvited}
                    isAgent={this.props.user.role_type === 'agent'}
                  />
                );
              })}
              {companies.length > 0 ? (
                <div className="company-list">
                  <h3>Companies on SellX</h3>
                  <div className="company-container">
                    {companies.map(company => (
                      <div className="company-card" key={'company-' + company.id}>
                        <Link to={`/company/${company.id}`}>
                          <Avatar size="small" picture={company.logo || null} />
                        </Link>
                        <Link to={`/company/${company.id}`}>
                          <h4>{company.name}</h4>
                        </Link>
                        <h5>
                          {company.industries.length > 0
                            ? company.industries[0].name
                            : 'Company'}
                        </h5>
                        <div className="tstamp">
                          Joined {moment(company.createdAt * 1).fromNow()}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}

              {lastList.map(c => {
                const hasApplied = this.hasApplied(c.applications);
                const hasJoined = this.props.myCampaigns.find(m => m.id === c.id);
                return (
                  <Listing
                    key={'c-' + c.id}
                    id={c.id}
                    companyId={c.company.id}
                    showModal={this.props.showModal}
                    campaign={c}
                    position={c.name}
                    category={
                      c.company && c.company.industries.length > 0
                        ? c.company.industries[0].name
                        : ''
                    }
                    name={c.company.name}
                    picture={c.company.logo || null}
                    favorite={this.props.favorites.find(f => f === c.id)}
                    addFavorite={this.props.addFavorite}
                    removeFavorite={this.props.removeFavorite}
                    description={c.description}
                    skills={c.target_skills}
                    hasApplied={hasApplied}
                    hasJoined={hasJoined ? true : false}
                    isAgent={this.props.user.role_type === 'agent'}
                  />
                );
              })}
              {isCohort && false ? (
                <div className="listing-fade">
                  <Fade />
                  <Fade />
                  <Fade />
                  <Fade />
                  <div className="fade-fade" />
                </div>
              ) : null}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default connect(mapStateToProps, {})(Recommended);
