import React, { Component } from 'react';
import { Modal, message, Button, Checkbox, TextArea, Avatar } from '_seriph';
import { createMessage } from 'Mutations/Message/message';
import { createInvite } from 'Mutations/Notification/notification';
import { statusMap } from '_constants/campaigns';
import { determineReputation, reputationTextIndex } from '_helpers/reputation';
import './InviteAgentModal.scss';
import classNames from 'classnames';

class InviteAgentModal extends Component {
  state = {
    visible: true,
    message: '',
    selectedCampaigns: {},
    stage: 'initial',
    loading: false
  };
  getSelectedIds = () => {
    let campIds = [];
    for (const campId in this.state.selectedCampaigns) {
      if (this.state.selectedCampaigns[campId] === true) {
        campIds.push(campId);
      }
    }
    return campIds;
  };

  hideModal = () => this.setState({ visible: false });
  handleClick = async () => {
    if (this.state.stage === 'initial') {
      this.setState({ stage: 'message-prompt' });
      return;
    }
    if (this.state.stage === 'message-prompt') {
      // they chose no message
      return await this.inviteAgent();
    }
    if (this.state.stage === 'message-entry') {
      await this.createMessage();
      return this.inviteAgent();
    }
  };

  inviteAgent = () => {
    this.setState({ loading: true });
    return this.props.client
      .mutate({
        variables: {
          agent_id: this.props.agent.id,
          campaign_ids: this.getSelectedIds()
        },
        mutation: createInvite
      })
      .then(result => {
        if (result && result.data && result.data.createInvite) {
          message.success('Invite sent to ' + this.props.agent.display_name);
          this.hideModal();
        } else {
          throw new Error('Could not send invite, try again');
        }
      })
      .catch(() => {
        message.error('Could not send invite, try again');
        this.setState({ loading: false });
      });
  };
  createMessage = async () => {
    this.setState({ loading: true });
    let result;
    try {
      result = await this.props.client.mutate({
        variables: {
          body: this.state.message,
          agent_id: this.props.agent.id,
          employee_id: this.props.user.id
        },
        mutation: createMessage
      });
    } catch (e) {
      message.error('Could not send message, try again');
      this.setState({ loading: false });
    }
    if (result?.data?.createMessage) {
      message.success('Message sent to ' + this.props.agent.display_name);
    } else {
      throw new Error('Could not send message, try again');
    }
  };
  toggleSelected = campaignId => {
    const selectedCampaigns = { ...this.state.selectedCampaigns };
    selectedCampaigns[campaignId] = !selectedCampaigns[campaignId];
    this.setState({ selectedCampaigns });
  };
  render() {
    const { agent } = this.props;
    let campaigns = this.props.campaigns.filter(camp => {
      const agentList = camp.agents.map(a => a.agent_id);
      const notInvited =
        !camp.invites.includes(agent.id) &&
        !camp.invited_reps.includes(agent.id) &&
        !agentList.includes(agent.id);
      const onlyActivePaused = ['active', 'inactive'].includes(camp.status);
      return notInvited && onlyActivePaused;
    });
    const reputationIndex = determineReputation(agent.reputation);
    return (
      <Modal
        wrapClassName="invite-agent-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        style={{ top: 60 }}
        width={600}
        closable={false}
        maskClosable={true}
      >
        <Modal.Close onClick={this.hideModal} />
        <div className="ia-agent">
          <Avatar size="large" type="agent" picture={agent.picture} reputation={agent.reputation} />
          <div className="ia-details">
            {this.state.stage === 'message-prompt' ? (
              <React.Fragment>
                <h3>Add a message to personalize your invitation?</h3>
                <p>Including a personal message increases the chances acceptance.</p>
              </React.Fragment>
            ) : null}

            {this.state.stage === 'initial' || this.state.stage === 'message-entry' ? (
              <React.Fragment>
                <h3>{agent.display_name}</h3>
                <p>
                  {agent.reputation} {reputationTextIndex(reputationIndex) || ''}
                </p>
              </React.Fragment>
            ) : null}
          </div>
        </div>
        {this.state.stage === 'initial' ? (
          <div className="ia-main">
            <div className="ia-main-header">
              <div>Invite {agent.display_name} to:</div>
              <div>{this.getSelectedIds()?.length || 0} selected</div>
            </div>

            <div className="ia-main-table">
              {campaigns?.map(campaign => {
                const isPendingReview = campaign.sub_status === 'review';
                return (
                  <div
                    className={classNames({
                      'ia-row': true,
                      disabled: isPendingReview,
                      selected: this.state.selectedCampaigns[campaign.id]
                    })}
                    key={campaign.id}
                    onClick={() => (isPendingReview ? null : this.toggleSelected(campaign.id))}
                  >
                    <div className="ia-row-left">
                      <Checkbox noBg={true} checked={this.state.selectedCampaigns[campaign.id]} />
                    </div>
                    <div className="ia-row-right">
                      <h4>{campaign.name}</h4>
                      <p>
                        {campaign.objective === 'leadgen'
                          ? 'Lead Generation'
                          : 'Lead Qualification'}
                        <span>
                          {' '}
                          • {isPendingReview ? 'Pending Review' : statusMap[campaign.status]}{' '}
                          {campaign?.status_updated_ago}
                        </span>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        {this.state.stage === 'message-entry' ? (
          <div className="ia-entry">
            <div className="ia-entry-header">
              <div>
                Include a message <span>(optional)</span>
              </div>
            </div>
            <TextArea
              placeholder={`Hi ${
                agent.first_name || 'there'
              }, please accept my invitation to our campaigns!`}
              value={this.state.message}
              onChange={e => this.setState({ message: e.target.value })}
            />
          </div>
        ) : null}
        <div className="ia-actions">
          {this.state.stage === 'initial' || this.state.stage === 'message-entry' ? (
            <Button onClick={this.hideModal}>Cancel</Button>
          ) : null}
          {this.state.stage === 'message-prompt' ? (
            <Button
              onClick={() => {
                this.setState({ stage: 'message-entry' });
              }}
            >
              Add a message
            </Button>
          ) : null}
          <Button
            type="primary"
            onClick={this.handleClick}
            loading={this.state.loading}
            disabled={this.getSelectedIds()?.length <= 0}
          >
            {this.state.stage === 'initial' || this.state.stage === 'message-entry' ? (
              <div>Send {this.getSelectedIds()?.length || 0} Invitations</div>
            ) : null}
            {this.state.stage === 'message-prompt' ? <div>Send without</div> : null}
          </Button>
        </div>
      </Modal>
    );
  }
}

export default InviteAgentModal;
