import React, { Component } from 'react';
import { Header, Avatar, Tooltip, Button, Modal, Card, Loading, message, Menu } from '_seriph';
import { getSubscription } from '_graphql/queries/stripe';
import { cancelSubscription } from '_graphql/mutations/subscription';
import { getErrors } from '_assets/js/helpers';
import { Dropdown } from 'antd';
import { IconDots } from '@tabler/icons-react';
import moment from 'moment';
import './Subscription.scss';

class Subscription extends Component {
  state = {
    loading: true,
    cancelling: false,
    sub: null,
    users: []
  }
  componentDidMount = () => {
    this.loadSubscription();
  }
  loadSubscription = () => {
    this.props.client.query({ query: getSubscription }).then(result => {
      if (result?.data?.getSubscription) {
        let subData = result?.data?.getSubscription?.data;
        try {
          subData = subData ? JSON.parse(subData) : null;
        } catch (err) {
          subData = null
        }
        const otherData = result?.data?.getSubscription;
        this.setState({
          sub: subData,
          users: [...otherData?.employees, ...otherData?.internal_reps],
          loading: false,
          cancelling: false
        });
      } else {
        throw new Error('Could not load subscription, try again');
      }
    })
    .catch(err => {
      this.setState({ loading: false, cancelling: false });
      message.error(getErrors(err) || 'Could not load billing, try again');
    });
  };
  cancel = status => {
    this.setState({ cancelling: true });
    return this.props.client
      .mutate({
        variables: { cancel: status },
        mutation: cancelSubscription
      })
      .then(result => {
        if (result?.data?.cancelSubscription) {
          this.loadSubscription();
          if (!status) message.success('Subscription set to cancel at the end of current cycle');
          if (status) message.success('Subscription set to continue');
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not cancel subscription, try again');
        this.setState({ cancelling: false });
      });
  };
  confirmCancel = () => {
    const cancelStatus = this.state.sub?.cancel_at_period_end ? false : true;
    if (cancelStatus) {
      Modal.confirm({
        title: 'Are you sure you want to set this subscription to cancel?',
        content: 'You will continue to have access to SellX until this cycle runs out and can continue the subscription if you change your mind.  If it lapses and you have to re-subscribe, you may miss out on possible savings.',
        onOk: () => this.cancel(cancelStatus),
        onCancel() {}
      });
    } else {
      this.cancel(false);
    }
  }
  render() {
    const { loading, sub, users, cancelling } = this.state;
    const { company } = this.props;

    const shouldDisable = cancelling ? true : false;
    const isCanceled = sub?.cancel_at_period_end ? true : false;
    const actionsMenu = (
      <Menu>
        <Menu.Item className={isCanceled ? 'success' : 'danger'} onClick={this.confirmCancel}>
          { isCanceled ? 'Continue' : 'Cancel'} plan
        </Menu.Item>
      </Menu>
    );

    const seatCount = company?.internal_seats || 0;

    return (
      <Card id="company-settings-plan-subscription">
        { loading ? <Loading /> : sub ? (
          <div className="settings-card">
            <Header size="4" type="sans">
              { sub?.metadata?.duration === '6' ? 'Semi-annual plan' : 'Annual plan' }
            </Header>
            <p className="seriph-subheader">{sub?.metadata?.seats || seatCount} seats, billed every {sub?.metadata?.duration} months</p>
            { isCanceled ? (
              <p className="seriph-subheader cancel">Plan ends on {moment(sub?.cancel_at*1000).format('M/D/YY')}</p>
            ) : (
              <p className="seriph-subheader renew">Renews on {moment(sub?.current_period_end*1000).format('M/D/YY')}</p>
            ) }
            <div className="my-reps">
              { users?.map(r => (
                <Tooltip key={r.id} title={r.display_name || 'hi'}>
                  <span><Avatar size="tiny" type="agent" picture={r.picture} /></span>
                </Tooltip>
              ))}
            </div>
            <div className="my-actions">
              <div className="left-actions">
                <Button size="tiny" disabled={shouldDisable}>+ Invite team</Button> 
              </div>
              <Dropdown
                overlayClassName="seriph-dropdown"
                overlay={actionsMenu}
                trigger={['click']}
                placement="bottomRight"
              >
                <div className="tbl-btn">
                  <IconDots size={18} stroke={2} />
                </div>
              </Dropdown>
            </div>
          </div>
        ) : (
          <div className="settings-card">
            <Header size="4" type="sans">
              { ['grandfathered', 'trial'].includes(company?.locked_type) && 'Free trial' }
              { ['trial-expired'].includes(company?.locked_type) && 'Trial expired' }
              { ['subscription-expired'].includes(company?.locked_type) && 'Subscription expired' }
            </Header>
            <p className="seriph-subheader">{seatCount} seat(s)</p>
            <div className="my-reps">
              { users?.map(r => (
                <Tooltip key={r.id} title={r.display_name || 'hi'}>
                  <span><Avatar size="tiny" type="agent" picture={r.picture} /></span>
                </Tooltip>
              ))}
            </div>
            { false && (
              <div className="my-actions">
                <div className="left-actions">
                  <Button size="tiny" type="primary" disabled={shouldDisable} onClick={this.props.showSubscribeModal}>Add seats</Button>
                </div>
              </div>
            )}
          </div>
        )}
      </Card>
    );
  }
}

export default Subscription;
