import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Layout, Button } from 'antd';
import queryString from 'query-string';
import logo from '_assets/img/logo_blue.svg';
import Footer from 'App/_shared/Footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { verifyAccount } from 'Mutations/User/user';

import './Verify.scss';

const { Content } = Layout;

class Verify extends Component {
  state = {
    loading: true,
    success: false
  };
  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    if (query.verification) {
      this.verifyAccount(query.verification);
    } else {
      this.props.history.push('/');
    }
  }
  verifyAccount = verification => {
    this.props.client
      .mutate({ variables: { verification: verification }, mutation: verifyAccount })
      .then(result => {
        if (result && result.data && result.data.verifyAccount) {
          this.setState({ success: true, loading: false });
        }
      });
  };
  render() {
    const { loading, success } = this.state;

    return (
      <Layout id="sellx-verify">
        <Content>
          {loading && (
            <div className="ready">
              <FontAwesomeIcon className="spinning" icon={faCircleNotch} spin />
            </div>
          )}

          {!loading && success && (
            <div className="forgot-body">
              <h3>
                <FontAwesomeIcon icon={faCheckCircle} />
                Email Verified
              </h3>
              <h5>Your email address has been successfully verified!</h5>
              <div style={{ textAlign: 'center', marginTop: '30px' }}>
                <Link to="/">
                  <Button className="btn btn-primary btn-large">Back to SellX</Button>
                </Link>
              </div>
            </div>
          )}
        </Content>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
            <h1>sellx</h1>
          </Link>
        </div>
        <Footer />
      </Layout>
    );
  }
}

export default withRouter(Verify);
