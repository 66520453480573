import React from 'react';
import { Link } from 'react-router-dom';
import { formatMoney, numberWithCommas, formatPercent } from '_assets/js/helpers';
import { Avatar, Button } from '_seriph';

export const getColumns = (masq) => {
  return [
    {
      title: 'Owner',
      dataIndex: 'owner',
      render: (column, data) => {
        return (
          <div className="meeting-own">
            <Avatar
              size="mini"
              type="company"
              picture={data.agent?.picture}
            />
            {data.agent?.display_name}
          </div>
        );
      }
    },
    {
      title: 'Manager',
      dataIndex: 'manager',
      render: (column, data) => {
        return data.agent?.manager?.admin?.display_name || <i>None</i>;
      }
    },
    {
      title: 'Touchpoint Stats',
      children: [
        {
          title: 'Calls',
          dataIndex: 'call',
          sorter: (a, b) => a.call - b.call,
          render: (column, data) => (
            <div className="tnum">
              {numberWithCommas(column)}
              <span className={data?.call_percent > 0 ? 'good' : 'bad'}>
                {data?.call_percent > 0 ? '+' : ''}{formatPercent(data?.call_percent, 1)}
              </span>
            </div>
          )
        },
        {
          title: 'Emails',
          dataIndex: 'email',
          sorter: (a, b) => a.email - b.email,
          render: (column, data) => (
            <div className="tnum">
              {numberWithCommas(column)}
              <span className={data?.email_percent > 0 ? 'good' : 'bad'}>
                {data?.email_percent > 0 ? '+' : ''}{formatPercent(data?.email_percent, 1)}
              </span>
            </div>
          )
        },
        {
          title: 'LinkedIns',
          dataIndex: 'linkedin',
          sorter: (a, b) => a.linkedin - b.linkedin,
          render: (column, data) => (
            <div className="tnum">
              {numberWithCommas(column)}
              <span className={data?.linkedin_percent >= 0 ? 'good' : 'bad'}>
                {data?.linkedin_percent > 0 ? '+' : ''}{formatPercent(data?.linkedin_percent, 1)}
              </span>
            </div>
          )
        },
        {
          title: 'Total Completed',
          dataIndex: 'total',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.total - b.total,
          render: (column, data) => (
            <div className="tnum">
              {numberWithCommas(column)}
              <span className={data?.total_percent > 0 ? 'good' : 'bad'}>
                {data?.total_percent > 0 ? '+' : ''}{formatPercent(data?.total_percent, 1)}
              </span>
            </div>
          )
        },
      ]
    },
    {
      title: 'Earnings',
      dataIndex: 'earnings',
      render: (column, data) => {
        if (data.agent?.sellx_rep) {
          return <span><b>$0</b> (SellX Rep)</span>;
        }
        let earned = 0;
        const tasks = data?.total || 0;
        if (tasks >= 500) earned = 100;
        if (tasks >= 1000) earned = 250;
        if (tasks >= 1500) earned = 500;

        return <b>{formatMoney(earned)}</b>;
      }
    },
    {
      title: 'Scorecard',
      dataIndex: 'scorecard',
      render: (column, data) => {
        return (
          <React.Fragment>
            <Link to={`/analytics/scorecard?rep_id=${data?.agent?.id}`}>
              <Button type="secondary">Scorecard</Button>
            </Link>
            <Button type="primary" onClick={() => masq(data?.agent?.id)} style={{marginLeft: '12px'}}>Login</Button>
          </React.Fragment>
        )
      }
    }
  ];
};
