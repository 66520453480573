import gql from 'graphql-tag';

export const createSubscription = gql`
  mutation createSubscription(
    $duration: Int!,
    $seats: Int!,
    $price: Float!
  ) {
    createSubscription(
      duration: $duration,
      seats: $seats,
      price: $price
    )
  }
`;

export const cancelSubscription = gql`
  mutation cancelSubscription(
    $cancel: Boolean!
  ) {
    cancelSubscription(
      cancel: $cancel
    )
  }
`;