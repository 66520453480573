import parsePhoneNumber from 'libphonenumber-js';

const allowedCountries = [
    {
      code: 'US',
      names: ['US', 'USA', 'UNITED STATES', 'UNITED STATES OF AMERICA'],
      postalCodeRegex: /^[0-9]{5}(?:-[0-9]{4})?$/
    },{
      code: 'CA',
      names: ['CA', 'CAN', 'CANADA'],
      postalCodeRegex: /[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d/
  }
];

export const isValidPostalCode = postalCode =>
 allowedCountries
    .map(country => country.postalCodeRegex)
    .some(countryRegex => countryRegex.test(postalCode));

export const isValidCountry = countryName => {
    let validNames = [];
    allowedCountries.map(country => validNames = [...validNames, ...country.names]);
    return validNames.includes(countryName.toUpperCase());
};

export const isValidPhone = phone => {
  let parsedPhone = parsePhoneNumber(phone, 'US');
   if (parsedPhone !== undefined) {
    return allowedCountries.map(country => country.code).includes(parsedPhone.country);
   }
}
   
