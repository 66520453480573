import React, { useState, useEffect } from 'react';

import { Modal, Select, Button, message } from '_seriph';
import { FollowUpTag } from './FollowUpTag';
import { NurtureTag } from './NurtureTag';
import { PauseTag } from './PauseTag';
import { ManualTag } from './ManualTag';
import { saveLeadTags } from '_graphql/mutations/lead';
import { tagMap } from './_variables';

const handleFormStateChange = setState => (name, value) => {
  setState(pS => ({
    ...pS,
    [name]: value
  }));
};

export function LeadTagModal({ open, close, lead, client, tagEdit }) {
  const [saving, setSaving] = useState(false);
  const [formState, setFormState] = useState({});

  useEffect(() => {
    setFormState({
      tag: '',
      note: '',
      date: '',
      opType: '',
      mode: 'new',
      ...tagEdit
    });
  }, [tagEdit]);

  const mayEdit = lead.stage !== 'completed';

  const { tag, mode, date, note, opType } = formState;
  const validations = {
    pause: [date],
    nurture: [date, opType],
    follow: [date],
    manual: []
  };
  const handleChange = handleFormStateChange(setFormState);
  const makeRequest = async (tags = []) => {
    return client
      .mutate({
        variables: {
          lead_id: lead.id,
          tags
        },
        mutation: saveLeadTags
      })
      .then(result => {
        if (result?.data?.saveLeadTags) {
          message.success(`Tag has been ${tags.length === 0 ? 'removed' : 'saved'}`);
          close();
        } else {
          throw new Error('Error saving tag information');
        }
      })
      .catch(() => {
        message.error('Error saving tag, try again.');
      });
  };

  const deleteTag = async () => {
    if (mayEdit) {
      Modal.confirm({
        title: 'Are you sure you want to remove this tag?',
        content: 'Are you sure you want to remove this tag?',
        onOk: async () => await makeRequest()
      });
    }
  };

  const submitLeadTag = async () => {
    setSaving(true);
    if (mayEdit) {
      if (validations[tag].filter(v => !v).length === 0) {
        await makeRequest([{ tag, note, date, opType }]);
      }
    }
    setSaving(false);
  };

  return (
    <Modal
      wrapClassName="lead-tag-modal"
      visible={open}
      title={null}
      footer={null}
      destroyOnClose={true}
      centered
      width={456}
      closable={false}
      maskClosable={false}
    >
      <div className="modal-header">
        <h4>{mode === 'new' ? 'Add' : 'Edit'} Tag</h4>
        <Modal.Close onClick={close} />
      </div>
      <div className="lead-tag-body">
        <Select
          value={tag}
          dropdownClassName="lead-tags"
          onChange={v => handleChange('tag', v)}
          label="Tag Type"
          style={{ width: '100%' }}
        >
          {Object.keys(tagMap).map(r => (
            <Select.Option value={r} label="tag" key={r}>
              <div className={tagMap[r].className}>{tagMap[r].display}</div>
            </Select.Option>
          ))}
        </Select>
        {tag === 'follow' && <FollowUpTag formState={formState} onChange={handleChange} />}
        {tag === 'nurture' && <NurtureTag formState={formState} onChange={handleChange} />}
        {tag === 'pause' && <PauseTag formState={formState} onChange={handleChange} />}
        {tag === 'manual' && <ManualTag formState={formState} onChange={handleChange} />}
      </div>
      <div className="lead-tag-footer">
        <div>
          {mode === 'new' ? (
            <></>
          ) : (
            <Button type="default" disabled={!mayEdit} onClick={deleteTag}>
              Delete
            </Button>
          )}
        </div>
        <div>
          <Button type="default" onClick={close}>
            Cancel
          </Button>
          <Button loading={saving} type="primary" disabled={!mayEdit} onClick={submitLeadTag}>
            {mode === 'new' ? 'Add' : 'Save'} Tag
          </Button>
        </div>
      </div>
    </Modal>
  );
}
