import React, { Component } from 'react';
import { Modal, Button, Header, Checkbox, message } from '_seriph';
import HubspotLogo from '_assets/brands/hubspot.svg';
import { getErrors } from '_assets/js/helpers';
import { updateHubspot } from '_graphql/mutations/integrations';
import './HubspotSettingsModal.scss';

export default class HubspotSettingsModal extends Component {
  state = {
    visible: true,
    form: {...this.props.integrations.hubspot}
  };
  saveSettings = () => {
    this.setState({ saving: true });
    const form = this.cleanForm();
    this.props.client
      .mutate({
        variables: { hubspot: {...form} },
        mutation: updateHubspot
      })
      .then(result => {
        if (result && result.data && result.data.updateHubspot) {
          if (this.props.reload) this.props.reload();
          message.success('Hubspot settings updated');
          this.setState({ saving: false });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Error updating hubspot settings, try again.');
      });
  };
  cleanForm = () => {
    const form = { ...this.state.form };
    delete form.portal_id;
    delete form.enabled;
    delete form.access_token;
    return form;
  };
  updateForm = (field, val) => {
    const form = {...this.state.form};
    form[field] = val;
    this.setState({ form });
  }
  updateSync = (field) => {
    const form = {...this.state.form};
    if (form.qual_sync.includes(field)) {
      form.qual_sync = form.qual_sync.filter(f => f !== field);
    } else {
      form.qual_sync.push(field);
    }
    this.setState({ form });
  }
  hideModal = () => this.setState({ visible: false });
  render() {
    const { form, saving } = this.state;

    return (
      <Modal
        wrapClassName="hubspot-settings-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={true}
        width={420}
      >
        <Modal.Close onClick={this.hideModal} />

        <Header size="2" type="serif" className="settings-header">
          <img className="chili-logo" src={HubspotLogo} title="Hubspot" alt="Hubspot" />
          Hubspot Settings
        </Header>

        <div className="options-list">
          <div className="descript">
            <Header size="4" type="sans" className="descript-title">Lead Qualification</Header>
            <div className="settings">
              <Header size="5" type="sans">Sync</Header>
              <Checkbox
                checked={form.qual_sync.includes('notes')}
                onChange={e => this.updateSync('notes', e.target.checked)}
              >
                Notes
              </Checkbox>
              <Checkbox
                checked={form.qual_sync.includes('calls')}
                onChange={e => this.updateSync('calls', e.target.checked)}
              >
                Calls
              </Checkbox>
              <Checkbox
                checked={form.qual_sync.includes('emails')}
                onChange={e => this.updateSync('emails', e.target.checked)}
              >
                Emails
              </Checkbox>
              <Checkbox
                checked={form.qual_sync.includes('meetings')}
                onChange={e => this.updateSync('meetings', e.target.checked)}
              >
                Meetings
              </Checkbox>
              <Header size="5" type="sans">Convert Qualified Lead</Header>
              <Checkbox
                checked={form.qual_convert}
                onChange={e => this.updateForm('qual_convert', e.target.checked)}
              >
                Update contact status if qualified
              </Checkbox>
            </div>
          </div>
        </div>

        <Modal.Actions align="right">
          <Button onClick={this.hideModal}>Close</Button>
          <Button type="primary" loading={saving} onClick={this.saveSettings}>Save Settings</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}