import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Skeleton, Collapse, Icon } from 'antd';
import { searchLeads, countLeads } from '_graphql/queries/lead';
import CreateLead from './_modals/CreateLead/CreateLead';
import BulkEmail from './_modals/BulkEmail/BulkEmail';
import Emailer from './_modals/Emailer/Emailer';
import Texter from './_modals/Texter/Texter';
import Lead from './Lead/Lead';
import LeadFilter from './_components/LeadFilter/LeadFilter';
import LeadHighlights from './_components/LeadHighlights/LeadHighlights';
import LeadCard from './_components/LeadCard/LeadCard';
import { numberWithCommas } from '_assets/js/helpers';
import Icons from 'App/_shared/Icons/Icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import leadStages from '../../../_constants/leadStages';
import classNames from 'classnames';
import { Input, Button, Avatar, CarouselList } from '_seriph';
import { CSSTransition } from 'react-transition-group';
import {
  IconPlus,
  IconCornerUpLeft,
  IconChevronDown,
  IconChevronUp,
  IconX,
  IconSearch,
  IconCheck
} from '@tabler/icons-react';
import './Leads.scss';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';

class Leads extends Component {
  state = {
    leads: [],
    pagination: { pageSize: 20 },
    page: 1,
    loading: true,
    searchText: '',
    hasMore: false,
    lead: this.props.leadId ? true : false,
    showFilter: false,
    showVeil: false,
    showCreate: false,
    showBulkEmail: null,
    showEmailer: false,
    full: true,
    totalLeadCount: 0,
    lastSearch: null,
    filter: {
      stages: [],
      campaigns: [],
      types: [],
      highlights: [],
      repId: null,
      for_today: false,
      follow_ups: false,
      tags: false,
      next_task_types: ['email', 'call', 'text', 'linkedin', 'instructions'],
      company: null
      // tag_types: ['follow', 'nurture', 'pause']
    },
    reloadHighlights: null,
    accordionKey: null,
    isSearching: false,
    isExitingSearch: false,
    isEnteringSearch: false,
    isExpanded: false,
    isSearchDismissed: false
  };

  constructor(props) {
    super(props);
    this.campaignDropdownRef = React.createRef();
    this.campaignHeaderRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount = () => {
    const stages = [];
    const highlights = [];
    const types = [];
    // const for_today = false;
    // const follow_ups = false;
    // const next_task_types = ['email', 'call', 'linkedin', 'general'];
    const params = queryString.parse(this.props.location.search);

    document.addEventListener('mousedown', this.handleClickOutside);
    if (params.campaign || params.stage || params.highlights || params.types)
      window.history.replaceState(null, null, window.location.pathname);
    if (params.types) types.push(params.types);
    if (params.highlights) highlights.push(params.highlights);
    let leadStageList = leadStages;
    const isAgent = this.props.user.role_type === 'agent';
    if (!isAgent) {
      leadStageList = [
        ...leadStages,
        {
          slug: 'unassigned',
          name: 'Unassigned',
          key: 'unassigned'
        }
      ];
    }

    if (params.stage) stages.push(params.stage);

    // const initialFilter = localStorage.getItem('lead-filters-v2')
    //   ? JSON.parse(localStorage.getItem('lead-filters-v2'))
    //   : {
    //     stages,
    //     campaigns: params.campaign ? [params.campaign] : [],
    //     types,
    //     repId: null,
    //     highlights,
    //     for_today,
    //     follow_ups,
    //     next_task_types
    //     // tag_types
    //   };

    this.setState(
      {
        // filter: initialFilter,
        leadStages: leadStageList,
        accordionKey: params.stage
      },
      () => {
        this.fetch({ results: 20, page: 1 }, true);
      }
    );
  };
  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClickOutside);
  };
  fetchCounts = () => {
    let variables = { name: this.state.searchText, ...this.state.filter };
    this.props.client.query({ variables, query: countLeads }).then(result => {
      this.setState({ leadCounts: result?.data?.countLeads });
    });
  };

  fetch = (params = {}, initial = false, lastSearch) => {
    if (initial) this.setState({ loading: true });
    let variables = { name: this.state.searchText, ...this.state.filter, ...params };
    variables.company_id = this.props.user.company;
    if (lastSearch) variables = { ...lastSearch };
    this.props.client
      .query({ variables, query: searchLeads })
      .then(result => {
        if (result && result.data) {
          const leads = result.data.searchLeads.data || [];
          const pagination = { ...this.state.pagination };
          pagination.total = result.data.searchLeads.pageTotal;
          pagination.totalAll = result.data.searchLeads.total;
          if (initial) {
            this.setState({
              loading: false,
              leads,
              pagination,
              hasMore: leads.length >= 20,
              page: 1
            });
          } else {
            const newleads = [...this.state.leads, ...leads];
            const lastLead = this.state.leads[this.state.leads.length - 1].id;
            const hasMore = newleads.length < result.data.searchLeads.pageTotal;
            this.setState(
              { loading: false, leads: newleads, pagination, page: params.page, hasMore },
              () => {
                const elmnt = document.getElementById('l-' + lastLead);
                elmnt.scrollIntoView({ behavior: 'smooth' });
              }
            );
          }
          this.setState({ lastSearch: variables });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(() => this.setState({ loading: false }));
    this.fetchCounts();
  };
  onChangeSearch = val =>
    this.setState({ searchText: val, filter: {...this.state.filter, company: null}, isSearchDismissed: false }, () =>
      this.fetch({ results: 20, page: 1 }, true)
    );
  getCampaign = id => {
    const exists = this.props.campaigns.find(c => c.id === id);
    if (exists) return exists;
    return {};
  };
  selectLead = (leadId, campaignId) => {
    this.setState({ lead: false }, () => {
      if (leadId === 'new') {
        this.props.history.push(`/leads/new?campaign=${campaignId}`);
      } else if (leadId === 'newQualified') {
        this.props.history.push(`/leads/newQualified?campaign=${campaignId}`);
      } else {
        this.props.history.push(`/leads/${leadId}`);
      }
      this.setState({ lead: true, showFilter: false }, () => {
        setTimeout(() => this.setState({ showVeil: false }), 1000);
      });
    });
  };
  reset = () => {
    this.setState({ lead: false }, () => {
      this.props.history.push(`/leads`);
    });
  };
  resetFilters = () => {
    this.updateFilter(
      { stages: [], campaigns: [], types: [], highlights: [], for_today: false },
      true
    );
  };
  reload = () => {
    if (this.state.lastSearch) {
      this.fetch({}, true, this.state.lastSearch);
    } else {
      this.fetch({ results: 20, page: 1 }, true, null);
    }
  };
  updateFilter = (filter, fromHighlights) => {
    this.setState(
      {
        filter,
        accordionKey: fromHighlights ? 'in-progress' : this.state.accordionKey
      },
      () => this.fetch({ results: 20, page: 1 }, true)
    );
    // ), localStorage.setItem('lead-filters-v2', JSON.stringify(filter));
  };
  toggleFilter = () => {
    const newVal = !this.state.showFilter;
    if (newVal && this.state.showFilter === this.state.showVeil) {
      this.setState({ showFilter: true, showVeil: true });
    } else if (this.state.showFilter === this.state.showVeil) {
      this.setState({ showFilter: false }, () => {
        setTimeout(() => this.setState({ showVeil: false }), 1000);
      });
    }
  };
  showCreateModal = () => this.setState({ showCreate: true });
  removeCreateModal = () => this.setState({ showCreate: false });
  showBulkEmailModal = (campaignIds, reload) =>
    this.setState({ showBulkEmail: campaignIds, reloadHighlights: reload });
  removeBulkEmailModal = () => this.setState({ showBulkEmail: null });
  showEmailerModal = () => this.setState({ showEmailer: true });
  removeEmailerModal = () => this.setState({ showEmailer: false });
  showTexterModal = () => this.setState({ showTexter: true });
  removeTexterModal = () => this.setState({ showTexter: false });
  isFiltering = () => {
    return this.state.filter.repId != null ||
      this.state.filter.campaigns.length ||
      this.state.filter.highlights.length ||
      this.state.filter.types.length
      ? true
      : false;
  };
  handleCollapseClick = activeKey => {
    let filter;
    filter = {
      ...this.state.filter,
      stages: activeKey ? [activeKey] : []
    };
    this.setState(
      {
        leads: [],
        loading: true,
        accordionKey: activeKey,
        filter
      },
      () => {
        if (activeKey) {
          this.fetch({ results: 20, page: 1 }, true);
        }
      }
    );
  };

  resetNextTaskType = () => {
    const filter = {
      ...this.state.filter,
      next_task_types: ['email', 'call', 'text', 'linkedin', 'instructions']
    };
    this.updateFilter(filter);
  };
  toggleExpanded = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };
  closeSearch = () => {
    this.onChangeSearch('');
    this.setState({ isSearching: false });
  };
  showSearch = () => {
    this.setState({ isSearching: true });
  };
  selectCampaign = (campaignId, clearSearch) => {
    // remove if already there
    if (this.state.filter.campaigns.includes(campaignId)) {
      this.updateFilter({
        ...this.state.filter,
        company: null,
        campaigns: this.state.filter.campaigns.filter(c => c !== campaignId)
      });
    } else {
      this.updateFilter({
        ...this.state.filter,
        company: null,
        campaigns: [...this.state.filter.campaigns, campaignId]
      });
    }
    this.setState({ isSearchDismissed: true });
    if (clearSearch) {
      this.closeSearch();
    }
  };

  selectCompany = (company) => {
    this.setState({ searchText: company, filter: {...this.state.filter, company}, isSearchDismissed: true }, () =>
      this.fetch({ results: 20, page: 1 }, true)
    );
  };

  handleClickOutside(event) {
    if (
      this.campaignDropdownRef?.current &&
      !this.campaignDropdownRef.current.contains(event.target)
    ) {
      if (this.campaignHeaderRef.current.contains(event.target)) return;
      this.setState({ isExpanded: false });
    }
  }

  render() {
    const { campaigns, leadId, hasLiveCampaign } = this.props;

    const isAgent = this.props.user.role_type === 'agent';

    let memberCampaigns = [...campaigns];
    if (isAgent) {
      memberCampaigns = campaigns.filter(c => {
        const member = c.agents.find(a => a.agent_id === this.props.user.id);
        return member && member.active ? true : false;
      });
    }

    const liveCampaigns = memberCampaigns.filter(c => c.status === 'active');
    const pausedCampaigns = memberCampaigns.filter(c => c.status === 'inactive');
    const completedCampaigns = memberCampaigns.filter(c => c.status === 'completed');
    const { loading, leads, hasMore, page, lead, pagination, showFilter, showVeil, filter, full } =
      this.state;

    const nextTaskTypes = [
      {
        name: 'Email',
        value: 'email'
      },
      {
        name: 'Call',
        value: 'call'
      },
      {
        name: 'Text',
        value: 'text'
      },
      {
        name: 'LinkedIn',
        value: 'linkedin'
      },
      {
        name: 'General',
        value: 'instructions'
      }
    ];
    const nextTasktagsTypes = [
      {
        name: 'Follow Up',
        value: 'follow'
      },
      {
        name: 'Paused',
        value: 'pause'
      },
      {
        name: 'Nurture',
        value: 'nurture'
      },
      {
        name: 'Manual',
        value: 'manual'
      }
    ];

    const isFiltering = this.isFiltering();
    const isSearching = this.state.searchText ? true : false;

    const campaignsInSearch = this.props.campaigns.filter(c =>
      c.name.toLowerCase().includes(this.state.searchText.toLowerCase())
    );

    let headingTitle = 'All Campaigns';
    if (this.state.filter?.campaigns?.length === 1) {
      headingTitle = campaigns.find(c => c.id === this.state.filter?.campaigns[0])?.name;
    } else if (this.state.filter?.campaigns?.length > 1) {
      headingTitle = `${this.state.filter.campaigns.length} Campaigns`;
    }

    // front-end search bolstering
    const searchResults = [...(this.state.leads || [])].map(l => {
      l.sort_name = (`${l?.first_name?.toLowerCase()}${l?.last_name?.toLowerCase()}`).replace(/\s+/g, '');
      l.company_name = (`${l?.company?.toLowerCase()}`).replace(/\s+/g, '');
      return l;
    }).filter(l => {
      const filterText = (`${this.state.searchText}`).replace(/\s+/g, '');
      return l.sort_name.startsWith(filterText) || l.company_name.includes(filterText) ? true : false;
    }).sort((a, b) => a.sort_name.localeCompare(b.sort_name)).slice(0, 5);

    // front-end search bolstering
    let companySearch = new Set();
    [...(this.state.leads || [])].forEach(l => {
      const filterText = (`${this.state.searchText}`).replace(/\s+/g, '');
      const companyText = (l?.company?.toLowerCase() || '').replace(/\s+/g, '');
      if (companyText.includes(filterText) && l.company) {
        companySearch.add(l.company);
      }
    });
    companySearch = [...companySearch];

    return (
      <div id="company-leads" className={full ? 'leads-full' : 'leads-small'}>
        <LeadFilter
          visible={showFilter}
          client={this.props.client}
          showVeil={showVeil}
          campaigns={campaigns}
          filter={filter}
          updateFilter={this.updateFilter}
          toggleFilter={this.toggleFilter}
          isFiltering={isFiltering}
          total={pagination.total || 0}
          name={this.state.searchText}
          user={this.props.user}
        />
        <div className="leads-list">
          <div className="list-heading">
            <CSSTransition
              in={this.state.isSearching}
              timeout={300}
              classNames="slide-down-custom"
              onEnter={() => this.setState({ isEnteringSearch: true })}
              onEntered={() => this.setState({ isEnteringSearch: false })}
              onExit={() => this.setState({ isExitingSearch: true })}
              onExited={() => this.setState({ isExitingSearch: false })}
              unmountOnExit
            >
              <div className="list-search">
                <Input
                  placeholder="Search..."
                  onChange={e => this.onChangeSearch(e.target.value)}
                  autoFocus
                  value={this.state.searchText}
                  onKeyDown={() => {
                    // escape
                    if (event.keyCode === 27) {
                      event.preventDefault();
                      this.closeSearch();
                    }

                    // enter
                    if (event.keyCode === 13) {
                      event.preventDefault();
                      this.setState({ isSearchDismissed: true });
                    }
                  }}
                />
                <Button type="secondary" onClick={this.closeSearch}>
                  <IconX />
                </Button>
              </div>
            </CSSTransition>
            {!this.state.isSearching &&
            !this.state.isExitingSearch &&
            !this.state.isEnteringSearch ? (
              <React.Fragment>
                <div
                  className="list-heading-left"
                  onClick={this.toggleExpanded}
                  ref={this.campaignHeaderRef}
                >
                  <div className="list-heading-title">{headingTitle}</div>
                  <div className="list-heading-icon">
                    {this.state.isExpanded ? <IconChevronUp /> : <IconChevronDown />}
                  </div>
                </div>
                <div className="list-heading-right">
                  <div className="list-header-search">
                    <IconSearch onClick={this.showSearch} />
                  </div>
                  {hasLiveCampaign ? (
                    <Button
                      onClick={this.showCreateModal}
                      type="default"
                      circle
                      size="tiny"
                      className="add-lead"
                    >
                      <IconPlus />
                    </Button>
                  ) : null}
                </div>
              </React.Fragment>
            ) : null}
          </div>

          <div className="filters">
            <div className="filters-top">
              <CarouselList>
                <Button
                  type={
                    !this.state.filter?.for_today &&
                    !this.state?.filter.follow_ups &&
                    !this.state?.filter.tags
                      ? 'primary'
                      : 'default'
                  }
                  onClick={() => {
                    const filter = {
                      ...this.state.filter,
                      for_today: false,
                      follow_ups: false,
                      tags: false,
                      next_task_types: ['email', 'call', 'text', 'linkedin', 'instructions']
                      // tag_types: ['follow', 'nurture', 'pause']
                    };
                    this.updateFilter(filter);
                  }}
                >
                  Status
                </Button>
                <Button
                  type={this.state.filter?.for_today ? 'primary' : 'default'}
                  onClick={() => {
                    const filter = {
                      ...this.state.filter,
                      for_today: true,
                      follow_ups: false,
                      tags: false,
                      next_task_types: ['email', 'call', 'text', 'linkedin', 'instructions']
                      // tag_types: ['follow', 'nurture', 'pause']
                    };
                    this.updateFilter(filter);
                  }}
                >
                  Today&apos;s tasks
                </Button>
                <Button
                  type={this.state.filter?.follow_ups ? 'primary' : 'default'}
                  onClick={() => {
                    const filter = {
                      ...this.state.filter,
                      for_today: false,
                      follow_ups: true,
                      tags: false,
                      next_task_types: ['email', 'call', 'text', 'linkedin', 'instructions']
                      // tag_types: ['follow', 'nurture', 'pause']
                    };
                    this.updateFilter(filter);
                  }}
                >
                  Responses
                </Button>
                {isAgent && (
                  <Button
                    type={this.state.filter?.tags ? 'primary' : 'default'}
                    onClick={() => {
                      const filter = {
                        ...this.state.filter,
                        for_today: false,
                        follow_ups: false,
                        // tag_types: ['follow', 'nurture', 'pause'],
                        next_task_types: ['follow', 'nurture', 'pause', 'manual'],
                        tags: true
                      };
                      this.updateFilter(filter);
                    }}
                  >
                    Tags
                  </Button>
                )}
              </CarouselList>
            </div>
            {this.state.filter?.for_today ||
            this.state.filter?.follow_ups ||
            this.state.filter?.tags ? (
              <div className="filters-bottom">
                <CarouselList classNames="xsm">
                  {(this.state.filter?.tags ? nextTasktagsTypes : nextTaskTypes).filter(type => {
                    const isMissed = this.state.filter?.follow_ups ? true : false;
                    if (!isMissed) return true;
                    return isMissed && (type?.value === 'email' || type?.value === 'call' || type?.value === 'text') ? true : false;
                  }).map(type => (
                    <Button
                      key={type.name}
                      type={
                        this.state?.filter?.next_task_types?.includes(type.value)
                          ? 'primary'
                          : 'default'
                      }
                      onClick={() => {
                        let filter = {
                          ...this.state.filter
                        };
                        if (filter.next_task_types.includes(type.value)) {
                          filter.next_task_types = filter.next_task_types.filter(
                            item => item !== type.value
                          );
                        } else {
                          filter.next_task_types.push(type.value);
                        }
                        this.updateFilter(filter);
                      }}
                      icon="right"
                    >
                      {type.name}{' '}
                      {this.state?.filter?.next_task_types?.includes(type.value) ? <IconX /> : null}
                    </Button>
                  ))}
                </CarouselList>
              </div>
            ) : null}
          </div>

          {this.state.isExpanded ? (
            <div className="campaign-list-dropdown" ref={this.campaignDropdownRef}>
              <div className="campaign-list-header">Active</div>
              {liveCampaigns.map(campaign => (
                <div
                  className="campaign-list-item"
                  key={campaign.id}
                  onClick={() => this.selectCampaign(campaign.id)}
                >
                  <Avatar picture={campaign.company.logo} />
                  {campaign.name}
                  {this.state.filter?.campaigns?.includes(campaign.id) ? <IconCheck /> : null}
                </div>
              ))}
              {pausedCampaigns?.length > 0 && (
                <Collapse
                  className="collapse-campaign-list"
                  expandIconPosition="end"
                  expandIcon={({ isActive }) => (
                    <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                  )}
                >
                  <CollapsePanel
                    className="campaign-list-header"
                    expandIconPosition="end"
                    header="Paused"
                    key="paused-compaigns"
                  >
                    {pausedCampaigns.map(campaign => (
                      <div
                        className="campaign-list-item"
                        key={campaign.id}
                        onClick={() => this.selectCampaign(campaign.id)}
                      >
                        <Avatar picture={campaign.company.logo} />
                        {campaign.name}
                        {this.state.filter?.campaigns?.includes(campaign.id) ? <IconCheck /> : null}
                      </div>
                    ))}
                  </CollapsePanel>
                </Collapse>
              )}
              {this.props.user.role_type === 'company' ? (
                <React.Fragment>
                  <div className="campaign-list-header">Completed</div>

                  {completedCampaigns.map(campaign => (
                    <div className="campaign-list-item" key={campaign.id}>
                      <Avatar picture={campaign.company.logo} />
                      {campaign.name}
                    </div>
                  ))}
                </React.Fragment>
              ) : null}
            </div>
          ) : null}

          {this.state.searchText.length && !this.state.isSearchDismissed ? (
            <div className="search-dropdown">
              {campaignsInSearch?.length ? <div className="search-header">Campaign</div> : null}
              {campaignsInSearch?.slice(0, 3).map(campaign => (
                <div
                  className="search-item"
                  key={campaign.id}
                  onClick={() => this.selectCampaign(campaign.id, true)}
                >
                  <Avatar picture={campaign.company.logo} />
                  {campaign.name}
                  {this.state.filter?.campaigns?.includes(campaign.id) ? <IconCheck /> : null}
                </div>
              ))}

              {companySearch?.length ? <div className="search-header">Company</div> : null}
              {companySearch?.slice(0, 3).map(company => (
                <div
                  className="search-item"
                  key={company}
                  onClick={() => this.selectCompany(company)}
                >
                  {company}
                </div>
              ))}

              {/*
              <div className="search-header">Company</div>
              <div className="search-item">
                <Avatar picture="..." />
                Some Company
              </div>
              */}

              {this.state.leads?.length ? <div className="search-header">Lead info</div> : null}
              {searchResults.map(lead => (
                <div
                  key={lead.id}
                  className="search-item"
                  onClick={() => {
                    this.setState({ isSearchDismissed: true });
                    this.selectLead(lead.id);
                  }}
                >
                  <Avatar />
                  {lead.first_name} {lead.last_name}
                </div>
              ))}
            </div>
          ) : null}

          <React.Fragment>
            <div
              id="scrollableDiv"
              className={classNames({
                'list-scroll': true,
                active: this.state.accordionKey
              })}
            >
              <Collapse
                activeKey={this.state.accordionKey}
                accordion={true}
                destroyInactivePanel={true}
                onChange={this.handleCollapseClick}
                expandIcon={({ isActive }) => (
                  <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                )}
              >
                {this.state?.leadStages?.map(stage => {
                  return (
                    <Collapse.Panel
                      header={
                        <React.Fragment>
                          <span className={classNames(['circle', stage.key])} />
                          {stage.name}
                        </React.Fragment>
                      }
                      className={
                        ((this.state.accordionKey && this.state.accordionKey !== stage.key
                          ? 'inactive'
                          : this.state.accordionKey
                          ? 'active'
                          : '') + this.state?.filter?.for_today
                          ? 'next-task-type'
                          : '')
                        + ((this.state.leadCounts?.[stage.slug] ?? 0) <= 0 ? ' nil' : '')
                      }
                      extra={numberWithCommas(this.state.leadCounts?.[stage.slug] ?? 0)}
                      key={stage.key}
                    >
                      <InfiniteScroll
                        dataLength={leads.length}
                        next={() => this.fetch({ results: 20, page: this.state.page + 1 })}
                        hasMore={hasMore}
                        loader={<p className="inf">Loading...</p>}
                        endMessage={
                          page > 1 && leads.length > 0 ? (
                            <p className="inf">No more leads to show.</p>
                          ) : (
                            <span></span>
                          )
                        }
                        scrollableTarget="scrollableDiv"
                      >
                        {loading === true && (
                          <div className="list-scroll">
                            {[...Array(3)].map((i, j) => (
                              <div className="lead-skeleton" key={`skel-${j}`}>
                                <Skeleton
                                  active
                                  paragraph={{ rows: 2, width: '100%' }}
                                  title={false}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                        {leads.map(lead => (
                          <LeadCard
                            client={this.props.client}
                            key={lead.id}
                            leadId={leadId}
                            selectLead={this.selectLead}
                            lead={lead}
                            isAgent={isAgent}
                            filter={this.state.filter}
                            campaign={this.getCampaign(lead.campaign_id)}
                          />
                        ))}
                        {page === 1 && leads.length <= 0 && (
                          <p className="inf">
                            {isFiltering || isSearching
                              ? 'No leads found'
                              : "You don't have any leads yet"}
                          </p>
                        )}
                      </InfiniteScroll>
                    </Collapse.Panel>
                  );
                })}
              </Collapse>
            </div>
          </React.Fragment>
          <Button
            type="secondary"
            icon="left"
            className={`back-to-highlights ${leadId && lead ? 'active' : ''}`}
            onClick={this.reset}
          >
            <IconCornerUpLeft />
            Back to Lead Highlights
          </Button>
          <Button
            type="secondary"
            icon="left"
            className={`reset-filters ${(!leadId || !lead) && isFiltering ? 'active' : ''}`}
            onClick={this.resetFilters}
          >
            <Icons.Filter />
            Clear Search Filter
          </Button>

          <div className="list-toggle" onClick={() => this.setState({ full: !full })}>
            {full ? (
              <FontAwesomeIcon icon={faChevronLeft} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </div>
        </div>
        <div className="leads-user">
          {(!leadId || !lead) && campaigns ? (
            <LeadHighlights
              user={this.props.user}
              info={this.props.info}
              client={this.props.client}
              campaigns={campaigns}
              updateFilter={this.updateFilter}
              showDialer={this.props.showDialer}
              showBulkEmailModal={this.showBulkEmailModal}
              showEmailerModal={this.showEmailerModal}
              showTexterModal={this.showTexterModal}
            />
          ) : (
            <Lead
              leadId={leadId}
              info={this.props.info}
              tab={this.props.tab}
              client={this.props.client}
              otherId={this.props.otherId}
              campaigns={campaigns}
              reload={this.reload}
              showDialer={this.props.showDialer}
              showCallPopout={this.props.showCallPopout}
              reset={this.reset}
            />
          )}
        </div>

        {this.state.showCreate ? (
          <CreateLead
            client={this.props.client}
            selectLead={this.selectLead}
            campaigns={campaigns}
            removeModal={this.removeCreateModal}
            reload={this.reload}
          />
        ) : null}
        {this.state.showBulkEmail ? (
          <BulkEmail
            client={this.props.client}
            removeModal={this.removeBulkEmailModal}
            agent={this.props.user}
            campaignId={this.state.showBulkEmail}
            reloadHighlights={this.state.reloadHighlights}
          />
        ) : null}
        {this.state.showEmailer ? (
          <Emailer
            client={this.props.client}
            removeModal={this.removeEmailerModal}
            agent={this.props.user}
            userInfo={this.props.info}
          />
        ) : null}
        {this.state.showTexter ? (
          <Texter
            client={this.props.client}
            removeModal={this.removeTexterModal}
            agent={this.props.user}
            userInfo={this.props.info}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(Leads));
