import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Header, Avatar, Button } from '_seriph';
import { IconPlus } from '@tabler/icons-react';
import './List.scss';

class List extends Component {
  reload = () => {
    if (this.props.message && this.props.message.id)
      this.props.selectMessage(this.props.message.id);
    this.props.loadMessages();
  };
  render() {
    const { campaigns, channels } = this.props;

    const activeCampaigns = campaigns
      .filter(c => ['active', 'inactive'].includes(c.status))
      .map(c => {
        return { ...c, messages: channels.find(n => n.campaign_id === c.id) || null };
      });
    const isAgent = this.props.user.role_type === 'agent';

    return (
      <div className="messaging-all" id="messaging-list">
        {false && (
          <React.Fragment>
            <Header font="sans" size="5" className="list-header">
              Campaigns
            </Header>
            <div className="category-list bottom">
              {activeCampaigns.map(a => {
                const m = a.messages || {};
                let className =
                  this.props.selected && this.props.selected.id === m.id
                    ? 'message message-active'
                    : 'message';
                const unread =
                  isAgent && !(m.feed_read || []).includes(this.props.user.id) ? true : false;
                if (unread) className += ' message-unread';
                return (
                  <div
                    className={className}
                    key={`ch-${a.id}`}
                    onClick={() => this.props.selectMessage(m.id, null, unread, a.id)}
                  >
                    <Avatar size="tiny" type="campaign" picture={a.company.logo} />
                    <div className="message-content">
                      <div className="message-info">
                        <Header font="sans" size="5">
                          {a.company.name}
                        </Header>
                        <div className="tstamp">
                          {m.updatedAt ? moment(m.updatedAt * 1).fromNow(true) : '0 msgs'}
                          {unread === true && <span className="message-alerting"></span>}
                        </div>
                      </div>
                      <div className="message-body">{a.name}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        )}
        <div className="category">
          <Header font="sans" size="5" className="list-header">
            Direct messages
          </Header>
          <Button circle type="default" size="tiny" onClick={this.props.showMessageOwnerModal}>
            <IconPlus />
          </Button>
        </div>
        <div className="category-list">
          {this.props.messages.map(m => {
            const unread = isAgent ? m.agent_unread : m.employee_unread;
            const user = isAgent ? m.employee : m.agent;
            let className =
              this.props.selected && this.props.selected.id === m.id
                ? 'message message-active'
                : 'message';
            if (unread) className += ' message-unread';

            let tag = null;
            if (!isAgent) {
              const hasCampaign = (campaigns || []).find(c => {
                const agentMap = c.agents.map(a => a.agent_id);
                return agentMap.includes(user.id);
              });
              tag = hasCampaign ? hasCampaign.name : 'Sales Rep';
            } else {
              tag = user.title ? user.title + ' at ' + user.company?.name : user.company?.name;
            }
            return (
              <div
                className={className}
                key={`m-${m.id}`}
                onClick={() => this.props.selectMessage(m.id, null, unread)}
              >
                {user.company && user?.company.logo ? (
                  <Avatar size="tiny" type="company" picture={user?.company.logo} />
                ) : (
                  <Avatar size="tiny" type="agent" picture={user.picture} />
                )}
                <div className="message-content">
                  <div className="message-info">
                    <Header font="sans" size="5">
                      {user.display_name}
                    </Header>
                    <div className="tstamp">
                      {moment(m.updatedAt * 1).fromNow(true)}
                      {unread === true && <span className="message-alerting"></span>}
                    </div>
                  </div>
                  <div className="message-body">{tag}</div>
                </div>
              </div>
            );
          })}
          {this.props.messages.length <= 0 ? <div className="no-messages">No messages</div> : null}
        </div>
      </div>
    );
  }
}

export default withRouter(List);
