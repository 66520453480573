import React, { Component } from 'react';
import { Modal, Button, Checkbox, TextArea, Avatar, message } from '_seriph';
import { getAgentReview } from 'src/Queries/Reviews/reviews';
import { removeAgent } from 'Mutations/Campaign/agents';
import { statusMap } from '_constants/campaigns';
import './RemoveAgentModal.scss';
import { Row, Col, Rate } from 'antd';

class RemoveAgentModal extends Component {
  state = {
    visible: true,
    message: '',
    selectedCampaign: null,
    loading: false,
    review: '',
    review_id: null,
    rating: {
      quality_of_work: 0,
      communication: 0,
      skills: 0,
      total: 0
    },
    stage: 'initial',
    campaigns: []
  };
  hideModal = () => this.setState({ visible: false });
  componentDidMount = () => {
    const { agent } = this.props;
    let campaigns = this.props.campaigns.filter(camp => {
      const isAgent = camp.agents.find(a => a.agent_id === agent.id);
      return isAgent?.active;
    });
    this.setState({ campaigns });
  };
  findReview = () => {
    this.setState({ loading: true });
    this.props.client
      .query({
        variables: { campaign_id: this.state.selectedCampaign?.id, agent_id: this.props.agent.id },
        query: getAgentReview
      })
      .then(result => {
        const data = result?.data?.agentReview;
        if (data) {
          const rating = {
            quality_of_work: data.quality_of_work,
            communication: data.communication,
            skills: data.skills,
            total: data.total
          };
          this.setState({ rating, review: data.review, loading: false });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(() => this.setState({ loading: false }));
  };
  advanceToRating = () => {
    this.setState({ stage: 'rating' });
    this.findReview();
  };

  removeAgent = () => {
    this.setState({ loading: true });
    this.props.client
      .mutate({
        variables: {
          campaign_id: this.state.selectedCampaign.id,
          agent_id: this.props.agent.id,
          review: this.state.review,
          ...this.state.rating
        },
        mutation: removeAgent
      })
      .then(result => {
        if (result?.data?.removeAgent) {
          message.success(this.props.agent.display_name + ' has been removed');
          if (this.props.reload) this.props.reload();
          this.hideModal();
        } else {
          throw new Error('Could not remove agent, try again');
        }
      })
      .catch(error => {
        console.log(error);
        message.error('Could not remove agent, try again');
        this.setState({ loading: false });
      });
  };

  changeRating = (key, val) => {
    const rating = { ...this.state.rating };
    rating[key] = val;
    rating.total = (rating.quality_of_work + rating.communication + rating.skills) / 3;
    this.setState({ rating, change: true }, () =>
      setTimeout(() => this.setState({ change: false }), 1000)
    );
  };

  render() {
    const { agent } = this.props;

    const totalRating = Math.round(this.state.rating.total * 2) / 2 || 0;
    const isInternal = agent?.is_internal;

    return (
      <Modal
        wrapClassName="remove-agent-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        style={{ top: 60 }}
        width={600}
        closable={false}
        maskClosable={true}
      >
        <Modal.Close onClick={this.hideModal} />
        <div className="ia-agent">
          <Avatar size="large" type="agent" picture={agent.picture} reputation={isInternal ? undefined : agent.reputation} />
          <div className="ia-details">
            <h3>Remove from campaign</h3>
            { isInternal ? (
              <p>
                The selected campaign will be hidden from {agent.display_name} unless re-added to
                the campaign.
              </p>
            ) : (
              <p>
                The selected campaign will be hidden from {agent.display_name} unless re-invited to
                the campaign.
              </p>
            )}
          </div>
        </div>
        {this.state.stage === 'initial' ? (
          <div className="ia-main">
            <div className="ia-main-table">
              {this.state.campaigns?.map(campaign => {
                return (
                  <div
                    className="ia-row"
                    key={campaign.id}
                    onClick={() => this.setState({ selectedCampaign: campaign })}
                  >
                    <div className="ia-row-left">
                      <Checkbox
                        noBg={true}
                        checked={this.state.selectedCampaign?.id === campaign.id}
                      />
                    </div>
                    <div className="ia-row-right">
                      <h4>{campaign.name}</h4>
                      <p>
                        <span className="obj">
                          {campaign.objective === 'leadgen'
                            ? 'Lead Generation'
                            : 'Lead Qualification'}
                        </span>

                        <span>{statusMap[campaign.status]} </span>
                        <span>{campaign?.status_updated_ago}</span>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        {this.state.stage === 'rating' ? (
          <div className="ra-body">
            <div className="ra-message">
              <Row gutter={30}>
                <Col span={8}>
                  <h3>Quality of Work *</h3>
                  <Rate
                    value={this.state.rating.quality_of_work}
                    onChange={val => this.changeRating('quality_of_work', val)}
                  />
                </Col>
                <Col span={8}>
                  <h3>Communication *</h3>
                  <Rate
                    value={this.state.rating.communication}
                    onChange={val => this.changeRating('communication', val)}
                  />
                </Col>
                <Col span={8}>
                  <h3>Overall Skills *</h3>
                  <Rate
                    value={this.state.rating.skills}
                    onChange={val => this.changeRating('skills', val)}
                  />
                </Col>
              </Row>
              <h3 className="ra-review">Write a review *</h3>
              <TextArea
                value={this.state.review}
                onChange={e => this.setState({ review: e.target.value })}
                size="large"
                rows={6}
                className="forms forms-textarea"
                placeholder="How did this rep perform? Would you hire them again?"
              />
              <Row gutter={30}>
                <Col span={12}>
                  Overall: <Rate allowHalf disabled value={totalRating} />
                </Col>
              </Row>
            </div>
          </div>
        ) : null}

        <div className="ia-actions">
          {this.state.stage === 'initial' ? <Button onClick={this.hideModal}>Cancel</Button> : null}
          {this.state.stage === 'rating' ? (
            <Button onClick={() => this.setState({ stage: 'initial' })}>Cancel</Button>
          ) : null}
          {this.state.stage === 'initial' && !isInternal ? (
            <Button
              type="primary"
              disabled={this.state.selectedCampaign == null}
              loading={this.state.loading}
              onClick={this.advanceToRating}
            >
              Next
            </Button>
          ) : null}
          {this.state.stage === 'rating' || isInternal ? (
            <Button
              type="primary"
              onClick={this.removeAgent}
              loading={this.state.loading}
              disabled={this.state.selectedCampaign == null}
            >
              Remove from campaign
            </Button>
          ) : null}
        </div>
      </Modal>
    );
  }
}

export default RemoveAgentModal;
