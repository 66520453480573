import React from 'react';

export const getColumns = (call, agentName, leadName) => {
  return [
    {
      title: 'Speaker',
      dataIndex: 'speaker',
      rowKey: 'speaker',
      width: 180,
      ellipsis: true,
      render: (c, r) => (
        <div className="spk-tbl">
          <div className={r.isAgent ? 'ball agent-ball' : 'ball lead-ball'} />
          <div>
            <h4>{r.isAgent ? agentName : leadName}</h4>
            <h5>{r.isAgent ? 'Sales Rep' : 'Lead'}</h5>
          </div>
        </div>
      )
    },
    {
      title: 'Time Code',
      dataIndex: 'code',
      rowKey: 'code',
      width: 120,
      render: c => (
        <div className="code" style={{ whiteSpace: 'nowrap' }}>
          {c}
        </div>
      )
    },
    {
      title: 'Recording Text',
      dataIndex: 'text',
      rowKey: 'text'
    }
  ];
};
