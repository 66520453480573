import React, { Component } from 'react';
import './Selection.scss';

export default class Selection extends Component {
  onChange = value => {
    if (this.props.multiple) {
      let multiple = this.props.value ? [...this.props.value] : [];
      if (multiple.includes(value)) {
        multiple = multiple.filter(item => item !== value);
      } else {
        multiple.push(value);
      }
      this.props.onChange(multiple);
    } else {
      this.props.onChange(value);
    }
  };
  isSelected = value => {
    if (this.props.multiple) {
      return (this.props.value || []).includes(value);
    } else {
      return this.props.value === value;
    }
  };
  render() {
    const options = this.props.values || [];
    const width = this.props.full ? 100 : Math.min((options.length / 4) * 100, 100);
    const otherClass = ' ' + (this.props.className || '');
    return (
      <div
        className={
          this.props.disabled
            ? 'seriph-selection disabled' + otherClass
            : 'seriph-selection' + otherClass
        }
        style={{ width: width + '%' }}
      >
        {options.map(option =>
          this.props.icons ? (
            <div
              className={this.isSelected(option.value) ? 'selected' : ''}
              key={'selection-' + option.value}
              onClick={() => (this.props.disabled ? null : this.onChange(option.value))}
            >
              <div className="opt-icon">
                <div className="icon-circle">{option.icon}</div>
                <span>{option.title}</span>
              </div>
            </div>
          ) : (
            <div
              className={this.isSelected(option.value) ? 'selected' : ''}
              key={'selection-' + option.value}
              onClick={() => (this.props.disabled ? null : this.onChange(option.value))}
            >
              {option.title}
              {option.description ? <div>{option.description}</div> : null}
              {option.helper ? <div className="helper">{option.helper}</div> : null}
            </div>
          )
        )}
      </div>
    );
  }
}
