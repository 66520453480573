import React, { Component } from 'react';
import { Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCheckCircle, faBan, faPlus } from '@fortawesome/free-solid-svg-icons';
import './ActionSelect.scss';

export default class ActionSelect extends Component {
  actionOptions = [
    {
      name: 'Unmatched',
      value: 'unmatched',
      icon: faInfoCircle
    },
    {
      name: 'Map to field',
      value: 'mapped',
      icon: faCheckCircle
    },
    {
      name: 'Ignore',
      value: 'ignore',
      icon: faBan
    },
    {
      name: 'Create new field',
      value: 'custom',
      icon: faPlus
    }
  ];
  render() {
    const { value, onChange } = this.props;

    return (
      <Select
        className="forms"
        dropdownClassName="sx-dropdown mapping-forms map-types"
        value={value}
        onChange={val => onChange(val)}
        getPopupContainer={trigger => trigger.parentNode}
      >
        {this.actionOptions.map(o => {
          return o.value !== 'unmatched' || (o.value === 'unmatched' && value === 'unmatched') ? (
            <Select.Option key={'as-' + o.value} value={o.value}>
              <span className="type-option">
                <FontAwesomeIcon icon={o.icon} />
                {o.name}
              </span>
            </Select.Option>
          ) : null;
        })}
      </Select>
    );
  }
}
