import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas, formatMoney } from '_assets/js/helpers';
import moment from 'moment';
import { Button, Header, Card } from '_seriph';
import {
  IconChevronRight,
  IconBolt,
  IconFlag,
  IconArrowRight,
  IconMail,
  IconMessage,
  IconPhone,
  IconFileCheck,
  IconMessageCircle2,
  IconBellRinging
} from '@tabler/icons-react';
import { hasAccess } from '_helpers/permissions';
import TaskProgress from './_components/TaskProgress/TaskProgress';
import './Highlights.scss';

class Highlights extends Component {
  render() {
    const counts = { ...this.props.counts, ...this.props.data };
    const campaignCount = (this.props.campaigns || []).filter(c => c.status === 'active').length;

    const hasHighlights =
      counts.message_count ||
      counts.ending_soon ||
      counts.activity.leads.disputed ||
      counts.notification_count ||
      counts.email_count ||
      counts.call_count ||
      counts.text_count
        ? true
        : false;

    return (
      <div id="agent-home-highlights">

        {hasAccess(['Rep']) && true && (
          <TaskProgress
            stipendData={this.props.data}
          />
        )}

        {hasAccess(['Rep']) && false && (
          <React.Fragment>
            <h3>Paid out</h3>
            <div id="agent-balance">
              {/* Hiding for now */ false && <h5>Balance</h5>}
              {/* Hiding for now */ false && <h2>{formatMoney(counts.balance || 0)}</h2>}
              <div className="others">
                <Card>
                  <Header size="2" font="sans">
                    {formatMoney(counts.last_payout_amount)}
                  </Header>
                  <div className="descriptor">
                    Last Payout{' '}
                    {counts.last_payout_amount
                      ? `(${moment(counts.last_payout_date * 1).format('M/D')})`
                      : null}
                  </div>
                </Card>
                <Card>
                  <Header size="2" font="sans">
                    {formatMoney(counts.last_thirty)}
                  </Header>
                  <div className="descriptor">Paid Last 30 Days</div>
                </Card>
              </div>
              <Link to="/settings/payouts">
                <div className="sxd-link">See all payouts</div>
              </Link>
            </div>
          </React.Fragment>
        )}

        <h3>Highlights</h3>
        <div className="all-highlights">
          {/* No Highlights */}
          {!hasHighlights ? (
            <div className="highlight none">
              <div className="highlight-icon">
                <IconBolt />
              </div>
              <div className="info">
                <Header size="5" font="sans">
                  Quickly act on the most important tasks
                </Header>
                <p>
                  We’ll only share time sensitive highlights like new messages, lead resubmissions,
                  ending campagins etc..
                </p>
              </div>
            </div>
          ) : null}

          {/* Messages */}
          {counts.message_count ? (
            <div
              className="highlight"
              onClick={this.props.loadMessaging ? this.props.loadMessaging : null}
            >
              <div className="highlight-icon" style={{ background: '#C1DDBB' }}>
                <IconMessageCircle2 />
              </div>
              <div className="info">
                <Header size="5" font="sans">
                  Unread Messages
                </Header>
                <p>
                  {counts.message_count} new message{counts.message_count !== 1 && 's'}
                </p>
              </div>
              <IconChevronRight />
            </div>
          ) : null}

          {/* Campaigns - Less than 48 hours left (90 day max?) */}
          {counts.ending_soon ? (
            <Link to="/campaigns">
              <div className="highlight">
                <div className="highlight-icon" style={{ background: '#FF684E' }}>
                  <IconFileCheck />
                </div>
                <div className="info">
                  <Header size="5" font="sans">
                    Campaigns ending soon
                  </Header>
                  <p>
                    {counts.ending_soon} out of {campaignCount} campaign{campaignCount !== 1 && 's'}{' '}
                    end within 48 hours
                  </p>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </Link>
          ) : null}

          {/* Leads */}
          {counts.activity.leads.disputed ? (
            <Link to="/leads?stage=disputed">
              <div className="highlight">
                <div className="highlight-icon" style={{ background: '#ebaea0' }}>
                  <IconFlag />
                </div>
                <div className="info">
                  <Header size="5" font="sans">
                    Leads that were disputed
                  </Header>
                  <p>
                    {counts.activity.leads.disputed} lead
                    {counts.activity.leads.disputed !== 1 && 's'} are disputed and may need work
                  </p>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </Link>
          ) : null}

          {/* Notifications */}
          {counts.notification_count ? (
            <div
              className="highlight"
              onClick={this.props.loadNotifications ? this.props.loadNotifications : null}
            >
              <div className="highlight-icon" style={{ background: '#D2D0FB' }}>
                <IconBellRinging />
              </div>
              <div className="info">
                <Header size="5" font="sans">
                  Unread Notifications
                </Header>
                <p>
                  {counts.notification_count} new notification
                  {counts.notification_count !== 1 && 's'}
                </p>
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          ) : null}

          {/* Unread Emails */}
          {counts.email_count ? (
            <Link to="/leads?highlights=email">
              <div className="highlight">
                <div className="highlight-icon" style={{ background: '#9dc0b7' }}>
                  <IconMail />
                </div>
                <div className="info">
                  <Header size="5" font="sans">
                    Unread Emails
                  </Header>
                  <p>
                    You have {counts.email_count} unread email{counts.email_count !== 1 && 's'} from
                    leads
                  </p>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </Link>
          ) : null}

          {/* Missed Calls */}
          {counts.call_count ? (
            <Link to="/leads?highlights=call">
              <div className="highlight">
                <div className="highlight-icon dark" style={{ background: '#e4d7a7' }}>
                  <IconPhone />
                </div>
                <div className="info">
                  <Header size="5" font="sans">
                    Missed Calls
                  </Header>
                  <p>
                    You have {counts.call_count} missed call{counts.call_count !== 1 && 's'} from
                    leads
                  </p>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </Link>
          ) : null}

          {/* Missed Texts */}
          {counts.text_count ? (
            <Link to="/leads?highlights=text">
              <div className="highlight">
                <div className="highlight-icon dark" style={{ background: '#e4d7a7' }}>
                  <IconMessage />
                </div>
                <div className="info">
                  <Header size="5" font="sans">
                    Missed Texts
                  </Header>
                  <p>
                    You have {counts.text_count} missed text{counts.text_count !== 1 && 's'} from
                    leads
                  </p>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </Link>
          ) : null}
        </div>

        <div className="divider" />

        <Header size="3" font="sans">
          Campaign activity
        </Header>
        <p className="activity-sub">
          There are {campaignCount} active campaign{campaignCount !== 1 && 's'}
        </p>

        <div className="live-activity">
          {/* IN PROGRESS */}
          <div
            className="status-activity"
            onClick={() => this.props.history.push('/leads?stage=in-progress')}
          >
            <div className="bar in-progress" />
            <div className="status-type">
              <h1>{numberWithCommas(counts.activity.leads.in_progress || 0)}</h1>
              <div>In Progress</div>
            </div>
          </div>

          {/* Review */}
          <div
            className="status-activity"
            onClick={() => this.props.history.push('/leads?stage=review')}
          >
            <div className="bar review" />
            <div className="status-type">
              <h1>{numberWithCommas(counts.activity.leads.review || 0)}</h1>
              <div>Pending Review</div>
            </div>
          </div>

          {/* Disputed */}
          <div
            className="status-activity"
            onClick={() => this.props.history.push('/leads?stage=disputed')}
          >
            <div className="bar disputed" />
            <div className="status-type">
              <h1>{numberWithCommas(counts.activity.leads.disputed || 0)}</h1>
              <div>Disputed</div>
            </div>
          </div>

          {/* Completed */}
          <div
            className="status-activity"
            onClick={() => this.props.history.push('/leads?stage=completed')}
          >
            <div className="bar completed" />
            <div className="status-type">
              <h1>{numberWithCommas(counts.activity.leads.completed || 0)}</h1>
              <div>Completed</div>
            </div>
          </div>

          {/* Not Interested */}
          <div
            className="status-activity"
            onClick={() => this.props.history.push('/leads?stage=not-interested')}
          >
            <div className="bar not-interested" />
            <div className="status-type">
              <h1>{numberWithCommas(counts.activity.leads.not_interested || 0)}</h1>
              <div>Not Interested</div>
            </div>
          </div>

          <Link to="/analytics">
            <Button type="secondary" size="medium" icon="right" block>
              See analytics
              <IconArrowRight />
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}

export default withRouter(Highlights);
