import React, { Component } from 'react';
import { Button, message } from 'antd';
import { createChat } from 'Mutations/Message/message';
import Avatar from '_styleguide/Avatar/Avatar';
import moment from 'moment';
import ReactQuill from 'react-quill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './Channel.scss';

const Chat = props => {
  const className = props.own ? 'message message-own' : 'message';
  return (
    <div className={className}>
      <div className="message-content">
        <div className="message-body" dangerouslySetInnerHTML={{ __html: props.body }} />
        <h3>
          {props.user.first_name}&nbsp;&bull;&nbsp;{moment(props.tstamp * 1).fromNow()}
        </h3>
      </div>
    </div>
  );
};

let initial = true;

class Channel extends Component {
  state = {
    body: '',
    loading: false
  };
  componentDidMount = () => {
    this.findBottom();
  };
  scrollToBottom = () => {
    if (this.props.message) {
      this.messagesEnd.scrollIntoView({ behavior: initial ? 'auto' : 'smooth' });
      initial = false;
    }
  };
  findBottom = () => {
    const scrollHeight = this.convoBox.scrollHeight;
    const height = this.convoBox.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.convoBox.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };
  componentDidUpdate = prevProps => {
    const prevId = prevProps.message ? prevProps.message.id : null;
    const newId = this.props.message ? this.props.message.id : null;
    if (prevId !== newId) {
      this.scrollToBottom();
    }
  };
  sendMessage = e => {
    if (e) e.preventDefault();
    if (this.state.body !== '') {
      this.setState({ loading: true });
      this.props.client
        .mutate({
          variables: { body: this.state.body, message_id: this.props.message.id },
          mutation: createChat
        })
        .then(result => {
          if (result && result.data && result.data.createChat) {
            this.props.reload(this.props.message.id, this.scrollToBottom);
            this.props.loadMessages();
            this.setState({ loading: false, body: '' });
          } else {
            throw new Error('Could not send message, try again');
          }
        })
        .catch(() => {
          message.error('Could not send message, try again');
          this.setState({ loading: false });
        });
    }
  };
  render() {
    const data = this.props.message;
    const campaign = this.props.campaign;
    const isRep = this.props.user.role_type === 'agent' ? true : false;
    const modules = {
      toolbar: [['bold', 'italic', 'underline', 'link']],
      clipboard: { matchVisual: false }
    };
    const formats = ['bold', 'italic', 'underline', 'link'];
    return (
      <div id="channel-conversation">
        <div className="close-icon" onClick={this.props.deselectMessage}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="conversation-title">
          <Avatar size="tiny" type="company" picture={campaign.company.logo} />
          <div className="title-info">
            <h2>{campaign.company.name}</h2>
            <h4>{campaign.name}</h4>
          </div>
        </div>
        <div className="conversation" ref={node => (this.convoBox = node)}>
          {data.conversation.map(c => {
            const muser = c.agent_id ? data.agent : data.employee;
            return (
              <Chat
                key={c.id}
                user={muser}
                own={muser.id === this.props.user.id}
                body={c.body}
                tstamp={c.createdAt}
              />
            );
          })}

          <div
            style={{ float: 'left', clear: 'both' }}
            ref={el => {
              this.messagesEnd = el;
            }}
          />
        </div>
        <div className="conversation-input">
          {isRep ? (
            <div className="no-input">Messaging is currently disabled in group feeds.</div>
          ) : (
            <div className="input-content">
              <ReactQuill
                formats={formats}
                modules={modules}
                value={this.state.body}
                onChange={body => this.setState({ body })}
                placeholder="Your message..."
              />
              <Button
                className="btnx btnx-primary"
                disabled={!this.props.user || !this.state.body}
                onClick={this.sendMessage}
              >
                Send
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Channel;
