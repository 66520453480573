import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { prettyPhone, getErrors } from '_assets/js/helpers';
import { checkVerification, resendVerification } from '_graphql/mutations/auth';
import { loginUser, logoutUser } from 'Store/user/user_actions';
import logo from '_assets/img/logo.svg';
import { Header, message, Button } from '_seriph';
import { Input } from 'antd';
import './VerifyPhone.scss';

class VerifyPhone extends Component {
  state = {
    checking: false,
    resending: false,
    code: {
      0: '',
      1: '',
      2: '',
      3: '',
      4: '',
      5: ''
    }
  };
  code = {};
  componentDidMount = () => {
    this.code['0']?.focus();
  };
  updateCode = e => {
    const code = { ...this.state.code };
    code[e.target.name] = e.target.value.length ? e.target.value[e.target.value.length - 1] : '';
    const name = e.target.name;
    const value = code[e.target.name];
    this.setState({ code }, () => {
      if (name === '5' && value) {
        // this.checkVerification();
      } else {
        this.code[parseInt(name) + 1].focus();
      }
    });
  };
  checkVerification = () => {
    this.setState({ checking: true });
    const code =
      this.state.code['0'] +
      this.state.code['1'] +
      this.state.code['2'] +
      this.state.code['3'] +
      this.state.code['4'] +
      this.state.code['5'];
    this.props.client
      .mutate({ variables: { code: code }, mutation: checkVerification })
      .then(result => {
        if (result.data.checkVerification) {
          this.props.loginUser(result.data.checkVerification);
        } else {
          this.setState({ checking: false });
          message.error('Invalid token, try again');
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Invalid token, try again');
        this.setState({ checking: false });
      });
  };
  resendVerification = () => {
    this.setState({ resending: true });
    this.props.client
      .mutate({ variables: { code: 'XXXXXX' }, mutation: resendVerification })
      .then(() => {
        message.success('Code re-sent, check your text messages');
        this.setState({ resending: false });
      })
      .catch(err => {
        this.setState({ resending: false });
        message.error(getErrors(err) || 'Invalid token, try again');
      });
  };
  logoutUser = () => {
    this.props.logoutUser(() => this.props.history.push('/'));
  };
  render() {
    const { checking, resending, code } = this.state;
    const { user } = this.props;

    return (
      <div id="onboarding" className="main-bg verify">
        <Button className="logout-btn" type="secondary" onClick={this.logoutUser}>
          Sign out
        </Button>
        <div className="home-logo">
          <img src={logo} alt={logo} />
        </div>
        <div className="home">
          <div className="home-left"></div>
          <div className="home-right">
            <div className="home-form">
              <Header type="display" size="4" className="headline">
                Verify your account
              </Header>
              <div className="user-example">
                <div className="user-card">
                  <div className="uc-name">{user.display_name}</div>
                  <div>•</div>
                  <div className="uc-email">{user.email}</div>
                </div>
              </div>
              <div className="verify-box">
                <p>
                  Enter the verification code sent to: <b>{prettyPhone(user.phone_number)}</b>
                </p>
                <div className="code">
                  <Input
                    disabled={checking}
                    autoComplete="off"
                    name="0"
                    value={code['0']}
                    onChange={this.updateCode}
                    ref={nput => {
                      this.code['0'] = nput;
                    }}
                  />
                  <Input
                    disabled={checking}
                    autoComplete="off"
                    name="1"
                    value={code['1']}
                    onChange={this.updateCode}
                    ref={nput => {
                      this.code['1'] = nput;
                    }}
                  />
                  <Input
                    disabled={checking}
                    autoComplete="off"
                    name="2"
                    value={code['2']}
                    onChange={this.updateCode}
                    ref={nput => {
                      this.code['2'] = nput;
                    }}
                  />
                  <Input
                    disabled={checking}
                    autoComplete="off"
                    name="3"
                    value={code['3']}
                    onChange={this.updateCode}
                    ref={nput => {
                      this.code['3'] = nput;
                    }}
                  />
                  <Input
                    disabled={checking}
                    autoComplete="off"
                    name="4"
                    value={code['4']}
                    onChange={this.updateCode}
                    ref={nput => {
                      this.code['4'] = nput;
                    }}
                  />
                  <Input
                    disabled={checking}
                    autoComplete="off"
                    name="5"
                    value={code['5']}
                    onChange={this.updateCode}
                    ref={nput => {
                      this.code['5'] = nput;
                    }}
                  />
                </div>
              </div>
              <Button
                type="default"
                size="large"
                loading={checking}
                onClick={this.checkVerification}
              >
                Submit code
              </Button>
              <Button
                className="resend-btn"
                type="primary"
                size="large"
                loading={resending}
                onClick={this.resendVerification}
              >
                Resend Code
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, { loginUser, logoutUser })(VerifyPhone));
