import React, { Component } from 'react';
import { Modal, Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import './RoleBreakdown.scss';

export default class RoleBreakdown extends Component {
  state = {
    visible: true
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const yes = (
      <Tooltip overlayClassName="sellx-tooltip sellx-tooltip-small" title="Allowed">
        <FontAwesomeIcon icon={faCheckCircle} />
      </Tooltip>
    );
    const no = (
      <Tooltip overlayClassName="sellx-tooltip sellx-tooltip-small" title="Not Allowed">
        <FontAwesomeIcon icon={faTimesCircle} />
      </Tooltip>
    );

    return (
      <Modal
        wrapClassName="role-breakdown-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        style={{ top: 60 }}
        width={700}
        closable={false}
        maskClosable={true}
      >
        <div className="close-icon" onClick={this.hideModal}>
          <FontAwesomeIcon icon={faTimes} />
        </div>

        <div className="itm-title">
          <h3 className="ra-title">Role Permissions</h3>
          <p>
            Here are the permissions for each role so you can decide which to give your team members
          </p>
        </div>

        <div className="itm-form sx-form">
          <table className="role-table">
            <tbody>
              {/* ACCOUNT */}
              <tr className="cat">
                <td>Account</td>
                <td>Admin</td>
                <td>Manager</td>
                <td>Member</td>
              </tr>
              <tr className="first">
                <td>Edit Company Profile</td>
                <td>{yes}</td>
                <td>{yes}</td>
                <td>{yes}</td>
              </tr>
              <tr className="first">
                <td>Edit Payment Method</td>
                <td>{yes}</td>
                <td>{no}</td>
                <td>{no}</td>
              </tr>
              <tr className="first">
                <td>View Payments/Receipts</td>
                <td>{yes}</td>
                <td>{yes}</td>
                <td>{no}</td>
              </tr>
              <tr className="first">
                <td>Manage Integrations</td>
                <td>{yes}</td>
                <td>{yes}</td>
                <td>{no}</td>
              </tr>
              <tr className="first">
                <td>Manage Team</td>
                <td>{yes}</td>
                <td>{no}</td>
                <td>{no}</td>
              </tr>

              {/* CAMPAIGN */}
              <tr className="cat">
                <td colSpan="4">Campaigns</td>
              </tr>
              <tr className="first">
                <td>Create or Duplicate</td>
                <td>{yes}</td>
                <td>{yes}</td>
                <td>{yes}</td>
              </tr>
              <tr className="first">
                <td>Edit (Draft)</td>
                <td>{yes}</td>
                <td>{yes}</td>
                <td>{yes}</td>
              </tr>
              <tr className="first">
                <td>Launch/Pause/End/Delete/Export</td>
                <td>{yes}</td>
                <td>{yes}</td>
                <td>{no}</td>
              </tr>
              <tr className="first">
                <td>Edit (Active/Paused/Completed)</td>
                <td>{yes}</td>
                <td>{yes}</td>
                <td>{no}</td>
              </tr>

              {/* LEADS */}
              <tr className="cat">
                <td colSpan="4">Leads</td>
              </tr>
              <tr className="first">
                <td>Dispute or Accept</td>
                <td>{yes}</td>
                <td>{yes}</td>
                <td>{no}</td>
              </tr>

              {/* REPS */}
              <tr className="cat">
                <td colSpan="4">Sales Reps</td>
              </tr>
              <tr className="first">
                <td>Invite Sales Reps</td>
                <td>{yes}</td>
                <td>{yes}</td>
                <td>{no}</td>
              </tr>
              <tr className="first">
                <td>Manage Applications</td>
                <td>{yes}</td>
                <td>{yes}</td>
                <td>{no}</td>
              </tr>
              <tr className="first">
                <td>Manage Sales Reps</td>
                <td>{yes}</td>
                <td>{yes}</td>
                <td>{no}</td>
              </tr>

              {/* MESSAGES/NOTIFICATIONS */}
              <tr className="cat">
                <td colSpan="4">Messages/Notifications</td>
              </tr>
              <tr className="first">
                <td>Message Reps</td>
                <td>{yes}</td>
                <td>{yes}</td>
                <td>{yes}</td>
              </tr>
              <tr className="first">
                <td>Campaign Announcements</td>
                <td>{yes}</td>
                <td>{yes}</td>
                <td>{yes}</td>
              </tr>
              <tr className="first">
                <td>Receive Notifications</td>
                <td>{yes}</td>
                <td>{yes}</td>
                <td>{yes}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="itm-actions">
          <Button className="btnx btnx-default" onClick={this.hideModal}>
            Close
          </Button>
        </div>
      </Modal>
    );
  }
}
