import React, { Component } from 'react';
import { Modal, message, Button, Checkbox, Avatar } from '_seriph';
import { addInternalToCampaigns } from '_graphql/mutations/internal';
import { statusMap } from '_constants/campaigns';
import './AddAgentModal.scss';
import classNames from 'classnames';

class AddAgentModal extends Component {
  state = {
    visible: true,
    message: '',
    selectedCampaigns: {},
    stage: 'initial',
    loading: false
  };
  getSelectedIds = () => {
    let campIds = [];
    for (const campId in this.state.selectedCampaigns) {
      if (this.state.selectedCampaigns[campId] === true) {
        campIds.push(campId);
      }
    }
    return campIds;
  };
  hideModal = () => this.setState({ visible: false });
  addAgent = () => {
    this.setState({ loading: true });
    return this.props.client
      .mutate({
        variables: {
          agent_id: this.props.agent.id,
          campaign_ids: this.getSelectedIds()
        },
        mutation: addInternalToCampaigns
      })
      .then(result => {
        if (result?.data?.addInternalToCampaigns) {
          message.success(this.props.agent.display_name + ' added to ' + this.getSelectedIds()?.length + ' campaigns');
          if (this.props.reload) this.props.reload();
          this.hideModal();
        } else {
          throw new Error('Could not send invite, try again');
        }
      })
      .catch(() => {
        message.error('Could not send invite, try again');
        this.setState({ loading: false });
      });
  };
  toggleSelected = campaignId => {
    const selectedCampaigns = { ...this.state.selectedCampaigns };
    selectedCampaigns[campaignId] = !selectedCampaigns[campaignId];
    this.setState({ selectedCampaigns });
  };
  render() {
    const { agent } = this.props;
    let campaigns = this.props.campaigns.map(camp => {
      const member = camp.agents.find(a => a.agent_id === agent.id);
      camp.activeMember = member && member.active ? true : false;
      camp.previousMember = member && !member.active ? true : false;
      return camp;
    }).filter(camp => {
      const onlyActivePaused = ['active', 'inactive'].includes(camp.status);
      return onlyActivePaused && !camp.activeMember;
    });

    return (
      <Modal
        wrapClassName="add-agent-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        style={{ top: 60 }}
        width={600}
        closable={false}
        maskClosable={true}
      >
        <Modal.Close onClick={this.hideModal} />
        <div className="ia-agent">
          <Avatar size="large" type="agent" picture={agent.picture} />
          <div className="ia-details">
            <h3>{agent.display_name}</h3>
          </div>
        </div>
        <div className="ia-main">
          <div className="ia-main-header">
            <div>Add {agent.display_name} to:</div>
            <div>{this.getSelectedIds()?.length || 0} selected</div>
          </div>

          <div className="ia-main-table">
            {campaigns?.map(campaign => {
              const isPendingReview = campaign.sub_status === 'review';
              return (
                <div
                  className={classNames({
                    'ia-row': true,
                    disabled: isPendingReview || campaign.activeMember,
                    selected: this.state.selectedCampaigns[campaign.id]
                  })}
                  key={campaign.id}
                  onClick={() => (isPendingReview ? null : this.toggleSelected(campaign.id))}
                >
                  <div className="ia-row-left">
                    <Checkbox noBg={true} checked={this.state.selectedCampaigns[campaign.id]} />
                  </div>
                  <div className="ia-row-right">
                    <h4>{campaign.name}</h4>
                    <p>
                      {campaign.objective === 'leadgen'
                        ? 'Lead Generation'
                        : 'Lead Qualification'}
                      <span>
                        {' '}
                        • {isPendingReview ? 'Pending Review' : statusMap[campaign.status]}{' '}
                        { campaign.activeMember && ' • Current Member' }
                        { campaign.previousMember && ' • Previous Member' }
                      </span>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="ia-actions">
          <Button onClick={this.hideModal}>Cancel</Button>
          <Button
            type="primary"
            onClick={this.addAgent}
            loading={this.state.loading}
            disabled={this.getSelectedIds()?.length <= 0}
          >
            <div>Add to {this.getSelectedIds()?.length || 0} Campaign(s)</div>
          </Button>
        </div>
      </Modal>
    );
  }
}

export default AddAgentModal;
