import React from 'react';
import { 
  IconPhone,
  IconLoader,
  IconPhoneOutgoing,
  IconPhoneCall
} from '@tabler/icons-react';
import './PhoneStatus.scss';


const renderSwitch = status => {
  switch(status) {
    case 'loading':
      return <IconLoader />;
    case 'ringing':
      return <IconPhoneOutgoing />;
    case 'in-progress':
      return <IconPhoneCall />;
    default:
      return <IconPhone />;
  }
}

export default function PhoneStatus(props) {

  const { status } = props;

  return (
    <div id="dialer-phone-status" className={status === 'in-progress' ? 'connected' : ''}>
      {renderSwitch(status)}
    </div>
  );
}
