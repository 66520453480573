import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, message, Header, Button, Select } from '_seriph';
import { DatePicker } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { downloadFile } from '_assets/js/helpers';
import './XDRStats.scss';

const { MonthPicker, WeekPicker } = DatePicker;

class XDRStats extends Component {
  state = {
    loading: false,
    visible: true,
    date: moment().format('YYYYMMDD'),
    type: 'weekly',
  };
  getXDRStats = () => {
    this.setState({ loading: true });
    const axiosData = {
      date: this.state.date,
      type: this.state.type
    };
    axios.post(`${process.env.REACT_APP_API_PATH}/v1/xdrstats/export`, axiosData, {
      headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') }
    }).then(res => {
      console.log(res.data.csv);
      if (res && res.data && res.data.csv) {
        downloadFile(
          `xdr_stats_${this.state.type}_${this.state.date}_export.csv`,
          'data:text/csv;charset=UTF-8,\uFEFF' + encodeURIComponent(res.data.csv)
        );
        message.success('XDR statistics successfully exported');
        this.hideModal();
      } else {
        this.setState({ loading: false });
        message.error('Export failed, try again');
      }
    })
    .catch(() => {
      message.error('Export failed, try again');
      this.setState({ loading: false });
    });
  };
  onChange = currentDate => {
    const { type } = this.state;
    let date = currentDate;
    if (type === 'weekly') {
      date = currentDate.startOf('week');
    } else if (type === 'monthly') {
      date = currentDate.startOf('month');
    }
    this.setState({ date: date.format('YYYYMMDD') });
  };
  onYearChange = year => {
    const currentDate = moment().day('Monday').year(year).week('24');
    this.setState({ date: currentDate.format('YYYYMMDD') });
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const year = moment(this.state.date, 'YYYYMMDD').format('YYYY');

    return (
      <Modal
        wrapClassName="xdr-stats-export-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={false}
        width={560}
      >
        <div className="seat-info">
          <Modal.Close onClick={this.hideModal} />
          <Header font="serif" size="2">
            XDR Stats Export
          </Header>
          <div className="dashboard-actions">
            <Select size="small" value={this.state.type} onChange={type => this.setState({ type })}>
              <Select.Option value="daily">Daily</Select.Option>
              <Select.Option value="weekly">Weekly</Select.Option>
              <Select.Option value="monthly">Monthly</Select.Option>
              <Select.Option value="yearly">Yearly</Select.Option>
            </Select>
            <span>stats, for</span>
            {this.state.type === 'daily' && (
              <DatePicker 
                className="seriph-daypicker"
                onChange={this.onChange}
                defaultValue={moment().startOf('week')}
                format="[the day of] M/D/YY"
              />
            )}
            {this.state.type === 'weekly' && (
              <WeekPicker 
                className="seriph-daypicker"
                onChange={this.onChange}
                defaultValue={moment().startOf('week')}
                format="[the week of] M/D/YY"
              />
            )}
            {this.state.type === 'monthly' && (
              <MonthPicker 
                className="seriph-daypicker"
                onChange={this.onChange}
                defaultValue={moment().startOf('week')}
                format="[the month of] MMM YYYY"
              />
            )}
            {this.state.type === 'yearly' && (
              <Select size="small" value={year} onChange={this.onYearChange}>
                <Select.Option value="2021">2021</Select.Option>
                <Select.Option value="2022">2022</Select.Option>
                <Select.Option value="2023">2023</Select.Option>
              </Select>
            )}
          </div>
          <Modal.Actions align="right">
            <Button onClick={this.hideModal}>Cancel</Button>
            <Button type="primary" loading={this.state.loading} onClick={this.getXDRStats}>
              Export to CSV
            </Button>
          </Modal.Actions>
        </div>
      </Modal>
    );
  }
}

export default withRouter(XDRStats);
