import React, { Component } from 'react';
import { Progress } from 'antd';
import Rocket from '_assets/icons/rocket.svg';
import './LaunchingStatus.scss';

class LaunchingStatus extends Component {
  render() {
    const { step, total, name, percent } = this.props;
    return (
      <div id="launching-status">
        <img src={Rocket} alt="" />
        <div className="ls-info">
          <h3>
            ({step}/{total}) {name}
          </h3>
          <Progress percent={percent} />
        </div>
      </div>
    );
  }
}

export default LaunchingStatus;
