import React from 'react';
import avatar from '_assets/img/avatar.png';
import avatar_company from '_assets/img/company.png';

import {
  determineReputation,
  imageForReputationIndex,
  reputationTextIndex
} from '_helpers/reputation';

import './Avatar.scss';

const badge = reputation => {
  const rep = determineReputation(reputation);
  return (
    <div>
      <img
        className="av-rep"
        src={imageForReputationIndex(rep)}
        alt={reputationTextIndex(rep)}
        title={reputationTextIndex(rep)}
      />
    </div>
  );
};
const Avatar = ({ type, picture, company, reputation, size, firstCharacter, lastCharacter }) => {
  // Sizes: giant, large, medium, small, tiny
  const calcReputation = reputation !== undefined && !reputation ? 1 : reputation;

  return (
    <div className={size ? 'sxd-avatar size-' + size : 'sxd-avatar'}>
      {/* MAIN AVATAR / PICTURE */}
      {type === 'lead' && !picture ? (
        <span className="character">
          {firstCharacter}
          {lastCharacter}
        </span>
      ) : (
        <img className="av-pic" src={picture || avatar} alt="Avatar" title="Avatar" />
      )}

      {/* COMPANY LOGO */}
      {type === 'company' && company && (
        <img className="av-comp" src={company || avatar_company} alt="Company" title="Company" />
      )}

      {type === 'agent' && badge(calcReputation)}
    </div>
  );
};

export default Avatar;
