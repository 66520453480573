import gql from 'graphql-tag';

export const getLead = gql`
  query lead($id: ID) {
    lead(id: $id) {
      id
      type
      stage
      checked
      hasResponded
      meetingScheduled
      calendly {
        start_time
        uri
        event_id
        calendar_type
        cancel_url
      }
      createdByCompany
      emailAttempts
      callAttempts
      answers {
        question
        answer
        answers
      }

      first_name
      last_name
      photo_url
      salutation
      description
      primary_language
      education
      race
      gender
      age
      tags {
        tag
        opType
        date
        note
      }

      email
      phone
      email_valid
      phone_valid
      mobile_valid
      mobile
      fax

      company
      company_scope
      job_position
      title
      number_of_employees
      annual_revenue
      industry

      website
      linkedin
      facebook
      twitter
      instagram

      street
      city
      state
      postal_code
      country
      lat
      lng

      custom_fields {
        type
        label
        value
      }

      unread_emails
      unread_calls
      unread_texts

      sequence_id
      sequences {
        task_id
        day
        step
        type
        duration
        duration_type
        template_id
        completed
        completed_id
        skipped
        skipped_type
        skipped_reason
        start_tstamp
        completed_tstamp
        now
      }
      last_sequence
      last_step
      last_timestamp

      updatedAt
      source
      status
      rating
      money
      dispute_date
      dispute_reason
      dispute_information
      interest_reason
      interest_information
      do_not_call
      agent_id
      agent {
        id
        display_name
        picture
        company {
          company_id
        }
      }
      employee {
        id
        display_name
        picture
      }
      campaign {
        id
        name
        objective
        script
        status
        calendly
        chilipiper
        forceCalendar
        custom_domain
        price_per_lead
        price_not_interested
        meeting_required
        rep_type
        company {
          id
          name
          website
          linkedin
          facebook
          instagram
          twitter
          logo
          text_number
          integration {
            calendly {
              url
            }
            chilipiper {
              domain
              inbound_router
            }
          }
        }
        required {
          field
          type
          values
          locations {
            name
            lat
            lng
          }
          min
          max
          required
        }
        custom_title
        questions {
          type
          question
          options
        }
        files {
          id
          name
          type
          fileType
          url
          size
        }
        agents {
          id
          agent_id
          active
          phone_number
        }
        guidelines
        wizard_version
        customer_type
        customer_target
        customer_decision_maker
        customer_company_size
        customer_industry
        customer_locations {
          name
        }
        customer_age
        customer_education
        customer_gender
        customer_race
        customer_spend_start
        customer_spend_end
        sequences {
          steps {
            type
            duration
            duration_type
            template_id
          }
        }
        benefits
        objections {
          name
          responses
        }
      }
      campaign_id
    }
  }
`;

export const searchLeads = gql`
  query searchLeads(
    $campaign_id: ID
    $company_id: String
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $name: String
    $company: String
    $agent: [String]
    $stages: [String]
    $repId: String
    $campaigns: [String]
    $highlights: [String]
    $types: [String]
    $for_today: Boolean
    $follow_ups: Boolean
    $tags: Boolean
    $next_task_types: [String]
  ) {
    searchLeads(
      campaign_id: $campaign_id
      company_id: $company_id
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      name: $name
      company: $company
      agent: $agent
      stages: $stages
      repId: $repId
      campaigns: $campaigns
      highlights: $highlights
      types: $types
      for_today: $for_today
      follow_ups: $follow_ups
      tags: $tags
      next_task_types: $next_task_types
    ) {
      data {
        id
        first_name
        last_name
        company
        email
        unread_emails
        unread_calls
        unread_texts
        missed_call_date
        missed_email_date
        missed_text_date
        createdByCompany
        type
        stage
        updatedAt
        last_activity
        campaign_id
        agent_id
        interest_reason
        interest_reason_detail
        interest_information
        tags {
          tag
          date
          note
          opType
        }
        sequences {
          completed
          completed_tstamp
          type
        }
        sequence_progress
        for_today
        calendly {
          start_time
        }
        dispute_reason
        dispute_information
        grouping
      }
      total
      pageTotal
    }
  }
`;

export const countLeads = gql`
  query countLeads(
    $campaign_id: ID
    $company_id: String
    $name: String
    $company: String
    $agent: [String]
    $repId: String
    $campaigns: [String]
    $highlights: [String]
    $types: [String]
    $for_today: Boolean
    $follow_ups: Boolean
    $tags: Boolean
    $next_task_types: [String]
  ) {
    countLeads(
      campaign_id: $campaign_id
      company_id: $company_id
      name: $name
      company: $company
      agent: $agent
      repId: $repId
      campaigns: $campaigns
      highlights: $highlights
      types: $types
      for_today: $for_today
      tags: $tags
      follow_ups: $follow_ups
      next_task_types: $next_task_types
    ) {
      disputed
      review
      improper
      completed
      in_progress
      unassigned
    }
  }
`;

export const leadNotes = gql`
  query leadNotes($lead_id: ID) {
    leadNotes(lead_id: $lead_id) {
      id
      agent {
        id
        display_name
        picture
      }
      employee {
        id
        display_name
        picture
      }
      content
      createdAt
    }
  }
`;

export const leadTexts = gql`
  query leadTexts($lead_id: ID) {
    leadTexts(lead_id: $lead_id) {
      id
      agent {
        id
        display_name
        picture
      }
      employee {
        id
        display_name
        picture
      }
      content
      status
      from_number
      to_number
      opt_out
      outgoing_text
      incoming_text
      createdAt
    }
  }
`;

export const leadThreads = gql`
  query leadThreads($lead_id: ID, $thread_id: ID) {
    leadThreads(lead_id: $lead_id, thread_id: $thread_id) {
      id
      subject
      origin_email_id
      last_email_id
      unread_emails
      createdAt
      status
      emails {
        id
        email_id
        from_name
        from_email
        to_name
        to_email
        subject
        content
        content_text
        createdAt
        status
        agent_id
        opened
        delivered
        bounced
        bounced_reason
        cc
        bcc
        attachment {
          filename
          url
        }
      }
    }
  }
`;

export const leadCalls = gql`
  query leadCalls($lead_id: ID) {
    leadCalls(lead_id: $lead_id) {
      id
      call_id
      status
      duration
      from_number
      to_number
      incoming_call
      recording_url
      transcription {
        start
        stop
        text
      }
      agent {
        id
        picture
        display_name
      }
      recording_available
      transcription_available
      createdAt
    }
  }
`;

export const leadActivity = gql`
  query leadActivity($lead_id: ID) {
    leadActivity(lead_id: $lead_id) {
      id
      type
      status {
        type
        automated
        dispute_reason
        from_admin
        information
      }
      update {
        fields {
          field_name
          old_value
          new_value
        }
      }
      meeting {
        start_time
        end_time
        name
      }
      note {
        note_id
      }
      email {
        thread_id
        email_id
        type
        from_name
        from_email
        to_name
        to_email
      }
      text {
        text_id
        sent
        incoming
        opt_out
        to_number
        from_number
      }
      call {
        call_id
        answered
        voicemail
        incoming
        duration
      }
      createdAt
      agent {
        id
        display_name
        picture
      }
      employee {
        id
        display_name
        picture
      }
    }
  }
`;

export const leadHighlights = gql`
  query leadHighlights($campaign: String) {
    leadHighlights(campaign: $campaign) {
      total
      completed
      unread_calls
      unread_emails
      unread_texts
      type_totals {
        email
        call
        text
        instructions
        linkedin
      }
      type_ready {
        email
        call
        text
        instructions
        linkedin
      }
      type_queue {
        email
        call
        text
        instructions
        linkedin
      }
      type_completed {
        email
        call
        text
        instructions
        linkedin
      }
    }
  }
`;

export const getTags = gql`
  query lead($id: ID) {
    lead(id: $id) {
      tags {
        tag
        opType
        date
        note
      }
    }
  }
`;
