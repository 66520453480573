import React, { Component } from 'react';
import { InputNumber } from 'antd';
import { Locked } from '../Components/Locked/Locked';
import Card from '_shared/Card/Card';

export default class Budget extends Component {
  state = {
    budget: null
  };
  validClass = field => {
    const { validation, showValidation } = this.props;
    return !validation[field] && showValidation ? 'input-label invalid' : 'input-label';
  };
  calculateGeneratedAmount = () => {
    const form = this.props.form;
    const budget = form.budget_ppl && form.budget_ppl > 0 ? form.budget_ppl : null;
    const total = form.budget_total && form.budget_total > 0 ? form.budget_total : null;
    return budget && total ? Math.floor(total / budget) : null;
  };
  calculateQualifiedAmount = () => {
    const form = this.props.form;
    const budget = form.budget_ppql && form.budget_ppql > 0 ? form.budget_ppql : null;
    const total = form.budget_total && form.budget_total > 0 ? form.budget_total : null;
    return budget && total ? Math.floor(total / budget) : null;
  };
  updateBudget = (value, field) => {
    this.props.updateForm(field, value);
  };
  render() {
    const form = this.props.form;
    const isLocked = this.props.isLocked;
    const { validation, showValidation } = this.props;
    const estimate =
      form.objective === 'leadgen'
        ? this.calculateGeneratedAmount()
        : this.calculateQualifiedAmount();

    return (
      <div className="step-container" id="budget-container">
        <Card id={this.props.id} className="step-card" padding="40px 20px 25px 20px">
          <div className="step-title">
            <h3 className={this.validClass('budget')}>Budget</h3>
            <p>Leads help companies grow, how much are leads worth for this campaign?</p>
          </div>
          <div className="step-form">
            {form.objective === 'leadgen' ? (
              <div className="step-input">
                <div className={this.validClass('budget_ppl')}>
                  <Locked isLocked={isLocked} />
                  How much will you pay per generated lead?
                </div>
                <div className="input-entry input-half">
                  <InputNumber
                    disabled={isLocked}
                    min={0}
                    value={form.budget_ppl}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={value => this.updateBudget(value, 'budget_ppl')}
                    size="large"
                    className="forms"
                    placeholder="$20.00"
                  />
                  <div className="suffix">Per generated lead</div>
                </div>
              </div>
            ) : null}

            {form.objective === 'qualify' ? (
              <div className="step-input">
                <div className={this.validClass('budget_ppql')}>
                  <Locked isLocked={isLocked} />
                  How much will you pay per qualified lead?
                </div>
                <div className="input-entry input-half">
                  <InputNumber
                    disabled={isLocked}
                    min={0}
                    value={form.budget_ppql}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={value => this.updateBudget(value, 'budget_ppql')}
                    size="large"
                    className="forms"
                    placeholder="$200.00"
                  />
                  <div className="suffix">Per qualified lead</div>
                </div>
              </div>
            ) : null}

            <div className="step-input">
              <div className={this.validClass('budget_total')}>
                <Locked isLocked={isLocked} />
                What is your max budget amount?
              </div>
              <div className="input-entry input-half">
                <InputNumber
                  disabled={isLocked}
                  min={0}
                  value={form.budget_total}
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  onChange={value => this.updateBudget(value, 'budget_total')}
                  size="large"
                  className="forms"
                  placeholder="$2000.00"
                />
                <div className="suffix">
                  Total &nbsp;
                  {estimate
                    ? `(Up to ${estimate} ${
                        form.objective === 'qualify' ? 'Qualified' : 'Generated'
                      } Lead${estimate === 1 ? '' : 's'})`
                    : null}
                </div>
              </div>
            </div>

            {false && (
              <div className="step-input">
                <div className="input-label">
                  <Locked isLocked={isLocked} />
                  Can reps earn a bonus?
                </div>
                <div className="input-entry input-quarter">
                  <InputNumber
                    disabled={isLocked}
                    min={0}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    value={form.budget_bonus}
                    onChange={value => this.props.updateForm('budget_bonus', value)}
                    size="large"
                    className="forms"
                    placeholder="$1,000"
                  />
                  <div className="suffix" style={{ marginRight: '20px' }}>
                    per
                  </div>
                  <InputNumber
                    disabled={isLocked}
                    min={0}
                    value={form.budget_bonus_count}
                    onChange={value => this.props.updateForm('budget_bonus_count', value)}
                    size="large"
                    className="forms"
                    placeholder="100"
                  />
                  <div className="suffix">leads</div>
                </div>
              </div>
            )}

            {form.objective === 'qualify' ? (
              <div className="step-input">
                <div className="input-label">
                  $2 is paid for a not interested or invalid lead (for the work the sales rep has
                  put in)
                </div>
              </div>
            ) : null}

            {(!validation.budget_ppl_over || !validation.budget_ppql_over) && showValidation ? (
              <div className="step-input">
                <div className="input-label invalid">
                  Pay per lead cannot be greater than your budget
                </div>
              </div>
            ) : null}
          </div>
        </Card>
        <div className="step-tips">&nbsp;</div>
      </div>
    );
  }
}
