import gql from 'graphql-tag';

export const companyOverviewStats = gql`
  query campaignStats($campaign_id: String, $status: String, $startRange: Int, $endRange: Int) {
    campaignStats(
      campaign_id: $campaign_id
      status: $status
      startRange: $startRange
      endRange: $endRange
    ) {
      days {
        date
        daily {
          in_progress
          review
          not_interested
          invalid
          disputed
          generated
          qualified
          qualified_review
          generated_review
          qualified_disputed
          generated_disputed
          touchpoint_email
          touchpoint_call
          touchpoint_linkedin
          touchpoint_instructions
          touchpoint_skipped
        }
      }
    }
  }
`;

export const companyConnectivityStats = gql`
  query campaignStats($campaign_id: String, $status: String, $startRange: Int, $endRange: Int) {
    campaignStats(
      campaign_id: $campaign_id
      status: $status
      startRange: $startRange
      endRange: $endRange
    ) {
      days {
        date
        daily {
          interest_bad_timing
          interest_financial
          interest_another_solution
          interest_wrong_contact
          interest_no_reason
          interest_no_response
          interest_wrong_number
          interest_bad_email
          interest_duplicate
          interest_other

          called
          called_answered
          called_no_answer
          incoming
          incoming_answered
          call_invalid
          call_busy
          call_short
          call_medium
          call_long

          emailed
          delivered
          opened
          bounced
          clicked
          unsubscribed
          spam
          replied
          received
        }
      }
    }
  }
`;

export const reportCampaignProgress = gql`
  query reportCampaignProgress($campaign_id: String) {
    reportCampaignProgress(campaign_id: $campaign_id) {
      campaign {
        name
        stats {
          total_leads
          estimated_total
          conversion_rate

          has_responded
          responded_qualified
          response_rate
          response_conversion_rate

          remaining
          completed
          generated
          qualified
          not_interested
          review
          review_interested
          review_not_interested
          disputed
          in_progress
          unassigned
          paid
          paid_interested
          paid_not_interested
          owed
          owed_interested
          owed_not_interested
        }
      }
      touchpoints {
        email {
          completed
          skipped
          delivered
          bounced
          opened
          replied
          qualified
          clicked
          unsubscribed
        }
        call {
          completed
          skipped
          invalid
          no_answer
          answered
          incoming
          incoming_answered
          qualified
        }
        linkedin {
          completed
          skipped
          qualified
        }
        instructions {
          completed
          skipped
          qualified
        }
      }
    }
  }
`;

export const reportLeadBreakdown = gql`
  query reportLeadBreakdown($campaign_id: String) {
    reportLeadBreakdown(campaign_id: $campaign_id) {
      campaign {
        name
      }
      leads {
        hasResponded
        industry
        job_position
        title
        interest_reason
      }
    }
  }
`;

export const getScheduledMeetings = gql`
  query getScheduledMeetings(
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $campaign_id: String
    $status: String
    $startRange: Int
    $endRange: Int
    $meetingStatus: String
    $owner: String
  ) {
    getScheduledMeetings(
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      campaign_id: $campaign_id
      status: $status
      startRange: $startRange
      endRange: $endRange
      meetingStatus: $meetingStatus
      owner: $owner
    ) {
      data {
        id
        stage
        salutation
        first_name
        last_name
        company
        meetingScheduled
        campaign {
          id
          name
        }
        calendly {
          created_at
          start_time
          end_time
          name
        }
        agent {
          picture
          display_name
        }
      }
      total
      pageTotal
    }
  }
`;

export const getCallRecordings = gql`
  query getCallRecordings(
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $startRange: Int
    $endRange: Int
    $duration: String
    $owner: String
    $status: String
    $campaign_id: String
  ) {
    getCallRecordings(
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      startRange: $startRange
      endRange: $endRange
      duration: $duration
      campaign_id: $campaign_id
      status: $status
      owner: $owner
    ) {
      data {
        call {
          id
          call_id
          recording_id
          transcription_id
          day
          from_number
          to_number
          recording_url
          transcription {
            start
            stop
            text
          }
          answered
          incoming_call
          recording_available
          transcription_available
          duration
          lead {
            first_name
            last_name
            stage
            id
            agent_id
            interest_reason
            sequences {
              completed
            }
            calendly {
              start_time
            }
          }
          campaign {
            name
            company {
              name
            }
          }
          agent {
            display_name
            picture
          }
          createdAt
        }
        is_favorited
      }
      total
      pageTotal
      totalDuration
    }
  }
`;

export const xdrScorecard = gql`
  query xdrScorecard($date: String, $type: String, $rep_id: String) {
    xdrScorecard(date: $date, type: $type, rep_id: $rep_id) {
      data {
        date
        daily {
          meetings
          touchpoint_email
          touchpoint_call
          touchpoint_linkedin
          touchpoint_instructions
          touchpoint_skipped
          touchpoint_total
          pulled
          review
          invalid
          qualified
          not_interested
          interest_bad_timing
          interest_financial
          interest_another_solution
          interest_wrong_contact
          interest_no_reason
          interest_no_response
          interest_wrong_number
          interest_bad_email
          interest_duplicate
          interest_other
        }
      }
      call_stats {
        total_duration
        total_calls
        total_answered
        total_recorded
        total_incoming
        dur_0_1
        dur_1_2
        dur_2_5
        dur_5_10
        dur_10
      }
      calls {
        id
        status
        duration
        recording_url
        answered
        createdAt
        lead {
          first_name
          last_name
          company
        }
        campaign {
          name
          company {
            name
          }
        }
      }
    }
  }
`;
