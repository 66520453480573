import React, { Component } from 'react';
import { IconX } from '@tabler/icons-react';
import './Tag.scss';

class Tag extends Component {
  state = {
    visible: true
  };
  hide = () => this.setState({ visible: false });
  render() {
    const props = { ...this.props };
    props.className = 'seriph-tag ' + (props.className || '');
    const hideable = props.canHide;
    delete props.canHide;

    // Handle Size (small (default), medium, large)
    if (props.size) {
      props.className += ' tag-' + props.size;
      delete props.size;
    }

    // Handle Type (default, primary, warning, info, success, danger)
    if (props.type) {
      props.className += ' tag-' + props.type;
      delete props.type;
    }

    return this.state.visible ? (
      <div {...props}>
        <div>{props.children}</div>
        {hideable && (
          <div className="tag-hide" onClick={this.hide}>
            <IconX size={16} stroke={2} />
          </div>
        )}
      </div>
    ) : null;
  }
}

export default Tag;
