import React, { Component } from 'react';
import { leadThreads } from '_graphql/queries/lead';
import moment from 'moment';
import './Email.scss';
import { IconMail, IconCirclePlus } from '@tabler/icons-react';
import { Avatar, Empty, Card, Button, message, Loading } from '_seriph';

export default class Email extends Component {
  state = {
    loading: true,
    threads: [],
    emails: []
  };
  componentDidMount() {
    this.loadThreads(this.props.lead.id);
  }
  loadThreads = id => {
    this.props.client
      .query({
        variables: { lead_id: id },
        query: leadThreads
      })
      .then(result => {
        if (result && result.data && result.data.leadThreads) {
          this.setState({ threads: (result.data.leadThreads || []).reverse() });
        } else {
          message.error('Could not load emails, try again');
        }
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error('Could not load emails, try again');
      });
  };
  decodeHtml = html => {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value.replace(/(<([^>]+)>)/gi, ' ');
  };
  isMe = email => {
    const actualDomain =
      this.props.campaign && this.props.campaign.custom_domain
        ? '@' + this.props.campaign.custom_domain
        : '@sellx.org';
    const myEmail = this.props.info ? this.props.info.email_username + actualDomain : null;
    return email === this.props.user.email || email === myEmail;
  };
  getMembers = thread => {
    let emails = new Set([]);
    thread.emails.forEach(e => {
      emails.add(e.from_name);
      emails.add(e.to_name);
    });
    emails = [...emails].filter(e => e !== null);
    if (emails.length === 2) {
      return `${emails[0]} and ${emails[1]}`;
    } else {
      const otherCount = emails.length - 2;
      return `${emails[0]}, ${emails[1]} and ${otherCount} other${otherCount !== 1 ? 's' : ''}`;
    }
  };
  showEmailPopout = () => {
    this.props.showPopout({
      update: () => this.loadThreads(this.props.lead.id),
      saveAnswers: this.props.saveAnswers,
      lead: this.props.lead,
      campaign: this.props.campaign,
      company: this.props.campaign.company,
      rep: this.props.user,
      type: 'email'
    });
  };
  goToThread = id => this.props.history.push(`/leads/${this.props.lead.id}/email/${id}`);
  render() {
    const { canEmail } = this.props;
    const { loading, threads } = this.state;

    return (
      <div id="lead-new-email">
        <div className="email-thread">
          {!loading && threads.length <= 0 && (
            <Empty
              icon={<IconMail />}
              title="No emails yet"
              onClick={this.showEmailPopout}
              action={
                <span>
                  <IconCirclePlus />
                  Compose email
                </span>
              }
            >
              Reps will follow your sequence and when emails are sent, they will appear here with
              any replies that are received.
            </Empty>
          )}

          {loading ? (
            <Loading />
          ) : (
            <React.Fragment>
              {canEmail && threads.length > 0 ? (
                <div className="compose-new">
                  <Button type="primary" icon="left" onClick={this.showEmailPopout}>
                    <IconCirclePlus />
                    Compose Email
                  </Button>
                </div>
              ) : null}
              {threads.map(t => {
                const lastEmail = t.emails[t.emails.length - 1];
                const lastBody = this.decodeHtml(lastEmail.content);
                const lastTimestamp = lastEmail.createdAt;
                const named = lastEmail.from_name.split(' ');
                const firstName = named[0];
                const lastName = named[named.length - 1] || '';
                const isMe = this.isMe(lastEmail.from_email) || lastEmail.agent_id;
                const isLead =
                  this.props.lead.email.toLowerCase() == lastEmail.from_email.toLowerCase();
                const emailStatus = isLead && t.status === 'sent' ? 'reply' : t.status;

                return (
                  <Card
                    key={`thread-${t.id}`}
                    className={`thread-body ${t.unread_emails > 0 ? 'unread' : ''}`}
                    onClick={() => this.goToThread(t.id)}
                  >
                    <div className={isMe ? 'emailer is-me' : 'emailer'}>
                      {isMe ? (
                        <Avatar size="tiny" picture={this.props.user.picture} />
                      ) : (
                        <Avatar
                          size="tiny"
                          type="lead"
                          firstCharacter={(firstName || '?').charAt(0).toUpperCase()}
                          lastCharacter={(lastName || '?').charAt(0).toUpperCase()}
                        />
                      )}
                    </div>
                    <div className="thread-content">
                      <div className="thread-subject">
                        {t.subject}{' '}
                        <b>-- {lastBody}</b>{' '}
                      </div>
                      <div className="thread-members">{this.getMembers(t)}{' '}</div>
                    </div>
                    <div className="tagged">
                      <div className={emailStatus}>{emailStatus}</div>
                    </div>
                    <div className="thread-tstamp">{moment(lastTimestamp * 1).format('MMM D')}</div>
                  </Card>
                );
              })}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}
