import React, { Component } from 'react';
import './Card.scss';

export default class Card extends Component {
  render() {
    const props = { ...this.props };
    props.className = 'sxd-card';
    if (this.props.className) props.className += ' ' + this.props.className;
    return <div {...props}>{this.props.children}</div>;
  }
}
