import gql from 'graphql-tag';

export const updateCompanyProfile = gql`
  mutation updateCompanyProfile(
    $name: String!
    $about: String
    $headline: String
    $overview: String
    $employee_count_min: Int!
    $employee_count_max: Int!
    $industries: [IndustryInput]
    $locations: [LocationInput]
    $website: String
    $linkedin: String
    $twitter: String
    $facebook: String
    $instagram: String
    $company_id: String
  ) {
    updateCompanyProfile(
      name: $name
      about: $about
      headline: $headline
      overview: $overview
      employee_count_min: $employee_count_min
      employee_count_max: $employee_count_max
      industries: $industries
      locations: $locations
      website: $website
      linkedin: $linkedin
      twitter: $twitter
      facebook: $facebook
      instagram: $instagram
      company_id: $company_id
    ) {
      id
    }
  }
`;

export const updateCompanyLogo = gql`
  mutation updateCompanyLogo($logo: String, $company_id: String) {
    updateCompanyLogo(logo: $logo, company_id: $company_id) {
      logo
    }
  }
`;
