import gql from 'graphql-tag';

export const agentReputation = gql`
  query {
    agentReputation {
      id
      type
      amount
      createdAt
      lead {
        id
        first_name
        last_name
      }
      campaign {
        id
        name
      }
    }
  }
`;
