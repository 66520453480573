import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { jobsTable } from '_graphql/queries/campaign';
import { getColumns } from './_components/Columns';
import InstructionsModal from './_modals/InstructionsModal/InstructionsModal';
import CreateLead from 'App/Company/Leads/_modals/CreateLead/CreateLead';
import { Button, Tabs, Table, Header } from '_seriph';
import { hasAccess } from '_helpers/permissions';
import './Campaigns.scss';

const paginationInit = {
  current: 1,
  pageSize: 5
};

class Campaigns extends Component {
  state = {
    campaigns: [],
    loading: true,
    tab: 'initial',
    counts: { all: 0, active: 0, completed: 0, saved: 0 },
    pagination: paginationInit,
    showModal: null,
    showCreate: false
  };
  componentDidMount = () => {
    this.getCampaigns();
  };
  getCampaigns = () => {
    if (!this.state.loading) this.setState({ loading: true });
    this.props.client
      .query({ variables: { status: this.state.tab }, query: jobsTable })
      .then(result => {
        if (result && result.data && result.data.jobsTable) {
          const campaigns = result.data.jobsTable.campaigns || [];
          const counts = result.data.jobsTable.counts;
          let tab = result.data.jobsTable.tab;
          this.setState({
            campaigns,
            counts,
            tab,
            loading: false,
            loaded: true,
            pagination: paginationInit
          });
        }
      });
  };
  changeTab = tab => {
    this.setState({ tab }, this.getCampaigns);
  };
  showInstructionsModal = campaign => this.setState({ showModal: campaign });
  removeInstructionsModal = () => this.setState({ showModal: null });
  showCreateModal = () => this.setState({ showCreate: true });
  removeCreateModal = () => this.setState({ showCreate: false });
  selectLead = (leadId, campaignId) => {
    this.props.history.push(`/leads/new?campaign=${campaignId}`);
  };
  render() {
    const { campaigns, loading, tab, counts, pagination } = this.state;

    const filtered = campaigns
      .filter(j => {
        if (tab === 'all') return true;
        return j.status === tab;
      })
      .sort((a, b) => {
        if (a.status === 'active') return -1;
        if (a.status === 'inactive' || b.status === 'draft') return -1;
        return 1;
      });

    const canAdd = counts.active > 0 ? true : false;
    const startNumber = (pagination.current - 1) * pagination.pageSize + 1;
    const highNumber = startNumber + pagination.pageSize - 1;
    const amount = startNumber + '-' + (highNumber > counts[tab] ? counts[tab] : highNumber);
    const customInfo = `Page ${pagination.current} of ${Math.ceil(
      counts[tab] / pagination.pageSize
    )} / Showing ${amount} of ${counts[tab]}`;

    return (
      <div id="agent-campaigns">
        <div className="seriph-header-wrapper">
          <Header type="display" weight="600" size="4">
            My Campaigns
          </Header>
          <div className="header-actions">
            {hasAccess(['Rep', 'SellX']) && (
              <Link to="/explore">
                <Button type="default">Explore Campaigns</Button>
              </Link>
            )}
            {canAdd && (
              <Button type="primary" onClick={this.showCreateModal}>
                New Lead
              </Button>
            )}
          </div>
        </div>

        <Tabs onChange={this.changeTab} currentTab={tab}>
          <Tabs.Tab key="active" amount={counts.active} disabled={!counts.active}>
            ACTIVE
          </Tabs.Tab>
          <Tabs.Tab key="inactive" amount={counts.inactive} disabled={!counts.inactive}>
            PAUSED
          </Tabs.Tab>
          <Tabs.Tab key="completed" amount={counts.completed} disabled={!counts.completed}>
            COMPLETED
          </Tabs.Tab>
        </Tabs>

        <Table
          lined
          rounded
          loading={loading}
          locale={{ emptyText: loading ? 'Loading campaigns...' : 'No campaigns found' }}
          dataSource={filtered.map(d => {
            d.key = d.id;
            return d;
          })}
          scroll={{ x: 'max-content' }}
          pagination={{ pageSize: paginationInit.pageSize }}
          customInfo={loading ? null : customInfo}
          onChange={pagination => this.setState({ pagination })}
          columns={getColumns(
            this.getCampaigns,
            this.props.myself,
            this.showInstructionsModal,
            this.showCreateModal
          )}
        />

        {this.state.showModal ? (
          <InstructionsModal
            campaign={this.state.showModal}
            removeModal={this.removeInstructionsModal}
          />
        ) : null}

        {this.state.showCreate ? (
          <CreateLead
            client={this.props.client}
            selectLead={this.selectLead}
            campaigns={campaigns}
            removeModal={this.removeCreateModal}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(Campaigns);
