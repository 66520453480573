import React, { Component } from 'react';
import { Checkbox, Select, SelectMultipleSimple, Alert } from '_seriph';
import { hasFeature } from '_helpers/permissions';
import './CallSettings.scss';

export default class CallSettings extends Component {
  render() {
    const {
      audio,
      settings,
      settingsDisabled,
      updateSettings,
      phoneOptions,
      phoneSelected,
      updateStatusSounds,
      soundsEnabled,
      manualDial
    } = this.props;

    const features = this.props.info?.features || [];

    return (
      <div id="call-settings">
        <div className="menu-title">Audio settings</div>
        <div className="menu-head">Microphone</div>
        <div className="menu-selecting">
          <Select value={audio?.audioInput} size="small" onChange={audio?.changeDevice}>
            {audio?.inputDevices?.map((p, i) => (
              <Select.Option key={'nd-' + i} value={p.value}>
                {p.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="menu-head">Speakers</div>
        <div className="menu-selecting">
          <Select value={audio?.audioOutput} size="small" onChange={audio?.changeOutput}>
            {audio?.outputDevices?.map((p, i) => (
              <Select.Option key={'nd-' + i} value={p.value}>
                {p.label}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className="menu-sep"></div>

        <div className="menu-title">Dialer options</div>
        {settingsDisabled ? (
          <Alert type="warning" title="Call in progress, settings disabled" />
        ) : null}
        <div className="menu-head">Phone types to call</div>
        <div className="menu-selecting">
          <SelectMultipleSimple
            placeholder="Select fields..."
            autocomplete={phoneOptions}
            value={phoneSelected || []}
            onChange={val => this.props.changePhoneSelected(val)}
            disabled={settingsDisabled}
          />
        </div>

        { !manualDial ? (
          <React.Fragment>
            <div className="menu-head">Dialer Speed</div>
            <div className="menu-selecting">
              <Select
                value={settings.delay}
                size="small"
                onChange={val => updateSettings('delay', val)}
                disabled={settingsDisabled}
              >
                <Select.Option value={100}>Turbo</Select.Option>
                <Select.Option value={500}>Fast</Select.Option>
                <Select.Option value={1000}>Average</Select.Option>
                <Select.Option value={2000}>Intermediate</Select.Option>
                <Select.Option value={3000}>Casual</Select.Option>
                <Select.Option value={4000}>Slow</Select.Option>
              </Select>
            </div>
          </React.Fragment>
        ) : null }
        

        { !manualDial ? (
          <React.Fragment>
            <div className="menu-head">Auto-hangup after {settings.timeout} seconds</div>
            <div className="menu-selecting">
              <Select
                value={settings.timeout}
                size="small"
                onChange={val => updateSettings('timeout', val)}
                disabled={settingsDisabled}
              >
                <Select.Option value={20}>20 Seconds</Select.Option>
                <Select.Option value={25}>25 Seconds</Select.Option>
                <Select.Option value={30}>30 Seconds</Select.Option>
                <Select.Option value={35}>35 Seconds</Select.Option>
                <Select.Option value={40}>40 Seconds</Select.Option>
                <Select.Option value={45}>45 Seconds</Select.Option>
                <Select.Option value={50}>50 Seconds</Select.Option>
                <Select.Option value={55}>55 Seconds</Select.Option>
                <Select.Option value={60}>60 Seconds</Select.Option>
              </Select>
            </div>
          </React.Fragment>
        ) : null }
        

        <div className="menu-head">Other settings</div>
        <div className="menu-selecting">
          {(hasFeature(features, 'dialer-v3') || true) && !manualDial ? (
            <Checkbox
              checked={settings.autoDial}
              onChange={e => updateSettings('autoDial', e.target.checked)}
              size="small"
              noBg
            >
              Auto Dial
            </Checkbox>
          ) : null}
          {!manualDial ? (
            <Checkbox
              checked={settings.skip_after_answer}
              onChange={e => updateSettings('skip_after_answer', e.target.checked)}
              size="small"
              noBg
              disabled={settingsDisabled}
            >
              Skip remaining calls after answer
            </Checkbox>
          ) : null}
          {!manualDial ? (
            <Checkbox
              checked={settings.auto_complete_no_answer}
              onChange={e => updateSettings('auto_complete_no_answer', e.target.checked)}
              size="small"
              noBg
              disabled={settingsDisabled}
            >
              Auto complete task if no connects
            </Checkbox>
          ) : null}
          <Checkbox
            checked={settings.autoDialExtensions}
            onChange={e => updateSettings('autoDialExtensions', e.target.checked)}
            size="small"
            noBg
            disabled={settingsDisabled}
          >
            Auto dial extensions
          </Checkbox>
          <Checkbox
            checked={soundsEnabled}
            onChange={e => updateStatusSounds(e.target.checked)}
            size="small"
            noBg
            disabled={settingsDisabled}
          >
            Enabled status sounds
          </Checkbox>
        </div>
      </div>
    );
  }
}
