import axios from 'axios';
import { Device } from '@twilio/voice-sdk';
const apiPath = process.env.REACT_APP_API_PATH;
import { noCall } from '_assets/js/helpers';

export const currentCall = lead => {
  return lead.calls.find(c => {
    const dontCall = noCall(lead.no_call_list, c?.number);
    return !c.called && !c.skip && !c.invalid && !c.duplicate && !dontCall;
  }) || null;
};

export const hasMoreCalls = lead => {
  const remainingCalls = (lead.calls || []).filter(c => {
    const dontCall = noCall(lead.no_call_list, c?.number);
    return !c.called && !c.skip && !c.invalid && !c.duplicate && !dontCall;
  });
  return remainingCalls.length > 0;
};

export const connectConference = async (conference, conferenceId) => {
  const callData = await conference?.connect({
    params: { joinConference: true, conferenceId }
  });
  return callData;
};

export const setupConference = async () => {
  const token = await getToken();
  const conferenceDevice = new Device(token, {
    codecPreferences: ['opus', 'pcmu'],
    enableRingingState: true
  });
  return conferenceDevice;
};

export const onConferenceReady = async (conference, updateMicOptions) => {
  conference.audio.on('deviceChange', async () => {
    updateMicOptions();
  });
  conference._enabledSounds[Device.SoundName.Outgoing] = false;
  conference._enabledSounds[Device.SoundName.Disconnect] = false;
  conference.on('tokenWillExpire', async () => {
    const token = await getToken();
    console.log('Refreshing device token: ' + token);
    conference.updateToken(token);
  });
};


export const getToken = async () => {
  let token = null;
  try {
    const results = await axios.get(`${apiPath}/twilio/v3/token`, {
      headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') }
    });
    token = results?.data?.token || null;
  } catch (err) {
    console.error(err);
  }
  return token;
};