export const ETHNICITIES = [
  'White English',
  'White Welsh',
  'White Scottish',
  'White Northern Irish',
  'White Irish',
  'White Gypsy or Irish Traveller',
  'White Other',
  'Mixed White and Black Caribbean',
  'Mixed White and Black African',
  'Mixed White Other',
  'Asian Indian',
  'Asian Pakistani',
  'Asian Bangladeshi',
  'Asian Chinese',
  'Asian Other',
  'Black African',
  'Black African American',
  'Black Caribbean',
  'Black Other',
  'Arab',
  'Hispanic',
  'Latino',
  'Native American',
  'Pacific Islander',
  'Other'
];
