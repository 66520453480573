import gql from 'graphql-tag';

export const sendInvitation = gql`
  mutation sendInvitation($response_id: String!) {
    sendInvitation(response_id: $response_id)
  }
`;

export const declineInvitation = gql`
  mutation declineInvitation($response_id: String!) {
    declineInvitation(response_id: $response_id)
  }
`;

export const acknowledgeFeedback = gql`
  mutation acknowledgeFeedback($feedback_id: String!) {
    acknowledgeFeedback(feedback_id: $feedback_id)
  }
`;

export const createCompany = gql`
  mutation createCompany($response_id: String!) {
    createCompany(response_id: $response_id)
  }
`;

export const updateCohort = gql`
  mutation updateCohort($agent_id: String!, $cohort: String!) {
    updateCohort(agent_id: $agent_id, cohort: $cohort)
  }
`;

export const updateManager = gql`
  mutation updateManager($agent_id: String!, $manager_id: String!) {
    updateManager(agent_id: $agent_id, manager_id: $manager_id)
  }
`;

export const updateCompanyManager = gql`
  mutation updateCompanyManager($company_id: String!, $manager_id: String!) {
    updateCompanyManager(company_id: $company_id, manager_id: $manager_id)
  }
`;

export const updateUnpaid = gql`
  mutation updateUnpaid($company_id: String!, $unpaid: Boolean!) {
    updateUnpaid(company_id: $company_id, unpaid: $unpaid)
  }
`;

export const updateCompanyReview = gql`
  mutation updateCompanyReview(
    $type: String
    $revisit: String
    $note: String
    $application_id: String!
  ) {
    updateCompanyReview(
      type: $type
      revisit: $revisit
      note: $note
      application_id: $application_id
    )
  }
`;

export const sendCompanyInvite = gql`
  mutation sendCompanyInvite($application_id: String!) {
    sendCompanyInvite(application_id: $application_id)
  }
`;

export const revokeCompanyInvite = gql`
  mutation revokeCompanyInvite($application_id: String!) {
    revokeCompanyInvite(application_id: $application_id)
  }
`;

export const revertToDraft = gql`
  mutation revertToDraft($campaign_id: String!) {
    revertToDraft(campaign_id: $campaign_id)
  }
`;

export const masqueradeUser = gql`
  mutation masqueradeUser($user_id: String!) {
    masqueradeUser(user_id: $user_id)
  }
`;

export const updateSalary = gql`
  mutation updateSalary(
    $agent_id: String!
    $type: String
    $amount: Float
    $sellx_fee_override: Float
    $monthly_paydate: Int
  ) {
    updateSalary(
      agent_id: $agent_id
      type: $type
      amount: $amount
      monthly_paydate: $monthly_paydate
      sellx_fee_override: $sellx_fee_override
    )
  }
`;

export const payBonus = gql`
  mutation payBonus($agent_id: String!, $amount: Int!, $reason: String!) {
    payBonus(agent_id: $agent_id, amount: $amount, reason: $reason)
  }
`;

export const saveSeats = gql`
  mutation saveSeats(
    $company_id: String!, 
    $internal_seats: Int!,
    $xdr_seats: Int!
    $trial_expire_date: String
  ) {
    saveSeats(
      company_id: $company_id, 
      internal_seats: $internal_seats,
      xdr_seats: $xdr_seats,
      trial_expire_date: $trial_expire_date
    )
  }
`;
