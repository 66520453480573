import React, { Component } from 'react';
import { Modal, Input, Radio, Row, Col } from 'antd';
import MultipleLocations from '_forms/MultipleLocations/MultipleLocations';
import MultipleIndustries from '_forms/MultipleIndustries/MultipleIndustries';
import { updateCompanyProfile } from 'Mutations/Company/profile';
import './EditDetails.scss';
const { TextArea } = Input;

export default class EditDetails extends Component {
  state = {
    saving: false,
    form: {
      name: this.props.company.name,
      about: this.props.company.about,
      headline: this.props.company.headline,
      overview: this.props.company.overview,
      locations: this.props.company.locations,
      industries: this.props.company.industries,
      employee_count_min: parseInt(this.props.company.employee_count_min || 0),
      employee_count_max: parseInt(this.props.company.employee_count_max || 0),
      website: this.props.company.website,
      linkedin: this.props.company.linkedin,
      facebook: this.props.company.facebook,
      instagram: this.props.company.instagram,
      twitter: this.props.company.twitter
    }
  };
  updateForm = (field, value) => {
    const form = Object.assign({}, this.state.form);
    form[field] = value;
    this.setState({ form });
  };
  onEmployeeSelect = e => {
    const [employee_count_min, employee_count_max] = e.target.value.split('-');
    const form = Object.assign({}, this.state.form);
    form.employee_count_min = parseInt(employee_count_min);
    form.employee_count_max = parseInt(employee_count_max);
    this.setState({ form });
  };
  save = () => {
    this.setState({ saving: true });
    this.props.client
      .mutate({
        variables: { ...this.state.form, company_id: this.props.companyId },
        mutation: updateCompanyProfile
      })
      .then(result => {
        if (result && result.data && result.data.updateCompanyProfile) {
          this.props.reload();
          this.props.close();
        } else {
          this.setState({ saving: false });
        }
      })
      .catch(() => {
        this.setState({ saving: false });
      });
  };
  render() {
    const form = this.state.form;

    return (
      <Modal
        title="Edit Details"
        visible={this.props.visible}
        afterClose={this.props.destroy}
        onCancel={this.props.close}
        okText="Save"
        onOk={this.save}
        wrapClassName="profile-form-modal"
        confirmLoading={this.state.saving}
        destroyOnClose={true}
        maskClosable={false}
        style={{ top: 30 }}
      >
        <Row className="live-chart-bottom" gutter={30}>
          <Col span={12}>
            <div className="form-title">Name *</div>
            <Input
              size="large"
              className="forms"
              value={form.name}
              onChange={e => this.updateForm('name', e.target.value)}
            />

            <div className="form-title">Website</div>
            <Input
              size="large"
              className="forms"
              value={form.website}
              onChange={e => this.updateForm('website', e.target.value)}
            />

            <div className="form-title">About *</div>
            <TextArea
              className="forms forms-textarea"
              rows={4}
              autoSize={false}
              value={form.about}
              onChange={e => this.updateForm('about', e.target.value)}
            />

            <div className="form-title">Headline *</div>
            <TextArea
              className="forms forms-textarea"
              rows={4}
              autoSize={false}
              value={form.headline}
              onChange={e => this.updateForm('headline', e.target.value)}
            />

            <div className="form-title">Overview *</div>
            <TextArea
              className="forms forms-textarea"
              rows={4}
              autoSize={false}
              value={form.overview}
              onChange={e => this.updateForm('overview', e.target.value)}
            />

            <div className="form-title">Locations *</div>
            <MultipleLocations
              placeholder="Search locations..."
              notFoundContent="Type to search locations..."
              value={form.locations}
              onChange={value => this.updateForm('locations', value)}
            />
          </Col>
          <Col span={12}>
            <div className="form-title">Industries *</div>
            <MultipleIndustries
              placeholder="Search industries..."
              notFoundContent="Type to search industries..."
              value={form.industries}
              onChange={value => this.updateForm('industries', value)}
            />

            <div className="form-title">Employees *</div>
            <Radio.Group
              onChange={this.onEmployeeSelect}
              value={form.employee_count_min + '-' + form.employee_count_max}
              className="radio-select"
              buttonStyle="solid"
            >
              <Radio.Button value="1-10">1-10</Radio.Button>
              <Radio.Button value="11-50">11-50</Radio.Button>
              <Radio.Button value="51-200">51-200</Radio.Button>
              <Radio.Button value="201-500">201-500</Radio.Button>
              <Radio.Button value="501-1000">501-1,000</Radio.Button>
              <Radio.Button value="1001-5000">1,001-5,000</Radio.Button>
              <Radio.Button value="5001-999999">5,000+</Radio.Button>
            </Radio.Group>

            <div className="form-title">LinkedIn</div>
            <Input
              size="large"
              className="forms"
              value={form.linkedin}
              onChange={e => this.updateForm('linkedin', e.target.value)}
            />

            <div className="form-title">Facebook</div>
            <Input
              size="large"
              className="forms"
              value={form.facebook}
              onChange={e => this.updateForm('facebook', e.target.value)}
            />

            <div className="form-title">Instagram</div>
            <Input
              size="large"
              className="forms"
              value={form.instagram}
              onChange={e => this.updateForm('instagram', e.target.value)}
            />

            <div className="form-title">Twitter</div>
            <Input
              size="large"
              className="forms"
              value={form.twitter}
              onChange={e => this.updateForm('twitter', e.target.value)}
            />
          </Col>
        </Row>
      </Modal>
    );
  }
}
