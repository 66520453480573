import gql from 'graphql-tag';

export const updateAgentProfileAbout = gql`
  mutation updateAgentAbout($about: String) {
    updateAgentAbout(about: $about) {
      about
    }
  }
`;

export const updateAgentProfileEmployment = gql`
  mutation updateAgentEmployment($employment: String) {
    updateAgentEmployment(employment: $employment) {
      employment
    }
  }
`;

export const updateAgentProfileSkills = gql`
  mutation updateAgentSkills($skills: [String]) {
    updateAgentSkills(skills: $skills) {
      skills
    }
  }
`;

export const updateAgentProfilePicture = gql`
  mutation updateAgentPicture($picture: String) {
    updateAgentPicture(picture: $picture)
  }
`;

export const updateAgentProfileUser = gql`
  mutation updateAgentInformation(
    $location: LocationInput!
    $total_experience: String!
    $employment: String!
    $languages: [String]
    $url_linkedin: String
    $url_twitter: String
    $url_facebook: String
    $url_website: String
  ) {
    updateAgentInformation(
      location: $location
      total_experience: $total_experience
      employment: $employment
      languages: $languages
      url_linkedin: $url_linkedin
      url_twitter: $url_twitter
      url_facebook: $url_facebook
      url_website: $url_website
    )
  }
`;

export const createAgentProfileAccomplishment = gql`
  mutation createAgentAccomplishment($description: String) {
    createAgentAccomplishment(description: $description) {
      accomplishments {
        id
        description
      }
    }
  }
`;

export const updateAgentProfileAccomplishment = gql`
  mutation updateAgentAccomplishment($id: String, $description: String) {
    updateAgentAccomplishment(id: $id, description: $description) {
      accomplishments {
        id
        description
      }
    }
  }
`;

export const deleteAgentProfileAccomplishment = gql`
  mutation deleteAgentAccomplishment($id: String) {
    deleteAgentAccomplishment(id: $id) {
      accomplishments {
        id
        description
      }
    }
  }
`;

export const createAgentProfileExperience = gql`
  mutation createAgentExperience(
    $company: String!
    $title: String!
    $description: String!
    $start_date: String!
    $end_date: String
    $current: Boolean!
  ) {
    createAgentExperience(
      company: $company
      title: $title
      description: $description
      start_date: $start_date
      end_date: $end_date
      current: $current
    ) {
      experiences {
        id
        company
        title
        description
        start_date
        end_date
        current
      }
    }
  }
`;

export const updateAgentProfileExperience = gql`
  mutation updateAgentExperience(
    $id: String!
    $company: String!
    $title: String!
    $description: String!
    $start_date: String!
    $end_date: String
    $current: Boolean!
  ) {
    updateAgentExperience(
      id: $id
      company: $company
      title: $title
      description: $description
      start_date: $start_date
      end_date: $end_date
      current: $current
    ) {
      experiences {
        id
        company
        title
        description
        start_date
        end_date
        current
      }
    }
  }
`;

export const deleteAgentProfileExperience = gql`
  mutation deleteAgentExperience($id: String) {
    deleteAgentExperience(id: $id) {
      experiences {
        id
        company
        title
        description
        start_date
        end_date
        current
      }
    }
  }
`;

export const createAgentProfileEducation = gql`
  mutation createAgentEducation(
    $school: String!
    $degree: String!
    $field: String!
    $description: String!
    $gpa: Float
    $start_date: String!
    $end_date: String
  ) {
    createAgentEducation(
      school: $school
      degree: $degree
      field: $field
      description: $description
      gpa: $gpa
      start_date: $start_date
      end_date: $end_date
    ) {
      education {
        id
        school
        degree
        field
        description
        gpa
        start_date
        end_date
      }
    }
  }
`;

export const updateAgentProfileEducation = gql`
  mutation updateAgentEducation(
    $id: String!
    $school: String!
    $degree: String!
    $field: String!
    $description: String!
    $gpa: Float
    $start_date: String!
    $end_date: String
  ) {
    updateAgentEducation(
      id: $id
      school: $school
      degree: $degree
      field: $field
      description: $description
      gpa: $gpa
      start_date: $start_date
      end_date: $end_date
    ) {
      education {
        id
        school
        degree
        field
        description
        gpa
        start_date
        end_date
      }
    }
  }
`;

export const deleteAgentProfileEducation = gql`
  mutation deleteAgentEducation($id: String) {
    deleteAgentEducation(id: $id) {
      education {
        id
        school
        degree
        field
        description
        gpa
        start_date
        end_date
      }
    }
  }
`;
