import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { getErrors, plural } from '_assets/js/helpers';
import { Modal, Button, Header, Loading, message } from '_seriph';
import TouchpointModal from '../TouchpointModal/TouchpointModal';
import { previewSequence } from '_graphql/queries/sequence';
import { IconPencil } from '@tabler/icons-react';
import { touchpointTypes } from '_constants/campaigns';
import './PreviewSequence.scss';

class PreviewSequence extends Component {
  state = {
    visible: true,
    loading: true,
    sequence: null
  };
  componentDidMount = () => {
    this.loadSequence();
  };
  loadSequence = () => {
    this.props.client
      .query({ variables: { id: this.props.sequenceId }, query: previewSequence })
      .then(result => {
        if (result && result.data && result.data.getSequence) {
          const sequence = result.data.getSequence || {};
          this.setState({
            sequence,
            loading: false
          });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        message.error(getErrors(err) || 'Could not load sequence, try again');
      });
  };
  selectSequence = () => {
    if (this.props.selectSequence) this.props.selectSequence();
    this.hideModal();
  };
  showTouchpointModal = id => {
    this.setState({ touchpointModal: id });
  };
  removeTouchpointModal = () => {
    this.setState({ touchpointModal: false });
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { sequence, loading } = this.state;

    return (
      <Modal
        wrapClassName="preview-sequence-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={true}
        width={660}
      >
        {loading ? (
          <Loading />
        ) : (
          <div className="prev-wrap">
            <Modal.Close onClick={this.hideModal} />

            <div className="prev-header">
              <Header size="2" font="sans" className="new-header">
                {sequence.name}
              </Header>
              <div className="stats">
                <b>{sequence.day_count}</b> {plural('day', sequence.day_count)}
                &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                <b>{sequence.touchpoint_count}</b> {plural('touchpoint', sequence.touchpoint_count)}
              </div>
            </div>

            <div className="prev-touchpoints">
              {sequence.days.map((d, i) => (
                <div key={'day-' + (i + 1)} className="day">
                  <div className="day-title">
                    <h4>Day {i + 1}&nbsp;&nbsp;- </h4>
                    <p>
                      {d.touchpoints.length} {plural('touchpoint', d.touchpoints.length)}
                    </p>
                  </div>

                  <div className="touchpoints">
                    {d.touchpoints.map((t, j) => {
                      const type = t.type;
                      const touchType = touchpointTypes[type];
                      const style = {
                        background: touchType?.color || '#090E15',
                        color: touchType?.static ? '#FFFFFF' : '#090E15'
                      };
                      return (
                        <div key={`touch-${i}-${j}`} className="touchpoint">
                          <div className="type" style={style}>
                            {touchType.icon}
                          </div>
                          <div className="info">
                            <h4>{touchType.name}</h4>
                          </div>
                          {type !== 'wait' && (
                            <Button
                              type="default"
                              size="tiny"
                              onClick={() => this.showTouchpointModal(t.touchpoint_id)}
                            >
                              Preview
                            </Button>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>

            <Modal.Actions align="right">
              <Link to={`/sequences/${sequence.id}`} className="left">
                <Button icon="left" type="secondary">
                  <IconPencil />
                  Edit
                </Button>
              </Link>
              <Button type="default" onClick={() => this.hideModal()}>
                Close
              </Button>
              {!this.props.isSelected && (
                <Button type="primary" onClick={this.selectSequence}>
                  Select Sequence
                </Button>
              )}
            </Modal.Actions>
          </div>
        )}

        {this.state.touchpointModal ? (
          <TouchpointModal
            touchpointId={this.state.touchpointModal}
            preview={true}
            client={this.props.client}
            removeModal={this.removeTouchpointModal}
            reload={this.getTouchpoints}
          />
        ) : null}
      </Modal>
    );
  }
}

export default withRouter(PreviewSequence);
