import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'antd';
import './Infotip.scss';

export default class Infotip extends Component {
  render() {
    return (
      <Tooltip overlayClassName="sellx-infotip" title={this.props.title}>
        <FontAwesomeIcon className="sellx-infotip-icon" icon={faInfoCircle} />
      </Tooltip>
    );
  }
}
