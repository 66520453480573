import { calculatePayMin } from './calculations';

export const experienceTypes = [
  { title: 'Any', value: 'any', salary: 55000 },
  { title: 'Pro', description: '1 - 2 years', value: 'entry', salary: 55000 },
  { title: 'Expert', description: '3 - 5 years', value: 'intermediate', salary: 65000 },
  { title: 'Specialist', description: '6+ years', value: 'expert', salary: 75000 }
];

export const startInboundTypes = {
  any: 0,
  pro: 0,
  intermediate: 0,
  expert: 0
};

export const startOutboundTypes = {
  any: 0,
  pro: 0,
  intermediate: 0,
  expert: 0
};

export const leadTypes = [
  { title: 'Companies', value: 'companies' },
  { title: 'Individuals', value: 'individuals' }
];

export const companyTypes = [
  { title: 'Micro', description: '1 - 9', value: 'micro' },
  { title: 'Small', description: '10 - 49', value: 'small' },
  { title: 'Mid', description: '40 - 249', value: 'mid' },
  { title: 'Large', description: '250+', value: 'large' }
];

const salaries = { any: 55000, entry: 55000, intermediate: 65000, expert: 75000 };

export const calculateValue = (campaign, importAmount) => {
  /* NECESSARY VARIABLES */
  const isGen = campaign.objective === 'qualify' ? false : true;
  const repExperience = campaign.target_experience_level;
  const payPerLead = isGen ? campaign.budget_ppl : campaign.budget_ppql;
  const estLeads = isGen
    ? Math.floor((campaign.budget_total || 0) / (payPerLead || 1))
    : importAmount;
  const maxTarget = Math.floor((estLeads || 0) / 600) + 1;
  const agentCount = campaign.target_agent_count;
  const budgetTotal = campaign.budget_total || 0;

  /* BUDGET SPECIFIC VARIABLES */
  const workableDays = 260;
  const repSalary = salaries[repExperience || 'any'];
  const amountPerDay = repSalary / workableDays; // Money they need to make per day to make a salary
  const payPerInvalid = 2 * 0.7;
  const payPerActual = (payPerLead || 1) * 0.7;

  /* HANDLE INBOUND AND OUTBOUND (1-3% Inbound - 10-15% Outbound) */
  let conversionRate = 0.0075,
    highRate = 0.075;
  if (!isGen && campaign.objective_type === 'inbound') {
    conversionRate = 0.06;
    highRate = 0.3; // ONLY USED FOR BUDGET TOTAL, NOT PAY PER LEAD
  }

  const payPerMin = calculatePayMin(campaign);

  if (isGen) {
    const perDay = Math.floor(amountPerDay / payPerActual);
    const result = payPerLead < payPerMin ? 'bad' : 'good';
    const highOrLow = result === 'bad' ? 'low' : null;
    const causes = [];
    if ((agentCount || 1) / (maxTarget || 1) < 0.34) causes.push('target_agent_count');
    if (result === 'bad') {
      causes.push('payPerLead');
      causes.push('target_experience_level');
    }
    return {
      leadsPerDay: perDay,
      payPerLead: payPerLead,
      result: causes.length > 0 ? 'bad' : 'good',
      resultReason: highOrLow,
      cause: causes
    };
  } else {
    /* BENCHMARKS FOR LEAD AMOUNTS */
    const leadsNeededPerDay =
      amountPerDay / (payPerActual * conversionRate + payPerInvalid * (1 - conversionRate));
    const result = payPerLead < payPerMin ? 'bad' : 'good';
    const highOrLow = result === 'bad' ? 'low' : null;
    const estimatedCostMin =
      conversionRate * (estLeads || 1) * (payPerLead || 1) +
      (1 - conversionRate) * (estLeads || 1) * 2;
    const estimatedCostMax =
      highRate * (estLeads || 1) * (payPerLead || 1) + (1 - highRate) * (estLeads || 1) * 2;
    const causes = [];

    // Calculate causes
    if ((agentCount || 1) / (maxTarget || 1) < 0.34) causes.push('target_agent_count');
    if (result === 'bad') {
      causes.push('payPerLead');
      causes.push('target_experience_level');
    }
    // if (budgetTotal < estimatedCostMin || budgetTotal > estimatedCostMax) causes.push('budget_total');

    return {
      leadsPerDay: leadsNeededPerDay,
      payPerLead: payPerLead,
      result: causes.length > 0 ? 'bad' : 'good',
      resultReason: highOrLow,
      budget:
        budgetTotal > estimatedCostMax ? 'high' : budgetTotal < estimatedCostMin ? 'low' : null,
      cause: causes
    };
  }
};
