import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { prettyPhone } from '_assets/js/helpers';

export const getColumns = (showReviewCandidate, resendInvite) => {
  return [
    {
      title: 'Full name',
      dataIndex: 'first_name',
      render: (column, data) => (
        <b>
          {data ? data.first_name : null} {data ? data.last_name : null}
        </b>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: column => <span className={'app-status ' + column}>{column}</span>
    },
    {
      title: 'Invite Link',
      dataIndex: 'invite_link',
      render: (column, data) =>
        data.status !== 'Invited' ? null : (
          <a
            href={process.env.REACT_APP_CLIENT_PATH + '/invitation/' + data.response_id}
            rel="noopener noreferrer"
            target="_blank"
          >
            Invite Link
          </a>
        )
    },
    {
      title: 'Score',
      dataIndex: 'score',
      sorter: true
    },
    {
      title: 'Started',
      dataIndex: 'landed_at',
      render: column => moment(column * 1).format('M/D/YY h:mma')
    },
    {
      title: 'Completed',
      dataIndex: 'submitted_at',
      render: column => moment(column * 1).format('M/D/YY h:mma')
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Phone',
      dataIndex: 'phone_number',
      render: column => prettyPhone(column)
    },
    {
      title: 'LinkedIn',
      dataIndex: 'linkedin',
      render: column =>
        column ? (
          <a href={column} rel="noopener noreferrer" target="_blank">
            {column}
          </a>
        ) : (
          ''
        )
    },
    {
      title: 'Answered',
      dataIndex: 'answer_count',
      render: (column, data) => (data.answers.length || 0) + ' answered'
    },
    {
      title: 'Actions',
      dataIndex: 'review',
      fixed: 'right',
      render: (column, data) => {
        const disabled = data.status === 'Joined';
        let className = 'btnx btnx-neutral';
        if (data.status === 'Unread' || data.status === 'Passed') className += ' btnx-caution';
        return (
          <Button
            disabled={disabled}
            onClick={() =>
              data.status === 'Invited' ? showReviewCandidate(data.id) : resendInvite(data.id)
            }
            className={className}
          >
            {data.status === 'Unread' ? 'Review Candidate' : ''}
            {data.status === 'Passed' ? 'Re-Review Candidate' : ''}
            {data.status === 'Joined' ? 'User Joined' : ''}
            {data.status === 'Invited' ? 'Resend Invite' : ''}
          </Button>
        );
      }
    }
  ];
};
