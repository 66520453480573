import React from 'react';
import { numberWithCommas } from '_assets/js/helpers';
import { IconPlayerPlay, IconPlayerPause, IconX } from '@tabler/icons-react';
import { getLeadName } from '_helpers/lead';
import { Button } from '_seriph';
import moment from 'moment';

export const getColumns = (playRecording, playingRecording) => {
  return [
    {
      title: <b>5 recent more than 30 seconds</b>,
      dataIndex: 'lead',
      fixed: 'left',
      render: (column, call) => {
        const lead = call.lead || {};
        const campaign = call.campaign || {};
        return (
          <div className="lead-cont">
            <div className="lead-section">
              <div className="lead-name">{getLeadName(lead)}</div>
              <div className="campaign-name">{campaign.name}</div>
            </div>
          </div>
        );
      }
    },
    {
      title: 'When',
      dataIndex: 'createdAt',
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: createdAt => {
        return moment(parseInt(createdAt)).format('MM/DD • h:mma');
      }
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      sorter: (a, b) => a.duration - b.duration,
      render: duration => {
        return numberWithCommas(duration) + ' seconds';
      }
    },
    {
      title: 'Recording',
      dataIndex: 'actions',
      render: (column, call) => {
        return (
          <div className="actions-cont">
            {call.recording_url ? (
              <Button
                type="primary"
                size="tiny"
                circle={true}
                onClick={() => playRecording(call.id, call.recording_url)}
              >
                {call.id === playingRecording ? <IconPlayerPause /> : <IconPlayerPlay />}
              </Button>
            ) : (
              <Button type="disabled" size="tiny" circle={true}>
                <IconX />
              </Button>
            )}
          </div>
        );
      }
    }
  ];
};