import React from 'react';
import { formatMoney, numberWithCommas, formatPercent, plural } from '_assets/js/helpers';
import { statusMap } from '_constants/campaigns';
import CampaignActions from '_shared/CampaignActions/CampaignActions';
import moment from 'moment';
import { Tooltip, Progress } from '_seriph';
import { durationShort } from '_constants/campaigns';

export const getColumns = (reload, mapAgents) => {
  return [
    {
      title: 'Campaign',
      dataIndex: 'name',
      key: 'name',
      width: 280,
      render: (c, r) => {
        const obj = r.objective;
        const underReview = r.sub_status === 'review' ? true : false;
        return (
          <div className="ctbl-box">
            <div className="ctbl-name">
              <h4>
                <span className={'sx-status ' + r.status} />
                {c}
              </h4>
              <h5>
                <span className={'left-type ' + obj}>
                  {obj === 'qualify' && 'Lead Qualification'}
                  {obj === 'leadgen' && 'Lead Generation'}
                  {obj === 'draft' && 'Draft'}
                </span>
                {r.status === 'active' && !underReview && r.status_updated && (
                  <span>
                    &nbsp;&nbsp;&bull;&nbsp;&nbsp;
                    {statusMap[r.status]} {moment(r.status_updated * 1).format('M/D')}
                  </span>
                )}
                {r.status === 'active' && underReview && r.status_updated && (
                  <span>
                    &nbsp;&nbsp;&bull;&nbsp;&nbsp; Pending Review{' '}
                    {moment(r.status_updated * 1).format('M/D')}
                  </span>
                )}
                {(r.status === 'inactive' || r.status === 'completed') && r.status_updated && (
                  <span>
                    &nbsp;&nbsp;&bull;&nbsp;&nbsp;
                    {statusMap[r.status]} {moment(r.status_updated * 1).format('M/D')}
                  </span>
                )}
                {r.status === 'draft' && (
                  <span>
                    &nbsp;&nbsp;&bull;&nbsp;&nbsp; Saved {moment(r.updatedAt * 1).format('M/D')}
                  </span>
                )}
              </h5>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Sales Reps',
      dataIndex: 'target_agent_count',
      key: 'target_agent_count',
      width: 150,
      render: (c, r) => {
        const allAgents = mapAgents(r.agents || []);
        const oversized = allAgents.length > 3 ? true : false;
        const firstThree = allAgents.slice(0, 3);
        return r.status === 'draft' || allAgents.length <= 0 ? (
          <div className="no-reps">No Reps yet</div>
        ) : (
          <div className="rep-list">
            <div className="rep-avs">
              {firstThree.map(r => (
                <img key={'repav-' + r.id} src={r.picture} alt="" />
              ))}
            </div>
            {oversized ? <div className="oversized">+{allAgents.length - 3} more</div> : null}
          </div>
        );
      }
    },
    {
      title: 'Budget/Threshold',
      key: 'threshold',
      width: 140,
      render: (c, r) => {
        const typeText = r.objective === 'qualify' ? 'qualified' : 'generated';
        if (r.threshold) {
          const duration = c.threshold_settings.duration;
          return (
            <div className="thresh-box">
              <Tooltip
                centered
                title={`You are paying ${formatMoney(r.price_per_lead)} per ${typeText} lead.`}
              >
                <div className="sxd-title">
                  {numberWithCommas(r.stats.estimated_total)}{' '}
                  {plural('lead', r.stats.estimated_total)}
                </div>
              </Tooltip>
              <div className="thresh-dur">{durationShort[duration]}</div>
            </div>
          );
        } else {
          return (
            <div className="thresh-box">
              <Tooltip
                title={`You are paying ${formatMoney(
                  r.price_per_lead
                )} per ${typeText} lead, and there are ${numberWithCommas(
                  r.stats.remaining
                )} remaining`}
              >
                <div className="sxd-title">{formatMoney(r.actual_budget)}</div>
                <div className="thresh-dur">Budget</div>
              </Tooltip>
            </div>
          );
        }
      }
    },
    {
      title: 'Generated / Qualified',
      key: 'lead_stats',
      width: 160,
      render: (c, r) => {
        const isQualify = r.objective === 'qualify' ? true : false;

        const tipData = (
          <div>
            {isQualify && (
              <div>
                {numberWithCommas(r.stats.qualified)} Qualified {plural('lead', r.stats.qualified)}
              </div>
            )}
            {!isQualify && (
              <div>
                {numberWithCommas(r.stats.generated)} Generated {plural('lead', r.stats.qualified)}
              </div>
            )}
            {isQualify && <div>{formatPercent(r.stats.conversion_rate, 1)} Conversion rate</div>}
            {isQualify && <div>{numberWithCommas(r.stats.total_leads)} Imported leads</div>}
            {!isQualify && (
              <div>
                {numberWithCommas(r.stats.remaining)} Remaining {plural('lead', r.stats.remaining)}
              </div>
            )}
          </div>
        );

        return (
          <div className="thresh-box">
            <Tooltip title={tipData}>
              <div className="sxd-title">
                {numberWithCommas(
                  isQualify
                    ? numberWithCommas(r.stats.qualified)
                    : numberWithCommas(r.stats.generated)
                )}
                &nbsp;
                {isQualify ? 'qualified' : 'generated'}
              </div>
            </Tooltip>
            <div className="thresh-dur">
              {!isQualify && numberWithCommas(r.stats.remaining) + ' remaining'}
              {isQualify &&
                formatPercent(r.stats.conversion_rate, 1) +
                  ' of ' +
                  numberWithCommas(r.stats.completed) +
                  ' ' +
                  plural('lead', r.stats.completed)}
            </div>
          </div>
        );
      }
    },
    {
      title: 'Paid out',
      key: 'paid_stats',
      width: 140,
      render: (c, r) => {
        const isQualify = r.objective === 'qualify' ? true : false;
        const tool = !isQualify ? (
          <div className="progress-tt">
            <div>
              &nbsp;{r.stats.completed} Generated ({formatMoney(r.stats.paid)})
            </div>
            <div className="pt-title top">Pending</div>
            <div>
              &nbsp;{r.stats.review} Pending
            </div>
          </div>
        ) : (
          <div className="progress-tt">
            <div className="pt-title">Paid ({formatMoney(r.stats.paid)})</div>
            <div>
              &nbsp;{r.stats.qualified} Qualified ({formatMoney(r.stats.paid_interested)})
            </div>
            <div>
              &nbsp;{r.stats.not_interested} Not Interested
            </div>
            <div className="pt-title top">Pending ({formatMoney(r.stats.owed)})</div>
            <div>
              &nbsp;{r.stats.review_interested} Interested ({formatMoney(r.stats.owed_interested)})
            </div>
            <div>
              &nbsp;{r.stats.review_not_interested} Not Interested
            </div>
          </div>
        );

        return (
          <div className="thresh-box">
            <Tooltip title={tool}>
              <div className="sxd-title">{formatMoney(r.stats.paid)}</div>
            </Tooltip>
            <div className="thresh-dur">{formatMoney(r.stats.owed)} pending</div>
          </div>
        );
      }
    },
    {
      title: 'Progress',
      key: 'progress',
      width: 170,
      render: (c, r) => {
        const completedPercent = Math.ceil((r.stats.completed / r.stats.estimated_total) * 100);
        const inProgressPercent = Math.ceil((r.stats.in_progress / r.stats.estimated_total) * 100);
        const reviewPercent = Math.ceil((r.stats.review / r.stats.estimated_total) * 100);

        const progressMessage = (
          <div className="progress-tt">
            <div>
              <span className="complete" /> {r.stats.completed} Completed
            </div>
            <div>
              <span className="review" /> {r.stats.review} Pending Review
            </div>
            <div>
              <span className="progress" /> {r.stats.in_progress} In-Progress
            </div>
            <div>
              <span className="unassigned" /> {r.stats.remaining} Available
            </div>
          </div>
        );

        let progressData = [];
        progressData = [
          { color: '#87B0ED', percent: completedPercent },
          { color: '#B5B5FD', percent: reviewPercent },
          { color: '#ABD4A2', ignore: true, percent: inProgressPercent }
        ];

        return (
          <Tooltip title={progressMessage}>
            <div className="progress-table">
              <Progress progressData={progressData} rounded />
            </div>
          </Tooltip>
        );
      }
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      align: 'right',
      fixed: 'right',
      render: (c, r) => <CampaignActions campaign={r} stats={true} reload={reload} />
    }
  ];
};
