import React, { Component } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { numberWithCommas } from '_assets/js/helpers';
import { Loading } from '_seriph';
import './LeadActivityChart.scss';

export default class LeadActivityChart extends Component {
  customTooltip = data => {
    const payload = data.payload || [];
    return (
      <div className="chart-tooltip">
        <div className="tip-box">
          <div className="tip-title">{data.label}</div>
          <div className="tip-contents">
            {payload.map((p, i) => (
              <div key={`tip-${i}`} className="tip-item">
                <div className="tip-circle" style={{ backgroundColor: p.stroke }} />
                <div className="tip-name">{p.name}</div>
                <div className="tip-value">{numberWithCommas(p.value) || '0'}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  render() {
    const { totals, data, range, loading } = this.props;

    const tickCount = data && data.length > 0 ? data[0].data.length : 0;
    const interval = tickCount > 7 ? Math.ceil(tickCount / 7) : 0;
    return (
      <div className="chart-box" id="overview-lead-activity-chart">
        <div className="chart-title">
          <h4>
            Lead activity<span>({range.title})</span>
          </h4>
          <p>Activity by leads in circulation of active campaigns</p>
        </div>
        <div className="chart-display">
          {loading ? (
            <div className="chart-loading" style={{ minHeight: '360px' }}>
              <Loading />
            </div>
          ) : (
            <React.Fragment>
              <div className="left-chart">
                <ResponsiveContainer height={360} width="99%">
                  <LineChart margin={{ right: 32 }}>
                    <CartesianGrid stroke="#E7ECF4" strokeDasharray="0 0" vertical={false} />
                    <XAxis
                      interval={interval}
                      tickLine={false}
                      axisLine={false}
                      dataKey="name"
                      type="category"
                      allowDuplicatedCategory={false}
                    />
                    <YAxis
                      tickLine={false}
                      axisLine={false}
                      dataKey="value"
                      domain={[0, 'dataMax + 2']}
                      allowDecimals={false}
                      width={40}
                    />
                    <Tooltip content={this.customTooltip} />
                    {data.map(s => (
                      <Line
                        dataKey="value"
                        data={s.data}
                        name={s.name}
                        key={s.name}
                        {...s.settings}
                      />
                    ))}
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div className="right-chart">
                <h4>Progress</h4>
                <div className="dash-data">
                  <div className="dash-sts">
                    <div>
                      <span className="in-progress" />
                      Created
                    </div>
                    <h2>{numberWithCommas(totals.in_progress) || '0'}</h2>
                  </div>
                  <div className="dash-sts">
                    <div>
                      <span className="review" />
                      Submitted
                    </div>
                    <h2>{numberWithCommas(totals.review) || '0'}</h2>
                  </div>
                  <div className="dash-sts">
                    <div>
                      <span className="generated" />
                      Generated
                    </div>
                    <h2>{numberWithCommas(totals.generated) || '0'}</h2>
                  </div>
                  <div className="dash-sts">
                    <div>
                      <span className="qualified" />
                      Qualified
                    </div>
                    <h2>{numberWithCommas(totals.qualified) || '0'}</h2>
                  </div>
                  <div className="dash-sts">
                    <div>
                      <span className="not-interested" />
                      Not Interested
                    </div>
                    <h2>{numberWithCommas(totals.not_interested) || '0'}</h2>
                  </div>
                  <div className="dash-sts">
                    <div>
                      <span className="disputed" />
                      Disputed
                    </div>
                    <h2>{numberWithCommas(totals.disputed) || '0'}</h2>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}
