import interestLevels from '_constants/interest';

export function computeLeadStatus(isAgent, lead, campaign) {
  const interest = interestLevels.find(i => i.slug === lead.interest_reason);
  const lastIndex = lead?.sequences?.map(s => s.completed).lastIndexOf(true);
  const lastStep = lead?.sequences?.[lastIndex];

  let colorPriority = [
    {
      id: 'unassigned',
      circleClass: 'unassigned',
      condition: !isAgent && lead.agent_id == null,
      firstLevel: 'Unassigned'
    },
    {
      id: 'paused',
      circleClass: 'paused',
      condition: campaign.status === 'inactive',
      firstLevel: 'Paused'
    },
    {
      id: 'disputed',
      circleClass: 'disputed',
      condition: lead.stage === 'disputed',
      firstLevel: 'Disputed'
    },
    {
      id: 'review',
      circleClass: 'review',
      condition: lead.stage === 'review',
      firstLevel: 'Review'
    },
    {
      id: 'no-for-some-reason',
      circleClass: 'invalid',
      condition: ['invalid', 'not-interested', 'no-response'].includes(lead.interest_reason),
      firstLevel: interest?.name
    },
    {
      id: 'call-scheduled-with-time',
      circleClass: 'call-scheduled',
      condition: lead?.calendly?.start_time,
      firstLevel: 'Meeting Scheduled'
    },
    {
      id: 'in-progress-called',
      circleClass: 'in-progress',
      condition: lastStep?.type === 'call',
      firstLevel: 'In Progress'
    },
    {
      id: 'in-progress-emailed',
      circleClass: 'in-progress',
      condition: lastStep?.type === 'email',
      firstLevel: 'In Progress'
    },
    {
      id: 'in-progress-linkedin',
      circleClass: 'in-progress',
      condition: lastStep?.type === 'linkedin',
      firstLevel: 'In Progress'
    },
    {
      id: 'completed',
      circleClass: 'completed',
      condition: lead.stage === 'completed',
      firstLevel: 'Completed'
    },
    {
      circleClass: 'in-progress',
      condition: lead.stage === 'in-progress',
      firstLevel: 'In Progress'
    }
  ];
  let winner = colorPriority.find(c => c.condition);
  return winner;
}
