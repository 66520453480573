import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import { updateEmployeePicture } from '_graphql/mutations/employee';
import Avatar from 'react-avatar-edit';
import { loginUser } from 'Store/user/user_actions';
import './AvatarEdit.scss';

class AvatarEdit extends Component {
  state = {
    saving: false,
    visible: true,
    preview: null
  };
  UNSAFE_componentWillMount = () => {
    this.setInitial();
  };
  setInitial = () => {
    this.setState({
      saving: false,
      preview: null
    });
  };
  onClose = () => {
    this.setState({ preview: null });
  };
  onCrop = preview => {
    this.setState({ preview });
  };
  onBeforeFileLoad = elem => {
    if (elem.target.files[0].size > 1024 * 1024) {
      message.error('Image size is too large, must be under 1 MB');
      elem.target.value = '';
    }
  };
  save = () => {
    this.setState({ saving: true });
    this.props.apollo
      .mutate({
        variables: {
          picture: this.state.preview
        },
        mutation: updateEmployeePicture
      })
      .then(result => {
        if (result && result.data && result.data.updateEmployeePicture) {
          this.props.loginUser(result.data.updateEmployeePicture);
          this.props.reload();
          this.hideModal();
        } else {
          this.setState({ saving: false });
        }
      })
      .catch(() => {
        this.setState({ saving: false });
      });
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    return (
      <Modal
        title="Edit: Profile Picture"
        visible={this.state.visible}
        okText="Save"
        onOk={this.save}
        okButtonProps={{ disabled: !this.state.preview }}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        wrapClassName="form-modal avatar-edit"
        confirmLoading={this.state.saving}
        maskClosable={false}
      >
        <div className="avatar-box">
          {this.state.preview === null ? (
            <div className="preview-box">
              <p className="ant-upload-drag-icon">
                <FontAwesomeIcon icon={faImages} />
              </p>
              <p className="ant-upload-text">Upload a profile image</p>
              <p className="ant-upload-hint">
                You can crop and customize your image after you select it.
              </p>
            </div>
          ) : null}
          <Avatar
            width={'100%'}
            height={300}
            cropRadius={100}
            onCrop={this.onCrop}
            onClose={this.onClose}
            onBeforeFileLoad={this.onBeforeFileLoad}
            src={this.state.src}
            borderStyle={{ border: '1px dashed #999', borderRadius: '0' }}
            label=""
            labelStyle={{ fontWeight: '500' }}
            mimeTypes="image/jpeg,image/pjpeg,image/png,image/gif"
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.apollo };
};

export default connect(mapStateToProps, { loginUser })(AvatarEdit);
