import React from 'react';
import { IconPhone } from '@tabler/icons-react';
import moment from 'moment';

export default function Email(props) {
  const { activity, user, goTo, lead } = props;

  return (
    <div className={'lead-activity ' + activity.type}>
      <div className={activity.call.incoming ? 'act-icon incoming call' : 'act-icon call'}>
        <IconPhone />
      </div>
      <div className="descript">
        {!activity.call.incoming && activity.call.answered && (
          <React.Fragment>
            <b>{user.display_name}</b> called <b>{lead.first_name + ' ' + lead.last_name}</b> for{' '}
            {activity.call.duration} second{activity.call.duration === 1 ? 's' : ''}
          </React.Fragment>
        )}
        {activity.call.incoming && activity.call.answered && (
          <React.Fragment>
            The lead <b>{lead.first_name + ' ' + lead.last_name}</b> called rep{' '}
            <b>{user.display_name}</b> for {activity.call.duration} second
            {activity.call.duration === 1 ? 's' : ''}
          </React.Fragment>
        )}
        {activity.call.incoming && !activity.call.answered && (
          <React.Fragment>
            The lead <b>{lead.first_name + ' ' + lead.last_name}</b> attempted to call rep{' '}
            <b>{user.display_name}</b> but they couldn&apos;t be reached
          </React.Fragment>
        )}
        {!activity.call.incoming && !activity.call.answered && !activity.call.voicemail && (
          <React.Fragment>
            <b>{user.display_name}</b> attempted to call{' '}
            <b>{lead.first_name + ' ' + lead.last_name}</b> but they couldn&apos;t be reached
          </React.Fragment>
        )}
        {!activity.call.incoming && !activity.call.answered && activity.call.voicemail && (
          <React.Fragment>
            <b>{user.display_name}</b> attempted to call{' '}
            <b>{lead.first_name + ' ' + lead.last_name}</b> and left a voicemail
          </React.Fragment>
        )}
        <div className="act-tstamp">
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;{moment(activity.createdAt * 1).format('MMM D @ h:mm a')}
        </div>
        
        {activity.call.answered || activity.call.voicemail ? (
          <React.Fragment>
            &nbsp;&middot;&nbsp;
            <div className="sxd-link" onClick={() => goTo('calls', `/${activity.call.call_id}`)}>
              See Call
            </div>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
}
