import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay,
  faPause,
  faUndoAlt,
  faRedoAlt,
  faCaretDown,
  faCaretUp
} from '@fortawesome/free-solid-svg-icons';
import WaveSurfer from 'wavesurfer.js';
import moment from 'moment';
import { timeFormat, getLeadPhone, prettyPhone } from '_assets/js/helpers';
import { getColumns } from './Columns';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import './SelectedCall.scss';
import Icons from 'App/_shared/Icons/Icons';
import { IconPhoneOutgoing, IconPhoneIncoming, IconPhoneX } from '@tabler/icons-react';
import { Card, Avatar, Button, Table, Tooltip, Tag } from '_seriph';

const formatTranscript = call => {
  const script = call.transcription.map(t => {
    if (call.incoming_call) {
      t.isAgent = t.text.includes('Speaker 1') ? false : true;
      t.isLead = t.text.includes('Speaker 1') ? true : false;
    } else {
      t.isAgent = t.text.includes('Speaker 1') ? true : false;
      t.isLead = t.text.includes('Speaker 1') ? false : true;
    }
    t.text = t.text.replace('Speaker 1 ', '').replace('Speaker 2 ', '');
    t.code = timeFormat(t.start / 1000) + ' - ' + timeFormat(t.stop / 1000);
    return t;
  });
  return script;
};

export default class SelectedCall extends Component {
  state = {
    playing: false,
    totalTime: 0,
    currentTime: 0,
    transcript: false,
    recording: false,
    script: formatTranscript(this.props.call)
  };
  waveform = null;
  componentDidMount() {
    if (
      !this.props.opened &&
      this.props.otherId === this.props.call.call_id &&
      !this.props.focused
    ) {
      if (this.props.call.recording_url) this.buildWaveform();
      this.props.scrollTo(this.props.call.call_id);
    } else if (this.props.focused && this.props.call.recording_url) {
      this.setState({ recording: true }, this.buildWaveform);
    }
  }
  buildWaveform = () => {
    this.setState({ playing: false, totalTime: 0, currentTime: 0 });
    const blue = { progressColor: '#B0D0E8', waveColor: '#B0D0E8' };
    const green = { progressColor: '#C1DDBB', waveColor: '#C1DDBB' };
    const { call } = this.props;
    this.waveform = WaveSurfer.create({
      cursorWidth: 1,
      container: '#waveform-' + call.id,
      backend: 'WebAudio',
      height: 100,
      cursorColor: '#090E15',
      splitChannels: true,
      fillParent: false,
      minPxPerSec: 15,
      barHeight: 0.75,
      barGap: null,
      barWidth: 3,
      barRadius: 0,
      splitChannelsOptions: {
        overlay: false,
        filterChannels: [],
        channelColors: {
          0: call.incoming_call ? green : blue,
          1: call.incoming_call ? blue : green
        }
      }
    });
    this.waveform.load(this.props.call.recording_url);
    this.waveform.on('finish', () => {
      this.setState({ playing: false });
    });
    this.waveform.on('ready', () => {
      const totalTime = this.waveform.getDuration();
      this.setState({ totalTime });
    });
    this.waveform.on('seek', () => {
      const currentTime = this.waveform.getCurrentTime();
      this.setState({ currentTime });
    });
    this.waveform.on('audioprocess', () => {
      if (this.waveform.isPlaying()) {
        const currentTime = this.waveform.getCurrentTime();
        this.setState({ currentTime });
      }
    });
  };
  destroyWaveform = () => {
    if (this.waveform) {
      this.waveform.destroy();
      this.waveform = null;
    }
  };
  UNSAFE_componentWillMount = () => this.destroyWaveform();
  handlePlay = () => {
    const playing = !this.state.playing;
    this.setState({ playing });
    if (playing) {
      this.waveform.play();
    } else {
      this.waveform.pause();
    }
  };
  skip = amount => this.waveform.skip(amount);
  seek = seconds => this.waveform.seekTo((seconds / 1000 + 0.01) / this.state.totalTime);
  whichScript = (record, index) => {
    const startTime = record.start / 1000;
    const stopTime = record.stop / 1000;
    const currentTime = this.state.currentTime;
    const rowType = record.isAgent ? ' row-agent' : '';
    let rowActive = '';
    if (currentTime === 0 && index === 0) rowActive = 'row-active ';
    if (currentTime >= startTime && currentTime <= stopTime) rowActive = 'row-active ';
    return rowActive + rowType;
  };
  toggleRecording = e => {
    if (e.target?.className?.includes('rhap_progress-bar')) {
      return;
    }
    if (this.state.recording) {
      this.props.resetOpened();
      this.setState({ recording: false, transcript: false }, this.destroyWaveform);
    } else {
      this.setState({ recording: true }, this.buildWaveform);
    }
  };
  render() {
    const { call, lead, source, opened, otherId, selectCall } = this.props;
    const { playing, totalTime, transcript, recording, currentTime } = this.state;
    const leadName = lead.first_name + ' ' + lead.last_name;
    const leadPicture = (
      <Avatar
        type="lead"
        size="tiny"
        picture={lead.photo_url}
        firstCharacter={(lead.first_name || '?').charAt(0).toUpperCase()}
        lastCharacter={(lead.last_name || '?').charAt(0).toUpperCase()}
      />
    );
    const agentName = source.display_name;
    const agentPicture = <Avatar size="tiny" picture={source.picture} />;
    const ticks = Math.ceil(totalTime / 10);

    const hasRecording = call.recording_url ? true : false;

    return (
      <Card
        id={'scroll-' + call.call_id}
        className={`selected-call ${!hasRecording ? 'disabled' : ''}`}
        onClick={selectCall && hasRecording ? () => selectCall(call.id) : null}
      >
        <div className="lead-call">
          <div
            className={
              'call-icon' +
              (call.incoming_call ? ' incoming' : '') +
              (call.status === 'missed' && call.incoming_call ? ' missed' : '')
            }
          >
            {call.status === 'missed' && call.incoming_call ? (
              <IconPhoneX />
            ) : call.incoming_call ? (
              <IconPhoneIncoming />
            ) : (
              <IconPhoneOutgoing />
            )}
          </div>
          <div className="call-info">
            <h3>
              {call.incoming_call
                ? call.status === 'missed'
                  ? 'Missed Call'
                  : 'Incoming Call'
                : 'Outgoing Call'}
            </h3>
            <h4>{moment(call.createdAt * 1).format('dddd, MMMM Do h:mma')}</h4>
          </div>
          {hasRecording && !recording ? (
            <div className="player init-player">
              <AudioPlayer
                layout="horizontal"
                showJumpControls={false}
                customAdditionalControls={[]}
                customVolumeControls={[]}
                customProgressBarSection={[null, RHAP_UI.PROGRESS_BAR, RHAP_UI.CURRENT_LEFT_TIME]}
                src={call.recording_url}
              />
            </div>
          ) : null}
        </div>
        <div className="call-which">
          <Tooltip title={prettyPhone(call.to_number)}>
            <Tag>{getLeadPhone(lead, call.to_number)}</Tag>
          </Tooltip>
        </div>
        {hasRecording && (recording || (!opened && otherId === call.call_id)) ? (
          <div className="wave">
            <div className="wave-holder">
              <div className="callers">
                <div className="caller-one">
                  {call.incoming_call ? leadPicture : agentPicture}
                  <div className="caller-info">
                    <h4>{call.incoming_call ? leadName : agentName}</h4>
                    <h5>{call.incoming_call ? 'Lead' : 'Sales Rep'}</h5>
                  </div>
                </div>
                <div className="caller-two">
                  {call.incoming_call ? agentPicture : leadPicture}
                  <div className="caller-info">
                    <h4>{call.incoming_call ? agentName : leadName}</h4>
                    <h5>{call.incoming_call ? 'Sales Rep' : 'Lead'}</h5>
                  </div>
                </div>
              </div>
              <div className="wave-container">
                <div className="wave-scroll">
                  <div id={'waveform-' + call.id} className="waveform-div">
                    {[...Array(ticks)].map((e, i) => (
                      <div key={'tick-' + i} className="tick" style={{ left: i * 150 }}>
                        <span>{i * 10}</span>
                      </div>
                    ))}
                    <div id="bg-one" />
                    <div id="bg-two" />
                    {ticks > 0 ? (
                      <Icons.ArrowDown id="arrow" style={{ left: currentTime * 15 - 7 }} />
                    ) : null}
                  </div>
                </div>
                <div className="controls">
                  <div className="play" onClick={this.handlePlay}>
                    <FontAwesomeIcon icon={playing ? faPause : faPlay} />
                  </div>
                  <Button type="default" icon="left" size="tiny" onClick={() => this.skip(-5)}>
                    <FontAwesomeIcon className="btnx-left" icon={faUndoAlt} />
                    5s
                  </Button>
                  <Button type="default" icon="right" size="tiny" onClick={() => this.skip(5)}>
                    5s
                    <FontAwesomeIcon className="btnx-left" icon={faRedoAlt} />
                  </Button>
                  <div className="current">
                    <span id="time-currentTime">{timeFormat(currentTime)}</span>/
                    <span id="time-totalTime">{timeFormat(totalTime)}</span>
                  </div>
                  <div className="right-controls">
                    {false && (
                      <div
                        className="sxd-link"
                        onClick={() => this.setState({ transcript: !transcript })}
                      >
                        Transcript
                        <FontAwesomeIcon icon={transcript ? faCaretUp : faCaretDown} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {transcript ? (
          <div className="transcript">
            <Table
              rowClassName={this.whichScript}
              onRow={record => ({ onClick: () => this.seek(record.start) })}
              dataSource={call.transcription.map((d, i) => {
                d.key = 't-col-' + i;
                return d;
              })}
              columns={getColumns(call, agentName, leadName)}
              pagination={false}
              scroll={{ y: 360 }}
            />
          </div>
        ) : null}
      </Card>
    );
  }
}
