import React, { Component } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { numberWithCommas } from '_assets/js/helpers';
import { Card, Header } from '_seriph';
import './StatusChart.scss';

export default class StatusChart extends Component {
  custom = data => {
    const payload = data.payload || [];
    return (
      <div className="sellx-tooltip">
        <div className="tip-box">
          <div className="tip-title">{data.label}</div>
          <div className="tip-contents">
            {payload.map((p, i) => (
              <div key={`tip-${i}`} className="tip-item">
                <div className="tip-circle" style={{ backgroundColor: p.stroke }} />
                <div className="tip-name">{p.name}</div>
                <div className="tip-value">{numberWithCommas(p.value) || '0'}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  getStatusUp = data => {
    const dataUp = JSON.parse(JSON.stringify(data));
    dataUp.forEach(col => {
      for (let i = 1; i < col.data.length; i++) {
        const last = col.data[i - 1];
        col.data[i].value = last.value + col.data[i].value;
      }
    });
    return dataUp;
  };
  render() {
    const { totals, data } = this.props;

    const tickCount = data && data.length > 0 ? data[0].data.length : 0;

    const interval = tickCount > 7 ? Math.ceil(tickCount / 7) : 0;

    const dataUp = this.getStatusUp(data);

    return (
      <div className="chart-box">
        <Header size="3" font="sans">
          Lead activity
        </Header>
        <Card id="agent-status-chart">
          <div className="dash-data">
            <div className="dash-card dash-sts dc-1">
              <div>
                <span className="in-progress" />
                Created
              </div>
              <h2>{numberWithCommas(totals.in_progress) || '0'}</h2>
            </div>
            <div className="dash-card dash-sts dc-1">
              <div>
                <span className="review" />
                Submitted
              </div>
              <h2>{numberWithCommas(totals.review) || '0'}</h2>
            </div>
            <div className="dash-card dash-sts dc-1">
              <div>
                <span className="generated" />
                Generated
              </div>
              <h2>{numberWithCommas(totals.generated) || '0'}</h2>
            </div>
            <div className="dash-card dash-sts dc-1">
              <div>
                <span className="qualified" />
                Qualified
              </div>
              <h2>{numberWithCommas(totals.qualified) || '0'}</h2>
            </div>
            <div className="dash-card dash-sts dc-1">
              <div>
                <span className="not-interested" />
                Not Interested
              </div>
              <h2>{numberWithCommas(totals.not_interested) || '0'}</h2>
            </div>
            <div className="dash-card dash-sts dc-1">
              <div>
                <span className="disputed" />
                Disputed
              </div>
              <h2>{numberWithCommas(totals.disputed) || '0'}</h2>
            </div>
          </div>
          <div className="status-row">
            <div>
              <ResponsiveContainer height={260} width="99%">
                <LineChart margin={{ right: 32 }}>
                  <CartesianGrid stroke="#E6E9EF" strokeDasharray="0 0" vertical={false} />
                  <XAxis
                    interval={interval}
                    tickLine={false}
                    axisLine={false}
                    dataKey="name"
                    type="category"
                    allowDuplicatedCategory={false}
                  />
                  <YAxis
                    tickLine={false}
                    axisLine={false}
                    dataKey="value"
                    domain={[0, 'dataMax + 2']}
                    allowDecimals={false}
                  />
                  <Tooltip content={this.custom} />
                  {data.map(s => (
                    <Line
                      dataKey="value"
                      data={s.data}
                      name={s.name}
                      key={s.name}
                      {...s.settings}
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div>
              <ResponsiveContainer height={260} width="99%">
                <LineChart margin={{ right: 32 }}>
                  <CartesianGrid stroke="#E6E9EF" strokeDasharray="0 0" vertical={false} />
                  <XAxis
                    interval={interval}
                    tickLine={false}
                    axisLine={false}
                    dataKey="name"
                    type="category"
                    allowDuplicatedCategory={false}
                  />
                  <YAxis
                    tickLine={false}
                    axisLine={false}
                    dataKey="value"
                    domain={[0, 'dataMax + 2']}
                    allowDecimals={false}
                  />
                  <Tooltip content={this.custom} />
                  {dataUp.map(s => (
                    <Line
                      dataKey="value"
                      data={s.data}
                      name={s.name}
                      key={s.name}
                      {...s.settings}
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}
