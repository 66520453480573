import moment from 'moment';

export const generateData = (data, previous) => {

  const chartData = {
    vs: [
      {
        name: 'Current Tasks',
        settings: { stroke: '#9E9BD8', strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Previous Week',
        settings: { stroke: '#C4CBD9', strokeWidth: 1, dot: false, strokeDasharray: '5 5' },
        data: []
      }
    ],
    totals: {}
  };
  if (!data) return chartData;

  const weekdayData = [...data];
  weekdayData.shift();
  weekdayData.pop();

  const previousData = [...previous];
  previousData.shift();
  previousData.pop();

  let zeroData = true;

  if (weekdayData && weekdayData.length > 0) {
    const totals = { ...weekdayData[0].daily };
    weekdayData.forEach((d, i) => {
      /* Totals */
      if (i > 0) Object.keys(d.daily).forEach(s => (totals[s] += d.daily[s]));
      const p = previousData[i];

      const hasHappened = moment(d.date.toString()).isSameOrBefore(moment());

      /* Activity Chart */
      if (hasHappened) {
        chartData.vs[0].data.push({
          month: moment(d.date.toString()).format('MM YY'),
          name: moment(d.date.toString()).format('dddd'),
          call_total: d.daily.touchpoint_call,
          email_total: d.daily.touchpoint_email,
          linkedin_total: d.daily.touchpoint_linkedin,
          value: d.daily.touchpoint_total,
        });
      }
      chartData.vs[1].data.push({
        month: moment(d.date.toString()).format('MM YY'),
        name: moment(d.date.toString()).format('dddd'),
        call_total: p.daily.touchpoint_call,
        email_total: p.daily.touchpoint_email,
        linkedin_total: p.daily.touchpoint_linkedin,
        value: p.daily.touchpoint_total,
      });

      if (p.daily.touchpoint_total > 0 || d.daily.touchpoint_total > 0) {
        zeroData = false;
      }

    });
    chartData.totals = totals;
    chartData.zeroData = zeroData;
  }

  return chartData;
};
