import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getImports } from '_graphql/queries/campaign';
import { getCampaignSequences } from '_graphql/queries/sequence';
import { validate } from '../../_helpers/flows';
import { getFlow } from '../../_helpers/flows';
import Launch from './_modals/Launch/Launch';
import { Loading, Card, Button } from '_seriph';
import { IconX, IconCheck, IconRocket } from '@tabler/icons-react';
import './Review.scss';

class Review extends Component {
  state = {
    loadingImports: true,
    loadingSequences: true,
    launchModal: false,
    importData: null,
    sequences: []
  };
  componentDidMount = () => {
    if (this.props.save) this.props.save();
    this.loadImports();
    this.loadSequences();
  };
  loadImports = () => {
    this.props.client
      .query({ variables: { campaign_id: this.props.campaignId }, query: getImports })
      .then(result => {
        if (result && result.data && result.data.imports) {
          const imports = result.data.imports || [];
          if (imports && imports.length > 0) {
            this.setState({ importData: imports[0], loadingImports: false });
          } else {
            this.setState({ loadingImports: false });
          }
        }
      });
  };
  loadSequences = () => {
    this.props.client
      .query({ variables: { campaign_id: this.props.campaignId }, query: getCampaignSequences })
      .then(result => {
        if (result && result.data && result.data.getCampaignSequences) {
          const sequences = result.data.getCampaignSequences || [];
          this.setState({ sequences, loadingSequences: false });
        }
      });
  };
  showLaunchModal = () => this.setState({ launchModal: true });
  removeLaunchModal = () => this.setState({ launchModal: false });
  render() {
    const { campaign, campaignId, actions, edited, isLocked } = this.props;
    const { importData, sequences } = this.state;

    const loading = this.state.loadingImports || this.state.loadingSequences;

    const validation = validate(campaign, importData, sequences);
    const flow = getFlow(campaign);

    return (
      <Card id="wizard-review" className="wz-card">
        <div className="wz-container sx-form">
          {/* HEADER */}
          <div className="wz-card-header">
            {isLocked ? (
              <React.Fragment>
                <h3>Campaign launched!</h3>
                <h5>Review your current settings for the active campaign</h5>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h3>
                  {(validation && validation.isValid && !validation.isWarned) ||
                    (loading && "Let's review your campaign before launch")}

                  {validation && !validation.isValid
                    ? `Oops, ${validation.invalidCount} item${
                        validation.invalidCount === 1 ? ' is' : 's are'
                      } missing info`
                    : null}

                  {validation && validation.isValid && validation.isWarned
                    ? `Warning, ${validation.warningCount} item${
                        validation.invalidCount === 1 ? ' is' : 's may'
                      } need to be reviewed before launch`
                    : null}
                </h3>
                <h5>
                  {(validation && validation.isValid) || loading
                    ? 'Review your campaign and make any necessary adjustments'
                    : 'Review your campaign and make any necessary adjustments'}
                </h5>
              </React.Fragment>
            )}
          </div>

          {/* CONTENT */}
          {loading ? (
            <Loading />
          ) : (
            <div className="reviews">
              {!isLocked && validation.isValid === true ? (
                <div className="launch">
                  <Button
                    disabled={edited}
                    type="primary"
                    size="large"
                    icon="right"
                    onClick={this.showLaunchModal}
                  >
                    Launch Campaign <IconRocket />
                  </Button>
                </div>
              ) : null}

              {flow.map((f, i) => {
                const vObj = validation[f.val];
                return vObj ? (
                  <div key={'flow-' + i} className="review">
                    <div className="review-header">
                      <div className={`outcome ${vObj.isValid || isLocked ? '' : 'invalid'}`}>
                        {vObj.isValid || isLocked ? <IconCheck /> : <IconX />}
                      </div>
                      <div className={`review-title ${vObj.isValid || isLocked ? '' : 'invalid'}`}>
                        {vObj.label}
                      </div>
                      {!isLocked ? (
                        <Link
                          to={{
                            pathname: `/wizard/${campaignId}/${f.val}`,
                            state: { fromReview: true }
                          }}
                        >
                          <Button type="secondary">Edit</Button>
                        </Link>
                      ) : null}
                    </div>
                    <div className="info">
                      {vObj.values.map((v, i) => {
                        const desc = Array.isArray(v) ? v[0] : 'Value';
                        const value = Array.isArray(v) ? v[1] : v;
                        return (
                          <div className="info-list" key={'info-val-' + i}>
                            <div>{desc}</div>
                            <div>{value}</div>
                          </div>
                        );
                      })}
                      {vObj.missing.map((v, i) => {
                        const desc = Array.isArray(v) ? v[0] : 'Issue';
                        const value = Array.isArray(v) ? v[1] : v;
                        return (
                          <div className="info-list missing" key={'info-missing-' + i}>
                            <div>{desc}</div>
                            <div>{value}</div>
                          </div>
                        );
                      })}
                      {vObj.warning.map((v, i) => {
                        const desc = Array.isArray(v) ? v[0] : 'Warning';
                        const value = Array.isArray(v) ? v[1] : v;
                        return (
                          <div className="info-list warning" key={'info-warning-' + i}>
                            <div>{desc}</div>
                            <div>{value}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null;
              })}
            </div>
          )}
        </div>

        {/* ACTIONS */}
        {actions}

        {this.state.launchModal ? (
          <Launch
            client={this.props.client}
            importData={importData}
            campaign={campaign}
            campaignId={campaignId}
            removeModal={this.removeLaunchModal}
          />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(Review));
