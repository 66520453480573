import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { IconChevronRight } from '@tabler/icons-react';
import Meetings from './_components/Meetings/Meetings';
import Leaderboard from './_components/Leaderboard/Leaderboard';
import CampaignStats from './_components/CampaignStats/CampaignStats';
import CompanyStats from './_components/CompanyStats/CompanyStats';
import RepStats from './_components/RepStats/RepStats';
import Tasks from './_components/Tasks/Tasks';
import Scorecard from './_components/Scorecard/Scorecard';
import Trigger from './_components/Trigger/Trigger';
import './Analytics.scss';

const MenuTab = props => (
  <Link to={`/analytics/${props.page}`} className={`menu-tab ${props.active ? 'active' : ''}`}>
    {props.children}
    <IconChevronRight />
  </Link>
);

class Analytics extends Component {
  state = {
    page: this.props.page || 'tasks'
  };
  scrollRef = null;
  componentDidUpdate = prevProps => {
    const prevId = prevProps.page;
    const newId = this.props.page;
    if (prevId !== newId)
      this.setState({ page: newId }, () => {
        this.scrollRef.scrollTo({ top: 0 });
      });
  };
  render() {
    const { page } = this.state;

    const pageProps = {
      client: this.props.client,
      campaigns: this.props.campaigns
    };

    return (
      <div id="company-analytics">
        <div className="analytics-menu">
          <div className="menu-title">
            <span>Sales Rep Analytics</span>
          </div>
          <div className="menu-tabs">
            <MenuTab active={page === 'tasks'} page="tasks">
              Tasks Completed
            </MenuTab>
            <MenuTab active={page === 'campaignstats'} page="campaignstats">
              Campaign Stats
            </MenuTab>
            <MenuTab active={page === 'companystats'} page="companystats">
              Company Stats
            </MenuTab>
            <MenuTab active={page === 'repstats'} page="repstats">
              XDR Stats
            </MenuTab>
            <MenuTab active={page === 'leaderboard'} page="leaderboard">
              XDR Leaderboard
            </MenuTab>
          </div>
          <div className="menu-title menu-space">
            <span>Overview Analytics</span>
          </div>
          <div className="menu-tabs">
            <MenuTab active={page === 'scorecard'} page="scorecard">
              XDR Scorecard
            </MenuTab>
            <MenuTab active={page === 'trigger'} page="trigger">
              Trigger Alerts
            </MenuTab>
          </div>
          <div className="menu-title menu-space">
            <span>Information</span>
          </div>
          <div className="menu-tabs">
            <MenuTab active={page === 'meetings'} page="meetings">
              Scheduled Meetings
            </MenuTab>
          </div>
        </div>

        <div className="analytics-main" ref={el => (this.scrollRef = el)}>
          {page === 'leaderboard' && <Leaderboard {...pageProps} />}
          {page === 'tasks' && <Tasks {...pageProps} />}
          {page === 'campaignstats' && <CampaignStats {...pageProps} />}
          {page === 'companystats' && <CompanyStats {...pageProps} />}
          {page === 'repstats' && <RepStats {...pageProps} />}
          {page === 'meetings' && <Meetings {...pageProps} />}
          {page === 'scorecard' && <Scorecard {...pageProps} />}
          {page === 'trigger' && <Trigger {...pageProps} />}
        </div>
      </div>
    );
  }
}

export default withRouter(Analytics);
