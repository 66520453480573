export default [
  {
    slug: 'in_progress',
    key: 'in-progress',
    name: 'In-progress'
  },
  {
    slug: 'review',
    key: 'review',
    name: 'Pending Review'
  },
  {
    slug: 'disputed',
    name: 'Disputed',
    key: 'disputed'
  },
  {
    slug: 'completed',
    name: 'Complete',
    key: 'completed'
  },
  {
    slug: 'improper',
    key: 'improper',
    name: 'Not interested / Invalid'
  }
];
