import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Badge } from 'antd';
import { TabMenu, Button, Tooltip, message } from '_seriph';
import Script from './_components/Script/Script';
import Questions from './_components/Questions/Questions';
import Benefits from './_components/Benefits/Benefits';
import Instructions from './_components/Instructions/Instructions';
import Objections from './_components/Objections/Objections';
import LeadInfo from './_components/LeadInfo/LeadInfo';
import Notes from './_components/Notes/Notes';
import CalendarModal from 'App/Company/Leads/Lead/_modals/CalendarModal/CalendarModal';
import ChilipiperModal from 'App/Company/Leads/Lead/_modals/ChilipiperModal/ChilipiperModal';
import { LeadTag } from 'App/Company/Leads/Lead/_components/LeadTag/LeadTag';
import { IconNote, IconBrandLinkedin, IconUser } from '@tabler/icons-react';
import { getLead } from '_graphql/queries/lead';
import { getErrors } from '_assets/js/helpers';
import './CallInformation.scss';

const { TabPane } = TabMenu;

class CallInformation extends Component {
  state = {
    currentTab: 'script',
    slideOut: null,
    closeActions: null,
    calendar: null
  };
  reloadTags = leadId => {
    this.props.client
      .query({ variables: { id: leadId }, query: getLead })
      .then(result => {
        if (result?.data?.lead) {
          const tags = result.data.lead?.tags || [];
          this.props.tagsUpdated(leadId, tags);
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Error updating tags, try again.');
      });
  };
  showCalendarModal = calendar => this.setState({ calendar });
  removeCalendarModal = () => this.setState({ calendar: false });
  changeTab = key => this.setState({ currentTab: key });
  goToLinkedIn = current => window.open(current?.linkedin, '_blank').focus();
  toggleSlideout = slideOut => {
    if (slideOut) {
      this.setState({ slideOut, closeActions: true });
    } else {
      this.setState({ slideOut: null }, () => {
        setTimeout(() => this.setState({ closeActions: false }), 300);
      })
    }
  };
  render() {
    const { slideOut, closeActions } = this.state;
    const { campaign, currentLead } = this.props;

    const currentTask = currentLead?.current_task;
    const integration = campaign?.integration;

    const useCalendly = campaign.calendly && integration?.calendly?.url ? true : false;
    const useChilipiper = campaign.chilipiper && integration?.chilipiper?.domain ? true : false;
    const meetingScheduled = currentLead?.meetingScheduled;

    return (
      <div id="call-information">

        <div className="meeting-actions">
          { useCalendly && !meetingScheduled ? (
            <Button 
              type="secondary" 
              size="tiny"
              onClick={() => this.showCalendarModal('calendly')}
            >
              Set meeting
            </Button>
          ) : null }
          { useChilipiper && !meetingScheduled ? (
            <Button 
              type="secondary" 
              size="tiny"
              onClick={() => this.showCalendarModal('chilipiper')}
            >
              Set meeting
            </Button>
          ) : null }
          { meetingScheduled ? (
            <Button type="secondary" size="tiny" disabled={true}>
              Meeting Scheduled
            </Button>
          ) : null }
        </div>

        <div className={`general-actions ${closeActions ? 'opened' : ''}`}>
          <Tooltip title="Lead Info" placement="left">
            <Button circle onClick={() => this.toggleSlideout('info')}>
              <IconUser />
            </Button>
          </Tooltip>
          <Tooltip title="Notes" placement="left">
            <Badge count={currentLead.notes_count} offset={[-5, 5]}>
              <Button circle onClick={() => this.toggleSlideout('notes')}>
                <IconNote />
              </Button>
            </Badge>
          </Tooltip>
          {currentLead?.linkedin ? (
            <Tooltip title="LinkedIn" placement="left">
              <Button circle onClick={() => this.goToLinkedIn(currentLead)}>
                <IconBrandLinkedin />
              </Button>
            </Tooltip>
          ) : null}
          <LeadTag
            manageOnly={true}
            lead={currentLead}
            client={this.props.client}
            reload={() => this.reloadTags(currentLead.id)}
          />
        </div>

        <div className={`slideout ${slideOut ? 'opened' : ''}`}>
          {slideOut === 'notes' && (
            <Notes
              lead={currentLead}
              client={this.props.client}
              close={() => this.toggleSlideout(null)}
              notesUpdated={this.props.notesUpdated}
            />
          )}
          {slideOut === 'info' && (
            <LeadInfo
              lead={currentLead}
              client={this.props.client}
              close={() => this.toggleSlideout(null)}
            />
          )}
        </div>


        <TabMenu animated={false} defaultActiveKey="script" onChange={this.changeTab}>
          <TabPane tab="Call script" key="script">
            <div className="pane-box">
              <Script 
                script={currentTask?.touchpoint?.content}
                campaign={this.props.campaign}
                agent={this.props.agent}
                lead={currentLead}
              />
            </div>
          </TabPane>
          <TabPane tab="Instructions" key="instructions">
            <div className="pane-box"><Instructions campaign={campaign} /></div>
          </TabPane>
          <TabPane tab="Questions" key="questions">
            <div className="pane-box"><Questions lead={currentLead} questions={campaign?.questions} /></div>
          </TabPane>
          <TabPane tab="Key benefits" key="benefits">
            <div className="pane-box"><Benefits benefits={campaign?.benefits} /></div>
          </TabPane>
          <TabPane tab="Objections" key="objections">
            <div className="pane-box"><Objections objections={campaign?.objections} /></div>
          </TabPane>
        </TabMenu>


        {this.state.calendar === 'calendly' && useCalendly ? (
          <CalendarModal
            client={this.props.client}
            user={this.props.user}
            calendly={integration && integration?.calendly?.url && campaign?.calendly}
            lead={currentLead}
            reload={this.props.meetingScheduled}
            campaign={campaign}
            removeModal={this.removeCalendarModal}
          />
        ) : null}

        {this.state.calendar === 'chilipiper' && useChilipiper ? (
          <ChilipiperModal
            client={this.props.client}
            user={this.props.user}
            lead={currentLead}
            reload={this.props.meetingScheduled}
            campaign={campaign}
            removeModal={this.removeCalendarModal}
          />
        ) : null}

      </div>
    );
  }
}

const mapStateToProps = state => ({ ...state.user });

export default withRouter(connect(mapStateToProps, {})(CallInformation));