export const logoutUser = callback => ({
  type: 'LOGOUT_USER',
  payload: callback
});

export const loginUser = jwt => ({
  type: 'LOGIN_USER',
  payload: { jwt }
});

export const updateUser = attribute => ({
  type: 'UPDATE_USER',
  payload: { attribute }
});

export const updateRole = role => ({
  type: 'UPDATE_ROLE',
  payload: { role }
});
