import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Header } from '_seriph';
import AddBox from '_shared/AddBox/AddBox';
import Spacer from '_shared/Spacer/Spacer';
import EducationEdit from './EducationEdit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import './Education.scss';

const School = props => {
  const gpa = props.data.gpa ? `| ${props.data.gpa} GPA` : '';
  const startDate = moment.unix(props.data.start_date).format('YYYY');
  const endDate = moment.unix(props.data.end_date).format('YYYY');
  return (
    <React.Fragment>
      <Header font="sans" size="4">
        {props.data.school}
        <span className="education-dates">
          {' '}
          | {startDate} - {endDate}
        </span>
      </Header>
      <div className="education-info">
        {props.data.degree}
        {gpa ? <span>&nbsp;&middot;&nbsp;{gpa}</span> : null}
      </div>
      <p className="education-description">{props.data.description}</p>
    </React.Fragment>
  );
};

const emptyForm = {
  school: '',
  degree: '',
  field: '',
  description: '',
  gpa: 0,
  start_date: null,
  end_date: null
};
class Education extends Component {
  state = { editor: false };
  state = {
    editor: false,
    selected: null,
    form: {}
  };
  componentDidMount = () => this.setState({ form: emptyForm });
  updateForm = (field, value) => {
    const form = Object.assign({}, this.state.form);
    form[field] = value;
    this.setState({ form });
  };
  openEditor = id => {
    if (id) {
      const education = this.props.agent.education.find(a => a.id === id);
      this.setState({ editor: true, selected: education.id, form: education });
    } else {
      this.setState({ editor: true, selected: null, form: emptyForm });
    }
  };
  closeEditor = () => this.setState({ editor: false, selected: null, form: emptyForm });
  render() {
    const { agent, isEditable } = this.props;
    const education = agent.education || [];
    const addBox = isEditable && education.length === 0;
    return (
      <Card id="agent-profile-education">
        {isEditable ? (
          <EducationEdit
            form={this.state.form}
            updateForm={this.updateForm}
            visible={this.state.editor}
            close={this.closeEditor}
            reload={this.props.reload}
            editing={this.state.selected}
          />
        ) : null}
        {!addBox && <h2>Education</h2>}
        {!addBox && (
          <React.Fragment>
            {education.map((e, i) => (
              <div className="education-box" key={'e-' + e.id}>
                <School data={e} /> {i + 1 < education.length ? <Spacer /> : null}
                {isEditable && (
                  <Button
                    type="secondary"
                    className="btnx-edit"
                    size="tiny"
                    icon="left"
                    onClick={() => this.openEditor(e.id)}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                    Edit
                  </Button>
                )}
              </div>
            ))}
          </React.Fragment>
        )}
        {isEditable && (
          <Button
            type="secondary"
            className="btnx-edit"
            size="tiny"
            icon="left"
            onClick={() => this.openEditor()}
          >
            <FontAwesomeIcon icon={faPlus} />
            Education
          </Button>
        )}
        {this.props.profile && education.length === 0 && !isEditable ? (
          <i className="none-yet">No education listed</i>
        ) : null}
        {addBox ? (
          <AddBox action={this.openEditor} title="Education" percent={this.props.value} />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user, ...state.agent };
};

export default connect(mapStateToProps)(Education);
