import React from 'react';
import { Tag } from '_seriph';
import { formatMoney } from '_assets/js/helpers';
import moment from 'moment';

export const getColumns = () => {
  return [

    {
      title: 'Type',
      dataIndex: 'type',
      render: type => {
        if (type === 'weekly') {
          return <Tag type="primary">Weekly</Tag>;
        } else if (type === 'bonus') {
          return <Tag type="warning">Bonus</Tag>;
        } else if (type === 'meeting') {
          return <Tag type="info">Lead Qualified</Tag>;
        } else if (type === 'lead') {
          return <Tag>Lead Submitted</Tag>;
        }
        return <Tag>{type}</Tag>;
      }
    },
    {
      title: 'Description',
      dataIndex: 'description'
    },
    {
      title: 'Amount Earned',
      dataIndex: 'payout_amount',
      render: payout_amount => {
        return formatMoney(payout_amount);
      }
    },
    {
      title: 'Paid',
      dataIndex: 'createdAt',
      render: createdAt => {
        return moment(createdAt * 1).format('M/D/YY');
      }
    },
  ];
};
