import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { addCalendly, removeCalendly } from '_graphql/mutations/integrations';
import queryString from 'query-string';
import { getErrors } from '_assets/js/helpers';
import CalendlyLogo from '_assets/brands/calendly.png';
import { Card, Button, message } from '_seriph';
import { IconCheck, IconX, IconPlus } from '@tabler/icons-react';
import './Calendly.scss';

class Calendly extends Component {
  state = {
    setting: null
  };
  componentDidMount = () => {
    const params = queryString.parse(this.props.location.search);
    if (params.type === 'calendly' && params.refresh && params.token) this.addCalendly(params);
  };
  addCalendly = params => {
    this.setState({ setting: 'calendly' });
    this.props.client
      .mutate({
        variables: { token: params.token, refresh: params.refresh },
        mutation: addCalendly
      })
      .then(result => {
        if (result && result.data && result.data.addCalendly) {
          window.history.replaceState(null, null, window.location.pathname);
          setTimeout(() => this.props.loadIntegrations(this.resetSetting), 100);
          message.success('Calendly successfully integrated');
        }
      })
      .catch(err => {
        this.setState({ setting: null });
        message.error(getErrors(err) || 'Error setting up calendly, try again');
      });
  };
  removeCalendly = () => {
    this.setState({ setting: 'calendly' });
    this.props.client
      .mutate({
        variables: { refresh: this.props.integrations.calendly.refresh_token },
        mutation: removeCalendly
      })
      .then(result => {
        if (result && result.data && result.data.removeCalendly) {
          setTimeout(() => this.props.loadIntegrations(this.resetSetting), 100);
          message.success('Calendly successfully removed');
        }
      })
      .catch(err => {
        this.setState({ setting: null });
        message.error(getErrors(err) || 'Error removing calendly, try again');
      });
  };
  calendly = () => {
    const base = 'https://auth.calendly.com/oauth/';
    const companyId = this.props.myself.company;
    window.location.href = `${base}authorize?client_id=${process.env.REACT_APP_CALENDLY_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_API_PATH}/auth/calendly&company_id=${companyId}`;
  };
  resetSetting = () => this.setState({ setting: null });
  render() {
    const { setting } = this.state;
    const { integrations, loading } = this.props;
    return (
      <Card id="calendly">
        <div className="int">
          <div className="int-header">
            <div className="int-logo">
              <img src={CalendlyLogo} title="Calendly" alt="Calendly" />
            </div>
            <h3 className="int-name">
              Calendly
              { integrations.calendly.url && <p>Account: {integrations.calendly.url}</p> }
            </h3>
            {integrations.calendly.url && !loading && (
              <div className="int-check">
                <IconCheck />
              </div>
            )}
          </div>
          <p className="int-desc">Calendly lets agents schedule meetings with qualified leads directly on your calendar</p>
          <div className="int-actions">
            {(!integrations.calendly.url || loading) && (
              <Button
                disabled={loading}
                type="secondary"
                icon="left"
                loading={setting === 'calendly'}
                onClick={this.calendly}
              >
                <IconPlus />
                Connect to Calendly
              </Button>
            )}
            {integrations.calendly.url && !loading && (
              <Button
                type="danger"
                icon="left"
                loading={setting === 'calendly'}
                onClick={this.removeCalendly}
              >
                <IconX />
                Disconnect
              </Button>
            )}
          </div>
        </div>
      </Card>
    );
  }
}

export default withRouter(Calendly);
