import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Header } from '_seriph';
import AddBox from '_shared/AddBox/AddBox';
import Spacer from '_shared/Spacer/Spacer';
import ExperienceEdit from './ExperienceEdit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import './Experience.scss';

const Position = props => {
  const startDate = moment.unix(props.data.start_date).format('MMM YYYY');
  const endDate = moment.unix(props.data.end_date).format('MMM YYYY');
  return (
    <React.Fragment>
      <Header font="sans" size="4">
        {props.data.title}
      </Header>
      <div className="position-info">
        {props.data.company}&nbsp;&middot;&nbsp;
        <span>
          {startDate} - {props.data.current ? 'Present' : endDate}
        </span>
      </div>
      <p className="position-description">{props.data.description}</p>
    </React.Fragment>
  );
};

const emptyForm = {
  company: '',
  title: '',
  description: '',
  start_date: null,
  end_date: null,
  current: true
};
class Experience extends Component {
  state = {
    editor: false,
    selected: null,
    form: {}
  };
  componentDidMount = () => this.setState({ form: emptyForm });
  updateForm = (field, value) => {
    const form = Object.assign({}, this.state.form);
    form[field] = value;
    this.setState({ form });
  };
  openEditor = id => {
    if (id) {
      const experience = this.props.agent.experiences.find(a => a.id === id);
      this.setState({ editor: true, selected: experience.id, form: experience });
    } else {
      this.setState({ editor: true, selected: null, form: emptyForm });
    }
  };
  closeEditor = () => this.setState({ editor: false, selected: null, form: emptyForm });
  render() {
    const { editor } = this.state;
    const { agent, isEditable } = this.props;
    const experiences = agent.experiences || [];
    const addBox = isEditable && experiences.length === 0;
    return (
      <Card id="agent-profile-experience">
        {isEditable ? (
          <ExperienceEdit
            form={this.state.form}
            updateForm={this.updateForm}
            visible={editor}
            close={this.closeEditor}
            reload={this.props.reload}
            editing={this.state.selected}
          />
        ) : null}
        {!addBox && <h2>Job Experience</h2>}
        {!addBox && (
          <React.Fragment>
            {experiences.map((e, i) => (
              <div className="position-box" key={'e-' + e.id}>
                <Position data={e} /> {i + 1 < experiences.length ? <Spacer /> : null}
                {isEditable && (
                  <Button
                    type="secondary"
                    className="btnx-edit"
                    size="tiny"
                    icon="left"
                    onClick={() => this.openEditor(e.id)}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                    Edit
                  </Button>
                )}
              </div>
            ))}
          </React.Fragment>
        )}
        {isEditable && (
          <Button
            type="secondary"
            className="btnx-edit"
            size="tiny"
            icon="left"
            onClick={() => this.openEditor()}
          >
            <FontAwesomeIcon icon={faPlus} />
            Experience
          </Button>
        )}
        {this.props.profile && experiences.length === 0 && !isEditable ? (
          <i className="none-yet">No experience listed</i>
        ) : null}
        {addBox ? (
          <AddBox action={this.openEditor} title="Experiences" percent={this.props.value} />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user, ...state.agent };
};

export default connect(mapStateToProps)(Experience);
