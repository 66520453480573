import React, { Component } from 'react';
import { Modal, Header, Select, TextArea, Input, Button, message } from '_seriph';
import './RequestIntegration.scss';
import { submitIntegration } from 'src/Mutations/User/user';
import integrationCategories from 'src/_constants/integration-categories';

const categories = ['---', ...integrationCategories];

export default class RequestIntegration extends Component {
  state = {
    visible: true,
    loading: false,
    name: '',
    category: '---',
    description: ''
  };
  hideModal = () => this.setState({ visible: false });
  submitIntegration = async () => {
    this.setState({ loading: true });

    const { name, category, description } = this.state;
    let result;
    try {
      result = await this.props.client.mutate({
        variables: { name, category, description },
        mutation: submitIntegration
      });
    } catch (e) {
      message.error('Could not send message, try again');
      this.setState({ loading: false });
      return;
    }

    this.setState({ loading: false });
    if (result?.data?.submitIntegration) {
      message.success('Submitted');
      this.hideModal();
    } else {
      message.error('Could not send message, try again');
    }
  };
  render() {
    return (
      <Modal
        wrapClassName="request-integration-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        style={{ top: 60 }}
        width={500}
        closable={false}
        maskClosable={true}
      >
        <Modal.Close onClick={this.hideModal} />

        <Header size="3" font="sans">
          Request Integration
        </Header>
        <div className="subheader ">Give us some information about the integration you need</div>

        <Input
          label="Integration name"
          size="medium"
          onChange={e => this.setState({ name: e.target.value })}
          value={this.state.name}
          className="name-input"
        />

        <Select
          label="Choose a category"
          placeholder="---"
          value={this.state.category}
          onChange={val => this.setState({ category: val })}
          getPopupContainer={trigger => trigger.parentNode}
          className="category-input"
        >
          {categories.map(c => (
            <Select.Option key={c} value={c}>
              {c}
            </Select.Option>
          ))}
        </Select>

        <TextArea
          label="Add some details"
          value={this.state.description}
          onChange={e => this.setState({ description: e.target.value })}
          size="large"
          rows={6}
          placeholder="Why is it useful, what is it used for?"
          className="description-input"
        />

        <div className="mo-actions">
          <Button type="default" onClick={this.hideModal}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={this.submitIntegration}
            loading={this.state.loading}
            disabled={
              !this.state.category?.length ||
              this.state.category === '---' ||
              !this.state.description?.length ||
              !this.state.name?.length
            }
          >
            Submit Request
          </Button>
        </div>
      </Modal>
    );
  }
}
