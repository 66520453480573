import React, { Component } from 'react';
import { Header, Modal, Button, message } from '_seriph';
import axios from 'axios';
import { downloadFile, plural, getErrors } from '_assets/js/helpers';
import { completeTasks } from '_graphql/mutations/lead';
import { io } from 'socket.io-client';
import './CallExport.scss';

export default class CallExport extends Component {
  state = {
    visible: true,
    loading: true,
    leadTasks: null,
    completedCount: 0
  };
  componentDidMount = async () => {
    this.setupSocket();
  };

  setupSocket = () => {
    this.socket = io(`${process.env.REACT_APP_API_PATH}`, {
      withCredentials: true
    });
    this.socket.on('connect', () => {
      this.setState({ socketId: this.socket.id });
    });
    this.socket.onAny(this.listener);
  };
  componentWillUnmount = () => {
    this.socket.offAny(this.listener);
    this.socket?.close();
  };
  listener = (eventName, ...args) => {
    if (eventName === 'callExportUpdate') {
      this.setState({ completedCount: args[0] });
    }
  };
  hideModal = () => {
    this.setState({ visible: false });
  };
  updateFilter = () => {
    if (this.props.updateFilter) this.props.updateFilter();
    this.hideModal();
  };
  exportLeads = () => {
    this.setState({ exporting: true });
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_PATH}/v1/calls/export`,
      headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') },
      data: {
        campaignId: this.props.campaignId
      }
    })
      .then(res => {
        if (res && res.data && res.data.csv) {
          downloadFile(
            `sellx_call_${res.data.amount}_leads.csv`,
            'data:text/csv;charset=UTF-8,\uFEFF' + encodeURIComponent(res.data.csv)
          );
          message.success('Leads (' + res.data.amount + ') successfully exported');
          if (res.data?.amount > 0) {
            this.setState({ leadTasks: res.data?.leadTasks || null });
          }
          this.setState({ exporting: false });
        } else {
          message.error('Export failed, try again');
          this.setState({ exporting: false });
        }
      })
      .catch(() => {
        message.error('Export failed, try again');
        this.setState({ exporting: false });
      });
  };
  completeTasks = () => {
    const { amount } = this.props;
    this.setState({ completing: true });
    this.props.client
      .mutate({
        variables: {
          tasks: this.state.leadTasks,
          socketId: this.state.socketId
        },
        mutation: completeTasks
      })
      .then(result => {
        if (result && result.data && result.data.completeTasks) {
          if (this.props.reloadHighlights) this.props.reloadHighlights();
          this.setState({ completed: true, completing: false });
          message.success(`You successfully completed ${amount} ${plural('call', amount)}`);
          this.hideModal();
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ completing: false });
        message.error(getErrors(err) || 'Error completing tasks, try again');
      });
  };
  render() {
    const { exporting, leadTasks } = this.state;
    const { amount } = this.props;

    const leadsReady = leadTasks?.length > 0;

    return (
      <Modal
        wrapClassName="call-export-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        afterClose={this.props.removeModal}
        onCancel={this.hideModal}
        width={480}
        closable={false}
        maskClosable={leadsReady ? false : true}
      >
        {!leadsReady ? (
          <div className="part-1">
            <Modal.Close onClick={this.hideModal} />
            <Header size="4" font="sans" className="new-header">
              Call {amount} {plural('lead', amount)}
            </Header>

            <p className="desc">
              You can either filter leads and call individually, or export the leads into a CSV to
              use in an external dialer. We prefer you use the SellX dialer for making calls as it
              properly tracks data that way.
            </p>

            <div className="actions">
              <Button type="secondary" onClick={this.exportLeads} loading={exporting}>
                Export
              </Button>
              <Button type="primary" onClick={this.updateFilter}>
                Use SellX
              </Button>
            </div>
          </div>
        ) : (
          <div className="part-2">
            <Header size="4" font="sans" className="new-header">
              How did your {amount} {plural('call', amount)} go?
            </Header>

            <p className="desc">
              Once you are done with your calls, click the Complete button below to mark all of your
              tasks as done.
            </p>

            <div className="actions">
              <Button
                type="secondary"
                disabled={this.state.completing}
                onClick={() => this.setState({ leadTasks: null })}
              >
                &lt;&nbsp;&nbsp;&nbsp;Go Back
              </Button>
              <Button type="primary" onClick={this.completeTasks} loading={this.state.completing}>
                {this.state.completing ? (
                  <span>
                    Completed {this.state.completedCount} of {amount} {plural('Task', amount)}
                  </span>
                ) : (
                  <span>
                    Complete {amount} {plural('Task', amount)}
                  </span>
                )}
              </Button>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
