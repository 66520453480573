import React from 'react';
import moment from 'moment';
import { Steps } from 'antd';
import { prettyPhone, formatMoney } from '_assets/js/helpers';

const { Step } = Steps;

export const getColumns = () => {
  return [
    {
      title: 'Full name',
      dataIndex: 'first_name',
      width: 360,
      render: (column, data) => (
        <div className="onboard-box">
          <div className="name">
            <b>
              {data.first_name} {data.last_name}
            </b>
          </div>
          <div className="name">
            {data.email}
            {data.linkedin ? (
              <span>
                &nbsp;&middot;&nbsp;
                <a href={data.linkedin} rel="noopener noreferrer" target="_blank">
                  LinkedIn
                </a>
              </span>
            ) : null}
          </div>
          <div className="name">{prettyPhone(data.phone_number)}</div>
          <div className="name"></div>
        </div>
      )
    },
    {
      title: 'Progress',
      dataIndex: 'landed_at',
      render: (column, data) => {
        let step = 0;
        if (data.invitedAt) step = 1;
        if (data.used) step = 2;
        if (data.agent && parseInt(data.agent.profile_percent || 0) > 90) step = 3;
        if (data.agent && data.agent.has_submitted_lead) step = 4;
        if (data.agent && parseInt(data.agent.paid || 0) > 0) step = 5;
        return (
          <div className="on-step">
            <Steps size="small" current={step} status="error">
              <Step
                title="Invited"
                description={moment(data.invitedAt * 1).format('MM/DD h:mma')}
              />
              <Step title="Signed up?" description={data.used ? 'Joined' : ''} />
              <Step title="Profile" description={data.agent ? data.agent.profile_percent : '0%'} />
              <Step
                title="Submitted"
                description={data.agent && data.agent.has_submitted_lead ? 'Yes' : 'No'}
              />
              <Step
                title="Earned"
                description={
                  data.agent && data.agent.paid ? formatMoney(data.agent.paid || 0) : '$0'
                }
              />
            </Steps>
          </div>
        );
      }
    }
    // {
    //   title: 'Finished Application',
    //   dataIndex: 'submitted_at',
    //   render: (column, data, index) => moment(column*1).format('MMM Do h:mma')
    // },
    // {
    //   title: 'Reviewed',
    //   dataIndex: 'reviewedAt',
    //   render: (column, data, index) => moment(column*1).format('MMM Do h:mma')
    // },
    // {
    //   title: 'Invited',
    //   dataIndex: 'invitedAt',
    //   render: (column, data, index) => moment(column*1).format('MMM Do h:mma')
    // },
    // {
    //   title: 'Joined',
    //   dataIndex: 'agent.createdAt',
    //   render: (column, data, index) => moment(column*1).format('MMM Do h:mma')
    // },
    // {
    //   title: 'Profile',
    //   dataIndex: 'agent.profile_percent',
    //   render: (column, data, index) => column || '-'
    // },
    // {
    //   title: 'Submitted Lead?',
    //   dataIndex: 'agent.has_submitted_lead',
    //   render: (column, data, index) => column ? 'Yes' : 'No'
    // },
    // {
    //   title: 'Earned',
    //   dataIndex: 'agent.paid',
    //   render: (column, data, index) => formatMoney(column || 0)
    // }
  ];
};
