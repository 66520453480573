import React, { Component } from 'react';
import { Select } from 'antd';
import { importFields } from 'App/Company/Leads/Lead/formData';
import './SellXFieldSelect.scss';

export default class SellXFieldSelect extends Component {
  render() {

    const campaignCustom = this.props.campaignCustomFields || [];

    return (
      <Select
        className="forms"
        dropdownClassName="sx-dropdown mapping-forms"
        value={this.props.value || undefined}
        onChange={val => this.props.onChange(val)}
        getPopupContainer={trigger => trigger.parentNode}
        placeholder="Select a lead field"
      >
        {Object.keys(importFields).filter(key => key !== 'custom_fields').map(field => (
          <Select.Option
            disabled={this.props.chosen.includes(field)}
            key={'map-' + field}
            value={field}
          >
            {field.replace(/_/gi, ' ').replace('crm', 'CRM')}
          </Select.Option>
        ))}
        {campaignCustom.map(field => (
          <Select.Option
            disabled={this.props.chosen.includes(`custom-${field.label}`)}
            key={'map-' + field.label}
            value={`custom-${field.label}`}
          >
            {field.label}
          </Select.Option>
        ))}
      </Select>
    );
  }
}
