import React from 'react';
import { InputNumber as AntdInput } from 'antd';
import { IconBolt, IconAlertCircle, IconInfoCircle, IconThumbUp } from '@tabler/icons-react';
import { Tooltip } from '_seriph';
import './InputNumber.scss';

const InputNumber = incomingProps => {
  const props = { ...incomingProps };
  let containerClassName = 'seriph-inputnumber';

  // Handle Size (medium, large)
  if (props.size) {
    containerClassName += ' input-' + props.size;
    delete props.size;
  }

  // Handle InputNumber Icons
  if (props.iconLeft) containerClassName += ' input-left';
  if (props.iconRight) containerClassName += ' input-right';

  // Handle Error/Success
  if (props.error) containerClassName += ' seriph-error';
  if (props.success) containerClassName += ' seriph-success';

  const iconLeft = props.iconLeft;
  const iconRight = props.iconRight;

  delete props.iconLeft;
  delete props.iconRight;

  return (
    <div className={containerClassName}>
      {props.label && (
        <label className="seriph-label">
          {props.label}
          {props.info && (
            <Tooltip title={props.info}>
              <IconInfoCircle size={16} stroke={1.75} />
            </Tooltip>
          )}
        </label>
      )}
      <div className="input-box">
        <AntdInput {...props} />
        {iconLeft && <span className="input-icon-left">{iconLeft}</span>}
        {iconRight && <span className="input-icon-right">{iconRight}</span>}
      </div>
      {props.hint && (
        <div className="seriph-hint">
          {!props.success && !props.error && <IconBolt size={16} stroke={1.75} />}
          {props.success && <IconThumbUp size={16} stroke={1.75} />}
          {props.error && <IconAlertCircle size={16} stroke={1.75} />}
          <span>{props.hint}</span>
        </div>
      )}
    </div>
  );
};

export default InputNumber;
