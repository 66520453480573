import React from 'react';
import { formatMoney, numberWithCommas } from '_assets/js/helpers';


const bonusCols = [.1, .075, .05, .03, .03, .025, .025,.025,.025,.025];


export const getColumns = () => {
  return [
    {
      title: 'Full Name',
      dataIndex: 'first_name',
      render: (column, data) => {
        return (
          <div className="lead-name">
            <div className="lead-photo">
              {data.agent.picture ? (
                <img src={data.agent.picture} alt="" />
              ) : (
                <span>{data.agent.display_name}</span>
              )}
            </div>
            <div className="lead-full">{data.agent.display_name}</div>
          </div>
        );
      }
    },
    {
      title: 'Individual Stats',
      children: [
        { title: 'Est. Bonus', dataIndex: 'bonus_test', render: (col, data) => {
          const bonusPercent = bonusCols[data.rank-1];
          const earned = formatMoney(parseFloat(data.earned * bonusPercent));
          return earned;
        } },
        { title: 'Earned', dataIndex: 'earned', render: column => formatMoney(column) },
        { title: 'SellX Cut', dataIndex: 'fees', render: column => formatMoney(column) },
        { title: 'Reputation', dataIndex: 'reputation', render: column => numberWithCommas(column) }
      ]
    },
    {
      title: 'Lead Stats',
      children: [
        { title: 'Generated', dataIndex: 'generated', render: column => numberWithCommas(column) },
        { title: 'Qualified', dataIndex: 'qualified', render: column => numberWithCommas(column) },
        { title: 'Meetings', dataIndex: 'meetings', render: column => numberWithCommas(column) },
        { title: 'Review', dataIndex: 'review', render: column => numberWithCommas(column) }
      ]
    },
    {
      title: 'Touchpoint Stats',
      children: [
        {
          title: 'Email',
          dataIndex: 'touchpoint_email',
          render: column => numberWithCommas(column)
        },
        { title: 'Call', dataIndex: 'touchpoint_call', render: column => numberWithCommas(column) },
        {
          title: 'LinkedIn',
          dataIndex: 'touchpoint_linkedin',
          render: column => numberWithCommas(column)
        },
        {
          title: 'General',
          dataIndex: 'touchpoint_instructions',
          render: column => numberWithCommas(column)
        },
        {
          title: 'Skipped',
          dataIndex: 'touchpoint_skipped',
          render: column => numberWithCommas(column)
        }
      ]
    }
  ];
};
