import { numberWithCommas } from '_assets/js/helpers';
import moment from 'moment';

export const getColumns = () => {
  return [
    {
      title: 'Day',
      dataIndex: 'date',
      render: day => {
        return moment(day.toString()).format('dddd, MMM Do');
      }
    },
    {
      title: 'Counted Tasks',
      children: [
        {
          title: 'Calls',
          dataIndex: 'daily.touchpoint_call',
          sorter: (a, b) => a.daily.touchpoint_call - b.daily.touchpoint_call,
          render: column => numberWithCommas(column)
        },
        {
          title: 'Emails',
          dataIndex: 'daily.touchpoint_email',
          sorter: (a, b) => a.daily.touchpoint_email - b.daily.touchpoint_email,
          render: column => numberWithCommas(column)
        },
        {
          title: 'LinkedIn',
          dataIndex: 'daily.touchpoint_linkedin',
          sorter: (a, b) => a.daily.touchpoint_linkedin - b.daily.touchpoint_linkedin,
          render: column => numberWithCommas(column)
        }
      ]
    },
    {
      title: 'Uncounted Tasks',
      children: [
        {
          title: 'General',
          dataIndex: 'daily.touchpoint_instructions',
          sorter: (a, b) => a.daily.touchpoint_instructions - b.daily.touchpoint_instructions,
          render: column => numberWithCommas(column)
        },
        {
          title: 'Skipped',
          dataIndex: 'daily.touchpoint_skipped',
          sorter: (a, b) => a.daily.touchpoint_skipped - b.daily.touchpoint_skipped,
          render: column => numberWithCommas(column)
        }
      ]
    },
    {
      title: 'Total Tasks',
      dataIndex: 'daily.touchpoint_total',
      sorter: (a, b) => a.daily.touchpoint_total - b.daily.touchpoint_total,
      render: column => numberWithCommas(column)
    },
    {
      title: 'Meetings',
      dataIndex: 'daily.meetings',
      sorter: (a, b) => a.daily.meetings - b.daily.meetings,
      render: column => numberWithCommas(column)
    }
  ];
};