import React from 'react';
import { getLeadName } from 'App/Company/Leads/Lead/_helpers/leadInformation';
import moment from 'moment';
import 'moment-timezone';

const zone_name = moment.tz.guess();
const timezone = moment.tz(zone_name).zoneAbbr();

export const getColumns = () => {
  return [
    {
      title: 'Lead',
      dataIndex: 'first_name',
      render: (column, data) => {
        let momentData = moment(Date.parse(data.calendly.created_at));
        if (!momentData.isValid()) {
          momentData = moment(data.calendly.created_at*1);
        }
        return (
          <div className="meeting-lead">
            <h3>{getLeadName(data)}</h3>
            <p>{data?.campaign?.name || 'Unknown Campaign'}</p>
          </div>
        );
      }
    },
    {
      title: 'Meeting date',
      dataIndex: 'calendly.start_time',
      render: (column, data) => {
        const startTime =
          data.meetingScheduled && data.calendly && data.calendly.start_time
            ? data.calendly.start_time
            : null;

        let momentData = moment(Date.parse(startTime));
        if (!momentData.isValid()) {
          momentData = moment(startTime*1);
        }
        
        return momentData.format('ddd, M/D/YY');
      }
    },
    {
      title: 'Meeting time',
      dataIndex: 'calendly.end_time',
      render: (column, data) => {
        const startTime =
          data.meetingScheduled && data.calendly && data.calendly.start_time
            ? data.calendly.start_time
            : null;

        let momentData = moment(Date.parse(startTime));
        if (!momentData.isValid()) {
          momentData = moment(startTime*1);
        }

        return '@ ' + momentData.format('h:mma') + ' ' + timezone;
      }
    }
  ];
};
