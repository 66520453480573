import React, { Component } from 'react';
import './Questions.scss';

export default class Questions extends Component {
  render() {
    const { questions, lead } = this.props;

    return (
      <div id="dialer-questions" className="sx-form">
        <h3 className="call-h3">Questions to ask {lead.first_name}</h3>
        {questions.length <= 0 ? (
          <div className="dialer-script">No questions to ask this lead</div>
        ) : null}
        {questions.map((q, i) => (
          <div className="lead-quest" key={'lq-' + i}>
            <h4>
              {i + 1}.&nbsp;{q.question}
            </h4>
          </div>
        ))}
      </div>
    );
  }
}
