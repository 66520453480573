import gql from 'graphql-tag';

export const adminAgents = gql`
  query adminAgents(
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $name: String
    $filterField: String
  ) {
    adminAgents(
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      name: $name
      filterField: $filterField
    ) {
      data {
        id
        first_name
        last_name
        picture
        user {
          email
          verified
        }
        phone_number
        onboard

        experience_type
        employment_type
        rating
        paid
        manager_id

        location {
          name
        }
        team {
          id
          name
          logo
        }
        sellx_rep
        cohort
        is_cohort
        stripe_user_id
        company {
          company_id
        }

        stats {
          in_progress
          generated
          qualified
          emailed
          called
          meetings
          review
          disputed
        }
      }
      tabAmounts {
        active
        sellx
        internal
        unassigned
        banned
      }
      pageTotal
    }
  }
`;

export const adminCampaigns = gql`
  query adminCampaigns(
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $name: String
  ) {
    adminCampaigns(
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      name: $name
    ) {
      data {
        id
        name
        status
        sub_status
        objective
        threshold
        threshold_settings {
          duration
          lead_count
        }
        stats {
          total_leads
          estimated_total
          conversion_rate
          remaining
          completed
          generated
          qualified
          not_interested
          review
          review_interested
          review_not_interested
          disputed
          in_progress
          unassigned
          paid
          paid_interested
          paid_not_interested
          owed
          owed_interested
          owed_not_interested
        }
        agent_count
        actual_budget
        status_updated
        updatedAt
        price_per_lead
        wizard_version
        company {
          id
          name
          logo
          owner_id
        }
        agents {
          agent_id
          paid
          active
        }
      }
      reps {
        id
        picture
      }
      total
      pageTotal
    }
  }
`;

export const adminCampaignImport = gql`
  query adminCampaignImport($campaign_id: String!) {
    adminCampaignImport(campaign_id: $campaign_id) {
      mappings
    }
  }
`;

export const adminReviews = gql`
  query adminReviews(
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $name: String
  ) {
    adminReviews(
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      name: $name
    ) {
      data {
        id
        name
        status
        objective
        current_review_count
        current_completed_count
        estimated_leads_count
        agent_count
        target_agent_count
        rep_interested_count
        price_not_interested
        actual_budget
        budget_total
        paid
        unassigned_count
        lead_est_count
        budget_ppl
        budget_ppql
        status_updated
        updatedAt
        price_per_lead
        wizard_version
        sequence_count
        leads_created_count
        leads_approved_count
        threshold
        threshold_settings {
          duration
          lead_count
        }
        company {
          id
          name
          logo
          owner {
            id
          }
        }
        agents {
          agent_id
          paid
          active
        }
        import {
          id
          name
          type
          amount
          imported
          import_id
        }
      }
      total
      pageTotal
    }
  }
`;

export const adminCompanies = gql`
  query adminCompanies(
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $name: String
    $filterField: String
  ) {
    adminCompanies(
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      name: $name
      filterField: $filterField
    ) {
      data {
        id
        name
        logo
        owner {
          id
          first_name
          last_name
          picture
          title
          user {
            email
          }
        }
        internal_seats
        xdr_seats
        active_subscription
        stripe_subscription_end
        trial_expire_date
        unpaid
        website
        employee_count_min
        employee_count_max
        manager_id
      }
      total
      pageTotal
    }
  }
`;

export const adminCompanyApplications = gql`
  query adminCompanyApplications(
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $roleType: String
    $name: String
  ) {
    adminCompanyApplications(
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      roleType: $roleType
      name: $name
    ) {
      data {
        id
        status
        name
        email
        phone
        company_name
        company_website
        company_size
        industries
        needs
        hear_about
        hear_about_other
        desired_start
        desired_engagement
        ok_with_remote
        ok_with_remote_explanation
        is_calendly_event_scheduled
        calendly {
          created_at
          start_time
          end_time
          name
          join_url
        }
        review {
          type
          revisit
          note
        }
        invite {
          invited
          link
          sent
          resent
          joined
        }
        company_id
        employee_id
      }
      total
      pageTotal
    }
  }
`;

export const adminApplications = gql`
  query adminApplications(
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $roleType: String
    $name: String
  ) {
    adminApplications(
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      roleType: $roleType
      name: $name
    ) {
      data {
        id
        response_id
        landed_at
        submitted_at
        score
        answers {
          answer_id
          title
          type
          answer
        }
        email
        phone_number
        first_name
        last_name
        linkedin
        company_name
        website
        invited
        reviewed
        status
        used
        version
        company_id
      }
      total
      pageTotal
    }
  }
`;

export const adminOnboarding = gql`
  query adminApplications(
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $roleType: String
    $query: String
    $name: String
  ) {
    adminApplications(
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      roleType: $roleType
      query: $query
      name: $name
    ) {
      data {
        id
        response_id
        landed_at
        submitted_at
        email
        phone_number
        first_name
        last_name
        linkedin
        company_name
        website
        reviewedAt
        invitedAt
        companyCreatedAt
        used
        agent {
          createdAt
          profile_percent
          has_submitted_lead
          has_created_lead
          paid
        }
      }
      total
      pageTotal
    }
  }
`;

export const adminCounts = gql`
  query adminCounts($roleType: String) {
    adminCounts(roleType: $roleType) {
      invitedCount
      signupCount
      applicationCount
      profileCount
    }
  }
`;

export const adminFeedback = gql`
  query adminFeedback(
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $name: String
  ) {
    adminFeedback(
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      name: $name
    ) {
      data {
        id
        type
        content
        acknowledged
        createdAt
        agent {
          id
          display_name
          picture
        }
        employee {
          id
          display_name
          picture
        }
        company {
          id
          name
          logo
        }
      }
      total
      pageTotal
    }
  }
`;

export const adminTemplates = gql`
  query adminTemplates(
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $name: String
  ) {
    adminTemplates(
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      name: $name
    ) {
      data {
        id
        name
        type
        information
        content
      }
      total
      pageTotal
    }
  }
`;

export const adminLeaderboard = gql`
  query adminLeaderboard(
    $date: String
    $type: String
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $name: String
    $companyId: String
    $campaignId: String
    $managerId: String
  ) {
    adminLeaderboard(
      date: $date
      type: $type
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      name: $name
      companyId: $companyId
      campaignId: $campaignId
      managerId: $managerId
    ) {
      data {
        id
        earned
        fees
        reputation
        generated
        qualified
        meetings
        review
        touchpoint_email
        touchpoint_call
        touchpoint_linkedin
        touchpoint_instructions
        touchpoint_skipped
        agent {
          id
          display_name
          picture
        }
      }
      earnedTotal
      feesTotal
      meetingsTotal
      total
      pageTotal
    }
  }
`;

export const adminTasks = gql`
  query adminTasks($date: String, $type: String, $internal: String) {
    adminTasks(date: $date, type: $type, internal: $internal) {
      data {
        email
        call
        linkedin
        total
        agent_id
        agent {
          id
          display_name
          sellx_rep
          picture
          manager {
            admin {
              display_name
            }
          }
        }
      }
      previous {
        email
        call
        linkedin
        total
        agent_id
        agent {
          id
          sellx_rep
        }
      }
    }
  }
`;

export const adminCampaignStats = gql`
  query adminCampaignStats($date: String, $type: String, $internal: String) {
    adminCampaignStats(date: $date, type: $type, internal: $internal) {
      data {
        daily {
          meetings
          touchpoint_email
          touchpoint_call
          touchpoint_linkedin
          touchpoint_instructions
          touchpoint_skipped
          touchpoint_total
          pulled
        }
        campaign {
          name
          agent_count
          status
          leads_created_count
          leads_remaining_count
          leads_in_progress_count
          company {
            name
            logo
            owner_id
          }
        }
      }
    }
  }
`;

export const adminCompanyStats = gql`
  query adminCompanyStats($date: String, $type: String, $internal: String) {
    adminCompanyStats(date: $date, type: $type, internal: $internal) {
      data {
        daily {
          meetings
          meeting_value
          touchpoint_email
          touchpoint_call
          touchpoint_linkedin
          touchpoint_instructions
          touchpoint_skipped
          touchpoint_total
          pulled
        }
        company {
          name
          logo
          owner_id
        }
      }
    }
  }
`;

export const adminRepStats = gql`
  query adminRepStats($date: String, $type: String, $company_id: String, $internal: String) {
    adminRepStats(date: $date, type: $type, company_id: $company_id, internal: $internal) {
      data {
        daily {
          meetings
          meeting_value
          touchpoint_email
          touchpoint_call
          touchpoint_linkedin
          touchpoint_instructions
          touchpoint_skipped
          touchpoint_total
          pulled
        }
        agent {
          id
          display_name
          picture
          manager {
            admin {
              display_name
            }
          }
        }
      }
    }
  }
`;

export const getFellowAdmins = gql`
  query getFellowAdmins {
    getFellowAdmins {
      display_name
      role
      companies {
        name
        id
      }
      user {
        email
        id
        createdAt
      }
    }
  }
`;

export const getManagers = gql`
  query getManagers {
    getManagers {
      email
      id
      admin {
        display_name
      }
    }
  }
`;

export const repSearchName = gql`
  query repSearchName {
    repSearchName {
      id
      display_name
      picture
    }
  }
`;

export const adminScorecard = gql`
  query adminScorecard($date: String, $type: String, $rep_id: String) {
    adminScorecard(date: $date, type: $type, rep_id: $rep_id) {
      data {
        date
        daily {
          meetings
          touchpoint_email
          touchpoint_call
          touchpoint_linkedin
          touchpoint_instructions
          touchpoint_skipped
          touchpoint_total
          pulled
          review
          invalid
          qualified
          not_interested
          interest_bad_timing
          interest_financial
          interest_another_solution
          interest_wrong_contact
          interest_no_reason
          interest_no_response
          interest_wrong_number
          interest_bad_email
          interest_duplicate
          interest_other
        }
      }
      call_stats {
        total_duration
        total_calls
        total_answered
        total_recorded
        total_incoming
        dur_0_1
        dur_1_2
        dur_2_5
        dur_5_10
        dur_10
      }
      calls {
        id
        status
        duration
        recording_url
        answered
        createdAt
        lead {
          first_name
          last_name
          company
        }
        campaign {
          name
          company {
            name
          }
        }
      }
    }
  }
`;


export const adminTriggers = gql`
  query adminTriggers {
    adminTriggers {
      campaigns {
        id
        name
        status
        company {
          name
          logo
          owner_id
        }
      }
      agents {
        id
        display_name
        picture
        user {
          email
        }
      }
      triggers {
        type
        id
        reason
        pretty_reason
        value
      }
    }
  }
`;
