import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { IconMicrophone, IconPlayerStop, IconRefresh } from '@tabler/icons-react';
import MicRecorder from 'mic-recorder-to-mp3';
import WaveSurfer from 'wavesurfer.js';
import { timeFormat } from '_assets/js/helpers';
import './Audio.scss';
import { Button } from '_seriph';

export default class AudioBody extends Component {
  state = {
    recording: false,
    waveform: false,
    playing: false,
    recordTimestamp: 0
  };
  recorder = null;
  waveform = null;
  timer = null;
  componentDidMount = () => {
    if (this.props.hasAudioFile) {
      this.file = this.props.hasAudioFile;
      this.setFile();
    }

    document.addEventListener('keydown', this.handleKeyDown);
  };
  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.handleKeyDown);
  };

  attemptRecord = () => {
    this.file = null;
    this.recorder = new MicRecorder({ bitRate: 128 });
    this.recorder
      .start()
      .then(() => {
        this.setState({ recording: true, waveform: false, recordTimestamp: 0 }, () => {
          this.timer = setInterval(this.recordTimer, 1000);
        });
      })
      .catch(e => {
        console.error(e);
      });
  };
  recordTimer = () => {
    this.setState({ recordTimestamp: this.state.recordTimestamp + 1 });
  };
  stopRecord = async () => {
    clearInterval(this.timer);
    this.timer = null;
    this.recorder
      .stop()
      .getMp3()
      .then(async ([buffer, blob]) => {
        this.file = new File(buffer, 'audioFile.mp3', {
          type: blob.type,
          lastModified: Date.now()
        });
        this.props.setAudioFile(this.file);
        this.setFile();
      })
      .catch(e => {
        console.log(e);
      });
  };
  setFile = () => {
    const player = new Audio(URL.createObjectURL(this.file));
    const loadAudio = audioObj => {
      const duration = audioObj.target ? audioObj.target.duration : null;
      this.waveform = WaveSurfer.create({
        container: '#visualize',
        backend: 'WebAudio',
        waveColor: '#ADB5C5',
        progressColor: '#090E15',
        normalize: true,
        height: 40,
        barWidth: 4,
        barMinHeight: 4,
        fillParent: false,
        maxCanvasWidth: 300,
        minPxPerSec: duration ? Math.floor(300 / duration) : 50,
        barRadius: 4,
        barGap: 4
      });
      this.waveform.loadBlob(this.file);
      this.setState({ recording: false, waveform: true });
      player.removeEventListener('click', loadAudio, false);
    };
    player.addEventListener('loadeddata', loadAudio);
  };
  play = () => {
    this.setState({ playing: true });
    this.waveform.play();
  };
  pause = () => {
    this.setState({ playing: false });
    this.waveform.pause();
  };
  resetRecording = () => {
    this.waveform.destroy();
    this.waveform = null;
    this.file = null;
    this.recorder = null;
    this.timer = null;
    this.setState({ waveform: null, recording: false, playing: false });
    this.props.setAudioFile(null);
  };
  handleKeyDown = e => {
    if (e.keyCode === 32) {
      e.preventDefault();
      if (!this.state.recording && !this.state.waveform) {
        this.attemptRecord();
      } else if (this.state.recording) {
        this.stopRecord();
      }
    }
  };
  render() {
    const { recording, waveform, playing } = this.state;
    const { company } = this.props;
    return (
      <div className="audio-content">
        <h3>Show off your pitch skills</h3>
        <div className="record-instructions">
          <span> When you’re ready, click </span>
          <IconMicrophone /> <span> below to record.</span>
          <span>Tell {company.name} why you&apos;re a a great fit for their campaign</span>
        </div>
        <div className="recording">
          {!recording && !waveform && (
            <div className="action start" onClick={this.attemptRecord}>
              <IconMicrophone />
            </div>
          )}
          {recording && (
            <div className="action stop" onClick={this.stopRecord}>
              <IconPlayerStop />
            </div>
          )}
          {!recording && waveform && !playing && (
            <div className="action play" onClick={this.play}>
              <FontAwesomeIcon icon={faPlay} />
            </div>
          )}
          {!recording && waveform && playing && (
            <div className="action pause" onClick={this.pause}>
              <FontAwesomeIcon icon={faPause} />
            </div>
          )}
          <div className="visualizer" id="visualize" />
          <div className="timestamp">{timeFormat(this.state.recordTimestamp || 0)}</div>
        </div>
        {!waveform && <div className="space-info">*Press the space bar to record & stop</div>}
        {!recording && waveform ? (
          <div className="retry">
            <Button type="default" onClick={this.resetRecording} icon="left">
              <IconRefresh />
              Retry
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}
