import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas } from '_assets/js/helpers';
import { Button, Header } from '_seriph';
import { Skeleton } from 'antd';
import {
  IconChevronRight,
  IconBolt,
  IconFlag,
  IconArrowRight,
  IconMail,
  IconPhone,
  IconMessage,
  IconMessageCircle2,
  IconBellRinging
} from '@tabler/icons-react';
import './Highlights.scss';

class Highlights extends Component {
  render() {
    const counts = { ...this.props.counts, ...this.props.data };
    const campaignCount = this.props.campaigns?.length || 0;

    const hasHighlights =
      counts.message_count ||
      counts.activity.leads.disputed ||
      counts.notification_count ||
      counts.email_count ||
      counts.call_count ||
      counts.text_count
        ? true
        : false;

    const skel = <Skeleton avatar paragraph={{ rows: 1 }} />;

    return (
      <div id="internal-home-highlights">

        <h3>Lead Highlights</h3>
        <div className="all-highlights">
          {/* No Highlights */}
          {!hasHighlights ? (
            <div className="no-high">
              {skel}
              <div className="highlight none">
                <div className="highlight-icon">
                  <IconBolt />
                </div>
                <div className="info">
                  <Header size="5" font="sans">
                    Quickly act on the most important tasks
                  </Header>
                  <p>
                    Time sensitive highlights like new messages, lead resubmissions, ending campagins etc..
                  </p>
                </div>
              </div>
              {skel}
            </div>
          ) : null}

          {/* Messages */}
          {counts.message_count ? (
            <div
              className="highlight"
              onClick={this.props.loadMessaging ? this.props.loadMessaging : null}
            >
              <div className="highlight-icon" style={{ background: '#C1DDBB' }}>
                <IconMessageCircle2 />
              </div>
              <div className="info">
                <Header size="5" font="sans">
                  Unread Messages
                </Header>
                <p>
                  {counts.message_count} new message{counts.message_count !== 1 && 's'}
                </p>
              </div>
              <IconChevronRight />
            </div>
          ) : null}

          {/* Leads */}
          {counts.activity.leads.disputed ? (
            <Link to="/leads?stage=disputed">
              <div className="highlight">
                <div className="highlight-icon" style={{ background: '#ebaea0' }}>
                  <IconFlag />
                </div>
                <div className="info">
                  <Header size="5" font="sans">
                    Leads that were disputed
                  </Header>
                  <p>
                    {counts.activity.leads.disputed} lead
                    {counts.activity.leads.disputed !== 1 && 's'} are disputed and may need work
                  </p>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </Link>
          ) : null}

          {/* Notifications */}
          {counts.notification_count ? (
            <div
              className="highlight"
              onClick={this.props.loadNotifications ? this.props.loadNotifications : null}
            >
              <div className="highlight-icon" style={{ background: '#D2D0FB' }}>
                <IconBellRinging />
              </div>
              <div className="info">
                <Header size="5" font="sans">
                  Unread Notifications
                </Header>
                <p>
                  {counts.notification_count} new notification
                  {counts.notification_count !== 1 && 's'}
                </p>
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          ) : null}

          {/* Unread Emails */}
          {counts.email_count ? (
            <Link to="/leads?highlights=email">
              <div className="highlight">
                <div className="highlight-icon" style={{ background: '#9dc0b7' }}>
                  <IconMail />
                </div>
                <div className="info">
                  <Header size="5" font="sans">
                    Unread Emails
                  </Header>
                  <p>
                    You have {counts.email_count} unread email{counts.email_count !== 1 && 's'} from
                    leads
                  </p>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </Link>
          ) : null}

          {/* Missed Calls */}
          {counts.call_count ? (
            <Link to="/leads?highlights=call">
              <div className="highlight">
                <div className="highlight-icon dark" style={{ background: '#e4d7a7' }}>
                  <IconPhone />
                </div>
                <div className="info">
                  <Header size="5" font="sans">
                    Missed Calls
                  </Header>
                  <p>
                    You have {counts.call_count} missed call{counts.call_count !== 1 && 's'} from
                    leads
                  </p>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </Link>
          ) : null}

          {/* Missed Texts */}
          {counts.text_count ? (
            <Link to="/leads?highlights=text">
              <div className="highlight">
                <div className="highlight-icon dark" style={{ background: '#e4d7a7' }}>
                  <IconMessage />
                </div>
                <div className="info">
                  <Header size="5" font="sans">
                    Missed Texts
                  </Header>
                  <p>
                    You have {counts.text_count} missed text{counts.text_count !== 1 && 's'} from
                    leads
                  </p>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </Link>
          ) : null}
        </div>

        <div className="divider" />

        <Header size="3" font="sans">
          Campaign activity
        </Header>
        <p className="activity-sub">
          There {campaignCount === 1 ? 'is' : 'are'} {campaignCount} active campaign{campaignCount !== 1 && 's'}
        </p>

        <div className="live-activity">
          {/* IN PROGRESS */}
          <div
            className="status-activity"
            onClick={() => this.props.history.push('/leads?stage=in-progress')}
          >
            <div className="bar in-progress" />
            <div className="status-type">
              <h1>{numberWithCommas(counts.activity.leads.in_progress || 0)}</h1>
              <div>In Progress</div>
            </div>
          </div>

          {/* Review */}
          <div
            className="status-activity"
            onClick={() => this.props.history.push('/leads?stage=review')}
          >
            <div className="bar review" />
            <div className="status-type">
              <h1>{numberWithCommas(counts.activity.leads.review || 0)}</h1>
              <div>Pending Review</div>
            </div>
          </div>

          {/* Disputed */}
          <div
            className="status-activity"
            onClick={() => this.props.history.push('/leads?stage=disputed')}
          >
            <div className="bar disputed" />
            <div className="status-type">
              <h1>{numberWithCommas(counts.activity.leads.disputed || 0)}</h1>
              <div>Disputed</div>
            </div>
          </div>

          {/* Completed */}
          <div
            className="status-activity"
            onClick={() => this.props.history.push('/leads?stage=completed')}
          >
            <div className="bar completed" />
            <div className="status-type">
              <h1>{numberWithCommas(counts.activity.leads.qualified || 0)}</h1>
              <div>Qualified</div>
            </div>
          </div>

          {/* Meetings */}
          <div
            className="status-activity"
            onClick={() => this.props.history.push('/analytics/meetings')}
          >
            <div className="bar meetings" />
            <div className="status-type">
              <h1>{numberWithCommas(counts.activity.leads.meetings || 0)}</h1>
              <div>Meetings</div>
            </div>
          </div>

          {/* Not Interested */}
          <div
            className="status-activity"
            onClick={() => this.props.history.push('/leads?stage=not-interested')}
          >
            <div className="bar not-interested" />
            <div className="status-type">
              <h1>{numberWithCommas(counts.activity.leads.not_interested || 0)}</h1>
              <div>Not Interested</div>
            </div>
          </div>

          <Link to="/analytics">
            <Button type="secondary" size="medium" icon="right" block>
              See analytics
              <IconArrowRight />
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}

export default withRouter(Highlights);
