import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { hourFields, touchpointMinimums } from '_constants/application';
import { Button, Tooltip, Avatar, Header, Tag } from '_seriph';
import { IconCircle, IconCheck, IconX, IconPlayerPlay, IconPlayerPause } from '@tabler/icons-react';

export const getColumns = (
  hire,
  pass,
  toggleCheck,
  checkList,
  handleAudio,
  audioInfo,
  userId,
  onExpand
) => {
  return [
    {
      title: 'Sales Rep',
      dataIndex: 'information',
      render: (column, data) => {
        const { agent, campaign } = data;
        return (
          <div className="app-card">
            <div className="app-card-bullet">
              <IconCircle className={data.expanded_by?.includes(userId) ? 'has-expanded' : ''} />
            </div>
            <Link to={`/rep/${agent.id}`} onClick={() => onExpand(true, data)}>
              <Avatar type="agent" size="small" picture={agent.picture} />
            </Link>
            <div className="app-card-info">
              <Link to={`/rep/${agent.id}`}>
                <Header size="4" font="sans">
                  {agent.display_name}
                </Header>
              </Link>
              <div className="campaign-name">{campaign.name}</div>
              <p>Applied {moment(data.applied_date * 1).fromNow()}</p>
            </div>
          </div>
        );
      }
    },
    {
      title: '% Match',
      dataIndex: 'match',
      sorter: (a, b) => {
        return a.match - b.match;
      },
      render: column => {
        return column >= 70 ? (
          <Tag type="info">
            {column}% match
          </Tag>
        ) : (
          <Tag>
            {column}% match
          </Tag>
        );
      }
    },
    {
      title: 'Location',
      dataIndex: 'location',
      render: (column, data) => {
        const { agent } = data;
        return (
          agent.location ? agent.location.name || 'Location Unknown' : 'Location Unknown'
        ).replace(', USA', '');
      }
    },
    {
      title: 'Daily availablility',
      dataIndex: 'availability',
      render: (column, data) => {
        const hourData = hourFields[data.hours];
        const touchpointData = touchpointMinimums.find(t => {
          return parseInt(t.value) === data.commitment;
        });

        return hourData?.description || touchpointData?.title || 'N/A';
      }
    },
    {
      title: 'Experience',
      dataIndex: 'experience',
      render: (column, data) => {
        const { agent } = data;
        return agent.experience_type || 'Experience Unknown';
      }
    },
    {
      title: '',
      dataIndex: 'actions',
      width: 180,
      render: (column, data) => {
        const isSetup = audioInfo.audioId === data.id ? true : false;
        const playing = isSetup && audioInfo.playing ? true : false;

        let action = () => handleAudio('setup', data);
        if (playing) {
          action = () => handleAudio('pause', data);
        } else if (isSetup && !playing) {
          action = () => handleAudio('play', data);
        }

        return (
          <div className="tbl-actions">
            {data.status === 'pending' ? (
              <React.Fragment>
                <Tooltip title="Pass">
                  <Button type="default" circle size="tiny" onClick={() => pass(data)}>
                    <IconX />
                  </Button>
                </Tooltip>
                <Tooltip title="Accept">
                  <Button type="default" circle size="tiny" onClick={() => hire(data)}>
                    <IconCheck />
                  </Button>
                </Tooltip>
              </React.Fragment>
            ) : null}

            {data.audio_url ? (
              <Tooltip title="Play pitch">
                <Button type="primary" circle onClick={action} size="tiny">
                  {playing ? <IconPlayerPause /> : <IconPlayerPlay />}
                </Button>
              </Tooltip>
            ) : null}
          </div>
        );
      }
    },
    {
      title: '',
      dataIndex: 'expand'
    }
  ];
};
