import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Input, Button } from 'antd';
import {
  createAgentProfileAccomplishment,
  deleteAgentProfileAccomplishment,
  updateAgentProfileAccomplishment
} from 'Mutations/Agent/updateAgentProfile';
import { updateAgentProfile } from 'Store/agent/agent_actions';

const { TextArea } = Input;

const mutations = {
  save: { mutation: updateAgentProfileAccomplishment, name: 'updateAgentAccomplishment' },
  create: { mutation: createAgentProfileAccomplishment, name: 'createAgentAccomplishment' },
  delete: { mutation: deleteAgentProfileAccomplishment, name: 'deleteAgentAccomplishment' }
};

class AccomplishmentsEdit extends Component {
  state = { saving: false };
  action = type => {
    this.setState({ saving: true });
    this.props.apollo
      .mutate({
        variables: { id: this.props.editing, description: this.props.description },
        mutation: mutations[type].mutation
      })
      .then(result => {
        if (result && result.data) {
          this.props.close();
          this.props.reload();
        } else {
          this.setState({ saving: false });
        }
      })
      .catch(() => {
        this.setState({ saving: false });
      });
  };
  setInitial = () => this.setState({ saving: false });
  render() {
    const editing = this.props.editing ? true : false;
    return (
      <Modal
        title={null}
        visible={this.props.visible}
        footer={null}
        afterClose={this.setInitial}
        onCancel={this.props.close}
        wrapClassName="sx-theme form-modal agent-accomplishment-edit"
        destroyOnClose={true}
        maskClosable={true}
        centered={true}
      >
        <div className="sx-form">
          <div className="field">
            <label>Accomplishment *</label>
            <TextArea
              placeholder="Write your accomplishment here..."
              rows={8}
              autoSize={true}
              value={this.props.description}
              onChange={this.props.updateDescription}
            />
          </div>
        </div>

        {editing ? (
          <div className="sx-actions">
            <Button className="btnx btnx-default" onClick={() => this.action('delete')}>
              Remove
            </Button>
            <Button
              className="btnx btnx-primary"
              loading={this.props.saving}
              onClick={() => this.action('save')}
            >
              Save
            </Button>
          </div>
        ) : (
          <div className="sx-actions">
            <Button
              className="btnx btnx-primary"
              loading={this.props.saving}
              onClick={() => this.action('create')}
            >
              Create
            </Button>
          </div>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.agent, ...state.apollo };
};

export default connect(mapStateToProps, { updateAgentProfile })(AccomplishmentsEdit);
