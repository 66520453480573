import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { IconChevronRight, IconDownload } from '@tabler/icons-react';
import Activity from './_components/Activity/Activity';
import Connectivity from './_components/Connectivity/Connectivity';
import CallRecordings from './_components/CallRecordings/CallRecordings';
import Meetings from './_components/Meetings/Meetings';
import Progress from './_components/Progress/Progress';
import LeadBreakdown from './_components/LeadBreakdown/LeadBreakdown';
import Scorecard from './_components/Scorecard/Scorecard';
import XDRStats from './_components/XDRStats/XDRStats';
import './Analytics.scss';

const MenuTab = props => (
  <Link to={`/analytics/${props.page}`} className={`menu-tab ${props.active ? 'active' : ''}`}>
    {props.children}
    <IconChevronRight />
  </Link>
);

const MenuModal = props => (
  <div className={`menu-tab`} onClick={props.onClick}>
    {props.children}
    <IconDownload />
  </div>
);

class Analytics extends Component {
  state = {
    loading: false,
    xdrStatsModal: false,
    page: this.props.page || 'activity'
  };
  scrollRef = null;
  componentDidUpdate = prevProps => {
    const prevId = prevProps.page;
    const newId = this.props.page;
    if (prevId !== newId)
      this.setState({ page: newId }, () => {
        this.scrollRef.scrollTo({ top: 0 });
      });
  };
  showXDRStatsModal = () => this.setState({ xdrStatsModal: true });
  hideXDRStatsModal = () => this.setState({ xdrStatsModal: false });
  render() {
    const { page, xdrStatsModal } = this.state;

    const pageProps = {
      client: this.props.client,
      campaigns: this.props.campaigns
    };

    return (
      <div id="company-analytics">
        <div className="analytics-menu">
          <div className="menu-title">
            <span>Analytics</span>
          </div>
          <div className="menu-tabs">
            <MenuTab active={page === 'activity'} page="activity">
              Activity
            </MenuTab>
            <MenuTab active={page === 'connectivity'} page="connectivity">
              Connectivity
            </MenuTab>
            <MenuTab active={page === 'call-recordings'} page="call-recordings">
              Call recordings
            </MenuTab>
            <MenuTab active={page === 'meetings'} page="meetings">
              Scheduled Meetings
            </MenuTab>
          </div>
          <div className="menu-title menu-space">
            <span>Reports</span>
          </div>
          <div className="menu-tabs">
            <MenuTab active={page === 'progress'} page="progress">
              Campaign progress
            </MenuTab>
            <MenuTab active={page === 'scorecard'} page="scorecard">
              XDR Scorecard
            </MenuTab>
            <MenuTab active={page === 'leads'} page="leads">
              Leads breakdown
            </MenuTab>
          </div>
           <div className="menu-title menu-space">
            <span>Exports</span>
          </div>
          <div className="menu-tabs">
            <MenuModal active={page === 'progress'} page="progress" onClick={this.showXDRStatsModal}>
              XDR Stats
            </MenuModal>
          </div>
        </div>

        <div className="analytics-main" ref={el => (this.scrollRef = el)}>
          {page === 'activity' && <Activity {...pageProps} />}
          {page === 'connectivity' && <Connectivity {...pageProps} />}
          {page === 'call-recordings' && <CallRecordings {...pageProps} />}
          {page === 'meetings' && <Meetings {...pageProps} />}
          {page === 'progress' && <Progress {...pageProps} />}
          {page === 'scorecard' && <Scorecard {...pageProps} />}
          {page === 'leads' && <LeadBreakdown {...pageProps} />}
        </div>

        { xdrStatsModal ? (
          <XDRStats
            client={this.props.client}
            removeModal={this.hideXDRStatsModal}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(Analytics);
