import React, { Component } from 'react';
import Progress from '_styleguide/Progress/Progress';
import { Card, Avatar, Button } from '_seriph';
import UserEdit from './UserEdit';
import AvatarEdit from './AvatarEdit';
import AddBox from '_shared/AddBox/AddBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faCameraRetro, faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faFacebookSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { linkify } from '_assets/js/helpers';
import './User.scss';

import { determineReputation, reputationTextIndex } from '_helpers/reputation';

const Project = props => (
  <div className="profile-project">
    {props.title}
    <span>{props.stat}</span>
  </div>
);

export default class User extends Component {
  state = {
    editor: false,
    avatarEditor: false
  };
  openEditor = () => this.setState({ editor: true });
  closeEditor = () => this.setState({ editor: false });
  openAvatarEditor = () => this.setState({ avatarEditor: true });
  closeAvatarEditor = () => this.setState({ avatarEditor: false });
  render() {
    const { agent, isEditable } = this.props;
    const reputation = agent.reputation || 1;

    let languageText =
      agent.languages && agent.languages.length > 0 ? agent.languages.slice(0, 2).join(', ') : null;
    if (agent.languages.length > 2) languageText = languageText + ', ...';

    const hasSocial =
      agent.url_website || agent.url_linkedin || agent.url_facebook || agent.url_twitter
        ? true
        : false;
    const hasOther =
      (agent.location && agent.location.name) ||
      agent.experience_short ||
      agent.employment_short ||
      languageText ||
      hasSocial
        ? true
        : false;

    const reputationIndex = determineReputation(reputation);
    const reputationClasses = [
      'earner',
      'big-earner',
      'super-earner',
      'ultra-earner',
      'extreme-earner'
    ];
    return (
      <Card id="sellx-profile-user">
        <div className="avatar-box">
          <Avatar size="giant" type="agent" picture={agent.picture} reputation={reputation} />
          {isEditable && (
            <Button type="secondary" circle onClick={this.openAvatarEditor}>
              <FontAwesomeIcon icon={faCameraRetro} />
            </Button>
          )}
        </div>
        <h1>
          {agent.first_name}&nbsp;{agent.last_name}
        </h1>
        <div className="user-rep">
          <span className={reputationClasses[reputationIndex]}>
            {reputationTextIndex(reputationIndex)}
          </span>
        </div>
        <div className="user-comp">
          <Progress percent={agent.profile_percent} />
          <h3>Profile {agent.profile_percent} Complete</h3>
        </div>
        {hasOther ? (
          <div className="user-other">
            {agent.experience_short ? (
              <Project title="Experience" stat={agent.experience_short} />
            ) : null}
            {agent.employment_short ? (
              <Project title="Availability" stat={agent.employment_short} />
            ) : null}
            {languageText ? <Project title="Language" stat={languageText} /> : null}
            {hasSocial ? (
              <div className="profile-project">
                Social
                <span className="social">
                  {agent.url_website ? (
                    <a href={linkify(agent.url_website)} target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faGlobeAmericas} />
                    </a>
                  ) : null}
                  {agent.url_linkedin ? (
                    <a href={linkify(agent.url_linkedin)} target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  ) : null}
                  {agent.url_facebook ? (
                    <a href={linkify(agent.url_facebook)} target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faFacebookSquare} />
                    </a>
                  ) : null}
                  {agent.url_twitter ? (
                    <a href={linkify(agent.url_twitter)} target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faTwitterSquare} />
                    </a>
                  ) : null}
                </span>
              </div>
            ) : null}
          </div>
        ) : null}
        {!hasOther && isEditable ? (
          <AddBox action={this.openEditor} title="Personal info" percent="10%" />
        ) : null}
        {isEditable && (
          <Button
            type="secondary"
            className="btnx-edit"
            size="tiny"
            icon="left"
            onClick={this.openEditor}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
            Edit
          </Button>
        )}
        {isEditable && agent ? (
          <UserEdit
            visible={this.state.editor}
            close={this.closeEditor}
            agent={agent}
            reload={this.props.reload}
          />
        ) : null}
        {isEditable ? (
          <AvatarEdit
            visible={this.state.avatarEditor}
            close={this.closeAvatarEditor}
            reload={this.props.reload}
          />
        ) : null}
      </Card>
    );
  }
}
