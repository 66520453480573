import React, { Component } from 'react';
import { Route, withRouter, Link, Switch } from 'react-router-dom';
import { Layout, Menu, Dropdown } from 'antd';
import { message, ProgressCircle } from '_seriph';
import { connect } from 'react-redux';
import Discover from './Discover/Discover';
import Campaigns from './Campaigns/Campaigns';
import Agents from './Agents/Agents';
import Leads from './Leads/Leads';
import Sequences from './Sequences/Sequences';
import RoundRobin from './RoundRobin/RoundRobin';
import SequenceData from './Sequences/_components/SequenceData/SequenceData';
import CampaignWizard from './CampaignWizard/CampaignWizard';
import Wizard from './Wizard/Wizard';
import Profile from './Profile/Profile';
import Dashboard from './Dashboard/Dashboard';
import Analytics from './Analytics/Analytics';
import Home from './Home/Home';
import AgentProfile from 'App/Agent/Profile/Profile';
import Notifications from '../_shared/Notifications/Notifications';
import Messaging from '../_shared/Messaging/Messaging';
import MessageRep from '../_shared/Messaging/_modals/MessageRep/MessageRep';
import SubscribeModal from './_modals/SubscribeModal/SubscribeModal';
import Settings from './Settings/Settings';
import logo from '../../_assets/img/logo_black.svg';
import { loginUser, logoutUser } from 'Store/user/user_actions';
import Verify from '../Home/Verify/Verify';
import { PublicRoutes } from '_shared/PublicRoutes/PublicRoutes';
import gql from 'graphql-tag';
import MissingAgent from 'App/Home/Missing/MissingAgent';
import Avatar from '_styleguide/Avatar/Avatar';
import { companyData } from '_graphql/queries/auth';
import { masqueradeAdmin, masqueradeOwner } from '_graphql/mutations/auth';
import { ReactComponent as SellXWordmark } from '_assets/img/sellx-wordmark.svg';
import './Company.scss';
import {
  IconBrandSafari,
  IconSmartHome,
  IconTriangleSquareCircle,
  IconRocket,
  IconChartArcs3,
  IconCirclePlus,
  IconMessageCircle,
  IconBell,
  IconUsers,
  IconReplace,
  IconArrowBackUp,
  IconFriends
} from '@tabler/icons-react';
import { hasAccess, hasAccessChanged } from '_helpers/permissions';

const { Content, Header } = Layout;

class Company extends Component {
  state = {
    searchText: '',
    campaigns: [],
    masqCompanies: [],
    applicationCount: 0,
    messageCount: 0,
    notificationCount: 0,
    integration: null,
    small: true,
    drawer: false,
    loadDrawer: false,
    counts: null,
    canCohort: false,
    company: null,
    expires: null,
    messageOwner: false
  };
  onChangeSearch = e => {
    this.setState({ searchText: e.target.value });
  };
  convoCount = obj => {
    let sum = 0;
    for (const el in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, el)) sum += parseFloat(obj[el]);
    }
    return sum;
  };
  UNSAFE_componentWillMount = () => {
    this.getCampaigns();
    this.getCompanyData();
  };
  getCompanyData = () => {
    this.props.client.query({ query: companyData }).then(result => {
      if (result && result.data && result.data.companyData) {
        const resultData = result.data.companyData;
        if (resultData.employee && resultData.employee.permission_type) {
          hasAccessChanged(resultData.employee.permission_type, () => this.props.logoutUser());
        }
        this.setState({
          counts: resultData,
          applicationCount: resultData.application_count || 0,
          messageCount: resultData.message_count || 0,
          notificationCount: resultData.notification_count || 0,
          integration: resultData.integration,
          company: resultData.company,
          canCohort: resultData.can_cohort,
          expires: resultData.expires,
          expires_period: parseInt(resultData.expires_period) * 24 * 60 * 60,
          locked_type: resultData.company?.locked_type || null
        });
      }
    });
  };
  getCampaigns = () => {
    this.props.client
      .query({
        query: gql`
          {
            campaigns {
              id
              name
              objective
              required {
                field
                type
                values
                locations {
                  name
                  lat
                  lng
                }
                min
                max
              }
              company {
                name
                logo
              }
              status
              sub_status
              invited_reps
              added_reps
              invites
              agents {
                agent_id
                active
              }
              target_experience_level
              target_skills
              target_languages
              target_locations {
                lat
                lng
              }
            }
          }
        `
      })
      .then(result => {
        const campaigns = result.data.campaigns || [];
        this.setState({ campaigns });
      });
  };
  masqOwner = () => {
    this.props.client
      .mutate({ mutation: masqueradeOwner })
      .then(result => {
        if (result && result.data && result.data.masqueradeOwner) {
          this.props.loginUser(result.data.masqueradeOwner);
          this.props.history.push('/');
        } else {
          throw new Error('Could not login as rep, try again');
        }
      })
      .catch(err => message.error(err.message));
  };
  masqAdmin = () => {
    this.props.client
      .mutate({ mutation: masqueradeAdmin })
      .then(result => {
        if (result && result.data && result.data.masqueradeAdmin) {
          this.props.loginUser(result.data.masqueradeAdmin);
          this.props.history.push('/companies');
        } else {
          throw new Error('Could not login as admin, try again');
        }
      })
      .catch(err => message.error(err.message));
  };
  logoutCallback = () => this.props.history.push('/');
  makeSmall = () => this.setState({ small: true });
  closeDrawer = () => this.setState({ drawer: false });
  closeAllDrawers = () =>
    this.setState({
      drawer: false,
      loadDrawer: false,
      messagingDrawer: false
    });
  showMessageOwnerModal = () => {
    this.closeAllDrawers();
    this.setState({ messageOwner: true });
  };
  removeMessageOwnerModal = () => this.setState({ messageOwner: false });
  loadNotifications = () => {
    this.setState({ drawer: true, loadDrawer: true, messagingDrawer: false }, () => {
      document.body.classList.remove('messaging-open');
    });
  };
  loadMessaging = messageFromDiscover => {
    this.setState(
      {
        messagingDrawer: true,
        drawer: false,
        loadDrawer: false,
        messageFromDiscover
      },
      () => {
        document.body.classList.add('messaging-open');
        setTimeout(() => {
          this.setState({ messageFromDiscover: null });
        }, 1000);
      }
    );
  };
  closeMessaging = () => {
    this.setState({ messagingDrawer: false, closingMessaging: false }, () => {
      document.body.classList.remove('messaging-open');
    });
  };
  hideMessaging = () => {
    this.setState({ closingMessaging: true });
  };
  showSubscribeModal = () => this.setState({ subscribeModal: true });
  removeSubscribeModal = () => this.setState({ subscribeModal: false });
  render() {
    const { messageCount, applicationCount, notificationCount } = this.state;

    let currentPage = [],
      newCampaign = false,
      editCampaign = false,
      isWizard = false;
    if (this.props.location.pathname === '/') currentPage = ['home'];
    if (this.props.location.pathname === '/analytics') currentPage = ['analytics'];
    if (this.props.location.pathname === '/discover') currentPage = ['discover'];
    if (this.props.location.pathname === '/campaigns') currentPage = ['campaigns'];
    if (this.props.location.pathname === '/sales-reps') currentPage = ['sales-reps'];
    if (this.props.location.pathname === '/leads') currentPage = ['leads'];
    if (this.props.location.pathname.includes('/leads/')) currentPage = ['leads'];
    if (this.props.location.pathname.includes('/sequences')) currentPage = ['sequences'];
    if (this.props.location.pathname.includes('/round-robin')) currentPage = ['round-robin'];
    if (this.props.location.pathname.includes('/analytics')) currentPage = ['analytics'];
    if (this.props.location.pathname.includes('/wizard/')) {
      currentPage = ['wizard'];
      isWizard = true;
    }
    if (this.state.messagingDrawer) currentPage = ['messages'];
    if (this.state.loadDrawer && this.state.drawer) currentPage = ['alerts'];

    const drawerOpen = this.state.drawer || this.state.messagingDrawer;
    const isMasq = this.props.user?.masq ? true : false;

    if (this.props.location.pathname === '/wizard/new') {
      currentPage = [];
      newCampaign = true;
    }
    const re = new RegExp('^(/campaign/(.)+/edit)$');
    if (re.test(this.props.location.pathname)) editCampaign = true;
    const isOwner = this.state.company?.owner_id === this.props.user?.id ? true : false;

    const userMenu = (
      <Menu>
        { isOwner ? (
          <Menu.Item key="4" onClick={this.masqOwner}>
            Switch to Sales Rep
          </Menu.Item>
        ) : null }
        <Menu.Item key="0">
          <Link to="/company">Edit Profile</Link>
        </Menu.Item>
        <Menu.Item key="1">
          <Link to="/settings/account">Manage Account</Link>
        </Menu.Item>
        <Menu.Item
          key="3"
          className="sign-out"
          onClick={() => this.props.logoutUser(this.logoutCallback)}
        >
          Sign Out
        </Menu.Item>
      </Menu>
    );

    const isPublic =
      PublicRoutes.Routes.has(this.props.location.pathname) ||
      PublicRoutes.Routes.has(this.props.location.pathname.slice(0, -1));


    const lockType = this.state.locked_type;
    let isOnTrial = lockType === 'trial' || lockType === 'trial-expired' ? true : false;
    let isExpired = lockType === 'trial-expired';
    let expiresIn = 14;
    let expiresPercent = 0;
    if (isOnTrial && this.state.expires_period) {
      expiresPercent = 1 - this.state.expires / this.state.expires_period;
      expiresIn = Math.floor(this.state.expires / 86400);
    }
    const subscribeModal = isExpired || this.state.subscribeModal;
    const roundRobinAccess = [
      '5fd107b7d20d5703fa0a1205',
      '64efb9916ab8c57a0f963c83'
    ].includes(this.props.user.company);


    return isPublic ? (
      PublicRoutes.Public(this.props)
    ) : (
      <React.Fragment>
        <Layout
          id="business"
          className={this.state.small && !drawerOpen ? 'small-menu' : ''}
          style={isPublic ? { display: 'none' } : {}}
        >
          <Header
            id="business-menu"
            style={isWizard ? { display: 'none' } : {}}
            onMouseEnter={() => this.setState({ small: false })}
            onMouseLeave={() => this.setState({ small: true })}
          >
            <div className="logo">
              <Link to="/">
                <div>
                  <img src={logo} alt="Logo" />
                </div>
                <div>
                  <SellXWordmark />
                </div>
              </Link>
            </div>
            <Menu mode="inline" selectedKeys={currentPage} className="main-menu">
              <Menu.Item key="home">
                <Link to="/">
                  <span className="lfa">
                    <IconSmartHome size={22} stroke={1.75} />
                  </span>
                  Home
                </Link>
              </Menu.Item>
              <Menu.Item key="discover">
                <Link to="/discover">
                  <span className="lfa">
                    <IconBrandSafari size={22} stroke={1.75} />
                  </span>
                  Discover
                </Link>
              </Menu.Item>
              <Menu.Item key="leads">
                <Link to="/leads">
                  <span className="lfa">
                    <IconTriangleSquareCircle size={22} stroke={1.75} />
                  </span>
                  Leads
                </Link>
              </Menu.Item>
              <Menu.Item key="campaigns">
                <Link to="/campaigns">
                  <span className="lfa">
                    <IconRocket size={22} stroke={1.75} />
                  </span>
                  Campaigns
                </Link>
              </Menu.Item>
              <Menu.Item key="sequences">
                <Link to="/sequences">
                  <span className="lfa">
                    <IconReplace size={22} stroke={1.75} />
                  </span>
                  Sequences
                </Link>
              </Menu.Item>
              { roundRobinAccess ? (
                <Menu.Item key="round-robin">
                  <Link to="/round-robin">
                    <span className="lfa">
                      <IconFriends size={22} stroke={1.75} />
                    </span>
                    Round Robin
                  </Link>
                </Menu.Item>
              ) : null }
              {hasAccess(['Admin', 'Manager']) && (
                <Menu.Item key="sales-reps">
                  <Link to="/sales-reps">
                    <span className="lfa">
                      <IconUsers size={22} stroke={1.75} />
                    </span>
                    Sales Reps
                    {applicationCount ? (
                      <div className="alerting">
                        <span>{applicationCount}</span>
                      </div>
                    ) : null}
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item key="analytics">
                <Link to="/analytics">
                  <span className="lfa">
                    <IconChartArcs3 size={22} stroke={1.75} />
                  </span>
                  Analytics
                </Link>
              </Menu.Item>

              {newCampaign || editCampaign ? null : (
                <Menu.Item key="newcampaign" className="menu-button" onClick={this.closeAllDrawers}>
                  <Link to="/wizard/new">
                    <span className="lfa">
                      <IconCirclePlus size={22} stroke={1.75} />
                    </span>
                    New Campaign
                  </Link>
                </Menu.Item>
              )}
              <div className="menu-line" />
              <Menu.Item key="messages">
                <div
                  className="drawer-click"
                  onClick={this.state.messagingDrawer ? this.hideMessaging : this.loadMessaging}
                >
                  <span className="lfa">
                    <IconMessageCircle size={22} stroke={1.75} />
                  </span>
                  Messaging
                  {messageCount ? (
                    <div className="alerting">
                      <span>{messageCount}</span>
                    </div>
                  ) : null}
                </div>
              </Menu.Item>
              <Menu.Item key="alerts">
                <div
                  className="drawer-click"
                  onClick={this.state.drawer ? this.closeDrawer : this.loadNotifications}
                >
                  <span className="lfa">
                    <IconBell size={22} stroke={1.75} />
                  </span>
                  Notifications
                  {notificationCount ? (
                    <div className="alerting">
                      <span>{notificationCount}</span>
                    </div>
                  ) : null}
                </div>
              </Menu.Item>
            </Menu>

            <div className="actions-menu">
              { isOnTrial ? (
                <div className="trial-period" onClick={this.showSubscribeModal}>
                  <ProgressCircle percent={isExpired ? 100 : parseInt(expiresPercent * 100)} format={() => isExpired ? '!!' : expiresIn} />
                  { isExpired ? (
                    <div className="link-text">Trial has expired</div>
                  ) : (
                    <div className="link-text">
                      { expiresIn <= 0 ? 'Trial ends today' : `Trial ends in ${expiresIn} days` }
                    </div>
                  )}
                </div>
              ) : null }
              <Dropdown
                overlayClassName={
                  this.state.small && !drawerOpen ? 'company-dropdown small' : 'company-dropdown'
                }
                overlay={userMenu}
                trigger={['click']}
                placement="topLeft"
              >
                <div className="menu-user">
                  <Avatar size="tiny" type="company" picture={this.props.user.company_logo} />
                  <div className="menu-av">
                    <div className="menu-user-name">{this.props.user.display_name}</div>
                    <div className="menu-user-company">{this.props.user.company_name}</div>
                  </div>
                </div>
              </Dropdown>
            </div>

            {this.state.loadDrawer ? (
              <Notifications
                type="company"
                small={this.state.small && !drawerOpen}
                drawer={this.state.drawer}
                closeDrawer={this.closeDrawer}
                client={this.props.client}
                user={this.props.user}
                unread={this.props.notifications}
                reload={this.getCompanyData}
              />
            ) : null}
          </Header>
          {this.state.messagingDrawer ? (
            <Messaging
              type="company"
              small={this.state.small && !drawerOpen}
              drawer={this.state.messagingDrawer}
              closeDrawer={this.closeMessaging}
              closing={this.state.closingMessaging}
              client={this.props.client}
              user={this.props.user}
              campaigns={this.state.campaigns}
              reload={this.getCompanyData}
              showMessageOwnerModal={this.showMessageOwnerModal}
              messageFromDiscover={this.state.messageFromDiscover}
            />
          ) : null}
          {this.state.messageOwner ? (
            <MessageRep
              client={this.props.client}
              reload={this.getCompanyData}
              user={this.props.user}
              removeModal={this.removeMessageOwnerModal}
              campaigns={this.state.campaigns}
            />
          ) : null}
          { subscribeModal ? (
            <SubscribeModal
              client={this.props.client}
              company={this.state.company}
              user={this.props.user}
              reload={this.getCompanyData}
              removeModal={this.removeSubscribeModal}
              canClose={isExpired ? false : true }
            />
          ) : null}
          <Content className={isWizard ? 'business-body no-menu' : 'business-body'}>
            {isMasq && (
              <div id="masq-user">
                <b>Warning</b>&nbsp;&nbsp;&nbsp;You are currently logged into a client account
                <div className="go-back" onClick={this.masqAdmin}>
                  <IconArrowBackUp />
                  Back to Admin
                </div>
              </div>
            )}
            <Switch>
              <Route
                exact
                path="/"
                render={i => (
                  <Home
                    match={i.match}
                    client={this.props.client}
                    counts={this.state.counts}
                    loadNotifications={this.loadNotifications}
                    loadMessaging={this.loadMessaging}
                    campaigns={this.state.campaigns}
                    messageCount={messageCount}
                    applicationCount={applicationCount}
                    integration={this.state.integration}
                    company={this.state.company}
                  />
                )}
              />
              <Route
                exact
                path="/analytics/:page(activity|connectivity|progress|leads|call-recordings|meetings|scorecard)?"
                render={i => (
                  <Analytics
                    myself={this.props.user}
                    client={this.props.client}
                    page={i.match.params.page}
                    match={i.match}
                    campaigns={this.state.campaigns}
                  />
                )}
              />
              <Route
                exact
                path="/dashy"
                render={i => (
                  <Dashboard
                    myself={this.props.user}
                    client={this.props.client}
                    match={i.match}
                    campaigns={this.state.campaigns}
                  />
                )}
              />
              <Route
                exact
                path="/discover"
                render={i => (
                  <Discover
                    user={this.props.user}
                    match={i.match}
                    client={this.props.client}
                    campaigns={this.state.campaigns}
                    loadMessaging={this.loadMessaging}
                    isOnTrial={isOnTrial}
                  />
                )}
              />
              <Route
                exact
                path="/verify"
                render={i => <Verify match={i.match} client={this.props.client} />}
              />
              {hasAccess(['Admin', 'Manager']) && (
                <Route
                  exact
                  path="/sales-reps/:tab?"
                  render={i => (
                    <Agents
                      match={i.match}
                      tab={i.match.params.tab}
                      client={this.props.client}
                      campaigns={this.state.campaigns}
                      reloadCampaigns={this.getCampaigns}
                      reloadCompany={this.getCompanyData}
                      alert={applicationCount > 0}
                      applicationCount={applicationCount}
                      company={this.state.company}
                    />
                  )}
                />
              )}
              <Route
                exact
                path="/leads"
                render={i => (
                  <Leads
                    match={i.match}
                    client={this.props.client}
                    campaigns={this.state.campaigns}
                  />
                )}
              />
              <Route
                exact
                path="/leads/:id/:tab(sequences|info|questions|email|calls|notes|texts|activity|instructions)?/:otherId?"
                render={i => (
                  <Leads
                    match={i.match}
                    client={this.props.client}
                    campaigns={this.state.campaigns}
                    leadId={i.match.params.id}
                    tab={i.match.params.tab}
                    otherId={i.match.params.otherId}
                  />
                )}
              />
              <Route
                exact
                path="/campaigns"
                render={i => (
                  <Campaigns
                    match={i.match}
                    client={this.props.client}
                    makeSmall={this.makeSmall}
                  />
                )}
              />
              <Route
                exact
                path="/campaign/new"
                render={i => (
                  <CampaignWizard
                    match={i.match}
                    client={this.props.client}
                    canCohort={this.state.canCohort}
                    isNew={true}
                    campaigns={this.state.campaigns}
                    integration={this.state.integration}
                  />
                )}
              />
              { roundRobinAccess ? (
                <Route
                  exact
                  path="/round-robin"
                  render={i => (
                    <RoundRobin
                      match={i.match}
                      sequenceId={i.match.params.id}
                      client={this.props.client}
                      canCohort={this.state.canCohort}
                    />
                  )}
                />
              ) : null }
              <Route
                exact
                path="/sequences/:id?"
                render={i => (
                  <Sequences
                    match={i.match}
                    sequenceId={i.match.params.id}
                    client={this.props.client}
                    canCohort={this.state.canCohort}
                    company={this.state.company}
                  />
                )}
              />
              <Route
                exact
                path="/sequences/:id/data"
                render={i => (
                  <SequenceData
                    match={i.match}
                    sequenceId={i.match.params.id}
                    client={this.props.client}
                    campaigns={this.state.campaigns}
                    canCohort={this.state.canCohort}
                    company={this.state.company}
                  />
                )}
              />
              <Route
                exact
                path="/campaign/:id/edit"
                render={i => (
                  <CampaignWizard
                    match={i.match}
                    client={this.props.client}
                    canCohort={this.state.canCohort}
                    campaigns={this.state.campaigns}
                    integration={this.state.integration}
                  />
                )}
              />
              <Route
                exact
                path="/wizard/:id/:tab(setup|customer|reps|import|questions|sequences|instructions|product|info|schedule|budget|threshold|review)?"
                render={i => (
                  <Wizard
                    match={i.match}
                    client={this.props.client}
                    campaignId={i.match.params.id}
                    integration={this.state.integration}
                    reloadIntegration={this.getCompanyData}
                    company={this.state.company}
                    tab={i.match.params.tab}
                  />
                )}
              />
              <Route
                exact
                path="/company"
                render={i => (
                  <Profile
                    match={i.match}
                    client={this.props.client}
                    companyId={this.props.user.company}
                  />
                )}
              />
              <Route
                exact
                path="/company/:id"
                render={i => (
                  <Profile
                    match={i.match}
                    client={this.props.client}
                    companyId={i.match.params.id}
                  />
                )}
              />
              <Route
                exact
                path="/rep/:id"
                render={i => (
                  <AgentProfile
                    match={i.match}
                    client={this.props.client}
                    agentId={i.match.params.id}
                    userRole="company"
                  />
                )}
              />
              <Route
                exact
                path="/settings/:page?"
                render={i => (
                  <Settings
                    match={i.match}
                    client={this.props.client}
                    page={i.match.params.page}
                    company={this.state.company}
                    campaigns={this.state.campaigns}
                    logoutUser={this.props.logoutUser}
                    reloadCompany={this.getCompanyData}
                    showSubscribeModal={this.showSubscribeModal}
                  />
                )}
              />
              <Route
                render={i => (
                  <MissingAgent
                    match={i.match}
                    className={this.state.small && !drawerOpen ? 'small' : 'big'}
                  />
                )}
              />
            </Switch>
          </Content>
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user, ...state.role };
};

export default withRouter(connect(mapStateToProps, { logoutUser, loginUser })(Company));
