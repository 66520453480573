import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getScheduledMeetings } from '_graphql/queries/analytics';
import { myAgents } from '_graphql/queries/company';
import CampaignDropdown from '_shared/CampaignDropdown/CampaignDropdown';
//import DateRange from '_shared/DateRange/DateRange';
import { generateRange } from '_constants/datepicker';
import { Table, Header, Select, Avatar, Button, message } from '_seriph';
import { getColumns } from './Columns';
// import moment from 'moment';
import axios from 'axios';
import { downloadFile } from '_assets/js/helpers';
import { IconTableExport } from '@tabler/icons-react';
import './Meetings.scss';

const { Option } = Select;

const generateCampaigns = campaign => {
  const isStatus = ['active', 'inactive', 'completed', 'all'].includes(campaign);
  if (isStatus) {
    return { status: campaign };
  } else {
    return { campaign_id: campaign };
  }
};

const defaultParams = {
  results: 5,
  page: 1,
  sortField: 'paid',
  sortOrder: 'descend'
};

class Meetings extends Component {
  state = {
    loading: true,
    pagination: { pageSize: 5 },
    data: [],
    hired: [],
    filters: {
      campaign_id: 'all',
      range: 'last7',
      meetingStatus: 'upcoming',
      owner: 'all'
    },
    exporting: false,
    previous: null
  };
  componentDidMount() {
    this.fetchMeetings();
    this.getHired();
  }
  fetchMeetings = async (params = defaultParams) => {
    this.setState({ loading: true });
    const range = generateRange(this.state.filters.range);
    const selected = generateCampaigns(this.state.filters.campaign_id);
    const meetingStatus = this.state.filters.meetingStatus;
    const owner = this.state.filters.owner;
    this.props.client
      .query({
        variables: { ...range, ...selected, meetingStatus, owner, ...params },
        query: getScheduledMeetings
      })
      .then(result => {
        if (result && result.data) {
          const meetings = result.data.getScheduledMeetings.data || [];
          const pagination = { ...this.state.pagination };
          pagination.total = result.data.getScheduledMeetings.pageTotal;
          pagination.totalAll = result.data.getScheduledMeetings.total;
          this.setState({
            loading: false,
            data: meetings,
            pagination,
            previous: params
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(() => this.setState({ loading: false }));
  };
  exportMeetings = () => {
    this.setState({ exporting: true });
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_PATH}/v1/meetings/export`,
      headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') },
    })
      .then(res => {
        if (res && res.data && res.data.csv) {
          downloadFile(
            `sellx_meetings_${res.data.amount}_export.csv`,
            'data:text/csv;charset=UTF-8,\uFEFF' + encodeURIComponent(res.data.csv)
          );
          message.success('Meetings (' + res.data.amount + ') successfully exported');
          this.setState({ exporting: false });
        } else {
          this.setState({ exporting: false });
          message.error('Export failed, try again');
        }
      })
      .catch(() => {
        message.error('Export failed, try again');
        this.setState({ exporting: false });
      });
  };
  getHired = () => {
    this.props.client.query({ variables: { status: 'all' }, query: myAgents }).then(result => {
      if (result?.data?.hiredAgents) {
        this.setState({ hired: result.data.hiredAgents || [] });
      }
    });
  };
  selectCampaign = campaign_id => {
    const filters = { ...this.state.filters, campaign_id };
    this.setState({ filters }, this.fetchMeetings);
  };
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetchMeetings({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order
    });
  };
  selectRange = range =>
    this.setState({ filters: { ...this.state.filters, range } }, this.fetchMeetings);
  updateStatus = meetingStatus =>
    this.setState({ filters: { ...this.state.filters, meetingStatus } }, this.fetchMeetings);
  updateOwner = owner =>
    this.setState({ filters: { ...this.state.filters, owner } }, this.fetchMeetings);
  UNSAFE_componentWillMount = () => window.scrollTo({ top: 0 });
  render() {
    const { data, pagination, loading, filters, hired, exporting } = this.state;

    const columns = getColumns(this.props.campaigns);

    return (
      <div id="company-analytics-meetings">
        <div className="seriph-header-wrapper">
          <Header type="display" weight="600" size="4">
            Scheduled Meetings
          </Header>
        </div>

        <div className="dashboard-actions">
          <span className="no-left">Show meetings from</span>
          <CampaignDropdown
            allowAll={true}
            placement="bottomLeft"
            campaign={filters.campaign_id}
            campaigns={this.props.campaigns}
            selectCampaign={this.selectCampaign}
          />
          <span>booked by</span>
          {/*          <DateRange range={filters.range} selectRange={this.selectRange} />
          <span>from</span>*/}
          <Select value={filters.owner} onChange={this.updateOwner}>
            <Option value="all">All reps</Option>
            {hired.map(r => (
              <Option value={r.agent?.id} key={'a-' + r.agent?.id}>
                <div className="rep-select-me">
                  <Avatar size="micro" type="company" picture={r.agent?.picture} />
                  {r.agent?.display_name}
                </div>
              </Option>
            ))}
          </Select>
          {/*          <span>that are</span>
          <Select value={filters.meetingStatus} onChange={this.updateStatus}>
            <Option value="upcoming"><Tag type="default">Upcoming</Tag></Option>
            <Option value="disputed"><Tag type="danger">Disputed</Tag></Option>
            <Option value="complete"><Tag type="primary">Completed</Tag></Option>
          </Select>
*/}
          <div className="export-btn">
            <Button icon="left" size="medium" loading={exporting} onClick={this.exportMeetings}>
              <IconTableExport />Export all
            </Button>
          </div>
        </div>

        <div className="meetings-table">
          <Table
            lined
            rounded
            rowSelection={null}
            columns={columns}
            rowKey={record => record.id}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={this.handleTableChange}
            scroll={{ x: 'max-content' }}
            locale={{
              emptyText: loading ? 'Loading scheduled meetings...' : 'No scheduled meetings found'
            }}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(Meetings);
