function safeToString(x) {
  switch (typeof x) {
    case 'object':
      return '';
    case 'function':
      return '';
    default:
      return x + '';
  }
}

function toTitleCase(str) {
  return safeToString(str).replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export default function (template, sequence) {
  const { lead, company, rep } = sequence;
  const replaceInfo = { lead: lead || {}, company: company || {}, rep: rep || {} };
  const preview = {
    content: template.content + '',
    information: template.information + '',
    subject: template.subject + ''
  };
  const content = template.content + template.information + template.subject + '';
  (content.match(/\{\{(.*?)\}\}/g) || []).forEach(fullTag => {
    const parts = fullTag.replace('{{', '').replace('}}', '').split('.');
    const [tagType, tagField] = parts;
    const valueToReplace = replaceInfo[tagType][tagField] || null;

    if (valueToReplace && valueToReplace.length > 0) {
      const properValueCase = toTitleCase(valueToReplace);
      preview.content = preview.content.replace(new RegExp(fullTag, 'g'), properValueCase);
      preview.information = preview.information.replace(new RegExp(fullTag, 'g'), properValueCase);
      preview.subject = preview.subject.replace(new RegExp(fullTag, 'g'), properValueCase);
    }
  });
  return preview;
}
