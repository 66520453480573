import gql from 'graphql-tag';

export const createSequence = gql`
  mutation createSequence($name: String!) {
    createSequence(name: $name)
  }
`;

export const removeSequence = gql`
  mutation removeSequence($id: String!) {
    removeSequence(id: $id)
  }
`;

export const saveSequence = gql`
  mutation saveSequence($sequence: SequenceInput!) {
    saveSequence(sequence: $sequence)
  }
`;

export const cloneSequence = gql`
  mutation cloneSequence($id: String!) {
    cloneSequence(id: $id)
  }
`;

export const removeTouchpoint = gql`
  mutation removeTouchpoint($id: String!) {
    removeTouchpoint(id: $id)
  }
`;

export const saveTouchpoint = gql`
  mutation saveTouchpoint($touchpoint: TouchpointInput!) {
    saveTouchpoint(touchpoint: $touchpoint)
  }
`;

export const createTouchpoint = gql`
  mutation createTouchpoint($touchpoint: TouchpointInput!) {
    createTouchpoint(touchpoint: $touchpoint)
  }
`;

export const unarchiveTouchpoint = gql`
  mutation unarchiveTouchpoint($id: String!) {
    unarchiveTouchpoint(id: $id)
  }
`;

export const archiveSequence = gql`
  mutation archiveSequence($id: String!) {
    archiveSequence(id: $id)
  }
`;

export const unarchiveSequence = gql`
  mutation unarchiveSequence($id: String!) {
    unarchiveSequence(id: $id)
  }
`;
