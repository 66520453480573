import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Pagination, message, Select, Input, Header } from '_seriph';
import User from './_components/User/User';
import Search from './_components/Search/Search';
import Featured from './_components/Featured/Featured';
import Recommended from './_components/Recommended/Recommended';
import CampaignOverview from '_styleguide/CampaignOverview/CampaignOverview';
import { searchCampaigns } from '_graphql/queries/campaign';
import { getNewCompanies } from '_graphql/queries/company';
import { favoriteCampaign, removeFavorite } from 'Mutations/Campaign/agents';
import Guide from '_styleguide/Guide/Guide';
import { LANGUAGES } from '_assets/js/languages';
import './Discover.scss';

import { debounce } from 'lodash';

const Option = Select.Option;

class Discover extends Component {
  state = {
    campaignId: null,
    campaigns: [],
    companies: [],
    campaignsTotal: 0,
    page: 1,
    featured: [],
    search: {},
    loadingFeatured: true,
    loadingCompanies: true,
    loadingCampaigns: true,
    searchText: '',
    isFromDiscover: false
  };
  UNSAFE_componentWillMount = () => {
    this.setState({ search: this.blankSearch() }, () => {
      this.loadCampaigns();
      this.loadCompanies();
    });
    this.props.getAgentInformation();
  };
  blankSearch = () => {
    const search = {
      name: '',
      sort: 'newest',
      lead_gen: true,
      lead_inbound: true,
      lead_outbound: true,
      exp_entry: true,
      exp_intermediate: true,
      exp_expert: true,
      availability_full: true,
      availability_part: true,
      availability_free: true,
      languages: {},
      company: { id: null, name: null }
    };
    LANGUAGES.forEach(l => {
      search.languages[l] = l === 'All Languages' ? true : false;
    });
    return search;
  };
  updateSearch = (field, value, subType) => {
    const search = Object.assign({}, this.state.search);
    if (subType) {
      search[subType][field] = value !== undefined ? value : null;
    } else {
      search[field] = value !== undefined ? value : null;
    }
    this.setState({ search, page: 1 }, () => {
      this.loadCampaigns();
    });
  };
  updateCompany = (id, name) => {
    this.removeModal();
    window.scrollTo({ top: 0 });
    const search = Object.assign({}, this.state.search);
    search.company = { id, name };
    this.setState({ search, page: 1 }, () => {
      this.loadCampaigns();
    });
  };
  changePage = page => {
    window.scrollTo({ top: 0 });
    this.setState({ page }, () => {
      this.loadCampaigns();
    });
  };
  reset = () => this.setState({ search: {} });
  showModal = (campaignId, isFromDiscover) => this.setState({ campaignId, isFromDiscover });
  removeModal = () => this.setState({ campaignId: null });
  changePreview = campaignId => {
    this.setState({ campaignId: null }, () => this.setState({ campaignId }));
  };
  loadCompanies = () => {
    if (!this.state.loadingCompanies) this.setState({ loadingCompanies: true });
    this.props.client.query({ query: getNewCompanies }).then(result => {
      if (result && result.data && result.data.newCompanies) {
        this.setState({
          companies: result.data.newCompanies || [],
          loadingCompanies: false
        });
      }
    });
  };
  loadCampaigns = debounce(() => {
    if (!this.state.loadingCampaigns) this.setState({ loadingCampaigns: true });
    this.props.client
      .query({
        variables: {
          filter: JSON.stringify(this.state.search).replaceAll('\\', ''),
          page: this.state.page
        },
        query: searchCampaigns
      })
      .then(result => {
        if (result && result.data && result.data.searchCampaigns) {
          this.setState({
            campaigns: result.data.searchCampaigns.data || [],
            campaignsTotal: result.data.searchCampaigns.total,
            loadingCampaigns: false
          });
        }
      });
  }, 300);
  addFavorite = campaign => {
    this.props.client
      .mutate({
        variables: { campaign_id: campaign.id },
        mutation: favoriteCampaign
      })
      .then(result => {
        if (result && result.data && result.data.favoriteCampaign) {
          const newFavs = result.data.favoriteCampaign.favorites || [];
          this.props.updateInfo('favorites', newFavs);
          message.success(campaign.name + ' has been added to your favorites');
        } else {
          message.error('Could not add to favorites, try again');
        }
      })
      .catch(() => message.error('Could not add to favorites, try again'));
  };
  removeFavorite = campaign => {
    this.props.client
      .mutate({
        variables: { campaign_id: campaign.id },
        mutation: removeFavorite
      })
      .then(result => {
        if (result && result.data && result.data.removeFavorite) {
          const newFavs = result.data.removeFavorite.favorites || [];
          this.props.updateInfo('favorites', newFavs);
          message.success(campaign.name + ' has been removed from your favorites');
        } else {
          message.error('Could not remove from favorites, try again');
        }
      })
      .catch(() => message.error('Could not remove from favorites, try again'));
  };
  onChangeSearch = e => {
    const newVal = e.target.value;
    const preVal = this.state.searchText;
    this.setState({ searchText: e.target.value }, () => {
      if (newVal.length > 1 && newVal?.length > preVal?.length) {
        this.updateSearch('name', newVal);
      } else if (newVal?.length < preVal?.length) {
        this.updateSearch('name', newVal);
      }
    });
  };
  render() {
    const favorites = this.props.info && this.props.info.favorites ? this.props.info.favorites : [];
    const myCampaigns =
      this.props.info && this.props.info.campaigns ? this.props.info.campaigns : [];
    const isCohort = this.props.info && this.props.info.is_cohort ? true : false;
    const reputation =
      this.props.info && this.props.info.reputation ? this.props.info.reputation : 1;

    const isProfileComplete = parseInt(this.props.info.profile_percent) >= 100 ? true : false;

    return (
      <div id="agent-discover">
        <div className="sx-header">
          <Header type="display" weight="600" size="4">
            Explore Campaigns
          </Header>
          <div className="header-actions">
            <Input
              placeholder="Search campaigns..."
              value={this.state.searchText}
              onChange={this.onChangeSearch}
            />
            <Select
              value={this.state.search.sort}
              onChange={value => this.updateSearch('sort', value)}
              style={{ width: 180 }}
            >
              <Option value="newest">
                <b>Sort:</b>Newest
              </Option>
              <Option value="highest">
                <b>Sort:</b>Per lead: Highest
              </Option>
              <Option value="lowest">
                <b>Sort:</b>Per lead: Lowest
              </Option>
            </Select>
          </div>
        </div>
        <div className="discover-search">
          <div className="discover-left">
            {this.props.info && (
              <User percent={this.props.info.profile_percent} reputation={reputation} />
            )}
            <Search
              search={this.state.search}
              updateSearch={this.updateSearch}
              updateCompany={this.updateCompany}
            />
          </div>
          <div className="discover-right">
            {isCohort && !this.state.loadingCampaigns && this.state.campaigns.length <= 0 ? (
              <div className="lead-dispute-guide">
                <Guide
                  type="alert"
                  dismissable={false}
                  id="no-cohort"
                  header="Message from SellX"
                  message="Welcome! Once your first campaign is available it will show up here for you to apply, please make sure you fill out your profile and join our Slack to get started."
                />
              </div>
            ) : null}
            {!isCohort && !this.state.loadingCampaigns ? (
              <Guide
                id="agent-explore"
                marginBottom={16}
                message="Explore campaigns and apply to the ones that match your experience and goals"
              />
            ) : null}
            {false && (
              <Featured
                companies={this.state.featured}
                loading={this.state.loadingFeatured}
                updateCompany={this.updateCompany}
              />
            )}
            <Recommended
              campaigns={this.state.campaigns}
              companies={this.state.companies}
              myCampaigns={myCampaigns}
              showModal={this.showModal}
              searchName={this.state.search.name}
              search={this.state.search}
              updateSearch={this.updateSearch}
              loading={this.state.loadingCampaigns}
              addFavorite={this.addFavorite}
              removeFavorite={this.removeFavorite}
              favorites={favorites}
              info={this.props.info}
              isProfileComplete={isProfileComplete}
            />
            {!this.state.loadingCampaigns ? (
              <div className="reco-page">
                <Pagination
                  total={this.state.campaignsTotal}
                  current={this.state.page}
                  onChange={this.changePage}
                  pageSize={10}
                />
              </div>
            ) : null}
          </div>
        </div>
        {this.state.campaignId ? (
          <CampaignOverview
            client={this.props.client}
            campaignId={this.state.campaignId}
            removeModal={this.removeModal}
            loadCampaigns={this.loadCampaigns}
            changePreview={this.changePreview}
            updateCompany={this.updateCompany}
            isProfileComplete={isProfileComplete}
            isAgent={true}
            isFromDiscover={this.state.isFromDiscover}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(Discover);
