const initialState = {
  profile: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_AGENT_PROFILE': {
      const { profile } = action.payload;
      if (profile) {
        return { ...state, profile };
      } else {
        return state;
      }
    }
    case 'UPDATE_AGENT_PROFILE': {
      const { attribute } = action.payload;
      console.log('ATTRIBUTE', attribute);
      if (attribute) {
        const profile = JSON.parse(JSON.stringify(state.profile));
        console.log(profile);
        for (let key in attribute) {
          profile[key] = attribute[key];
        }
        return { ...state, profile };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}
