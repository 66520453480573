import React, { Component } from 'react';
import { Button, Header } from '_seriph';
import { IconBrandSlack } from '@tabler/icons-react';
import './Marketing.scss';

export default class Marketing extends Component {
  render() {
    return (
      <div className="agent-marketing">
        <div className="marketing-info">
          <Header type="display" weight="600" size="4" className="marketing-title">
            Join the SellX SDR team on Slack
          </Header>
          <p>
            Stay connected with the SellX sales rep team and be the first to learn about updates in
            BETA.!
          </p>
          <a
            href="https://join.slack.com/t/sellx-sdrs/shared_invite/zt-nji1x0uv-Wb_Sw7CbFZrmozy~5TXjUQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button type="default" size="large" icon="left" onClick={this.chili}>
              <IconBrandSlack />
              Join us on Slack
            </Button>
          </a>
        </div>
        <div className="marketing-image"></div>
      </div>
    );
  }
}
