import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { leadHighlights } from '_graphql/queries/lead';
import ProgressV2 from '_styleguide/ProgressV2/ProgressV2';
import Infotip from '_shared/Infotip/Infotip';
import { getErrors, plural } from '_assets/js/helpers';
import { Header, Avatar, Loading, Select, message } from '_seriph';
import {
  IconMail,
  IconBrandLinkedin,
  IconClock,
  IconPhone,
  IconCheck,
  IconClipboardList,
  IconArrowRight,
  IconMessage
} from '@tabler/icons-react';
import CallExport from '../../_modals/CallExport/CallExport';
// import { hasAccess, hasFeature } from '_helpers/permissions';
import './LeadHighlights.scss';

const typeToIcon = {
  email: <IconMail />,
  call: <IconPhone />,
  text: <IconMessage />,
  linkedin: <IconBrandLinkedin />,
  instructions: <IconClipboardList />,
  wait: <IconClock />
};

const prefix = {
  email: 'Send',
  call: 'Call',
  text: 'Text',
  linkedin: 'Send',
  instructions: 'Review'
};
const suffix = {
  email: 'email',
  call: 'lead',
  text: 'lead',
  linkedin: 'Linkedin message',
  instructions: 'general task'
};

const HighlightCard = props => {
  const done = props.amount <= 0;

  let action = done ? null : props.updateSearchFilter;
  if (
    props.type === 'email' &&
    props.showEmailerModal &&
    (true) // Access for EVERYONE
  ) {
    action = props.showEmailerModal;
  } else if (
    props.type === 'text' &&
    props.showTexterModal &&
    (true) // Access for EVERYONE
  ) {
    action = props.showTexterModal;
  } else if (
    props.type === 'call' &&
    props.showDialer &&
    (true)
  ) {
    action = props.showDialer;
  }
  if (!props.canTakeActions) action = null;
  return (
    <div className={`hl-card ${done ? 'done' : ''}`} onClick={props.ready > 0 ? action : null}>
      <div className={`hl-icon ${props.type}`}>{done ? <IconCheck /> : typeToIcon[props.type]}</div>
      <div className="hl-info">
        <Header font="sans" size="4">
          {prefix[props.type]} {props.ready} {plural(suffix[props.type], props.ready)}
        </Header>
        <p>
          {props.queue > 0 ? (
            <span>
              <b>{props.queue}</b> waiting on other tasks,{' '}
            </span>
          ) : null}
          <span>
            <b>{props.completed}</b> completed
          </span>
        </p>
      </div>
      {props.canTakeActions && (
        <div className="hl-right">
          <IconArrowRight />
        </div>
      )}
    </div>
  );
};

class LeadHighlights extends Component {
  state = {
    loading: true,
    highlights: null,
    campaignId: 'all'
  };
  componentDidMount = () => {
    this.loadHighlights();
  };
  loadHighlights = () => {
    this.props.client
      .query({ variables: { campaign: this.state.campaignId }, query: leadHighlights })
      .then(result => {
        if (result && result.data && result.data.leadHighlights) {
          this.setState({
            loading: false,
            highlights: result.data.leadHighlights
          });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || "Could not load today's tasks, try again");
        this.setState({ loading: false });
      });
  };
  myCampaigns = () => {
    return this.props.campaigns?.filter(c => {
      let isActive = true;
      if (this.props.user.role_type === 'agent') {
        isActive = c.agents.find(a => a.agent_id === this.props.user.id && a.active === true)
          ? true
          : false;
      }
      return c.status === 'active' && isActive;
    });
  };
  updateFilter = campaignId => {
    this.setState({ campaignId }, this.loadHighlights);
  };
  progressData = () => {
    const { highlights } = this.state;
    let percent = Math.floor((highlights.completed / highlights.total) * 100);
    return [
      {
        percent: percent,
        color: '#090E15'
      }
    ];
  };
  campaignInfo = campaigns => {
    if (this.state.campaignId === 'all') {
      return (
        <b>
          {campaigns.length} {plural('campaign', campaigns.length)}
        </b>
      );
    } else {
      const campaign = campaigns.find(c => c.id.toString() === this.state.campaignId);
      return <b>{campaign.name}</b>;
    }
  };
  updateSearchFilter = type => {
    let campaignIds = [];
    if (this.state.campaignId === 'all') {
      const campaigns = this.myCampaigns();
      campaignIds = campaigns.map(c => c.id);
    } else {
      campaignIds = [this.state.campaignId];
    }
    this.props.updateFilter(
      { stages: ['in-progress'], campaigns: campaignIds, types: [], highlights: [type] },
      true
    );
  };
  showCallExportModal = data => this.setState({ callExport: data });
  removeCallExportModal = () => this.setState({ callExport: null });
  render() {
    const { loading, campaignId, highlights } = this.state;
    const { user } = this.props;

    const campaigns = this.myCampaigns();
    const isCompany = this.props.user.role_type === 'company';
    return loading ? (
      <Loading />
    ) : (
      <div id="lead-highlights">
        <Header type="display" size="3">
          Today&apos;s tasks
        </Header>
        <div className="paragraph">
          {isCompany ? (
            <p>
              <b>{user.company_name}&apos;s</b> sales reps have&nbsp;
              <b>
                {highlights.total} {plural('task', highlights.total)}
              </b>
              &nbsp;in {this.campaignInfo(campaigns)} for today
            </p>
          ) : (
            <p>
              You have&nbsp;
              <b>
                {highlights.total} {plural('task', highlights.total)}
              </b>
              &nbsp;in {this.campaignInfo(campaigns)} to complete
            </p>
          )}
        </div>

        <div className="lh-filter">
          <Select
            value={campaignId}
            onChange={val => this.updateFilter(val)}
            getPopupContainer={trigger => trigger.parentNode}
          >
            <Select.Option key="lhc-all" value="all">
              All Campaigns
            </Select.Option>
            {campaigns?.map(c => (
              <Select.Option key={'lhc-' + c.id} value={c.id}>
                <div className="lhc-option">
                  <Avatar size="micro" type="company" picture={c.company.logo} />
                  <div className="lhc-name">{c.name}</div>
                </div>
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className="lh-progress">
          <ProgressV2 progressData={this.progressData()} />
          <div className="progress-info">
            {highlights.completed} / {highlights.total}, tasks complete
          </div>
        </div>

        <div className="lh-tasks">
          {Object.keys(highlights.type_totals).map((h, i) => {
            const total = highlights.type_totals[h] || 0;
            const ready = highlights.type_ready[h] || 0;
            const queue = highlights.type_queue[h] || 0;
            const completed = highlights.type_completed[h] || 0;
            const amount = parseInt(ready + queue);
            return total > 0 ? (
              <HighlightCard
                key={'hl-card-' + i}
                type={h}
                ready={ready}
                features={this.props.info?.features || []}
                queue={queue}
                completed={completed}
                amount={amount}
                canTakeActions={user?.role_type !== 'company'}
                updateSearchFilter={() => this.updateSearchFilter('tasks_' + h)}
                showDialer={() => (ready > 0 ? this.props.showDialer(campaignId) : null)}
                showBulkEmailModal={() =>
                  this.props.showBulkEmailModal(this.state.campaignId, this.loadHighlights)
                }
                showEmailerModal={() => this.props.showEmailerModal()}
                showTexterModal={() => this.props.showTexterModal()}
                showCallExportModal={() => (ready > 0 ? this.showCallExportModal(ready) : null)}
              />
            ) : null;
          })}
        </div>

        {highlights.unread_calls > 0 || highlights.unread_emails > 0 || highlights.unread_texts > 0 ? (
          <div className="lh-follow">
            <Header font="sans" size="4">
              Follow ups
              <Infotip title="Respond to these leads immediately. They have expressed interest by calling back or responding to your email." />
            </Header>
            {highlights.unread_calls > 0 ? (
              <div className="follow-card" onClick={() => this.updateSearchFilter('unread_calls')}>
                <div className="tag missed">Missed</div>
                <p>
                  <b>{highlights.unread_calls}</b> missed calls
                </p>
              </div>
            ) : null}
            {highlights.unread_texts > 0 ? (
              <div className="follow-card" onClick={() => this.updateSearchFilter('unread_texts')}>
                <div className="tag text">Missed</div>
                <p>
                  <b>{highlights.unread_texts}</b> missed texts
                </p>
              </div>
            ) : null}
            {highlights.unread_emails > 0 ? (
              <div className="follow-card" onClick={() => this.updateSearchFilter('unread_emails')}>
                <div className="tag email">New</div>
                <p>
                  <b>{highlights.unread_emails}</b> email responses
                </p>
              </div>
            ) : null}
          </div>
        ) : null}

        {this.state.callExport ? (
          <CallExport
            client={this.props.client}
            removeModal={this.removeCallExportModal}
            agent={this.props.user}
            campaignId={campaignId}
            reloadHighlights={this.loadHighlights}
            amount={this.state.callExport}
            updateFilter={() => this.updateSearchFilter('tasks_call')}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(LeadHighlights));
