import React, { Component } from 'react';
import { 
  IconMicrophone,
  IconGridDots,
} from '@tabler/icons-react';
import moment from 'moment';
import { Button, Tooltip } from '_seriph';
import { Dropdown } from 'antd';
import './ManageCall.scss';


const DialPad = props => {
  return (
    <div id="dv3-pad">
      <div className="">
        <div className="pressed">&nbsp;{props.pressed}&nbsp;</div>
      </div>
      <div className="keys">
        <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('1')}>
          1<div>&nbsp;</div>
        </Button>
        <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('2')}>
          2<div>ABC</div>
        </Button>
        <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('3')}>
          3<div>DEF</div>
        </Button>
      </div>
      <div className="keys">
        <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('4')}>
          4<div>GHI</div>
        </Button>
        <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('5')}>
          5<div>JKL</div>
        </Button>
        <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('6')}>
          6<div>MNO</div>
        </Button>
      </div>
      <div className="keys">
        <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('7')}>
          7<div>PQRS</div>
        </Button>
        <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('8')}>
          8<div>TUV</div>
        </Button>
        <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('9')}>
          9<div>WXYZ</div>
        </Button>
      </div>
      <div className="keys">
        <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('*')}>
          *<div>&nbsp;</div>
        </Button>
        <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('0')}>
          0<div>+</div>
        </Button>
        <Button className="btnx btnx-empty btnx-large key" onClick={() => props.pressKey('#')}>
          #<div>&nbsp;</div>
        </Button>
      </div>
    </div>
  );
}

export default class ManageCall extends Component {
  state = {
    keyPadVisible: false
  };
  handleVisibleChange = keyPadVisible => {
    this.setState({ keyPadVisible });
  };
  render() {
    const { keyPadVisible } = this.state;
    const { status, time, mute, pressed } = this.props;

    const callState = status !== 'ready' && status !== 'ended' && status !== 'loading';


    return (
      <div id="manage-call-settings">
        {status === 'in-progress' && (
          <div className="timer">{moment.utc(time || 0).format('mm:ss')}</div>
        )}
        <Tooltip title="Mute">
          <Button
            type={mute ? 'danger' : 'secondary'}
            disabled={!callState}
            onClick={this.props.toggleMute}
          >
            <IconMicrophone />
          </Button>
        </Tooltip>
        <Dropdown
          overlay={<DialPad pressed={pressed} pressKey={this.props.pressKey} />}
          overlayClassName="seriph-dropdown"
          trigger={['click']}
          placement="topCenter"
          visible={keyPadVisible}
          onVisibleChange={this.handleVisibleChange}
        >
          <Tooltip title="Dialpad">
            <Button type="secondary" disabled={!callState}>
              <IconGridDots />
            </Button>
          </Tooltip>
        </Dropdown>
      </div>
    );
  }
}
