import React, { Component } from 'react';
import { Modal, Button, Upload, Select, Tag, message } from 'antd';
import { getImports } from '_graphql/queries/campaign';
import { createImport, removeImport } from '_graphql/mutations/campaign';
import csvIcon from '_assets/icons/csv.svg';
import csv from 'csvtojson';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faRocket, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { importFields } from '../../../Leads/Lead/formData';
import { LevenshteinDistance } from '_assets/js/helpers';
import ImportMapping from './_modals/ImportMapping/ImportMapping';
import { getErrors } from '_assets/js/helpers';
import './ImportLeads.scss';

const { Dragger } = Upload;

const formDataKeys = Object.keys(importFields);

export default class ImportLeads extends Component {
  state = {
    visible: true,
    importing: null,
    data: null,
    allData: null,
    fileName: null,
    dataCount: 0,
    import_id: undefined,
    imports: [],
    mappingModal: false,
    saving: false
  };
  componentDidMount() {
    this.loadImports();
  }
  loadImports = () => {
    this.props.client
      .query({ variables: { campaign_id: this.props.campaignId }, query: getImports })
      .then(result => {
        if (result && result.data && result.data.imports) {
          const imports = result.data.imports || [];
          this.props.updateLeadCount(imports);
          this.setState({ imports, import_id: undefined });
        }
      });
  };
  createImport = () => {
    this.setState({ saving: true });
    const campaign = this.props.campaigns.find(c => c.id === this.state.import_id);
    const importData = {
      name: campaign.name,
      type: 'campaign',
      import_id: this.state.import_id,
      campaign_id: this.props.campaignId,
      import_type: 'company'
    };
    this.props.client
      .mutate({ variables: { import: importData }, mutation: createImport })
      .then(result => {
        if (result && result.data && result.data.createImport) setTimeout(this.loadImports, 100);
        this.setState({ saving: false });
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Error creating import, try again.');
      });
  };
  removeImport = (e, data) => {
    e.preventDefault();
    Modal.confirm({
      title: 'Are you sure you want to remove this import?',
      content: 'This data will no longer be imported when you launch the campaign.',
      onOk: () => {
        this.props.client
          .mutate({ variables: { import_id: data.id }, mutation: removeImport })
          .then(result => {
            if (result && result.data && result.data.removeImport) {
              this.loadImports();
            }
          });
      },
      onCancel() {}
    });
  };
  defaultMappings = csvKeys => {
    const mappings = {};
    csvKeys.forEach(csvKey => {
      mappings[csvKey] = null;
      formDataKeys.forEach(fdKey => {
        if (LevenshteinDistance(csvKey, fdKey) <= 2) mappings[csvKey] = fdKey;
      });
    });
    return mappings;
  };
  updateMappings = (field, value) => {
    const data = [...this.state.data];
    data.map(d => {
      if (d.csvKey === field) d.leadProperty = value;
      return d;
    });
    this.setState({ data });
  };
  removeModal = () =>
    this.setState({
      importing: null,
      fileName: null,
      mappingModal: false,
      data: null,
      allData: null,
      dataCount: 0
    });
  render() {
    const { data, importing, dataCount, mappingModal, fileName, allData, imports, import_id } =
      this.state;
    const ready = data && importing === false;
    const props = {
      name: 'file',
      multiple: false,
      accept: '.csv',
      disabled: this.props.disabled,
      showUploadList: false,
      beforeUpload: file => {
        this.setState({ importing: true });
        const reader = new FileReader();
        reader.onload = e => {
          csv({ flatKeys: true })
            .fromString(e.target.result)
            .then(jsonObj => {
              if (jsonObj && jsonObj.length > 0) {
                const mappings = this.defaultMappings(Object.keys(jsonObj[0]));
                const amount = jsonObj.length;
                const data = Object.keys(mappings).map(csvKey => {
                  const base = {
                    csvKey: csvKey,
                    leadProperty: mappings[csvKey],
                    exampleValueOne: jsonObj[0][csvKey]
                  };
                  if (amount > 1) base.exampleValueTwo = jsonObj[1][csvKey];
                  if (amount > 2) base.exampleValueThree = jsonObj[2][csvKey];
                  return base;
                });
                this.setState({
                  importing: false,
                  fileName: file.name,
                  mappingModal: true,
                  data: data,
                  allData: jsonObj,
                  dataCount: amount
                });
              } else {
                this.setState({
                  importing: null,
                  fileName: null,
                  mappingModal: false,
                  data: null,
                  allData: null,
                  dataCount: 0
                });
              }
            });
        };
        reader.readAsText(file);
        return false;
      }
    };

    const { campaignId } = this.props;
    const campaigns = this.props.campaigns || [];
    const selectableCampaigns = campaigns.filter(c => {
      if (c.objective !== 'leadgen') return false;
      if (c.status !== 'completed') return false;
      if (c.id === campaignId) return false;
      if (imports.find(i => i.import_id === c.id)) return false;
      return true;
    });

    return (
      <div id="leads-import">
        <div className="import-list">
          {imports.map(i => (
            <Tag
              key={i.id}
              className="sx-tag"
              closable={i.imported === false}
              onClose={e => this.removeImport(e, i)}
            >
              <FontAwesomeIcon icon={i.type === 'csv' ? faFileCsv : faRocket} /> {i.name}&nbsp;(
              {i.amount})
            </Tag>
          ))}
        </div>

        {mappingModal && ready && (
          <ImportMapping
            data={data}
            allData={allData}
            fileName={fileName}
            dataCount={dataCount}
            campaignId={this.props.campaignId}
            updateMappings={this.updateMappings}
            removeModal={this.removeModal}
            client={this.props.client}
            reload={this.loadImports}
          />
        )}

        {importing === null && (
          <div className="import-options">
            <div className="select-csv">
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <img src={csvIcon} alt="" />
                </p>
                <p className="ant-upload-text">Drag & drop CSV here</p>
                <p className="ant-upload-desc">or click to browse</p>
              </Dragger>
            </div>
            {selectableCampaigns.length > 0 && (
              <React.Fragment>
                <div className="or-import">OR</div>
                <div className="select-campaign">
                  <h5>Import from Campaign: </h5>
                  <Select
                    disabled={this.props.disabled}
                    className="forms"
                    placeholder="Select a Lead Generation Campaign..."
                    dropdownClassName="sx-dropdown"
                    value={import_id}
                    onChange={import_id => this.setState({ import_id })}
                  >
                    {selectableCampaigns.map(c => (
                      <Select.Option key={'sc' + c.id} value={c.id}>
                        {c.name}
                      </Select.Option>
                    ))}
                  </Select>
                  <Button
                    onClick={this.createImport}
                    loading={this.state.saving}
                    disabled={!import_id}
                    className="btn btn-primary"
                  >
                    Add Leads
                  </Button>
                </div>
              </React.Fragment>
            )}
          </div>
        )}
        {importing === true && <FontAwesomeIcon icon={faCircleNotch} spin />}
      </div>
    );
  }
}
