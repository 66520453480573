import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export const formatEmail = n => ({
  description: (
    <p>
      New email from {n.lead.first_name} {n.lead.last_name}
    </p>
  ),
  avatar: (
    <IconWrapper>
      <FontAwesomeIcon className="email-icon" icon={faEnvelope} type="email" />
    </IconWrapper>
  ),
  link: '/settings/earnings'
});

const IconWrapper = ({ children }) => {
  return <div className="email-icon-wrapper">{children}</div>;
};
