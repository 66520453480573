import gql from 'graphql-tag';

export const updateEmployeePicture = gql`
  mutation updateEmployeePicture($picture: String!) {
    updateEmployeePicture(picture: $picture)
  }
`;

export const updateEmployeeAccount = gql`
  mutation updateEmployeeAccount(
    $first_name: String!
    $last_name: String!
    $phone_number: String
    $title: String
  ) {
    updateEmployeeAccount(
      first_name: $first_name
      last_name: $last_name
      phone_number: $phone_number
      title: $title
    )
  }
`;

export const updatePassword = gql`
  mutation updatePassword($current: String!, $new: String!) {
    updatePassword(current: $current, new: $new)
  }
`;

export const updateEmail = gql`
  mutation updateEmail($email: String!, $password: String!) {
    updateEmail(email: $email, password: $password)
  }
`;
