import gql from 'graphql-tag';

export const getAgentReview = gql`
  query ($campaign_id: ID, $agent_id: ID) {
    agentReview(campaign_id: $campaign_id, agent_id: $agent_id) {
      id
      quality_of_work
      communication
      skills
      total
      review
      updatedAt
      createdAt
    }
  }
`;
