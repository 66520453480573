import React, { Component } from 'react';
import { Alert } from 'antd';
import './Guide.scss';

export default class Guide extends Component {
  state = {
    visible: true
  };
  onClose = () => {
    const guide = this.parseGuide();
    if (this.props.id) {
      guide[this.props.id] = true;
      this.setState({ visible: false });
      localStorage.setItem('guide', JSON.stringify(guide));
    }
  };
  isVisible = () => {
    const guide = this.parseGuide();
    if (this.props.id && guide[this.props.id]) {
      return false;
    } else {
      return true;
    }
  };
  parseGuide = () => {
    let guide = {};
    try {
      guide = JSON.parse(localStorage.getItem('guide')) || {};
    } catch (e) {
      guide = {};
    }
    return guide;
  };
  render() {
    const props = { ...this.props };
    props.className = 'sxd-guide';
    if (this.props.className) props.className += ' ' + this.props.className;
    if (this.props.type === 'disputed' || this.props.type === 'alert')
      props.className += ' disputed';
    if (this.props.type === 'qualified') props.className += ' qualified';
    if (this.props.type === 'info') props.className += ' info';
    if (this.props.type === 'budget-bad') props.className += ' budget-bad';
    const isVisible = this.isVisible();
    const display = isVisible ? 'block' : 'none';
    const marginBottom = isVisible && this.state.visible ? this.props.marginBottom || 32 : 0;
    const dismissable = this.props.dismissable === false ? false : true;

    let message = this.props.message;
    if (this.props.type === 'disputed') {
      message = (
        <div className="full-message">
          <h5>Lead Disputed: Message from company</h5>
          <p>{this.props.message}</p>
        </div>
      );
    }
    if (['qualified', 'alert', 'budget-bad', 'info'].includes(this.props.type)) {
      message = (
        <div className="full-message">
          <h5>{this.props.header}</h5>
          <p>{this.props.message}</p>
        </div>
      );
    }

    return (
      <div className={props.className} style={{ display, marginBottom }}>
        <Alert message={message} closable={dismissable} onClose={this.onClose} />
        <div className="bar" />
      </div>
    );
  }
}
