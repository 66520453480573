import React, { Component } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { numberWithCommas } from '_assets/js/helpers';
import './TaskChart.scss';

export default class TaskChart extends Component {
  customTooltip = data => {
    const payload = data.payload || [];
    return (
      <div className="chart-tooltip">
        <div className="tip-box">
          <div className="tip-title">{data.label}</div>
          <div className="tip-contents">
            {payload.map((p, i) => (
              <div key={`tip-${i}`} className="tip-item">
                <div className="tip-circle" style={{ backgroundColor: p.stroke }} />
                <div className="tip-name">{p.name}</div>
                <div className="tip-value">{numberWithCommas(p.value) || '0'}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  render() {
    const { data } = this.props;

    const tickCount = data && data.length > 0 ? data[0].data.length : 0;
    const interval = tickCount > 7 ? Math.ceil(tickCount / 7) : 0;
    return (
      <div id="task-chart">
        <ResponsiveContainer height={360} width="99%">
          <LineChart margin={{ right: 32 }}>
            <CartesianGrid stroke="#E7ECF4" strokeDasharray="0 0" vertical={false} />
            <XAxis
              interval={interval}
              tickLine={false}
              axisLine={false}
              dataKey="name"
              type="category"
              allowDuplicatedCategory={false}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              dataKey="value"
              domain={[0, 'dataMax + 2']}
              allowDecimals={false}
              width={40}
            />
            <Tooltip content={this.customTooltip} />
            {data.map(s => (
              <Line
                dataKey="value"
                data={s.data}
                name={s.name}
                key={s.name}
                {...s.settings}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
