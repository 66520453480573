import React from 'react';
import { plural } from '_assets/js/helpers';
import { Tooltip } from '_seriph';
import SequenceActions from '../_components/SequenceActions/SequenceActions';

export const getColumns = (reloadSequences, cloneSequence) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (c, r) => {
        const campaigns = r.campaigns || [];
        const activeCampaigns = campaigns.filter(
          c => c.status === 'active' || c.status === 'inactive'
        );
        const activeCount = activeCampaigns.length;
        const isActive = activeCount > 0;
        const isDraft = campaigns.length === 0;
        const tooltip = (
          <div className="tooltip-list">
            <div className="title">
              Live in {campaigns.length} {plural('campaign', campaigns.length)}
            </div>
            {campaigns.map(c => (
              <div key={'sq-' + c.id}>&bull;&nbsp;&nbsp;{c.name}</div>
            ))}
          </div>
        );
        return (
          <div className="sequence-box">
            <h4>
              {c}
              {isActive && (
                <Tooltip title={tooltip}>
                  <span className="active">Live</span>
                </Tooltip>
              )}
              {isDraft && <span className="draft">Draft</span>}
            </h4>
            <p>
              {r.day_count} {plural('day', r.day_count)}
              &nbsp;&nbsp;&middot;&nbsp;&nbsp;
              {r.touchpoint_count} {plural('touchpoint', r.touchpoint_count)}
            </p>
          </div>
        );
      }
    },
    // {
    //   title: 'Open rate',
    //   key: 'open_rate',
    //   width: 120,
    //   render: () => '0%'
    // },
    // {
    //   title: 'Reply rate',
    //   key: 'reply_rate',
    //   width: 120,
    //   render: () => '0%'
    // },
    // {
    //   title: 'Conversion rate',
    //   key: 'conversion_rate',
    //   width: 120,
    //   render: () => '0%'
    // },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      align: 'right',
      fixed: 'right',
      render: (c, r) => {
        const campaigns = r.campaigns || [];
        const isActive = campaigns.length > 0;
        return (
          <SequenceActions
            cloneSequence={cloneSequence}
            sequence={r}
            reloadSequences={reloadSequences}
            isActive={isActive}
          />
        );
      }
    }
  ];
};
