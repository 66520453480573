import moment from 'moment';

export function humanizeDuration(durationMs) {
  let duration = moment.duration(durationMs);
  let durationString;
  const durationDays = parseInt(duration.asDays());
  const durationHours = parseInt(duration.asHours());
  const durationMins = parseInt(duration.asMinutes());
  const durationSecs = parseInt(duration.asSeconds());
  if (durationDays >= 1) {
    durationString = `${durationDays} day${durationDays > 1 ? 's' : ''}`;
  } else if (durationHours >= 1) {
    durationString = `${durationHours} hr${durationHours > 1 ? 's' : ''}`;
  } else if (durationMins >= 1) {
    durationString = `${durationMins} min${durationMins > 1 ? 's' : ''}`;
  } else {
    durationString = `${durationSecs} sec${durationSecs > 1 ? 's' : ''}`;
  }
  return durationString;
}
