import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import './Card.scss';

export default class Card extends Component {
  render() {
    let editable = null,
      addable = null,
      editing = null;

    if (this.props.editable)
      editable = (
        <div className="editable" onClick={this.props.editable}>
          <FontAwesomeIcon icon={faPencilAlt} />
        </div>
      );

    if (this.props.addable)
      addable = (
        <div
          className={this.props.hoverOn ? 'addable on' : 'addable'}
          onClick={() => this.props.addable(null)}
        >
          <FontAwesomeIcon icon={faPlus} />
        </div>
      );

    if (this.props.editing) editing = <div className="editing">Editing</div>;

    return (
      <div
        id={this.props.id}
        className={this.props.className ? 'sellx-card ' + this.props.className : 'sellx-card'}
        style={{ padding: this.props.padding || 30 }}
        onClick={this.props.onClick || null}
      >
        {editing}
        {editable}
        {addable}
        {this.props.children}
      </div>
    );
  }
}
