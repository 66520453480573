import React, { Component } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { formatMoney } from '_assets/js/helpers';
import { Card, Header } from '_seriph';
import { leadColors } from '_seriph/_constants/colors';
import './EarningsChart.scss';

export default class EarningsChart extends Component {
  custom = data => {
    const payload =
      data.payload && data.payload[0] && data.payload[0].payload ? data.payload[0].payload : {};
    return (
      <div className="sellx-tooltip">
        <div className="tip-box">
          <div className="tip-title">{payload.name}</div>
          <div className="tip-contents">
            <div className="tip-item">
              <div className="tip-name">Total</div>
              <div className="tip-value">{formatMoney(payload.Total)}</div>
            </div>
            <div className="tip-item">
              <div className="tip-name">Generated</div>
              <div className="tip-value">{formatMoney(payload.Generated)}</div>
            </div>
            <div className="tip-item">
              <div className="tip-name">Qualified</div>
              <div className="tip-value">{formatMoney(payload.Qualified)}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  getEarningsUp = data => {
    const dataUp = JSON.parse(JSON.stringify(data));
    for (let i = 1; i < dataUp.length; i++) {
      const last = dataUp[i - 1];
      dataUp[i].Total = last.Total + dataUp[i].Total;
      dataUp[i].Generated = last.Generated + dataUp[i].Generated;
      dataUp[i].Qualified = last.Qualified + dataUp[i].Qualified;
    }
    return dataUp;
  };
  render() {
    const { totals, data } = this.props;

    const interval = data.length > 7 ? Math.ceil(data.length / 7) : 0;
    const showMonth = data.length > 90;

    const dataUp = this.getEarningsUp(data);

    return (
      <div className="chart-box">
        <Header size="3" font="sans">
          How much have I earned?
        </Header>
        <Card id="agent-earnings-chart">
          <div className="earnings-amounts">
            <div className="eamount">
              <h3>{formatMoney(totals.total_paid - totals.total_fees)}</h3>
              <h5>
                <span className="total" />
                Total Earnings
              </h5>
            </div>
            <div className="eamount">
              <h3>{formatMoney(totals.generated_paid - totals.generated_fees)}</h3>
              <h5>
                <span className="generated" />
                Generated
              </h5>
            </div>
            <div className="eamount">
              <h3>{formatMoney(totals.qualified_paid - totals.qualified_fees)}</h3>
              <h5>
                <span className="qualified" />
                Qualified
              </h5>
            </div>
          </div>
          <div className="earnings-chart">
            <div className="up-down">
              <ResponsiveContainer height={240} width="99%">
                <AreaChart data={data} margin={{ right: 32 }}>
                  <defs>
                    <linearGradient id="totalColor" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor="#090E15" stopOpacity={0.1} />
                      <stop offset="100%" stopColor="#090E15" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="generatedColor" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor={leadColors['generated']} stopOpacity={0.1} />
                      <stop offset="100%" stopColor={leadColors['generated']} stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="qualifiedColor" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor={leadColors['qualified']} stopOpacity={0.1} />
                      <stop offset="100%" stopColor={leadColors['qualified']} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid stroke="#E6E9EF" fill="rgba(0,0,0,0)" strokeDasharray="3 3" />
                  <XAxis
                    interval={interval}
                    tickLine={false}
                    axisLine={false}
                    dataKey={showMonth ? 'month' : 'name'}
                    type="category"
                    allowDuplicatedCategory={showMonth ? true : false}
                  />
                  <YAxis
                    tickLine={false}
                    axisLine={false}
                    dataKey="Total"
                    tickFormatter={y => `$${y}`}
                    allowDecimals={false}
                  />
                  <Tooltip content={this.custom} />
                  <Area
                    type="linear"
                    dataKey="Total"
                    stroke="#090E15"
                    fill="url(#totalColor)"
                    strokeWidth="2"
                    dot={false}
                  />
                  <Area
                    type="linear"
                    dataKey="Generated"
                    stroke={leadColors['generated']}
                    fill="url(#generatedColor)"
                    strokeWidth="2"
                    dot={false}
                  />
                  <Area
                    type="linear"
                    dataKey="Qualified"
                    stroke={leadColors['qualified']}
                    fill="url(#qualifiedColor)"
                    strokeWidth="2"
                    dot={false}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <div className="only-up">
              <ResponsiveContainer height={240} width="99%">
                <AreaChart data={dataUp} margin={{ right: 32 }}>
                  <defs>
                    <linearGradient id="totalColor" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor="#090E15" stopOpacity={0.1} />
                      <stop offset="100%" stopColor="#090E15" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="generatedColor" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor={leadColors['generated']} stopOpacity={0.1} />
                      <stop offset="100%" stopColor={leadColors['generated']} stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="qualifiedColor" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor={leadColors['qualified']} stopOpacity={0.1} />
                      <stop offset="100%" stopColor={leadColors['qualified']} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid stroke="#E6E9EF" fill="rgba(0,0,0,0)" strokeDasharray="3 3" />
                  <XAxis
                    interval={interval}
                    tickLine={false}
                    axisLine={false}
                    dataKey={showMonth ? 'month' : 'name'}
                    type="category"
                    allowDuplicatedCategory={showMonth ? true : false}
                  />
                  <YAxis
                    tickLine={false}
                    axisLine={false}
                    dataKey="Total"
                    tickFormatter={y => `$${y}`}
                    allowDecimals={false}
                  />
                  <Tooltip content={this.custom} />
                  <Area
                    type="linear"
                    dataKey="Total"
                    stroke="#090E15"
                    fill="url(#totalColor)"
                    strokeWidth="2"
                    dot={false}
                  />
                  <Area
                    type="linear"
                    dataKey="Generated"
                    stroke={leadColors['generated']}
                    fill="url(#generatedColor)"
                    strokeWidth="2"
                    dot={false}
                  />
                  <Area
                    type="linear"
                    dataKey="Qualified"
                    stroke={leadColors['qualified']}
                    fill="url(#qualifiedColor)"
                    strokeWidth="2"
                    dot={false}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}
