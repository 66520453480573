import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { weeklyEarnings } from '_graphql/queries/rep';
import { Card, Header, Table, Alert, Tabs } from '_seriph';
import { getColumns } from './Columns';
import './Stipends.scss';

const PAGE_SIZE = 10;

class Stipends extends Component {
  state = {
    loading: true,
    tab: 'all',
    data: [],
    pagination: { pageSize: PAGE_SIZE },
  };
  componentDidMount() {
    this.fetch({ results: PAGE_SIZE, page: 1 });
  }
  fetch = async (params = {}) => {
    this.setState({ loading: true });
    this.props.client
      .query({
        variables: { ...params, tab: this.state.tab },
        query: weeklyEarnings
      })
      .then(result => {
        if (result && result.data) {
          const companies = result.data.weeklyEarnings.data || [];
          const pagination = { ...this.state.pagination };
          pagination.total = result.data.weeklyEarnings.pageTotal;
          pagination.totalAll = result.data.weeklyEarnings.total;
          this.setState({
            loading: false,
            data: companies,
            pagination
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(() => this.setState({ loading: false }));
  };
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    });
  };
  changeTab = tab => {
    this.setState(
      {
        tab,
        loading: true,
        pagination: { pageSize: PAGE_SIZE },
      },
      () => {
        this.fetch({ results: PAGE_SIZE, page: 1 });
      }
    );
  };
  render() {
    const {
      data,
      loading,
      pagination
    } = this.state;

    const columns = getColumns();

    return (
      <Card id="agent-settings-weekly">
        <Header size="2" type="sans">
          Earnings History
        </Header>
        <Alert type="info">
          <b>Note - </b>
          This earnings history started tracking 10/23/2022 - any earnings before then can be found in your Payouts history.
        </Alert>
        <div className="filter-menu">
          <Tabs size="small" currentTab={this.state.tab} onChange={this.changeTab}>
            <Tabs.Tab key="all">All</Tabs.Tab>
            <Tabs.Tab key="weekly">Weekly</Tabs.Tab>
            <Tabs.Tab key="meeting">Lead Qualified</Tabs.Tab>
            <Tabs.Tab key="lead">Lead Submitted</Tabs.Tab>
            <Tabs.Tab key="bonus">Bonus</Tabs.Tab>
          </Tabs>
        </div>
        <div className="billing-table">
          <Table
            lined
            striped={true}
            loading={loading}
            locale={{ emptyText: loading ? 'Loading weekly earnings...' : 'No earnings found' }}
            dataSource={data.map(d => {
              d.key = d?.id;
              return d;
            })}
            pagination={pagination}
            onChange={this.handleTableChange}
            scroll={{ x: 'max-content' }}
            columns={columns}
          />
        </div>

      </Card>
    );
  }
}

export default withRouter(Stipends);
