import React, { Component } from 'react';
import { Modal, Button, Quill, Popconfirm } from '_seriph';
import { touchpointTypes } from '_constants/campaigns';
import { plural, formatPercent } from '_assets/js/helpers';
import { countTags } from '_helpers/smartTags';
import { IconTag, IconTrophy, IconRocket, IconPencil } from '@tabler/icons-react';
import './TouchpointOverview.scss';

export default class TouchpointOverview extends Component {
  addToSequence = () => {
    this.props.updateTouchpoint();
    this.props.hideModal();
  };
  render() {
    const { touchpoint, touchpointCampaigns } = this.props;

    const touchType = touchpointTypes[touchpoint.type];
    const style = { background: touchType.color };
    const tagCount = countTags(touchpoint.content);
    const campaignCount = touchpointCampaigns.length;

    return (
      <div id="touchpoint-overview">
        <Modal.Close onClick={this.props.hideModal} />

        <div className="over-title">{touchpoint.name}</div>

        <div className="over-content">
          <div className="over-left">
            <div className="info-item">
              <div style={style}>{touchType.icon}</div>
              {touchType.name} template
            </div>
            <div className="info-item">
              <div>
                <IconTag />
              </div>
              {tagCount} smart {plural('tag', tagCount)}
            </div>
            <div className="info-item">
              <div>
                <IconTrophy />
              </div>
              {formatPercent(touchpoint.stats.conversion_rate, 1)} success
            </div>
            <div className="info-item">
              <div>
                <IconRocket />
              </div>
              {campaignCount} active {plural('campaign', campaignCount)}
            </div>
          </div>

          <div className="over-right">
            <Quill value={touchpoint.content} showTags={true} readOnly={true} />
          </div>
        </div>

        {this.props.preview !== true ? (
          <Modal.Actions align="right">
            {touchpoint.archived && (
              <Button
                className="left"
                type="danger"
                onClick={this.props.unarchive}
                loading={this.props.unarchiving}
              >
                Unarchive
              </Button>
            )}

            {campaignCount > 0 || touchpoint.stats.has_stats ? (
              <Popconfirm
                title={`Touchpoint active in ${campaignCount} ${plural('campaign', campaignCount)}`}
                content="We recommend cloning this template if you’re making drastic changes. Editing, will alter sequence & campaign data."
                onConfirm={() => this.props.cloneTouchpoint()}
                onCancel={() => this.props.changePage('edit')}
                okText="Clone template"
                cancelText="Edit anyway"
                warning={true}
              >
                <Button className="left" type="secondary" icon="left">
                  <IconPencil />
                  Edit
                </Button>
              </Popconfirm>
            ) : (
              <Button
                className="left"
                type="secondary"
                icon="left"
                onClick={() => this.props.changePage('edit')}
              >
                <IconPencil />
                Edit
              </Button>
            )}

            <Button type="default" onClick={() => this.props.hideModal()}>
              Close
            </Button>
            {this.props.updateTouchpoint ? (
              <Button type="primary" onClick={this.addToSequence}>
                Add to Sequence
              </Button>
            ) : null}
          </Modal.Actions>
        ) : null}
      </div>
    );
  }
}
