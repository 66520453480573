import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Select, Radio, Checkbox, Popconfirm } from 'antd';
import Card from '_shared/Card/Card';
import ReactQuill from 'react-quill';
import ImportLeads from '../Components/ImportLeads/ImportLeads';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Locked } from '../Components/Locked/Locked';
import { faPlusCircle, faTrashAlt, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

// Question Types: Text, Checkbox, Radio

export default class Questionnaire extends Component {
  addQuestion = () => {
    const questions = this.props.form.questions ? [...this.props.form.questions] : [];
    questions.push({ type: 'Text', question: '', options: [] });
    this.props.updateForm('questions', questions);
  };
  removeQuestion = ndx => {
    const questions = this.props.form.questions ? [...this.props.form.questions] : [];
    questions.splice(ndx, 1);
    this.props.updateForm('questions', questions);
  };
  updateValues = (ndx, field, value) => {
    const questions = this.props.form.questions ? [...this.props.form.questions] : [];
    questions[ndx][field] = value;
    this.props.updateForm('questions', questions);
  };
  addOption = ndx => {
    const questions = this.props.form.questions ? [...this.props.form.questions] : [];
    questions[ndx].options.push('');
    this.props.updateForm('questions', questions);
  };
  updateOption = (qNdx, oNdx, value) => {
    const questions = this.props.form.questions ? [...this.props.form.questions] : [];
    questions[qNdx].options[oNdx] = value;
    this.props.updateForm('questions', questions);
  };
  removeOption = (qNdx, oNdx) => {
    const questions = this.props.form.questions ? [...this.props.form.questions] : [];
    questions[qNdx].options.splice(oNdx, 1);
    this.props.updateForm('questions', questions);
  };
  validClass = field => {
    const { validation, showValidation } = this.props;
    return !validation[field] && showValidation ? 'input-label invalid' : 'input-label';
  };
  render() {
    const questions = this.props.form.questions;
    const form = this.props.form;
    const isLocked = this.props.isLocked;
    const integration = this.props.integration;

    return (
      <div className="step-container" id="question-container">
        <Card id={this.props.id} className="step-card" padding="40px 20px 25px 20px">
          <div className="step-title">
            <h3>Qualifying Information</h3>
          </div>

          <div className="step-title step-sub">
            <h3 className={this.validClass('imports')}>
              <Locked isLocked={isLocked} />
              Import Leads to Qualify *
            </h3>
            <p>
              Upload .csv files or select leads from a previous lead generation campaign. You
              currently have {form.leads_created_count} leads already in this campaign. You can also
              download our{' '}
              <a href="/template/SellX_Import_Template.csv" download="SellX_Import_Template.csv">
                SellX CSV Import Template
              </a>{' '}
              to get started.
            </p>
          </div>
          <div className="step-form">
            <div className="step-input no-hover">
              <div className="input-entry">
                <ImportLeads
                  disabled={isLocked}
                  client={this.props.client}
                  campaignId={this.props.campaignId}
                  campaigns={this.props.campaigns}
                  updateLeadCount={this.props.updateLeadCount}
                />
              </div>
            </div>
          </div>

          <div className="step-title step-sub border-top" style={{ marginTop: '16px' }}>
            <h3 className={this.validClass('questions')}>
              <Locked isLocked={isLocked} />
              Qualify Questions *
            </h3>
            <p>These are questions an Sales Rep will use to qualify a lead.</p>
          </div>
          <div className="step-form">
            {questions.map((q, i) => {
              return (
                <div className="quest step-input" key={'quest-' + i}>
                  <div className="quest-info">
                    <Input
                      disabled={isLocked}
                      value={q.question}
                      onChange={e => this.updateValues(i, 'question', e.target.value)}
                      size="large"
                      className="forms"
                      placeholder="What is their budget?"
                    />
                    <Select
                      disabled={isLocked}
                      className="forms"
                      size="large"
                      value={q.type}
                      onChange={value => this.updateValues(i, 'type', value)}
                    >
                      <Select.Option value="Text">Short Answer</Select.Option>
                      <Select.Option value="Checkbox">Checkboxes</Select.Option>
                      <Select.Option value="Radio">Multiple Choice</Select.Option>
                    </Select>
                    {!isLocked && (
                      <Popconfirm
                        title="Are you sure remove this question?"
                        onConfirm={() => this.removeQuestion(i)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button className="btn btn-default btn-large">
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                      </Popconfirm>
                    )}
                  </div>
                  {q.type !== 'Text' && (
                    <div className="quest-options">
                      {q.options.map((o, f) => (
                        <div className="quest-option" key={'option-' + i + '-' + f}>
                          {q.type === 'Radio' && (
                            <React.Fragment>
                              <Radio />
                              <Input
                                disabled={isLocked}
                                placeholder={'Option ' + (f + 1)}
                                value={o}
                                onChange={e => this.updateOption(i, f, e.target.value)}
                                className="forms"
                              />
                            </React.Fragment>
                          )}
                          {q.type === 'Checkbox' && (
                            <React.Fragment>
                              <Checkbox />
                              <Input
                                disabled={isLocked}
                                placeholder={'Option ' + (f + 1)}
                                value={o}
                                onChange={e => this.updateOption(i, f, e.target.value)}
                                className="forms"
                              />
                            </React.Fragment>
                          )}
                          {!isLocked && (
                            <div className="remove-option" onClick={() => this.removeOption(i, f)}>
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </div>
                          )}
                        </div>
                      ))}
                      {!isLocked && (
                        <div className="add-option">
                          <Button className="btn btn-default" onClick={() => this.addOption(i)}>
                            Add Option
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="quest-sep" />
                </div>
              );
            })}

            <div className="add-question">
              <Button
                disabled={isLocked}
                className="btn btn-default btn-large"
                onClick={this.addQuestion}
              >
                <FontAwesomeIcon icon={faPlusCircle} /> Add Question
              </Button>
            </div>
          </div>

          <React.Fragment>
            <div className="step-sub border-top">
              <h3>
                Script <span className="optional">Optional</span>
              </h3>
              <p>
                Let Sales Reps know there is a specific way you want to interact with you clients by
                following your companies script.
              </p>
            </div>

            <div className="step-input step-instruct">
              <ReactQuill
                value={form.script}
                onChange={val => this.props.updateForm('script', val)}
              />
            </div>
          </React.Fragment>

          <div
            className="step-title step-sub border-top"
            style={{ marginTop: '16px' }}
            id="calendar-tip"
          >
            <h3 className={this.validClass('calendly')}>
              <Locked isLocked={isLocked} />
              Schedule Meetings
            </h3>
            <p>Let reps fill up your calendar with qualified leads using Calendly. </p>
          </div>
          <div className="step-form">
            <div className="step-input">
              <div className="input-entry input-icon">
                <Input
                  disabled={true}
                  value={integration ? integration.calendly.url : undefined}
                  size="large"
                  className="forms"
                  placeholder="https://calendly.com/username"
                />
                <FontAwesomeIcon className="icon-input" icon={faCalendarAlt} />
              </div>
              <div className="input-entry" style={{ marginTop: '20px' }}>
                <Checkbox
                  disabled={isLocked || (integration ? !integration.calendly.url : true)}
                  checked={form.forceCalendar}
                  onChange={e => this.props.updateForm('forceCalendar', e.target.checked)}
                >
                  Require reps to add calendar event before submitting a lead?
                </Checkbox>
              </div>
              <div className="input-label" style={{ marginTop: '16px' }}>
                * You can setup Calendly in your{' '}
                <Link to="/settings/integrations">Integration Settings</Link>
              </div>
            </div>
          </div>
        </Card>
        <div className="step-tips">&nbsp;</div>
      </div>
    );
  }
}
