import React, { Component } from 'react';
import './Benefits.scss';

export default class Benefits extends Component {
  render() {
    const { benefits } = this.props;

    return (
      <div id="seq-benefits" className="sx-form">
        <h3 className="call-h3">Key product benefits</h3>
        {benefits.map((b, i) => (
          <div className="lead-benefit" key={'ben-' + i}>
            <span>&bull;</span>
            <span>{b}</span>
          </div>
        ))}
      </div>
    );
  }
}
