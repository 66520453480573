import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Header, Button, Select, Tooltip, Loading, message } from '_seriph';
import { getSequenceWithStats, getTouchpointStats } from '_graphql/queries/sequence';
import { cloneSequence } from '_graphql/mutations/sequence';
import { getErrors, plural } from '_assets/js/helpers';
import { IconPencil, IconChevronRight, IconCopy } from '@tabler/icons-react';
import SequenceDays from './_components/SequenceDays/SequenceDays';
import NewSequenceModal from '../../_modals/NewSequenceModal/NewSequenceModal';
import './SequenceData.scss';

class SequenceData extends Component {
  state = {
    sequence: null,
    stats: null,
    loading: true,
    loadingStats: true,
    saving: false,
    campaign: 'all'
  };
  componentDidMount = () => {
    this.loadSequence();
    this.loadStats();
  };
  loadSequence = () => {
    this.props.client
      .query({ variables: { id: this.props.sequenceId }, query: getSequenceWithStats })
      .then(result => {
        if (result && result.data && result.data.getSequence) {
          const sequence = result.data.getSequence || {};
          this.setState({ sequence, loading: false });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        message.error(getErrors(err) || 'Could not load sequence, try again');
      });
  };
  loadStats = () => {
    this.props.client
      .query({
        variables: {
          sequence_id: this.props.sequenceId,
          campaign_id: this.state.campaign === 'all' ? null : this.state.campaign
        },
        query: getTouchpointStats
      })
      .then(result => {
        if (result && result.data && result.data.getTouchpointStats) {
          const stats = result.data.getTouchpointStats || {};
          this.setState({ stats, loadingStats: false });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ loadingStats: false });
        message.error(getErrors(err) || 'Could not load stats, try again');
      });
  };
  cloneSequence = () => {
    this.props.client
      .mutate({ variables: { id: this.props.sequenceId }, mutation: cloneSequence })
      .then(result => {
        if (result && result.data && result.data.cloneSequence) {
          message.success('Sequence cloned');
          this.props.history.push('/sequences/' + result.data.cloneSequence);
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Error cloning sequence, try again.');
      });
  };
  changeCampaignFilter = campaign => {
    this.setState({ campaign }, this.loadStats);
  };
  matchStats = (sequence, stats) => {
    let sequenceData = { ...sequence };
    let statsData = [...stats];

    sequenceData.days.forEach((d, dayIndex) => {
      d.touchpoints.forEach(t => {
        const statsIndex = statsData.findIndex(s => {
          return s.touchpoint_id === t.touchpoint_id && s.day === dayIndex + 1;
        });
        if (statsIndex >= 0) {
          t.stats = { ...statsData[statsIndex] };
          statsData.splice(statsIndex, 1);
        }
      });
    });

    return {
      sequence: sequenceData,
      archived: statsData
    };
  };
  showNewSequenceModal = () => this.setState({ newSequenceModal: true });
  removeNewSequenceModal = () => this.setState({ newSequenceModal: false });
  render() {
    const { sequence, stats, loading, loadingStats } = this.state;

    let sequenceData = sequence && stats ? this.matchStats(sequence, stats) : null;

    return loading || loadingStats ? (
      <Loading />
    ) : (
      <div id="sequence-data">
        <div className="breadcrumbs">
          <Link to="/sequences">Sequences</Link>
          <div className="sep">
            <IconChevronRight />
          </div>
          <div>{sequence.name}</div>
        </div>
        <div className="data-header">
          <div className="left">
            <Header size="1" font="serif">
              {sequence.name}
            </Header>
            <p>
              Below are statistics for this sequence broken down by touchpoint. Select a specific
              campaign if you would like to just see the stats for that campaign and not all
              campaigns at once.
            </p>
          </div>
          <div className="right">
            <Link to={`/sequences/${sequence.id}`}>
              <Tooltip title="Edit">
                <Button type="secondary" circle>
                  <IconPencil />
                </Button>
              </Tooltip>
            </Link>

            <Tooltip title="Clone">
              <Button type="secondary" circle onClick={this.cloneSequence}>
                <IconCopy />
              </Button>
            </Tooltip>
            <div></div>
            <Button type="primary" size="medium" onClick={this.showNewSequenceModal}>
              New Sequence
            </Button>
          </div>
        </div>
        <div className="data-subheader">
          <div className="left">
            <div className="intro">Show</div>
            <Select value={this.state.campaign} onChange={this.changeCampaignFilter}>
              <Select.Option value="all">All Campaigns</Select.Option>
              {(sequence.campaigns || []).map(c => (
                <Select.Option value={c.id} key={'cmp-' + c.id}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="right">
            <b>{sequence.day_count}</b> {plural('day', sequence.day_count)}
            &nbsp;&nbsp;&middot;&nbsp;&nbsp;
            <b>{sequence.touchpoint_count}</b> {plural('touchpoint', sequence.touchpoint_count)}
          </div>
        </div>

        <SequenceDays sequence={sequenceData.sequence} archived={sequenceData.archived} />

        {this.state.newSequenceModal ? (
          <NewSequenceModal client={this.props.client} removeModal={this.removeNewSequenceModal} />
        ) : null}
      </div>
    );
  }
}

export default withRouter(SequenceData);
