import React, { Component } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { numberWithCommas } from '_assets/js/helpers';
import { Loading } from '_seriph';
import './ConnectivityChart.scss';

export default class ConnectivityChart extends Component {
  customTooltip = data => {
    const payload = data.payload || [];
    return (
      <div className="chart-tooltip">
        {payload.map((p, i) => {
          return (
            <div key={`tip-${i}`} className="tip-box">
              <div className="tip-title">{p.payload.name}</div>
              <div className="tip-contents">
                <div className="tip-item">
                  <div className="tip-name">Calls Answered</div>
                  <div className="tip-value">{numberWithCommas(p.payload?.called_answered)}</div>
                </div>
                <div className="tip-item">
                  <div className="tip-name">Incoming Answered</div>
                  <div className="tip-value">{numberWithCommas(p.payload?.incoming_answered)}</div>
                </div>
                <div className="tip-item">
                  <div className="tip-name">Emails Replied</div>
                  <div className="tip-value">{numberWithCommas(p.payload?.replied)}</div>
                </div>
                <div className="tip-item">
                  <div className="tip-name">Emails Received</div>
                  <div className="tip-value">{numberWithCommas(p.payload?.received)}</div>
                </div>
                <div className="tip-item tip-last">
                  <div className="tip-name">Total</div>
                  <div className="tip-value">{numberWithCommas(p.value)}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const { data, range, loading } = this.props;

    const tickCount = data && data.length > 0 ? data?.length || 0 : 0;
    const interval = tickCount > 7 ? Math.ceil(tickCount / 7) : 0;
    const showMonth = data.length > 90;

    return (
      <div className="chart-box" id="connectivity-chart">
        <div className="chart-title">
          <h4>
            Connectivity<span>({range.title})</span>
          </h4>
          <p>Sum of connected calls & replied emails</p>
        </div>
        <div className="chart-display">
          {loading ? (
            <div className="chart-loading" style={{ minHeight: '360px' }}>
              <Loading />
            </div>
          ) : (
            <ResponsiveContainer height={360} width="99%">
              <AreaChart data={data} margin={{ right: 32 }}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="#D2D0FB" stopOpacity={1} />
                    <stop offset="100%" stopColor="#D2D0FB" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid stroke="#E7ECF4" fill="rgba(0,0,0,0)" strokeDasharray="3 3" />
                <XAxis
                  interval={interval}
                  tickLine={false}
                  axisLine={false}
                  dataKey={showMonth ? 'month' : 'name'}
                  type="category"
                  allowDuplicatedCategory={showMonth ? true : false}
                />
                <YAxis
                  tickLine={false}
                  axisLine={false}
                  dataKey="Amount"
                  domain={[0, 'dataMax + 2']}
                  allowDecimals={false}
                />
                <Tooltip content={this.customTooltip} />
                <Area
                  type="linear"
                  dataKey="Amount"
                  stroke="#9E9BD8"
                  fill="url(#colorUv)"
                  strokeWidth="3"
                  dot={false}
                />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    );
  }
}
