import React, { Component } from 'react';
import { Loading, Modal, Button, Input, message } from '_seriph';
import { getErrors, stripHTML } from '_assets/js/helpers';
import { getTemplate } from '_graphql/queries/campaign';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { createNote } from '_graphql/mutations/lead';
import Icons from 'App/_shared/Icons/Icons';
import { convertTagsOnly } from '_helpers/smartTags';
import './LinkedIn.scss';

export default class LinkedIn extends Component {
  state = {
    saving: false,
    visible: true,
    template: null,
    loading: true,
    problem: null,
    part: 1
  };
  componentDidMount = () => {
    this.loadTemplate();
  };
  loadTemplate = () => {
    this.props.client
      .query({ variables: { template_id: this.props.templateId }, query: getTemplate })
      .then(result => {
        if (result && result.data && result.data.getTemplate) {
          const template = this.convert(result.data.getTemplate);
          this.setState({ template, loading: false });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not load instructions, try again');
        this.setState({ loading: false });
      });
  };
  convert = template => {
    const replaceInfo = {
      lead: this.props.lead || {},
      company: this.props.company || {},
      rep: this.props.user || {}
    };
    const templateWithoutSpan = convertTagsOnly(template.content);
    const preview = { content: templateWithoutSpan + '', information: template.information + '' };
    const content = templateWithoutSpan + template.information + '';
    (content.match(/\{\{(.*?)\}\}/g) || []).forEach(fullTag => {
      const parts = fullTag.replace('{{', '').replace('}}', '').split('.');
      const [tagType, tagField] = parts;
      const valueToReplace = replaceInfo[tagType][tagField] || null;
      if (valueToReplace && valueToReplace.length > 0) {
        preview.content = preview.content.replace(new RegExp(fullTag, 'g'), valueToReplace);
        preview.information = preview.information.replace(new RegExp(fullTag, 'g'), valueToReplace);
      }
    });
    return preview;
  };
  getTags = (body, subject) => {
    const content = body + subject + '',
      invalidTags = [];
    (content.match(/\{\{(.*?)\}\}/g) || []).forEach(val => invalidTags.push(val));
    return invalidTags;
  };
  copyToClipboard = () => {
    this.funky.select();
    document.execCommand('copy');
    message.success('Copied to clipboard');
  };
  goToLinkedIn = () => {
    const { lead } = this.props;
    if (lead.linkedin) {
      Object.assign(document.createElement('a'), { target: '_blank', href: lead.linkedin }).click();
      this.setState({ part: 2 });
    } else {
      message.error('LinkedIn not found for this lead, auto-completing task...');
      setTimeout(this.complete, 1000);
    }
  };
  createNote = () => {
    const lead = this.props.lead || {};
    this.setState({ saving: true });
    let note = 'Attempted to message the lead on LinkedIn but it was a Private account.';
    if (this.state.problem === 'invalid') {
      note = 'Attempted to message the lead on LinkedIn but it was the wrong/invalid account.';
    } else if (this.state.problem === 'other') {
      note = 'Attempted to message the lead on LinkedIn but ran into an issue.';
    }
    this.props.client
      .mutate({
        variables: { lead_id: lead.id, campaign_id: this.props.campaign.id, content: note },
        mutation: createNote
      })
      .then(result => {
        if (result && result.data && result.data.createNote) {
          this.complete();
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        this.setState({ saving: false });
        message.error('Error completing task, try again.');
      });
  };
  complete = () => {
    if (this.props.complete) this.props.complete();
    this.hideModal();
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { loading, template, part, problem, saving } = this.state;
    const { lead } = this.props;
    return (
      <Modal
        wrapClassName="sequence-linkedin-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={true}
        width={part < 2 ? 640 : 480}
      >
        <Modal.Close onClick={this.hideModal} />

        {part === 1 ? (
          <div className="part-one">
            <div className="seq-header">
              <Icons.LinkedIn />
              <h4>
                Send LinkedIn Message to {lead.first_name} {lead.last_name}
              </h4>
            </div>
            <div className="seq-body">
              {loading || !template ? (
                <Loading />
              ) : (
                <div
                  className="quill-render"
                  dangerouslySetInnerHTML={{ __html: template.content }}
                />
              )}
              <Input
                ref={nput => (this.funky = nput)}
                value={template ? stripHTML(template.content) : ''}
              />
            </div>
            <div className="seq-actions">
              <Button disabled={loading} type="default" icon="left" onClick={this.copyToClipboard}>
                <FontAwesomeIcon icon={faCopy} className="btnx-left" />
                Copy to Clipboard
              </Button>
              <Button type="primary" disabled={loading} onClick={this.goToLinkedIn}>
                Go to LinkedIn
              </Button>
            </div>
          </div>
        ) : null}
        {part === 2 ? (
          <div className="part-two">
            <div className="late-header">
              <Icons.LinkedIn />
              <div>
                <h3>Did you message lead on Linkedin?</h3>
                <p>
                  Confirm message was sent on Linkedin. If there was a problem, please let company
                  know by clicking <b>&quot;There was a problem&quot;.</b>
                </p>
              </div>
            </div>
            <div className="late-actions">
              <Button type="default" onClick={() => this.setState({ part: 3 })}>
                There was a problem
              </Button>
              <Button type="primary" onClick={this.complete}>
                Yes, message sent
              </Button>
            </div>
          </div>
        ) : null}
        {part === 3 ? (
          <div className="part-two">
            <div className="late-header">
              <Icons.LinkedIn />
              <div>
                <h3>What was the problem?</h3>
                <p>Let company know there was a problem with sending Linkedin message to lead.</p>
              </div>
            </div>
            <div className="problems">
              <div
                className={problem === 'private' ? 'active' : ''}
                onClick={() => this.setState({ problem: 'private' })}
              >
                Private Account
              </div>
              <div
                className={problem === 'invalid' ? 'active' : ''}
                onClick={() => this.setState({ problem: 'invalid' })}
              >
                Wrong/Invalid Account
              </div>
              <div
                className={problem === 'other' ? 'active' : ''}
                onClick={() => this.setState({ problem: 'other' })}
              >
                Other
              </div>
            </div>
            <div className="late-actions">
              <Button loading={saving} disabled={!problem} type="primary" onClick={this.createNote}>
                Submit
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
    );
  }
}
