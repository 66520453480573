import React from 'react';
import { IconPhone, IconMail, IconMessage, IconBrandLinkedin, IconSubtask, IconClock } from '@tabler/icons-react';

export const statusMap = {
  active: 'Active',
  inactive: 'Paused',
  draft: 'Draft',
  completed: 'Completed'
};

export const cohorts = {
  blue: 'Blue',
  red: 'Red',
  green: 'Green',
  yellow: 'Yellow',
  purple: 'Purple',
  orange: 'Orange'
};

export const durationText = {
  lifetime: 'Lifetime Campaign',
  continuous: 'Ongoing Campaign',
  weekly: 'Weekly Campaign'
};

export const durationShort = {
  lifetime: 'Lifetime',
  continuous: 'Ongoing',
  weekly: 'Weekly'
};

export const generateDuration = {
  lifetime: 'Lifetime Campaign',
  weekly: 'Weekly Campaign'
};

export const qualifyDuration = {
  lifetime: 'Lifetime Campaign',
  continuous: 'Ongoing Campaign'
};

export const touchpointTypes = {
  call: {
    name: 'Call',
    icon: <IconPhone />,
    color: '#C1DDBB'
  },
  email: {
    name: 'Email',
    icon: <IconMail />,
    color: '#D2D0FB'
  },
  text: {
    name: 'Text',
    icon: <IconMessage />,
    color: '#dfe6e9'
  },
  wait: {
    name: 'Wait',
    icon: <IconClock />,
    color: '#090E15',
    static: true
  },
  linkedin: {
    name: 'Linkedin',
    icon: <IconBrandLinkedin />,
    color: '#B0D0E8'
  },
  instructions: {
    name: 'General',
    icon: <IconSubtask />,
    color: '#CBC4B7'
  }
};

export const CAMPAIGN_TYPE_LEADGEN = 'leadgen';
export const CAMPAIGN_TYPE_QUALIFY = 'qualify';
export const LEAD_UPLOAD_TYPE_SINGLE = 'single';
export const LEAD_UPLOAD_TYPE_MULTI = 'multi';
