import { ReactComponent as Notifications } from '_assets/menu_icons/notifications.svg';
import { ReactComponent as Messages } from '_assets/menu_icons/messages.svg';
import { ReactComponent as Phone } from '_assets/icons/phone-slash.svg';
import { ReactComponent as Campaigns } from '_assets/menu_icons/campaigns.svg';
import { ReactComponent as Create } from '_assets/menu_icons/create.svg';
import { ReactComponent as Agents } from '_assets/menu_icons/agents.svg';
import { ReactComponent as Home } from '_assets/menu_icons/home.svg';
import { ReactComponent as Compass } from '_assets/menu_icons/compass.svg';
import { ReactComponent as Leads } from '_assets/menu_icons/leads.svg';
import { ReactComponent as Dashboard } from '_assets/menu_icons/dashboard.svg';
import { ReactComponent as ArrowDown } from '_assets/icons/arrow_down.svg';
import { ReactComponent as Bolt } from '_assets/icons/bolt.svg';
import { ReactComponent as NoEmail } from '_assets/icons/no_email.svg';
import { ReactComponent as Chat } from '_assets/menu_icons/chat.svg';
import { ReactComponent as Email } from '_assets/icons/template_email.svg';
import { ReactComponent as Call } from '_assets/icons/template_call.svg';
import { ReactComponent as Instructions } from '_assets/icons/template_instructions.svg';
import { ReactComponent as Wait } from '_assets/icons/template_wait.svg';
import { ReactComponent as LinkedIn } from '_assets/icons/template_linkedin.svg';
import { ReactComponent as Check } from '_assets/icons/template_check.svg';
import { ReactComponent as Flag } from '_assets/icons/template_flag.svg';
import { ReactComponent as Rocket } from '_assets/icons/rocket_square.svg';
import { ReactComponent as SalesAgents } from '_assets/icons/sales_agents.svg';
import { ReactComponent as Filter } from '_assets/icons/filter-icon.svg';
import { ReactComponent as FilterActive } from '_assets/icons/filter-active.svg';

const all = {
  Notifications,
  Messages,
  Agents,
  Phone,
  Campaigns,
  Create,
  Home,
  Compass,
  Leads,
  Dashboard,
  ArrowDown,
  Bolt,
  NoEmail,
  Email,
  Chat,
  Instructions,
  LinkedIn,
  Call,
  Wait,
  Check,
  Rocket,
  SalesAgents,
  Flag,
  Filter,
  FilterActive
};

export default all;
