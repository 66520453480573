import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { pickerFields } from '_constants/leadFields';
import { Locked } from '_shared/Locked/Locked';
import { Card, Input, InputNumber, Selection, SelectMultiple, LocationMultiple } from '_seriph';
import { IconBuilding, IconUser } from '@tabler/icons-react';
import './CustomerProfile.scss';

class CustomerProfile extends Component {
  componentDidMount = () => {
    if (this.props.save) this.props.save();
  };
  render() {
    const { campaign, update, actions, fromReview, isLocked } = this.props;

    return (
      <Card id="wizard-customer" className="wz-card">
        <div className="wz-container sx-form">
          {/* HEADER */}
          <div className="wz-card-header">
            <h3>What type of leads are you looking for?</h3>
            <h5>Reps will use this info to find leads that best match your requirements.</h5>
          </div>

          {/* CONTENT */}
          <div className={fromReview && !campaign.customer_type ? 'field invalid' : 'field'}>
            <label>
              Select a lead type
              <Locked isLocked={isLocked} />
            </label>
            <Selection
              disabled={isLocked}
              values={[
                {
                  title: 'Companies',
                  value: 'companies',
                  icon: <IconBuilding />
                },
                {
                  title: 'Individuals',
                  value: 'individuals',
                  icon: <IconUser />
                }
              ]}
              value={campaign.customer_type}
              onChange={val => update('customer_type', val)}
              full={true}
              icons
            />
          </div>

          <div
            className={fromReview && !campaign.customer_company_size ? 'field invalid' : 'field'}
          >
            <label>Company Size</label>
            <Selection
              multiple={true}
              values={[
                { title: 'Micro', description: '1 - 9', value: 'micro' },
                { title: 'Small', description: '10 - 49', value: 'small' },
                { title: 'Mid', description: '40 - 249', value: 'mid' },
                { title: 'Large', description: '250+', value: 'large' }
              ]}
              value={campaign.customer_company_size}
              onChange={val => update('customer_company_size', val)}
              full={true}
            />
          </div>

          <div
            className={
              fromReview && campaign.customer_industry.length <= 0 ? 'field invalid' : 'field'
            }
          >
            <label>Industry</label>
            <SelectMultiple
              placeholder="Search industries..."
              notFoundContent="Type to search industries..."
              autocomplete={pickerFields['industry']}
              value={campaign.customer_industry || []}
              onChange={val => update('customer_industry', val)}
            />
          </div>

          <div
            className={fromReview && !campaign.customer_decision_maker ? 'field invalid' : 'field'}
          >
            <label>Decision Maker</label>
            <Input
              placeholder="VP of Sales, CEO, etc..."
              onChange={e => update('customer_decision_maker', e.target.value)}
              value={campaign.customer_decision_maker}
            />
          </div>

          <div className="field">
            <label>
              Geography <span className="optional">(optional)</span>
            </label>
            <LocationMultiple
              allLocations
              placeholder="Search locations..."
              notFoundContent="Type to search locations..."
              autocomplete={[]}
              value={campaign.customer_locations || []}
              onChange={val => update('customer_locations', val)}
            />
          </div>

          <div className="field">
            <label>
              Revenue <span className="optional">(optional)</span>
            </label>
            <div className="field-inline">
              <span className="inline-label left">From</span>
              <InputNumber
                onChange={val => update('customer_spend_start', parseFloat(val))}
                value={campaign.customer_spend_start}
              />
              <span className="inline-label">To</span>
              <InputNumber
                type="number"
                onChange={val => update('customer_spend_end', parseFloat(val))}
                value={campaign.customer_spend_end}
              />
            </div>
          </div>
        </div>

        {/* ACTIONS */}
        {actions}
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(CustomerProfile));
