import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Loading, Button, Tooltip, message, Modal, Tabs } from '_seriph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import Setup from './_components/Setup/Setup';
import SalesReps from './_components/SalesReps/SalesReps';
import CustomerProfile from './_components/CustomerProfile/CustomerProfile';
import Instructions from './_components/Instructions/Instructions';
import LeadInformation from './_components/LeadInformation/LeadInformation';
import Schedule from './_components/Schedule/Schedule';
import Import from './_components/Import/Import';
import Questions from './_components/Questions/Questions';
// import Sequences from './_components/Sequences/Sequences';
import SequencesV2 from './_components/SequencesV2/SequencesV2';
import BenefitsObjections from './_components/BenefitsObjections/BenefitsObjections';
import Budget from './_components/Budget/Budget';
import Threshold from './_components/Threshold/Threshold';
import Review from './_components/Review/Review';
import logo from '_assets/img/logo_black.svg';
import { formData } from './_helpers/formData';
import { getFlow } from './_helpers/flows';
import { getWizard } from '_graphql/queries/campaign';
import { mySalesReps } from '_graphql/queries/campaign';
import { newCampaign, saveCampaign } from '_graphql/mutations/campaign';
import CampaignOverview from '_styleguide/CampaignOverview/CampaignOverview';
import { calculatePayMin } from './_helpers/calculations';
import { getErrors } from '_assets/js/helpers';
import './Wizard.scss';

class Wizard extends Component {
  state = {
    isCampaignLoading: true,
    isRepsLoading: true,
    saving: false,
    campaign: null,
    savedCampaign: null,
    reps: null,
    fromReview: false,
    tab: this.props.tab || 'setup'
  };
  componentDidMount() {
    this.loadReps();
    /* Reload Integration Data */
    if (this.props.reloadIntegration) this.props.reloadIntegration();

    /* Gather information */
    const { campaignId } = this.props;
    if (campaignId === 'new') {
      const tempCampaign = { ...formData };
      this.setState({
        isCampaignLoading: false,
        campaign: tempCampaign,
        savedCampaign: JSON.stringify(tempCampaign),
        tab: 'setup'
      });
    } else if (campaignId && campaignId !== 'new') {
      this.loadCampaign(campaignId);
    } else {
      this.props.history.push('/campaigns');
    }
  }
  componentDidUpdate = prevProps => {
    const prevTab = prevProps.tab ? prevProps.tab : null;
    const newTab = this.props.tab ? this.props.tab : null;
    if (prevTab !== newTab) {
      const fromReview =
        this.props.location.state && this.props.location.state.fromReview ? true : false;
      this.setState({ tab: newTab || 'setup', fromReview }, () => window.scrollTo({ top: 0 }));
    }
    if (JSON.stringify(this.state.campaign) !== this.state.savedCampaign) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };
  componentWillUnmount = () => {
    window.onbeforeunload = undefined;
  };
  loadCampaign = id => {
    this.props.client
      .query({ variables: { id: id }, query: getWizard })
      .then(result => {
        if (result && result.data && result.data.campaign) {
          this.setState({
            isCampaignLoading: false,
            campaign: result.data.campaign,
            savedCampaign: JSON.stringify(result.data.campaign)
          });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not find campaign, try again');
      });
  };
  loadReps = () => {
    this.props.client.query({ query: mySalesReps }).then(result => {
      if (result && result.data && result.data.mySalesReps) {
        this.setState({
          isRepsLoading: false,
          reps: result.data.mySalesReps
        });
      }
    });
  };
  createCampaign = () => {
    this.setState({ saving: true });
    const campaignData = this.setupPay({ ...this.state.campaign });
    this.props.client
      .mutate({ variables: { ...campaignData }, mutation: newCampaign })
      .then(result => {
        if (result && result.data && result.data.newCampaign) {
          const created = result.data.newCampaign;
          if (created.id) {
            this.props.history.push('/wizard/' + created.id);
            this.loadCampaign(created.id);
            message.success('Campaign has been created');
          } else {
            throw new Error();
          }
        }
        this.setState({ saving: false });
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Could not create campaign, try again');
      });
  };
  saveCampaign = (campaign, newRoute) => {
    this.setState({ saving: true });
    const savedCampaign = campaign ? campaign : this.state.campaign;
    this.props.client
      .mutate({
        variables: { campaign: this.cleanCampaign(savedCampaign) },
        mutation: saveCampaign
      })
      .then(result => {
        if (result && result.data && result.data.saveCampaign) {
          this.setState(
            { campaign: savedCampaign, savedCampaign: JSON.stringify(savedCampaign) },
            () => {
              if (newRoute) this.props.history.push(newRoute);
            }
          );
          if (!campaign && !newRoute) message.success('Campaign has been saved');
        }
        this.setState({ saving: false });
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Could not save campaign, try again');
      });
  };
  setupPay = c => {
    if (c.objective === 'qualify') {
      c.budget_ppql = calculatePayMin(c);
    } else if (c.objective === 'leadgen') {
      c.budget_ppl = calculatePayMin(c);
    }
    return c;
  };
  cleanCampaign = () => {
    const tempCampaign = { ...this.state.campaign, required: [...this.state.campaign.required] };
    delete tempCampaign.status;
    delete tempCampaign.threshold;
    for (const requiredField of tempCampaign.required) {
      delete requiredField.icon;
    }
    return tempCampaign;
  };
  updateCampaign = (field, value, save) => {
    const campaign = Object.assign({}, this.state.campaign);
    campaign[field] = value !== undefined ? value : null;
    if (save) {
      this.saveCampaign(campaign);
    } else {
      this.setState({ campaign });
    }
  };
  saveAndContinue = newRoute => {
    this.saveCampaign(null, newRoute);
  };
  updateCampaignMultiple = fieldValues => {
    const campaign = Object.assign({}, this.state.campaign);
    Object.keys(fieldValues).forEach(k => {
      campaign[k] = fieldValues[k] !== undefined ? fieldValues[k] : null;
    });
    this.setState({ campaign });
  };
  updateCampaignObject = (field, value, save) => {
    const campaign = Object.assign({}, this.state.campaign);
    const fieldItems = field.split('.');
    campaign[fieldItems[0]][fieldItems[1]] = value !== undefined ? value : null;
    if (save) {
      this.saveCampaign(campaign);
    } else {
      this.setState({ campaign });
    }
  };
  discardChanges = () => {
    Modal.confirm({
      title: `Are you sure you want to discard changes?`,
      content: 'Any changes made so far will be lost and cannot be recovered.',
      onOk: () => {
        const oldCampaign = JSON.parse(this.state.savedCampaign);
        this.setState({
          campaign: oldCampaign,
          savedCampaign: JSON.stringify(oldCampaign)
        });
      }
    });
  };
  getTabLink = page => {
    const { campaignId } = this.props;
    return `/wizard/${campaignId}${page && page !== 'setup' ? '/' + page : ''}`;
  };
  showPreviewModal = () => this.setState({ showPreview: true });
  removePreviewModal = () => this.setState({ showPreview: false });
  render() {
    const {
      isCampaignLoading,
      isRepsLoading,
      saving,
      campaign,
      savedCampaign,
      reps,
      tab,
      fromReview
    } = this.state;
    const { campaignId, company, integration } = this.props;

    const edited = JSON.stringify(campaign) !== savedCampaign;
    const isNew = campaignId === 'new';

    let wizardProps = {};
    const flow = getFlow(campaign);
    if (campaign && !isCampaignLoading && !isRepsLoading) {
      const current = flow.findIndex(t => t.val === tab);
      const previous = current === 0 ? undefined : flow[current - 1];
      const next = current === flow.length - 1 ? undefined : flow[current + 1];
      const isLocked =
        campaign.status === 'active' ||
        campaign.status === 'inactive' ||
        campaign.status === 'completed';
      wizardProps = {
        campaign,
        campaignId,
        integration,
        reps,
        company,
        client: this.props.client,
        fromReview: fromReview,
        isLocked: isLocked,
        isNew: isNew,
        edited: edited,
        update: this.updateCampaign,
        updateMultiple: this.updateCampaignMultiple,
        updateObject: this.updateCampaignObject,
        save: campaignId !== 'new' && edited ? () => this.saveCampaign() : null,
        saveAndContinue: this.saveAndContinue,
        actions:
          campaignId !== 'new' ? (
            <div className={`wz-actions${edited ? ' disabled' : ''}`}>
              <div className="wz-actions-left">
                {previous ? (
                  <Link to={`/wizard/${campaignId}/${previous.val}`}>
                    <Button disabled={edited} type="secondary">
                      Back
                    </Button>
                  </Link>
                ) : null}
              </div>
              <div className="wz-actions-right">
                {next && edited ? (
                  <Button
                    type="primary"
                    icon="right"
                    onClick={() => this.saveAndContinue(`/wizard/${campaignId}/${next.val}`)}
                  >
                    Save & Continue
                    <FontAwesomeIcon icon={faLongArrowAltRight} className="btnx-right" />
                  </Button>
                ) : null}
                {next && !edited ? (
                  <Link to={`/wizard/${campaignId}/${next.val}`}>
                    <Button type="primary" icon="right">
                      Continue
                      <FontAwesomeIcon icon={faLongArrowAltRight} className="btnx-right" />
                    </Button>
                  </Link>
                ) : null}
              </div>
            </div>
          ) : (
            <div className={`wz-actions`}>
              <div className="wz-actions-left">&nbsp;</div>
              <div className="wz-actions-right">
                <Button
                  disabled={!edited}
                  loading={saving}
                  type="Primary"
                  onClick={this.createCampaign}
                >
                  Create
                </Button>
              </div>
            </div>
          )
      };
    }

    return isCampaignLoading || isRepsLoading || !campaign ? (
      <Loading />
    ) : (
      <div id="wizard">
        <div className={`wz-unsaved ${edited ? 'edited' : ''}`}>
          <img src={logo} alt="Logo" />
          <div className="unsaved-name">Unsaved changes</div>
          <div className="unsaved-actions">
            <Button type="default" onClick={this.discardChanges}>
              Discard
            </Button>
            <Button
              disabled={!isNew && !edited}
              type="primary"
              loading={saving}
              onClick={isNew ? this.createCampaign : () => this.saveCampaign()}
            >
              {isNew ? 'Create' : 'Save'}
            </Button>
          </div>
        </div>
        <div className={`wz-header ${edited ? 'edited' : ''}`}>
          <Link to="/" className="logo">
            <img src={logo} alt="Logo" />
          </Link>
          <div className="wz-actions">
            <div className="wz-left">
              <Link to="/campaigns">
                <Button type="secondary" icon="left">
                  <FontAwesomeIcon icon={faAngleLeft} className="btnx-left" />
                  Back to Campaigns
                </Button>
              </Link>
            </div>
            <div className="wz-name">{campaign.name}</div>
            {!isNew ? (
              <div className="wz-right">
                {edited ? (
                  <Tooltip title="Campaign must be saved to preview">
                    <div>
                      <Button disabled={true} type="default">
                        Preview
                      </Button>
                    </div>
                  </Tooltip>
                ) : (
                  <Button type="default" onClick={this.showPreviewModal}>
                    Preview
                  </Button>
                )}
              </div>
            ) : (
              <div className="wz-right"></div>
            )}
          </div>
          <div className="wz-menu">
            <Tabs currentTab={tab} underline>
              {flow.map(m => (
                <Tabs.Tab
                  key={m.val}
                  disabled={edited || (isNew && m.val !== 'setup')}
                  to={this.getTabLink(m.val)}
                >
                  {m.label}
                </Tabs.Tab>
              ))}
            </Tabs>
          </div>
        </div>
        <div className="wz-body">
          {tab === 'setup' ? <Setup {...wizardProps} /> : null}
          {tab === 'reps' ? <SalesReps {...wizardProps} /> : null}
          {tab === 'customer' ? <CustomerProfile {...wizardProps} /> : null}
          {tab === 'instructions' ? <Instructions {...wizardProps} /> : null}
          {tab === 'info' ? <LeadInformation {...wizardProps} /> : null}
          {tab === 'product' ? <BenefitsObjections {...wizardProps} /> : null}
          {tab === 'schedule' ? <Schedule {...wizardProps} /> : null}
          {tab === 'import' ? <Import {...wizardProps} /> : null}
          {tab === 'questions' ? <Questions {...wizardProps} /> : null}
          {tab === 'sequences' ? <SequencesV2 {...wizardProps} /> : null}
          {tab === 'budget' ? <Budget {...wizardProps} /> : null}
          {tab === 'threshold' ? <Threshold {...wizardProps} /> : null}
          {tab === 'review' ? <Review {...wizardProps} /> : null}
        </div>

        {this.state.showPreview ? (
          <CampaignOverview
            client={this.props.client}
            campaignId={this.props.campaignId}
            removeModal={this.removePreviewModal}
            preview={true}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(Wizard));
