import React, { Component } from 'react';
import { numberWithCommas, decimalToPercent } from '_assets/js/helpers';
import { Loading } from '_seriph';
import './TouchpointTable.scss';

export default class TouchpointTable extends Component {
  render() {
    const { data, campaign, loading } = this.props;

    const callTotal = data?.call?.answered + data?.call?.incoming || 0;
    const emailTotal = data?.email?.replied || 0;
    const rateTotal = callTotal + emailTotal;

    const completedTotal = (data?.email?.completed || 0) + (data?.call?.completed || 0);

    return (
      <div className="chart-box" id="progress-touchpoint-table">
        <div className="chart-title">
          <h4>
            Touchpoint conversion<span>({campaign})</span>
          </h4>
          <p>Overall activities performed in sequence(s)</p>
        </div>
        <div className="chart-table">
          {loading ? (
            <div className="chart-loading" style={{ minHeight: '360px' }}>
              <Loading />
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Touchpoint</th>
                  <th>Total outreach</th>
                  <th>Responses</th>
                  <th>Response rate</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Email</td>
                  <td>{numberWithCommas(data?.email?.completed)}</td>
                  <td>{numberWithCommas(emailTotal)}</td>
                  <td>{decimalToPercent(emailTotal / (data?.email?.completed || 0))}</td>
                </tr>
                <tr>
                  <td>Call</td>
                  <td>{numberWithCommas(data?.call?.completed)}</td>
                  <td>{numberWithCommas(callTotal)}</td>
                  <td>{decimalToPercent(callTotal / (data?.call?.completed || 0))}</td>
                </tr>
                <tr>
                  <td>LinkedIn</td>
                  <td>{numberWithCommas(data?.linkedin?.completed)}</td>
                  <td>- -</td>
                  <td>- -</td>
                </tr>
                <tr className="summary">
                  <td></td>
                  <td>Conversions</td>
                  <td>{numberWithCommas(rateTotal)}</td>
                  <td>{decimalToPercent(rateTotal / completedTotal)}</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  }
}
