const initialState = { apollo: null };

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_APOLLO_CLIENT': {
      const { apollo } = action.payload;
      if (apollo) {
        return { ...state, apollo };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}
