import React, { Component } from 'react';
import Loading from '_shared/Loading/Loading';
import { Modal, Button, Select, Checkbox } from '_seriph';
import axios from 'axios';
import HubspotLogo from '_assets/brands/hubspot.svg';
import { IconInfoCircle } from '@tabler/icons-react';
import './HubspotImport.scss';

export default class HubspotImport extends Component {
  state = {
    visible: true,
    loading: true,
    importing: false,
    selectedList: undefined,
    crm_auto_import: false,
    lists: []
  };
  componentDidMount = () => {
    this.loadLists();
  };
  loadLists = () => {
    axios
      .get(`${process.env.REACT_APP_SYNC_PATH}/lists/hubspot`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('JWT') }
      })
      .then(response => {
        if (response?.data?.lists) {
          this.setState({ lists: response.data.lists, loading: false });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ lists: [], loading: false });
      });
  };
  selectList = listId => this.setState({ selectedList: listId });
  importList = () => {
    this.setState({ importing: true });
    const { selectedList } = this.state;
    const foundList = this.state.lists.find(l => l.listId === selectedList);
    axios
      .get(`${process.env.REACT_APP_SYNC_PATH}/lists/hubspot/${foundList.listId}?objectType=${foundList.objectType}`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('JWT') }
      })
      .then(response => {
        if (response?.data) {
          this.setState({ visible: false }, () => {
            this.props.handleCsv(response.data, foundList.name, 'hubspot', this.state.crm_auto_import, foundList.listId, foundList.objectType);
          })
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ importing: false });
      });
  }
  hideModal = () => this.setState({ visible: false });
  render() {
    const { loading, lists, selectedList } = this.state;

    return (
      <Modal
        wrapClassName="hubspot-csv-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={true}
        width={456}
      >
        {loading ? (
          <Loading></Loading>
        ) : (
          <div className="crm-wrapper">
            <Modal.Close onClick={this.hideModal} />
            <span><img className="hubspot" src={HubspotLogo} title="Hubspot" alt="Hubspot" /></span>
            <h3>Import from Hubspot</h3>
            <p>Integration: <b>Enabled</b></p>
            <Select
              placeholder={lists.length <= 0 ? 'No lists found' : 'Select list to import...'}
              value={selectedList || undefined}
              onChange={listId => this.selectList(listId)}
            >
              {lists.map(l => {
                return (
                  <Select.Option key={'hublist-' + l.listId} value={l.listId}>
                    {l.name} {l?.entries > 0 ? ` (${l.entries})` : ''}
                  </Select.Option>
                );
              })}
            </Select>
            <p><IconInfoCircle />If you don&apos;t have any lists, <a href="https://knowledge.hubspot.com/lists/create-active-or-static-lists" target="_blank" rel="noopener noreferrer">create a list in Hubspot</a></p>
            <div className="auto-import">
              <Checkbox checked={this.state.crm_auto_import} onChange={e => this.setState({ crm_auto_import: e.target.checked })}>
                Auto-import new leads once per-day from Hubspot
              </Checkbox>
            </div>
            <Modal.Actions align="right">
              <Button onClick={this.hideModal}>Cancel</Button>
              <Button type="primary" onClick={this.importList} loading={this.state.importing}>
                Import list
              </Button>
            </Modal.Actions>
          </div>
        )}
      </Modal>
    );
  }
}
