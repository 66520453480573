import gql from 'graphql-tag';

export const saveAdminUser = gql`
  mutation saveAdminUser(
    $user_id: String!
    $display_name: String!
    $role: String!
    $company_ids: [String]!
  ) {
    saveAdminUser(
      user_id: $user_id
      display_name: $display_name
      role: $role
      company_ids: $company_ids
    )
  }
`;

export const createAdminUser = gql`
  mutation createAdminUser(
    $display_name: String!
    $role: String!
    $company_ids: [String]!
    $email: String!
  ) {
    createAdminUser(
      display_name: $display_name
      role: $role
      company_ids: $company_ids
      email: $email
    )
  }
`;

export const removeAdminUser = gql`
  mutation removeAdminUser($user_id: String!) {
    removeAdminUser(user_id: $user_id)
  }
`;
