import React from 'react';
import { Button, Avatar } from '_seriph';
import moment from 'moment';

export const getColumns = (
  masq,
  updateUnpaid,
  saving,
  isSuperAdmin,
  updateManager,
  saveManager,
  managers,
  onSeatsClick
) => {
  return [
    {
      title: 'Company',
      dataIndex: 'name',
      fixed: 'left',
      render: (column, data) => {
        return (
          <div className="lead-name">
            <div className="lead-photo">
              <Avatar size="small" type="company" picture={data.logo} />
            </div>
            <div className="lead-full">
              <div>{data.name}</div>
              <div>
                <a href={data.website} target="_blank" rel="noopener noreferrer">
                  {data.website}
                </a>
              </div>
              <p style={{ margin: '4px 0 0 0', fontSize: '12px' }}>
                <b>ID:&nbsp;&nbsp;</b>
                {data.id}
              </p>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      render: (column, data) => {
        const firstName = data.owner ? data.owner.first_name || 'X' : 'X';
        const lastName = data.owner ? data.owner.last_name || 'X' : 'X';
        return data.owner ? (
          <div className="lead-name">
            <div className="lead-photo">
              <Avatar size="small" type="company" picture={data.owner?.picture} />
            </div>
            <div className="lead-full">
              {firstName} {lastName} {data.owner.title ? <span className="lead-title">({data.owner.title})</span> : null}
              <div>
                <a
                  href={'mailto:' + data.owner?.user?.email}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.owner?.user?.email}
                </a>
              </div>
              <p style={{ margin: '4px 0 0 0', fontSize: '12px' }}>
                <b>ID:&nbsp;&nbsp;</b>
                {data.owner?.id}
              </p>
            </div>
          </div>
        ) : (
          'No Owner'
        );
      }
    },
    {
      title: 'Login',
      dataIndex: 'login',
      render: (column, data) => {
        return (
          <Button size="small" type="secondary" onClick={() => masq(data.owner.id)}>
            Login
          </Button>
        );
      }
    },
    {
      title: 'Unpaid?',
      dataIndex: 'unpaid',
      render: (unpaid, data) => {
        return !unpaid ? (
          <Button loading={saving} onClick={() => updateUnpaid(data.id, !unpaid)}>
            Make Unpaid
          </Button>
        ) : (
          <Button loading={saving} type="danger" onClick={() => updateUnpaid(data.id, !unpaid)}>
            Remove Unpaid
          </Button>
        );
      }
    },
    {
      title: 'Seat Allotment',
      dataIndex: 'seats',
      render: (unpaid, data) => {

        return (
          <div className="seats">
            <div className="seats-data">
              <div><b>{data.internal_seats || 0}</b>&nbsp;&nbsp;Seats</div>
              <div><b>{data.xdr_seats || 0}</b>&nbsp;&nbsp;XDRs</div>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Subscriptions',
      dataIndex: 'trial',
      render: (unpaid, data) => {


        const expireDate = data.trial_expire_date ? moment(data.trial_expire_date * 1).format('M/D/YY') : 'Grandfathered';
        const subEnds = data.active_subscription ? moment(data.stripe_subscription_end * 1).format('M/D/YY') : 'Not subscribed';

        return (
          <div className="seats">
            <div className="seats-data">
              <div>Sub: <b>{subEnds}</b></div>
              <div>Trial: <b>{expireDate}</b></div>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Manage',
      dataIndex: 'trial',
      render: (unpaid, data) => {

        return (
          <div className="seats">
            <Button loading={saving} onClick={() => onSeatsClick(data)}>
              Manage Access
            </Button>
          </div>
        );
      }
    },
  ]
};
