import React, { Component } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { numberWithCommas } from '_assets/js/helpers';
import { Loading } from '_seriph';
import { leadColors } from '_seriph/_constants/colors';
import './QualifiedLeadsChart.scss';

export default class QualifiedLeadsChart extends Component {
  state = {
    visible: {
      qualified: true,
      not_interested: true,
      qualified_review: true,
      qualified_disputed: true,
      invalid: true
    }
  };
  toggleVisible = slug => {
    const visible = { ...this.state.visible };
    visible[slug] = !visible[slug];
    this.setState({ visible });
  };
  customTooltip = data => {
    const payload = data.payload || [];
    return (
      <div className="chart-tooltip">
        <div className="tip-box">
          <div className="tip-title">{data.label}</div>
          <div className="tip-contents">
            {payload.map((p, i) => (
              <div key={`tip-${i}`} className="tip-item">
                <div className="tip-circle" style={{ backgroundColor: p.stroke }} />
                <div className="tip-name">{p.name}</div>
                <div className="tip-value">{numberWithCommas(p.value) || '0'}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  render() {
    const { visible } = this.state;
    const { totals, data, range, loading } = this.props;

    const tickCount = data && data.length > 0 ? data[0].data.length : 0;
    const interval = tickCount > 7 ? Math.ceil(tickCount / 7) : 0;

    return (
      <div className="chart-box" id="overview-qualified-leads-chart">
        <div className="chart-title">
          <h4>
            Qualified leads<span>({range.title})</span>
          </h4>
          <p>Total leads that have worked through for warm & cold campaigns.</p>
        </div>
        <div className="chart-display">
          {loading ? (
            <div className="chart-loading" style={{ minHeight: '360px' }}>
              <Loading />
            </div>
          ) : (
            <div className="chart-column">
              <div className="chart-toggles">
                <div
                  className={`chart-toggle ${!visible.qualified ? 'disabled' : ''}`}
                  onClick={() => this.toggleVisible('qualified')}
                  style={{ borderColor: leadColors['qualified'] }}
                >
                  <h4>Qualified</h4>
                  <h2>{numberWithCommas(totals.qualified) || '0'}</h2>
                </div>
                <div
                  className={`chart-toggle ${!visible.not_interested ? 'disabled' : ''}`}
                  onClick={() => this.toggleVisible('not_interested')}
                  style={{ borderColor: leadColors['not-interested'] }}
                >
                  <h4>Not interested</h4>
                  <h2>{numberWithCommas(totals.not_interested) || '0'}</h2>
                </div>
                <div
                  className={`chart-toggle ${!visible.qualified_review ? 'disabled' : ''}`}
                  onClick={() => this.toggleVisible('qualified_review')}
                  style={{ borderColor: leadColors['review'] }}
                >
                  <h4>Review</h4>
                  <h2>{numberWithCommas(totals.qualified_review) || '0'}</h2>
                </div>
                <div
                  className={`chart-toggle ${!visible.qualified_disputed ? 'disabled' : ''}`}
                  onClick={() => this.toggleVisible('qualified_disputed')}
                  style={{ borderColor: leadColors['disputed'] }}
                >
                  <h4>Disputed</h4>
                  <h2>{numberWithCommas(totals.qualified_disputed) || '0'}</h2>
                </div>
                <div
                  className={`chart-toggle ${!visible.invalid ? 'disabled' : ''}`}
                  onClick={() => this.toggleVisible('invalid')}
                  style={{ borderColor: leadColors['invalid'] }}
                >
                  <h4>Invalid</h4>
                  <h2>{numberWithCommas(totals.invalid) || '0'}</h2>
                </div>
              </div>
              <ResponsiveContainer height={360} width="99%">
                <LineChart margin={{ right: 32 }}>
                  <CartesianGrid stroke="#E7ECF4" strokeDasharray="0 0" vertical={false} />
                  <XAxis
                    interval={interval}
                    tickLine={false}
                    axisLine={false}
                    dataKey="name"
                    type="category"
                    allowDuplicatedCategory={false}
                  />
                  <YAxis
                    tickLine={false}
                    axisLine={false}
                    dataKey="value"
                    domain={[0, 'dataMax + 2']}
                    allowDecimals={false}
                    width={40}
                  />
                  <Tooltip content={this.customTooltip} />
                  {data.map(s =>
                    visible[s.slug] ? (
                      <Line
                        dataKey="value"
                        data={s.data}
                        name={s.name}
                        key={s.name}
                        {...s.settings}
                      />
                    ) : null
                  )}
                </LineChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>
      </div>
    );
  }
}
