import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Header } from '_seriph';
import logo from '_assets/img/logo.svg';
import { logoutUser } from 'Store/user/user_actions';
import './Mobile.scss';

class Mobile extends Component {
  logoutUser = () => {
    this.props.logoutUser(() => this.props.history.push('/'));
  };
  render() {
    return (
      <div id="onboarding" className="mobile">
        <div className="home-logo">
          <Link to="/">
            <img src={logo} alt={logo} />
          </Link>
        </div>
        <div className="mobile-body">
          <Header type="display" size="3" className="headline">
            We&apos;re sorry...
          </Header>
          <div className="onboarding-mobile">
            Currently our product is not built to handle mobile devices very well. We are working
            swiftly on building native apps for iOS and Android to solve these issues and provide a
            better experience. For the best experience right now, please switch to using a computer.
            <br />
            <br />
            Thanks,
            <br /> The SellX Team
          </div>
          <div className="mobile-actions">
            <Button type="secondary" size="large" onClick={this.logoutUser}>
              Sign Out
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, { logoutUser })(Mobile));
