import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';
import User from './_components/User/User';
import Search from './_components/Search/Search';
import Recommended from './_components/Recommended/Recommended';
import MessageAgentModal from 'App/_shared/MessageAgentModal/MessageAgentModal';
import InviteAgentModal from 'App/_shared/InviteAgentModal/InviteAgentModal';
import { searchReps } from '_graphql/queries/rep';
import { getMessages } from '_graphql/queries/message';
import { LANGUAGES } from '_assets/js/languages';
import { calculateMultiple } from '_helpers/calculateMatch';
import { Header, Select, Input, Pagination, Button } from '_seriph';
import { IconSearch, IconLock } from '@tabler/icons-react';
import './Discover.scss';

const Option = Select.Option;

class Discover extends Component {
  state = {
    agents: [],
    agentsTotal: 0,
    page: 1,
    search: {},
    loading: true,
    agent: null,
    invite: false,
    searchText: ''
  };
  UNSAFE_componentWillMount = () => {
    window.scrollTo({ top: 0 });
    this.setState({ search: this.blankSearch() }, () => {
      this.loadAgents();
    });
  };
  blankSearch = () => {
    const search = {
      name: '',
      sort: 'relevance',
      availability_full: true,
      availability_part: true,
      availability_other: true,
      availability_free: true,
      reputation_earner: true,
      reputation_big_earner: true,
      reputation_super_earner: true,
      reputation_ultra_earner: true,
      reputation_extreme_earner: true,
      experience_entry: true,
      experience_intermediate: true,
      experience_expert: true,
      languages: {},
      location: null
    };
    LANGUAGES.forEach(l => {
      if (l === 'All Languages') {
        search.languages[l] = true;
      } else {
        search.languages[l] = false;
      }
    });
    return search;
  };
  updateSearch = (field, value, subType) => {
    const search = Object.assign({}, this.state.search);
    if (subType) {
      search[subType][field] = value !== undefined ? value : null;
    } else {
      search[field] = value !== undefined ? value : null;
    }
    this.setState({ search, page: 1 }, () => {
      this.loadAgents();
    });
  };
  changePage = page => {
    window.scrollTo(0, 0);
    this.setState({ page }, () => this.loadAgents());
  };
  loadAgents = async () => {
    if (!this.state.loading) this.setState({ loading: true });
    let reps;
    let messages;
    try {
      [reps, messages] = await Promise.all([
        this.props.client.query({
          variables: {
            filter: JSON.stringify(this.state.search),
            page: this.state.page
          },
          query: searchReps
        }),
        this.props.client.query({
          query: getMessages
        })
      ]);
    } catch (err) {
      // do nothing
    }

    reps = reps.data.searchReps;
    messages = messages.data.messages;

    if (reps) {
      this.setState({
        agents: reps.data || [],
        agentsTotal: reps.total,
        loading: false
      });
    } else {
      this.setState({ loading: false }, message.error('Error searching agents, try again'));
    }

    if (messages) {
      this.setState({ messages });
    }
  };
  onChangeSearch = e => {
    const newVal = e.target.value;
    const preVal = this.state.searchText;
    this.setState({ searchText: e.target.value }, () => {
      if (newVal.length > 1 && newVal?.length > preVal?.length) {
        this.updateSearch('name', newVal);
      } else if (newVal?.length < preVal?.length) {
        this.updateSearch('name', newVal);
      }
    });
  };
  emitEmpty = () => {
    this.searchInput.focus();
    this.setState({ searchText: '' });
  };
  showMessageModal = (agent, invite) => this.setState({ agent, invite: invite || false });
  removeModal = () => this.setState({ agent: null, invite: false });
  render() {
    const matchedAgents = (this.state.agents || []).map(a => {
      a.bestMatch = calculateMultiple(this.props.campaigns, a);
      return a;
    });

    return (
      <div id="company-agent-discover" className={this.props.isOnTrial ? 'trial' : ''}>
        { this.props.isOnTrial ? (
          <div id="on-trial">
            <div className="popup">
              <div className="icon"><IconLock /></div>
              <div className="in-touch">Get in touch with our team to unlock our freelance marketplace.</div>
              <div><a href="mailto:sales@sellx.com"><Button type="primary">Chat with us</Button></a></div>
            </div>
          </div>
        ) : null }
        <div className="seriph-header-wrapper">
          <Header type="display" weight="600" size="4">
            Discover Reps
          </Header>
          <div className="header-actions">
            <Input
              placeholder="Search..."
              value={this.state.searchText}
              onChange={this.onChangeSearch}
              iconLeft={<IconSearch />}
            />
            <Select
              value={this.state.search.sort}
              onChange={value => this.updateSearch('sort', value)}
              style={{ width: 180 }}
            >
              <Option value="relevance">
                <b>Sort:</b> Relevance
              </Option>
              <Option value="reputation">
                <b>Sort:</b> Reputation
              </Option>
              <Option value="newest">
                <b>Sort:</b> Newest
              </Option>
              <Option value="oldest">
                <b>Sort:</b> Oldest
              </Option>
            </Select>
          </div>
        </div>
        <div className="discover-search">
          <div className="discover-left">
            <User />
            <Search search={this.state.search} updateSearch={this.updateSearch} />
          </div>
          <div className="discover-right">
            <Recommended
              agents={matchedAgents}
              campaigns={this.props.campaigns}
              showModal={this.showMessageModal}
              searchName={this.state.search.name}
              search={this.state.search}
              updateSearch={this.updateSearch}
              loading={this.state.loading}
              loadMessaging={this.props.loadMessaging}
              messages={this.state.messages}
            />
            {!this.state.loading && (
              <Pagination
                total={this.state.agentsTotal}
                current={this.state.page}
                onChange={this.changePage}
                pageSize={10}
              />
            )}
          </div>
        </div>
        {this.state.agent && !this.state.invite ? (
          <MessageAgentModal
            user={this.props.user}
            client={this.props.client}
            agent={this.state.agent}
            removeModal={this.removeModal}
          />
        ) : null}

        {this.state.agent && this.state.invite ? (
          <InviteAgentModal
            user={this.props.user}
            campaigns={this.props.campaigns}
            client={this.props.client}
            agent={this.state.agent}
            removeModal={this.removeModal}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(Discover);
