import React, { Component } from 'react';
import { Avatar, Modal, Button, message, Loading, Progress } from '_seriph';
import moment from 'moment';
import { getEndCampaign } from '_graphql/queries/campaign';
import { endCampaign } from '_graphql/mutations/campaign';
import { formatMoney, numberWithCommas, getErrors } from '_assets/js/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { statusMap } from '_constants/campaigns';
import './EndThresholdCampaign.scss';

export default class EndThresholdCampaign extends Component {
  state = {
    visible: true,
    loading: true,
    campaign: null,
    reps: []
  };
  componentDidMount = () => {
    this.loadEndCampaign();
  };
  loadEndCampaign = () => {
    this.props.client
      .query({ variables: { id: this.props.campaign.id }, query: getEndCampaign })
      .then(result => {
        if (result && result.data && result.data.endCampaign) {
          const data = result.data.endCampaign;
          const sortedReps = data.reps.sort(
            (a, b) => parseFloat(b.stats.total_paid) - parseFloat(a.stats.total_paid)
          );
          this.setState({ campaign: data.campaign, reps: sortedReps.slice(0, 3), loading: false });
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        message.error('Could not load campaign, try again');
        this.setState({ loading: false });
      });
  };
  endCampaign = () => {
    this.setState({ ending: true });
    this.props.client
      .mutate({
        variables: { id: this.props.campaign.id },
        mutation: endCampaign
      })
      .then(result => {
        if (result && result.data && result.data.endCampaign) {
          this.props.reload();
          this.hideModal();
          message.success('Campaign is now complete');
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ ending: false });
        message.error(getErrors(err) || 'Could not end campaign, try again');
      });
  };
  confirmEnd = () => {
    Modal.confirm({
      title: 'Are you sure you want to end this campaign?',
      content:
        'Once your campaign has ended it cannot be restarted and all work will stop.  You will still be able to export/access your leads if needed.',
      onOk: this.endCampaign
    });
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { loading, reps, campaign, ending } = this.state;

    let reviewPercent = 0,
      completedPercent = 0;
    let progressData = [];
    if (!loading && campaign) {
      completedPercent = Math.floor(
        (campaign.stats.completed / campaign.stats.estimated_total) * 100
      );
      reviewPercent = Math.floor((campaign.stats.review / campaign.stats.estimated_total) * 100);
      progressData = [
        { color: '#87B0ED', percent: completedPercent },
        { color: '#B5B5FD', percent: reviewPercent }
      ];
    }

    return (
      <Modal
        wrapClassName="campaign-end-threshold-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        width={800}
        closable={false}
        maskClosable={false}
      >
        {!ending && <Modal.Close onClick={this.hideModal} />}
        {loading ? (
          <Loading />
        ) : (
          <div className="end-content">
            <div className="stats">
              <h5>Campaign Summary</h5>
              <h2>{campaign.name}</h2>
              <h4>
                <span className={'left-type'}>
                  {campaign.objective === 'qualify' && 'Lead Qualification'}
                  {campaign.objective === 'leadgen' && 'Lead Generation'}
                </span>
                {campaign.status === 'active' && campaign.status_updated && (
                  <span>
                    &nbsp;&nbsp;&bull;&nbsp;&nbsp;{statusMap[campaign.status]} since{' '}
                    {moment(campaign.status_updated * 1).format('M/D')}
                  </span>
                )}
                {(campaign.status === 'inactive' || campaign.status === 'completed') &&
                  campaign.status_updated && (
                    <span>
                      &nbsp;&nbsp;&bull;&nbsp;&nbsp;{statusMap[campaign.status]} since{' '}
                      {moment(campaign.status_updated * 1).format('M/D')}
                    </span>
                  )}
              </h4>
              <div className="progress">
                <Progress progressData={progressData} rounded large />
                <div className="info">
                  <div className="completed">
                    <span />
                    <b>{campaign.stats.completed}</b> Completed
                  </div>
                  <div className="pending">
                    <span />
                    <b>{campaign.stats.review}</b> Pending
                  </div>
                  <div className="available">
                    <span />
                    <b>{campaign.stats.estimated_total}</b> Total
                  </div>
                </div>
              </div>
              <div className="reps">
                <h3>Top Sales Reps</h3>
                <table className="rep-table">
                  <tbody>
                    {reps.map(r => {
                      const total =
                        campaign.objective === 'leadgen' ? r.stats.generated : r.stats.qualified;
                      return (
                        <tr key={'ar-' + r.rep.id}>
                          <td>
                            <Avatar size="mini" type="agent" picture={r.rep.picture} />
                          </td>
                          <td>{r.rep.display_name}</td>
                          <td>
                            {numberWithCommas(total)}&nbsp;
                            {campaign.objective === 'leadgen' ? 'generated' : 'qualified'}
                          </td>
                          <td>{formatMoney(r.stats.total_paid)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="summary">
              <div className="overview">
                <div className="right">
                  <h4>{formatMoney(campaign.paid)}</h4>
                  <p>Paid out so far</p>
                </div>
              </div>
              <div className="line-items">
                {campaign.objective === 'leadgen' ? (
                  <div className="item">
                    <div className="info">
                      <h4>
                        Pending Review<p>Leads awaiting approval</p>
                      </h4>
                    </div>
                    <div className="value">
                      {formatMoney(campaign.stats.owed)}
                      <p>
                        {campaign.stats.review} lead{campaign.stats.review === 1 ? '' : 's'}
                      </p>
                    </div>
                  </div>
                ) : null}
                {campaign.objective === 'qualify' ? (
                  <React.Fragment>
                    <div className="item">
                      <div className="info">
                        <h4>
                          Pending Qualified<p>Leads awaiting approval</p>
                        </h4>
                      </div>
                      <div className="value">
                        {formatMoney(campaign.stats.owed_interested)}
                        <p>
                          {campaign.stats.review_interested} lead
                          {campaign.stats.review_interested === 1 ? '' : 's'}
                        </p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="info">
                        <h4>
                          Pending Not-Interested<p>Leads awaiting approval</p>
                        </h4>
                      </div>
                      <div className="value">
                        {formatMoney(campaign.stats.owed_not_interested)}
                        <p>
                          {campaign.stats.review_not_interested} lead
                          {campaign.stats.review_not_interested === 1 ? '' : 's'}
                        </p>
                      </div>
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
              <div className="notice">
                <FontAwesomeIcon icon={faInfoCircle} />
                <p>Any leads Pending Review will be completed and billed within the next hour</p>
              </div>
              <div className="total">
                <h4>Amount to be paid out</h4>
                <div>{formatMoney(campaign.stats.owed)}</div>
              </div>
              <Button loading={ending} type="primary" size="large" block onClick={this.confirmEnd}>
                End Campaign
              </Button>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
