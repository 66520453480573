import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Input, Button } from '_seriph';
import { isMediumPassword, isEmail } from '_assets/js/helpers';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import './CreateAccount.scss';

const blankValidation = () => ({ first_name: '', last_name: '', email: '', password: '' });

class CreateAccount extends Component {
  state = {
    showPassword: false
  }
  isValid = () => {
    const validate = blankValidation();
    const form = this.props.form;
    if (!form.first_name) validate.first_name = 'First name required';
    if (!form.last_name) validate.last_name = 'Last name required';
    if (!isMediumPassword(form.password)){
      validate.password = 'Password must contain at least 6 characters with a minimum of 1 number';
    }
    if (!isEmail(form.email)){
      validate.email = 'Please enter a valid email address';
    }
    for (let v in validate) {
      if (validate[v]) {
        return { isValid: false, validate };
      }
    }
    return { isValid: true, validate };
  };
  togglePassword = () => this.setState({ showPassword: !this.state.showPassword })
  render() {
    const { form, updateForm } = this.props;

    const { validate, isValid } = this.isValid();

    return (
      <div className="join-form-box" id="onboard-create">
        <div className="join-title">
          <h2>Create your account</h2>
          <p>Getting started takes just a few steps</p>
        </div>

        <div className="join-fields">
          <div className="join-row">
            <Input
              label="First name"
              placeholder="John"
              onChange={e => updateForm('first_name', e.target.value)}
              value={form.first_name}
            />
            <Input
              label="Last name"
              placeholder="Smith"
              onChange={e => updateForm('last_name', e.target.value)}
              value={form.last_name}
            />
          </div>
          <Input
            label="Work email address"
            placeholder="jsmith@gmail.com"
            onChange={e => updateForm('email', e.target.value)}
            value={form.email}
            autoComplete="do-not-autofill"
            id="field1"
          />
          <div className="hide-show">
            <Input
              label="Password"
              type={this.state.showPassword ? 'text' : 'password'}
              onChange={e => updateForm('password', e.target.value)}
              value={form.password}
              autoComplete="do-not-autofill"
              id="field2"
              error={form.password !== undefined && validate.password}
              hint={form.password !== undefined && validate.password ? validate.password : ''}
            />
            <div className="tog" onClick={this.togglePassword}>
              { !this.state.showPassword ? (
                <div><IconEye />Show</div>
              ) : (
                <div><IconEyeOff />Hide</div>
              )}
            </div>
          </div>

        </div>

        <div className="join-actions">
          { this.props.next ? (
            <Button disabled={!isValid} type="primary" size="large" onClick={() => this.props.saveOnboard(this.props.next)}>
              Continue
            </Button>
          ) : null }

          <div className="already">
            Already have an account? <Link to="/">Log in</Link>
          </div>

        </div>
      </div>
    );
  }
}

export default withRouter(CreateAccount);
