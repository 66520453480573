import gql from 'graphql-tag';

export const searchAgents = gql`
  query searchAgents($filter: String, $page: Int) {
    searchAgents(filter: $filter, page: $page) {
      data {
        id
        about
        availability
        employment
        employment_type
        experiences {
          start_date
          end_date
        }
        display_name
        picture
        total_experience
        profile_percent
        skills
        location {
          name
          lat
          lng
        }
        languages
        experience_type
        reputation
        rating
      }
      total
    }
  }
`;

export const getAgent = gql`
  query client($id: ID) {
    client(id: $id) {
      first_name
      last_name
      phone_number
      email_username
      user {
        verified
        email
      }
    }
  }
`;

export const getAgentInformation = gql`
  query {
    agentInformation {
      first_name
      last_name
      phone_number
      email_username
      rating
      profile_percent
      favorites
      has_payment_method
      campaigns {
        target_experience_short
        target_availability_short
        id
        name
        objective
        required {
          field
          type
          values
          locations {
            name
            lat
            lng
          }
          min
          max
          required
        }
        status
        company {
          name
          logo
        }
        invites
        agents {
          agent_id
        }
      }
    }
  }
`;
