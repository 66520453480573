import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faTimes } from '@fortawesome/free-solid-svg-icons';
import './PaymentMethodModal.scss';

export default class PaymentMethodModal extends Component {
  state = {
    visible: true,
    maskClosable: true
  };
  hideModal = () => {
    this.setState({ visible: false });
  };
  render() {
    return (
      <Modal
        wrapClassName="payment-method-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={this.state.maskClosable}
        width={456}
      >
        <div className="close-icon" onClick={this.hideModal}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="stage-one">
          <div className="stage-icon">
            <FontAwesomeIcon icon={faExclamation} />
          </div>
          <h3>Your payment info is missing</h3>
          <p>
            We need a place to send your money. Please setup your payment info and resubmit lead
            after you’ve completed.
          </p>
          <div className="stage-actions">
            <Button className="btnx btnx-neutral" onClick={this.hideModal}>
              Cancel
            </Button>
            <Link to="/settings/banking">
              <Button className="btnx btnx-primary">Setup payment info</Button>
            </Link>
          </div>
        </div>
      </Modal>
    );
  }
}
