import React from 'react';
import { message as AntdMessage } from 'antd';
import { IconCircleCheck, IconInfoCircle, IconAlertTriangle, IconBan } from '@tabler/icons-react';

const typeIcons = {
  success: <IconCircleCheck color="#C1DDBB" />,
  warning: <IconAlertTriangle color="#EFDFC4" />,
  info: <IconInfoCircle color="#D2D0FB" />,
  error: <IconBan color="#B34043" />
};

const SendMessage = props => {
  const settings = {
    type: props.type || 'default',
    content: props.content || 'Error, try again',
    duration: props.duration || 3
  };

  if (props.type !== 'default') settings.icon = typeIcons[props.type];

  AntdMessage.open(settings);
};

const message = {
  open: (content, duration) => SendMessage({ content, duration }),
  success: (content, duration) => SendMessage({ type: 'success', content, duration }),
  error: (content, duration) => SendMessage({ type: 'error', content, duration }),
  info: (content, duration) => SendMessage({ type: 'info', content, duration }),
  warning: (content, duration) => SendMessage({ type: 'warning', content, duration }),
  warn: (content, duration) => SendMessage({ type: 'warning', content, duration }),
  loading: (content, duration) => SendMessage({ content, duration })
};

export default message;
