import React from 'react';
import { IconCircleCheck, IconAlertTriangle, IconInfoCircle, IconBan, IconX } from '@tabler/icons-react';
import './Alert.scss';

const Alert = incomingProps => {
  const props = { ...incomingProps };
  let containerClassName = 'seriph-alert';

  // Handle Type (success, warning, info, negative)
  const alertType = props.type;
  if (props.type) {
    containerClassName += ' alert-' + props.type;
    delete props.type;
  }

  // can be icon (default), line
  const variant = props.variant || 'icon';

  let icon = props.icon;
  if (!icon) {
    switch (alertType) {
      case 'success':
        icon = <IconCircleCheck />;
        break;
      case 'warning':
        icon = <IconAlertTriangle />;
        break;
      case 'info':
        icon = <IconInfoCircle />;
        break;
      case 'negative':
      case 'danger':
        icon = <IconBan />;
        break;
      default:
        icon = null;
        break;
    }
  }

  if (variant === 'line') {
    icon = null;
  }

  return (
    <div className={containerClassName}>
      {icon && <div className="alert-icon">{icon}</div>}
      {variant === 'line' && <div className="alert-line">&nbsp;</div>}
      <div className="alert-content">
        <h5>{props.title}</h5>
        <p>{props.children}</p>
      </div>
      <div className="alert-actions">{props.actions}</div>
      {props.closable && (
        <div className="alert-close">
          <IconX />
        </div>
      )}
    </div>
  );
};

export default Alert;
