import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AutoComplete } from 'antd';
import { Button } from '_seriph';
import { updateAgentProfileSkills } from 'Mutations/Agent/updateAgentProfile';
import { updateAgentProfile } from 'Store/agent/agent_actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { SKILLS } from '_assets/js/skills';

let shouldClear = false;

class SkillsEdit extends Component {
  state = {
    saving: false,
    term: '',
    skills: this.props.skills
  };
  save = () => {
    this.setState({ saving: true });
    this.props.apollo
      .mutate({
        variables: { skills: this.state.skills },
        mutation: updateAgentProfileSkills
      })
      .then(result => {
        if (result && result.data) {
          this.props.close();
          this.props.reload();
        } else {
          this.setState({ saving: false });
        }
      })
      .catch(() => {
        this.setState({ saving: false });
      });
  };
  addSkill = skill => {
    shouldClear = true;
    this.setState({
      term: '',
      skills: [...this.state.skills, skill]
    });
  };
  removeSkill = skill => {
    const skills = new Set(this.state.skills);
    if (skills.has(skill)) skills.delete(skill);
    this.setState({ skills: [...skills] });
  };
  searchTerm = term => {
    if (shouldClear) {
      shouldClear = false;
    } else {
      this.setState({ term });
    }
  };
  render() {
    let skillSource = [];
    const skillSearch = this.state.term || '';
    if (skillSearch.length > 0) {
      skillSource = SKILLS.filter(skill => {
        return skill.toLowerCase().includes(skillSearch.toLowerCase());
      });
      if (skillSource.length > 10) skillSource = skillSource.slice(0, 10);
    }
    const skills = this.state.skills;
    return (
      <div
        id="agent-skills-edit"
        className="inline-form skill-edit"
        style={{ paddingRight: '10px' }}
      >
        <AutoComplete
          style={{ width: '100%' }}
          dataSource={skillSource}
          placeholder="Ex: Programming Language, Language, etc"
          value={this.state.term}
          onChange={this.searchTerm}
          onSelect={this.addSkill}
          className="sx-select"
          dropdownClassName="seriph-dropdown"
        />
        <div className="antd-auto-list">
          {skills.map((s, i) => (
            <span key={'skilledit-' + i} className="antd-auto-tag">
              {s}
              <FontAwesomeIcon onClick={() => this.removeSkill(s)} icon={faTimesCircle} />
            </span>
          ))}
        </div>
        <div className="inline-actions">
          <Button type="default" onClick={this.props.close}>
            Cancel
          </Button>
          <Button type="primary" onClick={this.save} loading={this.state.saving}>
            Save
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.agent, ...state.apollo };
};

export default connect(mapStateToProps, { updateAgentProfile })(SkillsEdit);
