import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Select } from 'antd';
import { companySearchName } from '_graphql/queries/company';
import { getErrors } from '_assets/js/helpers';
import { message } from '_seriph';
import './CompanyDropdown.scss';

class CompanyDropdown extends Component {
  state = {
    companies: [],
    value: undefined
  };
  handleSearch = value => {
    if (value) {
      this.fetch(value);
    } else {
      this.setState({ companies: [] });
    }
  };
  fetch = name => {
    this.setState({ loading: true });
    this.props.apollo
      .query({ variables: { name }, query: companySearchName })
      .then(result => {
        if (result && result.data && result.data.companySearchName) {
          this.setState({ companies: result.data.companySearchName });
        } else {
          throw new Error();
        }
      })
      .catch(err => message.error(getErrors(err) || 'Could not search companies, try again'));
  };
  render() {
    const options = this.state.companies.map(d => (
      <Select.Option key={d.id}>{d.name}</Select.Option>
    ));
    return (
      <Select
        className="company-drop-down"
        showSearch
        value={this.props.value}
        placeholder="Companies..."
        defaultActiveFirstOption={false}
        filterOption={false}
        onSearch={this.handleSearch}
        onChange={this.props.onChange}
        notFoundContent={null}
      >
        {this.props.value !== undefined ? (
          <Select.Option value={undefined}>None</Select.Option>
        ) : null}
        {options}
      </Select>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.apollo };
};

export default withRouter(connect(mapStateToProps, {})(CompanyDropdown));
