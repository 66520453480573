import React, { Component } from 'react';
import { Progress as ProgressBar } from 'antd';
import './Progress.scss';

export default class Progress extends Component {
  render() {
    const { color, percent, showInfo } = this.props;

    const percentInt = parseInt(percent);

    let strokeColor = '#FD8321';
    if (percentInt > 40) strokeColor = '#00D5F2';
    if (percentInt > 75) strokeColor = '#7BEA69';

    strokeColor = '#AE84F9';
    const others = {};
    if (this.props.successPercent) others.successPercent = this.props.successPercent;
    return (
      <div className="sxd-progress-container">
        {this.props.inProgress ? (
          <div className="in-progress" style={{ width: this.props.inProgress + '%' }} />
        ) : null}
        <ProgressBar
          className="sxd-progress"
          strokeColor={color || strokeColor}
          percent={percentInt}
          showInfo={showInfo || false}
          {...others}
        />
      </div>
    );
  }
}
