import React, { Component } from 'react';
import { Modal, Button, Header, Input, message } from '_seriph';
import ChilipiperLogo from '_assets/brands/chilipiper.png';
import { getErrors } from '_assets/js/helpers';
import { addChilipiper } from '_graphql/mutations/integrations';
import './AddChilipiperModal.scss';

export default class AddChilipiperModal extends Component {
  state = {
    visible: true,
    domain: this.props.integrations?.chilipiper?.domain || undefined,
    inbound_router: this.props.integrations?.chilipiper?.inbound_router || undefined,
    saving: false,
    validate: {}
  };
  addChilipiper = () => {
    if (!this.isValid()) {
      message.error('Both fields are required to save Chilipiper integration');
      return;
    }
    this.setState({ saving: true });
    this.props.client
      .mutate({
        variables: { domain: this.state.domain, inbound_router: this.state.inbound_router },
        mutation: addChilipiper
      })
      .then(result => {
        if (result && result.data && result.data.addChilipiper) {
          if (this.props.reload) this.props.reload();
          message.success('Chilipiper successfully integrated');
          this.hideModal();
        }
      })
      .catch(err => {
        this.setState({ saving: false });
        this.handleError(getErrors(err));
      });
  };
  handleError = errorString => {
    if (errorString.includes('router')) {
      message.error('Could not find an inbound router with the name ' + this.state.inbound_router);
      this.setState({
        validate: {
          inbound_router: 'Could not find an active router with that name'
        }
      });
    } else if (errorString.includes('domain')) {
      message.error('Could not find a domain with the name ' + this.state.domain);
      this.setState({
        validate: {
          inbound_router: 'Could not find an active router with that name'
        }
      });
    } else if (errorString.includes('CHILIPIPER ERROR')) {
      message.error(errorString);
      this.setState({
        validate: {
          inbound_router: 'Issue with chilipiper setup, check with Chilipiper to resolve'
        }
      });
    } else {
      message.error(errorString || 'Something went wrong, try again');
    }
  };
  isValid = () => {
    const validate = {};
    if (!this.state.domain) validate.domain = 'Domain is required';
    if (!this.state.inbound_router) validate.inbound_router = 'Inbound Router is required';
    this.setState({ validate });
    for (let v in validate) {
      if (validate[v]) {
        return false;
      }
    }
    return true;
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { domain, inbound_router, validate, saving } = this.state;

    return (
      <Modal
        wrapClassName="add-chilipiper-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={true}
        width={600}
      >
        <Modal.Close onClick={this.hideModal} />

        <Header size="2" type="serif">
          <img className="chili-logo" src={ChilipiperLogo} title="Chilipiper" alt="Chilipiper" />
          Chilipiper Integration Settings
        </Header>

        <p className="descript">
          To integrate with Chilipiper, you need to make sure you have the following:
          <span>
            <b>1.&nbsp;&nbsp;Have a Workspace setup using Concierge (🔥 Hot)</b>
            <b>2.&nbsp;&nbsp;Have a Meeting Queue setup under your Workspace</b>
            <b>3.&nbsp;&nbsp;Create an Inbound Router using your Meeting Queue above</b>
          </span>
        </p>

        <p className="breaker">Once you have those setup, we need the following information:</p>

        <div className="chili-info">
          <Input
            label={
              <span>
                Your Domain (<b>sellx</b>.chilipiper.com)
              </span>
            }
            placeholder="sellx"
            value={domain}
            onChange={e => this.setState({ domain: e.target.value })}
            error={validate.domain}
            hint={validate.domain}
          />
          <Input
            label="Inbound Router Name"
            placeholder="my_router"
            value={inbound_router}
            onChange={e => this.setState({ inbound_router: e.target.value })}
            error={validate.inbound_router}
            hint={validate.inbound_router}
          />
        </div>

        <Modal.Actions align="right">
          <Button onClick={this.hideModal}>Close</Button>
          <Button type="primary" loading={saving} onClick={this.addChilipiper}>
            Save Settings
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
