import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menu } from 'antd';
import { companyAgentStats } from '_graphql/queries/stats';
import { applications } from '_graphql/queries/company';
import { markApplicationSeen, markApplicationExpanded } from '_graphql/mutations/campaign';
import Hired from './Hired/Hired';
import Applications from './_pages/Applications/Applications';
import Invitations from './Invitations/Invitations';
import './Agents.scss';
import { Header, Button } from '_seriph';
import { IconPlus, IconInfoCircle } from '@tabler/icons-react';
import InviteRepMember from './_modals/InviteRepMember/InviteRepMember';
import SeatInfoModal from 'App/Company/_modals/SeatInfoModal/SeatInfoModal';

class Agents extends Component {
  state = {
    loading: true,
    loadingStats: true,
    stats: {},
    applications: [],
    campaign: 'active',
    tab: this.props.tab || 'my-reps',
  };
  componentDidMount = () => {
    this.reload();
  };
  reload = () => {
    this.getStats();
    this.getApplications();
    this.props.reloadCompany();
  };
  getStats = () => {
    const selected = this.generateCampaigns(this.state.campaign);
    this.props.client
      .query({ variables: { ...selected }, query: companyAgentStats })
      .then(result => {
        if (result && result.data && result.data.companyAgentStats) {
          this.setState({ stats: result.data.companyAgentStats || {} });
        }
        this.setState({ loadingStats: false });
      });
  };
  getApplications = () => {
    this.props.client.query({ query: applications }).then(result => {
      if (result?.data?.applications) {
        this.setState({ applications: result.data.applications || [] });
      }
      this.setState({ loading: false });
    });
  };
  generateCampaigns = campaign => {
    const isStatus = ['all', 'active', 'inactive', 'completed'].includes(campaign);
    if (isStatus) {
      return { status: campaign };
    } else {
      return { campaign_id: campaign };
    }
  };
  isInCampaign = campaign_id => {
    if (this.state.campaign === 'all') return true;
    const isStatus = ['all', 'active', 'inactive', 'completed'].includes(this.state.campaign);
    if (isStatus) {
      const actual = this.props.campaigns.filter(c => c.status === this.state.campaign);
      return actual.find(c => c.id === campaign_id) ? true : false;
    } else {
      return campaign_id === this.state.campaign;
    }
  };

  selectCampaign = campaign => this.setState({ campaign }, this.getStats);
  handleClick = e => {
    this.setState({ tab: e.key, campaign: 'active' }, () => {
      this.props.history.push('/sales-reps/' + e.key);
    });
  };

  markApplicationSeen = async application_id => {
    await this.props.client.mutate({
      variables: { application_id },
      mutation: markApplicationSeen
    });
  };

  markApplicationExpanded = async application_id => {
    const { applications } = this.state;
    const index = applications.findIndex(a => a.id === application_id);
    if (index > -1) {
      applications[index].expanded_by.push(this.props.user.id);
      applications[index].seen_by.push(this.props.user.id);
      this.setState({ applications });
      await this.props.client.mutate({
        variables: { application_id },
        mutation: markApplicationExpanded
      });
      return true;
    }
    return false;
  };

  showInviteModal = () => this.setState({ inviteModal: true });
  removeInviteModal = () => this.setState({ inviteModal: false });
  showSeatInfoModal = () => this.setState({ seatInfoModal: true });
  removeSeatInfoModal = () => this.setState({ seatInfoModal: false });
  render() {
    const { tab, loading, campaign, applications } = this.state;
    const { campaigns, alert, applicationCount, company } = this.props;
    const seatsLeft = company?.seats_left || 0;

    return (
      <div id="company-agents">
        <div className="rep-header">
          <Header size="1" font="serif" weight="600">
            Sales Reps
          </Header>
          { company?.internal_seats > 0 ? (
            <div className="invite-rep">
              <div className="seats" onClick={this.showSeatInfoModal}>
                <IconInfoCircle />{seatsLeft} seat{seatsLeft === 1 ? '' : 's'} left
              </div>
              { seatsLeft > 0 ? (
                <div className="invite-button">
                  <Button icon="left" onClick={this.showInviteModal} type="secondary">
                    <IconPlus />
                    Invite team
                  </Button>
                </div>
              ) : null}
              
            </div>
          ) : null}
        </div>
        <Menu onClick={this.handleClick} selectedKeys={[tab]} mode="horizontal">
          <Menu.Item key="my-reps">My Reps</Menu.Item>
          <Menu.Item key="applications">
            <div className="applications-menu-item">
              XDR Applicants
              {applicationCount ? (
                <div className="rep-alerting">
                  <span>{applicationCount}</span>
                </div>
              ) : null}
            </div>
          </Menu.Item>
          <Menu.Item key="invitations">Invitations</Menu.Item>
        </Menu>
        <div className="campaigns-list">
          {tab === 'my-reps' && (
            <Hired
              client={this.props.client}
              user={this.props.user}
              campaigns={campaigns}
              isInCampaign={this.isInCampaign}
              reload={this.reload}
              reloadCampaigns={this.props.reloadCampaigns}
              campaign={campaign}
              generateCampaigns={this.generateCampaigns}
              allowAll={true}
              selectCampaign={this.selectCampaign}
            />
          )}
          {tab === 'applications' && (
            <Applications
              client={this.props.client}
              user={this.props.user}
              campaigns={campaigns}
              applications={applications}
              isInCampaign={this.isInCampaign}
              reload={this.reload}
              status={tab === 'passed' ? 'passed' : 'pending'}
              loading={loading}
              allowAll={true}
              campaign={campaign}
              selectCampaign={this.selectCampaign}
              alert={alert}
              getApplications={this.getApplications}
              markApplicationSeen={this.markApplicationSeen}
              markApplicationExpanded={this.markApplicationExpanded}
            />
          )}
          {tab === 'invitations' && (
            <Invitations
              client={this.props.client}
              user={this.props.user}
              campaigns={campaigns}
              isInCampaign={this.isInCampaign}
              reload={this.reload}
              reloadCampaigns={this.props.reloadCampaigns}
              campaign={campaign}
              generateCampaigns={this.generateCampaigns}
              allowAll={true}
              selectCampaign={this.selectCampaign}
            />
          )}
        </div>
        {this.state.inviteModal ? (
          <InviteRepMember
            client={this.props.client}
            removeModal={this.removeInviteModal}
            reload={this.loadNumSeats}
            numSeats={seatsLeft}
            campaigns={campaigns}
            company={this.props.company}
          />
        ) : null}
        {this.state.seatInfoModal ? (
          <SeatInfoModal
            client={this.props.client}
            company={this.props.company}
            removeModal={this.removeSeatInfoModal}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(Agents));
