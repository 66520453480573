import gql from 'graphql-tag';

export const getLeadsForTexter = gql`
  query getLeadsForTexter {
    getLeadsForTexter {
      lead {
        id
        first_name
        last_name
        phone
        mobile
        custom_fields {
          type
          label
          value
        }
        current_task {
          task_id
          day
          step
          steps_today
          type
        }
      }
      day
      template_id
      task_id
    }
  }
`;


export const getTextFromLead = gql`
  query getTextFromLead(
    $lead_id: String!
    $template_id: String!
    $main_area: String
  ) {
    getTextFromLead(
      lead_id: $lead_id
      template_id: $template_id
      main_area: $main_area
    ) {
      from
      replacedMainArea
      mainArea
      replacedSubject
      numSmartTags
      company {
        name
        about
        logo
        website
      }
      campaign {
        name
        custom_domain
        custom_domain_type
        agents {
          agent_id
          phone_number
        }
      }
      validEmail
      problems {
        message
        shortName
        type
      }
    }
  }
`;
