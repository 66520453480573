import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUser } from '_assets/js/helpers';
import Progress from '_styleguide/Progress/Progress';
import { Card, Avatar } from '_seriph';
import './User.scss';

class User extends Component {
  state = {
    user: getUser() || {}
  };
  render() {
    const user = this.props.user;
    return (
      <Card id="discover-user">
        <div className="user-information">
          <Avatar type="agent" picture={user.picture} reputation={this.props.reputation} />
          <div className="user-status">
            <div>
              <h3>{user.display_name}</h3>
              <p>Your profile is visible to companies.</p>
            </div>
          </div>
        </div>

        <div className="user-progress">
          <h3>Profile {this.props.percent} complete</h3>
          <Progress percent={this.props.percent} />
          <div className="user-update">
            <Link to="/profile" className="sxd-link">
              Update Profile
            </Link>
          </div>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default connect(mapStateToProps, {})(User);
