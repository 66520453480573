import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getMessages, getMessage } from '_graphql/queries/message';
import { readMessage } from 'Mutations/Message/message';
import List from './_components/List/List';
import Message from './_components/Message/Message';
import Channel from './_components/Channel/Channel';
import { Header, Modal } from '_seriph';
import './Messaging.scss';

class Messaging extends Component {
  state = {
    loadingMessages: true,
    messages: [],
    channels: [],
    selected: null,
    transition: true
  };
  componentDidMount = () => {
    setTimeout(() => this.setState({ transition: false }), 100);
    this.loadMessages();
  };
  componentDidUpdate = prevProps => {
    if (this.props.location?.pathname !== prevProps.location?.pathname) {
      this.hideMessaging();
    }
    if (this.props.closing !== prevProps.closing) {
      this.hideMessaging();
    }
  };
  loadMessages = () => {
    this.props.client
      .query({ query: getMessages })
      .then(result => {
        if (result && result.data && result.data.messages) {
          this.setState({
            messages: (result.data.messages || []).filter(m => !m.campaign_id),
            channels: (result.data.messages || []).filter(m => m.campaign_id)
          });
        }
        this.setState({ loadingMessages: false });
      })
      .catch(() => this.setState({ loadingMessages: false }));
  };
  componentDidUpdate = () => {
    if (this.props.messageFromDiscover) {
      this.selectMessage(
        this.props.messageFromDiscover.id,
        () => {},
        this.props.messageFromDiscover.employee_unread,
        this.props.messageFromDiscover.campaign_id
      );
    }
  };
  selectMessage = (messageId, callback, isUnread, campaignId) => {
    this.props.client
      .query({ variables: { id: messageId, campaign_id: campaignId }, query: getMessage })
      .then(result => {
        if (result && result.data && result.data.message) {
          this.setState({ selected: result.data.message }, () => {
            if (callback) callback();
          });
          if (isUnread) this.markAsRead(messageId);
        }
      });
  };
  markAsRead = messageId => {
    this.props.client
      .mutate({ variables: { message_id: messageId }, mutation: readMessage })
      .then(() => {
        this.loadMessages();
        if (this.props.reload) this.props.reload();
      })
      .catch(() => false);
  };
  hideMessaging = () => {
    this.setState({ selected: null }, () => {
      this.setState({ transition: true }, () => {
        setTimeout(this.props.closeDrawer, 300);
      });
    });
  };
  deselectMessage = () => this.setState({ selected: null });
  removeModal = () => this.setState({ campaignId: null, notificationId: null });
  render() {
    const { messages, selected, channels, transition } = this.state;
    const { campaigns, user } = this.props;

    const isSelected = selected ? true : false;
    return (
      <div className={!transition ? 'done' : ''} id="messaging">
        <div className="messaging-list">
          <div className="messaging-header">
            <Header font="sans" size="3">
              Messaging
            </Header>
            <Modal.Close onClick={this.hideMessaging} />
          </div>
          <List
            user={user}
            client={this.props.client}
            selected={selected}
            messages={messages}
            channels={channels}
            campaigns={campaigns}
            message={selected}
            loadMessages={this.loadMessages}
            selectMessage={this.selectMessage}
            showMessageOwnerModal={this.props.showMessageOwnerModal}
          />
        </div>
        <div className="messaging-content">
          {isSelected && selected.campaign_id ? (
            <Channel
              user={user}
              message={selected}
              campaign={campaigns.find(c => c.id === selected.campaign_id)}
              client={this.props.client}
              reload={this.selectMessage}
              loadMessages={this.loadMessages}
              deselectMessage={this.deselectMessage}
            />
          ) : isSelected ? (
            <Message
              user={user}
              message={selected}
              client={this.props.client}
              reload={this.selectMessage}
              loadMessages={this.loadMessages}
              deselectMessage={this.deselectMessage}
            />
          ) : (
            <div className="test" onClick={this.hideMessaging}>
              &nbsp;
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(Messaging);
