import React, { Component } from 'react';
import { Button, message } from '_seriph';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faMoneyCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { injectStripe, CardElement } from 'react-stripe-elements';
import Loading from '_shared/Loading/Loading';
import './PaymentMethod.scss';

class PaymentMethod extends Component {
  state = {
    loading: true,
    saving: false,
    errored: false,
    customer: null,
    source: null,
    addCard: false,
    removingCard: false
  };
  componentDidMount = () => {
    this.loadBanking();
  };
  loadBanking = newCard => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/v1/stripe/company/customer`, {
        headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') }
      })
      .then(response => {
        if (response && response.data && response.data.customer) {
          const customer = response.data.customer;
          const source =
            customer && customer.sources && customer.sources.data && customer.sources.data.length
              ? customer.sources.data[0]
              : null;
          if (newCard) message.success('Card successfully added');
          if (this.props.isValid) this.props.isValid(customer && source ? true : false);
          this.setState({ customer, source, addCard: false });
        }
      })
      .catch(() => {
        message.error('Could not load information');
        this.setState({ errored: true });
      })
      .finally(() => this.setState({ loading: false, saving: false }));
  };
  saveCard = async () => {
    this.setState({ saving: true });

    /* REMOVE AND ADD NEW CARD */
    if (this.state.customer && this.state.source) {
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_PATH}/v1/stripe/company/removeCard`,
        headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') },
        data: { id: this.state.source.id }
      })
        .then(async response => {
          if (response && response.data && response.data.removed) {
            let { token } = await this.props.stripe.createToken({
              name: this.props.myself.display_name || 'Name'
            });
            return axios({
              method: 'POST',
              url: `${process.env.REACT_APP_API_PATH}/v1/stripe/company/paymentMethod`,
              headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') },
              data: token
            });
          } else {
            throw new Error();
          }
        })
        .then(response => {
          if (response && response.data && response.data.customer) {
            setTimeout(() => this.loadBanking(true), 250);
          }
        })
        .catch(() => {
          message.error('Error changing payment method, try again');
          this.setState({ saving: false });
        });
    } else {
      let { token } = await this.props.stripe.createToken({
        name: this.props.myself.display_name || 'Name'
      });
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_PATH}/v1/stripe/company/paymentMethod`,
        headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') },
        data: token
      })
        .then(response => {
          if (response && response.data && response.data.customer) {
            setTimeout(() => this.loadBanking(true), 250);
          }
        })
        .catch(() => {
          message.error('Error changing payment method, try again');
          this.setState({ saving: false });
        });
    }
  };
  render() {
    const { loading, addCard, saving, source, addBank } = this.state;

    const isBank = source && source.object === 'bank_account';

    return (
      <div id="payment-method">
        <div className="settings-card">
          {!loading ? (
            <div className="sources">
              <h3>{addCard ? 'Add credit or debit card' : 'Payment Method'}</h3>
              {!source && !addCard && !addBank && (
                <div className="none">No payment methods setup yet</div>
              )}
              {source && !addCard && !addBank && (
                <div className="source">
                  <div className="source-icon">
                    <FontAwesomeIcon icon={isBank ? faMoneyCheck : faCreditCard} />
                  </div>
                  <div className="source-name">
                    <h3>
                      {'XXXX-' + source.last4}&nbsp;&middot;&nbsp;
                      {isBank ? source.bank_name : source.exp_month + '/' + source.exp_year}
                    </h3>
                    <div>{source.brand}</div>
                  </div>
                  <FontAwesomeIcon icon={faCheckCircle} className="checked" />
                </div>
              )}
              {addCard && (
                <div>
                  <CardElement />
                </div>
              )}
              <div className="manage-box">
                {addCard ? (
                  <React.Fragment>
                    <Button
                      onClick={() => this.setState({ addCard: false, addBank: false })}
                      className="cancel-add"
                    >
                      Cancel
                    </Button>
                    <Button
                      loading={saving === true}
                      onClick={this.saveCard}
                      type="primary"
                      className="manage-account"
                    >
                      Save Card
                    </Button>
                  </React.Fragment>
                ) : (
                  <Button
                    onClick={() => this.setState({ addCard: true })}
                    type="secondary"
                    className="manage-account"
                  >
                    {source ? 'Change Payment Method' : '+ Add Payment Method'}
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    );
  }
}

export default injectStripe(PaymentMethod);
