import moment from 'moment';
import { leadColors, otherColors } from '_seriph/_constants/colors';

export const generateData = data => {
  const { days } = data;

  const chartData = {
    vs: [
      {
        name: 'Created',
        settings: { stroke: leadColors['created'], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Review',
        settings: { stroke: leadColors['review'], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Generated',
        settings: { stroke: leadColors['generated'], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Qualified',
        settings: { stroke: leadColors['qualified'], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Not Interested',
        settings: { stroke: leadColors['not-interested'], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Disputed',
        settings: { stroke: leadColors['disputed'], strokeWidth: 3, dot: false },
        data: []
      }
    ],
    qualified: [
      {
        name: 'Qualified',
        slug: 'qualified',
        settings: { stroke: leadColors['qualified'], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Not interested',
        slug: 'not_interested',
        settings: { stroke: leadColors['not-interested'], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Submitted for review',
        slug: 'qualified_review',
        settings: { stroke: leadColors['review'], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Disputed',
        slug: 'qualified_disputed',
        settings: { stroke: leadColors['disputed'], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Invalid',
        slug: 'invalid',
        settings: { stroke: leadColors['invalid'], strokeWidth: 3, dot: false },
        data: []
      }
    ],
    generated: [
      {
        name: 'Created',
        slug: 'in_progress',
        settings: { stroke: leadColors['created'], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Submitted for review',
        slug: 'generated_review',
        settings: { stroke: leadColors['review'], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Generated',
        slug: 'generated',
        settings: { stroke: leadColors['generated'], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Disputed',
        slug: 'generated_disputed',
        settings: { stroke: leadColors['disputed'], strokeWidth: 3, dot: false },
        data: []
      }
    ],
    not_interested: [
      {
        name: 'Bad timing',
        settings: { stroke: otherColors[0], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Financial issue',
        settings: { stroke: otherColors[1], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Using another solution already',
        settings: { stroke: otherColors[2], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Wrong contact person',
        settings: { stroke: otherColors[3], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'No reason',
        settings: { stroke: otherColors[4], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'No response',
        settings: { stroke: otherColors[5], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Wrong number',
        settings: { stroke: otherColors[6], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Bad email address',
        settings: { stroke: otherColors[7], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Duplicate',
        settings: { stroke: otherColors[8], strokeWidth: 3, dot: false },
        data: []
      },
      { name: 'Other', settings: { stroke: otherColors[9], strokeWidth: 3, dot: false }, data: [] }
    ],
    touchpoints: []
  };

  if (days && days.length > 0) {
    const totals = { ...days[0].daily };
    days.forEach((d, i) => {
      /* Totals */
      if (i > 0) Object.keys(d.daily).forEach(s => (totals[s] += d.daily[s]));

      /* Activity Chart */
      chartData.vs[0].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.in_progress
      });
      chartData.vs[1].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.review
      });
      chartData.vs[2].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.generated
      });
      chartData.vs[3].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.qualified
      });
      chartData.vs[4].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.not_interested
      });
      chartData.vs[5].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.disputed
      });

      /* Qualified Chart */
      chartData.qualified[0].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.qualified
      });
      chartData.qualified[1].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.not_interested
      });
      chartData.qualified[2].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.qualified_review
      });
      chartData.qualified[3].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.qualified_disputed
      });
      chartData.qualified[4].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.invalid
      });

      /* Generated Chart */
      chartData.generated[0].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.in_progress
      });
      chartData.generated[1].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.generated_review
      });
      chartData.generated[2].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.generated
      });
      chartData.generated[3].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.generated_disputed
      });
    });
    chartData.totals = totals;
  }

  /* TOUCHPOINTS CHART */
  chartData.touchpoints = [
    {
      name: 'Email touchpoint',
      fill: otherColors[0],
      value: chartData.totals?.touchpoint_email || 0
    },
    {
      name: 'Call touchpoint',
      fill: otherColors[1],
      value: chartData.totals?.touchpoint_call || 0
    },
    {
      name: 'LinkedIn touchpoint',
      fill: otherColors[2],
      value: chartData.totals?.touchpoint_linkedin || 0
    },
    {
      name: 'General touchpoint',
      fill: otherColors[3],
      value: chartData.totals?.touchpoint_instructions || 0
    },
    { name: 'Skipped', fill: otherColors[4], value: chartData.totals?.touchpoint_skipped || 0 }
  ];

  /* Setup email percentages */
  const touchpointTotal = chartData.touchpoints?.reduce((n, { value }) => n + value, 0) || 0;
  chartData.touchpoints?.forEach((c, i) => {
    chartData.touchpoints[i].percent = c.value / touchpointTotal;
  });
  chartData.touchpoints?.sort((a, b) => parseFloat(b.percent) - parseFloat(a.percent));

  return chartData;
};
