import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons';

export default ({ state }) => {
  if (state === 'warning') {
    return (
      <div className="loader-bubble warning">
        <FontAwesomeIcon icon={faExclamation} />
      </div>
    );
  }
  if (state === 'success') {
    return (
      <div className="loader-bubble success">
        <FontAwesomeIcon icon={faCheck} />
      </div>
    );
  }
  return (
    <div className="loader-bubble">
      <div className="sk-chase">
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
      </div>
    </div>
  );
};
