const initialState = {
  profile: localStorage.getItem('onboardProfile') || null,
  profileOptions: {
    picture: true,
    about: true,
    location: true,
    industry: true,
    experience: true
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_ONBOARD': {
      const { profile } = action.payload;
      if (profile) {
        localStorage.setItem('onboardProfile', profile);
        return { ...state, profile };
      } else {
        return state;
      }
    }
    case 'UPDATE_OPTIONS': {
      const { profileOptions } = action.payload;
      if (profileOptions) {
        return { ...state, profileOptions };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}
