import React from 'react';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import './Locked.scss';

export const Locked = props => {
  const isLocked = props.isLocked;
  return (
    isLocked && (
      <Tooltip
        overlayClassName="sellx-infotip"
        title="You cannot edit this field for an active campaign"
      >
        <FontAwesomeIcon className="sellx-infotip-icon isLocked" icon={faLock} />
      </Tooltip>
    )
  );
};
