import React, { Component } from 'react';
import { Modal, Button, Select, message, Input, Checkbox } from 'antd';
import { getErrors } from '_assets/js/helpers';
import Quill from '_forms/Quill/Quill';
import { createTemplate, saveTemplate, removeTemplate } from '_graphql/mutations/campaign';
import { leadFields, companyFields, repFields, justTags } from '_constants/leadFields';
import './TemplateModal.scss';
import Icons from 'App/_shared/Icons/Icons';

const allTags = justTags();

const typeToIcon = {
  email: <Icons.Email />,
  call: <Icons.Call />,
  linkedin: <Icons.LinkedIn />,
  instructions: <Icons.Instructions />,
  wait: <Icons.Wait />
};

export default class TemplateModal extends Component {
  state = {
    visible: true,
    saving: false,
    missing: {},
    newTemplate: this.props.template ? false : true,
    previewTags: false,
    lastFocus: 'content',
    template: this.props.template
      ? this.props.template
      : {
          name: '',
          type: 'email',
          information: '',
          content: ''
        }
  };
  reactQuillRef = null;
  createTemplate = () => {
    if (this.validateTemplate())
      return message.error('Please fill out all fields before creating template.');
    this.setState({ saving: true });
    this.props.client
      .mutate({
        variables: { template: this.state.template, global: true },
        mutation: createTemplate
      })
      .then(result => {
        if (result && result.data && result.data.createTemplate) {
          message.success('Template created');
          setTimeout(() => {
            this.props.reload();
            this.hideModal();
          }, 100);
        } else {
          this.setState({ saving: false });
        }
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Error creating template, try again.');
      });
  };
  saveTemplate = () => {
    if (this.validateTemplate())
      return message.error('Please fill out all fields before saving template.');
    this.setState({ saving: true });
    this.props.client
      .mutate({ variables: { template: this.state.template }, mutation: saveTemplate })
      .then(result => {
        if (result && result.data && result.data.saveTemplate) {
          message.success('Template saved');
          setTimeout(() => {
            this.props.reload();
            this.hideModal();
          }, 100);
        } else {
          this.setState({ saving: false });
        }
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Error saving template, try again.');
      });
  };
  removeTemplate = () => {
    Modal.confirm({
      title: 'Are you sure you want to remove this template?',
      content: 'This template will no longer be used in your sequences.',
      onOk: () => {
        this.props.client
          .mutate({ variables: { template_id: this.state.template.id }, mutation: removeTemplate })
          .then(result => {
            if (result && result.data && result.data.removeTemplate) {
              message.success('Template removed');
              setTimeout(() => {
                this.props.reload();
                this.hideModal();
              }, 100);
            }
          });
      },
      onCancel() {}
    });
  };
  validateTemplate = () => {
    const missing = [];
    const { template } = this.state;
    if (!template.name) missing.push('name');
    if (!template.type) missing.push('type');
    if (!template.information && template.type === 'email') missing.push('information');
    if (!template.content) missing.push('content');
    this.setState({ missing: missing });
    return missing.length > 0 ? true : false;
  };
  updateTemplate = (field, value) => {
    if (this.state.previewTags) return;
    const template = Object.assign({}, this.state.template);
    template[field] = value !== undefined ? value : null;
    this.setState({ template });
  };
  hideModal = () => this.setState({ visible: false });
  addTagToContent = tag => {
    const quillRef = this.reactQuillRef.getEditor();
    var range = quillRef.getSelection();
    let position = range ? range.index : 0;
    quillRef.insertText(position, '{{' + tag + '}}');
  };
  addTagToInformation = tag => {
    const cursorPosition =
      this.templateInfo && this.templateInfo.input
        ? this.templateInfo.input.selectionStart || 0
        : 0;
    const value = this.state.template.information + '';
    let textBefore = value.substring(0, cursorPosition);
    let textAfter = value.substring(cursorPosition, value.length);
    this.updateTemplate('information', `${textBefore}${'{{' + tag + '}}'}${textAfter}`);
  };
  addTagTo = tag => {
    if (this.state.lastFocus === 'information') {
      this.addTagToInformation(tag);
    } else {
      this.addTagToContent(tag);
    }
  };
  getTags = () => {
    const content = this.state.template.content + this.state.template.information + '';
    const invalidTags = [];
    let tagCount = 0;
    (content.match(/\{\{(.*?)\}\}/g) || []).forEach(val => {
      const tag = val.replace('{{', '').replace('}}', '');
      if (!allTags[tag]) {
        invalidTags.push(tag);
      } else {
        tagCount++;
      }
    });
    return { invalidTags, tagCount };
  };
  previewTags = () => {
    const preview = {
      content: this.state.template.content + '',
      information: this.state.template.information + ''
    };
    const content = this.state.template.content + this.state.template.information + '';
    (content.match(/\{\{(.*?)\}\}/g) || []).forEach(val => {
      const fullTag = val;
      const basicTag = val.replace('{{', '').replace('}}', '');
      preview.content = preview.content.replace(new RegExp(fullTag, 'g'), allTags[basicTag]);
      preview.information = preview.information.replace(
        new RegExp(fullTag, 'g'),
        allTags[basicTag]
      );
    });
    return preview;
  };
  render() {
    const { saving, template, newTemplate, previewTags } = this.state;
    const { types } = this.props;

    const tags = this.getTags();
    const preview = previewTags ? this.previewTags() : null;

    return (
      <Modal
        wrapClassName="manage-admin-modal sx-theme"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        width={900}
        closable={false}
        maskClosable={false}
      >
        <div className="template">
          <div className="template-header sx-form">
            <Select
              className="add-type"
              dropdownClassName="sx-dropdown dropdown-templates"
              value={template.type}
              onChange={val => this.updateTemplate('type', val)}
            >
              {types.map(({ value, label }) => (
                <Select.Option key={'tp-' + value} value={value}>
                  {typeToIcon[value]}
                  <span>{label}</span>
                </Select.Option>
              ))}
            </Select>
            <Input
              placeholder="New Template..."
              onChange={e => this.updateTemplate('name', e.target.value)}
              value={template.name}
            />
            <Select
              className="add-tag"
              placeholder="Add Tag"
              dropdownClassName="sx-dropdown"
              value={undefined}
              onSelect={this.addTagTo}
            >
              <Select.OptGroup key="Lead Information" label="Lead Information">
                {Object.keys(leadFields).map(c =>
                  Object.keys(leadFields[c]).map(f =>
                    leadFields[c][f].canTag ? (
                      <Select.Option
                        key={leadFields[c][f].field}
                        value={'lead.' + leadFields[c][f].field}
                      >
                        {f}
                      </Select.Option>
                    ) : null
                  )
                )}
              </Select.OptGroup>
              {Object.keys(companyFields).map(c => (
                <Select.OptGroup key={c} label={c}>
                  {Object.keys(companyFields[c]).map(f =>
                    companyFields[c][f].canTag ? (
                      <Select.Option
                        key={companyFields[c][f].field}
                        value={'company.' + companyFields[c][f].field}
                      >
                        {f}
                      </Select.Option>
                    ) : null
                  )}
                </Select.OptGroup>
              ))}
              {Object.keys(repFields).map(c => (
                <Select.OptGroup key={c} label={c}>
                  {Object.keys(repFields[c]).map(f =>
                    repFields[c][f].canTag ? (
                      <Select.Option
                        key={repFields[c][f].field}
                        value={'rep.' + repFields[c][f].field}
                      >
                        {f}
                      </Select.Option>
                    ) : null
                  )}
                </Select.OptGroup>
              ))}
            </Select>
          </div>
          {template.type === 'email' ? (
            <Input
              disabled={previewTags}
              ref={node => (this.templateInfo = node)}
              className="template-info"
              placeholder="Subject line..."
              onClick={() => this.setState({ lastFocus: 'information' })}
              onChange={e => this.updateTemplate('information', e.target.value)}
              value={preview ? preview.information : template.information}
            />
          ) : null}
          <div
            className={`template-content sx-form ${previewTags ? 'disabled' : ''}`}
            onClick={() => this.setState({ lastFocus: 'content' })}
          >
            <Quill
              readOnly={previewTags}
              value={previewTags ? preview.content : template.content}
              onChange={val => this.updateTemplate('content', val)}
              placeholder="Template content..."
              setRef={el => {
                this.reactQuillRef = el;
              }}
            />
          </div>
        </div>
        <div className="template-actions">
          {tags.invalidTags.length > 0 ? (
            <div className="invalid-tags">
              <span />
              <div>Invalid Tags: {tags.invalidTags.join(', ')}</div>
            </div>
          ) : null}
          {tags.tagCount > 0 && tags.invalidTags.length <= 0 ? (
            <div className="preview-tags">
              <Checkbox
                className="sxd-checkbox"
                checked={previewTags}
                onChange={e => this.setState({ previewTags: e.target.checked })}
              >
                Preview Template ({tags.tagCount} Tag{tags.tagCount === 1 ? '' : 's'})
              </Checkbox>
            </div>
          ) : null}
          <Button disabled={saving} className="btnx btnx-default" onClick={this.hideModal}>
            Cancel
          </Button>
          {!newTemplate ? (
            <Button
              disabled={saving || previewTags}
              className="btnx btnx-danger"
              onClick={this.removeTemplate}
            >
              Delete
            </Button>
          ) : null}
          <Button
            loading={saving}
            disabled={previewTags || tags.invalidTags.length > 0}
            className="btnx btnx-primary"
            onClick={newTemplate ? this.createTemplate : this.saveTemplate}
          >
            {newTemplate ? 'Create' : 'Save'} Template
          </Button>
        </div>
      </Modal>
    );
  }
}
