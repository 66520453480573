import gql from 'graphql-tag';

export const getRoundRobins = gql`
  query {
    getRoundRobins {
      id
      name
      phone_number
      phone_number_id
      active
      busy_option
      cool_down
      time_out
      forward_to {
        number
        weight
        calls
        connected
        answered
      }
    }
  }
`;

export const getRoundRobin = gql`
  query getRoundRobin($id: ID) {
    getRoundRobin(id: $id) {
      id
      name
      phone_number
      phone_number_id
      active
      busy_option
      cool_down
      time_out
      forward_to {
        number
        weight
        calls
        connected
        answered
      }
    }
  }
`;

export const searchNumbers = gql`
  query searchNumbers($area_code: String) {
    searchNumbers(area_code: $area_code) {
      number
      friendly
    }
  }
`;

export const getRoundRobinCalls = gql`
  query getRoundRobinCalls(
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $startRange: Int
    $endRange: Int
    $duration: String
    $round_robin_id: String
    $status: String
    $number: String
    $search: String
  ) {
    getRoundRobinCalls(
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      startRange: $startRange
      endRange: $endRange
      duration: $duration
      round_robin_id: $round_robin_id
      status: $status
      number: $number
      search: $search
    ) {
      data {
        id
        call_id
        status
        answered
        duration
        from_number
        to_number
        rr_number
        round_robin {
          name
        }
        rep {
          id
          display_name
          picture
        }
        lead {
          id
          first_name
          last_name
        }
        recording_url
        createdAt
      }
      total
      pageTotal
      stat_total
      stat_answered
      stat_missed
      stat_failed
    }
  }
`;