import React, { Component } from 'react';
import Button from '../Button/Button';
import './RadioButtonList.scss';

export default class RadioButtonList extends Component {
  state = {
    value: ''
  };

  componentDidMount() {
    this.setState({ value: this.props?.value });
  }

  render() {
    const props = this.props;
    const { options = [], dark = false, label = '', labelhelper = '', onChange } = props;

    let className = 'seriph-radio-btn-list';
    if (dark) className += ' dark';

    return (
      <div {...props} className={className}>
        <div className="label-cont">
          <div className="label-main"> {label} </div>
          <div className="label-helper"> {labelhelper} </div>
        </div>
        {options.map((option, i) => (
          <Button
            size="medium"
            selected={option?.value === this.state.value}
            key={i}
            onClick={() => {
              this.setState({ value: option?.value }, () => {
                if (onChange) onChange(this.state.value);
              });
            }}
          >
            {option?.title}
          </Button>
        ))}
      </div>
    );
  }
}
