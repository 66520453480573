import React from 'react';
import { numberWithCommas } from '_assets/js/helpers';
import { Avatar, Button } from '_seriph';

export const getColumns = (masq) => {
  return [
    {
      title: 'Campaign',
      dataIndex: 'owner',
      render: (column, data) => {
        return (
          <div className="meeting-own">
            <Avatar
              size="mini"
              type="company"
              picture={data.campaign?.company?.logo}
            />
            <div className="campaign-info">
              <span>{data.campaign?.name}</span>
              <p>{data.campaign?.company?.name}</p>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Active Reps',
      dataIndex: 'campaign.agent_count',
      sorter: (a, b) => a.campaign?.agent_count - b.campaign?.agent_count,
      render: column => numberWithCommas(column)
    },
    {
      title: 'Lead Stats',
      children: [
        {
          title: 'Pulled',
          dataIndex: 'daily.pulled',
          sorter: (a, b) => a.daily?.pulled - b.daily?.pulled,
          render: column => numberWithCommas(column)
        },
        {
          title: 'Total',
          dataIndex: 'campaign.leads_created_count',
          sorter: (a, b) => a.campaign?.leads_created_count - b.campaign?.leads_created_count,
          render: column => numberWithCommas(column)
        },
        {
          title: 'In-Progress',
          dataIndex: 'campaign.leads_in_progress_count',
          sorter: (a, b) => a.campaign?.leads_in_progress_count - b.campaign?.leads_in_progress_count,
          render: column => numberWithCommas(column)
        },
        {
          title: 'Remaining',
          dataIndex: 'campaign.leads_remaining_count',
          sorter: (a, b) => a.campaign?.leads_remaining_count - b.campaign?.leads_remaining_count,
          render: column => numberWithCommas(column)
        },
      ]
    },
    {
      title: 'Touchpoint Stats',
      children: [
        {
          title: 'Emails',
          dataIndex: 'daily.touchpoint_email',
          sorter: (a, b) => a.daily?.touchpoint_email - b.daily?.touchpoint_email,
          render: column => numberWithCommas(column)
        },
        {
          title: 'Calls',
          dataIndex: 'daily.touchpoint_call',
          sorter: (a, b) => a.daily?.touchpoint_call - b.daily?.touchpoint_call,
          render: column => numberWithCommas(column)
        },
        {
          title: 'LinkedIns',
          dataIndex: 'daily.touchpoint_linkedin',
          sorter: (a, b) => a.daily?.touchpoint_linkedin - b.daily?.touchpoint_linkedin,
          render: column => numberWithCommas(column)
        },
        {
          title: 'Total Completed',
          dataIndex: 'daily.touchpoint_total',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.daily?.touchpoint_total - b.daily?.touchpoint_total,
          render: column => numberWithCommas(column)
        },
      ]
    },
    {
      title: 'Meetings Booked',
      dataIndex: 'daily.meetings',
      sorter: (a, b) => a.daily?.meetings - b.daily?.meetings,
      render: meetings => {
        return numberWithCommas(meetings) +  ` meeting${meetings !== 1 ? 's' : ''}`;
      }
    },
    {
      title: 'Login',
      dataIndex: 'login',
      render: (column, data) => {
        return (
          <Button size="small" type="primary" onClick={() => masq(data.campaign?.company?.owner_id)}>
            Login
          </Button>
        );
      }
    },
  ];
};
