import gql from 'graphql-tag';

export const getAgentInformation = gql`
  query {
    agentInformation {
      first_name
      last_name
      phone_number
      email_username
      rating
      profile_percent
      favorites
      has_payment_method
      is_cohort
      cohort
      banned
      reputation
      features
      campaigns {
        target_experience_short
        target_availability_short
        id
        name
        objective
        required {
          field
          type
          values
          locations {
            name
            lat
            lng
          }
          min
          max
          required
        }
        status
        status_updated
        company {
          name
          logo
        }
        employee {
          id
          picture
          display_name
          title
        }
        invites
        files {
          id
          name
          type
          fileType
          url
          size
        }
        agents {
          id
          agent_id
          active
          phone_number
        }
        guidelines
        customer_type
        customer_target
        customer_decision_maker
        customer_locations {
          name
        }
        customer_age
        customer_education
        customer_gender
        customer_race
        custom_fields {
          label
          type
          value
        }
      }
    }
  }
`;

export const searchReps = gql`
  query searchReps($filter: String, $page: Int) {
    searchReps(filter: $filter, page: $page) {
      data {
        id
        about
        availability
        employment
        employment_type
        experiences {
          start_date
          end_date
        }
        display_name
        first_name
        picture
        total_experience
        profile_percent
        skills
        location {
          name
          lat
          lng
        }
        languages
        experience_type
        reputation
        rating
      }
      total
    }
  }
`;

export const getRepApplication = gql`
  query getRepApplication($id: ID) {
    getRepApplication(id: $id) {
      id
      status
      name
      email
      phone
      linkedin_url
      country
      state
      city
      years_experience
      most_experienced_industries
      weekly_availability
      why_join
      hear_about
      hear_about_other
      biggest_achievement
      is_calendly_event_scheduled
      is_waitlist
    }
  }
`;

export const earningsHistory = gql`
  query earningsHistory($status: String, $startingAfter: String, $endingBefore: String) {
    earningsHistory(status: $status, endingBefore: $endingBefore, startingAfter: $startingAfter) {
      data {
        id
        status
        object
        created
        arrival_date
        amount
      }
      hasMore
      balance
    }
  }
`;

export const weeklyEarnings = gql`
  query weeklyEarnings(
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $tab: String
  ) {
    weeklyEarnings(
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      tab: $tab
    ) {
      data {
        type
        week_start
        week_end
        total_tasks
        payout_amount
        status
        description
        createdAt
      }
      total
      pageTotal
    }
  }
`;


export const agentForPercentMatch = gql`
  query {
    agentInformation {
      total_experience
      languages
      skills
      location {
        name
        lat
        lng
      }
    }
  }
`;

export const payoutDetailsHistory = gql`
  query payoutDetailsHistory($payoutId: String, $startingAfter: String, $endingBefore: String) {
    payoutDetailsHistory(
      payoutId: $payoutId
      endingBefore: $endingBefore
      startingAfter: $startingAfter
    ) {
      data {
        id
        name
        earned
        fee
        paid
        created
      }
      hasMore
    }
  }
`;

export const searchTeamReps = gql`
  query searchTeamReps($searchText: String) {
    searchTeamReps(searchText: $searchText) {
      id
      display_name
      picture
      reputation
    }
  }
`;
