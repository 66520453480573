import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { earningsHistory } from '_graphql/queries/rep';
import { getErrors } from '_assets/js/helpers';
import PayoutDetails from './_modals/PayoutDetails/PayoutDetails';
import { Card, Header, Tabs, Table, Button, message } from '_seriph';
import { getColumns } from './_data/Columns';
import { formatMoney } from '_assets/js/helpers';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import './Earnings.scss';

class Earnings extends Component {
  state = {
    initialLoading: true,
    loading: true,
    threshold: 0,
    runningTotal: 0,
    balance: 0,
    earnings: [],
    tab: 'all',
    hasMore: false,
    startId: null,
    endingBefore: null,
    startingAfter: null,
    payoutDetailsModal: null
  };
  componentDidMount = () => {
    this.reload();
  };
  reload = () => this.loadEarnings();
  loadEarnings = paging => {
    if (paging) {
      this.setState({ loading: true });
    } else {
      this.setState({ initialLoading: true });
    }
    this.props.client
      .query({
        variables: {
          status: this.state.tab,
          endingBefore: paging === 'prev' ? this.state.endingBefore : null,
          startingAfter: paging === 'next' ? this.state.startingAfter : null
        },
        query: earningsHistory
      })
      .then(result => {
        if (result && result.data && result.data.earningsHistory) {
          const earnings = result.data.earningsHistory.data || [];
          const balance = result.data.earningsHistory.balance || 0;
          const monthlyPaydate = result.data.earningsHistory.monthly_paydate;
          const salaryAmount = result.data.earningsHistory.salary_amount;
          this.setState({
            loading: false,
            initialLoading: false,
            earnings,
            balance,
            hasMore: result.data.earningsHistory?.hasMore ? true : false,
            endingBefore: earnings[0]?.id,
            startingAfter: earnings[earnings.length - 1]?.id,
            startId: this.state.startId || earnings[0]?.id,
            monthlyPaydate,
            salaryAmount
          });
        } else {
          throw new Error('Could not load earnings, try again');
        }
      })
      .catch(err => {
        this.setState({ loading: false, initialLoading: false });
        message.error(getErrors(err) || 'Could not load earnings, try again');
      });
  };
  changeTab = tab => {
    this.setState(
      {
        tab,
        loading: true,
        hasMore: false,
        startId: null,
        endingBefore: null,
        startingAfter: null
      },
      () => {
        this.loadEarnings();
      }
    );
  };
  showPayoutDetailsModal = billId => this.setState({ payoutDetailsModal: billId });
  removePayoutDetailsModal = () => this.setState({ payoutDetailsModal: null });
  render() {
    const {
      earnings,
      balance,
      startId,
      loading,
      initialLoading,
      payoutDetailsModal,
      salaryAmount,
      monthlyPaydate
    } = this.state;

    const columns = getColumns(this.showPayoutDetailsModal);
    const hasPrevious = startId !== earnings[0]?.id;
    const hasMore = (startId && startId === earnings[0]?.id) || this.state.hasMore;

    return (
      <Card id="agent-settings-earnings">
        <Header size="2" type="sans">
          Payouts History
        </Header>
        {!loading && (
          <Header className="running" size="4" type="sans">
            Payout Balance:&nbsp;&nbsp;&nbsp;<span>{formatMoney(balance)}</span>
            { false && (
              <React.Fragment>
                <br />
                Salary:&nbsp;&nbsp;&nbsp;<span>{formatMoney(salaryAmount)}</span>
                <br />
                Monthly Paydate:&nbsp;&nbsp;&nbsp;<span>{monthlyPaydate}</span>
              </React.Fragment>
            )}
          </Header>
        )}
        <div className="filter-menu">
          <Tabs size="small" currentTab={this.state.tab} onChange={this.changeTab}>
            <Tabs.Tab key="all">All</Tabs.Tab>
            <Tabs.Tab key="paid">Paid</Tabs.Tab>
            <Tabs.Tab key="pending">Pending</Tabs.Tab>
            <Tabs.Tab key="failed">Failed</Tabs.Tab>
            <Tabs.Tab key="canceled">Canceled</Tabs.Tab>
          </Tabs>
        </div>
        <div className="billing-table">
          <Table
            striped={true}
            columns={columns}
            rowKey={record => record.id}
            dataSource={earnings}
            loading={loading}
            onChange={this.handleTableChange}
            scroll={{ x: 'max-content' }}
            locale={{
              emptyText: `No ${this.state.tab !== 'all' ? this.state.tab : ''} earnings found`
            }}
          />
          {(hasMore || hasPrevious) && !initialLoading ? (
            <div className="earnings-actions">
              <Button
                onClick={() => this.loadEarnings('prev')}
                size="tiny"
                icon="left"
                disabled={!hasPrevious}
              >
                <IconChevronLeft />
                Prev.
              </Button>
              <Button
                onClick={() => this.loadEarnings('next')}
                size="tiny"
                icon="right"
                disabled={!hasMore}
              >
                Next
                <IconChevronRight />
              </Button>
            </div>
          ) : null}
        </div>
        {payoutDetailsModal ? (
          <PayoutDetails
            payout={payoutDetailsModal}
            client={this.props.client}
            reload={this.reload}
            removeModal={this.removePayoutDetailsModal}
          />
        ) : null}
      </Card>
    );
  }
}

export default withRouter(Earnings);
