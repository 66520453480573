import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { IconChevronRight } from '@tabler/icons-react';
import Dashboard from './_components/Dashboard/Dashboard';
import CallRecordings from './_components/CallRecordings/CallRecordings';
import Meetings from './_components/Meetings/Meetings';
import './Analytics.scss';

const MenuTab = props => (
  <Link to={`/analytics/${props.page}`} className={`menu-tab ${props.active ? 'active' : ''}`}>
    {props.children}
    <IconChevronRight />
  </Link>
);
class Analytics extends Component {
  state = {
    loading: false,
    page: this.props.page || 'dashboard'
  };
  scrollRef = null;
  componentDidUpdate = prevProps => {
    const prevId = prevProps.page;
    const newId = this.props.page;
    if (prevId !== newId)
      this.setState({ page: newId }, () => {
        this.scrollRef.scrollTo({ top: 0 });
      });
  };
  render() {
    const { page } = this.state;

    const pageProps = {
      client: this.props.client,
      campaigns: this.props.campaigns
    };

    return (
      <div id="xdr-analytics">
        <div className="analytics-menu">
          <div className="menu-title">
            <span>Analytics</span>
          </div>
          <div className="menu-tabs">
            <MenuTab active={page === 'dashboard'} page="dashboard">
              Dashboard
            </MenuTab>
            <MenuTab active={page === 'call-recordings'} page="call-recordings">
              Call recordings
            </MenuTab>
            <MenuTab active={page === 'meetings'} page="meetings">
              Scheduled Meetings
            </MenuTab>
          </div>
        </div>

        <div className="analytics-main" ref={el => (this.scrollRef = el)}>
          {page === 'dashboard' && <Dashboard {...pageProps} />}
          {page === 'call-recordings' && <CallRecordings {...pageProps} />}
          {page === 'meetings' && <Meetings {...pageProps} />}
        </div>
      </div>
    );
  }
}

export default withRouter(Analytics);
