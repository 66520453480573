import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button } from 'antd';
import { prettyPhone } from '_assets/js/helpers';

export const getColumns = (showReviewCandidate, resendInvite) => {
  return [
    {
      title: 'Company',
      dataIndex: 'company_name',
      render: (column, data) => (
        <div className="onboard-box">
          <div className="name">
            <b>{data.company_name}</b>
          </div>
          <div className="name">
            {data.first_name} {data.last_name}
          </div>
        </div>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: column => <span className={'app-status ' + column}>{column}</span>
    },
    {
      title: 'Invite Link',
      dataIndex: 'invite_link',
      render: (column, data) =>
        data.status === 'Unread' ? null : (
          <a
            href={process.env.REACT_APP_CLIENT_PATH + '/invite/' + data.response_id}
            rel="noopener noreferrer"
            target="_blank"
          >
            Invite Link
          </a>
        )
    },
    {
      title: 'Started',
      dataIndex: 'landed_at',
      render: column => moment(column * 1).format('M/D/YY h:mma')
    },
    {
      title: 'Completed',
      dataIndex: 'submitted_at',
      render: column => moment(column * 1).format('M/D/YY h:mma')
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Phone',
      dataIndex: 'phone_number',
      render: column => prettyPhone(column)
    },
    {
      title: 'Website',
      dataIndex: 'website',
      render: column =>
        column ? (
          <a href={column} rel="noopener noreferrer" target="_blank">
            {column}
          </a>
        ) : (
          ''
        )
    },
    {
      title: 'LinkedIn',
      dataIndex: 'linkedin',
      render: column =>
        column ? (
          <a href={column} rel="noopener noreferrer" target="_blank">
            {column}
          </a>
        ) : (
          ''
        )
    },
    {
      title: 'Answered',
      dataIndex: 'answer_count',
      render: (column, data) => (data.answers.length || 0) + ' answered'
    },
    {
      title: 'Actions',
      dataIndex: 'review',
      fixed: 'right',
      render: (column, data) => {
        const disabled = data.status === 'Joined';
        let className = 'btnx btnx-neutral';
        if (data.status === 'Unread' || data.status === 'Passed') className += ' btnx-caution';

        const editButton = (
          <Link to={`/company/${data.company_id}`}>
            <Button className="btnx btnx-neutral" style={{ marginRight: 10 }}>
              Edit Company
            </Button>
          </Link>
        );

        return data.status === 'Created' ? (
          editButton
        ) : (
          <React.Fragment>
            {data.company_id ? editButton : null}
            <Button
              disabled={disabled}
              onClick={() =>
                data.status === 'Unread'
                  ? showReviewCandidate(data.id)
                  : resendInvite(data.response_id)
              }
              className={className}
            >
              {data.status === 'Unread' ? 'Review Candidate' : ''}
              {data.status === 'Passed' ? 'Re-Review Candidate' : ''}
              {data.status === 'Joined' ? 'User Joined' : ''}
              {data.status === 'Invited' ? 'View Invite' : ''}
            </Button>
          </React.Fragment>
        );
      }
    }
  ];
};
