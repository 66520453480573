import React from 'react';
import { Avatar, Button, Tag } from '_seriph';

export const getCampaignColumns = (masq, triggers) => {
  return [
    {
      title: 'Campaign',
      dataIndex: 'owner',
      render: (column, data) => {
        return (
          <div className="meeting-own">
            <Avatar
              size="mini"
              type="company"
              picture={data?.company?.logo}
            />
            <div className="campaign-info">
              <span>{data?.name}</span>
              <p>{data?.company?.name}</p>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Alerts',
      dataIndex: 'alerts',
      render: (column, data) => {

        const typeMap = {
          'Less than 120 leads remaining': 'default',
          'Less than 50 leads remaining': 'warning',
          'No leads remaining': 'danger',
          'Low XDRs': 'warning',
          'Email domain not set': 'danger',
          'Paused for > 1 Week': 'warning'
        };

        const trigMap = triggers.filter(t => t.type === 'campaigns' && t.id === data.id).map((t,i) => {
          return <Tag key={data.id + '-t'+i} type={typeMap[t.pretty_reason]}>{t.pretty_reason} ({t.value})</Tag>
        });

        return trigMap;
      }
    },
    {
      title: 'Login',
      dataIndex: 'login',
      render: (column, data) => {
        return (
          <Button size="small" type="primary" onClick={() => masq(data?.company?.owner_id)}>
            Login
          </Button>
        );
      }
    },
  ];
};
