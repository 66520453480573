import React from 'react';
import { Modal as AntdModal } from 'antd';
import { IconX, IconMinus, IconAlertTriangle } from '@tabler/icons-react';
import './Modal.scss';

const Close = incomingProps => (
  <div className="seriph-modal-close" onClick={incomingProps.onClick}>
    <IconX size={16} stroke={2} />
  </div>
);

const Minimize = incomingProps => (
  <div className="seriph-modal-minimize" onClick={incomingProps.onClick}>
    <IconMinus size={16} stroke={2} />
  </div>
);

const Actions = incomingProps => {
  const props = { ...incomingProps };
  props.className = 'seriph-modal-actions ' + (props.className || '');
  if (props.align) {
    props.className += ' align-' + props.align;
  }

  return <div {...props} />;
};

const Header = incomingProps => {
  const props = { ...incomingProps };
  props.className = 'seriph-modal-title ' + (props.className || '');
  return <div {...props} />;
};

const Modal = incomingProps => {
  const props = { ...incomingProps };
  props.wrapClassName = 'seriph-modal ' + (props.wrapClassName || '');

  props.maskStyle = {
    background: 'rgba(36, 45, 62, 0.72)'
  };

  return <AntdModal {...props} />;
};

Modal.Close = Close;
Modal.Minimize = Minimize;
Modal.confirm = data => {
  AntdModal.confirm({
    className: 'seriph-confirm',
    icon: <IconAlertTriangle />,
    ...data
  });
};
Modal.Actions = Actions;
Modal.Header = Header;
export default Modal;
