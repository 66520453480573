import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { message } from 'antd';
import { createChat } from 'Mutations/Message/message';
import moment from 'moment';
import ReactQuill from 'react-quill';
import { Avatar, Header, Modal, Button } from '_seriph';
import { IconSend } from '@tabler/icons-react';
import './Message.scss';

const Chat = props => {
  const className = props.own ? 'message message-own' : 'message';
  const { user } = props;
  return (
    <div className={className}>
      {user.company && user.company.logo ? (
        <Avatar size="tiny" type="company" picture={user.company.logo} />
      ) : (
        <Link to={`/rep/${user.id}`}>
          <Avatar size="tiny" type="agent" picture={user.picture} />
        </Link>
      )}
      <div className="message-content">
        <div className="message-body" dangerouslySetInnerHTML={{ __html: props.body }} />
        <div className="message-info">
          {props.user.first_name}&nbsp;&bull;&nbsp;{moment(props.tstamp * 1).fromNow()}
        </div>
      </div>
    </div>
  );
};

let initial = true;

class Message extends Component {
  state = {
    body: '',
    loading: false,
    isFocused: false
  };
  componentDidMount = () => {
    this.findBottom();
  };
  scrollToBottom = () => {
    if (this.props.message) {
      this.messagesEnd.scrollIntoView({ behavior: initial ? 'auto' : 'smooth' });
      initial = false;
    }
  };
  findBottom = () => {
    const scrollHeight = this.convoBox.scrollHeight;
    const height = this.convoBox.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.convoBox.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };
  componentDidUpdate = prevProps => {
    const prevId = prevProps.message ? prevProps.message.id : null;
    const newId = this.props.message ? this.props.message.id : null;
    const oldCount = prevProps.message ? prevProps.message.conversation.length : 0;
    const newCount = this.props.message ? this.props.message.conversation.length : 0;
    if (prevId !== newId || oldCount !== newCount) this.scrollToBottom();
  };
  sendMessage = () => {
    this.setState({ loading: true });
    if (this.state.body !== '') {
      this.props.client
        .mutate({
          variables: { body: this.state.body, message_id: this.props.message.id },
          mutation: createChat
        })
        .then(result => {
          if (result && result.data && result.data.createChat) {
            this.props.reload(this.props.message.id, this.scrollToBottom);
            this.props.loadMessages();
            this.setState({ loading: false, body: '' });
          } else {
            throw new Error('Could not send message, try again');
          }
        })
        .catch(() => {
          message.error('Could not send message, try again');
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: false });
    }
  };
  toggleFocus = isFocused => {
    this.setState({ isFocused }, this.scrollToBottom);
  };
  render() {
    const data = this.props.message;
    let user = {};
    if (data) user = this.props.user.role_type === 'agent' ? data.employee : data.agent;

    const modules = {
      toolbar: [['bold', 'italic', 'underline', 'link']],
      clipboard: { matchVisual: false }
    };
    const formats = ['bold', 'italic', 'underline', 'link'];
    return (
      <div id="messaging-conversation" className={this.state.isFocused ? 'focused' : ''}>
        <Modal.Close onClick={this.props.deselectMessage} />
        <div className="conversation-title">
          {user.company && user.company.logo ? (
            <Avatar size="tiny" type="company" picture={user.company.logo} />
          ) : (
            <Link to={`/rep/${user.id}`}>
              <Avatar size="tiny" type="agent" picture={user.picture} />
            </Link>
          )}
          <div className="title-info">
            <Header font="sans" size="4">
              {user.display_name}
            </Header>
            <p>
              {user.company
                ? (user.title ? user.title + ' @ ' : '') + user.company.name
                : 'Direct Message'}
            </p>
          </div>
        </div>
        <div className="conversation" ref={node => (this.convoBox = node)}>
          <div className="conversation-container">
            {data.conversation.map(c => {
              const muser = c.agent_id ? data.agent : data.employee;
              return (
                <Chat
                  key={c.id}
                  user={muser}
                  own={muser.id === this.props.user.id}
                  body={c.body}
                  tstamp={c.createdAt}
                />
              );
            })}

            <div
              style={{ float: 'left', clear: 'both' }}
              ref={el => {
                this.messagesEnd = el;
              }}
            />
          </div>
        </div>
        <div className="conversation-input">
          <div className={`input-content ${this.state.isFocused ? 'focused' : ''}`}>
            <ReactQuill
              formats={formats}
              modules={modules}
              value={this.state.body}
              onChange={body => this.setState({ body })}
              placeholder="Your message..."
              onFocus={() => this.toggleFocus(true)}
              onBlur={() => this.toggleFocus(false)}
            />
            <Button
              circle
              type="primary"
              size="tiny"
              loading={this.state.loading}
              disabled={!this.props.user || !this.state.body || this.state.body === '<p><br></p>'}
              onClick={this.sendMessage}
            >
              <IconSend />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Message;
