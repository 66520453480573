import React from 'react';
import { Tabs } from 'antd';
import './TabMenu.scss';

const TabMenu = incomingProps => {
  const props = { ...incomingProps };
  props.className = 'seriph-tab-menu ' + (props.className || '');

  return <Tabs {...props} />;
};

TabMenu.TabPane = Tabs.TabPane;
export default TabMenu;
