import React, { Component } from 'react';
import { Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CustomFieldSelect.scss';
import { customOptions } from '_constants/leadFields';

export default class CustomFieldSelect extends Component {
  render() {
    return (
      <Select
        className="forms"
        dropdownClassName="sx-dropdown mapping-forms custom-field"
        value={this.props.value || undefined}
        onChange={val => this.props.onChange(val)}
        getPopupContainer={trigger => trigger.parentNode}
        placeholder="Select field type"
      >
        {customOptions.map(o => (
          <Select.Option key={'co-' + o.value} value={o.value}>
            <div className="cust-opt">
              <FontAwesomeIcon icon={o.icon} />
              <div className="cust-info">
                <h4>{o.name}</h4>
                <p>{o.description}</p>
              </div>
            </div>
          </Select.Option>
        ))}
      </Select>
    );
  }
}
