import React, { Component } from 'react';
import { Skeleton, message } from 'antd';
import { agentReputation } from '_graphql/queries/reputation';
import { getErrors } from '_assets/js/helpers';
import { Card, Table } from '_seriph';
import { getColumns } from './_data/Columns';
import { numberWithCommas } from '_assets/js/helpers';
import './Reputation.scss';
import {
  determineReputation,
  imageForReputationIndex,
  reputationTextIndex,
  reputationRangeForIndex
} from '_helpers/reputation';

export default class Reputation extends Component {
  state = {
    loading: true,
    page: 1,
    reputation: []
  };
  componentDidMount = () => {
    this.loadReputation();
  };
  loadReputation = () => {
    this.props.client
      .query({ query: agentReputation })
      .then(result => {
        if (result && result.data && result.data.agentReputation) {
          const reputation = result.data.agentReputation || [];
          this.setState({ loading: false, reputation: reputation, page: 1 });
        } else {
          throw new Error('Could not load reputation, try again');
        }
      })
      .catch(err => {
        this.setState({ loading: false, paging: false });
        message.error(getErrors(err) || 'Could not load reputation, try again');
      });
  };
  render() {
    const { reputation, pagination, loading } = this.state;
    const columns = getColumns();
    let reputationCount = this.props.score || 0;
    const reputationIndex = determineReputation(reputationCount);
    const [min, max] = reputationRangeForIndex(reputationIndex);

    return (
      <Card id="agent-settings-reputation">
        <div className="settings-card">
          <div className="settings-card-header">
            <div>
              <div className="reputation-header"> Your Reputation </div>
              <div className="point-section">
                <div className="badge-row">
                  <div className="badge">
                    <img src={imageForReputationIndex(reputationIndex)} />
                  </div>
                </div>
                <div>
                  <div className="header">{numberWithCommas(reputationCount)} pts.</div>
                  <div className="sub-header">{reputationTextIndex(reputationIndex)}</div>
                </div>
              </div>
            </div>
            <div className="gradient-row">
              <div className="small-font align-right">
                *{max - reputationCount + 1} pts. until your next reward
              </div>
              <div className="badge-row">
                <div className="badge tiny-badge">
                  <img src={imageForReputationIndex(reputationIndex + 1)} />
                </div>
              </div>

              <div className="earner-gradient">
                <div
                  className={`fill fill${reputationIndex}`}
                  style={{ width: `${(reputationCount / max) * 100}%` }}
                />
              </div>
              <div className="gradient-min-max small-font">
                <div>{numberWithCommas(min)}</div>
                <div>{numberWithCommas(max)}</div>
              </div>
            </div>
          </div>
          <hr />
          <h3 className="table-header">Last 10 Earnings</h3>
          {!loading ? (
            <React.Fragment>
              <div className="reputation-table">
                <Table
                  striped={true}
                  columns={columns}
                  rowKey={record => record.id}
                  dataSource={reputation}
                  pagination={pagination}
                  loading={loading}
                  onChange={this.handleTableChange}
                  scroll={{ x: 'max-content' }}
                  locale={{ emptyText: 'No reputation found' }}
                />
              </div>
            </React.Fragment>
          ) : (
            <Skeleton active paragraph={{ rows: 4 }} />
          )}
        </div>
      </Card>
    );
  }
}
