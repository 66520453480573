import React, { Component } from 'react';
import avatarpng from '_assets/img/avatar.png';
import { createOwnerMessage } from 'Mutations/Message/message';
import { Avatar, Modal, TextArea, Button, message, Select } from '_seriph';
import './MessageCompany.scss';

export default class MessageCompany extends Component {
  state = {
    visible: true,
    loading: false,
    campaignId: undefined,
    message: ''
  };
  createMessage = () => {
    this.setState({ loading: true });
    const campaign_id = this.props.campaign ? this.props.campaign.id : this.state.campaignId;
    if (!campaign_id) {
      message.error('Could not find company to message, try again');
      return;
    }
    this.props.client
      .mutate({
        variables: { body: this.state.message, campaign_id },
        mutation: createOwnerMessage
      })
      .then(result => {
        if (result && result.data && result.data.createOwnerMessage) {
          message.success('Message sent');
          if (this.props.reload) this.props.reload();
          this.hideModal();
        } else {
          throw new Error('Could not send message, try again');
        }
      })
      .catch(() => {
        message.error('Could not send message, try again');
        this.setState({ loading: false });
      });
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { campaignId } = this.state;
    const { campaign, campaigns } = this.props;
    const employee = campaign ? campaign.employee || {} : {};
    const company = campaign ? campaign.company || {} : {};

    const hasCampaign = campaign ? true : false;

    const employeeIds = [];
    let uniqueCampaigns = campaigns || [];
    if (!hasCampaign) {
      uniqueCampaigns = campaigns.filter(c => {
        const eId = c.employee.id;
        if (eId && !employeeIds.includes(eId)) {
          employeeIds.push(eId);
          return true;
        }
        return false;
      });
    }

    const currentId = campaign ? campaign.id : campaignId;

    return (
      <Modal
        wrapClassName="message-company-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        style={{ top: 60 }}
        width={500}
        closable={false}
        maskClosable={true}
      >
        {hasCampaign ? (
          <div className="mo-owner">
            <img className="employee" src={employee.picture || avatarpng} alt="" />
            {company.logo && <img className="c-logo" src={company.logo} alt="" />}
            <div className="mo-details">
              <h3>{employee.display_name}</h3>
              <p>
                {employee.title} at {company.name}
              </p>
              <p>
                Owner of <i>{campaign.name}</i>
              </p>
            </div>
          </div>
        ) : (
          <Select
            label="Select a Company"
            placeholder="Companies..."
            value={campaignId}
            onChange={val => this.setState({ campaignId: val })}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {uniqueCampaigns.map(c => (
              <Select.Option key={'scm-' + c.id} value={c.id}>
                <Avatar size="mini" type="company" picture={c.company.logo} />
                <span>
                  <b>{c.employee.display_name}</b> {c.company.name}
                </span>
              </Select.Option>
            ))}
          </Select>
        )}

        <TextArea
          label="Message"
          value={this.state.message}
          onChange={e => this.setState({ message: e.target.value })}
          size="large"
          rows={6}
          placeholder="Write your message here..."
        />

        <div className="mo-actions">
          <Button type="default" onClick={this.hideModal}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={this.createMessage}
            loading={this.state.loading}
            disabled={this.state.message.length <= 0 || !currentId}
          >
            Send Message
          </Button>
        </div>
      </Modal>
    );
  }
}
