import gql from 'graphql-tag';

export const getBulkEmailsForModal = gql`
  query getBulkEmailsForModal($campaignId: String!) {
    getBulkEmailsForModal(campaignId: $campaignId) {
      campaign {
        name
        type
        id
      }
      template {
        name
        content
        subject
        id
      }
      leads {
        id
        type
      }
      company {
        name
        logo
      }
      day
      fromEmailAddress
      numSmartTags
      replacedMainArea
      replacedSubject
      taskId
      problems {
        message
        shortName
      }
    }
  }
`;

export const getSingleBulkEmailForModal = gql`
  query getSingleBulkEmailForModal(
    $task_id: String!
    $mainArea: String!
    $subject: String!
    $campaignId: String!
  ) {
    getSingleBulkEmailForModal(
      task_id: $task_id
      mainArea: $mainArea
      subject: $subject
      campaignId: $campaignId
    ) {
      replacedMainArea
      replacedSubject
      numSmartTags
      problems {
        message
        shortName
      }
    }
  }
`;

export const getLeadsForEmailer = gql`
  query getLeadsForEmailer {
    getLeadsForEmailer {
      lead {
        id
        first_name
        last_name
        email
        current_task {
          task_id
          day
          step
          steps_today
          type
        }
      }
      day
      template_id
      task_id
    }
  }
`;

export const getEmailFromLead = gql`
  query getEmailFromLead(
    $lead_id: String!
    $template_id: String!
    $main_area: String
    $subject: String
  ) {
    getEmailFromLead(
      lead_id: $lead_id
      template_id: $template_id
      main_area: $main_area
      subject: $subject
    ) {
      from
      replacedMainArea
      mainArea
      replacedSubject
      subject
      numSmartTags
      company {
        name
        about
        logo
        website
      }
      campaign {
        name
        custom_domain
        custom_domain_type
        agents {
          agent_id
          phone_number
        }
      }
      validEmail
      problems {
        message
        shortName
        type
      }
      disableSignature
    }
  }
`;
