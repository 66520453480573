import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal } from '_seriph';
import './ViewMapping.scss';
import { Table } from 'antd';

class ViewMapping extends Component {
  state = {
    visible: true
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { mappings, campaignName } = this.props;
    return (
      <Modal
        wrapClassName="view-mapping-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={false}
        width={800}
      >
        <Modal.Close onClick={this.hideModal} />
        <Modal.Header>Mapping for {campaignName}</Modal.Header>

        <Table
          className="sellx-table bordered"
          bordered
          rowSelection={null}
          columns={[
            {
              title: 'CSV Key',
              dataIndex: 'csvKey',
              key: 'csvKey'
            },
            {
              title: 'Lead Property',
              dataIndex: 'leadProperty',
              key: 'leadProperty'
            },
            {
              title: 'Example Value 1',
              dataIndex: 'exampleValueOne',
              key: 'exampleValueOne'
            },
            {
              title: 'Example Value 2',
              dataIndex: 'exampleValueTwo',
              key: 'exampleValueTwo'
            },
            {
              title: 'Example Value 3',
              dataIndex: 'exampleValueThree',
              key: 'exampleValueThree'
            }
          ]}
          rowKey={record => record.id}
          dataSource={mappings}
          pagination={{ pageSize: 20 }}
          scroll={{ x: 'max-content' }}
        />
      </Modal>
    );
  }
}

export default withRouter(ViewMapping);
