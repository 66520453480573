import gql from 'graphql-tag';

export const newCampaign = gql`
  mutation newCampaign(
    $name: String
    $objective: String
    $objective_type: String
    $rep_type: String
    $type: String
    $budget_ppl: Float
    $budget_ppql: Float
  ) {
    newCampaign(
      name: $name
      objective: $objective
      objective_type: $objective_type
      rep_type: $rep_type
      type: $type
      budget_ppl: $budget_ppl
      budget_ppql: $budget_ppql
    ) {
      id
      name
      objective
      objective_type
      rep_type
      type
      budget_ppl
      budget_ppql
    }
  }
`;

export const saveCampaign = gql`
  mutation saveCampaign($campaign: CampaignInput!) {
    saveCampaign(campaign: $campaign)
  }
`;

export const createDuplicate = gql`
  mutation createDuplicate($campaignId: String!) {
    createDuplicate(campaignId: $campaignId)
  }
`;

export const endCampaign = gql`
  mutation endCampaign($id: String!) {
    endCampaign(id: $id)
  }
`;

export const createTemplate = gql`
  mutation createTemplate($template: CampaignTemplate!, $campaign_id: String, $global: Boolean) {
    createTemplate(template: $template, campaign_id: $campaign_id, global: $global)
  }
`;

export const saveTemplate = gql`
  mutation saveTemplate($template: CampaignTemplate!) {
    saveTemplate(template: $template)
  }
`;

export const removeTemplate = gql`
  mutation removeTemplate($template_id: String!) {
    removeTemplate(template_id: $template_id)
  }
`;

export const createImport = gql`
  mutation createImport($import: CampaignImportInput!) {
    createImport(import: $import) {
      id
    }
  }
`;

export const removeImport = gql`
  mutation removeImport($import_id: String!) {
    removeImport(import_id: $import_id)
  }
`;

export const launchCampaign = gql`
  mutation launchCampaign($campaign_id: String!) {
    launchCampaign(campaign_id: $campaign_id) {
      id
    }
  }
`;

export const launchWithReview = gql`
  mutation launchWithReview($campaign_id: String!) {
    launchWithReview(campaign_id: $campaign_id) {
      id
    }
  }
`;

export const importLeads = gql`
  mutation importLeads($campaign_id: String!) {
    importLeads(campaign_id: $campaign_id)
  }
`;

export const getNewNumber = gql`
  mutation getNewNumber($campaign_id: String!) {
    getNewNumber(campaign_id: $campaign_id)
  }
`;

export const createApplication = gql`
  mutation createApplication(
    $note: String!
    $audio_url: String
    $commitment: Int
    $campaign_id: String!
  ) {
    createApplication(
      note: $note
      audio_url: $audio_url
      commitment: $commitment
      campaign_id: $campaign_id
    )
  }
`;

export const markApplicationSeen = gql`
  mutation markApplicationSeen($application_id: ID!) {
    markApplicationSeen(application_id: $application_id)
  }
`;

export const markApplicationExpanded = gql`
  mutation markApplicationExpanded($application_id: ID!) {
    markApplicationExpanded(application_id: $application_id)
  }
`;
