import gql from 'graphql-tag';

export const companyAgentStats = gql`
  query companyAgentStats($campaign_id: String, $status: String) {
    companyAgentStats(campaign_id: $campaign_id, status: $status) {
      hired
      applications
      passed
    }
  }
`;

export const companyHome = gql`
  query {
    companyHome {
      team_count
      campaign_count
      activity {
        leads {
          in_progress
          review
          not_interested
          disputed
          completed
          qualified
          meetings
        }
      }
      meetings {
        id
        stage
        salutation
        first_name
        last_name
        company
        meetingScheduled
        campaign {
          id
          name
        }
        calendly {
          created_at
          start_time
          end_time
          name
        }
      }
      campaigns {
        id
        name
        status
        status_updated
        objective
        stats {
          estimated_total
          remaining
          completed
          in_progress
          review
        }
        company {
          id
          name
          logo
        }
      }
      data {
        date
        daily {
          meetings
          touchpoint_email
          touchpoint_call
          touchpoint_linkedin
          touchpoint_instructions
          touchpoint_skipped
          touchpoint_total
        }
      }
      previous {
        date
        daily {
          meetings
          touchpoint_email
          touchpoint_call
          touchpoint_linkedin
          touchpoint_instructions
          touchpoint_skipped
          touchpoint_total
        }
      }
    }
  }
`;

export const agentHome = gql`
  query {
    agentHome {
      agent {
        imported
        profile_percent
        has_payment_method
        has_submitted_lead
        has_created_lead
        url_linkedin
        has_slack
        lead_count
      }
      email_count
      call_count
      text_count
      ending_soon
      activity {
        leads {
          in_progress
          review
          not_interested
          disputed
          completed
        }
      }
      balance
      last_payout_amount
      last_payout_date
      last_thirty
      stipend_email
      stipend_call
      stipend_linkedin
      stipend_total
      stipend_earnings_qualified
      stipend_earnings_not_interested
      stipend_earnings_pending
    }
  }
`;

export const internalHome = gql`
  query {
    internalHome {
      email_count
      call_count
      text_count
      activity {
        leads {
          in_progress
          review
          not_interested
          disputed
          completed
          qualified
          meetings
        }
      }
      meetings {
        id
        stage
        salutation
        first_name
        last_name
        company
        meetingScheduled
        campaign {
          id
          name
        }
        calendly {
          created_at
          start_time
          end_time
          name
        }
      }
      campaigns {
        id
        name
        status
        status_updated
        objective
        stats {
          estimated_total
          remaining
          completed
          in_progress
          review
        }
        company {
          id
          name
          logo
        }
      }
      data {
        date
        daily {
          meetings
          touchpoint_email
          touchpoint_call
          touchpoint_linkedin
          touchpoint_instructions
          touchpoint_skipped
          touchpoint_total
        }
      }
      previous {
        date
        daily {
          meetings
          touchpoint_email
          touchpoint_call
          touchpoint_linkedin
          touchpoint_instructions
          touchpoint_skipped
          touchpoint_total
        }
      }
    }
  }
`;

export const agentStats = gql`
  query agentStats($campaign_id: String, $status: String, $startRange: Int, $endRange: Int) {
    agentStats(
      campaign_id: $campaign_id
      status: $status
      startRange: $startRange
      endRange: $endRange
    ) {
      days {
        date
        daily {
          in_progress
          review
          not_interested
          disputed
          generated
          qualified
          meetings
          called
          called_answered
          called_no_answer
          incoming
          incoming_answered
          emailed
          delivered
          opened
          replied
          notes
          total_paid
          generated_paid
          qualified_paid
          total_fees
          generated_fees
          qualified_fees
        }
        historical {
          in_progress
          review
          not_interested
          disputed
          generated
          qualified
          meetings
          called
          called_answered
          called_no_answer
          incoming
          incoming_answered
          emailed
          delivered
          opened
          replied
          notes
          total_paid
          generated_paid
          qualified_paid
          total_fees
          generated_fees
          qualified_fees
        }
      }
      leads {
        in_progress
        review
        not_interested
        disputed
        completed
      }
      campaigns {
        id
        name
        objective
        status
        budget_total
        actual_budget
        paid
        target_agent_count
        agent_count
        progress
        status_updated
        company {
          id
          name
          logo
        }
        employee {
          id
          picture
          display_name
          title
        }
        agents {
          agent_id
          paid
        }
      }
    }
  }
`;

export const campaignStats = gql`
  query campaignStats($campaign_id: String, $status: String, $startRange: Int, $endRange: Int) {
    campaignStats(
      campaign_id: $campaign_id
      status: $status
      startRange: $startRange
      endRange: $endRange
    ) {
      days {
        date
        daily {
          in_progress
          review
          not_interested
          disputed
          generated
          qualified
          meetings
          called
          called_answered
          called_no_answer
          incoming
          incoming_answered
          emailed
          delivered
          opened
          replied
          notes
          total_paid
          generated_paid
          qualified_paid
          total_fees
          generated_fees
          qualified_fees
        }
        historical {
          in_progress
          review
          not_interested
          disputed
          generated
          qualified
          meetings
          called
          called_answered
          called_no_answer
          incoming
          incoming_answered
          emailed
          delivered
          opened
          replied
          notes
          total_paid
          generated_paid
          qualified_paid
          total_fees
          generated_fees
          qualified_fees
        }
      }
      leads {
        in_progress
        review
        not_interested
        disputed
        completed
      }
      campaigns {
        id
        name
        objective
        status
        budget_total
        actual_budget
        paid
        target_agent_count
        agent_count
        progress
        status_updated
        company {
          id
          name
          logo
        }
        employee {
          id
          picture
          display_name
          title
        }
        agents {
          agent_id
          paid
        }
      }
    }
  }
`;
