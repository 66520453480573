import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getErrors } from '_assets/js/helpers';
import { Modal, Button, Header, Steps, Input, Tag, message } from '_seriph';
import { sendCompanyInvite, revokeCompanyInvite } from '../../../_graphql/mutations';
import moment from 'moment';
import './OnboardCompanyModal.scss';

class OnboardCompanyModal extends Component {
  state = {
    visible: true,
    creating: false,
    revoking: false,
    saving: false
  };
  sendInvite = () => {
    this.setState({ creating: true });
    const application_id = this.props.app?.id;
    this.props.client
      .mutate({ variables: { application_id }, mutation: sendCompanyInvite })
      .then(result => {
        if (result && result.data && result.data.sendCompanyInvite) {
          message.success('Invitation sent to ' + this.props.app?.email);
          if (this.props.reload) this.props.reload();
          this.hideModal();
        }
        this.setState({ creating: false });
      })
      .catch(err => {
        this.setState({ creating: false });
        message.error(getErrors(err) || 'Error sending invitation, try again.');
      });
  };
  revokeInvitation = () => {
    Modal.confirm({
      title: 'Are you sure you want to revoke this invitation?',
      okText: 'Revoke Invitation',
      cancelText: 'Cancel',
      onOk: () => {
        this.setState({ revoking: true });
        const application_id = this.props.app?.id;
        this.props.client
          .mutate({ variables: { application_id }, mutation: revokeCompanyInvite })
          .then(result => {
            if (result && result.data && result.data.revokeCompanyInvite) {
              message.success('Invitation revoked');
              if (this.props.reload) this.props.reload();
              this.hideModal();
            }
            this.setState({ revoking: false });
          })
          .catch(err => {
            this.setState({ revoking: false });
            message.error(getErrors(err) || 'Error revoking invitation, try again.');
          });
      }
    });
  };
  copyToClipboard = () => {
    this.funky.select();
    document.execCommand('copy');
    message.success('Copied to clipboard');
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { saving, revoking } = this.state;
    const { app } = this.props;

    let current = 1;
    if (app.invite.invited) current = 2;
    if (app.employee_id) current = 3;

    return (
      <Modal
        wrapClassName="onboard-company-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={true}
        width={500}
      >
        <Modal.Close onClick={this.hideModal} />

        <Header size="4" font="sans" className="new-header">
          Start Onboarding {app.company_name}
        </Header>

        <div className="sequence-form">
          <Steps direction="vertical" size="small" current={current}>
            <Steps.Step
              title="Review Application"
              description={
                <div className="step-desc">
                  An application must be a good fit to move through onboarding.
                  <br />
                  <Button size="tiny" type="secondary" onClick={this.props.showAppModal}>
                    Edit Review
                  </Button>
                </div>
              }
            />
            <Steps.Step
              title="Create Invitation"
              description={
                <div className="step-desc">
                  Let&apos;s get started by sending {app.name} and invitation to join SellX.
                  <br />
                  {app.invite?.sent ? (
                    <Tag type="success">
                      Invite sent: <b>{moment(app.invite.sent * 1).format('L @ h:mm a')}</b>
                    </Tag>
                  ) : (
                    <Button
                      size="tiny"
                      type="primary"
                      loading={this.state.creating}
                      disabled={current !== 1}
                      onClick={this.sendInvite}
                    >
                      Send Invitation
                    </Button>
                  )}
                </div>
              }
            />
            <Steps.Step
              title={`Waiting for ${app.company_name} to join...`}
              description={
                <div className="step-desc">
                  Need to send a reminder? Send them another email or copy the link and send it.
                  <br />
                  {app.invite?.joined ? (
                    <Tag type="success">
                      Joined: <b>{moment(app.invite.joined * 1).format('L @ h:mm a')}</b>
                    </Tag>
                  ) : (
                    <React.Fragment>
                      <Input
                        size="medium"
                        ref={nput => (this.funky = nput)}
                        value={app.invite?.link || null}
                      />
                      <div>
                        <Button
                          size="tiny"
                          type="secondary"
                          disabled={current !== 2}
                          onClick={this.copyToClipboard}
                        >
                          Copy Link
                        </Button>
                        <Button
                          size="tiny"
                          type="primary"
                          disabled={current !== 2}
                          onClick={this.sendInvite}
                        >
                          Re-send Invite
                        </Button>
                      </div>
                      {app.invite?.resent ? (
                        <Tag>
                          Invite Resent: <b>{moment(app.invite.resent * 1).format('L @ h:mm a')}</b>
                        </Tag>
                      ) : null}
                    </React.Fragment>
                  )}
                </div>
              }
            />
          </Steps>
        </div>

        <Modal.Actions align="right">
          {!app.employee_id && app.company_id ? (
            <Button
              type="danger"
              className="left"
              loading={revoking}
              onClick={() => this.revokeInvitation()}
            >
              Revoke Invitation
            </Button>
          ) : null}
          <Button type="default" onClick={() => this.hideModal()}>
            Close
          </Button>
          {current < 3 && (
            <Button type="primary" loading={saving} onClick={this.updateCompanyReview}>
              Save
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withRouter(OnboardCompanyModal);
