import React, { Component } from 'react';
import './Instructions.scss';

export default class Instructions extends Component {
  UNSAFE_componentWillMount = () => window.scrollTo({ top: 0 });
  render() {
    const { campaign } = this.props;
    return (
      <div id="dial-training">
        <h3 className="call-h3">Instructions</h3>
        {campaign?.guidelines ? (
          <div
            className="html-instructions"
            dangerouslySetInnerHTML={{ __html: campaign.guidelines }}
          />
        ) : (
          <div className="html-instructions">
            There are currently no instructions for this campaign, reach out to the owner for
            more information
          </div>
        )}
      </div>
    );
  }
}
