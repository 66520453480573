import React, { Component } from 'react';
import { Header, Card } from '_seriph';
import { Radio } from 'antd';
import { numberWithCommas } from '_assets/js/helpers';
import { generateData } from './data';
import TaskChart from './_charts/TaskChart/TaskChart';
import TaskTable from './_charts/TaskTable/TaskTable';
import './ScoreTasks.scss';

class ScoreTasks extends Component {
  state = {
    tab: 'table'
  }
  render() {
    const { data } = this.props;
    const { tab } = this.state;

    const realData = generateData(data);

    return (
      <div id="rep-analytics-scorecard-tasks">
        <Header size="3" font="sans">
          Task Analytics
        </Header>
        <div className="xdr-totals">
          <Card>
            <div className="descriptor">
              Calls
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(realData?.totals?.touchpoint_call)}
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              Emails
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(realData?.totals?.touchpoint_email)}
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              LinkedIn
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(realData?.totals?.touchpoint_linkedin)}
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              Skipped
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(realData?.totals?.touchpoint_skipped)}
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              Tasks
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(realData?.totals?.touchpoint_total)}
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              Meetings
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(realData?.totals?.meetings)}
            </Header>
          </Card>
        </div>
        <Card className="task-chart">
          <div className="task-toggle">
            <Radio.Group value={tab} onChange={e => this.setState({ tab: e.target.value })} size="small">
              <Radio.Button value="table">Table View</Radio.Button>
              <Radio.Button value="chart">Chart View</Radio.Button>
            </Radio.Group>
          </div>
          { tab === 'chart' && <TaskChart data={realData.vs} totals={realData.totals} /> }
          { tab === 'table' && <TaskTable data={data} /> }
        </Card>
      </div>
    );
  }
}

export default ScoreTasks;
