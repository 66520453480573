import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { adminRepStats } from '../../../_graphql/queries';
import { Header, message, Table, Card } from '_seriph';
import { DatePicker, Select } from 'antd';
import moment from 'moment';
import { getColumns } from './Columns';
import CompanyDropdown from '_shared/CompanyDropdown/CompanyDropdown';
import { numberWithCommas, formatMoney } from '_assets/js/helpers';
import { masqueradeUser } from '../../../_graphql/mutations';
import { loginUser } from 'Store/user/user_actions';
import './RepStats.scss';

const { MonthPicker, WeekPicker } = DatePicker;

class RepStats extends Component {
  state = {
    loading: true,
    date: moment().format('YYYYMMDD'),
    type: 'weekly',
    tableData: [],
    company_id: undefined,
    internal: 'xdr'
  };
  componentDidMount = () => {
    this.getStats();
  };
  getStats = () => {
    if (!this.state.loading) this.setState({ loading: true });
    this.props.client
      .query({
        variables: {
          date: this.state.date,
          type: this.state.type,
          company_id: this.state.company_id,
          internal: this.state.internal || 'xdr'
        },
        query: adminRepStats
      })
      .then(result => {
        if (result && result.data && result.data.adminRepStats) {
          const data = result.data.adminRepStats?.data || [];
          this.setState({ 
            tableData: data,
            totals: this.getTotals(data)
          }, () => {
            this.setState({ loading: false });
          });
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        message.error('Could not load your statistics, try again');
        this.setState({ loading: false });
      });
  };
  getTotals = data => {
    const totals = {};

    // Gather totals
    data.map(r => {
      for (const key in r.daily) {
        if (Number.isInteger(r.daily[key])) {
          totals[key] = !totals[key] ? r.daily[key] : totals[key] + r.daily[key];
        }
      }
      return r;
    });
    return totals;
  };
  masq = agent_id => {
    this.props.client
      .mutate({
        variables: { user_id: agent_id },
        mutation: masqueradeUser
      })
      .then(result => {
        if (result && result.data && result.data.masqueradeUser) {
          this.props.loginUser(result.data.masqueradeUser);
          this.props.history.push('/');
        } else {
          throw new Error('Could not login as agent, try again');
        }
      })
      .catch(err => {
        message.error(err.message);
      });
  };
  onChange = currentDate => {
    const { type } = this.state;
    let date = currentDate;
    if (type === 'weekly') {
      date = currentDate.startOf('week');
    } else if (type === 'monthly') {
      date = currentDate.startOf('month');
    }
    this.setState({ date: date.format('YYYYMMDD') }, this.getStats);
  };
  onYearChange = year => {
    const currentDate = moment().day('Monday').year(year).week('24');
    this.setState({ date: currentDate.format('YYYYMMDD') }, this.getStats);
  };
  onCompanyChange = company_id => {
    this.setState({ company_id }, this.getStats);
  };
  UNSAFE_componentWillMount = () => window.scrollTo({ top: 0 });
  render() {
    const { internal } = this.props;
    const { tableData, totals, loading } = this.state;

    const year = moment(this.state.date, 'YYYYMMDD').format('YYYY');

    return (
      <div id="rep-analytics-stats">
        <div className="seriph-header-wrapper">
          <Header type="display" weight="600" size="4">
            { internal ? 'Internal Rep Stats' : 'XDR Stats' }
          </Header>
        </div>

        <div className="dashboard-actions">
          <span className="no-left">Viewing</span>
          <Select value={this.state.type} onChange={type => this.setState({ type }, this.getStats)}>
            <Select.Option value="daily">Daily</Select.Option>
            <Select.Option value="weekly">Weekly</Select.Option>
            <Select.Option value="monthly">Monthly</Select.Option>
            <Select.Option value="yearly">Yearly</Select.Option>
          </Select>
          <span>stats, for</span>
          {this.state.type === 'daily' && (
            <DatePicker 
              onChange={this.onChange}
              defaultValue={moment().startOf('week')}
              format="[the day of] M/D/YY"
            />
          )}
          {this.state.type === 'weekly' && (
            <WeekPicker 
              onChange={this.onChange}
              defaultValue={moment().startOf('week')}
              format="[the week of] M/D/YY"
            />
          )}
          {this.state.type === 'monthly' && (
            <MonthPicker 
              onChange={this.onChange}
              defaultValue={moment().startOf('week')}
              format="[the month of] MMM YYYY"
            />
          )}
          {this.state.type === 'yearly' && (
            <Select value={year} onChange={this.onYearChange}>
              <Select.Option value="2021">2021</Select.Option>
              <Select.Option value="2022">2022</Select.Option>
              <Select.Option value="2023">2023</Select.Option>
            </Select>
          )}
          <span>under</span>
          <CompanyDropdown value={this.state.company_id} onChange={this.onCompanyChange} />
          <span>company(s), with only</span>
          <Select value={this.state.internal} onChange={internal => this.setState({ internal }, this.getStats)}>
            <Select.Option value="all">All Reps</Select.Option>
            <Select.Option value="xdr">XDRs Only</Select.Option>
            <Select.Option value="internal">Internal Only</Select.Option>
          </Select>
        </div>
        <div className="xdr-totals">
          <Card>
            <div className="descriptor">
              Calls
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(totals?.touchpoint_call)}
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              Emails
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(totals?.touchpoint_email)}
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              LinkedIn
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(totals?.touchpoint_linkedin)}
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              Total Tasks
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(totals?.touchpoint_total)}
            </Header>
          </Card>
        </div>
        <div className="xdr-totals">
          <Card>
            <div className="descriptor">
              Meetings Booked
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(totals?.meetings)}
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              Average Meeting Value
            </div>
            <Header size="2" font="sans">
              {formatMoney(totals?.meeting_value / parseFloat(totals?.meetings))}
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              Meeting $ / Task
            </div>
            <Header size="2" font="sans">
              {formatMoney(totals?.meeting_value / parseFloat(totals?.touchpoint_total || 1))}
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              Total Meeting Value
            </div>
            <Header size="2" font="sans">
              {formatMoney(totals?.meeting_value)}
            </Header>
          </Card>
        </div>
        <div className="task-table">
          <Table
            lined
            rounded
            loading={loading}
            locale={{ emptyText: loading ? 'Loading company statistics...' : 'No statistics found' }}
            dataSource={tableData.map(d => {
              d.key = d.agent?.id;
              return d;
            })}
            scroll={{ x: 'max-content' }}
            pagination={{ pageSize: 10 }}
            columns={getColumns(this.masq)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, { loginUser })(RepStats));
