import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './AddBox.scss';

export default class AddBox extends Component {
  render() {
    return (
      <div className="add-box">
        <div onClick={() => this.props.action()} className="add-box-text" role="presentation">
          <FontAwesomeIcon icon={faPlus} /> Add {this.props.title}
          <span>+ {this.props.percent}</span>
        </div>
      </div>
    );
  }
}
