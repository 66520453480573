import React from 'react';
import moment from 'moment';
import { IconStar, IconPlayerPlay, IconPlayerPause, IconDots, IconX } from '@tabler/icons-react';
import { Avatar, Button } from '_seriph';
import { Dropdown, Menu } from 'antd';
import { computeLeadStatus } from '_helpers/leadStatus';

export const getColumns = (
  callFavoriteClicked,
  playRecording,
  playingRecording,
  dropdownClicked,
  dropdownVisible,
  onVisibleChange,
  history
) => {
  return [
    {
      title: 'Lead / Campaign',
      dataIndex: 'lead',
      fixed: 'left',
      render: (column, data) => {
        const call = data?.call || {};
        const lead = data?.call?.lead || {};
        const campaign = data.call?.campaign || {};

        const formattedTime = moment(parseInt(call.createdAt)).format('MM/DD/YYYY • h:mma');
        let mins = moment.duration(call.duration * 1000).minutes();
        if (mins < 10) {
          mins = `0${mins}`;
        }
        let secs = moment.duration(call.duration * 1000).seconds();
        if (secs < 10) {
          secs = `0${secs}`;
        }
        return (
          <div className="lead-cont">
            <div
              className={`star-section ${data.is_favorited ? 'starred' : ''}`}
              onClick={() => {
                callFavoriteClicked(call.id);
              }}
            >
              <IconStar />
            </div>
            <div className="lead-section">
              <div className="lead-name">
                {lead.first_name} {lead.last_name}
              </div>
              <div className="campaign-name">{campaign.name}</div>
              <div className="call-time">
                {formattedTime} • {mins}:{secs}
              </div>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      render: (column, data) => {
        const agent = data?.call?.agent || {};
        return (
          <div className="agent-picture">
            <Avatar size="mini" type="agent" picture={agent.picture} />
            <div className="agent-info">
              <h4>{agent.display_name}</h4>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Company',
      dataIndex: 'company',
      render: (column, data) => {
        const company = data?.call?.campaign?.company || {};
        return <div className="company-name">{company.name}</div>;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (column, data) => {
        const lead = data?.call?.lead || {};
        const campaign = data.call?.campaign || {};

        let stageClassNames = ['lead-stage'];
        const leadStatus = computeLeadStatus(false, lead, campaign);
        let actualClass =
          leadStatus && leadStatus.circleClass === 'invalid' ? 'invalid' : lead.stage;
        stageClassNames.push(actualClass);

        return (
          <div className="lead-stage-cont">
            <div className={stageClassNames.join(' ')}>{leadStatus.firstLevel}</div>
          </div>
        );
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (column, data) => {
        const call = data?.call || {};
        const lead = data?.call?.lead || {};

        const dropdownMenu = (
          <Menu mode="vertical">
            <Menu.Item onClick={() => callFavoriteClicked(call.id)}> Favorite</Menu.Item>
            {call.recording_url && (
              <Menu.Item onClick={() => {}}>
                <a rel="noreferrer" href={call.recording_url} target="_blank" download>
                  Download audio
                </a>
              </Menu.Item>
            )}
            <Menu.Item onClick={() => history.push('/leads/' + lead.id)}>Go to lead</Menu.Item>
          </Menu>
        );

        return (
          <div className="actions-cont">
            {call.recording_url ? (
              <Button
                type="primary"
                size="tiny"
                circle={true}
                onClick={() => {
                  playRecording(call.id, call.recording_url);
                }}
              >
                {call.id === playingRecording ? <IconPlayerPause /> : <IconPlayerPlay />}
              </Button>
            ) : (
              <Button type="disabled" size="tiny" circle={true}>
                <IconX />
              </Button>
            )}

            <Dropdown
              overlay={dropdownMenu}
              overlayClassName="seriph-dropdown"
              trigger={['click']}
              placement="bottomRight"
              arrow
              visible={dropdownVisible === call.id}
              onVisibleChange={() => onVisibleChange(call.id)}
            >
              <div className="tbl-btn" onClick={() => dropdownClicked(call.id)}>
                <IconDots size={18} stroke={2} />
              </div>
            </Dropdown>
          </div>
        );
      }
    }
  ];
};
