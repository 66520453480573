import React, { Component } from 'react';
import { Select } from '_seriph';
import { leadFields, companyFields, repFields } from '_constants/leadFields';

export default class SmartTags extends Component {
  render() {
    return (
      <div className="smart-tags">
        <Select
          size="small"
          placeholder={this.props.placeholder || 'Insert smart tag'}
          value={undefined}
          disabled={this.props.disabled}
          onSelect={this.props.onSelect}
          onFocus={this.props.onFocus}
        >
          <Select.OptGroup key="Lead Information" label="Lead Information">
            {Object.keys(leadFields).map(c =>
              Object.keys(leadFields[c]).map(f =>
                leadFields[c][f].canTag ? (
                  <Select.Option
                    key={leadFields[c][f].field}
                    value={'lead.' + leadFields[c][f].field}
                  >
                    {f}
                  </Select.Option>
                ) : null
              )
            )}
          </Select.OptGroup>
          {Object.keys(companyFields).map(c => (
            <Select.OptGroup key={c} label={c}>
              {Object.keys(companyFields[c]).map(f =>
                companyFields[c][f].canTag ? (
                  <Select.Option
                    key={companyFields[c][f].field}
                    value={'company.' + companyFields[c][f].field}
                  >
                    {f}
                  </Select.Option>
                ) : null
              )}
            </Select.OptGroup>
          ))}
          {Object.keys(repFields).map(c => (
            <Select.OptGroup key={c} label={c}>
              {Object.keys(repFields[c]).map(f =>
                repFields[c][f].canTag ? (
                  <Select.Option key={repFields[c][f].field} value={'rep.' + repFields[c][f].field}>
                    {f}
                  </Select.Option>
                ) : null
              )}
            </Select.OptGroup>
          ))}
        </Select>
      </div>
    );
  }
}
