import gql from 'graphql-tag';

export const createPlaid = gql`
  mutation createPlaid($token: String!, $metadata: String!) {
    createPlaid(token: $token, metadata: $metadata)
  }
`;

export const inviteCompanyOwner = gql`
  mutation inviteCompanyOwner($company_id: String!) {
    inviteCompanyOwner(company_id: $company_id)
  }
`;

export const inviteMember = gql`
  mutation inviteMember($email: String!, $role: String!, $resend: Boolean) {
    inviteMember(email: $email, role: $role, resend: $resend)
  }
`;

export const inviteTeamRep = gql`
  mutation inviteTeamRep($email: String!, $resend: Boolean, $campaigns_auto_add: [String]) {
    inviteTeamRep(email: $email, resend: $resend, campaigns_auto_add: $campaigns_auto_add)
  }
`;

export const withdrawTeamRepInvite = gql`
  mutation withdrawTeamRepInvite($invite_id: String) {
    withdrawTeamRepInvite(invite_id: $invite_id)
  }
`;

export const updateTeamPermission = gql`
  mutation updateTeamPermission($type: String!, $id: String!, $value: String!) {
    updateTeamPermission(type: $type, id: $id, value: $value)
  }
`;

export const removeTeamMember = gql`
  mutation removeTeamMember($type: String!, $id: String!) {
    removeTeamMember(type: $type, id: $id)
  }
`;

export const removeTeamRep = gql`
  mutation removeTeamRep($type: String!, $id: String!) {
    removeTeamRep(type: $type, id: $id)
  }
`;

export const createCompanyApplication = gql`
  mutation createCompanyApplication($name: String!, $email: String!, $phone: String!) {
    createCompanyApplication(name: $name, email: $email, phone: $phone)
  }
`;

export const updateCompanyApplication = gql`
  mutation updateCompanyApplication(
    $id: String
    $status: String
    $name: String
    $email: String
    $phone: String
    $company_name: String
    $company_website: String
    $company_size: String
    $industries: [String]
    $needs: [String]
    $hear_about: [String]
    $hear_about_other: String
    $desired_start: String
    $desired_engagement: String
    $ok_with_remote: String
    $ok_with_remote_explanation: String
    $is_calendly_event_scheduled: Boolean
    $calendly_url: String
  ) {
    updateCompanyApplication(
      id: $id
      status: $status
      name: $name
      email: $email
      phone: $phone
      company_name: $company_name
      company_website: $company_website
      company_size: $company_size
      industries: $industries
      needs: $needs
      hear_about: $hear_about
      hear_about_other: $hear_about_other
      desired_start: $desired_start
      desired_engagement: $desired_engagement
      ok_with_remote: $ok_with_remote
      ok_with_remote_explanation: $ok_with_remote_explanation
      is_calendly_event_scheduled: $is_calendly_event_scheduled
      calendly_url: $calendly_url
    )
  }
`;

export const withdrawCampaignInvite = gql`
  mutation withdrawCampaignInvite($rep_id: String!, $campaign_ids: [String!]) {
    withdrawCampaignInvite(rep_id: $rep_id, campaign_ids: $campaign_ids)
  }
`;

export const resendCampaignInvite = gql`
  mutation resendCampaignInvite($rep_id: String!, $campaign_ids: [String!]) {
    resendCampaignInvite(rep_id: $rep_id, campaign_ids: $campaign_ids)
  }
`;

export const betaOptIn = gql`
  mutation betaOptIn {
    betaOptIn
  }
`;
