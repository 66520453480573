import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { campaignsTable } from '_graphql/queries/campaign';
import { getColumns } from './_components/Columns';
import { Button, Tabs, Table, Header } from '_seriph';
import './Campaigns.scss';

const paginationInit = {
  current: 1,
  pageSize: 5
};

class Campaigns extends Component {
  state = {
    campaigns: [],
    reps: [],
    loading: true,
    loaded: false,
    tab: 'initial',
    counts: { active: 0, completed: 0, inactive: 0, draft: 0 },
    pagination: paginationInit
  };
  componentDidMount = () => {
    this.getCampaigns();
  };
  getCampaigns = () => {
    if (!this.state.loading) this.setState({ loading: true });
    this.props.client
      .query({ variables: { status: this.state.tab }, query: campaignsTable })
      .then(result => {
        if (result && result.data && result.data.campaignsTable) {
          const campaigns = result.data.campaignsTable.campaigns || [];
          const counts = result.data.campaignsTable.counts;
          const reps = result.data.campaignsTable.reps || [];
          let tab = result.data.campaignsTable.tab;
          this.setState({
            campaigns,
            reps,
            counts,
            tab,
            loading: false,
            loaded: true,
            pagination: paginationInit
          });
        }
      });
  };
  mapAgents = agents => {
    return agents
      .filter(a => a.active === true)
      .map(a => {
        const findRep = this.state.reps.find(r => r.id === a.agent_id);
        if (findRep) return { ...a, ...findRep };
        return a;
      });
  };
  changeTab = tab => {
    this.setState({ tab }, this.getCampaigns);
  };
  render() {
    const { campaigns, loading, tab, counts, pagination } = this.state;
    const filtered = campaigns
      .filter(j => {
        return j.status === tab;
      })
      .sort((a, b) => {
        if (a.status === 'active') return -1;
        if (a.status === 'inactive' || b.status === 'draft') return -1;
        return 1;
      });

    const startNumber = (pagination.current - 1) * pagination.pageSize + 1;
    const highNumber = startNumber + pagination.pageSize - 1;
    const amount = startNumber + '-' + (highNumber > counts[tab] ? counts[tab] : highNumber);
    const customInfo = `Page ${pagination.current} of ${Math.ceil(
      counts[tab] / pagination.pageSize
    )} / Showing ${amount} of ${counts[tab]}`;

    return (
      <div id="company-campaigns">
        <div className="seriph-header-wrapper">
          <Header type="display" weight="600" size="4">
            My Campaigns
          </Header>
          <div className="header-actions">
            <Link to="/wizard/new">
              <Button type="primary">Create a Campaign</Button>
            </Link>
          </div>
        </div>
        <Tabs onChange={this.changeTab} currentTab={tab}>
          <Tabs.Tab key="active" amount={counts.active} disabled={!counts.active}>
            ACTIVE
          </Tabs.Tab>
          <Tabs.Tab key="inactive" amount={counts.inactive} disabled={!counts.inactive}>
            PAUSED
          </Tabs.Tab>
          <Tabs.Tab key="draft" amount={counts.draft} disabled={!counts.draft}>
            DRAFT
          </Tabs.Tab>
          <Tabs.Tab key="completed" amount={counts.completed} disabled={!counts.completed}>
            COMPLETED
          </Tabs.Tab>
        </Tabs>
        <Table
          lined
          rounded
          loading={loading}
          locale={{ emptyText: loading ? 'Loading campaigns...' : 'No campaigns found' }}
          dataSource={filtered.map(d => {
            d.key = d.id;
            return d;
          })}
          pagination={{ pageSize: paginationInit.pageSize }}
          customInfo={loading ? null : customInfo}
          onChange={pagination => this.setState({ pagination })}
          columns={getColumns(this.getCampaigns, this.mapAgents)}
        />
      </div>
    );
  }
}

export default withRouter(Campaigns);
