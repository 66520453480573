import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCompany } from '_graphql/queries/company';
import { getNotificationCampaign } from '_graphql/queries/notifications';
import {
  createNotificationCampaign,
  removeNotificationCampaign
} from '_graphql/mutations/notification';
import { inviteCompanyOwner } from '_graphql/mutations/company';
import Overview from './_components/Overview/Overview';
import About from './_components/About/About';
import EditLogo from './_modals/EditLogo/EditLogo';
import EditDetails from './_modals/EditDetails/EditDetails';
import { Header, Card, Loading, Avatar, Button, message, Tooltip, Toggle } from '_seriph';
import { IconBellRinging } from '@tabler/icons-react';
import './Profile.scss';

class Profile extends Component {
  state = {
    company: null,
    tab: 'overview',
    loading: true,
    notified: null,
    logoVisible: false,
    editorVisible: false,
    editorRender: false,
    inviting: false
  };
  componentDidMount = () => {
    this.getProfileInformation();
    this.getNotificationCampaign();
  };
  getProfileInformation = () => {
    this.props.client
      .query({ variables: { id: this.props.companyId }, query: getCompany })
      .then(result => {
        if (result && result.data && result.data.company) {
          this.setState({ company: result.data.company, loading: false });
        } else {
          message.error('Could not load company, try again');
        }
      });
  };
  getNotificationCampaign = () => {
    this.props.client
      .query({ variables: { company_id: this.props.companyId }, query: getNotificationCampaign })
      .then(result => {
        if (result && result.data && result.data.getNotificationCampaign) {
          this.setState({ notified: true });
        } else {
          this.setState({ notified: false });
        }
      });
  };
  updateNotify = checked => {
    this.setState({ notified: checked });
    this.props.client
      .mutate({
        variables: { company_id: this.props.companyId || this.state.company.id },
        mutation: checked ? createNotificationCampaign : removeNotificationCampaign
      })
      .then(() => {
        if (checked) {
          message.success(
            'You will now be notified when ' + this.state.company.name + ' posts a new campaign'
          );
        } else {
          message.success(
            'You will no longer receive notifications from ' + this.state.company.name
          );
        }
      })
      .catch(() => {
        this.setState({ notified: !checked });
        message.error('Could not update your notification preferences, try again');
      });
  };
  inviteOwner = () => {
    this.setState({ inviting: true });
    this.props.client
      .mutate({
        variables: { company_id: this.props.companyId || this.state.company.id },
        mutation: inviteCompanyOwner
      })
      .then(result => {
        if (result && result.data && result.data.inviteCompanyOwner) {
          message.success('Invite sent to email that applied with this company');
        }
        this.setState({ inviting: false });
      })
      .catch(() => {
        this.setState({ inviting: false });
        message.error('Could not invite owner, try again');
      });
  };
  changeTab = tab => {
    this.setState({ tab });
  };
  openLogo = () => this.setState({ logoVisible: true });
  closeLogo = () => this.setState({ logoVisible: false });
  openEditor = () => this.setState({ editorVisible: true, editorRender: true });
  closeEditor = () => this.setState({ editorVisible: false });
  destroyEditor = () => this.setState({ editorRender: false });
  render() {
    const { company, tab, notified, loading, inviting } = this.state;

    const isAgent = this.props.user ? this.props.user.role_type === 'agent' : false;
    const canEdit =
      this.props.user &&
      (this.props.user.company === this.props.companyId || this.props.user.role_type === 'admin');
    const myCampaigns =
      this.props.info && this.props.info.campaigns ? this.props.info.campaigns : [];

    const allTeam = company?.employees || [];

    return loading ? (
      <Loading />
    ) : (
      <div id="company-profile">
        {canEdit && (
          <div className="seriph-header-wrapper">
            <Header type="display" weight="600" size="4">
              Company Profile
            </Header>
            <div className="header-actions">
              <Button type="primary" onClick={this.openLogo}>
                Change Logo
              </Button>
              <Button type="primary" onClick={this.openEditor}>
                Edit Profile
              </Button>
              {!company.owner && this.props.user.role_type === 'admin' ? (
                <Button loading={inviting} type="primary" onClick={this.inviteOwner}>
                  Invite Owner
                </Button>
              ) : null}
            </div>
          </div>
        )}

        <div className="company-main">
          <Card className="company-data">
            <div className="data-header">
              <Avatar type="company" picture={company.logo} />
              <div className="data-text">
                <Header font="sans" size="2">
                  {company.name}
                </Header>
                <p>
                  {company.about || <i>This company has not written an about statement yet...</i>}
                </p>
              </div>
              <div className="data-team">
                <span>The Team</span>
                <Tooltip title={`${allTeam.length} members from ${company.name}`}>
                  <div className="team-list">
                    <div className="team-avs">
                      {allTeam.slice(0, 3).map(r => (
                        <img key={'teamav-' + r.id} src={r.picture} alt="" />
                      ))}
                    </div>
                    {allTeam > 3 ? (
                      <div className="oversized">+{allTeam.length - 3} more</div>
                    ) : null}
                  </div>
                </Tooltip>
              </div>
            </div>

            {tab === 'overview' && (
              <Overview
                company={company}
                myCampaigns={myCampaigns}
                isAgent={isAgent}
                client={this.props.client}
                user={this.props.user}
              />
            )}
          </Card>

          <div className="company-cards">
            {isAgent && (
              <Card className="company-notify">
                <div className="bell">
                  <IconBellRinging />
                </div>
                <Header font="sans" size="4">
                  Get notified when {company.name} posts a new campaign
                </Header>
                <Toggle
                  disabled={notified === null}
                  checked={notified}
                  onChange={this.updateNotify}
                />
              </Card>
            )}

            <Card className="company-about">
              <Header font="sans" size="4">
                About {company.name}
              </Header>
              <About company={company} />
            </Card>
          </div>

          {company && canEdit && this.state.editorRender ? (
            <EditDetails
              company={company}
              visible={this.state.editorVisible}
              close={this.closeEditor}
              destroy={this.destroyEditor}
              client={this.props.client}
              reload={this.getProfileInformation}
              companyId={this.props.companyId}
            />
          ) : null}

          {company && canEdit ? (
            <EditLogo
              visible={this.state.logoVisible}
              close={this.closeLogo}
              client={this.props.client}
              reload={this.getProfileInformation}
              companyId={this.props.companyId}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(Profile));
