import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getImports } from '_graphql/queries/campaign';
import { calculateValue } from '../../_helpers/budget';
import { durationText } from '../../_helpers/durationText';
import Infotip from '_shared/Infotip/Infotip';
import { Locked } from '_shared/Locked/Locked';
import { generateDuration, qualifyDuration } from '_constants/campaigns';
import { Input, InputNumber, Card, Select, Button, Loading, Tag } from '_seriph';
import { IconBolt } from '@tabler/icons-react';
import ThresholdModal from '_styleguide/ThresholdModal/ThresholdModal';
import './Threshold.scss';

class Threshold extends Component {
  state = {
    importAmount: 0,
    loadingImports: this.props.campaign.objective !== 'leadgen'
  };
  componentDidMount = () => {
    if (this.props.save) this.props.save();
    if (this.props.campaign.objective !== 'leadgen') this.loadImports();
  };
  loadImports = () => {
    this.props.client
      .query({ variables: { campaign_id: this.props.campaignId }, query: getImports })
      .then(result => {
        if (result && result.data && result.data.imports) {
          const imports = result.data.imports || [];
          if (imports && imports.length > 0) {
            this.setState({
              importAmount: parseInt(imports[0].amount || 0),
              loadingImports: false
            });
          } else {
            this.setState({ loadingImports: false });
          }
        }
      });
  };
  onChangePayPerLead = val => {
    const isGen = this.props.campaign.objective === 'qualify' ? false : true;
    this.props.update(isGen ? 'budget_ppl' : 'budget_ppql', val);
  };
  showThresholdModal = () => this.setState({ thresholdModal: true });
  removeThresholdModal = () => this.setState({ thresholdModal: false });
  render() {
    const { campaign, update, updateObject, actions, isLocked } = this.props;
    const { importAmount } = this.state;

    const isGen = campaign.objective === 'qualify' ? false : true;
    const payPerLead = isGen ? campaign.budget_ppl : campaign.budget_ppql;
    const moreLanguage = campaign.objective === 'qualify' ? ' to reach out to' : '';
    const calc = calculateValue(campaign, importAmount);
    const duration = isGen ? generateDuration : qualifyDuration;

    return (
      <Card id="wizard-threshold" className="wz-card">
        <div className="wz-container sx-form">
          {/* HEADER */}
          <div className="wz-card-header">
            <Tag>XDRs Only</Tag>
            <h3>Duration and Threshold</h3>
            <h5>
              How many leads does {this.props.user.company_name} need{moreLanguage}?
            </h5>
          </div>

          {/* CONTENT */}
          {this.state.loadingImports ? (
            <Loading />
          ) : (
            <React.Fragment>
              <div className="input-box">
                <div className="field">
                  <label>
                    Number of leads to {isGen ? 'generate' : 'qualify'}
                    {isLocked ? <Locked isLocked={isLocked} /> : null}
                    {isGen && !isLocked ? (
                      <Infotip title="This the the amount you leads you are looking for." />
                    ) : null}
                    {!isGen && !isLocked ? (
                      <Infotip title="Would you like to qualify the current amount, or be allowed to add more at any time?" />
                    ) : null}
                  </label>
                  {isGen ? (
                    <InputNumber
                      disabled={isLocked}
                      onChange={val => updateObject('threshold_settings.lead_count', val)}
                      value={campaign.threshold_settings.lead_count || undefined}
                      placeholder="0"
                    />
                  ) : importAmount ? (
                    <Input
                      disabled={true}
                      value={
                        importAmount && campaign.threshold_settings.duration === 'continuous'
                          ? importAmount + ' leads to start with'
                          : importAmount
                      }
                      placeholder="Import leads from the Import tab above"
                    />
                  ) : (
                    <Button
                      type="primary"
                      onClick={() => this.props.saveAndContinue(`/wizard/${campaign.id}/import`)}
                    >
                      Import leads to see estimates
                    </Button>
                  )}
                </div>
                <div className="field">
                  <label>
                    Pay per {campaign.objective === 'qualify' ? 'qualified' : 'generated'} lead
                    {isLocked ? <Locked isLocked={isLocked} /> : null}
                    {isGen && !isLocked ? (
                      <Infotip title="This the the amount you are willing to pay per generated lead." />
                    ) : null}
                    {!isGen && !isLocked ? (
                      <Infotip title="The amount you pay for a qualified lead. If a lead is not-interested, invalid, etc. we charge a standard fee of $2 for the work." />
                    ) : null}
                  </label>
                  <Input
                    disabled={isLocked}
                    error={!isLocked && calc.cause.includes('payPerLead')}
                    hint={
                      !isLocked && calc.cause.includes('payPerLead')
                        ? 'Pay per lead is a little low'
                        : ''
                    }
                    onChange={e => this.onChangePayPerLead(parseFloat(e.target.value))}
                    value={payPerLead || ''}
                  />
                </div>
              </div>
              <div className="input-box">
                <div className="field">
                  <label>
                    Total sales reps (max 6)
                    {isLocked ? (
                      <Locked isLocked={isLocked} />
                    ) : (
                      <Infotip title="The amount of sales reps you want to work on the campaign." />
                    )}
                  </label>
                  <InputNumber
                    disabled={isLocked}
                    className={
                      calc.cause.includes('target_agent_count') && !isLocked ? 'invalid' : ''
                    }
                    max={6}
                    min={1}
                    type="number"
                    onChange={val => update('target_agent_count', val)}
                    value={campaign.target_agent_count}
                  />
                </div>
                <div className="field">
                  <label>
                    Duration
                    {isLocked ? (
                      <Locked isLocked={isLocked} />
                    ) : (
                      <Infotip title="How long would you like this campaign to run for?" />
                    )}
                  </label>
                  <Select
                    disabled={isLocked}
                    value={campaign.threshold_settings.duration || undefined}
                    onChange={val => updateObject('threshold_settings.duration', val)}
                    placeholder="Select a campaign duration..."
                  >
                    {Object.keys(duration).map(d => (
                      <Select.Option key={d} value={d}>
                        {duration[d]}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="hint">
                <div>
                  <IconBolt size={18} stroke={1.75} />
                </div>
                {durationText(campaign, importAmount)}
              </div>
              <div className="thresh-info">
                <div className="icon-holder">
                  <span>
                    <IconBolt size={18} stroke={1.75} />
                  </span>
                </div>
                <h4>Your account threshold is $1,000</h4>
                <p>
                  Your account threshold is a fixed amount. Once your balance reaches or exceeds
                  your threshold ($1,000), your account will be charged.
                </p>
                <div>
                  <Button type="default" onClick={this.showThresholdModal}>
                    Learn More
                  </Button>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>

        {this.state.thresholdModal ? (
          <ThresholdModal removeModal={this.removeThresholdModal} />
        ) : null}

        {/* ACTIONS */}
        {actions}
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(Threshold));
