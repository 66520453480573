import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getErrors } from '_assets/js/helpers';
import { Modal, Loading, message } from '_seriph';
import { getRoundRobin } from '_graphql/queries/roundrobin';
import NewRoundRobin from './_components/NewRoundRobin/NewRoundRobin';
import EditRoundRobin from './_components/EditRoundRobin/EditRoundRobin';
import './RoundRobinModal.scss';

class RoundRobinModal extends Component {
  state = {
    visible: true,
    loading: true,
    roundRobinId: this.props.roundRobinId,
    roundRobin: null,
    saving: false,
    removing: false,
    page: 'new'
  };
  componentDidMount = () => {
    if (this.state.roundRobinId === 'new' || this.state.roundRobinId === 'create') {
      const roundRobin = {
        name: undefined,
        phone_number: undefined,
        forward_to: undefined
      };
      this.setState({
        page: this.state.roundRobinId === 'new' ? 'edit' : 'create',
        roundRobin,
        savedRoundRobin: JSON.stringify(roundRobin),
        loading: false
      });
    } else {
      this.loadRoundRobin();
    }
  };
  loadRoundRobin = () => {
    this.props.client
      .query({ variables: { id: this.state.roundRobinId }, query: getRoundRobin })
      .then(result => {
        if (result && result.data && result.data.getRoundRobin) {
          const roundRobin = result.data.getRoundRobin || {};
          this.setState({
            roundRobin,
            savedTouchpoint: JSON.stringify(roundRobin),
            loading: false
          });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        message.error(getErrors(err) || 'Could not load call list, try again');
      });
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { loading, roundRobin, page } =
      this.state;

    return (
      <Modal
        wrapClassName="manage-round-robin-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={false}
        width={520}
      >
        {loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            {page === 'create' && (
              <NewRoundRobin
                hideModal={this.hideModal}
                reload={this.props.reload}
                client={this.props.client}
              />
            )}
            {(page !== 'create' && roundRobin)  && (
              <EditRoundRobin
                hideModal={this.hideModal}
                data={roundRobin}
                reload={this.props.reload}
                client={this.props.client}
              />
            )}
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

export default withRouter(RoundRobinModal);
