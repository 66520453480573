import React from 'react';
import { Link } from 'react-router-dom';
import { formatMoney, numberWithCommas, plural, formatPercent } from '_assets/js/helpers';
import { statusMap } from '_constants/campaigns';
import CampaignActions from '_shared/CampaignActions/CampaignActions';
import moment from 'moment';
import { Avatar, Tooltip, Progress, Button } from '_seriph';
import { durationShort } from '_constants/campaigns';
import { isInternal } from '_helpers/permissions';

export const getColumns = (reload, user, showInstructionsModal, showCreateModal) => {
  const internal = isInternal();
  const resultTable = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 280,
      fixed: 'left',
      render: (c, r) => (
        <div className="ctbl-box">
          <Link to={`/company/${r.company.id}`}>
            <Avatar picture={r.company.logo} size="small" />
          </Link>
          <div className="ctbl-name">
            <h4 font="sans" size="4">
              {c}
            </h4>
            <h5>
              <Link to={`/company/${r.company.id}`}>{r.company.name}</Link>
              <span>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>
              <span className={'left-type ' + r.objective}>
                {r.objective === 'qualify' && 'Lead Qualification'}
                {r.objective === 'leadgen' && 'Lead Generation'}
                {r.objective === 'draft' && 'Draft'}
              </span>
              <span>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>
              {r.status === 'active' && r.sub_status !== 'review' && r.status_updated && (
                <span>
                  {statusMap[r.status]} {moment(r.status_updated * 1).format('M/D')}
                </span>
              )}
              {r.status === 'active' && r.sub_status === 'review' && r.status_updated && (
                <span>Pending Review {moment(r.status_updated * 1).format('M/D')}</span>
              )}
              {(r.status === 'inactive' || r.status === 'completed') && r.status_updated && (
                <span>
                  {statusMap[r.status]} {moment(r.status_updated * 1).format('M/D')}
                </span>
              )}
              {r.status === 'draft' && <span>Saved {moment(r.updatedAt * 1).format('M/D')}</span>}
            </h5>
          </div>
        </div>
      )
    },
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
      width: 100,
      render: (c, r) => {
        const actualAgents = r.agents.filter(a => a.active === true);
        actualAgents.sort((a, b) => b.paid - a.paid);
        const stat = actualAgents.findIndex(e => e.agent_id === user.id);
        return stat >= 0 ? '#' + (stat + 1) + ' of ' + r.agent_count : 'No Reps yet';
      }
    },
    {
      title: internal ? 'Remaining' : 'Budget/Threshold',
      key: 'threshold',
      width: 140,
      render: (c, r) => {
        const typeText = r.objective === 'qualify' ? 'qualified' : 'generated';
        if (r.threshold) {
          const duration = c.threshold_settings.duration;

          const ttInfo = internal ? 'Remaining leads' : (`${r.company.name} is paying ${formatMoney(
            r.price_per_lead
          )} per ${typeText} lead.`);

          return (
            <div className="thresh-box">
              <Tooltip
                centered
                title={ttInfo}
              >
                <div className="sxd-title">
                  {numberWithCommas(r.stats.estimated_total)}{' '}
                  {plural('lead', r.stats.estimated_total)}
                </div>
              </Tooltip>
              <div className="thresh-dur">{durationShort[duration]}</div>
            </div>
          );
        } else {
          return (
            <div className="thresh-box">
              <Tooltip
                title={`${r.company.name} is paying ${formatMoney(
                  r.price_per_lead
                )} per ${typeText} lead, and there are ${numberWithCommas(
                  r.stats.remaining
                )} remaining`}
              >
                <div className="sxd-title">{formatMoney(r.actual_budget)}</div>
                <div className="thresh-dur">Budget</div>
              </Tooltip>
            </div>
          );
        }
      }
    },
    {
      title: 'Generated / Qualified',
      key: 'lead_stats',
      width: 180,
      render: (c, r) => {
        const isQualify = r.objective === 'qualify' ? true : false;

        const tipData = (
          <div>
            {isQualify && (
              <div>
                {numberWithCommas(r.agent_stats.qualified)} Qualified{' '}
                {plural('lead', r.agent_stats.qualified)}
              </div>
            )}
            {!isQualify && (
              <div>
                {numberWithCommas(r.agent_stats.generated)} Generated{' '}
                {plural('lead', r.agent_stats.qualified)}
              </div>
            )}
            {isQualify && (
              <div>{formatPercent(r.agent_stats.conversion_rate, 1)} Conversion rate</div>
            )}
            {isQualify && <div>{numberWithCommas(r.stats.total_leads)} Imported leads</div>}
            {!isQualify && (
              <div>
                {numberWithCommas(r.stats.remaining)} Remaining {plural('lead', r.stats.remaining)}
              </div>
            )}
          </div>
        );

        return (
          <div className="thresh-box">
            <Tooltip title={tipData}>
              <div className="sxd-title">
                {isQualify
                  ? numberWithCommas(r.agent_stats.qualified) +
                    ` qualified (${formatPercent(r.agent_stats.conversion_rate, 1)})`
                  : numberWithCommas(r.agent_stats.generated) + ' generated'}
              </div>
            </Tooltip>
            <div className="thresh-dur">
              {!isQualify && numberWithCommas(r.stats.remaining) + ' remaining'}
              {isQualify &&
                numberWithCommas(r.stats.remaining) +
                  ' ' +
                  plural('lead', r.stats.remaining) +
                  ' remaining'}
            </div>
          </div>
        );
      }
    },
    {
      title: 'Paid',
      key: 'pending_stats',
      width: 140,
      hidden: internal ? true : false,
      render: (c, r) => {
        const isQualify = r.objective === 'qualify' ? true : false;
        const tool = !isQualify ? (
          <div className="progress-tt">
            <div>
              &nbsp;{r.agent_stats.completed} Generated ({formatMoney(r.agent_stats.paid)})
            </div>
            <div className="pt-title top">Pending</div>
            <div>
              &nbsp;{r.agent_stats.review} Pending ({formatMoney(r.agent_stats.owed)})
            </div>
          </div>
        ) : (
          <div className="progress-tt">
            <div className="pt-title">Paid ({formatMoney(r.agent_stats.paid)})</div>
            <div>
              &nbsp;{r.agent_stats.qualified} Qualified (
              {formatMoney(r.agent_stats.paid_interested)})
            </div>
            <div>
              &nbsp;{r.agent_stats.not_interested} Not Interested (
              {formatMoney(r.agent_stats.paid_not_interested)})
            </div>
            <div className="pt-title top">Pending ({formatMoney(r.agent_stats.owed)})</div>
            <div>
              &nbsp;{r.agent_stats.review_interested} Interested (
              {formatMoney(r.agent_stats.owed_interested)})
            </div>
            <div>
              &nbsp;{r.agent_stats.review_not_interested} Not Interested (
              {formatMoney(r.agent_stats.owed_not_interested)})
            </div>
          </div>
        );

        return (
          <div className="thresh-box">
            <Tooltip title={tool}>
              <div className="sxd-title">{formatMoney(r.agent_stats.paid)}</div>
            </Tooltip>
            <div className="thresh-dur">{formatMoney(r.agent_stats.owed)} pending</div>
          </div>
        );
      }
    },
    {
      title: 'Progress',
      key: 'progress',
      width: 170,
      render: (c, r) => {
        const completedPercent = Math.floor((r.stats.completed / r.stats.estimated_total) * 100);
        const inProgressPercent = Math.floor((r.stats.in_progress / r.stats.estimated_total) * 100);
        const reviewPercent = Math.floor((r.stats.review / r.stats.estimated_total) * 100);

        const progressMessage = (
          <div className="progress-tt">
            <div>
              <span className="complete" /> {r.stats.completed} Completed
            </div>
            <div>
              <span className="review" /> {r.stats.review} Pending Review
            </div>
            <div>
              <span className="progress" /> {r.stats.in_progress} In-Progress
            </div>
            <div>
              <span className="unassigned" /> {r.stats.remaining} Available
            </div>
          </div>
        );

        let progressData = [];
        progressData = [
          { color: '#87B0ED', percent: completedPercent },
          { color: '#B5B5FD', percent: reviewPercent },
          { color: '#ABD4A2', ignore: true, percent: inProgressPercent }
        ];

        return (
          <Tooltip title={progressMessage}>
            <div className="progress-table">
              <Progress progressData={progressData} rounded />
            </div>
          </Tooltip>
        );
      }
    },
    {
      title: '',
      dataIndex: 'instructions',
      key: 'instructions',
      width: 50,
      align: 'right',
      render: (c, r) => (
        <Button onClick={() => showInstructionsModal(r)} type="default">
          Instructions
        </Button>
      )
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      align: 'right',
      fixed: 'right',
      render: (c, r) => (
        <CampaignActions
          campaign={r}
          stats={true}
          reload={reload}
          showCreateModal={showCreateModal}
        />
      )
    }
  ];
  return resultTable.filter(item => !item.hidden);
};
