export const LANGUAGES = [
  'All Languages',
  'English',
  'Arabic',
  'Chinese',
  'Czech',
  'Danish',
  'Dutch',
  'French',
  'Finnish',
  'German',
  'Hebrew',
  'Italian',
  'Japanese',
  'Korean',
  'Norwegian',
  'Polish',
  'Portuguese',
  'Russian',
  'Spanish',
  'Swedish',
  'Turkish'
];
