import React, { Component } from 'react';
import { Modal, Button } from '_seriph';
import './CustomDomainListModal.scss';
import CustomDomainModal from '../CustomDomainModal/CustomDomainModal.js';

export default class CustomDomainListModal extends Component {
  state = {
    editDomain: null,
    addDomain: null,
    visible: true,
    saving: false,
    maskClosable: true
  };
  hideModal = () => {
    this.setState({ visible: false, editDomain: null, addDomain: null });
  };
  showModal = () => {
    this.setState({ visible: true, editDomain: null, addDomain: null });
  };
  render() {
    if (this.state.editDomain) {
      return (
        <CustomDomainModal
          client={this.props.client}
          company={this.props.company}
          loadIntegrations={this.props.loadIntegrations}
          removeCustomDomain={this.props.removeCustomDomain}
          integration={this.state.editDomain}
          removeModal={this.showModal}
        />
      );
    }
    if (this.state.addDomain) {
      return (
        <CustomDomainModal
          client={this.props.client}
          company={this.props.company}
          loadIntegrations={this.props.loadIntegrations}
          removeCustomDomain={this.props.removeCustomDomain}
          integration={null}
          removeModal={this.showModal}
        />
      );
    }
    return (
      <Modal
        wrapClassName="custom-domain-modal sx-theme"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={this.state.maskClosable}
        width={900}
      >
        <Modal.Close onClick={this.hideModal} />
        <table className="domain-table">
          <thead>
            <tr>
              <th>Domain</th>
              <th>Verified</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.props.customDomains?.map((customDomain, i) => (
              <tr key={i}>
                <td>{customDomain.domain}</td>
                <td>{customDomain.active ? 'Yes' : 'No'}</td>
                <td>
                  <Button
                    type="default"
                    onClick={() => {
                      this.setState({ editDomain: customDomain });
                    }}
                  >
                    Edit
                  </Button>
                </td>
              </tr>
            ))}
            <tr key="last">
              <td></td>
              <td></td>
              <td>
                <Button
                  type="default"
                  onClick={() => {
                    this.setState({ addDomain: true });
                  }}
                >
                  Create New
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </Modal>
    );
  }
}
