import React, { Component } from 'react';
import { message } from 'antd';
import { Button, Modal } from '_seriph';
import { getErrors } from '_assets/js/helpers';
import Commitment from './_components/Commitment/Commitment';
import Audio from './_components/Audio/Audio';
import Note from './_components/Note/Note';
import Submitted from './_components/Submitted/Submitted';
import { createApplication } from '_graphql/mutations/campaign';
import axios from 'axios';
import './Apply.scss';

export default class Apply extends Component {
  state = {
    visible: true,
    uploading: false,
    step: 'commitment', // commitment, record, note, submitted
    note: undefined,
    audioFile: null,
    audioUrl: 'hya'
  };
  applyToCampaign = () => {
    this.setState({ saving: true });
    this.props.client
      .mutate({
        variables: {
          note: this.state.note,
          commitment: parseInt(this.props.commitment),
          audio_url: this.state.audioUrl,
          campaign_id: this.props.campaign.id
        },
        mutation: createApplication
      })
      .then(result => {
        if (result?.data?.createApplication) {
          this.setState({ step: 'submitted' }, () => {
            if (this.props.reload) this.props.reload();
            if (this.props.loadCampaigns) this.props.loadCampaigns();
          });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || "Couldn't apply to campaign, please try again");
      });
  };
  setAudioFile = file => {
    if (file) {
      this.setState({ audioFile: file });
    } else {
      this.setState({ audioFile: null, audioUrl: null });
    }
  };
  setNote = note => this.setState({ note });
  next = () => this.setState({ step: 'record' });
  uploadAudio = () => {
    this.setState({ uploading: true });
    const data = new FormData();
    data.append('audio', this.state.audioFile, this.state.audioFile.name);
    data.set('campaign_id', this.props.campaign.id);
    axios
      .post(`${process.env.REACT_APP_API_PATH}/v1/aws/audio`, data, {
        headers: {
          Authorization: 'JWT ' + localStorage.getItem('JWT'),
          'content-type': 'multipart/form-data'
        }
      })
      .then(response => {
        if (response?.data?.audioUrl) {
          this.setState({ audioUrl: response.data.audioUrl, uploading: false, step: 'note' });
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        message.error('Could not save audio, try again');
      })
      .finally(() => this.setState({ uploading: false }));
  };
  confirmCommitment = () => {
    if (this.props.campaign.objective === 'leadgen') {
      this.setState({ step: 'note' });
    } else {
      this.setState({ step: 'record' });
    }
  };
  goBack = () => {
    if (this.state.step === 'record' || this.props.campaign.objective === 'leadgen') {
      this.setState({ step: 'commitment' });
    } else {
      this.setState({ step: 'record' });
    }
  };
  isApplicationValid = () => {
    if (this.props.campaign.objective === 'leadgen') {
      return this.state.note && this.props.commitment ? true : false;
    } else {
      return this.state.note && this.props.commitment && this.state.audioUrl ? true : false;
    }
  };
  audioNext = () => {
    if (this.state.audioUrl) {
      this.setState({ step: 'note' });
    } else {
      this.uploadAudio();
    }
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { campaign, company } = this.props;
    const { uploading, step, saving, audioFile } = this.state;

    const isValid = this.isApplicationValid();

    return (
      <Modal
        wrapClassName="campaign-apply-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={true}
        width={580}
      >
        <Modal.Close onClick={this.hideModal} />
        <div className="apply-header">
          <h4>
            {step === 'commitment' ? 'Availability' : null}
            {step === 'record' ? 'Record' : null}
            {step === 'note' ? 'Introduction' : null}
          </h4>
        </div>
        <div>
          {step === 'commitment' && (
            <Commitment
              commitment={this.props.commitment}
              setCommitment={this.props.setCommitment}
              campaign={campaign}
            />
          )}
          {step === 'record' && (
            <Audio
              campaign={campaign}
              company={company}
              setAudioFile={this.setAudioFile}
              hasAudioFile={this.state.audioFile}
            />
          )}
          {step === 'note' && (
            <Note
              campaign={campaign}
              company={company}
              note={this.state.note}
              setNote={this.setNote}
            />
          )}
          {step === 'submitted' && (
            <Submitted company={company} user={this.props.user} hideModal={this.hideModal} />
          )}
        </div>
        {step !== 'submitted' ? (
          <div className="apply-footer">
            <div className="left">
              {step !== 'commitment' && step !== 'submitted' && (
                <Button type="secondary" onClick={this.goBack}>
                  Back
                </Button>
              )}
            </div>
            <div className="right">
              <Button type="default" onClick={this.hideModal}>
                Cancel
              </Button>
              {step === 'commitment' && (
                <Button
                  type="primary"
                  disabled={!this.props.commitment}
                  onClick={this.confirmCommitment}
                >
                  Next
                </Button>
              )}
              {step === 'record' && (
                <Button
                  type="primary"
                  disabled={!audioFile}
                  loading={uploading}
                  onClick={this.audioNext}
                >
                  Next
                </Button>
              )}
              {step === 'note' && (
                <Button
                  disabled={!isValid}
                  loading={saving}
                  type="primary"
                  onClick={this.applyToCampaign}
                >
                  Submit Application
                </Button>
              )}
            </div>
          </div>
        ) : null}
      </Modal>
    );
  }
}
