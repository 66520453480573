import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { addSalesforce, removeSalesforce } from '_graphql/mutations/integrations';
import queryString from 'query-string';
import { getErrors } from '_assets/js/helpers';
import { Card, Button, message } from '_seriph';
import { IconCheck, IconX, IconPlus, IconSettings, IconClock } from '@tabler/icons-react';
import SalesforceLogo from '_assets/brands/salesforce.svg';
import SalesforceSettingsModal from './_modals/SalesforceSettingsModal/SalesforceSettingsModal';
import './Salesforce.scss';

class Salesforce extends Component {
  state = {
    setting: null,
    modal: false
  };
  componentDidMount = () => {
    const params = queryString.parse(this.props.location.search);
    if (
      params.type === 'salesforce' &&
      params.instance_url &&
      params.access_token &&
      params.refresh_token
    )
      this.addSalesforce(params);
  };
  addSalesforce = params => {
    this.setState({ setting: 'salesforce' });
    this.props.client
      .mutate({
        variables: {
          access_token: params.access_token,
          instance_url: params.instance_url,
          refresh_token: params.refresh_token
        },
        mutation: addSalesforce
      })
      .then(result => {
        if (result && result.data && result.data.addSalesforce) {
          window.history.replaceState(null, null, window.location.pathname);
          setTimeout(() => this.props.loadIntegrations(this.resetSetting, this.showModal), 100);
          message.success('Salesforce successfully integrated');

        }
      })
      .catch(err => {
        this.setState({ setting: null });
        message.error(getErrors(err) || 'Error setting up salesforce, try again');
      });
  };
  removeSalesforce = () => {
    this.setState({ setting: 'salesforce' });
    this.props.client
      .mutate({ variables: { refresh: 'false' }, mutation: removeSalesforce })
      .then(result => {
        if (result && result.data && result.data.removeSalesforce) {
          setTimeout(() => this.props.loadIntegrations(this.resetSetting), 100);
          message.success('Salesforce successfully removed');
        }
      })
      .catch(err => {
        this.setState({ setting: null });
        message.error(getErrors(err) || 'Error removing salesforce, try again');
      });
  };
  salesforce = () => {
    window.location.href = process.env.REACT_APP_API_PATH + '/salesforce/oath';
  };
  showModal = () => this.setState({ modal: true })
  removeModal = () => this.setState({ modal: false })
  resetSetting = () => this.setState({ setting: null });
  render() {
    const { setting } = this.state;
    const { integrations, loading } = this.props;

    const integration = integrations.salesforce ? integrations.salesforce : {};

    let instanceName = integration?.instance_url || '';
    instanceName = instanceName?.replace('https://', '');
    instanceName = instanceName?.length > 0 ? instanceName.split('.')[0] : null;
    return (
      <Card id="salesforce">
        <div className="int">
          <div className="int-header">
            <div className="int-logo">
              <img
                className="salesforce"
                src={SalesforceLogo}
                title="Salesforce"
                alt="Salesforce"
              />
            </div>
            <h3 className="int-name">
              Salesforce <span>BETA</span>
              { instanceName && <p>Account: {instanceName}</p> }
            </h3>
            {integration.access_token && !loading && (
              <div className="int-check"><IconCheck /></div>
            )}
          </div>
          <p className="int-desc">
            Salesforce lets you sync your leads, accounts, contacts and create opportunities!
          </p>


          {this.props.comingSoon === true ? (
            <div className="int-actions">
              <Button disabled={true} icon="left" type="secondary">
                <IconClock />
                Coming Soon
              </Button>
            </div>
          ) : (
            <div className="int-actions">
              {(!integration.access_token || loading) && (
                <Button
                  disabled={loading}
                  type="secondary"
                  icon="left"
                  loading={setting === 'salesforce'}
                  onClick={this.salesforce}
                >
                  <IconPlus />
                  Connect to Salesforce
                </Button>
              )}
              {integration.access_token && !loading && (
                <React.Fragment>
                  <Button
                    type="secondary"
                    icon="left"
                    onClick={this.showModal}
                  >
                    <IconSettings />Settings
                  </Button>
                  <Button
                    type="danger"
                    icon="left"
                    loading={setting === 'salesforce'}
                    onClick={this.removeSalesforce}
                  >
                    <IconX />Disconnect
                  </Button>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
        {this.state.modal ? (
          <SalesforceSettingsModal
            integrations={integrations}
            client={this.props.client}
            reload={() => this.props.loadIntegrations(this.resetSetting)}
            removeModal={this.removeModal}
          />
        ) : null}
      </Card>
    );
  }
}

export default withRouter(Salesforce);