import React, { Component } from 'react';
import axios from 'axios';
import { Header, Alert, Button, Card, Loading, message } from '_seriph';
import { IconCreditCard, IconBuildingBank } from '@tabler/icons-react';
import './Banking.scss';

class Banking extends Component {
  state = {
    loading: true,
    account: null,
    sources: [],
    manageLoading: false
  };
  componentDidMount = () => {
    this.loadBanking();
  };
  loadBanking = () => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/v1/accounts`, {
        headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') }
      })
      .then(response => {
        if (response && response.data) {
          const a = response.data.account;
          this.setState({
            account: a || null,
            sources:
              a && a.external_accounts && a.external_accounts.data ? a.external_accounts.data : []
          });
        }
      })
      .catch(() => {
        message.error('Could not load accounts');
      })
      .finally(() => this.setState({ loading: false }));
  };
  manageAccount = () => {
    this.setState({ manageLoading: true });
    axios
      .get(`${process.env.REACT_APP_API_PATH}/v1/stripe`, {
        headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') }
      })
      .then(response => {
        if (response && response.data && response.data.link && response.data.link.url) {
          window.location.href = response.data.link.url;
        }
      })
      .catch(() => {
        message.error('Loading Stripe dashboard failed, try again');
        this.setState({ manageLoading: false });
      });
  };
  loadStripe = () => {
    const clientID = process.env.REACT_APP_STRIPE_CLIENT_ID;
    window.location.href =
      `https://connect.stripe.com/express/oauth/authorize?client_id=${clientID}` +
      this.generateParams();
  };
  generateParams = () => {
    let params = `&state=${this.props.myself.id}&stripe_user[business_type]=individual`;
    params += `&stripe_user[url]=https://www.sellx.com`;
    params += `&stripe_user[country]=US`;
    params += `&stripe_user[first_name]=${this.props.myself.first_name}`;
    params += `&stripe_user[last_name]=${this.props.myself.last_name}`;
    params += `&stripe_user[email]=${this.props.myself.email}`;
    if (this.props.info && this.props.info.phone_number)
      params += `&stripe_user[phone_number]=${this.props.info.phone_number}`;

    return params;
  };
  render() {
    const { loading, account, manageLoading, sources } = this.state;
    return loading ? (
      <Loading />
    ) : (
      <Card id="agent-settings-banking">
        <div className="settings-card">
          <Header size="2" type="sans" className="pm-header">
            Banking
          </Header>

          {account ? (
            <div className="sources">
              {sources.length <= 0 ? (
                <Alert type="info">There is currently no bank account setup.</Alert>
              ) : (
                sources.map(s => (
                  <div className="source" key={s.id}>
                    {s.object === 'bank_account' ? <IconBuildingBank /> : <IconCreditCard />}
                    <div className="source-name">
                      <h3>
                        {s.bank_name || s.brand}
                        &nbsp;{s.object === 'card' ? `ending in ${s.last4}` : null}
                      </h3>
                      <div>
                        {s.object === 'card'
                          ? `Expires ${s.exp_month + '/' + s.exp_year}`
                          : `Account ending in #${s.last4}`}
                      </div>
                    </div>
                  </div>
                ))
              )}

              <div className="manage-box">
                <Button
                  onClick={this.manageAccount}
                  loading={manageLoading}
                  type="primary"
                  className="manage-account"
                >
                  Manage Account
                </Button>
              </div>
            </div>
          ) : null}

          {!account ? (
            <div className="setup-button">
              <Button onClick={this.loadStripe} type="primary" className="add-account">
                Setup Account
              </Button>
            </div>
          ) : null}
        </div>
      </Card>
    );
  }
}

export default Banking;
