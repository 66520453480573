import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Button, Tooltip } from '_seriph';
import './ScheduleMeeting.scss';

export default class ScheduleMeeting extends Component {
  render() {
    const { lead, curr } = this.props;

    const scheduled = lead.meetingScheduled ? true : false;
    const startTime =
      lead.meetingScheduled && lead.calendly && lead.calendly.start_time
        ? lead.calendly.start_time
        : null;
    const underReview = lead && lead.stage === 'review';

    const scheduleButton = (
      <Button
        className="schedule-calendar"
        disabled={underReview}
        type="primary"
        onClick={this.props.showCalendarModal}
      >
        Schedule Meeting
      </Button>
    );

    const doneButton = (
      <Tooltip
        placement="bottom"
        title={
          <span>
            Meeting{' '}
            {startTime ? 'on ' + moment(Date.parse(startTime)).format('MM/D h:mma') : 'Scheduled'}
          </span>
        }
      >
        <Button className="schedule-calendar" type="secondary" icon="left">
          <FontAwesomeIcon icon={faCheck} className="btnx-left" />
          Meeting Scheduled
        </Button>
      </Tooltip>
    );

    if ((curr.useCalendly || curr.useChilipiper) && !scheduled) {
      return scheduleButton;
    } else if (startTime && scheduled) {
      return doneButton;
    } else {
      return null;
    }
  }
}
