import React from 'react';
import moment from 'moment';

import { Alert, InputDate, Select, TextArea } from '_seriph';
import { typeMap } from './_variables';

export function NurtureTag({ formState: { note, date, opType }, onChange }) {
  return (
    <>
      <InputDate
        label="Revisit Date?"
        onChange={val => onChange('date', val)}
        size="large"
        disabledDate={current => {
          current && current < moment().endOf('day');
        }}
        value={date}
      />
      <Select
        value={opType}
        dropdownClassName="lead-tags"
        onChange={val => onChange('opType', val)}
        label="Opportunity Type"
        style={{ width: '100%' }}
      >
        {Object.keys(typeMap).map(r => (
          <Select.Option value={r} label="test" key={r}>
            <div className={typeMap[r].className}>{typeMap[r].display}</div>
          </Select.Option>
        ))}
      </Select>

      <TextArea
        value={note}
        onChange={v => onChange('note', v.target.value)}
        size="large"
        label={
          <>
            Add a note <span>(optional)</span>
          </>
        }
        rows={6}
        placeholder="Write a message..."
      />
      { false && (
        <Alert type="success" icon={<></>}>
          <b>Nurture - </b>
          Adds lead to a specific nurture sequence, based on the selected opportunity type.
        </Alert>
      )}
    </>
  );
}
