import React, { Component } from 'react';
import { Button, Modal } from '_seriph';
import './JustTouchpoints.scss';
import Commitment from '../Apply/_components/Commitment/Commitment.js';

export default class Apply extends Component {
  state = {
    visible: true,
    saving: false
  };
  hideModal = () => this.setState({ visible: false });
  saveToServer = async () => {
    this.props.completeModal(this.hideModal);
  };
  render() {
    return (
      <Modal
        wrapClassName="campaign-just-touchpoints-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={true}
        width={580}
      >
        <Modal.Close onClick={this.hideModal} />
        <div className="just-touchpoints-header">
          <h4> Availability </h4>
        </div>
        <div>
          <Commitment
            commitment={this.props.commitment}
            setCommitment={this.props.setCommitment}
            campaign={this.props.campaign}
          />
        </div>
        <div className="just-touchpoints-footer">
          <div className="right">
            <Button type="default" onClick={this.hideModal}>
              Cancel
            </Button>
            <Button type="primary" disabled={!this.props.commitment} onClick={this.saveToServer}>
              Accept
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}
