import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getErrors } from '_assets/js/helpers';
import { Modal, Button, Header, TextArea, Select, InputDate, message } from '_seriph';
import { updateCompanyReview } from '../../../_graphql/mutations';
import { IconMoodHappy, IconMoodNeutral, IconMoodSad } from '@tabler/icons-react';
import moment from 'moment';
import './ReviewApplicationModal.scss';

class ReviewApplicationModal extends Component {
  state = {
    visible: true,
    type: this.props.app?.review?.type || undefined,
    revisit: this.props.app?.review?.revisit
      ? moment(this.props.app.review?.revisit * 1)
      : undefined,
    note: this.props.app?.review?.note || undefined,
    saving: false
  };
  updateCompanyReview = () => {
    this.setState({ saving: true });
    const { note, type } = this.state;
    const revisit = this.state.revisit ? this.state.revisit.toDate() : '';
    this.props.client
      .mutate({
        variables: {
          application_id: this.props.app?.id,
          note,
          revisit,
          type
        },
        mutation: updateCompanyReview
      })
      .then(result => {
        if (result && result.data && result.data.updateCompanyReview) {
          message.success('Application review updated');
          if (this.props.reload) this.props.reload();
          this.hideModal();
        }
        this.setState({ saving: false });
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Error saving review, try again.');
      });
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { note, revisit, type, saving } = this.state;
    const { app } = this.props;

    return (
      <Modal
        wrapClassName="review-application-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={true}
        width={500}
      >
        <Modal.Close onClick={this.hideModal} />

        <Header size="4" font="sans" className="new-header">
          Review {app.company_name}&apos;s Application
          <div className="subheader">Please keep up-to-date information</div>
        </Header>

        <div className="sequence-form">
          <Select
            label="Application Status"
            placeholder="Select a status..."
            value={type}
            onChange={type => this.setState({ type })}
            getPopupContainer={trigger => trigger.parentNode}
          >
            <Select.Option value="good" className="go">
              <IconMoodHappy />
              Good Fit
            </Select.Option>
            <Select.Option value="timing" className="wait">
              <IconMoodNeutral />
              Bad Timing
            </Select.Option>
            <Select.Option value="unready" className="wait">
              <IconMoodNeutral />
              Not Ready
            </Select.Option>
            <Select.Option value="bad" className="stop">
              <IconMoodSad />
              Bad fit
            </Select.Option>
          </Select>
          <InputDate
            label="Revisit Date?"
            onChange={revisit => this.setState({ revisit })}
            value={revisit}
          />
          <TextArea
            label="Notes"
            value={note}
            onChange={e => this.setState({ note: e.target.value })}
            placeholder="Write review notes..."
            autoFocus
            rows={6}
            autoSize={false}
          />
        </div>

        <Modal.Actions align="right">
          <Button type="default" onClick={() => this.hideModal()}>
            Cancel
          </Button>
          <Button type="primary" loading={saving} onClick={this.updateCompanyReview}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withRouter(ReviewApplicationModal);
