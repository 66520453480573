import React, { Component } from 'react';
import { Button, Upload, message } from 'antd';
import ReactQuill from 'react-quill';
import Card from '_shared/Card/Card';
import { deleteFile } from 'Mutations/Campaign/files';
import { File } from '../Components/File/File';

export default class Training extends Component {
  state = {
    files: this.props.form ? this.props.form.files || [] : [],
    instruction: ''
  };
  addInstruction = e => {
    e.preventDefault();
    const { instructions } = this.props.form;
    instructions.push(this.state.instruction);
    this.props.updateForm('instructions', instructions);
    this.setState({ instruction: '' });
  };
  removeInstruction = ndx => {
    const { instructions } = this.props.form;
    instructions.splice(ndx, 1);
    this.props.updateForm('instructions', instructions);
  };
  changeFile = info => {
    if (info.file.status === 'uploading') {
      const files = JSON.parse(JSON.stringify(this.state.files));
      if (!files.find(f => f.name === info.file.name)) {
        files.push({ name: info.file.name, size: info.file.size, downloading: true });
        this.setState({ files });
      }
    } else if (info.file.status === 'done') {
      const files = JSON.parse(JSON.stringify(this.state.files));
      const data = info.file.response || null;
      const fileIndex = files.findIndex(f => f.name === info.file.name);
      files[fileIndex] = data.file;
      this.setState({ files });
      this.props.updateForm('files', files);
    } else if (info.file.status === 'error') {
      const errors = info.file.response.errors || [];
      errors.forEach(e => message.error(`${info.file.name} - ${e.detail}`));
    }
  };
  deleteFile = (fileId, campaignId) => {
    this.props.client
      .mutate({
        variables: { file_id: fileId, campaign_id: campaignId },
        mutation: deleteFile
      })
      .then(result => {
        if (result && result.data && result.data.deleteFile) {
          this.setState({ files: result.data.deleteFile.files || [] });
          this.props.updateForm('files', result.data.deleteFile.files || []);
        }
      })
      .catch(() => message.error('Error removing file, try again'));
  };
  render() {
    const form = this.props.form;

    const props = {
      name: 'file',
      action: `${process.env.REACT_APP_API_PATH}/v1/campaign-files`,
      multiple: false,
      showUploadList: false
    };

    return (
      <div className="step-container" id="train-container">
        <Card id={this.props.id} className="step-card" padding="40px 20px 25px 20px">
          <div className="step-title">
            <h3>Instructions</h3>
          </div>
          <div className="step-form">
            <div className="step-sub">
              <h3>
                Guidelines <span className="optional">Optional</span>
              </h3>
              <p>
                Help reps become more successful and outline how they can better represent your
                company. This can be managed later from the Campaign&apos;s Instructions page.
              </p>
            </div>

            <div className="step-input step-instruct">
              <ReactQuill
                value={form.guidelines}
                onChange={val => this.props.updateForm('guidelines', val)}
              />
            </div>

            <div className="step-sub border-top">
              <h3>
                Documents <span className="optional">Optional</span>
              </h3>
              <p>
                Upload scripts, sample calls, documents or any files that will help reps succeed.
                Documents can also be managed later from your Campaign&apos;s Instruction page.
              </p>
            </div>

            <div className="no-files">
              <div className="no-upload">
                <Upload
                  {...props}
                  data={{ campaignId: this.props.campaignId }}
                  onChange={info => this.changeFile(info)}
                >
                  <Button className="btn btn-primary">Upload Training Files</Button>
                </Upload>
              </div>
              <div className="nf-types">.pdf, .doc, .docx, .jpg, .png, .gif, .wav, .wmv, .mp4</div>
            </div>

            {this.state.files.length > 0 ? (
              <div className="step-files">
                {this.state.files.map(f => (
                  <File
                    key={'file-' + (f.id || f._id)}
                    data={f}
                    delete={() => this.deleteFile(f.id || f._id, this.props.campaignId)}
                  />
                ))}
              </div>
            ) : null}
          </div>
        </Card>
        <div className="step-tips">&nbsp;</div>
      </div>
    );
  }
}
