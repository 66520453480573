import React, { Component } from 'react';
import { Header, Modal, Button, Input, message, Radio, InputNumber } from '_seriph';
import { getErrors } from '_assets/js/helpers';
import { IconTrash } from '@tabler/icons-react';
import { FormatPhone, phoneValid, standardPhone, prettyPhone, formatPercent } from '_assets/js/helpers';
import { saveRoundRobin, toggleRoundRobin } from '_graphql/mutations/roundrobin';
import './EditRoundRobin.scss';

export default class EditRoundRobin extends Component {
  state = {
    name: this.props.data?.name,
    active: this.props.data?.active ? true : false,
    busy_option: this.props.data?.busy_option || 'kill',
    cool_down: this.props.data?.cool_down || 0,
    time_out: this.props.data?.time_out || 30,
    area_code: 'any',
    numbers: undefined,
    phone_number: undefined,
    forward_to: this.props.data?.forward_to,
    new_number: undefined,
    searching: false
  }
  resetNumbers = () => {
    this.setState({
      numbers: undefined,
      phone_number: undefined,
      area_code: 'any',
      searching: false
    })
  }
  addNumber = () => {
    if (!phoneValid(this.state.new_number)) {
      return message.error('Invalid number, try again');
    }
    // Check if number exists
    const exists = this.state.forward_to.find(n => n.number === standardPhone(this.state.new_number));
    if (exists) {
      return message.error('Number already exists, try again');
    }
    const newNumbers = [
      ...this.state.forward_to,
      { number: standardPhone(this.state.new_number), weight: 0 }
    ];
    this.setState({ forward_to: [...newNumbers]}, () => {
      this.setState({ new_number: undefined });
    });
  }
  removeNumber = number => {
    const newNumbers = this.state.forward_to?.filter(n => {
      return n.number !== number;
    });
    this.setState({ forward_to: [...newNumbers]});
  }
  toggle = (number, type) => {
    const newNumbers = this.state.forward_to?.map(n => {
      if (n.weight <= 0 && type === '-') return n;
      if (n.number === number) {
        n.weight = n.weight + (type === '+' ? 1 : -1);
      }
      return n;
    });
    this.setState({ forward_to: [...newNumbers]});
  }
  confirmRemove = number => {
    Modal.confirm({
      title: 'Are you sure you want to remove this number?',
      content:
        'Removing will cause any previous stats to be cleared for this phone number',
      onOk: () => this.removeNumber(number)
    });
  };
  isValid = () => {
    if (!this.state.name) return false;
    if (this.state.forward_to?.length <= 0) return false;
    return true;
  }
  saveRoundRobin = () => {
    this.setState({ saving: true });
    this.props.client
      .mutate({
        variables: { 
          roundrobin: {
            id: this.props.data?.id,
            name: this.state.name,
            forward_to: this.state.forward_to,
            busy_option: this.state.busy_option,
            cool_down: this.state.cool_down,
            time_out: this.state.time_out
          }
        },
        mutation: saveRoundRobin
      })
      .then(result => {
        if (result && result.data && result.data.saveRoundRobin) {
          message.success('Round robin saved');
          this.props.reload();
          this.setState({ saving: false });
          // this.props.hideModal();
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Could not save call list, try again');
      });
  };
  toggleRoundRobin = (active) => {
    this.setState({ toggling: true });
    this.props.client
      .mutate({
        variables: { id: this.props.data?.id, active },
        mutation: toggleRoundRobin
      })
      .then(result => {
        if (result && result.data && result.data.toggleRoundRobin) {
          message.success('Call list ' + (active ? 'enabled' : 'disabled'));
          this.props.reload();
          this.setState({ toggling: false, active });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ toggling: false });
        message.error(getErrors(err) || 'Could not save call list, try again');
      });
  };
  percent = weight => {
    const totalWeight = this.state.forward_to.reduce((n, {weight}) => n + weight, 0);
    return formatPercent(weight/totalWeight * 100);
  };
  render() {

    return (
      <div id="edit-round-robin">
        <Modal.Close onClick={this.props.hideModal} />

        <Header size="4" font="sans" className="create-header">
          Edit call list
        </Header>

        <div className="create-content">
          <Input
            value={this.state.name}
            size="medium"
            label="Name your call list"
            onChange={e => this.setState({ name: e.target.value })}
            placeholder="Name..."
            autoFocus
          />
        </div>

        <div className="create-content">
          <label className="seriph-label">
            Forward incoming calls to: 
          </label>
          <div className="generate">
            <Input
              value={this.state.new_number}
              onChange={e => this.setState({ new_number: FormatPhone(e.target.value) }) }
              placeholder="555-555-5555"
              size="medium"
              autoFocus
            />
            <Button type="secondary" onClick={this.addNumber}>Add number</Button>
          </div>
          <div className="numbers">
            {this.state.forward_to?.map(n => (
              <div className="number" key={'nn'+n.number}>
                <h4>{prettyPhone(n.number)}</h4>
                <h4>
                  Weight: {n.weight}
                  <span className="plus" onClick={() => this.toggle(n.number, '+')}>+</span>
                  <span className="minus" onClick={() => this.toggle(n.number, '-')}>-</span>
                </h4>
                <div className="percent">
                  {this.percent(n.weight)}
                </div>
                <Button icon type="danger" size="tiny" onClick={() => this.removeNumber(n.number)}><IconTrash /></Button>
              </div>
            ))}
            { this.state.forward_to?.length <= 0 ? <div className="no-numbers">Type in a number above to add</div> : null}
          </div>
        </div>

        <div className="create-content busy">
          <label className="seriph-label">
            How to handle busy calls: 
          </label>
          <div className="busy-option">
            <Radio.Group value={this.state.busy_option} onChange={e => this.setState({ busy_option: e.target.value })} className="seriph-radio-group">
              <Radio.Button value="kill">Busy signal</Radio.Button>
              <Radio.Button value="reject">Reject call</Radio.Button>
              <Radio.Button value="ignore">Forward anyways</Radio.Button>
            </Radio.Group>
            {this.state.busy_option === 'kill' && <p><b>Busy signal</b> - this will inform the caller &quot;all lines are busy, try again later&quot; and then play reject as busy.</p>}
            {this.state.busy_option === 'reject' && <p><b>Reject call</b> - this will immediately reject the call and be handled by the incoming call system.</p>}
            {this.state.busy_option === 'ignore' && <p><b>Forward anyways</b> - this will ignore any busy reps and forward the call anyways for the rep to let go to voicemail or reject.</p>}
          </div>
        </div>

        <div className="create-content">
          <div className="busy-option flexy">
            <label className="seriph-label">
              Cool down between calls in seconds: 
            </label>
            <InputNumber
              size="small"
              onChange={cool_down => this.setState({ cool_down })}
              value={this.state.cool_down}
              placeholder="0"
            />
          </div>
        </div>

        <div className="create-content">
          <div className="busy-option flexy">
            <label className="seriph-label">
              Call timeout in seconds: 
            </label>
            <InputNumber
              size="small"
              onChange={time_out => this.setState({ time_out })}
              value={this.state.time_out}
              placeholder="30"
            />
          </div>
        </div>

        <Modal.Actions align="right">
          <Button type="default" onClick={() => this.props.hideModal()}>
            Cancel
          </Button>
          {this.state.active ? (
            <Button
              type="danger"
              loading={this.state.toggling}
              onClick={() => this.toggleRoundRobin(false)}
            >
              Disable
            </Button>
          ) : (
            <Button
              type="success"
              loading={this.state.toggling}
              onClick={() => this.toggleRoundRobin(true)}
            >
              Enable
            </Button>
          )}
          <Button
            type="primary"
            disabled={!this.isValid()}
            loading={this.state.saving}
            onClick={this.saveRoundRobin}
          >
            Save Call List
          </Button>
        </Modal.Actions>
      </div>
    );
  }
}
