import React, { Component } from 'react';
import './Progress.scss';

export default class Progress extends Component {
  render() {
    const { progressData, rounded, large, current } = this.props;

    let totalPercent = progressData.reduce((a, b) => a + (b.ignore ? 0 : b.percent), 0);
    totalPercent = isNaN(totalPercent) || !totalPercent ? 0 : parseInt(totalPercent);

    const allData = [...progressData];
    if (rounded) allData.push({ color: '#E7ECF4', percent: 100, empty: true });

    let currentSum = 0;
    let markers = this.props.markers || [];
    return (
      <div
        className={`seriph-progress ${rounded ? 'rounded' : ''} ${large ? 'progress-large' : ''}`}
      >
        <div className="bar">
          {allData.map((p, i) => {
            const percent = p.percent || 0;
            currentSum = p.empty ? 100 : currentSum + percent;
            return percent > 0 ? (
              <div
                className="step"
                key={'bar-step-' + i}
                style={{
                  zIndex: p.empty ? 99 : 200 - currentSum,
                  width: `${currentSum}%`,
                  backgroundColor: p.color
                }}
              />
            ) : null;
          })}
          {markers.map((m, i) => (
            <span className="marker" style={{left: m+'%'}} key={'markme-'+i} />
          ))}
        </div>
        { current ? (
          <div className="percent">{current}</div>
        ) : (
          <div className="percent">{totalPercent}%</div>
        )}
      </div>
    );
  }
}
