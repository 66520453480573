import React from 'react';
import moment from 'moment';
import { Avatar, Tag, Tooltip, Button } from '_seriph';
import { IconPhoneOutgoing, IconPhoneIncoming, IconPhoneX } from '@tabler/icons-react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { getLeadPhone, prettyPhone, callDuration } from '_assets/js/helpers';

export const getColumns = (lead, selectCall) => {
  return [
    {
      title: 'Call info',
      dataIndex: 'first_name',
      render: (column, data) => {
        return (
          <div className="call-info">
            <span
              className={
                'call-icon' +
                (data.incoming_call ? ' incoming' : '') +
                (data.status === 'missed' && data.incoming_call ? ' missed' : '')
              }
            >
              {data.status === 'missed' && data.incoming_call ? (
                <IconPhoneX />
              ) : data.incoming_call ? (
                <IconPhoneIncoming />
              ) : (
                <IconPhoneOutgoing />
              )}
            </span>
            <div className="call-details">
              <h3>
                {data.incoming_call
                ? data.status === 'missed'
                  ? 'Missed Call'
                  : 'Incoming Call'
                : 'Outgoing Call'}
              </h3>
              <p>{moment(data.createdAt * 1).format('DD/MM/YYYY • h:mma')}</p>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Phone number',
      dataIndex: 'number',
      render: (column, data) => {

        const actualNumber = getLeadPhone(lead, data.to_number);

        return (
          <Tooltip title={prettyPhone(data.to_number)}>
            <Tag>{actualNumber}</Tag>
          </Tooltip>
        );
      }
    },
    {
      title: 'Sales rep',
      dataIndex: 'picture',
      render: (column, data) => {
        return (
          <div className="call-own">
            <Avatar
              size="mini"
              type="company"
              picture={data.agent?.picture}
            />
            {data.agent?.display_name}
          </div>
        );
      }
    },
    {
      title: 'Duration',
      width: 140,
      dataIndex: 'duration',
      sorter: (a, b) => a.duration - b.duration,
      render: (column, data) => {
        return data.recording_url ? (
          <div className="player init-player">
            <AudioPlayer
              layout="horizontal"
              showJumpControls={false}
              customAdditionalControls={[]}
              customVolumeControls={[]}
              customProgressBarSection={[null, RHAP_UI.PROGRESS_BAR, RHAP_UI.CURRENT_LEFT_TIME]}
              src={data.recording_url}
            />
          </div>
        ) : callDuration(data.duration);
      }
    },
    {
      title: '',
      dataIndex: 'details',
      render: (column, data) => {
        return data.recording_url ? (
          <Button size="tiny" onClick={() => selectCall(data.id)}>Details</Button>
        ) : '';
      }
    },
  ];
};