
const tooManyCaps = (mainArea, problems) => {
  const tooManyCaps = [...mainArea?.matchAll(/([A-Z]{4,})/g)];
  if (tooManyCaps.length > 0) {
    const warningMessage = `Four capital letters may trigger spam filters.`;

    problems.push({
      message: warningMessage,
      shortName: 'four-caps',
      type: 'warning'
    });

    const capsRegex = new RegExp(`([A-Z]{4,})`, 'g');
    mainArea = mainArea.replaceAll(capsRegex, (
      `<span class="ql-warning" contenteditable="false">$1</span>`
    ));
  }

  return mainArea;
}

export const checkProblems = (mainArea, replacedMainArea, problems) => {

  let newMain = mainArea;
  let newReplaced = replacedMainArea;
  newMain = tooManyCaps(newMain, problems);
  newReplaced = tooManyCaps(newReplaced, problems);

  return { 
    mainArea: newMain,
    replacedMainArea: newReplaced,
    problems 
  };
};


