import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, Button, Select, Checkbox, message } from '_seriph';
import { DatePicker } from 'antd';
import { saveSeats } from 'src/App/Admin/_graphql/mutations';
import { getErrors } from '_assets/js/helpers';
import moment from 'moment';
import './Seats.scss';

class Seats extends Component {
  state = {
    visible: true,
    xdr_seats: this.props.company?.xdr_seats || 0,
    internal_seats: this.props.company?.internal_seats || 0,
    trial_expire_date: this.props.company?.trial_expire_date ? moment(this.props.company?.trial_expire_date*1).endOf('day') : undefined,
    saving: false
  };
  hideModal = () => this.setState({ visible: false });

  onSaveClick = () => {
    this.setState({ saving: true });
    Modal.confirm({
      title: `Are you sure you want to adjust the seats for ${this.props.company?.name}?`,
      okText: 'Save',
      cancelText: 'Cancel',
      onCancel: () => this.setState({ saving: false }),
      onOk: () => {
        this.props.client
          .mutate({
            variables: { 
              company_id: this.props.company?.id, 
              xdr_seats: this.state.xdr_seats,
              internal_seats: this.state.internal_seats,
              trial_expire_date: this.state.trial_expire_date ? this.state.trial_expire_date.format('YYYYMMDD') : null
            },
            mutation: saveSeats
          })
          .then(result => {
            if (result?.data?.saveSeats) {
              this.setState({ saving: false });
              message.success(`Seat alottment successfully updated`);
              this.hideModal();
            } else {
              throw new Error();
            }
          })
          .catch(err => {
            this.setState({ saving: false });
            message.error(getErrors(err) || `Could not save seats, try again`);
          });
      }
    });
  };
  onChange = currentDate => {
    this.setState({ trial_expire_date: currentDate.endOf('day') });
  };
  render() {
    const { company } = this.props;
    return (
      <Modal
        wrapClassName="admin-seats-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={false}
        width={500}
      >
        <Modal.Close onClick={this.hideModal} />
        <Modal.Header>
          Manage seats for {company?.name}
        </Modal.Header>

        <div className="bonus-form-cont">
          <div className="bonus-form">
          <Select
              label="XDR Alottment"
              value={this.state.xdr_seats}
              onChange={value => this.setState({ xdr_seats: value })}
            >
              {[...Array(51)]
                .map((x, i) => i)
                .map(x => (
                  <Select.Option key={x} value={x}>
                    {x} Seat{x !== 1 ? 's' : ''}
                  </Select.Option>
                ))}
            </Select>
            <Select
              label="Seat Alottment"
              value={this.state.internal_seats}
              onChange={value => this.setState({ internal_seats: value })}
            >
              {[...Array(51)]
                .map((x, i) => i)
                .map(x => (
                  <Select.Option key={x} value={x}>
                    {x} Seat{x !== 1 ? 's' : ''}
                  </Select.Option>
                ))}
            </Select>
            <div className="day-pick">
              <label className="seriph-label">Trial expires:</label>
              <DatePicker 
                className="seriph-daypicker"
                onChange={this.onChange}
                defaultValue={this.state.trial_expire_date}
                format="[End of day:] M/D/YY"
              />
              <Checkbox
                checked={!this.state.trial_expire_date}
                onChange={e => this.setState({ trial_expire_date: e.target.checked ? null : moment().add(5, 'days').endOf('day') })}
              >
                Grandfathered? (Unlimited trial or subscruibed manually)
              </Checkbox>
            </div>
          </div>
          <Modal.Actions align="right">
            <Button onClick={this.hideModal}>Cancel</Button>
            <Button type="primary" loading={this.state.saving} onClick={this.onSaveClick}>
              Save Seats
            </Button>
          </Modal.Actions>
        </div>

      </Modal>
    );
  }
}

export default withRouter(Seats);
