import React, { Component } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { numberWithCommas, formatPercent } from '_assets/js/helpers';
import './CallChart.scss';

export default class CallChart extends Component {
  state = {
    active: null
  };
  customTooltip = data => {
    const payload = data.payload || [];
    return (
      <div className="chart-tooltip">
        {payload.map((p, i) => {
          const percent = p?.payload?.payload?.percent;
          return (
            <div key={`tip-${i}`} className="tip-box">
              <div className="tip-title">{p.payload.name}</div>
              <div className="tip-contents">
                <div className="tip-item">
                  <div className="tip-name">Percent</div>
                  <div className="tip-value">{formatPercent(percent, 1)}</div>
                </div>
                <div className="tip-item">
                  <div className="tip-name">Total</div>
                  <div className="tip-value">{numberWithCommas(p.value)}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  onMouseOver = (data, index) => this.setState({ active: index });
  onMouseLeave = () => this.setState({ active: null });
  render() {
    const { data } = this.props;

    return (
      <div className="chart-box" id="admin-score-call-chart">
        <div className="chart-display">
          <div className="left-chart">
            <PieChart width={370} height={370}>
              <Pie
                data={data}
                cx={180}
                cy={180}
                innerRadius={80}
                outerRadius={160}
                paddingAngle={2}
                dataKey="value"
                onMouseOver={this.onMouseOver}
                onMouseLeave={this.onMouseLeave}
              >
                {data.map((s, i) => (
                  <Cell
                    key={`cell-${i}`}
                    fill={i === this.state.active ? s.fill + 'ee' : s.fill}
                  />
                ))}
              </Pie>
              <Tooltip content={this.customTooltip} />
            </PieChart>
          </div>
          <div className="right-chart">
            <div className="dash-data">
              {data?.map((d, i) => (
                <div className={`dash-sts ${d.value <= 0 ? 'na' : ''}`} key={`ni-${i}`}>
                  <div>
                    <span style={{ backgroundColor: d.fill }} />
                    {d.name}
                  </div>
                  <h2>
                    {formatPercent(d.percent, 1)} ({numberWithCommas(d.value) || '0'})
                  </h2>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
