import gql from 'graphql-tag';

export const companyData = gql`
  query {
    companyData {
      application_count
      message_count
      notification_count
      can_cohort
      expires
      expires_period
      integration {
        calendly {
          url
          auth_token
          refresh_token
        }
        chilipiper {
          domain
          inbound_router
        }
        custom_domains {
          domain
          active
        }
        custom_domain {
          domain
          active
        }
        gmail {
          email
        }
        salesforce {
          enabled
        }
        hubspot {
          enabled
          portal_id
        }
      }
      company {
        website
        owner_id
        beta_opt_in
        seats_left
        internal_seats
        xdr_seats
        locked_type
        num_employees
        num_reps
        text_number
      }
      employee {
        permission_type
      }
    }
  }
`;

export const agentData = gql`
  query {
    agentData {
      message_count
      notification_count
    }
  }
`;

export const getTypeform = gql`
  query getTypeform($response_id: String!, $type: String!) {
    getTypeform(response_id: $response_id, type: $type) {
      id
      response_id
      email
      phone_number
      first_name
      last_name
      used
    }
  }
`;

export const getMemberInvite = gql`
  query getMemberInvite($invite_id: String!) {
    getMemberInvite(invite_id: $invite_id) {
      id
      email
      used
      company {
        id
        name
        logo
      }
    }
  }
`;

export const getRepInvite = gql`
  query getRepInvite($invite_id: String!) {
    getRepInvite(invite_id: $invite_id) {
      id
      email
      used
      company {
        id
        name
        logo
      }
    }
  }
`;

export const getCompanyInvite = gql`
  query getCompanyInvite($application_id: String!) {
    getCompanyInvite(application_id: $application_id) {
      id
      name
      email
      invite {
        invited
        link
        sent
        resent
        joined
      }
      employee_id
      company {
        id
        name
        logo
      }
    }
  }
`;

export const getCompanyTypeform = gql`
  query getTypeform($response_id: String!, $type: String!) {
    getTypeform(response_id: $response_id, type: $type) {
      id
      response_id
      email
      phone_number
      first_name
      last_name
      used
      company {
        id
        name
        logo
      }
    }
  }
`;
