import React, { Component } from 'react';
import { IconCheck } from '@tabler/icons-react';
import './ProgressCircle.scss';

export default class ProgressCircle extends Component {
  render() {
    const { percent, complete } = this.props;

    return (
      <div className="sxd-progress-circle">
        <div className="bordered" />
        { complete ? <IconCheck /> : null }
        <svg viewBox="0 0 32 32" className="thing">
          <circle r="16" cx="16" cy="16" strokeDasharray={(percent || 0) + ' 100'} />
        </svg>
      </div>
    );
  }
}
