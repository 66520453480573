import React, { Component } from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { Alert } from '_seriph';
import Settings from '../Agent/Settings/Settings';
import OAuth from '../Agent/Settings/_components/Banking/OAuth';
import { loginUser, logoutUser } from 'Store/user/user_actions';
import MissingAgent from 'App/Home/Missing/MissingAgent';
import { message } from '_seriph';
import { masqueradeAdmin } from '_graphql/mutations/auth';
import { IconArrowBackUp } from '@tabler/icons-react';
import { getAgentInformation } from '_graphql/queries/rep';
import './FrozenAgent.scss';

const { Content } = Layout;

class Agent extends Component {
  state = {
    info: {},
  };
  componentDidMount = () => {
    this.getAgentInformation();
  };
  getAgentInformation = () => {
    this.props.client.query({ query: getAgentInformation }).then(result => {
      if (result && result.data && result.data.agentInformation) {
        const info = result.data.agentInformation;
        this.setState({ info });
      }
    });
  };
  masqAdmin = () => {
    this.props.client
      .mutate({ mutation: masqueradeAdmin })
      .then(result => {
        if (result && result.data && result.data.masqueradeAdmin) {
          this.props.loginUser(result.data.masqueradeAdmin);
          this.props.history.push('/sales-reps');
        } else {
          throw new Error('Could not login as admin, try again');
        }
      })
      .catch(err => message.error(err.message));
  };
  render() {
    const isMasq = this.props.user?.masq ? true : false;

    return (
      <Layout id="frozen-agent">
        <Content className="agent-body">
          {isMasq && (
            <div id="masq-user">
              <b>Warning</b>&nbsp;&nbsp;&nbsp;You are currently logged into a client account
              <div className="go-back" onClick={this.masqAdmin}>
                <IconArrowBackUp />
                Back to Admin
              </div>
            </div>
          )}
          <Alert type="danger">
            This account has been put on hold, contact <a href="mailto:support@sellx.com">support@sellx.com</a> for more info
          </Alert>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/settings/account" />} />
            <Route
              exact
              path="/settings/:page?"
              render={i => (
                <Settings
                  match={i.match}
                  client={this.props.client}
                  info={this.state.info}
                  page={i.match.params.page}
                  logoutUser={this.props.logoutUser}
                />
              )}
            />
            <Route
              exact
              path="/stripe/oath"
              render={i => <OAuth match={i.match} client={this.props.client} />}
            />
            <Route component={MissingAgent} />
          </Switch>
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, { logoutUser, loginUser })(Agent));
