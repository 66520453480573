import React, { Component } from 'react';
import { Modal, Button, message } from 'antd';
import moment from 'moment';
import { getEndCampaign } from '_graphql/queries/campaign';
import { endCampaign } from '_graphql/mutations/campaign';
import { formatMoney, numberWithCommas, getErrors } from '_assets/js/helpers';
import Loading from '_shared/Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Progress from '_styleguide/Progress/Progress';
import Avatar from '_styleguide/Avatar/Avatar';
import { statusMap } from '_constants/campaigns';
import './EndCampaign.scss';

export default class EndCampaign extends Component {
  state = {
    visible: true,
    loading: true,
    campaign: null,
    reps: []
  };
  componentDidMount = () => {
    this.loadEndCampaign();
  };
  loadEndCampaign = () => {
    this.props.client
      .query({ variables: { id: this.props.campaign.id }, query: getEndCampaign })
      .then(result => {
        if (result && result.data && result.data.endCampaign) {
          const data = result.data.endCampaign;
          const sortedReps = data.reps.sort(
            (a, b) => parseFloat(b.stats.total_paid) - parseFloat(a.stats.total_paid)
          );
          this.setState({ campaign: data.campaign, reps: sortedReps.slice(0, 3), loading: false });
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        message.error('Could not load campaign, try again');
        this.setState({ loading: false });
      });
  };
  endCampaign = () => {
    this.setState({ ending: true });
    this.props.client
      .mutate({
        variables: { id: this.props.campaign.id },
        mutation: endCampaign
      })
      .then(result => {
        if (result && result.data && result.data.endCampaign) {
          this.props.reload();
          this.hideModal();
          message.success('Campaign is now complete');
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ ending: false });
        message.error(getErrors(err) || 'Could not end campaign, try again');
      });
  };
  confirmEnd = () => {
    Modal.confirm({
      title: 'Are you sure you want to end this campaign?',
      content:
        'Once your campaign has ended it cannot be restarted and all work will stop.  You will still be able to export/access your leads if needed.',
      onOk: this.endCampaign
    });
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { loading, reps, campaign, ending } = this.state;

    let totalPercent = 0,
      completedPercent = 0,
      amountDue = 0,
      refund = true;
    if (!loading && campaign) {
      totalPercent = Math.floor(
        ((campaign.stats.completed + campaign.stats.review) / campaign.estimated_leads_count) * 100
      );
      completedPercent = Math.floor(
        (campaign.stats.completed / campaign.estimated_leads_count) * 100
      );
      amountDue = campaign.actual_budget - campaign.paid - campaign.stats.owed;
      if (amountDue < 0) {
        amountDue = Math.abs(amountDue);
        refund = false;
      }
    }

    return (
      <Modal
        wrapClassName="campaign-end-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        width={800}
        closable={false}
        maskClosable={false}
      >
        {!ending && (
          <div className="close-icon" onClick={this.hideModal}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}
        {loading ? (
          <Loading />
        ) : (
          <div className="end-content">
            <div className="stats">
              <h5>Campaign Summary</h5>
              <h2>{campaign.name}</h2>
              <h4>
                <span className={'left-type'}>
                  {campaign.objective === 'qualify' && 'Lead Qualification'}
                  {campaign.objective === 'leadgen' && 'Lead Generation'}
                </span>
                {campaign.status === 'active' && campaign.status_updated && (
                  <span>
                    &nbsp;&nbsp;&bull;&nbsp;&nbsp;{statusMap[campaign.status]} since{' '}
                    {moment(campaign.status_updated * 1).format('M/D')}
                  </span>
                )}
                {(campaign.status === 'inactive' || campaign.status === 'completed') &&
                  campaign.status_updated && (
                    <span>
                      &nbsp;&nbsp;&bull;&nbsp;&nbsp;{statusMap[campaign.status]} since{' '}
                      {moment(campaign.status_updated * 1).format('M/D')}
                    </span>
                  )}
              </h4>
              <div className="progress">
                <Progress
                  percent={totalPercent}
                  successPercent={completedPercent}
                  showInfo={true}
                />
                <div className="info">
                  <div className="completed">
                    <span />
                    <b>{campaign.stats.completed}</b> Completed
                  </div>
                  <div className="pending">
                    <span />
                    <b>{campaign.stats.review}</b> Pending
                  </div>
                  <div className="available">
                    <span />
                    <b>{campaign.stats.unassigned}</b> Available
                  </div>
                </div>
              </div>
              <div className="reps">
                <h3>Top Sales Reps</h3>
                <table className="rep-table">
                  <tbody>
                    {reps.map(r => {
                      const total =
                        campaign.objective === 'leadgen'
                          ? r.stats.generated
                          : r.stats.qualified + r.stats.not_interested;
                      return (
                        <tr key={'ar-' + r.rep.id}>
                          <td>
                            <Avatar size="mini" type="agent" picture={r.rep.picture} />
                          </td>
                          <td>{r.rep.display_name}</td>
                          <td>
                            {numberWithCommas(total)} lead{total === 1 ? '' : 's'}
                          </td>
                          <td>{formatMoney(r.stats.total_paid)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="summary">
              <div className="overview">
                <div className="left">
                  <h4>{formatMoney(campaign.actual_budget)}</h4>
                  <p>Original Budget</p>
                </div>
                <div className="right">
                  <h4>{formatMoney(campaign.paid)}</h4>
                  <p>Paid out so far</p>
                </div>
              </div>
              <div className="line-items">
                <div className="item">
                  <div className="info">
                    <h4>
                      Pending Review<p>Leads awaiting approval</p>
                    </h4>
                  </div>
                  <div className="value">
                    {formatMoney(campaign.stats.owed)}
                    <p>
                      {campaign.stats.review} lead{campaign.stats.review === 1 ? '' : 's'}
                    </p>
                  </div>
                </div>
                <div className="item">
                  {campaign.actual_budget >= campaign.paid ? (
                    <div className="info">
                      <h4>Remaining Budget</h4>
                      <p>Unused budget will be refunded at campaigns end</p>
                    </div>
                  ) : (
                    <div className="info">
                      <h4>Amount Owed</h4>
                      <p>Money paid to reps over the initial budget amount</p>
                    </div>
                  )}
                  <div className="value">
                    {formatMoney(Math.abs(campaign.actual_budget - campaign.paid))}
                  </div>
                </div>
              </div>
              <div className="notice">
                <FontAwesomeIcon icon={faInfoCircle} />
                <p>
                  Any leads Pending Review will be completed and paid out immediately, once campaign
                  has been ended.
                </p>
              </div>
              <div className="total">
                <h4>{refund ? 'Refund amount' : 'Amount due'}</h4>
                <div>{formatMoney(amountDue)}</div>
              </div>
              <Button
                loading={ending}
                className="btnx btnx-primary btnx-large btnx-block"
                onClick={this.confirmEnd}
              >
                End Campaign
              </Button>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
