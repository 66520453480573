import gql from 'graphql-tag';

export const touchpointCalls = gql`
  query touchpointCalls($results: Int, $page: Int) {
    touchpointCalls(results: $results, page: $page) {
      data {
        id

        title
        type
        reason
        expired

        lead {
          id
          first_name
          last_name
        }

        updatedAt
        createdAt
      }
      total
    }
  }
`;

export const touchpointEmails = gql`
  query touchpointEmails($results: Int, $page: Int) {
    touchpointEmails(results: $results, page: $page) {
      data {
        id

        title
        type
        reason
        expired

        lead {
          id
          first_name
          last_name
        }

        updatedAt
        createdAt
      }
      total
    }
  }
`;
