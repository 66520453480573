import React, { Component } from 'react';
import { Modal, Button, Tooltip, message } from '_seriph';
import { getLeadName } from '_helpers/lead';
import PhoneStatus from './_components/PhoneStatus/PhoneStatus';
import LeadCalls from './_components/LeadCalls/LeadCalls';
import ManageCall from './_components/ManageCall/ManageCall';
import { delay, getErrors } from '_assets/js/helpers';
import { saveCallDisposition, noCallList } from '_graphql/mutations/call';
import QualifyModal from 'App/Company/Leads/Lead/_modals/QualifyModal/QualifyModal';
import { completeStep } from '_graphql/mutations/lead';
import {
  setupConference,
  onConferenceReady
} from '../../_helpers/twilio';
import {
  createPhoneListeners
} from '../../../../_helpers/twilio';
import { nonApiCall } from '../../_helpers/call';
import { 
  IconPlayerPause,
  IconArrowRight,
  IconPhoneOff,
  IconRecordMail,
  IconPhone,
  IconSettingsOff,
  IconSettings
} from '@tabler/icons-react';
import { noCall } from '_assets/js/helpers';
import './CallBar.scss';


const DELAY_AMOUNT = 2000;

export default class CallBar extends Component {
  state = {
    pressed: '',
    mute: false,
    conference: null,
    savingDisposition: false,
    savingComplete: false,
    skipping: false,
    qualifyModal: null
  };
  conference = null;
  call = null;
  componentDidMount = async () => {
    this.conference = await setupConference();
    onConferenceReady(this.conference, this.props.updateMicOptions);
    createPhoneListeners(this.conference, this.endConference);
    this.props.setDialer(this.conference);
    this.props.setMakeCall(this.makeCall);
  }
  componentWillUnmount = () => {
    this.call?.disconnect();
    this.conference = null;
    this.call = null;
  };
  makeCall = async lead => {
    this.props.showInfo(`Preparing to call ${getLeadName(lead)}`);
    this.props.updateStatus('initiated');
    await delay(this.props.settings?.delay || DELAY_AMOUNT);
    this.props.updateEndCall(this.endConference, this.pressKey);
    this.props.updateCompleteTask(this.completeTask);
    this.call = await nonApiCall(this.conference, lead, {...this.props, call: this.call});
  };
  hangUp = async () => this.conference?.disconnectAll();
  endConference = async () => {
    this.call?.disconnect();
    this.call = null;
    this.props.showInfo('Call ended');
    this.props.updateStatus('ready');
    setTimeout(() => {
      this.props.showInfo(null);
    }, 1000)
  };
  pressKey = key => {
    this.call?.sendDigits(key);
    this.setState({ pressed: this.state.pressed + key });
  };
  toggleMute = () => {
    const isMuted = !this.state.mute;
    this.call?.mute(isMuted);
    this.setState({ mute: isMuted });
  };
  toggleNoCall = (lead_id, number, type) => {
    this.setState({ savingNoCall: true });
    this.props.client
      .mutate({ variables: { lead_id, number, type }, mutation: noCallList })
      .then(result => {
        if (result?.data?.noCallList) {
          if (type === 'add') message.success('Phone number added to the no call list for this lead');
          if (type === 'remove') message.success('Phone number removed from the no call list for this lead');
          if (this.props.noCallUpdated) this.props.noCallUpdated(lead_id, result?.data?.noCallList);
          this.setState({ savingNoCall: false });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Error saving no call list, try again.');
        this.setState({ savingNoCall: false });
      });
  };
  saveDisposition = (callSID, answered, voicemail, nextAction) => {
    this.setState({ savingDisposition: answered ? 'yes' : 'no' });
    const lead = this.props.currentLead;
    this.props.callDisposition(lead?.id, callSID, answered ? 'answered' : (voicemail ? 'voicemail' : 'no-answer'));
    this.props.client
      .mutate({ variables: { callSID, answered, voicemail }, mutation: saveCallDisposition })
      .then(result => {
        if (result?.data?.saveCallDisposition) {
          const skipRest = this.props.settings?.skip_after_answer && answered;
          if (nextAction && !skipRest) nextAction();
          if (skipRest && this.props.goToEnd) {
            message.success('Call answered, skipping remaining dials');
            this.setState({ savingDisposition: false }, this.props.goToEnd);
          } else {
            this.props.updateNeedDisposition(false);
            this.setState({ savingDisposition: false });            
          }
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Error saving disposition, try again.');
        this.setState({ savingDisposition: false });
      });
  };
  completeTask = async (lead, fromSubmit, autoComplete) => {
    this.setState({ savingComplete: true });
    this.props.client
      .mutate({
        variables: {
          leadId: lead?.id,
          taskId: lead?.current_task?.task_id
        },
        mutation: completeStep
      })
      .then(async result => {
        if (result?.data?.completeStep) {
          if (this.props.manualDial) {
            message.success(`Task completed for ${getLeadName(lead)}`);
            if (this.props.hideModal) this.props.hideModal();
          } else {
            if (!fromSubmit && !autoComplete) message.success('Task completed, finding next lead...');
            if (!fromSubmit && autoComplete) message.success('Auto completing task, finding next lead...');
            this.props.findNextLead();
            this.setState({ savingComplete: false });            
          }
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        this.setState({ savingComplete: false });
        message.error('Error completing task, try again');
      });
  };
  skipStep = async () => {
    this.setState({ skipping: true });
    const lead = this.props.currentLead;
    this.props.client
      .mutate({
        variables: {
          leadId: lead?.id,
          taskId: lead?.current_task?.task_id,
          typeId: 'skip',
          skipType: 'other',
          skipReason: 'No valid numbers to call'
        },
        mutation: completeStep
      })
      .then(async result => {
        if (result?.data?.completeStep) {
          if (this.props.manualDial) {
            message.success(`Skipped calling ${getLeadName(lead)} for this task`);
            if (this.props.hideModal) this.props.hideModal();
          } else {
            message.success(`Skipped calling ${getLeadName(lead)} for this task`);
            this.props.findNextLead();
            this.setState({ skipping: false });          
          }
          
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        this.setState({ skipping: false });
        message.error('Error skipping call, try again');
      });
  };
  confirmSkip = () => {
    Modal.confirm({
      title: 'Are you sure you want to skip calling this lead?',
      content:
        <span><b>WARNING:</b> Calling a lead should ONLY be skipped if there are no valid numbers or if it is absolutely necessary to keep the lead viable.  <br /><br /><b>REMINDER:</b> You can adjust which numbers are called from the settings tab on the bottom left.</span>,
      onOk: this.skipStep
    });
  };
  completeSubmitLead = lead => {
    this.completeTask(lead, true)
  };
  setConference = conference => this.setState({ conference });
  showQualifyModal = () => this.setState({ qualifyModal: true, savingComplete: true });
  removeQualifyModal = () => this.setState({ qualifyModal: false, savingComplete: false });
  render() {
    const { pressed, mute, savingDisposition, savingComplete } = this.state;
    const { campaign, time, currentLead, status, needDisposition, testStatus, failed } = this.props;

    const lead = currentLead;
    const hasValidCalls = (lead.calls || []).filter(c => {
      const dontCall = noCall(lead.no_call_list, c?.number);
      return !c.invalid && !c.skip && !c.duplicate && !dontCall;
    }).length > 0;
    const isCompleted = lead?.completed ? true : false;
    const isTesting = testStatus === 'calling';
    const hasStarted = lead.started ? true : false;
    const integration = campaign?.integration;
    const useCalendly = campaign.calendly && integration?.calendly?.url ? true : false;

    let nextFunction = null;
    if (status === 'ready') {
      nextFunction = () => this.makeCall(lead);
    }

    return (
      <div id="call-bar">
        <div className={`dialing ${status}`}></div>

        <div className="bf">
          <div className="bf-lead">
            <PhoneStatus status={status} />
            <LeadCalls 
              lead={lead} 
              phoneSelected={this.props.phoneSelected}
              toggleNoCall={this.toggleNoCall}
              savingNoCall={this.state.savingNoCall}
          />
          </div>

          <div className="bf-actions">
            {(status === 'ringing' || status === 'in-progress') && (
              <ManageCall
                status={status}
                time={time}
                pressed={pressed}
                pressKey={this.pressKey}
                mute={mute}
                toggleMute={this.toggleMute}
              />
            )}
            { isTesting ? (
              <div className="real-actions">
                <Button type="primary" disabled={true}>
                  Testing in progress...
                </Button>
              </div>
            ) : (
              <div className="real-actions">
                { this.props.manualDial ? (
                  <Button className="settings-toggle" type="secondary" circle onClick={this.props.toggleSettings}>
                    { this.props.showSettings ? <IconSettingsOff /> : <IconSettings /> }
                  </Button>
                ) : null}
                { isCompleted ? (
                  <Button type="primary" disabled={true}>
                    { lead?.submitted ? 'Lead Submitted' : 'Task Completed' }
                  </Button>
                ) : null }
                {(!hasValidCalls || (!hasStarted && status === 'ready')) && !isCompleted ? (
                  <Button
                    type="secondary"
                    loading={this.state.skipping}
                    onClick={this.confirmSkip}
                  >
                    Skip
                  </Button>
                ) : null}
                {(status === 'ready' && !needDisposition) && !isCompleted && hasValidCalls ? (
                  <Button type="primary" onClick={() => this.makeCall(lead)}>
                    Begin Dialing
                  </Button>
                ) : null}
                

                
                {(status === 'ready' && needDisposition) || (status === 'ended' && needDisposition) ? (
                  failed ? (
                    <Button type="primary" onClick={nextFunction}>
                      Continue
                    </Button>
                  ) : (
                    <React.Fragment>
                      <Button 
                        type="secondary" 
                        onClick={() => this.saveDisposition(needDisposition, false, true, nextFunction)} 
                        disabled={savingDisposition === 'yes'}
                        loading={savingDisposition === 'no'}
                        icon="left"
                        className="voicemail"
                      >
                        <IconRecordMail />
                        Left Voicemail
                      </Button>
                      <Button 
                        type="secondary" 
                        onClick={() => this.saveDisposition(needDisposition, false, false, nextFunction)} 
                        disabled={savingDisposition === 'yes'}
                        loading={savingDisposition === 'no'}
                        icon="left"
                        className="no-answer"
                      >
                        <IconPhoneOff />
                        No Answer
                      </Button>
                      
                      <Button 
                        type="secondary" 
                        onClick={() => this.saveDisposition(needDisposition, true, false, nextFunction)} 
                        disabled={savingDisposition === 'no'}
                        loading={savingDisposition === 'yes'}
                        icon="left"
                        className="answer"
                      >
                        <IconPhone />
                        Answered
                      </Button>
                    </React.Fragment>
                  )
                ) : null}
                {(status === 'ended' && !needDisposition) ? (
                  <React.Fragment>
                    <Button type="secondary" onClick={this.showQualifyModal}>
                      Submit Lead
                    </Button>
                    <Button 
                      type="primary" 
                      onClick={() => this.completeTask(lead)} 
                      loading={savingComplete}
                    >
                      Complete Task
                    </Button>
                  </React.Fragment>
                  
                ) : null}
                {status === 'transition' && (
                  <React.Fragment>
                    <Button type="default" icon="left" onClick={this.endConference}>
                      <IconPlayerPause />
                      Pause
                    </Button>
                    <div
                      className="transition"
                      style={{ animationDuration: `${this.props.settings?.delay || DELAY_AMOUNT}ms` }}
                    >
                      <span className="capitalize">Calling lead</span>
                      <IconArrowRight />
                    </div>
                  </React.Fragment>
                )}
                {(status === 'ringing' || status === 'in-progress') ? (
                  <React.Fragment>
                    <Tooltip title="End call">
                      <Button type="danger" circle onClick={this.hangUp}>
                        <IconPhoneOff />
                      </Button>
                    </Tooltip>
                  </React.Fragment>
                ) : null}
              </div>
            )}
            
          </div>

        </div>

        {this.state.qualifyModal ? (
          <QualifyModal
            client={this.props.client}
            lead={lead}
            removeModal={this.removeQualifyModal}
            reload={() => this.completeSubmitLead(lead)}
            versionTwo={true}
            meetingValid={useCalendly ? lead && lead.meetingScheduled : true}
            questions={[]}
            campaign={campaign}
          />
        ) : null}

      </div>
    );
  }
}