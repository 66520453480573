import gql from 'graphql-tag';

export const getOnboard = gql`
  query getOnboard($id: ID) {
    getOnboard(id: $id) {
      id
      type
      first_name
      last_name
      email
      phone
      password
      company_name
      company_website
      company_size
      company_industry
      company_role
      email_verified
      completed
    }
  }
`;
