import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLead } from '_graphql/queries/lead';
import {
  saveLead,
  removeLead,
  saveAnswers,
  createLead,
  completeStep,
  getFromHopper
} from '_graphql/mutations/lead';
import { formData } from './formData';
import queryString from 'query-string';
import { getErrors, formatMoney, prettyPhone } from '_assets/js/helpers';
import CalendarModal from './_modals/CalendarModal/CalendarModal';
import CancelMeetingModal from './_modals/CancelMeetingModal/CancelMeetingModal';
import ChilipiperModal from './_modals/ChilipiperModal/ChilipiperModal';
import StageModal from './_modals/StageModal/StageModal';
import PaymentMethodModal from './_modals/PaymentMethodModal/PaymentMethodModal';
import OutOfMoneyModal from './_modals/OutOfMoneyModal/OutOfMoneyModal';
import RevertToEditModal from './_modals/RevertToEditModal/RevertToEditModal';
import AcceptModal from './_modals/AcceptModal/AcceptModal';
import QualifyModal from './_modals/QualifyModal/QualifyModal';
import DisputeModal from './_modals/DisputeModal/DisputeModal';
import CallLeadModal from './_modals/CallLeadModal/CallLeadModal';
import Info from './Info/Info';
import Email from './Email/Email';
import Thread from './Email/Thread/Thread';
import Calls from './Calls/Calls';
import Notes from './Notes/Notes';
import Texts from './Texts/Texts';
import Activity from './Activity/Activity';
import Sequences from './Sequences/Sequences';
import Questions from './Questions/Questions';
import Instructions from './Instructions/Instructions';
import { validate } from './_helpers/validateLead';
import {
  getLeadInfo,
  getLeadName,
  getLeadPrice,
  getCurrent,
  cleanLead
} from './_helpers/leadInformation';
import { isInternal } from '_helpers/permissions';
import { LeadTag } from './_components/LeadTag/LeadTag';
import { Header, message, Loading, Tooltip, Avatar, Button, Tabs, Select, Modal } from '_seriph';
import ScheduleMeeting from './_components/ScheduleMeeting/ScheduleMeeting';
import { statusMap } from '_constants/leadFields';
import moment from 'moment';
import parsePhoneNumber from 'libphonenumber-js';
import { IconTrash } from '@tabler/icons-react';
import './Lead.scss';

const { Option } = Select;

class Lead extends Component {
  state = {
    lead: null,
    loading: true,
    savedLead: null,
    saving: false,
    source: null,
    required: [],
    questions: [],
    tab: this.props.tab || 'sequences',
    outofmoney: false,
    campaign: {},
    isNew: false,
    editDropdownValue: 'Edit'
  };
  componentDidMount() {
    /* Gather information */
    let campaign = null;
    let isNew = ['new', 'newQualified'].includes(this.props.leadId);
    const params = queryString.parse(this.props.location.search);
    if (params.campaign) campaign = this.props.campaigns.find(c => c.id === params.campaign);
    if (isNew && campaign) {
      const newLead = { ...formData, custom_fields: campaign.custom_fields || [] };
      newLead.campaign_id = params.campaign;
      let required = null;
      if (campaign.required) required = [...campaign.required];
      this.setState({
        loading: false,
        lead: newLead,
        savedLead: JSON.stringify(newLead),
        required,
        tab: 'info',
        campaign,
        isNew
      });
    } else if (this.props.leadId && !isNew) {
      this.loadLead(this.props.leadId);
    } else {
      this.props.history.push('/leads');
    }
  }
  UNSAFE_componentWillMount = () => window.scrollTo({ top: 0 });
  componentDidUpdate(prevProps) {
    if (this.props.leadId !== prevProps.leadId) {
      this.loadLead(this.props.leadId);
    }
  }
  loadLead = leadId => {
    this.props.client
      .query({ variables: { id: leadId }, query: getLead })
      .then(result => {
        if (result && result.data && result.data.lead) {
          const leadData = getLeadInfo(result.data.lead);
          this.setState({
            ...leadData,
            savedLead: JSON.stringify(leadData.lead),
            loading: false
          });
        } else {
          message.error('Lead not found, try again');
        }
      })
      .catch(() => {
        message.error('Lead not found, try again');
      });
  };
  createLead = async shouldSubmit => {
    let lead = {
      ...this.state.lead,
      phone: parsePhoneNumber(this.state.lead.phone?.toString() || '', 'US')?.format('RFC3966'),
      mobile: parsePhoneNumber(this.state.lead.mobile?.toString() || '', 'US')?.format('RFC3966')
    };
    lead = cleanLead(lead);
    this.setState({ saving: true });
    this.props.client
      .mutate({ variables: { lead: lead }, mutation: createLead })
      .then(async result => {
        let newLeadId = result?.data?.createLead?.id;
        if (!newLeadId) throw new Error('Error creating lead information');
        if (this.props.leadId === 'newQualified') {
          await this.props.client.mutate({
            variables: {
              campaign_id: this.state.campaign.id,
              lead_id: newLeadId
            },
            mutation: getFromHopper
          });
        }
        this.props.history.push(`/leads/${newLeadId}`);
        this.loadLead(newLeadId);
        this.setState({ saving: false, isNew: false });
        this.props.reload();
        if (shouldSubmit === true) this.showCompleteModal();
      })
      .catch(err => {
        const errorMessage = getErrors(err);
        if (errorMessage === 'OUTOFMONEY') {
          this.setState({ outofmoney: true, outOfMoneyModal: true, saving: false });
        } else {
          message.error(errorMessage || 'Error creating lead, try again.');
          this.setState({ saving: false });
        }
      });
  };
  saveLead = () => {
    const lead = cleanLead(this.state.lead);
    this.setState({ saving: true });
    this.props.client
      .mutate({
        variables: {
          lead: {
            ...lead,
            phone: parsePhoneNumber(lead.phone?.toString() || '', 'US')?.format('RFC3966'),
            mobile: parsePhoneNumber(lead.mobile?.toString() || '', 'US')?.format('RFC3966')
          }
        },
        mutation: saveLead
      })
      .then(result => {
        if (result && result.data && result.data.saveLead) {
          message.success('Lead has been saved');
          this.setState({ saving: false }, () => this.loadLead(this.props.leadId));
          this.props.reload();
        } else {
          throw new Error('Error saving lead information');
        }
      })
      .catch(() => {
        this.setState({ saving: false });
        message.error('Error saving lead, try again.');
      });
  };
  removeLead = () => {
    this.props.client
      .mutate({ variables: { lead_id: this.state.lead.id }, mutation: removeLead })
      .then(result => {
        if (result.data.removeLead) {
          this.props.reload();
          message.success('This lead has been removed from this campaign and cannot be pulled in again');
          this.props.reset();
        } else {
          throw new Error();
        }
      }).catch(err => {
        message.error(getErrors(err) || 'Error removing lead, try again');
      });
  };
  confirmRemove = () => {
    Modal.confirm({
      title: 'Are you sure you want to remove this lead?',
      content: 'THIS CANNOT BE UNDONE.  You are unassigning this lead from the sales rep but also removing it from your company AND campaign.',
      onOk: async () => await this.removeLead()
    });
  }
  saveAnswers = (leadId, answers) => {
    this.props.client
      .mutate({ variables: { lead_id: leadId, answers: answers }, mutation: saveAnswers })
      .then(result => {
        if (result && result.data && result.data.saveAnswers) {
          this.loadLead(leadId);
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        // do nothing
      });
  };
  completeStep = (taskId, typeId, skipType, skipReason) => {
    this.setState({ saving: true });
    this.props.client
      .mutate({
        variables: {
          leadId: this.state.lead.id,
          taskId,
          typeId,
          skipType,
          skipReason
        },
        mutation: completeStep
      })
      .then(result => {
        if (result && result.data && result.data.completeStep) {
          message.success('Task completed');
          this.setState({ saving: false }, () => this.loadLead(this.props.leadId));
        } else {
          throw new Error('Error completing touchpoint');
        }
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Error completing touchpoint, try again');
      });
  };
  handleClick = tab => {
    if (this.props.leadId !== 'new') {
      this.setState({ tab }, () => {
        this.props.history.push(`/leads/${this.state.lead.id}/${tab}`);
      });
    }
  };
  goTo = (tab, route) => {
    this.setState({ tab: tab }, () => {
      this.props.history.push(`/leads/${this.state.lead.id}/${tab}${route}`);
    });
  };
  updateLead = (field, value) => {
    const lead = Object.assign({}, this.state.lead);
    lead[field] = value !== undefined ? value : null;
    this.setState({ lead });
  };
  updateLeadCustom = (index, value) => {
    const lead = Object.assign({}, this.state.lead);
    lead.custom_fields[index].value = value !== undefined ? value : null;
    this.setState({ lead });
  };
  updateSavedLead = lead => {
    this.setState({ savedLead: JSON.stringify(lead) });
  };
  showCalendarModal = curr => {
    if (curr.useCalendly) {
      this.setState({ calendar: 'calendly' });
    } else if (curr.useChilipiper) {
      this.setState({ calendar: 'chilipiper' });
    }
  };
  isPaused = tags => {
    if (!tags) return false;
    const hasPauseTag = tags.find(t => t.tag === 'pause');
    if (!hasPauseTag) return false;
    const pauseDate = moment(parseInt(hasPauseTag.date)).utc();
    if (!pauseDate) return false;
    const rightNow = moment().utc();
    return rightNow.isBefore(pauseDate) ? true : false;
  };
  showCancelMeetingModal = () => this.setState({ cancelMeetingModal: true });
  removeCancelMeetingModal = () => this.setState({ cancelMeetingModal: false });
  removeCalendarModal = () => this.setState({ calendar: false });
  showOutOfMoneyModal = () => this.setState({ outOfMoneyModal: true });
  removeOutOfMoneyModal = () => this.setState({ outOfMoneyModal: false });
  showCompleteModal = () => this.setState({ complete: true });
  removeCompleteModal = () => this.setState({ complete: false });
  showAcceptModal = () => this.setState({ acceptModal: true });
  removeAcceptModal = () => this.setState({ acceptModal: false });
  showDisputeModal = () => this.setState({ dispute: true });
  removeDisputeModal = () => this.setState({ dispute: false });
  showRevertToEditModal = () => this.setState({ revertToEditModal: true });
  removeRevertToEditModal = () => this.setState({ revertToEditModal: false });
  render() {
    const {
      lead,
      savedLead,
      campaign,
      integration,
      questions,
      required,
      source,
      loading,
      saving,
      removing,
      outofmoney,
      isNew
    } = this.state;
    const { info, leadId } = this.props;

    /* Setup user with phone number */
    const agent = (campaign ? campaign.agents || [] : []).find(
      a => a.agent_id === this.props.user.id
    );
    const user = { ...this.props.user };
    user.phone_number = agent?.phone_number ? prettyPhone(agent.phone_number) : null;

    const spacer = <span>&nbsp;&nbsp;&middot;&nbsp;&nbsp;</span>;
    const curr = getCurrent(
      leadId,
      lead,
      savedLead,
      campaign,
      integration,
      user,
      info,
      outofmoney,
      isNew
    );

    const isManual = lead?.tags?.filter(t => t.tag === 'manual').length > 0;
    const tab =
      this.state.tab === 'sequences' && (curr.isGenerated || isManual) ? 'info' : this.state.tab;
    const isPaused = this.isPaused(lead?.tags);
    const internal = isInternal();
    const sourceInternal = source?.company?.company_id;

    const deleteAllowedCompanies = [
      '5d653defebb2d01ba25014ad',
      '61ae81e15e744d18ad00f0d4',
      '6411db14e1fbc45e0ce7f598',
      '6462a3994f32162b90fadc08'
    ];
    const isLeadDeletable = ['in-progress', 'unassigned'].includes(lead?.stage) ? true : false;
    const canDelete = deleteAllowedCompanies.includes(user?.company) ? true : false;

    return loading ? (
      <Loading />
    ) : (
      <div id="lead-page">
        <div className="lead-banner">
          <div className="lead-header">
            <div className="lead-info">
              <Header className="lead-top" type="display" size="4">
                {getLeadName(lead)}
                <span className={`stage-tag ${lead.stage}`}>{statusMap[lead.stage || 'new']}</span>
                {curr.isAgent && (
                  <LeadTag
                    lead={lead}
                    client={this.props.client}
                    reload={() => this.loadLead(leadId)}
                  />
                )}
              </Header>
              <div className="lead-desc">
                {campaign.name}
                {spacer}
                { !internal && !sourceInternal ? (
                  <React.Fragment>
                    <span className="lead-price">{getLeadPrice(lead, campaign, user)}</span>
                    {spacer}
                  </React.Fragment>
                ) : null }
                {lead.updatedAt && moment(lead.updatedAt * 1).fromNow()}
              </div>
            </div>
            <div className="lead-actions">
              {source && (
                <React.Fragment>
                  <div className="lead-source">
                    <Tooltip
                      placement="bottom"
                      title={curr.isCompany ? source.display_name : campaign.company.name}
                    >
                      <Link
                        to={
                          curr.isCompany ? `/rep/${source.id}` : `/company/${campaign.company.id}`
                        }
                      >
                        <Avatar
                          type="agent"
                          size="tiny"
                          picture={curr.isCompany ? source.picture : campaign.company.logo}
                        />
                      </Link>
                    </Tooltip>
                  </div>
                  {curr.canActionButton && <div className="spacer" />}
                </React.Fragment>
              )}
              {curr.canActionButton && ( // Handle all of the actions
                <React.Fragment>
                  {curr.isQualified ? (
                    <ScheduleMeeting
                      lead={lead}
                      campaign={campaign}
                      integration={integration}
                      curr={curr}
                      showCalendarModal={() => this.showCalendarModal(curr)}
                    />
                  ) : null}
                  {lead.meetingScheduled &&
                  lead.calendly &&
                  (curr.submitButton || curr.revertButton) ? (
                    <Select
                      className={'leads-edit'}
                      // disabled={this.props.disabled}
                      mode="single"
                      size="small"
                      style={{ width: '150px' }}
                      placeholder={'Edit'}
                      value={this.state.editDropdownValue}
                      onChange={value => {
                        if (value === 'cancelMeeting') {
                          this.showCancelMeetingModal();
                        }
                        if (value === 'updatelead') {
                          this.showRevertToEditModal();
                        }
                        if (value === 'submitLead') {
                          this.showCompleteModal();
                        }
                      }}
                    >
                      {curr.submitButton ? (
                        <Option key={'submitLead'}>
                          {lead.stage === 'disputed' ? 'Re-submit' : 'Submit'}
                        </Option>
                      ) : null}
                      {curr.revertButton ? <Option key={'updateLead'}>Update lead</Option> : null}
                      {lead.meetingScheduled && lead.calendly ? (
                        <Option key={'cancelMeeting'}>Cancel meeting</Option>
                      ) : null}
                    </Select>
                  ) : null}
                  {isLeadDeletable && canDelete && !curr.isChanged && (
                    <Button
                      type="danger"
                      loading={removing}
                      onClick={this.confirmRemove}
                      circle
                      style={{ marginRight: '12px' }}
                    >
                      <IconTrash />
                    </Button>
                  )}
                  {curr.saveButton && (
                    <Button
                      type="primary"
                      disabled={!curr.isChanged}
                      loading={saving}
                      onClick={this.saveLead}
                    >
                      Save
                    </Button>
                  )}
                  {!(lead.meetingScheduled && lead.calendly) && curr.submitButton && (
                    <Button
                      disabled={curr.isChanged}
                      type="default"
                      loading={saving}
                      onClick={this.showCompleteModal}
                    >
                      {lead.stage === 'disputed' ? 'Re-submit' : 'Submit'}
                    </Button>
                  )}
                  {curr.reviewButtons && (
                    <span className="review-actions">
                      <Button type="primary" onClick={this.showAcceptModal}>
                        Accept
                      </Button>
                      <Button type="secondary" onClick={this.showDisputeModal} disabled={saving}>
                        Dispute
                      </Button>
                    </span>
                  )}
                  {!(lead.meetingScheduled && lead.calendly) && curr.revertButton && (
                    <Button onClick={this.showRevertToEditModal} type="secondary">
                      Edit Lead
                    </Button>
                  )}
                  {curr.outOfMoneyButton && (
                    <Button type="secondary" onClick={this.showOutOfMoneyModal}>
                      Campaign Filled
                    </Button>
                  )}
                  {curr.nextButton && (
                    <Button
                      disabled={!curr.isChanged}
                      type="primary"
                      loading={saving}
                      onClick={() => this.createLead(false)}
                    >
                      Next
                    </Button>
                  )}
                  {curr.completedButton && (
                    <Button disabled={true} type="neutral">
                      Completed
                    </Button>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>

          <div className="lead-menu">
            <Tabs onChange={this.handleClick} currentTab={tab} underline>
              <Tabs.Tab key="sequences" visible={!isManual && curr.isQualified}>
                TASKS
              </Tabs.Tab>
              <Tabs.Tab key="info">LEAD INFO</Tabs.Tab>
              <Tabs.Tab key="instructions">INSTRUCTIONS</Tabs.Tab>
              <Tabs.Tab key="questions" visible={curr.isQualified && questions.length > 0}>
                QUESTIONS
              </Tabs.Tab>
              <Tabs.Tab key="email" visible={curr.isQualified}>
                EMAIL
              </Tabs.Tab>
              <Tabs.Tab key="calls" visible={curr.isQualified}>
                CALLS
              </Tabs.Tab>
              { campaign?.company?.text_number && <Tabs.Tab key="texts">TEXTS</Tabs.Tab> }
              <Tabs.Tab key="notes">NOTES</Tabs.Tab>
              <Tabs.Tab key="activity">ACTIVITY</Tabs.Tab>
            </Tabs>
          </div>
        </div>

        <div className="lead-body-wrapper">
          <div className="lead-body">
            {tab === 'sequences' && (
              <Sequences
                lead={lead}
                campaign={campaign}
                client={this.props.client}
                goTo={this.goTo}
                isAgent={curr.isAgent}
                isCompany={curr.isCompany}
                update={this.updateLead}
                completeStep={this.completeStep}
                saveAnswers={this.saveAnswers}
                canEdit={curr.canEdit}
                user={user}
                info={this.props.info}
                showCallPopout={this.props.showCallPopout}
                showDialer={this.props.showDialer}
                integration={integration}
                showCalendarModal={() => this.showCalendarModal(curr)}
                isLeadChanged={curr.isChanged}
                showCompleteModal={this.showCompleteModal}
                isPaused={isPaused}
                reload={() => this.loadLead(leadId)}
              />
            )}
            {tab === 'info' && (
              <Info
                lead={lead}
                campaign={campaign}
                isAgent={curr.isAgent}
                isCompany={curr.isCompany}
                update={this.updateLead}
                updateCustom={this.updateLeadCustom}
                required={required}
                canEdit={curr.canEdit}
                isGenerated={curr.isGenerated}
                isNew={isNew}
              />
            )}
            {tab === 'questions' && curr.isQualified && (
              <Questions
                isAgent={curr.isAgent}
                isCompany={curr.isCompany}
                lead={lead}
                update={this.updateLead}
                questions={questions}
                campaign={campaign}
                canEdit={curr.canEdit}
              />
            )}
            {tab === 'email' && curr.isQualified && !this.props.otherId && (
              <Email
                isAgent={curr.isAgent}
                lead={lead}
                campaign={campaign}
                user={user}
                info={info}
                client={this.props.client}
                otherId={this.props.otherId}
                history={this.props.history}
                location={this.props.location}
                canEmail={curr.canEmail}
                showPopout={this.props.showCallPopout}
              />
            )}
            {tab === 'email' && curr.isQualified && this.props.otherId && (
              <Thread
                isAgent={curr.isAgent}
                lead={lead}
                campaign={campaign}
                company={campaign.company}
                user={user}
                info={this.props.info}
                client={this.props.client}
                otherId={this.props.otherId}
                history={this.props.history}
                location={this.props.location}
                canEmail={curr.canEmail}
              />
            )}
            {tab === 'calls' && curr.isQualified && (
              <Calls
                isAgent={curr.isAgent}
                campaign={campaign}
                lead={lead}
                source={source}
                user={user}
                client={this.props.client}
                otherId={this.props.otherId}
                history={this.props.history}
                location={this.props.location}
                canEdit={curr.canEdit}
                saveAnswers={this.saveAnswers}
                showCallPopout={this.props.showCallPopout}
                integration={integration}
                showCalendarModal={() => this.showCalendarModal(curr)}
              />
            )}
            {tab === 'notes' && !curr.isNew && (
              <Notes
                isAgent={curr.isAgent}
                isCompany={curr.isCompany}
                lead={lead}
                user={user}
                client={this.props.client}
                history={this.props.history}
                location={this.props.location}
                canEdit={curr.canEdit}
              />
            )}
            {tab === 'texts' && !curr.isNew && campaign?.company?.text_number && (
              <Texts
                isAgent={curr.isAgent}
                isCompany={curr.isCompany}
                lead={lead}
                user={user}
                client={this.props.client}
                history={this.props.history}
                location={this.props.location}
                canEdit={curr.canEdit}
              />
            )}
            {tab === 'activity' && !curr.isNew && (
              <Activity
                isAgent={curr.isAgent}
                isCompany={curr.isCompany}
                lead={lead}
                user={user}
                client={this.props.client}
                goTo={this.goTo}
              />
            )}
            {tab === 'instructions' && !curr.isNew && (
              <Instructions
                isAgent={curr.isAgent}
                campaign={campaign}
                client={this.props.client}
                reload={() => this.loadLead(leadId)}
                canEdit={curr.canEdit}
                role={user.role_type}
              />
            )}
          </div>
        </div>

        {/* HANDE ALL MODALS FOR THE PAGE */}
        <React.Fragment>
          {this.state.calendar === 'calendly' && curr.useCalendly ? (
            <CalendarModal
              client={this.props.client}
              user={this.props.user}
              calendly={integration && integration.calendly.url && campaign.calendly}
              lead={lead}
              reload={this.loadLead}
              campaign={campaign}
              removeModal={this.removeCalendarModal}
            />
          ) : null}

          {(this.state.calendar === 'chilipiper' && curr.useChilipiper) ||
          (this.state.cancelMeetingModal && curr.useChilipiper) ? (
            <ChilipiperModal
              client={this.props.client}
              user={this.props.user}
              lead={lead}
              reload={this.loadLead}
              campaign={campaign}
              removeModal={() => {
                this.removeCancelMeetingModal();
                this.removeCalendarModal();
              }}
            />
          ) : null}

          {this.state.cancelMeetingModal && !curr.useChilipiper ? (
            <CancelMeetingModal
              client={this.props.client}
              lead={lead}
              removeModal={this.removeCancelMeetingModal}
              reload={this.loadLead}
            />
          ) : null}

          {this.state.complete && curr.isGenerated && !curr.hasNoPaymentMethod ? (
            <StageModal
              client={this.props.client}
              lead={lead}
              removeModal={this.removeCompleteModal}
              reload={this.loadLead}
              missing={validate(lead, required, campaign.objective) || []}
            />
          ) : null}

          {this.state.complete && !curr.isGenerated && !curr.isNew && !curr.hasNoPaymentMethod ? (
            <QualifyModal
              client={this.props.client}
              lead={lead}
              removeModal={this.removeCompleteModal}
              reload={this.loadLead}
              versionTwo={true}
              meetingValid={curr.useCalendly ? lead && lead.meetingScheduled : true}
              questions={questions}
              campaign={campaign}
            />
          ) : null}

          {this.state.complete && curr.hasNoPaymentMethod ? (
            <PaymentMethodModal removeModal={this.removeCompleteModal} />
          ) : null}

          {this.state.outOfMoneyModal ? (
            <OutOfMoneyModal removeModal={this.removeOutOfMoneyModal} />
          ) : null}

          {this.state.revertToEditModal ? (
            <RevertToEditModal
              client={this.props.client}
              lead={lead}
              removeModal={this.removeRevertToEditModal}
              reload={this.loadLead}
            />
          ) : null}

          {this.state.acceptModal ? (
            <AcceptModal
              client={this.props.client}
              lead={lead}
              sourceInternal={sourceInternal}
              price={
                !lead.interest_reason || lead.interest_reason === 'interested'
                  ? formatMoney(campaign.price_per_lead)
                  : formatMoney(campaign.price_not_interested)
              }
              removeModal={this.removeAcceptModal}
              reload={this.loadLead}
            />
          ) : null}

          {this.state.dispute ? (
            <DisputeModal
              client={this.props.client}
              lead={lead}
              removeModal={this.removeDisputeModal}
              reload={this.loadLead}
            />
          ) : null}

          {this.state.call ? (
            <CallLeadModal
              user={this.props.user}
              lead={lead}
              campaign={this.state.campaign}
              removeModal={this.removeCallModal}
            />
          ) : null}
        </React.Fragment>
      </div>
    );
  }
}

const mapStateToProps = state => ({ ...state.user });

export default withRouter(connect(mapStateToProps, {})(Lead));
