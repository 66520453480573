import React from 'react';
import './Header.scss';

const Header = incomingProps => {
  const props = { ...incomingProps };
  props.className = 'seriph-header ' + (props.className || '');

  // Handle Type (light, normal (default))
  if (props.type === 'display' && props.weight) {
    props.className += ' display-' + props.weight;
    delete props.weight;
  }

  // Handle Type (display, heading (default))
  if (props.type) {
    props.className += ' header-' + props.type;
    delete props.type;
  }

  // Handle Type (sans, serif (default))
  if (props.font) {
    props.className += ' header-' + props.font;
    delete props.font;
  }

  const headerType = size => {
    switch (size) {
      case '1':
        return <h1 {...props} />;
      case '2':
        return <h2 {...props} />;
      case '3':
        return <h3 {...props} />;
      case '4':
        return <h4 {...props} />;
      case '5':
        return <h5 {...props} />;
      default:
        return <h1 {...props} />;
    }
  };

  return headerType(props.size);
};

export default Header;
