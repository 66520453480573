import React from 'react';
import { Button as AntdButton } from 'antd';
import './Button.scss';

const Button = incomingProps => {
  const props = { ...incomingProps };
  props.className = 'seriph-btn ' + (props.className || '');

  // Handle Size (tiny, small, medium, large)
  if (props.size) {
    props.className += ' btn-' + props.size;
    delete props.size;
  }

  // Handle Type (default, primary, secondary, plain, cirlce)
  if (props.type) {
    props.className += ' btn-' + props.type;
    delete props.type;
  }

  // Handle Circle
  if (props.circle) {
    props.className += ' btn-circle';
    delete props.circle;
  }

  // Handle Icon
  if (props.icon) {
    props.className += ' btn-icon icon-' + props.icon;
    delete props.icon;
  }

  if (props.selected) {
    props.className += ' btn-selected';
    delete props.selected;
  }

  return <AntdButton {...props} />;
};

export default Button;
