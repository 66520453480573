import React, { Component } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { numberWithCommas } from '_assets/js/helpers';
import { Loading } from '_seriph';
import { otherColors } from '_seriph/_constants/colors';
import './PositionChart.scss';

export default class PositionChart extends Component {
  customTooltip = data => {
    const payload = data.payload || [];
    const payloadData = payload[0]?.payload;
    return payloadData ? (
      <div className="chart-tooltip">
        <div className="tip-box">
          <div className="tip-title">{payloadData?.name}</div>
          <div className="tip-contents">
            <div className="tip-item">
              <div className="tip-name">Converted</div>
              <div className="tip-value">{numberWithCommas(payloadData.converted)}</div>
            </div>
            <div className="tip-item">
              <div className="tip-name">Connected</div>
              <div className="tip-value">{numberWithCommas(payloadData.connected)}</div>
            </div>
            <div className="tip-item">
              <div className="tip-name">Total</div>
              <div className="tip-value">{numberWithCommas(payloadData.total)}</div>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  };
  render() {
    const { data, campaign, loading } = this.props;

    return (
      <div className="chart-box" id="position-chart">
        <div className="chart-title">
          <h4>
            Positions<span>({campaign})</span>
          </h4>
          <p>Lead connections & conversions based on their position</p>
        </div>
        <div className="chart-display">
          {loading ? (
            <div className="chart-loading" style={{ minHeight: '360px' }}>
              <Loading />
            </div>
          ) : (
            <ResponsiveContainer height={360} width="99%">
              <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid stroke="#E7ECF4" fill="rgba(0,0,0,0)" strokeDasharray="3 3" />
                <XAxis dataKey="name" tickLine={false} axisLine={false} />
                <YAxis
                  tickLine={false}
                  axisLine={false}
                  dataKey="total"
                  domain={[0, 'dataMax + 5']}
                  allowDecimals={false}
                />
                <Tooltip content={this.customTooltip} />
                <Bar maxBarSize={72} dataKey="converted" stackId="a" fill={otherColors[4]} />
                <Bar maxBarSize={72} dataKey="connected" stackId="a" fill={otherColors[5]} />
                <Bar maxBarSize={72} dataKey="amount" stackId="a" fill={otherColors[10]} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    );
  }
}
