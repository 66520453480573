import React, { Component } from 'react';
import { Header, Card } from '_seriph';
import { numberWithCommas, formatPercent } from '_assets/js/helpers';
import CallTable from './_charts/CallTable/CallTable';
import CallChart from './_charts/CallChart/CallChart';
import { generateData } from './data';
import './CallStats.scss';

class CallStats extends Component {
  render() {
    const { callStats, calls } = this.props;

    const avgDuration = parseInt(callStats?.total_duration/callStats?.total_calls);
    const avgAnswered = parseFloat(callStats?.total_answered/callStats?.total_calls) * 100;
    const avgIncoming = parseFloat(callStats?.total_incoming/callStats?.total_calls) * 100;
    const avgRecorded = parseFloat(callStats?.total_recorded/callStats?.total_calls) * 100;

    const chartData = generateData(callStats);

    return (
      <div id="rep-analytics-call-stats">
        <Header size="3" font="sans">
          Call Statistics
        </Header>
        <div className="xdr-totals">
          <Card>
            <div className="descriptor">
              Call Volume
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(callStats?.total_calls)}<span>calls</span>
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              Average Duration
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(avgDuration)}<span>seconds</span>
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              Answered
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(callStats?.total_answered)}<span>/ {formatPercent(avgAnswered, 1)}</span>
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              Incoming Calls
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(callStats?.total_incoming)}<span>/ {formatPercent(avgIncoming, 1)}</span>
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              Recorded
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(callStats?.total_recorded)}<span>/ {formatPercent(avgRecorded, 1)}</span>
            </Header>
          </Card>
        </div>
        <div className="call-row">
          <div className="cs-left">
            <CallTable calls={calls} />
          </div>
          <div className="cs-right">
            <CallChart data={chartData.calls} />
          </div>
        </div>
      </div>
    );
  }
}

export default CallStats;
