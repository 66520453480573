import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getErrors } from '_assets/js/helpers';
import { Avatar, Loading, message } from '_seriph';
import { getCallTasks } from '_graphql/queries/call';
import { getCallList } from '../../_helpers/setup';
import './SelectList.scss';

class SelectList extends Component {
  state = {
    loading: true,
    campaigns: [],
    leads: []
  }
  componentDidMount = () => {
    this.loadCalls();
  };
  loadCalls = () => {
    this.props.client
      .query({ variables: { campaignId: this.props.campaignId || 'all' }, query: getCallTasks })
      .then(result => {
        if (result?.data?.getCallTasks) {
          let { campaigns, leads, agent } = result.data.getCallTasks;
          campaigns = this.getCampaigns(campaigns, leads);
          campaigns = campaigns.map(c => {
            c.call_count = leads.filter(l => l.campaign_id === c.id).length;
            return c;
          });

          if (campaigns.length === 1) {
            if (this.props.leadId) leads = leads.filter(l => l.id === this.props.leadId);
            this.setState({ 
              leads, 
              campaigns,
              agent
            }, () => {
              this.gatherCallList(campaigns[0].id);
            });
          } else {
            this.setState({ 
              leads, 
              campaigns,
              agent,
              loading: false 
            });
          }
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        message.error(getErrors(err) || 'Could not load calls, try again');
      });
  };
  getCampaigns = (campaigns, leads) => {
    const uniqueCampaignIds = new Set(leads.map(l => l.campaign_id));
    return campaigns.filter(c => uniqueCampaignIds.has(c.id))
  };
  gatherCallList = campaignId => {
    const campaign = this.state.campaigns.find(c => c.id === campaignId);
    const leads = this.state.leads.filter(l => l.campaign_id === campaign.id);
    const listData = getCallList(leads);
    if (this.props.setList) this.props.setList(listData.list, listData.phoneOptions, campaign, this.state.agent)
  };
  render() {
    const { campaigns, loading } = this.state;

    return loading ? <Loading /> : (
      <div id="sl-campaigns">
        <div className="select-title">Select a campaign to dial</div>
        { campaigns.sort((a, b) => b.call_count - a.call_count).map(c => (
          <div 
            className="slc-card"
            onClick={() => this.gatherCallList(c.id)}
            key={'sl-'+c.id}
          >
            <Avatar type="company" size="mini" picture={c?.company?.logo} />
            <div className="slc-info">
              <h3>{c.name}</h3>
              <p>{c.call_count} leads(s) to call</p>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(SelectList));
