import React, { Component } from 'react';
import { Table, Empty, message } from 'antd';
import { acknowledgeFeedback } from '../_graphql/mutations';
import { adminFeedback } from '../_graphql/queries';
import { getColumns } from './Columns';
import './Feedback.scss';

export default class Feedback extends Component {
  state = {
    data: [],
    selectedRowKeys: [],
    pagination: { pageSize: 20 },
    loading: false,
    selected: null,
    searchText: '',
    reviewCandidate: false
  };
  componentDidMount() {
    this.fetch({ results: 20, page: 1 });
  }
  fetch = (params = {}) => {
    this.setState({ loading: true });
    this.props.client
      .query({
        variables: {
          name: this.state.searchText,
          ...params
        },
        query: adminFeedback
      })
      .then(result => {
        if (result && result.data) {
          const applications = result.data.adminFeedback.data || [];
          const pagination = { ...this.state.pagination };
          pagination.total = result.data.adminFeedback.pageTotal;
          pagination.totalAll = result.data.adminFeedback.total;
          this.setState({
            loading: false,
            data: applications,
            pagination
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(() => this.setState({ loading: false }));
  };
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      name: this.state.searchText,
      ...filters
    });
  };
  acknowledgeFeedback = id => {
    this.props.client
      .mutate({ variables: { feedback_id: id }, mutation: acknowledgeFeedback })
      .then(result => {
        if (result && result.data && result.data.acknowledgeFeedback) {
          message.success('Feedback acknowledged sent');
          this.fetch({ results: 20, page: 1 });
        } else {
          throw new Error('Could not acknowledge feedback, try again');
        }
      })
      .catch(err => message.error(err.message));
  };
  render() {
    const { pagination, data, loading } = this.state;
    const total = pagination.totalAll || 0;
    const totalFiltered = pagination.total || 0;
    const columns = getColumns(this.acknowledgeFeedback);

    return (
      <div id="admin-feedback">
        <div className="admin-header">
          Feedback{' '}
          <div>
            Showing {totalFiltered} of {total}
          </div>
        </div>
        <div className="admin-table">
          <div className="table-content">
            <Table
              className="sellx-table bordered"
              bordered
              rowSelection={null}
              columns={columns}
              rowKey={record => record.id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              locale={{ emptyText: <Empty description="No reps" /> }}
            />
          </div>
        </div>
      </div>
    );
  }
}
