import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faFile,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFileImage,
  faFileAudio,
  faFileVideo,
  faCircleNotch
} from '@fortawesome/free-solid-svg-icons';
import './File.scss';

function formatBytes(a) {
  if (0 === a) return '0 Bytes';
  var c = 1024,
    d = 0,
    e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    f = Math.floor(Math.log(a) / Math.log(c));
  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f];
}

const allTypes = {
  PDF: <FontAwesomeIcon icon={faFilePdf} />,
  Document: <FontAwesomeIcon icon={faFileWord} />,
  Spreadsheet: <FontAwesomeIcon icon={faFileExcel} />,
  Image: <FontAwesomeIcon icon={faFileImage} />,
  Audio: <FontAwesomeIcon icon={faFileAudio} />,
  Video: <FontAwesomeIcon icon={faFileVideo} />,
  Unknown: <FontAwesomeIcon icon={faFile} />
};

export const File = props => {
  const f = props.data;
  return f.downloading ? (
    <div className="file-box">
      <div className="file">
        <FontAwesomeIcon icon={faCircleNotch} spin />
        <div className="info">
          <h5 title={f.name}>{f.name}</h5>
          <div>{formatBytes(f.size)}</div>
        </div>
      </div>
    </div>
  ) : (
    <div className="file-box">
      <div className="file">
        {allTypes[f.fileType]}
        <div className="info">
          <h5 title={f.name}>{f.name}</h5>
          <div>{formatBytes(f.size)}</div>
        </div>
        <div className="remove" onClick={props.delete}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </div>
      </div>
    </div>
  );
};
