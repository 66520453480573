import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { message } from 'antd';
import { campaignStats } from '_graphql/queries/stats';
import { generateRange } from '_constants/datepicker';
import { generateData } from './_data/charts';
import CampaignDropdown from '_shared/CampaignDropdown/CampaignDropdown';
import DateRange from '_shared/DateRange/DateRange';
import StatusChart from './_components/StatusChart/StatusChart';
import QualifyCharts from './_components/QualifyCharts/QualifyCharts';
import { Header } from '_seriph';

import './Dashboard.scss';

const generateCampaigns = campaign => {
  const isStatus = ['active', 'inactive', 'completed', 'all'].includes(campaign);
  if (isStatus) {
    return { status: campaign };
  } else {
    return { campaign_id: campaign };
  }
};

class Dashboard extends Component {
  state = {
    loading: true,
    data: generateData({ days: [] }),

    campaign: 'all',
    range: 'last7'
  };
  componentDidMount = () => {
    const params = queryString.parse(this.props.location.search);
    this.setState({ campaign: params.campaign ? params.campaign : 'all' }, () => {
      this.getStats();
    });
  };
  componentDidUpdate(prevProps) {
    const oldParams = queryString.parse(prevProps.location.search) || {};
    const newParams = queryString.parse(this.props.location.search) || {};
    if (oldParams.campaign !== newParams.campaign && newParams.campaign) {
      this.setState({ campaign: newParams.campaign }, this.getStats);
    }
  }
  getStats = () => {
    if (!this.state.loading) this.setState({ loading: true });
    const range = generateRange(this.state.range);
    const selected = generateCampaigns(this.state.campaign);
    this.props.client
      .query({ variables: { ...range, ...selected }, query: campaignStats })
      .then(result => {
        if (result && result.data && result.data.campaignStats) {
          const data = result.data.campaignStats || {};
          this.setState({ data: generateData(data), total: data.days.length || 0 });
        }
        this.setState({ loading: false });
      })
      .catch(() => {
        message.error('Could not load your statistics, try again');
        this.setState({ loading: false });
      });
  };
  selectCampaign = campaign => {
    const params = queryString.parse(this.props.location.search);
    if (params.campaign) this.props.history.push('/');
    this.setState({ campaign }, this.getStats);
  };
  selectRange = range => this.setState({ range }, this.getStats);
  UNSAFE_componentWillMount = () => window.scrollTo({ top: 0 });
  render() {
    const { data, range, loading, campaign } = this.state;
    return (
      <div id="campaign-dashboard">
        <div className="seriph-header-wrapper">
          <Header type="display" weight="600" size="4">
            Analytics
          </Header>
        </div>
        <div className="dashboard-actions">
          <span className="no-left">Show</span>
          <CampaignDropdown
            allowAll={true}
            campaign={campaign}
            campaigns={this.props.campaigns}
            selectCampaign={this.selectCampaign}
          />
          <span>within</span>
          <DateRange range={range} selectRange={this.selectRange} />
        </div>

        <StatusChart data={data.vs} totals={data.totals} loading={loading} />
        <QualifyCharts
          meetings={data.meetings}
          emails={data.emails}
          calls={data.calls}
          totals={data.totals}
        />
      </div>
    );
  }
}

export default withRouter(Dashboard);
