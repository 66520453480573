import React, { Component } from 'react';
import { Header, Modal, Button, Input } from '_seriph';
import { touchpointTypes } from '_constants/campaigns';
import './NewTouchpoint.scss';

export default class NewTouchpoint extends Component {
  render() {
    const { name, type } = this.props.touchpoint;

    return (
      <div id="create-touchpoint">
        <Modal.Close onClick={this.props.hideModal} />

        <Header size="4" font="sans" className="create-header">
          New template
        </Header>

        <div className="create-content">
          <Input
            value={name}
            label="Template name"
            onChange={e => this.props.update('name', e.target.value)}
            placeholder="Template name..."
            autoFocus
          />

          <div className="type">
            <label>Touchpoint type</label>
            <div className="types">
              {Object.keys(touchpointTypes).map(key => {
                const touchType = touchpointTypes[key];
                const style = {
                  background: touchType.color,
                  color: touchType.static ? '#FFFFFF' : '#090E15'
                };
                return key === 'wait' ? null : (
                  <div
                    key={key}
                    className={`type-opt ${type === key ? 'active' : ''}`}
                    onClick={() => this.props.update('type', key)}
                  >
                    <div style={style}>{touchType.icon}</div>
                    {touchType.name}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <Modal.Actions align="right">
          <Button type="default" onClick={() => this.props.hideModal()}>
            Cancel
          </Button>
          <Button
            type="primary"
            disabled={!name || !type}
            onClick={() => this.props.changePage('edit')}
          >
            Create
          </Button>
        </Modal.Actions>
      </div>
    );
  }
}
