import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTeamMembers, getTeamInvites } from '_graphql/queries/company';
import { updateTeamPermission, removeTeamMember, inviteMember } from '_graphql/mutations/company';
import { getErrors } from '_assets/js/helpers';
import InviteTeamMember from './_modals/InviteTeamMember/InviteTeamMember';
import RoleBreakdown from './_modals/RoleBreakdown/RoleBreakdown';
import SeatInfoModal from 'App/Company/_modals/SeatInfoModal/SeatInfoModal';
import { getTeamColumns } from './_data/TeamColumns';
import { getInviteColumns } from './_data/InviteColumns';
import { Card, Loading, Header, Button, Modal, Table, message } from '_seriph';
import { IconInfoCircle } from '@tabler/icons-react';
import './Team.scss';

class Team extends Component {
  state = {
    loadingMembers: true,
    loadingInvites: true,
    team: [],
    invites: [],
    inviteModal: false,
    roleModal: false,
    resendLoading: false,
  };
  inputs = {};
  componentDidMount = () => {
    this.loadTeamMembers();
    this.loadTeamInvites();
  };
  loadTeamMembers = () => {
    this.props.client
      .query({ query: getTeamMembers })
      .then(result => {
        if (result && result.data && result.data.getTeamMembers) {
          this.setState({ loadingMembers: false, team: result.data.getTeamMembers || [] });
        } else {
          throw new Error('Could not load team, try again');
        }
      })
      .catch(err => {
        this.setState({ loadingMembers: true });
        message.error(getErrors(err) || 'Could not load team, try again');
      });
  };
  loadTeamInvites = () => {
    this.props.client
      .query({ query: getTeamInvites })
      .then(result => {
        if (result && result.data && result.data.getTeamInvites) {
          this.setState({ loadingInvites: false, invites: result.data.getTeamInvites || [] });
        } else {
          throw new Error('Could not load invites, try again');
        }
      })
      .catch(err => {
        this.setState({ loadingInvites: true });
        message.error(getErrors(err) || 'Could not load invites, try again');
      });
  };
  updatePermissionType = (type, id, value) => {
    this.props.client
      .mutate({
        variables: { type, id, value },
        mutation: updateTeamPermission
      })
      .then(result => {
        if (result && result.data && result.data.updateTeamPermission) {
          message.success('Permissions successfully updated');
          if (type === 'employee') this.loadTeamMembers();
          if (type === 'invite') this.loadTeamInvites();
          if (this.props.reloadCompany) this.props.reloadCompany();
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not update permissions, try again');
      });
  };
  removeMember = (type, id) => {
    this.props.client
      .mutate({
        variables: { type, id },
        mutation: removeTeamMember
      })
      .then(result => {
        if (result && result.data && result.data.removeTeamMember) {
          if (type === 'employee') {
            message.success('Member successfully removed');
            this.loadTeamMembers();
          }
          if (type === 'invite') {
            message.success('Invite successfully removed');
            this.loadTeamInvites();
          }
          if (this.props.reloadCompany) this.props.reloadCompany();
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not remove invite/member, try again');
      });
  };
  confirmRemoveMember = (type, id) => {
    Modal.confirm({
      title: `Are you sure you want to remove this ${type === 'employee' ? 'member' : 'invite'}?`,
      content:
        type === 'employee'
          ? 'They can be added back at any time without having to sign up again'
          : 'A new invite can be created at a later time if necessary',
      onOk: () => this.removeMember(type, id),
      onCancel() {}
    });
  };
  resendInvite = email => {
    this.setState({ resendLoading: email });
    this.props.client
      .mutate({
        variables: { email, role: 'Member', resend: true },
        mutation: inviteMember
      })
      .then(result => {
        if (result && result.data && result.data.inviteMember) {
          message.success('An invite reminder was sent to ' + email);
          this.setState({ resendLoading: false });
        } else {
          throw new Error('Could not send an invite reminder, try again');
        }
      })
      .catch(err => {
        this.setState({ resendLoading: false });
        message.error(getErrors(err) || 'Could not send an invite reminder, try again');
      });
  };
  copyToClipboard = i => {
    this.inputs[i].select();
    document.execCommand('copy');
    message.success('Copied to clipboard');
  };
  showRoleModal = () => this.setState({ roleModal: true });
  removeRoleModal = () => this.setState({ roleModal: false });
  showInviteModal = () => this.setState({ inviteModal: true });
  removeInviteModal = () => this.setState({ inviteModal: false });
  showSeatInfoModal = () => this.setState({ seatInfoModal: true });
  removeSeatInfoModal = () => this.setState({ seatInfoModal: false });
  render() {
    const { loadingMembers, loadingInvites, team, invites, resendLoading } = this.state;
    const { user, company } = this.props;

    const seatsLeft = company?.seats_left || 0;
    const loading = loadingMembers || loadingInvites ? true : false;

    const teamColumns = getTeamColumns(
      company,
      user,
      this.updatePermissionType,
      this.confirmRemoveMember
    );
    const inviteColumns = getInviteColumns(
      resendLoading,
      this.resendInvite,
      this.confirmRemoveMember,
      this.inputs,
      this.copyToClipboard
    );

    const invitesNew = invites.filter(i => !i.used);

    return loading || !company ? (
      <Loading />
    ) : (
      <div id="company-settings-team">
        <Card>
          <Header size="2" type="sans">
            Team Management
          </Header>
          <div className="settings-subtitle">
            Invite team members to help manage {user.company_name}’s account
          </div>
          <Table
            className="team-table"
            striped={true}
            columns={teamColumns}
            rowKey={record => record.id}
            dataSource={team.sort(t => {
              const isOwner = company && company.owner_id === t.id ? true : false;
              t.permission_type !== 'Removed' || isOwner ? -1 : 1
            })}
            pagination={false}
            locale={{ emptyText: 'No team members found' }}
          />

          <div className="invite-actions">
            <span onClick={this.showSeatInfoModal}>
              <IconInfoCircle />{seatsLeft} seat{seatsLeft === 1 ? '' : 's'} left
            </span>
            <Button type="default" onClick={this.showRoleModal}>
              Role Permissions
            </Button>
            {seatsLeft > 0 ? (
              <Button type="primary" onClick={this.showInviteModal}>
                + Invite member
              </Button>
            ) : null}
          </div>
        </Card>

        <Card id="team-invites">
          <Header size="2" type="sans">
            Invited
          </Header>
          <Table
            className="team-table"
            striped={true}
            columns={inviteColumns}
            rowKey={record => record.id}
            dataSource={invitesNew}
            pagination={false}
            locale={{ emptyText: 'No open invites found, click + Invite member to add a new team member' }}
          />
        </Card>

        {this.state.roleModal ? <RoleBreakdown removeModal={this.removeRoleModal} /> : null}

        {this.state.inviteModal ? (
          <InviteTeamMember
            client={this.props.client}
            removeModal={this.removeInviteModal}
            reload={this.loadTeamInvites}
          />
        ) : null}
        {this.state.seatInfoModal ? (
          <SeatInfoModal
            client={this.props.client}
            company={this.props.company}
            removeModal={this.removeSeatInfoModal}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(Team));
