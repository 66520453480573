import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import { Device } from 'twilio-client';
import moment from 'moment';
import axios from 'axios';
import { prettyPhone } from '_assets/js/helpers';
import './CallLeadModal.scss';

const apiPath = process.env.REACT_APP_API_PATH;

export default class CallLeadModal extends Component {
  state = {
    visible: true,
    ready: false,
    callSid: null,
    time: 0,
    start: 0
  };
  callSid = null;
  hideModal = () => {
    Device.disconnectAll();
    this.setState({ visible: false });
  };
  componentDidMount = () => {
    // Get Twilio Device Token
    axios
      .get(`${apiPath}/twilio/token`, {
        headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') }
      })
      .then(result => {
        const { data } = result;
        if (data && data.token) {
          Device.setup(data.token);
        }
      })
      .catch(err => console.log(err));

    // Listen to connection to get CallSID
    Device.on('connect', connection => {
      console.log('Device connected', connection);
      this.setState({ callSid: connection.mediaStream.callSid }, () => {
        this.startTimer();
      });
    });

    // Listen to disconnect to get Other Id
    Device.disconnect(() => {
      this.setState({ callSid: null });
      this.stopTimer();
      console.log('Device has been disconnected');
    });

    Device.ready(() => {
      this.setState({ ready: true });
      console.log('Device Ready');
    });
  };
  makeCall = () => {
    if (!this.state.status && !this.state.callSid) {
      const number = this.props.lead.phone || this.props.lead.mobile;
      Device.connect({
        number,
        campaignId: this.props.campaign.id,
        leadId: this.props.lead.id,
        leadType: this.props.lead.type,
        userId: this.props.user.id,
        userRole: this.props.user.role_type
      });
    }
  };
  endCall = () => Device.disconnectAll();
  startTimer() {
    this.setState({ time: 0, start: Date.now() }, () => {
      this.timer = setInterval(() => {
        this.setState({ time: Date.now() - this.state.start });
      }, 1000);
    });
  }
  stopTimer() {
    this.setState({ time: 0, start: 0 });
    clearInterval(this.timer);
  }
  componentWillUnmount = () => {
    Device.disconnectAll();
    Device.destroy();
    clearInterval(this.timer);
  };
  render() {
    const { lead } = this.props;
    const firstName = lead.first_name || 'X';
    const lastName = lead.last_name || 'X';
    const agent = (this.props.campaign.agents || []).find(a => a.agent_id === this.props.user.id);
    return (
      <Modal
        wrapClassName="call-lead-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        width={400}
        closable={false}
        maskClosable={false}
      >
        <div className="cl-title">
          {this.state.callSid
            ? moment.utc(this.state.time).format('mm:ss')
            : 'Are you sure you want to call?'}
        </div>

        <div className="cl-lead">
          <div className="lead">
            <div className="lead-photo">
              {lead.photo_url ? (
                <img src={lead.photo_url} alt="" />
              ) : (
                <span>
                  {firstName.charAt(0).toUpperCase()}
                  {lastName.charAt(0).toUpperCase()}
                </span>
              )}
            </div>
            <div className="lead-full">
              <h2>
                {lead.salutation} {lead.first_name} {lead.last_name}
              </h2>
              <h5>{lead.title}</h5>
            </div>
          </div>
        </div>

        {this.state.callSid ? (
          <div className="on-call">
            <Button className="btn btn-danger btn-large" onClick={this.endCall}>
              Hang Up
            </Button>
          </div>
        ) : (
          <div className="cl-actions">
            <Button className="btn btn-neutral btn-large" onClick={this.hideModal}>
              No, cancel
            </Button>
            <Button
              disabled={!this.state.ready}
              className="btn btn-success btn-large"
              onClick={this.makeCall}
            >
              Yes, call
            </Button>
          </div>
        )}

        <div className="from">
          <b>Your phone number:</b> <span>{prettyPhone(agent.phone_number)}</span>
        </div>
      </Modal>
    );
  }
}
