import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Quill from '_forms/Quill/Quill';
import { Card } from '_seriph';

import './Instructions.scss';

class Instructions extends Component {
  componentDidMount = () => {
    if (this.props.save) this.props.save();
  };
  render() {
    const { campaign, update, actions, fromReview } = this.props;

    return (
      <Card id="wizard-quill" className="wz-card">
        <div className="wz-container sx-form">
          {/* HEADER */}
          <div className="wz-card-header">
            <h3>Are there additional guidelines reps should follow?</h3>
            <h5>Reps will be required to review this infomation before getting started.</h5>
          </div>

          {/* CONTENT */}
          <div className={fromReview && !campaign.guidelines ? 'field invalid' : 'field'}>
            <Quill
              value={campaign.guidelines}
              onChange={val => update('guidelines', val)}
              placeholder="Write any important instructions that the sales reps need to know before or during the campaign.."
            />
          </div>
        </div>

        {/* ACTIONS */}
        {actions}
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(Instructions));
