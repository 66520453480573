import React, { Component } from 'react';
import { Route, withRouter, Link, Switch } from 'react-router-dom';
import { Layout, Menu, Dropdown, Tooltip } from 'antd';
import { connect } from 'react-redux';
import Agents from './Agents/Agents';
import AgentApplications from './AgentApplications/AgentApplications';
import AgentOnboarding from './AgentOnboarding/AgentOnboarding';
import Analytics from './Analytics/Analytics';
import Campaigns from './Campaigns/Campaigns';
import Companies from './Companies/Companies';
import CompanyApplications from './CompanyApplications/CompanyApplications';
import CompanyApplicationsV2 from './CompanyApplicationsV2/CompanyApplicationsV2';
import CompanyOnboarding from './CompanyOnboarding/CompanyOnboarding';
import ReviewCampaigns from './ReviewCampaigns/ReviewCampaigns';
import ManageUsers from './ManageUsers/ManageUsers';
import Feedback from './Feedback/Feedback';
import Templates from './Templates/Templates';
import logo from '../../_assets/img/logo.svg';
import avatar from '_assets/img/avatar.png';
import Avatar from '_styleguide/Avatar/Avatar';
import Profile from 'App/Company/Profile/Profile';
import { logoutUser } from 'Store/user/user_actions';
import {
  IconSend,
  IconUsers,
  IconLicense,
  IconFilter,
  IconRocket,
  IconListCheck,
  IconBuilding,
  // IconFolders,
  IconChartArcs3
} from '@tabler/icons-react';
import ChangePasswordModal from 'src/App/Company/Settings/_components/Account/_modals/ChangePassword/ChangePassword';
import { hasAccess } from '_helpers/permissions';
import './Admin.scss';

const { Content, Header } = Layout;

class Admin extends Component {
  state = {
    small: true,
    showPasswordModal: false
  };
  componentDidMount = () => {
    const role = this.props.user?.admin?.role;
    if (role === 'company_manager') {
      this.props.history.push('/campaigns');
    } else if (role === 'rep_onboarding') {
      this.props.history.push('/sales-reps');
    } else if (role === 'company_onboarding') {
      this.props.history.push('/companies');
    }
  };
  showPasswordModal = () => {
    this.setState({ showPasswordModal: true });
  };
  hidePasswordModal = () => {
    this.setState({ showPasswordModal: false });
  };
  logoutCallback = () => this.props.history.push('/');
  makeSmall = () => this.setState({ small: true });

  render() {
    let currentPage = [];

    if (this.props.location.pathname === '/') currentPage = ['feedback'];
    if (this.props.location.pathname === '/sales-reps') currentPage = ['sales-reps'];
    if (this.props.location.pathname === '/applications/reps') currentPage = ['agentapplications'];
    if (this.props.location.pathname === '/onboard/reps') currentPage = ['agentonboarding'];
    if (this.props.location.pathname === '/companies') currentPage = ['companies'];
    if (this.props.location.pathname === '/campaigns') currentPage = ['campaigns'];
    if (this.props.location.pathname === '/leaderboard') currentPage = ['leaderboard'];
    if (this.props.location.pathname === '/review') currentPage = ['review'];
    if (this.props.location.pathname === '/users') currentPage = ['users'];
    if (this.props.location.pathname === '/applications/company')
      currentPage = ['companyapplications'];
    if (this.props.location.pathname === '/company/applications')
      currentPage = ['companyapplicationsv2'];
    if (this.props.location.pathname === '/onboard/company') currentPage = ['companyonboarding'];
    if (this.props.location.pathname === '/sequence/templates') currentPage = ['sequencetemplates'];

    const userMenu = (
      <Menu>
        <Menu.Item key="update-password" onClick={() => this.showPasswordModal()}>
          Update Password
        </Menu.Item>
        {hasAccess(['owner', 'super']) && (
          <Menu.Item key="manage-users">
            <Link to="/users">Manage Users</Link>
          </Menu.Item>
        )}
        <Menu.Item key="sign-out" onClick={() => this.props.logoutUser(this.logoutCallback)}>
          Sign Out
        </Menu.Item>
      </Menu>
    );

    return (
      <Layout id="admin" className={this.state.small ? 'sx-theme small-menu' : 'sx-theme'}>
        <Header
          id="admin-menu"
          onMouseEnter={() => this.setState({ small: false })}
          onMouseLeave={() => this.setState({ small: true })}
        >
          <div className="logo">
            <Link to="/">
              <div>
                <img src={logo} alt="Logo" />
              </div>
              <div>sellx</div>
            </Link>
          </div>
          {this.state.showPasswordModal ? (
            <ChangePasswordModal client={this.props.client} removeModal={this.hidePasswordModal} />
          ) : null}
          <Menu mode="inline" selectedKeys={currentPage} className="main-menu">
            {hasAccess(['owner', 'super']) && (
              <Menu.Item key="feedback">
                <Tooltip
                  placement="right"
                  title={this.state.small ? 'Feedback' : ''}
                  overlayClassName="menu-tt"
                >
                  <Link to="/">
                    <span className="lfa">
                      <IconSend size={22} stroke={1.75} />
                    </span>
                    Feedback
                  </Link>
                </Tooltip>
              </Menu.Item>
            )}

            { hasAccess(['owner', 'super', 'company_manager', 'rep_onboarding', 'company_onboarding']) && (
              <Menu.Item key="analytics">
                <Tooltip
                  placement="right"
                  title={this.state.small ? 'Analytics' : ''}
                  overlayClassName="menu-tt"
                >
                  <Link to="/analytics">
                    <span className="lfa">
                      <IconChartArcs3 size={22} stroke={1.75} />
                    </span>
                    Analytics
                  </Link>
                </Tooltip>
              </Menu.Item>
            )}
            {hasAccess(['owner', 'super', 'company_manager']) && (
              <Menu.Item key="campaigns">
                <Tooltip
                  placement="right"
                  title={this.state.small ? 'Live Campaigns' : ''}
                  overlayClassName="menu-tt"
                >
                  <Link to="/campaigns">
                    <span className="lfa">
                      <IconRocket size={22} stroke={1.75} />
                    </span>
                    Live Campaigns
                  </Link>
                </Tooltip>
              </Menu.Item>
            )}
            {hasAccess(['owner', 'super', 'company_manager']) && (
              <Menu.Item key="review">
                <Tooltip
                  placement="right"
                  title={this.state.small ? 'Review Campaigns' : ''}
                  overlayClassName="menu-tt"
                >
                  <Link to="/review">
                    <span className="lfa">
                      <IconListCheck size={22} stroke={1.75} />
                    </span>
                    Review Campaigns
                  </Link>
                </Tooltip>
              </Menu.Item>
            )}
            {hasAccess(['owner', 'super', 'company_manager', 'rep_onboarding']) && (
              <Menu.Item key="reps">
                <Tooltip
                  placement="right"
                  title={this.state.small ? 'Sales Reps' : ''}
                  overlayClassName="menu-tt"
                >
                  <Link to="/sales-reps">
                    <span className="lfa">
                      <IconUsers size={22} stroke={1.75} />
                    </span>
                    Sales Reps
                  </Link>
                </Tooltip>
              </Menu.Item>
            )}
            {hasAccess(['owner', 'super', 'rep_onboarding']) && (
              <Menu.Item key="agentapplications">
                <Tooltip
                  placement="right"
                  title={this.state.small ? 'Rep Applications' : ''}
                  overlayClassName="menu-tt"
                >
                  <Link to="/applications/reps">
                    <span className="lfa">
                      <IconLicense size={22} stroke={1.75} />
                    </span>
                    Rep Applications
                  </Link>
                </Tooltip>
              </Menu.Item>
            )}
            {hasAccess(['owner', 'super', 'rep_onboarding']) && (
              <Menu.Item key="agentonboarding">
                <Tooltip
                  placement="right"
                  title={this.state.small ? 'Rep Onboarding' : ''}
                  overlayClassName="menu-tt"
                >
                  <Link to="/onboard/reps">
                    <span className="lfa">
                      <IconFilter size={22} stroke={1.75} />
                    </span>
                    Rep Onboarding
                  </Link>
                </Tooltip>
              </Menu.Item>
            )}
            {hasAccess(['owner', 'super', 'company_manager', 'company_onboarding']) && (
              <Menu.Item key="companies">
                <Tooltip
                  placement="right"
                  title={this.state.small ? 'Companies' : ''}
                  overlayClassName="menu-tt"
                >
                  <Link to="/companies">
                    <span className="lfa">
                      <IconBuilding size={22} stroke={1.75} />
                    </span>
                    Companies
                  </Link>
                </Tooltip>
              </Menu.Item>
            )}
            {hasAccess(['owner', 'super', 'company_onboarding']) && false && (
              <Menu.Item key="companyapplicationsv2">
                <Tooltip
                  placement="right"
                  title={this.state.small ? 'Company Applications V2' : ''}
                  overlayClassName="menu-tt"
                >
                  <Link to="/company/applications">
                    <span className="lfa">
                      <IconLicense size={22} stroke={1.75} />
                    </span>
                    Comp. Applications V2
                  </Link>
                </Tooltip>
              </Menu.Item>
            )}
            {hasAccess(['owner', 'super', 'company_onboarding']) && (
              <Menu.Item key="companyapplications">
                <Tooltip
                  placement="right"
                  title={this.state.small ? 'Company Applications' : ''}
                  overlayClassName="menu-tt"
                >
                  <Link to="/applications/company">
                    <span className="lfa">
                      <IconLicense size={22} stroke={1.75} />
                    </span>
                    Comp. Applications
                  </Link>
                </Tooltip>
              </Menu.Item>
            )}
            {hasAccess(['owner', 'super', 'company_onboarding']) && (
              <Menu.Item key="companyonboarding">
                <Tooltip
                  placement="right"
                  title={this.state.small ? 'Company Onboarding' : ''}
                  overlayClassName="menu-tt"
                >
                  <Link to="/onboard/company">
                    <span className="lfa">
                      <IconRocket size={22} stroke={1.75} />
                    </span>
                    Comp. Onboarding
                  </Link>
                </Tooltip>
              </Menu.Item>
            )}
          </Menu>

          <div className="actions-menu">
            <Dropdown
              overlayClassName={this.state.small ? 'admin-dropdown small' : 'admin-dropdown'}
              overlay={userMenu}
              trigger={['click']}
              placement="topLeft"
            >
              <div className="menu-user">
                <Avatar type="company" size="tiny" picture={avatar} />
                <div className="menu-av">
                  <div className="menu-user-name">{this.props.user?.admin?.display_name}</div>
                  <div className="menu-user-company">Admin</div>
                </div>
              </div>
            </Dropdown>
          </div>
        </Header>
        <Content className="admin-body">
          <Switch>
            <Route
              exact
              path="/analytics/:page(leaderboard|meetings|tasks|scorecard|companystats|campaignstats|repstats|trigger)?"
              render={i => (
                <Analytics match={i.match} page={i.match.params.page} client={this.props.client} />
              )}
            />
            <Route
              exact
              path="/"
              render={i => <Feedback match={i.match} client={this.props.client} />}
            />
            <Route
              exact
              path="/sales-reps"
              render={i => <Agents match={i.match} client={this.props.client} />}
            />
            <Route
              exact
              path="/applications/reps"
              render={i => <AgentApplications match={i.match} client={this.props.client} />}
            />
            <Route
              exact
              path="/onboard/reps"
              render={i => <AgentOnboarding match={i.match} client={this.props.client} />}
            />
            <Route
              exact
              path="/campaigns"
              render={i => <Campaigns match={i.match} client={this.props.client} />}
            />
            <Route
              exact
              path="/review"
              render={i => <ReviewCampaigns match={i.match} client={this.props.client} />}
            />
            <Route
              exact
              path="/users"
              render={i => <ManageUsers match={i.match} client={this.props.client} />}
            />
            <Route
              exact
              path="/companies"
              render={i => <Companies match={i.match} client={this.props.client} />}
            />
            <Route
              exact
              path="/applications/company"
              render={i => <CompanyApplications match={i.match} client={this.props.client} />}
            />
            <Route
              exact
              path="/company/applications"
              render={i => <CompanyApplicationsV2 match={i.match} client={this.props.client} />}
            />
            <Route
              exact
              path="/onboard/company"
              render={i => <CompanyOnboarding match={i.match} client={this.props.client} />}
            />
            <Route
              exact
              path="/company/:id"
              render={i => (
                <Profile match={i.match} client={this.props.client} companyId={i.match.params.id} />
              )}
            />
            <Route
              exact
              path="/sequence/templates"
              render={i => (
                <Templates
                  match={i.match}
                  client={this.props.client}
                  companyId={i.match.params.id}
                />
              )}
            />
          </Switch>
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, { logoutUser })(Admin));
