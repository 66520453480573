import moment from 'moment';
import { leadColors } from '_seriph/_constants/colors';

export const generateData = data => {
  const { days } = data;

  const chartData = {
    totals: {},
    meetings: [],
    calls: [],
    emails: [],
    earnings: [],
    vs: [
      {
        name: 'Created',
        settings: { stroke: leadColors['created'], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Review',
        settings: { stroke: leadColors['review'], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Generated',
        settings: { stroke: leadColors['generated'], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Qualified',
        settings: { stroke: leadColors['qualified'], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Not Interested',
        settings: { stroke: leadColors['not-interested'], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Disputed',
        settings: { stroke: leadColors['disputed'], strokeWidth: 3, dot: false },
        data: []
      }
    ]
  };

  if (days && days.length > 0) {
    const totals = { ...days[0].daily };
    days.forEach((d, i) => {
      /* SUM UP TOTALS */
      if (i > 0) Object.keys(d.daily).forEach(s => (totals[s] += d.daily[s]));

      /* MEETINGS CHART */
      chartData.meetings.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        Amount: d.daily.meetings > 0 ? d.daily.meetings : 0
      });

      /* CALLS CHART */
      chartData.calls.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        Amount: d.daily.called > 0 ? d.daily.called : 0
      });

      /* EARNINGS CHART */
      const amount = d.daily.total_paid - d.daily.total_fees;
      const gen_amount = d.daily.generated_paid - d.daily.generated_fees;
      const qual_amount = d.daily.qualified_paid - d.daily.qualified_fees;
      chartData.earnings.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        Total: amount > 0 ? amount : 0,
        Generated: gen_amount > 0 ? gen_amount : 0,
        Qualified: qual_amount > 0 ? qual_amount : 0
      });

      /* LEAD STATUS VS CHART */
      chartData.vs[0].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.in_progress
      });
      chartData.vs[1].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.review
      });
      chartData.vs[2].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.generated
      });
      chartData.vs[3].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.qualified
      });
      chartData.vs[4].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.not_interested
      });
      chartData.vs[5].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.disputed
      });
    });
    chartData.totals = totals;
  }

  /* EMAILS CHART */
  chartData.emails = [
    { name: 'Sent', fill: '#3E3993', value: chartData.totals.emailed || 0 },
    { name: 'Opened', fill: '#C1DDBB', value: chartData.totals.opened || 0 }
  ];

  return chartData;
};
