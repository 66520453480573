import React, { Component } from 'react';
import { Select, Avatar } from '_seriph';
import './SelectMultiple.scss';

const { Option } = Select;

export default class SelectMultiple extends Component {
  state = {
    term: ''
  };
  onSearch = term => this.setState({ term });
  onChange = value => {
    this.props.onChange(value);
  };
  filterOption = (term, option) => {
    const companyName = option?.props?.children[1];
    const matches = companyName?.toLowerCase().includes(term.toLowerCase());
    return matches;
  };
  render() {
    let children = [];
    const autocomplete = this.props.autocomplete || [];
    const type = this.props.type;

    if (type === 'users' || type === 'companies') {
      children = autocomplete;
      children = children.map(user => (
        <Option value={user.id} key={user.id}>
          <Avatar
            className="multiple-user-avatar"
            size="mini"
            type="agent"
            picture={user.picture}
          />
          {user.display_name}
        </Option>
      ));
    } else if (this.props.showAll) {
      children = autocomplete;
      children = children.map(item => <Option key={item}>{item}</Option>);
    } else {
      const term = this.state.term || '';
      if (term.length > 0 || this.props.searchOnEmpty === true) {
        children = autocomplete.filter(item => {
          return item.toLowerCase().includes(term.toLowerCase());
        });
        const termAmount = 10 * (term.length || 1);
        if (children.length > termAmount && this.props.filter !== false)
          children = children.slice(0, termAmount);
      }
      children = children.map(item => <Option key={item}>{item}</Option>);
    }

    return (
      <Select
        className={`seriph-select-multiple ${type === 'users' ? 'users' : ''}`}
        disabled={this.props.disabled}
        mode="multiple"
        size="large"
        style={{ width: '100%' }}
        placeholder={this.props.placeholder}
        value={this.props.value || undefined}
        onChange={this.onChange}
        onSearch={this.onSearch}
        notFoundContent={this.props.notFoundContent}
        filterOption={type === 'companies' ? this.filterOption : undefined}
      >
        {children}
      </Select>
    );
  }
}
