import gql from 'graphql-tag';

export const saveOnboard = gql`
  mutation saveOnboard($onboard: OnboardInput!, $finish: Boolean) {
    saveOnboard(onboard: $onboard, finish: $finish)
  }
`;

export const verifyOnboard = gql`
  mutation verifyOnboard($key: String!) {
    verifyOnboard(key: $key)
  }
`;

export const resendOnboard = gql`
  mutation resendOnboard($id: String!) {
    resendOnboard(id: $id)
  }
`;
