import React, { Component } from 'react';
import './Spacer.scss';

export default class Spacer extends Component {
  render() {
    const margin = this.props.margin ? this.props.margin : '20px 0';
    const backgroundColor = this.props.color ? this.props.color : 'rgba(0,0,0,0.05)';
    return (
      <div
        className="sellx-spacer"
        style={this.props.style ? this.props.style : { margin, backgroundColor }}
      />
    );
  }
}
