import React from 'react';
import { Link } from 'react-router-dom';
import { numberWithCommas } from '_assets/js/helpers';
import { Dropdown } from 'antd';
import { Avatar, Tooltip, Header, Menu } from '_seriph';
import { IconDots } from '@tabler/icons-react';
import moment from 'moment';

export const getColumns = (
  reload,
  showMessageModal,
  showRemoveAgentModal,
  showInviteAgentModal,
  showAddAgentModal,
  addBackToCampaign,
  showAddAgentBackModal,
  revokeTeamRep,
  reinstateTeamRep,
  columns,
  columnsToNames
) => {
  const actualCols = [];

  actualCols.push({
    title: 'Sales Rep',
    dataIndex: 'name',
    key: 'name',
    rowKey: 'hired_name',
    width: 280,
    fixed: 'left',
    render: (c, r) => {
      return (
        <div className="ctbl-box">
          <Link to={`/rep/${r.agent.id}`}>
            <Avatar type="agent" picture={r.agent.picture} size="tiny" />
          </Link>
          <div className="ctbl-name">
            <Header size="5" font="sans">
              {r.agent.display_name}
            </Header>
            <div className="ctbl-hired">Joined, {moment(r.hired * 1).format('M/D/YYYY')}</div>
          </div>
        </div>
      );
    }
  });
  if (true) {
    actualCols.push({
      title: 'Rep type',
      dataIndex: 'rep-type',
      key: 'rep-type',
      rowKey: 'rep-type',
      width: 100,
      render: (c, r) => {
        if (!r.is_internal) {
          return (
            <div className="rep-type">
              <div className="xdr-label">XDR</div>
            </div>
          );
        } else {
          if (r.removed) {
            return (
              <div className="rep-type">
                <div className="removed-label">Removed</div>
              </div>
            );
          } else {
            return (
              <div className="rep-type">
                <div className="internal-label">Internal</div>
              </div>
            );
          }
        }
      }
    });
  }
  if (true) {
    actualCols.push({
      title: 'Active campaigns',
      dataIndex: 'active_campaigns',
      key: 'active_campaigns',
      rowKey: 'active_campaigns',
      width: 100,
      render: (c, r) => {
        let myCampaigns = r.campaigns.filter(c => {
          const member = c.agents.find(a => a.agent_id === r.agent.id);
          return member && member.active ? true : false;
        });

        myCampaigns = myCampaigns.map((c, i) => (
          <div key={i + r.agent.id}>&bull;&nbsp;&nbsp;{c.name}</div>
        ));

        let originalLength = myCampaigns?.length || 0;
        if (myCampaigns.length > 5) {
          const missing = myCampaigns.slice(5);
          myCampaigns = myCampaigns.slice(0, 5);
          myCampaigns.push(
            <div style={{marginTop: '8px', paddingTop: '8px', borderTop: '1px solid #ddd'}}>{missing.length} more not listed...</div>
          );
        }

        return originalLength > 0 ? (
          <Tooltip title={myCampaigns}>
            <div className="my-camps">{originalLength}</div>
          </Tooltip>
        ) : (
          <div className="no-campaigns">0</div>
        );
      }
    });
  }

  
  if (columns.qualified) {
    actualCols.push({
      title: columnsToNames.qualified,
      dataIndex: 'qualified',
      key: 'qualified',
      rowKey: 'hired_qualified',
      width: 100,
      render: (c, r) => numberWithCommas(r.stats.qualified)
    });
  }

  if (columns.pulled) {
    actualCols.push({
      title: columnsToNames.pulled,
      sorter: (a, b) => a.stats?.pulled - b.stats?.pulled,
      dataIndex: 'pulled',
      key: 'pulled',
      rowKey: 'hired_paid',
      width: 100,
      render: (c, r) => numberWithCommas(r.stats.pulled)
    });
  }

  if (columns.calls) {
    actualCols.push({
      title: columnsToNames.calls,
      sorter: (a, b) => a.stats?.touchpoint_call - b.stats?.touchpoint_call,
      dataIndex: 'calls',
      key: 'calls',
      rowKey: 'hired_calls',
      width: 100,
      render: (c, r) => numberWithCommas(r.stats.touchpoint_call)
    });
  }
  if (columns.emails) {
    actualCols.push({
      title: columnsToNames.emails,
      sorter: (a, b) => a.stats?.touchpoint_email - b.stats?.touchpoint_email,
      dataIndex: 'emails',
      key: 'emails',
      rowKey: 'hired_calls',
      width: 100,
      render: (c, r) => numberWithCommas(r.stats.touchpoint_email)
    });
  }

  if (columns.meetings) {
    actualCols.push({
      title: columnsToNames.meetings,
      sorter: (a, b) => a.stats?.meetings - b.stats?.meetings,
      dataIndex: 'meetings',
      key: 'meetings',
      rowKey: 'hired_meetings',
      width: 100,
      render: (c, r) => numberWithCommas(r.stats.meetings)
    });
  }
  if (columns.submitted) {
    actualCols.push({
      title: columnsToNames.submitted,
      sorter: (a, b) => a.stats?.review - b.stats?.review,
      dataIndex: 'review',
      key: 'review',
      rowKey: 'review',
      width: 100,
      render: (c, r) => numberWithCommas(r.stats.review)
    });
  }
  actualCols.push({
    title: '',
    dataIndex: 'id',
    key: 'id',
    rowKey: 'hired_id',
    width: 80,
    align: 'right',
    fixed: 'right',
    className: 'row-actions',
    render: (c, r) => {
      const myCampaigns = r.campaigns
        .filter(c => {
          const activeCamp = c.status === 'active' || c.status === 'inactive';
          const member = c.agents.find(a => a.agent_id === r.agent.id);
          const activeMember = member && member.active ? true : false;
          return activeCamp && activeMember;
        });

      const removedCampaigns = r.campaigns
        .filter(c => {
          const activeCamp = c.status === 'active' || c.status === 'inactive';
          const member = c.agents.find(a => a.agent_id === r.agent.id);
          const wasAMember = member && member.active === false;
          return activeCamp && wasAMember;
        });

      const menu = (
        <Menu>
          <Menu.Item onClick={() => showMessageModal(r.agent)}>
            Send a message
          </Menu.Item>
          {r.is_internal && !r.removed ? (
            <Menu.Item className="nocap" onClick={() => showAddAgentModal(r.agent)}>
              Add to campaign
            </Menu.Item>
          ) : null}
          {removedCampaigns.length > 0 && !r.removed ? (
            <Menu.Item className="nocap" onClick={() => showAddAgentBackModal(r.agent)}>
              Add back to campaign
            </Menu.Item>
          ) : null}
          { !r.is_internal ? (
            <Menu.Item className="nocap" onClick={() => showInviteAgentModal(r.agent)}>
              Invite to campaign
            </Menu.Item>
          ) : null}
          {myCampaigns.length > 0 ? <Menu.Divider /> : null}
          {myCampaigns.length > 0 ? (
            <Menu.Item className="danger" onClick={() => showRemoveAgentModal({...r.agent, is_internal: r.is_internal})}>
              Remove from campaign
            </Menu.Item>
          ) : null}
        </Menu>
      );
      return (
        <Dropdown
          overlayClassName="seriph-dropdown"
          overlay={menu}
          trigger={['click']}
          placement="bottomRight"
        >
          <div className="tbl-btn">
            <IconDots size={18} stroke={2} />
          </div>
        </Dropdown>
      );
    }
  });

  return actualCols;
};
