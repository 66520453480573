import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getMyReps, getRepInvites } from '_graphql/queries/company';
import { inviteTeamRep, withdrawTeamRepInvite } from '_graphql/mutations/company';
import { revokeTeamRep, reinstateTeamRep } from '_graphql/mutations/rep';
import { getErrors } from '_assets/js/helpers';
import InviteRepMember from 'src/App/Company/Agents/_modals/InviteRepMember/InviteRepMember';
import { getRepColumns } from './_data/RepColumns';
import { getInviteColumns } from './_data/InviteColumns';
import { Card, Loading, Header, Button, Modal, Table, message } from '_seriph';
import { IconInfoCircle } from '@tabler/icons-react';
import SeatInfoModal from 'App/Company/_modals/SeatInfoModal/SeatInfoModal';

import './Reps.scss';

class Reps extends Component {
  state = {
    loadingReps: true,
    loadingInvites: true,
    repInvites: [],
    team: [],
    invites: [],
    inviteModal: false,
    resendLoading: false
  };
  componentDidMount = () => {
    this.loadTeamReps();
    this.loadRepInvites();
  };
  loadTeamReps = () => {
    this.props.client
      .query({ query: getMyReps })
      .then(result => {
        if (result && result.data && result.data.getMyReps) {
          this.setState({ loadingReps: false, team: result.data.getMyReps || [] });
        } else {
          throw new Error('Could not load team, try again');
        }
      })
      .catch(err => {
        this.setState({ loadingReps: false });
        message.error(getErrors(err) || 'Could not load team, try again');
      });
  };
  removeMember = (agent) => {
    this.props.client
      .mutate({
        variables: { agent_id: agent.id },
        mutation: revokeTeamRep
      })
      .then(result => {
        if (result?.data?.revokeTeamRep) {
          message.success(agent.display_name + ' has had their account revoked.');
          this.loadTeamReps();
          if (this.props.reloadCompany) this.props.reloadCompany();
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not revoke sales rep, try again');
      });
  };
  confirmRemoveMember = agent => {
    Modal.confirm({
      title: `Are you sure you want to revoke the account for ${agent.display_name}?`,
      content: 'They will be REMOVED from all campaigns AND be unable to login after you revoke their account.',
      onOk: () => this.removeMember(agent),
      onCancel() {}
    });
  };
  reinstateTeamRep = agent => {
    Modal.confirm({
      title: `Are you sure you want to reinstate the account for ${agent.display_name}?`,
      content: 'They will be have to be added back to any campaigns they were previously on.',
      onOk: async () => {
        let result;
        let error = 'Could not reinstate agent, try again';
        try {
          result = (
            await this.props.client.mutate({
              variables: { agent_id: agent.id },
              mutation: reinstateTeamRep
            })
          )?.data?.reinstateTeamRep;
        } catch (e) {
          error = e.message;
        }

        if (result) {
          message.success(agent.display_name + ' has had their account reinstated.');
          this.loadTeamReps();
          if (this.props.reloadCompany) this.props.reloadCompany();
        } else {
          message.error(error);
        }
      }
    });
  };
  resendInvite = email => {
    this.setState({ resendLoading: email });
    this.props.client
      .mutate({
        variables: { email, resend: true },
        mutation: inviteTeamRep
      })
      .then(result => {
        if (result?.data?.inviteTeamRep) {
          message.success('An invite reminder was sent to ' + email);
          this.setState({ resendLoading: false });
        } else {
          throw new Error('Could not send an invite reminder, try again');
        }
      })
      .catch(err => {
        this.setState({ resendLoading: false });
        message.error(getErrors(err) || 'Could not send an invite reminder, try again');
      });
  };
  withdrawTeamRep = (email, invite_id) => {
    Modal.confirm({
      title: `Are you sure you want to withdraw the invitation for ${email}?`,
      content: 'They will be unable to create an account with this email.',
      onOk: async () => {
        let result;
        let error = 'Could not withdraw invite, try again';
        try {
          result = (
            await this.props.client.mutate({
              variables: { invite_id },
              mutation: withdrawTeamRepInvite
            })
          )?.data?.withdrawTeamRepInvite;
        } catch (e) {
          error = e;
        }

        if (result) {
          message.success(`Invitation withdrawn from ${email}`);
          this.loadTeamReps();
        } else {
          message.error(getErrors(error) || 'Could not withdraw invite, try again');
        }
      }
    });
  };
  loadRepInvites = () => {
    this.props.client
      .query({ query: getRepInvites })
      .then(result => {
        if (result?.data?.getRepInvites) {
          this.setState({ repInvites: result.data.getRepInvites || [], loadingInvites: false });
        } else {
          throw new Error('Could not load invites, try again');
        }
      })
      .catch(err => {
        this.setState({ loadingInvites: false });
        message.error(getErrors(err) || 'Could not load invites, try again');
      });
  };
  showInviteModal = () => this.setState({ inviteModal: true });
  removeInviteModal = () => this.setState({ inviteModal: false });
  showSeatInfoModal = () => this.setState({ seatInfoModal: true });
  removeSeatInfoModal = () => this.setState({ seatInfoModal: false });
  render() {
    const { loadingReps, loadingInvites, team, repInvites, resendLoading } = this.state;
    const { user, company } = this.props;

    const loading = loadingReps || loadingInvites ? true : false;

    const seatsLeft = company?.seats_left || 0;

    const repColumns = getRepColumns(
      company,
      user,
      this.confirmRemoveMember,
      this.reinstateTeamRep
    );
    const inviteColumns = getInviteColumns(
      resendLoading,
      this.resendInvite,
      this.confirmRemoveMember,
      this.withdrawTeamRep
    );

    return loading || !company ? (
      <Loading />
    ) : (
      <div id="company-settings-reps">
        <Card>
          <Header size="2" type="sans">
            My Sales Reps
          </Header>
          <div className="settings-subtitle">
            Invite reps from {user.company_name} or SellX to work directly for you
          </div>
          <Table
            className="reps-table"
            striped={true}
            columns={repColumns}
            rowKey={record => record.id}
            dataSource={team.sort(t => !t.removed ? -1 : 1)}
            pagination={false}
            locale={{ emptyText: 'No ' + user.company_name + ' sales reps found' }}
          />

          <div className="invite-actions">
            <span onClick={this.showSeatInfoModal}>
              <IconInfoCircle />{seatsLeft} seat{seatsLeft === 1 ? '' : 's'} left
            </span>
            {seatsLeft > 0 ? (
              <Button type="primary" onClick={this.showInviteModal}>
                + Invite Rep
              </Button>
            ) : null}
          </div>
        </Card>

          <Card id="reps-invites">
            <Header size="2" type="sans">
              Invited
            </Header>
            <Table
              className="reps-table"
              striped={true}
              columns={inviteColumns}
              rowKey={record => record.id}
              dataSource={repInvites}
              pagination={false}
              locale={{ emptyText: 'No open rep invites found, click + Invite Rep above to invite a rep' }}
            />
          </Card>

        {this.state.inviteModal ? (
          <InviteRepMember
            client={this.props.client}
            removeModal={this.removeInviteModal}
            reload={this.loadRepInvites}
            numSeats={seatsLeft}
            campaigns={this.props.campaigns}
            company={this.props.company}
          />
        ) : null}
        {this.state.seatInfoModal ? (
          <SeatInfoModal
            client={this.props.client}
            company={this.props.company}
            removeModal={this.removeSeatInfoModal}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(Reps));
