import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { getTypeform } from '_graphql/queries/auth';
import Avatar from 'react-avatar-edit';
import { phoneValid, isPassword, isEmail } from '_assets/js/helpers';
import { loginUser } from 'Store/user/user_actions';
import logo from '_assets/img/logo.svg';
import { IconAlertCircle, IconPhoto } from '@tabler/icons-react';
import { Avatar as AgentPic, Input, Button, Header, message } from '_seriph';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './Invited.scss';

const blankValidation = () => ({ picture: '', phone_number: '', email_username: '', password: '' });

class SignUp extends Component {
  state = {
    tab: 'agent',
    form: {
      email: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      email_username: '',
      password: '',
      picture: ''
    },
    validate: blankValidation(),
    finding: true,
    found: false,
    tok: null,
    saving: false
  };
  UNSAFE_componentWillMount() {
    if (this.props.responseId) {
      this.setState({ tok: this.props.responseId });
      this.getInvite(this.props.responseId);
    } else {
      this.props.history.push('/');
    }
  }
  getInvite = tok => {
    this.props.client
      .query({ variables: { response_id: tok, type: 'agent' }, query: getTypeform })
      .then(result => {
        if (result && result.data && result.data.getTypeform) {
          const invite = result.data.getTypeform;
          if (invite.used) {
            message.error(
              'Sorry, but that invitation has already been used.  If something is wrong, please contact: support@sellx.com'
            );
            this.setState({ finding: false });
          } else {
            this.setState({
              found: true,
              finding: false,
              form: {
                email: invite.email,
                phone_number: invite.phone_number,
                email_username: (
                  invite.first_name[0].toLowerCase() + invite.last_name.toLowerCase()
                ).replace(/[^a-zA-Z0-9_\-@.]/g, ''),
                first_name: invite.first_name,
                last_name: invite.last_name,
                password: '',
                picture: ''
              }
            });
          }
        } else {
          message.error(
            'Could not find invitation. If something is wrong, please contact: support@sellx.com'
          );
          this.setState({ finding: false });
        }
      })
      .catch(() => {
        message.error(
          'Could not find invitation. If something is wrong, please contact: support@sellx.com'
        );
        this.setState({ finding: false });
      });
  };
  handleChange = (e, i) => {
    const form = this.state.form;
    if (i === 'phone_number') {
      form[i] = e;
    } else {
      form[i] = e.target.value;
    }
    this.setState({ form });
  };
  async signup(type) {
    let isValid = false;
    await this.setState({ validate: blankValidation() }, () => {
      isValid = this.isValid();
    });
    if (!isValid) return;
    this.setState({ saving: true });
    const data = { ...this.state.form, role_type: type, tok: this.state.tok };
    axios
      .post(`${process.env.REACT_APP_API_PATH}/v1/register/${type}`, data)
      .then(response => {
        const { token } = response.data;
        if (token) {
          this.props.history.push('/?token=' + token);
        }
      })
      .catch(result => {
        if (result && result.response && result.response.data) {
          const { data } = result.response;
          if (data && data.error) message.error(data.error);
        } else {
          message.error('Could not create account, try again or reach out to support@sellx.com');
        }
      })
      .finally(() => this.setState({ saving: false }));
  }
  onClose = () => {
    const form = this.state.form;
    form.picture = null;
    this.setState({ form });
  };
  onAccept = () => {
    const form = this.state.form;
    form.picture = null;
    this.setState({ form });
  };
  onCrop = preview => {
    const form = this.state.form;
    form.picture = preview;
    this.setState({ form });
  };
  onBeforeFileLoad = elem => {
    if (elem.target.files[0].size > 1024 * 1024) {
      message.error('Image size is too large, must be under 1 MB');
      elem.target.value = '';
    }
  };
  isValid = () => {
    const validate = blankValidation();
    const form = this.state.form;
    if (!form.picture) validate.picture = 'Please select a headshot';
    if (!phoneValid(form.phone_number)) validate.phone_number = 'Enter a valid phone number';
    if (!isPassword(form.password))
      validate.password =
        'Password must be 6 characters and contain one (!@#$%^&), number, and capitalized letter';

    if (!isEmail(form.email_username + '@sellx.org'))
      validate.email_username = 'Enter a valid email address';
    this.setState({ validate });
    for (let v in validate) {
      if (validate[v]) {
        return false;
      }
    }
    return true;
  };
  render() {
    const form = this.state.form;
    const found = this.state.found;
    const validate = this.state.validate;

    return (
      <div id="onboarding" className="invitation">
        <div className="home-logo">
          <Link to="/">
            <img src={logo} alt={logo} />
          </Link>
        </div>
        <div className="home">
          <div className="home-left"></div>
          <div className="home-right">
            <div className="home-form">
              <Header type="display" size="3" className="headline">
                Setup your account
              </Header>
              <div className="user-example">
                <div className="user-card">
                  {form.picture ? (
                    <AgentPic size="mini" type="agent" picture={form.picture} />
                  ) : (
                    <AgentPic size="mini" type="blank" />
                  )}
                  <div className="uc-name">
                    {form.first_name} {form.last_name}
                  </div>
                  <div>•</div>
                  <div className="uc-email">{form.email}</div>
                </div>
              </div>
              <div className="signup">
                <div className={'big-input' + (validate.picture ? ' big-error' : '')}>
                  <div className="seriph-label">Upload a headshot</div>
                  <div className={'big-box big-pic ' + (form.picture ? 'pic' : '')}>
                    <Avatar
                      width={'100%'}
                      height={200}
                      cropRadius={50}
                      onCrop={this.onCrop}
                      onClose={this.onClose}
                      onBeforeFileLoad={this.onBeforeFileLoad}
                      src={form.picture}
                      lineWidth={4}
                      cropColor="#000000"
                      borderStyle={{ border: '1px dashed #999', borderRadius: '10px' }}
                      label={
                        <div className="upload-icon">
                          <div>
                            <IconPhoto />
                            Click to select an image...
                          </div>
                        </div>
                      }
                      labelStyle={{ fontWeight: '100' }}
                      mimeTypes="image/jpeg,image/pjpeg,image/png,image/gif"
                    />
                  </div>
                  {validate.picture ? (
                    <div className="error-text">
                      <IconAlertCircle />
                      {validate.picture}
                    </div>
                  ) : null}
                </div>
                <Input
                  label="Password"
                  name="new-password"
                  size="large"
                  error={validate.password}
                  hint={validate.password}
                  disabled={!found}
                  autoComplete="new-password"
                  type="password"
                  value={form.password}
                  onChange={e => this.handleChange(e, 'password')}
                  placeholder="Create a Password"
                />
                <div className="int-phone seriph-input input-large">
                  <label className="seriph-label">Phone Number</label>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={form.phone_number}
                    onChange={e => this.handleChange(e, 'phone_number')}
                  />
                </div>
                <div className="sx-email">
                  <Input
                    label="Email Username"
                    name="email_username"
                    size="large"
                    error={validate.email_username}
                    hint={validate.email_username}
                    disabled={!found}
                    value={form.email_username}
                    onChange={e => this.handleChange(e, 'email_username')}
                    placeholder="jsmith"
                  />
                  <div className="ending">@sellx.org</div>
                </div>
              </div>
              <div className="home-actions">
                <Button
                  type="default"
                  size="large"
                  disabled={!found}
                  loading={this.state.saving || this.state.finding}
                  onClick={() => this.signup('agent')}
                >
                  Create your account
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, { loginUser })(SignUp));
