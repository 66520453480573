import React, { Component } from 'react';
import moment from 'moment';
import { Avatar, Tooltip, Card, Button, Menu, Tag } from '_seriph';
import { IconDotsVertical, IconArrowBackUp } from '@tabler/icons-react';
import { Dropdown } from 'antd';

import './Chat.scss';

export default class Chat extends Component {
  render() {
    const props = this.props;
    const email = props.email;
    const className = email.isMe ? 'email-chat message-own' : 'email-chat';
    let openedTime = null;
    if (email.opened) {
      openedTime = moment(email.opened * 1);
      const isToday = openedTime.isSame(new Date(), 'day');
      if (isToday) {
        openedTime = moment(email.opened * 1).format('h:mma');
      } else {
        openedTime = moment(email.opened * 1).format('M/D/YY');
      }
    }
    const emailTo = email.from_email || [];
    const emailCC = email.cc || [];
    const emailBCC = email.bcc || [];

    const allEmails = [email.to_email, ...emailCC, ...emailBCC];
    const emailsMap = (
      <div>
        <div>To: {email.to_email}</div>
        {emailCC.length > 0 ? <div>Cc: {emailCC.join(', ')}</div> : null}
        {emailBCC.length > 0 ? <div>Bcc: {emailBCC.join(', ')}</div> : null}
      </div>
    );

    const isLead = props.lead.email.toLowerCase() == email.from_email.toLowerCase();

    const emailStatus = isLead && email.status === 'sent' ? 'new' : email.status;

    const menu = (
      <Menu>
        <Menu.Item onClick={() => props.onReply({ showReply: true, toContent: emailTo })}>
          Reply
        </Menu.Item>
        {allEmails.length > 1 && (
          <Menu.Item
            onClick={() =>
              props.onReply({
                showReply: true,
                toContent: emailTo,
                ccContent: email.cc,
                bccContent: email.bcc
              })
            }
          >
            Reply All
          </Menu.Item>
        )}
        <Menu.Item onClick={() => props.onReply({ showReply: true })}>Forward</Menu.Item>
      </Menu>
    );

    return (
      <Card className={className}>
        <div className="message-avatar">
          {email.isMe ? (
            <Avatar size="tiny" picture={props.user.picture} />
          ) : (
            <Avatar
              size="tiny"
              type="lead"
              firstCharacter={(email.firstName || '?').charAt(0).toUpperCase()}
              lastCharacter={(email.lastName || '?').charAt(0).toUpperCase()}
            />
          )}
        </div>
        <div className="message-content">
          <h3>
            {email.from_name}
            <span className="email">&lt;{email.from_email}&gt;</span>
          </h3>
          <Tooltip title={emailsMap}>
            <h4>
              <b>To:</b> {allEmails.join(', ')}
            </h4>
          </Tooltip>
          <p dangerouslySetInnerHTML={{ __html: email.content || email.content_text }}></p>
          {email.attachment && (
            <div className="d-flex align-center email-attachment">
              <b>attachment:</b>
              <a href={email.attachment.url}>
               <Tag size="small">{email.attachment.filename}</Tag>
              </a>
            </div>
          )}
          <div className="tagged">
            <div className={emailStatus}>{emailStatus}</div>
            <div className="tag-tstamp">{moment(email.createdAt * 1).format('h:mm a')}</div>
            <Button
              type="plain"
              circle
              onClick={() => props.onReply({ showReply: true, toContent: emailTo })}
            >
              <IconArrowBackUp />
            </Button>
            <Dropdown
              overlayClassName="seriph-dropdown"
              overlay={menu}
              trigger={['click']}
              placement="bottomRight"
            >
              <Button type="plain" circle>
                <IconDotsVertical />
              </Button>
            </Dropdown>
          </div>
        </div>
      </Card>
    );
  }
}
