import React, { Component } from 'react';
import { withRouter, Prompt } from 'react-router-dom';
import { Button, message, Modal, Tooltip } from 'antd';
import queryString from 'query-string';
import StepList from './Components/StepList/StepList';
import CampaignSetup from './Steps/CampaignSetup';
import AgentTargeting from './Steps/AgentTargeting';
import CustomerProfile from './Steps/CustomerProfile';
import Questionnaire from './Steps/Questionnaire';
import Review from './Review/Review';
import Budget from './Steps/Budget';
import Training from './Steps/Training';
import { createCampaign, deleteCampaign, updateCampaign } from 'Mutations/Company/campaign';
import CampaignOverview from '_styleguide/CampaignOverview/CampaignOverview';
import LaunchCampaign from './_modals/LaunchCampaign/LaunchCampaign';
import { isCalendly } from '_assets/js/helpers';
import { getCampaign, getDuplicate } from '_graphql/queries/campaign';
import Loading from '_shared/Loading/Loading';
import './CampaignWizard.scss';

const mutations = {
  save: { mutation: updateCampaign, name: 'updateCampaign' },
  create: { mutation: createCampaign, name: 'createCampaign' },
  delete: { mutation: deleteCampaign, name: 'deleteCampaign' }
};
const confirm = Modal.confirm;

class CampaignWizard extends Component {
  state = {
    step: 1,
    reviewing: false,
    form: {
      name: 'New Campaign',
      type: 'public',
      objective: null,
      calendly: null,
      description: null,
      short_description: null,
      guidelines: null,
      script: null,
      target_experience_level: null,
      target_availability: null,
      target_agent_count: 10,
      target_area_code: null,
      target_skills: [],
      target_languages: [],
      target_locations: [],
      instructions: [],
      files: [],
      customer_type: null,
      customer_target: null,
      customer_locations: [],
      customer_decision_maker: '',
      customer_age: null,
      customer_gender: null,
      customer_race: [],
      customer_education: null,
      customer_company_scope: null,
      customer_job_position: null,
      customer_industry: [],
      customer_languages: [],
      cohort: 'none',
      required: [
        { field: 'first_name' },
        { field: 'last_name' },
        { field: 'email' },
        { field: 'phone' },
        { field: 'location', type: 'Location', locations: [] }
      ],
      forceCalendar: false,
      questions: [],
      budget_ppl: 0,
      budget_ppql: 0,
      budget_total: 0,
      budget_bonus: null,
      budget_bonus_count: null,
      status: 'draft'
    },
    leadCount: 0,
    saved: null,
    validation: {},
    showValidation: false,
    isValid: false,
    campaignId: null,
    showPreview: false,
    launchModal: false,
    forceUpdate: 0,
    scrolls: {}
  };
  scrollListener = false;
  saveInterval = null;
  scrolls = {};
  steps = {};
  createCampaign = fromId => {
    this.props.client
      .mutate({
        variables: { name: 'New Campaign' },
        mutation: mutations.create.mutation
      })
      .then(result => {
        if (result && result.data) {
          const { id } = result.data[mutations.create.name];
          if (id && !fromId) {
            this.setState({ campaignId: id, saved: JSON.stringify(this.state.form) }, () => {
              this.getScrolls();
            });
          } else if (id && fromId) {
            this.getDuplicate(fromId, id);
          } else {
            message.error('Uh oh - something went wrong on our end, try again.');
            this.props.history.goBack();
          }
        }
      })
      .catch(() => {
        message.error('Uh oh - something went wrong on our end, try again.');
        this.props.history.goBack();
      });
  };
  loadCampaign = id => {
    this.props.client
      .query({ variables: { id: id }, query: getCampaign })
      .then(result => {
        if (result && result.data) {
          const campaign = result.data.campaign || null;
          if (campaign) {
            this.setState(
              { campaignId: id, form: campaign, saved: JSON.stringify(campaign) },
              () => {
                this.setState({ isValid: this.validateForm() });
                this.getScrolls();
              }
            );
          } else {
            message.error('Uh oh - something went wrong on our end, try again.');
          }
        } else {
          message.error('Uh oh - something went wrong on our end, try again.');
        }
      })
      .catch(() => {
        message.error('Could not load your campaigns, try again');
      });
  };
  getDuplicate = (fromId, campaignId) => {
    this.props.client
      .query({ variables: { id: fromId }, query: getDuplicate })
      .then(result => {
        if (result && result.data && result.data.campaign) {
          const campaign = result.data.campaign || null;
          campaign.status = 'draft';
          if (campaign) {
            this.setState(
              { campaignId: campaignId, form: campaign, saved: JSON.stringify(campaign) },
              () => {
                this.setState({ isValid: this.validateForm() });
                this.getScrolls();
              }
            );
          } else {
            message.error('Uh oh - something went wrong on our end, try again.');
          }
        } else {
          message.error('Uh oh - something went wrong on our end, try again.');
        }
      })
      .catch(() => {
        message.error('Could not load your campaign, try again');
      });
  };
  saveCampaign = (savingData, preview, launch) => {
    this.props.client
      .mutate({
        variables: { id: this.state.campaignId, ...this.state.form },
        mutation: mutations.save.mutation
      })
      .then(result => {
        if (result && result.data) {
          this.setState(
            { saving: false, saved: savingData, showPreview: preview ? true : false },
            () => {
              if (launch === 'active') {
                message.success(`Congrats, your campaign is now live!`);
              } else if (launch === 'deactive') {
                message.warning(`Campaign Paused`);
              } else if (!preview) {
                message.success(`Campaign Saved`);
              }
              if (this.props.isNew && this.state.campaignId) {
                this.props.history.push(`/campaign/${this.state.campaignId}/edit`);
              }
            }
          );
        }
      })
      .catch(() => this.setState({ saving: false }));
  };
  removeCampaign = () => {
    confirm({
      title: 'Are you sure you want to DELETE this campaign?',
      content:
        'Once your campaign is removed you will lose all information currently filled out and you will not be able to recover it.',
      onOk: () => {
        this.setState({ deleting: true });
        this.props.client
          .mutate({
            variables: { id: this.state.campaignId },
            mutation: mutations.delete.mutation
          })
          .then(result => {
            if (result && result.data && result.data[mutations.delete.name] === null) {
              message.success('Campaign Removed');
              this.props.history.push('/campaigns');
            } else {
              this.setState({ deleting: false });
              message.error('Error removing campaign, try again');
            }
          })
          .catch(() => {
            this.setState({ deleting: false });
            message.error('Error removing campaign, try again');
          });
      }
    });
  };
  autoSaveCampaign = () => {
    this.setState({ saving: true });
    if (this.state.form === 'active' && !this.state.isValid) {
      message.error('You cannot update an active form unless ALL inputs are complete');
    } else {
      this.saveCampaign(JSON.stringify(this.state.form));
    }
  };
  componentDidMount = () => {
    const queryParams = queryString.parse(this.props.location.search);
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      this.loadCampaign(this.props.match.params.id);
    } else if (queryParams && queryParams.from) {
      this.createCampaign(queryParams.from);
    } else {
      this.createCampaign();
    }
  };
  componentWillUnmount = () => {
    if (this.saveInterval) clearInterval(this.saveInterval);
    if (this.scrollListener)
      document.getElementById('cc-main').removeEventListener('scroll', this.handleScroll);
  };
  componentDidUpdate = () => {
    if (JSON.stringify(this.state.form) !== this.state.saved) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };
  getScrolls = () => {
    const scrolls = {};
    this.scrollListener = true;
    document.getElementById('cc-main').addEventListener('scroll', this.handleScroll);
    scrolls['1'] = document.getElementById('cw-1').offsetTop;
    scrolls['2'] = document.getElementById('cw-2').offsetTop;
    scrolls['3'] = document.getElementById('cw-3').offsetTop;
    scrolls['4'] = document.getElementById('cw-4').offsetTop;
    scrolls['5'] = document.getElementById('cw-5').offsetTop;
    this.setState({ scrolls });
  };
  updateForm = (field, value) => {
    const form = Object.assign({}, this.state.form);
    form[field] = value !== undefined ? value : null;
    this.setState({ form }, () => {
      this.setState({ isValid: this.validateForm() });
      setTimeout(this.getScrolls, 100);
    });
  };
  validateForm = send => {
    const c = this.state.form;
    // False = INVALID || True = VALID
    let validation = {
      /* CAMPAIGN SETUP */
      name: !c.name ? false : true,
      type: !c.type ? false : true,
      objective: !c.objective ? false : true,
      description: !c.description ? false : true,
      short_description: !c.short_description ? false : true,
      imports: c.objective === 'qualify' && this.state.leadCount <= 0 ? false : true,
      calendly: c.calendly && !isCalendly(c.calendly) ? false : true,

      /* CUSTOMER PROFILE */
      customer_type: c.objective === 'leadgen' && !c.customer_type ? false : true,
      customer_target: c.objective === 'leadgen' && !c.customer_target ? false : true,
      customer_decision_maker:
        c.objective === 'leadgen' && !c.customer_decision_maker ? false : true,
      required: c.objective === 'leadgen' && c.required.length < 6 ? false : true,

      /* QUALIFY QUESTIONS */
      questions: true,

      /* AGENT TARGETING */
      target_agent_count: !c.target_agent_count ? false : true,
      target_area_code: c.objective === 'qualify' && !c.target_area_code ? false : true,
      target_skills: c.target_skills.length <= 0 ? false : true,
      target_languages: c.target_languages.length <= 0 ? false : true,
      target_locations: c.target_locations.length <= 0 ? false : true,
      target_experience_level: !c.target_experience_level ? false : true,
      target_availability: !c.target_availability ? false : true,

      /* BUDGET */
      budget_ppl: !c.budget_ppl && c.budget_ppl !== 0 && c.objective === 'leadgen' ? false : true,
      budget_ppql:
        !c.budget_ppql && c.budget_ppql !== 0 && c.objective === 'qualify' ? false : true,
      budget_total: !c.budget_total ? false : true,
      budget_ppl_over: c.budget_ppl > c.budget_total && c.objective === 'leadgen' ? false : true,
      budget_ppql_over: c.budget_ppql > c.budget_total && c.objective === 'qualify' ? false : true
      // instructions: c.instructions.length === 0 ? false : true,
      // customer_target: !c.customer_target ? false : true,
      // customer_locations: c.customer_locations.length === 0 ? false : true,
      // customer_decision_maker: !c.customer_decision_maker ? false : true,
      // customer_languages: c.customer_languages.length === 0 ? false : true,
      // required: c.required.length === 0 ? false : true,
      // budget_ppql: (!c.budget_ppql && c.budget_ppql !== 0) && (c.objective === 'qualify') ? false : true,
      // budget_bonus: (!c.budget_bonus & c.budget_bonus !== 0) || (!c.budget_bonus_count && c.budget_bonus_count !== 0) ? false : true,
    };

    /* MANAGE STEPS */
    const v = validation;
    validation.campaign_setup =
      v.name && v.type && v.objective && v.description && v.short_description;
    validation.customer_profile =
      v.customer_type && v.customer_target && v.customer_decision_maker && v.required;
    validation.qualify_questions = v.imports && v.calendly;
    validation.agent_targeting =
      v.target_agent_count &&
      v.target_area_code &&
      v.target_skills &&
      v.target_languages &&
      v.target_locations &&
      v.target_experience_level &&
      v.target_availability;
    validation.budget =
      v.budget_ppl && v.budget_ppql && v.budget_total && v.budget_ppl_over && v.budget_ppql_over;

    this.setState({ validation });
    for (var key in validation) {
      if (!validation[key]) {
        if (send) {
          this.setState({ showValidation: true });
          message.error('Please fill out required fields before launching campaign');
        }
        return false;
      }
    }
    return true;
  };
  verifyLaunch = () => {
    const valid = this.validateForm(true);
    if (valid && (this.state.saving || JSON.stringify(this.state.form) !== this.state.saved)) {
      message.error('Please save changes before launching campaign');
    } else if (valid) {
      // LAUNCH CAMPAIGN
      this.setState({ reviewing: false }, this.showLaunchModal);
    } else {
      this.setState({ reviewing: true, isValid: valid });
    }
  };
  editCampaign = () => {
    this.setState({ reviewing: false, step: 1 });
    document.getElementById('step-list').style.top = `-${this.steps['1'] || 0}px`;
    document.getElementById('cc-main').addEventListener('scroll', this.handleScroll);
  };
  nextStep = () => {
    const scrolls = { ...this.state.scrolls };
    const ccMain = document.getElementById('sl-main');
    ccMain.scrollTo(0, scrolls[this.state.step + 1] - 112);
  };
  changeStep = step => {
    const stepEl = document.getElementById('cw-' + step).offsetTop;
    const main = document.getElementById('cc-main');
    main.scrollTo({ top: stepEl - 112, behavior: 'smooth' });
  };
  handleScroll = event => {
    const scrolls = { ...this.state.scrolls };
    let scrollTop = event.srcElement.scrollTop + 300;
    let step = 1;
    if (scrollTop > scrolls[2]) step = 2;
    if (scrollTop > scrolls[3]) step = 3;
    if (scrollTop > scrolls[4]) step = 4;
    if (scrollTop > scrolls[5]) step = 5;
    if (this.state.step !== step) this.setState({ step });
  };
  removeModal = () => this.setState({ showPreview: false });
  loadPreview = () => {
    this.saveCampaign(JSON.stringify(this.state.form), true);
  };
  launchCampaign = () => {
    const form = Object.assign({}, this.state.form);
    form.status = 'active';
    this.setState({ form, saved: JSON.stringify(form) }, () => {
      this.saveCampaign(JSON.stringify(this.state.form), false, 'active');
    });
  };
  deactiveCampaign = () => {
    confirm({
      title: 'Are you sure you want to deactivate this campaign?',
      content:
        'Once your campaign is inactive, your listing will be removed from searches and Sales Reps will no longer be able to apply to join the campaign.  You can re-activate your campaign at any time.',
      onOk: () => {
        const form = Object.assign({}, this.state.form);
        form.status = 'inactive';
        this.setState({ form, saved: JSON.stringify(form) }, () => {
          this.saveCampaign(JSON.stringify(this.state.form), false, 'deactive');
        });
      }
    });
  };
  updateLeadCount = leadCount => {
    let sum = 0;
    leadCount.forEach(l => (sum += l.amount));
    this.setState({ leadCount: sum }, () => {
      this.setState({ isValid: this.validateForm() });
      setTimeout(this.getScrolls, 100);
    });
  };
  showLaunchModal = () => this.setState({ launchModal: this.state.form });
  removeLaunchModal = () => this.setState({ launchModal: false });
  render() {
    const campaign = this.state.form;
    const edited = JSON.stringify(campaign) !== this.state.saved;
    let statusClass = 'status';
    if (campaign.status === 'active') statusClass += ' status-active';
    if (campaign.status === 'inactive') statusClass += ' status-inactive';

    const isLocked = campaign.status === 'active' || campaign.status === 'inactive';

    return this.state.campaignId ? (
      <div id="create-campaign" className={edited ? 'edited' : ''}>
        <div className="title-bar">
          <div className="name">
            <h1>{campaign.name || 'New Campaign'}</h1>
            <div className={statusClass}>{campaign.status}</div>
            {this.state.reviewing ? null : (
              <div className="progress">
                {this.state.saving ? <span className="saving">Saving...</span> : null}
                {!this.state.saving && !edited ? 'Up to date' : null}
              </div>
            )}
          </div>
          <div className="actions">
            <Button
              className="btn btn-default btn-empty"
              onClick={() => this.props.history.push('/campaigns')}
            >
              Go Back
            </Button>
            <Button className="btn btn-default btn-empty" onClick={this.loadPreview}>
              Preview
            </Button>
            {campaign.status === 'draft' && !this.props.isNew ? (
              <Button
                loading={this.state.deleting}
                disabled={this.state.deleting}
                className="btn btn-danger btn-empty"
                onClick={this.removeCampaign}
              >
                Delete
              </Button>
            ) : null}
            <div className="sep" />
            <Button
              loading={this.state.saving}
              disabled={!this.state.saving && !edited}
              className="btn btn-neutral"
              onClick={this.autoSaveCampaign}
            >
              {campaign.status === 'active'
                ? 'Update'
                : this.props.isNew
                ? 'Create Campaign'
                : 'Save'}
            </Button>
            {campaign.status !== 'active' && !this.props.isNew ? (
              <Tooltip
                placement="bottom"
                overlayClassName="sellx-tooltip sellx-tooltip-small sellx-tooltip-center"
                title={edited ? 'Please save campaign first.' : null}
              >
                <span>
                  <Button
                    disabled={this.state.saving || edited}
                    className="btn btn-primary"
                    onClick={this.verifyLaunch}
                  >
                    Launch Campaign
                  </Button>
                </span>
              </Tooltip>
            ) : null}
          </div>
        </div>
        <div className="cc-body">
          <div id="cc-main" className="cc-main">
            <StepList
              step={this.state.step}
              form={campaign}
              changeStep={this.changeStep}
              validation={this.state.validation}
              showValidation={this.state.showValidation}
              reviewing={this.state.reviewing}
            />
            {this.state.step <= 6 ? (
              <React.Fragment>
                <CampaignSetup
                  id="cw-1"
                  form={campaign}
                  canCohort={this.props.canCohort}
                  isLocked={isLocked}
                  campaignId={this.state.campaignId}
                  validation={this.state.validation}
                  showValidation={this.state.showValidation}
                  updateForm={this.updateForm}
                  getScrolls={this.getScrolls}
                  client={this.props.client}
                  campaigns={this.props.campaigns}
                  updateLeadCount={this.updateLeadCount}
                />
                {(campaign.objective === 'leadgen' || campaign.objective === null) && (
                  <CustomerProfile
                    id="cw-2"
                    form={campaign}
                    isLocked={isLocked}
                    updateForm={this.updateForm}
                    validation={this.state.validation}
                    showValidation={this.state.showValidation}
                  />
                )}
                {campaign.objective === 'qualify' && (
                  <Questionnaire
                    id="cw-2"
                    form={campaign}
                    isLocked={isLocked}
                    updateForm={this.updateForm}
                    validation={this.state.validation}
                    showValidation={this.state.showValidation}
                    getScrolls={this.getScrolls}
                    client={this.props.client}
                    campaigns={this.props.campaigns}
                    campaignId={this.state.campaignId}
                    updateLeadCount={this.updateLeadCount}
                    integration={this.props.integration}
                  />
                )}
                <AgentTargeting
                  id="cw-3"
                  form={campaign}
                  isLocked={isLocked}
                  updateForm={this.updateForm}
                  validation={this.state.validation}
                  showValidation={this.state.showValidation}
                  leadCount={this.state.leadCount || 0}
                />
                <Training
                  id="cw-4"
                  form={campaign}
                  isLocked={isLocked}
                  campaignId={this.state.campaignId}
                  updateForm={this.updateForm}
                  client={this.props.client}
                />
                <Budget
                  id="cw-5"
                  form={campaign}
                  isLocked={isLocked}
                  updateForm={this.updateForm}
                  validation={this.state.validation}
                  showValidation={this.state.showValidation}
                />
                <div style={{ display: 'block', height: '300px' }} />
              </React.Fragment>
            ) : (
              <Review form={campaign} />
            )}
          </div>
        </div>
        {this.state.showPreview ? (
          <CampaignOverview
            client={this.props.client}
            campaignId={this.state.campaignId}
            removeModal={this.removeModal}
            loadCampaigns={this.loadCampaigns}
            preview={true}
          />
        ) : null}
        <Prompt when={edited} message="You have unsaved changes, are you sure you want to leave?" />
        {this.state.launchModal ? (
          <LaunchCampaign
            client={this.props.client}
            campaign={campaign}
            campaignId={this.state.campaignId}
            removeModal={this.removeLaunchModal}
          />
        ) : null}
      </div>
    ) : (
      <Loading />
    );
  }
}

export default withRouter(CampaignWizard);

/* REVIEW BUTTON
  <Button disabled={this.state.reviewing && !this.state.isValid} className="btn btn-default" onClick={this.reviewCampaign}>Review</Button> 
*/
