import React, { Component } from 'react';
import Card from '_shared/Card/Card';
import { Input, Select, Collapse, Popconfirm } from 'antd';
import Multiple from '_forms/Multiple/Multiple';
import MultipleLocations from '_forms/MultipleLocations/MultipleLocations';
import Selection from '_forms/Selection/Selection';
import { Locked } from '../Components/Locked/Locked';
import { leadFields, pickerFields } from '_constants/leadFields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

function getKeyByValue(item) {
  let returnKey = null;
  Object.keys(leadFields).forEach(c => {
    Object.keys(leadFields[c]).forEach(f => {
      if (leadFields[c][f].field === item) returnKey = f;
    });
  });
  return returnKey;
}

export default class CustomerProfile extends Component {
  state = {
    activeKey: []
  };
  handleSelect = item => {
    let foundItem = null;
    Object.keys(leadFields).forEach(c => {
      Object.keys(leadFields[c]).forEach(f => {
        if (leadFields[c][f].field === item) foundItem = leadFields[c][f];
      });
    });
    if (foundItem !== null) {
      const required = this.props.form.required ? [...this.props.form.required] : [];
      required.push(foundItem);
      this.props.updateForm('required', required);
      if (foundItem.type) {
        const activeKey = [...this.state.activeKey];
        if (!activeKey.find(ak => ak === item)) activeKey.push(item);
        this.setState({ activeKey });
      }
    }
  };
  removeReq = req => {
    const required = this.props.form.required ? [...this.props.form.required] : [];
    this.props.updateForm(
      'required',
      required.filter(r => r.field !== req.field)
    );
  };
  updateValues = (field, values) => {
    const required = (this.props.form.required || []).map(r => {
      if (r.field === field) r.values = values;
      return r;
    });
    this.props.updateForm('required', required);
  };
  updateLocations = (field, locations) => {
    const required = (this.props.form.required || []).map(r => {
      if (r.field === field) r.locations = locations;
      return r;
    });
    this.props.updateForm('required', required);
  };
  validClass = field => {
    const { validation, showValidation } = this.props;
    return !validation[field] && showValidation ? 'input-label invalid' : 'input-label';
  };
  callback = activeKey => this.setState({ activeKey });
  render() {
    const form = this.props.form;
    const isLocked = this.props.isLocked;
    const required = this.props.form.required;
    const activeKey = this.state.activeKey;
    return (
      <div className="step-container" id="cp-container">
        <Card id={this.props.id} className="step-card" padding="40px 20px 25px 20px">
          <div className="step-title">
            <h3 className={this.validClass('customer_profile')}>Customer Profile</h3>
            <p>Let&apos;s gather some information for the type of lead your are searching for.</p>
          </div>
          <div className="step-form">
            <div className="step-input">
              <div className={this.validClass('customer_type')}>
                <Locked isLocked={isLocked} />
                What type of leads are you looking for? *
              </div>
              <div className="input-entry">
                <Selection
                  disabled={isLocked}
                  values={[
                    { title: 'Companies', value: 'companies' },
                    { title: 'Individuals', value: 'individuals' }
                  ]}
                  full={false}
                  value={form.customer_type}
                  onChange={value => this.props.updateForm('customer_type', value)}
                />
              </div>
            </div>

            <div className="step-input">
              <div className={this.validClass('customer_target')}>
                <Locked isLocked={isLocked} />
                Who is the target customer? *
              </div>
              <div className="input-entry">
                <Input
                  disabled={isLocked}
                  value={form.customer_target}
                  onChange={e => this.props.updateForm('customer_target', e.target.value)}
                  size="large"
                  className="forms"
                  placeholder="New home buyers, NY Restaurants, etc..."
                />
              </div>
            </div>

            <div className="step-input">
              <div className={this.validClass('customer_decision_maker')}>
                <Locked isLocked={isLocked} />
                Who is the decision maker? *
              </div>
              <div className="input-entry">
                <Input
                  disabled={isLocked}
                  value={form.customer_decision_maker}
                  onChange={e => this.props.updateForm('customer_decision_maker', e.target.value)}
                  size="large"
                  className="forms"
                  placeholder="Home owner, manager, etc..."
                />
              </div>
            </div>

            <div className="step-sub border-top">
              <h3 className={this.validClass('required')}>
                <Locked isLocked={isLocked} />
                What information should reps collect? * (6 minimum)
              </h3>
              <p>
                Select which lead inputs that are required based on the type of leads you are
                looking for.
              </p>
            </div>

            {!isLocked && (
              <div className="step-input">
                <div className="input-entry">
                  <div className="entry-search">
                    <Select
                      disabled={isLocked}
                      mode="multiple"
                      placeholder="Search and add lead fields"
                      dropdownClassName="sx-dropdown"
                      showArrow={false}
                      value={[]}
                      onSelect={this.handleSelect}
                      className="forms-light"
                      style={{ width: '100%' }}
                    >
                      {Object.keys(leadFields).map(c => (
                        <Select.OptGroup key={c} label={c}>
                          {Object.keys(leadFields[c]).map(f => (
                            <Select.Option
                              disabled={
                                required.find(r => r.field === leadFields[c][f].field) || isLocked
                                  ? true
                                  : false
                              }
                              key={leadFields[c][f].field}
                              value={leadFields[c][f].field}
                            >
                              {f}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                      ))}
                    </Select>
                    <FontAwesomeIcon icon={faSearch} />
                  </div>
                </div>
              </div>
            )}

            <div className="step-body">
              <Collapse className="sx-collapse" activeKey={activeKey} onChange={this.callback}>
                {required.map(r => {
                  const panelName = getKeyByValue(r.field);
                  const panelHeader = (
                    <div className="panel-header">
                      {!isLocked && (
                        <div className="panel-actions">
                          <Popconfirm
                            title="Are you sure remove this field?"
                            onConfirm={() => this.removeReq(r)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <span onClick={e => this.removeReq(e, r)}>
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </span>
                          </Popconfirm>
                        </div>
                      )}
                      {panelName}
                      {r.type === 'Multiple' && ` (${r.values.length})`}
                      {r.type === 'Location' && ` (${r.locations.length})`}
                    </div>
                  );

                  return (
                    <Collapse.Panel
                      disabled={r.type ? false : true}
                      showArrow={r.type ? true : false}
                      header={panelHeader}
                      key={r.field}
                    >
                      {r.type === 'Multiple' && (
                        <Multiple
                          disabled={isLocked}
                          placeholder={`Select ${panelName}...`}
                          notFoundContent="Type to search..."
                          autocomplete={pickerFields[r.field]}
                          value={r.values || undefined}
                          searchOnEmpty={true}
                          onChange={value => this.updateValues(r.field, value)}
                          filter={false}
                        />
                      )}
                      {r.type === 'Location' && (
                        <MultipleLocations
                          disabled={isLocked}
                          allLocations
                          placeholder="Search locations..."
                          notFoundContent="Type to search locations..."
                          autocomplete={[]}
                          value={r.locations}
                          onChange={location => this.updateLocations(r.field, location)}
                        />
                      )}
                    </Collapse.Panel>
                  );
                })}
              </Collapse>
            </div>
          </div>
        </Card>
        <div className="step-tips">&nbsp;</div>
      </div>
    );
  }
}
