import React, { Component } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { numberWithCommas, decimalToPercent } from '_assets/js/helpers';
import { Loading } from '_seriph';
import './NotInterestedChart.scss';

export default class NotInterestedChart extends Component {
  customTooltip = data => {
    const payload = data.payload || [];
    return (
      <div className="chart-tooltip">
        <div className="tip-box">
          <div className="tip-title">{data.label}</div>
          <div className="tip-contents">
            {payload.map((p, i) => (
              <div key={`tip-${i}`} className="tip-item">
                <div className="tip-circle" style={{ backgroundColor: p.stroke }} />
                <div className="tip-name">{p.name}</div>
                <div className="tip-value">{numberWithCommas(p.value) || '0'}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  render() {
    const { data, range, loading } = this.props;

    const tickCount = data && data.length > 0 ? data[0].data.length : 0;
    const interval = tickCount > 7 ? Math.ceil(tickCount / 7) : 0;

    return (
      <div className="chart-box" id="overview-not-interested-chart">
        <div className="chart-title">
          <h4>
            Not interested leads<span>({range.title})</span>
          </h4>
          <p>Total leads not interested by sentiment</p>
        </div>
        <div className="chart-display">
          {loading ? (
            <div className="chart-loading" style={{ minHeight: '360px' }}>
              <Loading />
            </div>
          ) : (
            <React.Fragment>
              <div className="left-chart">
                <ResponsiveContainer height={460} width="99%">
                  <LineChart margin={{ right: 32 }}>
                    <CartesianGrid stroke="#E7ECF4" strokeDasharray="0 0" vertical={false} />
                    <XAxis
                      interval={interval}
                      tickLine={false}
                      axisLine={false}
                      dataKey="name"
                      type="category"
                      allowDuplicatedCategory={false}
                    />
                    <YAxis
                      tickLine={false}
                      axisLine={false}
                      dataKey="value"
                      domain={[0, 'dataMax + 2']}
                      allowDecimals={false}
                      width={40}
                    />
                    <Tooltip content={this.customTooltip} />
                    {data.map(s =>
                      s?.total <= 0 ? null : (
                        <Line
                          dataKey="value"
                          data={s.data}
                          name={s.name}
                          key={s.name}
                          {...s.settings}
                        />
                      )
                    )}
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div className="right-chart">
                <h4>By sentiment</h4>
                <div className="dash-data">
                  {data?.map((d, i) => (
                    <div className={`dash-sts ${d.total <= 0 ? 'na' : ''}`} key={`ni-${i}`}>
                      <div>
                        <span style={{ backgroundColor: d.settings.stroke }} />
                        {d.name}
                      </div>
                      <h2>
                        {decimalToPercent(d.percent)} ({numberWithCommas(d.total) || '0'})
                      </h2>
                    </div>
                  ))}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}
