import React, { Component } from 'react';
import { 
  BarChart, 
  Bar, 
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip ,
  ResponsiveContainer,
  Legend
} from 'recharts';
import { numberWithCommas } from '_assets/js/helpers';
import { Loading } from '_seriph';
import './TouchpointChart.scss';

export default class TouchpointChart extends Component {
  customTooltip = data => {
    const payload = data.payload || [];
    return (
      <div className="chart-tooltip">
        {payload.map((p, i) => {
          return (
            <div key={`tip-${i}`} className="tip-box">
              <div className="tip-title">{p.name} ({p.payload.name})</div>
              <div className="tip-contents">
                <div className="tip-item">
                  <div className="tip-name">Total XDR&apos;s</div>
                  <div className="tip-value">{numberWithCommas(p.value)} rep(s)</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  render() {
    const { data, loading } = this.props;

    return (
      <div className="chart-box" id="activity-touchpoint-chart">
        <div className="chart-title">
          <h4>
            Touchpoint activity
          </h4>
          <p>Details on your current touchpoints being completed</p>
        </div>
        <div className="chart-display">
          {loading ? (
            <div className="chart-loading" style={{ minHeight: '360px' }}>
              <Loading />
            </div>
          ) : (
            <ResponsiveContainer height={360} width="99%">
              <BarChart data={data}>
                <CartesianGrid stroke="#E7ECF4" strokeDasharray="0 0" vertical={false} />
                <XAxis
                  tickLine={false}
                  axisLine={false}
                  dataKey="name"
                />
                <YAxis
                  tickLine={false}
                  axisLine={false}
                  domain={[0, 'dataMax + 2']}
                  allowDecimals={false}
                  width={40}
                />
                <Tooltip content={this.customTooltip} />
                <Legend />
                <Bar dataKey="Previous" fill="#e8e4e8" />
                <Bar dataKey="Current" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>

          )}
        </div>
      </div>
    );
  }
}
