import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, Button, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { createImport } from '_graphql/mutations/campaign';
import { getErrors } from '_assets/js/helpers';
import FieldMapping from '_styleguide/FieldMapping/FieldMapping';
import './CSVMapping.scss';
import { isValidPostalCode, isValidCountry, isValidPhone } from 'src/_helpers/fieldValidators';

class CSVMapping extends Component {
  state = {
    visible: true,
    saving: false,
    mappedData: [],
    isDataCompliant: true
  };
  validateDataCompliance = () => {
    let isCompliant = true;
    const phoneFields = this.state.mappedData
      .filter(d => d.mapType === 'custom' && d.customType === 'phone')
      .map(d => d.csvKey);
    phoneFields.push('phone', 'mobile');
    this.props.allData.map(row => {
      if (isCompliant) {
        let country = row['country'];
        let postalCode = row['zip'] || row['postal_code'];

        if (postalCode !== undefined && postalCode !== '' && !isValidPostalCode(postalCode)) {
          isCompliant = false;
        }
        phoneFields.map(phone => {
          if (row[phone] !== undefined && row[phone] !== '' && !isValidPhone(row[phone])) {
            isCompliant = false;
          }
        });
        if (
          country !== '' &&
          country !== undefined &&
          !isValidCountry(country)) {
          isCompliant = false;
        }
      }
    });
    return isCompliant;
  };
  validateMappings = () => {
    const missing = [];
    const isMapped = {
      first_name: null,
      last_name: null,
      company: null,
      mobile: null,
      phone: null,
      email: null
    };
    const mappedKeys = Object.keys(isMapped);
    (this.state.mappedData || []).forEach(m => {
      isMapped[m.leadProperty] =
        m.mapType === 'mapped' && mappedKeys.includes(m.leadProperty) ? true : null;
    });
    if (isMapped.first_name === null && isMapped.last_name === null && isMapped.company === null) {
      missing.push('At least one "Name" must be mapped (First and Last Name or Company Name)');
    }
    if (isMapped.mobile === null && isMapped.phone === null && isMapped.email === null) {
      missing.push('At least one contact method must be mapped (Email, Phone, Mobile)');
    }
    return missing;
  };
  createImport = () => {
    const missing = this.validateMappings();
    const isCompliant = this.validateDataCompliance();

    if (missing && missing.length > 0) {
      message.error(missing.join(', ') + '. Please adjust your CSV/Mappings before saving', 10);
      return;
    }
    if (!isCompliant && this.state.isDataCompliant) {
      this.setState({ isDataCompliant: false });
      return;
    }

    this.setState({ saving: true });
    const importData = {
      name: this.props.fileName,
      crm: this.props.fromCrm || null,
      crm_auto_import: this.props.autoImport || false,
      crm_list_id: this.props.crmListId || null,
      crm_object_type: this.props.crmObjectType || null,
      type: 'csv',
      mappings: JSON.stringify(this.state.mappedData),
      json_data: JSON.stringify(this.props.allData),
      campaign_id: this.props.campaignId,
      import_type: this.props.importType
    };
    this.props.client
      .mutate({ variables: { import: importData }, mutation: createImport })
      .then(result => {
        if (result?.data?.createImport) {
          setTimeout(() => {
            this.props.reload;
            this.props.reload(this.props.allData, this.state.mappedData, result.data.createImport);
            this.hideModal();
          }, 1000);
        } else {
          this.setState({ saving: false });
        }
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Error creating import, try again.');
      });
  };
  hideModal = () => this.setState({ visible: false });
  confirmClose = () => {
    Modal.confirm({
      title: 'Are you sure you want to close?',
      content: 'Closing will cancel all imported leads and any mappings you have created.',
      onOk: this.hideModal
    });
  };
  render() {
    const { mappedData, saving } = this.state;
    const {
      actionText = 'Import',
      cancelText = 'Cancel',
      confirmClose = false,
      extraClasses
    } = this.props;

    const unmatchedCount = mappedData.filter(d => {
      return (
        d.mapType === 'unmatched' ||
        (d.mapType === 'mapped' && !d.leadProperty) ||
        (d.mapType === 'custom' && (!d.customType || !d.customName))
      );
    }).length;
    const readyForSubmit = unmatchedCount <= 0;

    return (
      <Modal
        wrapClassName={'csv-mapping-modal ' + extraClasses}
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={false}
        width={800}
      >
        <div className="csv-content">
          <div className="mapping-header">
            <h4>Select CSV fields to import</h4>
            <div className="close-icon" onClick={confirmClose ? this.confirmClose : this.hideModal}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
          <FieldMapping
            isDataCompliant={this.state.isDataCompliant}
            data={this.props.data}
            dataCount={this.props.dataCount}
            updateData={mappedData => this.setState({ mappedData })}
            alertText={this.props.alertText}
            campaignCustomFields={this.props.campaignCustomFields}
            historicalMap={this.props.historicalMap}
          />
          <div className="mapping-actions">
            <Button className="btnx btnx-neutral btnx-large" onClick={this.hideModal}>
              {cancelText}
            </Button>
            <Button
              loading={saving}
              disabled={!readyForSubmit}
              className="btnx btnx-primary btnx-large"
              onClick={this.createImport}
            >
              {actionText} {this.props.dataCount} leads
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(CSVMapping));
