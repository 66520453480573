import React, { Component } from 'react';
import { Menu as AntdMenu } from 'antd';
import './Menu.scss';

class Menu extends Component {
  render() {
    const props = { ...this.props };
    props.className = 'seriph-menu ' + (props.className || '');

    // Handle Type (dark - default, light)
    if (props.type) {
      props.className += ' menu-' + props.type;
      delete props.type;
    }

    return <AntdMenu {...props} />;
  }
}

Menu.Item = AntdMenu.Item;
Menu.Divider = AntdMenu.Divider;
export default Menu;
