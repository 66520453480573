import React from 'react';
import { Input as AntdInput } from 'antd';
import { IconBolt, IconAlertCircle, IconInfoCircle, IconThumbUp } from '@tabler/icons-react';
import { Tooltip, Tag } from '_seriph';
import './Input.scss';

const Input = React.forwardRef((incomingProps, ref) => {
  const props = { ...incomingProps };
  let containerClassName = 'seriph-input';

  // Handle Size (medium, large)
  if (props.size) {
    containerClassName += ' input-' + props.size;
    delete props.size;
  }

  // Handle Input Icons
  if (props.iconLeft) containerClassName += ' input-left';
  if (props.iconRight) containerClassName += ' input-right';

  // Handle Error/Success
  if (props.error) containerClassName += ' seriph-error';
  if (props.success) containerClassName += ' seriph-success';
  if (props.dark) containerClassName += ' dark';

  const iconLeft = props.iconLeft;
  const iconRight = props.iconRight;

  props.error = props.error?.toString();
  props.success = props.success?.toString();
  delete props.iconLeft;
  delete props.iconRight;
  delete props.ref;

  return (
    <div className={containerClassName}>
      {props.label && (
        <label className="seriph-label">
          {props.label}
          {props.info && (
            <Tooltip title={props.info}>
              <IconInfoCircle size={16} stroke={1.75} />
            </Tooltip>
          )}
        </label>
      )}
      <div className="input-box">
        {props.tagValue ? (
          <div className="tag-input" onClick={props.onClear}>
            <Tag size="large" canHide>
              {props.tagValue}
            </Tag>
          </div>
        ) : (
          <AntdInput {...props} ref={ref} />
        )}
        {iconLeft && <span className="input-icon-left">{iconLeft}</span>}
        {iconRight && <span className="input-icon-right">{iconRight}</span>}
      </div>
      {props.hint && (
        <div className="seriph-hint">
          {!props.success && !props.error && <IconBolt size={16} stroke={1.75} />}
          {props.success && <IconThumbUp size={16} stroke={1.75} />}
          {props.error && <IconAlertCircle size={16} stroke={1.75} />}
          <span>{props.hint}</span>
        </div>
      )}
    </div>
  );
});

export default Input;
