import React, { Component } from 'react';
import { Modal, Loading, Button, Avatar, Toggle as Switch, message, Quill } from '_seriph';
import './Emailer.scss';
import { getLeadsForEmailer, getEmailFromLead } from '_graphql/queries/email';
import SmartTags from 'src/App/Company/Sequences/_modals/TouchpointModal/_components/SmartTags/SmartTags';
import {
  IconReplace,
  IconTag,
  IconChevronRight,
  IconBan,
  IconCircleCheck,
  IconAlertTriangle,
  IconConfetti
} from '@tabler/icons-react';
import { sendFromEmailer } from '_graphql/mutations/email';
import { completeStep } from '_graphql/mutations/lead';
import classNames from 'classnames';
import { generateSignature } from '_helpers/signature';
import { checkProblems } from './_helpers';
import { prettyPhone } from '_assets/js/helpers';

export default class Emailer extends Component {
  state = {
    lastFocus: null,
    selectingTag: false,
    visible: true,
    loading: true,
    isSending: false,
    subject: '',
    replacedSubject: '',
    leadsWithStuff: [],
    mainArea: '',
    replacedMainArea: '',
    toEmail: '',
    fromEmail: '',
    wasModified: false,
    realData: false,
    lead: null,
    template_id: null,
    task_id: null,
    day: null,
    numSmartTags: null,
    company: {},
    campaign: {},
    problems: [],
    validEmail: true,
    isSendable: false,
    noneLeft: false,
    hidingLeadId: null,
    numEmailsSent: 0,
    initialNumEmails: 0,
    sigExpanded: false
  };
  hideModal = () => {
    this.setState({ visible: false });
  };
  componentDidMount = async () => {
    await this.loadAllLeads();
  };

  loadAllLeads = async () => {
    let leadsWithStuff = (
      await this.props.client.query({
        query: getLeadsForEmailer
      })
    )?.data?.getLeadsForEmailer;
    // uncomment to test empty state
    // leadsWithStuff = [];

    const leadsData = leadsWithStuff || [];
    leadsData.sort((x, y) => {
      var n = x.lead?.current_task?.day - y.lead?.current_task?.day;
      if (n !== 0) return n;
      return x.lead?.current_task?.step - y.lead?.current_task?.step;
    });

    this.setState({
      leadsWithStuff: leadsData,
      loading: false,
      initialNumEmails: leadsData?.length || 0
    });
    if (leadsData?.length) {
      this.loadLead(leadsData[0]);
    } else {
      this.setState({ loading: false, noneLeft: true });
    }
  };

  loadLead = async ({
    lead,
    template_id,
    day,
    task_id,
    modifiedMainArea = '',
    modifiedSubject = '',
    resetExpanded = true
  }) => {
    this.setState({
      loading: true,
      hasErrors: false
    });

    let results = (
      await this.props.client.query({
        query: getEmailFromLead,
        variables: {
          lead_id: lead.id,
          template_id,
          main_area: modifiedMainArea,
          subject: modifiedSubject
        }
      })
    )?.data?.getEmailFromLead;
    let {
      from,
      subject,
      replacedSubject,
      mainArea,
      replacedMainArea,
      numSmartTags,
      company,
      campaign,
      problems,
      validEmail,
      disableSignature
    } = results;

    const adjusted = checkProblems(mainArea, replacedMainArea, problems);
    problems = adjusted.problems;

    const hasErrors = problems.find(p => p.type === 'error');
    const hasWarnings = problems.find(p => p.type === 'warning');

    this.setState({
      loading: false,
      lead,
      template_id,
      day,
      task_id,
      from,
      subject,
      replacedSubject,
      mainArea: adjusted.mainArea,
      replacedMainArea: adjusted.replacedMainArea,
      numSmartTags,
      company,
      campaign,
      problems,
      validEmail,
      hasErrors,
      hasWarnings,
      sigExpanded: resetExpanded ? false : this.state.sigExpanded,
      disableSignature
    });
  };

  toggleRealData = async () => {
    if (this.state.wasModified && !this.state.realData) {
      const { lead, template_id, mainArea, subject, day, task_id } = this.state;
      await this.loadLead({
        lead,
        template_id,
        task_id,
        day,
        modifiedMainArea: mainArea,
        modifiedSubject: subject,
        resetExpanded: false
      });
    }
    this.setState({ realData: !this.state.realData });
  };

  handleMainAreaModified = value => {
    if (!this.state.realData) {
      this.setState({ wasModified: true, mainArea: value });
    }
  };

  handleSubjectModified = value => {
    if (!this.state.realData) {
      this.setState({ wasModified: true, subject: value });
    }
  };

  flipSwitchIfNeeded = () => {
    if (this.state.realData) {
      //this.toggleRealData();
    }
  };

  getUserEmail = () => {
    const username = this.props.userInfo?.email_username || 'user';
    const domain = this.state.campaign?.custom_domain;
    return `${username}@${domain}`;
  };

  addTagToContent = tag => {
    let quillRef;
    if (this.state.lastFocus === 'subject') {
      quillRef = this.subjectRef.getEditor();
    } else if (this.state.lastFocus === 'mainArea') {
      quillRef = this.mainAreaRef.getEditor();
    }
    var range = quillRef.getSelection();
    let position = range ? range.index : 0;
    quillRef.insertText(position, '{{' + tag + '}}');
  };

  sendActual = async () => {
    if (this.state.isSending) {
      return;
    }

    const prevRealData = this.state.realData;
    if (!prevRealData) await this.toggleRealData();
    this.modalRef.current?.scrollIntoView();
    this.setState({ isSending: true });

    const result = (
      await this.props.client.mutate({
        variables: {
          task_id: this.state.task_id,
          lead_id: this.state.lead.id,
          mainArea: this.state.mainArea,
          subject: this.state.subject,
          signature: this.state.disableSignature
            ? ''
            : generateSignature(this.state.company, this.properAgentPhone(this.props.agent), this.state.campaign)
        },
        mutation: sendFromEmailer
      })
    )?.data?.sendFromEmailer;

    if (!result?.success) {
      message.error(`We couldn't send the email`);
      this.setState({ isSending: false });
      return;
    }

    message.success(`Email sent to ${this.state.lead.email}`);

    await this.getNextLead();
    this.setState({ isSending: false });
    if (!prevRealData) await this.toggleRealData();
  };

  skipStep = async () => {
    this.setState({ isSending: true });
    this.props.client
      .mutate({
        variables: {
          leadId: this.state.lead.id,
          taskId: this.state.task_id,
          typeId: 'skip',
          skipType: 'other',
          skipReason: 'Invalid email address'
        },
        mutation: completeStep
      })
      .then(async result => {
        if (result && result.data && result.data.completeStep) {
          message.success('Task skipped due to invalid email');
          await this.getNextLead();
          this.setState({ isSending: false });
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        this.setState({ isSending: false });
        message.error('Error skipping email, try again');
      });
  };

  getNextLead = async () => {
    const currentIndex = this.state.leadsWithStuff.findIndex(
      ({ lead }) => lead.id === this.state.lead.id
    );
    let nextLead;
    if (currentIndex !== -1) {
      nextLead = this.state.leadsWithStuff?.[currentIndex + 1];
    }

    this.setState({
      numEmailsSent: this.state.numEmailsSent + 1,
      hidingLeadId: this.state.leadsWithStuff?.[currentIndex]?.lead?.id
    });

    if (nextLead) {
      await this.loadLead(nextLead);
      let newLeadsWithStuff = this.state.leadsWithStuff;
      newLeadsWithStuff.splice(currentIndex, 1);
      this.setState({ leadsWithStuff: newLeadsWithStuff, isSending: false });
    } else {
      this.setState({ isSending: false, noneLeft: true });
    }
  };

  expandClicked = () => {
    this.setState({
      sigExpanded: true
    });
  };
  properAgentPhone = (agentData) => {
    const realAgent = this.state.campaign?.agents?.find(a => a.agent_id = agentData.id);
    if (realAgent) {
      let agent = {...agentData, phone_number: prettyPhone(realAgent.phone_number)};
      return agent;
    } else {
      return agentData;
    }
  };

  render() {
    this.modalRef = React.createRef();
    if (this.state.noneLeft) {
      return (
        <Modal
          wrapClassName="emailer-modal-none-left"
          visible={this.state.visible}
          title={null}
          footer={null}
          afterClose={this.props.removeModal}
          onCancel={this.hideModal}
          width={456}
          centered={true}
          closable={true}
        >
          <div className="none-left-body">
            <div className="none-left-icon">
              <IconConfetti />
            </div>
            <div className="none-left-title"> All done, Nice work! </div>
            <div className="none-left-subtitle">
              You’ve sent out all emails required for this task. Continue to your next set of tasks
            </div>
            <Button type="primary" size="large" onClick={this.hideModal}>
              Continue to tasks
            </Button>
          </div>
        </Modal>
      );
    }

    return (
      <Modal
        wrapClassName="emailer-modal overflow-scroll"
        visible={this.state.visible}
        title={null}
        footer={null}
        afterClose={() => {
          this.props.removeModal();
          this.loadAllLeads();
        }}
        onCancel={this.hideModal}
        style={{ top: 32 }}
        width={'auto'}
        closable={false}
      >
        <Modal.Close onClick={this.hideModal} />
        <div className="emailer-body">
          <div className="emailer-left">
            <div className="emailer-list-header">
              <h3 className="emailer-list-header-title">Bulk email</h3>
              <div className="emailer-list-header-description">
                {this.state.numEmailsSent} of {this.state.initialNumEmails} sent
              </div>
            </div>
            <div className="emailer-list">
              {this.state.leadsWithStuff.map(({ lead, template_id, day, task_id }) => (
                <React.Fragment key={lead.id}>
                  <div
                    className={classNames({
                      'emailer-list-item': true,
                      selected: lead.id === this.state.lead?.id,
                      hiding: lead.id === this.state.hidingLeadId
                    })}
                    onClick={() => this.loadLead({ lead, template_id, day, task_id })}
                  >
                    <div className="emailer-list-item-left">
                      <div className="lead-name">
                        {lead.first_name} {lead.last_name}
                      </div>
                      <div className="lead-email">{lead.email}</div>
                    </div>
                    {lead.id === this.state.lead?.id ? (
                      <div className="emailer-list-item-right">
                        <div className="icon-container">
                          <IconChevronRight />
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="emailer-list-item-spacer" />
                </React.Fragment>
              ))}
            </div>
          </div>

          <div className="emailer-main">
            {this.state.noneLeft ? (
              <div className="none-left">
                <h2>You have completed all emails. </h2>
              </div>
            ) : this.state.loading || this.state.isSending ? (
              <div className="emailer-loading">
                <Loading />
              </div>
            ) : (
              <div className="emailer-main-cont">
                <div className="subject">
                  <div id="subject-toolbar" className="hidden-toolbar" />
                  <div className="subject-label">Subject:</div>
                  <Quill
                    value={this.state.realData ? this.state.replacedSubject : this.state.subject}
                    readOnly={this.state.realData}
                    onChange={this.handleSubjectModified}
                    onFocus={() => this.setState({ lastFocus: 'subject' })}
                    setRef={el => (this.subjectRef = el)}
                    showTags={true}
                    modules={{
                      toolbar: {
                        container: '#subject-toolbar'
                      }
                    }}
                  />
                </div>

                <div className="main-toolbar-container">
                  <div className="main-toolbar">
                    <button className="ql-bold"></button>
                    <button className="ql-italic"></button>
                    <button className="ql-underline"></button>
                    <div className="emailer-lb">&nbsp;</div>
                    <button className="ql-list" value="ordered"></button>
                    <button className="ql-list" value="bullet"></button>
                    <div className="emailer-lb">&nbsp;</div>
                    <button className="ql-link"></button>
                  </div>
                  <div>
                    <SmartTags
                      onFocus={() => this.setState({ selectingTag: true })}
                      disabled={!this.state.lastFocus || this.state.realData}
                      onSelect={this.addTagToContent}
                      placeholder="Smart tags"
                    />
                  </div>
                </div>

                <div className="emailer-main-form" onClick={this.flipSwitchIfNeeded}>
                  <Quill
                    value={this.state.realData ? this.state.replacedMainArea : this.state.mainArea}
                    readOnly={this.state.realData}
                    onChange={this.handleMainAreaModified}
                    onFocus={() => this.setState({ lastFocus: 'mainArea' })}
                    setRef={el => (this.mainAreaRef = el)}
                    showTags={true}
                    noHeight={true}
                    modules={{
                      clipboard: { matchVisual: false },
                      toolbar: {
                        container: '.main-toolbar'
                      }
                    }}
                  />
                  {!this.state.disableSignature ? (
                    !this.state.sigExpanded ? (
                      <div className="expand-button" onClick={this.expandClicked}>
                        •••
                      </div>
                    ) : (
                      <div
                        className="signature-container"
                        dangerouslySetInnerHTML={{
                          __html: generateSignature(
                            this.state.company,
                            this.properAgentPhone(this.props.agent),
                            this.state.campaign
                          )
                        }}
                      />
                    )
                  ) : null}
                </div>
                <div className="emailer-main-problems">
                  {this.state.problems.length === 0 && this.state.validEmail ? (
                    <div className="problem-none">
                      <IconCircleCheck />
                      <div className="problem-right">No errors. Ready to send!</div>
                    </div>
                  ) : (
                    <React.Fragment>
                      {!this.state.validEmail ? (
                        <div className="problem-error">
                          <IconBan />
                          <div className="problem-right">Invalid Email Address, please skip</div>
                        </div>
                      ) : null}
                      {this.state.hasErrors ? (
                        <div className="problem-error">
                          <IconBan />
                          <div className="problem-right">{this.state.problems[0].message}</div>
                        </div>
                      ) : null}
                      {this.state.hasWarnings ? (
                        <div className="problem-warning">
                          <IconAlertTriangle />
                          <div className="problem-right">{this.state.problems[0].message}</div>
                        </div>
                      ) : null}
                    </React.Fragment>
                  )}
                </div>

                <div className="emailer-main-footer">
                  <div className="emailer-show-with">
                    <Switch checked={this.state.realData} onChange={this.toggleRealData} />
                    <div className="real-label">Show with real data</div>
                  </div>

                  {this.state.validEmail ? (
                    <Button
                      type="primary"
                      className="emailer-send-button"
                      disabled={this.state.hasErrors}
                      loading={this.state.isSending}
                      onClick={this.sendActual}
                    >
                      Send
                    </Button>
                  ) : (
                    <Button
                      type="secondary"
                      className="emailer-send-button"
                      loading={this.state.isSending}
                      onClick={this.skipStep}
                    >
                      Skip
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="emailer-right">
            <div className="emailer-company">
              <div>
                <Avatar type="company" picture={this.state.company.logo} size="icon" />
              </div>
              <div className="emailer-company-name">{this.state.company.name}</div>
              <div>{this.state.campaign?.name}</div>
            </div>
            <div className="emailer-summary">
              <div className="emailer-summary-header">Email & Sequence</div>
              <ul className="emailer-bullets">
                <li>
                  <IconReplace />
                  <div>{!this.state.noneLeft ? `Day ${this.state.day} in sequence` : '---'}</div>
                </li>
                <li>
                  <IconTag />
                  <div>
                    {!this.state.noneLeft ? `${this.state.numSmartTags} smart tags` : '---'}
                  </div>
                </li>
              </ul>
            </div>
            <div className="emailer-sending">
              <div className="emailer-sending-label">Sending to</div>
              <div className="emailer-sending-to">
                {!this.state.noneLeft ? this.state.lead?.email || 'Invalid Email' : '---'}
              </div>
              <div className="emailer-sending-label">From</div>
              <div className="emailer-sending-from">
                {!this.state.noneLeft ? this.getUserEmail() : '---'}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
