import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CallList from './_components/CallList/CallList';
import CallSettings from './_components/CallSettings/CallSettings';
import CallInformation from './_components/CallInformation/CallInformation';
import CallBar from './_components/CallBar/CallBar';
import ChangeNumber from './_components/ChangeNumber/ChangeNumber';
import { io } from 'socket.io-client';
import { capitalize } from '_assets/js/helpers';
import { hasMoreCalls } from './_helpers/twilio';
import { Device as DeviceSdk } from '@twilio/voice-sdk';
import { getLeadName } from '_helpers/lead';
import { IconPhone, IconSettings } from '@tabler/icons-react';
import { Tooltip, message } from '_seriph';
import './CallWindow.scss';

class CallWindow extends Component {
  state = {
    calls: this.props.list || [],
    currentCall: this.props.list[0].id,
    endCall: null,
    makeCall: null,
    status: 'ready',
    failed: false,
    testStatus: 'init',
    infover: null,
    socketId: null,
    needDisposition: false,
    menuTab: 'phone',
    Device: null,
    audio: {},
    soundsEnabled: false,
    phoneOptions: this.props.phoneOptions || [],
    phoneSelected: this.props.phoneOptions || [],
    time: 0,
    start: 0,
  };
  componentDidMount = () => {
    this.setupSocket();
  };
  setupSocket = () => {
    this.socket = io(`${process.env.REACT_APP_API_PATH}`, {
      withCredentials: true
    });
    this.socket.on('connect', () => {
      this.setState({ socketId: this.socket.id });
    });
    this.socket.onAny(this.listener);
  };
  componentWillUnmount = () => {
    this.socket?.offAny(this.listener);
    this.socket?.close();
    if (this.timer) clearInterval(this.timer);
  };
  listener = (eventName, ...args) => {
    if (eventName === 'dialerUpdate') {
      const eventData = args[0];
      this.updateCall(
        eventData.leadId,
        eventData.callKey,
        {
          callSID: eventData.callSID,
          status: eventData.status,
          errorMessage: eventData.errorMessage,
        }
      );
    }
  };
  selectCurrentCall = leadId => {
    this.setState({ currentCall: leadId });
  };
  showInfo = (text, icon) => {
    if (!text) {
      this.setState({ infover: null });
    } else {
      this.setState({ infover: { icon, text }});
    }
  };
  findNextLead = (isSubmitted) => {
    let nextLead = null;

    // Complete current lead
    let calls = [...this.state.calls];
    calls = calls.map(c => {
      if (c.id === this.state.currentCall) {
        c.completed = true;
        if (isSubmitted) c.submitted = true;
      }
      return c;
    });

    // Find first lead with available calls
    const leadIndex = calls.findIndex(l => l.id === this.state.currentCall);
    if (leadIndex) {
      for (let i = leadIndex || 0; i < calls.length; i++) {
        const lead = calls[i];
        const hasCalls = lead.calls.find(c => c.called === false);
        if (hasCalls && !nextLead && !lead.completed) {
          nextLead = lead.id;
        }
      }
    }
    if (!nextLead) {
      calls.forEach(lead => {
        const hasCalls = lead.calls.find(c => c.called === false);
        if (hasCalls && !nextLead && !lead.completed) {
          nextLead = lead.id;
        }
      });
    }

    // If next lead found, get it ready to go
    this.setState({
      calls,
      currentCall: nextLead ? nextLead : this.state.currentCall,
      status: 'ready'
    }, () => {
      const leadEl = document.getElementById('ls-' + nextLead);
      leadEl?.scrollIntoView({ behavior: 'smooth' });
      if (nextLead && this.state.makeCall && this.props.settings?.autoDial) {
        const actualLead = calls.find(l => l.id === nextLead);
        if (actualLead) this.state.makeCall(actualLead);
      }
    });
  };
  updateCall = async (leadId, callKey, newData) => {
    const calls = [...this.state.calls];
    const leadIndex = calls.findIndex(l => l.id === leadId);
    const callIndex = calls[leadIndex].calls.findIndex(c => c.key === callKey);
    const currentCall = calls[leadIndex].calls[callIndex];
    const currentLead = calls[leadIndex];
    let needDisposition = false;
    if (currentCall) {
      if (!calls[leadIndex].started) calls[leadIndex].started = true;
      calls[leadIndex].calls[callIndex] = {...currentCall, ...newData};
      if (newData.status === 'ringing') {
        this.setState({ calls, status: newData.status, needDisposition });
        this.showInfo(`Calling ${getLeadName(currentLead)} (${capitalize(currentCall.name)})`);
      } else if (newData.status === 'in-progress') {
        this.startTimer();
        this.setState({ calls, status: newData.status });
        this.showInfo(`Connected to ${getLeadName(currentLead)} (${capitalize(currentCall.name)})`);
        if (currentCall.extension && this.state.pressKey && this.props.settings?.autoDialExtensions) {
          try {
            setTimeout(() => this.state.pressKey(currentCall.extension), 2000);
          } catch (err) {
            console.log(err);
          }
        }
      } else if (['canceled', 'completed', 'failed', 'no-answer', 'busy'].includes(newData.status)) {
        const failed = ['failed', 'busy'].includes(newData.status) ? true : false;
        const noAnswer = ['failed', 'busy', 'no-answer', 'canceled'].includes(newData.status) ? true : false;
        if (newData.status === 'failed' && newData.errorMessage) message.info('Call to this number failed ('+newData.errorMessage+'), feel free to continue');
        if (newData.status === 'failed' && !newData.errorMessage) message.info('Call failed for an unknown reason, maybe consider changing your number');
        if (newData.status === 'busy') message.info('Call returned busy, feel free to continue');
        this.stopTimer();
        if (this.state.endCall) this.state.endCall();
        calls[leadIndex].calls[callIndex].called = true;
        if (newData?.callSID && !noAnswer) needDisposition = newData.callSID;
        if (hasMoreCalls(calls[leadIndex], this.state.phoneSelected)) {
          this.setState({ calls, status: 'ready', needDisposition, failed }, async () => {
            if (!needDisposition && this.state.makeCall && this.props.settings?.autoDial) {
              this.state.makeCall(calls[leadIndex]);
            }
          });
        } else {
          // Auto complete lead if necessary
          const noAnswers = (currentLead?.calls || []).filter(c => c.disposition === 'answered');
          if (!needDisposition && this.props.settings?.auto_complete_no_answer && noAnswers.length <= 0 && this.state.completeTask) {
            this.setState({ calls }, () => this.state.completeTask(currentLead, false, true));
          } else {
            this.setState({ calls, status: 'ended', needDisposition, failed });
          }
          
        }
      }
    }
  };
  goToEnd = () => this.setState({ status: 'ended', needDisposition: false });
  repPhone = campaign => {
    const rep = campaign?.agents?.find(r => r.agent_id === this.props.user?.id);
    return rep?.phone_number;
  };
  callDisposition = (leadId, callSID, disposition) => {
    let calls = [...this.state.calls];
    calls = calls.map(c => {
      if (c.id === leadId) {
        c.calls.map(j => {
          if (j.callSID === callSID) j.disposition = disposition;
        })
      }
      return c;
    });
    this.setState({ calls });
  };
  meetingScheduled = leadId => {
    let calls = [...this.state.calls];
    calls = calls.map(c => {
      if (c.id === leadId) c.meetingScheduled = true;
      return c;
    });
    this.setState({ calls });
  };
  noCallUpdated = (leadId, no_call_list) => {
    let calls = [...this.state.calls];
    calls = calls.map(c => {
      if (c.id === leadId) c.no_call_list = no_call_list;
      return c;
    });
    this.setState({ calls });
  };
  tagsUpdated = (leadId, tags) => {
    let calls = [...this.state.calls];
    calls = calls.map(c => {
      if (c.id === leadId) c.tags = tags;
      return c;
    });
    this.setState({ calls });
  };
  notesUpdated = (leadId, count) => {
    let calls = [...this.state.calls];
    calls = calls.map(c => {
      if (c.id === leadId) c.notes_count = count;
      return c;
    });
    this.setState({ calls });
  };
  setDialer = Device => {
    this.setState({ Device });
  };
  updateMicOptions = () => {
    let inputDevices = [],
      outputDevices = [];
    this.state.Device?.audio.availableInputDevices.forEach(d => {
      if (!inputDevices.find(i => i.label === d.label)) inputDevices.push({ value: d.deviceId, label: d.label });
    });
    this.state.Device?.audio.availableOutputDevices.forEach(d =>
      outputDevices.push({ value: d.deviceId, label: d.label })
    );
    this.setState({
      audio: {
        inputDevices,
        audioInput: inputDevices.length > 0 ? inputDevices[0].value : undefined,
        outputDevices,
        audioOutput: outputDevices.length > 0 ? outputDevices[0].value : undefined
      }
    });
  };
  changeDevice = val => {
    const audio = { ...this.state.audio };
    audio.audioInput = val;
    this.setState({ audio }, () => {
      this.state.Device.audio.setInputDevice(val);
    });
  };
  updateStatusSounds = (soundsEnabled) => {
    this.setState({ soundsEnabled: soundsEnabled }, () => {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.Device._enabledSounds[DeviceSdk.SoundName.Outgoing] = soundsEnabled;
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.Device._enabledSounds[DeviceSdk.SoundName.Disconnect] = soundsEnabled;
    });
  };
  changeOutput = val => {
    const audio = { ...this.state.audio };
    audio.audioOutput = val;
    this.setState({ audio }, () => {
      this.state.Device.audio.speakerDevices.set(val);
    });
  };
  startTimer = () => {
    this.setState({ time: 0, start: Date.now() }, () => {
      this.timer = setInterval(() => this.setState({ time: Date.now() - this.state.start }), 1000);
    });
  };
  stopTimer = () => {
    this.setState({ time: 0, start: 0 });
    clearInterval(this.timer);
  };
  updateStatus = status => this.setState({ status });
  updateEndCall = (endCall, pressKey) => this.setState({ endCall, pressKey });
  updateCompleteTask = completeTask => this.setState({ completeTask });
  setMakeCall = makeCall => this.setState({ makeCall });
  updateNeedDisposition = needDisposition => this.setState({ needDisposition });
  setMenuTab = menuTab => this.setState({ menuTab });
  changePhoneSelected = phoneSelected => {
    const remaining = [...this.state.phoneOptions].filter(o => !phoneSelected.includes(o));
    const order = [...phoneSelected, ...remaining];

    const callSorted = [...this.state.calls].map(l => {
      if (l.started || l.completed) return l;
      const callOrder = [...order];
      l.calls.forEach(c => {
        const newIndex = order.findIndex(o => c.name === o);
        if (newIndex >= 0) callOrder[newIndex] = {
          ...c, skip: remaining.includes(c.name) ? true : false
        };
      });

      // handle duplicates
      const callsCopy = [];
      callOrder.forEach(c => {
        let duplicate = false
        if (c.number && callsCopy.find(a => a.number === c.number)) {
          duplicate = true;
        }
        callsCopy.push({...c, duplicate: duplicate});
      });

      l.calls = callsCopy;
      return l;
    });
    this.setState({ phoneSelected, calls: callSorted });
  };
  getUserEmail = () => {
    const username = this.props.info?.email_username || 'user';
    const domain = this.props.campaign?.custom_domain;
    return `${username}@${domain}`;
  };
  changeTestStatus = testStatus => this.setState({ testStatus });
  render() {
    const { campaign, agent } = this.props;
    const { calls, status, currentCall, infover, socketId, needDisposition, menuTab, soundsEnabled } = this.state;

    let currentLead = {...calls.find(c => c.id === currentCall)};

    const repPhoneNumber = this.repPhone(campaign);
    const settingsDisabled = status !== 'ready';

    const audio = {
      ...this.state.audio,
      changeDevice: this.changeDevice,
      changeOutput: this.changeOutput
    };

    const agentWithData = {...agent, phone: repPhoneNumber, email: this.getUserEmail() };

    return (
      <div id="call-window">

        <div className={`infover ${infover ? 'visible' : ''}`}>
          {infover?.icon}
          {infover?.text}
        </div>

        <div className="cw-left">
          { menuTab === 'phone' ? (
            <CallList 
              calls={calls} 
              currentCall={currentCall}
              campaign={campaign}
              settingsDisabled={settingsDisabled}
              selectCurrentCall={this.selectCurrentCall}
            />
          ) : null }
          { menuTab === 'settings' ? (
            <CallSettings 
              settings={this.props.settings}
              updateSettings={this.props.updateSettings}
              settingsDisabled={settingsDisabled}
              audio={audio}
              info={this.props.info}
              changePhoneSelected={this.changePhoneSelected}
              phoneOptions={this.state.phoneOptions}
              phoneSelected={this.state.phoneSelected}
              updateStatusSounds={this.updateStatusSounds}
              soundsEnabled={soundsEnabled}
              manualDial={this.props.manualDial}
            />
          ) : null }
          <ChangeNumber
            client={this.props.client}
            user={this.props.user}
            campaign={campaign}
            repPhoneNumber={repPhoneNumber}
            reload={this.props.reload}
            changeTestStatus={this.changeTestStatus}
            testStatus={this.state.testStatus}
            settingsDisabled={settingsDisabled}
          />
          <div className="menu-tabs">
            <Tooltip title="Dial List">
              <div
                onClick={() => this.setMenuTab('phone')}
                className={`mtab ${menuTab === 'phone' ? 'active' : ''}`}
              >
                <IconPhone />
              </div>
            </Tooltip>
            <Tooltip title="Call Settings">
              <div
                onClick={() => this.setMenuTab('settings')}
                className={`mtab ${menuTab === 'settings' ? 'active' : ''}`}
              >
                <IconSettings />
              </div>
            </Tooltip>
          </div>
        </div>

        <div className="cw-right">
          <div className="caller-window">
            <CallInformation
              client={this.props.client}
              currentLead={currentLead}
              campaign={campaign}
              agent={agentWithData}
              meetingScheduled={this.meetingScheduled}
              tagsUpdated={this.tagsUpdated}
              notesUpdated={this.notesUpdated}
            />
            <CallBar 
              client={this.props.client}
              calls={calls}
              currentLead={currentLead}
              campaign={campaign}
              status={status}
              failed={this.state.failed}
              needDisposition={needDisposition}
              updateStatus={this.updateStatus}
              showInfo={this.showInfo}
              user={this.props.user}
              repPhoneNumber={repPhoneNumber}
              socketId={socketId}
              updateCall={this.updateCall}
              updateEndCall={this.updateEndCall}
              updateCompleteTask={this.updateCompleteTask}
              setMakeCall={this.setMakeCall}
              updateNeedDisposition={this.updateNeedDisposition}
              findNextLead={this.findNextLead}
              setDialer={this.setDialer}
              updateMicOptions={this.updateMicOptions}
              testStatus={this.state.testStatus}
              settings={this.props.settings}
              time={this.state.time}
              phoneSelected={this.state.phoneSelected}
              manualDial={this.props.manualDial}
              hideModal={this.props.hideModal}
              showSettings={this.props.showSettings}
              toggleSettings={this.props.toggleSettings}
              noCallUpdated={this.noCallUpdated}
              callDisposition={this.callDisposition}
              goToEnd={this.goToEnd}
            />
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(CallWindow));
