import React, { Component } from 'react';
import { Table, Input, Header } from '_seriph';
import { adminCompanyApplications } from '../_graphql/queries';
import ReviewApplicationModal from './_modals/ReviewApplicationModal/ReviewApplicationModal';
import OnboardCompanyModal from './_modals/OnboardCompanyModal/OnboardCompanyModal';
import { getColumns } from './Columns';
import './CompanyApplicationsV2.scss';

export default class CompanyApplicationsV2 extends Component {
  state = {
    data: [],
    selectedRowKeys: [],
    pagination: { pageSize: 20 },
    loading: false,
    selected: null,
    searchText: '',
    lastParams: null
  };
  componentDidMount() {
    this.fetch({ results: 20, page: 1 });
  }
  fetch = (params = {}) => {
    this.setState({ loading: true });
    const loadParams = this.state.lastParams || params;
    this.props.client
      .query({
        variables: {
          name: this.state.searchText,
          roleType: 'company',
          ...loadParams
        },
        query: adminCompanyApplications
      })
      .then(result => {
        if (result && result.data) {
          const applications = result.data.adminCompanyApplications.data || [];
          const pagination = { ...this.state.pagination };
          pagination.total = result.data.adminCompanyApplications.pageTotal;
          pagination.totalAll = result.data.adminCompanyApplications.total;
          this.setState({
            loading: false,
            data: applications,
            pagination,
            lastParams: params
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(() => this.setState({ loading: false }));
  };
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      name: this.state.searchText,
      ...filters
    });
  };
  updateSearch = searchText => {
    this.setState({ searchText }, () => {
      this.fetch({ results: 20, page: 1, name: searchText });
    });
  };
  showAppModal = app => this.setState({ appModal: app });
  removeAppModal = () => this.setState({ appModal: false });
  showOnboardModal = app => this.setState({ onboardModal: app });
  removeOnboardModal = () => this.setState({ onboardModal: false });
  render() {
    const { pagination, data, loading } = this.state;
    const total = pagination.totalAll || 0;
    const totalFiltered = pagination.total || 0;
    const columns = getColumns(this.showAppModal, this.showOnboardModal);

    return (
      <div id="admin-company-applications">
        <div className="admin-header">
          <Header type="display" weight="600" size="4">
            Company Applications
          </Header>
          <div>
            Showing {totalFiltered} of {total}
          </div>
          <Input
            placeholder="Search"
            value={this.state.searchText}
            onChange={e => this.updateSearch(e.target.value)}
            ref={node => (this.searchInput = node)}
          />
        </div>
        <Table
          lined
          rounded
          columns={columns}
          rowKey={record => record.id}
          dataSource={data}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
          scroll={{ x: 'max-content' }}
          locale={{ emptyText: loading ? 'Loading applications...' : 'No applications found' }}
        />

        {this.state.appModal ? (
          <ReviewApplicationModal
            client={this.props.client}
            app={this.state.appModal}
            reload={this.fetch}
            removeModal={this.removeAppModal}
          />
        ) : null}
        {this.state.onboardModal ? (
          <OnboardCompanyModal
            client={this.props.client}
            app={this.state.onboardModal}
            showAppModal={() => this.showAppModal(this.state.onboardModal)}
            reload={this.fetch}
            removeModal={this.removeOnboardModal}
          />
        ) : null}
      </div>
    );
  }
}
