import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Input, Button, Select, message } from 'antd';
import GooglePlaces from '_shared/GooglePlaces/GooglePlaces';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { updateAgentProfileUser } from 'Mutations/Agent/updateAgentProfile';
import { updateAgentProfile } from 'Store/agent/agent_actions';
import { loginUser } from 'Store/user/user_actions';
import { LANGUAGES } from '_assets/js/languages';
import Multiple from '_forms/Multiple/Multiple';
const { Option } = Select;

class UserEdit extends Component {
  state = {
    saving: false,
    total_experience: this.props.agent.total_experience,
    employment: this.props.agent.employment,
    languages: this.props.agent.languages,
    location: this.props.agent.location,
    url_linkedin: this.props.agent.url_linkedin,
    url_twitter: this.props.agent.url_twitter,
    url_facebook: this.props.agent.url_facebook,
    url_website: this.props.agent.url_website
  };
  UNSAFE_componentWillMount = () => {
    this.setInitial();
  };
  save = () => {
    this.setState({ saving: true });
    if (!this.checkValid()) {
      message.error('Please fill out required (*) fields before saving');
      return;
    }
    this.props.apollo
      .mutate({
        variables: {
          total_experience: this.state.total_experience,
          employment: this.state.employment,
          languages: this.state.languages,
          location: {
            name: this.state.location.name,
            lat: this.state.location.lat,
            lng: this.state.location.lng
          },
          url_linkedin: this.state.url_linkedin,
          url_twitter: this.state.url_twitter,
          url_facebook: this.state.url_facebook,
          url_website: this.state.url_website
        },
        mutation: updateAgentProfileUser
      })
      .then(result => {
        if (result && result.data && result.data.updateAgentInformation) {
          message.success('Profile saved');
          this.props.loginUser(result.data.updateAgentInformation);
          this.props.reload();
          this.props.close();
        } else {
          this.setState({ saving: false });
        }
      })
      .catch(() => {
        message.error('Issue saving information, try again');
        this.setState({ saving: false });
      });
  };
  setInitial = () => {
    this.setState({
      saving: false,
      location: this.state.location,
      total_experience: this.state.total_experience,
      employment: this.state.employment,
      languages: this.state.languages,
      url_linkedin: this.state.url_linkedin,
      url_twitter: this.state.url_twitter,
      url_facebook: this.state.url_facebook,
      url_website: this.state.url_website
    });
  };
  onSelectLocation = location => {
    geocodeByAddress(location)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.setState({
          location: {
            name: location,
            lat: latLng.lat,
            lng: latLng.lng
          }
        });
      })
      .catch(error => console.error('Error', error));
  };
  checkValid = () => {
    if (
      this.state.location &&
      this.state.location.name &&
      this.state.total_experience &&
      this.state.employment &&
      this.state.languages.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  render() {
    return (
      <Modal
        visible={this.props.visible}
        title={null}
        footer={null}
        afterClose={this.setInitial}
        onCancel={this.props.close}
        wrapClassName="sx-theme agent-user-edit"
        confirmLoading={this.state.saving}
        closable={false}
        maskClosable={true}
        centered={true}
      >
        <div className="sx-form">
          <div className="field">
            <label>Location *</label>
            <GooglePlaces
              size="default"
              placeLocation={true}
              placeholder={
                this.state.location && this.state.location.name ? this.state.location.name : null
              }
              inline={true}
              onSelect={this.onSelectLocation}
            />
          </div>

          <div className="field">
            <label>Experience *</label>
            <Select
              value={this.state.total_experience}
              className="sx-select"
              onChange={total_experience => this.setState({ total_experience })}
              dropdownClassName="sx-dropdown"
            >
              <Option value="entry">Pro (1 - 2 years)</Option>
              <Option value="intermediate">Expert (3 - 5 years)</Option>
              <Option value="expert">Specialist (6+ years)</Option>
            </Select>
          </div>

          <div className="field">
            <label>Employment *</label>
            <Select
              value={this.state.employment}
              className="sx-select"
              onChange={employment => this.setState({ employment })}
              dropdownClassName="sx-dropdown"
            >
              <Option value="full">Full time (40+ week)</Option>
              <Option value="part">Part time (up to 25 hrs)</Option>
              <Option value="free">On my free time</Option>
              <Option value="unknown">Not sure</Option>
            </Select>
          </div>

          <div className="field">
            <label>Languages *</label>
            <Multiple
              placeholder="Search languages..."
              notFoundContent="Type to search languages..."
              searchOnEmpty={true}
              autocomplete={LANGUAGES.filter(l => l !== 'All Languages')}
              value={this.state.languages}
              onChange={languages => this.setState({ languages })}
            />
          </div>

          <div className="field">
            <label>LinkedIn</label>
            <Input
              value={this.state.url_linkedin}
              onChange={e => this.setState({ url_linkedin: e.target.value })}
            />
          </div>

          <div className="field">
            <label>Twitter</label>
            <Input
              value={this.state.url_twitter}
              onChange={e => this.setState({ url_twitter: e.target.value })}
            />
          </div>

          <div className="field">
            <label>Facebook</label>
            <Input
              value={this.state.url_facebook}
              onChange={e => this.setState({ url_facebook: e.target.value })}
            />
          </div>

          <div className="field">
            <label>Website</label>
            <Input
              value={this.state.url_website}
              onChange={e => this.setState({ url_website: e.target.value })}
            />
          </div>

          <div className="sx-actions">
            <Button className="btnx btnx-default" onClick={this.props.close}>
              Cancel
            </Button>
            <Button className="btnx btnx-primary" onClick={this.save}>
              Save
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.agent, ...state.apollo };
};

export default connect(mapStateToProps, { updateAgentProfile, loginUser })(UserEdit);
