export const tagRegex = /(?<!"|">){\{(.*?)\}\}/g;
export const spanRegex = /<\/?span[^>]*>/g;

export const convertTagsOnly = templateString => {
  return (templateString || '').replace(spanRegex, '');
};

export const countTags = templateString => {
  const clean = convertTagsOnly(templateString);
  const amount = clean.match(tagRegex);
  return amount?.length || 0;
};
