import moment from 'moment';

export const dateRanges = [
  { value: 'today', title: 'Today', startRange: moment(), endRange: moment() },
  {
    value: 'yesterday',
    title: 'Yesterday',
    startRange: moment().subtract(1, 'days'),
    endRange: moment().subtract(1, 'days')
  },
  {
    value: 'last7',
    title: 'Last 7 days',
    startRange: moment().subtract(6, 'days'),
    endRange: moment()
  },
  {
    value: 'last2weeks',
    title: 'Last 2 weeks',
    startRange: moment().subtract(2, 'weeks'),
    endRange: moment()
  },
  {
    value: 'last30',
    title: 'Last 30 days',
    startRange: moment().subtract(29, 'days'),
    endRange: moment()
  },
  {
    value: 'last90',
    title: 'Last 90 days',
    startRange: moment().subtract(89, 'days'),
    endRange: moment()
  },
  {
    value: 'lastmonth',
    title: 'Last month',
    startRange: moment().subtract(1, 'months').startOf('month'),
    endRange: moment().subtract(1, 'months').endOf('month')
  },
  {
    value: 'lastyear',
    title: 'Last year',
    startRange: moment().subtract(1, 'years').startOf('year'),
    endRange: moment().subtract(1, 'years').endOf('year')
  },
  {
    value: 'weektodate',
    title: 'Week to date',
    startRange: moment().startOf('week'),
    endRange: moment()
  },
  {
    value: 'monthtodate',
    title: 'Month to date',
    startRange: moment().startOf('month'),
    endRange: moment()
  },
  {
    value: 'quartertodate',
    title: 'Quarter to date',
    startRange: moment().startOf('quarter'),
    endRange: moment()
  },
  {
    value: 'yeartodate',
    title: 'Year to date',
    startRange: moment().startOf('year'),
    endRange: moment()
  },
  {
    value: 'all',
    title: 'All',
    startRange: moment('1970-01-01'),
    endRange: moment()
  }
];

export const generateRange = range => {
  const getRange = dateRanges.find(r => r.value === range);
  return getRange
    ? {
        startRange: parseInt(getRange.startRange.format('YYYYMMDD')),
        endRange: parseInt(getRange.endRange.format('YYYYMMDD'))
      }
    : {
        startRange: parseInt(moment('2019-01-01').format('YYYYMMDD')),
        endRange: parseInt(moment().format('YYYYMMDD'))
      };
};
