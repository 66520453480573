import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Input, Select, Button } from '_seriph';
import { pickerData } from '_constants/salesforce';
import { isUrlValid } from '_assets/js/helpers';
import './CompanyInfo.scss';

const blankValidation = () => ({ company_name: '', company_website: '', company_size: '', company_industry: '' });

class CompanyInfo extends Component {
  isValid = () => {
    const validate = blankValidation();
    const form = this.props.form;
    if (!form.company_name) validate.company_name = 'Company name required';
    if (!form.company_website || !isUrlValid(form.company_website)) validate.company_website = 'Please enter a valid URL';
    if (!form.company_size) validate.company_size = 'Company size required';
    if (!form.company_industry) validate.company_industry = 'Industry required';
    for (let v in validate) {
      if (validate[v]) {
        return { isValid: false, validate };
      }
    }
    return { isValid: true, validate };
  };
  nonExist = val => {
    return val !== undefined && val !== null;
  }
  render() {

    const { form, updateForm } = this.props;
    const { validate, isValid } = this.isValid();

    return (
      <div className="join-form-box" id="onboard-company">
        <div className="join-title">
          <h2>Tell us about your company</h2>
        </div>

        <div className="join-fields">
          
          <Input
            label="Company name"
            placeholder="e.g. Google..."
            onChange={e => updateForm('company_name', e.target.value)}
            value={form.company_name}
            error={this.nonExist(form.company_name) && validate.company_name}
          />

          <Input
            label="Website"
            placeholder="e.g. www.website.com..."
            onChange={e => updateForm('company_website', e.target.value)}
            value={form.company_website}
            error={this.nonExist(form.company_website) && validate.company_website}
            hint={this.nonExist(form.company_website) && validate.company_website ? validate.company_website : ''}
          />

          <Select
            showSearch
            label="Company size"
            placeholder="Select a company size..."
            value={form.company_size || undefined}
            onChange={val => updateForm('company_size', val)}
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
          >
            <Select.Option value="1-10">Startup (1 - 10 employees)</Select.Option>
            <Select.Option value="11-200">Small (11 - 200 employees)</Select.Option>
            <Select.Option value="201-500">Mid (201 - 500 employees)</Select.Option>
            <Select.Option value="500+">Large (500+ employees)</Select.Option>
          </Select>

          <Select
            showSearch
            label="Industry"
            placeholder="Select an industry..."
            value={form.company_industry || undefined}
            onChange={val => updateForm('company_industry', val)}
            className="no-bottom"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
          >
            {pickerData.industry.map((p, i) => (
              <Select.Option key={`ind-${i}`} value={p}>
                {p}
              </Select.Option>
            ))}
          </Select>

        </div>

        <div className="join-actions">
          { this.props.next ? (
            <Button disabled={!isValid} type="primary" size="large" onClick={() => this.props.saveOnboard(this.props.next)}>
              Continue
            </Button>
          ) : null }
        </div>
      </div>
    );
  }
}

export default withRouter(CompanyInfo);
