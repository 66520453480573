import React, { Component } from 'react';
import { Select as AntdSelect } from 'antd';
import { IconBolt, IconAlertCircle, IconInfoCircle, IconThumbUp } from '@tabler/icons-react';
import { Tooltip } from '_seriph';
import './Select.scss';

class Select extends Component {
  render() {
    const props = { ...this.props };
    let containerClassName = 'seriph-select';

    // Handle Size (medium, large)
    if (props.size) {
      containerClassName += ' select-' + props.size;
      delete props.size;
    }

    // Handle Select Icon
    const icon = props.icon;
    if (props.icon) containerClassName += ' select-icon';
    delete props.icon;

    // Handle Dropdown ClassName
    if (!props.dropdownClassName) props.dropdownClassName = 'seriph-dropdown';

    // Handle Error/Success
    if (props.error) containerClassName += ' seriph-error';
    if (props.success) containerClassName += ' seriph-success';

    if (props.dark) containerClassName += ' dark';

    return (
      <div className={containerClassName}>
        {props.label && (
          <label className="seriph-label">
            {props.label}
            {props.info && (
              <Tooltip title={props.info}>
                <IconInfoCircle size={16} stroke={1.75} />
              </Tooltip>
            )}
          </label>
        )}
        <div className="input-box">
          <AntdSelect {...props} />
          {icon && <span className="select-icon-left">{icon}</span>}
        </div>
        {props.hint && (
          <div className="seriph-hint">
            {!props.success && !props.error && <IconBolt size={16} stroke={1.75} />}
            {props.success && <IconThumbUp size={16} stroke={1.75} />}
            {props.error && <IconAlertCircle size={16} stroke={1.75} />}
            <span>{props.hint}</span>
          </div>
        )}
      </div>
    );
  }
}

Select.Option = AntdSelect.Option;
Select.OptGroup = AntdSelect.OptGroup;
export default Select;
