export default [
  {
    name: 'Interested',
    slug: 'interested',
    titleText: 'Interested',
    nextWizardStep: 2,
    inputPlaceholder: 'They loved the product...'
  },
  {
    name: 'Not Interested',
    slug: 'not-interested',
    titleText: 'Why not interested?',
    nextWizardStep: 1,
    reasons: [
      {
        name: 'Bad timing',
        slug: 'bad-timing',
        prompt: 'Is there a better time to contact them?'
      },
      {
        name: 'Financial',
        slug: 'financial',
        prompt: 'What else did you find out?'
      },
      {
        name: 'Using another solution already',
        slug: 'another',
        prompt: 'Did they mention what they used and why?'
      },
      {
        name: 'Not the right contact person',
        slug: 'wrong-contact',
        prompt: 'Did they mention who to contact instead?'
      },
      {
        name: 'No reason',
        slug: 'no-reason',
        prompt: 'Was there any indication of why, at all?'
      },
      {
        name: 'Other',
        slug: 'other',
        prompt: 'Give us any info that will help with this lead.'
      }
    ]
  },
  {
    name: 'No Response',
    slug: 'no-response',
    nextWizardStep: 2,
    titleText: 'No Response',
    inputPlaceholder: 'I tried reaching out several times...'
  },
  {
    name: 'Invalid / Duplicate',
    shortName: 'Invalid',
    slug: 'invalid',
    titleText: 'Why invalid?',
    nextWizardStep: 1,
    reasons: [
      {
        name: 'Wrong number',
        slug: 'wrong-number',
        prompt: 'Any further info?'
      },
      {
        name: 'Wrong email / bounced',
        slug: 'bad-email',
        prompt: 'Any further info?'
      },
      {
        name: 'Duplicate',
        slug: 'duplicate',
        prompt: 'Any further info?'
      }
    ]
  }
];
