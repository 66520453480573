import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { adminTriggers } from '../../../_graphql/queries';
import { Header, message, Table, Select } from '_seriph';
import { masqueradeUser } from '../../../_graphql/mutations';
import { loginUser } from 'Store/user/user_actions';
import { getCampaignColumns } from './_columns/CampaignColumns';
import { getXDRColumns } from './_columns/XDRColumns';
import './Trigger.scss';

class Trigger extends Component {
  state = {
    loading: false,
    campaigns: [],
    agents: [],
    triggers: [],
    triggersFilter: undefined,
  };
  componentDidMount = () => {
    this.getTriggers();
  }
  getTriggers = () => {
    if (!this.state.loading) this.setState({ data: null, loading: true });
    this.props.client
      .query({ query: adminTriggers })
      .then(result => {
        if (result?.data?.adminTriggers) {
          const campaigns = result?.data?.adminTriggers?.campaigns || [];
          const agents = result?.data?.adminTriggers?.agents || [];
          const triggers = result?.data?.adminTriggers?.triggers || [];
          this.setState({ campaigns, agents, triggers }, () => {
            this.setState({ loading: false });
          });
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        message.error('Could not load triggers, try again');
        this.setState({ loading: false });
      });
  };
  masq = agent_id => {
    this.props.client
      .mutate({
        variables: { user_id: agent_id },
        mutation: masqueradeUser
      })
      .then(result => {
        if (result && result.data && result.data.masqueradeUser) {
          this.props.loginUser(result.data.masqueradeUser);
          this.props.history.push('/');
        } else {
          throw new Error('Could not login as agent, try again');
        }
      })
      .catch(err => {
        message.error(err.message);
      });
  };
  UNSAFE_componentWillMount = () => window.scrollTo({ top: 0 });
  render() {
    const { loading, campaigns, agents, triggers } = this.state;

    const campaignCount = triggers?.filter(t => t.type === 'campaigns').length;
    let campaignFilters = new Set();
    triggers?.filter(t => t.type === 'campaigns').forEach(t => {
      campaignFilters.add(t.pretty_reason);
    });

    const xdrCount = triggers?.filter(t => t.type === 'xdr').length;
    let xdrFilters = new Set();
    triggers?.filter(t => t.type === 'xdr').forEach(t => {
      xdrFilters.add(t.pretty_reason);
    });

    return (
      <div id="rep-analytics-scorecard">
        <div className="seriph-header-wrapper">
          <Header type="display" weight="600" size="4">
            Trigger Alerts
          </Header>
        </div>

        <div className="trigger-data">

          <div className="trigger-section">
            <Header size="3" font="sans">
              Campaign Alerts ({campaignCount})
            </Header>
            <div className="trigger-filter">
              <Select size="small" placeholder="Filter by..." value={this.state.campaignFilter} onChange={campaignFilter => this.setState({ campaignFilter: campaignFilter === 'all' ? undefined : campaignFilter })}>
                <Select.Option key={'tf-all'} value='all'>All Alerts</Select.Option>
                {[...campaignFilters].map((t, i) => (
                  <Select.Option key={'tf-'+i} value={t}>{t}</Select.Option>
                ))}
              </Select>
            </div>
            <Table
              lined
              rounded
              loading={loading}
              locale={{ emptyText: loading ? 'Loading campaign triggers...' : 'No campaign triggers found' }}
              dataSource={campaigns.filter(c => {
                if (!this.state.campaignFilter) return true;
                const ct = triggers.filter(t => t.pretty_reason === this.state.campaignFilter && t.id === c.id);
                return ct.length > 0 ? true : false;
              }).map(d => {
                d.key = d.id;
                return d;
              })}
              scroll={{ x: 'max-content' }}
              pagination={{ pageSize: 5 }}
              columns={getCampaignColumns(this.masq, triggers)}
            />
          </div>


          <div className="trigger-section">
            <Header size="3" font="sans">
              XDR Alerts ({xdrCount})
            </Header>
            <div className="trigger-filter">
              <Select size="small" placeholder="Filter by..." value={this.state.xdrFilter} onChange={xdrFilter => this.setState({ xdrFilter: xdrFilter === 'all' ? undefined : xdrFilter })}>
                <Select.Option key={'tf-all'} value='all'>All Alerts</Select.Option>
                {[...xdrFilters].map((t, i) => (
                  <Select.Option key={'tf-'+i} value={t}>{t}</Select.Option>
                ))}
              </Select>
            </div>
            <Table
              lined
              rounded
              loading={loading}
              locale={{ emptyText: loading ? 'Loading XDR triggers...' : 'No XDR triggers found' }}
              dataSource={agents.filter(c => {
                if (!this.state.xdrFilter) return true;
                const ct = triggers.filter(t => t.pretty_reason === this.state.xdrFilter && t.id === c.id);
                return ct.length > 0 ? true : false;
              }).map(d => {
                d.key = d.id;
                return d;
              })}
              scroll={{ x: 'max-content' }}
              pagination={{ pageSize: 5 }}
              columns={getXDRColumns(this.masq, triggers)}
            />
          </div>

        </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, { loginUser })(Trigger));
