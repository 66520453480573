import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import logo from '_assets/img/logo.svg';
import queryString from 'query-string';
import { changePassword } from '_graphql/mutations/auth';
import { Input, Button, Header, message } from '_seriph';
import { isPassword } from '_assets/js/helpers';
import './Reset.scss';

class Reset extends Component {
  state = {
    newPassword: '',
    confirmPassword: '',
    tok: null,
    loading: false,
    error: null
  };
  UNSAFE_componentWillMount() {
    const query = queryString.parse(this.props.location.search);
    if (query.tok) {
      this.setState({ tok: query.tok });
    } else {
      this.props.history.push('/');
    }
  }
  shouldDisable = () => {
    const isValid =
      this.state.newPassword &&
      this.state.confirmPassword &&
      this.state.newPassword === this.state.confirmPassword;
    const isAPassword = isPassword(this.state.newPassword);

    let error = null;
    if (!isAPassword)
      error =
        'Password must be 6 characters and contain one (!@#$%^&), a number, and a capitalized letter';
    if (!isValid) error = 'Passwords do not match';

    this.setState({ error });
    return error ? true : false;
  };
  updatePass = () => {
    if (this.shouldDisable()) return;
    this.setState({ loading: true });
    this.props.client
      .mutate({
        variables: { password: this.state.newPassword, token: this.state.tok },
        mutation: changePassword
      })
      .then(result => {
        if (result && result.data && result.data.changePassword) {
          message.success('Password successfully updated, you can now login');
          this.props.history.push('/');
        } else {
          this.setState({ loading: false });
          message.error('Could not update password, try again');
        }
      })
      .catch(() => {
        message.error('Could not update password, try again');
        this.setState({ loading: false });
      });
  };
  render() {
    const { newPassword, confirmPassword, loading, error } = this.state;

    return (
      <div id="sellx-reset" className="signin">
        <div className="home-logo">
          <img src={logo} alt={logo} />
        </div>
        <div className="home">
          <div className="home-left"></div>
          <div className="home-right">
            <div className="home-form">
              <Header type="display" size="4" className="headline">
                Update your password
              </Header>
              <Input
                label="New Password"
                name="newPassword"
                size="large"
                type="password"
                value={newPassword}
                onChange={e => this.setState({ newPassword: e.target.value, error: null })}
              />
              <Input
                label="Confirm Password"
                name="confirmPassword"
                size="large"
                type="password"
                error={error}
                hint={error}
                value={confirmPassword}
                onChange={e => this.setState({ confirmPassword: e.target.value, error: null })}
              />
              <div className="home-actions">
                <Button type="default" size="large" loading={loading} onClick={this.updatePass}>
                  Update Password
                </Button>
              </div>
              <Link to="/">
                <p className="descript">&lt;&nbsp;&nbsp;&nbsp;Sign in</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Reset);
