import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, Button, Select, Input, message } from '_seriph';
import { payBonus } from 'src/App/Admin/_graphql/mutations';
import { getErrors } from '_assets/js/helpers';
import './Bonus.scss';

class Bonus extends Component {
  state = {
    visible: true,
    amount: null,
    reason: null,
    paying: false
  };
  hideModal = () => this.setState({ visible: false });

  onPayClick = () => {
    if (!this.state.amount || !this.state.reason) {
      message.error('Must provide a valid amount up to $100 and reason');
      return;
    }
    this.setState({ paying: true });
    Modal.confirm({
      title: `Are you sure you want to pay ${this.props.agent?.first_name} a bonus of $${this.state.amount}?`,
      okText: 'Pay',
      cancelText: 'Cancel',
      onCancel: () => {
        this.setState({ paying: false });
      },
      onOk: () => {
        this.props.client
          .mutate({
            variables: { 
              agent_id: this.props.agent?.id, 
              amount: this.state.amount,
              reason: this.state.reason 
            },
            mutation: payBonus
          })
          .then(result => {
            if (result?.data?.payBonus) {
              this.setState({ paying: false });
              message.success(`Bonus paid to ${this.props.agent?.first_name}`);
              this.hideModal();
            } else {
              throw new Error();
            }
          })
          .catch(err => {
            this.setState({ paying: false });
            message.error(getErrors(err) || `Could not pay bonus to ${this.props.agent?.first_name}`);
          });
      }
    });
  };

  render() {
    const agent = this.props?.agent;
    return (
      <Modal
        wrapClassName="bonus-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={false}
        width={500}
      >
        <Modal.Close onClick={this.hideModal} />
        <Modal.Header>
          Pay bonus to {agent?.first_name} {agent?.last_name}
        </Modal.Header>

        <div className="bonus-form-cont">
          <div className="bonus-form">
            <Select
              label="Amount"
              value={this.state.amount}
              onChange={value => this.setState({ amount: value })}
            >
              {[...Array(20)]
                .map((x, i) => (i + 1) * 5)
                .map(x => (
                  <Select.Option key={x} value={x}>
                    ${x}
                  </Select.Option>
                ))}
            </Select>
            <Input
              label="Reason"
              placeholder="Bonus earned for..."
              onChange={e => this.setState({ reason: e.target.value })}
              value={this.state.reason}
            />
          </div>
          <Modal.Actions align="right">
            <Button onClick={this.hideModal}>Cancel</Button>
            <Button type="primary" loading={this.state.paying} onClick={this.onPayClick}>
              Pay
            </Button>
          </Modal.Actions>
        </div>

      </Modal>
    );
  }
}

export default withRouter(Bonus);
