import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Input, Tooltip } from '_seriph';
import { faCopy } from '@fortawesome/free-solid-svg-icons';


export const getInviteColumns = (resendLoading, resendInvite, confirmRemoveMember, inputs, copyToClipboard) => {
  return [
    {
      title: 'Name',
      dataIndex: 'used_by',
      key: 'used_by',
      render: (used_by, data) => {
        const unused = !data.used || !used_by;
        return (
          <div className="emp-info">
            <Avatar type="company" picture={unused ? undefined : used_by.picture} size="mini" />
            <h4>{data.email}</h4>
          </div>
        );
      }
    },
    {
      title: '',
      dataIndex: 'invite_link',
      key: 'invite_link',
      align: 'right',
      width: 180,
      render: (used, data) => {
        return !data.used ? (
          <div className="invite-copy">
            <Input size="small" ref={nput => (inputs[data.id] = nput)} value={data.invite_link} />
            <Tooltip title="Copy invite link">
              <Button
                size="tiny"
                className="btnx btnx-default"
                onClick={() => copyToClipboard(data.id)}
              >
                <FontAwesomeIcon icon={faCopy} />
              </Button>
            </Tooltip>
          </div>
        ) : null;
      }
    },
    {
      title: '',
      dataIndex: 'used',
      key: 'used',
      align: 'right',
      width: 180,
      render: (used, data) => {
        return !used ? (
          <div className="bundle">
            <Button
              loading={resendLoading === data.email}
              type="default"
              size="tiny"
              onClick={() => confirmRemoveMember('invite', data.id)}
            >
              Withdraw
            </Button>
            <Button
              loading={resendLoading === data.email}
              type="secondary"
              size="tiny"
              onClick={() => resendInvite(data.email)}
            >
              Send a reminder
            </Button>
          </div>
        ) : (
          <Button disabled={true} type="default">
            Joined {moment(data.used_tstamp * 1).fromNow()}
          </Button>
        );
      }
    }
  ];
};
