import React from 'react';
import { IconMessage } from '@tabler/icons-react';
import moment from 'moment';
import { prettyPhone } from '_assets/js/helpers';

export default function Text(props) {
  const { activity, user, goTo, lead } = props;

  const wasSent = activity.text?.sent ? true : false;
  const optedOut = activity.text?.opt_out ? true : false;

  console.log(activity, user);

  return (
    <div className={'lead-activity ' + activity.type}>
      <div className={!wasSent ? 'act-icon incoming email' : 'act-icon email'}>
        {(wasSent && !optedOut) && <IconMessage />}
        {(!wasSent && !optedOut) && <IconMessage />}
        {optedOut && <IconMessage />}
      </div>
      <div className="descript">
        {(wasSent && !optedOut) && (
          <React.Fragment>
            <b>{user.display_name}</b> sent a text message to{' '}
            <b>
              {lead.first_name + ' ' + lead.last_name} &lt;{prettyPhone(activity.text.to_number)}&gt;
            </b>
          </React.Fragment>
        )}
        {(!wasSent && !optedOut) && (
          <React.Fragment>
            <b>{lead.first_name + ' ' + lead.last_name}</b> replied to{' '}
            <b>
              {user.display_name} &lt;{prettyPhone(activity.text.to_number)}&gt; via text message
            </b>
          </React.Fragment>
        )}
        {optedOut && (
          <React.Fragment>
            <b>
              {lead.first_name + ' ' + lead.last_name} 
            </b>{' '}
            opted out of receiving text messages
          </React.Fragment>
        )}
        <div className="act-tstamp">
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;{moment(activity.createdAt * 1).format('MMM D @ h:mm a')}
        </div>
        &nbsp;&middot;&nbsp;
        <div className="sxd-link" onClick={() => goTo('texts', ``)}>
          { wasSent ? 'View Text' : 'See Reply'}
        </div>
      </div>
    </div>
  );
}
