import React, { Component } from 'react';
import { Collapse } from 'antd';
import './Accordion.scss';

class Accordion extends Component {
  render() {
    const props = { ...this.props };
    props.className = 'seriph-accordion ' + (props.className || '');

    // Handle weird children use-cases
    let children = [];
    (this.props.children || []).forEach(c => {
      if (Array.isArray(c)) {
        children = [...children, ...c];
      } else if (c) {
        children = [...children, c];
      }
    });

    return (
      <Collapse {...props}>
        {children.map(c => {
          return !c
            ? null
            : React.cloneElement(c, {
                className: c.props.menu ? 'panel-menu' : '',
                disabled: c.props.disabled,
                header: c.props.icon ? (
                  <div className="panel-icon">
                    {c.props.icon}
                    <span className="panel-name">{c.props.header}</span>
                    {c.props.amount && c.props.amount > 0 ? (
                      <span className="amount">{c.props.amount}</span>
                    ) : null}
                  </div>
                ) : (
                  c.props.header
                )
              });
        })}
      </Collapse>
    );
  }
}

Accordion.Panel = Collapse.Panel;
export default Accordion;
