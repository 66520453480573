import gql from 'graphql-tag';

export const removeAgent = gql`
  mutation removeAgent(
    $campaign_id: ID!
    $agent_id: ID!
    $review: String!
    $quality_of_work: Float!
    $communication: Float!
    $skills: Float!
  ) {
    removeAgent(
      campaign_id: $campaign_id
      agent_id: $agent_id
      review: $review
      quality_of_work: $quality_of_work
      communication: $communication
      skills: $skills
    )
  }
`;

export const bringBackAgent = gql`
  mutation bringBackAgent($campaign_id: ID!, $agent_id: ID!) {
    bringBackAgent(campaign_id: $campaign_id, agent_id: $agent_id)
  }
`;

export const leaveCampaign = gql`
  mutation leaveCampaign($campaign_id: ID!, $reason: String!) {
    leaveCampaign(campaign_id: $campaign_id, reason: $reason)
  }
`;

export const favoriteCampaign = gql`
  mutation favoriteCampaign($campaign_id: ID!) {
    favoriteCampaign(campaign_id: $campaign_id) {
      favorites
    }
  }
`;

export const removeFavorite = gql`
  mutation removeFavorite($campaign_id: ID!) {
    removeFavorite(campaign_id: $campaign_id) {
      favorites
    }
  }
`;
