import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Header, Table, Button } from '_seriph';
import { getFellowAdmins } from '../_graphql/queries';
import { allCompanies } from '_graphql/queries/company';
import { loginUser } from 'Store/user/user_actions';
import { getColumns } from './Columns';
import ManageAdminUser from './_modals/ManageAdminUser/ManageAdminUser';
import './ManageUsers.scss';

class ManageUsers extends Component {
  state = {
    loading: true,
    admins: [],
    editModal: false,
    selectedAdminId: '',
    selectedAdminDisplayName: '',
    selectedAdminRole: '',
    selectedAdminEmail: [],
    selectedCompanies: [],
    allCompanies: [],
    createNew: false
  };
  async componentDidMount() {
    this.setState({ loading: true });
    await this.getFellowAdmins();
    await this.getAllCompanies();
    this.setState({ loading: false });
  }
  getFellowAdmins = async () => {
    const result = await this.props.client.query({
      query: getFellowAdmins
    });
    const admins = result.data.getFellowAdmins;
    this.setState({ admins });
  };
  getAllCompanies = async () => {
    const result = await this.props.client.query({
      query: allCompanies
    });
    this.setState({ allCompanies: result.data.allCompanies });
  };
  showEditModal = user => {
    if (user !== 'new') {
      this.setState({
        editModal: {
          user_id: user.user.id,
          display_name: user.display_name,
          role: user.role,
          company_ids: user.companies.map(c => c.id),
          email: user.user.email
        }
      });
    } else {
      this.setState({ editModal: user });
    }
  };
  removeEditModal = () => this.setState({ editModal: false });
  render() {
    const columns = getColumns(this.showEditModal);
    const { loading, admins } = this.state;
    return (
      <div className="admin-manage-users">
        <div className="admin-header">
          <Header type="display" weight="600" size="4">
            Manage Users
          </Header>
          <Button type="primary" className="new-button" onClick={() => this.showEditModal('new')}>
            New Admin User
          </Button>
        </div>
        <div>
          <div className="users-table">
            <Table
              lined
              rounded
              rowSelection={null}
              columns={columns}
              rowKey={record => record.user.email}
              dataSource={admins}
              loading={loading}
              onChange={this.handleTableChange}
              scroll={{ x: 'max-content' }}
              locale={{ emptyText: loading ? 'Loading users...' : 'No users' }}
            />
          </div>
        </div>

        {this.state.editModal ? (
          <ManageAdminUser
            client={this.props.client}
            reload={this.getFellowAdmins}
            removeModal={this.removeEditModal}
            allCompanies={this.state.allCompanies}
            form={this.state.editModal}
            user={this.props.user}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, { loginUser })(ManageUsers));
