import React, { Component } from 'react';
import { Header, Button } from '_seriph';
import './Empty.scss';

export default class Empty extends Component {
  render() {
    const { icon, type, title, action, onClick } = this.props;

    return (
      <div className="seriph-empty">
        {icon && <div className={`empty-icon ${type || ''}`}>{icon}</div>}
        <Header type="display" size="3">
          {title}
        </Header>
        <p className="empty-desc">{this.props.children}</p>
        {action && onClick && (
          <Button className="empty-action" type="primary" size="large" onClick={onClick || null}>
            {action}
          </Button>
        )}
      </div>
    );
  }
}
