import React, { Component } from 'react';
import { Modal, Button, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faTimes } from '@fortawesome/free-solid-svg-icons';
import { revertLead } from '_graphql/mutations/lead';
import { getErrors } from '_assets/js/helpers';
import './RevertToEditModal.scss';

export default class RevertToEditModal extends Component {
  state = {
    visible: true,
    maskClosable: true,
    loading: false
  };
  hideModal = () => {
    this.setState({ visible: false });
  };
  revertLead = () => {
    this.setState({ loading: true });
    this.props.client
      .mutate({
        variables: {
          lead_id: this.props.lead.id
        },
        mutation: revertLead
      })
      .then(result => {
        if (result && result.data && result.data.revertLead) {
          setTimeout(() => {
            this.props.reload(this.props.lead.id);
            setTimeout(this.hideModal, 250);
          }, 1500);
        } else {
          throw new Error('Error disputing lead');
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        message.error(getErrors(err) || 'Could not dispute lead, try again');
      });
  };
  render() {
    const { loading } = this.state;
    return (
      <Modal
        wrapClassName="revert-edit-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={this.state.maskClosable}
        width={456}
      >
        <div className="close-icon" onClick={this.hideModal}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="stage-one">
          <div className="stage-icon">
            <FontAwesomeIcon icon={faExclamation} />
          </div>
          <h3>Edit Lead</h3>
          <p>
            Warning, this will put the lead back to <b>In Progress</b> and will have to be
            re-submitted once it is completed.
          </p>
          <div className="stage-actions">
            <Button className="btnx btnx-neutral" onClick={this.hideModal}>
              Cancel
            </Button>
            <Button className="btnx btnx-primary" loading={loading} onClick={this.revertLead}>
              Continue Editing
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}
