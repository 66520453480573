import jwt_decode from 'jwt-decode';
import parsePhoneNumber from 'libphonenumber-js';
import moment from 'moment';

export const getUser = () => {
  const userJWT = localStorage.getItem('JWT');
  let user;
  if (userJWT) {
    try {
      user = jwt_decode(userJWT);
    } catch (err) {
      localStorage.removeItem('JWT');
      window.location.href = '/';
    }
  }
  if (user) {
    let userData = user.user;
    userData.jwt = userJWT;
    return userData;
  }
  return null;
};

export const numberWithCommas = x => {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
};
export const formatMoney = amount =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount || 0);
export const flatMoney = amount => {
  const val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
    amount || 0
  );
  return val.split('.')[0];
};

export const plural = (text, amount) => {
  return text ? text + (amount === 1 ? '' : 's') : text;
};

export const hasLeadGenCampaign = (campaigns, id) => {
  return (campaigns || []).find(c => {
    const agent = c.agents.find(a => a.agent_id === id && a.active === true);
    return agent && c.objective === 'leadgen' && c.status === 'active' ? true : false;
  })
    ? true
    : false;
};

export const getEmailDomain = website => {
  if (!website) return null;
  const stripped = website.replace(/\//g, '');
  const parts = stripped.split('.');
  if (parts && parts.length > 1) {
    return parts[parts.length - 2] + '.' + parts[parts.length - 1];
  } else {
    return null;
  }
};

export const timeFormat = s => {
  const currentSeconds = Math.floor(s);
  const seconds = currentSeconds % 60;
  const minutes = Math.floor(currentSeconds / 60);
  return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
};

export const getProfile = profile => {
  if (profile) {
    const data = jwt_decode(profile);
    // console.log('GETTING PROFILE: ', data);
    return data.profile;
  }
  return null;
};

export const deepCopy = obj => {
  if (obj) {
    return JSON.parse(JSON.stringify(obj));
  }
  return obj;
};

export const isEmail = fullEmail => {
  const email = (fullEmail || '').toLowerCase();
  var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(email);
};

export const isCalendly = calendly => {
  var re = /http(?:s)?:\/\/(?:www\.)?calendly\.com\/([a-zA-Z0-9_]+)/;
  return re.test(calendly);
};

export const isName = name => {
  var re = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/;
  return re.test(name);
};

export const isCompany = company => {
  var re =
    /^(?=(?=([^{}()<>]*\[+[^{}()<>]*\]+[^{}()<>]*))\1*$|(?=([^[\]()<>]*\{+[^[\]()<>]*\}+[^[\]()<>]*))\2*$|(?=([^[\]{}<>]*\(+[^[\]{}<>]*\)+[^[\]{}<>]*))\3*$|(?=([^[\]{}()]*<+[^[\]{}()]*>+[^[\]{}()]*))\4*$|(?=([^[\]{}()<>]*))\5*$)(?=(?=([^"]*“+[^"]*”+[^"]*))\6*$|(?=([^“”]*"+[^“”]*"+[^“”]*))\7*$|(?=([^"“”]*))\8*$)(?=(?=([^']*‘+[^']*’+[^']*))\9*$|(?=([^‘’]*'+[^‘’]*'+[^‘’]*))\10*$|(?=([^'‘’]*))\11*$)[A-Za-z0-9 "“”'‘’()[\]{}<>«»\\/?!&@£$€¥.,-]{3}[A-Za-z0-9 "“”'‘’()[\]{}<>«»\\/?!&@£$€¥.,\-*=#%+]{0,157}$/;
  return re.test(company);
};

export const isPassword = password => {
  var re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
  return re.test(password);
};

export const isMediumPassword = password => {
  var re = /^(?=.*\d)(?=.*[a-z]).{6,}$/;
  return re.test(password);
};

export const linkify = inputText => {
  var replacedText, replacePattern1, replacePattern2;
  if (!inputText) return 'https://sellx.com';

  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
  replacedText = inputText.replace(replacePattern1, '$1');

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePattern2, 'http://$2');

  //Check if missing http
  replacedText = replacedText.indexOf('://') === -1 ? 'http://' + replacedText : replacedText;

  return replacedText;
};

export const LevenshteinDistance = function (a, b) {
  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;

  var matrix = [];

  // increment along the first column of each row
  var i;
  for (i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  // increment each column in the first row
  var j;
  for (j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  // Fill in the rest of the matrix
  for (i = 1; i <= b.length; i++) {
    for (j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1, // substitution
          Math.min(
            matrix[i][j - 1] + 1, // insertion
            matrix[i - 1][j] + 1
          )
        ); // deletion
      }
    }
  }

  return matrix[b.length][a.length];
};

export const downloadFile = (fileName, urlData) => {
  var aLink = document.createElement('a');
  aLink.download = fileName;
  aLink.href = urlData;
  var event = new MouseEvent('click');
  aLink.dispatchEvent(event);
};

export const FormatCountdown = seconds => {
  if (!seconds) return null;
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  // var m = Math.floor(seconds % 3600 / 60);
  // var s = Math.floor(seconds % 60);

  var dDisplay = d > 0 ? d + (d === 1 ? ' day' : ' days') : '';
  var hDisplay = h > 0 ? (d > 0 ? ', ' : '') + h + (h === 1 ? ' hour' : ' hours') : '';
  // var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
  // var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
  return d > 0 || h > 0 ? dDisplay + hDisplay : 'Soon (< 1 hour)';
};

export const FormatWait = seconds => {
  if (!seconds) return null;
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);

  // var dDisplay = d > 0 ? d + (d === 1 ? " day" : " days") : "";
  var hDisplay = h > 0 ? (d > 0 ? ', ' : '') + h + (h === 1 ? ' hour' : ' hours') : '';
  var mDisplay = m > 0 ? (h > 0 ? ', ' : '') + m + (m === 1 ? ' minute' : ' minutes') : '';
  // var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
  return h > 0 || m > 0 ? hDisplay + mDisplay : ' < 1 minute';
};

export const FormatPhone = nput => {
  let newValue = '';
  if (nput[0] === '+' && nput[1] === '1') nput = nput.replace('+1', '');
  const input = nput.replace(/\D/g, '').substring(0, 10);
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    newValue = `${zip}-${middle}-${last}`;
  } else if (input.length === 6) {
    newValue = `${zip}-${middle}`;
  } else if (input.length > 3) {
    newValue = `${zip}-${middle}`;
  } else if (input.length === 3) {
    newValue = `${zip}`;
  } else if (input.length > 0) {
    newValue = `${zip}`;
  }
  return newValue;
};

export const formatPercent = (val, points) => {
  return (val || 0).toFixed(points || 0) + '%';
};

export const decimalToPercent = val => {
  return formatPercent((val || 0) * 100);
};

export const prettyPhone = phoneNumberString => {
  if (!phoneNumberString) return phoneNumberString;
  const phoneNumber = parsePhoneNumber(phoneNumberString, 'US');
  if (phoneNumber && phoneNumber.country === 'US') {
    return phoneNumber.formatNational();
  } else if (phoneNumber) {
    return phoneNumber.formatInternational();
  } else {
    return phoneNumberString;
  }
};

export const standardPhone = phoneNumberString => {
  if (!phoneNumberString) return phoneNumberString;
  const phoneNumber = parsePhoneNumber(phoneNumberString, 'US');
  if (phoneNumber) {
    return phoneNumber.number;
  } else {
    return phoneNumberString;
  } 
};

export const noCall = (list, number) => {

  const isInList = list?.find(l => {
    const listNumber = standardPhone(l);
    const realNumber = standardPhone(number);
    return listNumber === realNumber;
  });

  return isInList ? true : false;

};

export const getLeadPhone = (lead, number) => {
  const leadNumbers = {
    'Primary': lead.phone?.number || lead.phone,
    'Mobile': lead.mobile?.number || lead.mobile,
  };
  lead?.custom_fields?.forEach(c => {
    if (c.type === 'phone') leadNumbers[c.label] = standardPhone(c.value);
  });
  const actualNumber = standardPhone(number);
  let label = prettyPhone(number);
  for (let key in leadNumbers) {
    if (leadNumbers[key] === actualNumber) {
      label = key;
    }
  }

  return label;
}

export const callDuration = duration => {
  let mins = moment.duration(duration * 1000).minutes();
  if (mins < 10) mins = `0${mins}`;
  let secs = moment.duration(duration * 1000).seconds();
  if (secs < 10) secs = `0${secs}`;
  return `${mins}:${secs}`
}

export const phoneValid = phoneNumberString => {
  const phoneNumber = parsePhoneNumber(phoneNumberString, 'US');
  if (phoneNumber && phoneNumber.isValid()) {
    return true;
  } else {
    return false;
  }
};

export const isUrlValid = userInput => {
  if (!userInput) return false;
  var res = userInput.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );
  if (res == null) {
    return false;
  } else {
    return true;
  }
};

export const stripHTML = html => {
  const div = document.createElement('div');
  div.innerHTML = html;
  const text = div.textContent || div.innerText || '';
  return text;
};

export const delay = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const slicer = (val, amt) => {
  return (
    (Array.isArray(val) ? val.slice(0, amt).join(', ') : val.slice(0, amt)) +
    (val.length >= amt ? '...' : '')
  );
};

export const capitalize = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getErrors = error => {
  if (error && error.message) {
    return error.message.replace('GraphQL error: ', '');
  } else if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
    return error.graphQLErrors[0].message.replace('GraphQL error: ', '');
  } else {
    return null;
  }
};
