import React, { Component } from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import './Checkbox.scss';

class Checkbox extends Component {
  render() {
    const props = { ...this.props };
    props.className = 'seriph-checkbox ' + (props.className || '');
    if (props.reverse === true) {
      props.className += ' reverse';
    }
    if (props.fontMinor === true) {
      props.className += ' font-minor';
    }
    if (props.noBg === true) {
      props.className += ' no-bg';
    }

    // Handle Size (regular - default, small)
    if (props.size) {
      props.className += ' size-' + props.size;
      delete props.size;
    }
    return <AntdCheckbox {...props} />;
  }
}

Checkbox.Group = AntdCheckbox.Group;
export default Checkbox;
