import React, { Component } from 'react';
import { Select } from 'antd';
import { INDUSTRIES } from '_assets/js/constants';
import './MultipleIndustries.scss';

const { Option } = Select;

export default class Multiple extends Component {
  state = {
    term: '',
    loaded: false
  };
  onSearch = term => this.setState({ term });
  onChange = value => {
    const results = (value || []).map(result => {
      return INDUSTRIES.find(i => i.name === result);
    });
    this.props.onChange(results);
  };
  handleChange = value => {
    console.log(`selected ${value}`);
  };
  render() {
    let children = [];
    const term = this.state.term || '';
    if (term.length > 0) {
      children = INDUSTRIES.filter(industry => {
        return industry.name.toLowerCase().includes(term.toLowerCase());
      });
      if (children.length > 10) children = children.slice(0, 10);
    }

    const results = children.map(industry => {
      return (
        <Option key={industry.code} value={industry.name}>
          {industry.name}
        </Option>
      );
    });

    return (
      <Select
        className="multiple"
        mode="multiple"
        size="large"
        style={{ width: '100%' }}
        placeholder={this.props.placeholder}
        value={this.props.value.map(v => v.name)}
        onChange={this.onChange}
        onSearch={this.onSearch}
        notFoundContent={this.props.notFoundContent}
      >
        {results}
      </Select>
    );
  }
}
