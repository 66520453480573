import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import './Quill.scss';

export default class Quill extends Component {
  render() {
    const modules = {
      toolbar: [
        { header: '1' },
        { header: '2' },
        'bold',
        'italic',
        'underline',
        'link',
        { list: 'ordered' },
        { list: 'bullet' }
      ],
      clipboard: { matchVisual: false }
    };

    return (
      <div className="sx-quill sx-form">
        <ReactQuill
          ref={el => (this.props.setRef ? this.props.setRef(el) : null)}
          modules={modules}
          {...this.props}
        />
      </div>
    );
  }
}
