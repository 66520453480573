import { getUser } from '_assets/js/helpers';
import { message } from 'antd';

// Rep Types: Rep, Company, SellX
const repType = user => {
  if (user?.sellx_rep) {
    return 'SellX';
  } else if (user?.company_rep || user?.company?.company_id) {
    return 'Company';
  }
  return 'Rep';
};

export const hasAccess = (permissions = []) => {
  const user = getUser();
  let access = null;
  if (user?.role_type === 'agent') {
    access = repType(user);
  } else if (user?.role_type === 'admin') {
    access = user?.admin?.role;
  } else {
    access = user?.access;
  }
  return permissions.includes(access);
};

export const hasAccessChanged = (permission_type, logout) => {
  const access = getUser()?.access;
  if (!access || !permission_type || permission_type === 'Removed' || access !== permission_type) {
    if (!access || !permission_type || access !== permission_type) {
      message.info('Your permissions have changed, please login again');
    }
    if (permission_type === 'Removed') {
      message.info(
        "You no longer have access, please contact your company's admin to restore access"
      );
    }
    setTimeout(logout, 0);
  }
};

export const hasFeature = (features = [], requested) => {
  return features?.includes(requested);
};

export const isInternal = () => {
  const user = getUser();
  return user?.company_rep || user?.company?.company_id ? true : false;
};