import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  IconWorld,
  IconUserPlus,
  IconCalendar,
  // IconDiamond,
  IconCheck,
  IconUsers
} from '@tabler/icons-react';
import './Tools.scss';

class Tools extends Component {
  render() {
    const { integration, company, data } = this.props;

    const calendly = integration?.calendly?.url ? true : false;
    const chilipiper = integration?.chilipiper?.domain && integration?.chilipiper?.inbound_router ? true : false;
    const email = integration?.custom_domains?.find(i => i.active) ? true : false;
    const calendar = calendly || chilipiper;

    const needTeam = (company?.num_employees + company?.num_reps) >= 1 ? true : false;
    const needCampaign = data?.campaign_count > 0 ? true : false;
    const hasAll = needCampaign && needTeam && email && calendar;

    return hasAll ? null : (
      <div id="internal-tools">

        <div className="tool-row bottom">
          <Link to="/settings/integrations" className={email ? 'tool done' : 'tool'}>
            <span>{ email ? <IconCheck /> : <IconWorld /> }</span>
            <h4>Connect your domain</h4>
          </Link>
          <Link to="/settings/team" className={needTeam ? 'tool done' : 'tool'}>
            <span>{ needTeam ? <IconCheck /> : <IconUserPlus /> }</span>
            <h4>Add your team</h4>
          </Link>
        </div>

        <div className="tool-row">
          <Link to="/settings/integrations" className={calendar ? 'tool done' : 'tool'}>
            <span>{ calendar ? <IconCheck /> : <IconCalendar /> }</span>
            <h4>Integrate your team calendar</h4>
          </Link>
{/*          <Link className={'tool'}>
            <span><IconDiamond /></span>
            <h4>Upgrade & save</h4>
          </Link>*/}
          <Link to="/wizard/new" className={needCampaign ? 'tool done' : 'tool'}>
            <span>{ needCampaign ? <IconCheck /> : <IconUsers /> }</span>
            <h4>Create your first campaign</h4>
          </Link>
        </div>

      </div>
    );
  }
}

export default Tools;
