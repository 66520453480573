export const formData = {
  /* Information */
  stage: 'in-progress',
  first_name: '',
  last_name: '',
  photo_url: '',
  salutation: '',
  description: '',
  primary_language: '',
  education: '',
  race: '',
  gender: '',
  age: '',

  /* Contact Information */
  email: '',
  phone: '',
  mobile: '',
  fax: '',

  /* Company Information */
  company: '',
  company_scope: '',
  job_position: '',
  title: '',
  number_of_employees: null,
  annual_revenue: null,
  industry: '',

  /* Social Information */
  website: '',
  linkedin: '',
  facebook: '',
  twitter: '',
  instagram: '',

  /* Address */
  street: '',
  city: '',
  state: '',
  postal_code: '',
  country: '',
  lat: null,
  lng: null,

  /* Lead Info */
  source: '',
  status: '',
  rating: '',
  do_not_call: false
};

export const importFields = {
  /* Information */
  crm_id: '',

  /* Information */
  first_name: '',
  last_name: '',
  salutation: '',
  description: '',
  primary_language: '',
  education: '',
  race: '',
  gender: '',
  age: '',

  /* Contact Information */
  email: '',
  phone: '',
  mobile: '',
  fax: '',

  /* Company Information */
  company: '',
  company_scope: '',
  job_position: '',
  title: '',
  number_of_employees: null,
  annual_revenue: null,
  industry: '',

  /* Social Information */
  website: '',
  linkedin: '',
  facebook: '',
  twitter: '',
  instagram: '',

  /* Address */
  street: '',
  city: '',
  state: '',
  postal_code: '',
  country: '',

  custom_fields: []
};
