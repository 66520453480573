import React from 'react';
import { Tooltip as AntdTooltip } from 'antd';
import './Tooltip.scss';

const Tooltip = incomingProps => {
  const props = { ...incomingProps };
  props.overlayClassName = 'seriph-tooltip ' + (props.className || '');

  if (props.centered) {
    props.overlayClassName += ' centered';
  }

  delete props.centered;

  return <AntdTooltip {...props} />;
};

export default Tooltip;
