import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReputationText from '_styleguide/ReputationText/ReputationText';
import { hasAccess } from '_helpers/permissions';
import { Avatar, Card, Button, Tooltip } from '_seriph';
import { IconMapPin, IconCalendar, IconClock, IconWorld } from '@tabler/icons-react';
import './Listing.scss';

const DESCRIPTION_MAX = 320;
const FAKE_TEXT = 'This rep does not have a bio.';

export default class Listing extends Component {
  state = {
    loading: false,
    which: null
  };
  messageAgent = () => {
    const agent = this.props.agent;
    this.props.history.push('/messages', {
      id: agent.id,
      display_name: agent.display_name,
      picture: agent.picture
    });
  };
  languages = agent => {
    if (agent.languages.length > 3) {
      return agent.languages.slice(0, 3).join(', ') + ', ...';
    } else {
      return agent.languages.join(', ');
    }
  };
  render() {
    const { agent, campaigns, messages } = this.props;
    let existingMessage;
    if (messages && messages?.length) {
      existingMessage = messages.find(message => {
        return message.agent?.id === agent?.id;
      });
    }
    const about = (agent.about || FAKE_TEXT).substring(0, DESCRIPTION_MAX);

    const hasActiveCampaign = campaigns.find(c => c.status === 'active');
    const hasLanguages = agent && agent.languages && agent.languages.length > 0 ? true : false;
    const bestMatch = agent.bestMatch >= 86 ? true : false;

    const skillSpacer = <span>&nbsp;&nbsp;&nbsp;&nbsp;&#9642;&nbsp;&nbsp;&nbsp;&nbsp;</span>;

    return (
      <div className="company-brief-listing">
        <Card style={{ padding: 0, marginBottom: '16px', position: 'relative' }}>
          <div className="company-listing">
            <div className="listing-top">
              <Link to={`/rep/${agent.id}`}>
                <Avatar
                  type="agent"
                  size="small"
                  picture={agent.picture}
                  reputation={agent.reputation || 1}
                />
              </Link>
              <div className="listing-info">
                <h3>{agent.display_name}</h3>
                <ReputationText reputation={agent.reputation || 1} />
              </div>
            </div>
            <div className="company-information">
              <p>
                {about}
                {(agent.about || FAKE_TEXT).length > DESCRIPTION_MAX ? '...' : ''}
              </p>
              <div className="skills">
                <div className="info">
                  {hasActiveCampaign && false ? (
                    <Tooltip title="The highest calculated fit this sales rep has to your active campaigns">
                      <span className={bestMatch ? 'app-match best-match' : 'app-match'}>
                        {bestMatch ? 'Best Match' : agent.bestMatch + '% Match'}
                      </span>
                    </Tooltip>
                  ) : null}
                  <Tooltip title="Current location">
                    <IconMapPin size={14} stroke={1.75} />
                    <span>{agent.location.name || 'Location Unknown'}</span>
                  </Tooltip>
                  <Tooltip title="Experience">
                    {skillSpacer}
                    <IconCalendar size={14} stroke={1.75} />
                    <span>{agent.experience_type || 'Experience Unknown'}</span>
                  </Tooltip>
                  {agent.employment_type && (
                    <Tooltip title="Availability">
                      {skillSpacer}
                      <IconClock size={14} stroke={1.75} />
                      <span>
                        {agent.employment_type === 'Not sure'
                          ? `Availability: ${agent.employment_type}`
                          : agent.employment_type}
                      </span>
                    </Tooltip>
                  )}
                  {hasLanguages && (
                    <Tooltip title="Languages">
                      {skillSpacer}
                      <IconWorld size={14} stroke={1.75} />
                      <span>{this.languages(agent)}</span>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="company-actions">
            <Button
              type="default"
              onClick={() => {
                if (existingMessage) {
                  this.props.loadMessaging(existingMessage);
                } else {
                  this.props.showModal(agent, false);
                }
              }}
            >
              Message
            </Button>
            {hasActiveCampaign && hasAccess(['Admin', 'Manager']) ? (
              <Button type="secondary" onClick={() => this.props.showModal(agent, true)}>
                Invite
              </Button>
            ) : null}
          </div>
        </Card>
      </div>
    );
  }
}
