import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { addHubspot, removeHubspot } from '_graphql/mutations/integrations';
import queryString from 'query-string';
import { getErrors } from '_assets/js/helpers';
import { Card, Button, message } from '_seriph';
import { IconCheck, IconX, IconPlus, IconClock, IconSettings } from '@tabler/icons-react';
import HubspotLogo from '_assets/brands/hubspot.svg';
import HubspotSettingsModal from './_modals/HubspotSettingsModal/HubspotSettingsModal';
import './Hubspot.scss';

class Hubspot extends Component {
  state = {
    setting: null
  };
  componentDidMount = () => {
    const params = queryString.parse(this.props.location.search);
    if (
      params.type === 'hubspot' &&
      params.access_token &&
      params.refresh_token &&
      params.expires_in
    )
      this.addHubspot(params);
  };
  addHubspot = params => {
    this.setState({ setting: 'hubspot' });
    this.props.client
      .mutate({
        variables: {
          access_token: params.access_token,
          refresh_token: params.refresh_token,
          expires_in: parseInt(params.expires_in),
          portal_id: params.portal_id || null
        },
        mutation: addHubspot
      })
      .then(result => {
        if (result && result.data && result.data.addHubspot) {
          window.history.replaceState(null, null, window.location.pathname);
          setTimeout(() => this.props.loadIntegrations(this.resetSetting), 100);
          message.success('Hubspot successfully integrated');
        }
      })
      .catch(err => {
        this.setState({ setting: null });
        message.error(getErrors(err) || 'Error setting up hubspot, try again');
      });
  };
  removeHubspot = () => {
    this.setState({ setting: 'hubspot' });
    this.props.client
      .mutate({ variables: { refresh: 'false' }, mutation: removeHubspot })
      .then(result => {
        if (result && result.data && result.data.removeHubspot) {
          setTimeout(() => this.props.loadIntegrations(this.resetSetting), 100);
          message.success('Hubspot successfully removed');
        }
      })
      .catch(err => {
        this.setState({ setting: null });
        message.error(getErrors(err) || 'Error removing hubspot, try again');
      });
  };
  hubspot = () => {
    window.location.href = process.env.REACT_APP_API_PATH + '/hubspot/oath';
  };
  showModal = () => this.setState({ modal: true })
  removeModal = () => this.setState({ modal: false })
  resetSetting = () => this.setState({ setting: null });
  render() {
    const { setting } = this.state;
    const { integrations, loading } = this.props;

    const integration = integrations.hubspot ? integrations.hubspot : {};

    return (
      <Card id="hubspot">
        <div className="int">
          <div className="int-header">
            <div className="int-logo">
              <img className="hubspot" src={HubspotLogo} title="Hubspot" alt="Hubspot" />
            </div>
            <h3 className="int-name">
              Hubspot <span>BETA</span>
              { integration.portal_id && <p>Account: {integration.portal_id}</p> }
            </h3>
            {integration.access_token && !loading && (
              <div className="int-check">
                <IconCheck />
              </div>
            )}
          </div>

          <p className="int-desc">Hubspot lets you sync your leads, accounts, contacts and create opportunities!</p>

          {this.props.comingSoon === true ? (
            <div className="int-actions">
              <Button disabled={true} icon="left" type="secondary">
                <IconClock />
                Coming Soon
              </Button>
            </div>
          ) : (
            <div className="int-actions">
              {(!integration.access_token || loading) && (
                <Button
                  disabled={loading}
                  type="secondary"
                  icon="left"
                  loading={setting === 'hubspot'}
                  onClick={this.hubspot}
                >
                  <IconPlus />
                  Connect to Hubspot
                </Button>
              )}
              {integration.access_token && !loading && (
                <React.Fragment>
                  <Button
                    type="secondary"
                    icon="left"
                    onClick={this.showModal}
                  >
                    <IconSettings />Settings
                  </Button>
                  <Button
                    type="danger"
                    icon="left"
                    loading={setting === 'hubspot'}
                    onClick={this.removeHubspot}
                  >
                    <IconX />Disconnect
                  </Button>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
        {this.state.modal ? (
          <HubspotSettingsModal
            integrations={integrations}
            client={this.props.client}
            reload={() => this.props.loadIntegrations(this.resetSetting)}
            removeModal={this.removeModal}
          />
        ) : null}
      </Card>
    );
  }
}

export default withRouter(Hubspot);
