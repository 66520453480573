import React, { Component } from 'react';
import { Input } from '_seriph';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import './Location.scss';

const searchOptions = {
  types: ['address']
};

export default class Location extends Component {
  state = {
    focused: false
  };
  handleChange = address => {
    this.props.onChange('street', address);
  };
  parseComponents = components => {
    const now = {};
    components.forEach(c => {
      now[c.types[0]] = c.long_name;
    });
    return now;
  };
  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        if (results && results.length > 0 && results[0].address_components) {
          const actual = this.parseComponents(results[0].address_components);
          this.props.onChange(
            'street',
            (actual.street_number || '') + (actual.route ? ' ' + actual.route : '')
          );
          this.props.onChange(
            'city',
            actual.locality || actual.administrative_area_level_3 || actual.political || ''
          );
          this.props.onChange('state', actual.administrative_area_level_1 || '');
          this.props.onChange('postal_code', actual.postal_code || '');
          this.props.onChange('country', actual.country || '');
          this.setState({ focused: false });
        }
        return getLatLng(results[0]);
      })
      .then(latLng => {
        this.props.onChange('lat', latLng.lat);
        this.props.onChange('lng', latLng.lng);
      });
  };
  render() {
    let className = 'seriph-location';
    if (this.state.focused) className += ' focused';
    if (this.props.className) className += ` ${this.props.className}`;
    if (this.props.required) className += ` grey-required`;
    if (this.props.soft) className += ` grey-soft-required`;
    return (
      <PlacesAutocomplete
        value={this.props.value || ''}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => {
          return (
            <div className={className}>
              <Input
                {...getInputProps({
                  placeholder: this.props.placeholder || 'Search Places...',
                  disabled: this.props.disabled
                })}
              />
              {suggestions.length > 0 && (
                <div className="places-container">
                  {suggestions.map((suggestion, i) => {
                    const className = suggestion.active ? 'gp-item gp-item--active' : 'gp-item';
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, { className })}
                        key={'suggestion-' + i}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}
