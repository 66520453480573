import React from 'react';
import { Popover as AntdPopover } from 'antd';
import './Popover.scss';

const Popover = incomingProps => {
  const props = { ...incomingProps };
  props.overlayClassName = 'seriph-popover ' + (props.className || '');

  return <AntdPopover {...props} />;
};

export default Popover;
