import React, { Component } from 'react';
import { Steps as AntSteps } from 'antd';
import './Steps.scss';

class Steps extends Component {
  render() {
    const props = { ...this.props };
    props.className = 'seriph-steps ' + (props.className || '');

    // // Handle Size (small (default), medium, large)
    // if (props.size) {
    //   props.className += ' tag-'+props.size;
    //   delete props.size;
    // }

    // // Handle Type (default, primary, warning, info, success, danger)
    // if (props.type) {
    //   props.className += ' tag-'+props.type;
    //   delete props.type;
    // }

    return <AntSteps {...props} />;
  }
}

Steps.Step = AntSteps.Step;
export default Steps;
