import {
  validateSetup,
  validateSalesReps,
  validateCustomerProfile,
  validateLeadInfo,
  validateInstructions,
  validateBudget,
  validateThreshold,
  validateImport,
  validateQuestions,
  validateProduct,
  validateSequences
  //validateSchedule,
} from './validate';

export const genFlow = [
  { label: 'Setup', val: 'setup', validate: validateSetup },
  { label: 'Sales Reps', short: 'Reps', val: 'reps', validate: validateSalesReps },
  {
    label: 'Customer Profile',
    short: 'Profile',
    val: 'customer',
    validate: validateCustomerProfile
  },
  { label: 'Lead Info', short: 'Info', val: 'info', validate: validateLeadInfo },
  { label: 'Instructions', val: 'instructions', validate: validateInstructions },
  { label: 'Budget', val: 'budget', validate: validateBudget },
  { label: 'Review', val: 'review' }
];

export const qualFlow = [
  { label: 'Setup', val: 'setup', validate: validateSetup },
  { label: 'Sales Reps', val: 'reps', validate: validateSalesReps },
  { label: 'Import', val: 'import', validate: validateImport },
  { label: 'Questions', val: 'questions', validate: validateQuestions },
  { label: 'Product', val: 'product', validate: validateProduct },
  { label: 'Instructions', val: 'instructions', validate: validateInstructions },
  { label: 'Sequences', val: 'sequences', validate: validateSequences },
  // { label: 'Schedule', val: 'schedule', validate: validateSchedule },
  { label: 'Budget', val: 'budget', validate: validateBudget },
  { label: 'Review', val: 'review' }
];

export const getFlow = campaign => {
  let selectedFlow = null;
  if (campaign && campaign.objective === 'qualify') {
    selectedFlow = qualFlow;
  } else {
    selectedFlow = genFlow;
  }

  /* Replace budget with threshold if true */
  if (campaign && campaign.threshold) {
    const budgetIndex = selectedFlow.findIndex(f => f.val === 'budget');
    if (budgetIndex) {
      selectedFlow[budgetIndex] = {
        label: 'Threshold',
        val: 'threshold',
        validate: validateThreshold
      };
    }
    if (campaign?.rep_type === 'internal') {
      selectedFlow = selectedFlow.filter(s => s.val !== 'threshold');
    }
  }

  return selectedFlow;
};

export const validate = (campaign, importData, templates) => {
  const validateData = {};

  const flow = getFlow(campaign);

  flow.forEach(f => {
    if (f.validate) {
      validateData[f.val] = f.validate(
        { label: f.label, isValid: true, values: [], missing: [], warning: [] },
        campaign,
        importData,
        templates
      );
    }
  });

  validateData.isValid = true;
  validateData.isWarned = false;
  validateData.invalidCount = 0;
  validateData.warningCount = 0;

  Object.keys(validateData).forEach(k => {
    const missing = validateData[k].missing || [];
    const warning = validateData[k].warning || [];
    if (missing.length > 0) {
      validateData.isValid = false;
      validateData.invalidCount += missing.length;
    }
    if (warning.length > 0) {
      validateData.isWarned = true;
      validateData.warningCount += warning.length;
    }
  });

  return validateData;
};
