import React, { Component } from 'react';
import { message } from 'antd';
import { leadActivity } from '_graphql/queries/lead';
import New from './_components/New';
import Status from './_components/Status';
import Update from './_components/Update';
import Note from './_components/Note';
import Email from './_components/Email';
import Call from './_components/Call';
import Text from './_components/Text';
import Meeting from './_components/Meeting';
import Loading from '_shared/Loading/Loading';
import moment from 'moment';
import './Activity.scss';

export default class Activity extends Component {
  state = {
    loading: true,
    activity: []
  };
  componentDidMount() {
    this.loadActivity(this.props.lead.id);
  }
  UNSAFE_componentWillMount = () => window.scrollTo({ top: 0 });
  createDates = activity => {
    let dates = [];
    activity.forEach(data => {
      dates.push(moment(data.createdAt * 1).format('dddd, MMMM Do YYYY'));
    });
    return [...new Set(dates.reverse())].reverse();
  };
  mapActivity = a => {
    if (a.type === 'status')
      return (
        <Status
          key={a.id}
          activity={a}
          user={a.status.type === 'completed' ? a.employee : (a.agent || a.employee)}
          lead={this.props.lead}
          isCompany={this.props.isCompany}
          myself={this.props.user}
        />
      );
    if (a.type === 'new') return <New key={a.id} activity={a} user={a.agent || a.employee} />;
    if (a.type === 'update') return <Update key={a.id} activity={a} user={a.agent || a.employee} />;
    if (a.type === 'note')
      return <Note key={a.id} activity={a} user={a.agent || a.employee} goTo={this.props.goTo} />;
    if (a.type === 'email')
      return <Email key={a.id} activity={a} user={a.agent || a.employee} goTo={this.props.goTo} />;
    if (a.type === 'text')
      return <Text key={a.id} lead={this.props.lead} activity={a} user={a.agent || a.employee} goTo={this.props.goTo} />;
    if (a.type === 'call')
      return (
        <Call
          key={a.id}
          activity={a}
          user={a.agent || a.employee}
          lead={this.props.lead}
          goTo={this.props.goTo}
        />
      );
    if (a.type === 'meeting')
      return (
        <Meeting
          key={a.id}
          activity={a}
          user={a.agent || a.employee}
          lead={this.props.lead}
          goTo={this.props.goTo}
        />
      );
    return null;
  };
  loadActivity = id => {
    this.props.client
      .query({
        variables: { lead_id: id },
        query: leadActivity
      })
      .then(result => {
        if (result && result.data && result.data.leadActivity) {
          this.setState({ activity: result.data.leadActivity });
        } else {
          message.error('Could not load activity, try again');
        }
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error('Could not load activity, try again');
      });
  };
  render() {
    const { loading, activity } = this.state;

    let dateList = null;
    if (activity.length > 0) {
      const dates = this.createDates(activity);
      dateList = dates.map((item, i) => {
        let notesList = activity.map(a => {
          if (moment(a.createdAt * 1).format('dddd, MMMM Do YYYY') === item) {
            return this.mapActivity(a);
          }
          return null;
        });
        return (
          <div key={'activitygroup-' + i} date={item} className="date-section">
            <div className="activity-date">
              <span>{item === moment().format('dddd, MMMM Do YYYY') ? 'Today' : item}</span>
            </div>
            {notesList}
          </div>
        );
      });
    }

    return (
      <div id="lead-activity" className="sx-form">
        {loading && <Loading />}
        <div className="activity-content">{!loading && dateList}</div>
      </div>
    );
  }
}
