import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import csv from 'csvtojson';
import { Upload } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { getImports, getMappings } from '_graphql/queries/campaign';
import { removeImport, importLeads } from '_graphql/mutations/campaign';
import { getErrors } from '_assets/js/helpers';
import CSVMapping from './_modals/CSVMapping/CSVMapping';
import HubspotImport from './_modals/HubspotImport/HubspotImport';
import SalesforceImport from './_modals/SalesforceImport/SalesforceImport';
import { Locked } from '_shared/Locked/Locked';
import Icons from 'App/_shared/Icons/Icons';
import { Loading, Card, Button, message, Modal, Checkbox } from '_seriph';
import { IconPlus, IconUpload, IconCloudUpload } from '@tabler/icons-react';
import HubspotLogo from '_assets/brands/hubspot.svg';
import SalesforceLogo from '_assets/brands/salesforce.svg';
import './Import.scss';
import { deduplicateHeaders, defaultMappings, getMappingLegend, historicalMappings } from '_helpers/csv';

const { Dragger } = Upload;

class Import extends Component {
  state = {
    loading: true,
    campaigns: [],
    imports: [],
    mappingLegend: {},
    historicalMap: true,
    import_id: undefined,
    type: null,

    addingLeads: false,
    importing: null,
    data: null,
    allData: null,
    fileName: null,
    fromCrm: null,
    autoImport: false,
    crmListId: null,
    crmObjectType: null,
    dataCount: 0
  };
  componentDidMount = () => {
    if (this.props.save) this.props.save();
    this.loadImports();
    this.loadMappings();
  };
  loadImports = () => {
    this.props.client
      .query({ variables: { campaign_id: this.props.campaignId }, query: getImports })
      .then(result => {
        if (result && result.data && result.data.imports) {
          const imports = result.data.imports || [];
          this.setState({
            imports,
            import_id: undefined,
            importing: null,
            fileName: null,
            fromCrm: null,
            autoImport: false,
            crmListId: null,
            crmObjectType: null,
            data: null,
            allData: null,
            dataCount: 0,
            addingLeads: false,
            loading: false
          });
        }
      });
  };
  loadMappings = () => {
    this.props.client
      .query({ query: getMappings })
      .then(result => {
        if (result?.data?.getMappings) {
          let mappingLegend = getMappingLegend(result?.data?.getMappings);
          this.setState({ mappingLegend })
        }
      });
  };
  removeImport = importId => {
    Modal.confirm({
      title: 'Are you sure you want to remove this import?',
      content: 'This data will no longer be imported when you launch the campaign.',
      onOk: () => {
        this.props.client
          .mutate({ variables: { import_id: importId }, mutation: removeImport })
          .then(result => {
            if (result && result.data && result.data.removeImport)
              setTimeout(this.loadImports, 100);
          });
      },
      onCancel() {}
    });
  };
  importLeads = () => {
    this.setState({ addingLeads: true });
    this.props.client
      .mutate({ variables: { campaign_id: this.props.campaignId }, mutation: importLeads })
      .then(result => {
        if (result && result.data && result.data.importLeads > 0) {
          message.success('New leads added to the campaign');
          this.loadImports();
        } else {
          this.setState({ addingLeads: false });
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not add leads to the campaign, try again');
        this.setState({ addingLeads: false });
      });
  };
  updateMappings = (field, value) => {
    const data = [...this.state.data];
    data.map(d => {
      if (d.csvKey === field) d.leadProperty = value;
      return d;
    });
    this.setState({ data });
  };
  showCrmModal = type => this.setState({ crmModal: type });
  hideCrmModal = () => this.setState({ crmModal: null });
  removeCSV = () => {
    this.setState({ 
      importing: null, 
      fileName: null, 
      fromCrm: null, 
      crmListId: null, 
      crmObjectType: null, 
      autoImport: false, 
      data: null, 
      allData: null, 
      dataCount: 0 
    });
  }
  render() {
    const { campaignId, campaign, actions, fromReview, isLocked, integration } = this.props;
    const { imports, type, loading, crmModal } = this.state;
    const { data, importing, dataCount, fileName, fromCrm, crmListId, crmObjectType, autoImport, allData } = this.state;
    const ready = data && importing === false;

    const canAddMore = campaign.threshold && ['active', 'inactive'].includes(campaign.status);

    const handleCsv = (input, fileName, fromCrm, autoImport, crmListId, crmObjectType) => {
      csv({ flatKeys: true })
        .on('header', headers => deduplicateHeaders(headers))
        .fromString(input)
        .then(jsonObj => {
          if (jsonObj && jsonObj.length > 0) {
            const mappings = defaultMappings(Object.keys(jsonObj[0]));
            const amount = jsonObj.length;
            let data = Object.keys(mappings).map(csvKey => {
              const base = {
                csvKey: csvKey,
                leadProperty: mappings[csvKey],
                exampleValueOne: jsonObj[0][csvKey]
              };
              if (amount > 1) base.exampleValueTwo = jsonObj[1][csvKey];
              if (amount > 2) base.exampleValueThree = jsonObj[2][csvKey];
              return base;
            });
            if (this.state.historicalMap) {
              data = historicalMappings(this.state.mappingLegend, data);
            }
            this.setState({
              importing: false,
              fileName,
              fromCrm,
              autoImport,
              crmListId,
              crmObjectType,
              data: data,
              allData: jsonObj,
              dataCount: amount
            });
          } else {
            this.setState({
              importing: null,
              fileName: null,
              fromCrm: null,
              autoImport: false,
              crmListId: null,
              crmObjectType: null,
              data: null,
              allData: null,
              dataCount: 0
            });
          }
        });
    };

    const props = {
      name: 'file',
      multiple: false,
      accept: '.csv',
      disabled: (this.props.disabled || isLocked) && !canAddMore ? true : false,
      showUploadList: false,
      beforeUpload: file => {
        this.setState({ importing: true });
        const reader = new FileReader();
        reader.onload = e => {
          handleCsv(e.target.result, file.name);
        };
        reader.readAsText(file);
        return false;
      }
    };
    const importData = imports && imports.length > 0 ? imports[0] : null;

    return (
      <Card id="wizard-import" className="wz-card">
        <div className="wz-container sx-form">
          {/* HEADER */}
          <div className="wz-card-header">
            <h3>
              Import leads
              <Locked isLocked={isLocked} />
            </h3>
            <h5>Select one of options to import your leads to qualify.</h5>
          </div>

          {/* CONTENT */}
          {loading ? (
            <Loading />
          ) : importData || ready ? null : (
            <div className="import-content">
              <div
                className={
                  !importData && fromReview ? 'import-selection invalid' : 'import-selection'
                }
              >
                <Dragger {...props} className={`csv ${type === 'csv' ? 'active' : ''}`}>
                  <div className="dragger-box" onClick={() => this.setState({ type: 'csv' })}>
                    <div className="type-icon">
                      <IconUpload />
                    </div>
                    <h3>Drag & drop or choose a .csv file</h3>
                  </div>
                </Dragger>
              </div>

              <div className="auto-map">
                <Checkbox checked={this.state.historicalMap} onChange={e => this.setState({ historicalMap: e.target.checked })}>
                  Auto-map fields based on previous import selections
                </Checkbox>
              </div>

                <div className="crm-csv">
                  <div className="crm-or"><span></span><div>OR</div><span></span></div>
                  <h4>Import from CRM</h4>

                  <div className="crm-box">
                    <span><img className="hubspot" src={HubspotLogo} title="Hubspot" alt="Hubspot" /></span>
                    <h4>Hubspot</h4>
                    { integration?.hubspot?.enabled ? (
                      <Button icon="left" type="primary" onClick={() => this.showCrmModal('hubspot')}><IconCloudUpload />Import</Button>
                    ) : (
                      <Link to="/settings/integrations"><Button icon="left" type="secondary"><IconPlus /> Connect</Button></Link>
                    )}
                  </div>
                  <div className="crm-box">
                    <span><img className="salesforce" src={SalesforceLogo} title="Salesforce" alt="Salesforce" /></span>
                    <h4>Salesforce</h4>
                    { integration?.salesforce?.enabled ? (
                      <Button icon="left" type="primary" onClick={() => this.showCrmModal('salesforce')}><IconCloudUpload />Import</Button>
                    ) : (
                      <Link to="/settings/integrations"><Button icon="left" type="secondary"><IconPlus /> Connect</Button></Link>
                    )}
                  </div>

                </div>
            </div>
          )}

          {importData && !canAddMore && !loading ? (
            <div className="import-container">
              <div className={`import ${isLocked ? 'disabled' : ''}`}>
                <div className="close-icon" onClick={() => this.removeImport(importData.id)}>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
                <div className="type-icon">
                  <Icons.Campaigns />
                </div>
                <div className="import-info">
                  <h3>{importData.name}</h3>
                  <div>
                    {importData.amount} lead{importData.amount !== 1 ? 's' : ''} imported from this{' '}
                    {importData.type === 'csv' ? 'list' : 'list'}
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {imports && imports.length > 0 && canAddMore && !loading ? (
            <div className="import-container grid">
              {imports.map(importInfo => (
                <div
                  className={`import small ${importInfo.imported ? 'disabled' : ''}`}
                  key={'wz-import-' + importInfo.id}
                >
                  <div className="close-icon" onClick={() => this.removeImport(importInfo.id)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                  <div className="type-icon">
                    <Icons.Campaigns />
                  </div>
                  <div className="import-info">
                    <h3>{importInfo.name}</h3>
                    <div>
                      {importInfo.amount} lead{importInfo.amount !== 1 ? 's' : ''}{' '}
                      {importInfo.imported ? 'added' : 'ready'}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : null}

          {!loading && canAddMore ? (
            <div className="more-container">
              <Dragger {...props} className="add-more">
                <Button
                  loading={loading}
                  type="primary"
                  size="large"
                  icon="left"
                  onClick={() => this.setState({ type: 'csv' })}
                >
                  {loading ? (
                    'Adding leads...'
                  ) : (
                    <React.Fragment>
                      <IconPlus />
                      Add more leads
                    </React.Fragment>
                  )}
                </Button>
              </Dragger>
            </div>
          ) : null}
        </div>

        {ready ? (
          <CSVMapping
            data={data}
            allData={allData}
            fileName={fileName}
            fromCrm={fromCrm}
            autoImport={autoImport}
            crmListId={crmListId}
            crmObjectType={crmObjectType}
            dataCount={dataCount}
            campaignId={campaignId}
            updateMappings={this.updateMappings}
            client={this.props.client}
            reload={canAddMore ? this.importLeads : this.loadImports}
            removeModal={this.removeCSV}
            importType="company"
            historicalMap={this.state.historicalMap}
          />
        ) : null}

        {crmModal === 'hubspot' ? (
          <HubspotImport
            campaignId={campaignId}
            client={this.props.client}
            integration={this.props.integration}
            removeModal={this.hideCrmModal}
            handleCsv={handleCsv}
          />
        ) : null}

        {crmModal === 'salesforce' ? (
          <SalesforceImport
            campaignId={campaignId}
            client={this.props.client}
            integration={this.props.integration}
            removeModal={this.hideCrmModal}
            handleCsv={handleCsv}
          />
        ) : null}

        {/* ACTIONS */}
        {ready ? null : actions}
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(Import));
