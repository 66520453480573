import React from 'react';
import { Input } from 'antd';
import { IconBolt, IconAlertCircle, IconInfoCircle, IconThumbUp } from '@tabler/icons-react';
import { Tooltip } from '_seriph';
import './TextArea.scss';

const TextArea = incomingProps => {
  const props = { ...incomingProps };
  let containerClassName = 'seriph-textarea';

  // Handle Error/Success
  if (props.error) containerClassName += ' textarea-error';
  if (props.success) containerClassName += ' textarea-success';
  if (props.dark) containerClassName += ' dark';

  return (
    <div className={containerClassName}>
      {props.label && (
        <label className="textarea-label">
          {props.label}
          {props.info && (
            <Tooltip title={props.info}>
              <IconInfoCircle size={16} stroke={1.75} />
            </Tooltip>
          )}
        </label>
      )}
      <div className="textarea-box">
        <Input.TextArea {...props} />
      </div>
      {props.hint && (
        <div className="textarea-hint">
          {!props.success && !props.error && <IconBolt size={16} stroke={1.75} />}
          {props.success && <IconThumbUp size={16} stroke={1.75} />}
          {props.error && <IconAlertCircle size={16} stroke={1.75} />}
          <span>{props.hint}</span>
        </div>
      )}
    </div>
  );
};

export default TextArea;
