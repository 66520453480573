import gql from 'graphql-tag';

export const getEmployee = gql`
  query employee($id: ID) {
    employee(id: $id) {
      first_name
      last_name
      picture
      phone_number
      title
      user {
        verified
        email
      }
    }
  }
`;
