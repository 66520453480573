import React, { Component } from 'react';
import { Modal, Button, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { skipMap } from '_constants/leadFields';
import './Skip.scss';

export default class Skip extends Component {
  state = {
    visible: true,
    reason: null,
    information: ''
  };
  completeSkip = () => {
    if (this.props.complete) this.props.complete(this.state.reason, this.state.information);
    this.hideModal();
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { reason, information } = this.state;
    return (
      <Modal
        wrapClassName="sequence-skip-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={true}
        width={480}
      >
        <div className="close-icon" onClick={this.hideModal}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="skip-content">
          <div className="skip-info">
            <div className="stage-icon">
              <FontAwesomeIcon icon={faExclamation} />
            </div>
            <div className="skip-title">
              <h3>Are you sure you want to skip?</h3>
              <h5>
                Only skip the touchpoint if you cannot complete it otherwise or have been instructed
                to
              </h5>
            </div>
          </div>

          <div className="skip-options">
            {Object.keys(skipMap).map(d => (
              <div
                key={'dm-' + d}
                onClick={() => this.setState({ reason: d })}
                className={reason === d ? 'active' : ''}
              >
                {skipMap[d]}
              </div>
            ))}
          </div>
          <Input.TextArea
            className="forms forms-textarea"
            rows={4}
            placeholder="Add addition information..."
            autoSize={false}
            value={information}
            onChange={e => this.setState({ information: e.target.value })}
          />
        </div>
        <div className="skip-actions">
          <Button className="btnx btnx-default" onClick={this.hideModal}>
            Cancel
          </Button>
          <Button
            disabled={!reason || (reason === 'other' && !information)}
            className="btnx btnx-primary"
            onClick={this.completeSkip}
          >
            Skip
          </Button>
        </div>
      </Modal>
    );
  }
}
