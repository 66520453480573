import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { message } from 'antd';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { createStripe } from 'Mutations/Agent/updateAccount';

class OAuth extends Component {
  UNSAFE_componentWillMount() {
    const query = queryString.parse(this.props.location.search);
    if (this.props.user.id === query.state) {
      this.props.client
        .mutate({ variables: { code: query.code }, mutation: createStripe })
        .then(result => {
          if (result && result.data && result.data.createStripe) {
            this.props.history.push('/settings/banking');
          } else {
            message.error('Could not connect account, try again');
          }
        })
        .catch(() => message.error('Could not connect account, try again'));
    } else {
      message.error('Could not connect account, try again');
    }
  }
  render() {
    return (
      <div id="oath">
        <div id="loading-svg">
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(OAuth));
