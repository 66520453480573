import React, { Component } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { numberWithCommas } from '_assets/js/helpers';
import { Loading } from '_seriph';
import { otherColors } from '_seriph/_constants/colors';
import './IndustryTitleChart.scss';

export default class IndustryTitleChart extends Component {
  customTooltip = data => {
    const payload = data.payload || [];
    return (
      <div className="chart-tooltip">
        <div className="tip-box">
          <div className="tip-title">{data?.label}</div>
          <div className="tip-contents">
            {payload.map((p, i) => (
              <div key={`tip-${i}`} className="tip-item">
                <div className="tip-circle" style={{ backgroundColor: p.fill }} />
                <div className="tip-name">{p.name}</div>
                <div className="tip-value">{numberWithCommas(p.value) || '0'}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  render() {
    const { data, titles, campaign, loading } = this.props;

    return (
      <div className="chart-box" id="industry-title-chart">
        <div className="chart-title">
          <h4>
            Industry connections by job title<span>({campaign})</span>
          </h4>
          <p>Total connections by industry and title of decision maker</p>
        </div>
        <div className="chart-display">
          {loading ? (
            <div className="chart-loading" style={{ minHeight: '360px' }}>
              <Loading />
            </div>
          ) : (
            <React.Fragment>
              <div className="left-chart">
                <ResponsiveContainer height={500} width="99%">
                  <BarChart data={data}>
                    <CartesianGrid stroke="#E7ECF4" fill="rgba(0,0,0,0)" strokeDasharray="3 3" />
                    <XAxis dataKey="name" tickLine={false} axisLine={false} />
                    <YAxis
                      tickLine={false}
                      axisLine={false}
                      dataKey="amount"
                      domain={[0, 'dataMax + 2']}
                      allowDecimals={false}
                    />
                    <Tooltip content={this.customTooltip} />
                    <Bar maxBarSize={72} dataKey="Other" stackId="a" fill={'#7D7B78'} />
                    {titles.map((t, i) =>
                      i < 9 ? (
                        <Bar
                          key={'bar-' + i}
                          maxBarSize={72}
                          dataKey={t.name}
                          stackId="a"
                          fill={otherColors[i]}
                        />
                      ) : null
                    )}
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div className="right-chart">
                <h4>Job title</h4>
                <div className="dash-data">
                  <div className={`dash-sts`}>
                    <div>
                      <span style={{ backgroundColor: '#7D7B78' }} />
                      Other
                    </div>
                  </div>
                  {titles?.map((d, i) =>
                    i < 9 ? (
                      <div className={`dash-sts`} key={`titint-${i}`}>
                        <div>
                          <span style={{ backgroundColor: otherColors[i] }} />
                          {d.name}
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}
