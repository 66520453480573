import React, { Component } from 'react';
import { Modal, Select, Button, message } from 'antd';
import { importFields } from '../../../../../Leads/Lead/formData';
import { createImport } from '_graphql/mutations/campaign';
import { getErrors } from '_assets/js/helpers';
import './ImportMapping.scss';

export default class ImportMapping extends Component {
  state = {
    visible: true,
    saving: false
  };
  createImport = () => {
    this.setState({ saving: true });
    const importData = {
      name: this.props.fileName,
      type: 'csv', // Campaign, CSV
      mappings: JSON.stringify(this.props.data),
      json_data: JSON.stringify(this.props.allData),
      campaign_id: this.props.campaignId,
      import_type: 'company'
    };
    this.props.client
      .mutate({ variables: { import: importData }, mutation: createImport })
      .then(result => {
        if (result && result.data && result.data.createImport) {
          setTimeout(this.props.reload, 100);
          this.hideModal();
        }
        this.setState({ saving: false });
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Error creating import, try again.');
      });
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { data, dataCount } = this.props;
    const chosen = data.map(d => d.leadProperty).filter(f => f !== null);

    return (
      <Modal
        wrapClassName="import-mapping-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        width={660}
        closable={false}
        maskClosable={false}
      >
        <h2>Map CSV Fields</h2>
        <h5>Let&apos;s make sure your CSV columns match up to our Lead&apos;s properties</h5>

        <div className="horiz-map">
          {data.map(d => (
            <div key={'dmap-' + d.csvKey} className="horiz-box">
              <h4>Lead Property:</h4>
              <div>
                <Select
                  className="forms"
                  dropdownClassName="sx-dropdown mapping-forms"
                  value={d.leadProperty}
                  onChange={val => this.props.updateMappings(d.csvKey, val)}
                >
                  <Select.Option value={null}>No Fields</Select.Option>
                  {Object.keys(importFields).map(field => (
                    <Select.Option
                      disabled={chosen.includes(field)}
                      key={'map-' + field}
                      value={field}
                    >
                      {field.replace('_', ' ').replace('crm', 'CRM')}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="csv-data">
                <span>Column: {d.csvKey}</span>
                <div>{d.exampleValueOne}</div>
                {d.exampleValueTwo ? <div>{d.exampleValueTwo}</div> : null}
                {d.exampleValueThree ? <div>{d.exampleValueThree}</div> : null}
              </div>
            </div>
          ))}
        </div>

        <div className="map-actions">
          <Button disabled={this.state.saving} className="btn btn-default" onClick={this.hideModal}>
            Cancel
          </Button>
          <Button
            loading={this.state.saving}
            className="btn btn-primary"
            onClick={this.createImport}
          >
            Add&nbsp;{dataCount}&nbsp;Lead{dataCount === 1 ? '' : 's'}
          </Button>
        </div>
      </Modal>
    );
  }
}
