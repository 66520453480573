import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Accordion, Button } from '_seriph';
import { IconSettings, IconPlus, IconPhone, IconPhoneOff } from '@tabler/icons-react';
import { prettyPhone } from '_assets/js/helpers';
import './PhoneNumbers.scss';

const Panel = Accordion.Panel;

const Touchpoint = props => {
  return (
    <div className="touchpoint" onClick={props.onClick}>
      <span>{props.children}</span>
    </div>
  );
};

class PhoneNumbers extends Component {
  newRoundRobin = () => {
    this.props.showRoundRobinModal('create');
  };
  render() {
    const { roundRobins } = this.props;

    return (
      <div id="company-round-robin-numbers">
        <div className="touchpoints-title">
          <span>Call Lists</span>
          <Button type="default" size="tiny" circle onClick={this.newRoundRobin}>
            <IconPlus />
          </Button>
        </div>
        <Accordion defaultActiveKey={['calls']} className="touchpoint-list">
          {roundRobins.map(r => {
            return (
              <Panel
                header={prettyPhone(r.phone_number)}
                key={r.id}
                menu
                icon={r.active ? <IconPhone style={{ color: 'green'}} /> : <IconPhoneOff style={{ color: 'red'}} />}
                amount={r?.forward_to?.length}
                disabled={r?.forward_to?.length <= 0}
              >
                { r?.forward_to?.map(ch => <Touchpoint key={r.id + '-ch-' + ch.number}>Fwrd: {prettyPhone(ch.number)}</Touchpoint> ) }
                <div className="touchpoint" onClick={() => this.props.showRoundRobinModal(r.id)}>
                  <span className="pn-edit"><span>Edit Call List</span><IconSettings /></span>
                </div>
              </Panel>
            );
          })}
        </Accordion>
      </div>
    );
  }
}

export default withRouter(PhoneNumbers);
