import React from 'react';
import moment from 'moment';
import { Steps } from 'antd';
import { prettyPhone, formatMoney } from '_assets/js/helpers';

const { Step } = Steps;

export const getColumns = () => {
  return [
    {
      title: 'Full name',
      dataIndex: 'first_name',
      render: (column, data) => (
        <div className="onboard-box">
          <div className="name">
            <b>
              {data.first_name} {data.last_name}
            </b>
          </div>
          <div className="name">{data.email}</div>
          <div className="name">{prettyPhone(data.phone_number)}</div>
        </div>
      )
    },
    {
      title: 'Progress',
      dataIndex: 'landed_at',
      render: (column, data) => {
        let step = 0;
        if (data.landed_at) step = 1;
        if (data.submitted_at) step = 2;
        if (data.reviewedAt) step = 3;
        if (data.invitedAt) step = 4;
        if (data.agent && parseInt(data.agent.profile_percent || 0) > 90) step = 5;
        if (data.agent && parseInt(data.agent.paid || 0) > 0) step = 6;
        return (
          <Steps size="small" current={step} status="error">
            <Step title="Started" description={moment(data.landed_at * 1).format('MM/DD h:mma')} />
            <Step
              title="Applied"
              description={moment(data.submitted_at * 1).format('MM/DD h:mma')}
            />
            <Step
              title="Reviewed"
              description={moment(data.reviewedAt * 1).format('MM/DD h:mma')}
            />
            <Step title="Invited" description={moment(data.invitedAt * 1).format('MM/DD h:mma')} />
            <Step title="Profile" description={data.agent ? data.agent.profile_percent : '0%'} />
            <Step
              title="Submitted"
              description={data.agent && data.agent.has_submitted_lead ? 'Yes' : 'No'}
            />
            <Step
              title="Earned"
              description={data.agent && data.agent.paid ? formatMoney(column || 0) : '$0'}
            />
          </Steps>
        );
      }
    }
    // {
    //   title: 'Finished Application',
    //   dataIndex: 'submitted_at',
    //   render: (column, data, index) => moment(column*1).format('MMM Do h:mma')
    // },
    // {
    //   title: 'Reviewed',
    //   dataIndex: 'reviewedAt',
    //   render: (column, data, index) => moment(column*1).format('MMM Do h:mma')
    // },
    // {
    //   title: 'Invited',
    //   dataIndex: 'invitedAt',
    //   render: (column, data, index) => moment(column*1).format('MMM Do h:mma')
    // },
    // {
    //   title: 'Joined',
    //   dataIndex: 'agent.createdAt',
    //   render: (column, data, index) => moment(column*1).format('MMM Do h:mma')
    // },
    // {
    //   title: 'Profile',
    //   dataIndex: 'agent.profile_percent',
    //   render: (column, data, index) => column || '-'
    // },
    // {
    //   title: 'Submitted Lead?',
    //   dataIndex: 'agent.has_submitted_lead',
    //   render: (column, data, index) => column ? 'Yes' : 'No'
    // },
    // {
    //   title: 'Earned',
    //   dataIndex: 'agent.paid',
    //   render: (column, data, index) => formatMoney(column || 0)
    // }
  ];
};
