import React, { Component } from 'react';
import { TextArea } from '_seriph';
import Avatar from '_styleguide/Avatar/Avatar';
import './Note.scss';

export default class Note extends Component {
  render() {
    const { campaign, company, note, setNote } = this.props;

    const employee = campaign ? campaign.employee : {};

    return (
      <div className="apply-note">
        <div className="note-info">
          <Avatar size="large" type="company" picture={employee.picture} company={company.logo} />
          <h3>{employee.display_name} is the campaign manager</h3>
          <p>Introduce yourself, make a great first impression</p>
        </div>
        <div className="options">
          <TextArea
            value={note}
            onChange={e => setNote(e.target.value)}
            size="large"
            rows={6}
            placeholder={'Your message to ' + employee.first_name + '...'}
          />
        </div>
      </div>
    );
  }
}
