import React from 'react';
import Avatar from '_styleguide/Avatar/Avatar';
import { Button } from '_seriph';
import { formatMoney } from '_assets/js/helpers';
import logo from '_assets/img/logo_black.svg';
import { isInternal } from '_helpers/permissions';

export const formatNotification = (n, user) => {
  const isCompany = user && user.role_type === 'company' ? true : false;
  const internal = isInternal();
  if (n.type === 'note_added') {
    return {
      description: (
        <p>
          <b>{n.employee.display_name}</b> added a note to the lead page for{' '}
          <b>
            {n.lead.first_name} {n.lead.last_name}
          </b>
        </p>
      ),
      avatar: (
        <Avatar
          size="tiny"
          type="company"
          picture={n.employee.picture}
          company={n.employee.company.logo}
        />
      ),
      link: `/leads/${n.lead.id}/notes/`
    };
  }
  if (n.type === 'campaign_launch') {
    return {
      description: (
        <p>
          <b>{n.employee.display_name}</b> successfully launched <b>{n.campaign.name}</b>
        </p>
      ),
      avatar: (
        <Avatar
          size="tiny"
          type="company"
          picture={n.employee.picture}
          company={n.employee.company.logo}
        />
      ),
      link: '/analytics?campaign=' + n.campaign.id
    };
  }
  if (n.type === 'campaign_paused') {
    return {
      description: (
        <p>
          <b>
            {n.employee.display_name} ({n.employee.company.name})
          </b>{' '}
          paused <b>{n.campaign.name}</b>
        </p>
      ),
      avatar: (
        <Avatar
          size="tiny"
          type="company"
          picture={n.employee.picture}
          company={n.employee.company.logo}
        />
      ),
      link: '/campaigns'
    };
  }
  if (n.type === 'campaign_restarted') {
    return {
      description: (
        <p>
          <b>
            {n.employee.display_name} ({n.employee.company.name})
          </b>{' '}
          restarted <b>{n.campaign.name}</b>
        </p>
      ),
      avatar: (
        <Avatar
          size="tiny"
          type="company"
          picture={n.employee.picture}
          company={n.employee.company.logo}
        />
      ),
      link: '/campaigns'
    };
  }
  if (n.type === 'campaign_ended' && isCompany) {
    return {
      description: (
        <p>
          <b>
            {n.employee.display_name} ({n.employee.company.name})
          </b>{' '}
          ended <b>{n.campaign.name}</b>, let&apos;s get the next one started!
        </p>
      ),
      avatar: (
        <Avatar
          size="tiny"
          type="company"
          picture={n.employee.picture}
          company={n.employee.company.logo}
        />
      ),
      link: '/wizard/new'
    };
  } else if (n.type === 'campaign_ended' && !isCompany) {
    return {
      description: (
        <p>
          <b>
            {n.employee.display_name} ({n.employee.company.name})
          </b>{' '}
          ended <b>{n.campaign.name}</b>, thanks for all your work!
        </p>
      ),
      avatar: (
        <Avatar
          size="tiny"
          type="company"
          picture={n.employee.picture}
          company={n.employee.company.logo}
        />
      ),
      link: '/campaigns'
    };
  } else if (n.type === 'agent_left') {
    return {
      description: (
        <p>
          <b>{n.agent.display_name}</b> left <b>{n.campaign.name}</b> because:{' '}
          <i>{n.description}</i>
        </p>
      ),
      avatar: <Avatar size="tiny" type="agent" picture={n.agent.picture} />,
      link: '/sales-reps'
    };
  }
  if (n.type === 'agent_readded') {
    return {
      description: (
        <p>
          <b>
            {n.employee.display_name} ({n.employee.company.name})
          </b>{' '}
          re-added you back to <b>{n.campaign.name}</b>
        </p>
      ),
      avatar: (
        <Avatar
          size="tiny"
          type="company"
          picture={n.employee.picture}
          company={n.employee.company.logo}
        />
      ),
      link: '/campaigns'
    };
  }
  if (n.type === 'agent_removed') {
    return {
      description: (
        <p>
          <b>
            {n.employee.display_name} ({n.employee.company.name})
          </b>{' '}
          removed you from <b>{n.campaign.name}</b>
        </p>
      ),
      avatar: (
        <Avatar
          size="tiny"
          type="company"
          picture={n.employee.picture}
          company={n.employee.company.logo}
        />
      ),
      link: '/campaigns'
    };
  }
  if (n.type === 'agent_invite') {
    return {
      description: (
        <p>
          <b>
            {n.employee.display_name} ({n.employee.company.name})
          </b>{' '}
          invited you to join <b>{n.campaign.name}</b>{' '}
          <Button className="cmp-invite" type="secondary" size="micro">
            View Campaign
          </Button>
        </p>
      ),
      avatar: (
        <Avatar
          size="tiny"
          type="company"
          picture={n.employee.picture}
          company={n.employee.company.logo}
        />
      ),
      link: null,
      invitation: true
    };
  }
  if (n.type === 'agent_withdraw') {
    return {
      description: (
        <p>
          <b>
            {n.employee.display_name} ({n.employee.company.name})
          </b>{' '}
          withdrew invitation to <b>{n.campaign.name}</b>
        </p>
      ),
      avatar: (
        <Avatar
          size="tiny"
          type="company"
          picture={n.employee.picture}
          company={n.employee.company.logo}
        />
      ),
      link: null,
      invitation: false
    };
  }
  if (n.type === 'agent_added') {
    return {
      description: (
        <p>
          <b>
            {n.employee.display_name} ({n.employee.company.name})
          </b>{' '}
          added you to <b>{n.campaign.name}</b>{' '}
        </p>
      ),
      avatar: (
        <Avatar
          size="tiny"
          type="company"
          picture={n.employee.picture}
          company={n.employee.company.logo}
        />
      ),
      link: '/campaigns',
      invitation: false
    };
  }
  if (n.type === 'agent_accepted') {
    return {
      description: (
        <p>
          <b>{n.agent.display_name}</b> accepted your invite and joined <b>{n.campaign.name}</b>
        </p>
      ),
      avatar: <Avatar size="tiny" type="agent" picture={n.agent.picture} />,
      link: '/xdrs'
    };
  }
  if (n.type === 'agent_declined' && isCompany) {
    return {
      description: (
        <p>
          <b>{n.agent.display_name}</b> declined your invite to join <b>{n.campaign.name}</b>
        </p>
      ),
      avatar: <Avatar size="tiny" type="agent" picture={n.agent.picture} />,
      link: '/explore'
    };
  }
  if (n.type === 'agent_hired') {
    return {
      description: (
        <p>
          <b>
            {n.employee.display_name} ({n.employee.company.name})
          </b>{' '}
          accepted your application to join <b>{n.campaign.name}</b>, welcome to the team!
        </p>
      ),
      avatar: (
        <Avatar
          size="tiny"
          type="company"
          picture={n.employee.picture}
          company={n.employee.company.logo}
        />
      ),
      link: '/campaigns'
    };
  }
  if (n.type === 'agent_declined' && !isCompany) {
    return {
      description: (
        <p>
          <b>{n.employee.company.name}</b> passed on your application to join{' '}
          <b>{n.campaign.name}</b>, keep applying to find that perfect campaign!
        </p>
      ),
      avatar: <Avatar size="tiny" type="agent" picture={n.agent.picture} />,
      link: '/explore'
    };
  }
  if (n.type === 'campaign_notify') {
    return {
      description: (
        <p>
          <b>{n.employee.company.name}</b> just launched a new campaign called{' '}
          <b>{n.campaign.name}</b>, check it out!
        </p>
      ),
      avatar: <Avatar size="tiny" type="company" picture={n.employee.company.logo} />,
      link: null,
      invitation: true
    };
  }
  if (n.type === 'lead_accepted') {
    return {
      description: (
        <p>
          <b>
            {n.employee.display_name} ({n.employee.company.name})
          </b>{' '}
          approved one of your leads&nbsp;
          {!internal && <span>and <b>{formatMoney(n.description)}</b> has been added to yournext payout.</span>}
        </p>
      ),
      avatar: (
        <Avatar
          size="tiny"
          type="company"
          picture={n.employee.picture}
          company={n.employee.company.logo}
        />
      ),
      link: '/settings/earnings'
    };
  }
  if (n.type === 'lead_auto_approved') {
    return {
      description: (
        <p>
          One of your leads for {n.employee.company.name} has been auto-approved&nbsp;
          {(!internal && parseInt(n.description || 0) > 0) && <span>and <b>{formatMoney(n.description)}</b> has been added to your next payout.</span>}
        </p>
      ),
      avatar: <Avatar size="tiny" type="company" picture={n.employee.company.logo} />,
      link: '/settings/earnings'
    };
  }
  if (n.type === 'stipend') {
    return {
      description: (
        <p>
          Congrats, you were paid <b>{formatMoney(n.description)}</b> for reaching you task goals last week.
        </p>
      ),
      avatar: <Avatar size="tiny" type="company" picture={logo} />,
      link: '/settings/earnings'
    };
  }
  if (n.type === 'bonus') {
    return {
      description: (
        <p>
          Congrats, you earned a bonus of <b>{formatMoney(n.description)}</b>!.
        </p>
      ),
      avatar: <Avatar size="tiny" type="company" picture={logo} />,
      link: '/settings/earnings'
    };
  }
  if (n.type === 'seats') {
    return {
      description: (
        <p>
          Seat alottment updated. {n.description}.
        </p>
      ),
      avatar: <Avatar size="tiny" type="company" picture={logo} />,
      link: '/sales-reps'
    };
  }
  return null;
};
