import gql from 'graphql-tag';


export const sendFromTexter = gql`
  mutation sendFromTexter(
    $task_id: String!
    $lead_id: String!
    $mainArea: String!
    $sendTo: String!
  ) {
    sendFromTexter(
      task_id: $task_id
      lead_id: $lead_id
      mainArea: $mainArea
      sendTo: $sendTo
    ) {
      success
    }
  }
`;
