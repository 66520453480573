import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Layout } from 'antd';
import logo from '_assets/img/logo.svg';
import FourOhFour from '_assets/backgrounds/404.svg';
import Circle from '_assets/backgrounds/circle_404.svg';
import './Missing.scss';

class Missing extends Component {
  render() {
    return (
      <Layout id="sellx-missing">
        <div className="forgot-main">
          <div className="img-fof">
            <img src={FourOhFour} alt="" />
            <div className="blur" />
          </div>
        </div>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <div className="img-circle">
          <img className="circle" src={Circle} alt="" />
        </div>
      </Layout>
    );
  }
}

export default withRouter(Missing);
