import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import User from './_components/User/User';
import Skills from './_components/Skills/Skills';
import AboutMe from './_components/AboutMe/AboutMe';
import Experience from './_components/Experience/Experience';
import Accomplishments from './_components/Accomplishments/Accomplishments';
import Education from './_components/Education/Education';
import Guide from '_styleguide/Guide/Guide';
import { Loading, message } from '_seriph';
import './Profile.scss';

class Profile extends Component {
  state = {
    loading: true,
    agent: null,
    agentId:
      this.props.user.role_type === 'agent' && !this.props.agentId
        ? this.props.user.id
        : this.props.agentId,
    showModal: false,
    linkedinSlug: null,
    linkedinProfile: null
  };
  componentDidMount = () => {
    this.getProfileInformation();
  };
  getProfileInformation = () => {
    this.props.client
      .query({
        query: gql`{
      client(id: "${this.state.agentId}"){ 
        id, picture, first_name, last_name,
        about, location{ name, lat, lng },
        url_twitter, url_website, url_linkedin, url_facebook,
        accomplishments { id, description },
        total_experience,
        experience_short,
        employment_short,
        languages,
        experiences{ id, title, company, current, description, start_date, end_date },
        education{ id, school, gpa, field, degree, description, start_date, end_date },
        skills, employment, reputation, profile_percent, imported,
        campaigns { id },
        stats {
          generated,
          qualified,
          disputed,
          total_paid
        },
        reviews { total }
      }
    }`
      })
      .then(result => {
        if (result.data.client) {
          this.setState({ agent: result.data.client, loading: false });
        } else {
          message.error('Could not find user');
        }
      });
  };
  updateLinkedinProfile = e => {
    const regex = /http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/([A-z0-9_-]+)\/?/;
    const url = e.target.value;
    if (url && url.match(regex)) {
      const results = url.match(regex)[3];
      if (!results) message.error('Could not match LinkedIn URL, try again');
      this.setState({ linkedinProfile: url, linkedinSlug: results || null });
    } else {
      this.setState({ linkedinProfile: url, linkedinSlug: null });
    }
  };
  showLinkedInModal = () => this.setState({ showModal: true });
  removeLinkedInModal = () => this.setState({ showModal: false });
  render() {
    const { agent, loading, agentId } = this.state;
    const isEditable = agentId === this.props.user.id;
    return (
      <div id="sellx-profile">
        {loading || !agent ? (
          <Loading />
        ) : (
          <React.Fragment>
            {isEditable && (
              <Guide
                id="agent-profile"
                message="Companies use profiles to determine whether you are a good fit for their campaign.  Get your profile to 100% for best visibility."
              />
            )}
            <div className="prof-bod">
              <div className="left-profile">
                <User agent={agent} isEditable={isEditable} reload={this.getProfileInformation} />
                <Skills
                  value="20%"
                  agent={agent}
                  isEditable={isEditable}
                  reload={this.getProfileInformation}
                />
              </div>
              <div className="middle-profile">
                <AboutMe
                  value="10%"
                  agent={agent}
                  isEditable={isEditable}
                  reload={this.getProfileInformation}
                />
                <Experience
                  value="20%"
                  agent={agent}
                  isEditable={isEditable}
                  reload={this.getProfileInformation}
                />
                <Accomplishments
                  value="20%"
                  agent={agent}
                  isEditable={isEditable}
                  reload={this.getProfileInformation}
                />
                <Education
                  value="20%"
                  agent={agent}
                  isEditable={isEditable}
                  reload={this.getProfileInformation}
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(Profile));
