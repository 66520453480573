import gql from 'graphql-tag';

export const getCallTasks = gql`
  query getCallTasks($campaignId: String!) {
    getCallTasks(campaignId: $campaignId) {
      agent {
        first_name
        last_name
        display_name
      }
      campaigns {
        id
        name
        custom_domain
        guidelines
        company {
          name
          logo
          website
          linkedin
          facebook
          instagram
          twitter
        }
        questions {
          type
          question
          options
        }
        agents {
          id
          agent_id
          phone_number
        }
        benefits
        objections {
          name
          responses
        }
        calendly
        chilipiper
        forceCalendar
        meeting_required
        integration {
          calendly {
            url
          }
          chilipiper {
            domain
            inbound_router
          }
        }
      }
      leads {
        id
        meetingScheduled

        first_name
        last_name
        salutation
        primary_language
        education
        race
        gender
        age

        email
        phone
        mobile
        fax
        no_call_list

        company
        company_scope
        job_position
        title
        number_of_employees
        annual_revenue
        industry

        website
        linkedin
        facebook
        twitter
        instagram

        street
        city
        state
        postal_code
        country

        custom_fields {
          type
          label
          value
        }
        calendly {
          created_at
          start_time
          end_time
          name
        }
        tags {
          tag
          opType
          date
          note
        }
        answers {
          question
          answer
          answers
        }
        notes_count
        campaign_id
        sequence_id
        company_id
        agent_id
        current_task {
          task_id
          day
          step
          steps_today
          type
          touchpoint {
            id
            name
            subject
            content
          }
        }
      }
    }
  }
`;

export const callNotes = gql`
  query leadNotes($lead_id: ID) {
    leadNotes(lead_id: $lead_id) {
      id
      content
      createdAt
    }
  }
`;
