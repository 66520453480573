import React from 'react';
import { getLeadName } from '_helpers/lead';
import { noCall } from '_assets/js/helpers';
import { Header, Tooltip, Popover, Tag, Button } from '_seriph';
import { 
  IconChevronsRight,
  IconCheck,
  IconBan,
  IconArrowForwardUp,
  IconCopy,
  IconAlertTriangle
} from '@tabler/icons-react';
import './LeadCalls.scss';

export default function LeadCalls(props) {
  const { lead, toggleNoCall, savingNoCall } = props;
  const generateContent = (number, pretty) => {
    const isNoCall = noCall(lead.no_call_list, number);
    return isNoCall ? (
      <div className="nocall-pop">
        <h3>{pretty}</h3>
        <p>Marking valid will add this number back to the dial queue to call.</p>
        <Button loading={savingNoCall} type="success" icon="left" block size="tiny" onClick={() => toggleNoCall(lead.id, number, 'remove')}>
          <IconCheck /> Mark number valid
        </Button>
      </div>
    ) : (
      <div className="nocall-pop">
        <h3>{pretty}</h3>
        <p>Marking invalid will remove this number from the dial queue.</p>
        <Button loading={savingNoCall} type="warning" icon="left" block size="tiny" onClick={() => toggleNoCall(lead.id, number, 'add')}>
          <IconAlertTriangle /> Mark number invalid
        </Button>
      </div>
    );
  }

  return (
    <div id="lead-call-info">
      <Header size="4" font="sans">
        {getLeadName(lead, true)}
      </Header>
      <div className="phones">
        {lead.calls.map((call, i) => {
          const number = call?.number ? call?.pretty : null;
          const isLast = i >= lead.calls.length - 1;
          const skip = call.skip;
          const duplicate = call.duplicate;
          const dontCall = noCall(lead.no_call_list, call?.number);

          // Generate Icon
          let icon = null;
          const isDone = call?.status === 'completed' || call?.status === 'canceled' || call?.status === 'failed' || call?.status === 'busy' || call?.status === 'no-answer';
          if (call.invalid) icon = <IconBan />;
          if (skip) icon = <IconArrowForwardUp />;
          if (duplicate) icon = <IconCopy />;
          if (dontCall) icon = <IconAlertTriangle />;
          if (isDone) icon = <IconCheck />;

          // Generate Type
          let tagType = 'default';
          if (call?.status === 'ringing') tagType = 'info';
          if (call?.status === 'in-progress') tagType = 'primary';
          if (isDone) tagType = 'default';

          // Generate tooltip
          let tagTooltip = number;
          if (!number) tagTooltip = 'Invalid or missing number field';
          if (skip) tagTooltip = 'Skipping based on your Settings';
          if (call.invalid) tagTooltip = call.invalid_reason || 'Invalid or missing number field';
          if (call.duplicate) tagTooltip = call.pretty + ' is a duplicate of another number';

          const shouldDisabled = !number || call.invalid || skip || duplicate || dontCall;

          return (
            <React.Fragment key={'s-' + i}>
              { shouldDisabled && !dontCall ? (
                <Tooltip title={tagTooltip}>
                  <Tag size="tiny" type={tagType} disabled={shouldDisabled}>
                    {icon}
                    {call.name}
                  </Tag>
                </Tooltip>
              ) : (
                <Popover content={generateContent(call?.number, number)}>
                  <Tag size="tiny" type={tagType} disabled={shouldDisabled}>
                    {icon}
                    {call.name}
                  </Tag>
                </Popover>
              )}
              {!isLast && <IconChevronsRight />}
            </React.Fragment>
          );
        })}
        { lead.calls.length <= 0 && (
          <p className="no-calls">
            No Valid Numbers
          </p>
        )}
      </div>
    </div>
  );
}
