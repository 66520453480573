import React, { Component } from 'react';
import { Avatar, Modal, Toggle as Switch, Tooltip, Loading, message } from '_seriph';
import { Button, Spin, Icon } from 'antd';
import './BulkEmail.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import ReactQuill from 'react-quill';
import { getBulkEmailsForModal, getSingleBulkEmailForModal } from '_graphql/queries/email';
import { sendBulkEmail } from '_graphql/mutations/email';
import { IconSend, IconClipboardList, IconReplace, IconTag, IconCircleCheck } from '@tabler/icons-react';
import { io } from 'socket.io-client';

class BulkEmailPreview extends Component {
  state = {
    expanded: false,
    isSent: false,
    isSending: false,
    mainArea: '',
    subject: '',
    numSmartTags: 0,
    replacedMainArea: '',
    replacedSubject: '',
    realData: false,
    signatureVisible: false,
    wasModified: false,
    problems: [],
    isDisabled: false
  };
  componentDidMount = () => {
    const {
      expanded,
      mainArea,
      subject,
      replacedMainArea,
      replacedSubject,
      numSmartTags,
      problems
    } = this.props;
    this.setState({
      expanded,
      mainArea,
      subject,
      replacedMainArea,
      replacedSubject,
      numSmartTags,
      problems
    });
  };

  showSignature = () => {
    this.setState({ signatureVisible: true });
  };

  toggleRealData = async () => {
    if (this.state.wasModified) {
      let result = await this.props.client.query({
        variables: {
          task_id: this.props.taskId,
          mainArea: this.state.mainArea,
          subject: this.state.subject,
          campaignId: this.props.campaignId
        },
        query: getSingleBulkEmailForModal
      });
      result = result.data?.getSingleBulkEmailForModal;

      this.setState({
        replacedMainArea: result?.replacedMainArea,
        replacedSubject: result?.replacedSubject,
        numSmartTags: result?.numSmartTags,
        problems: result?.problems,
        isDisabled: (result?.problems?.length ?? 0) > 0
      });
    }
    this.setState({ realData: !this.state.realData });
  };

  handleMainAreaModified = value => {
    if (!this.state.realData) {
      this.setState({ wasModified: true, mainArea: value });
    }
  };

  handleSubjectModified = value => {
    if (!this.state.realData) {
      this.setState({ wasModified: true, subject: value });
    }
  };

  render() {
    const {
      from,
      numLeads,
      day,
      company,
      campaign,
      toolbarId,
      agent,
      sendTest,
      sendActual,
      taskId,
      status,
      count
    } = this.props;

    const sending = status === 'sending' || status === 'sent';
    const expanded = this.state.expanded && !sending;

    return (
      <div className={`be-body ${status} ${expanded ? 'expanded' : ''}`}>
        <div
          className={classNames({ persistent: true, expanded })}
          onClick={() => (sending ? null : this.setState({ expanded: !this.state.expanded }))}
        >
          <div className="be-pers-left">
            {this.state.problems?.length ? (
              <div className="problems">
                {this.state.problems.map((problem, i) => (
                  <div key={i}> {problem.message}</div>
                ))}
              </div>
            ) : null}
            <div className="subject">
              <div id={`subject-toolbar-${taskId}`} className="hidden-toolbar" />
              <ReactQuill
                modules={{
                  toolbar: {
                    container: `#subject-toolbar-${taskId}`
                  }
                }}
                formats={['strike']}
                value={this.state.realData ? this.state.replacedSubject : this.state.subject}
                readOnly={this.state.realData}
                onChange={this.handleSubjectModified}
              />
            </div>

            <div className="from-label">From: </div>
            <div className="from">{from}</div>
          </div>

          <div className="be-pers-right">
            {sending ? (
              status === 'sending' ? (
                <div className="spinning">
                  <Spin indicator={<Icon type="loading" style={{ fontSize: 18 }} spin />} />
                  <div className="spinfo">
                    Sent to {count} of {numLeads} {numLeads > 1 ? 'leads' : 'lead'}...
                  </div>
                </div>
              ) : (
                <div className="spinning">
                  <div className="spinfo">
                    Sent to {numLeads} {numLeads > 1 ? 'leads' : 'lead'}
                  </div>
                  <IconCircleCheck />
                </div>
              )
            ) : (
              <Button className="be-expand">
                <FontAwesomeIcon icon={this.state.expanded ? faChevronUp : faChevronDown} />
              </Button>
            )}
          </div>
        </div>

        {expanded && (
          <div className="be-main">
            <div className="be-main-cont">
              <div className="be-main-form">
                <ReactQuill
                  formats={['bold', 'italic', 'underline', 'list', 'link', 'strike']}
                  modules={{
                    toolbar: {
                      container: `#${toolbarId}`
                    },
                    clipboard: { matchVisual: false }
                  }}
                  value={this.state.realData ? this.state.replacedMainArea : this.state.mainArea}
                  onChange={this.handleMainAreaModified}
                  readOnly={this.state.realData}
                />
              </div>
              <div className="be-main-summary">
                <ul className="be-bullets">
                  <li>
                    <Avatar type="company" picture={company.logo} size="icon" />
                    <div>{company.name}</div>
                  </li>
                  <li>
                    <IconClipboardList />
                    <div>{campaign.name}</div>
                  </li>
                  <li>
                    <IconSend />
                    <div>
                      {numLeads} {numLeads > 1 ? 'leads' : 'lead'}
                    </div>
                  </li>
                  <li>
                    <IconReplace />
                    <div>Day {day} in sequence</div>
                  </li>
                  <li>
                    <IconTag />
                    <div>{this.state.numSmartTags} smart tags</div>
                  </li>
                </ul>
                <div>
                  {status === 'tested' ? (
                    <Tooltip
                      overlayClassName="sellx-tooltip"
                      title={`A test email has been sent to your email (${this.props.agent?.email})`}
                      defaultVisible={true}
                    >
                      <Button className="be-test-button">
                        <FontAwesomeIcon icon={faCheck} />
                        <span>Test sent</span>
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      className="be-test-button"
                      onClick={async () => {
                        if (!this.state.realData) {
                          await this.toggleRealData();
                        }
                        sendTest(taskId, this.state.mainArea, this.state.subject);
                      }}
                      disabled={this.state.isDisabled}
                    >
                      {status === 'testing' ? (
                        <React.Fragment>
                          <div className="spinning-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                          <span>Sending test...</span>
                        </React.Fragment>
                      ) : (
                        'Send test'
                      )}
                    </Button>
                  )}
                  <Button
                    className="be-send-button"
                    disabled={this.state.isDisabled}
                    onClick={async () => {
                      if (!this.state.realData) {
                        await this.toggleRealData();
                      }
                      sendActual(taskId, this.state.mainArea, this.state.subject);
                    }}
                  >
                    Send to {numLeads} {numLeads > 1 ? 'leads' : 'lead'}
                  </Button>
                </div>
              </div>
            </div>
            <div className="be-main-signature">
              <p>Best,</p>
              {this.state.signatureVisible ? (
                <div className="be-sig">
                  <div className="be-sig-name">
                    --- <br />
                    {agent.display_name}
                  </div>
                  <div className="be-sig-title">
                    {campaign.custom_title ? `${campaign.custom_title} at` : ''} {company.name}
                  </div>
                  <div className="be-sig-phone">{agent.phone_number}</div>
                  <div className="be-sig-website">{company.website}</div>
                </div>
              ) : (
                <Button onClick={this.showSignature} className="be-sig-button">
                  •••
                </Button>
              )}
            </div>

            <div className="be-main-buttons">
              <div className="be-show-with">
                <Switch checked={this.state.realData} onChange={this.toggleRealData} />
                <div className="real-label">Show with real data</div>
              </div>
              <div id={toolbarId} className="be-toolbar">
                <div className="be-lb">&nbsp;</div>
                <button className="ql-bold"></button>
                <button className="ql-italic"></button>
                <button className="ql-underline"></button>
                <div className="be-lb">&nbsp;</div>
                <button className="ql-list" value="ordered"></button>
                <button className="ql-list" value="bullet"></button>
                <button className="ql-link"></button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default class BulkEmail extends Component {
  state = {
    visible: true,
    loading: true,
    socketId: null,
    ctLeads: []
  };
  hideModal = () => {
    this.setState({ visible: false });
  };
  componentDidMount = async () => {
    let results = await this.props.client.query({
      variables: { campaignId: this.props.campaignId },
      query: getBulkEmailsForModal
    });
    const ctLeads = results.data.getBulkEmailsForModal;
    this.setState({ ctLeads, loading: false });
    this.setupSocket();
  };

  setupSocket = () => {
    this.socket = io(`${process.env.REACT_APP_API_PATH}`, {
      withCredentials: true
    });
    this.socket.on('connect', () => {
      this.setState({ socketId: this.socket.id });
    });
    this.socket.onAny(this.listener);
  };
  componentWillUnmount = () => {
    this.socket.offAny(this.listener);
    this.socket?.close();
  };
  listener = (eventName, ...args) => {
    if (eventName === 'bulkEmailUpdate') {
      this.updateCount(args[0], args[1]?.count);
    }
  };
  sendTest = async (taskId, mainArea, subject) => {
    this.updateStatus(taskId, 'testing');
    await this.props.client.mutate({
      variables: {
        task_id: taskId,
        mainArea,
        subject,
        isTest: true,
        campaignId: this.props.campaignId,
        socketId: this.state.socketId
      },
      mutation: sendBulkEmail
    });
    this.updateStatus(taskId, 'tested');
  };

  sendActual = async (taskId, mainArea, subject) => {
    this.updateStatus(taskId, 'sending');
    this.modalRef.current?.scrollIntoView();

    const bulkResults = await this.props.client.mutate({
      variables: {
        task_id: taskId,
        mainArea,
        subject,
        campaignId: this.props.campaignId,
        socketId: this.state.socketId
      },
      mutation: sendBulkEmail
    });
    const results = bulkResults?.data?.sendBulkEmail;
    if (bulkResults?.success) {
      message.success(`Sent ${results.sent} email(s) and skipped ${results.skip}`);
    }
    this.updateStatus(taskId, 'sent');
    if (this.props.reloadHighlights) this.props.reloadHighlights();
  };

  updateCount = (taskId, count) => {
    const taskIndex = this.state.ctLeads.findIndex(c => c.taskId === taskId);
    if (taskIndex < 0) return;
    const ctLeads = [...this.state.ctLeads];
    ctLeads[taskIndex].count = count;
    this.setState({ ctLeads });
  };
  updateStatus = (taskId, status) => {
    const taskIndex = this.state.ctLeads.findIndex(c => c.taskId === taskId);
    if (taskIndex < 0) return;
    const ctLeads = [...this.state.ctLeads];
    ctLeads[taskIndex].status = status;
    this.setState({ ctLeads });
  };

  render() {
    this.modalRef = React.createRef();
    const { ctLeads } = this.state;
    const diffEmails = ctLeads?.length || 0;
    let isSending = false;
    const numLeads = ctLeads.reduce((num, ctLead) => {
      if (ctLead.status === 'sending') {
        isSending = true;
      }
      return num + ctLead?.leads?.length ?? 0;
    }, 0);

    const isLoading = this.state.loading || !this.state.socketId ? true : false;

    return (
      <Modal
        wrapClassName="bulk-email-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        afterClose={this.props.removeModal}
        onCancel={this.hideModal}
        style={{ top: 32 }}
        width={600}
        closable={false}
        maskClosable={isSending ? false : true}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <React.Fragment>
            {!isSending ? <Modal.Close onClick={this.hideModal} /> : null}
            <h3 className="be-title" ref={this.modalRef}>
              Send <span className="num-emails">{diffEmails} emails</span> to{' '}
              <span className="num-leads">{numLeads} leads</span>
            </h3>
            <h5 className="be-subtitle">Please review the emails below before sending</h5>
            {this.state.ctLeads.map((ctLead, i) => (
              <BulkEmailPreview
                key={`bulk-email-${i}`}
                expanded={i === 0}
                subject={ctLead.template.subject}
                agent={this.props.agent}
                status={ctLead.status || 'none'}
                count={ctLead.count || 0}
                client={this.props.client}
                numLeads={ctLead.leads.length}
                numSmartTags={ctLead.numSmartTags}
                replacedMainArea={ctLead.replacedMainArea}
                replacedSubject={ctLead.replacedSubject}
                mainArea={ctLead.template.content}
                campaign={ctLead.campaign}
                company={ctLead.company}
                from={ctLead.fromEmailAddress}
                day={ctLead.day}
                taskId={ctLead.taskId}
                toolbarId={`bulk-email-toolbar-${i}`}
                sendTest={this.sendTest}
                sendActual={this.sendActual}
                problems={ctLead.problems}
                campaignId={this.props.campaignId}
              />
            ))}
          </React.Fragment>
        )}
      </Modal>
    );
  }
}
