import React from 'react';
import { numberWithCommas } from '_assets/js/helpers';
import { Avatar, Button } from '_seriph';

export const getColumns = (masq) => {
  return [
    {
      title: 'Company',
      dataIndex: 'owner',
      render: (column, data) => {
        return (
          <div className="meeting-own">
            <Avatar
              size="mini"
              type="company"
              picture={data.company?.logo}
            />
            {data.company?.name}
          </div>
        );
      }
    },
    {
      title: 'Touchpoint Stats',
      children: [
        {
          title: 'Emails',
          dataIndex: 'daily.touchpoint_email',
          sorter: (a, b) => a.daily?.touchpoint_email - b.daily?.touchpoint_email,
          render: column => numberWithCommas(column)
        },
        {
          title: 'Calls',
          dataIndex: 'daily.touchpoint_call',
          sorter: (a, b) => a.daily?.touchpoint_call - b.daily?.touchpoint_call,
          render: column => numberWithCommas(column)
        },
        {
          title: 'LinkedIns',
          dataIndex: 'daily.touchpoint_linkedin',
          sorter: (a, b) => a.daily?.touchpoint_linkedin - b.daily?.touchpoint_linkedin,
          render: column => numberWithCommas(column)
        },
        {
          title: 'Total Completed',
          dataIndex: 'daily.touchpoint_total',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.daily?.touchpoint_total - b.daily?.touchpoint_total,
          render: column => numberWithCommas(column)
        },
      ]
    },
    {
      title: 'Meetings Booked',
      dataIndex: 'daily.meetings',
      sorter: (a, b) => a.daily?.meetings - b.daily?.meetings,
      render: meetings => {
        return numberWithCommas(meetings) +  ` meeting${meetings !== 1 ? 's' : ''}`;
      }
    },
    {
      title: 'Login',
      dataIndex: 'login',
      render: (column, data) => {
        return (
          <Button size="small" type="primary" onClick={() => masq(data.company?.owner_id)}>
            Login
          </Button>
        );
      }
    },
  ];
};
