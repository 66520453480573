import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { reportLeadBreakdown } from '_graphql/queries/analytics';
import { generateData } from './_helpers/data';
import CampaignDropdown from '_shared/CampaignDropdown/CampaignDropdown';
import { Header, message, Button, Loading } from '_seriph';
import IndustryChart from './_charts/IndustryChart/IndustryChart';
import TitleChart from './_charts/TitleChart/TitleChart';
import PositionChart from './_charts/PositionChart/PositionChart';
import IndustryTitleChart from './_charts/IndustryTitleChart/IndustryTitleChart';
import './LeadBreakdown.scss';

class LeadBreakdown extends Component {
  state = {
    loading: false,
    data: null,

    campaign: undefined,
    campaign_name: null,
    range: 'last7'
  };
  getStats = () => {
    if (!this.state.loading) this.setState({ loading: true });
    this.props.client
      .query({ variables: { campaign_id: this.state.campaign }, query: reportLeadBreakdown })
      .then(result => {
        if (result && result.data && result.data.reportLeadBreakdown) {
          const data = result.data.reportLeadBreakdown || {};
          this.setState({ data: generateData(data), campaign_name: data.campaign?.name }, () => {
            this.setState({ loading: false });
          });
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        message.error('Could not load your statistics, try again');
        this.setState({ loading: false });
      });
  };
  selectCampaign = campaign => {
    const params = queryString.parse(this.props.location.search);
    if (params.campaign) this.props.history.push('/');
    this.setState({ campaign });
  };
  selectRange = range => this.setState({ range }, this.getStats);
  UNSAFE_componentWillMount = () => window.scrollTo({ top: 0 });
  render() {
    const { data, loading, campaign, campaign_name } = this.state;

    return (
      <div id="company-analytics-lead-breakdown">
        <div className="seriph-header-wrapper">
          <Header type="display" weight="600" size="4">
            Leads breakdown
          </Header>
        </div>

        <div className="dashboard-actions">
          <span className="no-left">Select a campaign</span>
          <CampaignDropdown
            singleCampaign={true}
            placement="bottomLeft"
            campaign={campaign}
            campaigns={this.props.campaigns}
            selectCampaign={this.selectCampaign}
          />
          <span>then</span>
          <Button
            onClick={this.getStats}
            loading={loading}
            disabled={!campaign}
            type="primary"
            size="medium"
          >
            Generate a report
          </Button>
        </div>

        {loading || !data ? (
          <div className="report-loading">
            {loading ? <Loading /> : 'Select a campaign and click generate above to see report'}
          </div>
        ) : (
          <div className="chart-list">
            <IndustryTitleChart
              data={data.indtitle}
              titles={data.titles}
              campaign={campaign_name}
              loading={loading}
            />
            <IndustryChart data={data.industries} campaign={campaign_name} loading={loading} />
            <TitleChart data={data.titles} campaign={campaign_name} loading={loading} />
            <PositionChart data={data.positions} campaign={campaign_name} loading={loading} />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(LeadBreakdown);
