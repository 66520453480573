import React, { Component } from 'react';
import { Promise } from 'bluebird';
import { withRouter } from 'react-router-dom';
import { Modal, Button, Upload, Tooltip } from 'antd';
import { createLead } from '_graphql/mutations/lead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import csv from 'csvtojson';
import { importFields } from 'App/Company/Leads/Lead/formData';
import { LevenshteinDistance } from '_assets/js/helpers';

import Guide from '_styleguide/Guide/Guide';
import { deduplicateHeaders } from '_helpers/csv';
import { getImports } from '_graphql/queries/campaign';

import CSVMapping from 'App/Company/Wizard/_components/Import/_modals/CSVMapping/CSVMapping';
import {
  faTimes,
  faCloudUploadAlt,
  faFileCsv,
  faChevronLeft
} from '@fortawesome/free-solid-svg-icons';
import './UploadLeads.scss';
import classNames from 'classnames';
import Loader from './Loader';
import parsePhoneNumber from 'libphonenumber-js';

import { IconAlertTriangle } from '@tabler/icons-react';

const { Dragger } = Upload;
const formDataKeys = Object.keys(importFields);
const objectKeysToLowerCase = origObj =>
  Object.fromEntries(Object.entries(origObj).map(([k, v]) => [k.toLowerCase(), v]));

class UploadLeads extends Component {
  state = {
    visible: true,
    campaign: undefined,
    saving: false,
    ready: false,
    csvReady: false,
    processingNumber: 0,
    validPhones: 0,
    validMobiles: 0,
    validEmails: 0,
    width: 540,
    leadErrors: [],
    viewErrors: false
  };
  hideModal = () => {
    if (this.props.afterClose) {
      this.props.afterClose();
    }
  };
  defaultMappings = async csvKeys => {
    const {
      defaultMappings,
      success,
      mappings = {}
    } = await this.retrieveDefaultMappings(this.props.campaign.id);
    csvKeys.forEach(csvKey => {
      mappings[csvKey] = null;
      if (success && defaultMappings[csvKey]) {
        mappings[csvKey] = defaultMappings[csvKey];
      } else {
        formDataKeys.forEach(fdKey => {
          if (LevenshteinDistance(csvKey, fdKey) <= 3) {
            mappings[csvKey] = fdKey;
          }
        });
      }
    });
    return mappings;
  };
  renderUpload = () => {
    const options = {
      name: 'file',
      multiple: false,
      accept: '.csv',
      disabled: this.props.disabled,
      showUploadList: false,
      beforeUpload: file => {
        this.setState({ importing: true });
        const reader = new FileReader();
        reader.onload = e => {
          csv({ flatKeys: true })
            .fromString(e.target.result)
            .on('header', headers => deduplicateHeaders(headers))
            .then(async jsonObj => {
              if (jsonObj && jsonObj.length > 0) {
                const mappings = await this.defaultMappings(Object.keys(jsonObj[0]));
                const amount = jsonObj.length;
                const data = Object.keys(mappings).map(csvKey => {
                  const base = {
                    csvKey: csvKey,
                    leadProperty: mappings[csvKey],
                    exampleValueOne: jsonObj[0][csvKey]
                  };
                  if (amount > 1) base.exampleValueTwo = jsonObj[1][csvKey];
                  if (amount > 2) base.exampleValueThree = jsonObj[2][csvKey];
                  return base;
                });
                this.setState({
                  importing: false,
                  fileName: file.name,
                  columnCount: Object.keys(mappings).length,
                  mappingModal: true,
                  data: data,
                  ready: false,
                  allData: jsonObj,
                  dataCount: amount,
                  csvReady: false
                });
              } else {
                this.setState({
                  importing: null,
                  fileName: null,
                  mappingModal: false,
                  columnCount: 0,
                  data: null,
                  allData: null,
                  ready: false,
                  dataCount: 0,
                  csvReady: false
                });
              }
            });
        };
        reader.readAsText(file);
        return false;
      }
    };
    return (
      <Dragger {...options}>
        <p className="ant-upload-drag-icon">
          <FontAwesomeIcon icon={faCloudUploadAlt} />
        </p>
        <p className="ant-upload-text">Upload CSV or XLS file</p>
        <p className="ant-upload-desc">
          or click to <a>browse</a>
        </p>
      </Dragger>
    );
  };
  maxUploadWarning = () => {
    const header = (
      <React.Fragment>
        Bulk upload has a limit of <b>20 leads</b> per upload
      </React.Fragment>
    );
    return <Guide header={header} type="alert" dismissable={false} />;
  };
  overlimitWarning = dataCount => {
    const header = `${dataCount} leads found`;
    const message = 'The first 20 leads will be used.';
    return <Guide header={header} message={message} type="alert" dismissable={false} />;
  };
  underLimit = () => {
    const header = 'Great job! All leads will be used.';
    return <Guide message={header} type="info" dismissable={false} />;
  };
  leadsWithErrors = () => {
    const header = 'Leads with errors';
    const message = (
      <React.Fragment>
        We&apos;re ready to upload your leads. Leads with validation errors will be created &quot;
        <b>In-Progress</b>&quot;, while valid leads will be submitted for review. Make sure to
        update later before submitting for review
      </React.Fragment>
    );
    return <Guide message={message} header={header} type="info" dismissable={false} />;
  };
  removeCSV = () =>
    this.setState({
      importing: null,
      fileName: null,
      // data: null,
      // allData: null,
      dataCount: 0,
      ready: false
    });
  retrieveDefaultMappings = async campaign_id => {
    const defaultMappings = {};
    try {
      const results = await this.props.client.query({
        query: getImports,
        variables: { campaign_id }
      });

      const [{ mappings }] = results?.data?.imports;
      JSON.parse(mappings).forEach(
        ({ csvKey, leadProperty }) => (defaultMappings[csvKey] = leadProperty)
      );
      return { defaultMappings, success: true };
    } catch (error) {
      return { defaultMappings, success: false };
    }
  };
  acceptCSV = campaign => {
    const { importing, dataCount, fileName, columnCount } = this.state;
    const { name } = campaign;
    return (
      <div className="file-upload">
        <div className="upload-header">
          <div className="back-icon">
            <FontAwesomeIcon icon={faChevronLeft} onClick={this.hideModal} />
          </div>
          <h3>Upload file</h3>
          <p>{name}</p>
        </div>

        {!fileName && (
          <div className="select-csv">
            {this.renderUpload()}
            {this.maxUploadWarning()}
          </div>
        )}
        {fileName && (
          <div className="confirm-csv">
            <div className="csv-bubble">
              <div className="csv-icon">
                <FontAwesomeIcon className="btnx-left" icon={faFileCsv} />
              </div>
              <div className="csv-description">
                <div className="filename">{fileName}</div>
                <div className="description">
                  {columnCount} columns • {dataCount} leads
                </div>
              </div>

              <Tooltip overlayClassName="sellx-tooltip sellx-tooltip-small" title="Remove">
                <div className="csv-delete">
                  <FontAwesomeIcon className="btnx-left" icon={faTimes} onClick={this.removeCSV} />
                </div>
              </Tooltip>
            </div>
            {dataCount > 20 && this.overlimitWarning(dataCount)}
            {dataCount <= 20 && this.underLimit()}
          </div>
        )}
        <div className="create-actions">
          <Button className="btn btn-large btn-default" onClick={this.hideModal}>
            &#8249; Back
          </Button>
          <Button
            disabled={!fileName}
            loading={importing}
            className="btn btn-large btn-primary"
            onClick={() => this.setState({ ready: true })}
          >
            Continue
          </Button>
        </div>
      </div>
    );
  };
  loadImports = (importData, importMappings, importObject) => {
    const importKeyMap = {};
    importMappings.forEach(({ csvKey, leadProperty }) => (importKeyMap[csvKey] = leadProperty));
    this.setState({
      csvReady: true,
      importData,
      importKeyMap,
      importObject
    });
  };
  sanatizeLeadKeys = (leadObject, importKeyMap, customFields = []) => {
    const keyWhiteList = new Set(formDataKeys);
    const typeMap = {};
    const customFieldKeys = customFields?.map(v => {
      typeMap[v.label] = v.type;
      return `custom-${v.label}`;
    });
    const leadObjectCopy = objectKeysToLowerCase(
      Object.keys(leadObject).reduce((newObj, key) => {
        if (customFieldKeys?.includes(importKeyMap[key])) {
          const keyLookup = importKeyMap[key].replace('custom-', '');
          newObj['custom_fields'] ||= [];
          newObj['custom_fields'].push({
            label: keyLookup,
            type: typeMap[keyLookup],
            value: leadObject[key]
          });
        } else {
          newObj[importKeyMap[key]] = leadObject[key];
        }
        return newObj;
      }, {})
    );

    for (let prop of Object.keys(leadObjectCopy)) {
      if (!keyWhiteList.has(prop)) {
        delete leadObjectCopy[prop];
      }
    }

    return leadObjectCopy;
  };
  validEmail = email => {
    return email && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
  };
  validPhone = phone => {
    if (!phone) {
      return false;
    }
    const phoneNumber = parsePhoneNumber(phone);
    return phoneNumber && phoneNumber.isValid();
  };
  validateCSV = () => {
    const { processing, processingComplete, importData /*importObject*/ } = this.state;
    if (!processing && !processingComplete) {
      this.setState({ processing: true, totalToProcess: importData.length });
      importData.forEach(({ email, phone }, index) => {
        this.setState({ processingNumber: index + 1 });
        this.setState({
          validPhones: this.validPhone(phone) ? validPhones + 1 : validPhones,
          validEmails: this.validEmail(email) ? validEmails + 1 : validEmails
        });
      });
      this.setState({ processing: false, processingComplete: true });
    }

    const { totalToProcess, processingNumber, validPhones, validEmails } = this.state;
    const doneWithErrors =
      processingComplete &&
      (processingNumber - validEmails > 0 || processingNumber - validPhones > 0);
    const getText = (errors, done) => {
      if (errors > 0) {
        return `${errors} errors found`;
      } else if (done) {
        return 'No errors';
      }
      return 'Processing...';
    };
    let emailState = '';
    if (processingNumber - validEmails > 0) {
      emailState = 'warning';
    } else if (!processing) {
      emailState = 'success';
    }
    let phoneState = '';
    if (processingNumber - validPhones > 0) {
      phoneState = 'warning';
    } else if (!processing) {
      phoneState = 'success';
    }
    return (
      <div className="confirm-csv">
        <div className="header-bar">
          <div className="header-loader">
            <Loader state={processingComplete ? 'success' : ''} />
          </div>
          <div className="header-description">
            {!processingComplete ? (
              <h3> Hang tight, we’re processing your csv </h3>
            ) : (
              <h3>Form validation complete</h3>
            )}
            <p>
              {!processingComplete
                ? `Processing ${processingNumber} of ${totalToProcess}`
                : `All ${totalToProcess} leads ready for submission.`}
            </p>
          </div>
        </div>
        <div className="validation-list">
          <div
            className={classNames([
              'validation-item',
              {
                warning: processingNumber - validPhones > 0
              }
            ])}
          >
            <Loader state={phoneState} />
            <div className="validation-type">Phone</div>
            <div className="validation-status">
              {getText(processingNumber - validPhones, processingComplete)}
            </div>
          </div>
          <div
            className={classNames([
              'validation-item',
              {
                warning: processingNumber - validEmails > 0
              }
            ])}
          >
            <Loader state={emailState} />
            <div className="validation-type">Email</div>
            <div className="validation-status">
              {getText(processingNumber - validEmails, processingComplete)}
            </div>
          </div>
        </div>
        {doneWithErrors && this.leadsWithErrors()}

        <div className="create-actions">
          <Button
            className="btn btn-large btn-default"
            onClick={() => {
              this.setState({
                ready: false,
                uploadReady: false,
                csvReady: false
              });
            }}
          >
            &#8249; Back
          </Button>
          <Button
            disabled={!processingComplete}
            loading={processing}
            className="btn btn-large btn-primary"
            onClick={() =>
              this.setState({
                uploadReady: true,
                processing: false,
                processingComplete: false,
                processingNumber: 0
              })
            }
          >
            Submit {processingNumber} Leads
          </Button>
        </div>
      </div>
    );
  };
  doCSVUpload = async importData => {
    const { importKeyMap, importObject } = this.state;
    const leadErrors = [];
    for (let index in importData) {
      const lead = importData[index];
      let stateUpdate = { processingNumber: ++index };
      try {
        const result = await this.props.client.mutate({
          variables: {
            submit: true,
            lead: {
              ...this.sanatizeLeadKeys(
                {
                  ...lead,
                  Phone: parsePhoneNumber(lead.Phone?.toString() || '', 'US')?.format('RFC3966'),
                  Mobile: parsePhoneNumber(lead.Mobile?.toString() || '', 'US')?.format('RFC3966')
                },
                importKeyMap,
                this.props.campaign?.custom_fields
              ),
              ...{
                campaign_id: this.props.campaign.id,
                import_id: importObject.id
              }
            }
          },
          mutation: createLead
        });
        if (result?.data?.createLead) {
          const { validPhones, validMobiles, validEmails } = this.state;
          const { phone_valid, mobile_valid, email_valid } = result.data.createLead;
          stateUpdate = {
            ...stateUpdate,
            ...{
              validPhones: phone_valid ? validPhones + 1 : validPhones,
              validMobiles: mobile_valid ? validMobiles + 1 : validMobiles,
              validEmails: email_valid ? validEmails + 1 : validEmails
            }
          };
        } else {
          console.error('Error creating lead information', result);
        }
      } catch (e) {
        console.log(e);
        leadErrors.push({
          ...lead,
          error: e?.graphQLErrors?.[0] || e
        });
      }
      this.setState({ ...stateUpdate, leadErrors });
      await Promise.delay(100);
    }
    this.setState({ processing: false, processingComplete: true });
  };
  processCSV = () => {
    // Lead submission stuff
    const {
      processing,
      processingComplete,
      importData,
      leadErrors,
      totalToProcess,
      processingNumber
    } = this.state;

    if (!processing && !processingComplete) {
      this.setState({ processing: true, totalToProcess: importData.length });
      this.doCSVUpload(importData);
    }
    const clickHandler = () => {
      if (leadErrors.length > 0) {
        this.setState({ viewErrors: true, csvReady: false, uploadReady: false });
      } else {
        this.hideModal();
      }
    };
    return (
      <div className="confirm-csv">
        <div className="header-bar">
          <div className="header-loader">
            <Loader state={processingComplete ? 'success' : ''} />
          </div>
          <div className="header-description">
            {!processingComplete ? (
              <h3> Hang tight, we’re submitting your csv </h3>
            ) : (
              <h3>Lead submission complete!</h3>
            )}
            <p>
              {!processingComplete
                ? `Processing ${processingNumber} of ${totalToProcess}`
                : `${totalToProcess - leadErrors.length} leads have been created`}
              <br />
              {leadErrors.length > 0 ? `${leadErrors.length} leads have errors` : ''}
            </p>
          </div>
        </div>
        <div className="create-actions">
          <Button
            disabled={!processingComplete}
            loading={processing}
            className="btn btn-large btn-primary"
            onClick={clickHandler}
          >
            Continue
          </Button>
        </div>
      </div>
    );
  };
  renderErrors() {
    const { leadErrors, importKeyMap } = this.state;
    return (
      <div className="errors-csv">
        <div className="header-bar">
          <div className="header-loader">
            <h3>Errors</h3>
          </div>
        </div>
        <table className="csv-table">
          <tbody>
            {leadErrors.map((error, idx) => {
              const lead = this.sanatizeLeadKeys(
                error,
                importKeyMap,
                this.props.campaign?.custom_fields
              );
              return (
                <tr key={'ar-' + idx}>
                  <td>{lead.first_name}</td>
                  <td>{lead.last_name}</td>
                  <td>
                    <Tooltip
                      placement="right"
                      title={error.error.message}
                      overlayClassName="menu-tt"
                    >
                      <IconAlertTriangle />
                    </Tooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="create-actions">
          <Button className="btn btn-large btn-primary" onClick={this.hideModal}>
            Done
          </Button>
        </div>
      </div>
    );
  }
  pickView = campaign => {
    const { csvReady, viewErrors, uploadReady } = this.state;
    if (csvReady && !uploadReady) {
      return this.validateCSV();
    } else if (uploadReady) {
      return this.processCSV();
    } else if (viewErrors) {
      return this.renderErrors();
    } else {
      return this.acceptCSV(campaign);
    }
  };
  render() {
    // const { campaign: activeCampaign, leadUploadType } = this.state;
    const { campaign } = this.props;
    const { data, dataCount, fileName, allData, ready, width } = this.state;

    return (
      <Modal
        wrapClassName="create-lead-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        width={width}
        closable={true}
        maskClosable={false}
      >
        {ready && (
          <CSVMapping
            data={data}
            allData={allData.slice(0, 20)}
            fileName={fileName}
            dataCount={dataCount > 20 ? 20 : dataCount}
            campaignId={campaign.id}
            extraClasses="create-lead-page"
            defaultMappings={this.mappings}
            // updateMappings={this.updateMappings}
            campaignCustomFields={campaign?.custom_fields || []}
            client={this.props.client}
            confirmClose={true}
            reload={this.loadImports}
            // removeModal={this.removeCSV}
            importType="agent"
            cancelText="&#8249; Back"
            actionText="Validate"
            alertText="Your leads are ready to be validated!"
          />
        )}
        {this.pickView(campaign)}
      </Modal>
    );
  }
}

export default withRouter(UploadLeads);
