import { pickerData } from '_constants/salesforce';
import { Country } from 'country-state-city';

export const companyStages = [
  {
    stage: 1,
    title: 'Tell us about yourself',
    subtitle: 'So we have a way to get in touch with you',
    inputs: [
      {
        type: 'text',
        label: 'Full name',
        key: 'name',
        placeholder: 'Michael Scott',
        validation: 'name',
        validationStateKey: 'name_valid',
        errorText: 'Please enter both, first and last name'
      },
      {
        type: 'text',
        label: 'Business email address',
        key: 'email',
        placeholder: 'name@company.com',
        validation: 'email',
        validationStateKey: 'email_valid',
        errorText: 'Invalid email'
      },
      {
        type: 'text',
        label: 'Mobile number',
        key: 'phone',
        placeholder: '631 928 1988',
        validation: 'phone',
        validationStateKey: 'phone_valid',
        format: 'phone',
        errorText: 'Invalid phone number'
      }
    ]
  },
  {
    stage: 2,
    title: 'Introduce your company',
    subtitle: 'This will help to start filling out your account',
    inputs: [
      {
        type: 'text',
        label: 'Company name',
        key: 'company_name',
        placeholder: 'Acme, Inc'
      },
      {
        type: 'text',
        label: 'Company website',
        key: 'company_website',
        placeholder: 'www.companywebsite.com',
        validation: 'website',
        validationStateKey: 'website_valid',
        errorText: 'Invalid website'
      },
      {
        type: 'radio-button-list',
        label: 'How big is your company?',
        labelhelper: '(select one)',
        key: 'company_size',
        multi: false,
        options: [
          { title: '0-10', value: '0-10' },
          { title: '11-50', value: '11-50' },
          { title: '51-100', value: '51-100' },
          { title: '101-250', value: '101-250' },
          { title: '251+', value: '251+' }
        ]
      },
      {
        type: 'select',
        label: 'What industry is your company in?',
        placeholder: '---',
        key: 'industries',
        options: pickerData.industry
      }
    ]
  },
  {
    stage: 3,
    title: 'What can we help you with?',
    subtitle: 'Select as many options that apply',
    key: 'needs',
    inputs: [
      {
        type: 'checkbox-list',
        key: 'needs',
        multi: true,
        options: [
          { title: 'List Building', value: 'list-building' },
          { title: 'Warm outreach', value: 'warm-outreach' },
          { title: 'Qualifying Cold Leads', value: 'qualify-cold' },
          { title: 'Full Sales Cycle', value: 'full-sales' },
          { title: 'Market research', value: 'market-research' },
          { title: 'Data enrichment', value: 'data-enrich' },
          { title: 'Customer support', value: 'customer-support' },
          { title: 'Success & Retention', value: 'success-ret' }
        ]
      }
    ]
  },
  {
    stage: 4,
    title: 'How soon would you like to get started?',
    subtitle: 'Select 1 option that applies',
    inputs: [
      {
        type: 'checkbox-list',
        multi: false,
        key: 'desired_start',
        options: [
          { title: 'Immediately', value: 'immediately' },
          { title: '1 - 2 weeks', value: '1-2-weeks' },
          { title: '3 - 4 weeks', value: '3-4-weeks' },
          { title: '1 - 3 months', value: '1-3-months' },
          { title: "I'm not sure", value: 'not-sure' }
        ]
      }
    ]
  },
  {
    stage: 5,
    title: 'What level of engagement do you need?',
    subtitle: 'Just an estimate, this can be adjusted later',
    inputs: [
      {
        type: 'checkbox-list',
        multi: false,
        key: 'desired_engagement',
        options: [
          { title: '5 hours / week', value: '5 hours / week' },
          { title: '10 hours / week', value: '10 hours / week' },
          { title: '25 hours / week', value: '25 hours / week' },
          { title: '40 hours / week', value: '40 hours / week' },
          { title: "I'm not sure", value: "I'm not sure" }
        ]
      }
    ]
  },
  {
    stage: 6,
    title: 'Are you ok working with someone remotely?',
    subtitle: '',
    textarea: {
      key: 'ok_with_remote_explanation',
      placeholder: 'I prefer an internal team because...',
      label: 'Any reason why?'
    },
    inputs: [
      {
        type: 'checkbox-list',
        multi: false,
        key: 'ok_with_remote',
        options: [
          { title: 'Yes', value: 'yes' },
          { title: 'No', value: 'no', textarea: true },
          { title: "I'm not sure", value: 'not-sure' }
        ]
      }
    ]
  },
  {
    stage: 7,
    title: 'How did you hear about us?',
    subtitle: '',
    textarea: {
      key: 'hear_about_other',
      placeholder: 'I heard a podcast about SellX..'
    },
    inputs: [
      {
        type: 'checkbox-list',
        multi: true,
        key: 'hear_about',
        options: [
          { title: 'Search engine (Google etc..)', value: 'search-engine' },
          { title: 'Social Media (Linkedin, Reddit etc...)', value: 'social-media' },
          { title: 'Blog post', value: 'blog' },
          { title: 'News article', value: 'news' },
          { title: 'Referred by a friend / co-worker', value: 'referred' },
          { title: 'Other...', value: 'other', textarea: true }
        ]
      }
    ]
  },
  {
    stage: 8,
    title: 'Schedule a walkthrough with us',
    subtitle: 'To finish setting up your account, please schedule an onboarding call with our team',
    inputs: [
      {
        type: 'calendly-embed',
        key: 'calendly-embed',
        url: 'https://calendly.com/mattsellx'
      }
    ]
  }
];

export const repStages = [
  {
    stage: 1,
    title: 'Tell us about yourself',
    subtitle: 'So we have a way to get in touch with you',
    inputs: [
      {
        type: 'text',
        label: 'Full name',
        key: 'name',
        placeholder: 'Michael Scott...',
        validation: 'name',
        validationStateKey: 'name_valid',
        errorText: 'Please enter both, first and last name'
      },
      {
        type: 'text',
        label: 'Email address',
        key: 'email',
        placeholder: 'name@website.com...',
        validation: 'email',
        validationStateKey: 'email_valid',
        errorText: 'Invalid email'
      },
      {
        type: 'text',
        label: 'Mobile number',
        key: 'phone',
        placeholder: '631 928 1988...',
        validation: 'phone',
        validationStateKey: 'phone_valid',
        format: 'phone',
        errorText: 'Invalid phone number'
      },
      {
        type: 'text',
        label: 'Linkedin URL',
        key: 'linkedin_url',
        placeholder: 'linkedin.com/in/your-handle...',
        validation: 'linkedin',
        validationStateKey: 'linkedin_valid',
        errorText:
          'Invalid URL. Please use the following format: https://www.linkedin.com/in/your-handle'
      }
    ]
  },
  {
    stage: 2,
    title: 'Where are you located?',
    subtitle: 'Please select your country, city & state',
    inputs: [
      {
        type: 'select',
        label: 'Select your country',
        placeholder: '---',
        key: 'country',
        showSearch: true,
        options: Country.getAllCountries().map(c => {
          return { title: c.name, id: c.name };
        })
      },
      {
        type: 'select',
        label: 'Select your state',
        placeholder: '---',
        key: 'state',
        showSearch: true,
        options: []
      },
      {
        type: 'select',
        label: 'Select your city',
        placeholder: '---',
        key: 'city',
        showSearch: true,
        options: []
      }
    ]
  },
  {
    stage: 3,
    title: 'How long have you been in sales?',
    subtitle: '',
    inputs: [
      {
        type: 'checkbox-list',
        multi: false,
        key: 'years_experience',
        options: [
          { title: 'No experience', value: 'no-experience' },
          { title: 'Less than 6 months', value: 'less-6-months' },
          { title: '6 months - 1 year', value: '6months-1year' },
          { title: '1 - 3 years', value: '1-3-years' },
          { title: '3 - 5 years', value: '3-5-years' },
          { title: '5+ years', value: '5-years' }
        ]
      }
    ]
  },

  {
    stage: 4,
    title: 'What industry are you most experienced?',
    subtitle: 'Select up to 3 that apply',
    startAtTop: true,
    inputs: [
      {
        type: 'checkbox-list',
        multi: true,
        key: 'most_experienced_industries',
        searchPlaceholder: 'Search industries...',
        options: pickerData.industry.map(ind => {
          return { value: ind, key: ind, title: ind };
        }),
        hasSearch: true
      }
    ]
  },

  {
    stage: 5,
    title: 'If selected, what is your availability?',
    subtitle: '',
    inputs: [
      {
        type: 'checkbox-list',
        multi: false,
        key: 'weekly_availability',
        options: [
          { title: '1 - 10 hours / week', value: '1-10-hours-week' },
          { title: '11 - 25 hours / week', value: '11-25-hours-week' },
          { title: '26 - 40 hours / week', value: '26-40-hours-week' },
          { title: '40 hours / week +', value: '40-plus-hours-week' }
        ]
      }
    ]
  },

  {
    stage: 6,
    title: 'Why do you want to join SellX?',
    subtitle: 'Select as many options that apply',
    inputs: [
      {
        type: 'checkbox-list',
        multi: true,
        key: 'why_join',
        options: [
          { title: 'Create income', value: 'create-income' },
          { title: 'Work remotely', value: 'work-remotely' },
          { title: 'Flexible schedule', value: 'fleixble-schedule' },
          { title: 'Work with various companies', value: 'various-companies' },
          { title: 'Work in tech sales', value: 'work-tech-sales' },
          { title: 'Join exclusive community', value: 'exclusive-community' },
          { title: 'Other...', value: 'other', textarea: true }
        ]
      }
    ]
  },

  {
    stage: 7,
    title: "What's your biggest sales achievement to date?",
    subtitle: 'Flex a little, tell us something that highlights you',
    inputs: [
      {
        type: 'textarea',
        key: 'biggest_achievement',
        placeholder: 'Awarded best up and coming sales rep in my organization...'
      }
    ]
  },

  {
    stage: 8,
    title: 'How did you hear about us?',
    subtitle: '',
    textarea: {
      key: 'hear_about_other',
      placeholder: 'I heard a podcast about SellX..'
    },
    inputs: [
      {
        type: 'checkbox-list',
        multi: true,
        key: 'hear_about',
        options: [
          { title: 'Search engine (Google etc..)', value: 'search-engine' },
          { title: 'Social Media (Linkedin, Reddit etc...)', value: 'social-media' },
          { title: 'Blog post', value: 'blog' },
          { title: 'News article', value: 'news' },
          { title: 'Referred by a friend / co-worker', value: 'referred' },
          { title: 'Other...', value: 'other', textarea: true }
        ]
      }
    ]
  },
  {
    stage: 9,
    title: 'Schedule an interview with us',
    subtitle: 'You might be a great fit to join us!',
    inputs: [
      {
        type: 'calendly-embed',
        key: 'calendly-embed',
        url: 'https://calendly.com/mattsellx'
      }
    ]
  },
  {
    stage: 'waitlist',
    title: '', // set by component
    subtitle: '', // set by component
    inputs: []
  }
];
