import parsePhoneNumber from 'libphonenumber-js';

const VALID_COUNTRIES = ['US', 'CA'];

export const getCallList = leads => {
  const globalPhoneOptions = new Set(['phone', 'mobile']);

  leads.forEach(l => {
    if (l.current_task?.touchpoint?.id) {
      l.custom_fields.forEach(f => {
        if (f.type === 'phone') globalPhoneOptions.add(f.label);
      });
      return true;
    }
  });

  const leadsToCall = generateLeadsToCall(leads, globalPhoneOptions);

  return {
    list: leadsToCall,
    phoneOptions: globalPhoneOptions
  };
};

const generateLeadsToCall = (leads, globalPhoneOptions) => {
  const leadsData = leads.map(lead => {
    lead.calls = [];
    lead.completed = false;
    lead.started = false;
    globalPhoneOptions.forEach(s => {
      const customExists = lead?.custom_fields?.find(c => c.label === s);

      let number = customExists ? customExists?.value : null;
      if (!number) number = lead[s] || '';

      const numberObject = parsePhoneNumber(number, 'US');

      let invalid = false, invalidReason = null, duplicate = false;
      if (!(numberObject && numberObject?.isValid())) {
        invalidReason = 'Invalid phone number';
        invalid = true;
      }
      if (numberObject?.country && !VALID_COUNTRIES.includes(numberObject?.country)) {
        invalidReason = 'The country ('+numberObject?.country+') is not currently supported ('+VALID_COUNTRIES.join(', ')+')';
        invalid = true;
      }
      // if (lead.calls.find(c => c.number === numberObject?.number)) {
      //   invalidReason = 'This number '+numberObject?.formatNational()+' is a duplicate of another number';
      //   invalid = true;
      // }
      if (numberObject?.number && lead.calls.find(c => c.number === numberObject?.number)) {
        duplicate = true;
      }
      lead.calls.push({
        name: s,
        type: customExists ? 'custom' : 'field',
        key: (`${s}-${customExists ? 'custom' : 'field'}`).toLowerCase(),
        number: numberObject?.number,
        extension: numberObject?.ext,
        pretty: numberObject?.formatNational(),
        duplicate: duplicate,
        invalid: invalid,
        invalid_reason: invalidReason,
        skip: false,
        status: 'ready',
        called: false,
        result: null
      });
    });

    return lead;
  });

  leadsData.sort((x, y) => {
    var n = x.current_task?.day - y.current_task?.day;
    if (n !== 0) return n;
    return x.current_task?.step - y.current_task?.step;
  });

  return leadsData;
};
