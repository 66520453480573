import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IconDots } from '@tabler/icons-react';
import { Dropdown, Menu } from 'antd';
import { message, Modal, Button } from '_seriph';
import { getErrors } from '_assets/js/helpers';
import { removeSequence, archiveSequence, unarchiveSequence } from '_graphql/mutations/sequence';
import './SequenceActions.scss';

class SequenceActions extends Component {
  editSequence = () => {
    const { sequence } = this.props;
    this.props.history.push('/sequences/' + sequence.id);
  };
  viewStats = () => {
    const { sequence } = this.props;
    this.props.history.push('/sequences/' + sequence.id + '/data');
  };
  cloneSequence = () => {
    if (this.props.cloneSequence) {
      this.props.cloneSequence(this.props.sequence.id);
    } else {
      message.error('Error cloning sequence, try again.');
    }
  };
  archive = () => {
    const id = this.props.sequence.id;
    this.props.apollo
      .mutate({ variables: { id }, mutation: archiveSequence })
      .then(result => {
        if (result && result.data && result.data.archiveSequence) {
          message.success('Sequence archived');
          if (this.props.reloadSequences) this.props.reloadSequences();
        } else {
          throw new Error();
        }
      })
      .catch(err => message.error(getErrors(err) || 'Error archiving sequence, try again.'));
  };
  unarchive = () => {
    const id = this.props.sequence.id;
    this.props.apollo
      .mutate({ variables: { id }, mutation: unarchiveSequence })
      .then(result => {
        if (result && result.data && result.data.unarchiveSequence) {
          message.success('Sequence unarchived');
          if (this.props.reloadSequences) this.props.reloadSequences();
        } else {
          throw new Error();
        }
      })
      .catch(err => message.error(getErrors(err) || 'Error unarchiving sequence, try again.'));
  };
  removeSequence = () => {
    Modal.confirm({
      title: 'Are you sure you want to remove this sequence?',
      content:
        'DANGER: This cannot be undone, all stats and data will be lost if you remove this sequence.',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: () => {
        const id = this.props.sequence.id;
        this.props.apollo
          .mutate({ variables: { id }, mutation: removeSequence })
          .then(result => {
            if (result && result.data && result.data.removeSequence) {
              message.success('Sequence removed');
              if (this.props.reloadSequences) this.props.reloadSequences();
            }
          })
          .catch(err => {
            message.error(getErrors(err) || 'Error removing sequence, try again.');
          });
      }
    });
  };
  render() {
    const { isActive, sequence } = this.props;

    const menu = (
      <div onClick={e => e.stopPropagation()}>
        <Menu>
          <Menu.Item onClick={this.editSequence}>Edit</Menu.Item>
          <Menu.Item onClick={this.viewStats}>View Stats</Menu.Item>
          <Menu.Item onClick={this.cloneSequence}>Clone Sequence</Menu.Item>
          <Menu.Divider />
          {!isActive && (
            <Menu.Item className="danger" onClick={this.removeSequence}>
              Remove
            </Menu.Item>
          )}
          {isActive && !sequence.archived && (
            <Menu.Item className="danger" onClick={this.archive}>
              Archive
            </Menu.Item>
          )}
          {isActive && sequence.archived && (
            <Menu.Item className="danger" onClick={this.unarchive}>
              Unarchive
            </Menu.Item>
          )}
        </Menu>
      </div>
    );

    return (
      <div className="seq-actions">
        <Button size="tiny" onClick={this.editSequence}>
          Edit
        </Button>
        <Button size="tiny" onClick={this.viewStats}>
          View Stats
        </Button>
        <Dropdown
          overlayClassName="seriph-dropdown"
          overlay={menu}
          trigger={['click']}
          placement="bottomRight"
        >
          <div className="sequence-actions" onClick={e => e.stopPropagation()}>
            <IconDots />
          </div>
        </Dropdown>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.apollo };
};

export default withRouter(connect(mapStateToProps, {})(SequenceActions));
