import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getErrors } from '_assets/js/helpers';
import { Modal, Loading, message } from '_seriph';
import {
  saveTouchpoint,
  createTouchpoint,
  removeTouchpoint,
  unarchiveTouchpoint
} from '_graphql/mutations/sequence';
import { getTouchpoint } from '_graphql/queries/sequence';
import TouchpointOverview from './_components/TouchpointOverview/TouchpointOverview';
import EditTouchpoint from './_components/EditTouchpoint/EditTouchpoint';
import NewTouchpoint from './_components/NewTouchpoint/NewTouchpoint';
import './TouchpointModal.scss';

class TouchpointModal extends Component {
  state = {
    visible: true,
    loading: true,
    touchpointId: this.props.touchpointId,
    touchpoint: null,
    touchpointCampaigns: [],
    saving: false,
    removing: false,
    unarchiving: false,
    page: 'preview'
  };
  componentDidMount = () => {
    if (this.state.touchpointId === 'new' || this.state.touchpointId === 'create') {
      const touchpoint = {
        name: undefined,
        type: this.props.touchpointType || undefined,
        subject: undefined,
        content: ''
      };
      this.setState({
        page: this.state.touchpointId === 'new' ? 'edit' : 'create',
        touchpoint,
        touchpointCampaigns: [],
        savedTouchpoint: JSON.stringify(touchpoint),
        loading: false
      });
    } else {
      this.loadTouchpoint();
    }
  };
  cloneTouchpoint = () => {
    message.success('You are now editing a clone of: ' + this.state.touchpoint.name);
    const touchpoint = {
      name: this.state.touchpoint.name + ' (Copy)',
      type: this.state.touchpoint.type,
      subject: this.state.touchpoint.subject,
      content: this.state.touchpoint.content
    };
    this.setState({
      page: 'edit',
      touchpointId: 'new',
      touchpoint,
      touchpointCampaigns: [],
      savedTouchpoint: JSON.stringify(touchpoint),
      loading: false
    });
  };
  loadTouchpoint = () => {
    this.props.client
      .query({ variables: { id: this.state.touchpointId }, query: getTouchpoint })
      .then(result => {
        if (result && result.data && result.data.getTouchpoint) {
          const touchpoint = result.data.getTouchpoint || {};
          const touchpointCampaigns = [...touchpoint.campaigns];
          this.setState({
            touchpoint,
            touchpointCampaigns,
            savedTouchpoint: JSON.stringify(touchpoint),
            loading: false
          });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        message.error(getErrors(err) || 'Could not load touchpoint, try again');
      });
  };
  cleanTouchpoint = touchpoint => {
    const cleanMe = { ...touchpoint };
    delete cleanMe.campaigns;
    delete cleanMe.stats;
    delete cleanMe.archived;
    return cleanMe;
  };
  changePage = page => this.setState({ page });
  createTouchpoint = () => {
    this.setState({ saving: true });
    const savedTouchpoint = this.state.touchpoint;
    this.props.client
      .mutate({
        variables: { touchpoint: this.cleanTouchpoint(savedTouchpoint) },
        mutation: createTouchpoint
      })
      .then(result => {
        if (result && result.data && result.data.createTouchpoint) {
          this.setState(
            {
              touchpoint: savedTouchpoint,
              savedTouchpoint: JSON.stringify(savedTouchpoint),
              saving: false
            },
            this.props.reload
          );
          message.success('Touchpoint created');
          if (this.props.updateTouchpoint) {
            this.props.updateTouchpoint(result.data.createTouchpoint);
          }
          this.hideModal();
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Could not create touchpoint, try again');
      });
  };
  saveTouchpoint = () => {
    this.setState({ saving: true });
    const savedTouchpoint = this.state.touchpoint;
    this.props.client
      .mutate({
        variables: { touchpoint: this.cleanTouchpoint(savedTouchpoint) },
        mutation: saveTouchpoint
      })
      .then(result => {
        if (result && result.data && result.data.saveTouchpoint) {
          this.setState(
            {
              touchpoint: savedTouchpoint,
              savedTouchpoint: JSON.stringify(savedTouchpoint),
              saving: false
            },
            this.props.reload
          );
          message.success('Touchpoint saved');
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Could not save touchpoint, try again');
      });
  };
  removeTouchpoint = () => {
    this.setState({ removing: true });
    this.removeAction();
  };
  removeAction = () => {
    const id = this.state.touchpoint.id;
    this.props.client
      .mutate({ variables: { id }, mutation: removeTouchpoint })
      .then(result => {
        if (result && result.data && result.data.removeTouchpoint) {
          message.success('Touchpoint removed');
          if (this.props.reload) this.props.reload();
          this.hideModal();
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ removing: false });
        message.error(getErrors(err) || 'Error removing touchpoint, try again.');
      });
  };
  unarchive = () => {
    this.setState({ unarchiving: true });
    const id = this.state.touchpoint.id;
    this.props.client
      .mutate({ variables: { id }, mutation: unarchiveTouchpoint })
      .then(result => {
        if (result && result.data && result.data.unarchiveTouchpoint) {
          message.success('Touchpoint unarchived');
          if (this.props.reload) this.props.reload();
          this.loadTouchpoint();
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ unarchiving: false });
        message.error(getErrors(err) || 'Error unarchiving touchpoint, try again.');
      });
  };
  update = (field, value) => {
    const touchpoint = Object.assign({}, this.state.touchpoint);
    touchpoint[field] = value !== undefined ? value : null;
    this.setState({ touchpoint });
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { loading, saving, removing, touchpoint, touchpointCampaigns, savedTouchpoint, page } =
      this.state;

    const edited = JSON.stringify(touchpoint) !== savedTouchpoint;
    const isNew = this.state.touchpointId === 'new' || this.state.touchpointId === 'create';

    return (
      <Modal
        wrapClassName="manage-touchpoint-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={page === 'preview' ? true : false}
        width={page === 'create' ? 456 : this.props.preview === true ? 680 : 800}
      >
        {loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            {page === 'preview' && (
              <TouchpointOverview
                touchpoint={touchpoint}
                touchpointCampaigns={touchpointCampaigns}
                updateTouchpoint={this.props.updateTouchpoint}
                cloneTouchpoint={this.cloneTouchpoint}
                changePage={this.changePage}
                hideModal={this.hideModal}
                saving={saving}
                preview={this.props.preview}
                unarchiving={this.state.unarchiving}
                unarchive={this.unarchive}
              />
            )}

            {page === 'create' && (
              <NewTouchpoint
                touchpoint={touchpoint}
                changePage={this.changePage}
                hideModal={this.hideModal}
                update={this.update}
              />
            )}

            {page === 'edit' && (
              <EditTouchpoint
                touchpoint={touchpoint}
                touchpointCampaigns={touchpointCampaigns}
                createTouchpoint={this.createTouchpoint}
                saveTouchpoint={this.saveTouchpoint}
                removeTouchpoint={this.removeTouchpoint}
                changePage={this.changePage}
                hideModal={this.hideModal}
                saving={saving}
                removing={removing}
                update={this.update}
                edited={edited}
                isNew={isNew}
                touchpointId={this.state.touchpointId}
              />
            )}
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

export default withRouter(TouchpointModal);
