import React, { Component } from 'react';
import './AgentTile.scss';
import { Avatar, Button } from '_seriph';
import { Dropdown, Menu } from 'antd';
import { IconDots } from '@tabler/icons-react';

export default class AgentTile extends Component {
  render() {
    const {
      agent,
      joinedInfo,
      campaigns,
      isInternal,
      email,
      isInvite = false,
      inviteId,
      showMessageModal,
      showInviteAgentModal,
      showRemoveAgentModal,
      revokeTeamRep,
      withdrawTeamRep,
      addWithdrawModal,
      remindTeamRep
    } = this.props;

    let myCampaigns = [];
    if (campaigns && !isInvite) {
      myCampaigns =
        campaigns
          ?.filter(c => c.status === 'active' || c.status === 'inactive')
          .map(c => {
            const member = c.agents.find(a => a.agent_id === agent.id);
            c.memberActive = member && member.active ? true : false;
            return c;
          }) || [];
    }

    let menu;
    if (!isInvite) {
      menu = (
        <Menu>
          <Menu.Item onClick={() => showMessageModal(agent)}>Send a Message</Menu.Item>
          <Menu.Item onClick={() => showInviteAgentModal(agent)}>Invite to Campaign</Menu.Item>
          {myCampaigns.length > 0 ? <Menu.Divider /> : null}
          {myCampaigns.length > 0 ? (
            <Menu.Item className="nocap" onClick={() => showRemoveAgentModal(agent)}>
              Remove from campaign
            </Menu.Item>
          ) : null}
          {isInternal ? (
            <Menu.Item onClick={() => revokeTeamRep(agent)} className="danger">
              Revoke account
            </Menu.Item>
          ) : null}
        </Menu>
      );
    }

    return (
      <div className="agent-tile">
        <div className="agent-tile-top">
          <Avatar type="agent" picture={agent?.picture} size="small" />
          {!isInvite && (
            <Dropdown
              overlayClassName="seriph-dropdown"
              overlay={menu}
              trigger={['click']}
              placement="bottomRight"
            >
              <Button type="transparent" size="tiny">
                <IconDots size={18} stroke={2} />
              </Button>
            </Dropdown>
          )}
        </div>
        <div className="agent-tile-bottom">
          <div className="agent-info">
            <h4>{agent?.display_name || email}</h4>
            <div className="joined-info">{joinedInfo}</div>
          </div>

          <div className="rep-type">
            {isInternal ? (
              <div className="internal-label">Internal</div>
            ) : (
              <div className="xdr-label">XDR</div>
            )}
          </div>
        </div>

        {isInvite && (
          <div className="agent-tile-invite-buttons">
            <Button
              size="tiny"
              onClick={() => {
                if (isInternal) {
                  withdrawTeamRep(email, inviteId);
                } else {
                  addWithdrawModal(agent, campaigns);
                }
              }}
            >
              Withdrawn
            </Button>
            <Button
              size="tiny"
              type="secondary"
              onClick={() => {
                if (isInternal) {
                  remindTeamRep(email);
                } else {
                  addWithdrawModal(agent, campaigns, true);
                }
              }}
            >
              Send reminder
            </Button>
          </div>
        )}
      </div>
    );
  }
}
