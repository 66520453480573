const totalDiff = (curr, prev) => {
  if (curr === prev) return 0;
  const diff = Math.abs(prev-curr);
  const avg = (curr + prev) / 2;
  const percent = diff / avg * 100;
  return curr > prev ? percent : percent * -1;
}


export const matchReps = (repData, previousData) => {
  repData.map(r => {
    const prev = previousData.find(p => p.agent_id === r.agent_id);
    if (prev) {
      r.p_call = prev.call;
      r.p_email = prev.email;
      r.p_linkedin = prev.linkedin;
      r.p_total = prev.total;
      r.p_earned = repData?.sellx_rep ? 0 : prev.earned;
      r.call_percent = totalDiff(r.call, prev.call);
      r.email_percent = totalDiff(r.email, prev.email);
      r.linkedin_percent = totalDiff(r.linkedin, prev.linkedin);
      r.total_percent = totalDiff(r.total, prev.total);
      r.earned_percent = totalDiff(r.earned, prev.earned);
    }
    return r;
  });
  
  return repData;
}



export const generateData = (repData, previousData) => {
  const totals = {};

  // Gather totals
  repData.map(r => {
    let earned = 0;
    const tasks = r?.total || 0;
    if (tasks >= 500) earned = 100;
    if (tasks >= 1000) earned = 250;
    if (tasks >= 1500) earned = 500;
    r.earned = r.agent?.sellx_rep ? 0 : earned;
    for (const key in r) {
      if (Number.isInteger(r[key])) {
        totals[key] = !totals[key] ? r[key] : totals[key] + r[key];
      }
    }
    return r;
  });

  // Gather totals
  previousData?.map(r => {
    let earned = 0;
    const tasks = r?.total || 0;
    if (tasks >= 500) earned = 100;
    if (tasks >= 1000) earned = 250;
    if (tasks >= 1500) earned = 500;
    r.earned = r.agent?.sellx_rep ? 0 : earned;
    for (const key in r) {
      if (Number.isInteger(r[key])) {
        totals['p_'+key] = !totals['p_'+key] ? r[key] : totals['p_'+key] + r[key];
      }
    }
    return r;
  });

  totals.call_percent = totalDiff(totals.call, totals.p_call);
  totals.email_percent = totalDiff(totals.email, totals.p_email);
  totals.linkedin_percent = totalDiff(totals.linkedin, totals.p_linkedin);
  totals.total_percent = totalDiff(totals.total, totals.p_total);
  totals.earned_percent = totalDiff(totals.earned, totals.p_earned);


  const chartData = {
    totals,
    tasks: [
      {
        name: '0 - 300',
        slug: '0_300',
        Current: repData.filter(r => r.total <= 300).length,
        Previous: previousData.filter(r => r.total <= 300).length,
        reps: repData.filter(r => r.total <= 300)
      },
      {
        name: '300 - 600',
        slug: '300_600',
        Current: repData.filter(r => r.total > 300 && r.total <= 600).length,
        Previous: previousData.filter(r => r.total > 300 && r.total <= 600).length,
        reps: repData.filter(r => r.total > 300 && r.total <= 600)
      },
      {
        name: '600 - 900',
        slug: '600_900',
        Current: repData.filter(r => r.total > 600 && r.total <= 900).length,
        Previous: previousData.filter(r => r.total > 600 && r.total <= 900).length,
        reps: repData.filter(r => r.total > 600 && r.total <= 900)
      },
      {
        name: '900 - 1200',
        slug: '900_1200',
        Current: repData.filter(r => r.total > 900 && r.total <= 1200).length,
        Previous: previousData.filter(r => r.total > 900 && r.total <= 1200).length,
        reps: repData.filter(r => r.total > 900 && r.total <= 1200)
      },
      {
        name: '1200 - 1500',
        slug: '1200_1500',
        Current: repData.filter(r => r.total > 1200 && r.total <= 1500).length,
        Previous: previousData.filter(r => r.total > 1200 && r.total <= 1500).length,
        reps: repData.filter(r => r.total > 1200 && r.total <= 1500)
      },
      {
        name: '1500 +',
        slug: '1500',
        Current: repData.filter(r => r.total > 1500).length,
        Previous: previousData.filter(r => r.total > 1500).length,
        reps: repData.filter(r => r.total > 1500)
      },
    ]
  };

  return chartData;
};
