import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Empty, DatePicker, Select } from 'antd';
import { adminLeaderboard, getManagers } from '../../../_graphql/queries';
import { formatMoney } from '_assets/js/helpers';
import { loginUser } from 'Store/user/user_actions';
import { getColumns } from './Columns';
import moment from 'moment';
import './Leaderboard.scss';

const { MonthPicker, WeekPicker } = DatePicker;

class Leaderboard extends Component {
  state = {
    data: [],
    reps: [],
    selectedRowKeys: [],
    pagination: { pageSize: 20 },
    loading: false,
    selected: null,
    date: moment().format('YYYYMMDD'),
    type: 'daily',
    managerId: undefined
  };
  componentDidMount() {
    this.fetch({ results: 20, page: 1 });
    this.fetchManagers();
  }
  fetch = (params = {}) => {
    this.setState({ loading: true });
    this.props.client
      .query({
        variables: {
          name: this.state.searchText,
          managerId: this.state.managerId,
          date: this.state.date,
          type: this.state.type,
          ...params
        },
        query: adminLeaderboard
      })
      .then(result => {
        if (result && result.data) {
          const leaderboard = result.data.adminLeaderboard.data || [];
          const pagination = { ...this.state.pagination };
          pagination.total = result.data.adminLeaderboard.pageTotal;
          pagination.totalAll = result.data.adminLeaderboard.total;
          this.setState({
            loading: false,
            data: leaderboard.map((l, i) => {
              l.rank = i + 1;
              return l;
            }),
            earnedTotal: result.data.adminLeaderboard.earnedTotal,
            feesTotal: result.data.adminLeaderboard.feesTotal,
            meetingsTotal: result.data.adminLeaderboard.meetingsTotal,
            pagination
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(() => this.setState({ loading: false }));
  };

  fetchManagers = async () => {
    const managers = (
      await this.props.client.query({
        query: getManagers
      })
    ).data.getManagers;
    if (managers) {
      this.setState({ managers });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      name: this.state.searchText,
      managerId: this.state.managerId,
      ...filters
    });
  };
  mapAgents = agents => {
    return agents
      .filter(a => a.active === true)
      .map(a => {
        const findRep = this.state.reps.find(r => r.id === a.agent_id);
        if (findRep) return { ...a, ...findRep };
        return a;
      });
  };
  onChange = currentDate => {
    this.setState({ date: currentDate.format('YYYYMMDD') }, this.fetch);
  };
  onManagerChange = managerId => {
    this.setState({ managerId }, this.fetch);
  };
  onYearChange = year => {
    const currentDate = moment().day('Monday').year(year).week('24');
    this.setState({ date: currentDate.format('YYYYMMDD') }, this.fetch);
  };
  render() {
    const { pagination, data, loading } = this.state;
    const total = pagination.totalAll || 0;
    const totalFiltered = pagination.total || 0;
    const columns = getColumns(this.mapAgents);

    const year = moment(this.state.date, 'YYYYMMDD').format('YYYY');

    return (
      <div id="admin-leaderboard">
        <div className="admin-header">
          Leaderboard
          <div>
            Showing {totalFiltered} of {total}
          </div>
          <div className="filters">
            <Select
              showSearch
              value={this.state.managerId}
              placeholder="By Manager"
              defaultActiveFirstOption={false}
              filterOption={false}
              onChange={this.onManagerChange}
              notFoundContent={null}
            >
              <Select.Option key="none" value={undefined}>
                None
              </Select.Option>
              {this.state.managers?.map(m => (
                <Select.Option key={m.id} value={m.id}>
                  {m?.admin?.display_name}
                </Select.Option>
              ))}
            </Select>

            <Select value={this.state.type} onChange={type => this.setState({ type }, this.fetch)}>
              <Select.Option value="daily">Daily</Select.Option>
              <Select.Option value="weekly">Weekly</Select.Option>
              <Select.Option value="monthly">Monthly</Select.Option>
              <Select.Option value="yearly">Yearly</Select.Option>
            </Select>

            {this.state.type === 'daily' && <DatePicker onChange={this.onChange} />}
            {this.state.type === 'weekly' && <WeekPicker onChange={this.onChange} />}
            {this.state.type === 'monthly' && <MonthPicker onChange={this.onChange} />}
            {this.state.type === 'yearly' && (
              <Select value={year} onChange={this.onYearChange}>
                <Select.Option value="2021">2021</Select.Option>
                <Select.Option value="2022">2022</Select.Option>
              </Select>
            )}
          </div>
        </div>
        <div className="admin-table">
          <div className="totals">
            <span>
              Total Earned: <b>{formatMoney(this.state.earnedTotal)}</b>
            </span>
            <span>
              Total Cut: <b>{formatMoney(this.state.feesTotal)}</b>
            </span>
            <span>
              Total Meetings Booked: <b>{this.state.meetingsTotal}</b>
            </span>
          </div>
          <div className="table-content">
            <Table
              className="sellx-table bordered"
              bordered
              rowSelection={null}
              columns={columns}
              rowKey={record => record.id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              scroll={{ x: 'max-content' }}
              locale={{ emptyText: <Empty description="No agents" /> }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, { loginUser })(Leaderboard));
