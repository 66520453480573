import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Button } from 'antd';
import { Tutorials } from './LearnData';
import './Learn.scss';

const Course = props => {
  const { image, title, length, link } = props;
  return (
    <div className="course" onClick={link}>
      {image ? <img src={image} alt="" /> : null}
      <div className="course-info">
        <h4>{title}</h4>
        <span>{length}</span>
      </div>
    </div>
  );
};

class Learn extends Component {
  componentDidMount = () => window.scrollTo({ top: 0 });
  goTo = link => {
    window.scrollTo({ top: 0 });
    this.props.history.push(`/learn/${link}`);
  };
  render() {
    const id = this.props.learnId || 'profile';
    const tutorial = Tutorials[id] || Tutorials['profile'];

    return (
      <div id="agent-learn">
        <div className="slide">
          <iframe
            title="learn-iframe"
            width="747"
            height="408"
            src={`https://www.youtube.com/embed/${tutorial.videoId}?autoplay=1&rel=0&showinfo=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          <div className="slide-info">
            <h1>{tutorial.title}</h1>
            <h5>
              {tutorial.length}&nbsp;•&nbsp;{tutorial.date}
            </h5>
            <p>{tutorial.description}</p>
            <div className="slide-status">
              {tutorial.next ? (
                <Link to={`/learn/${tutorial.next}`}>
                  <Button className="btn btn-primary">{tutorial.nextText}</Button>
                </Link>
              ) : null}
              <div className="slide-progress">
                <div className="pbar">
                  <div style={{ width: tutorial.percent }} />
                </div>
                Tutorial {tutorial.index} of 6
              </div>
            </div>
          </div>
        </div>
        <div className="learn-box">
          <h2>Other Tutorials</h2>
          <div className="courses">
            {Object.keys(Tutorials).map(c =>
              id !== c ? <Course key={c} link={() => this.goTo(c)} {...Tutorials[c]} /> : null
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Learn);

// { false && Object.keys(LearnData).map(c => <CourseList category={c} courses={LearnData[c]} />)}
