import React, { Component } from 'react';
import { Modal, Button, Input, message } from '_seriph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconChevronLeft } from '@tabler/icons-react';
import { faCheckCircle, faTimesCircle, faCopy, faSync } from '@fortawesome/free-solid-svg-icons';
import { addCustomDomain, completeDomainSetup } from '_graphql/mutations/integrations';
import { getErrors, getEmailDomain } from '_assets/js/helpers';
import axios from 'axios';
import Loading from '_shared/Loading/Loading';
import Guide from '_styleguide/Guide/Guide';
import './CustomDomainModal.scss';

const tempDomain = website => {
  const emailDomain = getEmailDomain(website);
  if (emailDomain) return 'get.' + emailDomain;
  return undefined;
};

export default class CustomDomainModal extends Component {
  state = {
    visible: true,
    loading: this.props.integration && this.props.integration.domain ? true : false,
    saving: false,
    maskClosable: true,
    domain: tempDomain(
      this.props.company
        ? this.props.company.website.replace(/https?:\/\//g, '')?.toLowerCase()
        : null
    ),
    domainData: null
  };
  inputs = {};
  componentDidMount = () => {
    if (this.props.integration && this.props.integration.domain) {
      this.getCustomDomain(this.props.integration.domain);
    }
  };
  getCustomDomain = domain => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/mg/domain?domain=${domain}`, {
        headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') }
      })
      .then(response => {
        if (response && response.data) {
          this.setState({ domainData: response.data, loading: false });
        }
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };
  refreshDomain = domain => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/mg/refresh?domain=${domain}`, {
        headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') }
      })
      .then(response => {
        if (response && response.data && response.data.domain) {
          const data = { ...response.data.domain, ...response.data };
          this.setState({ domainData: data, loading: false });
        }
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };
  addCustomDomain = () => {
    this.setState({ saving: true });
    this.props.client
      .mutate({ variables: { domain: this.state.domain }, mutation: addCustomDomain })
      .then(result => {
        if (result && result.data && result.data.addCustomDomain) {
          setTimeout(() => this.props.loadIntegrations(), 100);
          this.setState({ saving: false, loading: true });
          this.getCustomDomain(this.state.domain);
        }
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Error setting up custom domain, try again');
      });
  };
  completeDomainSetup = () => {
    this.setState({ saving: true });

    this.props.client
      .mutate({
        variables: { domain: this.props.integration?.domain || this.state.domainData?.name },
        mutation: completeDomainSetup
      })
      .then(result => {
        if (result && result.data && result.data.completeDomainSetup) {
          setTimeout(() => this.props.loadIntegrations(this.hideModal), 100);
        }
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Error completing custom domain, try again');
      });
  };
  copyToClipboard = i => {
    this.inputs[i].select();
    document.execCommand('copy');
    message.success('Copied to clipboard');
  };
  removeDomain = () => {
    this.setState({ removing: true });
    this.hideModal();
    this.props.removeCustomDomain(this.props?.integration?.domain);
  };
  hideModal = () => {
    this.setState({ visible: false });
  };
  refresh = () => {
    this.setState({ loading: true }, () => this.refreshDomain(this.state.domainData.name));
  };
  render() {
    const { domain, domainData, loading, removing, saving } = this.state;

    let domainSettings = [],
      isValid = false;
    if (domainData) {
      domainSettings = [...domainData.sending_dns_records, ...domainData.receiving_dns_records];
      isValid = true;
      domainSettings.forEach(s => {
        if (s.valid !== 'valid') isValid = false;
      });
    }
    isValid = isValid && domainData && domainData.state === 'active';
    return (
      <Modal
        wrapClassName="custom-domain-modal sx-theme"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={this.state.maskClosable}
        width={!loading && domainData ? 900 : 640}
      >
        <div className="close-icon" onClick={this.hideModal}>
          <IconChevronLeft />
        </div>
        {loading ? <Loading /> : null}
        {!loading && !domainData ? (
          <div className="stage-one">
            <h3>Add custom email domain</h3>
            <div className="stage-body sx-form">
              <div className="field">
                <label>Domain</label>
                <Input
                  disabled={loading}
                  addonBefore="salesrep@"
                  placeholder="get.ourproduct.com"
                  onChange={e => this.setState({ domain: e.target.value })}
                  value={domain}
                />
              </div>
              <Guide
                type="budget-bad"
                dismissable={false}
                id="domain-warning"
                header="Warning"
                message="DO NOT use your primary domain (the domain you use for your companies emails).  This could override your email and affect delivery."
              />
            </div>
            <div className="stage-actions">
              <Button type="default" onClick={this.hideModal}>
                Back to List
              </Button>
              <Button
                disabled={loading}
                loading={saving}
                className="btnx btnx-primary"
                onClick={this.addCustomDomain}
              >
                Create Domain
              </Button>
            </div>
          </div>
        ) : null}
        {!loading && domainData ? (
          <div className="stage-one">
            <h3>Verify your domain (@{domainData.name})</h3>
            <div className="stage-body sx-form">
              <p>
                Please add the following entries to your DNS records for {domainData.name}. This
                will allow you to send and receive emails through our platform using your custom
                domain @{domainData.name} instead of our provided email domain @sellx.org
              </p>
              <table className="domain-table">
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Type</th>
                    <th>Hostname</th>
                    <th>Priority</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {domainSettings.map((s, i) => (
                    <tr key={'ds-' + i}>
                      <td>
                        <FontAwesomeIcon
                          icon={s.valid === 'valid' ? faCheckCircle : faTimesCircle}
                        />
                      </td>
                      <td>{s.record_type}</td>
                      <td>{s.name || domainData.name}</td>
                      <td>{s.priority || ''}</td>
                      <td>
                        <Input size="small" ref={nput => (this.inputs[i] = nput)} value={s.value} />
                        <Button
                          className="btnx btnx-default"
                          onClick={() => this.copyToClipboard(i)}
                        >
                          <FontAwesomeIcon icon={faCopy} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="stage-actions">
              <Button className="btnx btnx-neutral refresh" onClick={this.refresh}>
                <FontAwesomeIcon icon={faSync} className="btnx-left" />
                Refresh
              </Button>
              <Button type="secondary" onClick={this.hideModal}>
                Back to List
              </Button>
              <Button loading={removing} className="btnx btnx-danger" onClick={this.removeDomain}>
                Remove Domain
              </Button>
              <Button
                className="btnx btnx-primary"
                loading={saving}
                disabled={!isValid}
                onClick={this.completeDomainSetup}
              >
                Complete Setup
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
    );
  }
}
