import React from 'react';
import moment from 'moment';
import { formatMoney } from '_assets/js/helpers';
import { Tag } from '_seriph';

export const getColumns = showBillDetailsModal => {
  return [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => {
        return moment(createdAt * 1).format('L');
      }
    },
    {
      title: 'Type',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => {
        if (status === 'paid') {
          return <Tag type="primary">Paid</Tag>;
        } else if (status === 'processing') {
          return <Tag type="default">Processing</Tag>;
        } else if (status === 'failed') {
          return <Tag type="danger">Failed</Tag>;
        } else if (status === 'refunded') {
          return <Tag type="info">Refunded</Tag>;
        }
        return <Tag>{status}</Tag>;
      }
    },
    {
      title: 'Price',
      dataIndex: 'total',
      key: 'total',
      render: total => {
        return formatMoney(total);
      }
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      align: 'right',
      render: (total, bill) => {
        return (
          <span onClick={() => showBillDetailsModal(bill.id)} className="billing-view">
            View
          </span>
        );
      }
    }
  ];
};
