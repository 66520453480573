import React, { Component } from 'react';
import { TextArea, Modal, Button, message, Radio, Select, InputDate, InputTime } from '_seriph';
import { submitQualified } from '_graphql/mutations/lead';
import { getErrors } from '_assets/js/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faExclamation, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { validateQualify } from '../../_helpers/validateQualify';
import './QualifyModal.scss';
import interestConstants from '_constants/interest';
import { Timezones } from '_constants/timezones';
import moment from 'moment';
import 'moment-timezone';
import classNames from 'classnames';

export default class QualifyModal extends Component {
  state = {
    visible: true,
    result: null,
    maskClosable: true,
    interestLevel: null,
    information: '',
    missing: [],
    wizardStep: 0,
    titleText: '',
    reasons: [],
    submitable: false,
    selectedReason: '',
    inputPlaceholder: '',
    timezone: 'US/Eastern',
    date: undefined,
    time: undefined,
    fullDate: null
  };
  submitLead = () => {
    if (!this.isTimeValid()) {
      message.error('Meeting time is required to submit an interested lead for this campaign');
      return;
    }
    const { interestLevel, selectedReason } = this.state;
    const { versionTwo } = this.props;
    const missing =
      validateQualify(this.props.lead, this.props.questions, interestLevel, versionTwo) || [];
    if (interestLevel === 'interested' && !this.props.meetingValid && !this.state.fullDate)
      missing.push('Scheduling a meeting is required before submit');
    if (missing.length >= 1) {
      this.setState({ wizardStep: -1, titleText: '', missing });
      return;
    }
    this.submitQualified(interestLevel, selectedReason);
  };
  submitQualified = (interestLevel, reasonDetail) => {
    this.setState({ maskClosable: false });
    const needTime = this.needTime();
    this.props.client
      .mutate({
        variables: {
          lead_id: this.props.lead.id,
          interested: interestLevel === 'interested',
          reason: interestLevel,
          reason_detail: reasonDetail,
          information: this.state.information,
          meeting_time: needTime ? this.state.fullDate : null
        },
        mutation: submitQualified
      })
      .then(result => {
        if (result && result.data && result.data.submitQualified) {
          message.success('Lead submitted for review.');
          this.setState({ result: result.data.submitQualified }, this.handleEnd);
        } else {
          throw new Error('Error saving lead information');
        }
      })
      .catch(this.catchError);
  };
  handleEnd = () => {
    if (this.props.reload) this.props.reload(this.props.lead.id);
    setTimeout(this.hideModal, 0);
  };
  catchError = err => {
    this.setState({ result: 'error', maskClosable: true });
    message.error(getErrors(err) || 'Could not submit lead, try again');
  };
  hideModal = () => this.setState({ visible: false });
  goBack = () => this.setState({ wizardStep: 0, titleText: '', information: '' });
  updateInformation = e => {
    this.setState({
      information: e.target.value,
      submitable: e.target.value?.length > 0
    });
  };

  updateReason = e => {
    this.setState({
      selectedReason: e.target.value,
      information: ''
    });
  };
  updateDate = () => {
    const getDate = this.state.date ? this.state.date.format('YYYY-MM-DD') : '';
    const getTime = this.state.time ? this.state.time.format('HH:mm:ss') : '';
    const getDateTime = getDate + ' ' + getTime;
    const fullDate = moment(getDateTime).tz(this.state.timezone).toDate();
    this.setState({ fullDate: fullDate ? fullDate : null });
  };
  needTime = () => {
    const { campaign, lead } = this.props;
    const isInterested = this.state.interestLevel === 'interested';
    const meetingRequired = campaign?.meeting_required ? true : false;
    const timeScheduled = lead?.calendly?.start_time ? true : false;
    return !lead.meetingScheduled && isInterested && meetingRequired && !timeScheduled ? true : false;
  };
  isTimeValid = () => {
    const needTime = this.needTime();
    const { date, time, timezone, fullDate } = this.state;
    if (needTime && date && time && timezone && fullDate) {
      return true;
    } else if (!needTime) {
      return true;
    }
    return false;
  };
  render() {
    const { information } = this.state;

    const needTime = this.needTime();
    const isOptional =
      this.state.interestLevel === 'interested' || this.state.interestLevel === 'no-response';

    const dateTimePicker = (
      <div className="dtp">
        <div className="datetime">
          <InputDate
            onChange={date => this.setState({ date }, this.updateDate)}
            value={this.state.date}
          />
          <InputTime
            onChange={time => this.setState({ time }, this.updateDate)}
            value={this.state.time}
            use12Hours
            format="hh:mm A"
            minuteStep={15}
          />
        </div>
        <Select
          placeholder="Select a Timezone"
          value={this.state.timezone || undefined}
          onChange={timezone => this.setState({ timezone })}
        >
          {Timezones.map((t, i) => (
            <Select.Option key={`tz-${i}`} value={t.value}>
              {t.label}
            </Select.Option>
          ))}
        </Select>
      </div>
    );

    return (
      <Modal
        wrapClassName="qualify-confirmation"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={this.state.maskClosable}
        width={500}
      >
        <div className="header-row">
          <div className="back-cont">
            {this.state.wizardStep > 0 && (
              <div className="back-icon" onClick={this.goBack}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </div>
            )}
          </div>
          <div className="title-cont">{this.state.titleText}</div>
          <div className="close-cont">
            {this.state.wizardStep == 0 && (
              <div className="close-icon" onClick={this.hideModal}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            )}
          </div>
        </div>
        {this.state.wizardStep == 0 && (
          <div className="stage-one">
            <div className="stage-icon">
              <FontAwesomeIcon icon={faExclamation} />
            </div>
            <h3>How would you like to qualify this lead?</h3>
            <div className="submit-type">
              <div className="interest-options">
                {interestConstants.map(option => (
                  <div
                    key={option.slug}
                    onClick={() =>
                      this.setState({
                        interestLevel: option.slug,
                        wizardStep: option.nextWizardStep ?? 3,
                        titleText: option.titleText ?? '',
                        reasons: option.reasons ?? [],
                        inputPlaceholder: option.inputPlaceholder ?? ''
                      })
                    }
                  >
                    {option.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {this.state.wizardStep == 1 && (
          <div className="reason">
            <Radio.Group onChange={this.updateReason} value={this.state.selectedReason}>
              {this.state.reasons.map(reason => (
                <div key={reason.slug}>
                  <Radio value={reason.slug}>
                    {reason.name}
                    {this.state.selectedReason === reason.slug && (
                      <div className="inline-input">
                        <p>{reason.prompt}</p>
                        <TextArea
                          className={classNames({
                            filled: true
                          })}
                          rows={6}
                          placeholder={this.state.inputPlaceholder}
                          autoSize={false}
                          value={information}
                          onChange={this.updateInformation}
                        />
                      </div>
                    )}
                  </Radio>
                </div>
              ))}
            </Radio.Group>
          </div>
        )}
        {this.state.wizardStep == 2 && (
          <div className="interested">
            {needTime && (
              <div>
                <p>When was the meeting scheduled?</p>
                {dateTimePicker}
              </div>
            )}
            <p>Any additional information? (optional)</p>
            <TextArea
              className={classNames({
                filled: this.state.information?.length
              })}
              rows={8}
              placeholder={this.state.inputPlaceholder}
              autoSize={false}
              value={information}
              onChange={this.updateInformation}
            />
          </div>
        )}
        {this.state.wizardStep > 0 && (
          <div className="bottom-row">
            <Button type="secondary" className="back-button" onClick={this.goBack} icon="left">
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>Back</span>
            </Button>
            <Button
              disabled={!this.state.submitable && !isOptional}
              className="submit-button"
              type="primary"
              onClick={this.submitLead}
            >
              Submit
            </Button>
          </div>
        )}
        {this.state.wizardStep == -1 && (
          <div className="stage-one">
            <div className="stage-icon missing">
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <h3>Incorrect Information</h3>
            <p>
              It looks like there are some items that are either missing or incomplete.{' '}
              <b>Please update the following fields and resubmit.</b>
            </p>
            <div className="missing-list">
              {this.state.missing.map(m => (
                <div key={m}>{m}</div>
              ))}
            </div>
            <div className="stage-actions">
              <Button type="default" onClick={this.hideModal}>
                Close
              </Button>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
