import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormatPhone } from '_assets/js/helpers';
import { getEmployee } from 'Queries/Company/employee';
import { updateEmployeeAccount } from '_graphql/mutations/employee';
import { loginUser } from 'Store/user/user_actions';
import ChangeEmail from './_modals/ChangeEmail/ChangeEmail';
import ChangePassword from './_modals/ChangePassword/ChangePassword';
import { resendVerify } from 'Mutations/User/user';
import AvatarEdit from './_modals/AvatarEdit/AvatarEdit';
import avatar from '_assets/img/avatar.png';
import { Card, Input, Button, Avatar, Header, Loading, message } from '_seriph';
import './Account.scss';

class Account extends Component {
  state = {
    loading: true,
    user: null,
    saved: null,
    changePassword: false,
    changeEmail: false
  };
  componentDidMount = () => {
    this.loadAccount();
  };
  loadAccount = () => {
    this.props.client
      .query({ variables: { id: this.props.myself.id }, query: getEmployee })
      .then(result => {
        if (result && result.data && result.data.employee) {
          this.setState({
            user: result.data.employee,
            saved: JSON.stringify(result.data.employee),
            loading: false
          });
        } else {
          this.setState({ loading: false });
          message.error('Account not found, try again');
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error('Account not found, try again');
      });
  };
  saveAccount = () => {
    this.props.client
      .mutate({ variables: { ...this.state.user }, mutation: updateEmployeeAccount })
      .then(result => {
        if (result && result.data && result.data.updateEmployeeAccount) {
          this.props.loginUser(result.data.updateEmployeeAccount);
          this.setState({ saved: JSON.stringify(this.state.user) });
          message.success('Your information has been updated');
        } else {
          message.error('Could not save, try again');
        }
      });
  };
  resendCode = email => {
    this.props.client
      .mutate({ variables: { email: email }, mutation: resendVerify })
      .then(result => {
        if (result && result.data && result.data.resendVerify) {
          message.success('A new verification code has been sent to ' + email);
          this.setState({ resent: true });
        } else {
          message.error("Couldn't send code, try again");
        }
      });
  };
  update = (field, value) => {
    const user = Object.assign({}, this.state.user);
    user[field] = value !== undefined ? value : null;
    this.setState({ user });
  };
  removeChangeEmail = () => this.setState({ changeEmail: false });
  removeChangePassword = () => this.setState({ changePassword: false });
  removeChangePicture = () => this.setState({ changePicture: false });
  render() {
    const { user, loading } = this.state;

    const isEdited = JSON.stringify(user) !== this.state.saved;

    return loading ? (
      <Loading />
    ) : (
      <Card id="company-settings-account" className="settings-card">
        <Header font="sans" size="2" className="settings-header">
          Personal Info
        </Header>

        {isEdited ? (
          <Button onClick={this.saveAccount} type="primary" className="save-account">
            Save Information
          </Button>
        ) : null}

        <div className="settings-items">
          <div className="settings-item">
            <div className="type">Avatar</div>
            <div className="value">
              <Avatar size="mini" type="company" picture={user.picture || avatar} />
            </div>
            <div className="edit">
              <span
                className="link link-change"
                onClick={() => this.setState({ changePicture: true })}
              >
                Update Picture
              </span>
            </div>
          </div>
          <div className="settings-item">
            <div className="type">First Name</div>
            <div className="value">
              <Input
                size="medium"
                placeholder="First Name"
                onChange={e => this.update('first_name', e.target.value)}
                value={user.first_name}
              />
            </div>
            <div className="edit" />
          </div>
          <div className="settings-item">
            <div className="type">Last Name</div>
            <div className="value">
              <Input
                size="medium"
                placeholder="Last Name"
                onChange={e => this.update('last_name', e.target.value)}
                value={user.last_name}
              />
            </div>
            <div className="edit" />
          </div>
          <div className="settings-item">
            <div className="type">Job Title</div>
            <div className="value">
              <Input
                size="medium"
                placeholder="Title"
                onChange={e => this.update('title', e.target.value)}
                value={user.title}
              />
            </div>
            <div className="edit" />
          </div>
          <div className="settings-item">
            <div className="type">Phone Number</div>
            <div className="value">
              <Input
                size="medium"
                placeholder="Phone Number"
                onChange={e => this.update('phone_number', FormatPhone(e.target.value))}
                value={user.phone_number}
              />
            </div>
            <div className="edit" />
          </div>
          <div className="settings-item">
            <div className="type">Email Address</div>
            <div className="value">{user.user.email} </div>
            <div className="edit">
              <span
                className="link link-change"
                onClick={() => this.setState({ changeEmail: true })}
              >
                Change Email
              </span>
            </div>
          </div>
          <div className="settings-item">
            <div className="type">Password</div>
            <div className="value">************</div>
            <div className="edit">
              <span className="link" onClick={() => this.setState({ changePassword: true })}>
                Update Password
              </span>
            </div>
          </div>
        </div>

        {this.state.changeEmail ? (
          <ChangeEmail
            myself={this.props.user}
            client={this.props.client}
            removeModal={this.removeChangeEmail}
            reload={this.loadAccount}
          />
        ) : null}

        {this.state.changePassword ? (
          <ChangePassword client={this.props.client} removeModal={this.removeChangePassword} />
        ) : null}

        {this.state.changePicture ? (
          <AvatarEdit removeModal={this.removeChangePicture} reload={this.loadAccount} />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default connect(mapStateToProps, { loginUser })(Account);
