import React from 'react';
import { Tooltip } from 'antd';
import { formatMoney, numberWithCommas } from '_assets/js/helpers';
import moment from 'moment';
import { durationText } from '_constants/campaigns';
import { Avatar, Button } from '_seriph';

export const getColumns = (masq, launchCampaign, revertToDraft, onViewMappingClick) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (c, r) => (
        <div className="ctbl-box">
          <Avatar picture={r.company.logo} size="small" />
          <div className="ctbl-name">
            <h4>{c}</h4>
            <h5>
              {r.company.name}
              <span>
                &nbsp;&middot;&nbsp;
                <span className={'sx-status ' + r.status} />
                Review since {moment(r.status_updated * 1).format('M/D')}
              </span>
            </h5>
          </div>
        </div>
      )
    },
    {
      title: 'Type',
      dataIndex: 'objective',
      key: 'objective',
      width: 120,
      render: c => {
        const obj = c !== 'qualify' && c !== 'leadgen' ? 'draft' : c;
        return (
          <div className={'tbl-type ' + obj}>
            {obj === 'qualify' && 'Qualify'}
            {obj === 'leadgen' && 'Generation'}
            {obj === 'draft' && 'Draft'}
          </div>
        );
      }
    },
    {
      title: 'Billing Type',
      dataIndex: 'threshold',
      key: 'threshold',
      width: 120,
      render: c => {
        return c ? 'Threshold' : 'Escrow';
      }
    },
    {
      title: 'Duration',
      dataIndex: 'threshold_settings',
      key: 'threshold_settings',
      width: 120,
      render: (c, r) => {
        return r.threshold ? durationText[c.duration].split(' ')[0] : 'Lifetime';
      }
    },
    {
      title: 'Sales Reps',
      dataIndex: 'target_agent_count',
      key: 'target_agent_count',
      width: 160,
      render: c => (
        <span>
          {c} Rep{c === 1 ? '' : 's'}
        </span>
      )
    },
    {
      title: 'Budget',
      dataIndex: 'budget_total',
      key: 'budget_total',
      width: 160,
      render: (c, r) => {
        const budget = r.objective === 'qualify' ? r.budget_ppql : r.budget_ppl;
        const actual = r.budget_total;
        return (
          <Tooltip
            overlayClassName="sellx-tooltip sellx-tooltip-small"
            title={`Budget before fees is ${formatMoney(c)}, and you are paying ${formatMoney(
              budget
            )} per lead`}
          >
            <div className="sxd-link">{formatMoney(actual)}</div>
          </Tooltip>
        );
      }
    },
    {
      title: 'Pay Per Lead',
      dataIndex: 'pay_per_lead',
      key: 'pay_per_lead',
      width: 160,
      render: (c, r) => {
        return <div className="sxd-link">{formatMoney(r.price_per_lead)}</div>;
      }
    },
    {
      title: '# of Touchpoints',
      dataIndex: 'sequence_count',
      key: 'sequence_count',
      width: 160,
      render: c => {
        return <div className="sxd-link">{numberWithCommas(c)}</div>;
      }
    },
    {
      title: 'Lead Count',
      dataIndex: 'lead_est_count',
      key: 'lead_est_count',
      width: 160,
      render: (c, r) => {
        let est_count = c;
        if (r.threshold && r.objective === 'leadgen') est_count = r.threshold_settings.lead_count;
        return <div className="sxd-link">{est_count}</div>;
      }
    },
    {
      title: 'View Mapping',
      dataIndex: 'view_mapping',
      key: 'view_mapping',
      width: 100,
      render: (c, r) => {
        if (r.objective === 'qualify') {
          return (
            <Button
              type="default"
              onClick={() => {
                onViewMappingClick(r);
              }}
            >
              View
            </Button>
          );
        } else {
          return null;
        }
      }
    },
    {
      title: 'Actions',
      dataIndex: 'launch',
      fixed: 'right',
      render: (column, data) => {
        return (
          <div className="review-actions">
            <Button className="btnx btnx-neutral" onClick={() => revertToDraft(data.id)}>
              Revert to Draft
            </Button>
            <Button
              className="btnx btnx-default"
              onClick={() => masq(data.company.owner.id, data.id)}
            >
              Edit
            </Button>
            <Button className="btnx btnx-primary" onClick={() => launchCampaign(data)}>
              Launch
            </Button>
          </div>
        );
      }
    }
  ];
};
