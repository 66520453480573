import React from 'react';
import { FormatWait } from '_assets/js/helpers';
import { getTitle } from '../../_helpers/stepMapping';
import moment from 'moment';
import { Button, Tooltip } from '_seriph';
import {
  IconMail,
  IconBrandLinkedin,
  IconClock,
  IconPhone,
  IconCheck,
  IconMessage,
  IconClipboardList
} from '@tabler/icons-react';
import './Step.scss';

const goToLinkedIn = linkedin => {
  Object.assign(document.createElement('a'), { target: '_blank', href: linkedin }).click();
};

const typeToIcon = {
  email: <IconMail />,
  call: <IconPhone />,
  text: <IconMessage />,
  linkedin: <IconBrandLinkedin />,
  instructions: <IconClipboardList />,
  wait: <IconClock />
};

export const getTimeDiff = (currentTime, completedTime) => {
  const end = moment(completedTime);
  const duration = moment.duration(end.diff(currentTime));
  const seconds = duration.asSeconds();
  return seconds > 0 ? seconds : null;
};

export const Step = props => {
  const { type, info } = props.step;
  const { step, lead, goTo } = props;

  const currentTime = parseInt(step.now);
  const startTime = parseInt(step.start_tstamp);
  const completedTime = parseInt(step.completed_tstamp);

  const isCompleted = step.completed && currentTime > completedTime;
  const isPaused = props.isPaused ? true : false;
  const canEdit = props.canEdit && !isPaused;

  let status = 'todo';
  if (isCompleted) {
    status = 'completed';
  } else if (!isCompleted && currentTime > startTime) {
    status = 'current';
  }

  const timeDiff =
    type === 'wait' && (status === 'current' || status === 'completed')
      ? getTimeDiff(currentTime, completedTime)
      : null;

  return (
    <div className={'sequence-step ' + status}>
      <div className={`step-icon ${status === 'completed' ? 'completed' : type}`}>
        {status === 'completed' ? <IconCheck /> : typeToIcon[type]}
      </div>
      <h4>{getTitle(props.step)}</h4>

      {type !== 'wait' && info ? (
        <div className="completed">Completed {moment(info.completed * 1).fromNow()}</div>
      ) : null}
      {type === 'wait' && props.waitCompleted ? (
        <div className="completed">Completed {props.waitCompleted.fromNow()}</div>
      ) : null}
      {/* HANDLE ALL ACTIONS BELOW */}
      {type === 'instructions' ? (
        <div className="actions">
          {status === 'current' && (
            <Button type="primary" disabled={!canEdit} onClick={() => props.showModal()}>
              Review
            </Button>
          )}
          {status === 'completed' && (
            <Button type="default" onClick={() => props.showModal(true)}>
              Review
            </Button>
          )}
        </div>
      ) : null}
      {type === 'call' ? (
        <div className="actions">
          {status === 'current' && canEdit && (
            <Button type="plain" onClick={props.showSkip}>
              Skip
            </Button>
          )}
          {status === 'todo' && (
            <Button type="secondary" disabled={true}>
              Get Started
            </Button>
          )}
          {status === 'current' && (
            <Button type="primary" disabled={!canEdit} onClick={props.showCallPopout}>
              Get Started
            </Button>
          )}
          {status === 'completed' && !step.skipped && step.completed_id && (
            <Button type="default" onClick={() => goTo('calls', `/${step.completed_id}`)}>
              View call
            </Button>
          )}
          {status === 'completed' && step.skipped && (
            <Tooltip
              title={
                <div className="tt-step-skip">
                  <span>
                    {step.skipped_type}
                    {step.skipped_reason ? ':' : ''}
                  </span>
                  {step.skipped_reason || ''}
                </div>
              }
            >
              <Button type="secondary" disabled>
                Skipped
              </Button>
            </Tooltip>
          )}
        </div>
      ) : null}
      {type === 'text' ? (
        <div className="actions">
          {status === 'current' && canEdit && (
            <Button type="plain" onClick={props.showSkip}>
              Skip
            </Button>
          )}
          {status === 'todo' && (
            <Button type="secondary" disabled={true}>
              Get Started
            </Button>
          )}
          {status === 'current' && (
            <Button type="primary" disabled={!canEdit} onClick={() => props.showModal()}>
              Get Started
            </Button>
          )}
          {status === 'completed' && !step.skipped && (
            <Button type="default" onClick={() => goTo('texts', ``)}>
              See Text
            </Button>
          )}
          {status === 'completed' && step.skipped && (
            <Tooltip
              title={
                <div className="tt-step-skip">
                  <span>
                    {step.skipped_type}
                    {step.skipped_reason ? ':' : ''}
                  </span>
                  {step.skipped_reason || ''}
                </div>
              }
            >
              <Button type="secondary" disabled>
                Skipped
              </Button>
            </Tooltip>
          )}
        </div>
      ) : null}
      {type === 'email' ? (
        <div className="actions">
          {status === 'current' && canEdit && (
            <Button type="plain" onClick={props.showSkip}>
              Skip
            </Button>
          )}
          {status === 'todo' && (
            <Button type="secondary" disabled={true}>
              Get Started
            </Button>
          )}
          {status === 'current' && (
            <Button type="primary" disabled={!canEdit} onClick={props.showEmailPopout}>
              Get Started
            </Button>
          )}
          {status === 'completed' && !step.skipped && step.completed_id && (
            <Button type="default" onClick={() => goTo('email', `/${step.completed_id}`)}>
              See Email
            </Button>
          )}
          {status === 'completed' && step.skipped && (
            <Tooltip
              title={
                <div className="tt-step-skip">
                  <span>
                    {step.skipped_type}
                    {step.skipped_reason ? ':' : ''}
                  </span>
                  {step.skipped_reason || ''}
                </div>
              }
            >
              <Button type="secondary" disabled>
                Skipped
              </Button>
            </Tooltip>
          )}
        </div>
      ) : null}
      {type === 'linkedin' ? (
        <div className="actions">
          {status === 'current' && canEdit && (
            <Button type="plain" onClick={props.showSkip}>
              Skip
            </Button>
          )}
          {status === 'todo' && (
            <Button type="secondary" disabled={true}>
              Go to LinkedIn
            </Button>
          )}
          {status === 'current' && (
            <Button type="primary" disabled={!canEdit} onClick={() => props.showModal()}>
              Go to LinkedIn
            </Button>
          )}
          {status === 'completed' && !step.skipped && lead && lead.linkedin && (
            <Button type="default" onClick={() => goToLinkedIn(lead.linkedin)}>
              Open LinkedIn
            </Button>
          )}
          {status === 'completed' && step.skipped && (
            <Tooltip
              title={
                <div className="tt-step-skip">
                  <span>
                    {step.skipped_type}
                    {step.skipped_reason ? ':' : ''}
                  </span>
                  {step.skipped_reason || ''}
                </div>
              }
            >
              <Button type="secondary" disabled>
                Skipped
              </Button>
            </Tooltip>
          )}
        </div>
      ) : null}
      {type === 'wait' ? (
        <div className="actions">
          {(status === 'current' || status === 'completed') && timeDiff ? (
            <Button type="secondary" disabled={true}>
              <IconClock />
              {FormatWait(timeDiff)} remaining
            </Button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
