import React from 'react';
import { IconCheck, IconAlertTriangle, IconListCheck, IconHistory } from '@tabler/icons-react';
import moment from 'moment';

const disputeMap = {
  incorrect: 'Incorrect info',
  enough: 'Not enough info',
  scope: 'Out of scope',
  other: 'Other'
};

export default function Status(props) {
  const { activity, user, myself } = props;
  const status = activity.status;
  let icon = <IconCheck />;
  if (status.type === 'disputed') icon = <IconAlertTriangle />;
  if (status.type === 'review') icon = <IconListCheck />;
  if (status.type === 'reverted') icon = <IconHistory />;
  const reviewType = ['review', 'auto_review'].includes(status.type);
  const reviewText = 'auto_review' === status.type ? 'Automatically' : '';
  return (
    <div className={'lead-activity status-' + status.type}>
      <div className="act-icon">{icon}</div>
      <div className="descript">
        {status.type === 'completed' && !status.automated && (
          <React.Fragment>
            <b>{user?.display_name || 'Company'}</b> accepted the lead and is now completed {activity.status?.from_admin && myself.masq ? ' (Admin)' : null}
          </React.Fragment>
        )}
        {status.type === 'completed' && status.automated && (
          <React.Fragment>
            The lead was <b>auto-completed</b>
          </React.Fragment>
        )}
        {status.type === 'assigned' && (
          <React.Fragment>
            <b>{user.display_name}</b> pulled and started working
          </React.Fragment>
        )}
        {status.type === 'disputed' && (
          <React.Fragment>
            <b>{user.display_name}</b> disputed the lead
          </React.Fragment>
        )}
        {status.type === 'disputed' && (
          <div>
            {' '}
            due to <b>{disputeMap[status.dispute_reason]}</b>: {status.information}
          </div>
        )}
        {status.type === 'reverted' && (
          <React.Fragment>
            <b>{user.display_name}</b> moved the lead back to <b>in progress</b>
          </React.Fragment>
        )}
        {reviewType && status.dispute_reason === 'interested' && (
          <React.Fragment>
            <b>{user.display_name}</b> {reviewText} submitted the lead as <b>qualified</b> for
            review
          </React.Fragment>
        )}
        {reviewType && status.dispute_reason === 'no-response' && (
          <React.Fragment>
            <b>{user.display_name}</b> {reviewText} submitted the lead as <b>no response</b> for
            review because: {status.information}
          </React.Fragment>
        )}
        {reviewType && status.dispute_reason === 'not-interested' && (
          <React.Fragment>
            <b>{user.display_name}</b> {reviewText} submitted the lead as <b>not interested</b> for
            review because: {status.information}
          </React.Fragment>
        )}
        {reviewType && status.dispute_reason === 'invalid' && (
          <React.Fragment>
            <b>{user.display_name}</b> {reviewText} submitted the lead as <b>invalid/duplicate</b>{' '}
            for review because: {status.information}
          </React.Fragment>
        )}
        {reviewType && status.dispute_reason === null && (
          <React.Fragment>
            <b>{user.display_name}</b> {reviewText} submitted the lead for review
          </React.Fragment>
        )}
        <div className="act-tstamp">
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;{moment(activity.createdAt * 1).format('MMM D @ h:mm a')}
        </div>
      </div>
    </div>
  );
}
