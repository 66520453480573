import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CallPopout from './_components/CallPopout/CallPopout';
import EmailPopout from './_components/EmailPopout/EmailPopout';
import './ReachOut.scss';

class ReachOut extends Component {
  state = {
    width: 999999,
    height: 999999
  };
  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  };
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateWindowDimensions);
  };
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  render() {
    const { width } = this.state;
    const emailMaxShown = Math.floor(width / 524);
    const { email, call } = this.props.touchpoints;
    return (
      <React.Fragment>
        {email.length > 0 ? (
          <div className="ro-emails">
            {email.map((eml, i) => (
              <EmailPopout
                key={'ro-email-' + eml.lead.id}
                shouldDisplay={email.length - i <= emailMaxShown ? true : false}
                client={this.props.client}
                sequence={eml}
                hidePopout={() => this.props.hidePopout(i, 'email')}
              />
            ))}
          </div>
        ) : null}

        {call.length > 0 ? (
          <div className="ro-calls">
            {call.map((call, i) => (
              <CallPopout
                key={'ro-call-' + call.lead.id}
                client={this.props.client}
                sequence={call}
                hideCallPopout={() => this.props.hidePopout(i, 'call')}
              />
            ))}
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(ReachOut));
