import moment from 'moment';

const stepTitles = {
  instructions: 'Review General Task',
  email: 'Send Email',
  text: 'Send Text',
  call: 'Make Phone Call',
  linkedin: 'Send LinkedIn Message'
};

export const getTitle = step => {
  if (step.type === 'wait') {
    return step.duration_type === 'All Day'
      ? 'Wait All Day'
      : 'Wait ' + step.duration + ' ' + step.duration_type;
  }
  return stepTitles[step.type];
};

/* GET CURRENT DAY FOR A SEQUENCE */
export const getDay = (
  sequence,
  sequenceIndex,
  lead,
  lastCompletedIndex,
  lastWorkedIndex,
  lastCompletedTstamp
) => {
  let currentDate = moment(Date.now());
  let lastCompleted = lastCompletedTstamp ? moment(lastCompletedTstamp * 1) : null;
  let actualDay = null;

  /* IF IT IS THE BEGINNING */
  if (lead.last_sequence === null || lastCompletedIndex === null) {
    actualDay = currentDate.add(sequenceIndex, 'day');
    return actualDay;
  }

  /* IF LAST COMPLETED DAY */
  if (sequence.status === 'complete' || sequence.status === 'started') {
    actualDay = moment(sequence.last_tstamp * 1);
  } else if (sequence.status === 'today') {
    actualDay = currentDate.add(0, 'day');
    if (actualDay.isSame(lastCompleted, 'd')) {
      actualDay = currentDate.add(1, 'day');
    }
  } else {
    actualDay = currentDate.add(sequenceIndex - lastWorkedIndex, 'day');
  }

  return actualDay;
};

/* GET THE POSITION BASED OFF OF CURRENT SEQUENCE AND STEPS COMPLETED */
export const getPosition = (l, sequence, step, lastSteps) => {
  // HANDLE IF SEQUENCES HAVEN'T STARTED YET
  if (l.last_timestamp === null && sequence === 0 && step === 0) {
    return 'current';
  } else if (l.last_timestamp === null) {
    return 'todo';
  }

  // GET STATUS BASED ON POSITION
  let position = 'todo';
  if (sequence < l.last_sequence || (sequence <= l.last_sequence && step <= l.last_step)) {
    position = 'completed';
  } else if (sequence === l.last_sequence && step === l.last_step + 1) {
    position = 'current';
  } else if (sequence === l.last_sequence + 1 && lastSteps === l.last_step + 1 && step === 0) {
    position = 'current';
  }

  return position;
};

/* HANDLE WAITS AND UPDATE STATUS ACCORDINGLY IF THE WAIT TIME IS COMPLETED */
export const getStatus = (stepInfo, previousInfo) => {
  let position = stepInfo.position;
  if (stepInfo.type === 'wait' && stepInfo.position === 'current' && !stepInfo.time_diff) {
    position = 'completed';
  }
  if (
    previousInfo &&
    previousInfo.type === 'wait' &&
    previousInfo.position === 'current' &&
    !previousInfo.time_diff
  ) {
    position = 'current';
  }
  return position;
};

export const getWaitCompleted = stepInfo => {
  if (
    stepInfo.type === 'wait' &&
    stepInfo.duration_type !== 'All Day' &&
    !stepInfo.time_diff &&
    stepInfo.position !== 'todo' &&
    stepInfo.last_info &&
    stepInfo.last_info.completed
  ) {
    return moment(stepInfo.last_info.completed * 1).add(
      stepInfo.duration,
      stepInfo.duration_type.toLowerCase()
    );
  }
  return null;
};

/* GET THE TIME DIFF IN SECTONDS FOR A WAIT */
export const getTimeDiff = (lastInfo, step) => {
  if (step.type !== 'wait' || !lastInfo) return null;
  // HANDLE NEW LEAD
  const currentTime = moment(lastInfo.now * 1);
  const end = moment(lastInfo.completed * 1).add(step.duration, step.duration_type.toLowerCase());
  const duration = moment.duration(end.diff(currentTime));
  const seconds = duration.asSeconds();
  return seconds > 0 ? seconds : null;
};
