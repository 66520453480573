import gql from 'graphql-tag';

export const getSequences = gql`
  query {
    getSequences {
      id
      name
      campaigns {
        name
        status
        id
      }
      days {
        touchpoints {
          type
          duration
          duration_type
          auto
          touchpoint_id
        }
      }
      day_count
      touchpoint_count
      global
      archived
      company_id
    }
  }
`;

export const getSequence = gql`
  query getSequence($id: ID) {
    getSequence(id: $id) {
      id
      name
      campaigns {
        name
        id
        status
      }
      days {
        touchpoints {
          type
          duration
          duration_type
          auto
          touchpoint_id
          hasStats
        }
      }
    }
  }
`;

export const previewSequence = gql`
  query getSequence($id: ID) {
    getSequence(id: $id) {
      id
      name
      days {
        touchpoints {
          type
          duration
          duration_type
          auto
          touchpoint_id
        }
      }
      day_count
      touchpoint_count
    }
  }
`;

export const getSequenceWithStats = gql`
  query getSequence($id: ID) {
    getSequence(id: $id) {
      id
      name
      campaigns {
        name
        id
      }
      days {
        touchpoints {
          type
          duration
          duration_type
          auto
          touchpoint_id
          touchpoint {
            name
            type
            subject
            content
          }
        }
      }
      day_count
      touchpoint_count
      stats {
        email {
          completed
          skipped
          delivered
          bounced
          opened
          replied
          qualified
          clicked
          unsubscribed
        }
        call {
          completed
          skipped
          invalid
          no_answer
          answered
          incoming
          incoming_answered
          qualified
        }
        text {
          completed
          text_sent
          text_delivered
          text_reply
          text_opt_out
        }
        linkedin {
          completed
          skipped
          qualified
        }
        instructions {
          completed
          skipped
          qualified
        }
      }
    }
  }
`;

export const getTouchpointStats = gql`
  query getTouchpointStats($sequence_id: ID, $campaign_id: ID) {
    getTouchpointStats(sequence_id: $sequence_id, campaign_id: $campaign_id) {
      touchpoint_id
      day
      touchpoint {
        id
        name
        type
        subject
        content
      }
      email {
        completed
        skipped
        delivered
        bounced
        opened
        replied
        qualified
        clicked
        unsubscribed
      }
      call {
        completed
        skipped
        invalid
        no_answer
        answered
        incoming
        incoming_answered
        qualified
      }
      text {
        completed
        text_sent
        text_delivered
        text_reply
        text_opt_out
      }
      linkedin {
        completed
        skipped
        qualified
      }
      instructions {
        completed
        skipped
        qualified
      }
    }
  }
`;

export const getTouchpoints = gql`
  query {
    getTouchpoints {
      id
      name
      type
      subject
      content
      archived
    }
  }
`;

export const getTouchpoint = gql`
  query getTouchpoint($id: ID) {
    getTouchpoint(id: $id) {
      id
      name
      type
      subject
      content
      archived
      stats {
        conversion_rate
        has_stats
      }
      campaigns {
        id
        name
      }
      disable_signature
    }
  }
`;

export const getCampaignSequences = gql`
  query getCampaignSequences($campaign_id: ID) {
    getCampaignSequences(campaign_id: $campaign_id) {
      id
      name
      days {
        touchpoints {
          type
          duration
          duration_type
          auto
          touchpoint_id
        }
      }
      day_count
      touchpoint_count
    }
  }
`;
