import React from 'react';
import moment from 'moment';
import { IconPlayerPlay, IconPlayerPause, IconDots, IconX } from '@tabler/icons-react';
import { Avatar, Button } from '_seriph';
import { Dropdown, Menu } from 'antd';
import { prettyPhone } from '_assets/js/helpers';
import { statusMap } from '_constants/twilio';

export const getColumns = (
  playRecording,
  playingRecording,
  dropdownClicked,
  dropdownVisible,
  onVisibleChange
) => {
  return [
    {
      title: 'Call information',
      dataIndex: 'lead',
      fixed: 'left',
      render: (column, data) => {
        return (
          <div className="lead-cont">
            <div className="lead-section">
              <div className="lead-name">
                {data?.round_robin?.name || 'Call list'}
              </div>
              <div className="campaign-name">{prettyPhone(data?.rr_number)}</div>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Date/time',
      dataIndex: 'createdAt',
      render: (column, data) => {
        return <div className="company-name">{moment(parseInt(data?.createdAt)).format('MM/D/YY • h:mm a')}</div>;
      }
    },
    {
      title: 'From (Lead)',
      dataIndex: 'from_number',
      render: (column, data) => {
        return !data?.lead ? (
          <div className="company-name">{prettyPhone(data?.from_number)}</div>
        ) : (
          <div className="agent-picture">
            <div className="agent-info">
              <h4>{data?.lead?.first_name} {data?.lead?.last_name}</h4>
              <p>{prettyPhone(data?.from_number)}</p>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Forwarded to (Rep)',
      dataIndex: 'forward_number',
      render: (column, data) => {
        return !data?.rep ? (
          <div className="company-name">{prettyPhone(data?.to_number)}</div>
        ) : (
          <div className="agent-picture">
            <Avatar size="mini" type="agent" picture={data?.rep?.picture} />
            <div className="agent-info">
              <h4>{data?.rep?.display_name}</h4>
              <p>{prettyPhone(data?.to_number)}</p>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (column, data) => {
        return <div className="company-name">{statusMap[data?.status] || 'Unknown'}</div>;
      }
    },
    {
      title: 'Duration',
      dataIndex: 'to_number',
      render: (column, data) => {
        return <div className="company-name">{data.duration} second(s)</div>;
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (column, data) => {
        const dropdownMenu = (
          <Menu mode="vertical">
            {data.recording_url && (
              <Menu.Item onClick={() => {}}>
                <a rel="noreferrer" href={data.recording_url} target="_blank" download>
                  Download audio
                </a>
              </Menu.Item>
            )}
          </Menu>
        );

        return (
          <div className="actions-cont">
            {data.recording_url ? (
              <Button
                type="primary"
                size="tiny"
                circle={true}
                onClick={() => {
                  playRecording(data.id, data.recording_url);
                }}
              >
                {data.id === playingRecording ? <IconPlayerPause /> : <IconPlayerPlay />}
              </Button>
            ) : (
              <Button type="disabled" size="tiny" circle={true}>
                <IconX />
              </Button>
            )}

            <Dropdown
              overlay={dropdownMenu}
              overlayClassName="seriph-dropdown"
              trigger={['click']}
              placement="bottomRight"
              arrow
              visible={dropdownVisible === data.id}
              onVisibleChange={() => onVisibleChange(data.id)}
            >
              <div className="tbl-btn" onClick={() => dropdownClicked(data.id)}>
                <IconDots size={18} stroke={2} />
              </div>
            </Dropdown>
          </div>
        );
      }
    }
  ];
};
