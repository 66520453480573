import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Input } from '_seriph';
import { phoneValid, FormatPhone } from '_assets/js/helpers';
import './PersonalInfo.scss';

const blankValidation = () => ({ company_role: '', phone: '' });

class PersonalInfo extends Component {
  isValid = () => {
    const validate = blankValidation();
    const form = this.props.form;
    if (!form.company_role) validate.company_role = 'Company role required';
    if (!form.phone || !phoneValid(form.phone)) validate.phone = 'Please enter a valid Phone Number';
    for (let v in validate) {
      if (validate[v]) {
        return { isValid: false, validate };
      }
    }
    return { isValid: true, validate };
  };
  handleChange = val => {
    this.props.updateForm('phone', FormatPhone(val));
  };
  nonExist = val => {
    return val !== undefined && val !== null;
  }
  render() {

    const { form, updateForm } = this.props;
    const { validate, isValid } = this.isValid();

    return (
      <div className="join-form-box" id="onboard-personal">
        <div className="join-title">
          <h2>Tell us about yourself</h2>
        </div>

        <div className="join-fields">
          
          <Input
            label="Your role"
            placeholder="e.g. VP of Marketing..."
            onChange={e => updateForm('company_role', e.target.value)}
            value={form.company_role}
            error={this.nonExist(form.company_role) && validate.company_role}
          />

          <Input
            label="Phone number"
            placeholder="--"
            onChange={e => this.handleChange(e.target.value)}
            value={form.phone}
            error={this.nonExist(form.phone) && validate.phone}
            hint={this.nonExist(form.phone) && validate.phone ? validate.phone : ''}
          />

        </div>

        <div className="join-actions">
          <Button disabled={!isValid} type="primary" size="large" onClick={() => this.props.saveOnboard(this.props.next, true)}>
            Finish
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(PersonalInfo);
