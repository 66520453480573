import React from 'react';
import { Pagination as AntdPagination } from 'antd';
import './Pagination.scss';

const Pagination = incomingProps => {
  const props = { ...incomingProps };
  props.className = 'seriph-pagination ' + (props.className || '');

  let pageInfo = {};
  pageInfo.page = props.current || 1;
  pageInfo.pages = Math.ceil(props.total / props.pageSize);
  pageInfo.total = props.total || 0;
  const startNumber = (pageInfo.page - 1) * props.pageSize + 1;
  const highNumber = startNumber + props.pageSize - 1;
  pageInfo.amount = startNumber + '-' + (highNumber > pageInfo.total ? pageInfo.total : highNumber);

  return (
    <div className="pagination-wrapper">
      <div className="page-info">
        Page {pageInfo.page} of {pageInfo.pages} / Showing {pageInfo.amount} of {pageInfo.total}
      </div>
      <AntdPagination {...props} />
    </div>
  );
};

export default Pagination;
