import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { removeCustomDomain } from '_graphql/mutations/integrations';
import { getErrors } from '_assets/js/helpers';
import CustomDomainListModal from './_modals/CustomDomainListModal/CustomDomainListModal';
import { Card, Button, message } from '_seriph';
import { IconCheck, IconMailbox, IconPlus } from '@tabler/icons-react';
import './CustomDomain.scss';

class CustomDomain extends Component {
  state = {
    setting: null
  };
  removeCustomDomain = (domain, callback) => {
    this.setState({ setting: 'domain' });
    this.props.client
      .mutate({
        variables: { domain },
        mutation: removeCustomDomain
      })
      .then(result => {
        if (result?.data?.removeCustomDomain) {
          setTimeout(() => {
            this.props.loadIntegrations(this.resetSetting);
            if (callback) callback();
          }, 100);
          message.success('Custom domain successfully removed');
        }
      })
      .catch(err => {
        this.setState({ setting: null });
        message.error(getErrors(err) || 'Error removing custom domain, try again');
      });
  };
  resetSetting = () => this.setState({ setting: null });
  showCustomDomainModal = () => this.setState({ customDomainModal: true });
  removeCustomDomainModal = () => this.setState({ customDomainModal: false });
  render() {
    const { setting } = this.state;
    const { integrations, loading } = this.props;

    const hasEmailIntegration = integrations.custom_domains.length > 0;
    const hasActiveEmailIntegration = integrations.custom_domains?.find(i => i.active);

    return (
      <Card id="custom-domain">
        <div className="int">
          <div className="int-header">
            <div className="int-logo">
              <IconMailbox />
            </div>
            <h3 className="int-name">Custom Email</h3>

            {hasActiveEmailIntegration && (
              <div className="int-check">
                <IconCheck />
              </div>
            )}
          </div>
          <p className="int-desc">
            Use your own email domain, such as rep@sales.company.com instead of rep@sellx.org.
          </p>
          <div className="int-actions">
            {(hasEmailIntegration || true) && (
              <Button
                disabled={loading}
                type="secondary"
                icon="left"
                loading={setting === 'domain'}
                onClick={this.showCustomDomainModal}
              >
                <IconPlus />
                {hasActiveEmailIntegration ? 'Manage Custom Domains' : 'Add Custom Domain'}
              </Button>
            )}
          </div>
        </div>

        {this.state.customDomainModal ? (
          <CustomDomainListModal
            client={this.props.client}
            company={this.props.company}
            loadIntegrations={this.props.loadIntegrations}
            removeCustomDomain={this.removeCustomDomain}
            customDomains={integrations.custom_domains}
            removeModal={this.removeCustomDomainModal}
          />
        ) : null}
      </Card>
    );
  }
}

export default withRouter(CustomDomain);
