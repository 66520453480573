import React, { Component } from 'react';
import { Table } from '_seriph';
import { getColumns } from './Columns';
import './TaskTable.scss';

class TaskTable extends Component {
  render() {
    const { data } = this.props;

    const weekdayData = [...data];
    weekdayData.shift();
    weekdayData.pop();

    return (
      <div id="report-task-table">
        <Table
          lined
          rounded
          locale={{ emptyText: 'No tasks found' }}
          dataSource={weekdayData.map((d, i) => {
            d.key = 'rtt-'+i;
            return d;
          })}
          scroll={{ x: 'max-content' }}
          pagination={{ pageSize: 10 }}
          columns={getColumns()}
        />
      </div>
    );
  }
}

export default TaskTable;
