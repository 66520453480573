import React, { Component } from 'react';
import { Modal, Input, message } from 'antd';
import { Button } from '_seriph';
import avatar from '_assets/img/avatar.png';
import { createMessage } from 'Mutations/Message/message';
import './MessageAgentModal.scss';

const { TextArea } = Input;

class MessageAgentModal extends Component {
  state = {
    visible: true,
    message: '',
    subject: '',
    loading: false
  };
  hideModal = () => this.setState({ visible: false });
  createMessage = () => {
    this.setState({ loading: true });
    this.props.client
      .mutate({
        variables: {
          body: this.state.message,
          agent_id: this.props.agent.id,
          employee_id: this.props.user.id
        },
        mutation: createMessage
      })
      .then(result => {
        if (result && result.data && result.data.createMessage) {
          message.success('Message sent to ' + this.props.agent.display_name);
          this.hideModal();
        } else {
          throw new Error('Could not send message, try again');
        }
      })
      .catch(() => {
        message.error('Could not send message, try again');
        this.setState({ loading: false });
      });
  };
  getSubject = () => {
    if (this.props.campaign) return `Application: ${this.props.campaign.name}`;
    return `Inquiry: ${this.props.user.display_name} from ${this.props.user.company_name}`;
  };
  render() {
    const { agent, campaign } = this.props;

    return (
      <Modal
        wrapClassName="message-agent-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        style={{ top: 60 }}
        width={600}
        closable={false}
        maskClosable={true}
      >
        <div className="ma-agent">
          <img src={agent.picture || avatar} alt="" />
          <div className="ma-details">
            <h3>
              {agent.display_name}{' '}
              {campaign ? <div className="app-applied">{campaign.name}</div> : null}
            </h3>
            <p>{agent.about || <i>This rep does not have a bio</i>}</p>
          </div>
        </div>

        <div className="ma-message">
          <TextArea
            value={this.state.message}
            onChange={e => this.setState({ message: e.target.value })}
            size="large"
            rows={6}
            className="forms forms-textarea"
            placeholder="Write a message..."
          />
        </div>

        <div className="ma-actions">
          <Button className="btn btn-default" onClick={this.hideModal}>
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            onClick={this.createMessage}
            loading={this.state.loading}
            disabled={this.state.message.length <= 0}
          >
            Send Message
          </Button>
        </div>
      </Modal>
    );
  }
}

export default MessageAgentModal;
