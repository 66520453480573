import React, { Component } from 'react';
import { Table as AntdTable } from 'antd';
import './Table.scss';

class Table extends Component {
  render() {
    const props = { ...this.props };
    props.className = 'seriph-table ' + (props.className || '');

    // Handle Striped
    if (props.striped) {
      props.className += ' table-striped';
      delete props.striped;
    }

    // Handle Lined
    if (props.lined) {
      props.className += ' table-lined';
      delete props.lined;
    }

    // Rounded
    if (props.rounded) {
      props.className += ' table-rounded';
      delete props.rounded;
    }

    // Handle Lined
    let customInfo = null;
    if (props.customInfo) {
      customInfo = props.customInfo;
      delete props.customInfo;
    }

    let pageInfo = null;
    if (props.pagination && !customInfo) {
      pageInfo = {};
      pageInfo.page = props.pagination.current || 1;
      pageInfo.pages = Math.ceil(props.pagination.total / props.pagination.pageSize);
      pageInfo.total = props.pagination.total || 0;
      const startNumber = (pageInfo.page - 1) * props.pagination.pageSize + 1;
      const highNumber = startNumber + props.pagination.pageSize - 1;
      pageInfo.amount =
        startNumber + '-' + (highNumber > pageInfo.total ? pageInfo.total : highNumber);
    }

    return (
      <div className="seriph-table-wrapper">
        <AntdTable {...props} />
        {pageInfo && !props.loading ? (
          <div className="page-info">
            Page {pageInfo.page} of {pageInfo.pages} / Showing {pageInfo.amount} of {pageInfo.total}
          </div>
        ) : null}
        {customInfo && !props.loading ? <div className="custom-info">{customInfo}</div> : null}
      </div>
    );
  }
}

export default Table;
