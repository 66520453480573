import React, { Component } from 'react';
import Card from '_styleguide/Card/Card';
import { File } from './File/File';
import Guide from '_styleguide/Guide/Guide';
import { flatMoney } from '_assets/js/helpers';
import './Instructions.scss';

const companySizeMap = {
  micro: { title: 'Micro', description: '1 - 9', value: 'micro' },
  small: { title: 'Small', description: '10 - 49', value: 'small' },
  mid: { title: 'Mid', description: '40 - 249', value: 'mid' },
  large: { title: 'Large', description: '250+', value: 'large' }
};

export default class Instructions extends Component {
  getRequiredValues = field => {
    const { campaign } = this.props;
    if (campaign && campaign.required && campaign.required.length > 0) {
      const fieldData = campaign.required.find(r => r.field === field);
      if (field === 'location') {
        return fieldData ? fieldData.locations || [] : [];
      } else {
        return fieldData ? fieldData.values || [] : [];
      }
    } else {
      return [];
    }
  };
  UNSAFE_componentWillMount = () => window.scrollTo({ top: 0 });
  render() {
    const { campaign, isAgent } = this.props;

    return (
      <div id="live-training">
        {isAgent && (
          <Guide
            id="lead-instructions-agent"
            message="Learn detailed information about the company and what they are trying to accomplish with this campaign. Message the company if you have additional questions on how to be successful. The company’s profile page is also a great place to learn more."
          />
        )}
        <div className="training-bod">
          <div className="editor-col">
            <Card>
              <h2>Instructions</h2>
              {campaign.guidelines ? (
                <div
                  className="html-instructions"
                  dangerouslySetInnerHTML={{ __html: campaign.guidelines }}
                />
              ) : (
                <div className="html-instructions">
                  There are currently no instructions for this campaign, reach out to the owner for
                  more information
                </div>
              )}
            </Card>
          </div>

          <div className="prof-col">
            {campaign.objective === 'leadgen' ? (
              <div className="cust-prof">
                <h3>Customer Profile</h3>
                {campaign.customer_type ? (
                  <div>
                    <h4>Lead Type</h4>
                    <p>{campaign.customer_type}</p>
                  </div>
                ) : null}
                {campaign.customer_company_size?.length > 0 ? (
                  <div>
                    <h4>Company Size</h4>
                    <p>
                      {campaign.customer_company_size
                        .map(s => `${companySizeMap[s].title} (${companySizeMap[s].description})`)
                        .join(', ')}
                    </p>
                  </div>
                ) : null}
                {campaign.customer_industry?.length > 0 ? (
                  <div>
                    <h4>Industry</h4>
                    <p>{campaign.customer_industry.join(', ')}</p>
                  </div>
                ) : null}
                {campaign.customer_target ? (
                  <div>
                    <h4>Target Customer</h4>
                    <p>{campaign.customer_target}</p>
                  </div>
                ) : null}
                {campaign.customer_decision_maker ? (
                  <div>
                    <h4>Decision Maker</h4>
                    <p>{campaign.customer_decision_maker}</p>
                  </div>
                ) : null}
                {campaign.customer_locations?.length > 0 ? (
                  <div>
                    <h4>Location(s)</h4>
                    <p>{campaign.customer_locations.map(l => l.name).join(', ')}</p>
                  </div>
                ) : null}
                {campaign.customer_spend_start && campaign.customer_spend_end ? (
                  <div>
                    <h4>Revenue</h4>
                    <p>
                      From {flatMoney(campaign.customer_spend_start)} to{' '}
                      {flatMoney(campaign.customer_spend_end)}
                    </p>
                  </div>
                ) : null}
              </div>
            ) : null}

            {campaign.files.length > 0 ? (
              <div className="files">
                <h3>Files & Documents</h3>
                {campaign.files.length > 0 ? (
                  <div className="step-files">
                    {campaign.files.map(f => (
                      <File key={'file-' + (f.id || f._id)} data={f} />
                    ))}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
