import React, { Component } from 'react';
import { Modal, Button, Header, Checkbox, Radio, message } from '_seriph';
import SalesforceLogo from '_assets/brands/salesforce.svg';
import { getErrors } from '_assets/js/helpers';
import { updateSalesforce } from '_graphql/mutations/integrations';
import './SalesforceSettingsModal.scss';

export default class SalesforceSettingsModal extends Component {
  state = {
    visible: true,
    form: { ...this.props.integrations.salesforce }
  };
  saveSettings = () => {
    this.setState({ saving: true });
    const form = this.cleanForm();
    this.props.client
      .mutate({
        variables: { salesforce: { ...form } },
        mutation: updateSalesforce
      })
      .then(result => {
        if (result && result.data && result.data.updateSalesforce) {
          if (this.props.reload) this.props.reload();
          message.success('Salesforce settings updated');
          this.setState({ saving: false });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Error updating salesforce settings, try again.');
      });
  };
  cleanForm = () => {
    const form = { ...this.state.form };
    delete form.access_token;
    delete form.enabled;
    return form;
  };
  updateForm = (field, val) => {
    const form = { ...this.state.form };
    form[field] = val;
    this.setState({ form });
  };
  updateSync = field => {
    const form = { ...this.state.form };
    if (form.qual_sync.includes(field)) {
      form.qual_sync = form.qual_sync.filter(f => f !== field);
    } else {
      form.qual_sync.push(field);
    }
    this.setState({ form });
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { form, saving } = this.state;

    return (
      <Modal
        wrapClassName="salesforce-settings-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={true}
        width={680}
      >
        <Modal.Close onClick={this.hideModal} />

        <Header size="2" type="serif" className="settings-header">
          <img className="chili-logo" src={SalesforceLogo} title="Salesforce" alt="Salesforce" />
          Salesforce Integration Settings
        </Header>

        <div className="options-list">
          <div className="descript">
            <Header size="4" type="sans" className="descript-title">
              Lead Generation
            </Header>
            <div className="settings">
              <Header size="5" type="sans">
                Duplication
              </Header>
              <Checkbox
                checked={form.gen_duplicates}
                onChange={e => this.updateForm('gen_duplicates', e.target.checked)}
              >
                Check for duplicates
              </Checkbox>
              <Header size="5" type="sans">
                Creation
              </Header>
              <Radio.Group
                onChange={e => this.updateForm('gen_type', e.target.value)}
                value={form.gen_type}
              >
                <Radio value="lead">Push as Lead</Radio>
                <Radio value="contact">Push as Contact</Radio>
                <Radio value="none">Don&apos;t push to Salesforce</Radio>
              </Radio.Group>
            </div>
          </div>
          <div className="descript">
            <Header size="4" type="sans" className="descript-title">
              Lead Qualification
            </Header>
            <div className="settings">
              <Header size="5" type="sans">
                Sync
              </Header>
              <Checkbox
                checked={form.qual_sync.includes('notes')}
                onChange={e => this.updateSync('notes', e.target.checked)}
              >
                Notes
              </Checkbox>
              <Checkbox
                checked={form.qual_sync.includes('calls')}
                onChange={e => this.updateSync('calls', e.target.checked)}
              >
                Calls
              </Checkbox>
              <Checkbox
                checked={form.qual_sync.includes('emails')}
                onChange={e => this.updateSync('emails', e.target.checked)}
              >
                Emails
              </Checkbox>
              <Checkbox
                checked={form.qual_sync.includes('meetings')}
                onChange={e => this.updateSync('meetings', e.target.checked)}
              >
                Meetings
              </Checkbox>
              <Header size="5" type="sans">
                Convert Qualified Lead
              </Header>
              <Checkbox
                checked={form.qual_account}
                onChange={e => this.updateForm('qual_account', e.target.checked)}
              >
                Create Account
              </Checkbox>
              <Checkbox
                checked={form.qual_contact}
                onChange={e => this.updateForm('qual_contact', e.target.checked)}
              >
                Create Contact (if applicable)
              </Checkbox>
              <Checkbox
                checked={form.qual_opportunity}
                onChange={e => this.updateForm('qual_opportunity', e.target.checked)}
              >
                Create Opportunity
              </Checkbox>
            </div>
          </div>
        </div>

        <Modal.Actions align="right">
          <Button onClick={this.hideModal}>Close</Button>
          <Button type="primary" loading={saving} onClick={this.saveSettings}>
            Save Settings
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
