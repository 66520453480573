import React, { Component } from 'react';
import { Loading, Modal, Button, message, Select } from '_seriph';
import { getErrors } from '_assets/js/helpers';
import { getTemplate } from '_graphql/queries/campaign';
import { convertTagsOnly } from '_helpers/smartTags';
import { IconMessage } from '@tabler/icons-react';
import { sendTextMessage } from '_graphql/mutations/lead';
import './Text.scss';

export default class Text extends Component {
  state = {
    visible: true,
    template: null,
    loading: true,
    sending: false,
    sendTo: undefined
  };
  componentDidMount = () => {
    this.loadTemplate();
  };
  loadTemplate = () => {
    this.props.client
      .query({ variables: { template_id: this.props.templateId }, query: getTemplate })
      .then(result => {
        if (result && result.data && result.data.getTemplate) {
          const template = this.convert(result.data.getTemplate);
          this.setState({ template, loading: false });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not load text, try again');
        this.setState({ loading: false });
      });
  };

  sendTextMessage = () => {
    this.setState({ sending: true });
    this.props.client
      .mutate({
        variables: {
          lead_id: this.props.lead?.id,
          sequence_id: this.props.lead?.sequence_id || null,
          touchpoint_id: this.props.step?.task_id,
          day: this.props.step?.day?.toString() || null,
          to_number: this.state.sendTo?.split('---')?.[0],
          content: this.state.template.content
        },
        mutation: sendTextMessage
      })
      .then(result => {
        if (result && result.data && result.data.sendTextMessage) {
          this.props.reload();
          this.hideModal();
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not send text message, try again');
        this.setState({ sending: false });
      });
  };
  convert = template => {
    const replaceInfo = {
      lead: this.props.lead || {},
      company: this.props.company || {},
      rep: this.props.user || {}
    };
    const templateWithoutSpan = convertTagsOnly(template.content);
    const preview = { content: templateWithoutSpan + '', information: template.information + '' };
    const content = templateWithoutSpan + template.information + '';
    (content.match(/\{\{(.*?)\}\}/g) || []).forEach(fullTag => {
      const parts = fullTag.replace('{{', '').replace('}}', '').split('.');
      const [tagType, tagField] = parts;
      const valueToReplace = replaceInfo[tagType][tagField] || null;
      if (valueToReplace && valueToReplace.length > 0) {
        preview.content = preview.content.replace(new RegExp(fullTag, 'g'), valueToReplace);
        preview.information = preview.information.replace(new RegExp(fullTag, 'g'), valueToReplace);
      }
    });
    return preview;
  };
  getTags = (body, subject) => {
    const content = body + subject + '',
      invalidTags = [];
    (content.match(/\{\{(.*?)\}\}/g) || []).forEach(val => invalidTags.push(val));
    return invalidTags;
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { loading, template, sending } = this.state;
    const { isCompleted } = this.props;

    const numbers = [
      { type: 'Phone', number: this.props.lead?.phone?.number },
      { type: 'Mobile', number: this.props.lead?.mobile?.number },
    ];

    this.props.lead?.custom_fields?.forEach(f => {
      if (f.type === 'phone') {
        numbers.push([{
          type: f.label,
          number: f.value
        }]);
      }
    })

    return (
      <Modal
        wrapClassName="sequence-text-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={true}
        width={640}
      >
        <Modal.Close onClick={this.hideModal} />
        <div className="seq-header">
          <IconMessage />
          <h4>Review Text Message</h4>
        </div>
        <div className="seq-body">
          {loading || !template ? (
            <Loading />
          ) : (
            <div>
              <div className="text-bubble">
                <div className="quill-render" dangerouslySetInnerHTML={{ __html: template.content }} />
              </div>
              <div className="send-to">
                <div>Send text message to:</div>
                <Select
                  placeholder="Select a phone number"
                  value={this.state.sendTo}
                  onChange={val => this.setState({ sendTo: val })}
                  getPopupContainer={trigger => trigger.parentNode}
                >
                  {numbers.map((n, i) => (
                    <Select.Option key={`pm-${i}`} value={n.number + '---' + n.type}>{n.type}:&nbsp;&nbsp;{n.number}</Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          )}
        </div>
        <div className="seq-actions">
          <Button type="default" onClick={this.hideModal}>
            Close
          </Button>
          {!isCompleted && (
            <Button type="primary" disabled={loading} loading={sending} onClick={this.sendTextMessage}>
              Send Text Message
            </Button>
          )}
        </div>
      </Modal>
    );
  }
}
