import React from 'react';
import ReactDOMServer from 'react-dom/server';

const blankSignature = (
  <div className="sxd-signature">
    ---
    <div style={{ fontWeight: '500', fontSize: '14px', lineHeight: '21px', color: '#000' }}>
      {'{rep.first_name} {rep.last_name}'}
    </div>
    <div style={{ fontWeight: '500', fontSize: '12px', lineHeight: '21px', color: '#707B90' }}>
      {'{campaign.custom_title}'} at {'{company.name}'}
    </div>
    <a
      href="tel:{rep.phone_number}"
      style={{
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '18px',
        color: '#4353FF',
        textDecoration: 'none'
      }}
    >
      {'{rep.phone_number}'}
    </a>
    <br />
    <a
      href={'{company.website}'}
      style={{ fontWeight: '500', fontSize: '12px', lineHeight: '18px', color: '#4353FF' }}
    >
      {'{company.website}'}
    </a>
    <br />
    <a href={'{company.website}'}>
      <img
        src={'{company.logo}'}
        style={{ width: '64px', height: '64px', borderRadius: '32px', marginTop: '16px' }}
        alt=""
      />
    </a>
  </div>
);

export const generateSignature = (company, rep, campaign) => {
  // some defaults, ran into this with demo data
  if (!rep.phone_number) {
    rep.phone_number = '555-555-5555';
  }
  if (!campaign.custom_title) {
    campaign.custom_title = 'Sales Rep';
  }
  if (!company.website) {
    company.website = 'https://www.sellx.com';
  }
  const replaceInfo = { company, rep, campaign };
  let generatedString = ReactDOMServer.renderToString(blankSignature);
  (generatedString.match(/\{(.*?)\}/g) || []).forEach(fullTag => {
    const parts = fullTag.replace('{', '').replace('}', '').split('.');
    const [tagType, tagField] = parts;
    const valueToReplace = replaceInfo[tagType][tagField] || null;
    if (valueToReplace && valueToReplace.length > 0) {
      generatedString = generatedString.replace(new RegExp(fullTag, 'g'), valueToReplace);
      generatedString = generatedString.replace(new RegExp(fullTag, 'g'), valueToReplace);
    }
  });
  return generatedString;
};
