import React from 'react';
import { IconArrowBackUp, IconSend, IconMailbox } from '@tabler/icons-react';
import moment from 'moment';

export default function Email(props) {
  const { activity, user, goTo } = props;

  const emailType = activity.email.type;
  const threadId = activity.email.thread_id;
  const emailId = activity.email.email_id.split('@')[0].replace('<', '').replace('>', '');

  return (
    <div className={'lead-activity ' + activity.type}>
      <div className={emailType === 'incoming' ? 'act-icon incoming email' : 'act-icon email'}>
        {emailType === 'new' && <IconSend />}
        {emailType === 'reply' && <IconArrowBackUp />}
        {emailType === 'incoming' && <IconMailbox />}
        {emailType === 'received' && <IconMailbox />}
      </div>
      <div className="descript">
        {emailType === 'new' && (
          <React.Fragment>
            <b>{user.display_name}</b> sent an email to{' '}
            <b>
              {activity.email.to_name} &lt;{activity.email.to_email}&gt;
            </b>
          </React.Fragment>
        )}
        {emailType === 'reply' && (
          <React.Fragment>
            <b>{user.display_name}</b> replied to{' '}
            <b>
              {activity.email.to_name} &lt;{activity.email.to_email}&gt;
            </b>
          </React.Fragment>
        )}
        {emailType === 'incoming' && (
          <React.Fragment>
            <b>
              {activity.email.from_name} &lt;{activity.email.from_email}&gt;
            </b>{' '}
            responded to <b>{activity.email.to_name}</b>
          </React.Fragment>
        )}
        {emailType === 'received' && (
          <React.Fragment>
            <b>
              {activity.email.from_name} &lt;{activity.email.from_email}&gt;
            </b>{' '}
            sent a new email to <b>{activity.email.to_name}</b>
          </React.Fragment>
        )}
        <div className="act-tstamp">
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;{moment(activity.createdAt * 1).format('MMM D @ h:mm a')}
        </div>
        &nbsp;&middot;&nbsp;
        <div className="sxd-link" onClick={() => goTo('email', `/${threadId}?email=${emailId}`)}>
          {(emailType === 'new' || emailType === 'incoming' || emailType === 'received') &&
            'View Email'}
          {emailType === 'reply' && 'See Reply'}
        </div>
      </div>
    </div>
  );
}
