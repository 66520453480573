import React, { Component } from 'react';
import logo from '_assets/img/logo_black.svg';
import './Loading.scss';

export default class Loading extends Component {
  render() {
    return (
      <div id="seriph-loading" className={this.props.size || ''}>
        <img src={logo} alt="" />
      </div>
    );
  }
}
