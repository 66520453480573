import React, { Component } from 'react';
import { Selection, Alert } from '_seriph';
import { touchpointMinimums, generateMinimums } from '_constants/application';
import './Commitment.scss';

export default class Commitment extends Component {
  render() {
    const { commitment, setCommitment, campaign } = this.props;

    const isLeadGen = campaign?.objective === 'leadgen';
    const values = isLeadGen ? generateMinimums : touchpointMinimums;

    return (
      <div className="apply-commitment">
        {isLeadGen ? (
          <h3>How many leads are you able to generate daily on this campaign?</h3>
        ) : (
          <h3>How many daily tasks are you able you work on this campaign?</h3>
        )}
        <div className="options">
          <Selection
            values={values}
            value={commitment}
            onChange={val => setCommitment(val)}
            full={true}
          />
        </div>
        <Alert id="apply-touchpoints" type="info">
          Apply only if you’re able to commit to the selected amount of&nbsp;
          {isLeadGen ? 'leads generated' : 'touchpoints'} or risk a lowering your reputation score
          and getting removed from the campaign.
        </Alert>
      </div>
    );
  }
}
