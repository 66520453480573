import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAgent } from 'Queries/Agents/agents';
import { updateAccount } from 'Mutations/Agent/updateAccount';
import { loginUser } from 'Store/user/user_actions';
import ChangeEmail from '../ChangeEmail/ChangeEmail';
import ChangePassword from '../ChangePassword/ChangePassword';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { resendVerify } from 'Mutations/User/user';
import { Card, Input, Button, Tooltip, Header, Loading, message } from '_seriph';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './Account.scss';

class Account extends Component {
  state = {
    loading: true,
    user: null,
    saved: null,
    changePassword: false,
    changeEmail: false,
    resent: false
  };
  componentDidMount = () => {
    this.loadAccount();
  };
  loadAccount = () => {
    this.props.client
      .query({ variables: { id: this.props.myself.id }, query: getAgent })
      .then(result => {
        if (result && result.data && result.data.client) {
          this.setState({
            user: result.data.client,
            saved: JSON.stringify(result.data.client),
            loading: false
          });
        } else {
          this.setState({ loading: false });
          message.error('Account not found, try again');
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error('Account not found, try again');
      });
  };
  saveAccount = () => {
    this.props.client
      .mutate({ variables: { ...this.state.user }, mutation: updateAccount })
      .then(result => {
        if (result && result.data && result.data.updateAccount) {
          this.props.loginUser(result.data.updateAccount);
          this.loadAccount();
          message.success('Your information has been updated');
        } else {
          message.error('Could not save, try again');
        }
      });
  };
  resendCode = email => {
    this.props.client
      .mutate({ variables: { email: email }, mutation: resendVerify })
      .then(result => {
        if (result && result.data && result.data.resendVerify) {
          message.success('A new verification code has been sent to ' + email);
          this.setState({ resent: true });
        } else {
          message.error("Couldn't send code, try again");
        }
      });
  };
  update = (field, value) => {
    console.log(value);
    const user = Object.assign({}, this.state.user);
    user[field] = value !== undefined ? value : null;
    this.setState({ user });
  };
  removeChangeEmail = () => this.setState({ changeEmail: false });
  removeChangePassword = () => this.setState({ changePassword: false });
  render() {
    const { user, loading } = this.state;

    return loading ? (
      <Loading />
    ) : (
      <Card id="agent-settings-account" className="settings-card">
        <Header font="sans" size="2" className="settings-header">
          Personal Info
        </Header>
        {JSON.stringify(user) !== this.state.saved ? (
          <Button onClick={this.saveAccount} type="primary" className="save-account">
            Save Information
          </Button>
        ) : null}

        <div className="settings-items">
          <div className="settings-item">
            <div className="type">First Name</div>
            <div className="value">
              <Input
                size="medium"
                onChange={e => this.update('first_name', e.target.value)}
                value={user.first_name}
              />
            </div>
            <div className="edit" />
          </div>

          <div className="settings-item">
            <div className="type">Last Name</div>
            <div className="value">
              <Input
                size="medium"
                placeholder="Last Name"
                onChange={e => this.update('last_name', e.target.value)}
                value={user.last_name}
              />
            </div>
            <div className="edit" />
          </div>

          <div className="settings-item">
            <div className="type">Phone Number</div>
            <div className="value int-phone seriph-input input-medium">
              <PhoneInput
                onChange={val => this.update('phone_number', val)}
                value={user.phone_number}
              />
            </div>
            <div className="edit" />
          </div>
          <div className="settings-item">
            <div className="type">
              SellX Email
              <Tooltip
                overlayClassName="sellx-tooltip sellx-tooltip-small"
                title="This email is only for use inside the platform, it is not for personal use."
              >
                <FontAwesomeIcon icon={faInfoCircle} className="tt-icon" />
              </Tooltip>
            </div>
            <div className="value email">{user.email_username}@sellx.org</div>
            <div className="edit" />
          </div>
          <div className="settings-item">
            <div className="type">Personal Email</div>
            <div className="value">{user.user.email} </div>
            <div className="edit">
              <span
                className="link link-change"
                onClick={() => this.setState({ changeEmail: true })}
              >
                Change Email
              </span>
            </div>
          </div>
          <div className="settings-item">
            <div className="type">Password</div>
            <div className="value">************</div>
            <div className="edit">
              <span className="link" onClick={() => this.setState({ changePassword: true })}>
                Update Password
              </span>
            </div>
          </div>
        </div>

        {this.state.changeEmail ? (
          <ChangeEmail
            myself={this.props.user}
            client={this.props.client}
            removeModal={this.removeChangeEmail}
            loginUser={this.props.loginUser}
            reload={this.loadAccount}
          />
        ) : null}

        {this.state.changePassword ? (
          <ChangePassword client={this.props.client} removeModal={this.removeChangePassword} />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.agent };
};

export default connect(mapStateToProps, { loginUser })(Account);

// display_name: "Dean Glas"
// email: "agent@sellx.app"
// first_name: "Dean"
// id: "5d6549d5d7c0341dea6967cd"
// jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNWQ2NTQ5ZDVkN2MwMzQxZGVhNjk2N2NkIiwiZW1haWwiOiJhZ2VudEBzZWxseC5hcHAiLCJyb2xlX3R5cGUiOiJhZ2VudCIsImZpcnN0X25hbWUiOiJEZWFuIiwibGFzdF9uYW1lIjoiR2xhcyIsIm9uYm9hcmQiOm51bGwsImRpc3BsYXlfbmFtZSI6IkRlYW4gR2xhcyIsInBpY3R1cmUiOiJodHRwczovL3NlbGx4LnMzLnVzLWVhc3QtMi5hbWF6b25hd3MuY29tLzE1NzI5NzY2MTQ1OTcucG5nIn0sImlhdCI6MTU3NTY2MjA2N30.ZEKwB_3zAHvNiczpZBlK_Tpiysl5gmGpcFQ3SywlZXQ"
// last_name: "Glas"
// onboard: null
// picture: "https://sellx.s3.us-east-2.amazonaws.com/1572976614597.png"
// role_type: "agent"
