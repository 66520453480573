import React, { Component } from 'react';
import { Dropdown } from 'antd';
import { IconChevronDown } from '@tabler/icons-react';
import './RepFilter.scss';

const repMap = {
  all: 'All Reps',
  xdr: 'XDR',
  internal: 'Internal'
}

export default class RepFilter extends Component {
  render() {
    const { setFilter, filter } = this.props;
    return (
      <div id="rep-filter">
        <Dropdown
          trigger={['click']}
          placement="bottomLeft"
          overlay={
            <div id="rep-filter-overlay">
              <div className="filter-container">
                <div className="filter-options">
                  <div
                    onClick={() => setFilter('all')}
                    className={filter === 'all' ? 'filter-option active' : 'filter-option'}
                  >
                    All Reps
                  </div>
                </div>
                <div className="filter-title">Rep Type</div>
                <div className="filter-options">
                  <div
                    onClick={() => setFilter('xdr')}
                    className={filter === 'xdr' ? 'filter-option active' : 'filter-option'}
                  >
                    XDRs
                  </div>
                  <div
                    onClick={() => setFilter('internal')}
                    className={filter === 'internal' ? 'filter-option active' : 'filter-option'}
                  >
                    Internal
                  </div>
                </div>
              </div>
            </div>
          }
        >
          <h4>
            { filter ? repMap[filter] : 'Rep type'}
            <IconChevronDown size={18} stroke={1.75} />
          </h4>
        </Dropdown>
      </div>
    );
  }
}
