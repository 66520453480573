import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { adminCampaignStats } from '../../../_graphql/queries';
import { masqueradeUser } from '../../../_graphql/mutations';
import { Header, message, Table, Button } from '_seriph';
import { DatePicker, Select } from 'antd';
import moment from 'moment';
import { getColumns } from './Columns';
import { loginUser } from 'Store/user/user_actions';
import axios from 'axios';
import { downloadFile } from '_assets/js/helpers';
import './CampaignStats.scss';

const { MonthPicker, WeekPicker } = DatePicker;

class CampaignStats extends Component {
  state = {
    loading: true,
    date: moment().format('YYYYMMDD'),
    type: 'weekly',
    tableData: [],
    internal: 'xdr'
  };
  componentDidMount = () => {
    this.getStats();
  };
  getStats = () => {
    if (!this.state.loading) this.setState({ loading: true });
    this.props.client
      .query({
        variables: {
          date: this.state.date,
          type: this.state.type,
          internal: this.state.internal || 'xdr'
        },
        query: adminCampaignStats
      })
      .then(result => {
        if (result && result.data && result.data.adminCampaignStats) {
          const data = result.data.adminCampaignStats?.data || [];
          this.setState({ 
            tableData: data
          }, () => {
            this.setState({ loading: false });
          });
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        message.error('Could not load your statistics, try again');
        this.setState({ loading: false });
      });
  };
  downloadStats = () => {
    this.setState({ exporting: true });
    const axiosData = {
      date: this.state.date,
      type: this.state.type,
      internal: this.state.internal || 'xdr'
    };
    axios.post(`${process.env.REACT_APP_API_PATH}/v1/campaignstats/export`, axiosData, {
      headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') }
    }).then(res => {
      if (res && res.data && res.data.csv) {
        downloadFile(
          `campaign_stats_${this.state.type}_${this.state.date}_export.csv`,
          'data:text/csv;charset=UTF-8,\uFEFF' + encodeURIComponent(res.data.csv)
        );
        message.success('Campaign statistics statistics successfully exported');
        this.setState({ exporting: false });
      } else {
        this.setState({ exporting: false });
        message.error('Export failed, try again');
      }
    })
    .catch(() => {
      message.error('Export failed, try again');
      this.setState({ exporting: false });
    });
  };
  onChange = currentDate => {
    const { type } = this.state;
    let date = currentDate;
    if (type === 'weekly') {
      date = currentDate.startOf('week');
    } else if (type === 'monthly') {
      date = currentDate.startOf('month');
    }
    this.setState({ date: date.format('YYYYMMDD') }, this.getStats);
  };
  onYearChange = year => {
    const currentDate = moment().day('Monday').year(year).week('24');
    this.setState({ date: currentDate.format('YYYYMMDD') }, this.getStats);
  };
  masq = user_id => {
    this.props.client
      .mutate({
        variables: { user_id: user_id },
        mutation: masqueradeUser
      })
      .then(result => {
        if (result && result.data && result.data.masqueradeUser) {
          this.props.loginUser(result.data.masqueradeUser);
          this.props.history.push('/');
        } else {
          throw new Error('Could not login as company, try again');
        }
      })
      .catch(err => {
        message.error(err.message);
      });
  };
  UNSAFE_componentWillMount = () => window.scrollTo({ top: 0 });
  render() {
    const { tableData, loading } = this.state;

    const year = moment(this.state.date, 'YYYYMMDD').format('YYYY');

    return (
      <div id="campaign-analytics-stats">
        <div className="seriph-header-wrapper">
          <Header type="display" weight="600" size="4">
            Campaign Stats
          </Header>
        </div>

        <div className="dashboard-actions">
          <span className="no-left">Viewing</span>
          <Select value={this.state.type} onChange={type => this.setState({ type }, this.getStats)}>
            <Select.Option value="daily">Daily</Select.Option>
            <Select.Option value="weekly">Weekly</Select.Option>
            <Select.Option value="monthly">Monthly</Select.Option>
            <Select.Option value="yearly">Yearly</Select.Option>
          </Select>
          <span>stats, for</span>
          {this.state.type === 'daily' && (
            <DatePicker 
              onChange={this.onChange}
              defaultValue={moment().startOf('week')}
              format="[the day of] M/D/YY"
            />
          )}
          {this.state.type === 'weekly' && (
            <WeekPicker 
              onChange={this.onChange}
              defaultValue={moment().startOf('week')}
              format="[the week of] M/D/YY"
            />
          )}
          {this.state.type === 'monthly' && (
            <MonthPicker 
              onChange={this.onChange}
              defaultValue={moment().startOf('week')}
              format="[the month of] MMM YYYY"
            />
          )}
          {this.state.type === 'yearly' && (
            <Select value={year} onChange={this.onYearChange}>
              <Select.Option value="2021">2021</Select.Option>
              <Select.Option value="2022">2022</Select.Option>
              <Select.Option value="2023">2023</Select.Option>
            </Select>
          )}
          <span>with only</span>
          <Select value={this.state.internal} onChange={internal => this.setState({ internal }, this.getStats)}>
            <Select.Option value="all">All Reps</Select.Option>
            <Select.Option value="xdr">XDRs Only</Select.Option>
            <Select.Option value="internal">Internal Only</Select.Option>
          </Select>
          <Button loading={this.state.exporting} className="export" onClick={this.downloadStats} type="primary" size="small">
            Export to CSV
          </Button>
        </div>
        <div className="task-table">
          <Table
            lined
            rounded
            loading={loading}
            locale={{ emptyText: loading ? 'Loading campaign statistics...' : 'No statistics found' }}
            dataSource={tableData.map(d => {
              d.key = d.agent?.id;
              return d;
            })}
            scroll={{ x: 'max-content' }}
            pagination={{ pageSize: 10 }}
            columns={getColumns(this.masq)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, { loginUser })(CampaignStats));