import React from 'react';

import { Alert, TextArea } from '_seriph';

export function ManualTag({ formState: { note }, onChange }) {
  return (
    <>
      <TextArea
        value={note}
        onChange={v => onChange('note', v.target.value)}
        size="large"
        label={
          <>
            Add a note <span>(optional)</span>
          </>
        }
        rows={6}
        placeholder="Write a message..."
      />
      <Alert type="warning" icon={<></>}>
        <b>Manual - </b>
        Progression of tasks will be halted and lead will be in a manual state.
      </Alert>
    </>
  );
}
