import gql from 'graphql-tag';

export const createFileCategory = gql`
  mutation createFileCategory($category: String!, $campaign_id: String!) {
    createFileCategory(category: $category, campaign_id: $campaign_id) {
      files {
        id
        category
        files {
          id
          name
          type
          fileType
          url
          size
        }
      }
    }
  }
`;

export const deleteFile = gql`
  mutation deleteFile($file_id: String!, $campaign_id: String!) {
    deleteFile(file_id: $file_id, campaign_id: $campaign_id) {
      files {
        id
        name
        type
        fileType
        url
        size
      }
    }
  }
`;
