import React, { Component } from 'react';
import { Tooltip } from 'antd';
import {
  IconCoin,
  IconCash,
  IconCircleSquare,
  IconFlame,
  IconMapPin,
  IconLanguage
} from '@tabler/icons-react';
import { formatMoney } from '_assets/js/helpers';
import './Requirements.scss';

const OverviewIcon = props => (
  <div className="overview-icon">
    <div className="icn">{props.icon}</div>
    <div className="info">
      <h4>{props.info}</h4>
      <p>{props.type}</p>
    </div>
  </div>
);

const TooltipList = props => (
  <Tooltip placement="top" overlayClassName="sellx-tooltip sellx-tooltip-small" title={props.data}>
    {props.data}
  </Tooltip>
);

export default class Requirements extends Component {
  render() {
    const { campaign } = this.props;

    const isQualify = campaign.objective === 'qualify';
    let shortObjective = 'generated';
    if (isQualify) shortObjective = 'qualified';

    let languages = campaign.target_languages.sort((a, b) => a.localeCompare(b));
    let newLocations = campaign.target_locations.map(l => l.name);
    let locations = newLocations.sort((a, b) => a.localeCompare(b));

    return (
      <div className="overview-requirements">
        <h3>Requirements and Expectations</h3>
        <div className="overview-container">
          <OverviewIcon
            type="Compensation"
            info={formatMoney(campaign.price_per_lead) + ` / ${shortObjective} lead`}
            icon={<IconCash />}
          />
          {isQualify ? (
            <OverviewIcon
              type="Compensation"
              info={formatMoney(campaign.price_not_interested) + ` / non-qualified lead`}
              icon={<IconCoin />}
            />
          ) : null}
          <OverviewIcon
            type="Experience Level"
            info={campaign.target_experience_text}
            icon={<IconCircleSquare />}
          />
          <OverviewIcon
            type="Skills"
            info={<TooltipList data={campaign.target_skills.join(', ')} />}
            icon={<IconFlame />}
          />
          {locations.length > 0 ? (
            <OverviewIcon
              type="Locations"
              info={<TooltipList data={locations.join(', ')} />}
              icon={<IconMapPin />}
            />
          ) : null}
          {languages.length > 0 ? (
            <OverviewIcon
              type="Languages"
              info={<TooltipList data={languages.join(', ')} />}
              icon={<IconLanguage />}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
