import React, { Component } from 'react';
import { Modal, Button, Input, message } from 'antd';
import { getErrors } from '_assets/js/helpers';
import { getTemplate } from '_graphql/queries/campaign';
import { sendEmail } from '_graphql/mutations/lead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Loading from '_shared/Loading/Loading';
import Quill from '_forms/Quill/Quill';
import Infotip from '_shared/Infotip/Infotip';
import { generateSignature } from '_helpers/signature';
import './Email.scss';
import Icons from 'App/_shared/Icons/Icons';

export default class Email extends Component {
  state = {
    visible: true,
    body: '',
    subject: '',
    invalidTags: [],
    signature: generateSignature(this.props.company, this.props.user, this.props.campaign),
    sending: false,
    loading: true
  };
  componentDidMount = () => {
    this.loadTemplate();
  };
  loadTemplate = () => {
    this.props.client
      .query({ variables: { template_id: this.props.templateId }, query: getTemplate })
      .then(result => {
        if (result && result.data && result.data.getTemplate) {
          const template = this.convertEmail(result.data.getTemplate);
          this.setState({
            subject: template.information,
            body: template.content,
            invalidTags: this.getTags(template.content, template.information),
            loading: false
          });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not load email template, try again');
        this.setState({ loading: false });
      });
  };
  sendEmail = () => {
    this.setState({ sending: true });
    const lead = this.props.lead || {};
    this.props.client
      .mutate({
        variables: {
          lead_id: lead.id,
          campaign_id: lead.campaign_id,
          is_reply: false,
          email_id: null,
          origin_email_id: null,
          content: this.state.body + this.state.signature,
          subject: this.state.subject
        },
        mutation: sendEmail
      })
      .then(result => {
        if (result && result.data && result.data.sendEmail) {
          setTimeout(() => {
            message.success('Email sent to ' + lead.email);
            if (this.props.complete) this.props.complete(result.data.sendEmail);
            this.hideModal();
          }, 1000);
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Error sending email, try again');
        this.setState({ sending: false });
      });
  };
  convertEmail = template => {
    const replaceInfo = {
      lead: this.props.lead || {},
      company: this.props.company || {},
      rep: this.props.user || {}
    };
    const preview = { content: template.content + '', information: template.information + '' };
    const content = template.content + template.information + '';
    (content.match(/\{\{(.*?)\}\}/g) || []).forEach(fullTag => {
      const parts = fullTag.replace('{{', '').replace('}}', '').split('.');
      const [tagType, tagField] = parts;
      const valueToReplace = replaceInfo[tagType][tagField] || null;
      if (valueToReplace && valueToReplace.length > 0) {
        preview.content = preview.content.replace(new RegExp(fullTag, 'g'), valueToReplace);
        preview.information = preview.information.replace(new RegExp(fullTag, 'g'), valueToReplace);
      }
    });
    return preview;
  };
  getTags = (body, subject) => {
    const content = body + subject + '',
      invalidTags = [];
    (content.match(/\{\{(.*?)\}\}/g) || []).forEach(val => invalidTags.push(val));
    return invalidTags;
  };
  complete = () => {
    if (this.props.complete) this.props.complete();
    this.hideModal();
  };
  updateBody = val =>
    this.setState({ body: val, invalidTags: this.getTags(val, this.state.subject) });
  updateSubject = val =>
    this.setState({ subject: val, invalidTags: this.getTags(this.state.body, val) });
  hideModal = () => this.setState({ visible: false });
  render() {
    const { loading, sending, body, subject, invalidTags } = this.state;
    const { lead } = this.props;

    return (
      <Modal
        wrapClassName="sequence-email-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={true}
        width={720}
      >
        <div className="close-icon" onClick={this.hideModal}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="seq-header">
          <Icons.Email />
          <h4>
            Send email to {lead.first_name} {lead.last_name} &lt;{lead.email}&gt;
          </h4>
        </div>
        <div className="seq-body">
          {loading ? (
            <Loading />
          ) : (
            <React.Fragment>
              <Input
                className="template-info"
                placeholder="Subject..."
                onChange={e => this.updateSubject(e.target.value)}
                value={subject}
              />
              <Quill value={body} onChange={this.updateBody} placeholder="Send an email..." />
            </React.Fragment>
          )}
        </div>
        <div className="seq-actions">
          {invalidTags.length > 0 ? (
            <div className="invalid-tags">
              <Infotip title="We were unable to find info for the following tags, try to replace the {{tag}} yourself using information you know or please adjust the email accordingly." />
              <div>{invalidTags.join(', ')}</div>
            </div>
          ) : (
            <div className="signature-warning">
              <b>Note:</b> Signature will be added automatically
            </div>
          )}
          <Button className="btnx btnx-default" onClick={this.hideModal}>
            Close
          </Button>
          <Button
            className="btnx btnx-primary"
            loading={sending}
            disabled={loading || invalidTags.length > 0}
            onClick={this.sendEmail}
          >
            Send Email
          </Button>
        </div>
      </Modal>
    );
  }
}
