import React, { Component } from 'react';
import { Modal, Button, Checkbox, Avatar } from '_seriph';
import './AddAgentBackModal.scss';
import classNames from 'classnames';

class AddAgentBackModal extends Component {
  state = {
    visible: true,
    message: '',
    selectedCampaign: null,
    stage: 'initial',
    loading: false
  };
  hideModal = () => this.setState({ visible: false });
  toggleSelected = campaignId => {
    this.setState({ selectedCampaign: campaignId });
  };
  addBack = () => {
    const activeCampaign = this.props.campaigns.find(c => c.id === this.state.selectedCampaign);
    this.props.addBackToCampaign(this.props.agent, activeCampaign, () => this.toggleSelected(null));
  };
  render() {
    const { agent } = this.props;
    let campaigns = this.props.campaigns.map(camp => {
      const member = camp.agents.find(a => a.agent_id === agent.id);
      camp.activeMember = member && member.active ? true : false;
      camp.previousMember = member && !member.active ? true : false;
      return camp;
    }).filter(camp => {
      const onlyActivePaused = ['active', 'inactive'].includes(camp.status);
      return onlyActivePaused && camp.previousMember;
    });

    const activeCampaign = this.props.campaigns.find(c => c.id === this.state.selectedCampaign);

    return (
      <Modal
        wrapClassName="add-agent-back-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        style={{ top: 60 }}
        width={600}
        closable={false}
        maskClosable={true}
      >
        <Modal.Close onClick={this.hideModal} />
        <div className="ia-agent">
          <Avatar size="large" type="agent" picture={agent.picture} />
          <div className="ia-details">
            <h3>{agent.display_name}</h3>
          </div>
        </div>
        <div className="ia-main">
          <div className="ia-main-header">
            <div>{agent.display_name} was removed from:</div>
          </div>

          <div className="ia-main-table">
            {campaigns?.map(campaign => {
              return (
                <div
                  className={classNames({
                    'ia-row': true,
                    selected: this.state.selectedCampaign === campaign.id
                  })}
                  key={campaign.id}
                  onClick={() => this.toggleSelected(campaign.id)}
                >
                  <div className="ia-row-left">
                    <Checkbox noBg={true} checked={this.state.selectedCampaign === campaign.id} />
                  </div>
                  <div className="ia-row-right">
                    <h4>{campaign.name}</h4>
                    <p>
                      {campaign.objective === 'leadgen'
                        ? 'Lead Generation'
                        : 'Lead Qualification'}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="ia-actions">
          <Button onClick={this.hideModal}>Cancel</Button>
          <Button
            type="primary"
            onClick={this.addBack}
            loading={this.state.loading}
            disabled={!this.state.selectedCampaign}
          >
            { !this.state.selectedCampaign ? (
              <div>Select a campaign</div>
            ) : (
              <div>Add back to: {activeCampaign?.name}</div>
            )}
          </Button>
        </div>
      </Modal>
    );
  }
}

export default AddAgentBackModal;
