import gql from 'graphql-tag';

export const addCalendly = gql`
  mutation addCalendly($token: String!, $refresh: String!) {
    addCalendly(token: $token, refresh: $refresh)
  }
`;

export const removeCalendly = gql`
  mutation removeCalendly($refresh: String!) {
    removeCalendly(refresh: $refresh)
  }
`;

export const addChilipiper = gql`
  mutation addChilipiper($domain: String!, $inbound_router: String!) {
    addChilipiper(domain: $domain, inbound_router: $inbound_router)
  }
`;

export const removeChilipiper = gql`
  mutation removeChilipiper($refresh: String) {
    removeChilipiper(refresh: $refresh)
  }
`;

export const addSalesforce = gql`
  mutation addSalesforce($instance_url: String!, $access_token: String!, $refresh_token: String!) {
    addSalesforce(
      instance_url: $instance_url
      access_token: $access_token
      refresh_token: $refresh_token
    )
  }
`;

export const removeSalesforce = gql`
  mutation removeSalesforce($refresh: String!) {
    removeSalesforce(refresh: $refresh)
  }
`;

export const addHubspot = gql`
  mutation addHubspot($access_token: String!, $refresh_token: String!, $expires_in: Int!, $portal_id: String) {
    addHubspot(access_token: $access_token, refresh_token: $refresh_token, expires_in: $expires_in, , portal_id: $portal_id)
  }
`;

export const removeHubspot = gql`
  mutation removeHubspot($refresh: String!) {
    removeHubspot(refresh: $refresh)
  }
`;

export const addGmail = gql`
  mutation addGmail($access_token: String!, $refresh_token: String!, $expiry_date: Int!) {
    addGmail(access_token: $access_token, refresh_token: $refresh_token, expiry_date: $expiry_date)
  }
`;

export const removeGmail = gql`
  mutation removeGmail($refresh: String!) {
    removeGmail(refresh: $refresh)
  }
`;

export const addCustomDomain = gql`
  mutation addCustomDomain($domain: String!) {
    addCustomDomain(domain: $domain)
  }
`;

export const completeDomainSetup = gql`
  mutation completeDomainSetup($domain: String!) {
    completeDomainSetup(domain: $domain)
  }
`;

export const removeCustomDomain = gql`
  mutation removeCustomDomain($domain: String!) {
    removeCustomDomain(domain: $domain)
  }
`;

export const updateSalesforce = gql`
  mutation updateSalesforce($salesforce: SalesforceInput!) {
    updateSalesforce(salesforce: $salesforce)
  }
`;

export const updateHubspot = gql`
  mutation updateHubspot($hubspot: HubspotInput!) {
    updateHubspot(hubspot: $hubspot)
  }
`;
