import React, { Component } from 'react';
import { IconSearch } from '@tabler/icons-react';
import { Input } from '_seriph';
import './AgentSort.scss';

export default class AgentSort extends Component {
  render() {
    const { onChangeSearch } = this.props;

    return (
      <div className="agent-sort">
{/*        <Select value={sort} className="hired-status" onChange={updateSort} placeholder="Sort by">
          <Select.Option value="most">
            Most Earned
          </Select.Option>
          <Select.Option value="least">
            Least Earned
          </Select.Option>
          <Select.Option value="mostqualified">
            Most Leads Qualified
          </Select.Option>
          <Select.Option value="leastqualified">
            Least Leads Qualified
          </Select.Option>
          <Select.Option value="mostgenerated">
            Most Leads Generated
          </Select.Option>
          <Select.Option value="leastgenerated">
            Least Leads Generated
          </Select.Option>
          <Select.Option value="newest">
            Recent Hired
          </Select.Option>
          <Select.Option value="oldest">
            Oldest Hired
          </Select.Option>
        </Select>*/}
        <Input
          placeholder="Search..."
          onChange={e => onChangeSearch(e.target.value)}
          iconLeft={<IconSearch />}
        />
      </div>
    );
  }
}
