import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Loading, Button, Card, Input, message } from '_seriph';
import { IconPlus, IconSearch, IconCheck } from '@tabler/icons-react';
import { getSequences } from '_graphql/queries/sequence';
import { getErrors, plural } from '_assets/js/helpers';
import PreviewSequence from 'App/Company/Sequences/_modals/PreviewSequence/PreviewSequence';

import './SequencesV2.scss';

class SequencesV2 extends Component {
  state = {
    loading: true,
    sequences: [],
    searchText: ''
  };
  componentDidMount = () => {
    if (this.props.save) this.props.save();
    this.getSequences();
  };
  getSequences = () => {
    this.props.client
      .query({ query: getSequences })
      .then(result => {
        if (result && result.data && result.data.getSequences) {
          const sequences = result.data.getSequences || [];
          this.setState({ sequences, loading: false });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        message.error(getErrors(err) || 'Could not load sequences, try again');
      });
  };
  isActive = id => {
    const { campaign } = this.props;
    return campaign.sequence_list.find(s => s === id);
  };
  toggleSequence = id => {
    let sequence_list = this.props.campaign.sequence_list
      ? [...this.props.campaign.sequence_list]
      : [];
    const exists = sequence_list.indexOf(id);
    if (exists >= 0) {
      sequence_list = [];
    } else {
      sequence_list = [id];
    }
    this.props.update('sequence_list', sequence_list);
  };
  preview = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    this.showPreviewSequenceModal(id);
  };
  showPreviewSequenceModal = id => this.setState({ previewSequenceModal: id });
  removePreviewSequenceModal = () => this.setState({ previewSequenceModal: false });
  render() {
    const { loading, sequences, searchText } = this.state;
    const { actions, isLocked } = this.props;

    const filteredList = sequences.filter(s => {
      return isLocked
        ? this.isActive(s.id)
        : this.isActive(s.id) || s.name.toLowerCase().includes(searchText.toLowerCase());
    });

    const sequence_list = this.props.campaign.sequence_list
      ? [...this.props.campaign.sequence_list]
      : [];
    const isSelected = sequence_list.find(s => s === this.state.previewSequenceModal);

    return (
      <Card id="wizard-sequences-v2" className="wz-card">
        <div className="wz-container sx-form">
          {/* HEADER */}
          <div className="wz-card-header">
            <h3>Sequences</h3>
            <h5>Use one of our templates to get started or create your own</h5>
          </div>

          {/* CONTENT */}
          {loading ? (
            <Loading />
          ) : (
            <div className="seq-content">
              <div className="seq-filter">
                <Input
                  placeholder="Search sequences..."
                  value={this.state.searchText}
                  onChange={e => this.setState({ searchText: e.target.value })}
                  iconLeft={<IconSearch />}
                  disabled={isLocked}
                />
                <Link to="/sequences">
                  <Button type="default" size="medium" disabled={isLocked}>
                    New Sequence
                  </Button>
                </Link>
              </div>

              <div className="seq-options">
                {filteredList.map(s => (
                  <div
                    className={`seq-option ${this.isActive(s.id) ? 'active' : ''}`}
                    key={`seq-${s.id}`}
                    onClick={() => (isLocked ? null : this.toggleSequence(s.id))}
                  >
                    <div className="seq-icon">
                      {this.isActive(s.id) ? <IconCheck /> : <IconPlus />}
                    </div>
                    <div className="seq-details">
                      <h4>{s.name}</h4>
                      <p>
                        {s.day_count} {plural('day', s.day_count)}
                        &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                        {s.touchpoint_count} {plural('touchpoint', s.touchpoint_count)}
                      </p>
                    </div>
                    <Button
                      className="preview-btn"
                      type="default"
                      size="tiny"
                      onClick={e => this.preview(e, s.id)}
                    >
                      Preview
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          )}

          {this.state.previewSequenceModal ? (
            <PreviewSequence
              client={this.props.client}
              removeModal={this.removePreviewSequenceModal}
              sequenceId={this.state.previewSequenceModal}
              selectSequence={() =>
                isLocked ? null : this.toggleSequence(this.state.previewSequenceModal, true)
              }
              isSelected={isSelected}
            />
          ) : null}
        </div>
        {/* ACTIONS */}
        {actions}
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(SequencesV2));
