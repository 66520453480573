import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, Button, message, Radio } from '_seriph';
import { getErrors, formatMoney, flatMoney, numberWithCommas } from '_assets/js/helpers';
import PaymentMethod from '_styleguide/PaymentMethod/PaymentMethod';
import { Elements } from 'react-stripe-elements';
import { IconMinus, IconPlus, IconCheck, IconX } from '@tabler/icons-react';
import { createSubscription } from '_graphql/mutations/subscription';
import './SubscribeModal.scss';

const costMap = {
  m_s: 229,
  m_m: 199,
  m_l: 169,
  s_s: 199,
  s_m: 179,
  s_l: 149,
  y_s: 149,
  y_m: 129,
  y_l: 99,
};

class SubscribeModal extends Component {
  state = {
    saving: false,
    visible: true,
    validPaymentMethod: false,
    seats: 3,
    duration: 6,
  };
  subscribe = price => {
    this.setState({ saving: true });
    return this.props.client
      .mutate({
        variables: {
          duration: parseInt(this.state.duration),
          seats: parseInt(this.state.seats),
          price: price
        },
        mutation: createSubscription
      })
      .then(result => {
        if (result?.data?.createSubscription) {
          if (this.props.reload) this.props.reload();
          message.success('Subscription successful, welcome to SellX!');
          this.hideModal();
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not create subscription, try again');
        this.setState({ saving: false });
      });
  };
  decrement = () => {
    if (this.state.seats > 1) {
      this.setState({ seats: this.state.seats - 1 });
    }
  };
  increment = () => {
    this.setState({ seats: this.state.seats + 1 })
  };
  seatMap = num => {
    if (num >= 10 && num < 25) return 'm';
    if (num >= 25) return 'l';
    return 's';
  };
  durationMap = num => {
    if (num === 6) return 's';
    if (num === 12) return 'y';
    return 'm';
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    
    const { seats, duration, validPaymentMethod, saving } = this.state;

    const costPerSeat = costMap[`${this.durationMap(duration)}_${this.seatMap(seats)}`];
    const durationText = `${duration === 1 ? '' : duration + ' '}month${duration > 1 ? 's' : ''}`;

    const maxTotal = 229 * seats * duration;
    const finalTotal = costPerSeat * seats * duration;
    const hasSavings = duration > 1 || seats > 9;
    return (
      <Modal
        wrapClassName="company-subscribe-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={false}
        width={820}
      >
        { this.props.canClose ? <Modal.Close onClick={this.hideModal} /> : null }

        <div className="subscribe-form">

          <div className="payment-method">
            <h2>Join SellX</h2>
            <Elements>
              <PaymentMethod
                client={this.props.client}
                myself={this.props.user}
                isValid={validPaymentMethod => this.setState({ validPaymentMethod })}
              />
            </Elements>
            <div className="notice">
              <p>  
                Want to use the marketplace to hire talent?  Reach out to us at <a href="mailto: sales@sellx.com">sales@sellx.com</a> to get started.
              </p>
            </div>
          </div>

          <div className="payment-breakdown">

            <div className="itemize">
              <div className="itemize-info">
                <h3>Seat total</h3>
              </div>
              <div className="itemize-num">
                <Button circle size="tiny" onClick={this.decrement}>
                  <IconMinus />
                </Button>
                <span>{numberWithCommas(seats)}</span>
                <Button circle size="tiny" onClick={this.increment}>
                  <IconPlus />
                </Button>
              </div>
            </div>
            <div className="discount">
              <Radio.Group value={duration} onChange={e => this.setState({ duration: e.target.value })} className="seriph-radio-group">
                <Radio.Button value={1}>Monthly</Radio.Button>
                <Radio.Button value={6}>Semi-annual</Radio.Button>
                <Radio.Button value={12}>Annual</Radio.Button>
              </Radio.Group>
            </div>
            <div className="itemize">
              <div className="itemize-info">
                <h3>{numberWithCommas(seats)} seat(s){ hasSavings && <span>{formatMoney(229)}</span>}</h3>
              </div>
              <div className="itemize-num">
                {flatMoney(costPerSeat)} / seat
              </div>
            </div>
            <div className="itemize dis">
              <div className="itemize-info">
                { duration === 1 && <p>Choose a longer plan for a discount</p> }
                { duration === 6 && <p>Semi-annual discount applied!</p> }
                { duration === 12 && <p>Annual discount applied!</p> }
              </div>
              <div className="itemize-icon">
                { duration > 1 && <span className="good"><IconCheck /></span> }
                { duration <= 1 && <span className="bad"><IconX /></span> }
              </div>
            </div>
            <div className="itemize dis">
              <div className="itemize-info">
                { seats < 10 && <p>* Add {10 - seats} more seat(s) for discount</p> }
                { seats >= 10 && seats < 25 && <p>10+ seats discount applied!</p> }
                { seats >= 25 && <p>25+ seats discount applied!</p> }
              </div>
              <div className="itemize-icon">
                { seats >= 10 && <span className="good"><IconCheck /></span> }
                { seats < 10 && <span className="bad"><IconX /></span> }              
              </div>
            </div>
            { seats >= 10 && seats < 25 ? (
              <div className="itemize dis">
                <div className="itemize-info">
                  <p>* {25 - seats} more seat(s) for even bigger discount</p>
                </div>
                <div className="itemize-icon">
                  <span className="bad"><IconX /></span>           
                </div>
              </div>
            ) : null }
            <div className="itemize total">
              <div className="itemize-info">
                <h3>Total due { hasSavings && <span>{formatMoney(maxTotal)}</span> }</h3>
                <p>{numberWithCommas(seats)} seat{seats > 1 ? 's' : ''} at {flatMoney(costPerSeat)} per seat every {durationText}</p>
              </div>
              <div className="itemize-num">
                {formatMoney(finalTotal)}
              </div>
            </div>
            <Button
              disabled={!validPaymentMethod}
              loading={saving}
              type="primary"
              size="large"
              style={{ width: '100%' }}
              onClick={() => this.subscribe(finalTotal)}
            >
              Pay {formatMoney(finalTotal)}
            </Button>
            <div className="notice">
              <p>
                You’ll be charged {formatMoney(finalTotal)}, for the cost of your plan every {durationText}, unless canceled. Payment method will be saved to your account.
              </p>
            </div>
          </div>

        </div>

      </Modal>
    );
  }
}

export default withRouter(SubscribeModal);
