import React, { Component } from 'react';
import { Modal, Button, message } from 'antd';
import { submitGenerated } from '_graphql/mutations/lead';
import { getErrors } from '_assets/js/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faExclamation } from '@fortawesome/free-solid-svg-icons';
import './StageModal.scss';

export default class StageModal extends Component {
  state = {
    visible: true,
    result: null,
    maskClosable: true
  };
  submitLead = () => {
    this.setState({ maskClosable: false });
    this.props.client
      .mutate({ variables: { lead_id: this.props.lead.id }, mutation: submitGenerated })
      .then(result => {
        if (result && result.data && result.data.submitGenerated) {
          this.setState({ result: result.data.submitGenerated }, this.handleEnd);
        } else {
          throw new Error('Error saving lead information');
        }
      })
      .catch(err => {
        setTimeout(() => {
          this.setState({ result: 'error', maskClosable: true });
          message.error(getErrors(err) || 'Could not submit lead, try again');
        }, 2000);
      });
  };
  handleEnd = () => {
    this.props.reload(this.props.lead.id);
    setTimeout(this.hideModal, 0);
  };
  hideModal = () => {
    this.setState({ visible: false });
  };
  render() {
    const missing = this.props.missing || [];
    return (
      <Modal
        wrapClassName="stage-confirmation"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={this.state.maskClosable}
        width={456}
      >
        {this.state.maskClosable && (
          <div className="close-icon" onClick={this.hideModal}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}
        {missing.length <= 0 ? (
          <div className="stage-one">
            <div className="stage-icon">
              <FontAwesomeIcon icon={faExclamation} />
            </div>
            <h3>Are you sure you want to submit this lead?</h3>
            <p>
              Leads will be validated and reviewed to confirm completion. Make sure all lead
              information is correct before continuing.
            </p>
            <div className="stage-actions">
              <Button
                className="btnx btnx-neutral"
                onClick={this.hideModal}
                disabled={!this.state.maskClosable}
              >
                Continue Editing
              </Button>
              <Button
                className="btnx btnx-primary"
                onClick={this.submitLead}
                loading={!this.state.maskClosable}
              >
                Submit Lead
              </Button>
            </div>
          </div>
        ) : null}
        {missing.length >= 1 ? (
          <div className="stage-one">
            <div className="stage-icon missing">
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <h3>Missing/Incorrect Info</h3>
            <p>
              It looks like there are some fields that are either missing or incorrect.{' '}
              <b>Please update the following fields and resubmit.</b>
            </p>
            <div className="missing-list">
              {missing.map(m => (
                <div key={m}>{m}</div>
              ))}
            </div>
            <div className="stage-actions">
              <Button className="btnx btnx-neutral" onClick={this.hideModal}>
                Continue Editing
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
    );
  }
}
