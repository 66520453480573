import React, { Component } from 'react';
import './Card.scss';

export default class Card extends Component {
  render() {
    const props = { ...this.props };
    props.className = 'seriph-card ' + (props.className || '');

    // Handle Weight (light, normal (default), bold)
    if (props.weight) {
      props.className += ' card-' + props.weight;
      delete props.weight;
    }

    return <div {...props} />;
  }
}
