import React, { Component } from 'react';
import { Input, Checkbox, Radio } from 'antd';
import './Questions.scss';

export default class Questions extends Component {
  changeAnswers = (question, val) => {
    const answers = [...(this.props.answers || [])];
    if (this.doesAnswerExist(question)) {
      answers.map(a => {
        if (a.question === question.question && question.type === 'Checkbox') a.answers = val;
        if (a.question === question.question && question.type !== 'Checkbox') a.answer = val;
        return a;
      });
    } else {
      if (question.type === 'Checkbox') {
        answers.push({ question: question.question, answer: null, answers: val });
      } else {
        answers.push({ question: question.question, answer: val, answers: [] });
      }
    }
    this.props.updateLead(answers);
  };
  doesAnswerExist = q => {
    return this.props.answers.find(a => a.question === q.question) ? true : false;
  };
  getAnswer = q => {
    const answer = this.props.answers.find(a => a.question === q.question);
    if (q.type === 'Checkbox') {
      return answer && answer.answers ? answer.answers : [];
    } else {
      return answer && answer.answer ? answer.answer : '';
    }
  };
  render() {
    const { questions, lead } = this.props;

    return (
      <div id="seq-questions" className="sx-form">
        <h3 className="call-h3">Questions to ask {lead.first_name}</h3>
        {questions.map((q, i) => (
          <div className="lead-quest" key={'lq-' + i}>
            <h4>
              {i + 1}.&nbsp;{q.question}
            </h4>
            {q.type === 'Text' && (
              <Input.TextArea
                rows={2}
                placeholder="Write your answer here..."
                value={this.getAnswer(q)}
                onChange={e => this.changeAnswers(q, e.target.value)}
              />
            )}
            {q.type === 'Checkbox' && (
              <Checkbox.Group
                options={q.options}
                value={this.getAnswer(q)}
                onChange={val => this.changeAnswers(q, val)}
              />
            )}
            {q.type === 'Radio' && (
              <Radio.Group
                options={q.options}
                value={this.getAnswer(q)}
                onChange={e => this.changeAnswers(q, e.target.value)}
              />
            )}
          </div>
        ))}
      </div>
    );
  }
}
