import React from 'react';
import { numberWithCommas } from '_assets/js/helpers';
import { Avatar, Button } from '_seriph';
import { Link } from 'react-router-dom';

export const getColumns = (masq) => {
  return [
    {
      title: 'XDR',
      dataIndex: 'owner',
      render: (column, data) => {
        return (
          <div className="meeting-own">
            <Avatar
              size="mini"
              type="company"
              picture={data.agent?.picture}
            />
            {data.agent?.display_name}
          </div>
        );
      }
    },
    {
      title: 'Manager',
      dataIndex: 'manager',
      render: (column, data) => {
        return data.agent?.manager?.admin?.display_name || <i>None</i>;
      }
    },
    {
      title: 'Leads Pulled',
      dataIndex: 'daily.pulled',
      sorter: (a, b) => a.daily?.pulled - b.daily?.pulled,
      render: column => numberWithCommas(column)
    },
    {
      title: 'Touchpoint Stats',
      children: [
        {
          title: 'Emails',
          dataIndex: 'daily.touchpoint_email',
          sorter: (a, b) => a.daily?.touchpoint_email - b.daily?.touchpoint_email,
          render: column => numberWithCommas(column)
        },
        {
          title: 'Calls',
          dataIndex: 'daily.touchpoint_call',
          sorter: (a, b) => a.daily?.touchpoint_call - b.daily?.touchpoint_call,
          render: column => numberWithCommas(column)
        },
        {
          title: 'LinkedIns',
          dataIndex: 'daily.touchpoint_linkedin',
          sorter: (a, b) => a.daily?.touchpoint_linkedin - b.daily?.touchpoint_linkedin,
          render: column => numberWithCommas(column)
        },
        {
          title: 'Skipped',
          dataIndex: 'daily.touchpoint_skipped',
          sorter: (a, b) => a.daily?.touchpoint_skipped - b.daily?.touchpoint_skipped,
          render: column => numberWithCommas(column)
        },
        {
          title: 'Total Completed',
          dataIndex: 'daily.touchpoint_total',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.daily?.touchpoint_total - b.daily?.touchpoint_total,
          render: column => numberWithCommas(column)
        },
      ]
    },
    {
      title: 'Meetings Booked',
      dataIndex: 'daily.meetings',
      sorter: (a, b) => a.daily?.meetings - b.daily?.meetings,
      render: meetings => {
        return numberWithCommas(meetings) +  ` meeting${meetings !== 1 ? 's' : ''}`;
      }
    },
    {
      title: 'Scorecard',
      dataIndex: 'scorecard',
      render: (column, data) => {
        return (
          <React.Fragment>
            <Link to={`/analytics/scorecard?rep_id=${data?.agent?.id}`}>
              <Button type="secondary">Scorecard</Button>
            </Link>
            <Button type="primary" onClick={() => masq(data?.agent?.id)} style={{marginLeft: '12px'}}>Login</Button>
          </React.Fragment>
        )
      }
    }
  ];
};
