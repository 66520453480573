import React, { Component } from 'react';
import { calculateMatch } from '_helpers/calculateMatch';
import { Modal, message } from 'antd';
import CampaignDropdown from '_shared/CampaignDropdown/CampaignDropdown';
import { getColumns } from './_components/Columns';
import { hireApplication, passApplication } from 'Mutations/Company/campaign';
import { getErrors } from '_assets/js/helpers';
import { Select, Button, Table } from '_seriph';
import { IconCheck, IconX, IconChevronUp, IconChevronDown } from '@tabler/icons-react';
import './Applications.scss';

const { Option } = Select;

const MoreInfo = ({ application, hire, pass }) => (
  <div className="more-info">
    <p className="more-note">{application.note}</p>
    {application.status === 'pending' ? (
      <div className="actions">
        <Button type="default" size="medium" onClick={() => hire(application)}>
          <IconCheck size={16} stroke={1.75} />
          Accept
        </Button>
        <Button type="default" size="medium" onClick={() => pass(application)}>
          <IconX size={16} stroke={1.75} />
          Pass
        </Button>
      </div>
    ) : null}
  </div>
);

export default class Applications extends Component {
  state = {
    app: null,
    searchText: '',
    status: 'pending',
    checkList: {},
    audio: null,
    audioId: null,
    playing: false,
    paginationPageSize: 5
  };
  componentWillUnmount() {
    if (this.state.audio) {
      this.state.audio.removeEventListener('ended', this.resetAudio);
    }
  }
  hire = app => {
    this.setState({ loading: true, which: 'hire', actionId: app.id });
    Modal.confirm({
      title: `Are you sure you hire ${app.agent.display_name} for the ${app.campaign.name} campaign?`,
      content:
        'Please make sure all your Campaign material and instructions are up to date before accepting Sales Reps for them.',
      onOk: () => {
        this.props.client
          .mutate({
            variables: { id: app.id },
            mutation: hireApplication
          })
          .then(result => {
            if (result?.data?.hireApplication) {
              message.success(
                `You successfully hired ${app.agent.display_name} to the ${app.campaign.name} campaign`
              );
              this.setState({ loading: false, which: null, actionId: null }, () => {
                if (this.props.reload) this.props.reload();
              });
            }
          })
          .catch(err => {
            this.setState({ loading: false, which: null, actionId: null });
            message.error(getErrors(err) || 'Error hiring application, try again.');
          });
      },
      onCancel: () => this.setState({ loading: false, which: null, actionId: null })
    });
    this.onExpand(true, app);
  };
  pass = app => {
    this.setState({ loading: true, which: 'pass', actionId: app.id });
    Modal.confirm({
      title: `Are you sure you pass on ${app.agent.display_name} for the ${app.campaign.name} campaign?`,
      content: '',
      onOk: () => {
        this.props.client
          .mutate({
            variables: { id: app.id },
            mutation: passApplication
          })
          .then(result => {
            if (result && result.data && result.data.passApplication) {
              this.setState({ loading: false, which: null, actionId: null }, () =>
                this.props.getApplications()
              );
            }
          })
          .catch(err => {
            this.setState({ loading: false, which: null, actionId: null });
            message.error(getErrors(err) || 'Error passing application, try again.');
          });
      },
      onCancel: () => this.setState({ loading: false, which: null, actionId: null })
    });
    this.onExpand(true, app);
  };
  toggleCheck = (id, checked) => {
    const checkList = { ...this.state.checkList };
    checkList[id] = checked;
    this.setState({ checkList });
  };
  onChangeSearch = val => this.setState({ searchText: val });
  updateStatus = status => this.setState({ status });
  removeModal = () => this.setState({ app: null });
  customExpandIcon = props => (
    <Button
      className={props.expanded ? '' : 'not-expanded'}
      type="default"
      circle
      size="tiny"
      onClick={e => props.onExpand(props.record, e)}
    >
      {props.expanded ? <IconChevronUp /> : <IconChevronDown />}
    </Button>
  );
  resetAudio = () => {
    this.state.audio.removeEventListener('ended', this.resetAudio);
    this.setState({
      audio: null,
      audioId: null,
      playing: false
    });
  };
  handleAudio = (action, data) => {
    if (action === 'setup') {
      if (this.state.audio) {
        this.state.audio.pause();
        this.state.audio.removeEventListener('ended', this.resetAudio);
      }
      this.setState({ audio: new Audio(data.audio_url), audioId: data.id, playing: false }, () => {
        this.setState({ playing: true }, () => {
          this.state.audio.play();
          this.state.audio.addEventListener('ended', this.resetAudio);
        });
      });
    } else if (action === 'pause' && this.state.audio) {
      this.setState({ playing: false }, () => this.state.audio.pause());
    } else if (action === 'play' && this.state.audio) {
      this.setState({ playing: true }, () => this.state.audio.play());
    }
  };

  onExpand = async (expanded, record) => {
    if (expanded) {
      const neededMarking = await this.props.markApplicationExpanded(record.id);
      if (neededMarking) {
        this.props.reload();
      }
    }
  };

  onRow = record => {
    if (!record.seen_by?.includes(this.props.user.id)) {
      return {
        onLoad: () => {
          this.props.markApplicationSeen(record.id);
        }
      };
    }
  };

  render() {
    const { applications, isInCampaign, loading } = this.props;
    const { searchText, status, checkList } = this.state;
    const stats = { pending: 0, hired: 0, passed: 0 };

    const filtered = applications
      .filter(a => {
        const name = a.agent.display_name.toLowerCase();
        const isFiltered = searchText === '' || name.includes(searchText.toLowerCase());
        stats[a.status] += 1;
        return isFiltered && isInCampaign(a.campaign.id) && a.status === status;
      })
      .map(a => {
        a.match = calculateMatch(a);
        return a;
      });

    const audioInfo = { audioId: this.state.audioId, playing: this.state.playing };
    const columns = getColumns(
      this.hire,
      this.pass,
      this.toggleCheck,
      checkList,
      this.handleAudio,
      audioInfo,
      this.props.user.id,
      this.onExpand
    );

    return (
      <div id="rep-applications">
        <div className="hired-filter">
          <CampaignDropdown
            placement="bottomLeft"
            allowAll={true}
            campaign={this.props.campaign}
            campaigns={this.props.campaigns}
            selectCampaign={this.props.selectCampaign}
          />
          <Select value={status} onChange={this.updateStatus}>
            <Option value="pending">Needs Review ({stats.pending})</Option>
            <Option value="hired">Hired ({stats.hired})</Option>
            <Option value="passed">Passed ({stats.passed})</Option>
          </Select>
        </div>
        <div className="applications-table">
          <Table
            lined
            rounded
            loading={loading}
            rowSelection={null}
            columns={columns}
            pagination={{
              pageSize: this.state.paginationPageSize,
              total: filtered.length
            }}
            expandedRowRender={row => (
              <MoreInfo application={row} hire={this.hire} pass={this.pass} />
            )}
            expandIconColumnIndex={6}
            expandIconAsCell={false}
            expandIcon={props => this.customExpandIcon(props)}
            onExpand={this.onExpand}
            rowKey={record => record.id}
            dataSource={filtered}
            onRow={this.onRow}
            rowClassName={record => (record.seen_by?.includes(this.props.user.id) ? '' : 'unseen')}
          />
        </div>
      </div>
    );
  }
}
