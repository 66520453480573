import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getErrors } from '_assets/js/helpers';
import { Modal, Button, Header, Input, message } from '_seriph';
import { createSequence } from '_graphql/mutations/sequence';
import './NewSequenceModal.scss';

class NewSequenceModal extends Component {
  state = {
    visible: true,
    name: undefined,
    saving: false,
    validation: null
  };
  createSequence = () => {
    const name = this.state.name;
    if (!name) {
      this.setState({ validation: 'Sequence name is required' });
    } else {
      this.setState({ saving: true });
      this.props.client
        .mutate({ variables: { name }, mutation: createSequence })
        .then(result => {
          if (result && result.data && result.data.createSequence) {
            message.success('Sequence created');
            this.props.history.push('/sequences/' + result.data.createSequence);
            this.hideModal();
          }
          this.setState({ saving: false });
        })
        .catch(err => {
          this.setState({ saving: false });
          message.error(getErrors(err) || 'Error creating sequence, try again.');
        });
    }
  };
  updateValidate = e => {
    const name = e.target.value;
    if (!name) {
      this.setState({ name, validation: 'Sequence name is required' });
    } else {
      this.setState({ name, validation: null });
    }
  };
  enterFunction = event => {
    if (event.keyCode === 13) this.createSequence();
  };
  componentDidMount = () => document.addEventListener('keydown', this.enterFunction, false);
  componentWillUnmount = () => document.removeEventListener('keydown', this.enterFunction, false);
  hideModal = () => this.setState({ visible: false });
  render() {
    const { name, saving } = this.state;

    return (
      <Modal
        wrapClassName="new-sequence-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={true}
        width={456}
      >
        <Modal.Close onClick={this.hideModal} />

        <Header size="4" font="sans" className="new-header">
          New Sequence
          <div className="subheader">Give your new sequence a name</div>
        </Header>

        <div className="sequence-form">
          <Input
            value={name}
            onChange={this.updateValidate}
            placeholder="Sequence Name"
            hint={this.state.validation}
            error={this.state.validation}
            autoFocus
          />
        </div>

        <Modal.Actions align="right">
          <Button type="default" onClick={() => this.hideModal()}>
            Cancel
          </Button>
          <Button type="primary" loading={saving} onClick={this.createSequence}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withRouter(NewSequenceModal);
