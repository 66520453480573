import React, { Component } from 'react';
import { convertTagsOnly } from '_helpers/smartTags';
import './Script.scss';

export default class Script extends Component {
  convertCall = script => {
    const { lead, campaign, agent } = this.props;
    const company = campaign?.company || {};
    const replaceInfo = { lead: lead || {}, company: company || {}, rep: agent || {} };
    const templateWithoutSpan = convertTagsOnly(script);
    const preview = { content: templateWithoutSpan + '' };
    const content = templateWithoutSpan + '';
    (content.match(/\{\{(.*?)\}\}/g) || []).forEach(fullTag => {
      const parts = fullTag.replace('{{', '').replace('}}', '').split('.');
      const [tagType, tagField] = parts;
      const valueToReplace = replaceInfo[tagType][tagField] || null;
      if (valueToReplace && valueToReplace.length > 0) {
        preview.content = preview.content.replace(
          new RegExp(fullTag, 'g'), 
          `<span class="script-tag">${valueToReplace}</span>`
        );
      }
    });
    return preview;
  };
  render() {
    const { script } = this.props;

    const convertedScript = this.convertCall(script);

    return (
      <div id="dialer-script">
        <div
          className="dialer-script"
          dangerouslySetInnerHTML={{ __html: convertedScript?.content }}
        />
      </div>
    );
  }
}
