import React, { Component } from 'react';
import { Input, Select } from 'antd';
import Card from '_shared/Card/Card';
import Selection from '_forms/Selection/Selection';
import Infotip from '_shared/Infotip/Infotip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Locked } from '../Components/Locked/Locked';
import { cohorts } from '_constants/campaigns';
const { TextArea } = Input;

export default class CampaignSetup extends Component {
  validClass = field => {
    const { validation, showValidation } = this.props;
    return !validation[field] && showValidation ? 'input-label invalid' : 'input-label';
  };
  render() {
    const form = this.props.form;
    const isLocked = this.props.isLocked;
    let actualTop = 0,
      actualCalendar = 0;
    var tipTop = document.getElementById('objective-tip');
    var tipCalendar = document.getElementById('calendar-tip');
    if (tipTop) actualTop = tipTop.offsetTop;
    if (tipCalendar) actualCalendar = tipCalendar.offsetTop;

    return (
      <div className="step-container" id="cs-container">
        <Card id={this.props.id} className="step-card" padding="40px 20px 25px 20px">
          <div className="step-title">
            <h3 className={this.validClass('campaign_setup')}>Campaign Setup</h3>
            <p>The basics, tell reps what you&apos;re hiring for and expectations of the role</p>
          </div>
          <div className="step-form">
            <div className="step-input">
              <div className={this.validClass('name')}>
                <Locked isLocked={isLocked} />
                Campaign name *
              </div>
              <div className="input-entry">
                <Input
                  disabled={isLocked}
                  value={form.name}
                  onChange={e => this.props.updateForm('name', e.target.value)}
                  size="large"
                  className="forms"
                  placeholder="Sales rep..."
                />
              </div>
            </div>

            <div className="step-input" id="objective-tip">
              <div className={this.validClass('type')}>
                Campaign type *{' '}
                <Infotip title="This determines whether or not sales reps can find your campaign and apply to it. If Private you would have to find and invite Sales Reps." />
              </div>
              <div className="input-entry">
                <Selection
                  values={[
                    { title: 'Public', value: 'public' },
                    { title: 'Private', value: 'private' }
                  ]}
                  full={false}
                  value={form.type}
                  onChange={value => this.props.updateForm('type', value)}
                />
              </div>
            </div>

            {this.props.canCohort ? (
              <div className="step-input" id="objective-tip">
                <div className={this.validClass('cohort')}>
                  Cohort{' '}
                  <Infotip title="Select if this is a cohort campaign and which team you want to assign it to." />
                </div>
                <div className="input-entry">
                  <Select
                    className="forms"
                    placeholder="Select a cohort..."
                    dropdownClassName="sx-dropdown"
                    style={{ width: '100%' }}
                    value={form.cohort}
                    onChange={value => this.props.updateForm('cohort', value)}
                  >
                    <Select.Option key="cohnone" value="none">
                      None
                    </Select.Option>
                    {Object.keys(cohorts).map(c => (
                      <Select.Option key={'coh' + c} value={c}>
                        {cohorts[c]}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            ) : null}

            <div className="step-input">
              <div className={this.validClass('objective')}>
                <Locked isLocked={isLocked} />
                Objective *
              </div>
              <div className="input-entry">
                <Selection
                  disabled={isLocked}
                  className={form.objective}
                  values={[
                    { title: 'Lead Generation', value: 'leadgen' },
                    { title: 'Qualify Leads', value: 'qualify' }
                  ]}
                  full={true}
                  value={form.objective}
                  onChange={value => this.props.updateForm('objective', value)}
                />
                {form.objective ? (
                  <div className={`objective-info ${form.objective}`}>
                    {form.objective === 'leadgen' ? (
                      <span>
                        <b>Lead Generation</b>: Sales Reps will generate leads based on your{' '}
                        <b>customer profile</b>
                      </span>
                    ) : null}
                    {form.objective === 'qualify' ? (
                      <span>
                        <b>Lead Qualification</b>: Sales Reps will engage leads interest based on
                        your <b>guidelines & training</b>
                      </span>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="step-input">
              <div className={this.validClass('short_description')}>
                Short Description *{' '}
                <Infotip title="This is only for the search listing, and will be the first thing Sales Reps read before learning more or moving on." />
              </div>
              <div className="input-entry">
                <TextArea
                  value={form.short_description}
                  onChange={e => this.props.updateForm('short_description', e.target.value)}
                  size="large"
                  rows={3}
                  className="forms forms-textarea"
                  placeholder="Please enter a short campaign description..."
                />
              </div>
            </div>

            <div className="step-input">
              <div className={this.validClass('description')}>
                Campaign Details *{' '}
                <Infotip title="This will show up in the campaign preview popup when Sales Reps search for jobs." />
              </div>
              <div className="input-entry">
                <TextArea
                  value={form.description}
                  onChange={e => this.props.updateForm('description', e.target.value)}
                  size="large"
                  rows={10}
                  className="forms forms-textarea"
                  placeholder="Please enter a full campaign description..."
                />
              </div>
            </div>
          </div>
        </Card>
        <div className="step-tips">
          {actualTop ? (
            <div className="step-tip" style={{ position: 'absolute', top: actualTop }}>
              <div className="tip-title">
                <FontAwesomeIcon icon={faInfoCircle} />
                Campaign Type
              </div>
              <div className="tip-item">
                <b>Public</b> - Any rep can apply to this campaign
              </div>
              <div className="tip-item">
                <b>Private</b> - Closed to the public. Only reps you invite can work on this
                campaign
              </div>
            </div>
          ) : null}

          {actualCalendar && form.objective === 'qualify' ? (
            <div className="step-tip" style={{ position: 'absolute', top: actualCalendar }}>
              <div className="tip-title">
                <FontAwesomeIcon icon={faInfoCircle} />
                Calendar Invites
              </div>
              <div className="tip-item">
                As soon as an Sales Rep creates a Calendly event for a qualified lead, that lead
                will be paid and marked as complete.
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
