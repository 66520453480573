import React, { Component } from 'react';
import { Modal, Input, Button, message, Select } from 'antd';
import avatarpng from '_assets/img/avatar.png';
import Avatar from '_styleguide/Avatar/Avatar';
import { createOwnerMessage } from 'Mutations/Message/message';
import './MessageOwner.scss';

const { TextArea } = Input;

export default class MessageOwner extends Component {
  state = {
    visible: true,
    loading: false,
    campaignId: undefined,
    message: ''
  };
  createMessage = () => {
    this.setState({ loading: true });
    const campaign_id = this.props.campaign ? this.props.campaign.id : this.state.campaignId;
    if (!campaign_id) {
      message.error('Could not find company to message, try again');
      return;
    }
    this.props.client
      .mutate({
        variables: { body: this.state.message, campaign_id },
        mutation: createOwnerMessage
      })
      .then(result => {
        if (result && result.data && result.data.createOwnerMessage) {
          message.success('Message sent');
          if (this.props.reload) this.props.reload();
          this.hideModal();
        } else {
          throw new Error('Could not send message, try again');
        }
      })
      .catch(err => {
        console.log(err);
        message.error('Could not send message, try again');
        this.setState({ loading: false });
      });
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { campaignId } = this.state;
    const { campaign, campaigns } = this.props;
    const employee = campaign ? campaign.employee || {} : {};
    const company = campaign ? campaign.company || {} : {};

    const hasCampaign = campaign ? true : false;

    const employeeIds = [];
    let uniqueCampaigns = campaigns || [];
    if (!hasCampaign) {
      uniqueCampaigns = campaigns.filter(c => {
        const eId = c.employee.id;
        if (eId && !employeeIds.includes(eId)) {
          employeeIds.push(eId);
          return true;
        }
        return false;
      });
    }

    const currentId = campaign ? campaign.id : campaignId;

    return (
      <Modal
        wrapClassName="message-owner-modal sx-theme"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        style={{ top: 60 }}
        width={500}
        closable={false}
        maskClosable={true}
      >
        <div className="sx-form">
          {hasCampaign ? (
            <div className="mo-owner">
              <img className="employee" src={employee.picture || avatarpng} alt="" />
              {company.logo && <img className="c-logo" src={company.logo} alt="" />}
              <div className="mo-details">
                <h3>{employee.display_name}</h3>
                <p>
                  {employee.title} at {company.name}
                </p>
                <p>
                  Owner of <i>{campaign.name}</i>
                </p>
              </div>
            </div>
          ) : (
            <div className="select-campaign field">
              <label>Company</label>
              <Select
                dropdownClassName="sx-dropdown dropdown-owners"
                placeholder="Select a company"
                value={campaignId}
                onChange={val => this.setState({ campaignId: val })}
              >
                {uniqueCampaigns.map(c => (
                  <Select.Option key={'scm-' + c.id} value={c.id}>
                    <Avatar
                      size="mini"
                      type="company"
                      picture={c.employee.picture}
                      company={c.company.logo}
                    />
                    <span>
                      <b>{c.employee.display_name}</b> {c.company.name}
                    </span>
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}

          <div className="mo-message field">
            <label>Message</label>
            <TextArea
              value={this.state.message}
              onChange={e => this.setState({ message: e.target.value })}
              size="large"
              rows={6}
              className="forms forms-textarea"
              placeholder="Write your message here..."
            />
          </div>

          <div className="mo-actions">
            <Button className="btnx btnx-default" onClick={this.hideModal}>
              Cancel
            </Button>
            <Button
              className="btnx btnx-primary"
              onClick={this.createMessage}
              loading={this.state.loading}
              disabled={this.state.message.length <= 0 || !currentId}
            >
              Send Message
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}
