import React, { Component } from 'react';
import Button from '../Button/Button';
import TextArea from '../TextArea/TextArea';
import AutoComplete from '../AutoComplete/AutoComplete';
import './CheckboxList.scss';
import { IconCheck } from '@tabler/icons-react';

export default class CheckboxList extends Component {
  state = {
    valueSingle: '',
    valueMulti: new Set([]),
    searchValue: ''
  };

  componentDidMount() {
    if (this.props.multi) {
      this.setState({ valueMulti: new Set(this.props?.value ?? []) });
    } else {
      this.setState({ valueSingle: this.props?.value });
    }
  }

  getValue() {
    return this.props.multi ? Array.from(this.state.valueMulti) : this.state.valueSingle;
  }

  render() {
    const props = this.props;
    const {
      options = [],
      dark = false,
      multi = false,
      onChange,
      textareaKey,
      textareaValue,
      textareaPlaceholder,
      textareaLabel,
      hasSearch = false,
      searchPlaceholder = 'Search...'
    } = props;
    let className = 'seriph-checkbox-list';
    if (dark) className += ' dark';

    const { valueMulti, valueSingle } = this.state;

    /*
    let shownOptions =
      hasSearch && multi
        ? Array.from(this.state.valueMulti).map(o => {
            return {
              title: o,
              value: o
            };
          })
        : options;
       */

    return (
      <div className={className}>
        {hasSearch && multi ? (
          <AutoComplete
            dataSource={options.map(o => o.value)}
            placeholder={searchPlaceholder}
            value={this.state.searchValue}
            onChange={v => this.setState({ searchValue: v })}
            filterOption={(inputValue, option) =>
              option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            onSelect={v => {
              valueMulti.add(v);
              this.setState({ valueMulti, searchValue: '' }, () => {
                if (onChange) onChange(Array.from(this.state.valueMulti));
              });
            }}
          />
        ) : null}
        {options.map((option, i) => {
          let isChecked = multi ? valueMulti.has(option.value) : valueSingle === option.value;
          return (
            <React.Fragment key={i}>
              <Button
                size="large"
                selected={isChecked}
                onClick={() => {
                  if (multi) {
                    if (valueMulti.has(option.value)) {
                      valueMulti.delete(option.value);
                    } else {
                      valueMulti.add(option.value);
                    }
                    this.setState(valueMulti, () => {
                      if (onChange) onChange(Array.from(this.state.valueMulti));
                    });
                  } else {
                    this.setState({ valueSingle: option.value }, () => {
                      if (onChange) onChange(this.state.valueSingle);
                    });
                  }
                }}
              >
                <div className="left-div">{option?.title}</div>
                <div className="right-div">{isChecked ? <IconCheck /> : null}</div>
              </Button>

              {option.textarea && isChecked && (
                <React.Fragment>
                  {textareaLabel ? <p>{textareaLabel}</p> : null}
                  <TextArea
                    value={textareaValue}
                    onChange={e => onChange(e.target.value, textareaKey)}
                    size="large"
                    rows={6}
                    placeholder={textareaPlaceholder}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}
