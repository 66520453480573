import { importFields } from 'App/Company/Leads/Lead/formData';
import { LevenshteinDistance } from '_assets/js/helpers';
const formDataKeys = Object.keys(importFields);

export const deduplicateHeaders = arr => {
  let count = {};
  arr.forEach(function (header, headerIndex) {
    if (arr.indexOf(header) !== headerIndex) {
      count?.[header] ?? (count[header] = 0);
      let counter = count[header]++;
      let headerNumber = counter + 1;
      let headerKey = `${header} ${headerNumber}`;

      while (arr.indexOf(headerKey) !== -1) {
        headerKey = `${header} ${++headerNumber}`;
      }
      arr[headerIndex] = headerKey;
    }
  });
  return arr;
};


export const defaultMappings = csvKeys => {
  const mappings = {};
  csvKeys.forEach(csvKey => {
    mappings[csvKey] = null;
    formDataKeys.forEach(fdKey => {
      if (LevenshteinDistance(csvKey, fdKey) <= 2) mappings[csvKey] = fdKey;
    });
  });
  return mappings;
};


export const getMappingLegend = rawMappings => {
  /* Create list of every previous mapping */
  let mappingList = [];
  (rawMappings || []).forEach(m => {
    try {
      let mapArray = JSON.parse(m.mappings);
      if (!Array.isArray(mapArray)) mapArray = [];
      mappingList = [...mappingList, ...mapArray];
    } catch (e) {/* do nothing */ }
  });

  /* Create unique key legend of all previous mappings - newest only */
  const mappingLegend = {};
  mappingList.forEach(m => mappingLegend[m.csvKey] = m);

  return mappingLegend || {};
};

export const historicalMappings = (legend, data) => {
  const newData = data.map(d => {
    return legend[d.csvKey] ? {...legend[d.csvKey]} : d;
  });
  return newData;
};