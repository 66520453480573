import React, { Component } from 'react';
import {
  AreaChart,
  Area,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { numberWithCommas } from '_assets/js/helpers';
import { Card, Header } from '_seriph';
import './QualifyCharts.scss';

export default class QualifyCharts extends Component {
  custom = data => {
    const payload = data.payload || [];
    return (
      <div className="sellx-tooltip">
        {payload.map((p, i) => {
          return (
            <div key={`tip-${i}`} className="tip-box">
              <div className="tip-title">{p.payload.name}</div>
              <div className="tip-contents">
                <div className="tip-item">
                  <div className="tip-name">Amount</div>
                  <div className="tip-value">{numberWithCommas(p.value)}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  emailsCustom = data => {
    const payload = data.payload || [];
    return (
      <div className="sellx-tooltip">
        <div className="tip-box">
          <div className="tip-title">{data.label}</div>
          <div className="tip-contents">
            {payload.map((p, i) => (
              <div key={`tip-${i}`} className="tip-item">
                <div className="tip-circle" style={{ backgroundColor: p.stroke }} />
                <div className="tip-name">{p.name}</div>
                <div className="tip-value">{numberWithCommas(p.value) || '0'}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  render() {
    const { totals, meetings, emails, calls } = this.props;

    const interval = meetings.length > 4 ? Math.ceil(meetings.length / 4) : 0;
    const showMonth = meetings.length > 90;

    return (
      <Card id="company-qualify-charts">
        <div className="three-chart">
          <Header size="4" font="sans">
            Meetings Scheduled
          </Header>
          <h2>{totals.meetings || 0}</h2>
          <ResponsiveContainer height={150} width="99%">
            <AreaChart data={meetings} margin={{ right: 32 }}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#D2D0FB" stopOpacity={1} />
                  <stop offset="100%" stopColor="#D2D0FB" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid stroke="#E6E9EF" fill="rgba(0,0,0,0)" strokeDasharray="3 3" />
              <XAxis
                interval={interval}
                tickLine={false}
                axisLine={false}
                dataKey={showMonth ? 'month' : 'name'}
                type="category"
                allowDuplicatedCategory={showMonth ? true : false}
              />
              <YAxis
                tickLine={false}
                axisLine={false}
                dataKey="Amount"
                domain={[0, 'dataMax + 2']}
                allowDecimals={false}
              />
              <Tooltip content={this.custom} />
              <Area
                type="linear"
                dataKey="Amount"
                stroke="#3E3993"
                fill="url(#colorUv)"
                strokeWidth="3"
                dot={false}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <div className="spacer" />
        <div className="three-chart">
          <Header size="4" font="sans">
            Emails
          </Header>
          <div className="pie-chart">
            <PieChart width={172} height={172}>
              <Pie
                data={emails}
                cx={86}
                cy={86}
                innerRadius={40}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={2}
                dataKey="value"
              >
                {emails.map((s, i) => (
                  <Cell key={`cell-${i}`} fill={s.fill} />
                ))}
              </Pie>
            </PieChart>
            <div className="pie-stats">
              <h5>
                <span style={{ background: '#3E3993' }} />
                Sent
              </h5>
              <h4>{numberWithCommas(emails[0].value)}</h4>
              <h5>
                <span style={{ background: '#C1DDBB' }} />
                Opened
              </h5>
              <h4>{numberWithCommas(emails[1].value)}</h4>
            </div>
          </div>
        </div>
        <div className="spacer" />
        <div className="three-chart">
          <Header size="4" font="sans">
            Calls made
          </Header>
          <h2>{totals.called || 0}</h2>
          <ResponsiveContainer height={150} width="99%">
            <AreaChart data={calls} margin={{ right: 32 }}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#D2D0FB" stopOpacity={1} />
                  <stop offset="100%" stopColor="#D2D0FB" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid stroke="#E6E9EF" fill="rgba(0,0,0,0)" strokeDasharray="3 3" />
              <XAxis
                interval={interval}
                tickLine={false}
                axisLine={false}
                dataKey={showMonth ? 'month' : 'name'}
                type="category"
                allowDuplicatedCategory={showMonth ? true : false}
              />
              <YAxis
                tickLine={false}
                axisLine={false}
                dataKey="Amount"
                domain={[0, 'dataMax + 2']}
                allowDecimals={false}
              />
              <Tooltip content={this.custom} />
              <Area
                type="linear"
                dataKey="Amount"
                stroke="#090E15"
                fill="url(#colorUv)"
                strokeWidth="3"
                dot={false}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </Card>
    );
  }
}
