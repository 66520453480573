import React from 'react';
import { flatMoney, numberWithCommas } from '_assets/js/helpers';

export const getQualEstimate = (campaign, importAmount) => {
  const budget = campaign.budget_ppql || 0;
  const leadCount = importAmount || 0;

  /* HANDLE INBOUND AND OUTBOUND (1-3% Inbound - 10-15% Outbound) */
  let conversionRate = 0.0075,
    highRate = 0.03;
  if (campaign.objective_type === 'inbound') {
    conversionRate = 0.04;
    highRate = 0.1;
  }

  const lowQualCount = Math.floor(leadCount * conversionRate);
  const highQualCount = Math.floor(leadCount * highRate);

  let lowCost = lowQualCount * budget + (leadCount - lowQualCount) * 2;
  let highCost = highQualCount * budget + (leadCount - highQualCount) * 2;

  /* ROUND COSTS DOWN */
  lowCost = Math.floor(lowCost / 100) * 100;
  highCost = Math.floor(highCost / 100) * 100;

  return flatMoney(lowCost) + ' - ' + flatMoney(highCost);
};

export const durationText = (campaign, importAmount) => {
  const isGen = campaign.objective === 'leadgen' ? true : false;
  const leadCount = campaign.threshold_settings.lead_count || 0;
  const duration = campaign.threshold_settings.duration;

  const genEstimate = isGen ? campaign.budget_ppl * leadCount : 0;
  const leadGenLifetime = (
    <p>
      Leads generated may vary but will not exceed <b>{numberWithCommas(leadCount)} leads</b> for
      the <b>lifetime</b> of this campaign - (Est. up to <b>{flatMoney(genEstimate)}</b>). Campaign
      will end once <b>{numberWithCommas(leadCount)} leads</b> have been generated.
    </p>
  );
  const leadGenWeekly = (
    <p>
      Leads generated may vary but will not exceed <b>{numberWithCommas(leadCount)} leads weekly</b>{' '}
      for this campaign - (Est. up to <b>{flatMoney(genEstimate)} / week</b>). Campaign will
      continue <b>weekly</b> until you decide to pause or end the campaign.
    </p>
  );
  const genStoppers = !campaign.budget_ppl || !leadCount;

  const qualEstimate = !isGen ? getQualEstimate(campaign, importAmount) : 0;
  const leadQualLifetime = (
    <p>
      Leads qualified may vary but will not exceed <b>{numberWithCommas(importAmount)} leads</b> for
      the <b>lifetime</b> of this campaign - (Est. cost of <b>{qualEstimate}</b>). Campaign will end
      once the total of <b>{numberWithCommas(importAmount)} leads</b> have been outreached.
    </p>
  );
  const leadQualContinuous = (
    <p>
      Leads qualified may vary but will start with <b>{numberWithCommas(importAmount)} leads</b>,
      but leads can be added at any time (Est. cost of{' '}
      <b>
        {qualEstimate} / per {numberWithCommas(importAmount)} leads
      </b>
      ). Campaign will be <b>continuous</b> as long as leads are added to the campaign.
    </p>
  );
  const qualStoppers = !campaign.budget_ppql || !importAmount;

  if (isGen && duration === 'lifetime' && !genStoppers) {
    return leadGenLifetime;
  } else if (isGen && duration === 'weekly' && !genStoppers) {
    return leadGenWeekly;
  } else if (!isGen && duration === 'lifetime' && !qualStoppers) {
    return leadQualLifetime;
  } else if (!isGen && duration === 'continuous' && !qualStoppers) {
    return leadQualContinuous;
  } else {
    return (
      <p>Make sure all of the fields above are filled out to see a proper campaign estimate</p>
    );
  }
};
