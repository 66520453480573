import React, { Component } from 'react';
import companyLogo from '_assets/img/company.png';
import { Card, Header, Toggle } from '_seriph';
import './EmailSignature.scss';

class EmailSignature extends Component {
  state = {
    realData: true
  };
  actualData = () => ({
    rep_first_name: 'John',
    rep_last_name: 'Smith',
    rep_phone: '(555) 555-555',
    company_name: this.props.myself.company_name,
    company_website: this.props.company ? this.props.company.website : 'https://sellx.com',
    company_logo: this.props.myself.company_logo
  });
  render() {
    const { realData } = this.state;

    const actualData = this.actualData();

    const example = (
      <div className="sxd-signature">
        ---
        <div style={{ fontWeight: '500', fontSize: '14px', lineHeight: '21px', color: '#000' }}>
          {realData
            ? actualData.rep_first_name + ' ' + actualData.rep_last_name
            : '{rep.first_name} {rep.last_name}'}
        </div>
        <div style={{ fontWeight: '500', fontSize: '12px', lineHeight: '21px', color: '#707B90' }}>
          Sales Representative at {realData ? actualData.company_name : '{company.name}'}
        </div>
        <a
          href="tel:+15555555555"
          style={{ fontWeight: '500', fontSize: '12px', lineHeight: '18px', color: '#4353FF' }}
        >
          {realData ? actualData.rep_phone : '{rep.phone_number}'}
        </a>
        <br />
        <a
          href={realData ? actualData.company_website : 'https://sellx.com'}
          style={{ fontWeight: '500', fontSize: '12px', lineHeight: '18px', color: '#4353FF' }}
        >
          {realData ? actualData.company_website : '{company.website}'}
        </a>
        <br />
        <a href={realData ? actualData.company_website : 'https://sellx.com'}>
          <img
            src={realData ? actualData.company_logo : companyLogo}
            style={{ width: '64px', height: '64px', borderRadius: '32px', marginTop: '16px' }}
            alt=""
          />
        </a>
      </div>
    );

    return (
      <Card id="company-settings-signature">
        <div className="settings-card">
          <Header font="sans" size="2" className="settings-header">
            Personal Info
          </Header>
          <div className="settings-subtitle">
            Stay on brand with custom email signatures. These will appear on all emails that sales
            reps send to leads.
          </div>

          <div className="signature">
            <div className="signature-demo">{example}</div>
            <div className="signature-footer">
              <Toggle
                checked={realData}
                size="small"
                onChange={checked => this.setState({ realData: checked })}
              />
              <h4>Show with real data</h4>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

export default EmailSignature;
