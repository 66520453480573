import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Slider } from 'antd';
import { connect } from 'react-redux';
import { flatMoney, numberWithCommas } from '_assets/js/helpers';
import {
  experienceTypes,
  startInboundTypes,
  startOutboundTypes,
  calculateValue
} from '../../_helpers/budget';
import { getImports } from '_graphql/queries/campaign';
import Infotip from '_shared/Infotip/Infotip';
import { Locked } from '_shared/Locked/Locked';
import Guide from '_styleguide/Guide/Guide';
import { Card, Loading, Input, InputNumber, Select } from '_seriph';

import './Budget.scss';

class Budget extends Component {
  state = {
    importAmount: 0,
    loadingImports: true
  };
  componentDidMount = () => {
    const { campaign } = this.props;
    if (this.props.save) this.props.save();
    this.loadImports();
    const hasGenPPL = campaign.objective === 'leadgen' && campaign.budget_ppl;
    const hasQualPPL = campaign.objective === 'qualify' && campaign.budget_ppql;
    const isInbound = campaign.objective_type === 'inbound';
    if (!campaign.budget_total || !hasGenPPL || !hasQualPPL || !campaign.target_experience_level) {
      const experience = campaign.target_experience_level || 'any';
      this.props.updateMultiple({
        budget_total: campaign.budget_total || (campaign.objective === 'qualify' ? 3000 : 1500),
        budget_ppl: campaign.budget_ppl || 3,
        budget_ppql:
          campaign.budget_ppql ||
          (isInbound ? startInboundTypes[experience] : startOutboundTypes[experience]),
        target_experience_level: experience
      });
    }
  };
  loadImports = () => {
    this.props.client
      .query({ variables: { campaign_id: this.props.campaignId }, query: getImports })
      .then(result => {
        if (result && result.data && result.data.imports) {
          const imports = result.data.imports || [];
          if (imports && imports.length > 0) {
            this.setState({
              importAmount: parseInt(imports[0].amount || 0),
              loadingImports: false
            });
          } else {
            this.setState({ loadingImports: false });
          }
        }
      });
  };
  onSlide = (val, maxTarget) => {
    const isGen = this.props.campaign.objective === 'qualify' ? false : true;
    const payPerLead = isGen ? this.props.campaign.budget_ppl : this.props.campaign.budget_ppql;
    const targetAgentCount = this.props.campaign.target_agent_count || 1;
    let estLeads = 0;
    if (isGen) {
      estLeads = Math.floor((val || 0) / (payPerLead || 1));
    } else {
      estLeads = this.state.importAmount;
    }
    const newTarget = Math.floor((estLeads || 0) / 600) + 1;

    if (newTarget > maxTarget && maxTarget > targetAgentCount) {
      this.props.updateMultiple({ target_agent_count: maxTarget, budget_total: val });
    } else {
      this.props.update('budget_total', val);
    }
  };
  onChange = val => {
    const isGen = this.props.campaign.objective === 'qualify' ? false : true;
    this.props.update(isGen ? 'budget_ppl' : 'budget_ppql', val);
  };
  render() {
    const { campaign, update, actions, isLocked } = this.props;
    const { importAmount } = this.state;

    const isGen = campaign.objective === 'qualify' ? false : true;
    const min = 0,
      max = 30000,
      step = 50;
    const inputValue = campaign.budget_total || min;
    const payPerLead = isGen ? campaign.budget_ppl : campaign.budget_ppql;
    const prefix = isGen ? { prefix: '~' } : {};
    let estLeads = 0;
    if (isGen) {
      estLeads = Math.floor((campaign.budget_total || 0) / (payPerLead || 1));
    } else {
      estLeads = importAmount;
    }

    const maxTarget = Math.floor((estLeads || 0) / 600) + 1;
    const calc = calculateValue(campaign, importAmount);

    const badRepCount =
      'We recommend more sales reps to help complete your campaign in a timely manner.';
    const badPayHigh =
      'You may be paying too much per lead, we recommend lowering pay per lead or increasing the rep experience level.';
    let badPayLow =
      'It seems your pay per lead is a little low, please consider raising pay per lead or lowering rep experience level.';
    const badBudgetLow =
      'Your budget may be a bit too low to accomplish qualifying all ' +
      estLeads +
      ' leads within this campaign.';
    const badBudgetHigh =
      'Your budget may be a bit too high for qualifying ' + estLeads + ' leads.';

    if (campaign.objective === 'qualify' && campaign.objective_type === 'outbound') {
      badPayLow +=
        ' Outbound leads are more difficult to qualify (1-3%) so require a higher pay per lead than inbound leads (10-15%).';
    }

    return (
      <Card id="wizard-budget" className="wz-card">
        <div className="wz-container sx-form">
          {/* HEADER */}
          <div className="wz-card-header">
            <h3>What is your budget for this campaign?</h3>
            <h5>Start with your budget and we’ll take care of the rest.</h5>
          </div>

          {/* CONTENT */}
          {this.state.loadingImports ? (
            <Loading />
          ) : (
            <React.Fragment>
              <div className="budget-number">
                <div className={calc.cause.includes('budget_total') ? 'val invalid' : 'val'}>
                  {flatMoney(inputValue)}
                </div>
                {!isLocked ? (
                  <Slider
                    className={calc.cause.includes('budget_total') ? 'invalid' : ''}
                    min={min}
                    max={max}
                    step={step}
                    tooltipVisible={false}
                    onChange={val => this.onSlide(val, maxTarget)}
                    value={typeof inputValue === 'number' ? inputValue : 0}
                  />
                ) : null}
              </div>

              <div className="input-box">
                <div className="field">
                  <label>
                    Total sales reps (max {maxTarget})
                    {isLocked ? (
                      <Locked isLocked={isLocked} />
                    ) : (
                      <Infotip title="The amount of sales reps you want to work on the campaign, we recommend a maximum based on the amount of leads." />
                    )}
                  </label>
                  <InputNumber
                    disabled={isLocked}
                    className={calc.cause.includes('target_agent_count') ? 'invalid' : ''}
                    max={maxTarget}
                    min={1}
                    type="number"
                    onChange={val => update('target_agent_count', val)}
                    value={campaign.target_agent_count}
                  />
                </div>
                <div className="field">
                  <label>
                    Pay per {campaign.objective === 'qualify' ? 'qualified' : 'generated'} lead
                    {isLocked ? <Locked isLocked={isLocked} /> : null}
                    {isGen && !isLocked ? (
                      <Infotip title="This the the amount you are willing to pay per generated lead." />
                    ) : null}
                    {!isGen && !isLocked ? (
                      <Infotip title="The amount you pay for a qualified lead. If a lead is not-interested, invalid, etc. we charge a standard fee of $2 for the work." />
                    ) : null}
                  </label>
                  <Input
                    disabled={isLocked}
                    className={calc.cause.includes('payPerLead') ? 'invalid' : ''}
                    type="number"
                    prefix="$"
                    onChange={e => this.onChange(parseFloat(e.target.value))}
                    value={payPerLead || ''}
                  />
                </div>
              </div>

              <div className="input-box">
                <div className="field">
                  <label>
                    {isGen ? 'Total estimated leads' : 'Number of leads to qualify'}
                    {isLocked ? <Locked isLocked={isLocked} /> : null}
                    {isGen && !isLocked ? (
                      <Infotip title="This the the amount of leads you can generate based on your budget and how much you pay per lead" />
                    ) : null}
                    {!isGen && !isLocked ? (
                      <Infotip title="Amount of leads you are importing to be qualified, you can adjust this from the Import tab" />
                    ) : null}
                  </label>
                  <Input {...prefix} disabled={true} value={numberWithCommas(estLeads)} />
                </div>
                <div className="field">
                  <label>
                    Rep experience
                    {isLocked ? (
                      <Locked isLocked={isLocked} />
                    ) : (
                      <Infotip title="The experience you are looking for from a sales rep. More experience usually requires higher pay per lead." />
                    )}
                  </label>
                  <Select
                    disabled={isLocked}
                    className={
                      calc.cause.includes('target_experience_level') ? 'forms invalid' : 'forms'
                    }
                    size="large"
                    value={campaign.target_experience_level}
                    onChange={val => update('target_experience_level', val)}
                  >
                    {experienceTypes.map((d, i) => (
                      <Select.Option key={'et-' + i} value={d.value}>
                        {d.title} {d.value !== 'any' ? `(${d.description})` : null}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>

              {!isLocked ? (
                <div>
                  {calc.result === 'good' ? (
                    <React.Fragment>
                      <Guide
                        type="info"
                        dismissable={false}
                        id="budget-good"
                        header="Budget looks good!"
                        message="Based on your campaign budget, these numbers ensure a great value for reps, with a maximum outcome."
                      />
                      {!isGen && (
                        <Guide
                          type="budget-bad"
                          dismissable={false}
                          id="budget-reminder"
                          header="Reminder"
                          message="If a lead is not-interested, invalid, etc. we charge a standard fee of $2 for the work."
                        />
                      )}
                    </React.Fragment>
                  ) : null}
                  {calc.result === 'bad' && calc.cause.includes('target_agent_count') ? (
                    <Guide
                      type="budget-bad"
                      dismissable={false}
                      id="budget-reps"
                      header="Low rep count"
                      message={badRepCount}
                    />
                  ) : null}
                  {calc.result === 'bad' &&
                  calc.cause.includes('payPerLead') &&
                  calc.resultReason === 'low' ? (
                    <Guide
                      type="budget-bad"
                      dismissable={false}
                      id="budget-pay-low"
                      header="Pay per lead too low"
                      message={badPayLow}
                    />
                  ) : null}
                  {calc.result === 'bad' &&
                  calc.cause.includes('payPerLead') &&
                  calc.resultReason === 'high' ? (
                    <Guide
                      type="budget-bad"
                      dismissable={false}
                      id="budget-pay-high"
                      header="Pay per lead too high"
                      message={badPayHigh}
                    />
                  ) : null}
                  {calc.result === 'bad' &&
                  calc.cause.includes('budget_total') &&
                  calc.budget === 'low' ? (
                    <Guide
                      type="budget-bad"
                      dismissable={false}
                      id="budget-budget-low"
                      header="Budget too low"
                      message={badBudgetLow}
                    />
                  ) : null}
                  {calc.result === 'bad' &&
                  calc.cause.includes('budget_total') &&
                  calc.budget === 'high' ? (
                    <Guide
                      type="budget-bad"
                      dismissable={false}
                      id="budget-budget-high"
                      header="Budget too high"
                      message={badBudgetHigh}
                    />
                  ) : null}
                </div>
              ) : null}
            </React.Fragment>
          )}
        </div>

        {/* ACTIONS */}
        {actions}
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(Budget));
