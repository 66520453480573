import gql from 'graphql-tag';

export const createMessage = gql`
  mutation createMessage($body: String!, $agent_id: ID!, $employee_id: ID!) {
    createMessage(body: $body, agent_id: $agent_id, employee_id: $employee_id)
  }
`;

export const createOwnerMessage = gql`
  mutation createOwnerMessage($body: String!, $campaign_id: ID!) {
    createOwnerMessage(body: $body, campaign_id: $campaign_id)
  }
`;

export const createChat = gql`
  mutation createChat($body: String!, $message_id: ID!) {
    createChat(body: $body, message_id: $message_id)
  }
`;

export const readMessage = gql`
  mutation readMessage($message_id: ID!) {
    readMessage(message_id: $message_id)
  }
`;

// export const addToConversation = gql`
//   mutation addToConversation(
//     $body: String!,
//     $conversation: ConversationFirebaseType!,
//   ){
//     addToConversation(
//       body: $body,
//       conversation: $conversation,
//     )
//   }
// `;

// export const deleteConversation = gql`
//   mutation deleteConversation(
//     $id: String,
//   ){
//     deleteConversation(
//       id: $id,
//     ){ id }
//   }
// `;
