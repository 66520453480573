export const generateData = data => {
  const { touchpoints, campaign } = data;

  const chartData = {
    touchpoints: {
      call: {},
      email: {},
      linkedin: {},
      instructions: {}
    },
    stats: { ...campaign.stats }
  };

  touchpoints.forEach(t => {
    Object.keys(t).forEach(k => {
      Object.keys(t[k]).forEach(f => {
        chartData.touchpoints[k][f] =
          chartData.touchpoints[k][f] !== undefined
            ? chartData.touchpoints[k][f] + t[k][f]
            : t[k][f];
      });
    });
  });

  return chartData;
};
