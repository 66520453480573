import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import PageNotFound from '_assets/img/page-not-found.svg';
import './Missing.scss';

const { Content } = Layout;

class MissingAgent extends Component {
  render() {
    return (
      <Layout id="sellx-missing" className={this.props.className || ''}>
        <Content>
          <div className="forgot-body">
            <img src={PageNotFound} alt="" />
            <div className="descriptor-wrap">
              <p>The page you were looking for, may be broken or has been removed.</p>
            </div>
          </div>
        </Content>
      </Layout>
    );
  }
}

export default withRouter(MissingAgent);
