import React, { Component } from 'react';
import { Select } from 'antd';
import './SelectMultipleSimple.scss';

const { Option } = Select;

export default class SelectMultipleSimple extends Component {
  state = {
    term: ''
  };
  onSearch = term => this.setState({ term });
  onChange = value => {
    this.props.onChange(value);
  };

  render() {
    let children = this.props.autocomplete || [];
    children = children.map(item => <Option key={item}>{item}</Option>);

    return (
      <Select
        className={`seriph-select-multiple-simple`}
        dropdownClassName="seriph-dropdown"
        disabled={this.props.disabled}
        mode="multiple"
        allowClear
        style={{ width: '100%' }}
        placeholder={this.props.placeholder}
        value={this.props.value || undefined}
        onChange={this.onChange}
      >
        {children}
      </Select>
    );
  }
}
