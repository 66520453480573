import React, { Component } from 'react';
import { Progress } from 'antd';
import './ProgressCircle.scss';

export default class ProgressCircle extends Component {
  render() {
    return (
      <Progress 
        className="seriph-progress-circle" 
        type="circle"
        width={40}
        strokeWidth={15}
        strokeColor="#9E9BD8"
        strokeLinecap="square"
        {...this.props} 
      />
    );
  }
}
