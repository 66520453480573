import React from 'react';
import { Avatar, Menu, Tag } from '_seriph';
import moment from 'moment';
import { Dropdown } from 'antd';
import { IconDots } from '@tabler/icons-react';

export const getRepColumns = (company, user, confirmRemoveMember, reinstateTeamRep) => {
  return [
    {
      title: 'Name',
      dataIndex: 'display_name',
      key: 'display_name',
      render: (column, data) => {
        return (
          <div className="emp-info">
            <Avatar type="company" picture={data.picture} size="small" />
            <div>
              <h4>{column}</h4>
              <p className="joined-date">Joined {moment(data?.createdAt * 1).fromNow()}</p>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Email',
      dataIndex: 'user.email',
      key: 'user.email'
    },
    {
      title: '',
      dataIndex: 'user.type',
      key: 'user.type',
      render: (column, data) => {
        const isOwner = data?.company?.role === 'owner';
        if (isOwner) return <Tag type="info">Owner</Tag>;
        return data.removed ? <Tag type="danger">Removed</Tag> : <Tag type="primary">Active</Tag>;
      }
    },    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 40,
      align: 'right',
      render: (id, data) => {
        const menu = (
          <Menu>
            {!data.removed ? (
              <Menu.Item onClick={() => confirmRemoveMember(data)} className="danger">
                Revoke account
              </Menu.Item>
            ) : null}
            {data.removed ? (
              <Menu.Item onClick={() => reinstateTeamRep(data)} className="success">
                Reinstate account
              </Menu.Item>
            ) : null}
          </Menu>
        );
        return (
          <Dropdown
            overlayClassName="seriph-dropdown"
            overlay={menu}
            trigger={['click']}
            placement="bottomRight"
          >
            <div className="tbl-btn">
              <IconDots size={18} stroke={2} />
            </div>
          </Dropdown>
        );
      }
    }
  ];
};
