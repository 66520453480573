export const qualifyWarm = {
  any: 25,
  entry: 25,
  intermediate: 25,
  expert: 25
};

export const qualifyCold = {
  any: 25,
  entry: 25,
  intermediate: 25,
  expert: 25
};

export const generation = {
  any: 1,
  entry: 1,
  intermediate: 1,
  expert: 1
};

export const calculatePayMin = campaign => {
  /* NECESSARY VARIABLES */
  const isGen = campaign.objective === 'leadgen';
  const isWarm = campaign.objective === 'qualify' && campaign.objective_type === 'inbound';
  const isCold = campaign.objective === 'qualify' && campaign.objective_type !== 'inbound';

  const expLevel = campaign.target_experience_level || 'any';

  if (isGen) {
    return generation[expLevel] || generation['any'];
  } else if (isWarm) {
    return qualifyWarm[expLevel] || qualifyWarm['any'];
  } else if (isCold) {
    return qualifyCold[expLevel] || qualifyCold['any'];
  }

  return isGen ? 3.0 : 500;
};
