import React, { Component } from 'react';
import Card from '_shared/Card/Card';
import { Select } from 'antd';
import Selection from '_forms/Selection/Selection';
import Multiple from '_forms/Multiple/Multiple';
import MultipleLocations from '_forms/MultipleLocations/MultipleLocations';
import { Locked } from '../Components/Locked/Locked';
import { SKILLS } from '_assets/js/skills';
import { LANGUAGES } from '_assets/js/languages';
import { AREA_CODES } from '_constants/areaCodes';
const { Option } = Select;

export default class AgentTargeting extends Component {
  validClass = field => {
    const { validation, showValidation } = this.props;
    return !validation[field] && showValidation ? 'input-label invalid' : 'input-label';
  };
  calculateGeneratedAmount = () => {
    const form = this.props.form;
    const budget = form.budget_ppl && form.budget_ppl > 0 ? form.budget_ppl : null;
    const total = form.budget_total && form.budget_total > 0 ? form.budget_total : null;
    return budget && total ? Math.floor(total / budget) : null;
  };
  render() {
    const form = this.props.form;
    const leadCount = this.props.leadCount;
    const isLocked = this.props.isLocked;
    const agentLimit = 100;

    /* Setup lead estimates */
    let sentence = null;
    if (form.objective === 'leadgen') {
      const estimate = this.calculateGeneratedAmount();
      if (!estimate) {
        sentence = (
          <i>
            Fill out the <b>Budget</b> section at the bottom to see an estimate amount of leads per
            agent
          </i>
        );
      } else {
        const perLead = Math.floor(estimate / form.target_agent_count) || 0;
        sentence = (
          <span>
            Based on your reps needed and budget, the approximate leads generated is{' '}
            <b>
              {perLead} lead{perLead !== 1 && 's'}
            </b>{' '}
            / per agent.
          </span>
        );
      }
    } else if (form.objective === 'qualify') {
      if (leadCount <= 0) {
        sentence = <i>Please import leads above to get a proper estimate</i>;
      } else {
        const perLead = Math.floor(leadCount / form.target_agent_count) || 0;
        sentence = (
          <span>
            Based on your reps needed and imported leads ({leadCount}),{' '}
            <b>
              {perLead} lead{perLead !== 1 && 's'}
            </b>{' '}
            will be assigned to each agent when they join. The campaign will rebalance each time a
            lead is completed or agents join/leave.
          </span>
        );
      }
    }

    return (
      <div className="step-container" id="at-container">
        <Card id={this.props.id} className="step-card" padding="40px 20px 25px 20px">
          <div className="step-title">
            <h3 className={this.validClass('agent_targeting')}>Sales Rep Criteria</h3>
            <p>
              Find the right agents for your campaign by matching your campaign needs to an
              agent&apos;s experience and skills.
            </p>
          </div>
          <div className="step-form">
            <div className="step-input">
              <div className={this.validClass('target_agent_count')}>
                <Locked isLocked={isLocked} />
                Sales Reps Needed
              </div>
              <div className="input-entry">
                <Select
                  placeholder="How many agents do you need?"
                  className="forms"
                  style={{ width: '100%' }}
                  size="large"
                  value={form.target_agent_count}
                  disabled={isLocked}
                  onChange={value => this.props.updateForm('target_agent_count', value)}
                >
                  {[...Array(agentLimit)].map((e, i) => (
                    <Option value={i + 1} key={'at' + i + 1}>
                      {i + 1}
                      {i + 1 === agentLimit ? ' (Limit)' : null}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="input-info">{sentence}</div>
            </div>

            {form.objective === 'qualify' ? (
              <div className="step-input">
                <div className={this.validClass('target_area_code')}>
                  <Locked isLocked={isLocked} />
                  Phone Area Code
                </div>
                <div className="input-entry">
                  <Select
                    showSearch
                    placeholder="Select a phone number area code"
                    className="forms"
                    style={{ width: '100%' }}
                    size="large"
                    value={form.target_area_code || undefined}
                    onChange={value => this.props.updateForm('target_area_code', value)}
                  >
                    <Option value="any">Any</Option>
                    {AREA_CODES.map((e, i) => (
                      <Option value={e.code} key={'area' + i + 1}>
                        {e.code} ({e.state})
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="input-info">
                  This let&apos;s you select which area code you want your rep phone numbers to have
                  when they possibly call leads. If no preference, set to <i>Any</i>
                </div>
              </div>
            ) : null}

            <div className="step-input">
              <div className={this.validClass('target_experience_level')}>Experience Level</div>
              <div className="input-entry">
                <Selection
                  values={[
                    { title: 'Any level', value: 'any' },
                    { title: 'Pro', description: '1 - 2 years', value: 'entry' },
                    { title: 'Expert', description: '3 - 5 years', value: 'intermediate' },
                    { title: 'Specialist', description: '6+ years', value: 'expert' }
                  ]}
                  value={form.target_experience_level}
                  onChange={value => this.props.updateForm('target_experience_level', value)}
                />
              </div>
            </div>

            <div className="step-input">
              <div className={this.validClass('target_availability')}>Target Availability</div>
              <div className="input-entry">
                <Selection
                  values={[
                    { title: 'Full time', description: '40+ hrs/week', value: 'full' },
                    { title: 'Part time', description: 'up to 25 hrs/week', value: 'part' },
                    { title: 'Free time only', value: 'free' }
                  ]}
                  value={form.target_availability}
                  onChange={value => this.props.updateForm('target_availability', value)}
                />
              </div>
            </div>

            <div className="step-input">
              <div className={this.validClass('target_locations')}>Location(s)</div>
              <div className="input-entry">
                <MultipleLocations
                  allLocations
                  placeholder="Search locations..."
                  notFoundContent="Type to search locations..."
                  autocomplete={LANGUAGES}
                  value={form.target_locations}
                  onChange={value => this.props.updateForm('target_locations', value)}
                />
              </div>
            </div>

            <div className="step-input">
              <div className={this.validClass('target_skills')}>Skills</div>
              <div className="input-entry">
                <Multiple
                  placeholder="Search skills..."
                  notFoundContent="Type to search skills..."
                  autocomplete={SKILLS}
                  value={form.target_skills}
                  onChange={value => this.props.updateForm('target_skills', value)}
                />
              </div>
            </div>

            <div className="step-input">
              <div className={this.validClass('target_languages')}>Languages</div>
              <div className="input-entry">
                <Multiple
                  placeholder="Search languages..."
                  notFoundContent="Type to search languages..."
                  autocomplete={LANGUAGES}
                  value={form.target_languages}
                  onChange={value => this.props.updateForm('target_languages', value)}
                />
              </div>
            </div>
          </div>
        </Card>
        <div className="step-tips">&nbsp;</div>
      </div>
    );
  }
}
