import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Locked } from '_shared/Locked/Locked';
import { Card, Button, Input, Select, Radio, Checkbox, Popconfirm } from '_seriph';
import { IconTrash } from '@tabler/icons-react';
import './Questions.scss';

const PlaceHolders = index => {
  const options = ['Response 1', 'Response 2'];
  if (options[index]) {
    return options[index];
  } else {
    return `Response ${index + 1}`;
  }
};

class Questions extends Component {
  componentDidMount = () => {
    if (this.props.save) this.props.save();
    // if (this.props.campaign.questions && this.props.campaign.questions.length <= 0) {
    //   this.addQuestion('Checkbox');
    // }
  };
  addQuestion = type => {
    const questions = this.props.campaign.questions ? [...this.props.campaign.questions] : [];
    questions.push({ type: type ? type : 'Text', question: '', options: [] });
    this.props.update('questions', questions);
  };
  removeQuestion = ndx => {
    const questions = this.props.campaign.questions ? [...this.props.campaign.questions] : [];
    questions.splice(ndx, 1);
    this.props.update('questions', questions);
  };
  updateValues = (ndx, field, value) => {
    const questions = this.props.campaign.questions ? [...this.props.campaign.questions] : [];
    questions[ndx][field] = value;
    this.props.update('questions', questions);
  };
  addOption = ndx => {
    const questions = this.props.campaign.questions ? [...this.props.campaign.questions] : [];
    questions[ndx].options.push('');
    this.props.update('questions', questions);
  };
  updateOption = (qNdx, oNdx, value) => {
    const questions = this.props.campaign.questions ? [...this.props.campaign.questions] : [];
    questions[qNdx].options[oNdx] = value;
    this.props.update('questions', questions);
  };
  removeOption = (qNdx, oNdx) => {
    const questions = this.props.campaign.questions ? [...this.props.campaign.questions] : [];
    questions[qNdx].options.splice(oNdx, 1);
    this.props.update('questions', questions);
  };
  render() {
    const { campaign, actions, isLocked } = this.props;
    const questions = campaign.questions || [];

    const hasQuestions = questions.length > 0;

    return (
      <Card id="wizard-questions" className="wz-card">
        <div className="wz-container sx-form">
          {/* HEADER */}
          <div className="wz-card-header">
            <h3>
              Get answers from leads <span className="optional">(Optional)</span>
              <Locked isLocked={isLocked} />
            </h3>
            <h5>Sales reps will use during conversation to learn more about leads.</h5>
          </div>

          {/* CONTENT */}
          <div className="step-form">
            {questions.map((q, i) => {
              return (
                <Card className="quest step-input" key={'quest-' + i}>
                  <div className="quest-info">
                    <Input
                      disabled={isLocked}
                      value={q.question}
                      onChange={e => this.updateValues(i, 'question', e.target.value)}
                      size="medium"
                      placeholder={`Question ${i + 1}`}
                    />
                    <Select
                      disabled={isLocked}
                      size="small"
                      value={q.type}
                      onChange={value => this.updateValues(i, 'type', value)}
                    >
                      <Select.Option value="Text">Short Answer</Select.Option>
                      <Select.Option value="Checkbox">Checkboxes</Select.Option>
                      <Select.Option value="Radio">Multiple Choice</Select.Option>
                    </Select>
                    {!isLocked && (
                      <Popconfirm
                        title="Are you sure you want to remove this question?"
                        onConfirm={() => this.removeQuestion(i)}
                        okText="Yes"
                        cancelText="No"
                        warning={true}
                      >
                        <div className="quest-trash">
                          <IconTrash />
                        </div>
                      </Popconfirm>
                    )}
                  </div>
                  {q.type !== 'Text' && (
                    <div className="quest-options">
                      {q.options.map((o, f) => (
                        <div className="quest-option" key={'option-' + i + '-' + f}>
                          {q.type === 'Radio' && (
                            <React.Fragment>
                              <Radio />
                              <Input
                                size="medium"
                                disabled={isLocked}
                                placeholder={PlaceHolders(f)}
                                value={o}
                                onChange={e => this.updateOption(i, f, e.target.value)}
                              />
                            </React.Fragment>
                          )}
                          {q.type === 'Checkbox' && (
                            <React.Fragment>
                              <Checkbox />
                              <Input
                                size="medium"
                                disabled={isLocked}
                                placeholder={PlaceHolders(f)}
                                value={o}
                                onChange={e => this.updateOption(i, f, e.target.value)}
                              />
                            </React.Fragment>
                          )}
                          {!isLocked && (
                            <div className="remove-option" onClick={() => this.removeOption(i, f)}>
                              <IconTrash />
                            </div>
                          )}
                        </div>
                      ))}
                      {!isLocked && (
                        <Button
                          className="add-option"
                          type="default"
                          onClick={() => this.addOption(i)}
                        >
                          + Add {q.options.length > 0 ? 'another' : ''} option
                        </Button>
                      )}
                    </div>
                  )}
                </Card>
              );
            })}

            {!isLocked ? (
              <div className="add-question">
                <Button
                  disabled={isLocked}
                  type="secondary"
                  onClick={() => this.addQuestion('Text')}
                >
                  + Add{hasQuestions ? ' another ' : ' a '}question
                </Button>
              </div>
            ) : null}
          </div>
        </div>

        {/* ACTIONS */}
        {actions}
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(Questions));
