import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { numberWithCommas, formatMoney } from '_assets/js/helpers';
import { Header, Card, Tooltip, Button } from '_seriph';
import {
  IconCheck,
  IconLock,
  IconInfoCircle,
  IconHistory
} from '@tabler/icons-react';
import './TaskProgress.scss';


const StipendStep = props => {

  const { step, tasks, earnings, remaining } = props;

  let status = 'complete'
  if (step === props.currentStep) status = 'active';
  if (step > props.currentStep) status = 'locked';

  // Completed / total
  const taskPercent = Math.floor((500 - remaining) / 500 * 100);

  if (status === 'complete') {
    return (
      <div className="stipend-step complete">
        <div className="ss-icon"><IconCheck /></div>
        <p>Complete {tasks} tasks</p>
        <div className="ss-amount">+${earnings}</div>
      </div>
    );
  }
  if (status === 'active') {
    return (
      <React.Fragment>
        <div className="stipend-step active">
          <div className="ss-icon">{step}</div>
          <p>Complete {tasks} tasks</p>
          <div className="ss-amount">Earn ${earnings}</div>
        </div>
        <div className="stipend-info">
          Only {remaining} tasks to unlock your next goal!
        </div>
        <div className="stipend-progress">
          <div style={{ width: taskPercent + '%'}} />
        </div>
      </React.Fragment>
    );
  }
  if (status === 'locked') {
    return (
      <div className="stipend-step locked">
        <div className="ss-icon"><IconLock /></div>
        <p>Complete {tasks} tasks</p>
        <div className="ss-amount">Earn ${earnings}</div>
      </div>
    );
  }  return null;
};


class TaskProgress extends Component {
  render() {
    const stipendData = this.props.stipendData;
    const stTotal = stipendData.stipend_total;

    let earnings = 0, step = 1;
    if (stTotal >= 500) { earnings = 100; step = 2; }
    if (stTotal >= 1000) {  earnings = 250; step = 3; }
    if (stTotal >= 1500) {  earnings = 500; step = 4; }

    let goal = 500;
    if (stTotal >= 500) goal = 1000;
    if (stTotal >= 1000) goal = 1500;

    const remaining = goal - stTotal;

    const earnQual = stipendData?.stipend_earnings_qualified || 0;
    const earnLeads = stipendData?.stipend_earnings_not_interested || 0;
    const earnTasks = earnings || 0;
    const totalEarnings = parseFloat(earnQual + earnLeads + earnTasks);


    const progressMessage = (
      <div className="progress-tt">
        <div>
          <span className="email" /> {stipendData.stipend_email} emails sent
        </div>
        <div>
          <span className="call" /> {stipendData.stipend_call} calls made
        </div>
        <div>
          <span className="linkedin" /> {stipendData.stipend_linkedin} linkedin messages
        </div>
        <div className="breaker">
          <span className="unassigned" /> {remaining} tasks until next goal
        </div>
      </div>
    );

    const earningsMessage = (
      <div className="progress-tt">
        <div className="nowrap">
          {formatMoney(stipendData.stipend_earnings_qualified)} - Qualified
        </div>
        <div className="nowrap">
          {formatMoney(earnings)} - Completed Tasks
        </div>
        <div className="breaker nowrap light">
          <IconHistory /> {formatMoney(stipendData.stipend_earnings_pending)} - Pending
        </div>
      </div>
    );

    return (
      <div id="agent-stipend">
        <div className="others">
          <Card>
            <div className="descriptor">
              Tasks Completed
            </div>
            <Tooltip title={progressMessage}>
              <Header size="1" font="sans">
                {numberWithCommas(stTotal)} <span>/ {goal}</span>
              </Header>
            </Tooltip>
          </Card>
          <Card>
            <div className="descriptor">Earned this week </div>
            <Tooltip title={earningsMessage}>
              <Header size="1" font="sans" style={{whiteSpace: 'nowrap'}}>
                {formatMoney(totalEarnings)} 
                <span><IconInfoCircle /></span>
              </Header>
            </Tooltip>
          </Card>
        </div>

        <div className="stipend-steps">

          <StipendStep
            step={1}
            currentStep={step}
            earnings={100}
            tasks={500}
            remaining={remaining}
          />
          <StipendStep
            step={2}
            currentStep={step}
            earnings={150}
            tasks={1000}
            remaining={remaining}
          />
          <StipendStep
            step={3}
            currentStep={step}
            earnings={250}
            tasks={1500}
            remaining={remaining}
          />

        </div>


        { true && (
          <div className="stipend-actions">
            <Link to="/leads">
              <Button type="secondary">
                Today&apos;s Tasks
              </Button>
            </Link>
            <Link to="/settings/earnings">
              <Button type="secondary">
                Earning History
              </Button>
            </Link>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(TaskProgress);
