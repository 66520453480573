import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUser } from '_assets/js/helpers';
import Spacer from '_shared/Spacer/Spacer';
import { Card, Avatar } from '_seriph';
import './User.scss';

class User extends Component {
  state = {
    user: getUser() || {}
  };
  render() {
    const user = this.props.user;
    return (
      <Card id="company-discover-user">
        <div className="user-information">
          <Avatar type="company" picture={user.picture} company={user.company_logo} />
          <div className="user-status">
            <div>
              <h3>{user.display_name}</h3>
              <p>Your name and avatar are visible to reps.</p>
            </div>
          </div>
        </div>

        <Spacer />

        <div className="prof-link">
          <Link to="/company" className="sxd-link">
            See {user.company_name} Profile
          </Link>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default connect(mapStateToProps, {})(User);
