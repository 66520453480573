import React, { Component } from 'react';
import { Table, Empty, Input } from 'antd';
import { adminApplications } from '../_graphql/queries';
import { getColumns } from './Columns';
import ReviewCandidate from './_modals/ReviewCandidate/ReviewCandidate';
import './AgentApplications.scss';

export default class AgentApplications extends Component {
  state = {
    data: [],
    selectedRowKeys: [],
    pagination: { pageSize: 20 },
    loading: false,
    selected: null,
    searchText: '',
    reviewCandidate: false,
    previous: null
  };
  componentDidMount() {
    this.fetch({ results: 20, page: 1 });
  }
  fetch = (params = {}) => {
    this.setState({ loading: true });
    this.props.client
      .query({
        variables: {
          name: this.state.searchText,
          roleType: 'agent',
          ...params
        },
        query: adminApplications
      })
      .then(result => {
        if (result && result.data) {
          const applications = result.data.adminApplications.data || [];
          const pagination = { ...this.state.pagination };
          pagination.total = result.data.adminApplications.pageTotal;
          pagination.totalAll = result.data.adminApplications.total;
          this.setState({
            loading: false,
            data: applications,
            pagination,
            previous: params
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(() => this.setState({ loading: false }));
  };
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      name: this.state.searchText,
      ...filters
    });
  };
  resendInvite = id => this.setState({ reviewCandidate: id });
  showReviewCandidate = id => this.setState({ reviewCandidate: id });
  removeReviewCandidate = () => this.setState({ reviewCandidate: false });
  render() {
    const { pagination, data, loading } = this.state;
    const total = pagination.totalAll || 0;
    const totalFiltered = pagination.total || 0;
    const columns = getColumns(this.showReviewCandidate, this.resendInvite);

    return (
      <div id="admin-applications">
        <div className="admin-header">
          Sales Rep Applications{' '}
          <div>
            Showing {totalFiltered} of {total}
          </div>
          <Input.Search
            placeholder="Search"
            value={this.state.searchText}
            onChange={e => this.setState({ searchText: e.target.value })}
            onSearch={value => this.fetch({ results: 20, page: 1, name: value })}
            ref={node => (this.searchInput = node)}
            size="large"
          />
        </div>
        <div className="admin-table">
          <div className="table-content">
            <Table
              className="sellx-table bordered"
              bordered
              rowSelection={null}
              columns={columns}
              rowKey={record => record.id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              scroll={{ x: 'max-content' }}
              locale={{ emptyText: <Empty description="No reps" /> }}
            />
          </div>
        </div>
        {this.state.reviewCandidate ? (
          <ReviewCandidate
            client={this.props.client}
            candidate={data.find(d => d.id === this.state.reviewCandidate)}
            removeModal={this.removeReviewCandidate}
            reload={() =>
              this.fetch(this.state.previous ? this.state.previous : { results: 20, page: 1 })
            }
          />
        ) : null}
      </div>
    );
  }
}
