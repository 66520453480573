export const statusMap = {
  initiated: 'Initiated',
  ringing: 'Ringing',
  answered: 'Answered',
  completed: 'Completed',
  canceled: 'Canceled',
  failed: 'Failed',
  'no-answer': 'No Answer',
  busy: 'Busy'
};
