import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Button, Modal, Select, Tooltip, Alert, Loading, message , Checkbox} from '_seriph';
import { Input, Dropdown, Icon } from 'antd';
import { getSequence } from '_graphql/queries/sequence';
import { saveSequence } from '_graphql/mutations/sequence';
import { getErrors, plural } from '_assets/js/helpers';
import {
  IconCopy,
  IconGripVertical,
  IconChevronLeft,
  IconArrowNarrowRight,
  IconCirclePlus,
  IconTrash,
  IconEye
} from '@tabler/icons-react';
import { touchpointTypes } from '_constants/campaigns';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { reorder, validate, TimeMenu } from './_helpers/helpers';
import './Sequence.scss';

class Sequence extends Component {
  state = {
    sequence: null,
    campaigns: [],
    savedSequence: null,
    loading: true,
    saving: false,
    dropdownVisible: {}
  };
  componentDidMount = () => {
    this.loadSequence();
  };
  componentDidUpdate = prevProps => {
    const prevId = prevProps.sequenceId;
    const newId = this.props.sequenceId;
    if (prevId !== newId) {
      this.setState({ sequence: null, savedSequence: null, loading: true }, this.loadSequence);
    }
  };
  loadSequence = () => {
    this.props.client
      .query({ variables: { id: this.props.sequenceId }, query: getSequence })
      .then(result => {
        if (result && result.data && result.data.getSequence) {
          const sequence = result.data.getSequence || {};
          const campaigns = sequence.campaigns?.filter(
            c => c.status === 'active' || c.status === 'inactive'
          );
          delete sequence.campaigns;
          this.setState({
            sequence,
            campaigns,
            savedSequence: JSON.stringify(sequence),
            loading: false
          });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        message.error(getErrors(err) || 'Could not load sequence, try again');
      });
  };
  saveSequence = () => {
    if (!validate(this.state.sequence)) return;
    this.setState({ saving: true });
    const savedSequence = this.state.sequence;
    this.props.client
      .mutate({ variables: { sequence: this.state.sequence }, mutation: saveSequence })
      .then(result => {
        if (result && result.data && result.data.saveSequence) {
          this.setState({
            sequence: savedSequence,
            savedCampaign: JSON.stringify(savedSequence),
            saving: false
          });
          message.success('Sequence saved');
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Could not save sequence, try again');
      });
  };
  cloneSequence = () => {
    if (JSON.stringify(this.state.sequence) !== this.state.savedSequence) {
      message.error('Please save your changes before cloning this sequence');
    } else if (this.props.cloneSequence) {
      this.props.cloneSequence(this.state.sequence.id);
    } else {
      message.error('Error cloning sequence, try again.');
    }
  };
  update = (field, value) => {
    const sequence = Object.assign({}, this.state.sequence);
    sequence[field] = value !== undefined ? value : null;
    this.setState({ sequence });
  };
  updateTouchpoint = (dayIndex, touchpointIndex, field, value) => {
    let sequence = Object.assign({}, this.state.sequence);
    const touchpoints = sequence.days[dayIndex].touchpoints;
    touchpoints[touchpointIndex][field] = value !== undefined ? value : null;
    if (field === 'type') touchpoints[touchpointIndex].touchpoint_id = undefined;
    sequence = this.clearAuto(sequence);
    this.setState({ sequence });
  };
  touchpointCount = () => {
    if (!this.state.sequence) return 0;
    let touchpointCount = 0;
    this.state.sequence.days?.forEach(d => (touchpointCount += d.touchpoints?.length || 0));
    return touchpointCount;
  };
  addDay = type => {
    let sequence = Object.assign({}, this.state.sequence);
    sequence.days.push({
      touchpoints: [
        { type, duration: undefined, duration_type: undefined, touchpoint_id: undefined, auto: undefined }
      ]
    });
    sequence = this.clearAuto(sequence);
    this.setState({ sequence });
  };
  addTouchpoint = dayIndex => {
    let sequence = Object.assign({}, this.state.sequence);
    sequence.days[dayIndex].touchpoints.push({
      type: undefined,
      duration: undefined,
      duration_type: undefined,
      touchpoint_id: undefined,
      auto: undefined
    });
    sequence = this.clearAuto(sequence);
    this.setState({ sequence });
  };
  updateSelectTouchpoint = (dayIndex, touchpointIndex, val, type) => {
    if (val === 'new-template') {
      const updateTouchpoint = value => {
        this.updateTouchpoint(dayIndex, touchpointIndex, 'touchpoint_id', value);
      };
      this.props.showTouchpointModal('new', type, updateTouchpoint);
    } else {
      this.updateTouchpoint(dayIndex, touchpointIndex, 'touchpoint_id', val);
    }
  };
  updateTouchpointAuto = (dayIndex, touchpointIndex, val) => {
    this.updateTouchpoint(dayIndex, touchpointIndex, 'auto', val);
  };
  removeTouchpoint = (dayIndex, touchpointIndex) => {
    const touchpoint = this.state.sequence.days[dayIndex].touchpoints[touchpointIndex];

    let content = null;
    if (touchpoint.hasStats) {
      content =
        'This touchpoint has statistics for this sequence.  You will still be able to see the stats in the sequence details page.';
    }
    Modal.confirm({
      title: 'Are you sure you want to remove this touchpoint?',
      content: content,
      okText: 'Remove',
      cancelText: 'Cancel',
      onOk: () => {
        let sequence = Object.assign({}, this.state.sequence);
        sequence.days[dayIndex].touchpoints.splice(touchpointIndex, 1);
        // If all touchpoints removed, remove day
        if (sequence.days[dayIndex].touchpoints.length <= 0) {
          sequence.days.splice(dayIndex, 1);
        }
        sequence = this.clearAuto(sequence);
        this.setState({ sequence });
      }
    });
  };
  previewTouchpoint = (e, type, dayIndex, touchpointIndex, id) => {
    e.stopPropagation();
    e.preventDefault();
    const updateTouchpoint = () => {
      this.updateTouchpoint(dayIndex, touchpointIndex, 'touchpoint_id', id);
    };
    this.props.showTouchpointModal(id, type, updateTouchpoint);
  };
  onDragEnd = result => {
    if (!result.destination) return;
    const dayIndex = parseInt(result.draggableId.split('-')[0] || 0) - 1;
    if (dayIndex < 0) return;
    let sequence = Object.assign({}, this.state.sequence);
    const touchpoints = reorder(
      sequence.days[dayIndex].touchpoints,
      result.source.index,
      result.destination.index
    );
    sequence.days[dayIndex].touchpoints = touchpoints;
    sequence = this.clearAuto(sequence);
    this.setState({ sequence });
  };
  onDayDragEnd = result => {
    if (!result.destination) return;
    let sequence = Object.assign({}, this.state.sequence);
    sequence.days = reorder(
      sequence.days,
      result.source.index,
      result.destination.index
    );
    sequence = this.clearAuto(sequence);
    this.setState({ sequence, dayDrag: false });
  };

  clearAuto = sequence => {
    sequence.days.forEach((d, i) => {
      d.touchpoints.forEach((t, j) => {
        const previous = d.touchpoints[j-1];
        if (!['email', 'text'].includes(t.type) || previous?.type !== 'call'){
          sequence.days[i].touchpoints[j].auto = false;
        }
      })
    });
    return sequence;
  };
  handleVisibleChange = (key, visible) => {
    const dropdownVisible = { ...this.state.dropdownVisible };
    dropdownVisible[key] = visible;
    if (!visible) this.setState({ dropdownVisible: {} });
    if (visible) this.setState({ dropdownVisible });
  };
  dropKey = (i, j) => {
    return i.toString() + j.toString() + '';
  };
  render() {
    const { sequence, campaigns, savedSequence, loading, saving, dropdownVisible } = this.state;
    const { touchpoints, company } = this.props;

    const touchpointCount = this.touchpointCount();
    const edited = JSON.stringify(sequence) !== savedSequence;

    const campaignCount = campaigns.length;

    return loading ? (
      <div className="sequence-content">
        <Loading />
      </div>
    ) : (
      <div id="edit-sequence" className="sequence-content">
        <div className="sequence-title">
          <div className="left-actions">
            <Link to="/sequences">
              <Button type="secondary" icon="left">
                <IconChevronLeft />
                Back
              </Button>
            </Link>
          </div>
          <div className="title">
            <Input value={sequence.name} onChange={e => this.update('name', e.target.value)} />
            <p>
              {sequence.days.length} {plural('day', sequence.days.length)}
              &nbsp;&nbsp;&middot;&nbsp;&nbsp;
              {touchpointCount} {plural('touchpoint', touchpointCount)}
            </p>
          </div>
          <div className="right-actions">
            <Tooltip title="Clone Sequence" placement="bottom">
              <Button circle onClick={this.cloneSequence} type="secondary">
                <IconCopy />
              </Button>
            </Tooltip>
            <Button loading={saving} disabled={!edited} type="primary" onClick={this.saveSequence}>
              Save
            </Button>
          </div>
        </div>
        <div className="sequence-wrap">
          {campaignCount > 0 && (
            <Alert type="warning" title="WARNING">
              This sequence is active in {campaignCount} {plural('campaign', campaignCount)}.
              Changes will result in future leads using the new changes, while previous leads will
              continue to use the touchpoints they were assigned when pulled in.
            </Alert>
          )}



    <DragDropContext 
      onDragStart={() => this.setState({dayDrag: true})}
      onDragEnd={this.onDayDragEnd}
    >
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={`days ${
              snapshot.isUsingPlaceholder ? 'dragging' : ''
            }`}
            style={{ paddingBottom: snapshot.isUsingPlaceholder ? '0' : '0' }}
          >

            {sequence.days.map((d, i) => (



          <Draggable
            key={'day-drag-' + (i + 1)}
            draggableId={(i + 1) + '-d'}
            index={i}
          >
            {(provided, snapshot) => (

              <div 
                key={'day-' + (i + 1)} 
                className="day day-card"
                ref={provided.innerRef}
                {...provided.draggableProps}
              >
                <div
                  className={`drag-day ${snapshot.isDragging ? 'dragging' : ''}`}
                  {...provided.dragHandleProps}
                >
                  <IconGripVertical />
                </div>
                <div className="day-title">
                  <h4>Day {i + 1}</h4>
                  <p>
                    {d.touchpoints.length} {plural('touchpoint', d.touchpoints.length)}
                  </p>
                </div>

                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={`day-touchpoints ${
                          snapshot.isUsingPlaceholder ? 'dragging' : ''
                        }`}
                        style={{ paddingBottom: snapshot.isUsingPlaceholder ? '56px' : '0' }}
                      >
                        {d.touchpoints.map((t, j) => {

                          const previous = d.touchpoints[j-1];

                          return (
                            <Draggable
                              key={'day-' + (i + 1) + '-touch-' + j}
                              draggableId={i + 1 + '-' + j}
                              index={j}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className="touchpoint"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <div
                                    className={`drag ${snapshot.isDragging ? 'dragging' : ''}`}
                                    {...provided.dragHandleProps}
                                  >
                                    <IconGripVertical />
                                  </div>
                                  <Select
                                    value={t.type || undefined}
                                    onChange={val => this.updateTouchpoint(i, j, 'type', val)}
                                    placeholder="Select a Touchpoint"
                                    getPopupContainer={trigger => trigger.parentNode}
                                  >
                                    {Object.keys(touchpointTypes).filter(t => {
                                      if (company?.text_number) return true;
                                      return t !== 'text'
                                    }).map(key => {
                                      const touchType = touchpointTypes[key];
                                      const style = {
                                        background: touchType?.color,
                                        color: touchType?.static ? '#FFFFFF' : '#090E15'
                                      };
                                      return (
                                        <Select.Option key={key} value={key}>
                                          <div className="touch-option-drop">
                                            <div style={style}>{touchType.icon}</div>
                                            {touchType.name}
                                          </div>
                                        </Select.Option>
                                      );
                                    })}
                                  </Select>
                                  <div className="arrow">
                                    <IconArrowNarrowRight />
                                  </div>

                                  {t.type === 'wait' ? (
                                    <Dropdown
                                      overlay={
                                        <TimeMenu
                                          step={t}
                                          updateType={val =>
                                            this.updateTouchpoint(i, j, 'duration_type', val)
                                          }
                                          updateTime={val =>
                                            this.updateTouchpoint(i, j, 'duration', val)
                                          }
                                          hide={() =>
                                            this.handleVisibleChange(this.dropKey(i, j), false)
                                          }
                                        />
                                      }
                                      trigger={['click']}
                                      placement="bottomRight"
                                      arrow
                                      getPopupContainer={trigger => trigger.parentNode}
                                      visible={dropdownVisible[this.dropKey(i, j)] ? true : false}
                                      onVisibleChange={val =>
                                        this.handleVisibleChange(this.dropKey(i, j), val)
                                      }
                                    >
                                      <div
                                        className={
                                          !t.duration_type ||
                                          (t.duration_type !== 'All Day' && !t.duration)
                                            ? 'time-button invalid'
                                            : 'time-button'
                                        }
                                      >
                                        {(t.duration_type && t.duration) ||
                                        t.duration_type === 'All Day' ? (
                                          <React.Fragment>
                                            <span className="place">
                                              {(t.duration ? t.duration + ' ' : '') + t.duration_type}
                                            </span>
                                            <span className="drop-arrow">
                                              <Icon type="down" />
                                            </span>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            <span className="place">Select time...</span>
                                            <span className="drop-arrow">
                                              <Icon type="down" />
                                            </span>
                                          </React.Fragment>
                                        )}{' '}
                                      </div>
                                    </Dropdown>
                                  ) : (
                                    <Select
                                      disabled={!t.type}
                                      value={t.touchpoint_id || undefined}
                                      onChange={val => this.updateSelectTouchpoint(i, j, val, t.type)}
                                      placeholder={`Select ${
                                        touchpointTypes[t.type]?.name || 'a'
                                      } Template`}
                                      getPopupContainer={trigger => trigger.parentNode}
                                    >
                                      {t.type ? (
                                        <Select.Option key="new-template" value="new-template">
                                          <div className="touch-option-drop new">
                                            <div>
                                              <IconCirclePlus />
                                            </div>{' '}
                                            New Template
                                          </div>
                                        </Select.Option>
                                      ) : null}
                                      {touchpoints
                                        .filter(list => list.type === t.type)
                                        .map(list => (
                                          <Select.Option
                                            className="sel-touch"
                                            key={i + '-' + j + '-' + list.id}
                                            value={list.id}
                                          >
                                            {list.name}
                                            <div
                                              className="preview"
                                              onClick={e =>
                                                this.previewTouchpoint(e, list.type, i, j, list.id)
                                              }
                                            >
                                              <IconEye />
                                            </div>
                                          </Select.Option>
                                        ))}
                                    </Select>
                                  )}

                                  <div className="auto-check">
                                    { ['email', 'text'].includes(t.type) && previous?.type === 'call' ? (
                                      <div>
                                        <Checkbox 
                                          noBg={true} 
                                          checked={t.auto}
                                          onChange={e => this.updateTouchpointAuto(i, j, e.target.checked)}
                                        /> Auto
                                      </div>
                                    ) : (
                                      <span>&nbsp;</span>
                                    )}
                                  </div>

                                  <div className="trash" onClick={() => this.removeTouchpoint(i, j)}>
                                    <IconTrash />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        <div
                          className="add-another"
                          style={{ top: snapshot.isUsingPlaceholder ? '56px' : '0' }}
                        >
                          <Button type="plain" size="small" onClick={() => this.addTouchpoint(i)}>
                            + Add touchpoint
                          </Button>
                        </div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
          </Draggable>







            ))}
          </div>
        )}  
      </Droppable>
    </DragDropContext>




          { !this.state.dayDrag ? (
            <div className="add-days">
              <h4>Add a new day by selecting a touchpoint</h4>
              <div className="types">
                <div className="lined"></div>
                <div className="type-box">
                  {Object.keys(touchpointTypes).filter(t => {
                    if (company?.text_number) return true;
                    return t !== 'text'
                  }).map(t => {
                    const touchpoint = touchpointTypes[t];
                    const style = {
                      background: touchpoint.color,
                      color: touchpoint.static ? '#FFFFFF' : '#090E15'
                    };
                    return (
                      <Tooltip title={touchpoint.name} key={t}>
                        <div className="add-touchpoint" style={style} onClick={() => this.addDay(t)}>
                          {touchpoint.icon}
                        </div>
                      </Tooltip>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null }
        </div>
      </div>
    );
  }
}

export default withRouter(Sequence);
