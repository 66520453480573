import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, Button, Input, Popconfirm } from '_seriph';
import './BenefitsObjections.scss';
import { IconPlus, IconTrash, IconX } from '@tabler/icons-react';

const BenefitInput = props => (
  <div className="sx-bullet">
    <div className="bullet-and-input">
      <span>&bull;</span>
      <Input
        placeholder={props.placeholder}
        className={props.className}
        onChange={props.onChange}
        value={props.value}
      />
    </div>
    <Popconfirm
      title="Are you sure you want to clear this benefit?"
      onConfirm={props.remove}
      okText="Yes, clear"
      cancelText="No"
      warning={true}
    >
      <div className="remove-option">
        <IconTrash />
      </div>
    </Popconfirm>
  </div>
);

const ObjectionInput = props => (
  <div className="sx-bullet">
    <div className="bullet-and-input">
      <span>&bull;</span>
      <Input
        placeholder={props.placeholder}
        className={props.className}
        onChange={props.onChange}
        value={props.value}
      />
    </div>
    <Popconfirm
      title="Are you sure you want to clear this response?"
      onConfirm={props.remove}
      okText="Yes, clear"
      cancelText="No"
      warning={true}
    >
      <div className="remove-option">
        <IconTrash />
      </div>
    </Popconfirm>
  </div>
);

const PlaceHolders = index => {
  const options = ['Response 1', 'Response 2'];
  if (options[index]) {
    return options[index];
  } else {
    return `Response ${index + 1}`;
  }
};

class BenefitsObjections extends Component {
  componentDidMount = () => {
    if (this.props.save) this.props.save();
    if (this.props.campaign.benefits && this.props.campaign.benefits.length <= 0) {
      setTimeout(() => this.addBenefit(true), 100);
    }
    if (this.props.campaign.objections && this.props.campaign.objections.length <= 0) {
      this.addObjection('Pain Points', true);
    }
  };
  addBenefit = save => {
    const benefits = this.props.campaign.benefits ? [...this.props.campaign.benefits] : [];
    benefits.push('');
    this.props.update('benefits', benefits, save || false);
  };
  updateBenefit = (ndx, value) => {
    const benefits = this.props.campaign.benefits ? [...this.props.campaign.benefits] : [];
    benefits[ndx] = value;
    this.props.update('benefits', benefits);
  };
  removeBenefit = ndx => {
    const benefits = this.props.campaign.benefits ? [...this.props.campaign.benefits] : [];
    benefits.splice(ndx, 1);
    this.props.update('benefits', benefits);
  };
  addObjection = (name, save) => {
    const objections = this.props.campaign.objections ? [...this.props.campaign.objections] : [];
    objections.push({ name: name || '', responses: [''] });
    this.props.update('objections', objections, save || false);
  };
  updateObjection = (ndx, value) => {
    const objections = this.props.campaign.objections ? [...this.props.campaign.objections] : [];
    objections[ndx].name = value;
    this.props.update('objections', objections);
  };
  removeObjection = ndx => {
    const objections = this.props.campaign.objections ? [...this.props.campaign.objections] : [];
    objections.splice(ndx, 1);
    this.props.update('objections', objections);
  };
  updateResponse = (ndx, rdx, value) => {
    const objections = this.props.campaign.objections ? [...this.props.campaign.objections] : [];
    objections[ndx].responses[rdx] = value;
    this.props.update('objections', objections);
  };
  addResponse = ndx => {
    const objections = this.props.campaign.objections ? [...this.props.campaign.objections] : [];
    objections[ndx].responses.push('');
    this.props.update('objections', objections);
  };
  removeResponse = (ndx, rdx) => {
    const objections = this.props.campaign.objections ? [...this.props.campaign.objections] : [];
    objections[ndx].responses.splice(rdx, 1);
    this.props.update('objections', objections);
  };
  render() {
    const { campaign, actions, fromReview } = this.props;

    return (
      <React.Fragment>
        <Card id="wizard-benefits" className="wz-card">
          <div className="wz-container sx-form">
            {/* HEADER */}
            <div className="wz-card-header">
              <h3>What are some key benefits of your product or service?</h3>
              <h5>Tell reps why your product is the best and better than the rest!</h5>
            </div>

            {/* CONTENT */}
            <div className="benefits">
              {campaign.benefits.map((b, i) => (
                <BenefitInput
                  key={'bf-' + i}
                  className={fromReview && !b ? 'invalid' : ''}
                  placeholder={`Benefit ${i + 1}`}
                  onChange={e => this.updateBenefit(i, e.target.value)}
                  remove={() => this.removeBenefit(i)}
                  value={b}
                />
              ))}
            </div>

            <div className="add-benefit">
              <Button type="secondary" size="large" icon="left" onClick={() => this.addBenefit('')}>
                <IconPlus />
                Add another benefit
              </Button>
            </div>
          </div>
        </Card>

        <Card id="wizard-objections" className="wz-card">
          <div className="wz-container sx-form">
            {/* HEADER */}
            <div className="wz-card-header">
              <h3>What are some possible objections of your product or service?</h3>
              <h5>For those pesky naysayers out there, give reps the right info to act quickly.</h5>
            </div>

            {/* CONTENT */}
            <div className="objections">
              {campaign.objections.map((o, i) => {
                return (
                  <Card className="object step-input" key={'object-' + i}>
                    <div className="object-info">
                      <Input
                        className={fromReview && !o.name ? 'forms invalid' : 'forms'}
                        value={o.name}
                        onChange={e => this.updateObjection(i, e.target.value)}
                        size="large"
                        placeholder="Objection..."
                      />
                      <Popconfirm
                        title="Are you sure you want to clear this benefit and responses?"
                        onConfirm={() => this.removeObjection(i)}
                        okText="Yes, clear"
                        cancelText="No"
                        warning={true}
                      >
                        <div className="remove-option">
                          <IconX />
                        </div>
                      </Popconfirm>
                    </div>
                    <div className="benefits">
                      {o.responses.map((b, j) => (
                        <ObjectionInput
                          key={'resp-' + j}
                          placeholder={PlaceHolders(j)}
                          className={fromReview && !b ? 'invalid' : ''}
                          onChange={e => this.updateResponse(i, j, e.target.value)}
                          remove={() => this.removeResponse(i, j)}
                          value={b}
                        />
                      ))}
                      <div className="add-option">
                        <div onClick={() => this.addResponse(i)}>
                          + add {o.responses.length > 0 ? 'another' : ''} response
                        </div>
                      </div>
                    </div>
                  </Card>
                );
              })}
            </div>

            <div className="add-objection">
              <Button
                type="secondary"
                size="large"
                icon="left"
                onClick={() => this.addObjection('')}
              >
                <IconPlus />
                Add another objection
              </Button>
            </div>
          </div>

          {/* ACTIONS */}
          {actions}
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(BenefitsObjections));
