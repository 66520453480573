import React, { Component } from 'react';
import { hiredAgents } from '_graphql/queries/company';
import { Dropdown } from 'antd';
import { getColumns } from './_components/Columns';
import MessageAgentModal from 'App/_shared/MessageAgentModal/MessageAgentModal';
import AddAgentModal from 'App/_shared/AddAgentModal/AddAgentModal';
import AddAgentBackModal from 'App/_shared/AddAgentBackModal/AddAgentBackModal';
import InviteAgentModal from 'App/_shared/InviteAgentModal/InviteAgentModal';
import RemoveAgentModal from 'App/_shared/RemoveAgentModal/RemoveAgentModal';
//import RemoveAgent from '_shared/AgentActions/_modals/RemoveAgent/RemoveAgent';
import { bringBackAgent } from 'Mutations/Campaign/agents';
import { revokeTeamRep, reinstateTeamRep } from '_graphql/mutations/rep';
import CampaignDropdown from '_shared/CampaignDropdown/CampaignDropdown';
import { Card, Table, Checkbox, Pagination, Modal, message } from '_seriph';
import { IconPlus } from '@tabler/icons-react';
import RepFilter from '../_components/RepFilter/RepFilter';
import AgentSort from '../_components/AgentSort/AgentSort';
// import AgentTileToggle from '../_components/AgentTileToggle/AgentTileToggle';
import AgentTile from '../_components/AgentTile/AgentTile';
import moment from 'moment';
import './Hired.scss';

const columnsToNames = {
  pulled: 'Leads pulled',
  calls: 'Call tasks',
  emails: 'Email tasks',
  meetings: 'Meetings scheduled',
  submitted: 'Leads submitted',
};

export default class Hired extends Component {
  state = {
    loading: true,
    hired: [],
    searchText: '',
    sort: undefined,
    agent: null,
    invite: false,
    removeAgent: false,
    visible: false,
    columns: {
      pulled: false,
      calls: true,
      emails: true,
      meetings: false,
      submitted: false
    },
    paginationPageSize: 16,
    paginationStartIndex: 0,
    paginationEndIndex: 8,
    paginationCurrent: 1,
    repFilter: 'all',
    tile: false
  };
  componentDidMount = () => {
    this.getHired();
  };
  componentDidUpdate = prevProps => {
    if (prevProps.campaign !== this.props.campaign) {
      this.getHired();
    }
  };
  getHired = () => {
    const selected = this.props.generateCampaigns(this.props.campaign);
    this.props.client.query({ variables: { ...selected }, query: hiredAgents }).then(result => {
      if (result?.data?.hiredAgents) {
        const hiredMap = (result.data.hiredAgents || []).map(r => {
          const myCampaigns = r.campaigns.filter(c => {
            const member = c.agents.find(a => a.agent_id === r.agent.id);
            return member && member.active ? true : false;
          });
          r.inactive = !r.is_internal && myCampaigns.length <= 0;
          return r;
        });

        if (this.state.repFilter === 'xdr') {
          const xdr = hiredMap.filter(r => !r.is_internal);
          this.setState({ hired: xdr });
        } else if (this.state.repFilter === 'internal') {
          const internal = hiredMap.filter(r => r.is_internal);
          this.setState({ hired: internal });
        } else {
          this.setState({ hired: hiredMap });
        }
      }
      this.setState({ loading: false });
    });
  };
  addBackToCampaign = (agent, campaign, callBack) => {
    Modal.confirm({
      title: `Are you sure you want to welcome back ${agent.display_name}?`,
      content:
        'Once they are added back they will immediately be given an opportunity to generate or qualify leads.',
      onOk: () => {
        this.props.client
          .mutate({
            variables: { campaign_id: campaign.id, agent_id: agent.id },
            mutation: bringBackAgent
          })
          .then(result => {
            if (result && result.data && result.data.bringBackAgent) {
              message.success(agent.display_name + ' has been re-added to the campaign');
              this.props.reloadCampaigns();
              this.getHired();
              if (callBack) callBack();
            } else {
              message.error('Could not re-add agent, try again');
            }
          });
      }
    });
  };
  revokeTeamRep = agent => {
    Modal.confirm({
      title: `Are you sure you want to revoke the account for ${agent.display_name}?`,
      content: 'They will be REMOVED from all campaigns AND be unable to login after you revoke their account.',
      onOk: async () => {
        let result;
        let error = 'Could not revoke agent, try again';
        try {
          result = (
            await this.props.client.mutate({
              variables: { agent_id: agent.id },
              mutation: revokeTeamRep
            })
          )?.data?.revokeTeamRep;
        } catch (e) {
          error = e.message;
        }

        if (result) {
          message.success(agent.display_name + ' has had their account revoked.');
          this.getHired();
        } else {
          message.error(error);
        }
      }
    });
  };
  reinstateTeamRep = agent => {
    Modal.confirm({
      title: `Are you sure you want to reinstate the account for ${agent.display_name}?`,
      content: 'They will be have to be added back to any campaigns they were previously on.',
      onOk: async () => {
        let result;
        let error = 'Could not reinstate agent, try again';
        try {
          result = (
            await this.props.client.mutate({
              variables: { agent_id: agent.id },
              mutation: reinstateTeamRep
            })
          )?.data?.reinstateTeamRep;
        } catch (e) {
          error = e.message;
        }

        if (result) {
          message.success(agent.display_name + ' has had their account reinstated.');
          this.getHired();
        } else {
          message.error(error);
        }
      }
    });
  };
  showMessageModal = agent => this.setState({ agent });
  showInviteAgentModal = agent => this.setState({ agent, invite: true });
  showAddAgentModal = agent => this.setState({ agent, addAgent: true });
  removeModal = () => this.setState({ agent: null, invite: false, addAgent: false });
  showAddAgentBackModal = agent => this.setState({ agent, addBack: true });
  removeModal = () => this.setState({ agent: null, invite: false, addAgent: false, addBack: false });
  showRemoveAgentModal = agent => this.setState({ removeAgent: agent });
  removeRemoveAgentModal = () => this.setState({ removeAgent: false });
  onChangeSearch = val => this.setState({ searchText: val });
  updateSort = sort => this.setState({ sort });
  updateColumns = (field, value) => {
    const columns = Object.assign({}, this.state.columns);
    columns[field] = value !== undefined ? value : null;
    this.setState({ columns });
  };
  handleVisibleChange = flag => {
    this.setState({ visible: flag });
  };
  render() {
    const { hired, loading, sort, searchText, columns } = this.state;
    const filtered = hired
      .filter(h => {
        const name = h.agent.display_name.toLowerCase();
        return searchText === '' || name.includes(searchText.toLowerCase());
      })
      .sort(a => (a.inactive ? 1 : -1))
      .sort((a, b) => {
        if (sort === 'most')
          return parseInt(a.stats.total_paid || 0) > parseInt(b.stats.total_paid || 0) ? -1 : 1;
        if (sort === 'least')
          return parseInt(a.stats.total_paid || 0) > parseInt(b.stats.total_paid || 0) ? 1 : -1;
        if (sort === 'mostqualified')
          return parseInt(a.stats.qualified || 0) > parseInt(b.stats.qualified || 0) ? -1 : 1;
        if (sort === 'leastqualified')
          return parseInt(a.stats.qualified || 0) > parseInt(b.stats.qualified || 0) ? 1 : -1;
        if (sort === 'mostgenerated')
          return parseInt(a.stats.generated || 0) > parseInt(b.stats.generated || 0) ? -1 : 1;
        if (sort === 'leastgenerated')
          return parseInt(a.stats.generated || 0) > parseInt(b.stats.generated || 0) ? 1 : -1;
        if (sort === 'newest') return parseInt(a.hired) > parseInt(b.hired) ? -1 : 1;
        if (sort === 'oldest') return parseInt(a.hired) > parseInt(b.hired) ? 1 : -1;
        return 1;
      });

    const menu = (
      <Card id="column-menu">
        {Object.keys(columns).map(c => (
          <Checkbox
            key={c}
            checked={this.state.columns[c]}
            onChange={e => this.updateColumns(c, e.target.checked)}
          >
            {columnsToNames[c]}
          </Checkbox>
        ))}
      </Card>
    );
    return (
      <div id="real-hired-agents">
        <div className="real-hired-filter">
          <div>
            <CampaignDropdown
              placement="bottomLeft"
              allowAll={true}
              campaign={this.props.campaign}
              campaigns={this.props.campaigns}
              selectCampaign={this.props.selectCampaign}
            />
            <RepFilter
              filter={this.state.repFilter}
              setFilter={f => {
                this.setState({ repFilter: f });
                this.getHired();
              }}
            />
          </div>
          <div className="filter-right">
            <AgentSort
              onChangeSearch={this.onChangeSearch}
              updateSort={this.updateSort}
              sort={sort}
            />
{/*            <AgentTileToggle
              tile={this.state.tile}
              setTile={f => {
                this.setState({ tile: f, paginationPageSize: f ? 8 : 4 });
              }}
            />*/}
          </div>
        </div>
        {!this.state.tile ? (
          <div id="hired-table">
            <Table
              lined
              rounded
              loading={loading}
              dataSource={filtered.map(d => {
                d.key = d.agent.id;
                return d;
              })}
              columns={getColumns(
                this.getHired,
                this.showMessageModal,
                this.showRemoveAgentModal,
                this.showInviteAgentModal,
                this.showAddAgentModal,
                this.addBackToCampaign,
                this.showAddAgentBackModal,
                this.revokeTeamRep,
                this.reinstateTeamRep,
                columns,
                columnsToNames
              )}
              locale={{ emptyText: loading ? 'Loading sales reps...' : 'No sales reps found' }}
              scroll={{ x: 'max-content' }}
              rowClassName={r => (r.inactive ? 'inactive' : '')}
              pagination={{
                pageSize: this.state.paginationPageSize,
                total: filtered.length,
                current: this.state.paginationCurrent,
                onChange: paginationCurrent => {
                  this.setState({ paginationCurrent });
                }
              }}
            />
            <Dropdown
              overlayClassName="agent-actions-dropdown"
              overlay={menu}
              trigger={['click']}
              placement="bottomRight"
              onVisibleChange={this.handleVisibleChange}
              visible={this.state.visible}
            >
              <div id="hired-columns">
                <IconPlus size={16} stroke={2} />
              </div>
            </Dropdown>
          </div>
        ) : (
          <React.Fragment>
            <div id="hired-tiles">
              {filtered
                .slice(this.state.paginationStartIndex, this.state.paginationEndIndex)
                .map(d => (
                  <AgentTile
                    key={d.agent.id}
                    agent={d.agent}
                    joinedInfo={`Joined ${moment(d.hired * 1).format('M/D/YYYY')}`}
                    campaigns={d.campaigns}
                    isInternal={d.is_internal}
                    showMessageModal={this.showMessageModal}
                    showRemoveAgentModal={this.showRemoveAgentModal}
                    showInviteAgentModal={this.showInviteAgentModal}
                    addBackToCampaign={this.addBackToCampaign}
                    revokeTeamRep={this.revokeTeamRep}
                    reinstateTeamRep={this.reinstateTeamRep}
                  />
                ))}
            </div>
            <Pagination
              pageSize={this.state.paginationPageSize}
              total={filtered.length}
              current={this.state.paginationCurrent}
              onChange={paginationCurrent => {
                const paginationStartIndex =
                  (paginationCurrent - 1) * this.state.paginationPageSize;
                const paginationEndIndex = paginationCurrent * this.state.paginationPageSize;
                this.setState({ paginationStartIndex, paginationEndIndex, paginationCurrent });
              }}
            />
          </React.Fragment>
        )}

        {this.state.agent && !this.state.invite && !this.state.addAgent ? (
          <MessageAgentModal
            user={this.props.user}
            client={this.props.client}
            agent={this.state.agent}
            removeModal={this.removeModal}
          />
        ) : null}
        {this.state.agent && this.state.addAgent && !this.state.invite ? (
          <AddAgentModal
            user={this.props.user}
            client={this.props.client}
            campaigns={this.props.campaigns}
            agent={this.state.agent}
            removeModal={this.removeModal}
            reload={this.getHired}
          />
        ) : null}
        {this.state.agent && this.state.addBack && !this.state.invite ? (
          <AddAgentBackModal
            user={this.props.user}
            client={this.props.client}
            campaigns={this.props.campaigns}
            agent={this.state.agent}
            removeModal={this.removeModal}
            reload={this.getHired}
            addBackToCampaign={this.addBackToCampaign}
          />
        ) : null}
        {this.state.agent && this.state.invite && !this.state.addAgent ? (
          <InviteAgentModal
            user={this.props.user}
            client={this.props.client}
            campaigns={this.props.campaigns}
            agent={this.state.agent}
            removeModal={this.removeModal}
          />
        ) : null}
        {this.state.removeAgent ? (
          <RemoveAgentModal
            user={this.props.user}
            client={this.props.client}
            campaigns={this.props.campaigns}
            agent={this.state.removeAgent}
            removeModal={this.removeRemoveAgentModal}
            reload={this.getHired}
          />
        ) : /*
          <RemoveAgent
            user={this.props.user}
            client={this.props.client}
            agent={this.state.removeAgent}
            campaign={this.state.removeAgentCampaign}
            removeModal={this.removeRemoveAgentModal}
            reload={this.getHired}
          />
          */
        null}
      </div>
    );
  }
}
