import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Input, message } from 'antd';
import { isEmail, isPassword } from '_assets/js/helpers';
import { updateEmail } from '_graphql/mutations/employee';
import { loginUser } from 'Store/user/user_actions';
import './ChangeEmail.scss';

class ChangeEmail extends Component {
  state = {
    visible: true,
    loading: false,
    email: '',
    password: ''
  };
  logoutCallback = () => this.props.history.push('/');
  hideModal = () => this.setState({ visible: false });
  shouldDisable = () => {
    const { email, password } = this.state;
    let disable = false;
    if (!isEmail(email)) disable = true;
    if (!isPassword(password)) disable = true;
    return disable;
  };
  saveEmail = () => {
    this.props.client
      .mutate({
        variables: { email: this.state.email, password: this.state.password },
        mutation: updateEmail
      })
      .then(result => {
        if (result && result.data && result.data.updateEmail) {
          this.props.loginUser(result.data.updateEmail);
          message.success('Email has been successfully changed');
          this.props.reload();
        } else {
          message.error('Could not change your email, try again');
        }
      });
  };
  render() {
    return (
      <Modal
        wrapClassName="change-email-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        style={{ top: 60 }}
        width={600}
        closable={false}
        maskClosable={false}
      >
        <h3 className="ra-title">Verify & Change Email</h3>

        <div className="ra-body">
          <div className="ra-message">
            <h3 className="ra-review">New Email</h3>
            <Input
              id="new"
              name="new"
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value })}
              size="large"
              className="forms"
              placeholder=""
              autoComplete="off"
            />
            <h3 className="ra-review">Confirm Password</h3>
            <Input
              id="confirm"
              name="confirm"
              value={this.state.password}
              onChange={e => this.setState({ password: e.target.value })}
              type="password"
              size="large"
              className="forms"
              placeholder=""
              autoComplete="off"
            />
          </div>
        </div>

        <div className="ra-actions">
          <Button className="btn btn-default" onClick={this.hideModal}>
            Cancel
          </Button>
          <Button
            disabled={this.shouldDisable()}
            className="btn btn-primary"
            onClick={this.saveEmail}
            loading={this.state.loading}
          >
            Change Email
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default connect(mapStateToProps, { loginUser })(ChangeEmail);
