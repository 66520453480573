import React, { Component } from 'react';
import { Modal, Input, Button, message } from 'antd';
import { leaveCampaign } from 'Mutations/Campaign/agents';
import './LeaveCampaign.scss';

const { TextArea } = Input;

export default class LeaveCampaign extends Component {
  state = {
    visible: true,
    loading: false,
    reason: ''
  };
  leave = () => {
    this.setState({ loading: true });
    this.props.client
      .mutate({
        variables: {
          campaign_id: this.props.campaign.id,
          reason: this.state.reason
        },
        mutation: leaveCampaign
      })
      .then(result => {
        if (result && result.data && result.data.leaveCampaign) {
          message.success('You have successfully left ' + this.props.campaign.name);
          this.hideModal();
          this.props.reload();
        } else {
          throw new Error('Could not remove rep, try again');
        }
      })
      .catch(() => {
        message.error('Could not remove rep, try again');
        this.setState({ loading: false });
      });
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { campaign } = this.props;

    return (
      <Modal
        wrapClassName="leave-campaign-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        style={{ top: 60 }}
        width={500}
        closable={false}
        maskClosable={true}
      >
        <h3 className="ra-title">Are you sure you want to leave?</h3>

        <div className="ra-body">
          <div className="ra-message">
            <h3 className="ra-review">Reason for leaving *</h3>
            <TextArea
              value={this.state.reason}
              onChange={e => this.setState({ reason: e.target.value })}
              size="large"
              rows={6}
              className="forms forms-textarea"
              placeholder="Moving, vacation, found a new job, etc..."
            />
            <div className="notice">
              <b>Note:</b> Make sure you are 100% sure before leaving a campaign. The campaign owner
              is the only person who can let you rejoin.
            </div>
          </div>
        </div>

        <div className="ra-actions">
          <Button className="btn btn-default" onClick={this.hideModal}>
            Cancel
          </Button>
          <Button
            disabled={this.state.reason.length <= 0}
            className="btn btn-primary"
            onClick={this.leave}
            loading={this.state.loading}
          >
            Leave&nbsp;{campaign.name}
          </Button>
        </div>
      </Modal>
    );
  }
}
