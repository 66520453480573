import React, { Component } from 'react';
import Listing from 'App/Agent/Discover/_components/Listing/Listing';
import CampaignOverview from '_styleguide/CampaignOverview/CampaignOverview';
import { Header } from '_seriph';
import './Overview.scss';

export default class Overview extends Component {
  state = {
    campaignId: null
  };
  showModal = campaignId => this.setState({ campaignId });
  removeModal = () => this.setState({ campaignId: null });
  changePreview = campaignId => {
    this.setState({ campaignId: null }, () => this.setState({ campaignId }));
  };
  hasApplied = applications => {
    const userId = this.props.user.id;
    for (let i = 0; i < applications.length; i++) {
      if (applications[i].agent_id === userId) return applications[i].status;
    }
    return false;
  };
  render() {
    const { company } = this.props;
    const campaigns = company.active_campaigns.sort(
      (a, b) => parseInt(b.created || 0) - parseInt(a.created || 0)
    );

    return (
      <div id="company-profile-overview">
        <Header font="sans" size="3">
          {company.headline || 'Overview of ' + company.name}
        </Header>
        <p className={`overview ${campaigns.length <= 0 ? 'empty' : ''}`}>
          {company.overview?.length > 1 ? company.overview : <i>Write your overview here...</i>}
        </p>

        {campaigns.length > 0 ? (
          <React.Fragment>
            <Header font="sans" size="3">
              Recent Campaigns
            </Header>
            <div className="recent-listings">
              {campaigns.slice(0, 2).map(c => {
                const hasApplied = this.hasApplied(c.applications || []);
                const hasJoined = this.props.myCampaigns.find(m => m.id === c.id);
                return (
                  <Listing
                    key={'llc-' + c.id}
                    id={c.id}
                    campaign={c}
                    showModal={this.showModal}
                    category={
                      company && company.industries.length > 0 ? company.industries[0].name : ''
                    }
                    actions={false}
                    hasApplied={hasApplied}
                    hasJoined={hasJoined ? true : false}
                    isAgent={this.props.isAgent}
                  />
                );
              })}
            </div>
            {this.state.campaignId ? (
              <CampaignOverview
                client={this.props.client}
                campaignId={this.state.campaignId}
                removeModal={this.removeModal}
                changePreview={this.changePreview}
                isAgent={this.props.isAgent}
              />
            ) : null}
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}
