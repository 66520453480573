import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './CallRecordings.scss';
import CampaignDropdown from '_shared/CampaignDropdown/CampaignDropdown';
import { Header, Table, message, Select } from '_seriph';
import { getColumns } from './Columns';
import { getCallRecordings } from '_graphql/queries/analytics';
import { myAgents } from '_graphql/queries/company';
import { favoriteCall } from '_graphql/mutations/call';
import { generateRange } from '_constants/datepicker';
import DateRange from '_shared/DateRange/DateRange';
import numeral from 'numeral';
import { humanizeDuration } from '_helpers/duration';
import WaveSurfer from 'wavesurfer.js';

const defaultParams = {
  results: 6,
  page: 1,
  sortField: 'paid',
  sortOrder: 'descend',
  duration: 'all',
  owner: 'all'
};

const generateCampaigns = campaign => {
  const isStatus = ['active', 'inactive', 'completed', 'all'].includes(campaign);
  if (isStatus) {
    return { status: campaign };
  } else {
    return { campaign_id: campaign };
  }
};

class CallRecordings extends Component {
  state = {
    loading: false,
    data: [],
    campaign: null,
    playing_recording: null,
    filters: {
      campaign_id: 'all',
      range: 'all',
      duration: 'all',
      owner: 'all'
    },
    hired: [],
    pagination: { pageSize: 6 },
    totalDurationString: '0 mins',
    averageDurationString: '0 mins',
    dropdown_visible: null
  };
  componentDidMount = () => {
    this.fetchCallRecordings();
    this.getHired();
  };
  playRecording = (call_id, url) => {
    if (this.state.playing_recording === call_id) {
      message.info(`Playback stopped.`);
      this.setState({ playing_recording: null });
      this.wavesurfer.stop();
    } else {
      this.setState({ playing_recording: call_id });
      const wavesurfer = WaveSurfer.create({
        container: '#waveform' // this is hidden
      });
      this.wavesurfer = wavesurfer;
      message.info(`Playing recording...`);
      wavesurfer.load(url);
      wavesurfer.on('ready', function () {
        wavesurfer.play();
      });
    }
  };
  fetchCallRecordings = async (params = defaultParams) => {
    this.setState({ loading: true });
    const selected = generateCampaigns(this.state.filters.campaign_id);
    const range = generateRange(this.state.filters.range);
    const duration = this.state.filters.duration;
    const owner = this.state.filters.owner;
    let result;
    try {
      result = (
        await this.props.client.query({
          variables: { ...selected, ...range, ...params, duration, owner },
          query: getCallRecordings
        })
      ).data.getCallRecordings;
    } catch (e) {
      result = null;
    }

    if (!result) {
      this.setState({ loading: false });
      message.error('Failed to fetch calls');
      return;
    }

    const pagination = { ...this.state.pagination };
    pagination.total = result.pageTotal;
    pagination.totalAll = result.total;

    const totalDurationString = humanizeDuration(result.totalDuration * 1000);
    const averageDuration = result.totalDuration / result.pageTotal;
    const averageDurationString = humanizeDuration(averageDuration * 1000);

    this.setState({
      loading: false,
      data: result.data,
      pagination,
      previous: params,
      totalDurationString,
      averageDurationString
    });

    this.setState({ loading: false });
  };
  UNSAFE_componentWillMount = () => window.scrollTo({ top: 0 });
  callFavoriteClicked = async call_id => {
    let result;
    try {
      result = (
        await this.props.client.mutate({
          mutation: favoriteCall,
          variables: { call_id }
        })
      )?.data?.favoriteCall;
    } catch (e) {
      result = null;
    }

    if (result === true || result === false) {
      const data = this.state.data;
      data.map(d => {
        if (d.call.id === call_id) d.is_favorited = result;
        return d;
      });
      this.setState({ data });
    } else {
      message.error("We couldn't favorite that call. Please try again later.");
    }
  };
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetchCallRecordings({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      name: this.state.searchText,
      ...filters
    });
  };
  getHired = () => {
    this.props.client.query({ variables: { status: 'all' }, query: myAgents }).then(result => {
      if (result?.data?.hiredAgents) {
        this.setState({ hired: result.data.hiredAgents || [] });
      }
    });
  };
  selectCampaign = campaign_id => {
    const filters = { ...this.state.filters, campaign_id };
    this.setState({ filters }, this.fetchCallRecordings);
  };
  selectRange = range =>
    this.setState({ filters: { ...this.state.filters, range } }, this.fetchCallRecordings);
  updateDuration = duration =>
    this.setState({ filters: { ...this.state.filters, duration } }, this.fetchCallRecordings);
  updateOwner = owner =>
    this.setState({ filters: { ...this.state.filters, owner } }, this.fetchCallRecordings);

  onVisibleChanged = call_id => {
    if (this.state.dropdown_visible === call_id) {
      this.setState({ dropdown_visible: null });
    }
  };
  dropdownClicked = call_id => {
    if (this.state.dropdown_visible !== call_id) {
      this.setState({ dropdown_visible: call_id });
    }
  };

  render() {
    const { loading } = this.state;
    const columns = getColumns(
      this.callFavoriteClicked,
      this.playRecording,
      this.state.playing_recording,
      this.dropdownClicked,
      this.state.dropdown_visible,
      this.onVisibleChanged,
      this.props.history
    );

    const callData = this.state.data || [];

    return (
      <div id="company-analytics-call-recordings">
        <div className="call-recordings-header">
          <div className="seriph-header-wrapper">
            <Header type="display" weight="600" size="4">
              Call recordings
            </Header>
          </div>
          <div id="waveform" />

          <div className="dashboard-actions">
            <CampaignDropdown
              allowAll={true}
              placement="bottomLeft"
              campaign={this.state.filters.campaign_id}
              campaigns={this.props.campaigns}
              selectCampaign={this.selectCampaign}
            />

            <DateRange range={this.state.filters.range} selectRange={this.selectRange} />

            <Select value={this.state.filters.duration} onChange={this.updateDuration}>
              <Select.Option value="all">All durations</Select.Option>
              <Select.Option value="under-1">&lt;1 min</Select.Option>
              <Select.Option value="1-3">1-3 mins</Select.Option>
              <Select.Option value="3-5">3-5 mins</Select.Option>
              <Select.Option value="5-7">5-7 mins</Select.Option>
              <Select.Option value="8-10">8-10 mins</Select.Option>
              <Select.Option value="over-10">10+ mins</Select.Option>
            </Select>
          </div>
        </div>


        <div className="call-recordings-main">
          <div className="main-top">
            <div className="top-info">
              <div className="info-title">Avg. call duration</div>
              <div className="info-content">{this.state.averageDurationString}</div>
            </div>
            <div className="top-info">
              <div className="info-title">Total call volume</div>
              <div className="info-content">
                {numeral(this.state.pagination?.total).format('0,0')}
              </div>
            </div>
            <div className="top-info">
              <div className="info-title">Total call duration</div>
              <div className="info-content">{this.state.totalDurationString}</div>
            </div>
          </div>
          <div className="main-table">
            <Table
              lined
              rounded
              rowSelection={null}
              columns={columns}
              rowKey={record => record.call.id}
              dataSource={callData}
              pagination={this.state.pagination}
              loading={loading}
              onChange={this.handleTableChange}
              scroll={{ x: 'max-content' }}
              locale={{
                emptyText: loading ? 'Loading call recordings...' : 'No call recordings found'
              }}
            />
          </div>
        </div>

      </div>
    );
  }
}

export default withRouter(CallRecordings);
