import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Header, message } from '_seriph';
import { resendOnboard } from '_graphql/mutations/onboard';
import './EmailVerification.scss';

class EmailVerification extends Component {
  state = {
    sending: false
  };
  resendVerification = () => {
    this.setState({ sending: true });
    this.props.client
      .mutate({ variables: { id: this.props.form.id }, mutation: resendOnboard })
      .then(result => {
        if (result?.data?.resendOnboard) {
          message.success('Email verification sent to: ' + this.props.form.email);
          this.setState({ sending: false });
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        this.setState({ sending: false });
        message.error('Could not resend email, try again');
      });
  };
  selectVal = val => {
    this.props.updateForm('type', val);
  }
  render() {
    const { sending } = this.state;
    const { form } = this.props;

    return (
      <div className="join-form-box" id="onboard-verify">
        <Header type="display" size="4">
          Check your email
        </Header>
        <p>
          We’ve sent a message to <b>{form.email}</b> with a link to activate your account.
        </p>

        <div className="notice"><b>Didn’t get the email?  Check your spam folder.</b></div>
        <div className="notice">Still can’t find it? Try resending.</div>

        <div className="notice">
          <Button type="default" onClick={this.resendVerification} loading={sending}>Resend email</Button>
        </div>

      </div>
    );
  }
}

export default withRouter(EmailVerification);
