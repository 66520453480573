import { formatMoney } from '_assets/js/helpers';
import { hasAccess } from '_helpers/permissions';
import parsePhoneNumber from 'libphonenumber-js';

export const getLeadInfo = lead => {
  const data = { lead: { ...lead } };

  // GET SOURCE
  if (data.lead.agent) {
    data.source = { ...data.lead.agent };
  }

  // GET REQUIRED
  if (data.lead.campaign.required) {
    data.required = [...data.lead.campaign.required];
  }

  // GET QUESTIONS
  if (data.lead.campaign.questions) {
    data.questions = [...data.lead.campaign.questions];
  }

  // GET CAMPAIGN/INTEGRATION
  if (data.lead.campaign) {
    data.campaign = { ...data.lead.campaign };
    data.integration = { ...data.lead.campaign.company.integration };
  }

  // GET INTEGRATION
  if (data.lead.campaign.questions) {
    data.questions = [...data.lead.campaign.questions];
  }

  // Handle STAGE
  if (data.lead && !data.lead?.agent_id) {
    data.lead.stage = 'unassigned';
  }

  // Parse lead mobile number
  if (data.lead.mobile) {
    data.lead.mobile = parsePhoneNumber(data.lead.mobile, 'US');
    // example of RFC3966
    // data.lead.mobile = parsePhoneNumber('tel:+12133734253;ext=123', 'US');
    if (data.lead.mobile) {
      data.lead.mobile.toString = function phoneFormat() {
        return this.formatNational();
      };
    }
  }

  // Parse lead phone number
  if (data.lead.phone) {
    data.lead.phone = parsePhoneNumber(data.lead.phone, 'US');
    // example of RFC3966
    // data.lead.phone = parsePhoneNumber('tel:+12133734253;ext=123', 'US');
    if (data.lead.phone) {
      data.lead.phone.toString = function phoneFormat() {
        return this.formatNational();
      };
    }
  }

  // Organize Lead Data
  delete data.lead.agent;
  delete data.lead.employee;
  delete data.lead.campaign;

  return data;
};

export const getLeadName = lead => {
  let leadName = `${lead.salutation || ''} ${lead.first_name || ''} ${lead.last_name || ''}`;
  if (!lead.first_name && !lead.last_name && lead.company) {
    leadName = lead.company;
  }
  const noWhiteSpace = leadName.replace(/ /g, '');
  return noWhiteSpace.length > 0 ? leadName : 'Unknown';
};

export const getLeadPrice = (lead, campaign) => {
  let leadPrice = campaign.price_per_lead;
  if (campaign.objective === 'qualify') {
    const isReadyToPay = lead.stage === 'review' || lead.stage === 'completed';
    if (isReadyToPay && lead.interest_reason !== 'interested') {
      leadPrice = campaign.price_not_interested;
    }
  }
  return formatMoney(leadPrice);
};

export const cleanLead = lead => {
  const tempLead = { ...lead };
  delete tempLead.agent_id;
  delete tempLead.updatedAt;
  delete tempLead.hasResponded;
  delete tempLead.meetingScheduled;
  delete tempLead.calendly;
  delete tempLead.chilipiper;
  delete tempLead.dispute_date;
  delete tempLead.dispute_reason;
  delete tempLead.dispute_information;
  delete tempLead.interest_reason;
  delete tempLead.interest_information;
  delete tempLead.money;
  delete tempLead.createdByCompany;
  delete tempLead.emailAttempts;
  delete tempLead.callAttempts;
  delete tempLead.email_valid;
  delete tempLead.phone_valid;
  delete tempLead.mobile_valid;
  delete tempLead.unread_emails;
  delete tempLead.unread_calls;
  delete tempLead.unread_texts;
  delete tempLead.last_timestamp;
  delete tempLead.last_sequence;
  delete tempLead.last_step;
  delete tempLead.sequences;
  delete tempLead.sequence_id;
  return tempLead;
};

export const getCurrent = (
  leadId,
  lead,
  savedLead,
  campaign,
  integration,
  user,
  info,
  outofmoney,
  isNewText
) => {
  if (!lead) return {};
  /* Lead Stage, Types and User Types */
  const isChanged = JSON.stringify(lead) !== savedLead;
  const isNew = isNewText ? isNewText : leadId === 'new';
  const isGenerated = campaign.objective === 'leadgen';
  const isQualified = campaign.objective === 'qualify';
  const isAgent = user.role_type === 'agent';
  const isCompany = user.role_type === 'company';

  /* Permissions and Integrations */
  const useCalendly = campaign.calendly && integration && integration.calendly.url ? true : false;
  const useChilipiper =
    campaign.chilipiper && integration && integration.chilipiper.domain ? true : false;
  const underReview = lead.stage === 'review';
  const canEdit =
    lead && lead.stage !== 'review' && lead.stage !== 'disputed' && campaign.status === 'active';
  const canEmail = lead.stage !== 'completed' && !isCompany && campaign.status === 'active';
  const canActionButton = campaign.status === 'active';
  const hasNoPaymentMethod = isAgent && info && !info.has_payment_method && hasAccess(['Rep']);

  /* Lead Status States */
  const isCreatable =
    isAgent && isNew && ['in-progress', 'disputed', 'not-interested'].includes(lead.stage);
  const isSavable =
    isChanged &&
    !isNew &&
    ['in-progress', 'disputed', 'not-interested', 'completed'].includes(lead.stage);
  const isSubmittable =
    !isNew && !isChanged && isAgent && ['in-progress', 'disputed'].includes(lead.stage);
  const wasSubmitted = isAgent && ['review'].includes(lead.stage);
  const wasCompleted = isAgent && ['completed'].includes(lead.stage);
  const wasDisputed = isAgent && ['disputed'].includes(lead.stage);

  /* Handle Button Logic */
  const saveButton = isSavable || (isCompany && !underReview);
  const outOfMoneyButton = isCreatable && outofmoney;
  const nextButton = isCreatable && !outofmoney;
  const reviewButtons = isCompany && underReview && hasAccess(['Admin', 'Manager']);
  const submitButton = isSubmittable;
  const completedButton = !isSavable && wasCompleted;
  const revertButton = wasSubmitted || wasDisputed;

  return {
    isChanged,
    isNew,
    isGenerated,
    isQualified,
    isAgent,
    isCompany,
    useCalendly,
    useChilipiper,
    underReview,
    canEdit,
    canEmail,
    canActionButton,
    hasNoPaymentMethod,
    isCreatable,
    isSavable,
    isSubmittable,
    wasSubmitted,
    wasCompleted,
    wasDisputed,

    saveButton,
    outOfMoneyButton,
    nextButton,
    reviewButtons,
    submitButton,
    revertButton,
    completedButton
  };
};
