import React, { Component } from 'react';
import { Card, Button } from '_seriph';
import AddBox from '_shared/AddBox/AddBox';
import SkillsEdit from './SkillsEdit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import './Skills.scss';

export default class Skills extends Component {
  state = {
    editing: false,
    showAll: false
  };
  openEditor = () => this.setState({ editing: true });
  closeEditor = () => this.setState({ editing: false });
  toggleAllSkills = () => this.setState({ showAll: !this.state.showAll });
  render() {
    const { editing, showAll } = this.state;
    const { agent, isEditable } = this.props;
    let skills = agent.skills || [];
    if (!showAll && !editing) skills = skills.slice(0, 8);
    const addBox = isEditable && skills.length === 0;
    return (
      <Card id="agent-profile-skills" padding={addBox ? '20px' : '20px 10px 10px 20px'}>
        {editing || !addBox ? <h2>Skills</h2> : null}
        {!editing && !addBox && (
          <React.Fragment>
            <div>
              {skills.map((s, i) => (
                <div key={'skill-' + i} className="prof-tag">
                  {s}
                </div>
              ))}
              {skills.length > 8 && (
                <div>
                  <div className="sxd-link" onClick={this.toggleAllSkills}>
                    {showAll ? 'See less skills' : 'See all skills'}
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        )}
        {editing && isEditable ? (
          <SkillsEdit reload={this.props.reload} close={this.closeEditor} skills={skills} />
        ) : null}
        {addBox && !editing ? (
          <AddBox action={this.openEditor} title="Skills" percent={this.props.value} />
        ) : null}
        {!editing && !addBox && skills.length === 0 ? (
          <i className="none-yet">No skills listed</i>
        ) : null}
        {isEditable && !editing && (
          <Button
            type="secondary"
            className="btnx-edit"
            size="tiny"
            icon="left"
            onClick={this.openEditor}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
            Edit
          </Button>
        )}
      </Card>
    );
  }
}
