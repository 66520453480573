import React from 'react';
import avatar from '_assets/img/avatar.png';
import avatar_company from '_assets/img/company.png';

import {
  determineReputation,
  imageForReputationIndex,
  reputationTextIndex
} from '_helpers/reputation';

import { IconRocket } from '@tabler/icons-react';
import './Avatar.scss';

const badge = reputation => {
  const rep = determineReputation(reputation);
  return reputation ? (
    <img
      className="av-rep"
      src={imageForReputationIndex(rep)}
      alt={reputationTextIndex(rep)}
      title={reputationTextIndex(rep)}
    />
  ) : null;
};
const Avatar = ({
  type,
  picture,
  company,
  reputation,
  size,
  firstCharacter,
  lastCharacter,
  className = ''
}) => {
  // Sizes: giant, large, medium, small, tiny
  const calcReputation = reputation !== undefined && !reputation ? 1 : reputation;
  className += ' seriph-avatar';
  if (size) className += ` size-${size}`;

  return (
    <div className={className}>
      {/* MAIN AVATAR / PICTURE */}
      {type === 'lead' && !picture ? (
        <span className="character">
          {firstCharacter}
          {lastCharacter}
        </span>
      ) : (
        <img className="av-pic" src={picture || avatar} alt="Avatar" title="Avatar" />
      )}

      {/* COMPANY LOGO */}
      {type === 'company' && company && (
        <img className="av-comp" src={company || avatar_company} alt="Company" title="Company" />
      )}

      {/* CAMPAIGN */}
      {type === 'campaign' && (
        <div className="av-campaign">
          <IconRocket />
        </div>
      )}
      {type === 'agent' && badge(calcReputation)}
    </div>
  );
};

export default Avatar;
