import React from 'react';

export const getLeadName = (lead, addCompany) => {
  let onlyCompany = false;
  let leadName = `${lead.first_name || ''} ${lead.last_name || ''}`;
  if (!lead.first_name && !lead.last_name && lead.company) {
    leadName = lead.company;
    onlyCompany = true;
  }
  const noWhiteSpace = leadName.replace(/ /g, '');

  if (addCompany && !onlyCompany && lead?.company) {
    return <React.Fragment>{leadName} <span className="lead-name-company">/&nbsp;{lead.company}</span></React.Fragment>;
  }

  return noWhiteSpace.length > 0 ? leadName : 'Unknown';
};