import gql from 'graphql-tag';

export const sendBulkEmail = gql`
  mutation sendBulkEmail(
    $task_id: String!
    $mainArea: String
    $subject: String
    $isTest: Boolean
    $campaignId: String!
    $socketId: String!
  ) {
    sendBulkEmail(
      task_id: $task_id
      mainArea: $mainArea
      subject: $subject
      isTest: $isTest
      campaignId: $campaignId
      socketId: $socketId
    ) {
      sent
      skip
      success
    }
  }
`;

export const sendFromEmailer = gql`
  mutation sendFromEmailer(
    $task_id: String!
    $lead_id: String!
    $mainArea: String!
    $subject: String!
    $signature: String!
  ) {
    sendFromEmailer(
      task_id: $task_id
      lead_id: $lead_id
      mainArea: $mainArea
      subject: $subject
      signature: $signature
    ) {
      success
    }
  }
`;
