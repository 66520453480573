import React, { Component } from 'react';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faBullseye,
  faBuilding,
  faIndustry,
  faHandHoldingUsd,
  faFunnelDollar
} from '@fortawesome/free-solid-svg-icons';
import { formatMoney } from '_assets/js/helpers';
import './CustomerProfile.scss';

const OverviewIcon = props => (
  <div className="overview-icon customer-profile">
    <div className="icn">
      <FontAwesomeIcon icon={props.icon} />
    </div>
    <div className="info">
      <h4>{props.info || 'Not Available'}</h4>
      <p>{props.type}</p>
    </div>
  </div>
);

const TooltipList = props =>
  props.data ? (
    <Tooltip
      placement="top"
      overlayClassName="sellx-tooltip sellx-tooltip-small"
      title={props.data}
    >
      {props.data}
    </Tooltip>
  ) : (
    'Not Available'
  );

export default class CustomerProfile extends Component {
  getRequiredValues = field => {
    const { campaign } = this.props;
    if (campaign && campaign.required && campaign.required.length > 0) {
      const fieldData = campaign.required.find(r => r.field === field);
      if (field === 'location') {
        return fieldData ? fieldData.locations || [] : [];
      } else {
        return fieldData ? fieldData.values || [] : [];
      }
    } else {
      return [];
    }
  };
  render() {
    const { campaign } = this.props;

    const locationsData = this.getRequiredValues('location');
    let newLocations = locationsData.map(l => l.name);
    let locations = newLocations.sort((a, b) => a.localeCompare(b));
    const industries = this.getRequiredValues('industry');

    return (
      <div className="overview-cp">
        <h3>Customer Profile</h3>
        <div className="overview-container">
          <OverviewIcon type="Targeting" info={campaign.customer_type} icon={faBullseye} />
          {campaign.customer_company_size ? (
            <OverviewIcon
              type="Company Size"
              info={<TooltipList data={campaign.customer_company_size.join(', ')} />}
              icon={faBuilding}
            />
          ) : null}
          <OverviewIcon
            type="Industry"
            info={<TooltipList data={industries.join(', ')} />}
            icon={faIndustry}
          />
          <OverviewIcon
            type="Decision Maker"
            info={campaign.customer_decision_maker}
            icon={faHandHoldingUsd}
          />
          {locations.length > 0 ? (
            <OverviewIcon
              type="Location(s)"
              info={<TooltipList data={locations.join(', ')} />}
              icon={faMapMarkerAlt}
            />
          ) : null}
          {campaign.customer_spend_start && campaign.customer_spend_end ? (
            <OverviewIcon
              type="Annual Revenue"
              info={
                formatMoney(campaign.customer_spend_start) +
                ' - ' +
                formatMoney(campaign.customer_spend_end)
              }
              icon={faFunnelDollar}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
