import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { message, Card, Loading, Header, Button } from '_seriph';
import { integrations } from '_graphql/queries/company';
import { getErrors } from '_assets/js/helpers';
import Calendly from './_components/Calendly/Calendly';
import Texting from './_components/Texting/Texting';
import Chilipiper from './_components/Chilipiper/Chilipiper';
import Salesforce from './_components/Salesforce/Salesforce';
import Hubspot from './_components/Hubspot/Hubspot';
import Gmail from './_components/Gmail/Gmail';
import CustomDomain from './_components/CustomDomain/CustomDomain';
import { IconPlus } from '@tabler/icons-react';
import './Integrations.scss';
import RequestIntegration from './_modals/RequestIntegration/RequestIntegration';

class Integrations extends Component {
  state = {
    loading: true,
    integrations: {
      calendly: {}
    },
    requestIntegration: false
  };
  componentDidMount = () => {
    this.loadIntegrations();
  };
  loadIntegrations = reset => {
    this.props.client
      .query({ query: integrations })
      .then(result => {
        if (result && result.data && result.data.integrations) {
          this.setState({ loading: false, integrations: result.data.integrations || {} });
          if (reset) reset();
        } else {
          throw new Error('Could not load integrations, try again');
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        message.error(getErrors(err) || 'Could not load integrations, try again');
      });
  };
  showRequestIntegrationModal = () => this.setState({ requestIntegration: true });
  removeRequestIntegrationModal = () => this.setState({ requestIntegration: false });
  render() {
    const { integrations, loading } = this.state;
    const { user } = this.props;
    return loading ? (
      <Loading />
    ) : (
      <Card id="company-settings-integrations" className="settings-card">
        <div className="settings-header-container">
          <div>
            <Header font="sans" size="2" className="settings-header">
              Integrations
            </Header>
            <div className="settings-subtitle">
              Connect {user.company_name}’s favorite tools for a seamless workflow
            </div>
          </div>
          <div className="right-section">
            <div className="button-label">Don&apos;t see what you&apos;e looking for?</div>
            <Button type="secondary" icon="left" onClick={this.showRequestIntegrationModal}>
              <IconPlus />
              Request integration
            </Button>
          </div>
        </div>
        <div className="integrations">
          {true && (
            <CustomDomain
              client={this.props.client}
              myself={this.props.myself}
              company={this.props.company}
              integrations={integrations}
              loading={loading}
              loadIntegrations={this.loadIntegrations}
            />
          )}
          <Hubspot
            client={this.props.client}
            myself={this.props.myself}
            integrations={integrations}
            loading={loading}
            loadIntegrations={this.loadIntegrations}
            comingSoon={false}
          />
          <Salesforce
            client={this.props.client}
            myself={this.props.myself}
            integrations={integrations}
            loading={loading}
            loadIntegrations={this.loadIntegrations}
            comingSoon={false}
          />
          <Calendly
            client={this.props.client}
            myself={this.props.myself}
            integrations={integrations}
            loading={loading}
            loadIntegrations={this.loadIntegrations}
          />
          <Texting
            client={this.props.client}
            myself={this.props.myself}
            integrations={integrations}
            loading={loading}
            loadIntegrations={this.loadIntegrations}
            company={this.props.company}
          />
          <Chilipiper
            client={this.props.client}
            myself={this.props.myself}
            integrations={integrations}
            loading={loading}
            loadIntegrations={this.loadIntegrations}
          />

          {false && (
            <Gmail
              client={this.props.client}
              myself={this.props.myself}
              integrations={integrations}
              loading={loading}
              loadIntegrations={this.loadIntegrations}
            />
          )}
        </div>
        {this.state.requestIntegration ? (
          <RequestIntegration
            removeModal={this.removeRequestIntegrationModal}
            client={this.props.client}
          />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(Integrations));
