import React, { Component } from 'react';
import { Button, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Avatar from '_styleguide/Avatar/Avatar';
import { typeList } from '_constants/leadFields';
import { mySalesReps } from '_graphql/queries/campaign';
import './LeadFilter.scss';

const FilterOption = props => (
  <div onClick={props.toggle} className={props.active ? 'filter-option active' : 'filter-option'}>
    {props.sts ? <span className={`sts ${props.sts}`} /> : null}
    {props.name}
    <FontAwesomeIcon icon={faCheck} />
  </div>
);

export default class LeadFilter extends Component {
  state = {
    reps: [],
    repId: null
  };
  componentDidMount = () => {
    this.getReps();
  };
  getReps = () => {
    this.props.client
      .query({ query: mySalesReps })
      .then(result => {
        if (result && result.data && result.data.mySalesReps) {
          this.setState({ reps: result.data.mySalesReps || [] });
        }
      })
      .catch(() => console.error('Could not load reps'));
  };
  toggle = (field, item) => {
    if (this.props.filter[field].includes(item)) {
      this.removeOption(field, item);
    } else {
      this.addOption(field, item);
    }
  };
  updateField = (field, value) => {
    const filter = this.props.filter ? { ...this.props.filter } : {};
    filter[field] = value;
    this.props.updateFilter(filter);
  };
  addOption = (field, item) => {
    const filter = this.props.filter ? { ...this.props.filter } : {};
    filter[field].push(item);
    this.props.updateFilter(filter);
  };
  removeOption = (field, item) => {
    const filter = this.props.filter ? { ...this.props.filter } : {};
    filter[field] = filter[field].filter(f => f !== item);
    this.props.updateFilter(filter);
  };
  resetFilters = () => {
    this.props.updateFilter({ stages: [], campaigns: [], types: [], highlights: [] });
  };
  offsetToggle = () => {
    if (this.props.showVeil && this.props.visible) this.props.toggleFilter();
  };
  render() {
    const { reps } = this.state;
    const { campaigns, visible, filter, isFiltering, showVeil } = this.props;

    const isAgent = this.props.user.role_type === 'agent';

    const validCampaigns = campaigns.filter(c => {
      const isAvailable =
        c.status === 'active' || c.status === 'inactive' || (!isAgent && c.status === 'completed');
      if (isAgent) {
        const agent = c.agents.find(a => a.agent_id === this.props.user.id);
        const isActive = agent && agent.active ? true : false;
        return isActive && isAvailable ? true : false;
      } else {
        return isAvailable ? true : false;
      }
    });

    let veilClass = '';
    if (visible) veilClass += ' filtering';
    if (showVeil) veilClass += ' show';

    return (
      <React.Fragment>
        <div id="lead-filter" className={visible ? 'filtering' : ''}>
          <div className="filter-header">
            <h2>Filters</h2>
            {isFiltering && (
              <Button className="btnx btnx-default btnx-small" onClick={this.resetFilters}>
                Clear
              </Button>
            )}
          </div>
          <div className="filter-container sx-form">
            <div className="filter-title">Campaign Type</div>
            <div className="filter-options">
              {typeList.map(s => (
                <FilterOption
                  name={s.name}
                  key={'lt-' + s.key}
                  active={filter.types.includes(s.key)}
                  toggle={() => this.toggle('types', s.key)}
                />
              ))}
            </div>

            {!isAgent ? (
              <React.Fragment>
                <div className="filter-title">Sales Rep</div>
                <div className="filter-options field">
                  <Select
                    showSearch
                    dropdownClassName="sx-dropdown dropdown-reps"
                    placeholder="Select a sales rep"
                    value={filter.repId}
                    onChange={val => this.updateField('repId', val)}
                    optionFilterProp="displayname"
                    filterOption={(input, option) =>
                      (option.props.displayname || '').toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    <Select.Option value={null}>All Sales Reps</Select.Option>
                    {reps.map(r => (
                      <Select.Option
                        key={'ffcsr-' + r.id}
                        displayname={r.display_name}
                        value={r.id}
                      >
                        <Avatar size="mini" type="company" picture={r.picture} />
                        <span>
                          <b>{r.display_name}</b>
                        </span>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </React.Fragment>
            ) : null}

            <div className="filter-title">Campaigns</div>
            <div className="filter-options no-bottom">
              {validCampaigns.map(c => (
                <FilterOption
                  name={c.name}
                  key={'fc-' + c.id}
                  active={filter.campaigns.includes(c.id)}
                  toggle={() => this.toggle('campaigns', c.id)}
                />
              ))}
            </div>
          </div>

          <div className="filter-actions">
            <div className="fa-left">
              <Button onClick={this.offsetToggle} className="btnx btnx-neutral">
                Close
              </Button>
            </div>
          </div>
        </div>
        <div onClick={this.offsetToggle} id="filter-veil" className={veilClass} />
      </React.Fragment>
    );
  }
}
