import React, { Component } from 'react';
import AddBox from '_shared/AddBox/AddBox';
import AboutMeEdit from './AboutMeEdit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Card, Button } from '_seriph';
import './AboutMe.scss';

export default class AboutMe extends Component {
  state = {
    editing: false
  };
  openEditor = () => this.setState({ editing: true });
  closeEditor = () => this.setState({ editing: false });
  render() {
    const { editing } = this.state;
    const { agent, isEditable } = this.props;
    const addBox = isEditable && !agent.about;

    return (
      <Card id="agent-profile-aboutme">
        {(!addBox || editing) && <h2>About Me</h2>}
        {!editing && agent.about ? <p style={{ whiteSpace: 'pre-line' }}>{agent.about}</p> : null}
        {!editing && !agent.about && !addBox ? (
          <p style={{ whiteSpace: 'pre-line', fontStyle: 'italic' }}>
            Give companies an overview of your career and experiences...
          </p>
        ) : null}
        {editing && isEditable ? (
          <AboutMeEdit agent={agent} reload={this.props.reload} close={this.closeEditor} />
        ) : null}
        {addBox && !editing ? (
          <AddBox action={this.openEditor} title="About Me" percent={this.props.value} />
        ) : null}
        {isEditable && !editing && (
          <Button
            type="secondary"
            className="btnx-edit"
            size="tiny"
            icon="left"
            onClick={this.openEditor}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
            Edit
          </Button>
        )}
        {editing || (!editing && agent.about) ? (
          <div className="edit-note">
            <b>Note:</b> This is the first things companies see so stand out, this is your elevator
            pitch!
          </div>
        ) : null}
      </Card>
    );
  }
}
