const toTitleCase = phrase => {
  return phrase
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const generateData = data => {
  const { leads } = data;

  const leadData = {
    industries: {},
    positions: {},
    titles: {},
    indtitle: {}
  };

  leads?.forEach(l => {
    /* Industry */
    const industry = toTitleCase(l.industry || '');
    if (industry) {
      if (!leadData.industries[industry])
        leadData.industries[industry] = { amount: 0, connected: 0, converted: 0 };
      leadData.industries[industry].amount = leadData.industries[industry].amount + 1;
      if (l.hasResponded) leadData.industries[industry].connected++;
      if (l.interest_reason === 'interested') leadData.industries[industry].converted++;
      if (!leadData.indtitle[industry]) leadData.indtitle[industry] = {};
    }

    /* Position */
    const position = toTitleCase(l.job_position || '');
    if (position) {
      if (!leadData.positions[position])
        leadData.positions[position] = { amount: 0, connected: 0, converted: 0 };
      leadData.positions[position].amount = leadData.positions[position].amount++;
      if (l.hasResponded) leadData.positions[position].connected + 1;
      if (l.interest_reason === 'interested') leadData.positions[position].converted++;
    }

    /* Industry */
    const title = toTitleCase(l.title || '');
    if (title) {
      if (!leadData.titles[title])
        leadData.titles[title] = { amount: 0, connected: 0, converted: 0 };
      leadData.titles[title].amount = leadData.titles[title].amount + 1;
      if (l.hasResponded) leadData.titles[title].connected++;
      if (l.interest_reason === 'interested') leadData.titles[title].converted++;
    }

    /* Indtitle */
    if (industry && title) {
      if (!leadData.indtitle[industry][title]) leadData.indtitle[industry][title] = 0;
      leadData.indtitle[industry][title] = leadData.indtitle[industry][title] + 1;
    }
  });

  /* Setup industry chart */
  const chartData = {
    industries: [],
    positions: [],
    titles: [],
    indtitle: []
  };

  /* Handle industries */
  Object.keys(leadData.industries).forEach(k => {
    const industry = leadData.industries[k];
    chartData.industries.push({
      name: k,
      total: industry.amount,
      amount: industry.amount - industry.connected - industry.converted,
      connected: industry.connected,
      converted: industry.converted
    });
  });

  /* Handle positions */
  Object.keys(leadData.positions).forEach(k => {
    const position = leadData.positions[k];
    chartData.positions.push({
      name: k,
      total: position.amount,
      amount: position.amount - position.connected - position.converted,
      connected: position.connected,
      converted: position.converted
    });
  });

  /* Handle titles */
  Object.keys(leadData.titles).forEach(k => {
    const title = leadData.titles[k];
    chartData.titles.push({
      name: k,
      total: title.amount,
      amount: title.amount - title.connected - title.converted,
      connected: title.connected,
      converted: title.converted
    });
  });
  chartData.titles?.sort((a, b) => parseFloat(b.total) - parseFloat(a.total));

  /* Handle indtitle */
  Object.keys(leadData.indtitle).forEach(k => {
    const indtitle = leadData.indtitle[k];
    const values = {};
    let total = 0;
    chartData.titles.forEach((f, i) => {
      if (i < 9) {
        values[f.name] = indtitle[f.name] || 0;
      } else {
        values['Other'] = values['Other'] ? values['Other'] + indtitle[f.name] : indtitle[f.name];
      }
      total = total + f.total;
    });
    chartData.indtitle.push({
      name: k,
      amount: total,
      ...values
    });
  });

  return chartData;
};
