import axios from 'axios';
const apiPath = process.env.REACT_APP_API_PATH;

export const getToken = async () => {
  let token = null;
  try {
    const results = await axios.get(`${apiPath}/twilio/v3/token`, {
      headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') }
    });
    token = results?.data?.token || null;
  } catch (err) {
    console.error(err);
  }
  return token;
};


export const createPhoneListeners = async (conference, endPhone) => {
  conference.on('error', function (error) {
    console.log('Twilio.Device Error: ' + error.message);
    endPhone();
  });
};

export const createTestListeners = async (call, endPhone) => {
  // console.log('Creating TEST listeners');

  call.on('disconnect', function () {
    // console.log("Test Phone disconnected", data);
    endPhone();
  });

  call.on('cancel', function () {
    // console.log("Test Phone cancelled", data);
    endPhone();
  });

  call.on('error', function (error) {
    console.log('Error: ' + error.message);
    endPhone();
  });
};
