import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from '_seriph';
import { IconMoodHappy, IconCheck } from '@tabler/icons-react';
import './Type.scss';

class Type extends Component {
  selectVal = val => {
    this.props.updateForm('type', val);
  }
  render() {

    const { form } = this.props;
    const options = [
      {
        value: 'rep',
        title: 'Im a freelancer / contractor',
        icon: <IconMoodHappy />
      },
      {
        value: 'company',
        title: 'I work for a company',
        icon: <IconMoodHappy />
      },
      {
        value: 'agency',
        title: 'I work for an agency',
        icon: <IconMoodHappy />
      },
    ];

    return (
      <div className="join-form-box" id="onboard-type">
        <div className="join-title">
          <h2>Which best describes you?</h2>
          <p>This determines your account type</p>
        </div>

        <div className="join-fields">
          <div className="list-select">
            { options.map(o => (
              <div key={'jf-'+o.value} className={o.value === form.type ? 'selected' : ''} onClick={() => this.selectVal(o.value)}>
                <span>{o.icon}</span>
                <h4>{o.title}</h4>
                { o.value === form.type && (
                  <div><IconCheck /></div>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="join-actions">
          { this.props.next ? (
            <Button type="primary" onClick={() => this.props.saveOnboard(this.props.next)}>Continue</Button>
          ) : null }
        </div>
      </div>
    );
  }
}

export default withRouter(Type);
