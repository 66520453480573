import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Header, message, Loading, Select, Table, Input } from '_seriph';
import PhoneNumbers from './_components/PhoneNumbers/PhoneNumbers';
import RoundRobinModal from './_modals/RoundRobinModal/RoundRobinModal';
import RobinStats from './_components/RobinStats/RobinStats';
import { getColumns } from './_data/Columns';
import { getRoundRobins, getRoundRobinCalls } from '_graphql/queries/roundrobin';
import { getErrors, prettyPhone } from '_assets/js/helpers';
import DateRange from '_shared/DateRange/DateRange';
import { statusMap } from '_constants/twilio';
import { generateRange } from '_constants/datepicker';
import debounce from 'debounce';
import WaveSurfer from 'wavesurfer.js';

import './RoundRobin.scss';

const defaultParams = {
  results: 10,
  page: 1,
  duration: 'all',
  range: 'all',
  status: 'all',
  number: 'all',
  round_robin_id: 'all',
  sortField: 'paid',
  sortOrder: 'descend',
  search: undefined
};

class RoundRobin extends Component {
  state = {
    loading: true,
    loadingCalls: true,
    roundRobins: [],
    playing_recording: null,
    dropdown_visible: null,
    filters: {
      range: 'all',
      duration: 'all',
      status: 'all',
      number: 'all',
      round_robin_id: 'all',
      search: undefined
    },
    stats: {
      total: 0,
      answered: 0,
      missed: 0,
      recorded: 0
    },
    pagination: { pageSize: 10 },
  };
  componentDidMount = () => {
    this.onLoadPage();
  };
  onLoadPage = () => {
    this.setState(
      { loading: true, loadingCalls: true },
      () => {
        this.getRoundRobins();
        this.fetchCallRecordings();
      }
    );
  };
  playRecording = (call_id, url) => {
    if (this.state.playing_recording === call_id) {
      message.info(`Playback stopped.`);
      this.setState({ playing_recording: null });
      this.wavesurfer.stop();
    } else {
      this.setState({ playing_recording: call_id });
      const wavesurfer = WaveSurfer.create({
        container: '#waveform' // this is hidden
      });
      this.wavesurfer = wavesurfer;
      message.info(`Playing recording...`);
      wavesurfer.load(url);
      wavesurfer.on('ready', function () {
        wavesurfer.play();
      });
    }
  };
  UNSAFE_componentWillMount = () => window.scrollTo({ top: 0 });
  getRoundRobins = () => {
    this.props.client
      .query({ query: getRoundRobins })
      .then(result => {
        if (result && result.data && result.data.getRoundRobins) {
          const roundRobins = result.data.getRoundRobins || [];
          this.setState({ roundRobins, loading: false });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        message.error(getErrors(err) || 'Could not load round robins, try again');
      });
  };
  showRoundRobinModal = (id, type, updateRoundRobin) => {
    this.setState({
      roundRobinModal: id,
      roundRobinModalType: type,
      updateRoundRobin
    });
  };
  removeRoundRobinModal = () => {
    this.setState({
      roundRobinModal: false,
      roundRobinModalType: undefined,
      updateRoundRobin: undefined
    });
  };
  fetchCallRecordings = async (params = defaultParams) => {
    this.setState({ loadingCalls: true });
    const range = generateRange(this.state.filters.range);
    const duration = this.state.filters.duration;
    const owner = this.state.filters.owner;
    const status = this.state.filters.status;
    const number = this.state.filters.number;
    const round_robin_id = this.state.filters.round_robin_id;
    const search = this.state.filters.search;
    let result;
    try {
      result = (
        await this.props.client.query({
          variables: { ...range, ...params, duration, owner, status, round_robin_id, search, number },
          query: getRoundRobinCalls
        })
      ).data.getRoundRobinCalls;
    } catch (e) {
      result = null;
    }

    if (!result) {
      this.setState({ loadingCalls: false });
      message.error('Failed to fetch calls');
      return;
    }

    const pagination = { ...this.state.pagination };
    pagination.total = result.pageTotal;
    pagination.totalAll = result.total;

    this.setState({
      loadingCalls: false,
      data: result.data,
      pagination,
      previous: params,
      stats: {
        total: result.stat_total,
        answered: result.stat_answered,
        missed: result.stat_missed,
        failed: result.stat_failed,
      }
    });

    this.setState({ loadingCalls: false });
  };
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetchCallRecordings({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      name: this.state.searchText,
      ...filters
    });
  };
  onVisibleChanged = call_id => {
    if (this.state.dropdown_visible === call_id) {
      this.setState({ dropdown_visible: null });
    }
  };
  dropdownClicked = call_id => {
    if (this.state.dropdown_visible !== call_id) {
      this.setState({ dropdown_visible: call_id });
    }
  };
  selectRange = range =>
    this.setState({ filters: { ...this.state.filters, range } }, this.fetchCallRecordings);
  updateDuration = duration =>
    this.setState({ filters: { ...this.state.filters, duration } }, this.fetchCallRecordings);
  filterStatus = status =>
    this.setState({ filters: { ...this.state.filters, status } }, this.fetchCallRecordings);
  filterSearch = e =>
    this.setState({ filters: { ...this.state.filters, search: e.target.value || undefined } }, () => this.debounceCallRecordings());
  filterNumber = number =>
    this.setState({ filters: { ...this.state.filters, number } }, this.fetchCallRecordings);
  filterRoundRobin = round_robin_id =>
    this.setState({ filters: { ...this.state.filters, round_robin_id } }, this.fetchCallRecordings);
  debounceCallRecordings = debounce(() => {
    this.fetchCallRecordings();
  }, 1000);
  render() {
    const { loading, loadingCalls, roundRobins } =
      this.state;

    let allNumbers = [];
    roundRobins?.forEach(r => {
      const forwardTo = r.forward_to.map(ft => ft.number);
      allNumbers = new Set([...allNumbers, ...forwardTo]);
    });
    allNumbers = [...allNumbers];

    const columns = getColumns(
      this.playRecording,
      this.state.playing_recording,
      this.dropdownClicked,
      this.state.dropdown_visible,
      this.onVisibleChanged,
    );

    return loading ? (
      <Loading />
    ) : (
      <div id="company-round-robin">
        <PhoneNumbers roundRobins={roundRobins} showRoundRobinModal={this.showRoundRobinModal} />

        <div className="sequence-content">
          <div className="sequence-wrap">
            <div className="seriph-header-wrapper">
              <Header type="display" weight="600" size="4">
                Round Robin
              </Header>
            </div>
            <div className="subheader">
              Create ways to direct incoming calls with custom phone numbers.
            </div>
              <div id="waveform" />

            <div className="dashboard-actions">
              <DateRange range={this.state.filters.range} selectRange={this.selectRange} />

              <Select value={this.state.filters.duration} onChange={this.updateDuration}>
                <Select.Option value="all">All durations</Select.Option>
                <Select.Option value="under-1">&lt;1 min</Select.Option>
                <Select.Option value="1-3">1-3 mins</Select.Option>
                <Select.Option value="3-5">3-5 mins</Select.Option>
                <Select.Option value="5-7">5-7 mins</Select.Option>
                <Select.Option value="8-10">8-10 mins</Select.Option>
                <Select.Option value="over-10">10+ mins</Select.Option>
              </Select>

              <Select value={this.state.filters.round_robin_id} onChange={this.filterRoundRobin}>
                <Select.Option value="all">All call lists</Select.Option>
                {roundRobins.map(r => (
                  <Select.Option value={r.id} key={'a-' + r.id}>{r.name}</Select.Option>
                ))}
              </Select>

              <Select value={this.state.filters.status} onChange={this.filterStatus}>
                <Select.Option value="all">All statuses</Select.Option>
                {Object.keys(statusMap).map(r => (
                  <Select.Option value={r} key={'sm-' + r}>{statusMap[r]}</Select.Option>
                ))}
              </Select>
              <Select 
                value={this.state.filters.number} 
                onChange={this.filterNumber}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  console.log(option, input);
                  return option.props.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                <Select.Option value="all">All numbers</Select.Option>
                {allNumbers?.map(r => (
                  <Select.Option value={r} key={'sm-' + r}>{prettyPhone(r)}</Select.Option>
                ))}
              </Select>
              <Input
                value={this.state.filters.search}
                onChange={this.filterSearch}
                placeholder="Lead number"
              />
            </div>
            <RobinStats stats={this.state.stats} />
            <div className="sequence-table">
              <Table
                lined
                rounded
                rowSelection={null}
                columns={columns}
                rowKey={record => record.id}
                dataSource={this.state.data || []}
                pagination={this.state.pagination}
                loading={this.state.loadingCalls}
                onChange={this.handleTableChange}
                scroll={{ x: 'max-content' }}
                locale={{
                  emptyText: loadingCalls ? 'Loading calls...' : 'No calls found'
                }}
              />
            </div>
          </div>
        </div>

        {this.state.roundRobinModal ? (
          <RoundRobinModal
            roundRobinId={this.state.roundRobinModal}
            roundRobinModalType={this.state.roundRobinModalType}
            updateRoundRobin={this.state.updateRoundRobin}
            client={this.props.client}
            removeModal={this.removeRoundRobinModal}
            reload={this.getRoundRobins}
          />
        ) : null}

      </div>
    );
  }
}

export default withRouter(RoundRobin);
