import gql from 'graphql-tag';

export const getImports = gql`
  query imports($campaign_id: ID) {
    imports(campaign_id: $campaign_id) {
      id
      name
      type
      amount
      imported
      import_id
      mappings
      is_valid {
        linkedin
        phone
        mobile
        email
      }
    }
  }
`;

export const getMappings = gql`
  query {
    getMappings {
      id
      mappings
    }
  }
`;

export const getEndCampaign = gql`
  query endCampaign($id: ID) {
    endCampaign(id: $id) {
      campaign {
        id
        name
        status
        objective
        estimated_leads_count
        paid
        actual_budget
        status
        status_updated
        stats {
          total_leads
          estimated_total
          conversion_rate
          remaining
          completed
          generated
          qualified
          not_interested
          review
          review_interested
          review_not_interested
          disputed
          in_progress
          unassigned
          paid
          paid_interested
          paid_not_interested
          owed
          owed_interested
          owed_not_interested
        }
      }
      reps {
        rep {
          id
          picture
          display_name
        }
        stats {
          total_paid
          generated
          qualified
          not_interested
        }
      }
    }
  }
`;

export const campaignsTable = gql`
  query campaignsTable($status: String) {
    campaignsTable(status: $status) {
      campaigns {
        id
        name
        status
        sub_status
        objective
        threshold
        threshold_settings {
          duration
          lead_count
        }
        stats {
          total_leads
          estimated_total
          conversion_rate
          remaining
          completed
          generated
          qualified
          not_interested
          review
          review_interested
          review_not_interested
          disputed
          in_progress
          unassigned
          paid
          paid_interested
          paid_not_interested
          owed
          owed_interested
          owed_not_interested
        }
        agent_count
        actual_budget
        status_updated
        updatedAt
        price_per_lead
        wizard_version
        company {
          id
          name
          logo
        }
        agents {
          agent_id
          paid
          active
        }
      }
      reps {
        id
        picture
      }
      tab
      counts {
        active
        inactive
        draft
        completed
      }
    }
  }
`;

export const mySalesReps = gql`
  query {
    mySalesReps {
      id
      display_name
      picture
      company {
        company_id
      }
    }
  }
`;

export const campaignChannels = gql`
  query {
    campaigns {
      id
      name
      status
      objective
      employee {
        id
        first_name
        display_name
        title
        picture
      }
      company {
        id
        name
        logo
      }
    }
  }
`;

export const jobsTable = gql`
  query jobsTable($status: String) {
    jobsTable(status: $status) {
      campaigns {
        id
        name
        status
        objective
        threshold
        threshold_settings {
          duration
          lead_count
        }
        stats {
          estimated_total
          remaining
          completed
          in_progress
          review
        }
        agent_stats {
          conversion_rate
          completed
          generated
          qualified
          not_interested
          review
          review_interested
          review_not_interested
          paid
          paid_interested
          paid_not_interested
          owed
          owed_interested
          owed_not_interested
        }
        current_review_count
        current_completed_count
        estimated_leads_count
        rep_interested_count
        price_not_interested
        rep_review_count
        unassigned_count
        agent_count
        target_agent_count
        actual_budget
        budget_total
        paid
        budget_ppl
        budget_ppql
        status_updated
        price_per_lead

        employee {
          display_name
          title
          picture
        }
        company {
          id
          name
          logo
        }
        agents {
          agent_id
          paid
          active
        }
        guidelines
        customer_type
        customer_target
        customer_decision_maker
        customer_company_size
        customer_industry
        customer_locations {
          name
        }
        customer_age
        customer_education
        customer_gender
        customer_race
        customer_spend_start
        customer_spend_end
        files {
          id
          name
          type
          fileType
          url
          size
        }
      }
      tab
      counts {
        active
        inactive
        draft
        completed
      }
    }
  }
`;

export const searchCampaigns = gql`
  query searchCampaigns($filter: String, $page: Int) {
    searchCampaigns(filter: $filter, page: $page) {
      data {
        id
        name
        objective
        objective_type
        description
        short_description
        target_skills
        budget_ppl
        budget_ppql
        price_per_lead
        budget_total
        budget_bonus
        budget_bonus_count
        target_experience_text
        target_availability_text
        target_experience_short
        target_availability_short
        target_agent_count
        agents_remaining
        estimated_earnings
        target_languages
        invited_reps
        added_reps
        invites
        target_locations {
          name
        }
        threshold
        threshold_settings {
          lead_count
        }
        created
        status_updated
        company {
          id
          name
          logo
          overview
          industries {
            name
          }
        }
        applications {
          agent_id
          status
        }
      }
      total
    }
  }
`;

export const getTemplates = gql`
  query {
    getTemplates {
      id
      name
      type
      subject
      content
    }
  }
`;

export const getTemplate = gql`
  query getTemplate($template_id: ID) {
    getTemplate(template_id: $template_id) {
      id
      name
      type
      subject
      content
    }
  }
`;

export const getGlobalTemplates = gql`
  query {
    getGlobalTemplates {
      id
      name
      type
      information
      content
    }
  }
`;

export const getWizard = gql`
  query campaign($id: ID) {
    campaign(id: $id) {
      id
      status
      name
      objective
      calendly
      chilipiper
      type
      objective_type
      rep_type
      description
      invited_reps
      added_reps
      custom_title
      target_experience_level
      target_skills
      target_area_code
      target_locations {
        name
        lat
        lng
      }
      target_languages
      forceCalendar
      custom_domain
      custom_domain_type
      customer_type
      customer_company_size
      customer_industry
      customer_decision_maker
      threshold
      threshold_settings {
        duration
        lead_count
      }
      customer_locations {
        name
        lat
        lng
      }
      customer_spend_start
      customer_spend_end
      guidelines
      meeting_required
      schedule_required
      schedule_timezone
      schedule_days {
        day
        start
        end
      }
      questions {
        type
        question
        options
      }
      benefits
      objections {
        name
        responses
      }
      required {
        field
        type
        values
        locations {
          name
          lat
          lng
        }
        min
        max
        required
      }
      custom_fields {
        label
        type
        value
      }
      sequences {
        steps {
          type
          duration
          duration_type
          template_id
        }
      }
      sequence_list
      budget_ppl
      budget_ppql
      budget_total
      budget_bonus
      target_agent_count
    }
  }
`;

export const getCampaign = gql`
  query campaign($id: ID) {
    campaign(id: $id) {
      name
      type
      objective
      calendly
      description
      cohort
      short_description
      guidelines
      script
      target_experience_level
      target_availability
      target_agent_count
      target_area_code
      target_skills
      target_languages
      target_locations {
        name
        lat
        lng
      }
      instructions
      files {
        id
        name
        type
        fileType
        url
        size
      }
      leads_created_count
      customer_type
      customer_target
      customer_locations {
        name
        lat
        lng
      }
      customer_decision_maker
      customer_age
      customer_gender
      customer_race
      customer_education
      customer_company_scope
      customer_job_position
      customer_industry
      customer_languages
      required {
        field
        type
        values
        locations {
          name
          lat
          lng
        }
        min
        max
        required
      }
      forceCalendar
      questions {
        type
        question
        options
      }
      budget_ppl
      budget_ppql
      budget_total
      budget_bonus
      budget_bonus_count
      status
      custom_fields {
        type
        label
        value
      }
    }
  }
`;

export const getDuplicate = gql`
  query campaign($id: ID) {
    campaign(id: $id) {
      name
      type
      objective
      calendly
      description
      cohort
      short_description
      guidelines
      script
      target_experience_level
      target_availability
      target_agent_count
      target_area_code
      target_skills
      target_languages
      target_locations {
        name
        lat
        lng
      }
      instructions
      files {
        id
        name
        type
        fileType
        url
        size
      }
      customer_type
      customer_target
      customer_locations {
        name
        lat
        lng
      }
      customer_decision_maker
      customer_age
      customer_gender
      customer_race
      customer_education
      customer_company_scope
      customer_job_position
      customer_industry
      customer_languages
      required {
        field
        type
        values
        locations {
          name
          lat
          lng
        }
        min
        max
        required
      }
      forceCalendar
      questions {
        type
        question
        options
      }
      budget_ppl
      budget_ppql
      budget_total
      budget_bonus
      budget_bonus_count
    }
  }
`;

export const campaignAgents = gql`
  query campaign($id: ID) {
    campaign(id: $id) {
      agents {
        id
        agent_id
        phone_number
      }
    }
  }
`;
