import gql from 'graphql-tag';

export const markAsRead = gql`
  mutation markAsRead($notification_id: String!) {
    markAsRead(notification_id: $notification_id)
  }
`;

export const markAllRead = gql`
  mutation markAllRead {
    markAllRead
  }
`;

export const createNotificationCampaign = gql`
  mutation createNotificationCampaign($company_id: String!) {
    createNotificationCampaign(company_id: $company_id)
  }
`;

export const removeNotificationCampaign = gql`
  mutation removeNotificationCampaign($company_id: String!) {
    removeNotificationCampaign(company_id: $company_id)
  }
`;
