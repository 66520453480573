import gql from 'graphql-tag';

export const calendlyEventTypes = gql`
  query {
    calendlyEventTypes
  }
`;

export const validateEmail = gql`
  query validateEmail($email: String!) {
    validateEmail(email: $email)
  }
`;
export const validatePhone = gql`
  query validatePhone($phone: String!) {
    validatePhone(phone: $phone)
  }
`;
