import React, { Component } from 'react';
import { Modal, Button, Input, message } from 'antd';
import { isPassword } from '_assets/js/helpers';
import { updatePassword } from '_graphql/mutations/employee';
import './ChangePassword.scss';

export default class ChangePassword extends Component {
  state = {
    visible: true,
    loading: false,
    newPassword: { current: '', new: '', confirm: '' }
  };
  hideModal = () => this.setState({ visible: false });
  shouldDisable = () => {
    const info = this.state.newPassword;
    let disable = false;
    if (!isPassword(info.new)) disable = true;
    if (info.new !== info.confirm) disable = true;
    return disable;
  };
  updatePassword = (field, value) => {
    const newPassword = Object.assign({}, this.state.newPassword);
    newPassword[field] = value !== undefined ? value : null;
    this.setState({ newPassword });
  };
  savePassword = () => {
    this.props.client
      .mutate({ variables: { ...this.state.newPassword }, mutation: updatePassword })
      .then(result => {
        if (result && result.data && result.data.updatePassword) {
          message.success('Your password has been updated');
          this.hideModal();
        } else {
          message.error('Current password does not match password we have stored, try again');
        }
      });
  };
  render() {
    const { newPassword } = this.state;
    return (
      <Modal
        wrapClassName="change-password-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        style={{ top: 60 }}
        width={600}
        closable={false}
        maskClosable={false}
      >
        <h3 className="ra-title">Change Password</h3>

        <div className="ra-body">
          <div className="ra-message">
            <h3 className="ra-review">Current Password</h3>
            <Input
              placeholder=""
              name="current-password"
              autoComplete="off"
              type="password"
              onChange={e => this.updatePassword('current', e.target.value)}
              value={newPassword.current}
              size="large"
              className="forms"
            />

            <h3 className="ra-review">New Password</h3>
            <Input
              placeholder=""
              name="new-password"
              type="password"
              autoComplete="off"
              onChange={e => this.updatePassword('new', e.target.value)}
              value={newPassword.new}
              size="large"
              className="forms"
            />

            <h3 className="ra-review">Confirm Password</h3>
            <Input
              placeholder=""
              name="confirm-password"
              type="password"
              autoComplete="off"
              onChange={e => this.updatePassword('confirm', e.target.value)}
              value={newPassword.confirm}
              size="large"
              className="forms"
            />
          </div>
        </div>

        <div className="ra-actions">
          <Button className="btn btn-default" onClick={this.hideModal}>
            Cancel
          </Button>
          <Button
            disabled={this.shouldDisable()}
            className="btn btn-primary"
            onClick={this.savePassword}
            loading={this.state.loading}
          >
            Change Password
          </Button>
        </div>
      </Modal>
    );
  }
}
