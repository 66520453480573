import React, { Component } from 'react';
import { Header, Card } from '_seriph';
import { numberWithCommas, formatPercent } from '_assets/js/helpers';
import './RobinStats.scss';

class RobinStats extends Component {
  render() {
    const { stats } = this.props;

    const avgAnswered = parseFloat(stats?.answered/stats?.total) * 100;
    const avgMissed = parseFloat(stats?.missed/stats?.total) * 100;
    const avgFailed = parseFloat(stats?.failed/stats?.total) * 100;

    return (
      <div id="robin-stats">
        <Card>
          <div className="descriptor">
            Total Calls
          </div>
          <Header size="2" font="sans">
            {numberWithCommas(stats?.total)}
          </Header>
        </Card>
        <Card>
          <div className="descriptor">
            Answered
          </div>
          <Header size="2" font="sans">
            {numberWithCommas(stats?.answered)}<span>/ {formatPercent(avgAnswered, 1)}</span>
          </Header>
        </Card>
        <Card>
          <div className="descriptor">
            Missed
          </div>
          <Header size="2" font="sans">
            {numberWithCommas(stats?.missed)}<span>/ {formatPercent(avgMissed, 1)}</span>
          </Header>
        </Card>
        <Card>
          <div className="descriptor">
            Failed
          </div>
          <Header size="2" font="sans">
            {numberWithCommas(stats?.failed)}<span>/ {formatPercent(avgFailed, 1)}</span>
          </Header>
        </Card>
      </div>
    );
  }
}

export default RobinStats;
