import React, { Component } from 'react';
import { Button } from '_seriph';

import './CarouselList.scss';

import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

export default class CarouselList extends Component {
  state = {
    scrollLeft: 0,
    scrollWidth: 0,
    offsetWidth: 0
  };

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
  }

  componentDidMount() {
    if (this.scrollRef?.current?.scrollWidth) {
      this.setState({
        scrollWidth: this.scrollRef.current.scrollWidth,
        offsetWidth: this.scrollRef.current.offsetWidth
      });
    }
  }

  handleScroll = ({ scrollLeft }) => {
    this.scrollRef.current.scrollTo({ left: scrollLeft, behavior: 'smooth' });
  };

  render() {
    const { children, classNames } = this.props;
    const { scrollWidth, offsetWidth, scrollLeft } = this.state;

    return (
      <div className={`carousel-list ${classNames ? classNames : ''}`}>
        <div
            className="control control-background-left"
            style={scrollLeft === 0 ? { visibility: 'hidden' } : {}}

        >
            <Button
            onClick={() => this.setState({ scrollLeft: 0 }, () => this.handleScroll({ scrollLeft: 0 }))}
            circle
            size="tiny"
            className="control control-left"
            style={scrollLeft === 0 ? { visibility: 'hidden' } : {}}
            >
            <IconChevronLeft />
            </Button>
        </div>
        <div ref={this.scrollRef} className="carousel-scroll">
          {children}
        </div>
        <div
            className="control control-background-right"
            style={scrollLeft === scrollWidth - offsetWidth ? { visibility: 'hidden' } : {}}

        >
            <Button
            onClick={() => this.setState({ scrollLeft: scrollWidth - offsetWidth }, () => this.handleScroll({ scrollLeft: scrollWidth - offsetWidth }))}
            circle
            size="tiny"
            className="control-right"
            style={scrollLeft === scrollWidth - offsetWidth ? { visibility: 'hidden' } : {}}
            >
            <IconChevronRight />
            </Button>
        </div>
      </div>
    );
  }
}
