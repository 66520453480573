export const hourFields = {
  free: { title: 'Free Time', description: '1 - 2 hours', value: 'free' },
  part: { title: 'Part Time', description: '3 - 5 hours', value: 'part' },
  full: { title: 'Full Time', description: '6 - 8 hours', value: 'full' }
};

export const touchpointMinimums = [
  {
    title: '100 touchpoints',
    description: 'Minimum per day',
    value: '100',
    helper: '$'
  },
  {
    title: '250 touchpoints',
    description: 'Minimum per day',
    value: '250',
    helper: '$$'
  },
  {
    title: '400 touchpoints',
    description: 'Minimum per day',
    value: '400',
    helper: '$$$'
  }
];

export const generateMinimums = [
  {
    title: '100 leads',
    description: 'Minimum per day',
    value: '100',
    helper: '$'
  },
  {
    title: '250 leads',
    description: 'Minimum per day',
    value: '250',
    helper: '$$'
  },
  {
    title: '400 leads',
    description: 'Minimum per day',
    value: '400',
    helper: '$$$'
  }
];
