import React, { Component } from 'react';
import { Accordion, Checkbox, Card } from '_seriph';
import { IconViewfinder, IconRocket, IconCircleSquare, IconStars } from '@tabler/icons-react';
import './Search.scss';

const Panel = Accordion.Panel;

export default class Search extends Component {
  render() {
    const { search } = this.props;
    return (
      <div id="discover-search">
        <Card id="search-filters">
          <Accordion defaultActiveKey={['company', 'markets', 'availability']}>
            {search.company.id ? (
              <Panel header="Company" key="company" menu icon={<IconRocket />}>
                <Checkbox checked={true} onChange={() => this.props.updateCompany(null, null)}>
                  {search.company.name}
                </Checkbox>
              </Panel>
            ) : null}
            <Panel header="Objective" key="markets" menu icon={<IconViewfinder />}>
              <Checkbox
                checked={search.lead_gen}
                onChange={e => this.props.updateSearch('lead_gen', e.target.checked)}
              >
                Generate New Leads
              </Checkbox>
              <Checkbox
                checked={search.lead_inbound}
                onChange={e => this.props.updateSearch('lead_inbound', e.target.checked)}
              >
                Connect with Warm leads
              </Checkbox>
              <Checkbox
                checked={search.lead_outbound}
                onChange={e => this.props.updateSearch('lead_outbound', e.target.checked)}
              >
                Qualify Cold Leads
              </Checkbox>
            </Panel>
            <Panel header="Availability" key="availability" menu icon={<IconCircleSquare />}>
              <Checkbox
                checked={search.availability_full}
                onChange={e => this.props.updateSearch('availability_full', e.target.checked)}
              >
                Full time (40+ week)
              </Checkbox>
              <Checkbox
                checked={search.availability_part}
                onChange={e => this.props.updateSearch('availability_part', e.target.checked)}
              >
                Part time (up to 25 hrs)
              </Checkbox>
              <Checkbox
                checked={search.availability_free}
                onChange={e => this.props.updateSearch('availability_free', e.target.checked)}
              >
                Free time (when they can)
              </Checkbox>
            </Panel>
            <Panel header="Experience" key="experience" menu icon={<IconStars />}>
              <Checkbox
                checked={search.exp_entry}
                onChange={e => this.props.updateSearch('exp_entry', e.target.checked)}
              >
                Pro (1-2 years)
              </Checkbox>
              <Checkbox
                checked={search.exp_intermediate}
                onChange={e => this.props.updateSearch('exp_intermediate', e.target.checked)}
              >
                Expert (3-5 years)
              </Checkbox>
              <Checkbox
                checked={search.exp_expert}
                onChange={e => this.props.updateSearch('exp_expert', e.target.checked)}
              >
                Specialist (6+ years)
              </Checkbox>
            </Panel>
          </Accordion>
        </Card>
      </div>
    );
  }
}
