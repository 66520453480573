import { currentCall } from './twilio';

export const nonApiCall = async (Device, lead, props) => {
  const call = currentCall(lead);
  let callData = null;
  try {
    callData = await Device.connect({
      params: {
        lead_id: lead?.id,
        sequence_id: lead?.sequence_id,
        touchpoint_id: lead?.current_task?.touchpoint?.id,
        day: lead?.current_task?.day,
        campaign_id: lead?.campaign_id,
        company_id: lead?.company_id,
        agent_id: lead?.agent_id,
        callField: call.name,
        callKey: call.key,
        number: call.number,
        rep_phone: props.repPhoneNumber,
        socketId: props.socketId,
        autoDialExtensions: props?.settings?.autoDialExtensions,
        timeout: props?.settings?.timeout,
        fromDialer: 'mainDialer'
      }
    });
  } catch (err) {
    console.error(err);
    callData = null;
  }

  return callData;

};