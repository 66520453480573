import React from 'react';
import { IconUserPlus } from '@tabler/icons-react';
import moment from 'moment';

export default function New(props) {
  const { activity, user } = props;

  return (
    <div className={'lead-activity ' + activity.type}>
      <div className="act-icon">
        <IconUserPlus />
      </div>
      <div className="descript">
        <b>{user.display_name}</b> created a new lead
        <div className="act-tstamp">
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;{moment(activity.createdAt * 1).format('MMM D @ h:mm a')}
        </div>
      </div>
    </div>
  );
}
