import gql from 'graphql-tag';

export const verifyAccount = gql`
  mutation verifyAccount($verification: String!) {
    verifyAccount(verification: $verification)
  }
`;

export const resendVerify = gql`
  mutation resendVerify($email: String!) {
    resendVerify(email: $email)
  }
`;

export const forgotPassword = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const changePassword = gql`
  mutation changePassword($password: String!, $token: String!) {
    changePassword(password: $password, token: $token)
  }
`;

export const createInterest = gql`
  mutation createInterest($email: String!, $role_type: String!) {
    createInterest(email: $email, role_type: $role_type)
  }
`;

export const submitIntegration = gql`
  mutation submitIntegration($name: String!, $description: String!, $category: String!) {
    submitIntegration(name: $name, description: $description, category: $category)
  }
`;
