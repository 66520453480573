import React, { Component } from 'react';
import { Select } from '_seriph';
import { IconCalendar } from '@tabler/icons-react';
import { dateRanges } from '_constants/datepicker';
import './DateRange.scss';

export default class DateRange extends Component {
  render() {
    const { range, selectRange } = this.props;

    return (
      <div id="date-range">
        <Select value={range} onChange={selectRange} style={{ width: 200 }} icon={<IconCalendar />}>
          {dateRanges.map(r => (
            <Select.Option key={r.value} value={r.value}>
              {r.title}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  }
}
