import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Modal, Button, message } from 'antd';
import axios from 'axios';
import { launchCampaign, importLeads } from '_graphql/mutations/campaign';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { getErrors } from '_assets/js/helpers';
import LaunchingStatus from './_components/LaunchingStatus/LaunchingStatus';
import './LaunchCampaign.scss';

class LaunchCampaign extends Component {
  state = {
    visible: true,
    loading: true,
    customer: null,
    saving: false,
    launching: false,
    status: { step: 0, name: '', percent: 0 }
  };
  componentDidMount = () => {
    this.loadBanking();
  };
  loadBanking = () => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/v1/stripe/company/customer`, {
        headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') }
      })
      .then(response => {
        if (response && response.data && response.data.customer) {
          this.setState({ customer: response.data.customer });
        }
      })
      .catch(() => {
        message.error(
          'Payment information is required to Launch a Campaign, please click the link below to add then try again.'
        );
        this.setState({ customer: null });
      })
      .finally(() => this.setState({ loading: false }));
  };
  importLeads = () => {
    this.setState({ launching: true, status: { step: 1, percent: 30, name: 'Importing Leads' } });
    this.props.client
      .mutate({ variables: { campaign_id: this.props.campaignId }, mutation: importLeads })
      .then(result => {
        if (result && result.data && result.data.importLeads > 0) {
          setTimeout(this.fillAndStart, 1500);
        } else {
          this.setState({ launching: false });
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not import leads, try again');
        this.setState({ launching: false });
      });
  };
  fillAndStart = () => {
    this.setState({ launching: true, status: { step: 2, percent: 60, name: 'Filling Budget' } });
    this.props.client
      .mutate({ variables: { campaign_id: this.props.campaignId }, mutation: launchCampaign })
      .then(result => {
        if (
          result &&
          result.data &&
          result.data.launchCampaign &&
          result.data.launchCampaign.id === this.props.campaignId
        ) {
          this.setState({
            launching: true,
            status: { step: 3, percent: 90, name: 'Launching...' }
          });
          setTimeout(this.finishLaunch, 1500);
        } else {
          this.setState({ launching: false });
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not launch Campaign, try again');
        this.setState({ launching: false });
      });
  };
  finishLaunch = () => {
    message.success('Campaign is now live!');
    this.props.history.push('/campaigns');
  };
  hideModal = () => this.setState({ visible: false });
  launchCampaign = () => {
    if (this.props.campaign.objective === 'qualify') {
      this.importLeads();
    } else {
      this.setState({
        launching: true,
        status: { step: 1, percent: 30, name: 'Gathering Information' }
      });
      setTimeout(this.fillAndStart, 1500);
    }
  };
  render() {
    const { campaign } = this.props;
    const { customer, loading, launching, status } = this.state;

    const budget = campaign.budget_total ? campaign.budget_total * 1 : 0;
    let fees = budget * 0.029 + 0.3;
    const source =
      customer && customer.sources && customer.sources.data && customer.sources.data.length
        ? customer.sources.data[0]
        : null;
    const isBank = source && source.object === 'bank_account' ? true : false;
    if (isBank) fees = Math.min(5, budget * 0.008);
    const realBudget = budget - fees;
    return (
      <Modal
        wrapClassName="launch-campaign-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        width={540}
        closable={false}
        maskClosable={false}
      >
        {launching ? (
          <LaunchingStatus total={3} {...status} />
        ) : (
          <React.Fragment>
            <h3>Let&apos;s get to work! </h3>
            <div className="hiring">
              <div className="hiring-info">
                <p>
                  Your campaign is ready to go, once Launched and Reviewed it will be discoverable
                  by Sales Reps and work can begin! Please review the information below so we can
                  get started:
                </p>
                <div className="start-table">
                  <h4>Campaign Budget</h4>
                  <div className="st-row">
                    <div>
                      <b>Total Budget</b>
                    </div>
                    <div>
                      <b>
                        $
                        {(budget - fees)
                          .toFixed(2)
                          .toLocaleString(undefined, { minimumFractionDigits: 2 })}
                      </b>
                    </div>
                  </div>
                  {source ? (
                    <React.Fragment>
                      <div className="st-row">
                        <div style={{ paddingLeft: '40px' }}>Campaign Budget</div>
                        <div>
                          $
                          {budget
                            .toFixed(2)
                            .toLocaleString(undefined, { minimumFractionDigits: 2 })}
                        </div>
                      </div>
                      <div className="st-row">
                        <div style={{ paddingLeft: '40px' }}>
                          {isBank ? 'ACH Fee' : 'Credit Card Fee'}
                        </div>
                        <div>
                          -$
                          {fees.toFixed(2).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                        </div>
                      </div>
                      {campaign.objective === 'leadgen' && (
                        <div className="st-row">
                          <div style={{ paddingLeft: '40px' }}>Est. Generated Leads</div>
                          <div>
                            {Math.floor(realBudget / campaign.budget_ppl)} leads ($
                            {(campaign.budget_ppl || 0)
                              .toFixed(2)
                              .toLocaleString(undefined, { minimumFractionDigits: 0 })}{' '}
                            / lead)
                          </div>
                        </div>
                      )}
                      {campaign.objective === 'qualify' && (
                        <div className="st-row">
                          <div style={{ paddingLeft: '40px' }}>Est. Qualified Leads</div>
                          <div>
                            {Math.floor(realBudget / campaign.budget_ppql)} leads ($
                            {(campaign.budget_ppql || 0)
                              .toFixed(2)
                              .toLocaleString(undefined, { minimumFractionDigits: 0 })}{' '}
                            / lead)
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ) : null}
                  <div className="st-row">
                    <div>
                      <b>Payment Method</b>
                    </div>
                    <div>
                      {source && !loading && (
                        <b>
                          {isBank ? source.bank_name : source.brand} ({source.last4})
                        </b>
                      )}
                      {!source && !loading && (
                        <Link to={`/settings/paymentmethod`}>Add Payment Method</Link>
                      )}
                      {loading && <FontAwesomeIcon icon={faCircleNotch} spin />}
                    </div>
                  </div>
                </div>
                <p className="of-note">
                  <b>Important:</b> Any funds not used during the campaign will automatically be
                  refunded to you once the campaign is ended.
                </p>
              </div>
            </div>
            <div className="hiring-actions">
              <Button className="btn btn-default" onClick={this.hideModal}>
                Cancel
              </Button>
              <Button
                className="btn btn-primary"
                loading={launching}
                disabled={!source}
                onClick={this.launchCampaign}
              >
                Launch Campaign
              </Button>
            </div>
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

export default withRouter(LaunchCampaign);
