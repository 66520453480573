import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App/App';
import * as serviceWorker from './serviceWorker';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import TagManager from 'react-gtm-module';
import store from './Store/store';
import { getUser } from '_assets/js/helpers';
import moment from 'moment';

moment.locale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'seconds',
    ss: '%ss',
    m: 'a minute',
    mm: '%dm',
    h: 'an hour',
    hh: '%dh',
    d: 'a day',
    dd: '%dd',
    M: 'a month',
    MM: '%dM',
    y: 'a year',
    yy: '%dY'
  }
});

const user = getUser();
const dataLayer = user
  ? {
      userId: user.id,
      userEmail: user.email,
      userName: user.display_name,
      userRole: user.role_type,
      userCompanyId: user.company || null,
      userCompany: user.company_name || null,
      userPhone: user.phone_number || null
    }
  : {};

// remove null keys to avoid data errors
const dataLayerNotNull = Object.keys(dataLayer)
  .filter(k => dataLayer[k] !== null)
  .reduce((a, k) => ({ ...a, [k]: dataLayer[k] }), {});

Sentry.init({
  dsn: 'https://5496f9df14604a8189091b76e374eddb@o931218.ingest.sentry.io/5880108',
  integrations: [new Integrations.BrowserTracing()],
  initialScope: {
    user: { ...dataLayerNotNull }
  },
  environment: process.env.NODE_ENV === 'production' ? 'production' : 'development',
  tracesSampleRate: 0.1
});

const tagManagerArgs = {
  gtmId: 'GTM-TF2WN4Q',
  dataLayer: dataLayerNotNull
};
TagManager.initialize(tagManagerArgs);

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_PATH}/v1/graphql`
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('JWT');
  return {
    headers: { ...headers, Authorization: token ? `JWT ${token}` : '' }
  };
});

const errorLink = onError(({ networkError }) => {
  if (networkError?.statusCode === 401) {
    localStorage.removeItem('JWT');
    window.location.href = '/';
    return false;
  }
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
};

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions: defaultOptions
});

const SellX = () => (
  <React.Fragment>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
          <App client={client} />
        </StripeProvider>
      </Provider>
    </ApolloProvider>
  </React.Fragment>
);

ReactDOM.render(<SellX />, document.getElementById('root'), () => {
  const loading = document.getElementById('loading-logo');
  const amount = window.location.hostname === 'localhost' ? 1000 : 750;
  if (loading) {
    setTimeout(() => {
      document.getElementById('loading-logo').className = 'hide-loading';
    }, amount);
  }
});

serviceWorker.unregister();
