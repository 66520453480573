import React from 'react';
import { Button, Tag } from '_seriph';
import { companyStages } from '_constants/onboarding';
import { IconMoodHappy, IconMoodNeutral, IconMoodSad } from '@tabler/icons-react';
import moment from 'moment';

const needsMap = companyStages.find(c => c.key === 'needs')?.inputs[0].options;
const hearAboutMap = companyStages.find(c => c.stage === 7)?.inputs[0].options;

const reviewTypeMap = {
  good: { type: 'success', name: 'Good fit', icon: <IconMoodHappy /> },
  timing: { type: 'warning', name: 'Bad timing', icon: <IconMoodNeutral /> },
  unready: { type: 'warning', name: 'Not Ready', icon: <IconMoodNeutral /> },
  bad: { type: 'danger', name: 'Bad fit', icon: <IconMoodSad /> }
};

export const getColumns = (showAppModal, showOnboardModal) => {
  return [
    {
      title: 'Company',
      dataIndex: 'company_name',
      render: (column, data) => (
        <div className="onboard-box">
          <div className="name">
            <b>{data.company_name}</b>
          </div>
          <div className="contact">
            <a href={data.company_website}>{data.company_website}</a>
          </div>
          <div className="contact">{data.company_size} Employees</div>
          <div className="contact">{data.industries.join(', ')}</div>
        </div>
      )
    },
    {
      title: 'Employee',
      dataIndex: 'name',
      render: (column, data) => {
        const hearAboutData = data.hear_about
          .map(c => {
            const hearAbout = hearAboutMap.find(n => n.value === c);
            return hearAbout.title;
          })
          .join(', ');

        return (
          <div className="onboard-box">
            <div className="name">
              <b>{data.name}</b>
            </div>
            <div className="contact">
              <a href={`mailto:${data.email}`}>{data.email}</a>
            </div>
            <div className="contact">
              <a href={`tel:${data.phone}`}>{data.phone}</a>
            </div>
            <div className="contact">
              How?: <b>{hearAboutData}</b>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Desires',
      dataIndex: 'desired_start',
      render: (column, data) => (
        <div className="onboard-box">
          <div className="name">
            Start: <b>{column}</b>
          </div>
          <div className="name">
            Engagement: <b>{data.desired_engagement}</b>
          </div>
          <div className="contact">
            Remote: <b>{data.ok_with_remote}</b>
          </div>
          <div className="contact">
            Needs:&nbsp;
            <b>
              {data.needs
                .map(c => {
                  const need = needsMap.find(n => n.value === c);
                  return need.title;
                })
                .join(', ')}
            </b>
          </div>
        </div>
      )
    },
    {
      title: 'Review',
      dataIndex: 'meeting_info',
      render: (column, data) => {
        return (
          <div className="onboard-box">
            {data.review.note ? (
              <div className="contact">
                Notes: <b>{data.review.note}</b>
              </div>
            ) : null}
            {data.calendly.created_at ? (
              <div className="contact">
                Submitted: <b>{moment(data.calendly.created_at).format('M/D/YY h:mma')}</b>
              </div>
            ) : null}
            {data.review.revisit ? (
              <div className="contact">
                Revisit?: <b>{moment(data.review.revisit * 1).format('M/D/YY h:mma')}</b>
              </div>
            ) : null}
          </div>
        );
      }
    },
    {
      title: 'Meeting Information',
      dataIndex: 'meeting_info',
      render: (column, data) => {
        return (
          <div className="onboard-box">
            {data.calendly.start_time ? (
              <div className="contact">
                Meeting Time: <b>{moment(data.calendly.start_time).format('ll @ h:mma')}</b>
              </div>
            ) : null}
            {data.calendly.join_url ? (
              <div className="contact">
                Meeting Link: <a href={data.calendly.join_url}>Google Meet</a>
              </div>
            ) : null}
          </div>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'review_status',
      fixed: 'right',
      render: (column, data) => {
        const typeMap = reviewTypeMap[data.review.type];
        return typeMap ? (
          <Tag type={typeMap.type}>
            {typeMap.icon}
            {typeMap.name}
          </Tag>
        ) : (
          <Tag>Not Reviewed</Tag>
        );
      }
    },
    {
      title: '',
      dataIndex: 'take_actions',
      align: 'right',
      fixed: 'right',
      render: (column, data) => {
        if (!data.employee_id && data.review.type === 'good') {
          return (
            <div className="multi">
              <Button type="primary" onClick={() => showOnboardModal(data)}>
                Onboard
              </Button>
            </div>
          );
        } else if (data.employee_id) {
          return (
            <div className="multi">
              <Button type="primary" onClick={() => showOnboardModal(data)}>
                Joined
              </Button>
            </div>
          );
        }
        return (
          <div className="multi">
            <Button type="secondary" onClick={() => showAppModal(data)}>
              Review
            </Button>
          </div>
        );
      }
    }
  ];
};
