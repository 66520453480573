import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Infotip from '_shared/Infotip/Infotip';
import { Locked } from '_shared/Locked/Locked';
import { SKILLS } from '_assets/js/skills';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { calendlyEventTypes } from '_graphql/queries/integration';
import { Card, Input, Select, Button, Toggle, Selection, SelectMultiple, Checkbox } from '_seriph';
import { AREA_CODES } from '_constants/areaCodes';
import './SalesReps.scss';

class SalesReps extends Component {
  state = {
    eventTypes: []
  };
  componentDidMount = () => {
    if (this.props.save) this.props.save();
    this.getEventTypes();
  };
  getEventTypes = () => {
    this.props.client.query({ query: calendlyEventTypes }).then(result => {
      const eventTypes = result.data.calendlyEventTypes || [];
      this.setState({ eventTypes });
    });
  };
  render() {
    const { campaign, integration, update, actions, fromReview, reps, isLocked } = this.props;
    const { eventTypes } = this.state;

    /* Check if has custom domain */
    let customDomains = integration?.custom_domains?.filter(cd => cd.active).map(cd => cd.domain) || [];

    const depCustom = integration?.custom_domain?.active ? integration?.custom_domain : null;
    if (depCustom && !customDomains.find(c => c.domain === depCustom.domain)) {
      customDomains.push(depCustom.domain);
    }

    const internalReps = reps.filter(r => r?.company?.company_id ? true: false);
    const xdrReps = reps.filter(r => r?.company?.company_id ? false: true);

    return (
      <Card id="wizard-reps" className="wz-card">
        <div className="wz-container sx-form">
          {/* HEADER */}
          <div className="wz-card-header">
            <h3>Tell us the type of reps you need</h3>
            <h5>We’ll match your campaign with reps that best match.</h5>
          </div>

          {!isLocked && (campaign.rep_type === 'internal' || campaign.rep_type === 'both') ? (
            <div className="field">
              <label>
                Add internal reps <span className="optional">(optional)</span>
                <Infotip title="Add reps from your company when the campaign launches" />
              </label>
              <SelectMultiple
                placeholder="Search my reps..."
                notFoundContent="Type to find sales reps..."
                autocomplete={internalReps}
                value={campaign.added_reps || []}
                onChange={val => update('added_reps', val)}
                type="users"
              />
            </div>
          ) : null}

          {!isLocked && (campaign.rep_type === 'xdr' || campaign.rep_type === 'both') ? (
            <div className="field">
              <label>
                Invite XDRs <span className="optional">(optional)</span>
                <Infotip title="Invite reps you have worked with before to jumpstart your campaign" />
              </label>
              <SelectMultiple
                placeholder="Search XDR's. .."
                notFoundContent="Type to find sales reps..."
                autocomplete={xdrReps}
                value={campaign.invited_reps || []}
                onChange={val => update('invited_reps', val)}
                type="users"
              />
            </div>
          ) : null}

          {/* CONTENT */}
          { campaign.rep_type !== 'internal' ? (
            <div
              className={!campaign.target_experience_level && fromReview ? 'field invalid' : 'field'}
            >
              <label>
                Experience Level
                <Locked isLocked={isLocked} />
              </label>
              <Selection
                disabled={isLocked}
                values={[
                  { title: 'Any', value: 'any' },
                  { title: 'Pro', description: '1 - 2 years', value: 'entry' },
                  { title: 'Expert', description: '3 - 5 years', value: 'intermediate' },
                  { title: 'Specialist', description: '6+ years', value: 'expert' }
                ]}
                value={campaign.target_experience_level}
                onChange={val => update('target_experience_level', val)}
                full={true}
              />
            </div>
          ) : null }

          { campaign.rep_type !== 'internal' ? (
            <div
              className={campaign.target_skills.length <= 0 && fromReview ? 'field invalid' : 'field'}
            >
              <label>What skills should reps have?</label>
              <SelectMultiple
                placeholder="Search skills..."
                notFoundContent="Type to search skills..."
                autocomplete={SKILLS}
                value={campaign.target_skills || []}
                onChange={val => update('target_skills', val)}
              />
            </div>
          ) : null }

          {campaign.objective === 'qualify' ? (
            <div className="custom-domain">
              <div className={!campaign.custom_title && fromReview ? 'field invalid' : 'field'}>
                <label>
                  What should the rep&apos;s title be?
                  {isLocked ? <Locked isLocked={isLocked} /> : null}
                  {!isLocked ? (
                    <Infotip title="This is how the rep will address themselves as well as show up in email signatures." />
                  ) : null}
                </label>
                <Input
                  placeholder="Sales Representative"
                  onChange={e => update('custom_title', e.target.value)}
                  value={campaign.custom_title}
                />
              </div>
              <div className={!campaign.custom_domain && fromReview ? 'field invalid' : 'field'}>
                <label>What domain should reps use for Email?</label>
                <Select
                  className="forms"
                  dropdownClassName="seriph-dropdown custom-domain-drop"
                  size="large"
                  value={campaign.custom_domain}
                  onChange={val => update('custom_domain', val)}
                >
                  <Select.Option value="sellx.org">
                    salesrep<b>@sellx.org</b>
                  </Select.Option>
                  {customDomains.map(cd => {
                    return (
                      <Select.Option key={cd} value={cd}>
                        salesrep<b>@{cd}</b>
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
              <div className="field">
                <label>
                  Target Phone Number Area-Code? <span className="optional">(optional)</span>
                </label>
                <Select
                  className="forms"
                  dropdownClassName="seriph-dropdown"
                  size="large"
                  value={campaign.target_area_code || 'any'}
                  onChange={val => update('target_area_code', val)}
                  showSearch
                  filterOption={(input, option) => {
                    return option?.props?.srch?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                >
                  <Select.Option value="any" srch="Any">Any</Select.Option>
                  {AREA_CODES.map((e, i) => (
                    <Select.Option value={e.code} srch={`${e.code} (${e.state})`} key={'area' + i + 1}>
                      {e.code} ({e.state})
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          ) : null}

          {campaign.objective === 'qualify' ? (
            <div className="check-wrapper">
              <Checkbox
                checked={campaign.meeting_required}
                onChange={e => update('meeting_required', e.target.checked)}
                disabled={isLocked}
              >
                Are meetings required for a Qualified Lead?
                {isLocked ? <Locked isLocked={isLocked} /> : null}
                {!isLocked ? (
                  <Infotip title="For a lead to be interested, they must schedule a meeting between your company and the lead" />
                ) : null}
              </Checkbox>
            </div>
          ) : null}


          {campaign.objective === 'qualify' ? (
            <div className="calendly">
              <div className="field">
                <label>
                  Schedule Meetings using Calendly? <span className="optional">(optional)</span>
                </label>
                {integration && integration.calendly && integration.calendly.url ? (
                  <React.Fragment>
                    <Select
                      className="forms calendly-select"
                      size="large"
                      value={campaign.calendly || undefined}
                      placeholder="Select a Calendly meeting URL"
                      onChange={val => update('calendly', val)}
                    >
                      {eventTypes.map((d, i) => (
                        <Select.Option key={'evt-' + i} value={d}>
                          {d}
                        </Select.Option>
                      ))}
                    </Select>
                    <FontAwesomeIcon className="icon-input" icon={faCalendarAlt} />
                  </React.Fragment>
                ) : (
                  <Link to="/settings/integrations">
                    <Button type="primary">Setup your Calendly integration</Button>
                  </Link>
                )}
              </div>
            </div>
          ) : null}

          {campaign.objective === 'qualify' ? (
            <div className="chilipiper">
              <div className="field">
                <label>
                  Schedule Meetings using Chilipiper? <span className="optional">(optional)</span>
                  {isLocked ? <Locked isLocked={isLocked} /> : null}
                  {!isLocked ? (
                    <Infotip title="Sales reps will schedule meetings directly to your calendar if they are qualified. Requires Concierge Chilipiper account." />
                  ) : null}
                </label>
                {integration?.chilipiper?.domain ? (
                  <div className="chilipiper-check">
                    <Toggle
                      checked={campaign?.chilipiper}
                      onChange={checked => update('chilipiper', checked)}
                    />
                    <FontAwesomeIcon className="icon-input" icon={faCalendarAlt} />
                    Schedule meetings with <b>{integration.chilipiper.domain}</b> Chilipiper
                    Concierge and <b>{integration.chilipiper.inbound_router}</b> inbound router?
                  </div>
                ) : (
                  <Link to="/settings/integrations">
                    <Button type="primary">Setup your Chilipiper integration</Button>
                  </Link>
                )}
              </div>
            </div>
          ) : null}
        </div>

        {/* ACTIONS */}
        {actions}
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(SalesReps));
