export const pickerData = {
  salutation: ['Mr.', 'Ms.', 'Mrs.', 'Dr.', 'Prof.'],
  company_scope: ['Unknown', 'Local', 'Regional', 'National', 'Global'],
  job_position: [
    'Unknown',
    'Employee',
    'Manager',
    'VP or Director',
    'C-Level Executive',
    'Board or Chairman'
  ],
  education: [
    'No formal education',
    'Primary education',
    'Secondary education or high school',
    'GED',
    'Vocational qualification',
    "Bachelor's degree",
    "Master's degree",
    'Doctorate or higher'
  ],
  gender: ['Male', 'Female', 'Other'],
  source: [
    'Advertisement',
    'Employee Referral',
    'External Referral',
    'Partner',
    'Public Relations',
    'Seminar Internal',
    'Seminar Partner',
    'Trade Show',
    'Web',
    'Word of mouth',
    'Other'
  ],
  industry: [
    'Agriculture',
    'Apparel',
    'Automotive/Car',
    'Banking',
    'Beauty',
    'Biotechnology',
    'Brokerage',
    'Chemicals',
    'Communications',
    'Construction',
    'Consulting',
    'Cosmetic Treatments',
    'Education',
    'Electronics',
    'Energy',
    'Engineering',
    'Entertainment',
    'Environmental',
    'Excercise Equipment/Classes',
    'Finance',
    'Food & Beverage',
    'Food Delivery',
    'Furniture',
    'Gaming',
    'Government',
    'Healthcare',
    'Hospitality',
    'Housing',
    'Insurance',
    'Jewelry',
    'Luxury Goods',
    'Machinery',
    'Manufacturing',
    'Media',
    'Medical Care',
    'Not for Profit',
    'Personal Care',
    'Pets',
    'Product Delivery',
    'Real Estate',
    'Recreation',
    'Retail',
    'Shipping',
    'Snack Food',
    'Vitamins & Supplements',
    'Technology',
    'Telecommunications',
    'Transportation',
    'Utilities',
    'Vacation/Trip',
    'Venture Capital',
    'Other'
  ],
  status: ['Open', 'Contacted', 'Qualified', 'Unqualified', 'Passed', 'Not Interested', 'Bad Fit'],
  rating: ['Hot', 'Warm', 'Cold']
};
