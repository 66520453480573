import { getUser } from '_assets/js/helpers';
import TagManager from 'react-gtm-module';

const initialState = {
  user: getUser(),
  role: { campaign: {}, lead: {}, rep: {} }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'LOGOUT_USER': {
      const callback = action.payload;
      localStorage.removeItem('JWT');
      const jwt = localStorage.getItem('JWT');
      if (!jwt) {
        if (callback) callback();
        TagManager.dataLayer({ dataLayer: {} });
        return { ...state, user: null };
      } else {
        return state;
      }
    }
    case 'LOGIN_USER': {
      const { jwt } = action.payload;
      localStorage.setItem('JWT', jwt);
      const user = getUser();
      if (user) {
        const dataLayer = user
          ? {
              userId: user.id,
              userEmail: user.email,
              userName: user.display_name,
              userRole: user.role_type,
              userCompanyId: user.company || null,
              userCompany: user.company_name || null,
              userPhone: user.phone_number || null
            }
          : {};

        // remove null keys to avoid data errors
        TagManager.dataLayer({
          dataLayer: Object.keys(dataLayer)
            .filter(k => dataLayer[k] !== null)
            .reduce((a, k) => ({ ...a, [k]: dataLayer[k] }), {})
        });
        return { ...state, user };
      } else {
        return state;
      }
    }
    case 'UPDATE_USER': {
      const { attribute } = action.payload;
      if (attribute) {
        const user = { ...getUser() };
        for (let key in attribute) {
          if (user[key] !== undefined) user[key] = attribute[key];
        }
        user.display_name = user.first_name + ' ' + user.last_name;
        return { ...state, user };
      } else {
        return state;
      }
    }
    case 'UPDATE_ROLE': {
      const { role } = action.payload;
      if (role) {
        return { ...state, role };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}
