import React, { Component } from 'react';
import { LANGUAGES } from '_assets/js/languages';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { imageForReputationIndex, reputationTextIndex } from '_helpers/reputation';
import { Accordion, Checkbox, Card } from '_seriph';
import { IconCalendarTime, IconCircleSquare, IconStars, IconMapPin } from '@tabler/icons-react';
import './Search.scss';

const Panel = Accordion.Panel;

export default class Search extends Component {
  state = {
    searchText: ''
  };
  onChangeSearch = e => {
    this.setState({ searchText: e.target.value });
  };
  emitEmpty = () => {
    this.searchInput.focus();
    this.setState({ searchText: '' });
  };
  onSelectLocation = location => {
    geocodeByAddress(location)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.props.updateSearch('location', {
          name: location,
          lat: latLng.lat,
          lng: latLng.lng
        });
      })
      .catch(error => console.error('Error', error));
  };
  render() {
    const { search, updateSearch } = this.props;
    return (
      <div id="company-discover-search">
        <Card id="search-filters">
          <Accordion defaultActiveKey={['availability']}>
            <Panel header="Availability" key="availability" menu icon={<IconCalendarTime />}>
              <Checkbox
                className="sxd-checkbox"
                checked={search.availability_full}
                onChange={e => updateSearch('availability_full', e.target.checked)}
              >
                Full time (40+ week)
              </Checkbox>
              <Checkbox
                className="sxd-checkbox"
                checked={search.availability_part}
                onChange={e => updateSearch('availability_part', e.target.checked)}
              >
                Part time (up to 25 hrs)
              </Checkbox>
              <Checkbox
                className="sxd-checkbox"
                checked={search.availability_free}
                onChange={e => updateSearch('availability_free', e.target.checked)}
              >
                Free time (when they can)
              </Checkbox>
              <Checkbox
                className="sxd-checkbox"
                checked={search.availability_other}
                onChange={e => updateSearch('availability_other', e.target.checked)}
              >
                Not sure
              </Checkbox>
            </Panel>

            <Panel
              className="reputation-panel"
              header="Reputation Level"
              key="reputation"
              menu
              icon={<IconCircleSquare />}
            >
              <Checkbox
                className="sxd-checkbox earner"
                checked={search.reputation_earner}
                onChange={e => updateSearch('reputation_earner', e.target.checked)}
              >
                {reputationTextIndex(0)}
                <img
                  src={imageForReputationIndex(0)}
                  title={reputationTextIndex(0)}
                  alt={reputationTextIndex(0)}
                  className="rep-img"
                />
              </Checkbox>
              <Checkbox
                className="sxd-checkbox big-earner"
                checked={search.reputation_big_earner}
                onChange={e => updateSearch('reputation_big_earner', e.target.checked)}
              >
                {reputationTextIndex(1)}
                <img
                  src={imageForReputationIndex(1)}
                  title={reputationTextIndex(1)}
                  alt={reputationTextIndex(1)}
                  className="rep-img"
                />
              </Checkbox>
              <Checkbox
                className="sxd-checkbox super-earner"
                checked={search.reputation_super_earner}
                onChange={e => updateSearch('reputation_super_earner', e.target.checked)}
              >
                {reputationTextIndex(2)}
                <img
                  src={imageForReputationIndex(2)}
                  title={reputationTextIndex(2)}
                  alt={reputationTextIndex(2)}
                  className="rep-img"
                />
              </Checkbox>
              <Checkbox
                className="sxd-checkbox ultra-earner"
                checked={search.reputation_ultra_earner}
                onChange={e => updateSearch('reputation_ultra_earner', e.target.checked)}
              >
                {reputationTextIndex(3)}
                <img
                  src={imageForReputationIndex(3)}
                  title={reputationTextIndex(3)}
                  alt={reputationTextIndex(3)}
                  className="rep-img"
                />
              </Checkbox>
              <Checkbox
                className="sxd-checkbox extreme-earner"
                checked={search.reputation_extreme_earner}
                onChange={e => updateSearch('reputation_extreme_earner', e.target.checked)}
              >
                {reputationTextIndex(4)}
                <img
                  src={imageForReputationIndex(4)}
                  title={reputationTextIndex(4)}
                  alt={reputationTextIndex(4)}
                  className="rep-img"
                />
              </Checkbox>
            </Panel>

            <Panel header="Experience" key="experience" menu icon={<IconStars />}>
              <Checkbox
                className="sxd-checkbox"
                checked={search.experience_entry}
                onChange={e => updateSearch('experience_entry', e.target.checked)}
              >
                Pro (1 - 2 years)
              </Checkbox>
              <Checkbox
                className="sxd-checkbox"
                checked={search.experience_intermediate}
                onChange={e => updateSearch('experience_intermediate', e.target.checked)}
              >
                Expert (3 - 5 years)
              </Checkbox>
              <Checkbox
                className="sxd-checkbox"
                checked={search.experience_expert}
                onChange={e => updateSearch('experience_expert', e.target.checked)}
              >
                Specialist (6+ years)
              </Checkbox>
            </Panel>

            <Panel header="Languages" key="language" menu icon={<IconMapPin />}>
              {search['languages']['All Languages'] ? (
                <Checkbox
                  className="sxd-checkbox"
                  checked={search['languages']['All Languages']}
                  onChange={e => updateSearch('All Languages', e.target.checked, 'languages')}
                >
                  All Languages
                </Checkbox>
              ) : (
                LANGUAGES.map((l, i) => (
                  <Checkbox
                    key={i}
                    checked={search['languages'][l]}
                    onChange={e => updateSearch(l, e.target.checked, 'languages')}
                  >
                    {l}
                  </Checkbox>
                ))
              )}
            </Panel>
          </Accordion>
        </Card>
      </div>
    );
  }
}
