import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Loading, Modal, message } from '_seriph';
import { getCampaign } from 'Queries/Campaigns/campaigns';
import CompanyHeader from './_components/CompanyHeader/CompanyHeader';
import CompanyContent from './_components/CompanyContent/CompanyContent';
import Requirements from './_components/Requirements/Requirements';
import CustomerProfile from './_components/CustomerProfile/CustomerProfile';
import Apply from './_modals/Apply/Apply';
import JustTouchpoints from './_modals/JustTouchpoints/JustTouchpoints';
import { agentForPercentMatch } from '_graphql/queries/rep';
import { calculateMatch } from '_helpers/calculateMatch';
import { acceptInvite, acceptInviteFromDiscover } from 'Mutations/Notification/notification';
import { getErrors } from '_assets/js/helpers';

import './CampaignOverview.scss';

class CampaignOverview extends Component {
  state = {
    visible: true,
    loaded: false,
    campaign: null,
    apply: false,
    justTouchpoints: false,
    commitment: null,
    isBelow: false
  };
  scrollRef = null;
  UNSAFE_componentWillMount = () => {
    this.loadCampaign();
  };
  listenToScroll = () => {
    const scrollTop = this.scrollRef.scrollTop;
    if (scrollTop >= 55 && !this.state.isBelow) this.setState({ isBelow: true });
    if (scrollTop < 55 && this.state.isBelow) this.setState({ isBelow: false });
  };

  acceptInviteFromDiscover = () => {
    Modal.confirm({
      title: `Are you sure you want to accept this invitation?`,
      content:
        'Once accepted, the campaign will be added to your campaigns are you can start working/reviewing material.',
      onOk: () => {
        this.props.client
          .mutate({
            variables: {
              campaign_id: this.props.campaignId,
              commitment: parseInt(this.state.commitment)
            },
            mutation: acceptInviteFromDiscover
          })
          .then(result => {
            if (result?.data?.acceptInviteFromDiscover) {
              message.success(`Congrats, you joined ${this.state.campaign.name}!`);
              this.hideModal();
            }
          })
          .catch(err => message.error(getErrors(err) || 'Could not accept invite, try again'));
      }
    });
  };

  acceptInvite = callback => {
    Modal.confirm({
      title: `Are you sure you want to accept this invitation?`,
      content:
        'Once accepted, the campaign will be added to your campaigns are you can start working/reviewing material.',
      onOk: () => {
        this.props.client
          .mutate({
            variables: { id: this.props.notification, commitment: parseInt(this.state.commitment) },
            mutation: acceptInvite
          })
          .then(result => {
            if (result?.data?.acceptInvite) {
              this.props.loadNotifications();
              message.success(`Congrats, you joined ${this.state.campaign.name}!`);
              if (callback) callback();
              this.hideModal();
            }
          })
          .catch(err => message.error(getErrors(err) || 'Could not accept invite, try again'));
      }
    });
  };
  loadCampaign = async () => {
    const campResult = await this.props.client.query({
      variables: { id: this.props.campaignId },
      query: getCampaign
    });
    const campaign = campResult?.data?.campaign;
    if (campaign) {
      this.setState({ campaign });
    }

    const agentResult = await this.props.client.query({
      query: agentForPercentMatch
    });
    const agent = agentResult?.data?.agentInformation;
    const percentMatch = calculateMatch({ agent, campaign });

    this.setState({ percentMatch, loaded: true });
  };
  toggleApply = apply => {
    const isProfileComplete =
      this.props.isProfileComplete || this.props.isProfileComplete === undefined ? true : false;
    if (apply && !isProfileComplete) {
      message.error('Please complete your profile to 100% before applying to a campaign');
    } else {
      this.setState({ apply });
    }
  };
  hasApplied = () => {
    if (!this.state.campaign) return false;
    const userId = this.props.user.id;
    const applications = this.state.campaign.applications;
    for (let i = 0; i < applications.length; i++) {
      if (applications[i].agent_id === userId) return true;
    }
    return false;
  };
  isAlreadyIn = () => {
    return this.state.campaign?.agents?.find(a => a?.agent_id === this.props.user.id) !== undefined;
  };
  setCommitment = commitment => this.setState({ commitment });
  hideModal = () => this.setState({ visible: false });
  showApplyModal = () => this.setState({ apply: true });
  removeApplyModal = () => this.setState({ apply: false });
  showJustTouchpointsModal = () => this.setState({ justTouchpoints: true });
  removeJustTouchpointsModal = () => this.setState({ justTouchpoints: false });
  render() {
    const { campaign, loaded, isBelow } = this.state;

    const company = campaign && loaded ? campaign.company : {};

    const hasApplied = this.hasApplied();
    const isAlreadyIn = this.isAlreadyIn();

    return (
      <Modal
        wrapClassName="campaign-overview-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={true}
        width={780}
      >
        {loaded ? (
          <div className="overview">
            <CompanyHeader
              client={this.props.client}
              campaign={campaign}
              company={company}
              isBelow={isBelow}
              toggleApply={this.toggleApply}
              invitation={this.props.invitation}
              notification={this.props.notification}
              loadNotifications={this.props.loadNotifications}
              hideModal={this.hideModal}
              agentId={this.props.user.id}
              preview={this.props.preview}
              showApplyModal={this.showApplyModal}
              showJustTouchpointsModal={this.showJustTouchpointsModal}
              hasApplied={hasApplied}
              isAlreadyIn={isAlreadyIn}
              isFromDiscover={this.props.isFromDiscover}
              percentMatch={this.state.percentMatch}
            />

            <div
              className={`campaign-content ${isBelow ? 'scrolled' : ''}`}
              ref={el => (this.scrollRef = el)}
              onScroll={this.listenToScroll}
            >
              <CompanyContent
                client={this.props.client}
                campaign={campaign}
                company={company}
                toggleApply={this.toggleApply}
                invitation={this.props.invitation}
                agentId={this.props.agentId}
                preview={this.props.preview}
              />
              <Requirements campaign={campaign} company={company} />
              {campaign.objective === 'leadgen' ? (
                <CustomerProfile campaign={campaign} company={company} />
              ) : null}
            </div>
          </div>
        ) : (
          <Loading />
        )}

        {this.state.apply ? (
          <Apply
            client={this.props.client}
            campaignId={this.state.campaignId}
            removeModal={this.removeApplyModal}
            campaign={campaign}
            company={company}
            loadCampaigns={this.props.loadCampaigns}
            reload={this.loadCampaign}
            user={this.props.user}
            setCommitment={this.setCommitment}
            commitment={this.state.commitment}
          />
        ) : null}
        {this.state.justTouchpoints ? (
          <JustTouchpoints
            client={this.props.client}
            removeModal={this.removeJustTouchpointsModal}
            completeModal={
              this.props.isFromDiscover ? this.acceptInviteFromDiscover : this.acceptInvite
            }
            campaign={campaign}
            reload={this.loadCampaign}
            user={this.props.user}
            setCommitment={this.setCommitment}
            commitment={this.state.commitment}
          />
        ) : null}
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(CampaignOverview));
