import React, { Component } from 'react';
import { disputeMap } from '_constants/leadFields';
import moment from 'moment';
import './LeadCard.scss';
import leadStages from '../../../../../_constants/leadStages';
import interestLevels from '../../../../../_constants/interest';
import ProgressCircle from '_styleguide/ProgressCircle/ProgressCircle';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { leadActivity } from '_graphql/queries/lead';
import { Tooltip } from '_seriph';
import { IconChevronRight, IconCheck } from '@tabler/icons-react';

export default class LeadCard extends Component {
  state = { activityTime: null };
  componentDidMount = () => {
    const lead = this.props.lead;
    const stage = leadStages.find(l => l.slug === lead.stage);
    if (['review', 'disputed'].includes(stage?.slug)) {
      this.props.client
        .query({ variables: { lead_id: lead.id }, query: leadActivity })
        .then(result => {
          const submissionActivity = result?.data?.leadActivity?.find(activity => {
            return activity.status.type === stage.slug;
          });
          const activityTime = moment(submissionActivity?.createdAt * 1).fromNow(true);
          this.setState({
            activityTime
          });
        });
    }
  };

  render() {
    const { campaign, leadId, lead, isAgent, selectLead, filter } = this.props;
    const isPaused = campaign.status === 'inactive';
    const isComplete = lead.stage === 'completed';
    const leadName = lead.first_name || lead.last_name ? true : false;
    const interest = interestLevels.find(i => i.slug === lead.interest_reason);
    const interestDetail = interest?.reasons?.find(r => r.slug === lead.interest_reason_detail);
    const lastIndex = lead?.sequences?.map(s => s.completed).lastIndexOf(true);
    const lastStep = lead?.sequences?.[lastIndex];
    const lastTime = moment(lastStep?.completed_tstamp * 1).fromNow(true) || '';
    const lastActivityTime = moment((lead.last_activity || lead.updatedAt) * 1).fromNow(true) || '';

    const totalTouchpoints = (lead.sequences || []).length;
    const totalTouchpointsCompleted = (lead.sequences || []).filter(s => s.completed).length;

    const tagDisplay = {
      follow: 'Follow Up',
      nurture: 'Nurture',
      pause: 'Paused',
      manual: 'Manual'
    };
    let stageClassNames = ['lead-stage'];
    let colorPriority = [
      {
        id: 'unassigned',
        circleClass: 'unassigned',
        condition: !isAgent && lead.agent_id == null,
        firstLevel: 'Unassigned'
      },
      {
        id: 'paused',
        circleClass: 'paused',
        condition: campaign.status === 'inactive',
        firstLevel: 'Paused',
        secondLevel: '...',
        tooltip:
          'Campaign has been paused by company. Leads in-progress or pending review will not be editable until campaign is set live.'
      },
      {
        id: 'disputed',
        circleClass: 'disputed',
        condition: lead.stage === 'disputed',
        firstLevel: 'Disputed',
        secondLevel: disputeMap?.[lead.dispute_reason],
        tooltip: lead.dispute_information,
        lastAction: `${this.state.activityTime} ago`
      },
      {
        id: 'review',
        circleClass: 'review',
        condition: lead.stage === 'review',
        firstLevel: 'Review',
        secondLevel: interest?.name,
        tooltip: interestDetail?.name || lead.interest_information,
        lastAction: `${this.state.activityTime} ago`
      },
      {
        id: 'no-for-some-reason',
        circleClass: 'invalid',
        condition: ['invalid', 'not-interested', 'no-response'].includes(lead.interest_reason),
        firstLevel: interest?.name,
        secondLevel: interestDetail?.name,
        tooltip: lead.interest_information
      },
      {
        id: 'call-scheduled-with-time',
        circleClass: 'call-scheduled',
        condition: lead?.calendly?.start_time,
        firstLevel: 'Meeting Scheduled',
        lastAction: (
          <React.Fragment>
            <FontAwesomeIcon icon={faCalendar} className="lead-icon" />
            {moment(lead?.calendly?.start_time).format(
              moment(lead?.calendly?.start_time).format('mm') === '00'
                ? 'MM/D @ ha'
                : 'MM/D @ h:mma'
            )}
          </React.Fragment>
        )
      },
      {
        id: 'in-progress-called',
        circleClass: 'in-progress',
        condition: lastStep?.type === 'call',
        firstLevel: 'In Progress',
        lastAction: `Called ${lastTime} ago`
      },
      {
        id: 'in-progress-emailed',
        circleClass: 'in-progress',
        condition: lastStep?.type === 'email',
        firstLevel: 'In Progress',
        lastAction: `Emailed ${lastTime} ago`
      },
      {
        id: 'in-progress-linkedin',
        circleClass: 'in-progress',
        condition: lastStep?.type === 'linkedin',
        firstLevel: 'In Progress',
        lastAction: (
          <React.Fragment>
            <FontAwesomeIcon icon={faLinkedin} className="lead-icon" />
            {lastTime} ago
          </React.Fragment>
        )
      },
      {
        id: 'completed',
        circleClass: 'completed',
        condition: lead.stage === 'completed',
        firstLevel: 'Completed',
        secondLevel: <IconCheck />,
        tooltip: 'Lead is complete and payment has been initiated. No further action required.'
      },
      {
        circleClass: 'in-progress',
        condition: lead.stage === 'in-progress',
        firstLevel: 'In Progress',
        lastAction: (
          <div className="lead-time">
            <div className="tstamp">{lastActivityTime} ago</div>
          </div>
        )
      }
    ];

    let winner = colorPriority.find(c => c.condition);
    let actualClass = winner && winner.circleClass === 'invalid' ? 'invalid' : lead.stage;
    if (lead.agent_id == null) actualClass = 'unassigned';
    stageClassNames.push(actualClass);
    const isChosen = leadId === lead.id;

    const isResponse = filter?.follow_ups ? true : false;
    const missedCall = moment(lead?.missed_call_date ? lead?.missed_call_date  * 1 : null);
    const missedEmail = moment(lead?.missed_email_date ? lead?.missed_email_date  * 1 : null);
    const missedText = moment(lead?.missed_text_date ? lead?.missed_text_date  * 1 : null);
    let responseDate = null;
    if (missedCall.isValid() && !missedEmail.isValid() && !missedText.isValid()) responseDate = { type: 'call', date: missedCall };
    if (missedEmail.isValid() && !missedCall.isValid() && !missedText.isValid()) responseDate = { type: 'email', date: missedEmail };
    if (missedText.isValid() && !missedCall.isValid() && !missedEmail.isValid()) responseDate = { type: 'text', date: missedText };
    if (missedEmail.isValid() && missedCall.isValid()) {
      responseDate = missedCall.isAfter(missedEmail) ? { type: 'call', date: missedCall } : { type: 'email', date: missedEmail };
    }

    return (
      <div
        className={classNames({
          'lead-card': true,
          chosen: isChosen
        })}
        key={`l-${lead.id}`}
        id={`l-${lead.id}`}
        onClick={() => {
          if (!isAgent || !isPaused) selectLead(lead.id);
        }}
      >
        <div className="lead-row">
          <h5 className="lead-name">
            {leadName ? `${lead.first_name || ''} ${lead.last_name || ''}` : null}
            {!leadName && lead.company ? lead.company : null}
            {!leadName && !lead.company ? lead.email : null}
            {!leadName && !lead.company && !lead.email ? 'Unknown' : null}
            {lead.type === 'qualified' && !isComplete && !isChosen ? (
              <Tooltip title={`${totalTouchpointsCompleted}/${totalTouchpoints} tasks complete`}>
                <span className={`lead-progress ${lead.for_today ? 'has-work' : ''}`}>
                  <ProgressCircle percent={lead.sequence_progress} complete={lead.for_today ? false : true} />
                </span>
              </Tooltip>
            ) : null}
          </h5>
        </div>
        { !responseDate || !isResponse ? (
          <div className="bottom-row">
            <div className={stageClassNames.join(' ')}>
              <span className="stage-name">{winner.firstLevel}</span>
            </div>
            {isAgent && lead.tags?.length > 0 && (
              <div className={`${lead.tags?.[0]?.tag} ${stageClassNames.join(' ')}`}>
                <span className="tag-text">{tagDisplay[lead.tags?.[0]?.tag]}</span>
              </div>
            )}
            {winner.secondLevel &&
              (winner.tooltip ? (
                <Tooltip title={winner.tooltip}>
                  <div className="second-level">{winner.secondLevel}</div>
                </Tooltip>
              ) : (
                <div className="second-level">{winner.secondLevel}</div>
              ))}
            {lead.unread_calls > 0 && (
              <div className="call">
                {lead.unread_calls} missed call{lead.unread_calls !== 1 && 's'}
              </div>
            )}
            {lead.unread_emails > 0 && (
              <div className="email">
                {lead.unread_emails} email{lead.unread_emails !== 1 && 's'}
              </div>
            )}
            {lead.unread_texts > 0 && (
              <div className="text">
                {lead.unread_texts} texts{lead.unread_texts !== 1 && 's'}
              </div>
            )}

            { lead.unread_calls <= 0 && lead.unread_emails <= 0 && lead.unread_texts <= 0 ? (
              <span className="last-action">{winner.lastAction}</span>
            ) : null }
          </div>
        ) : (
          <div className="bottom-row">
            <div className="lead-stage response">
              <span className="stage-name cap">Incoming {responseDate?.type || ''}</span>
            </div>
            {responseDate?.type === 'call' && (
              <div className="call">Called {responseDate.date?.fromNow()}</div>
            )}
            {responseDate?.type === 'email' && (
              <div className="call">Emailed {responseDate.date?.fromNow()}</div>
            )}
            {responseDate?.type === 'text' && (
              <div className="call">Texted {responseDate.date?.fromNow()}</div>
            )}
          </div>
        )}
        {isChosen && (
          <div className="chosen-arrow">
            <IconChevronRight />
          </div>
        )}
      </div>
    );
  }
}
