import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faTimes } from '@fortawesome/free-solid-svg-icons';
import './OutOfMoneyModal.scss';

export default class OutOfMoneyModal extends Component {
  state = {
    visible: true,
    maskClosable: true
  };
  hideModal = () => {
    this.setState({ visible: false });
  };
  render() {
    return (
      <Modal
        wrapClassName="out-of-money-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={this.state.maskClosable}
        width={456}
      >
        <div className="close-icon" onClick={this.hideModal}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="stage-one">
          <div className="stage-icon">
            <FontAwesomeIcon icon={faExclamation} />
          </div>
          <h3>This campaign is filled</h3>
          <p>
            It looks like this campaign has reached it&apos;s goal or has enough leads waiting for
            review. You can view the status of the campaign from your{' '}
            <Link to="/campaigns">Campaigns</Link> page.
          </p>
          <div className="stage-actions">
            <Button className="btnx btnx-neutral" onClick={this.hideModal}>
              Close
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}
