import React, { Component } from 'react';
import { Radio as AntdRadio } from 'antd';
import './Radio.scss';

class Radio extends Component {
  render() {
    const props = { ...this.props };
    props.className = 'seriph-radio ' + (props.className || '');

    return <AntdRadio {...props} />;
  }
}

Radio.Group = AntdRadio.Group;
Radio.Button = AntdRadio.Button;
export default Radio;
