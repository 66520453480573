import React, { Component } from 'react';
import { linkify } from '_assets/js/helpers';
import {
  IconBrandLinkedin,
  IconBrandFacebook,
  IconBrandTwitter,
  IconBrandInstagram
} from '@tabler/icons-react';
import './About.scss';

export default class About extends Component {
  render() {
    const { company } = this.props;
    return (
      <div id="company-profile-about">
        <div className="about-row">
          <h5>Website</h5>
          <div>
            <a href={linkify(company.website)} target="_blank" rel="noopener noreferrer">
              {company.name}
            </a>
          </div>
        </div>

        <div className="about-row">
          <h5>Industries</h5>
          <div>
            {company.industries.map((i, ndx) => {
              if (ndx > 2) {
                return null;
              } else if (ndx < company.industries.length - 1) {
                return i.name + ', ';
              } else {
                return i.name;
              }
            })}
          </div>
        </div>
        <div className="about-row">
          <h5>Company Size</h5>
          <div>
            {company.employee_count_min} - {company.employee_count_max} Employees
          </div>
        </div>
        <div className="about-row">
          <h5>Location</h5>
          <div>
            {company.locations.length > 0 ? company.locations[0].name : 'No Location Given'}
          </div>
        </div>

        <div className="about-row">
          <h5>Social</h5>
          <div className="social">
            {company.linkedin ? (
              <a href={linkify(company.linkedin)} target="_blank" rel="noopener noreferrer">
                <IconBrandLinkedin />
              </a>
            ) : null}
            {company.facebook ? (
              <a href={linkify(company.facebook)} target="_blank" rel="noopener noreferrer">
                <IconBrandFacebook />
              </a>
            ) : null}
            {company.instagram ? (
              <a href={linkify(company.instagram)} target="_blank" rel="noopener noreferrer">
                <IconBrandInstagram />
              </a>
            ) : null}
            {company.twitter ? (
              <a href={linkify(company.twitter)} target="_blank" rel="noopener noreferrer">
                <IconBrandTwitter />
              </a>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
