import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmail, isPassword } from '_assets/js/helpers';
import axios from 'axios';
import { loginUser } from 'Store/user/user_actions';
import queryString from 'query-string';
import logo from '_assets/img/logo.svg';
import login_1 from '_assets/backgrounds/login_1.png';
import login_2 from '_assets/backgrounds/login_2.png';
import login_3 from '_assets/backgrounds/login_3.png';
import { Input, Button, Header, message } from '_seriph';
import './Login.scss';

const blankValidation = () => ({ email: null, password: null });

class Login extends Component {
  state = {
    form: {
      email: '',
      password: ''
    },
    validate: blankValidation(),
    loading: false
  };
  UNSAFE_componentWillMount() {
    const query = queryString.parse(this.props.location.search);
    if (query.token && query.token !== 'false') {
      this.props.loginUser(query.token);
      this.props.history.push(query.page ? `/${query.page}` : '/');
    } else if (query.token === 'false') {
      message.error('There was an issue logging with that account, try again');
      this.props.history.push('/');
    }
  }
  handleChange = (e, i) => {
    const form = this.state.form;
    form[i] = e.target.value;
    this.setState({ form });
  };
  isValid = () => {
    const validate = blankValidation();
    const form = this.state.form;
    if (!isEmail(form.email)) validate.email = 'Enter an email address';
    if (!isPassword(form.password)) validate.password = 'Enter a valid password';
    this.setState({ validate });
    for (let v in validate) {
      if (validate[v]) {
        return false;
      }
    }
    return true;
  };
  async signin() {
    let isValid = false;
    await this.setState({ validate: blankValidation() }, () => {
      isValid = this.isValid();
    });
    if (!isValid) return;
    this.setState({ loading: true });
    let data = { ...this.state.form };
    data.email = (`${data.email}`).toLowerCase();
    axios
      .post(`${process.env.REACT_APP_API_PATH}/v1/login`, data)
      .then(response => {
        const { token } = response.data;
        if (token) window.location.href = '/?token=' + token;
      })
      .catch(result => {
        this.setState({ loading: false });
        const data =
          result && result.response && result.response.data
            ? result.response.data
            : { error: 'Could not login, try again' };
        if (data && data.error) message.error(data.error);
      });
  }
  enterFunction = event => {
    if (event.keyCode === 13) this.signin();
  };
  componentDidMount = () => document.addEventListener('keydown', this.enterFunction, false);
  componentWillUnmount = () => document.removeEventListener('keydown', this.enterFunction, false);
  render() {
    const validate = this.state.validate;
    const form = this.state.form;
    return (
      <div id="onboarding" className="signin">
        <div className="home-bgs">
          <img className="bg1" src={login_1} alt="" />
          <img className="bg2" src={login_2} alt="" />
          <img className="bg3" src={login_3} alt="" />
        </div>
        <div className="home-logo">
          <img src={logo} alt={logo} />
        </div>
        <div className="home">
          <div className="home-left"></div>
          <div className="home-right">
            <div className="home-form">
              <Header type="display" size="3" className="headline">
                Welcome to SellX
              </Header>
              <p className="descript">
                Don’t have an account?&nbsp;&nbsp;<a href="https://sellx.com">Apply to join</a>
              </p>
              <Input
                label="Email address"
                size="large"
                name="email"
                autoComplete="email"
                value={form.email}
                error={validate.email}
                hint={validate.email}
                onChange={e => this.handleChange(e, 'email')}
                placeholder="Email address"
              />
              <Input
                label="Password"
                size="large"
                name="current-password"
                autoComplete="current-password"
                type="password"
                value={form.password}
                error={validate.password}
                hint={validate.password}
                onChange={e => this.handleChange(e, 'password')}
                placeholder="Create a Password"
              />
              <div className="home-forgot">
                <Link to="/forgot">Forgot Password?</Link>
              </div>
              <div className="home-actions">
                <Button
                  type="default"
                  size="large"
                  loading={this.state.loading}
                  onClick={this.signin.bind(this)}
                >
                  Sign In
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, { loginUser })(Login));
