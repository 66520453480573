import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { leadThreads } from '_graphql/queries/lead';
import { sendEmail } from '_graphql/mutations/lead';
import moment from 'moment';
import ReactQuill from 'react-quill';
import { generateSignature } from '_helpers/signature';
import Chat from './_components/Chat/Chat';
import { message, Button, Tooltip, Tag, Clippy } from '_seriph';
import { Select } from 'antd';
import { IconChevronLeft, IconArrowBackUp } from '@tabler/icons-react';
import './Thread.scss';
import { isArray } from '@craco/craco/lib/utils';

let initial = true;

class Thread extends Component {
  state = {
    loading: true,
    isNew: this.props.otherId === 'new',
    thread: null,
    emails: [],
    emailContent: '',
    subjectContent: '',
    dateContent: new Date(),
    signature: generateSignature(this.props.company, this.props.user, this.props.campaign),
    sending: false,
    toContent: null,
    ccContent: null,
    bccContent: null,
    uploadingFile: false,
    attachmentUrl: null,
    attachmentName: null
  };
  componentDidMount() {
    if (this.props.otherId !== 'new') this.loadThread(this.props.otherId);
  }
  loadThread = threadId => {
    this.props.client
      .query({
        variables: { thread_id: threadId },
        query: leadThreads
      })
      .then(result => {
        if (result && result.data && result.data.leadThreads) {
          const thread = result.data.leadThreads ? result.data.leadThreads[0] : null;
          if (thread) {
            let emails = this.manageEmails(thread.emails);
            emails.sort((a, b) => (parseInt(a.createdAt) > parseInt(b.createdAt) ? 1 : -1));
            this.setState(
              {
                thread: thread,
                emails,
                isNew: false,
                subjectContent: thread.subject,
                emailContent: '',
                dateContent: thread.createdAt * 1
              },
              this.scrollToBottom
            );
          } else {
            message.error('Could not load email, try again');
          }
        } else {
          message.error('Could not load email, try again');
        }
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error('Could not load email, try again');
      });
  };
  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: initial ? 'auto' : 'smooth' });
    initial = false;
  };
  isMe = email => {
    const actualDomain =
      this.props.campaign && this.props.campaign.custom_domain
        ? '@' + this.props.campaign.custom_domain
        : '@sellx.org';
    const myEmail = this.props.info ? this.props.info.email_username + actualDomain : null;
    return email === this.props.user.email || email === myEmail;
  };
  manageEmails = emails => {
    emails.forEach(e => {
      const named = e.from_name.split(' ');
      e.firstName = named[0];
      e.lastName = named[named.length - 1] || '';
      e.emailId = e.email_id.split('@')[0].replace('<', '').replace('>', '');
      e.isMe = this.isMe(e.from_email);
    });
    return emails;
  };
  createDates = emails => {
    let dates = [];
    emails.forEach(data => dates.push(moment(data.createdAt * 1).format('dddd, MMMM Do YYYY')));
    return [...new Set(dates.reverse())].reverse();
  };
  sendEmail = () => {
    this.setState({ sending: true });
    const lead = this.props.lead || {};
    const { isNew } = this.state.isNew;
    let thread = this.state.thread;
    this.props.client
      .mutate({
        variables: {
          lead_id: lead.id,
          campaign_id: lead.campaign_id,
          thread_id: this.props.otherId,
          email_id: isNew ? null : thread.last_email_id,
          origin_email_id: isNew ? null : thread.origin_email_id,
          content: this.state.emailContent + this.state.signature,
          subject: isNew ? this.state.subjectContent : thread.subject,
          to: isArray(this.state.toContent) ? this.state.toContent[0] : this.state.toContent,
          cc: this.state.ccContent,
          bcc: this.state.bccContent,
          attachment_url: this.state.attachmentUrl
        },
        mutation: sendEmail
      })
      .then(result => {
        if (result && result.data && result.data.sendEmail) {
          setTimeout(() => {
            this.setState(
              {
                emailContent: '',
                subjectContent: '',
                sending: false,
                showReply: false,
                attachmentUrl: null,
                attachmentName: null,
                toContent: null,
                ccContent: null,
                bccContent: null
              },
              () => {
                this.loadThread(result.data.sendEmail);
                this.props.history.push(`/leads/${lead.id}/email/${result.data.sendEmail}`);
              }
            );
            message.success(`${isNew ? 'Email' : 'Reply'} sent`);
          }, 1000);
        } else {
          this.setState({ sending: false });
          throw new Error('Error sending email');
        }
      })
      .catch(() => this.setState({ sending: false }));
  };
  onReply = ({ showReply, toContent, ccContent = null, bccContent = null }) => {
    this.setState({ showReply, toContent, ccContent, bccContent }, () => {
      this.scrollToBottom();
    });
  };
  handleKeyDown = e => {
    if (e.keyCode === 9) {
      const editor = document.querySelectorAll('.ql-editor')[0];
      setTimeout(function () {
        editor.focus();
      }, 0);
    }
  };
  goBack = () => this.props.history.push(`/leads/${this.props.lead.id}/email`);
  render() {
    const { canEmail, lead, campaign } = this.props;
    const {
      emails,
      isNew,
      emailContent,
      subjectContent,
      dateContent,
      sending,
      showReply,
      toContent,
      ccContent,
      bccContent,
      attachmentName,
      uploadingFile
    } = this.state;

    const actualDomain =
      campaign && campaign.custom_domain ? '@' + campaign.custom_domain : '@sellx.org';
    const myEmail = this.props.info ? this.props.info.email_username + actualDomain : null;

    const modules = {
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link']
      ],
      clipboard: { matchVisual: false }
    };

    let dateList = emails.map(c => (
      <Chat key={c.id} user={this.props.user} lead={lead} email={c} onReply={this.onReply} />
    ));

    return (
      <div id="lead-thread">
        <div className="thread-box">
          <div className="subject-hr">
            <Button type="plain" circle onClick={this.goBack}>
              <IconChevronLeft />
            </Button>
            <div className="subject-title">
              <h4>{subjectContent || 'No Subject'}</h4>
              <h5>Started on {moment(dateContent).format('MMM D')}</h5>
            </div>
            {canEmail && !showReply ? (
              <Tooltip title="Reply">
                <Button
                  type="plain"
                  circle
                  onClick={() => this.onReply({ showReply: true, toContent: lead.email })}
                >
                  <IconArrowBackUp />
                </Button>
              </Tooltip>
            ) : null}
          </div>
          <div
            className={`thread-conversation ${!isNew ? 'old' : ''}`}
            ref={node => (this.convoBox = node)}
          >
            {dateList}
            <div style={{ float: 'left', clear: 'both' }} ref={el => (this.messagesEnd = el)}></div>
          </div>
          {canEmail && showReply ? (
            <div className={`email-input ${!isNew ? 'old' : ''}`}>
              <div className="input-content">
                {myEmail ? (
                  <div className="email-field from-address">
                    <div className="info">
                      <b>From: </b>
                      {myEmail}
                    </div>
                    <div className="actions">
                      {ccContent === null && (
                        <Button
                          onClick={() => this.setState({ ccContent: [] })}
                          type="default"
                          size="tiny"
                        >
                          Cc:
                        </Button>
                      )}
                      {bccContent === null && (
                        <Button
                          onClick={() => this.setState({ bccContent: [] })}
                          type="default"
                          size="tiny"
                        >
                          Bcc:
                        </Button>
                      )}
                      <Clippy
                        ref={node => (this.clippy = node)}
                        onError={() => this.setState({ uploadingFile: false })}
                        onUploadStart={() => this.setState({ uploadingFile: true })}
                        onUploadComplete={({ attachmentUrl, attachmentName }) =>
                          this.setState({
                            attachmentUrl,
                            attachmentName,
                            uploadingFile: false
                          })
                        }
                        onClear={() => this.setState({ attachmentUrl: null, attachmentName: null })}
                      />
                    </div>
                  </div>
                ) : null}
                <div className="email-field to-address">
                  <div className="d-flex align-center" onClick={() => this.toField.focus()}>
                    <b>To: </b>
                    <Select
                      className="to-input"
                      value={isArray(toContent) ? toContent[0] : toContent}
                      mode="tags"
                      style={{ width: '100%' }}
                      disabled={toContent === lead.email}
                      ref={node => (this.toField = node)}
                      onChange={toContent => this.setState({ toContent })}
                      dropdownRender={menu => (
                        <div className="select-dropdown-hide-dropdown">{menu}</div>
                      )}
                    />
                  </div>
                  {ccContent !== null ? (
                    <div className="d-flex align-center">
                      <b>Cc:</b>
                      <Select
                        value={ccContent}
                        mode="tags"
                        style={{ width: '100%' }}
                        onChange={ccContent => this.setState({ ccContent })}
                        dropdownRender={menu => (
                          <div className="select-dropdown-hide-dropdown">{menu}</div>
                        )}
                      />
                    </div>
                  ) : null}
                  {bccContent !== null ? (
                    <div className="d-flex align-center">
                      <b>Bcc:</b>
                      <Select
                        value={bccContent}
                        mode="tags"
                        style={{ width: '100%' }}
                        onChange={bccContent => this.setState({ bccContent })}
                        dropdownRender={menu => (
                          <div className="select-dropdown-hide-dropdown">{menu}</div>
                        )}
                      />
                    </div>
                  ) : null}
                  {attachmentName !== null ? (
                    <div className="d-flex align-center">
                      <b>attachment:</b>
                      <Tag
                        size="small"
                        canHide
                        onClick={this.clippy.handleClearAttachment}
                      >
                        {attachmentName}
                      </Tag>
                    </div>
                  ) : null}
                </div>
                <div className="content">
                  <ReactQuill
                    modules={modules}
                    value={emailContent}
                    onChange={emailContent => this.setState({ emailContent }, this.scrollToBottom)}
                    placeholder="Your message..."
                  />
                  <Button
                    type="primary"
                    disabled={!emailContent || uploadingFile}
                    loading={sending}
                    onClick={this.sendEmail}
                  >
                    {isNew ? 'Send' : 'Reply'}
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(Thread);
