import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Input, message, Header } from '_seriph';
import { adminCompanies, getManagers } from '../_graphql/queries';
import { masqueradeUser, updateUnpaid, updateCompanyManager } from '../_graphql/mutations';
import { loginUser } from 'Store/user/user_actions';
import { getColumns } from './Columns';
import Seats from './_modals/Seats/Seats.js';
import './Companies.scss';

class Companies extends Component {
  state = {
    data: [],
    selectedRowKeys: [],
    pagination: { pageSize: 10 },
    loading: false,
    selected: null,
    searchText: '',
    searchTerm: '',
    managers: [],
    tab: 'active',
    tabAmounts: {
      active: 0,
      unassigned: 0
    }
  };
  componentDidMount() {
    this.fetch();
  }
  fetch = async (params = {}) => {
    this.setState({ loading: true, searchTerm: this.state.searchText });
    this.props.client
      .query({
        variables: {
          name: this.state.searchText,
          filterField: null,
          results: 10,
          page: 1,
          ...params
        },
        query: adminCompanies
      })
      .then(result => {
        if (result && result.data) {
          const companies = result.data.adminCompanies.data || [];
          const pagination = { ...this.state.pagination };
          const tabAmounts = { ...this.state.tabAmounts, ...result.data.adminCompanies.tabAmounts };
          pagination.total = result.data.adminCompanies.pageTotal;
          pagination.totalAll = result.data.adminCompanies.total;
          this.setState({
            loading: false,
            data: companies,
            pagination,
            tabAmounts
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(() => this.setState({ loading: false }));

    const managers = (
      await this.props.client.query({
        query: getManagers
      })
    ).data.getManagers;
    if (managers) {
      this.setState({ managers });
    }
  };
  masq = user_id => {
    this.props.client
      .mutate({
        variables: { user_id: user_id },
        mutation: masqueradeUser
      })
      .then(result => {
        if (result && result.data && result.data.masqueradeUser) {
          this.props.loginUser(result.data.masqueradeUser);
          this.props.history.push('/');
        } else {
          throw new Error('Could not login as company, try again');
        }
      })
      .catch(err => {
        message.error(err.message);
      });
  };
  saveManager = (company_id, manager_id) => {
    this.setState({ saving: company_id });
    this.props.client
      .mutate({
        variables: { company_id, manager_id },
        mutation: updateCompanyManager
      })
      .then(result => {
        if (result?.data?.updateCompanyManager) {
          if (this.state.previous) {
            this.fetch(this.state.previous);
          } else {
            this.fetch({ sortField: 'paid', sortOrder: 'descend' });
          }
          this.setState({ saving: null });
        } else {
          throw new Error('Could not update manager, try again');
        }
      })
      .catch(err => {
        this.setState({ saving: null });
        message.error(err.message);
      });
  };
  updateManager = (company_id, manager_id) => {
    const data = this.state.data;
    data.map(d => {
      if (d.id === company_id) d.manager_id = manager_id;
      return d;
    });
    this.setState({ data });
  };
  toggleUnpaid = (company_id, unpaid) => {
    this.setState({ saving: company_id });
    this.props.client
      .mutate({
        variables: { company_id, unpaid },
        mutation: updateUnpaid
      })
      .then(result => {
        if (result && result.data && result.data.updateUnpaid) {
          if (this.state.previous) {
            this.fetch(this.state.previous);
          } else {
            this.fetch({ sortField: 'paid', sortOrder: 'descend' });
          }
          this.setState({ saving: null });
        } else {
          throw new Error('Could not update unpaid, try again');
        }
      })
      .catch(err => {
        this.setState({ saving: null });
        message.error(err.message);
      });
  };
  changeTab = tab => {
    this.setState({ tab }, this.fetch);
  };
  clearSearch = () => {
    this.setState({ searchText: '', searchTerm: '' }, () => {
      this.fetch();
      this.searchInput?.focus();
    });
   
  };
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      name: this.state.searchText,
      ...filters
    });
  };
  onSeatsClick = async data => {
    this.setState({ showSeatsModal: data });
  };
  onSeatsHide = () => {
    this.setState({ showSeatsModal: false}, () => this.fetch(this.state.previous));
  };
  render() {
    const { pagination, data, loading, saving } = this.state;
    const total = pagination.totalAll || 0;
    const columns = getColumns(
      this.masq,
      this.toggleUnpaid,
      saving,
      this.props.user?.admin?.role === 'super' || this.props.user?.admin?.role === 'owner',
      this.updateManager,
      this.saveManager,
      this.state.managers,
      this.onSeatsClick
    );

    return (
      <div id="admin-companies">
        <div className="admin-header">
          <Header type="display" weight="600" size="4">
            Companies
          </Header>
          <div>{total} companies on SellX</div>
          <Input
            placeholder="Search"
            value={this.state.searchText}
            tagValue={this.state.searchTerm}
            onClear={this.clearSearch}
            onChange={e => this.setState({ searchText: e.target.value })}
            onKeyDown={e =>
              e.key === 'Enter' ? this.fetch({ name: this.state.searchText }) : null
            }
            ref={node => (this.searchInput = node)}
          />
        </div>
        <div className="admin2-table">
          <Table
            lined
            rounded
            rowSelection={null}
            columns={columns}
            rowKey={record => record.id}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={this.handleTableChange}
            scroll={{ x: 'max-content' }}
            locale={{ emptyText: loading ? 'Loading companies...' : 'No companies found' }}
          />
        </div>
        {this.state.showSeatsModal ? (
          <Seats
            client={this.props.client}
            removeModal={this.onSeatsHide}
            company={this.state.showSeatsModal}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, { loginUser })(Companies));
