import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Input, Button, DatePicker, Switch, AutoComplete, message } from 'antd';
import {
  createAgentProfileExperience,
  deleteAgentProfileExperience,
  updateAgentProfileExperience
} from 'Mutations/Agent/updateAgentProfile';
import { TITLES, COMPANIES } from '_assets/js/constants';
import { updateAgentProfile } from 'Store/agent/agent_actions';
import moment from 'moment';

const { TextArea } = Input;
const { MonthPicker } = DatePicker;

const mutations = {
  save: { mutation: updateAgentProfileExperience, name: 'updateAgentExperience' },
  create: { mutation: createAgentProfileExperience, name: 'createAgentExperience' },
  delete: { mutation: deleteAgentProfileExperience, name: 'deleteAgentExperience' }
};

class ExperienceEdit extends Component {
  state = { saving: false };
  action = type => {
    this.setState({ saving: true });
    this.props.apollo
      .mutate({
        variables: { id: this.props.editing, ...this.props.form },
        mutation: mutations[type].mutation
      })
      .then(result => {
        if (result && result.data) {
          if (type === 'create') message.success('Experience has been added to your profile');
          if (type === 'save')
            message.success('Your experience at ' + this.props.form.company + ' has been updated');
          if (type === 'delete')
            message.success('Your experience at ' + this.props.form.company + ' was removed');
          this.props.close();
          this.props.reload();
        } else {
          this.setState({ saving: false });
        }
      })
      .catch(() => {
        message.error('Please fill out the required fields');
        this.setState({ saving: false });
      });
  };
  setInitial = () => this.setState({ saving: false });
  disabledDate = current => {
    return current && current > moment().endOf('day');
  };
  render() {
    const editing = this.props.editing ? true : false;

    let titleSource = [];
    const titleSearch = this.props.form.title || '';
    if (titleSearch.length > 0) {
      titleSource = TITLES.filter(title => {
        return title.toLowerCase().includes(titleSearch.toLowerCase());
      });
      if (titleSource.length > 10) titleSource = titleSource.slice(0, 10);
    }

    let companySource = [];
    const companySearch = this.props.form.company || '';
    if (companySearch.length > 0) {
      companySource = COMPANIES.filter(company => {
        return company.toLowerCase().includes(companySearch.toLowerCase());
      });
      if (companySource.length > 10) companySource = companySource.slice(0, 10);
    }
    const defaultStart = this.props.form.start_date
      ? moment.unix(this.props.form.start_date)
      : null;
    const defaultEnd = this.props.form.end_date ? moment.unix(this.props.form.end_date) : null;
    return (
      <Modal
        visible={this.props.visible}
        footer={null}
        title={null}
        afterClose={this.setInitial}
        onCancel={this.props.close}
        wrapClassName="sx-theme form-modal agent-experience-edit"
        destroyOnClose={true}
        maskClosable={true}
        centered={true}
      >
        <div className="sx-form">
          <div className="field">
            <label>Title *</label>
            <AutoComplete
              style={{ width: '100%' }}
              dataSource={titleSource}
              placeholder="Ex: Manager"
              value={this.props.form.title}
              onChange={v => this.props.updateForm('title', v)}
              className="sx-select"
              dropdownClassName="sx-dropdown"
            />
          </div>

          <div className="field">
            <label>Company *</label>
            <AutoComplete
              style={{ width: '100%' }}
              dataSource={companySource}
              placeholder="Ex: Google"
              value={this.props.form.company}
              onChange={c => this.props.updateForm('company', c)}
              className="sx-select"
              dropdownClassName="sx-dropdown"
            />
          </div>

          <div className="field">
            <label>Current role? *</label>
            <Switch
              checked={this.props.form.current}
              onChange={c => this.props.updateForm('current', c)}
            />
          </div>

          <div className="field">
            <label>Start Date *</label>
            <MonthPicker
              defaultValue={defaultStart}
              disabledDate={this.disabledDate}
              onChange={d => this.props.updateForm('start_date', d.unix().toString())}
              placeholder="Start Date"
            />
          </div>

          {!this.props.form.current ? (
            <div className="field">
              <label>End Date *</label>
              <MonthPicker
                defaultValue={defaultEnd}
                disabledDate={this.disabledDate}
                onChange={d => this.props.updateForm('end_date', d.unix().toString())}
                placeholder="End Date"
              />
            </div>
          ) : null}

          <div className="field">
            <label>Description *</label>
            <TextArea
              rows={4}
              autoSize={false}
              value={this.props.form.description}
              onChange={e => this.props.updateForm('description', e.target.value)}
            />
          </div>
        </div>

        {editing ? (
          <div className="sx-actions">
            <Button className="btnx btnx-default" onClick={() => this.action('delete')}>
              Remove
            </Button>
            <Button
              className="btnx btnx-primary"
              loading={this.props.saving}
              onClick={() => this.action('save')}
            >
              Save
            </Button>
          </div>
        ) : (
          <div className="sx-actions">
            <Button
              className="btnx btnx-primary"
              loading={this.props.saving}
              onClick={() => this.action('create')}
            >
              Create
            </Button>
          </div>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.agent, ...state.apollo };
};

export default connect(mapStateToProps, { updateAgentProfile })(ExperienceEdit);
