import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Button, Header } from '_seriph';
import './Completed.scss';

class Completed extends Component {
  render() {
    return (
      <div className="join-form-box" id="onboard-completed">
        <Header type="display" size="4">
          Onboarding completed
        </Header>
        <p>
          Welcome! Please login to your account to get started with SellX.
        </p>

        <div className="notice"><b>Something wrong?  Contact us at <a href="mailto:support@sellx.com">support@sellx.com</a>.</b></div>

        <div className="notice">
          <Link to="/">
            <Button type="default">Log in to your account</Button>
          </Link>
        </div>

      </div>
    );
  }
}

export default withRouter(Completed);
