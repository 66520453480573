import React, { Component } from 'react';
import { createMessage } from 'Mutations/Message/message';
import { mySalesReps } from '_graphql/queries/campaign';
import { Avatar, Modal, TextArea, Button, message, Select } from '_seriph';
import './MessageRep.scss';

export default class MessageRep extends Component {
  state = {
    visible: true,
    saving: false,
    loading: true,
    repId: undefined,
    message: '',
    reps: []
  };
  componentDidMount = () => {
    this.getReps();
  };
  getReps = () => {
    this.props.client
      .query({ query: mySalesReps })
      .then(result => {
        if (result && result.data && result.data.mySalesReps) {
          this.setState({ reps: this.filterReps(result.data.mySalesReps) });
        }
        this.setState({ loading: false });
      })
      .catch(() => this.setState({ loading: false }));
  };
  filterReps = reps => {
    const campaigns = this.props.campaigns || [];
    const repIds = new Set();
    campaigns.forEach(c => {
      c.agents?.forEach(a => {
        if (a.active) repIds.add(a.agent_id);
      })
    });
    const filteredReps = reps.filter(r => {
      return repIds.has(r.id);
    })
    return filteredReps || [];
  }
  createMessage = () => {
    this.setState({ saving: true });
    this.props.client
      .mutate({
        variables: {
          body: this.state.message,
          agent_id: this.state.repId,
          employee_id: this.props.user.id
        },
        mutation: createMessage
      })
      .then(result => {
        if (result && result.data && result.data.createMessage) {
          message.success('Message sent');
          if (this.props.reload) this.props.reload();
          this.hideModal();
        } else {
          throw new Error('Could not send message, try again');
        }
      })
      .catch(() => {
        message.error('Could not send message, try again');
        this.setState({ saving: false });
      });
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { repId, reps } = this.state;

    return (
      <Modal
        wrapClassName="message-reps-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        style={{ top: 60 }}
        width={500}
        closable={false}
        maskClosable={true}
      >
        <Select
          label="Select a Sales Rep"
          placeholder="Sales reps..."
          value={repId}
          onChange={val => this.setState({ repId: val })}
          getPopupContainer={trigger => trigger.parentNode}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => {
            return option.props.term?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {reps.map(r => (
            <Select.Option key={'scsr-' + r.id} value={r.id} term={r.display_name}>
              <Avatar size="mini" type="company" picture={r.picture} />
              <span>
                <b>{r.display_name}</b>
              </span>
            </Select.Option>
          ))}
        </Select>

        <TextArea
          label="Message"
          value={this.state.message}
          onChange={e => this.setState({ message: e.target.value })}
          size="large"
          rows={6}
          placeholder="Write your message here..."
        />

        <div className="mo-actions">
          <Button type="default" onClick={this.hideModal}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={this.createMessage}
            loading={this.state.saving}
            disabled={this.state.message.length <= 0 || !repId}
          >
            Send Message
          </Button>
        </div>
      </Modal>
    );
  }
}
