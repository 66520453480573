import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Highlights from './_components/Highlights/Highlights';
import GetStarted from './_components/GetStarted/GetStarted';
import Marketing from './_components/Marketing/Marketing';
import { agentHome } from '_graphql/queries/stats';
import { Header, Loading, message } from '_seriph';
import './XDRHome.scss';

class XDRHome extends Component {
  state = {
    loading: true,
    data: null
  };
  componentDidMount = () => {
    this.loadAgentHome();
  };
  loadAgentHome = () => {
    this.props.client.query({ query: agentHome }).then(result => {
      if (result && result.data && result.data.agentHome) {
        this.setState({ data: result.data.agentHome, loading: false });
      } else {
        message.error('Could not load, try again');
      }
    });
  };
  render() {
    const { campaigns, counts, loadNotifications, loadMessaging, hasLiveCampaign } = this.props;
    const { loading, data } = this.state;

    return loading || this.props.counts === null || this.state.data === null ? (
      <Loading />
    ) : (
      <div id="agent-home">
        <div className="home-main">
          <Header size="3" type="sans" className="home-name">
            Welcome back {this.props.user.display_name.split(' ')[0]},
          </Header>
          <Header type="display" weight="600" size="3">
            Let&apos;s get caught up
          </Header>

          {data.agent && !data.agent.has_slack ? (
            <Marketing
              data={data}
              client={this.props.client}
              user={this.props.user}
              reload={this.loadAgentHome}
            />
          ) : null}

          <GetStarted
            client={this.props.client}
            counts={counts}
            data={data}
            campaigns={campaigns}
            user={this.props.user}
            hasLiveCampaign={hasLiveCampaign}
            reload={this.loadAgentHome}
          />
        </div>

        <Highlights
          counts={counts}
          data={data}
          campaigns={campaigns}
          loadNotifications={loadNotifications}
          loadMessaging={loadMessaging}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(XDRHome));
