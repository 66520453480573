import React, { Component } from 'react';
import './Objections.scss';

export default class Objections extends Component {
  render() {
    const { objections } = this.props;

    return (
      <div id="dialer-objections" className="sx-form">
        <h3 className="call-h3">Possible objections</h3>
        {objections.map((o, i) => {
          return (
            <div className="lead-object" key={'lobj-' + i}>
              <h4 className="object-info">{o.name}</h4>
              <div className="benefits">
                {o.responses.map((b, j) => (
                  <div className="lead-resp" key={'resp-' + j}>
                    <span>&bull;</span>
                    <span>{b}</span>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
