import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getErrors } from '_assets/js/helpers';
import { Input, Select } from 'antd';
import { Loading, Button, message, Tag, Clippy } from '_seriph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { getTemplate } from '_graphql/queries/campaign';
import { sendEmail } from '_graphql/mutations/lead';
import Avatar from '_styleguide/Avatar/Avatar';
import ReactQuill from 'react-quill';
import { generateSignature } from '_helpers/signature';
import { convertTagsOnly } from '_helpers/smartTags';
import './EmailPopout.scss';
import convertEmail from 'src/_helpers/convertEmail';

class EmailPopout extends Component {
  state = {
    loading: true,
    sending: false,
    minimized: false,
    ccContent: null,
    bccContent: null,
    uploadingFile: false,
    attachmentUrl: null,
    attachmentName: null,
    emailContent: '',
    subjectContent: '',
    invalidTags: [],
    signature: generateSignature(
      this.props.sequence.company,
      this.props.sequence.rep,
      this.props.sequence.campaign
    )
  };
  componentDidMount = () => {
    setTimeout(() => {
      if (this.props.sequence && this.props.sequence.templateId) {
        this.loadTemplate();
      } else {
        this.setState({ loading: false });
      }
    }, 0);
  };
  loadTemplate = () => {
    this.props.client
      .query({ variables: { template_id: this.props.sequence.templateId }, query: getTemplate })
      .then(result => {
        if (result && result.data && result.data.getTemplate) {
          const template = convertEmail(result.data.getTemplate, this.props.sequence);
          this.setState({
            subjectContent: convertTagsOnly(template.subject),
            emailContent: convertTagsOnly(template.content),
            invalidTags: this.getTags(template.content, template.subject),
            loading: false
          });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not load email template, try again');
        this.setState({ loading: false });
      });
  };
  sendEmail = () => {
    this.setState({ sending: true });
    const { lead, campaign } = this.props.sequence;
    this.props.client
      .mutate({
        variables: {
          lead_id: lead.id,
          campaign_id: campaign.id,
          is_reply: false,
          sequence_id: this.props.sequence.lead?.sequence_id || null,
          touchpoint_id: this.props.sequence.templateId,
          day: this.props.sequence.day || null,
          email_id: null,
          origin_email_id: null,
          content: this.state.emailContent + this.state.signature,
          subject: this.state.subjectContent,
          cc: this.state.ccContent,
          bcc: this.state.bccContent,
          attachment_url: this.state.attachmentUrl
        },
        mutation: sendEmail
      })
      .then(result => {
        if (result && result.data && result.data.sendEmail) {
          if (!this.props.sequence.templateId) message.success('Email sent');
          if (this.props.sequence.complete) {
            this.props.sequence.complete(result.data.sendEmail);
          }
          setTimeout(this.props.hidePopout, 100);
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Error sending email, try again');
        this.setState({ sending: false });
      });
  };
  getTags = (body, subject) => {
    const content = body + subject + '',
      invalidTags = [];
    (content.match(/\{\{(.*?)\}\}/g) || []).forEach(val => invalidTags.push(val));
    return invalidTags;
  };
  handleKeyDown = e => {
    if (e.keyCode === 9) {
      const editor = document.querySelectorAll('.ql-editor')[0];
      setTimeout(function () {
        editor.focus();
      }, 0);
    }
  };
  updateBody = val =>
    this.setState({ emailContent: val, invalidTags: this.getTags(val, this.state.subjectContent) });
  updateSubject = val =>
    this.setState({ subjectContent: val, invalidTags: this.getTags(this.state.emailContent, val) });
  toggleMinimize = () => this.setState({ minimized: !this.state.minimized });
  render() {
    const {
      loading,
      sending,
      invalidTags,
      minimized,
      subjectContent,
      emailContent,
      ccContent,
      bccContent,
      attachmentName,
      uploadingFile
    } = this.state;
    const { lead } = this.props.sequence;

    let leadTitleName = `${lead.salutation || ''} ${lead.first_name || ''} ${lead.last_name || ''}`;
    if (!lead.first_name && !lead.last_name && lead.company) {
      leadTitleName = lead.company;
    }

    const modules = {
      toolbar: [['bold', 'italic', 'underline', 'link']],
      clipboard: { matchVisual: false }
    };
    const formats = ['bold', 'italic', 'underline', 'link'];

    let className = 'email-popout';
    if (minimized) className += ' hidden';
    if (!this.props.shouldDisplay) className += ' invisible';

    return (
      <div className={className}>
        {loading ? (
          <Loading />
        ) : minimized ? (
          <div className="mini-headline" onClick={this.toggleMinimize}>
            <Avatar
              size="mini"
              type="lead"
              picture={lead.photo_url}
              firstCharacter={(lead.first_name || '?').charAt(0).toUpperCase()}
              lastCharacter={(lead.last_name || '?').charAt(0).toUpperCase()}
            />
            <div className="info">
              <h4>{leadTitleName}</h4>
              <h5>
                <b>To:</b> {lead.email}
              </h5>
            </div>
            <div className="mini-actions">
              <div className="expand" onClick={this.toggleMinimize}>
                <FontAwesomeIcon icon={minimized ? faChevronUp : faChevronDown} />
              </div>
              <div className="close" onClick={this.props.hidePopout}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          </div>
        ) : (
          <div className={ccContent !== null || bccContent !== null ? 'has-cc' : ''}>
            <div className="title-bar" onClick={this.toggleMinimize}>
              <h4>{subjectContent}</h4>
              <div className="expand" onClick={this.toggleMinimize}>
                <FontAwesomeIcon icon={minimized ? faChevronUp : faChevronDown} />
              </div>
              <div className="close" onClick={this.props.hidePopout}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
            <div className="headline">
              <Avatar
                size="mini"
                type="lead"
                picture={lead.photo_url}
                firstCharacter={(lead.first_name || '?').charAt(0).toUpperCase()}
                lastCharacter={(lead.last_name || '?').charAt(0).toUpperCase()}
              />
              <div className="info">
                <h4>{leadTitleName}</h4>
                <h5>
                  <b>To:</b> {lead.email}
                </h5>
              </div>
              <div className="actions">
                {ccContent === null && (
                  <Button
                    onClick={() => this.setState({ ccContent: [] })}
                    type="default"
                    size="tiny"
                  >
                    Cc:
                  </Button>
                )}
                {bccContent === null && (
                  <Button
                    onClick={() => this.setState({ bccContent: [] })}
                    type="default"
                    size="tiny"
                  >
                    Bcc:
                  </Button>
                )}
                <Clippy
                  ref={node => (this.clippy = node)}
                  onError={() => this.setState({ uploadingFile: false })}
                  onUploadStart={() => this.setState({ uploadingFile: true })}
                  onUploadComplete={({ attachmentUrl, attachmentName }) =>
                    this.setState({
                      attachmentUrl,
                      attachmentName,
                      uploadingFile: false
                    })
                  }
                  onClear={() => this.setState({ attachmentUrl: null, attachmentName: null })}
                />
              </div>
            </div>
            {ccContent !== null ? (
              <div className="cc">
                <span>Cc:</span>
                <Select
                  value={ccContent}
                  mode="tags"
                  style={{ width: '100%' }}
                  onChange={ccContent => this.setState({ ccContent })}
                  dropdownRender={menu => (
                    <div className="select-dropdown-hide-dropdown">{menu}</div>
                  )}
                />
              </div>
            ) : null}
            {bccContent !== null ? (
              <div className="bcc">
                <span>Bcc:</span>
                <Select
                  value={bccContent}
                  mode="tags"
                  style={{ width: '100%' }}
                  onChange={bccContent => this.setState({ bccContent })}
                  dropdownRender={menu => (
                    <div className="select-dropdown-hide-dropdown">{menu}</div>
                  )}
                />
              </div>
            ) : null}
            {attachmentName !== null ? (
              <div className="d-flex align-center mail-attachment">
                <span>attachment: </span>
                <Tag
                  size="small"
                  canHide
                  onClick={this.clippy.handleClearAttachment}
                >
                  {attachmentName}
                </Tag>
              </div>
            ) : null}
            <div className="subject">
              <span>Subject:</span>
              <Input
                onKeyDown={this.handleKeyDown}
                value={subjectContent}
                onChange={e => this.updateSubject(e.target.value)}
              />
            </div>
            <div className="content">
              <ReactQuill
                formats={formats}
                modules={modules}
                value={emailContent}
                onChange={this.updateBody}
                placeholder="Your message..."
              />
              <Button
                type="primary"
                loading={sending}
                disabled={loading || invalidTags.length > 0 || !emailContent || !subjectContent  || uploadingFile}
                onClick={this.sendEmail}
              >
                Send
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(EmailPopout));
