import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Locked } from '_shared/Locked/Locked';
import Infotip from '_shared/Infotip/Infotip';
import { Input, Card, Selection } from '_seriph';
import { IconListSearch, IconFlame, IconSnowflake } from '@tabler/icons-react';
import './Setup.scss';

class Setup extends Component {
  state = {
    campaignName: 'New Campaign'
  };
  componentDidMount = () => {
    if (this.props.save) this.props.save();
    if (this.props.campaign?.name) {
      this.setState({ campaignName: this.props.campaign.name });
    }
  };
  updateObjective = which => {
    if (which === 'inbound') {
      this.props.updateMultiple({
        objective: 'qualify',
        objective_type: 'inbound',
        name: this.state.campaignName,
        threshold_settings: {
          lead_count: null,
          duration: 'continuous'
        }
      });
    } else if (which === 'outbound') {
      this.props.updateMultiple({
        objective: 'qualify',
        objective_type: 'outbound',
        name: this.state.campaignName,
        threshold_settings: {
          lead_count: null,
          duration: 'continuous'
        }
      });
    }
  };
  setLeadgen = () => {
    this.props.updateMultiple({
      objective: 'leadgen',
      name: this.state.campaignName,
      threshold_settings: {
        lead_count: 1000,
        duration: 'lifetime'
      }
    });
  };
  saveName = value => {
    this.setState({ campaignName: value });
    if (this.props.campaign.objective) {
      this.props.update('name', value);
    }
  };
  render() {
    const { campaign, company, actions, fromReview, isLocked } = this.props;

    let type = null;
    if (campaign.objective === 'leadgen') {
      type = 'leadgen';
    } else if (campaign.objective === 'qualify' && campaign.objective_type === 'inbound') {
      type = 'inbound';
    } else if (campaign.objective === 'qualify' && campaign.objective_type === 'outbound') {
      type = 'outbound';
    }

    const availableTo = [
      { title: 'XDR\'s', description: 'Marketplace reps', value: 'xdr' }
    ];

    if (company?.internal_seats > 0) {
      availableTo.push({ title: 'Internal', description: 'Employees', value: 'internal' });
      availableTo.push({ title: 'Both', description: 'XDR & Internal', value: 'both' });
    }

    return (
      <Card id="wizard-setup" className="wz-card">
        <div className="wz-container sx-form">
          {/* HEADER */}
          <div className="wz-card-header">
            <h3>Setup your campaign</h3>
            <h5>It’s like a job listing, add info sales reps should know before applying.</h5>
          </div>

          {/* CONTENT */}
          <div className={!campaign.name && fromReview ? 'field invalid' : 'field'}>
            <label>Campaign Name</label>
            <Input
              placeholder="My Campaign"
              onChange={e => this.saveName(e.target.value)}
              value={this.state.campaignName}
            />
          </div>

          <div className={`field ${isLocked ? 'is-locked' : ''}`}>
            <label>
              Available to
              {isLocked ? (
                <Locked isLocked={isLocked} />
              ) : (
                <Infotip title="Decide if you want to have marketplace and/or internal reps work on this campaign" />
              )}
            </label>
            <Selection
              values={availableTo}
              disabled={isLocked}
              value={campaign.rep_type}
              onChange={val => this.props.update('rep_type', val)}
              full={true}
            />
          </div>

          <div className={`field ${isLocked ? 'is-locked' : ''}`}>
            <label>
              Campaign Objective
              {isLocked ? (
                <Locked isLocked={isLocked} />
              ) : (
                <Infotip title="The type of campaign you want to create." />
              )}
            </label>
            {!this.props.isNew && type === 'leadgen' ? (
              <div
                className={`objective ${type === 'leadgen' ? 'active' : ''}`}
                onClick={() => (isLocked ? null : this.setLeadgen())}
              >
                <div className="obj-icon">
                  <IconListSearch />
                </div>
                <div className="obj-desc">
                  <h3>Generate Leads</h3>
                  <p>Discover new potential customers that fit your customer profile. </p>
                </div>
              </div>
            ) : null}
            <div
              className={`objective ${type === 'inbound' ? 'active' : ''}`}
              onClick={() => (isLocked ? null : this.updateObjective('inbound'))}
            >
              <div className="obj-icon">
                <IconFlame />
              </div>
              <div className="obj-desc">
                <h3>Connect with Warm Leads</h3>
                <p>
                  Also known as <b>interested or inbound leads</b>, these are leads that have
                  expressed interest by signing up on your website or socials. (<b>10-15%</b>{' '}
                  average estimated conversion)
                </p>
              </div>
            </div>
            <div
              className={`objective ${type === 'outbound' ? 'active' : ''}`}
              onClick={() => (isLocked ? null : this.updateObjective('outbound'))}
            >
              <div className="obj-icon">
                <IconSnowflake />
              </div>
              <div className="obj-desc">
                <h3>Qualify Cold Leads</h3>
                <p>
                  Also known as <b>outbound leads</b>, these are leads that have not expressed
                  interest in your product or service yet but fit your customer profile. (
                  <b>1-3%</b> average estimated conversion)
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* ACTIONS */}
        {actions}
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(Setup));

/*
            <IconSelection
              disabled={isLocked}
              className={campaign.objective}
              values={[
                { title: 'Lead Generation', value: 'leadgen', icon: <SVG src={require('_assets/icons/user_plus.svg')} /> },
                { title: 'Qualify Leads', value: 'qualify', icon: <SVG src={require('_assets/icons/user-check.svg')} /> }
              ]}
              value={campaign.objective}
              onChange={val => update('objective', val)}
              full={true}
            />
*/
