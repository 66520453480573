import React, { Component } from 'react';
import { Modal, Loading, message } from '_seriph';
import { meetingScheduled, cancelMeeting } from '_graphql/mutations/lead';
import axios from 'axios';
import { getErrors } from '_assets/js/helpers';
import './ChilipiperModal.scss';

export default class ChilipiperModal extends Component {
  state = {
    visible: true,
    loading: true,
    url: null
  };
  componentDidMount = () => {
    this.loadUrl();
    window.addEventListener('message', this.handleEvent);
  };
  componentWillUnmount = () => {
    window.removeEventListener('message', this.handleEvent);
  };
  scheduleMeeting = ({ event_id, start_time, end_time }) => {
    this.props.client
      .mutate({
        variables: {
          lead_id: this.props.lead.id,
          calendar_type: 'chilipiper',
          event_id,
          start_time,
          end_time
        },
        mutation: meetingScheduled
      })
      .then(result => {
        if (result && result.data && result.data.meetingScheduled) {
          setTimeout(() => {
            message.success('Meeting scheduled.');
            if (this.props.reload) this.props.reload(this.props.lead.id);
            this.hideModal();
          }, 2000);
        } else {
          throw new Error('Error scheduling meeting, try again');
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Error scheduling meeting, try again');
      });
  };
  cancelMeeting = async () => {
    const { id, calendly } = this.props.lead;
    const { event_id, calendar_type } = calendly;

    this.props.client
      .mutate({
        variables: {
          lead_id: id,
          event_id,
          calendar_type
        },
        mutation: cancelMeeting
      })
      .then(result => {
        if (result?.data?.cancelMeeting) {
          this.props.reload(id);
          message.success('Meeting canceled');
          this.hideModal();
        } else {
          message.error('Error cancelling meeting, try again');
        }
      })
      .catch(err => {
        console.log(err);
        message.error('Lead not found, try again');
      });
  };
  loadUrl = () => {
    const leadId = this.props.lead.id;
    const companyId = this.props.campaign?.company?.id;
    axios
      .get(
        `${process.env.REACT_APP_API_PATH}/chilipiper/url?company_id=${companyId}&lead_id=${leadId}`,
        {
          headers: { Authorization: 'JWT ' + localStorage.getItem('JWT') }
        }
      )
      .then(response => {
        if (response && response.data && response.data.url) {
          this.setState({
            loading: false,
            url: response.data.url
          });
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        message.error('Could not find Chilipiper meeting url, try again');
        this.hideModal();
      });
  };
  handleEvent = e => {
    if (e.data?.action === 'close') {
      this.hideModal();
    } else if (e.data?.action === 'booking-confirmed') {
      this.scheduleMeeting({
        event_id: e.data?.args?.eventId,
        start_time: new Date(e.data?.args?.slot?.start),
        end_time: new Date(e.data?.args?.slot?.end)
      });
    } else if (e.data?.action === 'prospect_cancel') {
      this.cancelMeeting({
        event_id: e.data?.args?.eventId,
        calendar_type: 'chilipiper'
      });
    }
  };

  hideModal = () => this.setState({ visible: false });
  render() {
    const { removeModal, lead } = this.props;
    const { cancel_url } = lead.calendly;

    return (
      <Modal
        wrapClassName="chilipiper-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={true}
        width={600}
      >
        {this.state.loading || !this.state.url ? (
          <div className="loading-wrapper">
            <Loading />
          </div>
        ) : (
          <iframe
            title="cp-iframe"
            width={cancel_url ? '600' : '600'}
            height={cancel_url ? '740' : '600'}
            src={cancel_url || this.state.url}
            frameBorder="0"
            allowFullScreen
          />
        )}
      </Modal>
    );
  }
}
