import gql from 'graphql-tag';

export const createCampaign = gql`
  mutation createCampaign($name: String!) {
    createCampaign(name: $name) {
      id
      name
    }
  }
`;

export const updateCampaign = gql`
  mutation updateCampaign(
    $id: String!
    $name: String
    $type: String
    $objective: String
    $calendly: String
    $description: String
    $short_description: String
    $guidelines: String
    $script: String
    $target_experience_level: String
    $target_availability: String
    $target_agent_count: Int
    $target_area_code: String
    $target_skills: [String]
    $target_languages: [String]
    $target_locations: [CompanyLocation]
    $instructions: [String]
    $customer_type: String
    $cohort: String
    $customer_target: String
    $customer_locations: [CompanyLocation]
    $customer_decision_maker: String
    $customer_age: Int
    $customer_gender: [String]
    $customer_race: [String]
    $customer_education: [String]
    $customer_company_scope: [String]
    $customer_job_position: [String]
    $customer_industry: [String]
    $customer_languages: [String]
    $required: [CampaignFieldType]
    $forceCalendar: Boolean
    $questions: [CampaignQuestionType]
    $budget_ppl: Float
    $budget_ppql: Float
    $budget_total: Float
    $budget_bonus: Float
    $budget_bonus_count: Float
    $status: String
  ) {
    updateCampaign(
      id: $id
      name: $name
      type: $type
      objective: $objective
      calendly: $calendly
      description: $description
      short_description: $short_description
      guidelines: $guidelines
      script: $script
      target_experience_level: $target_experience_level
      target_availability: $target_availability
      target_agent_count: $target_agent_count
      target_area_code: $target_area_code
      target_skills: $target_skills
      target_languages: $target_languages
      target_locations: $target_locations
      instructions: $instructions
      customer_type: $customer_type
      cohort: $cohort
      customer_target: $customer_target
      customer_locations: $customer_locations
      customer_decision_maker: $customer_decision_maker
      customer_age: $customer_age
      customer_gender: $customer_gender
      customer_race: $customer_race
      customer_education: $customer_education
      customer_company_scope: $customer_company_scope
      customer_job_position: $customer_job_position
      customer_industry: $customer_industry
      customer_languages: $customer_languages
      required: $required
      forceCalendar: $forceCalendar
      questions: $questions
      budget_ppl: $budget_ppl
      budget_ppql: $budget_ppql
      budget_total: $budget_total
      budget_bonus: $budget_bonus
      budget_bonus_count: $budget_bonus_count
      status: $status
    ) {
      id
      name
    }
  }
`;

export const updateCampaignStatus = gql`
  mutation updateCampaignStatus($id: String!, $status: String!) {
    updateCampaignStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const endCampaign = gql`
  mutation endCampaign($id: String!, $agents: String!) {
    endCampaign(id: $id, agents: $agents) {
      id
      status
    }
  }
`;

export const fillBudgetAndStart = gql`
  mutation fillBudgetAndStart($campaign_id: String!) {
    fillBudgetAndStart(campaign_id: $campaign_id) {
      id
    }
  }
`;

export const deleteCampaign = gql`
  mutation deleteCampaign($id: String) {
    deleteCampaign(id: $id)
  }
`;

export const addMessageToCampaign = gql`
  mutation addMessageToCampaign($body: String!, $campaign_id: String!, $message_id: String!) {
    addMessageToCampaign(body: $body, campaign_id: $campaign_id, message_id: $message_id)
  }
`;

export const createApplication = gql`
  mutation createApplication($note: String!, $campaign_id: String!) {
    createApplication(note: $note, campaign_id: $campaign_id) {
      id
      status
    }
  }
`;

export const updateApplication = gql`
  mutation updateApplication($id: String!, $status: String!) {
    updateApplication(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const deleteApplication = gql`
  mutation deleteApplication($id: String!) {
    deleteApplication(id: $id) {
      id
    }
  }
`;

export const hireApplication = gql`
  mutation hireApplication($id: String!) {
    hireApplication(id: $id) {
      id
    }
  }
`;

export const passApplication = gql`
  mutation passApplication($id: String!) {
    passApplication(id: $id) {
      id
    }
  }
`;

export const acceptInvite = gql`
  mutation acceptInvite($campaign_id: String!, $convo_id: String!) {
    acceptInvite(campaign_id: $campaign_id, convo_id: $convo_id)
  }
`;

export const declineInvite = gql`
  mutation declineInvite($campaign_id: String!, $convo_id: String!) {
    declineInvite(campaign_id: $campaign_id, convo_id: $convo_id)
  }
`;
