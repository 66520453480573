import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { adminTasks } from '../../../_graphql/queries';
import { generateData, matchReps } from './_helpers/data';
import { Header, message, Table, Card } from '_seriph';
import TouchpointChart from './_charts/TouchpointChart/TouchpointChart';
import { DatePicker, Select } from 'antd';
import moment from 'moment';
import { getColumns } from './Columns';
import { numberWithCommas, flatMoney, formatPercent } from '_assets/js/helpers';
import { masqueradeUser } from '../../../_graphql/mutations';
import { loginUser } from 'Store/user/user_actions';
import './Tasks.scss';

const { MonthPicker, WeekPicker } = DatePicker;

class Tasks extends Component {
  state = {
    loading: true,
    data: generateData([], []),
    date: moment().format('YYYYMMDD'),
    tableData: [],
    type: 'weekly',
    internal: 'xdr'
  };
  componentDidMount = () => {
    this.getStats();
  };
  getStats = () => {
    if (!this.state.loading) this.setState({ loading: true });
    this.props.client
      .query({
        variables: {
          date: this.state.date,
          type: this.state.type,
          internal: this.state.internal || 'xdr'
        },
        query: adminTasks
      })
      .then(result => {
        if (result && result.data && result.data.adminTasks) {
          const data = result.data.adminTasks?.data || [];
          const previous = result.data.adminTasks?.previous || [];
          this.setState({ 
            data: generateData(data, previous),
            tableData: matchReps(data, previous),
          }, () => {
            this.setState({ loading: false });
          });
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        message.error('Could not load your statistics, try again');
        this.setState({ loading: false });
      });
  };
  masq = agent_id => {
    this.props.client
      .mutate({
        variables: { user_id: agent_id },
        mutation: masqueradeUser
      })
      .then(result => {
        if (result && result.data && result.data.masqueradeUser) {
          this.props.loginUser(result.data.masqueradeUser);
          this.props.history.push('/');
        } else {
          throw new Error('Could not login as agent, try again');
        }
      })
      .catch(err => {
        message.error(err.message);
      });
  };
  onChange = currentDate => {
    const { type } = this.state;
    let date = currentDate;
    if (type === 'weekly') {
      date = currentDate.startOf('week');
    } else if (type === 'monthly') {
      date = currentDate.startOf('month');
    }
    this.setState({ date: date.format('YYYYMMDD') }, this.getStats);
  };
  onYearChange = year => {
    const currentDate = moment().day('Monday').year(year).week('24');
    this.setState({ date: currentDate.format('YYYYMMDD') }, this.getStats);
  };
  UNSAFE_componentWillMount = () => window.scrollTo({ top: 0 });
  render() {
    const { data, tableData, loading } = this.state;

    const year = moment(this.state.date, 'YYYYMMDD').format('YYYY');
    const totals = data?.totals;

    return (
      <div id="company-analytics-activity">
        <div className="seriph-header-wrapper">
          <Header type="display" weight="600" size="4">
            Tasks Completed
          </Header>
        </div>

        <div className="dashboard-actions">
          <span className="no-left">Viewing</span>
          <Select value={this.state.type} onChange={type => this.setState({ type }, this.getStats)}>
            <Select.Option value="daily">Daily</Select.Option>
            <Select.Option value="weekly">Weekly</Select.Option>
            <Select.Option value="monthly">Monthly</Select.Option>
            <Select.Option value="yearly">Yearly</Select.Option>
          </Select>
          <span>stats, for</span>
          {this.state.type === 'daily' && (
            <DatePicker 
              onChange={this.onChange}
              defaultValue={moment().startOf('week')}
              format="[the day of] M/D/YY"
            />
          )}
          {this.state.type === 'weekly' && (
            <WeekPicker 
              onChange={this.onChange}
              defaultValue={moment().startOf('week')}
              format="[the week of] M/D/YY"
            />
          )}
          {this.state.type === 'monthly' && (
            <MonthPicker 
              onChange={this.onChange}
              defaultValue={moment().startOf('week')}
              format="[the month of] MMM YYYY"
            />
          )}
          {this.state.type === 'yearly' && (
            <Select value={year} onChange={this.onYearChange}>
              <Select.Option value="2021">2021</Select.Option>
              <Select.Option value="2022">2022</Select.Option>
              <Select.Option value="2023">2023</Select.Option>
            </Select>
          )}
          <span>with only</span>
          <Select value={this.state.internal} onChange={internal => this.setState({ internal }, this.getStats)}>
            <Select.Option value="all">All Reps</Select.Option>
            <Select.Option value="xdr">XDRs Only</Select.Option>
            <Select.Option value="internal">Internal Only</Select.Option>
          </Select>
        </div>
        <div className="task-totals">
          <Card>
            <div className="descriptor">
              Calls
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(totals?.call)}
              <span className={totals?.call_percent > 0 ? 'good' : 'bad'}>
                {totals?.call_percent > 0 ? '+' : ''}{formatPercent(totals?.call_percent, 1)}
              </span>
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              Emails
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(totals?.email)}
              <span className={totals?.email_percent > 0 ? 'good' : 'bad'}>
                {totals?.email_percent > 0 ? '+' : ''}{formatPercent(totals?.email_percent, 1)}
              </span>
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              LinkedIn
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(totals?.linkedin)}
              <span className={totals?.linkedin_percent > 0 ? 'good' : 'bad'}>
                {totals?.linkedin_percent > 0 ? '+' : ''}{formatPercent(totals?.linkedin_percent, 1)}
              </span>
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              Totals
            </div>
            <Header size="2" font="sans">
              {numberWithCommas(totals?.total)}
              <span className={totals?.total_percent > 0 ? 'good' : 'bad'}>
                {totals?.total_percent > 0 ? '+' : ''}{formatPercent(totals?.total_percent, 1)}
              </span>
            </Header>
          </Card>
          <Card>
            <div className="descriptor">
              Payout total
            </div>
            <Header size="2" font="sans">
              {flatMoney(totals?.earned)}
              <span className={totals?.earned_percent > 0 ? 'good' : 'bad'}>
                {totals?.earned_percent > 0 ? '+' : ''}{formatPercent(totals?.earned_percent, 1)}
              </span>
            </Header>
          </Card>
        </div>
        <div className="chart-list">
          <TouchpointChart
            data={data.tasks}
            loading={false}
          />
        </div>
        <div className="task-table">
          <Table
            lined
            rounded
            loading={loading}
            locale={{ emptyText: loading ? 'Loading task statistics...' : 'No tasks found' }}
            dataSource={tableData.map(d => {
              d.key = d.agent?.id;
              return d;
            })}
            scroll={{ x: 'max-content' }}
            pagination={{ pageSize: 10 }}
            columns={getColumns(this.masq)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, { loginUser })(Tasks));
