import React, { Component } from 'react';
import { campaignInvitations } from '_graphql/queries/company';
import { withdrawTeamRepInvite, inviteTeamRep } from '_graphql/mutations/company';
import CampaignDropdown from '_shared/CampaignDropdown/CampaignDropdown';
import { Table, message } from '_seriph';
import './Invitations.scss';
import { Link } from 'react-router-dom';
import { Avatar, Header, Button, Modal, Pagination } from '_seriph';
import WithdrawModal from './_modals/WithdrawModal/WithdrawModal';
import moment from 'moment';
import AgentTile from '../_components/AgentTile/AgentTile';

export default class Hired extends Component {
  state = {
    loading: true,
    campaignInvites: [],
    repInvites: [],
    withdrawRep: null,
    withdrawCampaigns: [],
    campaignStatus: 'active',
    insteadResend: false,
    inviteModal: false,
    paginationPageSize: 4,
    paginationStartIndex: 0,
    paginationEndIndex: 8,
    paginationCurrent: 1,
    tile: false
  };
  componentDidMount = () => {
    this.loadCampaignInvites();
  };
  componentDidUpdate = prevProps => {
    if (prevProps.campaign !== this.props.campaign) {
      this.loadCampaignInvites()
    }
  };
  loadCampaignInvites = async () => {
    const result = await this.props.client.query({
      query: campaignInvitations,
      variables: { campaign_status: this.state.campaignStatus }
    });

    let campaignInvites = result?.data?.campaignInvitations || [];
    moment.updateLocale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'a few seconds',
        ss: '%d seconds',
        m: 'a minute',
        mm: '%d minutes',
        h: 'an hour',
        hh: '%d hours',
        d: 'a day',
        dd: '%d days',
        w: 'a week',
        ww: '%d weeks',
        M: 'a month',
        MM: '%d months',
        y: 'a year',
        yy: '%d years'
      }
    });
    campaignInvites = campaignInvites.map(ci => {
      ci.campaigns = ci.campaigns.map(c => {
        c.status_updated_ago = moment(parseInt(c.status_updated)).fromNow();
        return c;
      });
      return ci;
    });

    this.setState({ campaignInvites, loading: false });
  };

  withdrawTeamRep = (email, invite_id) => {
    Modal.confirm({
      title: `Are you sure you want to withdraw the invitation for ${email}?`,
      content: 'They will be unable to create an account with this email.',
      onOk: async () => {
        let result;
        let error = 'Could not revoke agent, try again';
        try {
          result = (
            await this.props.client.mutate({
              variables: { invite_id },
              mutation: withdrawTeamRepInvite
            })
          )?.data?.withdrawTeamRepInvite;
        } catch (e) {
          error = e.message;
        }

        if (result) {
          message.success(`1 invitation withdrawn from ${email}`);
          this.loadCampaignInvites();
        } else {
          message.error(error);
        }
      }
    });
  };

  remindTeamRep = email => {
    Modal.confirm({
      title: `Are you sure you want to resend the invitation for ${email}?`,
      content: 'They will be able to create an internal rep account with this email.',
      onOk: async () => {
        let result;
        let error = 'Could not resend invitaition, try again';
        try {
          result = (
            await this.props.client.mutate({
              variables: { email, resend: true },
              mutation: inviteTeamRep
            })
          )?.data?.inviteTeamRep;
        } catch (e) {
          error = e.message;
        }

        if (result) {
          message.success(`Email reminder sent to ${email}`);
        } else {
          message.error(error);
        }
      }
    });
  };

  addWithdrawModal = (withdrawRep, withdrawCampaigns, insteadResend = false) => {
    this.setState({ withdrawRep, withdrawCampaigns, insteadResend });
  };
  removeWithdrawModal = () => this.setState({ withdrawRep: false, withdrawCampaigns: [] });

  render() {
    const { campaignInvites, loading } = this.state;

    return (
      <div className="invitations">
        <div className="inv-filter">
          <div>
            <CampaignDropdown
              placement="bottomLeft"
              allowAll={true}
              campaign={this.state.campaignStatus}
              campaigns={this.props.campaigns}
              selectCampaign={campaignStatus => {
                this.setState({ campaignStatus }, () => {
                  this.loadCampaignInvites();
                });
              }}
              hideList={true}
            />
{/*            <RepFilter
              filter={this.state.repFilter}
              setFilter={f => this.setState({ repFilter: f })}
            />*/}
          </div>
          <div className="filter-right">
{/*            <AgentTileToggle
              tile={this.state.tile}
              setTile={f => {
                this.setState({ tile: f, paginationPageSize: f ? 8 : 4 });
              }}
            />*/}
          </div>
        </div>

        {!this.state.tile ? (
          <div className="invites-table">
            <Table
              lined
              rounded
              loading={loading}
              dataSource={campaignInvites}
              pagination={{
                pageSize: this.state.paginationPageSize,
                total: campaignInvites.length
              }}
              columns={[
                {
                  title: 'Sales Rep',
                  key: 'rep',
                  align: 'left',
                  render: (c, r) => {
                    return (
                      <div className="ctbl-box">
                        {!r.is_internal ? (
                          <Link to={`/rep/${r.rep.id}`}>
                            <Avatar type="agent" picture={r.rep.picture} size="small" />
                          </Link>
                        ) : (
                          <Avatar type="agent" size="small" />
                        )}
                        <div className="ctbl-name">
                          <Header size="5" font="sans">
                            {!r.is_internal ? r.rep.display_name : r.email}
                          </Header>
                          <div className="joined-info">
                            Invited&nbsp;
                            {!r.is_internal
                              ? moment(parseInt(r.most_recent_invite)).fromNow()
                              : moment(parseInt(r.createdAt)).fromNow()}
                          </div>
                        </div>
                      </div>
                    );
                  }
                },
                {
                  title: 'Invited to',
                  key: 'camp-list',
                  align: 'left',
                  render: (c, r) => {

                    let campaignList = (r.campaigns || []).map((campaign, i) => (
                      <div key={i}>&bull;&nbsp;&nbsp;{campaign.name}</div>
                    ));
                    let totalCount = campaignList.length;
                    if (totalCount > 3) {
                      const missing = campaignList.slice(3);
                      campaignList = campaignList.slice(0, 3);
                      campaignList.push(
                        <div key="fff" style={{ color: '#000000', fontWeight: '500'}}>+&nbsp;&nbsp;{missing.length} more campaign(s)</div>
                      );
                    }

                    return (
                      <div className="camp-list">
                        {campaignList}
                      </div>
                    );
                  }
                },
                {
                  title: '',
                  key: 'actions',
                  align: 'right',
                  className: 'row-actions',
                  render: (c, r) => {
                    return (
                      <div>
                        <Button
                          size="tiny"
                          onClick={() => {
                            if (r.is_internal) {
                              this.withdrawTeamRep(r.email, r.id);
                            } else {
                              this.addWithdrawModal(r.rep, r.campaigns);
                            }
                          }}
                        >
                          Withdraw
                        </Button>
                        <Button
                          size="tiny"
                          type="secondary"
                          onClick={() => {
                            if (r.is_internal) {
                              this.remindTeamRep(r.email);
                            } else {
                              this.addWithdrawModal(r.rep, r.campaigns, true);
                            }
                          }}
                        >
                          Send reminder
                        </Button>
                      </div>
                    );
                  }
                }
              ]}
              locale={{ emptyText: loading ? 'Loading invitations...' : 'No invitations found' }}
              scroll={{ x: 'max-content' }}
            />
          </div>
        ) : (
          <React.Fragment>
            <div id="invitations-tiles">
              {campaignInvites
                .slice(this.state.paginationStartIndex, this.state.paginationEndIndex)
                .map(d => (
                  <AgentTile
                    key={d?.rep?.id || d?.id}
                    agent={d.rep}
                    joinedInfo={`Sent ${
                      !d.is_internal
                        ? moment(parseInt(d.most_recent_invite)).fromNow()
                        : moment(parseInt(d.createdAt)).fromNow()
                    }`}
                    campaigns={this.props.campaigns}
                    isInternal={d.is_internal}
                    isInvite={true}
                    inviteId={d.email ? d.id : null}
                    email={d.email}
                    withdrawTeamRep={this.withdrawTeamRep}
                    addWithdrawModal={this.addWithdrawModal}
                    remindTeamRep={this.remindTeamRep}
                  />
                ))}
            </div>
            <Pagination
              pageSize={this.state.paginationPageSize}
              total={campaignInvites.length}
              current={this.state.paginationCurrent}
              onChange={paginationCurrent => {
                const paginationStartIndex =
                  (paginationCurrent - 1) * this.state.paginationPageSize;
                const paginationEndIndex = paginationCurrent * this.state.paginationPageSize;
                this.setState({ paginationStartIndex, paginationEndIndex, paginationCurrent });
              }}
            />
          </React.Fragment>
        )}
        {this.state.withdrawRep && this.state.withdrawCampaigns?.length ? (
          <WithdrawModal
            user={this.props.user}
            client={this.props.client}
            rep={this.state.withdrawRep}
            campaigns={this.state.withdrawCampaigns}
            insteadResend={this.state.insteadResend}
            removeModal={this.removeWithdrawModal}
            reload={this.loadCampaignInvites}
          />
        ) : null}
      </div>
    );
  }
}
