import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Header, Toggle } from '_seriph';
import { numberWithCommas, stripHTML } from '_assets/js/helpers';
import { touchpointTypes } from '_constants/campaigns';
import Infotip from '_shared/Infotip/Infotip';
import './SequenceDays.scss';

const Stat = props => (
  <div className="t-stat">
    <h4>{numberWithCommas(props.value || 0)}</h4>
    <p>{props.info}</p>
  </div>
);

const Touchpoint = props => {
  const touchpoint = props.touchpoint;
  const s = props.stats;
  const type = touchpoint.type;
  const touchType = touchpointTypes[type];
  const style = {
    background: props.archived ? '#E7ECF4' : touchType?.color,
    color: touchType?.static ? '#FFFFFF' : props.archived ? '#707B90' : '#090E15'
  };
  return (
    <div className="touch">
      <div className="type" style={style}>
        {touchType.icon}
      </div>
      <div className="info">
        <h4>
          {touchpoint.name} <span>{props.archived ? '- Archived' : ''}</span>
        </h4>
        <p>{stripHTML(touchpoint.content)}</p>
      </div>
      {type === 'email' && <Stat value={s?.email?.completed} info="Sent" />}
      {type === 'email' && <Stat value={s?.email?.skipped} info="Skipped" />}
      {type === 'email' && <Stat value={s?.email?.delivered} info="Delivered" />}
      {type === 'email' && <Stat value={s?.email?.bounced} info="Bounced" />}
      {type === 'email' && <Stat value={s?.email?.opened} info="Opened" />}
      {type === 'email' && <Stat value={s?.email?.replied} info="Replies" />}
      {type === 'email' && <Stat value={s?.email?.qualified} info="Qualified" />}

      {type === 'call' && <Stat value={s?.call?.completed} info="Called" />}
      {type === 'call' && <Stat value={s?.call?.skipped} info="Skipped" />}
      {type === 'call' && <Stat value={s?.call?.no_answer} info="No answer" />}
      {type === 'call' && <Stat value={s?.call?.answered} info="Answered" />}
      {type === 'call' && <Stat value={s?.call?.incoming} info="Incoming" />}
      {type === 'call' && <Stat value={s?.call?.qualified} info="Qualified" />}

      {type === 'text' && <Stat value={s?.text?.completed} info="Sent" />}
      {type === 'text' && <Stat value={s?.text?.text_delivered} info="Delivered" />}
      {type === 'text' && <Stat value={s?.text?.text_reply} info="Replies" />}
      {type === 'text' && <Stat value={s?.text?.text_opt_out} info="Opt outs" />}

      {type === 'linkedin' && <Stat value={s?.linkedin?.completed} info="Sent" />}
      {type === 'linkedin' && <Stat value={s?.linkedin?.skipped} info="Skipped" />}
      {type === 'linkedin' && <Stat value={s?.linkedin?.qualified} info="Qualified" />}

      {type === 'instructions' && <Stat value={s?.instructions?.completed} info="Completed" />}
      {type === 'instructions' && <Stat value={s?.instructions?.skipped} info="Skipped" />}
    </div>
  );
};

const Wait = props => {
  const type = props.type;
  const touchType = touchpointTypes[type];
  const style = {
    background: touchType.color,
    color: touchType.static ? '#FFFFFF' : '#090E15'
  };
  return (
    <div className="touch">
      <div className="type" style={style}>
        {touchType.icon}
      </div>
      <div className="info">
        <h4>Wait</h4>
        <p>{(props.duration ? props.duration + ' ' : '') + props.duration_type}</p>
      </div>
    </div>
  );
};

class SequenceDays extends Component {
  state = {
    showArchived: {}
  };
  toggleArchived = (day, value) => {
    let showArchived = { ...this.state.showArchived };
    showArchived[day] = value;
    this.setState({ showArchived });
  };
  render() {
    const { showArchived } = this.state;
    const { sequence, archived } = this.props;
    return (
      <div id="sequence-days">
        {sequence.days.map((d, i) => {
          const dayArchived = archived.filter(a => a.day === i + 1);
          const archiveVisible = showArchived[i + 1];
          const maxHeight = archiveVisible ? dayArchived.length * 81 : 0;
          return (
            <div className="s-day" key={`s-day-${i}`}>
              <Header size="3" font="sans">
                Day {i + 1}
              </Header>
              <div className={`touches ${archiveVisible ? '' : 'none'}`}>
                {d.touchpoints.map((t, j) => {
                  if (!t.touchpoint) return null;
                  return t.type !== 'wait' ? (
                    <Touchpoint
                      key={`s-touch-${j}`}
                      type={t.type}
                      touchpoint={t.touchpoint}
                      stats={t.stats}
                    />
                  ) : (
                    <Wait
                      key={`s-wait-${j}`}
                      type={t.type}
                      duration={t.duration}
                      duration_type={t.duration_type}
                    />
                  );
                })}
                <div className="archived" style={{ maxHeight: maxHeight + 'px' }}>
                  {dayArchived.map((t, j) => {
                    if (!t.touchpoint) return null;
                    return (
                      <Touchpoint
                        key={`s-archived-${j}`}
                        archived={true}
                        touchpoint={t.touchpoint}
                        stats={t}
                      />
                    );
                  })}
                </div>
              </div>
              {dayArchived.length > 0 ? (
                <div className="archive-toggle">
                  <Toggle
                    checked={archiveVisible}
                    size="small"
                    onChange={checked => this.toggleArchived(i + 1, checked)}
                  />
                  <h4>{archiveVisible ? 'Hide' : 'Show'} previously used touchpoints</h4>
                  <Infotip title="These are touchpoints that have previously been used for this day and have stats attached to them." />
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    );
  }
}

export default withRouter(SequenceDays);
