import React from 'react';
import moment from 'moment';
import { numberWithCommas } from '_assets/js/helpers';
import { Tag } from '_seriph';

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getColumns = () => {
  return [
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => moment(createdAt * 1).format('MM/DD/YY, h:mma')
    },
    {
      title: 'Description',
      dataIndex: 'first_name',
      key: 'first_name',
      render: (_, { campaign, lead }) => {
        return (
          <React.Fragment>
            {lead.first_name} {lead.last_name} for {campaign.name}
          </React.Fragment>
        );
      }
    },
    {
      title: 'Type',
      dataIndex: '',
      key: 'type',
      render: (_, { type }) => {
        return <React.Fragment>{capitalizeFirstLetter(type)}</React.Fragment>;
      }
    },
    {
      title: 'Points',
      dataIndex: 'amount',
      key: 'points',
      render: amount => {
        const render = (amount > 0 ? '+' : '') + numberWithCommas(amount);
        return <Tag type={amount > 0 ? 'success' : 'danger'}>{render}</Tag>;
      }
    }
  ];
};
