import React, { Component } from 'react';
import Card from '_shared/Card/Card';
import './Review.scss';

const StepReview = props => {
  return (
    <div className="step-review">
      <div className="sr-title">{props.title}</div>
      <div className="sr-value">{props.value}</div>
    </div>
  );
};

const levels = {
  any: 'Any level',
  entry: 'Pro (1 - 2 years)',
  intermediate: 'Expert (3 - 5 years)',
  expert: 'Specialist (6+ years)'
};
export default class Review extends Component {
  getLocations = locations => {
    let str = '';
    locations.forEach(location => {
      str += location.name + ' - ';
    });
    return str ? str.substring(0, str.length - 3) : '';
  };
  getList = items => {
    let str = '';
    items.forEach(item => (str += item + ', '));
    return str ? str.substring(0, str.length - 2) : '';
  };
  render() {
    const form = this.props.form;
    return (
      <React.Fragment>
        <Card className="step-card" padding="40px 20px 25px 20px">
          <div className="step-title">
            <h4>Campaign Setup</h4>
          </div>
          <div className="step-box">
            <StepReview title="Title" value={form.name || 'N/A'} />
            <StepReview title="Objective" value={form.objective || 'N/A'} />
            <StepReview title="Description" value={form.description || 'N/A'} />
          </div>
        </Card>
        <Card className="step-card" padding="40px 20px 25px 20px">
          <div className="step-title">
            <h4>Sales Rep Targeting</h4>
          </div>
          <div className="step-box">
            <StepReview
              title="Experience level"
              value={levels[form.target_experience_level] || 'N/A'}
            />
            <StepReview
              title="Location(s)"
              value={this.getLocations(form.target_locations) || 'N/A'}
            />
            <StepReview title="Skills" value={this.getList(form.target_skills) || 'N/A'} />
            <StepReview title="Languages" value={this.getList(form.target_languages) || 'N/A'} />
          </div>
        </Card>
        <Card className="step-card" padding="40px 20px 25px 20px">
          <div className="step-title">
            <h4>Customer Profile</h4>
          </div>
          <div className="step-box">
            <StepReview title="Target customer" value={form.customer_target || 'N/A'} />
            <StepReview
              title="Location(s)"
              value={this.getLocations(form.customer_locations) || 'N/A'}
            />
            <StepReview title="Decision maker" value={form.customer_decision_maker || 'N/A'} />
            <StepReview
              title="Customer spend"
              value={form.customer_spend_start + ' to ' + form.customer_spend_end || 'N/A'}
            />
            {form.customer_age ? (
              <StepReview title="Age" value={form.customer_age || 'N/A'} />
            ) : null}
            {form.customer_gender ? (
              <StepReview title="Gender" value={form.customer_gender || 'N/A'} />
            ) : null}
            {form.customer_race.length ? (
              <StepReview title="Race" value={this.getList(form.customer_race) || 'N/A'} />
            ) : null}
            {form.customer_education ? (
              <StepReview title="Education" value={form.customer_education || 'N/A'} />
            ) : null}
          </div>
        </Card>
        <Card className="step-card" padding="40px 20px 25px 20px">
          <div className="step-title">
            <h4>Budget</h4>
          </div>
          <div className="step-box">
            <StepReview title="Pay per lead" value={form.budget_ppl || 'N/A'} />
            <StepReview title="Sales Rep commission" value={form.budget_commission || 'N/A'} />
            <StepReview title="Sales Rep bonus" value={form.budget_bonus || 'N/A'} />
          </div>
        </Card>
      </React.Fragment>
    );
  }
}
