import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Empty, message } from 'antd';
import { adminCampaigns, adminCampaignImport } from '../_graphql/queries';
import { masqueradeUser } from '../_graphql/mutations';
import { loginUser } from 'Store/user/user_actions';
import { getColumns } from './Columns';
import ViewMapping from './_modals/ViewMapping/ViewMapping.js';
import './Campaigns.scss';

class Campaigns extends Component {
  state = {
    data: [],
    reps: [],
    selectedRowKeys: [],
    pagination: { pageSize: 20 },
    loading: false,
    selected: null,
    viewMapping: null,
    campaignName: ''
  };
  componentDidMount() {
    this.fetch({ results: 20, page: 1 });
  }
  fetch = (params = {}) => {
    this.setState({ loading: true });
    this.props.client
      .query({
        variables: {
          name: this.state.searchText,
          ...params
        },
        query: adminCampaigns
      })
      .then(result => {
        if (result && result.data) {
          const campaigns = result.data.adminCampaigns.data || [];
          const reps = result.data.adminCampaigns.reps || [];
          const pagination = { ...this.state.pagination };
          pagination.total = result.data.adminCampaigns.pageTotal;
          pagination.totalAll = result.data.adminCampaigns.total;
          this.setState({
            loading: false,
            data: campaigns,
            reps,
            pagination
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(() => this.setState({ loading: false }));
  };
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      name: this.state.searchText,
      ...filters
    });
  };
  mapAgents = agents => {
    return agents
      .filter(a => a.active === true)
      .map(a => {
        const findRep = this.state.reps.find(r => r.id === a.agent_id);
        if (findRep) return { ...a, ...findRep };
        return a;
      });
  };
  onViewMappingClick = async campaign => {
    this.setState({ loading: true });
    let result;
    let viewMapping;
    try {
      result = await this.props.client.query({
        variables: {
          campaign_id: campaign.id
        },
        query: adminCampaignImport
      });
      viewMapping = JSON.parse(result.data.adminCampaignImport.mappings);
    } catch (e) {
      this.setState({ loading: false });
    }

    this.setState({ loading: false, viewMapping, campaignName: campaign.name });
  };
  onViewMappingHide = () => {
    this.setState({ viewMapping: null });
  };
  masq = user_id => {
    this.props.client
      .mutate({
        variables: { user_id: user_id },
        mutation: masqueradeUser
      })
      .then(result => {
        if (result && result.data && result.data.masqueradeUser) {
          this.props.loginUser(result.data.masqueradeUser);
          this.props.history.push('/');
        } else {
          throw new Error('Could not login as company, try again');
        }
      })
      .catch(err => {
        message.error(err.message);
      });
  };
  render() {
    const { pagination, data, loading } = this.state;
    const total = pagination.totalAll || 0;
    const totalFiltered = pagination.total || 0;
    const columns = getColumns(this.mapAgents, this.onViewMappingClick, this.masq);

    return (
      <div id="admin-campaigns">
        <div className="admin-header">
          Live Campaigns{' '}
          <div>
            Showing {totalFiltered} of {total}
          </div>
        </div>
        <div className="admin-table">
          <div className="table-content">
            <Table
              className="sellx-table bordered"
              bordered
              rowSelection={null}
              columns={columns}
              rowKey={record => record.id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              scroll={{ x: 'max-content' }}
              locale={{ emptyText: <Empty description="No agents" /> }}
            />
          </div>
        </div>

        {this.state.viewMapping ? (
          <ViewMapping
            client={this.props.client}
            mappings={this.state.viewMapping}
            removeModal={this.onViewMappingHide}
            campaignName={this.state.campaignName}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, { loginUser })(Campaigns));
