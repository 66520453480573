import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { IconUser, IconCash, IconCoin, IconBuildingBank, IconPower, IconTrophy } from '@tabler/icons-react';
import Account from './_components/Account/Account';
import Banking from './_components/Banking/Banking';
import Earnings from './_components/Earnings/Earnings';
import Reputation from './_components/Reputation/Reputation';
import Stipends from './_components/Stipends/Stipends';
import { Header, Menu, Card } from '_seriph';
import { hasAccess } from '_helpers/permissions';
import './Settings.scss';

class Settings extends Component {
  logoutCallback = () => this.props.history.push('/');
  render() {
    const currentPage = this.props.page || 'account';
    const reputation = this.props.info?.reputation || 0;

    return (
      <div id="agent-settings">
        <Header className="settings-header">Manage Account</Header>
        <div className="settings-content">
          <div className="settings-menu">
            <Card className="menu-card">
              <Menu mode="vertical" selectedKeys={[currentPage]} className="settings-antd">
                <Menu.Item key="account">
                  <Link to="/settings/account">
                    <IconUser size={16} stroke={1.75} /> Account
                  </Link>
                </Menu.Item>
                <Menu.Item key="reputation">
                  <Link to="/settings/reputation">
                    <IconTrophy size={16} stroke={1.75} /> Reputation
                  </Link>
                </Menu.Item>
                {hasAccess(['Rep']) && (
                  <Menu.Item key="banking">
                    <Link to="/settings/banking">
                      <IconBuildingBank size={16} stroke={1.75} /> Banking
                    </Link>
                  </Menu.Item>
                )}
                {hasAccess(['Rep']) && (
                  <Menu.Item key="earnings">
                    <Link to="/settings/earnings">
                      <IconCoin size={16} stroke={1.75} /> Earnings
                    </Link>
                  </Menu.Item>
                )}
                {hasAccess(['Rep']) && (
                  <Menu.Item key="payouts">
                    <Link to="/settings/payouts">
                      <IconCash size={16} stroke={1.75} /> Payouts
                    </Link>
                  </Menu.Item>
                )}
                <Menu.Divider />
                <Menu.Item key="logout" onClick={() => this.props.logoutUser(this.logoutCallback)}>
                  <IconPower size={16} stroke={1.75} /> Log out
                </Menu.Item>
              </Menu>
            </Card>
          </div>
          <div className="settings-main">
            {currentPage === 'account' && (
              <Account client={this.props.client} myself={this.props.user} />
            )}
            {currentPage === 'reputation' && (
              <Reputation client={this.props.client} myself={this.props.user} score={reputation} />
            )}
            {currentPage === 'banking' && (
              <Banking client={this.props.client} myself={this.props.user} info={this.props.info} />
            )}
            {currentPage === 'earnings' && (
              <Stipends client={this.props.client} myself={this.props.user} />
            )}
            {currentPage === 'payouts' && (
              <Earnings client={this.props.client} myself={this.props.user} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(Settings));
