import React, { Component } from 'react';
import { Modal, Button, message, Input } from 'antd';
import { disputeLead } from '_graphql/mutations/lead';
import { getErrors } from '_assets/js/helpers';
import { disputeMap } from '_constants/leadFields';
import './DisputeModal.scss';

export default class DisputeModal extends Component {
  state = {
    visible: true,
    reason: null,
    information: '',
    loading: false
  };
  disputeLead = () => {
    this.setState({ loading: true });
    this.props.client
      .mutate({
        variables: {
          lead_id: this.props.lead.id,
          reason: this.state.reason,
          information: this.state.information
        },
        mutation: disputeLead
      })
      .then(result => {
        if (result && result.data && result.data.disputeLead) {
          setTimeout(() => {
            this.props.reload(this.props.lead.id);
            setTimeout(this.hideModal, 250);
          }, 2000);
        } else {
          throw new Error('Error disputing lead');
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        message.error(getErrors(err) || 'Could not dispute lead, try again');
      });
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { reason, information, loading } = this.state;
    return (
      <Modal
        wrapClassName="dispute-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={false}
        width={640}
      >
        <div className="dispute-content">
          <h3>Dispute lead</h3>
          <h5>Let the rep know why this lead is being disputed.</h5>
          <div className="dispute-options">
            {Object.keys(disputeMap).map(d => (
              <div
                key={'dm-' + d}
                onClick={() => this.setState({ reason: d })}
                className={reason === d ? 'active' : ''}
              >
                {disputeMap[d]}
              </div>
            ))}
          </div>
          <Input.TextArea
            className="forms forms-textarea"
            rows={6}
            placeholder="Add additional information..."
            autoSize={false}
            value={information}
            onChange={e => this.setState({ information: e.target.value })}
          />
        </div>
        <div className="dispute-actions">
          <Button className="btn btn-default" onClick={this.hideModal}>
            Cancel
          </Button>
          <Button
            disabled={!reason || !information}
            loading={loading}
            className="btn btn-danger"
            onClick={this.disputeLead}
          >
            Dispute Lead
          </Button>
        </div>
      </Modal>
    );
  }
}
