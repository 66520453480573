import React, { Component } from 'react';
import axios from 'axios';
import { message, Button } from '_seriph';
import { IconPaperclip } from '@tabler/icons-react';
import { getErrors } from '_assets/js/helpers';

const apiPath = process.env.REACT_APP_API_PATH;

class Clippy extends Component {
  state = {
    attachmentUrl: null,
    uploadingFile: false,
    attachmentName: null
  };

  handleClearAttachment = () => {
    this.setState({ attachmentUrl: null, attachmentName: null }, () => {
      if (this.props.onClear) {
        this.props.onClear();
      }
    });
  };

  handleUploadAttachment = ev => {
    ev.stopPropagation();
    ev.preventDefault();
    this.setState({ uploadingFile: true }, () => {
      if (this.props.onUploadStart) {
        this.props.onUploadStart();
      }
    });
    if (!ev.target.files.length > 0) {
      this.setState({ uploadingFile: false }, () => {
        if (this.props.onError) {
          this.props.onError();
        }
      });
      return;
    }
    const file = ev.target.files[0];
    if (file.type !== 'application/pdf' || file.size > 5242880) {
      message.error('Only PDFs less than 5MB allowed');
      this.setState({ uploadingFile: false }, () => {
        if (this.props.onError) {
          this.props.onError();
        }
      });
      return;
    }
    const data = new FormData();
    data.append('file', file);
    axios
      .post(`${apiPath}/v1/pdf-upload`, data, {
        headers: {
          Authorization: 'JWT ' + localStorage.getItem('JWT'),
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        this.setState(
          {
            attachmentUrl: res.data.attachmentUrl,
            attachmentName: file.name,
            uploadingFile: false
          },
          () => {
            if (this.props.onUploadComplete) {
              this.props.onUploadComplete({
                attachmentUrl: res.data.attachmentUrl,
                attachmentName: file.name
              });
            }
          }
        );
      })
      .catch(err => {
        console.log(err);
        message.error(getErrors(err) || `Oops, something went wrong`);
        this.setState({ uploadingFile: false }, () => {
          if (this.props.onError) {
            this.props.onError();
          }
        });
      });
  };

  render() {
    const { uploadingFile, attachmentUrl } = this.state;
    return (
      <>
        {attachmentUrl === null && (
          <Button
            className={this.props.className}
            loading={uploadingFile}
            onClick={() => this.fileInput.click()}
            type="default"
            size="tiny"
          >
            {!uploadingFile && <IconPaperclip size={13} stroke={1.75} />}
          </Button>
        )}
        <input
          type="file"
          id="file"
          ref={ref => (this.fileInput = ref)}
          style={{ display: 'none' }}
          onChange={this.handleUploadAttachment}
        />
      </>
    );
  }
}

export default Clippy;
