import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, message, Button } from '_seriph';
import SelectList from './_components/SelectList/SelectList';
import CallWindow from './_components/CallWindow/CallWindow';
import { campaignAgents } from '_graphql/queries/campaign';
import { getErrors } from '_assets/js/helpers';
import { IconArrowsMaximize } from '@tabler/icons-react';

import './Dialer.scss';

class Dialer extends Component {
  state = {
    visible: true,
    loading: true,
    list: null,
    campaign: null,
    manualDial: false,
    minimize: false,
    showSettings: true,
    settings: {
      hangupVoicemail: true,
      autoDialExtensions: true,
      autoDial: false,
      delay: 500,
      timeout: 30,
      skip_after_answer: false,
      auto_complete_no_answer: false
    },
    phoneOptions: []
  };
  updateCampaignAgents = () => {
    this.props.client
      .query({ variables: { id: this.state.campaign.id }, query: campaignAgents })
      .then(result => {
        if (result?.data?.campaign?.agents) {
          const campaign = {...this.state.campaign};
          campaign.agents = result?.data?.campaign?.agents;
          this.setState({ campaign });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Could not load your new number, try again');
      });
  }
  updateSettings = (field, value) => {
    const settings = { ...this.state.settings };
    settings[field] = value;
    this.setState({ settings });
  };
  setList = (list, phoneOptions, campaign, agent) => {
    const stateUpdates = { list, phoneOptions: [...phoneOptions], campaign, agent };
    const manualDial = this.props.campaignId && this.props.leadId ? true : false;
    if (manualDial) {
      stateUpdates.settings = {
        hangupVoicemail: true,
        autoDialExtensions: true,
        autoDial: false,
        delay: 100,
        timeout: 200
      };
    }
    
    stateUpdates.manualDial = manualDial;
    stateUpdates.showSettings = false;
    this.setState(stateUpdates);
  };
  toggleMinimize = () => {
    this.setState({ minimize: !this.state.minimize });
  };
  toggleSettings = () => {
    this.setState({ showSettings: !this.state.showSettings });
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { list, campaign, agent, settings, phoneOptions, manualDial, minimize } = this.state;
    const { campaignId, leadId } = this.props;

    const showSettings = !manualDial && !minimize ? true : this.state.showSettings;
    const showList = list === null || campaign === null  ? true : false;
    return (
      <Modal
        wrapClassName={`dialer-v3 ${list !== null ? 'ready' : ''} ${minimize ? 'manual' : ''} ${!showSettings ? 'hide-settings' : false}`}
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={false}
        mask={minimize ? false : true}
      >

        <Modal.Close onClick={this.hideModal} />
        { !showList && <Modal.Minimize onClick={this.toggleMinimize} /> }
        { minimize ? <Button circle type="plain" className="expand" onClick={this.toggleMinimize}><IconArrowsMaximize /></Button> : null }

        { showList ? (
          <SelectList 
            client={this.props.client}
            setList={this.setList}
            campaignId={campaignId}
            leadId={leadId}
          />
        ) : (
          <CallWindow 
            client={this.props.client}
            list={list}
            info={this.props.info}
            phoneOptions={phoneOptions}
            campaign={campaign}
            agent={agent}
            reload={this.updateCampaignAgents}
            settings={settings}
            manualDial={manualDial}
            updateSettings={this.updateSettings}
            hideModal={this.hideModal}
            showSettings={showSettings}
            toggleSettings={this.toggleSettings}
          />
        )}

      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, {})(Dialer));
