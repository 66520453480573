import React, { Component } from 'react';
import './ProgressV2.scss';

export default class ProgressV2 extends Component {
  render() {
    const { progressData } = this.props;

    let totalPercent = progressData.reduce((a, b) => a + b.percent, 0);
    totalPercent = isNaN(totalPercent) || !totalPercent ? 0 : parseInt(totalPercent);

    let currentSum = 0;
    return (
      <div className="sxd-progress-campaign">
        <div className="bar">
          {progressData.map((p, i) => {
            const percent = p.percent < 4 && p.percent > 0 ? 4 : p.percent;
            currentSum = currentSum + percent;
            return p.percent > 0 ? (
              <div
                className="step"
                key={'bar-step-' + i}
                style={{
                  zIndex: 500 - currentSum,
                  width: `${currentSum}%`,
                  backgroundColor: p.color
                }}
              />
            ) : null;
          })}
        </div>
        <div className="percent">{totalPercent}%</div>
      </div>
    );
  }
}
