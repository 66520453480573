import moment from 'moment';
import { otherColors } from '_seriph/_constants/colors';

export const generateData = data => {
  const { days } = data;

  const chartData = {
    connectivity: [],
    emails: [],
    calls: [],
    not_interested: [
      {
        name: 'Bad timing',
        settings: { stroke: otherColors[0], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Financial issue',
        settings: { stroke: otherColors[1], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Using another solution already',
        settings: { stroke: otherColors[2], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Wrong contact person',
        settings: { stroke: otherColors[3], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'No reason',
        settings: { stroke: otherColors[4], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'No response',
        settings: { stroke: otherColors[5], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Wrong number',
        settings: { stroke: otherColors[6], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Bad email address',
        settings: { stroke: otherColors[7], strokeWidth: 3, dot: false },
        data: []
      },
      {
        name: 'Duplicate',
        settings: { stroke: otherColors[8], strokeWidth: 3, dot: false },
        data: []
      },
      { name: 'Other', settings: { stroke: otherColors[9], strokeWidth: 3, dot: false }, data: [] }
    ]
  };

  if (days && days.length > 0) {
    const totals = { ...days[0].daily };
    days.forEach((d, i) => {
      /* Totals */
      if (i > 0) Object.keys(d.daily).forEach(s => (totals[s] += d.daily[s]));

      /* Connectivity Chart */
      const dailyCall = (d.daily.called_answered || 0) + (d.daily.incoming_answered || 0);
      const dailyEmail = (d.daily.replied || 0) + (d.daily.received || 0);
      chartData.connectivity.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        Amount: dailyCall + dailyEmail,
        called_answered: d.daily.called_answered || 0,
        incoming_answered: d.daily.incoming_answered || 0,
        replied: d.daily.replied || 0,
        received: d.daily.received || 0
      });

      /* Sentiment Chart */
      chartData.not_interested[0].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.interest_bad_timing
      });
      chartData.not_interested[1].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.interest_financial
      });
      chartData.not_interested[2].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.interest_another_solution
      });
      chartData.not_interested[3].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.interest_wrong_contact
      });
      chartData.not_interested[4].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.interest_no_reason
      });
      chartData.not_interested[5].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.interest_no_response
      });
      chartData.not_interested[6].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.interest_wrong_number
      });
      chartData.not_interested[7].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.interest_bad_email
      });
      chartData.not_interested[8].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.interest_duplicate
      });
      chartData.not_interested[9].data.push({
        month: moment(d.date.toString()).format('MMM YY'),
        name: moment(d.date.toString()).format('MMM D'),
        value: d.daily.interest_other
      });

      chartData.not_interested[0].total = totals?.interest_bad_timing || 0;
      chartData.not_interested[1].total = totals?.interest_financial || 0;
      chartData.not_interested[2].total = totals?.interest_another_solution || 0;
      chartData.not_interested[3].total = totals?.interest_wrong_contact || 0;
      chartData.not_interested[4].total = totals?.interest_no_reason || 0;
      chartData.not_interested[5].total = totals?.interest_no_response || 0;
      chartData.not_interested[6].total = totals?.interest_wrong_number || 0;
      chartData.not_interested[7].total = totals?.interest_bad_email || 0;
      chartData.not_interested[8].total = totals?.interest_duplicate || 0;
      chartData.not_interested[9].total = totals?.interest_other || 0;
    });
    chartData.totals = totals;
  }

  /* Setup not-interested percentages */
  const niAmount = chartData.not_interested?.reduce((n, { total }) => n + total, 0) || 0;
  chartData.not_interested?.forEach((c, i) => {
    chartData.not_interested[i].percent = c.total / niAmount;
  });
  chartData.not_interested?.sort((a, b) => parseFloat(b.percent) - parseFloat(a.percent));

  /* EMAILS CHART */
  chartData.emails = [
    { name: 'Sent', fill: otherColors[0], value: chartData.totals?.emailed || 0 },
    { name: 'Delivered', fill: otherColors[1], value: chartData.totals?.delivered || 0 },
    { name: 'Opened', fill: otherColors[2], value: chartData.totals?.opened || 0 },
    { name: 'Clicked', fill: otherColors[3], value: chartData.totals?.clicked || 0 },
    { name: 'Replied', fill: otherColors[4], value: chartData.totals?.replied || 0 },
    { name: 'Received', fill: otherColors[5], value: chartData.totals?.received || 0 },
    { name: 'Bounced', fill: otherColors[6], value: chartData.totals?.bounced || 0 },
    { name: 'Unsubscribed', fill: otherColors[7], value: chartData.totals?.unsubscribed || 0 },
    { name: 'Reported as spam', fill: otherColors[8], value: chartData.totals?.spam || 0 }
  ];
  /* Setup email percentages */
  const totalAmount = chartData.emails?.reduce((n, { value }) => n + value, 0) || 0;
  chartData.emails?.forEach((c, i) => {
    chartData.emails[i].percent = c.value / totalAmount;
  });
  chartData.emails?.sort((a, b) => parseFloat(b.percent) - parseFloat(a.percent));

  /* CALLS CHART */
  chartData.calls = [
    { name: 'Called', fill: otherColors[0], value: chartData.totals?.called || 0 },
    { name: 'Answered', fill: otherColors[1], value: chartData.totals?.called_answered || 0 },
    { name: 'No answer', fill: otherColors[2], value: chartData.totals?.called_no_answer || 0 },
    { name: 'Incoming', fill: otherColors[3], value: chartData.totals?.incoming || 0 },
    {
      name: 'Incoming answered',
      fill: otherColors[4],
      value: chartData.totals?.incoming_answered || 0
    },
    { name: 'Invalid', fill: otherColors[5], value: chartData.totals?.call_invalid || 0 },
    { name: 'Busy', fill: otherColors[6], value: chartData.totals?.call_busy || 0 },
    {
      name: 'Short call (0 - 3 min)',
      fill: otherColors[7],
      value: chartData.totals?.call_short || 0
    },
    {
      name: 'Medium call (3 - 5 min)',
      fill: otherColors[8],
      value: chartData.totals?.call_medium || 0
    },
    { name: 'Long call (5+ min)', fill: otherColors[9], value: chartData.totals?.call_long || 0 }
  ];
  /* Setup email percentages */
  const callTotal = chartData.calls?.reduce((n, { value }) => n + value, 0) || 0;
  chartData.calls?.forEach((c, i) => {
    chartData.calls[i].percent = c.value / callTotal;
  });
  chartData.calls?.sort((a, b) => parseFloat(b.percent) - parseFloat(a.percent));

  return chartData;
};
