import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import JoinBG from '_assets/backgrounds/join-final.png';
import Quote from '_assets/icons/quote.svg';
import Logo from '_assets/img/full_black.svg';
import Type from './_components/Type/Type';
import CreateAccount from './_components/CreateAccount/CreateAccount';
import CompanyInfo from './_components/CompanyInfo/CompanyInfo';
import PersonalInfo from './_components/PersonalInfo/PersonalInfo';
import EmailVerification from './_components/EmailVerification/EmailVerification';
import Completed from './_components/Completed/Completed';
import { Avatar, Loading, message } from '_seriph';
import { loginUser } from 'Store/user/user_actions';
import { saveOnboard, verifyOnboard } from '_graphql/mutations/onboard';
import { getOnboard } from '_graphql/queries/onboard';
import { getErrors } from '_assets/js/helpers';
import Derek from '_assets/brands/derek.jpeg';
import './Join.scss';

class Join extends Component {
  state = {
    tab: this.props?.match?.params?.tab || 'create',
    form: {
      id: localStorage.getItem('onboard-id') || undefined,
      type: 'company'
    },
    saving: false,
    loading: true
  };
  componentDidMount = () => {
    const params = queryString.parse(this.props.location.search);
    if (params.key && this.state.tab === 'verify') {
      this.checkVerification(params.key);
    } else if (this.state.form.id) {
      this.loadOnboard();
    } else {
      this.setState({ loading: false });
    }
  };
  checkVerification = key => {
    this.props.client
      .mutate({ variables: { key }, mutation: verifyOnboard })
      .then(result => {
        if (result?.data?.verifyOnboard) {
          const newId = result?.data?.verifyOnboard;
          this.setState({ form: {...this.state.form, id: newId} },
            () => {
              this.loadOnboard();
              message.success('Your email has successfully been verified');
            }
          );
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.loadOnboard();
        message.error(getErrors(err) || 'Could not verify email, try again');
      });
  };
  loadOnboard = () => {
    this.props.client
      .query({ variables: { id: this.state.form.id }, query: getOnboard })
      .then(result => {
        if (result?.data?.getOnboard) {
          const formData = result?.data?.getOnboard;
          this.setState({ form: result?.data?.getOnboard, loading: false }, () => {
            if (!formData.email_verified && formData.id) {
              this.props.history.push(`/start/verify`);
            } else if (formData.email_verified && !formData.completed && formData.id) {
              this.props.history.push(`/start/company`);
            } else if (formData.email_verified && formData.completed && formData.id) {
              this.props.history.push(`/start/completed`);
            }
          });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.props.history.push(`/start/create`);
        message.error(getErrors(err) || 'Could not find application, try again');
      });
  };
  saveOnboard = (newRoute, finish) => {
    this.setState({ saving: true });
    this.props.client
      .mutate({
        variables: { onboard: {...this.state.form}, finish: finish },
        mutation: saveOnboard
      })
      .then(result => {
        if (result?.data?.saveOnboard) {
          if (finish) {
            message.success('Welcome to SellX!')
            this.props.loginUser(result?.data?.saveOnboard);
            this.props.history.push(`/`);
          } else {
            const newId = result?.data?.saveOnboard || this.state.form.id;
            localStorage.setItem('onboard-id', newId);
            this.setState({ saving: false, form: {...this.state.form, id: newId} },
              () => {
                if (newRoute) this.props.history.push(`/start/${newRoute}`)
              }
            );
          }
        } else {
          this.setState({ saving: false });
        }
      })
      .catch(err => {
        this.setState({ saving: false });
        message.error(getErrors(err) || 'Could not save campaign, try again');
      });
  };
  componentDidUpdate = () => {
    const prevTab = this.state.tab;
    let newTab = this.props?.match?.params?.tab || null;
    if (prevTab !== newTab && newTab) {
      this.setState({ tab: newTab || 'type' }, () => window.scrollTo({ top: 0 }));
    }
  };
  updateForm = (field, value) => {
    const form = {...this.state.form};
    form[field] = value;
    this.setState({ form });
  }
  render() {
    const { tab, form, saving, loading } = this.state;

    const onboardProps = {
      form,
      client: this.props.client,
      updateForm: this.updateForm,
      saveOnboard: this.saveOnboard
    };

    return (
      <div id="join">
        <img src={Logo} alt="SellX" className="join-logo" />
        <div className="join-form">
          { saving || loading ? <Loading /> : (
            <React.Fragment>
              { tab === 'type' && <Type next="create" {...onboardProps} /> }
              { tab === 'create' && <CreateAccount next="verify" {...onboardProps} /> }
              { tab === 'verify' && <EmailVerification next="company" {...onboardProps} /> }
              { tab === 'company' && <CompanyInfo next="personal" {...onboardProps} /> }
              { tab === 'personal' && <PersonalInfo {...onboardProps} /> }
              { tab === 'completed' && <Completed {...onboardProps} /> }
            </React.Fragment>
          )}
        </div>

        <div className="join-marketing">
          
          <div className="join-quote">
            <img src={Quote} alt="Quote" />
            <div className="quote-box">
              <div className="quote-text">
                The <i>platform</i> is what sold our team. This set SellX <i>apart from the competition.</i>
              </div>
              <div className="quote-person">
                <Avatar size="small" type="agent" picture={Derek} />
                <div className="qp-info">
                  <h3>Derek Henmi</h3>
                  <p>President & Co-Founder, Vivid</p>
                </div>
              </div>
            </div>
          </div>
          <div className="join-bg" style={{ backgroundImage: `url(${JoinBG})` }}></div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, { loginUser })(Join));
