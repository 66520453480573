import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { removeChilipiper } from '_graphql/mutations/integrations';
import { getErrors } from '_assets/js/helpers';
import ChilipiperLogo from '_assets/brands/chilipiper.png';
import AddChilipiperModal from './_modals/AddChilipiperModal/AddChilipiperModal';
import { Card, Button, message } from '_seriph';
import { IconCheck, IconX, IconPlus } from '@tabler/icons-react';
import './Chilipiper.scss';

class Chilipiper extends Component {
  state = {
    setting: null,
    modal: false
  };
  removeChilipiper = () => {
    this.setState({ setting: 'chilipiper' });
    this.props.client
      .mutate({
        variables: { refresh: this.props.integrations.chilipiper.refresh_token },
        mutation: removeChilipiper
      })
      .then(result => {
        if (result && result.data && result.data.removeChilipiper) {
          setTimeout(() => this.props.loadIntegrations(this.resetSetting), 100);
          message.success('Chilipiper successfully removed');
        }
      })
      .catch(err => {
        this.setState({ setting: null });
        message.error(getErrors(err) || 'Error removing chilipiper, try again');
      });
  };
  showModal = () => this.setState({ modal: true });
  removeModal = () => this.setState({ modal: false });
  resetSetting = () => this.setState({ setting: null });
  render() {
    const { setting } = this.state;
    const { integrations, loading } = this.props;

    const isIntegrated =
      integrations?.chilipiper?.domain && integrations?.chilipiper?.inbound_router;

    return (
      <Card id="chilipiper">
        <div className="int">
          <div className="int-header">
            <div className="int-logo">
              <img src={ChilipiperLogo} title="Chilipiper" alt="Chilipiper" />
            </div>
            <h3 className="int-name">
              Chilipiper
              { integrations?.chilipiper?.domain && <p>Account: {integrations?.chilipiper?.domain}</p> }
            </h3>
            {isIntegrated && !loading && (
              <div className="int-check">
                <IconCheck />
              </div>
            )}
          </div>
          <p className="int-desc">Chilipiper lets agents schedule meetings with qualified leads directly on your calendar</p>
          <div className="int-actions">
            {(!isIntegrated || loading) && (
              <Button
                disabled={loading}
                type="secondary"
                icon="left"
                loading={setting === 'chilipiper'}
                onClick={this.showModal}
              >
                <IconPlus />
                Connect to Chilipiper
              </Button>
            )}
            {isIntegrated && !loading && (
              <Button
                type="danger"
                icon="left"
                loading={setting === 'chilipiper'}
                onClick={this.removeChilipiper}
              >
                <IconX />
                Disconnect Chilipiper
              </Button>
            )}
          </div>
        </div>
        {this.state.modal ? (
          <AddChilipiperModal
            integrations={integrations}
            client={this.props.client}
            reload={() => this.props.loadIntegrations(this.resetSetting)}
            removeModal={this.removeModal}
          />
        ) : null}
      </Card>
    );
  }
}

export default withRouter(Chilipiper);
