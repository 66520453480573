import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Input, message, Tabs } from '_seriph';
import { adminAgents, getManagers } from '../_graphql/queries';
import { updateCohort, masqueradeUser, updateManager } from '../_graphql/mutations';
import { loginUser } from 'Store/user/user_actions';
import { getColumns } from './Columns';
import Salary from './_modals/Salary/Salary.js';
import Bonus from './_modals/Bonus/Bonus.js';
import './Agents.scss';

class Agents extends Component {
  state = {
    data: [],
    selectedRowKeys: [],
    pagination: { pageSize: 20 },
    loading: true,
    selected: null,
    searchText: '',
    searchTerm: '',
    previous: null,
    saving: null,
    showSalaryModal: false,
    tab: 'active',
    salary: {},
    tabAmounts: {
      active: 0,
      sellx: 0,
      internal: 0,
      unassigned: 0,
      banned: 0
    }
  };
  componentDidMount() {
    this.fetch();
  }
  fetch = async (paramOverrides = {}) => {
    const params = {
      ...{
        results: 20,
        page: 1,
        filterField: this.state.tab,
        sortField: 'paid',
        sortOrder: 'descend'
      },
      ...paramOverrides
    };
    this.setState({ loading: true, searchTerm: this.state.searchText });
    this.props.client
      .query({
        variables: { name: this.state.searchText, ...params },
        query: adminAgents
      })
      .then(result => {
        if (result && result.data) {
          const agents = result.data.adminAgents.data || [];
          const pagination = { ...this.state.pagination };
          const tabAmounts = { ...result.data.adminAgents.tabAmounts };
          pagination.total = result.data.adminAgents.pageTotal;
          pagination.totalAll = result.data.adminAgents.total;
          this.setState({
            loading: false,
            data: agents,
            pagination,
            previous: params,
            tabAmounts
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(() => this.setState({ loading: false }));

    const managers = (
      await this.props.client.query({
        query: getManagers
      })
    ).data.getManagers;
    if (managers) {
      this.setState({ managers });
    }
  };
  clearSearch = () => {
    this.setState({ searchText: '', searchTerm: '' }, () => {
      this.fetch();
      this.searchInput?.focus();
    });
  };
  saveCohort = (agent_id, cohort) => {
    this.setState({ saving: agent_id });
    this.props.client
      .mutate({
        variables: { agent_id, cohort },
        mutation: updateCohort
      })
      .then(result => {
        if (result && result.data && result.data.updateCohort) {
          if (this.state.previous) {
            this.fetch(this.state.previous);
          } else {
            this.fetch();
          }
          this.setState({ saving: null });
        } else {
          throw new Error('Could not update cohort, try again');
        }
      })
      .catch(err => {
        this.setState({ saving: null });
        message.error(err.message);
      });
  };
  saveManager = (agent_id, manager_id) => {
    this.setState({ saving: agent_id });
    this.props.client
      .mutate({
        variables: { agent_id, manager_id },
        mutation: updateManager
      })
      .then(result => {
        if (result?.data?.updateManager) {
          if (this.state.previous) {
            this.fetch(this.state.previous);
          } else {
            this.fetch();
          }
          this.setState({ saving: null });
        } else {
          throw new Error('Could not update manager, try again');
        }
      })
      .catch(err => {
        this.setState({ saving: null });
        message.error(err.message);
      });
  };
  masq = agent_id => {
    this.props.client
      .mutate({
        variables: { user_id: agent_id },
        mutation: masqueradeUser
      })
      .then(result => {
        if (result && result.data && result.data.masqueradeUser) {
          this.props.loginUser(result.data.masqueradeUser);
          this.props.history.push('/');
        } else {
          throw new Error('Could not login as agent, try again');
        }
      })
      .catch(err => {
        message.error(err.message);
      });
  };
  updateCohort = (agent_id, cohort) => {
    const data = this.state.data;
    data.map(d => {
      if (d.id === agent_id) d.cohort = cohort;
      return d;
    });
    this.setState({ data });
  };
  updateManager = (agent_id, manager_id) => {
    const data = this.state.data;
    data.map(d => {
      if (d.id === agent_id) d.manager_id = manager_id;
      return d;
    });
    this.setState({ data });
  };
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      name: this.state.searchText,
      ...filters
    });
  };

  onSalaryClick = async data => {
    this.setState({ showSalaryModal: true, agent: data, salary: data?.salary || {} });
  };
  onSalaryHide = () => {
    this.setState({ showSalaryModal: false, agent: {}, salary: {} });
  };
  onBonusClick = async data => {
    this.setState({ showBonusModal: true, agent: data });
  };
  onBonusHide = () => {
    this.setState({ showBonusModal: false, agent: {} });
  };
  changeTab = tab => {
    this.setState({ tab }, this.fetch);
  };
  render() {
    const { pagination, data, loading } = this.state;
    const columns = getColumns(
      this.updateCohort,
      this.saveCohort,
      this.state.saving,
      this.masq,
      this.onSalaryClick,
      this.props.user?.admin?.role === 'super' || this.props.user?.admin?.role === 'owner',
      this.updateManager,
      this.saveManager,
      this.state.managers,
      this.onBonusClick
    );

    return (
      <div id="admin-agents">
        <div className="admin-header">
          XDRs{' '}
          <Tabs onChange={this.changeTab} currentTab={this.state.tab}>
            <Tabs.Tab key="active" amount={this.state.tabAmounts['active']}>
              Active
            </Tabs.Tab>
            <Tabs.Tab key="sellx" amount={this.state.tabAmounts['sellx']}>
              SellX Reps
            </Tabs.Tab>
            <Tabs.Tab key="internal" amount={this.state.tabAmounts['internal']}>
              Internal Reps
            </Tabs.Tab>
            <Tabs.Tab key="unassigned" amount={this.state.tabAmounts['unassigned']}>
              Unassigned
            </Tabs.Tab>
            <Tabs.Tab key="banned" amount={this.state.tabAmounts['banned']}>
              Removed
            </Tabs.Tab>
          </Tabs>
          <Input
            placeholder="Search"
            value={this.state.searchText}
            tagValue={this.state.searchTerm}
            onClear={this.clearSearch}
            onChange={e => this.setState({ searchText: e.target.value })}
            onKeyDown={e =>
              e.key === 'Enter' ? this.fetch({ name: this.state.searchText }) : null
            }
            ref={node => (this.searchInput = node)}
          />
        </div>
        <div className="admin2-table">
          <Table
            lined
            rounded
            rowSelection={null}
            columns={columns}
            rowKey={record => record.id}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={this.handleTableChange}
            scroll={{ x: 'max-content' }}
            locale={{ emptyText: loading ? 'Loading sales reps...' : 'No sales reps found' }}
          />
        </div>
        {this.state.showSalaryModal ? (
          <Salary
            client={this.props.client}
            removeModal={this.onSalaryHide}
            salary={this.state.salary}
            agent={this.state.agent}
            reload={async agent => {
              await this.onSalaryClick(agent);
              await this.fetch();
            }}
          />
        ) : null}
        {this.state.showBonusModal ? (
          <Bonus
            client={this.props.client}
            removeModal={this.onBonusHide}
            agent={this.state.agent}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.user };
};

export default withRouter(connect(mapStateToProps, { loginUser })(Agents));
