import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { createFeedback } from '_graphql/mutations/feedback';
import CreateLead from 'App/Company/Leads/_modals/CreateLead/CreateLead';
import { Button, TextArea, Select, Header, message } from '_seriph';
import {
  IconNews,
  IconRocket,
  IconBuildingBank,
  IconUsers,
  IconCheck,
  IconMessage,
  IconChevronDown,
  IconChevronUp,
  IconSend
} from '@tabler/icons-react';
import { hasAccess } from '_helpers/permissions';
import './GetStarted.scss';

class GetStarted extends Component {
  state = {
    feedback: false,
    linkedin: false,
    linkedinSlug: null,
    linkedinProfile: null,
    showLIModal: false,
    savingFeedback: false,
    type: undefined,
    content: '',
    showCreate: false
  };
  submitFeedback = () => {
    this.setState({ savingFeedback: true });
    this.props.client
      .mutate({
        variables: { type: this.state.type, content: this.state.content },
        mutation: createFeedback
      })
      .then(result => {
        if (result && result.data && result.data.createFeedback) {
          this.setState({ savingFeedback: false, feedback: false, type: undefined, content: '' });
          message.success('Thank you for your feedback!');
        } else {
          message.error('Could not send feedback, try again');
          this.setState({ savingFeedback: false });
        }
      })
      .catch(() => {
        message.error('Could not send feedback, try again');
        this.setState({ savingFeedback: false });
      });
  };
  updateLinkedinProfile = e => {
    const regex = /http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/([A-z0-9_-]+)\/?/;
    const url = e.target.value;
    if (url && url.match(regex)) {
      const results = url.match(regex)[3];
      if (!results) message.error('Could not match LinkedIn URL, try again');
      this.setState({ linkedinProfile: url, linkedinSlug: results || null });
    } else {
      this.setState({ linkedinProfile: url, linkedinSlug: null });
    }
  };
  toggleFeedback = () => this.setState({ feedback: !this.state.feedback });
  toggleLinkedIn = () => this.setState({ linkedin: !this.state.linkedin });
  showLinkedInModal = () => this.setState({ showLIModal: true });
  removeLinkedInModal = () => this.setState({ showLIModal: false });
  showCreateModal = () => this.setState({ showCreate: true });
  removeCreateModal = () => this.setState({ showCreate: false });
  selectLead = (leadId, campaignId) => {
    this.props.history.push(`/leads/new?campaign=${campaignId}`);
  };
  render() {
    const { data, campaigns } = this.props;
    const { type, content, feedback, savingFeedback } = this.state;

    const hasNoProfile = data.agent && parseInt(data.agent.profile_percent) < 100;
    const hasNoCampaigns = campaigns && campaigns.length <= 0;
    const hasNoPayment = data.agent && !data.agent.has_payment_method;
    const hasSubmitted = data.agent && data.agent.has_submitted_lead;
    const hasNotCreated = data.agent && !data.agent.has_created_lead;
    const hasFirstLead = !hasNoCampaigns && !hasNotCreated;

    const tasks = [
      {
        title: `Complete your profile (${data.agent.profile_percent} complete)`,
        description: `Let companies know what you are all about!`,
        icon: <IconNews />,
        completed: !hasNoProfile,
        btnAction: '/profile',
        btnText: 'Build profile',
        hidden: hasAccess(['Company'])
      },
      {
        title: `Apply to your first campaign`,
        description: `Get the ball rolling by finding the perfect campaign to work on`,
        icon: <IconRocket />,
        completed: !hasNoCampaigns,
        btnAction: '/explore',
        btnText: 'Find Campaigns',
        hidden: hasAccess(['Company'])
      },
      {
        title: `Add your Bank Account`,
        description: `Get paid for each lead right away, no waiting for a paycheck`,
        icon: <IconBuildingBank />,
        completed: !hasNoPayment,
        btnAction: '/settings/banking',
        btnText: 'Setup Account',
        hidden: hasAccess(['Company', 'SellX'])
      },
      {
        title: `Create your first lead`,
        description: `Generate your first lead and get paid!`,
        icon: <IconUsers />,
        completed: hasFirstLead,
        btnType: 'function',
        btnAction: this.showCreateModal,
        btnText: 'Create Lead'
      },
      {
        title: `Submit your first lead`,
        description: `Qualify your first lead and get paid!`,
        icon: <IconUsers />,
        completed: hasSubmitted,
        btnAction: '/leads?stage=in-progress',
        btnText: 'View Leads'
      }
    ];
    tasks.sort((a, b) => a.completed - b.completed);
    const filteredTasks = tasks.filter(t => !t.hidden);

    return (
      <div id="agent-get-started">
        <Header size="3" font="sans" className="gs-title">
          Getting started tasks
        </Header>
        <div className="all-started">
          {filteredTasks.map((t, i) => (
            <div className={t.completed ? 'task done' : 'task'} key={'gs-' + i}>
              <div className="task-image">{t.completed ? <IconCheck /> : t.icon}</div>
              <div className="task-info">
                <Header size="4" font="sans">
                  {t.title}
                </Header>
                <p>{t.description}</p>
              </div>
              {t.btnType === 'function' ? (
                <Button type="primary" size="medium" disabled={t.completed} onClick={t.btnAction}>
                  {t.completed ? 'Completed' : t.btnText}
                </Button>
              ) : (
                <Link to={t.btnAction}>
                  <Button type="primary" size="medium" disabled={t.completed}>
                    {t.completed ? 'Completed' : t.btnText}
                  </Button>
                </Link>
              )}
            </div>
          ))}

          <div className="task task-drop">
            <div className="task-container">
              <div className="task-image" style={{ background: '#FDFAEF' }}>
                <IconMessage />
              </div>
              <div className="task-info">
                <Header size="4" font="sans">
                  Have feedback, questions, ideas?
                </Header>
                <p>Let us know how we can improve</p>
              </div>
              <Button type="default" size="medium" icon="right" onClick={this.toggleFeedback}>
                {feedback ? 'Hide' : 'Add'} Feedback
                {feedback ? (
                  <IconChevronUp size={18} stroke={1.75} />
                ) : (
                  <IconChevronDown size={18} stroke={1.75} />
                )}
              </Button>
            </div>
            <div className={`task-form ${feedback ? 'opened' : ''}`}>
              <div className="form-box">
                <Select
                  value={type}
                  placeholder="Feedback type..."
                  onChange={value => this.setState({ type: value })}
                >
                  <Select.Option value="Feedback">I have some feedback</Select.Option>
                  <Select.Option value="Question">I have a question</Select.Option>
                  <Select.Option value="Idea">I have an idea</Select.Option>
                </Select>
                <TextArea
                  value={content}
                  onChange={e => this.setState({ content: e.target.value })}
                  size="large"
                  rows={6}
                  placeholder="Write a message..."
                />
                <div className="form-actions">
                  <Button
                    loading={savingFeedback}
                    onClick={this.submitFeedback}
                    type="primary"
                    size="medium"
                    icon="left"
                  >
                    <IconSend size={18} stroke={1.75} />
                    Send Feedback
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {this.state.showCreate ? (
            <CreateLead
              client={this.props.client}
              selectLead={this.selectLead}
              campaigns={campaigns}
              removeModal={this.removeCreateModal}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(GetStarted);
