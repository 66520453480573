import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Button, Avatar, Card, Tooltip, Progress } from '_seriph';
import { statusMap } from '_constants/campaigns';
import EmptyBox from '../EmptyBox/EmptyBox';
import moment from 'moment';

import './CampaignCards.scss';

const ProgressBar = props => {
  const r = props.campaign;
  const completedPercent = Math.floor((r.stats.completed / r.stats.estimated_total) * 100);
  const inProgressPercent = Math.floor((r.stats.in_progress / r.stats.estimated_total) * 100);
  const reviewPercent = Math.floor((r.stats.review / r.stats.estimated_total) * 100);

  const progressMessage = (
    <div className="progress-tt">
      <div>
        <span className="complete" /> {r.stats.completed} Completed
      </div>
      <div>
        <span className="review" /> {r.stats.review} Pending Review
      </div>
      <div>
        <span className="progress" /> {r.stats.in_progress} In-Progress
      </div>
      <div>
        <span className="unassigned" /> {r.stats.remaining} Available
      </div>
    </div>
  );

  let progressData = [];
  progressData = [
    { color: '#87B0ED', percent: completedPercent },
    { color: '#B5B5FD', percent: reviewPercent },
    { color: '#ABD4A2', ignore: true, percent: inProgressPercent }
  ];

  return (
    <Tooltip title={progressMessage}>
      <div className="progress-table">
        <Progress progressData={progressData} rounded />
      </div>
    </Tooltip>
  );
};

class CampaignCards extends Component {
  render() {
    const { campaigns } = this.props;

    const showMore = campaigns?.length > 3 ? true : false;

    return (
      <div id="internal-campaign-cards">
        <h3>Campaigns</h3>
        { showMore && <Link to="/campaigns"><Button size="tiny" type="secondary">More Campaigns</Button></Link> }
        { campaigns?.length > 0 ? (
          <div className="icc-cards">
            { campaigns.filter((c, i) => i < 3 ? true : false).map(c => {
              return (
                <Card className="icc" key={'icc-'+c.id}>
                  <Avatar type="company" size="mini" picture={c?.company?.logo} />
                  <h4>{c.name}</h4>
                  <p>{statusMap[c.status]} {moment(c.status_updated * 1).format('M/D')}</p>
                  <ProgressBar campaign={c} />
                </Card>
              );
            })}
          </div>
        ) : (
          <EmptyBox
            title="Active campaigns will show here"
            description="Get added to a campaign to get started"
            to="/campaigns"
            button="My Campaigns"
          />
        )}
      </div>
    );
  }
}

export default withRouter(CampaignCards);
