import React, { Component } from 'react';
import { leadNotes } from '_graphql/queries/lead';
import { createNote } from '_graphql/mutations/lead';
import Guide from '_styleguide/Guide/Guide';
import moment from 'moment';
import queryString from 'query-string';
import ReactQuill from 'react-quill';
import { IconNote } from '@tabler/icons-react';
import { Card, Avatar, Empty, Button, message, Loading } from '_seriph';
import './Notes.scss';

const Note = props => {
  const { note } = props;
  const user = note.agent || note.employee;
  return (
    <Card className="lead-note" id={'note-' + note.id}>
      <div className="note-header">
        <Avatar size="mini" picture={user.picture} />
        <div className="note-user">{user.display_name}</div>
        <span>{moment(note.createdAt * 1).format('MMM D')}</span>
      </div>
      <div className="note-body">
        <p dangerouslySetInnerHTML={{ __html: note.content }}></p>
      </div>
    </Card>
  );
};

export default class Notes extends Component {
  state = {
    loading: true,
    notes: [],
    note: '',
    saving: false,
    readyForScroll: false,
    showEdit: false
  };
  componentDidMount() {
    this.loadNotes(this.props.lead.id);
  }
  componentDidUpdate = (prevProps, prevState) => {
    const params = queryString.parse(this.props.location.search);
    if (params.note && prevState.readyForScroll !== this.state.readyForScroll) {
      document.getElementById('note-' + params.note).scrollIntoView({ behavior: 'smooth' });
    }
  };
  UNSAFE_componentWillMount = () => window.scrollTo({ top: 0 });
  createDates = notes => {
    let dates = [];
    notes.forEach(data => {
      dates.push(moment(data.createdAt * 1).format('dddd, MMMM Do YYYY'));
    });
    return [...new Set(dates.reverse())].reverse();
  };
  loadNotes = id => {
    this.props.client
      .query({
        variables: { lead_id: id },
        query: leadNotes
      })
      .then(result => {
        if (result && result.data && result.data.leadNotes) {
          this.setState({ notes: result.data.leadNotes }, () => {
            setTimeout(() => this.setState({ readyForScroll: true }), 100);
          });
        } else {
          message.error('Could not load notes, try again');
        }
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error('Could not load notes, try again');
      });
  };
  createNote = () => {
    const lead = this.props.lead || {};
    this.setState({ saving: true });
    this.props.client
      .mutate({
        variables: { lead_id: lead.id, campaign_id: lead.campaign_id, content: this.state.note },
        mutation: createNote
      })
      .then(result => {
        if (result && result.data && result.data.createNote) {
          this.setState({ note: '' });
          this.loadNotes(lead.id);
        }
        this.setState({ saving: false });
      })
      .catch(() => {
        this.setState({ saving: false });
        message.error('Error creating note, try again.');
      });
  };
  render() {
    const { loading, notes, note, saving, showEdit } = this.state;
    const { canEdit, isAgent } = this.props;

    const modules = {
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link']
      ],
      clipboard: { matchVisual: false }
    };

    let dateList = null;
    if (notes.length > 0) {
      const dates = this.createDates(notes);
      dateList = dates.map((item, i) => {
        let notesList = notes.map(n => {
          if (moment(n.createdAt * 1).format('dddd, MMMM Do YYYY') === item) {
            return <Note key={n.id} note={n} />;
          }
          return null;
        });
        return (
          <div key={'notegroup-' + i} date={item} className="date-section">
            <div className="note-date">
              <span>{item === moment().format('dddd, MMMM Do YYYY') ? 'Today' : item}</span>
            </div>
            {notesList}
          </div>
        );
      });
    }

    const hasNotes = notes.length > 0 ? true : false;

    return loading ? (
      <Loading />
    ) : (
      <div id="lead-notes" className="sx-form">
        {isAgent && (
          <div style={{ padding: '32px 32px 0px 32px' }}>
            <Guide
              id="lead-notes-agent"
              message="Add notes about the lead to give the company a better understanding of why they would be interested in using their product or service."
            />
          </div>
        )}
        {canEdit && (hasNotes || (!hasNotes && showEdit)) ? (
          <div className="note-input">
            <div className="input-content">
              <ReactQuill
                modules={modules}
                value={note}
                onChange={note => this.setState({ note })}
                placeholder="Write a note..."
              />
              <Button
                type="primary"
                disabled={!this.state.note}
                loading={saving}
                onClick={this.createNote}
              >
                Post
              </Button>
            </div>
          </div>
        ) : null}
        {hasNotes || (!hasNotes && showEdit) ? (
          <div className="note-content">{notes.length > 0 ? dateList : null}</div>
        ) : (
          <Empty
            icon={<IconNote />}
            type="negative"
            title="No notes yet"
            onClick={() => this.setState({ showEdit: true })}
            action={<span>+&nbsp;&nbsp;Add a note</span>}
          >
            When reps learn additional info about a lead, or information that may be important to
            the company, they will add notes here.
          </Empty>
        )}
      </div>
    );
  }
}
