import React from 'react';
import moment from 'moment';

import { Alert, TextArea, InputDate } from '_seriph';

export function PauseTag({ formState: { note, date }, onChange }) {
  return (
    <>
      <InputDate
        label="Pause until"
        onChange={v => onChange('date', v)}
        disabledDate={current => {
          current && current < moment().endOf('day');
        }}
        size="large"
        value={date}
      />
      <TextArea
        value={note}
        onChange={v => onChange('note', v.target.value)}
        size="large"
        label={
          <>
            Add a note <span>(optional)</span>
          </>
        }
        rows={6}
        placeholder="Write a message..."
      />
      <Alert type="warning" icon={<></>}>
        <b>Pause - </b>
        Removes lead from your tasks, adding them back at the selected date.
      </Alert>
    </>
  );
}
