import one from '_assets/learn/1.png';
import two from '_assets/learn/2.png';
import four from '_assets/learn/4.png';
import five from '_assets/learn/5.png';
import six from '_assets/learn/6.png';
import seven from '_assets/learn/7.png';
import eight from '_assets/learn/8.png';
import nine from '_assets/learn/9.png';

export const LearnData = {
  Courses: [
    { title: 'Why do companies need sales reps?', length: 16, date: '2/10/2019', image: two },
    { title: 'Sales Machine: Become a B2B Master!', length: 21, date: '2/10/2019', image: nine },
    {
      title: 'How to become a lead generation machine',
      length: 16,
      date: '1/22/2019',
      image: four
    },
    { title: 'Cold calling, a new approach', length: 10, date: '12/22/2018', image: five },
    { title: 'Generate lead with Sellx', length: 16, date: '2/10/2019', image: one },
    { title: 'Close deals in your pajamas', length: 21, date: '2/10/2019', image: six },
    { title: 'Learn to sell anything', length: 16, date: '1/22/2019', image: seven },
    { title: 'How to: sales fundamentals', length: 10, date: '12/22/2018', image: eight }
  ],
  Lessons: [
    { title: 'Generate lead with Sellx', length: 16, date: '2/10/2019', image: one },
    { title: 'Close deals in your pajamas', length: 21, date: '2/10/2019', image: six },
    { title: 'Learn to sell anything', length: 16, date: '1/22/2019', image: seven },
    { title: 'How to: sales fundamentals', length: 10, date: '12/22/2018', image: eight },
    { title: 'Why do companies need sales reps?', length: 16, date: '2/10/2019', image: two },
    { title: 'Sales Machine: Become a B2B Master!', length: 21, date: '2/10/2019', image: nine },
    {
      title: 'How to become a lead generation machine',
      length: 16,
      date: '1/22/2019',
      image: four
    },
    { title: 'Cold calling, a new approach', length: 10, date: '12/22/2018', image: five }
  ]
};

export const Tutorials = {
  profile: {
    title: 'Sales Rep Profile',
    length: '1 min 52 sec',
    date: '2/10/2020',
    videoId: 'PijaOr3ngzI',
    description:
      'Learn how to properly fill out your profile.  Companies find agents like you through your profile, so you can improve your chances of being hired by having a full profile.  Even easier, you can import your profile from LinkedIn!',
    percent: Math.round(16.66666 * 1) + '%',
    index: 1,
    next: 'explore',
    nextText: 'Learn to Explore Jobs',
    image: two
  },
  explore: {
    title: 'Explore Jobs',
    length: '1 min 22 sec',
    date: '2/10/2020',
    videoId: 'ODOTj-S9ekc',
    description:
      'The explore page lets you find the job that is perfect for you!  Learn how to use filters and preview jobs to find the best fit for you to earn money.',
    percent: Math.round(16.66666 * 2) + '%',
    index: 2,
    next: 'jobs',
    nextText: 'Learn about My Jobs',
    image: four
  },
  jobs: {
    title: 'My Jobs',
    length: '52 sec',
    date: '2/10/2020',
    videoId: 'CtGKRrf1W98',
    description:
      'Your "My Jobs page" has a list of every job your are working on, or have worked on in the past.  Use this as your launching point to work on specific jobs or check a campaign\'s progress',
    percent: Math.round(16.66666 * 3) + '%',
    index: 3,
    next: 'instructions',
    nextText: 'Learn about Job Instructions',
    image: five
  },
  instructions: {
    title: 'Job Instructions',
    length: '48 sec',
    date: '2/10/2020',
    videoId: 'JneQFfmUs2I',
    description:
      'The instructions page is extremely important to review.  This is your one stop shop to gather all of the information you need to help the company find EXACTLY what leads it is looking for.  The more accurate the lead, the more likely to earn money fast!',
    percent: Math.round(16.66666 * 4) + '%',
    index: 4,
    next: 'overview',
    nextText: 'Learn about Job Overview',
    image: nine
  },
  overview: {
    title: 'Job Overview',
    length: '52 sec',
    date: '2/10/2020',
    videoId: 'E-CGIVcQ-HA',
    description:
      'Keep track of your progress and all the money you have earned on the job so far!  Also, compare your stats against other agents on the campaign to see who is earning the most.',
    percent: Math.round(16.66666 * 5) + '%',
    index: 5,
    next: 'leads',
    nextText: 'Learn about Leads',
    image: eight
  },
  leads: {
    title: 'Leads',
    length: '2 min 42 sec',
    date: '2/10/2020',
    videoId: 'XaUI2nrGMqY',
    description:
      'The most important part of the process, creating a lead.  You want to create the best lead possible using the information provided in the instructions.  Learn how to fill out and submit your lead for review.',
    percent: Math.round(16.66666 * 6) + '%',
    index: 6,
    next: null,
    nextText: null,
    image: seven
  }
};
