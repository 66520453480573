import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import Avatar from '_styleguide/Avatar/Avatar';

export const getColumns = acknowledgeFeedback => {
  return [
    {
      title: 'From',
      dataIndex: 'from',
      render: (column, data) => (
        <div className="ctbl-box">
          {data.employee ? (
            <Avatar
              size="tiny"
              type="company"
              picture={data.employee.picture}
              company={data.company.logo}
            />
          ) : (
            <Avatar size="tiny" type="agent" picture={data.agent.picture} />
          )}
          <div className="ctbl-name">
            <h4>{data.agent ? data.agent.display_name : data.employee.display_name}</h4>
            <h5>{data.agent ? 'Sales Rep' : data.company.name}</h5>
          </div>
        </div>
      )
    },
    {
      title: 'Submitted',
      dataIndex: 'createdAt',
      render: column => moment(column * 1).format('M/D/YY h:mma')
    },
    {
      title: 'Content',
      dataIndex: 'content',
      render: (column, data) => (
        <div className="feedback-content">
          <h4>Type: {data.type}</h4>
          <p>{column}</p>
        </div>
      )
    },
    {
      title: 'Actions',
      dataIndex: 'acknowledged',
      fixed: 'right',
      render: (column, data) => {
        return (
          <Button
            disabled={column}
            onClick={() => acknowledgeFeedback(data.id)}
            className="btnx btnx-primary"
          >
            {column ? 'Acknowledged' : 'Acknowledge Feedback'}
          </Button>
        );
      }
    }
  ];
};
