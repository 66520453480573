import React from 'react';
import { IconCalendarTime } from '@tabler/icons-react';
import moment from 'moment';

export default function New(props) {
  const { activity, user } = props;

  const { start_time, name } = activity.meeting;

  return (
    <div className={'lead-activity ' + activity.type}>
      <div className="act-icon">
        <IconCalendarTime />
      </div>
      <div className="descript">
        <b>{user.display_name}</b> scheduled a meeting{' '}
        {start_time ? (
          <span>
            for <b>{moment(start_time * 1).format('M/D @ h:mma')}</b> on the {name} calendar
            (Calendly)
          </span>
        ) : null}
        <div className="act-tstamp">
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;{moment(activity.createdAt * 1).format('MMM D @ h:mm a')}
        </div>
      </div>
    </div>
  );
}
