import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Header, Button, message, Loading, Table, Tabs } from '_seriph';
import Touchpoints from './_components/Touchpoints/Touchpoints';
import Sequence from './_components/Sequence/Sequence';
import NewSequenceModal from './_modals/NewSequenceModal/NewSequenceModal';
import TouchpointModal from './_modals/TouchpointModal/TouchpointModal';
import { getSequences, getTouchpoints } from '_graphql/queries/sequence';
import { cloneSequence } from '_graphql/mutations/sequence';
import { getErrors } from '_assets/js/helpers';
import { getColumns } from './_data/Columns';
import './Sequences.scss';

class Sequences extends Component {
  state = {
    loadingSequences: true,
    loadingTouchpoints: true,
    sequences: [],
    touchpoints: [],
    tab: undefined,
    pagination: {
      pageSize: 10
    }
  };
  componentDidMount = () => {
    this.onLoadPage();
  };
  onLoadPage = () => {
    if (this.props.sequenceId) {
      this.setState(
        {
          sequenceId: this.props.sequenceId,
          loadingSequences: false
        },
        this.getTouchpoints
      );
    } else {
      this.setState(
        {
          sequenceId: null,
          loadingSequences: true,
          loadingTouchpoints: true
        },
        () => {
          this.getSequences();
          this.getTouchpoints();
        }
      );
    }
  };
  componentDidUpdate = prevProps => {
    const prevId = prevProps.sequenceId;
    const newId = this.props.sequenceId;
    if (prevId !== newId) {
      this.onLoadPage();
    }
  };
  getSequences = () => {
    this.props.client
      .query({ query: getSequences })
      .then(result => {
        if (result && result.data && result.data.getSequences) {
          const sequences = result.data.getSequences || [];
          const counts = this.getCounts(sequences);
          let tab = 'archived';
          if (counts.draft > 0) tab = 'draft';
          if (counts.active > 0) tab = 'active';
          if (sequences.length <= 0) tab = 'active';
          this.setState({ sequences, tab, loadingSequences: false });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ loadingSequences: false });
        message.error(getErrors(err) || 'Could not load sequences, try again');
      });
  };
  getTouchpoints = () => {
    this.props.client
      .query({ query: getTouchpoints })
      .then(result => {
        if (result && result.data && result.data.getTouchpoints) {
          const touchpoints = result.data.getTouchpoints || [];
          this.setState({ touchpoints, loadingTouchpoints: false });
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.setState({ loadingTouchpoints: false });
        message.error(getErrors(err) || 'Could not load touchpoints, try again');
      });
  };
  cloneSequence = sequenceId => {
    this.props.client
      .mutate({ variables: { id: sequenceId }, mutation: cloneSequence })
      .then(result => {
        if (result && result.data && result.data.cloneSequence) {
          message.success('Sequence cloned');
          this.props.history.push('/sequences/' + result.data.cloneSequence);
        }
      })
      .catch(err => {
        message.error(getErrors(err) || 'Error cloning sequence, try again.');
      });
  };
  showTouchpointModal = (id, type, updateTouchpoint) => {
    this.setState({
      touchpointModal: id,
      touchpointModalType: type,
      updateTouchpoint
    });
  };
  removeTouchpointModal = () => {
    this.setState({
      touchpointModal: false,
      touchpointModalType: undefined,
      updateTouchpoint: undefined
    });
  };
  changeTab = tab => {
    this.setState({ tab });
  };
  getCounts = sequences => {
    const seq = sequences || this.state.sequences;
    return {
      active: seq.filter(s => s.campaigns?.length > 0 && !s.archived).length,
      draft: seq.filter(s => s.campaigns?.length <= 0 && !s.archived).length,
      archived: seq.filter(s => s.archived === true).length
    };
  };
  showNewSequenceModal = () => this.setState({ newSequenceModal: true });
  removeNewSequenceModal = () => this.setState({ newSequenceModal: false });
  render() {
    const { sequenceId, sequences, touchpoints, loadingSequences, loadingTouchpoints, tab } =
      this.state;

    const loading = loadingSequences || loadingTouchpoints ? true : false;
    const columns = getColumns(this.getSequences, this.cloneSequence);
    const counts = this.getCounts();

    const filteredSequences = sequences
      .filter(s => {
        if (tab === 'active') return s.campaigns?.length > 0 && !s.archived;
        if (tab === 'draft') return s.campaigns?.length <= 0 && !s.archived;
        if (tab === 'archived') return s.archived === true;
        return false;
      })
      .map(s => {
        const campaigns = s.campaigns || [];
        const activeCampaigns = campaigns.filter(
          c => c.status === 'active' || c.status === 'inactive'
        );
        const isActive = activeCampaigns?.length > 0;
        return {
          ...s,
          isActive
        };
      })
      .sort((a, b) => b.isActive - a.isActive);

    return loading ? (
      <Loading />
    ) : (
      <div id="company-sequences">
        <Touchpoints 
          touchpoints={touchpoints} 
          showTouchpointModal={this.showTouchpointModal}
          company={this.props.company}
        />

        {sequenceId ? (
          <Sequence
            cloneSequence={this.cloneSequence}
            touchpoints={touchpoints}
            sequenceId={sequenceId}
            client={this.props.client}
            showTouchpointModal={this.showTouchpointModal}
            company={this.props.company}
          />
        ) : (
          <div className="sequence-content">
            <div className="sequence-wrap">
              <div className="seriph-header-wrapper">
                <Header type="display" weight="600" size="4">
                  Sequences
                </Header>
                <div className="header-actions">
                  <Button type="primary" onClick={this.showNewSequenceModal}>
                    New sequence
                  </Button>
                </div>
              </div>
              <div className="subheader">
                Create workflows of touchpoints for reps to follow when doing outreach.
              </div>
              <Tabs onChange={this.changeTab} currentTab={tab}>
                <Tabs.Tab key="active" amount={counts.active} disabled={!counts.active}>
                  ACTIVE
                </Tabs.Tab>
                <Tabs.Tab key="draft" amount={counts.draft} disabled={!counts.draft}>
                  DRAFT
                </Tabs.Tab>
                <Tabs.Tab key="archived" amount={counts.archived} disabled={!counts.archived}>
                  ARCHIVED
                </Tabs.Tab>
              </Tabs>
              <div className="sequence-table">
                <Table
                  lined
                  rounded
                  columns={columns}
                  rowKey={record => record.id}
                  dataSource={filteredSequences}
                  scroll={{ x: 'max-content' }}
                  locale={{ emptyText: `No ${tab} sequences found` }}
                />
              </div>
            </div>
          </div>
        )}

        {this.state.newSequenceModal ? (
          <NewSequenceModal client={this.props.client} removeModal={this.removeNewSequenceModal} />
        ) : null}

        {this.state.touchpointModal ? (
          <TouchpointModal
            touchpointId={this.state.touchpointModal}
            touchpointType={this.state.touchpointModalType}
            updateTouchpoint={this.state.updateTouchpoint}
            client={this.props.client}
            removeModal={this.removeTouchpointModal}
            reload={this.getTouchpoints}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(Sequences);
