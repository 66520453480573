import earner from '_assets/reputation/earner_new.svg';
import big_earner from '_assets/reputation/big_earner_new.svg';
import super_earner from '_assets/reputation/super_earner_new.svg';
import ultra_earner from '_assets/reputation/ultra_earner_new.svg';
import extreme_earner from '_assets/reputation/xtreme_earner_new.svg';

const MAX = 1000000;
const tierRange = [100, 500, 1000, 4000, 10000, 20000, MAX];
const reputationRanges = [
  [100, 499],
  [500, 999],
  [1000, 3999],
  [4000, 9999],
  [10000, 19999]
];

const reputationImages = [earner, big_earner, super_earner, ultra_earner, extreme_earner];
const reputationText = ['Earner', 'Big Earner', 'Super Earner', 'Ultra Earner', 'Xpert Earner'];

const MAX_INDEX = reputationImages.length - 1;
const reputationLetters = ['E', 'B', 'S', 'U', 'X'];

export const determineReputation = reputation => {
  let returnVal = reputation ? tierRange.findIndex(e => reputation < e) - 1 : 0;
  if (returnVal == -1) returnVal = 0;
  return returnVal;
};
export const reputationRangeForIndex = index => {
  return reputationRanges[Math.min(MAX_INDEX, index)];
};
export const imageForReputationIndex = index => reputationImages[Math.min(MAX_INDEX, index)];
export const reputationLetterIndex = index => reputationLetters[Math.min(MAX_INDEX, index)];
export const reputationTextIndex = index => reputationText[Math.min(MAX_INDEX, index)];
