import { createStore, combineReducers } from 'redux';

import user from './user/user_reducers';
import onboard from './onboard/onboard_reducers';
import apollo from './apollo/apollo_reducers';
import agent from './agent/agent_reducers';

export default createStore(
  combineReducers({
    user,
    onboard,
    agent,
    apollo
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
