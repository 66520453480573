import React from 'react';
import { Drawer, Tabs } from 'antd';
import Call from './_components/Call/Call';
import General from './_components/General/General';
import Email from './_components/Email/Email';
import { Header } from '_seriph';
import './Notifications.scss';

const { TabPane } = Tabs;

const Notifications = ({ small, drawer, closeDrawer, client, user, reload }) => {
  const className = `notify notification${small ? ' small' : ''}`;
  //const isAgent = type === 'agent';

  return (
    <Drawer
      title={
        <Header size="3" font="sans">
          Notifications
        </Header>
      }
      placement="left"
      closable={true}
      visible={drawer}
      onClose={closeDrawer}
      className={className}
      mask={true}
      width="380"
    >
      <Tabs defaultActiveKey="1">
        <TabPane style={{ height: '100vh ' }} tab="General" key="1">
          <General client={client} reload={reload} closeDrawer={closeDrawer} user={user} />
        </TabPane>
        {false && (
          <TabPane tab="Email" key="2">
            <Email client={client} reload={reload} closeDrawer={closeDrawer} user={user} />
          </TabPane>
        )}
        {false && (
          <TabPane tab="Calls" key="3">
            <Call client={client} reload={reload} closeDrawer={closeDrawer} user={user} />
          </TabPane>
        )}
      </Tabs>
    </Drawer>
  );
};

export default Notifications;
