import React, { Component } from 'react';
import { getErrors, formatMoney } from '_assets/js/helpers';
import { billDetails } from '_graphql/queries/company';
import { message } from 'antd';
import Loading from '_shared/Loading/Loading';
import { Modal, Button, Header, Tag } from '_seriph';
import moment from 'moment';
import './BillDetails.scss';

const lineCategories = {
  lead: 'Lead payments',
  plan: 'Monthly Plan',
  addon: 'Plan add-ons'
};

const lineDescriptors = {
  lead: 'Campaigns that leads were paid from',
  plan: null,
  addon: null
};

export default class BillDetails extends Component {
  state = {
    visible: true,
    loading: true,
    billDetails: null
  };
  componentDidMount = () => {
    this.loadBillDetails();
  };
  fetchReceiptUrl = () => {
    const newWindow = window.open(this.state.billDetails.pdf_url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  loadBillDetails = () => {
    this.props.client
      .query({
        variables: {
          id: this.props.billId
        },
        query: billDetails
      })
      .then(result => {
        if (result?.data?.billDetails) {
          this.setState({
            loading: false,
            billDetails: result.data.billDetails
          });
        } else {
          throw new Error('Could not load bill details, try again');
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        message.error(getErrors(err) || 'Could not load bill details, try again');
      });
  };
  getStatusTag = status => {
    if (status === 'paid') {
      return <Tag type="primary">Paid</Tag>;
    } else if (status === 'processing') {
      return <Tag type="default">Processing</Tag>;
    } else if (status === 'failed') {
      return <Tag type="danger">Failed</Tag>;
    } else if (status === 'refunded') {
      return <Tag type="info">Refunded</Tag>;
    }
    return <Tag>{status}</Tag>;
  };
  getLineItems = billDetails => {
    if (!billDetails) return [];
    const lineTypes = {};
    billDetails.items.forEach(i => {
      if (!lineTypes[i.type]) {
        lineTypes[i.type] = {
          category: lineCategories[i.type],
          subCategory: lineDescriptors[i.type],
          total: i.charge_amount,
          groups: {},
          items: [{ ...i, group_id: i.campaign.id }]
        };
      } else {
        lineTypes[i.type].total += i.charge_amount;
        lineTypes[i.type].items.push({ ...i, group_id: i.campaign.id });
      }
      // Handle Grouping
      if (i.type === 'lead') {
        if (!lineTypes[i.type].groups[i.campaign.id]) {
          lineTypes[i.type].groups[i.campaign.id] = {
            name: i.campaign.name,
            total: i.charge_amount
          };
        } else {
          lineTypes[i.type].groups[i.campaign.id].total += i.charge_amount;
        }
      }
    });
    return lineTypes;
  };
  hideModal = () => this.setState({ visible: false });
  render() {
    const { billDetails, loading } = this.state;

    const lineItems = this.getLineItems(billDetails);
    return (
      <Modal
        wrapClassName="bill-details-modal"
        visible={this.state.visible}
        title={null}
        footer={null}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
        closable={false}
        maskClosable={true}
        width={700}
      >
        {loading ? (
          <Loading />
        ) : (
          <div className="bd-wrapper">
            <div className="bd-details">
              <Modal.Close onClick={this.hideModal} />

              <Header size="2" type="serif">
                Threshold Trigger
              </Header>

              <div className="bd-info">
                {this.getStatusTag(billDetails.status)}
                on<span className="last-date">{moment(billDetails.updatedAt * 1).format('L')}</span>
              </div>

              <h5>SUMMARY</h5>
              <div className="bd-summary">
                {Object.keys(lineItems).map(l => {
                  const item = lineItems[l];
                  return (
                    <div key={'line-item-' + l} className="line-cat">
                      <div className="line-item">
                        <h4>{item.category}</h4>
                        <h4>+{formatMoney(item.total)}</h4>
                      </div>
                      {item.subCategory && <div className="line-info">{item.subCategory}</div>}
                      {Object.keys(item.groups).map(g => {
                        const groupInfo = item.groups[g];
                        const indiItems = (item.items || []).filter(i => i.group_id === g);
                        return (
                          <React.Fragment key={l + 'ig' + g}>
                            <div className="line-item">
                              <h5>&nbsp;&nbsp;-&nbsp;{groupInfo.name}</h5>
                            </div>
                            { indiItems?.map(i => (
                              <div key={g + '-ii-'+i.id} className="line-item i-item">
                                <h5>&nbsp;&nbsp;&nbsp;&nbsp;&middot;&nbsp;<span>({i.type})</span>{i.name}</h5>
                                <h5>{formatMoney(i.charge_amount)}</h5>
                              </div>
                            ))}
                          </React.Fragment>
                        );
                      })}
                      
                    </div>
                  );
                })}

                <div className="line-item payment-total">
                  <h3>Payment total</h3>
                  <h3 className="subtotal">+{formatMoney(billDetails.total)}</h3>
                </div>
              </div>

              <div className="bd-hint">
                If you have any questions, contact us at{' '}
                <a href="mailto:support@sellx.com">support@sellx.com</a>
              </div>

              <Modal.Actions align="right">
                <Button type="secondary" onClick={() => this.fetchReceiptUrl()}>
                  DOWNLOAD RECEIPT
                </Button>
                <Button onClick={this.hideModal}>CLOSE</Button>
              </Modal.Actions>
            </div>
            <div className="bd-activity">
              <Header size="4" type="sans">
                Activity
              </Header>
              <div className="bd-list">
                {billDetails.activity.map((a, i) => (
                  <div key={i + ' - ' + a.id} className="activity-item">
                    <div className="left"></div>
                    <div className="right">
                      <b>{a.name};</b> on {moment(a.timestamp * 1).format('L')}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
