import React, { Component } from 'react';
import logo from '_assets/img/full_white_logo.svg';
import unplug from '_assets/icons/unplug.svg';
import { Header } from '_seriph';
import './Construction.scss';

export default class Construction extends Component {
  render() {
    return (
      <div id="construction">
        <div className="home-logo">
          <a href="https://sellx.com">
            <img src={logo} alt={logo} />
          </a>
        </div>
        <div className="construction-body">
          <Header type="display" size="1" className="headline">
            We&apos;ll be right back
          </Header>
          <p>
            SellX is undergoing scheduled maintenance. <br />
            We’ll be back online soon. Thank you for your patience!
          </p>
        </div>
        <div className="unplug">
          <img src={unplug} alt="" />
        </div>
      </div>
    );
  }
}
