export default [
  'Analytics',
  'Chat / Messaging',
  'Coaching',
  'Content',
  'Conversation Intelligence',
  'CRM',
  'Data & Enrichment',
  'Email',
  'Integration Platform',
  'Marketing',
  'Privacy & Security',
  'Scheduling',
  'Sales Intelligence',
  'Sales Productivity',
  'Surveys & Feedback',
  'Video Conferencing',
  'Other'
];
