import gql from 'graphql-tag';

export const updateAccount = gql`
  mutation updateAccount($first_name: String!, $last_name: String!, $phone_number: String) {
    updateAccount(first_name: $first_name, last_name: $last_name, phone_number: $phone_number)
  }
`;

export const updatePassword = gql`
  mutation updatePassword($current: String!, $new: String!) {
    updatePassword(current: $current, new: $new)
  }
`;

export const updateEmail = gql`
  mutation updateEmail($email: String!, $password: String!) {
    updateEmail(email: $email, password: $password)
  }
`;

export const createStripe = gql`
  mutation createStripe($code: String!) {
    createStripe(code: $code)
  }
`;
