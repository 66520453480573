import { otherColors } from '_seriph/_constants/colors';

export const generateData = data => {

  const chartData = { calls: [] };
  const total = data?.total_calls;

  /* CALLS CHART */
  chartData.calls = [
    { name: '0 - 1 Minutes', fill: otherColors[1], value: data?.dur_0_1 || 0 },
    { name: '1 - 2 Minutes', fill: otherColors[2], value: data?.dur_1_2 || 0 },
    { name: '2 - 5 Minutes', fill: otherColors[3], value: data?.dur_2_5 || 0 },
    { name: '5 - 10 Minutes', fill: otherColors[4], value: data?.dur_5_10 || 0 },
    { name: '10 + Minutes', fill: otherColors[5], value: data?.dur_10 || 0 }
  ];
  /* Setup email percentages */
  chartData.calls?.forEach((c, i) => { chartData.calls[i].percent = c.value / total * 100 });

  return chartData;
};
