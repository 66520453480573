import React, { Component } from 'react';
import { Table, Empty, message, Button } from 'antd';
import { acknowledgeFeedback } from '../_graphql/mutations';
import { adminTemplates } from '../_graphql/queries';
import { getColumns } from './Columns';
import TemplateModal from './_modals/TemplateModal/TemplateModal';
import './Templates.scss';

const types = [
  { label: 'email', value: 'email' },
  { label: 'call', value: 'call' },
  { label: 'linkedin', value: 'linkedin' },
  { label: 'General Task', value: 'instructions' }
];

export default class Templates extends Component {
  state = {
    data: [],
    selectedRowKeys: [],
    pagination: { pageSize: 20 },
    loading: false,
    selected: null,
    searchText: '',
    reviewCandidate: false
  };
  componentDidMount() {
    this.fetch({ results: 20, page: 1 });
  }
  fetch = (params = {}) => {
    this.setState({ loading: true });
    this.props.client
      .query({
        variables: {
          name: this.state.searchText,
          ...params
        },
        query: adminTemplates
      })
      .then(result => {
        if (result && result.data) {
          const applications = result.data.adminTemplates.data || [];
          const pagination = { ...this.state.pagination };
          pagination.total = result.data.adminTemplates.pageTotal;
          pagination.totalAll = result.data.adminTemplates.total;
          this.setState({
            loading: false,
            data: applications,
            pagination
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(() => this.setState({ loading: false }));
  };
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      name: this.state.searchText,
      ...filters
    });
  };
  acknowledgeFeedback = id => {
    this.props.client
      .mutate({ variables: { feedback_id: id }, mutation: acknowledgeFeedback })
      .then(result => {
        if (result && result.data && result.data.acknowledgeFeedback) {
          message.success('Feedback acknowledged sent');
          this.fetch({ results: 20, page: 1 });
        } else {
          throw new Error('Could not acknowledge feedback, try again');
        }
      })
      .catch(err => message.error(err.message));
  };
  showTemplateModal = template => {
    this.setState({ modal: template ? template : true });
  };
  removeTemplateModal = () => this.setState({ modal: false });
  render() {
    const { pagination, data, loading, modal } = this.state;
    const total = pagination.totalAll || 0;
    const totalFiltered = pagination.total || 0;
    const columns = getColumns(this.showTemplateModal);

    return (
      <div id="admin-templates">
        <div className="admin-header">
          Sequence Templates{' '}
          <div>
            Showing {totalFiltered} of {total}
          </div>
          <Button className="btnx btnx-primary" onClick={() => this.showTemplateModal()}>
            + Template
          </Button>
        </div>
        <div className="admin-table">
          <div className="table-content">
            <Table
              className="sellx-table bordered"
              bordered
              rowSelection={null}
              columns={columns}
              rowKey={record => record.id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              locale={{ emptyText: <Empty description="No sequence templates" /> }}
            />
          </div>
        </div>
        {modal ? (
          <TemplateModal
            client={this.props.client}
            types={types}
            template={modal === true ? null : modal}
            global={true}
            removeModal={this.removeTemplateModal}
            reload={() => this.fetch({ results: 20, page: 1 })}
          />
        ) : null}
      </div>
    );
  }
}
